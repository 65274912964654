package search.searchFilterStores

import analytics.AnalyticsCategory
import analytics.AnalyticsService
import apiclient.geoobjects.ObjectType
import dev.fritz2.core.RootStore
import koin.koinCtx
import kotlinx.coroutines.Job

abstract class ActiveObjectTypesStore : RootStore<List<ObjectType>>(
    initialData = emptyList(),
    job = Job(),
) {

    private val analyticsService by koinCtx.inject<AnalyticsService>()

    val add = handle<ObjectType> { current, newObjType ->
        if (newObjType !in current) {
            analyticsService.createEvent(AnalyticsCategory.AddObjectType) {
                click(newObjType.name)
            }
//            (current + newObjType).distinct()
            listOf(newObjType)
        } else current
    }

    val remove = handle<ObjectType> { current, newObjType ->
        if (current.isNotEmpty() && newObjType in current) (current - newObjType).distinct()
        else current
    }

    val reset = handle {
        emptyList()
    }
}
