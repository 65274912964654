package map.bottombar

import apiclient.validations.parseEnumValue
import camera.nimiq.BrowserCamera
import camera.zxing.ZXingCodeScanService
import dev.fritz2.core.RootStore
import dev.fritz2.core.invoke
import dev.fritz2.routing.MapRouter
import koin.koinCtx
import kotlinx.coroutines.CoroutineName
import kotlinx.coroutines.Job
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.flow.mapNotNull
import kotlinx.coroutines.launch
import mainmenu.Pages
import mainmenu.RouterStore
import search.global.ActiveSearchKeywordsStore
import search.global.SearchInputFieldStore

class ActiveBottomBarPageStore : RootStore<Pages>(
    initialData = Pages.Map,
    job = Job(),
) {
    val routerStore: RouterStore by koinCtx.inject()

    // search
    private val router: MapRouter by koinCtx.inject()
    private val searchInputFieldStore: SearchInputFieldStore by koinCtx.inject()
    private val activeSearchKeywordsStore: ActiveSearchKeywordsStore by koinCtx.inject()
    private val browserCamera: BrowserCamera by koinCtx.inject()
    private val zXingCodeScanService = koinCtx.get<ZXingCodeScanService>()

    val changePage = handle<Pages> { current, newPage ->
        val pageBeforeWas = current
        if (current != newPage) {
            coroutineScope {
                CoroutineName("execute-page-change-handlers")
                launch {
                    when (pageBeforeWas) {
                        Pages.Menu -> {
                            // handlers when menu closed
                            routerStore.removeToMapClickListener()
                        }

                        Pages.Hub -> {
                            // handlers when hub closed
                        }

                        Pages.Map -> {
                            // handlers when map closed
                        }

                        Pages.Scan -> {
                            // handlers when scan closed
                            browserCamera.stop()
                            zXingCodeScanService.reset()
                        }

                        Pages.Search -> {
                            // handlers when search closed
                            searchInputFieldStore.reset()
                            activeSearchKeywordsStore.reset()
                            //                            routerStore.goToMap()
                        }

                        else -> {}
                    }
                }
            }
            newPage
        } else current
    }

    init {
        router.data.mapNotNull { parseEnumValue<Pages>(it["page"]) } handledBy changePage
    }
}
