package signup

import dev.fritz2.components.compat.button
import dev.fritz2.components.compat.div
import dev.fritz2.components.compat.input
import dev.fritz2.components.flexBox
import dev.fritz2.components.icon
import dev.fritz2.components.lineUp
import dev.fritz2.components.stackUp
import dev.fritz2.core.RenderContext
import dev.fritz2.core.RootStore
import dev.fritz2.core.SimpleHandler
import dev.fritz2.core.autofocus
import dev.fritz2.core.placeholder
import dev.fritz2.core.value
import dev.fritz2.core.values
import koin.koinCtx
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import localization.TL
import localization.Translation
import routing.MainController
import styling.secondaryButtonStyleParams
import theme.FormationDefault.Companion.formationStyles
import theme.FormationIcons
import utils.focusInputObserver
import webcomponents.baseLayout
import webcomponents.contentScrollBox
import webcomponents.fullWidthFullContainer
import webcomponents.genericButton
import webcomponents.mainTitle
import webcomponents.twoButtonFooter

class ActivationCodeStore : RootStore<String>(
    initialData = "",
    job = Job(),
) {

    var latest: String = ""

    val addDigits = handle<Int> { current, digits ->
        val number = digits.toString()
        if (latest.length + number.length <= 6) {
            latest += number
            latest
        } else current
    }

    val removeLastDigit = handle { current ->
        if (latest.isNotEmpty()) {
            latest = latest.dropLast(1)
            latest
        } else current
    }

    val reset = handle {
        latest = ""
        latest
    }
}

fun RenderContext.pageActivate() {

    val translation: Translation by koinCtx.inject()
    val mainController: MainController by koinCtx.inject()
    val activationCodeStore: ActivationCodeStore by koinCtx.inject()
    val signupStore: SignupStore by koinCtx.inject()
    val validatePasswordStore: ValidatePasswordStore by koinCtx.inject()

    baseLayout(
        expandable = false,
        content = {
            contentScrollBox(margins = { top { normal } }) {
                fullWidthFullContainer {
                    flexBox({ flex { grow { "1" } } }) { }

                    mainTitle(translation[TL.General.ACTIVATE_ACCOUNT])

                    flexBox({ flex { grow { "1" } } }) { }

                    activationCodeInput()

                    flexBox({ flex { grow { "2" } } }) { }

                    resendCodeButton()

                    flexBox({ flex { grow { "2" } } }) { }
                }
            }
        },
        footer = {
            twoButtonFooter(
                secondaryTitle = translation[TL.General.CANCEL],
                secondaryClickHandlers = listOf(mainController.logoutWithParams, activationCodeStore.reset, validatePasswordStore.reset),
                primaryTitle = translation[TL.General.ACTIVATE_ACCOUNT],
                primaryValue = Unit,
                primaryClickHandlers = listOf(signupStore.activateAccount),
                primaryTracker = signupStore.activationTracker,
            )
        },
    )
}

fun RenderContext.activationCodeInput() {
    val activationCodeStore: ActivationCodeStore by koinCtx.inject()
    val translation: Translation by koinCtx.inject()

    input(
        {
            minHeight(formationStyles.inputHeight)
            maxHeight(formationStyles.inputHeight)
            width { maxContent }
            fontFamily { mono }
            fontSize { normal }
            fontWeight { bold }
            border {
                width { "2px" }
                color { primary.main }
            }
            textAlign { center }
            padding { small }
        },
        id = "activationCodeInput",
    ) {
        autofocus(true)
        value(activationCodeStore.data)
        placeholder(translation[TL.PageActivation.ACTIVATION_CODE_PLACEHOLDER])
        inputs.values() handledBy activationCodeStore.update

        focusInputObserver({ js("document.getElementById('activationCodeInput').focus()") }, domNode)
    }

}

fun RenderContext.resendCodeButton() {
    val translation: Translation by koinCtx.inject()
    val signupStore: SignupStore by koinCtx.inject()
//    smallSelectButton {
//        translation[TL.PageActivation.RESEND_CODE].renderText(into = this)
//        clicks handledBy signupStore.resendActivationCode
//    }
    genericButton(
        title = translation[TL.PageActivation.RESEND_CODE],
        width = { auto },
        styleType = { secondary },
        styleFlow = flowOf(secondaryButtonStyleParams),
        clickHandlers = listOf(signupStore.resendActivationCode),
        value = Unit,
        tracker = signupStore.resendCodeTracker,
        trackerLoadingText = translation[TL.PageActivation.SENDING],
    )
}


/**
 * CodePad and single digit output components, maybe use later
 */

fun RenderContext.activationCodeOutput() {
    val activationCodeStore: ActivationCodeStore by koinCtx.inject()

    activationCodeStore.data.render { code ->
        lineUp {
            spacing { tiny }
            items {
                singleDigitOutput(code[0].toString())
                singleDigitOutput(code[1].toString())
                singleDigitOutput(code[2].toString())
                singleDigitOutput(code[3].toString())
                singleDigitOutput(code[4].toString())
                singleDigitOutput(code[5].toString())
            }
        }
    }
}

fun RenderContext.singleDigitOutput(value: String? = null) {
    flexBox(
        {
            width { "30px" }
            height { "30px" }
            border {
                width { "2px" }
                color { primary.main }
            }
            alignItems { center }
            justifyContent { center }
            fontFamily { mono }
            fontSize { normal }
            fontWeight { bold }
            if (value != null) {
                color { secondary.main }
                background { color { primary.main } }
            }
        },
    ) {
        if (value != null) +"$value"
    }
}

fun RenderContext.singleDigitButton(value: Int, clickHandlers: List<SimpleHandler<Int>>? = null) {
    button(
        {
            width { "40px" }
            height { "40px" }
            hover {
                color { secondary.main }
                background {
                    color { primary.main }
                }
            }
            display { flex }
            alignItems { center }
            justifyContent { center }
            fontFamily { mono }
            fontSize { larger }
            fontWeight { bold }
        },
    ) {
        +"$value"
        clickHandlers?.forEach { handler ->
            clicks.map { value } handledBy handler
        }
    }
}

fun RenderContext.removeDigitButton(clickHandlers: List<SimpleHandler<Unit>>? = null) {
    button(
        {
            width { "40px" }
            height { "40px" }
            hover {
                color { secondary.main }
                background {
                    color { primary.main }
                }
            }
            display { flex }
            alignItems { center }
            justifyContent { center }
        },
    ) {
        icon(
            {
                size { larger }
            },
        ) { fromTheme { FormationIcons.Backspace.icon } }
        clickHandlers?.forEach { handler ->
            clicks handledBy handler
        }
    }
}

fun RenderContext.digitButtonRow(
    values: IntArray,
    clickHandlers: List<SimpleHandler<Int>>? = null
) {
    lineUp(
        {
            width { full }
            justifyContent { center }
            alignItems { center }
        },
    ) {
        spacing { larger }
        items {
            values.forEach { v ->
                singleDigitButton(
                    value = v,
                    clickHandlers = clickHandlers,
                )
            }
        }
    }
}

fun RenderContext.codePad() {
    val activationCodeStore: ActivationCodeStore by koinCtx.inject()
    stackUp(
        {
            justifyContent { spaceBetween }
            alignItems { center }
        },
    ) {
        spacing { large }
        items {
            digitButtonRow(intArrayOf(1, 2, 3), listOf(activationCodeStore.addDigits))
            digitButtonRow(intArrayOf(4, 5, 6), listOf(activationCodeStore.addDigits))
            digitButtonRow(intArrayOf(7, 8, 9), listOf(activationCodeStore.addDigits))
            lineUp(
                {
                    width { full }
                    justifyContent { center }
                    alignItems { center }
                },
            ) {
                spacing { larger }
                items {
                    div({ width { "40px" }; height { "40px" } }) { } // dummy
                    singleDigitButton(0, listOf(activationCodeStore.addDigits))
                    removeDigitButton(listOf(activationCodeStore.removeLastDigit))
                }
            }
        }
    }
}
