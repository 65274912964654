package auth

import apiclient.FormationClient
import apiclient.auth.createSignInToken
import com.tryformation.localization.Translatable
import dev.fritz2.components.modal
import dev.fritz2.core.RootStore
import dev.fritz2.core.SimpleHandler
import dev.fritz2.core.invoke
import koin.withKoin
import kotlin.time.Duration.Companion.seconds
import kotlinx.coroutines.CoroutineName
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.cancel
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.filter
import kotlinx.coroutines.launch
import localization.TL
import localization.translate
import qrcodegeneration.toSvgQrCode
import theme.FormationUIIcons
import twcomponents.twColOf
import twcomponents.twLargeIconButton
import twcomponents.twMarkdownContent

enum class OpenOnAnotherDeviceTexts : Translatable {
    Explainer
    ;

    override val prefix = "open-on-another-device"
}

fun openAnotherDevicePopup() = modal(
    {
        flex {
            shrink { "1" }
        }
        width { "500px" }
        maxWidth { full }
        height { maxContent }
        maxHeight { full }
        radius { "24px" }
    },
) {
    placement { center }
    hasCloseButton(false)
    content { closeHandler ->
        withKoin {
            // create a new store when this is rendered; that way it should go away when the user navigates away as well.
            val signinUrlStore = AutoRefreshingQrCodeLinkStore(job)

            signinUrlStore.startAutoRefresh()

            div("flex flex-col w-full h-full") {
                div("flex w-full h-max items-start justify-end gap-2") {
                    twLargeIconButton(icon = FormationUIIcons.Close.icon) {
                        clicks handledBy {
                            signinUrlStore.stopAutoRefresh()
                            closeHandler.invoke()
                        }
                    }
                }
                div("flex flex-col justify-between w-full px-2 lg:px-5") {
                    twColOf {
                        h3 {
                            translate(TL.MainMenu.LoginWithDifferentDevice)
                        }

                        signinUrlStore.data.filter { it.isNotBlank() }.render { qrCode ->
                            svg("w-full") {}.domNode.apply {
                                outerHTML = qrCode
                            }
                        }

                        twMarkdownContent(OpenOnAnotherDeviceTexts.Explainer)
                    }
                }
            }
        }
    }
}

class AutoRefreshingQrCodeLinkStore(job: Job) : RootStore<String>("", job) {

    private var autoRefreshJob: Job? = null

    private suspend fun getNewCode() {
        withKoin {
            val client = get<FormationClient>()
            val currentWorkspaceStore = get<CurrentWorkspaceStore>()
            val apiUserStore = get<ApiUserStore>()
            currentWorkspaceStore.current?.name?.let { workspaceName ->

                client.createSignInToken(apiUserStore.current.userId, workspaceName).getOrNull()?.let { token ->
                    update(toSvgQrCode("https://app.tryformation.com/#logintoken=${token.token}"))
                }
            }
        }
    }

    private val autoRefreshOrCancel = SimpleHandler<Boolean> { data, _ ->
        data handledBy { autoRefresh ->
            if (autoRefresh) {
                autoRefreshJob?.cancel("Stop auto-refresh of qr code link to start a new one.")
                val newJob = CoroutineScope(CoroutineName("qr-code-link-auto-refresh")).launch {
                    console.log("Start auto-refresh of qr code link.")
                    while (true) {
                        try {
                            console.log("Getting new code")
                            getNewCode()
                        } catch (t: Throwable) {
                            console.error(t)
                        }
                        delay(20.seconds)
                    }
                }
                autoRefreshJob = newJob
            } else {
                console.log("Stop auto-refresh of qr code link.")
                autoRefreshJob?.cancel("Stop auto-refresh of qr code link.")
                autoRefreshJob = null
                update("")
            }
        }
    }

    fun startAutoRefresh() = autoRefreshOrCancel(true)

    fun stopAutoRefresh() = autoRefreshOrCancel(false)
    
}
