package wizard.onbaording

import apiclient.FormationClient
import apiclient.auth.createSignInToken
import auth.ApiUserStore
import auth.CurrentWorkspaceStore
import com.jillesvangurp.geojson.urlEncode
import data.users.settings.LocalSettingsStore
import dev.fritz2.components.icon
import dev.fritz2.components.modal
import dev.fritz2.core.HtmlTag
import dev.fritz2.core.RenderContext
import dev.fritz2.core.RootStore
import dev.fritz2.core.SimpleHandler
import dev.fritz2.core.invoke
import dev.fritz2.core.src
import dev.fritz2.core.transition
import dev.fritz2.styling.params.ScaledValueProperty
import koin.koinCtx
import kotlinx.browser.window
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import kotlinx.datetime.Clock
import kotlinx.serialization.SerializationException
import kotlinx.serialization.json.Json
import localization.TL
import localization.Translation
import mainmenu.Pages
import mainmenu.RouterStore
import model.LocalSettings
import org.w3c.dom.HTMLElement
import org.w3c.dom.asList
import org.w3c.dom.svg.SVGElement
import overlays.BusyStore
import qrcode.CodeConfigurationStore
import qrcode.defaultCharacters
import qrcode.randomCode
import qrcodegeneration.toSvgQrCode
import signup.textButton
import styling.primaryButtonStyleParams
import styling.secondaryButtonStyleParams
import theme.FormationColors
import theme.FormationIcons
import theme.FormationUIIcons
import twcomponents.twLargeIconButton
import utils.getPartOfDay
import utils.makeRGBA
import webcomponents.Position
import webcomponents.genericButton
import workspacetools.usermanagement.confirm

enum class Wizard {
    Onboarding
}

enum class OnboardingWizardPage {
    Welcome {
        override val lastPage = false
    },
    PrintQR {
        override val lastPage = false
    },
    CreateAsset {
        override val lastPage = false
    },
    ScanWithMobile {
        override val lastPage = false
    },
    ScanQRTracker {
        override val lastPage = false
    },
    LearnTracking {
        override val lastPage = false
    },

    //    PracticeTracking {
//        override val lastPage = false
//    },
    TrackingSuccess {
        override val lastPage = false
    },
    UpgradeCTA {
        override val lastPage = true
    },
    ;

    abstract val lastPage: Boolean

}

class OnboardingWizardPageStore(
    json: Json,
    localSettingsStore: LocalSettingsStore
) : RootStore<OnboardingWizardPage>(
    initialData = getWizardState(OnboardingWizardPage.Welcome, json, localSettingsStore),
    job = Job(),
) {

    val routerStore: RouterStore by koinCtx.inject()
    val localSettingsStore: LocalSettingsStore by koinCtx.inject()

    val initialize = handle { current ->
        current
    }

    val next = handle { current ->
        if (current.ordinal < OnboardingWizardPage.entries.size - 1) {
            OnboardingWizardPage.entries[current.ordinal + 1]
        } else current
    }

    val previous = handle { current ->
        if (current.ordinal > 0) {
            OnboardingWizardPage.entries[current.ordinal - 1]
        } else current
    }

    val selectByIndex = handle<Int> { _, index ->
        OnboardingWizardPage.entries[index]
    }

    val reset = handle {
        OnboardingWizardPage.Welcome
    }

    val wizardFinished = handle { current ->
        // TODO add things to do when onboarding wizard finished
        current
    }

    val quit = handle {
        routerStore.back()
        OnboardingWizardPage.Welcome
    }

    val wizardClosed = handle { currentStep ->
        // send analytics feedback to server with current onbaordingwizardpage
        currentStep
    }

    private val syncWithLocalStore = handle<OnboardingWizardPage> { _, page ->
        localSettingsStore.updateWizardState(Wizard.Onboarding to page.ordinal)
        setActionButtons(page.ordinal, localSettingsStore)
        page
    }

    init {
        data handledBy syncWithLocalStore
    }

    companion object {
        fun setActionButtons(
            pageOrdinal: Int,
            localSettingsStore: LocalSettingsStore
        ) {
            val page = OnboardingWizardPage.entries.getOrNull(pageOrdinal)
            when {
                pageOrdinal <= 0 -> {
                    localSettingsStore.updateActionButtonState(ActionButton.UpgradePlan to false)
                    localSettingsStore.updateActionButtonState(ActionButton.StartOnboarding to true)
                    localSettingsStore.updateActionButtonState(ActionButton.ContinueOnboarding to false)
                }

                page != null && page.lastPage -> {
                    localSettingsStore.updateActionButtonState(ActionButton.UpgradePlan to true)
                    localSettingsStore.updateActionButtonState(ActionButton.StartOnboarding to false)
                    localSettingsStore.updateActionButtonState(ActionButton.ContinueOnboarding to false)
                }

                page != null && !page.lastPage -> {
                    localSettingsStore.updateActionButtonState(ActionButton.UpgradePlan to false)
                    localSettingsStore.updateActionButtonState(ActionButton.StartOnboarding to false)
                    localSettingsStore.updateActionButtonState(ActionButton.ContinueOnboarding to true)
                }
            }
        }

        fun getWizardState(
            default: OnboardingWizardPage,
            json: Json,
            localSettingsStore: LocalSettingsStore
        ): OnboardingWizardPage {
            val string = window.localStorage.getItem("com.tryformation.webapp.localSettings") ?: return default
            return try {
                val localSettings = json.decodeFromString(LocalSettings.serializer(), string)
                val pageOrdinal = localSettings.wizardStates[Wizard.Onboarding] ?: -1
                setActionButtons(pageOrdinal, localSettingsStore)
                if (pageOrdinal in 0 until OnboardingWizardPage.entries.size) {
                    OnboardingWizardPage.entries[pageOrdinal]
                } else {
                    OnboardingWizardPage.Welcome
                }
            } catch (e: SerializationException) {
                console.error(e)
                default
            }
        }
    }
}

class OnboardingWizardDemoCodeStore : RootStore<String?>(null, job = Job()) {

    val currentWorkspaceStore: CurrentWorkspaceStore by koinCtx.inject()

    val generateOnscreenDemoTrackerCode = handle {
        val newCode = "DEMO-${
            randomCode(
                length = 10,
                charPool = defaultCharacters,
            )
        }"
        toSvgQrCode(
            "https://app.tryformation.com/#id=${
                newCode.urlEncode()
            }&ws=${currentWorkspaceStore.current?.name}",
        ).also {
            console.log("Created onscreen DEMO Tracker Code svg (Code: $newCode)", it)
            update(it)
        }
    }

    val reset = handle {
        null
    }
}

class OnboardingWizardLoginCodeStore : RootStore<String?>(null, job = Job()) {

    val apiUserStore: ApiUserStore by koinCtx.inject()
    val currentWorkspaceStore: CurrentWorkspaceStore by koinCtx.inject()
    val formationClient: FormationClient by koinCtx.inject()
    val busyStore: BusyStore by koinCtx.inject()

    val generateLoginCode = handle { current ->
        busyStore.handleApiCall(
            supplier = {
                currentWorkspaceStore.current?.name?.let { workspaceName ->
                    formationClient.createSignInToken(
                        userId = apiUserStore.current.userId,
                        workspace = workspaceName,
                    )
                }
            },
            processResult = { result ->
                console.log("Created Sign in token successfully!")
                console.log("Update Sign in token link", "https://app.tryformation.com/#logintoken=${result.token}")
                toSvgQrCode("https://app.tryformation.com/#logintoken=${result.token}").also {
                    console.log("Created Sign in token QR code svg", it)
                    update(it)
                }
            },
            processError = { error ->
                console.error("Failed to create Sign in token", error.message)
            },
        )
        current
    }

    val reset = handle {
        null
    }
}

fun wizardModal(
    width: ScaledValueProperty = { "800px" },
    content: (HtmlTag<HTMLElement>.(close: SimpleHandler<Unit>) -> Unit)? = null,
) = modal(
    {
        flex {
            shrink { "1" }
        }
        width(width)
        maxWidth { full }
        height(sm = { "600px" }, md = { "540px" })
        maxHeight { full }
        radius { "24px" }
    },
) {
    placement { center }
    hasCloseButton(false)
    content { closeHandler ->

        div("w-full h-full flex flex-col") {
            // Content
            content?.invoke(this, closeHandler)
        }
    }
}

fun RenderContext.onBoardingWizardHeader(modalCloseHandler: SimpleHandler<Unit>) {
    val translation: Translation by koinCtx.inject()
    val onboardingWizardPageStore: OnboardingWizardPageStore by koinCtx.inject()
    val onboardingWizardLoginCodeStore: OnboardingWizardLoginCodeStore by koinCtx.inject()
    // Logo
    span(
        "text-xl sm:text-2xl font-thin tracking-widest pl-2",
    ) {
        +"F O R M A T I O N"
    }

    div("flex flex-row items-center justify-center gap-2") {
        // Restart button
        onboardingWizardPageStore.data.render { page ->
            if (page.lastPage) {
                textButton(
                    text = translation[TL.OnBoardingWizardTexts.RESTART_DEMO_BUTTON],
                    fontSize = { small },
                    clickHandlers = listOf(onboardingWizardPageStore.reset),
                )
            }
        }

        // Close button
        twLargeIconButton(icon = FormationUIIcons.Close) {
            clicks handledBy {
                when (onboardingWizardPageStore.current) {
                    OnboardingWizardPage.UpgradeCTA -> {
                        modalCloseHandler()
                        onboardingWizardLoginCodeStore.reset()
                    }

                    else -> modalCloseHandler()
                }
            }
        }
    }
}

fun RenderContext.onScreenTrackingDemoCodeSection() {
    val onboardingWizardDemoCodeStore: OnboardingWizardDemoCodeStore by koinCtx.inject()

    val demoTrackingCode: HtmlTag<HTMLElement>.() -> Unit = {
        onboardingWizardDemoCodeStore.data.render { onscreenDemoTrackerCode ->
            onscreenDemoTrackerCode?.let {
                this.domNode.innerHTML = it
            }.also {
                this.domNode.children.asList().firstOrNull { it is SVGElement }.also { svg ->
                    svg?.setAttribute("height", "100%")
                    svg?.setAttribute("width", "100%")
                    console.log("SVG ATTRIBUTES", svg?.attributes?.asList()?.toTypedArray())
                }
            }
        }
    }

    img("max-h-48 max-w-full") {
        src("assets/images/wizardImages/onboardingImages/qr_tracker.png")
    }

    span("text-lg sm:text-xl font-bold max-w-full") { +"OR" }

    // generated working Tracking QR code that is stored throughout the wizard session
    div("flex flex-col items-stretch justify-center hover:bg-formationBlack hover:text-formationWhite p-1 rounded-lg cursor-pointer") {

        div("h-48 max-h-48 max-w-full") {
            demoTrackingCode()
        }

        div("flex flex-row items-center justify-evenly gap-1") {
            span("text-sm sm:text-lg font-mono font-bold italic text-center") { +"Tracking Code" }
            icon { fromTheme { expand } }
        }

        clicks handledBy wizardModal(width = { "500px" }) { close ->
            div("flex flex-row items-center justify-between gap-2") {
                onBoardingWizardHeader(close)
            }
            div("w-full h-full flex flex-row items-center justify-center gap-2") {
                div("flex flex-col items-stretch justify-center") {
                    div("h-full max-h-full max-w-full") {
                        demoTrackingCode()
                    }
                    span("text-sm sm:text-lg font-mono font-bold italic w-full text-center") { +"Demo Tracking Code" }
                }
            }
        }
    }
}

fun RenderContext.onBoardingWizard(closeHandler: SimpleHandler<Unit>) {
    val translation: Translation by koinCtx.inject()
    val apiUserStore: ApiUserStore by koinCtx.inject()
    val onboardingWizardPageStore: OnboardingWizardPageStore by koinCtx.inject()
    val onboardingWizardDemoCodeStore: OnboardingWizardDemoCodeStore by koinCtx.inject()
    val onboardingWizardLoginCodeStore: OnboardingWizardLoginCodeStore by koinCtx.inject()
    val codeConfigurationStore: CodeConfigurationStore by koinCtx.inject()

    if (onboardingWizardDemoCodeStore.current == null) {
        onboardingWizardDemoCodeStore.generateOnscreenDemoTrackerCode()
    }

    // HEADER
    div("flex flex-row items-center justify-between gap-2") {
        onBoardingWizardHeader(closeHandler)
    }

    onboardingWizardPageStore.data.render { page ->
        div("flex flex-col w-full h-full") {
            transition(
                "ease-out duration-200",
                "opacity-0",
                "opacity-100",
                "ease-in duration-200",
                "opacity-100",
                "opacity-0",
            )

            // pptional swipe listener for back and forth with mobile swipe gestures
//            horizontalSwipeListener(
//                domNode,
//                onSwipedLeft = { onboardingWizardPageStore.next() },
//                onSwipedRight = { onboardingWizardPageStore.previous() },
//            )

            when (page) {
                OnboardingWizardPage.Welcome -> {
                    wizardPage(
                        headerSection = null,
                        titleSection = {
                            div("flex flex-col w-full text-2xl font-mono text-center gap-1") {
                                translation[
                                    TL.PageHub.GREETING,
                                    mapOf(
                                        "partOfDay" to Clock.System.now().getPartOfDay().stringKey,
                                        "userName" to apiUserStore.current.firstName,
                                    ),
                                ].renderText()
                                translation[TL.OnBoardingWizardTexts.WELCOME].renderText()
                            }
                        },
                        textSection = {
                            span("font-mono text-center text-sm sm:text-base w-full sm:w-3/4") {
                                translation[TL.OnBoardingWizardTexts.WELCOME_TEXT].renderText(into = this)
                            }
                        },
                        buttonsSection = {
                            div("w-full flex flex-row flex-wrap items-center justify-center gap-2") {
                                genericButton(
                                    title = translation[TL.OnBoardingWizardTexts.WELCOME_TRACK_YOUR_FIRST_ASSET_BUTTON],
                                    icon = { FormationIcons.Location.icon },
                                    styleFlow = flowOf {
                                        primaryButtonStyleParams()
                                        background {
                                            color {
                                                FormationColors.MarkerYou.color
                                            }
                                        }
                                        hover {
                                            background {
                                                color {
                                                    makeRGBA(FormationColors.MarkerYou.color, 0.5)
                                                }
                                            }
                                        }
                                    },
                                    iconPosition = Position.Right,
                                    width = { "240px" },
                                    value = Unit,
                                    clickHandlers = listOf(onboardingWizardPageStore.next),
                                )
                            }
                        },
                    )
                }

                OnboardingWizardPage.PrintQR -> {
                    wizardPage(
                        headerSection = null,
                        titleSection = {
                            img("mx-aut0 max-h-48") {
                                src("assets/images/wizardImages/onboardingImages/tracking_path_start.png")
                            }
                        },
                        textSection = {
                            span("font-mono text-center text-sm sm:text-base w-full sm:w-3/4") {
                                translation[TL.OnBoardingWizardTexts.PRINT_QR_TEXT].renderText(into = this)
                            }
                        },
                        buttonsSection = {
                            textButton(
                                text = translation[TL.OnBoardingWizardTexts.PRINT_QR_DONT_HAVE_PRINTER_BUTTON],
                                clickHandlers = listOf(
                                    confirm(
                                        title = translation[TL.OnBoardingWizardTexts.PRINT_QR_NO_PRINTER_PROMPT_TITLE],
                                        text = translation[TL.OnBoardingWizardTexts.PRINT_QR_NO_PRINTER_PROMPT_TEXT],
                                        okHandlers = listOf(),
                                        withCancelButton = false,
                                    ),
                                ),
                            )

                            textButton(
                                text = translation[TL.OnBoardingWizardTexts.ALREADY_HAVE_QR_TRACKER_BUTTON],
                                clickHandlers = listOf(onboardingWizardPageStore.next),
                            )

                            div("w-full flex flex-row flex-wrap items-center justify-center gap-2") {
                                genericButton(
                                    title = translation[TL.OnBoardingWizardTexts.PRINT_QR_BUTTON],
                                    icon = { FormationIcons.Printer.icon },
                                    styleFlow = flowOf { primaryButtonStyleParams() },
                                    iconPosition = Position.Right,
                                    width = { "240px" },
                                    value = Unit,
                                    clickHandlers = listOf(codeConfigurationStore.generate, codeConfigurationStore.print),
                                )
                                genericButton(
                                    title = translation[TL.OnBoardingWizardTexts.DONE_NEXT_STEP_BUTTON],
                                    icon = { FormationIcons.ArrowRight.icon },
                                    iconPosition = Position.Right,
                                    width = { "240px" },
                                    styleFlow = flowOf { secondaryButtonStyleParams() },
                                    value = Unit,
                                    clickHandlers = listOf(onboardingWizardPageStore.next),
                                )
                            }
                        },
                    )
                }

                OnboardingWizardPage.CreateAsset -> {
                    wizardPage(
                        headerSection = null,
                        titleSection = {
                            img("mx-aut0 max-h-48") {
                                src("assets/images/wizardImages/onboardingImages/tracking_path_start.png")
                            }
                        },
                        textSection = {
                            span("font-mono text-center text-sm sm:text-base w-full sm:w-3/4") {
                                translation[TL.OnBoardingWizardTexts.CREATE_ASSET_TEXT].renderText(into = this)
                            }
                        },
                        buttonsSection = {
                            div("w-full flex flex-row flex-wrap items-center justify-center gap-2") {
                                genericButton(
                                    title = translation[TL.OnBoardingWizardTexts.WIZARD_GO_TO_NEXT_STEP_BUTTON],
                                    icon = { FormationIcons.ArrowRight.icon },
                                    styleFlow = flowOf { primaryButtonStyleParams() },
                                    iconPosition = Position.Right,
                                    width = { "240px" },
                                    value = Unit,
                                    clickHandlers = listOf(onboardingWizardPageStore.next),
                                )
                            }
                        },
                    )
                }

                OnboardingWizardPage.ScanWithMobile -> {
                    wizardPage(
                        headerSection = null,
                        titleSection = {

                            onboardingWizardLoginCodeStore.generateLoginCode()

                            val loginCode: HtmlTag<HTMLElement>.() -> Unit = {
                                onboardingWizardLoginCodeStore.data.render { signInSVG ->
                                    signInSVG?.let {
                                        this.domNode.innerHTML = it
                                    }.also {
                                        this.domNode.children.asList().firstOrNull { it is SVGElement }.also { svg ->
                                            svg?.setAttribute("height", "100%")
                                            svg?.setAttribute("width", "100%")
                                        }
                                    }
                                }
                            }

                            div("flex flex-col items-stretch justify-center hover:bg-formationBlack hover:text-formationWhite p-1 rounded-lg cursor-pointer") {

                                div("h-48 max-h-48 max-w-full") {
                                    loginCode()
                                }

                                div("flex flex-row items-center justify-evenly gap-1") {
                                    span("text-sm sm:text-lg font-mono font-bold italic text-center") { +"Login Code" }
                                    icon { fromTheme { expand } }
                                }


                                clicks handledBy wizardModal(width = { "500px" }) { close ->
                                    div("flex flex-row items-center justify-between gap-2") {
                                        onBoardingWizardHeader(close)
                                    }
                                    div("w-full h-full flex flex-row items-center justify-center gap-2") {
                                        div("flex flex-col items-stretch justify-center") {
                                            div("h-full max-h-full max-w-full") {
                                                loginCode()
                                            }
                                            span("text-sm sm:text-lg font-mono font-bold italic w-full text-center") { +"Mobile Login Code" }
                                        }
                                    }
                                }
                            }
                            img("max-h-48 max-w-full") {
                                src("assets/images/wizardImages/onboardingImages/hand.svg")
                            }
                        },
                        textSection = {
                            span("font-mono text-center text-sm sm:text-base w-full sm:w-3/4") {
                                translation[TL.OnBoardingWizardTexts.SCAN_WITH_MOBILE_TEXT].renderText(into = this)
                            }
                        },
                        buttonsSection = {
                            div("w-full flex flex-row flex-wrap items-center justify-center gap-2") {
                                genericButton(
                                    title = translation[TL.OnBoardingWizardTexts.WIZARD_GO_TO_NEXT_STEP_BUTTON],
                                    icon = { FormationIcons.ArrowRight.icon },
                                    styleFlow = flowOf { primaryButtonStyleParams() },
                                    iconPosition = Position.Right,
                                    width = { "240px" },
                                    value = Unit,
                                    clickHandlers = listOf(onboardingWizardPageStore.next),
                                )
                            }
                        },
                    )
                }

                OnboardingWizardPage.ScanQRTracker -> {
                    wizardPage(
                        headerSection = null,
                        titleSection = {
                            onScreenTrackingDemoCodeSection()
                        },
                        textSection = {
                            span("font-mono text-center text-sm sm:text-base w-full sm:w-3/4") {
                                translation[TL.OnBoardingWizardTexts.SCAN_QR_TRACKER_TEXT].renderText(into = this)
                            }
                        },
                        buttonsSection = {
                            div("w-full flex flex-row flex-wrap items-center justify-center gap-2") {
                                genericButton(
                                    title = translation[TL.OnBoardingWizardTexts.WIZARD_GO_TO_NEXT_STEP_BUTTON],
                                    icon = { FormationIcons.ArrowRight.icon },
                                    styleFlow = flowOf { primaryButtonStyleParams() },
                                    iconPosition = Position.Right,
                                    width = { "240px" },
                                    value = Unit,
                                    clickHandlers = listOf(onboardingWizardPageStore.next),
                                )
                            }
                        },
                    )
                }

                OnboardingWizardPage.LearnTracking -> {
                    wizardPage(
                        headerSection = null,
                        titleSection = {
                            onScreenTrackingDemoCodeSection()
                        },
                        textSection = {
                            span("font-mono text-center text-sm sm:text-base w-full sm:w-3/4") {
                                translation[TL.OnBoardingWizardTexts.LEARN_TRACKING_TEXT].renderText(into = this)
                            }
                        },
                        buttonsSection = {
                            div("w-full flex flex-row flex-wrap items-center justify-center gap-2") {
                                genericButton(
                                    title = translation[TL.OnBoardingWizardTexts.DONE_UNDERSTOOD_BUTTON],
                                    icon = { FormationIcons.ArrowRight.icon },
                                    styleFlow = flowOf { primaryButtonStyleParams() },
                                    iconPosition = Position.Right,
                                    width = { "240px" },
                                    value = Unit,
                                    clickHandlers = listOf(onboardingWizardPageStore.next),
                                )
                            }
                        },
                    )
                }

                OnboardingWizardPage.TrackingSuccess -> {
                    wizardPage(
                        headerSection = null,
                        titleSection = {
                            img("mx-aut0 max-h-48") {
                                src("assets/images/wizardImages/onboardingImages/tracking_path_done.png")
                            }
                        },
                        textSection = {
                            span("font-mono text-center text-sm sm:text-base w-full sm:w-3/4") {
                                translation[TL.OnBoardingWizardTexts.TRACKING_SUCCESS_TEXT].renderText(into = this)
                            }
                        },
                        buttonsSection = {
                            div("w-full flex flex-row flex-wrap items-center justify-center gap-2") {
                                genericButton(
                                    title = translation[TL.OnBoardingWizardTexts.WIZARD_DONE_FINISH_DEMO_BUTTON],
                                    icon = { circleCheck },
                                    styleFlow = flowOf { primaryButtonStyleParams() },
                                    iconPosition = Position.Right,
                                    width = { "240px" },
                                    value = Unit,
                                    clickHandlers = listOf(onboardingWizardPageStore.next),
                                )
                            }
                        },
                    )
                }

                OnboardingWizardPage.UpgradeCTA -> {
                    wizardPage(
                        headerSection = null,
                        titleSection = {
                            img("mx-aut0 max-h-48") {
                                src("assets/images/wizardImages/onboardingImages/noun-feedback-6636187 1.png")
                            }
                        },
                        textSection = {
                            span("font-mono text-center text-sm sm:text-base w-full sm:w-3/4") {
                                translation[TL.OnBoardingWizardTexts.UPGRADE_CTA_TEXT, mapOf("amount" to 3)].renderText(into = this)
                            }
                        },
                        buttonsSection = {
                            textButton(
                                text = translation[TL.OnBoardingWizardTexts.SEND_US_MESSAGE_BUTTON],
                                replaceRoute = Pages.Feedback.route,
                                clickHandlers = listOf(closeHandler),
                            )
                            div("w-full flex flex-row flex-wrap items-center justify-center gap-2") {
                                genericButton(
                                    title = translation[TL.OnBoardingWizardTexts.UPGRADE_FOR_X_MONTH_BUTTON, mapOf("amount" to 3)],
                                    icon = { FormationIcons.UpgradePlan.icon },
                                    styleFlow = flowOf {
                                        primaryButtonStyleParams()
                                        background {
                                            color {
                                                FormationColors.MarkerYou.color
                                            }
                                        }
                                        hover {
                                            background {
                                                color {
                                                    makeRGBA(FormationColors.MarkerYou.color, 0.5)
                                                }
                                            }
                                        }
                                    },
                                    iconPosition = Position.Right,
                                    width = { "240px" },
                                    value = Unit,
                                    clickHandlers = listOf(closeHandler, onboardingWizardPageStore.wizardFinished),
                                )
                            }
                        },
                    )
                }
            }
        }
    }

    // Progress bar showing current wizard page number
    div("w-full bg-slate-300 rounded-full") {
        div("flex flex-row items-center justify-center h-7.5 bg-formationBlack text-xs sm:text-sm text-slate-100 font-mono font-medium p-0.5 leading-none rounded-full transition-all duration-2000") {
            inlineStyle(
                onboardingWizardPageStore.data.map { page ->
                    "width: ${((page.ordinal + 1).toDouble() / OnboardingWizardPage.entries.size) * 100}%;"
                },
            )

            onboardingWizardPageStore.data.map { page ->
                if (page.ordinal in 0 until OnboardingWizardPage.entries.size - 1) {
                    "${page.ordinal + 1} / ${OnboardingWizardPage.entries.size}"
                } else {
                    translation.getString(TL.General.DONE).lowercase().replaceFirstChar { it.uppercase() } + "!"
                }
            }.renderText(into = this)
        }
    }
}

fun RenderContext.wizardPage(
    headerSection: (HtmlTag<HTMLElement>.() -> Unit)? = null,
    titleSection: (HtmlTag<HTMLElement>.() -> Unit)? = null,
    textSection: (HtmlTag<HTMLElement>.() -> Unit)? = null,
    buttonsSection: (HtmlTag<HTMLElement>.() -> Unit)? = null,
) {

    div("flex flex-row items-center justify-between gap-2") {
        headerSection?.invoke(this)
    }

    div("w-full h-full flex flex-col items-center justify-evenly p-2 gap-4") {

        div("w-full flex flex-row items-center justify-center gap-2") {
            titleSection?.invoke(this)
        }

        div("w-full flex flex-col items-center justify-center") {
            textSection?.invoke(this)
        }

    }
    div("w-full flex flex-col items-center justify-center mb-4 gap-2") {
        buttonsSection?.invoke(this)
    }
}
