package signup

import apiclient.passwords.PasswordEvaluation
import dev.fritz2.components.compat.a
import dev.fritz2.components.compat.button
import dev.fritz2.components.compat.div
import dev.fritz2.components.compat.span
import dev.fritz2.components.flexBox
import dev.fritz2.components.lineUp
import dev.fritz2.core.RenderContext
import dev.fritz2.core.SimpleHandler
import dev.fritz2.core.href
import dev.fritz2.core.target
import dev.fritz2.core.title
import dev.fritz2.styling.params.FlexParams
import dev.fritz2.styling.params.ScaledValueProperty
import dev.fritz2.styling.params.Style
import koin.koinCtx
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.map
import mainmenu.RouterStore
import model.ValidatePassword
import theme.FormationColors

fun RenderContext.inputTitle(text: Flow<String>) {
    span({
        fontSize { smaller }
        fontWeight { lighter }
        color { FormationColors.GrayDisabled.color }
        margins {
            left { normal }
        }
    }) { text.renderText(into = this) }
}

fun RenderContext.textButton(
    text: Flow<String>,
    fontSize: ScaledValueProperty = { normal },
    routingMap: Map<String, String>? = null,
    replaceRoute: Map<String, String>? = null,
    attributes: List<Pair<String, String>>? = null,
    clickHandlers: List<SimpleHandler<Unit>>? = null
) {
    val routerStore: RouterStore by koinCtx.inject()

    button({
        margins { horizontal { tiny } }
        height { minContent }
        hover {
            color { FormationColors.GreenBright.color }
        }
    }) {
        attributes?.forEach { (key, value) ->
            attr(key, value)
        }
        span({
            fontSize(fontSize)
            textDecoration { underline }
        }) {
            text.renderText(into = this)
        }
        with(clicks) {
            clickHandlers?.forEach { handler ->
                this handledBy handler
            }
            replaceRoute?.let { route ->
                this.map { route } handledBy routerStore.addOrReplaceRoute
            }
            routingMap?.let { route ->
                this.map { route } handledBy routerStore.validateInternalRoute
            }
        }
    }
}

fun RenderContext.textLinkButton(
    text: Flow<String>,
    fontSize: ScaledValueProperty = { normal },
    link: String,
    attributes: List<Pair<String, String>>? = null,
    addStyling: Style<FlexParams>? = null
) {
    a({
        height { minContent }
        hover {
            color { FormationColors.GreenBright.color }
        }
        addStyling?.let { it() }
    }) {
        href(link)
        target("_blank")
        attributes?.forEach { (key, value) ->
            attr(key, value)
        }
        span({
            fontSize(fontSize)
            textDecoration { underline }
        }) {
            text.renderText(into = this)
        }
    }
}

fun RenderContext.passwordStrengthIndicator(result: Flow<ValidatePassword>) {

    result.render { pwStack ->
        flexBox({
            direction { row }
            alignItems { center }
            justifyContent { spaceBetween }
            paddings { horizontal { normal } }
        }) {
            if (pwStack.firstPass.isNotBlank()) {
                val eval = pwStack.passwordEval
                if (eval != null) {
                    val color = when (eval) {
                        PasswordEvaluation.Unacceptable -> FormationColors.RedError.color
                        PasswordEvaluation.Weak -> FormationColors.OrangeCustom.color
                        PasswordEvaluation.Acceptable -> FormationColors.BlueMarine.color
                        PasswordEvaluation.Strong -> FormationColors.GreenForest.color
                        else -> FormationColors.RedError.color
                    }
                    val number = when (eval) {
                        PasswordEvaluation.Unacceptable -> 0
                        PasswordEvaluation.Weak -> 1
                        PasswordEvaluation.Acceptable -> 2
                        PasswordEvaluation.Strong -> 3
                        else -> 0
                    }

                    lineUp {
                        spacing { tiny }
                        items {
                            for (i in 1..number) {
                                div({
                                    width { "40px" }
                                    height { "5px" }
                                    radius { full }
                                    background { color { color } }
                                }) {}
                            }
                        }
                    }
                    span({
                        height { normal }
                        fontSize { tiny }
                        color { color }
                    }) {
                        title(eval.description)
                        +eval.name
                    }
                } else {
                    span({ height { normal } }) { +"" } // Placeholder
                }
            } else {
                span({ height { normal } }) { +"" } // Placeholder
            }
        }
    }
}
