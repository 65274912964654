package point2pointnavigation

import apiclient.FormationClient
import apiclient.geoobjects.Content
import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.LatLon
import apiclient.geoobjects.ObjectTags
import apiclient.geoobjects.SearchQueryContext
import apiclient.geoobjects.distanceTo
import apiclient.geoobjects.newContext
import apiclient.geoobjects.restSearch
import apiclient.tags.getUniqueTag
import apiclient.tags.tag
import auth.CurrentWorkspaceStore
import koin.withKoin


data class Edge(
    val from: GeoObjectDetails,
    val to: GeoObjectDetails,

    ) {
    val weight: Double = from.latLon.distanceTo(to.latLon)

    override fun equals(other: Any?): Boolean {
        return if (other is Edge) {
            this.from.id == other.from.id && this.to.id == other.to.id
        } else {
            false
        }
    }

    override fun hashCode(): Int {
        var result = from.hashCode()
        result = 31 * result + to.hashCode()
        result = 31 * result + weight.hashCode()
        return result
    }
}

data class NavigationGraph(
    val nodes: List<GeoObjectDetails>,
    val edges: List<Edge>
)

fun NavigationGraph.nearestNode(position: LatLon, floorId: String? = null) = nodes.filter { node ->
    node.tags.getUniqueTag(ObjectTags.ConnectedTo) == floorId
}.toList().minByOrNull {
    it.latLon.distanceTo(position)
} ?: error("no node found")


fun NavigationGraph.dijkstraShortestPath(start: GeoObjectDetails, end: GeoObjectDetails): List<GeoObjectDetails> {
    val distances = mutableMapOf<GeoObjectDetails, Double>()
    val previousNodes = mutableMapOf<GeoObjectDetails, GeoObjectDetails?>()
    val unvisitedNodes = mutableSetOf<GeoObjectDetails>()

    nodes.forEach { node ->
        distances[node] = Double.POSITIVE_INFINITY
        previousNodes[node] = null
        unvisitedNodes.add(node)
    }

    distances[start] = 0.0

    while (unvisitedNodes.isNotEmpty()) {
        val currentNode = unvisitedNodes.minByOrNull { distances[it] ?: Double.POSITIVE_INFINITY } ?: break
        unvisitedNodes.remove(currentNode)

        if (currentNode == end) break

        edges.filter { it.from == currentNode }.forEach { edge ->
            val neighbor = edge.to
            if (neighbor in unvisitedNodes) {
                val newDist = distances[currentNode]!! + edge.weight
                if (newDist < distances[neighbor]!!) {
//                    console.log("adding ${neighbor.title} with distance $newDist")
                    distances[neighbor] = newDist
                    previousNodes[neighbor] = currentNode
                }
            }
        }
    }

    return generatePath(previousNodes, end)
}

private fun generatePath(previousNodes: Map<GeoObjectDetails, GeoObjectDetails?>, end: GeoObjectDetails): List<GeoObjectDetails> {
    val path = mutableListOf<GeoObjectDetails>()
    var currentNode: GeoObjectDetails? = end
    while (currentNode != null) {
        path.add(currentNode)
        currentNode = previousNodes[currentNode]
    }
    if (path.first() != end) {
        console.log("invalid path")
        // This condition checks if the start node was reached
        // If not, this means no valid path was found
        return emptyList()
    }
    return path.reversed()
}


object RoutingService {
    private var graph: NavigationGraph? = null

    fun clearNavigationGraph() {
        console.log("Cleared NavigationGraph.")
        graph = null
    }

    suspend fun navigate(from: LatLon, to: LatLon, startFloorId: String? = null, targetFloorId: String? = null): List<GeoObjectDetails> {
        if (graph == null && from != to) {
            withKoin {
                console.log("calculating graph")
                val currentWorkspaceStore = get<CurrentWorkspaceStore>()
                val groupId = currentWorkspaceStore.current?.groupId ?: error("No GROUP!")
                graph = buildGraph(groupId)
                console.log("graph calculation completed ${graph?.nodes?.size} nodes, ${graph?.edges?.size} edges")
            }
        }
        return graph?.let { navigationGraph ->
            val fromNode = navigationGraph.nearestNode(from, startFloorId)
            val toNode = navigationGraph.nearestNode(to, targetFloorId)
            console.log("calculating path between ${fromNode.title} and ${toNode.title}")
            navigationGraph.dijkstraShortestPath(fromNode, toNode).also {
                console.log("found path!", it.joinToString(" ->\n\t") { it.title })
            }
        }.orEmpty()
    }

    private suspend fun buildGraph(groupId: String): NavigationGraph {
        withKoin {
            val client = get<FormationClient>()
            val navigationPoints = client.restSearch(
                SearchQueryContext.newContext(listOf(groupId)).copy(
                    size = 1000,
                    tags = listOf(ObjectTags.Keyword.tag("navigation-point")),
                ),
            ).getOrThrow().hits.map { it.hit }.associateBy { it.id }

            val nodes = mutableSetOf<GeoObjectDetails>()
            val edges = mutableSetOf<Edge>()

            navigationPoints.values.forEach { obj ->
                nodes.add(obj)
                obj.attachments?.filterIsInstance<Content.GeoObject>()?.forEach { other ->
                    other.objectId.let {
                        navigationPoints[it]
                    }?.let {
                        console.log("edge between ${obj.title} and ${it.title}")
                        // add incoming and outgoing edges
                        edges.add(Edge(obj, it))
                        edges.add(Edge(it, obj))
                    }
                }
            }

            return NavigationGraph(
                nodes = nodes.toList(),
                edges = edges.toList(),
            )
        }
    }
}
