package data.objects.views.directediting

import apiclient.geoobjects.Content
import apiclient.geoobjects.TaskTemplate
import com.tryformation.localization.Translatable
import data.objects.ActiveObjectStore
import dev.fritz2.core.HtmlTag
import dev.fritz2.core.RenderContext
import dev.fritz2.core.SimpleHandler
import dev.fritz2.core.storeOf
import koin.withKoin
import localization.translate
import org.w3c.dom.HTMLDivElement
import theme.FormationIcons
import theme.FormationUIIcons
import twcomponents.contentSelectButton
import twcomponents.twAddContentButton
import twcomponents.twColOf
import twcomponents.twIconMedium
import twcomponents.twMediumIconButtonNeutral
import twcomponents.twRowOfJustifyBetween

enum class ContentEditor {
    None,
    SelectContentType,
    MarkdownSection,
    Image,
    Link,
    Poll,
    TaskTemplate,
    Object,
//    Icon
}

enum class AddContentBar : Translatable {
    Text,
    Image,
    Link,
    Poll,
    Object,
    TaskTemplate,
    AddMoreContent,
//    Icon
    ;

    override val prefix = "add-content-bar"
}

fun RenderContext.addContentBar() {
    withKoin {
        val activeObjectStore = get<ActiveObjectStore>()
        val isEditable = activeObjectStore.current.editable
        val objectId = activeObjectStore.current.id
        val attachments = activeObjectStore.current.attachments
        if (isEditable) {
            val showContentButtonsStore = storeOf(ContentEditor.None)
            val contentButtonCloseHandler = SimpleHandler<Unit> { data, _ -> data handledBy { showContentButtonsStore.update(ContentEditor.None) } }
            showContentButtonsStore.data.render { show ->
                when (show) {
                    ContentEditor.SelectContentType -> {
                        twColOf {
                            div("mt-2 py-2 px-4 place-items-center bg-gray-200 rounded-xl shadow self-end", id = id) {
                                twMediumIconButtonNeutral(icon = FormationUIIcons.Close) {
                                    clicks handledBy contentButtonCloseHandler
                                }
                            }
                            contentSelectButton {
                                twIconMedium(icon = FormationIcons.Description)
                                translate(AddContentBar.Text)
                                clicks handledBy {
                                    showContentButtonsStore.update(ContentEditor.MarkdownSection)
                                }
                            }
                            contentSelectButton {
                                twIconMedium(icon = FormationUIIcons.Image)
                                translate(AddContentBar.Image)
                                clicks handledBy {
                                    showContentButtonsStore.update(ContentEditor.Image)
                                }
                            }
//                            twFeatureFlagDiv(flag = Features.AllowBitmapMarkerIcons) {
//                            contentSelectButton {
//                                twIconMedium(icon = FormationUIIcons.Image))
//                                translate(AddContentBar))
//                                clicks handledBy {
//                                    showContentButtonsStore.update(ContentEditor))
//                                }
//                            }
//                            }
                            contentSelectButton {
                                twIconMedium(icon = FormationUIIcons.Link)
                                translate(AddContentBar.Link)
                                clicks handledBy {
                                    showContentButtonsStore.update(ContentEditor.Link)
                                }
                            }
                            // FIXME: Multiple polls are not working due to tag issues
                            //  So for now disable this button if object already has a poll to prevent a second
                            if (attachments?.any { it is Content.Poll } != true) {
                                contentSelectButton {
                                    twIconMedium(icon = FormationUIIcons.Clipboard)
                                    translate(AddContentBar.Poll)

                                    clicks handledBy {
                                        showContentButtonsStore.update(ContentEditor.Poll)
                                    }
                                }
                            }
                            contentSelectButton {
                                twIconMedium(icon = FormationIcons.Location)
                                translate(AddContentBar.Object)
                                clicks handledBy {
                                    showContentButtonsStore.update(ContentEditor.Object)
                                }
                            }
                            contentSelectButton {
                                twIconMedium(icon = FormationIcons.TaskAlt)
                                translate(AddContentBar.TaskTemplate)
                                clicks handledBy {
                                    showContentButtonsStore.update(ContentEditor.TaskTemplate)
                                }
                            }
                        }
                    }

                    ContentEditor.None -> {
                        div("py-2") {
                            twAddContentButton {
                                twIconMedium(icon = FormationUIIcons.Add)
                                translate(AddContentBar.AddMoreContent)
                                clicks handledBy {
                                    showContentButtonsStore.update(ContentEditor.SelectContentType)
                                }
                            }
                        }
                    }

                    ContentEditor.MarkdownSection -> {
                        div("-mx-2 p-2 bg-gray-300") {
                            mdEditor(
                                objectId = objectId,
                                content = Content.Markdown("", ""),
                                editorCloseHandler = contentButtonCloseHandler,
                                header = ContentTexts.AddMarkdownSection,
                                isCreateNew = true,
                            )
                        }
                    }

                    ContentEditor.Link -> {
                        div("-mx-2 p-2 bg-gray-300") {
                            linkEditor(
                                objectId = objectId,
                                content = Content.WebLink("", ""),
                                editorCloseHandler = contentButtonCloseHandler,
                                header = ContentTexts.AddWebLink,
                                isCreateNew = true,
                            )
                        }
                    }

                    ContentEditor.Poll -> {
                        div("-mx-2 p-2 bg-gray-300") {
                            pollEditor(
                                objectId = objectId,
                                content = Content.Poll("", "", "", listOf()),
                                editorCloseHandler = contentButtonCloseHandler,
                                header = ContentTexts.AddPoll,
                                isCreateNew = true,
                            )
                        }
                    }

                    ContentEditor.Object -> {
                        div("-mx-2 p-2 bg-gray-300") {
                            geoObjectEditor(
                                objectId = objectId,
                                content = Content.GeoObject("", "", "", ""),
                                editorCloseHandler = contentButtonCloseHandler,
                                header = ContentTexts.AddGeoObject,
                                isCreateNew = true,
                            )
                        }
                    }

                    ContentEditor.TaskTemplate -> {
                        div("-mx-2 p-2 bg-gray-300") {
                            taskTemplateEditor(
                                objectId = objectId,
                                content = Content.ScanToCreateTask("", "", TaskTemplate()),
                                editorCloseHandler = contentButtonCloseHandler,
                                header = ContentTexts.AddTaskTemplate,
                                isCreateNew = true,
                            )
                        }
                    }

                    ContentEditor.Image -> {
                        div("-mx-2 p-2 bg-gray-300") {
                            imageEditor(
                                objectId = objectId,
                                content = null,
                                editorCloseHandler = contentButtonCloseHandler,
                                header = ContentTexts.AddImage,
                                isCreateNew = true,
                            )
                        }
                    }

//                    ContentEditor) -> {
//                        twFeatureFlagDiv(flag = Features.AllowBitmapMarkerIcons) {
//                            iconEditor(
//                                objectId = objectId,
//                                content = null,
//                                editorCloseHandler = contentButtonCloseHandler,
//                                header = AddContentTexts.Image,
//                                isCreateNew = true,
//                            )
//                        }
//                    }
                }
            }
        }
    }
}

enum class ContentTexts : Translatable {
    AddMarkdownSection,
    AddWebLink,
    AddGeoObject,
    AddPoll,
    AddTaskTemplate,
    AddImage,
    AddIcon,
    ARE_YOU_SURE_TO_DELETE_CONTENT,
    ARE_YOU_SURE_TO_DELETE_DESCRIPTION,
    ARE_YOU_SURE_TO_DELETE_BITMAP,
    SHARE_ON_FORMATION,
    SHARE_LINK,
    REPLACE_WITH,
    ;

    override val prefix = "content"
}

fun RenderContext.contentCreateHeader(
    closeHandler: SimpleHandler<Unit>? = null,
    header: Translatable? = null,
    saveButton: HtmlTag<HTMLDivElement>.() -> Unit
) {
    twRowOfJustifyBetween {
        header?.let { head ->
            h3 {
                translate(head)
            }
        }
        div("flex flex-row w-max gap-5 text-formationWhite bg-formationBlack py-2 px-5 rounded-xl shadow-xl") {
            saveButton(this)
            closeHandler?.let { handler ->
                twMediumIconButtonNeutral(icon = FormationUIIcons.Close) {
                    clicks handledBy handler
                }
            }
        }
    }
}




