package camera.cameraWrapper

import dev.fritz2.core.HtmlTag
import dev.fritz2.core.RenderContext
import dev.fritz2.core.ScopeContext
import dev.fritz2.core.selected
import dev.fritz2.core.storeOf
import dev.fritz2.core.value
import koin.koinCtx
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.map
import model.id
import org.w3c.dom.HTMLSelectElement
import org.w3c.dom.HTMLVideoElement
import theme.FormationIcons
import twcomponents.twMediumIconButtonHighlight
import twcomponents.twRowOfJustifyBetween
import twcomponents.twSelect

fun RenderContext.cameraControls(videoElement: HTMLVideoElement? = null) {
    val devicesStore: CameraDevicesStore by koinCtx.inject()
    val selectedCameraDeviceStore: SelectedCameraDeviceStore by koinCtx.inject()
    val selectedId = selectedCameraDeviceStore.map(CameraMeta.id())

    twRowOfJustifyBetween {
        devicesStore.data.render { devices ->
            if (devices.isEmpty()) {
                p {
                    +"no camera found"
                }
            } else if (devices.size == 1) {
                p {
                    devices.firstOrNull()?.label?.let { +it }
                }
            } else if (devices.size > 1) {
                twSelect(store = storeOf("", Job())) {
                    changes handledBy { selected ->
                        val t = selected.target as HTMLSelectElement
                        console.log(t.value)
                        val selectedDevice = devices.firstOrNull { it.id == t.value }
                        selectedDevice?.id?.let { id ->
                            if (videoElement != null && videoElement.clientWidth > 0 && videoElement.clientHeight > 0) {
                                devicesStore.selectCameraByIdWithAspectRatio(
                                    id to (videoElement.clientWidth.toDouble() / videoElement.clientHeight.toDouble()),
                                )
                            } else devicesStore.selectCameraByIdWithAspectRatio(id to null)
                        }
                    }
                    devices.forEach { device ->
                        option("", id = device.id) {
                            value(device.id)
                            selected(selectedId.data.map { it == device.id })
                            +device.label
                        }
                    }
                }
                // Switch to next Camera button
                twMediumIconButtonHighlight(icon = FormationIcons.ChangeCamera) {
                    clicks handledBy {
                        devicesStore.nextCamera(Unit)
                    }
                }
            }
        }
    }
}

fun RenderContext.selectComponent(
    id: String? = null,
    scope: (ScopeContext.() -> Unit) = {},
    content: HtmlTag<HTMLSelectElement>.() -> Unit
) {
    select(
        "block appearance-none w-min-content bg-white border border-gray-300 hover:border-gray-400 focus:border-2 focus:border-gray-500 text-gray-700 py-2 px-3 pr-8 rounded leading-tight focus:outline-none transition ease-in-out duration-150",
        id = id,
        scope = scope,
        content = content,
    )
}
