package workspacetools.workspaceoptions

import analytics.AnalyticsCategory
import analytics.AnalyticsService
import auth.ApiUserStore
import auth.CurrentWorkspaceStore
import com.jillesvangurp.geojson.urlEncode
import apiclient.util.createHttpClient
import apiclient.FormationClient
import apiclient.groups.Group
import apiclient.groups.GroupDetails
import apiclient.groups.restUpdateGroupDetails
import apiclient.geoobjects.LatLon
import com.tryformation.localization.Translatable
import dev.fritz2.core.HtmlTag
import dev.fritz2.core.Store
import dev.fritz2.core.disabled
import dev.fritz2.core.storeOf
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import koin.withKoin
import kotlin.time.Duration.Companion.milliseconds
import kotlinx.coroutines.flow.debounce
import localization.translate
import mainmenu.RouterStore
import org.w3c.dom.HTMLDivElement
import overlays.BusyStore
import twcomponents.twCheckButton
import twcomponents.twInputField
import twcomponents.twInputTextField
import twcomponents.twMarkdownContent
import twcomponents.twRevertButton
import twcomponents.twRightAlignedButtonRow

enum class WorkspaceNameState : Translatable {
    NotModified,
    Checking,
    Invalid,
    Unavailable,
    Available,
    ;

    override val prefix: String = "workspacenamestate"
}

fun HtmlTag<HTMLDivElement>.workspaceNameEditor(group: Group) {
    val nameStore = storeOf(group.name)
    val workspaceNameStateStore = storeOf(WorkspaceNameState.NotModified)

    nameStore.data.debounce(200.milliseconds) handledBy {
        checkName(group.name, it.normalizeName(), workspaceNameStateStore)
    }

    h2 {
        translate(WorkspaceOptionsTexts.WorkspaceName)
    }
    twMarkdownContent(WorkspaceOptionsTexts.WorkspaceNameExplanation)

    div("w-full sm:w-[700px]") {
        twInputField(nameStore) {
            twInputTextField {}
            twRightAlignedButtonRow {
                workspaceNameStateStore.data.render { state ->
                    when (state) {
                        WorkspaceNameState.NotModified -> {
                            div("text-formationBlack") {}
                            twRevertButton(nameStore, group.name)
                            twCheckButton {
                                disabled(true)
                            }
                        }

                        WorkspaceNameState.Checking -> {
                            div {
                                +"Checking"
                            }
                            twRevertButton(nameStore, group.name)
                            twCheckButton {
                                disabled(true)
                            }
                        }

                        WorkspaceNameState.Invalid -> {
                            div("text-red-500 whitespace-nowrap") {
                                +"Name invalid"
                            }
                            twRevertButton(nameStore, group.name)
                            twCheckButton {
                                disabled(true)
                            }
                        }

                        WorkspaceNameState.Unavailable -> {
                            div("text-red-500 whitespace-nowrap") {
                                +"Not available"
                            }
                            twRevertButton(nameStore, group.name)
                            twCheckButton {
                                disabled(true)
                            }
                        }

                        WorkspaceNameState.Available -> {
                            nameStore.data.render { newName ->
                                div("text-green-500 whitespace-nowrap") {
                                    +"Available"
                                }
                                twRevertButton(nameStore, group.name)
                                twCheckButton {
                                    clicks handledBy {
                                        console.log("Set Group Name to $newName")
                                        setGroupName(group, newName)
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

private suspend fun setGroupName(group: Group, newName: String) {
    withKoin {
        val formationClient = get<FormationClient>()
        val busyStore = get<BusyStore>()
        val currentWorkspaceStore = get<CurrentWorkspaceStore>()
        val router = get<RouterStore>()
        val apiUserStore = get<ApiUserStore>()
        val analyticsService = get<AnalyticsService>()
        busyStore.withBusy(
            {
                formationClient.restUpdateGroupDetails(
                    group.groupId,
                    GroupDetails(
                        newName,
                        // pick a sensible default of Ahoy
                        group.defaultMapCenter ?: LatLon(52.54114951642207, 13.390883791205976),
                        group.defaultMapZoomLevel ?: 16.0,
                    ),
                )
            },
        ) { modifiedGroup ->
            analyticsService.createEvent(
                AnalyticsCategory.WorkspaceOptions,
            ) {
                recordAction("change-name", target = newName)
            }

            currentWorkspaceStore.update(modifiedGroup)
            // ws has changed so update the router
//            router.validateInternalRoute(WorkspaceToolsScreens.WorkspaceOptions.route)
            apiUserStore.refreshUser(Unit)
        }
    }
}

fun String.normalizeName(): String {
    return trim().lowercase()
        .replace(" ", "-")
        .replace("_", "-")
}

private fun String.isValidSubdomain(): Boolean {
    return matches(Regex("^(?!-).+[a-zA-Z0-9-]{1,63}.*(?<!-)\$"))
}

suspend fun checkName(existingName: String, newName: String, workspaceNameStateStore: Store<WorkspaceNameState>) {
    withKoin {
        val normalized = newName.normalizeName()
        when {
            existingName.normalizeName() == newName.normalizeName() -> {
                workspaceNameStateStore.update(WorkspaceNameState.NotModified)
            }

            normalized.isValidSubdomain() -> {
                workspaceNameStateStore.update(WorkspaceNameState.Checking)
                if (isGroupNameAvailable(normalized)) {
                    workspaceNameStateStore.update(WorkspaceNameState.Available)
                } else {
                    workspaceNameStateStore.update(WorkspaceNameState.Unavailable)
                }
            }

            else -> {
                workspaceNameStateStore.update(WorkspaceNameState.Invalid)
            }
        }
    }
}

suspend fun isGroupNameAvailable(name: String): Boolean {
    val client = createHttpClient()
    return try {
        // don't use FormationClient here to ensure we hit the right server for a name check
        client.head("https://${name}.tryformation.com/groups/${name.urlEncode()}/exists")
        // the name is in use
        false
    } catch (e: ClientRequestException) {
        if (e.response.status.value == 404) {
            true
        } else {
            console.error("unexpected status checking group name availability", e)
            // bias to unavailable
            false
        }
    } catch (e: Throwable) {
        // this can happen because of networking
        false
    }
}
