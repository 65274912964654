package data.objects.views.directediting

import analytics.AnalyticsCategory
import analytics.analyticsEvent
import apiclient.FormationClient
import apiclient.geoobjects.Content
import apiclient.geoobjects.DeleteContent
import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.MoveContentDown
import apiclient.geoobjects.MoveContentUp
import apiclient.geoobjects.NewTask
import apiclient.geoobjects.createTask
import apiclient.util.isNotNullOrEmpty
import auth.CurrentWorkspaceStore
import auth.FeatureFlagStore
import auth.Features
import com.jillesvangurp.geojson.urlEncode
import data.objects.ActiveObjectStore
import data.objects.views.attachments.AttachedGeoObjectsStore
import dev.fritz2.components.modal
import dev.fritz2.core.Handler
import dev.fritz2.core.HtmlTag
import dev.fritz2.core.RenderContext
import dev.fritz2.core.SimpleHandler
import dev.fritz2.core.alt
import dev.fritz2.core.crossOrigin
import dev.fritz2.core.href
import dev.fritz2.core.src
import dev.fritz2.core.storeOf
import dev.fritz2.core.tabIndex
import dev.fritz2.core.target
import koin.koinCtx
import koin.withKoin
import kotlin.random.Random
import kotlin.random.nextULong
import kotlinx.browser.window
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.mapNotNull
import localization.Translation
import mainmenu.Pages
import mainmenu.RouterStore
import model.L
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.asList
import org.w3c.dom.svg.SVGElement
import overlays.BusyStore
import overlays.withBusyApplyContentChange
import poll.ActivePollStore
import poll.cardPoll
import qrcodegeneration.toSvgQrCode
import search.simpleListEntry
import theme.FormationIcons
import theme.FormationUIIcons
import twcomponents.fadeInFadeoutTransition
import twcomponents.toggleClassOnElement
import twcomponents.twColOf
import twcomponents.twColOfNoGap
import twcomponents.twContentBoxOf
import twcomponents.twFlatCopyClipboardButton
import twcomponents.twIconMedium
import twcomponents.twIconSmall
import twcomponents.twLargeIconButton
import twcomponents.twMarkdownContent
import twcomponents.twMediumIconButtonNeutral
import twcomponents.twMediumIconButtonRed
import twcomponents.twPrimaryButton
import twcomponents.twRowOfJustifyBetween
import twcomponents.twRowOfJustifyStart
import twcomponents.twTextLinkButton
import workspacetools.usermanagement.confirm

fun RenderContext.showContent() {
    withKoin {
        val activeObjectStore = get<ActiveObjectStore>()
        val attachedGeoObjectsStore = get<AttachedGeoObjectsStore>()
        val objAttachmentsSubstore = activeObjectStore.map(GeoObjectDetails.L.attachments)
        val featureFlagStore: FeatureFlagStore = get()

        objAttachmentsSubstore.data.render { objAttachments ->
            attachedGeoObjectsStore.getAttachedGeoObjects(objAttachments?.filterIsInstance<Content.GeoObject>()?.map { it.objectId })
            val objectId = activeObjectStore.current.id
            val editable = activeObjectStore.current.editable
            twColOfNoGap {
                objAttachments?.filter { it.id != "description" && it !is Content.Icon }?.forEach { content ->
                    val showContentEditorStore = storeOf(false)
                    val closeEditorHandler = SimpleHandler<Unit> { data, _ -> data handledBy { showContentEditorStore.update(false) } }
                    val id = Random.nextULong().toString()
                    showContentEditorStore.data.render { showContentEditor ->
                        twContentBoxOf {
                            className(if (showContentEditor) "bg-gray-300 hover:bg-gray-300 focus:bg-gray-300" else "bg-formationWhite")
                            div("flex flex-col gap-5 grow mt-2") {
                                tabIndex(0)
                                if (editable) {
                                    // TODO come up with better div structure to handle this feature flag restriction
                                    if (content is Content.Icon && featureFlagStore.current[Features.AllowBitmapMarkerIcons] == true) {
                                        contentEditButtons(id, objectId, content, showContentEditor, showContentEditorStore.update)
                                        if (!showContentEditor) {
                                            toggleClassOnElement(
                                                className = "hidden",
                                                elementId = id,
                                            )
                                        }
                                    } else if (content !is Content.SvgImage) {
                                        contentEditButtons(id, objectId, content, showContentEditor, showContentEditorStore.update)
                                        if (!showContentEditor) {
                                            toggleClassOnElement(
                                                className = "hidden",
                                                elementId = id,
                                            )
                                        }
                                    }
                                }
                                if (showContentEditor) {
                                    div("px-2 pb-2 bg-gray-300") {
                                        when (content) {
                                            is Content.Markdown -> mdEditor(
                                                objectId = objectId,
                                                content = content,
                                                editorCloseHandler = closeEditorHandler,
                                                header = ContentTexts.AddMarkdownSection,
                                            )

                                            is Content.WebLink -> linkEditor(
                                                objectId = objectId,
                                                content = content,
                                                editorCloseHandler = closeEditorHandler,
                                                header = ContentTexts.AddWebLink,
                                            )

                                            is Content.GeoObject -> geoObjectEditor(
                                                objectId = objectId,
                                                content = content,
                                                editorCloseHandler = closeEditorHandler,
                                                header = ContentTexts.AddGeoObject,
                                            )

                                            is Content.GeoReferencedImage -> div { +"Not supported yet" }

                                            is Content.Image -> imageEditor(
                                                objectId = objectId,
                                                content = content,
                                                editorCloseHandler = closeEditorHandler,
                                                header = ContentTexts.AddImage,
                                            )

                                            is Content.Poll -> pollEditor(
                                                objectId = objectId,
                                                content = content,
                                                editorCloseHandler = closeEditorHandler,
                                                header = ContentTexts.AddPoll,
                                            )

                                            is Content.ScanToCreateTask -> taskTemplateEditor(
                                                objectId = objectId,
                                                content = content,
                                                editorCloseHandler = closeEditorHandler,
                                                header = ContentTexts.AddTaskTemplate,
                                            )

                                            else -> {}
                                        }
                                    }
                                } else {
                                    when (content) {
                                        is Content.Markdown -> renderMarkdownSection(content)
                                        is Content.WebLink -> renderWebLink(content)
                                        is Content.GeoObject -> renderGeoObject(content)
                                        is Content.GeoReferencedImage -> div { +"Not supported yet" }
                                        is Content.Image -> renderImage(content)
                                        is Content.Poll -> renderPoll(content)
                                        is Content.ScanToCreateTask -> renderScanToCreateTask(content)
                                        is Content.SvgImage -> renderSVGContent(content) // TODO translate
                                        is Content.Icon -> {
//                                            twFeatureFlagDiv(flag = Features.AllowBitmapMarkerIcons) {
//                                                renderIcon(content)
//                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

private fun HtmlTag<HTMLDivElement>.renderMarkdownSection(content: Content.Markdown) {
    div("px-2 pb-2") {
        content.title?.let {
            h3 {
                +it
            }
        }
        twMarkdownContent(content.text)
    }
}

fun fixOldLinks(link: String): String {

    val regex = """/img-cache/(.*)""".toRegex()
    return link.replace(regex) { matchResult ->
        val base64Part = matchResult.groupValues[1]
        if (base64Part.contains('%')) {
            "/img-cache/$base64Part"
        } else {
            val fixedBase64Part = base64Part.urlEncode()
            "/img-cache/$fixedBase64Part"
        }
    }
}

fun RenderContext.renderWebLink(content: Content.WebLink) {
    div("flex flex-col w-full items-stretch justify-start px-2 pt-2 gap-2") {
        // logo, title
        div("flex flex-row w-full items-center justify-start gap-4") {
            content.openGraphMetadata?.siteIcon?.let { siteIcon ->
                img("h-8 object-contain") {
                    crossOrigin("anonymous")

                    src(fixOldLinks(siteIcon))
                }
            }
            (content.title.takeIf { it.isNotNullOrEmpty() } ?: content.openGraphMetadata?.title)?.let { title ->
                div("text-sm font-bold text-ellipsis overflow-hidden") {
                    +title
                }
            }
        }
        if (content.href.contains("youtube")) {
            youtubePreview(content.href, true, true)
        } else {

            // image
            content.openGraphMetadata?.image?.let { imgSrc ->
                a("flex items-center justify-center w-full") {
                    clicks handledBy {
                        analyticsEvent(AnalyticsCategory.WebLink, content.href, label = content.title)
                        window.open(content.href, "_blank") // Open the link in a new tab
                    }

                    img("w-full object-cover max-h-64") {
                        crossOrigin("anonymous")
                        src(fixOldLinks(imgSrc))
                    }
                }
            }
        }

        // prevText
        content.openGraphMetadata?.description?.let { description ->
            p("text-xs") {
                +description
            }
        }

        // linkIcon, clickable link text, copy button
        twRowOfJustifyBetween {
            className("pl-2 -mx-2")
            twIconMedium(FormationUIIcons.Link)
            twTextLinkButton {
//                target("_blank")
//                href(content.href)
                clicks handledBy {
                    analyticsEvent(AnalyticsCategory.WebLink, content.href, label = content.title)
                    window.open(content.href, "_blank") // Open the link in a new tab
                }

                +content.href
            }
            twFlatCopyClipboardButton(content.href)
        }
    }
}

fun extractVideoId(url: String): String? {
    val regex = Regex("(?<=v=|youtu\\.be/|embed/)[^#&?]*")
    val matchResult = regex.find(url)
    return matchResult?.value
}

fun buildIframeSrc(videoId: String, autoplay: Boolean, loop: Boolean): String {
    return buildString {
        append("https://www.youtube-nocookie.com/embed/$videoId?")
        val params = mutableListOf<String>()
        params += "enablejsapi=1"
        params += "origin=${window.location.origin.urlEncode()}"
        if (autoplay) {
            params += "autoplay=1"
            params += "mute=1" // Mute the video to allow autoplay in browsers
        }
        if (loop) {
            params += "loop=1"
            params += "playlist=$videoId"
        }
        append(params.joinToString("&"))
    }
}

fun RenderContext.youtubePreview(
    videoUrl: String,
    autoplay: Boolean = false,
    loop: Boolean = false
) {
    val videoId = extractVideoId(videoUrl)

    if (videoId != null) {
        val isPlaying = storeOf(autoplay)

        div("relative w-full") {
            // Add a wrapper div to maintain aspect ratio
            div("relative overflow-hidden w-full") {
                // Use a utility class to set padding-bottom to 56.25% (16:9 aspect ratio)
                div("w-full h-0 pb-[56.25%]") {
                    isPlaying.data.render { playing ->
                        if (playing) {
                            val embedUrl = buildIframeSrc(videoId, autoplay, loop)
                            console.log(embedUrl)
                            // Show iframe
                            iframe("absolute top-0 left-0 w-full h-full") {
                                src(embedUrl)
                                attr("allow", "autoplay; encrypted-media")
                                attr("allowfullscreen", "true")
                            }
                        } else {
                            // Show thumbnail image with play button
                            div("absolute top-0 left-0 w-full h-full cursor-pointer") {
                                clicks.map { true } handledBy isPlaying.update

                                img("w-full h-full object-cover") {
                                    src("https://img.youtube.com/vi/$videoId/0.jpg")
                                }

                                // Play button overlay
                                div("absolute inset-0 flex items-center justify-center") {
                                    div("bg-black bg-opacity-50 text-white rounded-full p-4") {
                                        +"Play"
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

fun RenderContext.renderImage(content: Content.Image) {
    twColOf {
        className("-mb-2")
        twRowOfJustifyBetween {
            className("px-2")
            content.title?.let { title ->
                span("font-bold") {
                    +title
                }
            }
            // Open in new tab button
            a("flex gap-2 text-sm items-center justify-center rounded-xl") {
                +"Open in tab" // TODO translate
                twIconSmall(icon = FormationUIIcons.ExternalLink)
                target("_blank")
                href(content.href)
            }
        }
        img("px-1") {
            src(content.href)
            alt(content.title ?: "image ${content.width}x${content.height}")
        }
    }
}

fun RenderContext.renderSVGContent(content: Content.SvgImage) {
    div("flex w-full min-h-0 items-center justify-start -mb-2 px-2") {
        svg { }.domNode.apply {
            outerHTML = content.svgContent
        }
    }
}

fun RenderContext.renderGeoObject(content: Content.GeoObject) {
    val attachedGeoObjectsStore: AttachedGeoObjectsStore by koinCtx.inject()

    val icon = when (content.rel) {
        "prev", "previous" -> {
            FormationUIIcons.ArrowLeft
        }

        "related", "about" -> {
            FormationIcons.InformationAlt
        }

        "next" -> {
            FormationUIIcons.ArrowRight
        }

        else -> {
            null // FormationUIIcons.Link
        }
    }

    attachedGeoObjectsStore.data.render { attachedGeoObjects ->
        attachedGeoObjects[content.objectId]?.let { obj ->
            twColOf {
                className("px-2 cursor-pointer hover:text-highlight")
                twRowOfJustifyStart {
                    icon?.let { twIconMedium(it) }
                    content.title?.let { title ->
                        p("text-sm") {
                            +title
                        }
                    }
                }
                simpleListEntry(geoObject = obj)

                clicks handledBy {
                    withKoin {
                        val routerStore = get<RouterStore>()
                        analyticsEvent(AnalyticsCategory.OpenMarker, content.rel?:"object-link", target = content.objectId, label = content.title)
                        routerStore.addOrReplaceRoute(
                            Pages.Map.route + mapOf("id" to content.objectId),
                        )
                    }
                }
            }
        }
    }
}

fun RenderContext.renderPoll(content: Content.Poll) {
    val pollStore = ActivePollStore(content)
    cardPoll(showBack = false, expandable = false, pollStore)

    // Show action qr code
    showActionQRCodeButton(content.actionId, content.title)
}

fun RenderContext.renderScanToCreateTask(content: Content.ScanToCreateTask) {
    twColOf {
        className("px-2 pb-2")
        p("font-bold italic") {
            +(content.title.takeIf { it.isNotNullOrEmpty() } ?: "Task")
        }

        val template = content.taskTemplate

        template.title?.let { title ->
            h3("flex flex-row gap-2 place-items-center") {
                twIconSmall(FormationIcons.Task)
                +title
            }
        }
        template.textAttachment?.let { description ->
            twMarkdownContent(description)
        }

        twPrimaryButton(icon = FormationIcons.Create) {
            className("self-end")
            +"Create Task on Map" // TODO translate
            clicks handledBy {
                withKoin {
                    val currentWorkspaceStore = get<CurrentWorkspaceStore>()
                    val activeObjectStore = get<ActiveObjectStore>()
                    val client = get<FormationClient>()
                    val busyStore = get<BusyStore>()
                    val routerStore = get<RouterStore>()
                    busyStore.withBusy(
                        {
                            client.createTask(
                                group = currentWorkspaceStore.current?.groupId ?: error("should not get here"),
                                NewTask(
                                    title = template.title ?: content.title ?: "Task",
                                    latLon = template.latLon ?: activeObjectStore.current.latLon,
                                    description = template.textAttachment,
                                    assignedTo = template.assignedTo,
                                    fieldValueTags = template.fieldValueTags,
                                    iconCategory = template.iconCategory,
                                    shape = template.shape,
                                    color = template.color,
                                ),
                            )
                        },
                        processResult = { newObject ->
                            analyticsEvent(
                                AnalyticsCategory.TaskTemplate,
                                "retract",
                                target = activeObjectStore.current.id + " | " + template.title,
                                label = activeObjectStore.current.title + " | " + template.title,
                                location = activeObjectStore.current.latLon,
                            )

                            routerStore.addOrReplaceRoute(Pages.Map.route + mapOf("id" to newObject.id))
                        },
                    )
                }
            }
        }
        // Show action qr code
        showActionQRCodeButton(content.actionId, content.title)
    }
}


fun RenderContext.contentEditButtons(
    id: String,
    objId: String,
    content: Content,
    showContentEditor: Boolean = false,
    showContentEditorStoreHandler: Handler<Boolean>
) {
    val translation: Translation by koinCtx.inject()

    div("static flex flex-row h-0 w-full pr-2 items-center justify-end") {
        div("relative flex flex-row w-max gap-5 bg-gray-300 py-2 px-4 rounded-xl shadow-xl", id = id) {
            if (showContentEditor) {
                if (domNode.classList.contains("hidden")) {
                    domNode.classList.remove("hidden")
                }
            } else domNode.classList.add("hidden")

            fadeInFadeoutTransition()
//            showContentEditorStore.data.render { showContentEditor ->
            if (showContentEditor) {
                twMediumIconButtonNeutral(FormationUIIcons.Close) {
//                        clicks handledBy { showContentEditorStore.update(!showContentEditorStore.current) }
                    clicks handledBy { showContentEditorStoreHandler(!showContentEditor) }
                }
            } else {
                twMediumIconButtonNeutral(FormationIcons.Edit) {
//                        clicks handledBy { showContentEditorStore.update(!showContentEditorStore.current) }
                    clicks handledBy { showContentEditorStoreHandler(!showContentEditor) }
                }
            }
//            }
            twMediumIconButtonNeutral(FormationUIIcons.ArrowUp) {
                clicks handledBy {
                    withBusyApplyContentChange(objId, MoveContentUp(content.id))
                }
            }
            twMediumIconButtonNeutral(FormationUIIcons.ArrowDown) {
                clicks handledBy {
                    withBusyApplyContentChange(objId, MoveContentDown(content.id))
                }
            }
            twMediumIconButtonRed(FormationIcons.DeleteAlt) {
                clicks handledBy confirm(
                    translation[ContentTexts.ARE_YOU_SURE_TO_DELETE_CONTENT],
                    okHandlers = listOf(
                        SimpleHandler { data, _ ->
                            data handledBy {
                                withBusyApplyContentChange(objId, DeleteContent(content.id))
                            }
                        },
                    ),
                )
            }
        }
    }
}

fun RenderContext.showActionQRCodeButton(actionId: String? = null, title: String? = null) {
    // Show action qr code
    actionId?.let { action ->
        flowOf(
            action,
        ).mapNotNull { it } handledBy { id ->
            if (id.isNotBlank()) {
                val svgContent = toSvgQrCode("https://app.tryformation.com/#id=${id.urlEncode()}")
                twTextLinkButton {
                    a("flex gap-2 text-sm items-center justify-center cursor-pointer py-2") {
                        +"Show action qr code" // TODO translate
                        twIconSmall(icon = FormationIcons.QRCode)

                        clicks handledBy modal(
                            {
                                flex {
                                    shrink { "1" }
                                }
                                width { "500px" }
                                maxWidth { full }
                                height { maxContent }
                                maxHeight { full }
                                radius { "24px" }
                            },
                        ) {
                            placement { center }
                            hasCloseButton(false)
                            content { closeHandler ->
                                div("flex flex-col w-full h-full") {
                                    div("flex w-full h-max items-start justify-between gap-2") {
                                        p("font-bold") { +"Action QR Code for: \"${title ?: ""}\"" } // TODO translate
                                        twLargeIconButton(icon = FormationUIIcons.Close) {
                                            clicks handledBy closeHandler
                                        }
                                    }

                                    div("flex flex-col w-full h-full") {
                                        div("flex max-h-max w-full object-scale-down") {
                                            domNode.innerHTML = svgContent
                                        }.also {
                                            this.domNode.children.asList().firstOrNull { it is SVGElement }.also { svg ->
                                                svg?.setAttribute("height", "100%")
                                                svg?.setAttribute("width", "100%")
                                            }
                                        }
                                    }
                                    p("text-xs font-mono text-center w-full h-full") { +id }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
