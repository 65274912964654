package search.global

import apiclient.customfields.FieldValue
import koin.koinCtx
import mainmenu.Pages
import mainmenu.RouterStore
import search.searchFilterStores.ActiveFieldValuesStore

class ActiveSearchFieldValuesStore: ActiveFieldValuesStore() {

    private val routerStore by koinCtx.inject<RouterStore>()

    val addAndNavToSearch = handle<FieldValue?> { current, fieldValue ->
        add(fieldValue)
        routerStore.validateInternalRoute(Pages.Search.route)
        current
    }
}
