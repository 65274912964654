package utils

import dev.fritz2.core.RootStore
import kotlinx.coroutines.Job

open class ToggleStore(initState: Boolean? = null) : RootStore<Boolean>(initState ?: false, Job()) {
    val toggle = handle {
        !current
    }
}
