package data.objects

import dev.fritz2.core.RootStore
import kotlinx.coroutines.Job

class DurationPickerStore : RootStore<Int>(
    initialData = 30,
    job = Job(),
) {
    val reset = handle { current ->
        update(30)
        current
    }
}
