package camera.cameraWrapper

import dev.fritz2.core.RootStore
import dev.fritz2.core.SimpleHandler
import koin.koinCtx
import kotlinx.coroutines.Job
import org.koin.dsl.module
import utils.obj
import web.media.devices.MediaDeviceKind.Companion.videoinput
import web.media.streams.MediaStream

val cameraModule = module {
    single { CameraDevicesStore() }
    single { SelectedCameraDeviceStore() }
}

enum class CameraDirection {
    User, Environment, Unknown
}

data class CameraMeta(val id: String, val label: String, val direction: CameraDirection, val mediaStream: MediaStream?) {
    companion object {
        val empty = CameraMeta("", "", CameraDirection.Unknown, null)
    }
}

class SelectedCameraDeviceStore : RootStore<CameraMeta?>(null, Job()) {

    fun stopAllMediaTracks(camera: CameraMeta? = current) {
        camera?.mediaStream?.getTracks()?.forEach { track ->
            console.log("Stop media track of current selected:", track.label)
            track.stop()
        }
    }

    fun stopTracksAndReset() {
        console.log("Stop camera tracks and reset")
        stopAllMediaTracks()
        update(null)
    }
}

class CameraDevicesStore : RootStore<List<CameraMeta>>(listOf(), Job()) {

    private val selectedCameraDeviceStore: SelectedCameraDeviceStore by koinCtx.inject()

    @Suppress("UnsafeCastFromDynamic")
    private suspend fun requestCameraById(id: String, videoAspectRatio: Double? = null, facing: String = "environment"): MediaStream? {
        console.log("Requested Camera:", id, "with AspectRatio:", videoAspectRatio)
        val idealWidth = videoAspectRatio?.let { ratio ->
            if (ratio >= 1) 1920 else 1080
        } ?: 1920
        val idealHeight = videoAspectRatio?.let { ratio ->
            if (ratio >= 1) 1080 else 1920
        } ?: 1080
        val constraints = obj {
            deviceId = id
            width = obj {
                min = 640
                ideal = idealWidth
                max = 2880
            }
            height = obj {
                min = 480
                ideal = idealHeight
                max = 1800
            }
            facingMode = facing
        }
        return try {
            val cam = web.navigator.navigator.mediaDevices.getUserMedia(
                obj {
                    video = constraints
                    audio = false
                },
            )
            console.log("Camera MediaStream found:", cam.id)
            cam
        } catch (e: Exception) {
            console.log("Camera not found", e.message)
            null
        }
    }

    val selectCameraByIdWithAspectRatio = SimpleHandler<Pair<String, Double?>> { data, _ ->
        data handledBy { (selectedCameraId, videoAspectRatio) ->
            val requestedCamera = requestCameraById(selectedCameraId, videoAspectRatio)?.let { cameraMediaStream ->
                current.firstOrNull { it.id == selectedCameraId }?.copy(
                    mediaStream = cameraMediaStream,
                )
                    ?: CameraMeta(
                        id = selectedCameraId,
                        label = cameraMediaStream.getVideoTracks()[0].label,
                        direction = CameraDirection.Unknown,
                        mediaStream = cameraMediaStream,
                    )
            }
            requestedCamera?.let { camWithStream ->
                selectedCameraDeviceStore.stopAllMediaTracks()
                selectedCameraDeviceStore.update(requestedCamera)
                update(
                    current.map { cameraMeta ->
                        if (cameraMeta.id == camWithStream.id) {
                            cameraMeta.copy(mediaStream = camWithStream.mediaStream)
                        } else cameraMeta
                    },
                )
            }
        }
    }

    val nextCamera = SimpleHandler<Unit> { data, _ ->
        data handledBy {
            current.map { device -> device.id }.firstOrNull { id -> id != selectedCameraDeviceStore.current?.id }?.let { id ->
                selectCameraByIdWithAspectRatio(id to null)
            }
        }
    }

    @Suppress("UnsafeCastFromDynamic")
    val fetchCameras = SimpleHandler<Double?> { data, _ ->
        data handledBy { videoAspectRatio ->
            console.log("Get camera permissions and query available devices.")
            try {
                // Trigger media query and stop it immediately, so that enumerateDevices will work
                val triggerCam = web.navigator.navigator.mediaDevices.getUserMedia(
                    obj {
                        video = true
                        audio = false
                    },
                )

                // enumerate all available devices
                val videoDevices = web.navigator.navigator.mediaDevices.enumerateDevices().filter {
                    it.kind == videoinput
                }
                console.log("Found Devices:", videoDevices.map { it.label }.toString())

                // close tracks of triggerCam again
                triggerCam.getTracks().forEach { track ->
                    track.stop()
                }

                val deviceList = videoDevices.map {
                    CameraMeta(
                        id = it.deviceId,
                        label = it.label,
                        direction = CameraDirection.Unknown,
                        mediaStream = null,
                    )
                }
                update(deviceList)
                selectedCameraDeviceStore.update(
                    deviceList.firstOrNull()?.let {
                        it.copy(mediaStream = requestCameraById(it.id, videoAspectRatio))
                    },
                )
            } catch (e: Exception) {
                console.error("error listing devices", e)
            }
        }
    }

    val reset = SimpleHandler<Unit> { data, _ ->
        data handledBy {
            current.forEach { camera ->
                camera.mediaStream?.getTracks()?.forEach { track ->
                    console.log("Stop media track of:", track.label)
                    track.stop()
                }
            }
            update(emptyList())
        }
    }
}
