package websocket

import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.LatLon
import apiclient.geoobjects.ObjectTags
import apiclient.geoobjects.ObjectType
import apiclient.markers.Marker
import apiclient.search.ObjectSearchResult
import apiclient.search.ObjectSearchResults
import apiclient.tags.getUniqueTag
import auth.ApiUserStore
import data.users.UserListStore
import dev.fritz2.core.RootStore
import koin.koinCtx
import kotlinx.coroutines.Job
import kotlinx.datetime.Clock
import location.LocationUploadStore
import map.MapLayersStore
import model.LayerType
import model.LiveData

class UserAndObjectResultsStore : RootStore<LiveData>(
    initialData = LiveData(),
    job = Job(),
) {
    private val markerClientStore: MarkerClientStore by koinCtx.inject()
    private val mapLayersStore: MapLayersStore by koinCtx.inject()
    private val userListStore: UserListStore by koinCtx.inject()

    private suspend fun updateUsersAndObjects(
        searchResults: Map<String, GeoObjectDetails>? = null,
        markers: Map<String, Marker>? = null
    ): Map<String, GeoObjectDetails> {
//        if(!markers.isNullOrEmpty()) {
//            console.log("* got markers from markerClient:", markers.map { marker ->
//                if(marker.value is Marker.UserMarker) {
//                        (marker.value as Marker.UserMarker).userId
//                } else (marker.value as Marker.ObjectMarker).objectId + ", " + (marker.value as Marker.ObjectMarker).enrichedObjectId
//            }.toString()
//            )
//        }
//        if(!searchResults.isNullOrEmpty()) console.log("* got active.objects from search:", searchResults.map { it.value.hit.title }.toString())

        val userMarkers = (markers ?: current.websocketData).filterValues { it is Marker.UserMarker }
        val fakeUserMarkersMap =
            userMarkers.mapNotNull { (key, value) -> value.toGeoObjectDetails()?.let { key to it } }
                .toMap().ifEmpty { mapOf() }
//        val userSearchResults = (searchResults?: if(current.searchData.isNotEmpty()) current.renderData else emptyMap()).filter { it.value.hit.objectType == ObjectType.UserMarker }
//        val usersMap = fakeUserMarkersMap + if(userSearchResults.isNotEmpty()) {
//            userSearchResults.entries.associate { (userId, user) ->
//                userMarkers[userId]?.let { marker ->
//                    userId to user.copy(
//                        hit = user.hit.copy(
//                            latLon = marker.latLon,
//                            tags = user.hit.tags.setUniqueTag(MarkerTags.LocationLastUpdatedAt, Clock.System.now().toString())
//                        )
//                    )
//                }?: (userId to user)
//            }.toMap().filter { (_, user) ->
//                user.hit.tags.getUniqueTag(MarkerTags.LocationUnset) != "true"
//                        && user.hit.tags.getUniqueTag(MarkerTags.LocationLastUpdatedAt)?.parseInstant()?.let { timeStamp ->
////                    console.log("time difference", user.hit.owner?.name, (Clock.System.now() - timeStamp).toString())
//                    (Clock.System.now() - timeStamp) < 10.minutes
//                } ?: true
//            }
//        } else emptyMap()

        val usersMap = fakeUserMarkersMap

        val objectMarkers = (markers ?: current.websocketData).filterValues { it is Marker.ObjectMarker }
        val objectSearchResults =
            (searchResults ?: if (current.searchData.isNotEmpty()) current.renderData else emptyMap())
                .filter { it.value.objectType == ObjectType.ObjectMarker }
//        val fetchedMarkers =
//            objectMarkers.filter { (key, _) -> !objectSearchResults.containsKey(key) }.mapNotNull { (key, _) ->
//                current.renderData[key]?.let { obj ->
//                    console.log("Fetched from render data", obj.hit.title)
//                    key to obj
//                } ?: (workspacesStore.current.firstOrNull()?.groupId?.let {
//                    val byExt = formationClient.getObjectByExternalId(groupId = it, externalId = key).data
//                    byExt?.let { console.log("Fetched by external id", byExt.title) }
//                    byExt
//                } ?: run {
//                    val byId = formationClient.restGetObjectById(id = key).getOrNull()
//                    byId?.let { console.log("Fetched by id", byId.title) }
//                    byId
//                })?.toSearchResult()?.let { obj -> key to obj }
//            }.toMap()
        val objectResults = objectSearchResults // + fetchedMarkers
        val objectsMap = if (objectResults.isNotEmpty()) {
            objectResults.entries.associate { (objId, obj) ->
                objectMarkers[objId]?.let { objMarker ->
                    val updatedObject = obj.copy(
                        latLon = if (objMarker.latLon != LatLon(0.0, 0.0)) objMarker.latLon else obj.latLon,
//                        tags = obj.hit.tags.tagMap().plus(objMarker.tags?.tagMap().orEmpty()).toTagList()
//                            .setUniqueTag(
//                                MarkerTags.LocationLastUpdatedAt,
//                                Clock.System.now().toString()
//                            )
                    )
//                    if (updatedObject.id == activeObjectStore.current.id) activeObjectTags.update(updatedObject.tags)
                    objId to updatedObject
                } ?: (objId to obj)
            }
        } else emptyMap()

        val users = usersMap.values.toList()
        val objects = objectsMap.values.toList()
//        console.log("* updated users:", users.map { it.hit.title}.toString())
//        console.log("* updated objects:", objects.map { it.hit.title}.toString())
        mapLayersStore.setResults(
            mapOf(
                LayerType.MarkerClientUsers to if (users.isNotEmpty()) {
                    ObjectSearchResults(
                        from = 0,
                        pageSize = 0,
                        total = users.size,
                        time = 0,
                        hits = users.map { ObjectSearchResult(hit = it) },
                    )
                } else null,
                LayerType.MarkerClientObjects to if (objects.isNotEmpty()) {
                    ObjectSearchResults(
                        from = 0,
                        pageSize = 0,
                        total = objects.size,
                        time = 0,
                        hits = objects.map { ObjectSearchResult(hit = it) },
                    )
                } else null,
            ),
        )
        return usersMap.plus(objectsMap)
    }

    val updateStoreBySearchResults = handle<Map<String, GeoObjectDetails>> { current, newSearchResults ->
        current.copy(
            renderData = updateUsersAndObjects(searchResults = newSearchResults),
            searchData = newSearchResults,
        )
    }

    private val updateStoreByMarkerClient = handle<Map<String, Marker>> { current, newMarkers ->
        current.copy(
            renderData = updateUsersAndObjects(markers = newMarkers),
            websocketData = newMarkers,
        )
    }

    private fun Marker.toGeoObjectDetails(): GeoObjectDetails? {
        val obj = when (val marker = this) {
            is Marker.UserMarker -> {
                userListStore.getPublicUserProfile(marker.userId)?.let { user ->
                    GeoObjectDetails(
                        id = marker.enrichedObjectId,
                        ownerId = marker.userId,
                        objectType = ObjectType.UserMarker,
                        createdAt = Clock.System.now().toString(),
                        updatedAt = Clock.System.now().toString(),
                        latLon = marker.latLon,
                        title = "${user.firstName} ${user.lastName}",
                        tags = marker.tags ?: emptyList(),
                    )
                }
            }

            is Marker.ObjectMarker -> {
                GeoObjectDetails(
                    id = marker.id,
                    ownerId = marker.id,
                    objectType = ObjectType.ObjectMarker,
                    createdAt = Clock.System.now().toString(),
                    updatedAt = Clock.System.now().toString(),
                    latLon = marker.latLon,
                    title = marker.tags?.getUniqueTag(ObjectTags.ExternalId) ?: marker.id,
                    tags = marker.tags ?: emptyList(),
                )
            }
        }

        return obj
    }

    fun isUserSharing(userId: String): Boolean {
        val apiUserStore: ApiUserStore by koinCtx.inject()
        val locationUploadStore: LocationUploadStore by koinCtx.inject()

        return if (userId == apiUserStore.current.userId) {
            locationUploadStore.current.isActive
        } else current.renderData[userId] != null
    }

    init {
        markerClientStore.data handledBy updateStoreByMarkerClient
    }
}
