package workspacetools.workspaceoptions

import auth.CurrentWorkspaceStore
import com.tryformation.localization.Translatable
import dev.fritz2.core.RenderContext
import koin.withKoin
import kotlinx.coroutines.flow.filterNotNull

enum class WorkspaceOptionsTexts : Translatable {
    WorkspaceName,
    WorkspaceNameExplanation,
    WorkspaceDefaultMapLocation,
    WorkspaceDefaultMapLocationExplanation,
    ;

    override val prefix = "workspace-options-texts"
}

fun RenderContext.workspaceOptions() {
    withKoin {
        val currentWorkspaceStore = get<CurrentWorkspaceStore>()
        currentWorkspaceStore.data.filterNotNull().render { group ->
            div {
                workspaceNameEditor(group)
                defaultMapPositionEditor(group)
                slackHandleEditor(group)
                featureFlagsEditor(group)
            }
        }
    }
}



