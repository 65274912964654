package data.objects.views.directediting

import apiclient.geoobjects.Content
import apiclient.geoobjects.UpsertTaskTemplate
import camera.cameraWrapper.cameraModal
import camera.nimiq.qrScanCamera
import com.tryformation.localization.Translatable
import dev.fritz2.core.RenderContext
import dev.fritz2.core.SimpleHandler
import dev.fritz2.core.disabled
import dev.fritz2.core.placeholder
import dev.fritz2.core.storeOf
import kotlin.random.Random
import kotlin.random.nextULong
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import localization.getTranslationFlow
import model.ScanPurpose
import overlays.withBusyApplyContentChange
import theme.FormationUIIcons
import twcomponents.twColOf
import twcomponents.twFullWidthTextArea
import twcomponents.twIconLarge
import twcomponents.twInputField
import twcomponents.twInputTextField
import twcomponents.twMediumIconButtonNeutral
import twcomponents.twMultiLineTextareaTextfield
import twcomponents.twRowOfJustifyBetween
import twcomponents.twSecondaryButton
import webcomponents.inputLabelWrapper

enum class TaskTemplateEditor : Translatable {
    TemplateHeader,
    TemplateHeaderPlaceholder,
    TaskTitle,
    TaskTitlePlaceholder,
    TaskDescription,
    TaskDescriptionPlaceholder,
    ActionCode,
    ;

    override val prefix = "task-template-editor"
}

fun RenderContext.taskTemplateEditor(
    objectId: String,
    content: Content.ScanToCreateTask,
    editorCloseHandler: SimpleHandler<Unit>? = null,
    header: Translatable? = null,
    isCreateNew: Boolean = false,
) {
    val titleStore = storeOf(content.title ?: "")
    val taskTitleStore = storeOf(content.taskTemplate.title ?: "")
    val taskDescriptionStore = storeOf(content.taskTemplate.textAttachment ?: "")
    val actionIdStore = storeOf(content.actionId)

    twColOf {
        contentCreateHeader(if (isCreateNew) editorCloseHandler else null, header) {
            twMediumIconButtonNeutral(icon = FormationUIIcons.Save) {
                taskTitleStore.data.render {
                    disabled(it.isBlank())
                }
                clicks handledBy {
                    withBusyApplyContentChange(
                        objectId,
                        UpsertTaskTemplate(
                            id = content.id.takeIf { it.isNotBlank() } ?: Random.nextULong().toString(),
                            title = titleStore.current,
                            taskTemplate = content.taskTemplate.copy(
                                title = taskTitleStore.current,
                                textAttachment = taskDescriptionStore.current,
                            ),
                            actionId = actionIdStore.current,
                        ),
                    )
                    editorCloseHandler?.let { closeEditor ->
                        closeEditor(Unit)
                    }
                }
            }
        }
        inputLabelWrapper(
            title = TaskTemplateEditor.TemplateHeader.getTranslationFlow(),
            visibilityFlow = flowOf(true),
        ) {
            twInputField(titleStore) {
                twInputTextField {
                    placeholder(TaskTemplateEditor.TemplateHeaderPlaceholder.getTranslationFlow())
                }
            }
        }
        inputLabelWrapper(
            title = TaskTemplateEditor.TaskTitle.getTranslationFlow(),
            visibilityFlow = flowOf(true),
        ) {
            twInputField(taskTitleStore) {
                twInputTextField {
                    placeholder(TaskTemplateEditor.TaskTitlePlaceholder.getTranslationFlow())
                }
            }
        }
        inputLabelWrapper(
            title = TaskTemplateEditor.TaskDescription.getTranslationFlow(),
            visibilityFlow = flowOf(true),
        ) {
            twFullWidthTextArea(taskDescriptionStore) {
                twMultiLineTextareaTextfield {
                    placeholder(TaskTemplateEditor.TaskDescriptionPlaceholder.getTranslationFlow())
                }
            }
        }

        // Action Code
        // QR Scan Camera Modal
        val toggleCamera = storeOf(false, Job())
        cameraModal(toggleCamera) { close, opened, video, _ ->
            qrScanCamera(
                closeModal = close,
                modalOpened = opened,
                videoElement = video,
                scanPurpose = ScanPurpose.ReadCodeAndStore,
                codeStore = actionIdStore,
            )
        }
        // Action Code Input
        twRowOfJustifyBetween {
            inputLabelWrapper(
                title = TaskTemplateEditor.ActionCode.getTranslationFlow(),
                visibilityFlow = actionIdStore.data.map { it.isNotBlank() },
            ) {
                twInputField(actionIdStore) {
                    twInputTextField {
                        placeholder(TaskTemplateEditor.ActionCode.getTranslationFlow())
                    }
                }
            }
            // Action Code Scan Button
            twSecondaryButton {
                className("self-end")
                twIconLarge(FormationUIIcons.ScanQR)
                clicks handledBy {
                    toggleCamera.update(true)
                }
            }
        }
    }
}
