package notifications

import dev.fritz2.core.RootStore
import kotlinx.coroutines.Job

enum class NotificationFilter { NOTIFICATIONS, MESSAGES }

class NotificationFilterStore : RootStore<NotificationFilter>(
    initialData = NotificationFilter.NOTIFICATIONS,
    job = Job(),
) {

    val selectFilter = handle<NotificationFilter> { _, filter ->
        update(filter)
        filter
    }

    // TODO NOTIFICATION-CENTER update searchClients or other source of data to current filter choice
    private val updateSearchClients = handle<NotificationFilter> { current, _ ->
//        val clientList = emptyList<MapLayer>().toMutableList()
//        clientList += when(filter){
//            NotificationFilter.NOTIFICATIONS -> DefaultLayers.MyNotifications
//            //TODO add and enable layer for messages here
//            NotificationFilter.MESSAGES -> DefaultLayers.MyNotifications
//        }
        current
    }

    val reset = handle {
        update(NotificationFilter.NOTIFICATIONS)
        NotificationFilter.NOTIFICATIONS
    }

    init {
        data handledBy updateSearchClients
    }

}
