package dev.fritz2.components

import dev.fritz2.components.compat.div
import dev.fritz2.components.compat.input
import dev.fritz2.components.compat.label
import dev.fritz2.components.foundations.Component
import dev.fritz2.components.foundations.ComponentProperty
import dev.fritz2.components.foundations.DynamicComponentProperty
import dev.fritz2.components.foundations.ElementMixin
import dev.fritz2.components.foundations.ElementProperties
import dev.fritz2.components.foundations.EventMixin
import dev.fritz2.components.foundations.EventProperties
import dev.fritz2.components.foundations.InputFormMixin
import dev.fritz2.components.foundations.InputFormProperties
import dev.fritz2.core.HtmlTag
import dev.fritz2.core.RenderContext
import dev.fritz2.core.Store
import dev.fritz2.core.checked
import dev.fritz2.core.disabled
import dev.fritz2.core.`for`
import dev.fritz2.core.readOnly
import dev.fritz2.core.states
import dev.fritz2.core.type
import dev.fritz2.styling.StyleClass
import dev.fritz2.styling.params.BasicParams
import dev.fritz2.styling.params.BoxParams
import dev.fritz2.styling.params.Style
import dev.fritz2.styling.staticStyle
import dev.fritz2.styling.theme.FormSizesStyles
import dev.fritz2.styling.theme.IconDefinition
import dev.fritz2.styling.theme.Icons
import dev.fritz2.styling.theme.Theme
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flowOf
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLLabelElement

/**
 * This component generates a *single* checkbox.
 * So this component supports the use case to select or deselect an option. If an application has a strong focus on
 * mobile, consider a [switch] instead!
 *
 * Example usage
 * ```
 * val cheeseStore = storeOf(false)
 * checkbox(value = cheeseStore) {
 *      label("with extra cheese") // set the label
 * }
 * ```
 *
 * @see CheckboxComponent
 *
 * @param styling a lambda expression for declaring the styling as fritz2's styling DSL
 * @param value a boolean store to handle the state and its changes automatically
 * @param baseClass optional CSS class that should be applied to the element
 * @param id the ID of the element
 * @param prefix the prefix for the generated CSS class resulting in the form ``$prefix-$hash``
 * @param build a lambda expression for setting up the component itself. Details in [CheckboxComponent]
 */
fun RenderContext.checkbox(
    styling: BasicParams.() -> Unit = {},
    value: Store<Boolean>? = null,
    baseClass: StyleClass = StyleClass.None,
    id: String? = value?.id,
    prefix: String = "checkboxComponent",
    build: CheckboxComponent.() -> Unit = {}
): HtmlTag<HTMLLabelElement> = CheckboxComponent(value).apply(build).render(this, styling, baseClass, id, prefix)

/**
 * This class combines the _configuration_ and the core styling of a checkbox.
 *
 * This class offers the following _configuration_ features:
 *  - the label(mapping) static or dynamic via a [Flow<String>] or customized content see the examples below
 *  - some predefined styling variants (size)
 *  - the style of the checkbox
 *  - the style checked state
 *  - the style of the label
 *  - the checked icon (use our icon library of our theme)
 *  - link an external boolean flow to set the checked state of the box
 *  - link an external boolean flow to set the disabled state of the box
 *  - link events of the checkbox like `changes` with external handlers
 *
 *  This can be done within a functional expression that is the last parameter of the factory function, called
 *  `build`. It offers an initialized instance of this [CheckboxComponent] class as receiver, so every mutating
 *  method can be called for configuring the desired state for rendering the checkbox.
 *
 * Example usage
 * ```
 * // simple, store based:
 * val cheeseStore = storeOf(false)
 * checkbox(value = cheeseStore) {
 *      label("with extra cheese") // set the label
 * }
 *
 * // with manual event handling and further options
 * val cheeseStore = storeOf(false)
 * checkbox {
 *      label("with extra cheese") // set the label
 *      size { normal } // choose a predefined size
 *      checked(cheeseStore.data) // link a [Flow<Boolean>] in order to visualize the checked state
 *      events { // open inner context with all DOM-element events
 *          changes.states() handledBy cheeseStore.update // connect the changes event with the state store
 *      }
 *      element {
 *          // exposes the underlying HTML input element for direct access. Use with caution!
 *      }
 * }
 * ```
 */
open class CheckboxComponent(protected val value: Store<Boolean>?) :
    Component<HtmlTag<HTMLLabelElement>>,
    EventProperties<HTMLInputElement> by EventMixin(),
    ElementProperties<HtmlTag<HTMLInputElement>> by ElementMixin(),
    InputFormProperties by InputFormMixin() {

    companion object {
        val checkboxInputStaticCss = staticStyle(
            "checkbox",
            """
            position: absolute;
            border: 0px;
            clip: rect(0px, 0px, 0px, 0px);
            height: 0px;
            width: 0px;
            overflow: hidden;
            white-space: nowrap;
            outline: none;
            &:focus{
                outline: none;
            }
            """,
        )
    }

    val size = ComponentProperty<FormSizesStyles.() -> Style<BasicParams>> { Theme().checkbox.sizes.normal }
    val icon = ComponentProperty<Icons.() -> IconDefinition> { Theme().icons.check }

    private var label: (RenderContext.() -> Unit)? = null
    fun label(value: String) {
        label = {
            span { +value }
        }
    }

    fun label(value: Flow<String>) {
        label = {
            span { value.renderText() }
        }
    }

    fun label(value: (RenderContext.() -> Unit)) {
        label = value
    }

    val labelStyle = ComponentProperty(Theme().checkbox.label)
    val checked = DynamicComponentProperty(flowOf(false))
    var checkedStyle = ComponentProperty(Theme().checkbox.checked)

    override fun render(
        context: RenderContext,
        styling: BoxParams.() -> Unit,
        baseClass: StyleClass,
        id: String?,
        prefix: String
    ): HtmlTag<HTMLLabelElement> = with(context) {
        label(
            {
                display { inlineFlex }
                alignItems { center }
                this@CheckboxComponent.size.value.invoke(Theme().checkbox.sizes)()
                // to "capture" the invisible, absolute positioned `input`, see `checkboxInputStaticCss`
                position { relative { } }
            },
            baseClass, prefix = prefix,
        ) {
            if (id != null) `for`(id)
            input(
                {
                    Theme().checkbox.input()
                    children("&[checked] + div") {
                        this@CheckboxComponent.checkedStyle.value()
                    }
                },
                checkboxInputStaticCss, id, prefix,
            ) {
                disabled(this@CheckboxComponent.disabled.values)
                readOnly(this@CheckboxComponent.readonly.values)
                type("checkbox")
                checked(this@CheckboxComponent.value?.data ?: this@CheckboxComponent.checked.values)
                this@CheckboxComponent.value?.let { changes.states() handledBy it.update }
                this@CheckboxComponent.events.value.invoke(this)
                this@CheckboxComponent.element.value.invoke(this)
            }

            div(
                {
                    Theme().checkbox.default()
                    styling()
                },
            ) {
                icon(
                    {
                        Theme().checkbox.icon()
                    },
                ) {
                    def(this@CheckboxComponent.icon.value(Theme().icons))
                }
            }

            this@CheckboxComponent.label?.let {
                div(
                    {
                        this@CheckboxComponent.labelStyle.value()
                    },
                ) {
                    it(this)
                }
            }
        }
    }
}
