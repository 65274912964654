package analyticsdashboard

import analytics.AnalyticsCategory
import analytics.AnalyticsService
import apiclient.FormationClient
import apiclient.customfields.FieldValue
import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.LatLon
import apiclient.geoobjects.ObjectType
import apiclient.geoobjects.ReadOnlyTags
import apiclient.geoobjects.SearchQueryContext
import apiclient.geoobjects.restSearch
import apiclient.search.ObjectSearchResult
import apiclient.validations.parseEnumValue
import data.objects.CurrentActiveFieldValueStore
import dev.fritz2.core.RootStore
import dev.fritz2.core.SimpleHandler
import dev.fritz2.core.invoke
import koin.koinCtx
import kotlinx.coroutines.Job
import layercache.GeoObjectDetailsCache
import maplibreGL.MaplibreMap
import model.KeywordTag
import model.SearchContexts
import model.analyticsPaths
import overlays.BusyStore
import search.PathActiveHighlightedObjectMarkersStore
import search.SearchContextsStore
import search.searchFilterStores.ActiveSearchOptionsStore
import services.SuggestedTagsContextStore

class PathToolSearchInputStore : RootStore<String>(
    initialData = "",
    job = Job(),
) {

    val reset = handle { current ->
        update("")
        current
    }
}

class PathSearchResultsStore : RootStore<List<ObjectSearchResult>>(
    initialData = emptyList(),
    job = Job(),
) {

    val maplibreMap: MaplibreMap by koinCtx.inject()
    val formationClient: FormationClient by koinCtx.inject()
    val busyStore: BusyStore by koinCtx.inject()
    private val searchContextsStore: SearchContextsStore by koinCtx.inject()
    private val pathActivehighlightedObjectMarkersStore: PathActiveHighlightedObjectMarkersStore by koinCtx.inject()
    private val pathActiveHighlightedObjectStore: PathActiveHighlightedObjectStore by koinCtx.inject()
    private val geoObjectDetailsCache: GeoObjectDetailsCache by koinCtx.inject()

    private val searchObjects = SimpleHandler<SearchQueryContext> { data, _ ->
        data handledBy { ctx ->
            busyStore.handleApiCall(
                supplier = suspend {
                    formationClient.restSearch(ctx)
                },
                processResult = { res ->
                    update(res.hits)
                    geoObjectDetailsCache.updateMultiple(res.hits.map { it.hit })
                    pathActivehighlightedObjectMarkersStore.update(res.hits.map { it.hit }.toSet())
                    console.log("Results for object history paths", res.hits.map { it.hit })
                },
            )
        }
    }

    fun triggerSearch() {
        searchObjects(searchContextsStore.current.analyticsPaths)
    }

    val centerToObject = SimpleHandler<GeoObjectDetails> { data, _ ->
        data handledBy { obj ->
            maplibreMap.flyTo(center = LatLon(obj.latLon.lat, obj.latLon.lon), zoom = maplibreMap.getZoom())
            maplibreMap.removeAllActiveObjectOverrides()
            maplibreMap.addActiveObjectOverride(obj)
            pathActiveHighlightedObjectStore.update(obj.id)
        }
    }

    val reset = handle { emptyList() }

    init {
        searchContextsStore.map(SearchContexts.analyticsPaths()).data handledBy searchObjects
    }
}

class PathActiveHighlightedObjectStore : RootStore<String?>(
    initialData = null,
    job = Job(),
) {

    val reset = handle { null }
}

class ActiveHistoryPathSearchKeywordsStore : RootStore<List<String>>(
    initialData = emptyList(),
    job = Job(),
) {

    private val suggestedTagsContextStore: SuggestedTagsContextStore by koinCtx.inject()
    private val analyticsService by koinCtx.inject<AnalyticsService>()

    val addHistoryPathKeywordFromInput = handle { current ->
        val prefix = suggestedTagsContextStore.current.prefix
        if (prefix.isNotBlank()) {
            suggestedTagsContextStore.resetPrefix()
            (current + prefix).distinct()
        } else current
    }

    val add = handle<String> { current, newKeyword ->
        if (newKeyword.isNotBlank() && newKeyword !in current) {
            analyticsService.createEvent(AnalyticsCategory.AddKeyWord) {
                click(newKeyword)
            }
            (current + newKeyword).distinct()
        } else current
    }

    val remove = handle<String> { current, keyWord ->
        if (current.isNotEmpty() && keyWord in current) (current - keyWord).distinct()
        else current
    }

    val reset = handle {
        emptyList()
    }
}

class ActiveHistoryPathSearchObjectTypesStore : RootStore<List<ObjectType>>(
    initialData = emptyList(),
    job = Job(),
) {

    private val analyticsService by koinCtx.inject<AnalyticsService>()

    val add = handle<String?> { current, newObjType ->
        val parsedObjType = parseEnumValue<ObjectType>(newObjType)
        if (parsedObjType != null && parsedObjType !in current) {
            analyticsService.createEvent(AnalyticsCategory.AddObjectType) {
                click(parsedObjType.name)
            }
//            (current + newObjType).distinct()
            listOf(parsedObjType)
        } else current
    }

    val remove = handle<String?> { current, newObjType ->
        val parsedObjType = parseEnumValue<ObjectType>(newObjType)
        if (current.isNotEmpty() && parsedObjType != null && parsedObjType in current) (current - parsedObjType).distinct()
        else current
    }

    val reset = handle {
        emptyList()
    }
}

class ActiveHistoryPathSearchReadOnlyKeywordsStore : RootStore<List<KeywordTag>>(
    initialData = emptyList(),
    job = Job(),
) {

    private val analyticsService by koinCtx.inject<AnalyticsService>()

    val add = handle<KeywordTag> { current, otherTag ->
        when (otherTag.readOnlyType) {
            ReadOnlyTags.Assignee -> {
                analyticsService.createEvent(AnalyticsCategory.AddAssignee) {
                    click(otherTag.fieldText)
                }
            }

            ReadOnlyTags.Creator -> {
                analyticsService.createEvent(AnalyticsCategory.AddCreator) {
                    click(otherTag.fieldText)
                }
            }

            else -> analyticsService.createEvent(AnalyticsCategory.AddKeyWord) {
                click(otherTag.fieldText)
            }
        }
        // Make sure only one tag of each type is active
        if (otherTag.readOnlyType in current.map { it.readOnlyType }) {
            (current.filter { it.readOnlyType != otherTag.readOnlyType } + otherTag).distinct()
        } else (current + otherTag).distinct()
    }

    val remove = handle<KeywordTag> { current, otherTag ->
        if (current.isNotEmpty() && otherTag.readOnlyType in current.map { it.readOnlyType }) {
            (current.filter { it.readOnlyType != otherTag.readOnlyType }).distinct()
        } else current
    }

    val reset = handle {
        emptyList()
    }
}

class ActiveHistoryPathSearchFieldValuesStore : RootStore<List<FieldValue>>(
    initialData = emptyList(),
    job = Job(),
) {

    private val currentActiveFieldValueStore: CurrentActiveFieldValueStore by koinCtx.inject()

    val add = handle<FieldValue?> { current, newFieldValue ->
        if (newFieldValue != null) {
            if (newFieldValue.field !in current.map { it.field }) {
                current + newFieldValue
            } else {
                current.filterNot { it.field == newFieldValue.field } + newFieldValue
            }
        } else current
    }

    val remove = handle<FieldValue?> { current, newFieldValue ->
        if (newFieldValue != null && newFieldValue.field in current.map { it.field }) {
            (current - newFieldValue).distinct()
        } else current
    }

    val addOrChangeFieldValue = handle { current ->
        val currentFieldValue = currentActiveFieldValueStore.current
        if (currentFieldValue != null) {
            if (currentFieldValue.field in current.map { it.field }) {
                current.map { fieldValue ->
                    if (fieldValue.field == currentFieldValue.field) {
                        when {
                            fieldValue is FieldValue.CategoryValue
                                && currentFieldValue is FieldValue.CategoryValue -> {
                                fieldValue.copy(value = currentFieldValue.value)
                            }

                            fieldValue is FieldValue.DoubleValue
                                && currentFieldValue is FieldValue.DoubleValue -> {
                                fieldValue.copy(value = currentFieldValue.value)
                            }

                            fieldValue is FieldValue.EmptyValue
                                && currentFieldValue is FieldValue.EmptyValue -> {
                                fieldValue
                            }

                            fieldValue is FieldValue.InstantValue
                                && currentFieldValue is FieldValue.InstantValue -> {
                                fieldValue.copy(value = currentFieldValue.value)
                            }

                            fieldValue is FieldValue.LongValue
                                && currentFieldValue is FieldValue.LongValue -> {
                                fieldValue.copy(value = currentFieldValue.value)
                            }

                            fieldValue is FieldValue.StringValue
                                && currentFieldValue is FieldValue.StringValue -> {
                                fieldValue.copy(value = currentFieldValue.value)
                            }

                            else -> fieldValue
                        }
                    } else fieldValue
                }.distinct()
            } else current + currentFieldValue
        } else current
    }

//    val addAndNavToSearch = handle<FieldValue?> { current, fieldValue ->
//        add(fieldValue)
//        current
//    }

    val reset = handle {
        emptyList()
    }
}

class ActiveHistoryPathSearchOptionsStore : ActiveSearchOptionsStore()
