package model

import apiclient.geoobjects.Content
import apiclient.users.UserFeatureFlag
import apiclient.users.UserPreferences

data class MyProfile(
    val userId: String = "",
    val firstName: String = "",
    val lastName: String = "",
    val jobTitle: String = "",
    val emails: List<String> = emptyList(),
    val password: String = "****************",
    val phoneNumbers: List<String> = emptyList(),
    val preferences: UserPreferences? = null,
//    val responseStatus: StatusResult? = null,
    val profilePhoto: Content.Image? = null,
    val featureFlags: Map<UserFeatureFlag, Boolean>? = null,
    val keywords: List<String> = listOf(),
    val company: String = "",
    val linkedInLink: String = "",
    val websiteLink: String = "",
    val webLinks: List<String> = listOf(),
    val allowEmailInPublic: Boolean = false,
    val allowVCardInPublic: Boolean = false,
) {
    companion object
}

val MyProfile.name get() = "$firstName $lastName"
