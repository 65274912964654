// GENERATED by fritz2 - NEVER CHANGE CONTENT MANUALLY!
package model

import apiclient.auth.ApiUser
import dev.fritz2.core.Lens
import dev.fritz2.core.lensOf

fun User.Companion.userId(): Lens<User, String> = lensOf(
    "userId",
    { it.userId },
    { p, v -> p.copy(userId = v) }
)

fun User.Companion.firstName(): Lens<User, String> = lensOf(
    "firstName",
    { it.firstName },
    { p, v -> p.copy(firstName = v) }
)

fun User.Companion.lastName(): Lens<User, String> = lensOf(
    "lastName",
    { it.lastName },
    { p, v -> p.copy(lastName = v) }
)

fun User.Companion.valid(): Lens<User, Boolean> = lensOf(
    "valid",
    { it.valid },
    { p, v -> p.copy(valid = v) }
)

fun User.Companion.apiUser(): Lens<User, ApiUser?> = lensOf(
    "apiUser",
    { it.apiUser },
    { p, v -> p.copy(apiUser = v) }
)
