package auth

import apiclient.auth.ApiUser
import apiclient.auth.LoginAndTokenRefreshService
import apiclient.auth.expirationIsValid
import apiclient.auth.restLoginToWorkspace
import apiclient.util.ItemHolder
import dev.fritz2.core.RootStore
import dev.fritz2.core.SimpleHandler
import dev.fritz2.core.invoke
import dev.fritz2.core.storeOf
import dev.fritz2.routing.MapRouter
import koin.koinCtx
import kotlinx.browser.window
import kotlinx.coroutines.Job
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.mapNotNull
import kotlinx.coroutines.launch
import kotlinx.datetime.Clock
import kotlinx.serialization.SerializationException
import kotlinx.serialization.json.Json
import login.ChangeWorkspaceStore
import login.CredentialsStore
import login.DirectAnonymousLoginStore
import login.HostConfigStore
import login.WorkspaceInputStore
import mainmenu.Pages
import mainmenu.RouterStore
import map.mapStatePrefix
import model.InitializationState
import model.User
import model.toUser
import overlays.BusyStore
import utils.graphqlScope

const val userPrefix = "com.tryformation.webapp.user"

val emptyUser = User(
    userId = "",
    firstName = "",
    lastName = "",
    apiUser = null,
)

class ApiUserStore(
    private val json: Json
) : RootStore<User>(
    initialData = getUserOrDefault(emptyUser, json),
    id = "apiusers",
    job = Job(),
), ItemHolder<ApiUser> {

    private val router: MapRouter by koinCtx.inject()
    private val routerStore: RouterStore by koinCtx.inject()
    private val workspaceOptionsStore: WorkspaceOptionsStore by koinCtx.inject()
    private val sessionIdStore by koinCtx.inject<SessionIdStore>()

    // FIXME circular dependency!!
    private val loginAndTokenRefreshService: LoginAndTokenRefreshService by koinCtx.inject()
    private val workspacesStore by koinCtx.inject<WorkspacesStore>()
    private val currentWorkspaceStore by koinCtx.inject<CurrentWorkspaceStore>()
    private val changeWorkspaceStore by koinCtx.inject<ChangeWorkspaceStore>()
    private val workspaceInputStore by koinCtx.inject<WorkspaceInputStore>()
    private val directAnonymousLoginStore by koinCtx.inject<DirectAnonymousLoginStore>()
    private val busyStore by koinCtx.inject<BusyStore>()

    val isBusyCheckingStore = storeOf(InitializationState.UNKNOWN, job)

    val anonymousUserStore = storeOf<ApiUser?>(null, job)

    private val hostConfigStore by koinCtx.inject<HostConfigStore>()

    val anonymousGraphQLClient = hostConfigStore.anonymousClient()
    var latest: User = current

    override suspend fun clear() {
        update(emptyUser)
        latest = emptyUser
        window.localStorage.removeItem(userPrefix)
    }

    override suspend fun get(): ApiUser {
        // throws if you are not logged in
        return latest.apiUser!!
    }

    override suspend fun getIfExists(): ApiUser? {
        return if (latest.userId.isNotBlank()) latest.apiUser else null
    }

    override suspend fun set(value: ApiUser) {
        val u = value.toUser()
        latest = u
        update(u)

        isBusyCheckingStore.update(InitializationState.DONE)

        val credentialsStore: CredentialsStore by koinCtx.inject()

        if (credentialsStore.current.rememberMe) {
            window.localStorage.setItem(userPrefix, json.encodeToString(User.serializer(), u))
        } else {
            window.localStorage.removeItem(userPrefix)
            window.localStorage.removeItem(mapStatePrefix)
        }
    }

    val logoutWithParams = SimpleHandler<Unit> { data, _ ->
        data handledBy {
            isBusyCheckingStore.update(InitializationState.RUNNING)
            val loginRoute = mutableMapOf("page" to Pages.Login.name)
            router.current["ws"] ?: latest.apiUser?.workspaceName?.let { loginRoute["ws"] = it }
            latest.apiUser?.emails?.firstOrNull()?.let { loginRoute["email"] = it }
            routerStore.addOrReplaceRouteDirectly(loginRoute.toMap())
            console.log("logout user, but set params ...")
            directAnonymousLoginStore.reset()
            window.localStorage.removeItem(userPrefix)
            window.localStorage.removeItem(mapStatePrefix)
            currentWorkspaceStore.reset()
            loginAndTokenRefreshService.logout()
            sessionIdStore.update(null)
            val u = emptyUser
            latest = u
            isBusyCheckingStore.update(InitializationState.DONE)
            update(u)
        }
    }

    val changeWorkspace = handle {
        isBusyCheckingStore.update(InitializationState.RUNNING)
        var loginRoute = Pages.Login.route
        changeWorkspaceStore.current?.let {
            loginRoute = it.newRoute
            workspaceInputStore.update(it.newWorkspace)
        }
        console.log("Logout user, but set new workspace ...")
        directAnonymousLoginStore.reset()
        window.localStorage.removeItem(userPrefix)
        window.localStorage.removeItem(mapStatePrefix)
        workspacesStore.reset()
        currentWorkspaceStore.reset()
        changeWorkspaceStore.reset()
        routerStore.addOrReplaceRoute(loginRoute)
        loginAndTokenRefreshService.logout()
        sessionIdStore.update(null)
        val u = emptyUser
        latest = u
        isBusyCheckingStore.update(InitializationState.DONE)
        u
    }

    val logoutFun = SimpleHandler<Unit> { data, _ ->
        data handledBy {
            isBusyCheckingStore.update(InitializationState.RUNNING)
            routerStore.addOrReplaceRouteDirectly(Pages.Login.route)
            console.log("Logout user ...")
            directAnonymousLoginStore.reset()
            window.localStorage.removeItem(userPrefix)
            window.localStorage.removeItem(mapStatePrefix)
            workspacesStore.reset()
            currentWorkspaceStore.reset()
            loginAndTokenRefreshService.logout()
            sessionIdStore.update(null)
            isBusyCheckingStore.update(InitializationState.DONE)
        }
    }

    val logoutToPage = handle<Map<String, String>> { _, path ->
        console.log("Logout user and go to $path ...")
        isBusyCheckingStore.update(InitializationState.RUNNING)
        directAnonymousLoginStore.reset()
        window.localStorage.removeItem(userPrefix)
        window.localStorage.removeItem(mapStatePrefix)
        workspacesStore.reset()
        currentWorkspaceStore.reset()
//        anonymousUserStore.update(null)
        routerStore.addOrReplaceRoute(path)
        loginAndTokenRefreshService.logout()
        sessionIdStore.update(null)
        val u = emptyUser
        latest = u
        update(u)
        isBusyCheckingStore.update(InitializationState.DONE)
        u
    }

    val goToSignUp = handle {
        console.log("go to sign up ...")
        isBusyCheckingStore.update(InitializationState.RUNNING)
        directAnonymousLoginStore.reset()
        val signUpRoute = mutableMapOf("page" to Pages.SignUp.name)
        latest.apiUser?.workspaceName?.let { signUpRoute["ws"] = it }
        latest.apiUser?.emails?.firstOrNull()?.let { signUpRoute["email"] = it }
        console.log("Logout user, but set params ...")
        window.localStorage.removeItem(userPrefix)
        window.localStorage.removeItem(mapStatePrefix)
        workspacesStore.reset()
        currentWorkspaceStore.reset()
//        anonymousUserStore.update(null)
        routerStore.addOrReplaceRoute(signUpRoute.toMap())
        loginAndTokenRefreshService.logout()
        sessionIdStore.update(null)
        val u = emptyUser
        latest = u
        update(u)
        isBusyCheckingStore.update(InitializationState.DONE)
        u
    }

    val refreshUser = handle { current ->
        if (current.apiUser != null) {
            isBusyCheckingStore.update(InitializationState.RUNNING)
            delay(500) // allow workspaceNameStore update to happen
            console.log("Refresh user")
            loginAndTokenRefreshService.forceRefresh()
            isBusyCheckingStore.update(InitializationState.DONE)
            sessionIdStore.ensureHasSession()
        }
        current
    }

//    val setExpiredDate30 = handle {current ->
//        console.log("expire tokens now")
//        current.copy(
//            apiUser = current.apiUser?.copy(
//                apiRefreshToken = current.apiUser.apiRefreshToken.copy(expiration = (Clock.System.now() + 30.seconds).toEpochMilliseconds()),
//                apiAccessToken = current.apiUser.apiAccessToken.copy(expiration = (Clock.System.now() + 30.seconds).toEpochMilliseconds())
//            ),
//            valid = false
//        ).apiUser?.let { set(it) }
//        current
//    }

    val setExpiredToken = handle { current ->
        console.log("Set expired tokens.")
        current.copy(
            apiUser = current.apiUser?.copy(
                apiRefreshToken = current.apiUser.apiRefreshToken.copy(expiration = (Clock.System.now()).toEpochMilliseconds()),
                apiAccessToken = current.apiUser.apiAccessToken.copy(expiration = (Clock.System.now()).toEpochMilliseconds()),
            ),
            valid = false,
        ).apiUser?.let { set(it) }
        current
    }

    fun loginAnonymous(workspaceName: String?, directToMap: Boolean = false) {
        if (current.apiUser == null && !workspaceName.isNullOrBlank()) {
            // Try anonymous login
            console.log("No User found -> Try anonymous login to:", workspaceName)
            graphqlScope.launch {
                val res = anonymousGraphQLClient.restLoginToWorkspace(
                    workspace = workspaceName,
                    email = null,
                    password = null,
                )
                res.fold(
                    { anonymousUser ->
                        console.log("Anonymous login succeeded!", anonymousUser)
                        anonymousUserStore.update(anonymousUser)
                        sessionIdStore.ensureHasSession()
                        if (directToMap) {
                            set(anonymousUser)
                        }
                    },
                    {
                        console.log("Anonymous login failed", it.message)
                        anonymousUserStore.update(null)
                    },
                )
            }
        }
    }

    private val anonymousLogin = handle<String?> { current, workspace ->
        loginAnonymous(workspace)
        current
    }

    val proceedAnonymously = handle {
        routerStore.resetPreLoginRedirect()
        val anonymousUser = anonymousUserStore.current
        if (anonymousUser != null) {
            console.log("anonymous user", anonymousUser)
//            anonymousUser.workspaceName?.let { ws -> routerStore.updateWsOfRedirectRoute(ws) }
            set(anonymousUser)
        } else {
            console.log(":-( ", anonymousUser)
        }
        it
    }

    init {
        refreshUser()
        // make sure we talk to the right dns after reload
//        if(current.apiUser != null) currentWorkspaceStore.fetchWorkspaces(current)
        data.mapNotNull { if (it.apiUser != null) it.apiUser.workspaceName else null } handledBy hostConfigStore.updateHostConfig
        workspaceOptionsStore.data.mapNotNull {
            if (it?.anonymousAccessAllowed == true) {
                it.name
            } else {
                anonymousUserStore.update(null)
                null
            }
        } handledBy anonymousLogin
    }

    companion object {

        fun getUserOrDefault(
            default: User,
            json: Json
        ): User {
            val string = window.localStorage.getItem(userPrefix) ?: return default
            return try {
                val user = json.decodeFromString(User.serializer(), string).copy(valid = false)
                console.warn("GET USER:", user, "Expiration valid?", user.apiUser?.apiRefreshToken?.expirationIsValid())
                if (user.apiUser?.apiRefreshToken?.expirationIsValid() == true) user else {
                    window.localStorage.removeItem(userPrefix)
                    default
                }
            } catch (e: SerializationException) {
                console.error(e)
                default
            }
        }
    }
}
