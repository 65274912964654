package utils

import kotlin.coroutines.suspendCoroutine
import org.w3c.dom.Image

suspend fun getImageDimensions(url: String): Pair<Int, Int> {
    return suspendCoroutine { c ->
        try {
            val img = Image()
            img.src = url
            img.onload = { e -> c.resumeWith(Result.success(img.width to img.height)) }
        } catch (e: Exception) {
            console.log(e)
            c.resumeWith(Result.failure(e))
        }
    }
}
