package dev.fritz2.components.foundations

/**
 * Generic container for modeling a property for a component class.
 * Use it like this:
 * ```
 * open class SomeComponent {
 *      val myProp = ComponentProperty("some text")
 *      val myBooleanProp = ComponentProperty(false)
 *
 *      // should rather be some implementation in the default theme of course!
 *      class SizesContext {
 *          small: Style<BasicParams> = { fontSize { small } }
 *          normal: Style<BasicParams> = { fontSize { small } }
 *          large: Style<BasicParams> = { fontSize { small } }
 *      }
 *
 *      val sizes = ComponentProperty<SizesContext.() -> Style<BasicParams>> { normal }
 * }
 * // within your UI declaration:
 * someComponent {
 *      myProp("Some specific content") // pass simple parameter
 *      myBooleanProp(true)
 *      sizes { large } // use expression syntax
 * }
 * ```
 */
class ComponentProperty<T>(var value: T) {
    operator fun invoke(newValue: T) {
        value = newValue
    }
}
