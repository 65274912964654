package data.objects.views.attachments

import auth.ApiUserStore
import apiclient.geoobjects.AttachmentType
import apiclient.geoobjects.Content
import apiclient.geoobjects.LinkRel
import apiclient.validations.parseEnumValue
import dev.fritz2.core.Id
import dev.fritz2.core.RootStore
import dev.fritz2.core.invoke
import koin.koinCtx
import kotlinx.coroutines.Job
import mainmenu.RouterStore
import model.GeoObjectData
import model.ImageFileData
import model.MarkdownData
import model.PollData
import model.PreAttachment
import model.TaskTemplateData
import model.WebLinkData

class RemovedAttachmentsStore : RootStore<Map<String, Content>>(
    initialData = emptyMap(),
    job = Job(),
) {

    var interimState: Map<String, Content> = emptyMap()

    val add = handle<Content> { current, content ->
        val mutable = current.toMutableMap()
        mutable[content.id] = content
        mutable.toMap()
    }
    val remove = handle<String> { current, id ->
        current - id
    }

    val saveAsInterimState = handle { current ->
        interimState = current
        current
    }

    val resetToInterimState = handle {
        interimState
    }

    val reset = handle {
        interimState = emptyMap()
        emptyMap()
    }
}

class PreAttachmentsStore : RootStore<Map<String, PreAttachment>>(
    initialData = emptyMap(),
    job = Job(),
) {

    val routerStore: RouterStore by koinCtx.inject()
    val apiUserStore: ApiUserStore by koinCtx.inject()
    private val imageFileDataStore by koinCtx.inject<ImageFileDataStore>()
    private val weblinkDataStore by koinCtx.inject<WeblinkDataStore>()
    private val geoObjectDataStore by koinCtx.inject<GeoObjectDataStore>()
    private val markdownDataStore by koinCtx.inject<MarkdownDataStore>()
    private val pollDataStore by koinCtx.inject<PollDataStore>()
    private val taskTemplateDataStore by koinCtx.inject<TaskTemplateDataStore>()

    private var interimState: Map<String, PreAttachment> = emptyMap()

    fun addOrUpdateMultiple(preAttachments: List<PreAttachment>) {
        val currentAttachments = current.toMutableMap()
        preAttachments.forEach { preAttachment ->
            currentAttachments[preAttachment.id] = preAttachment
        }
        update(currentAttachments.toMap())
    }

    fun addOrUpdate(preAttachment: PreAttachment) {
        val currentAttachments = current.toMutableMap()
        currentAttachments[preAttachment.id] = preAttachment
        update(currentAttachments.toMap())
    }

    val addOrUpdatePreImageAttachment = handle { current ->
        val fileData = imageFileDataStore.current
        imageFileDataStore.reset()
        if (fileData != ImageFileData.EMPTY) { //&& fileData.prevBytes?.isNotEmpty() == true
            imageFileDataStore.reset()
            val fallbackId = "new-${Id.next()}"
            console.log(
                "AddOrUpdate Image Attachment", PreAttachment.PreImage(
                    id = fileData.id ?: fallbackId,
                    width = fileData.width,
                    height = fileData.height,
                    prevName = fileData.prevName,
                    mimeType = fileData.mimeType,
                    prevBytes = fileData.prevBytes ?: byteArrayOf(),
                    href = fileData.href,
                    title = fileData.title
                )
            )
            addOrUpdate(
                PreAttachment.PreImage(
                    id = fileData.id ?: fallbackId,
                    width = fileData.width,
                    height = fileData.height,
                    prevName = fileData.prevName,
                    mimeType = fileData.mimeType,
                    prevBytes = fileData.prevBytes ?: byteArrayOf(),
                    href = fileData.href,
                    title = fileData.title
                )
            )
        }
        current
    }

    val addOrUpdatePreMarkdownAttachment = handle { current ->
        val markdownData = markdownDataStore.generateHtml()
        if (markdownData.text.isNotBlank() || markdownData.title.isNotBlank()) {
            addOrUpdate(
                PreAttachment.PreMarkdown(
                    id = markdownData.id ?: "new-${Id.next()}",
                    text = markdownData.text,
                    htmlPreview = markdownData.htmlPreview,
                    title = markdownData.title
                )
            )
            markdownDataStore.reset()
        }
        current
    }

    val addOrUpdatePreWeblinkAttachment = handle { current ->
        val weblinkData = weblinkDataStore.current
        addOrUpdate(
            PreAttachment.PreWebLink(
                id = weblinkData.id ?: "new-${Id.next()}",
                href = weblinkData.href,
                title = weblinkData.title,
                rel = parseEnumValue<LinkRel>(weblinkData.rel),
                htmlPreview = null,
                openGraphMetadata = null,
                actionId = weblinkData.actionId
            )
        )
        weblinkDataStore.reset()
        current
    }

    val addPreAttachmentAndSave = handle<PreAttachment> { current, preAttachment ->
        val currentAttachments = current.toMutableMap()
        currentAttachments[preAttachment.id] = preAttachment
        interimState = currentAttachments.toMap()
        currentAttachments.toMap()
    }

    val addOrUpdateGeoObjectAttachment = handle { current ->
        val geoObjectData = geoObjectDataStore.current
        addOrUpdate(
            PreAttachment.PreGeoObject(
                id = geoObjectData.id ?: "new-${Id.next()}",
                objectId = geoObjectData.objectId,
                title = geoObjectData.title,
                rel = parseEnumValue<LinkRel>(geoObjectData.rel),
                htmlPreview = null,
            )
        )
        geoObjectDataStore.reset()
        current
    }

    val addOrUpdatePollAttachment = handle { current ->
        val pollData = pollDataStore.current
        addOrUpdate(
            PreAttachment.PrePoll(
                id = pollData.id ?: "new-${Id.next()}",
                title = pollData.title,
                actionId = pollData.actionId,
                options = pollData.options,
                maxVotesPerUser = pollData.maxVotesPerUser
            )
        )
        taskTemplateDataStore.reset()
        current
    }

    val addOrUpdateTaskTemplateAttachment = handle { current ->
        val taskTemplateData = taskTemplateDataStore.current
        addOrUpdate(
            PreAttachment.PreScanToCreateTask(
                id = taskTemplateData.id ?: "new-${Id.next()}",
                actionId = taskTemplateData.actionId,
                title = taskTemplateData.title,
                taskTemplate = taskTemplateData.taskTemplate,
            )
        )
        taskTemplateDataStore.reset()
        current
    }

    val remove = handle<String> { current, preAttachmentId ->
        val currentAttachments = current.toMutableMap()
        currentAttachments.remove(preAttachmentId)
        currentAttachments.toMap()
    }

    val edit = handle<String> { current, preAttachmentId ->
        console.log("edit attachemnt:", current[preAttachmentId])
        when (val preAttachment = current[preAttachmentId]) {
            is PreAttachment.PreImage -> {
                imageFileDataStore.update(
                    ImageFileData(
                        id = preAttachment.id,
                        prevName = preAttachment.prevName,
                        mimeType = preAttachment.mimeType,
                        prevBytes = preAttachment.prevBytes,
                        href = preAttachment.href,
                        width = preAttachment.width,
                        height = preAttachment.height,
                        title = preAttachment.title ?: ""
                    )
                )
                routerStore.addOrReplaceRoute(mapOf("change" to AttachmentType.Image.name))
            }

            is PreAttachment.PreMarkdown -> {
                markdownDataStore.update(
                    MarkdownData(
                        id = preAttachment.id,
                        text = preAttachment.text,
                        htmlPreview = preAttachment.htmlPreview ?: "",
                        title = preAttachment.title ?: ""
                    )
                )
                routerStore.addOrReplaceRoute(mapOf("change" to AttachmentType.Markdown.name))
            }

            is PreAttachment.PreWebLink -> {
                weblinkDataStore.update(
                    WebLinkData(
                        id = preAttachment.id,
                        href = preAttachment.href,
                        rel = preAttachment.rel?.name ?: "",
                        title = preAttachment.title,
                        actionId = preAttachment.actionId
                    )
                )
                routerStore.addOrReplaceRoute(mapOf("change" to AttachmentType.WebLink.name))
            }

            is PreAttachment.PreGeoObject -> {
                geoObjectDataStore.update(
                    GeoObjectData(
                        id = preAttachment.id,
                        objectId = preAttachment.objectId,
                        rel = preAttachment.rel?.name ?: "",
                        title = preAttachment.title
                    )
                )
                routerStore.addOrReplaceRoute(mapOf("change" to AttachmentType.GeoObject.name))
            }

            is PreAttachment.PrePoll -> {
                pollDataStore.update(
                    PollData(
                        id = preAttachment.id,
                        title = preAttachment.title,
                        actionId = preAttachment.actionId ?: "",
                        options = preAttachment.options
                    )
                )
                routerStore.addOrReplaceRoute(mapOf("change" to AttachmentType.Poll.name))
            }

            is PreAttachment.PreScanToCreateTask -> {
                taskTemplateDataStore.setData(
                    TaskTemplateData(
                        id = preAttachment.id,
                        title = preAttachment.title ?: "",
                        actionId = preAttachment.actionId,
                        taskTemplate = preAttachment.taskTemplate
                    )
                )
                routerStore.addOrReplaceRoute(mapOf("change" to AttachmentType.ScanToCreateTask.name))
            }

            else -> {}
        }
        current
    }

    val saveAsInterimState = handle { current ->
        interimState = current
        current
    }

    val resetToInterimState = handle {
        interimState
    }

    val reset = handle {
        interimState = emptyMap()
        emptyMap()
    }
}
