package geofenceeditor

import com.jillesvangurp.geo.GeoGeometry
import com.jillesvangurp.geojson.Geometry
import com.jillesvangurp.geojson.polygonGeometry
import com.jillesvangurp.geojson.rotate
import com.jillesvangurp.geojson.translate
import apiclient.geoobjects.LatLon
import apiclient.geoobjects.pointCoordinates
import theme.FormationShapes
import utils.roundTo

sealed class GeoShape {

    abstract val stringCode: String
    abstract val shapeSelect: GeoShapeSelect
    abstract val rotationDegrees: Double
    abstract fun geometry(center: LatLon): Geometry

    data class Circle(
        val radius: Double,
        override val rotationDegrees: Double
    ): GeoShape() {
        override val stringCode get() = "${SHORTNAME},${radius.roundTo(1)}"
        override val shapeSelect get() = GeoShapeSelect.Circle
        override fun geometry(center: LatLon): Geometry {
            return GeoGeometry.circle2polygon(50, center.lat, center.lon, radius).polygonGeometry()
        }
        companion object {
            const val SHORTNAME = "circle"
            val DEFAULT: GeoShape
                get() = Circle(2.5, 0.0)
        }
    }

    data class Rectangle(
        val xMeters: Double,
        val yMeters: Double,
        override val rotationDegrees: Double
    ): GeoShape() {
        override val stringCode get() = "${SHORTNAME},${xMeters.roundTo(1)},${yMeters.roundTo(1)},${rotationDegrees.roundTo(1)}"
        override val shapeSelect get() = GeoShapeSelect.Rectangle
        override fun geometry(center: LatLon): Geometry {
            val p = center.pointCoordinates()
            val tl = p.translate(yMeters / 2.0, xMeters / -2.0)
            val bl = p.translate(yMeters / -2.0, xMeters / -2.0)
            val br = p.translate(yMeters / -2.0, xMeters / 2.0)
            val tr = p.translate(yMeters / 2.0, xMeters / 2.0)

            return arrayOf(arrayOf(tl, bl, br, tr, tl)).polygonGeometry().rotate(rotationDegrees)
        }
        companion object {
            const val SHORTNAME = "rectangle"
            val DEFAULT: GeoShape
                get() = Rectangle(5.0, 5.0, 0.0)
        }
    }

    data class Isogon(
        val vertices: Int,
        val radius: Double,
        override val rotationDegrees: Double
    ): GeoShape() {
        override val stringCode get() = "${SHORTNAME},${vertices},${radius.roundTo(1)},${rotationDegrees.roundTo(1)}"
        override val shapeSelect get() = GeoShapeSelect.Isogon
        override fun geometry(center: LatLon): Geometry {
            return GeoGeometry.circle2polygon(vertices, center.lat, center.lon, radius).polygonGeometry().rotate(rotationDegrees)
        }
        companion object {
            const val SHORTNAME = "isogon"
            val DEFAULT: GeoShape
                get() = Isogon(5, 2.5, 0.0)
        }
    }
}

fun parseGeoShapebyCode(code: String): GeoShape {
    val shortname = code.substringBefore(",")
    val params = code.split(",")
    return when(shortname) {
        GeoShape.Circle.SHORTNAME -> {
            if(params.size > 1) {
                try {
                    val (_, radius) = params
                    GeoShape.Circle(
                        radius = radius.toDouble(),
                        rotationDegrees = 0.0
                    )
                } catch (e: NumberFormatException) {
                    GeoShape.Circle.DEFAULT
                }
            } else {
                GeoShape.Circle.DEFAULT
            }
        }
        GeoShape.Rectangle.SHORTNAME -> {
            if(params.size > 3) {
                try {
                    val (_, x, y , rot) = params
                    GeoShape.Rectangle(
                        xMeters = x.toDouble(),
                        yMeters = y.toDouble(),
                        rotationDegrees = rot.toDouble()
                    )
                } catch (e: NumberFormatException) {
                    GeoShape.Rectangle.DEFAULT
                }
            } else {
                GeoShape.Rectangle.DEFAULT
            }
        }
        GeoShape.Isogon.SHORTNAME -> {
            if(params.size > 3) {
                try {
                    val (_, cor, rad, rot) = params
                    GeoShape.Isogon(
                        vertices = cor.toInt(),
                        radius = rad.toDouble(),
                        rotationDegrees = rot.toDouble()
                    )
                } catch (e: NumberFormatException) {
                    GeoShape.Isogon.DEFAULT
                }
            } else {
                GeoShape.Isogon.DEFAULT
            }

        }

        else -> {
            GeoShape.Circle.DEFAULT
        }
    }
}

enum class GeoShapeSelect {
    Circle {
        override val icon = FormationShapes.Circle
    },
    Rectangle {
        override val  icon = FormationShapes.Square
    },
    Isogon {
        override val icon = FormationShapes.Pentagon
    },
    ;

    abstract val icon: FormationShapes
}
