package mainmenu.termstexts

import dev.fritz2.components.compat.a
import dev.fritz2.components.compat.div
import dev.fritz2.components.compat.p
import dev.fritz2.components.compat.span
import dev.fritz2.core.RenderContext
import dev.fritz2.core.href
import dev.fritz2.core.target
import mainmenu.termsLinkStyle
import mainmenu.textParagraphs
import mainmenu.textPart
import mainmenu.textPartTitle

fun RenderContext.privacyTextEN() {
    div({
        width { full }
    }) {
        textPartTitle("PRIVACY POLICY") {
            p {
                +"Last updated: March 2023"
            }
        }
        textPart("NAME AND ADDRESS OF THE RESPONSIBLE PARTY", 1) {
            textParagraphs {
                p {
                    +"The responsible party within the meaning of the General Data Protection Regulation (GDPR) and other national data protection laws of the member states as well as other data protection regulations is:"
                }
                p {
                    +"FORMATION GmbH"
                }
                div {
                    p {
                        +"Urbanstraße 71"
                    }
                    p {
                        +"10967 Berlin"
                    }
                    p {
                        +"Germany"
                    }
                }
                p {
                    +"mail: info@tryformation.com"
                }
            }
        }
        textPart("ACCESS DATA AND SERVER LOG FILES", 2) {
            textParagraphs {
                p {
                    +"When you visit our platform, access data is automatically saved in server log files. This includes the date and time of retrieval and possibly the entered search term."
                }
                p {
                    +"Temporary storage of the IP address by the system is necessary to deliver the platform and its contents to your device. For this, your IP address must be stored for the duration of the session."
                }
                p {
                    +"The legal basis for the temporary storage of your data and the log files is Art. 6 Para. 1 lit. b GDPR. This data is evaluated exclusively to ensure the continuous and trouble-free operation of the platform, improve the content of our platform, and ensure the security of our information technology systems. Your personal data will not be analyzed for marketing purposes in this context."
                }
                p {
                    +"The collection of data for the provision of the platform and the storage of data in log files is necessary for the operation of our platform. As a result, there is no possibility of objection."
                }
            }
        }
        textPart("USE OF COOKIES", 3) {
            textParagraphs {
                p {
                    +"To make the use of our platform attractive and to enable certain functions, we use so-called \"Cookies\". These are small text files that are stored on your device via a browser. Cookies may contain a so-called Cookie ID. It consists of a sequence of characters by which this Cookie ID can be assigned to a specific browser where the respective Cookie was stored."
                }
                p {
                    +"The following data is stored and transmitted in cookies: language settings, entered search terms, frequency of page views, use of platform functions, origin of the user, operating system used, device used, browser used, device resolution."
                }
                p {
                    +"Your data collected on our platform is anonymized by technical precautions. Therefore, the data cannot be assigned to you anymore. The data is not stored together with any of your other personal data."
                }
                p {
                    +"The legal basis for the processing of personal data using cookies is Article 6 paragraph 1 lit. a GDPR."
                }
                p {
                    +"The purpose of using technically necessary cookies is to simplify the use of our platform for you (e.g., your settings are saved). The features of our platform cannot be offered without the use of cookies. This is necessary as we need to ensure that you have agreed to our platform's terms of use. Therefore, it is important that you are recognized as a platform user in anonymized form. If you do not accept or deactivate cookies, the functionality of our platform may be limited."
                }
            }
        }
        textPart("DATA PROCESSING WHEN USING THE APP", 4) {
            textParagraphs {
                p {
                    +"When using the app, we process the following information described below based on Article 6 paragraph 1 lit. b) GDPR to enable the convenient use of features. If you want to use the app, we process the following data, which is technically necessary for us to offer you the app's features and ensure stability and security, in particular: the IP address of the device used, date and time of use, access status/HTTP status code, browser you are using, operating system and its interface, language and version of the browser software, etc."
                }
            }
        }
        textPart("MAPTILER", 5) {
            textParagraphs {
                p {
                    +"For the provision of our services and the displayed maps, we use Maptiler, a service of MapTiler AG, Höfnerstrasse 98, Unterägeri, Zug 6314, Switzerland (hereinafter: \"Maptiler\"), on the basis of Art. 1 lit. b) GDPR."
                }
                p {
                    +"When you use our maps, the maps and coordinates are processed via Maptiler's servers. We have concluded a contract with Maptiler for data processing according to Art. 28 GDPR, in which Maptiler commits to process the received data only according to our instructions and to comply with the EU data protection level. You can find additional information about Sendgrid and data protection at Sendgrid in the "
                    a(termsLinkStyle) {
                        +"privacy policy"
                        href("https://www.maptiler.com/privacy-policy/")
                        target("_blank")
                    }
                    +" of the provider."
                }
                p {
                    +"The IP addresses of MapTiler Cloud visitors are stored for a limited time, a maximum of 20 minutes, and then automatically destroyed. The collection and temporary storage are necessary for logging security-relevant activities on the Maptiler infrastructure. How you use the map itself is not recorded by MapTiler."
                }
                p {
                    +"For more information on data protection by MapTiler, please visit:"
                }
                a(termsLinkStyle) {
                    +"www.maptiler.com/privacy-policy/index.html"
                    href("www.maptiler.com/privacy-policy/index.html")
                    target("_blank")
                }
            }
        }
        textPart("SANDGRID", 6) {
            textParagraphs {
                p {
                    +"For the provision of our services and the maps displayed, we use Maptiler, a service provided by MapTiler AG, Höfnerstrasse 98, Unterägeri, Zug 6314, Switzerland (hereinafter: \"Maptiler\"), based on Art. 1 lit. b) GDPR."
                }
                p {
                    +"For email communication with customers and prospects, such as registration and appointment confirmations, we use the shipping software Sendgrid, a service of Sendgrid Inc., 1801 California Street, Denver, CO 80202, USA (hereinafter: \"Sendgrid\") based on Art. 6 para. 1 lit. b GDPR. In this case, the following data: email address, name, first name, contents of the respective transaction, IP address, etc. are processed via Sendgrid servers in the USA. We have entered into a contract with Sendgrid for data processing according to Art. 28 GDPR, in which Sendgrid commits to process the received data only according to our instructions and to comply with the EU data protection level. You can find additional information about Sendgrid and privacy policy at Sendgrid in the provider's "
                    a(termsLinkStyle) {
                        +"privacy policy"
                        href("https://www.twilio.com/en-us/legal/privacy")
                        target("_blank")
                    }
                    +"."
                }
            }
        }
        textPart("E-MAIL AND CONTACT FORM", 7) {
            textParagraphs {
                p {
                    +"On our website, due to legal requirements, we provide information under \"Contact\" allowing for quick electronic contact and direct communication with us via email. If you contact us by email, the personal data you provide will be automatically stored."
                }
                p {
                    +"The legal basis for processing the data transmitted in the course of sending an email is Art. 6 para. 1 lit. b GDPR. We use the personal data you provide solely to process your specific request. The submitted data will always be treated confidentially."
                }
                p {
                    +"The data will be deleted as soon as they are no longer necessary for the purpose they were collected. For the personal data sent by email, this is the case when the conversation with you has ended. The conversation is considered ended when it can be inferred from the circumstances that the matter in question has been conclusively clarified."
                }
                p {
                    +"When you contact us, you may object to the storage of your personal data at any time. In such a case, the conversation cannot continue."
                }
            }
        }
        textPart("GOOGLE FIREBASE", 8) {
            textParagraphs {
                p {
                    +"For the purpose of demand-oriented design and continuous user-specific optimization of our app, we use Firebase, a service of Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA (hereinafter: \"Firebase\"), to manage push notifications. By allowing the app to send you push messages, you agree that an anonymized device ID (push notification token) and status and technical data will be stored on our servers together with the associated app settings of the respective user and processed via Firebase servers. The legal basis for this is Art. 6 para. 1 lit. a) GDPR."
                }
                p {
                    +"We have entered into a contract with Firebase for data processing in accordance with EU standard contractual clauses in line with Art. 28 GDPR to ensure the security of this data processing."
                }
                div {
                    p {
                        +"You can revoke your consent to this data processing at any time with future effect by disabling push notifications in your device settings, or by simply informing us that you no longer wish to have such processing in the future. For this, please use the contact options provided above. You can find more information on Firebase and Firebase privacy in the provider's privacy policy under this "

                        span({ fontWeight { bold } }) {
                            +"revoke"
                        }

                        +", by disabling push notifications in your device settings, or by simply informing us that you no longer wish to have such processing in the future. For this, please use the contact options provided above. More information about Firebase and privacy at Firebase can be found in the provider's privacy policy under this "

                        a(termsLinkStyle) {
                            +"link"
                            href("https://firebase.google.com/support/privacy/")
                            target("_blank")
                        }

                        +"."
                    }
                }
            }
        }
        textPart("HUBSPOT", 9) {
            textParagraphs {
                div {
                    p {
                        +"For managing our customer data and prospects, we use the CRM platform from Hubspot, a service of HubSpot Inc., 2nd Floor 30 North Wall Quay, Dublin 1, Ireland (hereinafter: \"Hubspot\"). This helps us to record customer data, communicate with the customer, document this contact, and create offers according to their wishes. If you have contacted us, e.g. via the questionnaire, the following data will be processed through Salesforce's servers: name, email address, offers for the customer, data about interactions with emails, etc. The legal basis for these data processing operations is Art. 6 para. 1 sentence 1 lit. b GDPR. Hubspot uses so-called \"cookies\", text files that are stored on your computer and that enable analysis of the use of the website by you. The information generated by the cookie about your use of this website will be processed and stored on a Hubspot server. We have concluded a contract with Hubspot on processing according to Art. 28 GDPR, in which Hubspot commits to process the received data only according to our instructions and to maintain the EU data protection level. You can find more information about data protection at Hubspot in the provider's data protection provisions by clicking on this "

                        a(termsLinkStyle) {
                            +"link"
                            href("https://legal.hubspot.com/de/privacy-policy")
                            target("_blank")
                        }

                        +" click."
                    }
                }
            }
        }
        textPart("DATA SECURITY", 10) {
            textParagraphs {
                p {
                    +"We secure our platform and other systems through numerous technical and organizational measures against loss, destruction, access, alteration, or dissemination of your data by unauthorized persons. Despite regular checks, complete protection against all risks is not possible and cannot be guaranteed by us. For this reason, you are free to transmit your personal data to us using other means, such as by telephone or post, at any time."
                }
            }
        }
        textPart("DATA DELETION AND STORAGE DURATION", 11) {
            textParagraphs {
                p {
                    +"Your personal data will be deleted or blocked as soon as the purpose of storage no longer applies or you revoke your consent. Storage may also take place if required by European or national legislators in Union regulations, laws, or other provisions to which the controller is subject. If the purpose of storage no longer applies, you revoke your consent or a storage period prescribed by the European Directive and Regulation Giver or another competent legislator expires, the personal data will be routinely blocked or deleted in accordance with legal requirements, unless there is a need to continue storing the data for a contract conclusion or contract fulfillment."
                }
            }
        }
        textPart("RIGHT TO INFORMATION", 12) {
            textParagraphs {
                p {
                    +"You also have the right to obtain free information about the personal data relating to you that is stored by us and a copy of this information at any time. You also have the right to access the following information:"
                }
                ul {
                    li {
                        +"the purposes of processing,"
                    }
                    li {
                        +"the categories of personal data being processed,"
                    }
                    li {
                        +"the recipients or categories of recipients to whom the personal data has been or will be disclosed, particularly recipients in third countries or international organizations,"
                    }
                    li {
                        +"if possible, the planned duration for which the personal data will be stored, or if this is not possible, the criteria for determining this duration,"
                    }
                    li {
                        p {
                            +"the existence of a right to rectify or delete your personal data or to restrict its processing by the controller, or a "

                            span({ fontWeight { bold } }) {
                                +"right to object"
                            }

                            +" to such processing,"
                        }
                    }
                    li {
                        +"the existence of a right to lodge a complaint with a supervisory authority,"
                    }
                    li {
                        +"if the personal data is not collected from the data subject: all available information about the source of the data and,"
                    }
                    li {
                        +"the existence of automated decision-making, including profiling, pursuant to Article 22(1) and (4) GDPR and – at least in these cases – meaningful information about the logic involved, as well as the significance and the envisaged consequences of such processing for the data subject."
                    }
                }
                p {
                    +"In addition, you have a right to know whether personal data has been transmitted to a third country or an international organization. If this is the case, you also have the right to obtain information about the appropriate safeguards relating to the transfer."
                }
            }
        }
        textPart("RIGHT TO RECTIFICATION", 13) {
            textParagraphs {
                p {
                    +"You have the right to request immediate rectification and/or completion of your inaccurate or incomplete personal data. We shall make the correction without undue delay."
                }
            }
        }
        textPart("RIGHT TO RESTRICTION OF PROCESSING", 14) {
            textParagraphs {
                p {
                    +"You have the right to demand from us the restriction of processing if one of the following conditions applies:"
                }
                ul {
                    li {
                        +"The accuracy of the personal data is contested by the data subject, for a period enabling the controller to verify the accuracy of the personal data."
                    }
                    li {
                        +"The processing is unlawful, the data subject opposes the erasure of the personal data and requests instead the restriction of their use."
                    }
                    li {
                        +"The controller no longer needs the personal data for the purposes of the processing, but they are required by the data subject for the establishment, exercise or defense of legal claims."
                    }
                    li {
                        +"The data subject has objected to processing pursuant to Article 21(1) GDPR, pending verification whether the legitimate grounds of the controller override those of the data subject."
                    }
                }
                p {
                    +"Where processing has been restricted concerning your personal data, such data shall – with the exception of storage – only be processed with your consent or for the establishment, exercise, or defense of legal claims or for the protection of the rights of another natural or legal person, or for reasons of important public interest of the Union or of a Member State."
                }
                p {
                    +"If processing has been restricted under the above conditions, you will be informed by us before the restriction is lifted."
                }
            }
        }
        textPart("RIGHT TO ERASURE", 15) {
            textParagraphs {
                p {
                    +"You have the right to request from us the immediate erasure of your personal data, provided that one of the following reasons applies and processing is not necessary:"
                }
                ul {
                    li {
                        +"The personal data is collected or otherwise processed for purposes for which they are no longer necessary."
                    }
                    li {
                        +"The data subject withdraws the consent on which the processing is based according to Article 6(1)(a) GDPR or Article 9(2)(a) GDPR, and there is no other legal ground for the processing."
                    }
                    li {
                        +"The data subject objects to the processing pursuant to Article 21(1) GDPR, and there are no overriding legitimate grounds for the processing, or the data subject objects to the processing pursuant to Article 21(2) GDPR."
                    }
                    li {
                        +"The personal data has been processed unlawfully."
                    }
                    li {
                        +"The erasure of the personal data is necessary for compliance with a legal obligation under Union law or the law of the Member States to which the controller is subject."
                    }
                    li {
                        +"The personal data has been collected in relation to the offer of information society services referred to in Article 8(1) GDPR."
                    }
                }
                p {
                    +"If we have made the personal data public and are obliged to delete it as the controller pursuant to Article 17(1) GDPR, we shall take appropriate measures, including technical measures, considering the available technology and the cost of implementation, to inform other data controllers processing the published personal data that the data subject has requested the deletion of all links to this personal data or of copies or replications of this personal data from those other data controllers, insofar as processing is not necessary."
                }
                p {
                    +"The right to erasure does not exist if processing is necessary:"
                }
                ul {
                    li {
                        +"for exercising the right of freedom of expression and information;"
                    }
                    li {
                        +"for compliance with a legal obligation which requires processing under Union or Member State law to which the controller is subject, or for the performance of a task carried out in the public interest or in the exercise of official authority vested in the controller;"
                    }
                    li {
                        +"for reasons of public interest in the area of public health in accordance with Article 9(2)(h) and (i), and Article 9(3) GDPR;"
                    }
                    li {
                        +"for archiving purposes in the public interest, scientific or historical research purposes, or statistical purposes according to Article 89(1) GDPR, in so far as the right referred to in section a) is likely to render impossible or seriously impair the achievement of the objectives of that processing; or"
                    }
                    li {
                        +"for the establishment, exercise or defense of legal claims."
                    }
                }
            }
        }
        textPart("RIGHT TO INFORMATION", 16) {
            textParagraphs {
                p {
                    +"If you have asserted the right to rectification, erasure or restriction of processing against us, we are obliged to inform all recipients to whom the personal data concerning you have been disclosed of this rectification, erasure of data or restriction of processing, unless this proves impossible or involves disproportionate effort."
                }
                p {
                    +"You have the right to be informed about these recipients against us."
                }
            }
        }
        textPart("RIGHT TO DATA PORTABILITY", 17) {
            textParagraphs {
                p {
                    +"You have the right to receive the personal data concerning you that you have provided to us in a structured, commonly used and machine-readable format. You also have the right to transmit this data to another controller without hindrance from us, provided that the processing is based on consent pursuant to Art. 6 Para. 1 letter a GDPR or Art. 9 Para. 2 letter a GDPR or on a contract pursuant to Art. 6 Para. 1 letter b GDPR and the processing is carried out using automated procedures, provided that the processing is not necessary for the performance of a task in the public interest or in the exercise of public authority vested in us."
                }
                p {
                    +"You also have the right, when exercising your right to data portability pursuant to Art. 20 Para. 1 GDPR, to obtain that personal data is transferred directly from us to another controller, insofar as this is technically feasible and provided that it does not adversely affect the rights and freedoms of other persons."
                }
                p {
                    +"The right to data portability does not apply to the processing of personal data necessary for the performance of a task carried out in the public interest or in the exercise of official authority vested in the controller."
                }
            }
        }
        textPart("RIGHT TO OBJECT", 18) {
            textParagraphs {
                p {
                    +"You have the right to object, on grounds relating to your particular situation, at any time to the processing of personal data concerning you, which is carried out pursuant to Art. 6 Para. 1 letters e or f GDPR. This also applies to profiling based on these provisions."
                }
                p {
                    +"In the event of an objection, we will no longer process your personal data unless we can demonstrate compelling legitimate grounds for the processing that override your interests, rights and freedoms, or the processing serves to assert, exercise or defend legal claims."
                }
                p {
                    +"If we process your personal data for direct marketing purposes, you have the right to object at any time to the processing of your personal data for the purpose of such advertising. This also applies to profiling, insofar as it is related to such direct marketing. If you object to the processing for direct marketing purposes, we will no longer process your personal data for these purposes."
                }
                p {
                    +"You also have the right to object, on grounds relating to your particular situation, to the processing of your personal data for scientific or historical research purposes or for statistical purposes pursuant to Art. 89 Para. 1 GDPR, unless such processing is necessary for the performance of a task in the public interest."
                }
                p {
                    +"In order to exercise the right to object, you may contact us at any time. You are also free, in connection with the use of information society services, regardless of Directive 2002/58/EC, to exercise your right to object through automated procedures that use technical specifications."
                }
            }
        }
        textPart("RIGHT TO WITHDRAW CONSENT FOR DATA PROTECTION", 19) {
            textParagraphs {
                p({ fontWeight { bold } }) {
                    +"You have the right to withdraw your consent to the processing of personal data at any time. The withdrawal of consent does not affect the legality of the processing carried out based on the consent until the withdrawal."
                }
            }
        }
        textPart("RIGHT TO AUTOMATED DECISIONS IN INDIVIDUAL CASES INCLUDING PROFILING", 20) {
            textParagraphs {
                p {
                    +"You have the right not to be subject to a decision based solely on automated processing, including profiling, which produces legal effects concerning you or similarly significantly affects you, provided that the decision"
                }
                ul {
                    li {
                        +"is not necessary for the conclusion or fulfillment of a contract between you and us or"
                    }
                    li {
                        +"is permissible under European Union or European Member State law to which we are subject and that law contains appropriate measures to safeguard your rights, freedoms, and legitimate interests or"
                    }
                    li {
                        +"is made with your explicit consent."
                    }
                }
                p {
                    +"If the decision"
                }
                ul {
                    li {
                        +"is necessary for the conclusion or fulfillment of a contract between you and us or"
                    }
                    li {
                        +"is made with your explicit consent,"
                    }
                }
                p {
                    +"we shall implement suitable measures to safeguard your rights, freedoms, and legitimate interests, which includes at least the right to obtain the intervention of a person by us, to express your point of view, and to contest the decision."
                }
            }
        }
        textPart("EXISTENCE OF AUTOMATED DECISION-MAKING", 21) {
            textParagraphs {
                p {
                    +"We do not conduct automated decision-making or profiling."
                }
            }
        }
        textPart("RIGHT TO FILE A COMPLAINT WITH A SUPERVISORY AUTHORITY", 22) {
            textParagraphs {
                p {
                    +"Without prejudice to any other administrative or judicial remedy, you have the right to lodge a complaint with a supervisory authority, in particular in the Member State of your residence, your place of work or the place of the alleged infringement, if you consider that the processing of personal data relating to you infringes the GDPR."
                }
                p {
                    +"The supervisory authority with which the complaint was lodged informs the complainant of the status and outcome of the complaint, including the possibility of a judicial remedy under Article 78 of the GDPR. The supervisory authority responsible for us is the Berlin Commissioner for Data Protection and Freedom of Information, Friedrichstraße 219, 10969 Berlin."
                }
            }
        }
    }
}
