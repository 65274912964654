package objectrouting

import apiclient.geoobjects.Action
import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.LatLon
import apiclient.tags.actionIdMap
import auth.Features
import data.objects.ActiveObjectStore
import dev.fritz2.core.HtmlTag
import dev.fritz2.core.RenderContext
import dev.fritz2.core.href
import dev.fritz2.core.storeOf
import dev.fritz2.core.target
import koin.koinCtx
import koin.withKoin
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import localization.TL
import localization.getTranslationFlow
import mainmenu.RouterStore
import model.originObject
import org.w3c.dom.HTMLDivElement
import search.distanceInfo
import search.titleSizedText
import theme.FormationIcons
import theme.FormationUIIcons
import theme.IconEnum
import twcomponents.doIfFeatureFlagEnabled
import twcomponents.twColOfNoGap
import twcomponents.twFlatIconButton
import twcomponents.twIconMedium
import twcomponents.twIconSmall
import twcomponents.twSecondaryButton
import utils.isIos
import utils.roundTo
import webcomponents.Position
import webcomponents.ellipseText

fun RenderContext.openObjectNavigationButton() {

    val navigationToolToggleStore: NavigationToolToggleStore by koinCtx.inject()
    val navigationStore: NavigationStore by koinCtx.inject()
    val activeObjectStore: ActiveObjectStore by koinCtx.inject()
    val navigationSearchHistoryStore: NavigationSearchHistoryStore by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()

    twFlatIconButton(
        icon = FormationUIIcons.NavigationArrow,
        iconPosition = Position.Left,
    ) {
        p("text-sm truncate") {
            +"Open Navigation"
        }
        clicks handledBy {
            navigationToolToggleStore.update(true)
            navigationStore.setDestinationObjectWithSourceAndLatLon(activeObjectStore.current)
            navigationSearchHistoryStore.add(activeObjectStore.current)
            activeObjectStore.resetStore(Unit)
            routerStore.goToMap(Unit)
        }
    }
}

fun RenderContext.openExternalNavigationButton() {

    val activeObjectStore: ActiveObjectStore by koinCtx.inject()

    doIfFeatureFlagEnabled(Features.EnableRouteToObject) {
        activeObjectStore.data.render { activeObject ->
            a("w-full") {
                twFlatIconButton(icon = if (isIos()) FormationIcons.Apple else FormationIcons.Map) {
                    className("grow")
                    p("text-sm truncate") {
                        +"Open with ${if (isIos()) "Apple Maps" else "Google Maps"}"
                    }
                }
                val mapsLink = if (isIos()) {
                    "maps://?daddr=${activeObject.latLon.lat},${activeObject.latLon.lon}&dirflg=d"
                } else {
                    "https://www.google.com/maps/dir/?api=1&destination=${activeObject.latLon.lat},${activeObject.latLon.lon}&travelmode=driving"
                }
                target("_blank")
                href(mapsLink)
            }
        }
    }
}

fun RenderContext.navigationPointSourceRichButton(
    title: Flow<String>,
    icon: IconEnum,
    subtitleBlock: (HtmlTag<HTMLDivElement>.() -> Unit)? = null,
    latLonAsSubtitleFlow: Flow<LatLon?>? = null,
    block: HtmlTag<HTMLDivElement>.() -> Unit,
) {
    div("flex w-full items-center justify-between py-1 pl-4 pr-2 gap-2 cursor-pointer bg-gray-100 hover:bg-gray-200 rounded-xl overflow-hidden") {

        div("flex flex-row items-center justify-start gap-4 shrink-0") {
            twIconMedium(icon)
            twColOfNoGap {
                className("p-0.5 self-start min-w-0")
                titleSizedText { title.renderText() }
                if (subtitleBlock != null) {
                    subtitleBlock.invoke(this)
                } else {
                    ellipseText(
                        {
                            fontSize(sm = { tiny }, md = { smaller })
                            fontStyle { italic }
                        },
                    ) {
                        latLonAsSubtitleFlow?.map { latLon ->
                            "(${latLon?.lat?.roundTo(5)}, ${latLon?.lon?.roundTo(5)})"
                        }?.renderText(into = this)
                    }
                }
            }
        }

        block.invoke(this)
    }
}

fun RenderContext.navigationPointSourceSmallButton(
    title: Flow<String>,
    icon: IconEnum,
    block: HtmlTag<HTMLDivElement>.() -> Unit,
) {
    div("flex items-center justify-start p-2 gap-2 cursor-pointer bg-gray-100 hover:bg-gray-200 rounded-xl overflow-hidden") {

        twIconSmall(icon)
        ellipseText(
            {
                fontSize { small }
            },
        ) { title.renderText() }

        block.invoke(this)
    }
}

fun RenderContext.navigationCoordinateButtonContent(
    title: Flow<String>,
    icon: IconEnum,
    subtitle: String? = null,
    latLon: LatLon? = null,
) {
    div("flex flex-row w-full items-center justify-start py-1 pl-4 pr-2 gap-4") {
        twIconMedium(icon)
        twColOfNoGap {
            className("p-0.5 self-start w-full")
            div("flex flex-row w-full items-center justify-between") {
                titleSizedText { title.renderText() }
                distanceInfo(flowOf(latLon))
            }
            if (subtitle != null) {
                p("text-xs") {
                    +subtitle
                }
            }
        }
    }
}

fun RenderContext.navigationCoordinateButton(
    block: HtmlTag<HTMLDivElement>.() -> Unit,
) {
    div("flex w-full items-center justify-between gap-2 cursor-pointer bg-gray-100 hover:bg-gray-200 rounded-xl overflow-hidden") {
        block.invoke(this)
    }
}

fun RenderContext.routeToXActionQRButton(
    originObj: GeoObjectDetails?,
    destinationObjectId: String? = null,
    destinationLatLon: LatLon? = null,
    disabled: Boolean = false
) {
    withKoin {
        val navigationSearchHistoryStore: NavigationSearchHistoryStore = koinCtx.get()
        val navigationStore: NavigationStore = koinCtx.get()
        val originObjectStore = navigationStore.map(NavigationData.originObject())

        val openRouteToXActionId = originObj?.tags?.actionIdMap?.firstNotNullOfOrNull { (actionId, action) ->
            if (action is Action.OpenRouteToX
                && if (destinationObjectId != null) {
                    action.destinationObjectId == destinationObjectId
                } else if (destinationLatLon != null) {
                    action.latLon == destinationLatLon
                } else {
                    false
                }
            ) {
                actionId
            } else {
                null
            }
        }

        val actionCodeStore = storeOf(openRouteToXActionId ?: "")

        val toggleAddActionCodeModal = storeOf(false, Job())
        // Modal to associate Route with Action QR code
        addRouteToXActionCodeModal(
            title = TL.ActionCode.ADD_ACTION_CODE.getTranslationFlow(),
            toggleShowModalStore = toggleAddActionCodeModal,
            actionCodeStore = actionCodeStore,
            geoObject = originObj,
            destinationObjectId = destinationObjectId,
            destinationLatLon = destinationLatLon,
            objectUpdateHandlers = listOf(
                navigationSearchHistoryStore.updateEntry,
                originObjectStore.update,
            ),
        )

        val toggleShowActionCodeModal = storeOf(false, Job())
        // Modal to show and delete existing RouteToX QR code
        showActionCodeModal(
            title = TL.ActionCode.LINKED_ACTION_CODE.getTranslationFlow(),
            toggleShowModalStore = toggleShowActionCodeModal,
            actionCodeFlow = actionCodeStore.data,
            geoObject = originObj,
            objectUpdateHandlers = listOf(
                navigationSearchHistoryStore.updateEntry,
                originObjectStore.update,
            ),
        )

        // Actual QR Button
        twSecondaryButton(
            icon = FormationIcons.QRCode,
            iconPosition = Position.Right,
            disabledFlow = flowOf(disabled),
        ) {
            if (openRouteToXActionId != null) {
                twIconMedium(icon = FormationUIIcons.ArrowRightUp)
                clicks handledBy {
                    // open QR code
                    toggleShowActionCodeModal.update(true)
                }
            } else {
                twIconSmall(icon = FormationUIIcons.Add)
                clicks handledBy {
                    // open Camera & scan code
                    toggleAddActionCodeModal.update(true)
                }
            }
        }
    }
}
