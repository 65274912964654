package signup

import auth.FeatureFlagStore
import auth.Features
import dev.fritz2.components.compat.p
import dev.fritz2.components.modal
import dev.fritz2.components.stackUp
import dev.fritz2.core.Handler
import koin.koinCtx
import localization.TL
import localization.Translation
import mainmenu.RouterStore
import routing.MainController
import styling.secondaryButtonStyleParams
import theme.FormationIcons
import webcomponents.cardTitle
import webcomponents.fullPageConfirmation
import webcomponents.fullPageConfirmationContainer
import webcomponents.oneButtonFooter
import webcomponents.twoButtonFooter

fun popupSignUp(): Handler<Unit> {
    val translation by koinCtx.inject<Translation>()
    val routerStore by koinCtx.inject<RouterStore>()
    val mainController by koinCtx.inject<MainController>()
    val featuresFlagStore by koinCtx.inject<FeatureFlagStore>()

    return modal({
        width { none }
        height { none }
    }) {
        placement { center }
        hasCloseButton(false)
        content { close ->
            fullPageConfirmation {
                fullPageConfirmationContainer {
                    stackUp({
                        width { full }
                    }) {
                        spacing { small }
                        items {
//                            p({
//                                fontSize { larger }
//                                fontWeight { bold }
//                                margins { vertical { large } }
//                            }) { translation[TL.PopUpSignUp.POPUP_SIGN_UP_TITLE].renderText(into = this) }
                            cardTitle(translation[TL.PopUpSignUp.POPUP_SIGN_UP_TITLE]) { FormationIcons.Lock.icon }
                            p({
                                margins { vertical { large } }
                            }) { translation[TL.PopUpSignUp.SIGN_UP_FOR_FEATURES_MESSAGE].renderText(into = this) }
                            featuresFlagStore.data.render { features ->
                                if (features[Features.EnableSignUpButton] == true) {
                                    twoButtonFooter(
                                        primaryTitle = translation[TL.General.SIGN_UP],
                                        primaryValue = Unit,
                                        primaryClickHandlers = listOf(close, mainController.goToSignUp),
                                        primaryStyleParams = secondaryButtonStyleParams,
                                        primaryStyleType = { secondary },
                                        secondaryTitle = translation[TL.General.LOGIN],
                                        secondaryClickHandlers = listOf(close, mainController.logoutWithParams),
                                        secondaryStyleType = { secondary }
                                    )
                                } else {
                                    oneButtonFooter(
                                        title = translation[TL.General.LOGIN],
                                        value = Unit,
                                        clickHandlers = listOf(close, mainController.logoutWithParams),
                                        styleParams = secondaryButtonStyleParams,
                                    )
                                }
                            }
                            oneButtonFooter(
                                title = translation[TL.General.CLOSE],
                                value = Unit,
                                clickHandlers = listOf(close, routerStore.back),
                            )
                        }
                    }
                }
            }

        }
    }
}
