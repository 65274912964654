package workspacetools.buildingeditor

import apiclient.geoobjects.BuildingAndFloorTags
import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.LatLon
import apiclient.geoobjects.toLatLon
import apiclient.tags.getUniqueTag
import dev.fritz2.core.RootStore
import dev.fritz2.core.Store
import kotlin.coroutines.suspendCoroutine
import kotlinx.coroutines.Job
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.builtins.nullable
import kotlinx.serialization.builtins.serializer
import kotlinx.serialization.json.Json
import maplibreGL.LngLat
import maplibreGL.toLatLon
import org.w3c.dom.Image
import utils.getImageDimensions

private fun String.parsePointCoordinate() =
    Json.decodeFromString(ListSerializer(Double.serializer().nullable), this)
        .filterNotNull()
        .toDoubleArray()

fun GeoObjectDetails.toImageLocation(): ImageLocation? {

    val bl = tags.getUniqueTag(BuildingAndFloorTags.BottomLeft)?.parsePointCoordinate()
    val br = tags.getUniqueTag(BuildingAndFloorTags.BottomRight)?.parsePointCoordinate()
    val tl = tags.getUniqueTag(BuildingAndFloorTags.TopLeft)?.parsePointCoordinate()
    val tr = tags.getUniqueTag(BuildingAndFloorTags.TopRight)?.parsePointCoordinate()

    return bl?.let { bottomLeft ->
        tl?.let { topLeft ->
            br?.let { bottomRight ->
                tr?.let { topRight ->
                    ImageLocation(
                        topLeft = topLeft.toLatLon(),
                        topRight = topRight.toLatLon(),
                        bottomLeft = bottomLeft.toLatLon(),
                        bottomRight = bottomRight.toLatLon()
                    )
                }
            }
        }
    }
}

suspend fun GeoObjectDetails.toImagePosition(): ImageRotationAndScale? {
    return tags.getUniqueTag(BuildingAndFloorTags.FloorPlanImageUrl)?.let { url ->
        try {
            val (width, height) = getImageDimensions(url)
            this.toImageLocation()
                ?.let { location ->
                    ImageRotationAndScale(
                        imageResource = ImageResource(url, width, height),
                        angle = 0.0,
                        pixelsPerMeter = location.scale(width, height),
                        imageLocation = location
                    )
                }

        } catch (e: Exception) {
            console.error("fall back")
            null
        }
    }
}

class ImageRotationAndScaleStore(
    private val buildingEditorMapStateStore: BuildingEditorMapStateStore,
    private val angleStore: Store<String>,
    private val pixelsPerMeterStore: Store<String>,
) : RootStore<ImageRotationAndScale?>(null, Job()) {

    val floorUpdatedHandler =
        handle<GeoObjectDetails?> { old, floor ->
            MainScope().launch {
                val updated =
                    if (floor == null) {
                        old
                    } else {
                        floor.toImagePosition().let { rotationAndScale ->
                            pixelsPerMeterStore.update(rotationAndScale?.pixelsPerMeter.toString())
                            angleStore.update(rotationAndScale?.angle.toString())
                            rotationAndScale
                        }
                    }
                val centroid = updated?.imageLocation?.centroid
                if (centroid != null) {
                    buildingEditorMapStateStore.update(
                        BuildingEditorMapState(
                            LngLat(lng = centroid.lon, lat = centroid.lat),
                            buildingEditorMapStateStore.current?.zoom ?: 18
                        )
                    )
                }
                update(updated)
            }
            old
        }

}
