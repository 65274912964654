package data.objects.views.cardinfo

import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.MeetingInvitationStatus
import apiclient.geoobjects.ObjectTags
import apiclient.geoobjects.ObjectType
import apiclient.tags.getUniqueTag
import auth.ApiUserStore
import data.objects.ActiveObjectStore
import data.objects.views.attachments.toPreAttachment
import data.objects.views.directediting.directEditingCardContent
import data.objects.views.dotNumber
import data.objects.views.iconNumber
import dev.fritz2.components.flexBox
import dev.fritz2.components.icon
import dev.fritz2.components.lineUp
import dev.fritz2.core.RenderContext
import koin.koinCtx
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import localization.TL
import localization.Translation
import mainmenu.RouterStore
import model.L
import theme.FormationColors
import theme.FormationIcons
import utils.formatEventTimeSpan
import utils.getColor
import utils.getIcon
import utils.getName
import utils.respectFeatureFlags
import webcomponents.baseLayout
import webcomponents.cardTitleWithSubtitle
import webcomponents.cardTitleWithSubtitleDirectEdit
import webcomponents.ellipseText
import webcomponents.selectorButton
import webcomponents.selectorButtonFrame
import webcomponents.selectorContent

fun RenderContext.eventInfoCardContent() {

    val objectType = ObjectType.Event
    val translation: Translation by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()
    val apiUserStore: ApiUserStore by koinCtx.inject()
    val activeObjectStore: ActiveObjectStore by koinCtx.inject()
    val title = activeObjectStore.map(apiclient.geoobjects.GeoObjectDetails.L.title)
    val description = activeObjectStore.map(apiclient.geoobjects.GeoObjectDetails.L.description)
    val startTime = activeObjectStore.map(GeoObjectDetails.L.atTime)
    val endTime = activeObjectStore.map(apiclient.geoobjects.GeoObjectDetails.L.endTime)
    val attendees = activeObjectStore.map(apiclient.geoobjects.GeoObjectDetails.L.attendees)
    val attachments =
        activeObjectStore.map(GeoObjectDetails.L.attachments).data.map { attachments ->
            attachments.respectFeatureFlags()?.mapNotNull { it.toPreAttachment() }
        }
    val externalId = activeObjectStore.map(GeoObjectDetails.L.tags).data.map { it.getUniqueTag(ObjectTags.ExternalId) }
    val canModify = activeObjectStore.map(GeoObjectDetails.L.canModify)
    val canManage = activeObjectStore.map(GeoObjectDetails.L.canManage)

    baseLayout(
        header = {
            flexBox(
                {
                    direction { row }
                    justifyContent { spaceBetween }
                    alignItems { start }
                    width { full }
                    height { maxContent }
                },
            ) {
                // CARD TITLE
                canManage.data.combine(canModify.data) { manage, modify -> manage || modify }
                    .render { editAccess ->
                        if (editAccess) {
                            cardTitleWithSubtitleDirectEdit(
                                titleFlow = title.data,
                                title = title.current,
                                subtitle = flowOf(objectType.getName()),
                                titleIconFlow = flowOf(objectType.getIcon().icon),
                                subtitleIconFlow = externalId.map { if (!it.isNullOrBlank()) FormationIcons.QRCode.icon else null },
                                changeHandler = activeObjectStore.editTitle,
                            )
                        } else {
                            cardTitleWithSubtitle(
                                title = title.data,
                                subtitle = flowOf(objectType.getName()),
                                titleIconFlow = flowOf(objectType.getIcon().icon),
                                subtitleIconFlow = externalId.map { if (!it.isNullOrBlank()) FormationIcons.QRCode.icon else null },
                            )
                        }
                    }
                infoCardButtons()
            }
        },
        content = {
            directEditingCardContent {
                // EVENT DATE
                selectorButtonFrame {
                    selectorContent {
                        icon({ margins { horizontal { smaller } }; size { normal } }) { fromTheme { FormationIcons.Time.icon } }
                        ellipseText { formatEventTimeSpan(startTime.data, endTime.data).renderText(into = this) }
                    }
                }

                // ATTENDEE BUTTON
                attendees.data.render { attendeeList ->
                    selectorButton {
                        if (!attendeeList.isNullOrEmpty()) {
                            selectorContent {
                                icon(
                                    {
                                        margins { horizontal { smaller } }
                                        size { normal }
                                    },
                                ) { fromTheme { FormationIcons.UserGroup.icon } }
                                ellipseText {
                                    translation[TL.CardInfo.ATTENDEES, mapOf("size" to attendeeList.size)].renderText(
                                        into = this,
                                    )
                                }
                            }
                            val pending = attendeeList.filter {
                                it.meetingInvitationStatus == MeetingInvitationStatus.Pending
                            }.size
                            val accepted = attendeeList.filter {
                                it.meetingInvitationStatus == MeetingInvitationStatus.Accepted
                            }.size
                            val maybe = attendeeList.filter {
                                it.meetingInvitationStatus == MeetingInvitationStatus.Maybe
                            }.size
                            val rejected = attendeeList.filter {
                                it.meetingInvitationStatus == MeetingInvitationStatus.Rejected
                            }.size
                            lineUp(
                                {
                                    flex {
                                        grow { "1" }
                                        shrink { "0" }
                                        basis { maxContent }
                                    }
                                    alignItems { center }
                                    justifyContent { spaceEvenly }
                                    fontSize { small }
                                    overflowX { hidden }
                                    margins { left { smaller } }
                                },
                            ) {
                                spacing { none }
                                items {
                                    iconNumber(pending) { FormationIcons.Wait.icon }
                                    dotNumber(accepted, FormationColors.GreenActive)
                                    dotNumber(maybe, FormationColors.YellowDoing)
                                    dotNumber(rejected, FormationColors.RedError)
                                }
                            }
                        } else {
                            selectorContent {
                                ellipseText(
                                    {
                                        margins { left { small } }
                                    },
                                ) {
                                    translation[
                                        TL.CardInfo.ATTENDEES,
                                        mapOf(
                                            "size" to translation.getString(TL.General.NONE),
                                        ),
                                    ].renderText(into = this)
                                }
                            }
                        }
                        clicks.map {
                            mapOf("show" to "attendees")
                        } handledBy routerStore.addOrReplaceRoute
                    }
                }
                // CHANGE MEETING STATUS BUTTON
                attendees.data.render { attendeeList ->
                    val usersStatus = attendeeList?.firstOrNull {
                        it.userId == apiUserStore.current.userId
                    }?.meetingInvitationStatus
                    // Your Meeting Status
                    usersStatus?.let { status ->
                        selectorButton {
                            selectorContent {
                                if (status == MeetingInvitationStatus.Pending) {
                                    ellipseText(
                                        {
                                            margins { left { small } }
                                        },
                                    ) {
                                        translation[TL.CardInfo.SET_YOUR_MEETING_STATUS].renderText(into = this)
                                    }
                                } else {
                                    ellipseText(
                                        {
                                            margins { left { small } }
                                        },
                                    ) {
                                        translation[TL.CardInfo.YOUR_STATUS].renderText(into = this)
                                    }
                                    colorDot(status.getColor())
                                    ellipseText { +status.getName() }
//                                    TODO decide if we want this new invatationState version or the old one above
//                                    icon({
//                                        margins { horizontal { smaller } }
//                                        size { normal }
//                                    }) { fromTheme { FormationIcons.UserAlt.icon } }
//                                    lineUp({
//                                        alignItems { center }
//                                        justifyContent { spaceBetween }
//                                    }) {
//                                        spacing { normal }
//                                        items {
//                                            span { translation[TL.CardInfo.STATUS].renderText(into = this) }
//                                            invitationStatusButton(
//                                                isActive = true,
//                                                status = status,
//                                                clickable = true,
//                                                colored = true,
//                                                withIcon = true
//                                            )
//                                        }
//                                    }
                                }
                            }
                            clicks.map {
                                mapOf("show" to "meetingState")
                            } handledBy routerStore.addOrReplaceRoute
                        }
                    }
                }
            }
        },
        footer = {
            // CARD BUTTONS FOOTER WITH: [BACK] [EDIT]
            infoCardEditFooter(objectType)
        },
    )
}
