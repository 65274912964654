package twcomponents

import data.objects.views.directediting.directEditingCardContentId
import dev.fritz2.core.HtmlTag
import dev.fritz2.core.RenderContext
import koin.koinCtx
import kotlin.math.abs
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.flow.combine
import maplibreGL.MaplibreMap
import objectrouting.NavigationStore
import objectrouting.NavigationToolToggleStore
import org.w3c.dom.DragEvent
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import org.w3c.dom.MutationObserver
import org.w3c.dom.MutationObserverInit
import org.w3c.dom.TouchEvent
import org.w3c.dom.events.Event
import org.w3c.dom.get
import theme.FormationUIIcons
import utils.isTouchDevice
import utils.resizeObserver

const val draggableCardContentWrapperId = "draggable-card-content-wrapper"

enum class CardSnapPosition(val heightFraction: Double) {
    Bottom(heightFraction = 0.2),
    Half(heightFraction = 0.5),
    Top(heightFraction = 1.0),
    ;

    val value get() = window.innerHeight.toDouble() * heightFraction
}

fun RenderContext.twPullCardWrapper(
    draggableCardElementId: String,
    content: HtmlTag<HTMLDivElement>.() -> Unit
) {

    val maplibreMap: MaplibreMap by koinCtx.inject()
    val navigationStore: NavigationStore by koinCtx.inject()
    val navigationToolToggleStore: NavigationToolToggleStore by koinCtx.inject()

    var isDragging = false
    var initialDragClientY = 0
    var draggingStartedFrom = CardSnapPosition.Bottom

    class YDrag {
        var draggableElement: HTMLElement? = null
        var dragValue: Double = CardSnapPosition.Half.value

        var value: Double
            get() = dragValue
            set(valueToSet) {
                val width = window.innerWidth
                when {
                    width > 786 -> {
                        dragValue = window.innerHeight.toDouble()
                        draggableElement?.style?.height = "100vh"
                        draggableElement?.style?.borderRadius = "unset"
                    }

                    else -> {
                        dragValue = valueToSet
                        draggableElement?.style?.height = "${valueToSet}px"
                        if (valueToSet == CardSnapPosition.Top.value) {
                            draggableElement?.style?.borderRadius = "unset"
                        } else {
                            draggableElement?.style?.borderRadius = "1rem 1rem 0 0"
                        }
                    }
                }
            }
    }

    val yDrag = YDrag()

    val breakPointListener: (Any) -> Unit = {
        if (window.innerWidth > 768 && window.innerHeight.toDouble() != yDrag.value) {
//            console.log("Resize, set height from ${yDrag.value} to ${window.innerHeight}")
            yDrag.value = window.innerHeight.toDouble()
        }

    }

    combine(navigationToolToggleStore.data, navigationStore.data) { enabled, navData ->
        Pair(enabled, navData)
    } handledBy { (enabled, _) ->
        if (enabled) {
            yDrag.value = CardSnapPosition.Bottom.value
        } else {
            yDrag.value = CardSnapPosition.Half.value
        }
    }

    val startListener: (Event) -> Unit = { event ->
        val eventParse = (event as? TouchEvent) ?: event as? DragEvent
//        eventParse?.preventDefault()
        eventParse?.stopPropagation()

        if (yDrag.draggableElement == null) {
            yDrag.draggableElement = document.getElementById(draggableCardElementId) as? HTMLElement
        }

        isDragging = true
        draggingStartedFrom = CardSnapPosition.entries.firstOrNull {
            it.value == yDrag.value
        } ?: CardSnapPosition.Bottom

        // Prevent scrolling while dragging
//        yDrag.draggableElement?.style?.overflowY = "hidden"
        if (draggingStartedFrom == CardSnapPosition.Half || draggingStartedFrom == CardSnapPosition.Bottom) {
            (document.getElementById(draggableCardContentWrapperId) as? HTMLElement)?.style?.overflowY = "hidden"
            (document.getElementById("draggable-card-col") as? HTMLElement)?.style?.overflowY = "hidden"
        }


        val clientY = when (eventParse) {
            is TouchEvent -> eventParse.touches[0]?.clientY
            is DragEvent -> eventParse.clientY
            else -> 0
        }
        initialDragClientY = clientY ?: 0
//        console.log("Start drag. Initial clientY == $clientY")
        console.log("Started dragging from ${draggingStartedFrom.name}.")
    }

    val moveListener: (Event) -> Unit = { event ->
        if (!isDragging) {
            Unit
        } else {
            val eventParse = (event as? TouchEvent) ?: event as? DragEvent
//            eventParse?.preventDefault()
            eventParse?.stopPropagation()

            val initialYDragValue = yDrag.value

            val clientY = when (eventParse) {
                is TouchEvent -> eventParse.touches[0]?.clientY
                is DragEvent -> eventParse.clientY
                else -> null
            }
            var draggingValue = (clientY?.let { initialDragClientY - it } ?: 0).toDouble()

            // set map and card to fixed positions, while moving the card, to smooth out the card pull movement
            // Does break scrolling on iOS!
//            if (abs(draggingValue) > 20) {
//                yDrag.draggableElement?.style?.position = "fixed"
//                (document.getElementById(maplibreMap.targetId) as? HTMLElement)?.let {
//                    it.style.position = "fixed"
//                    it.style.top = "0"
//                    it.style.bottom = when (draggingStartedFrom) {
//                        CardSnapPosition.Bottom, CardSnapPosition.Half -> "${CardSnapPosition.Bottom.value.toInt()}"
//                        else -> "${CardSnapPosition.Half.value.toInt()}"
//                    }
//                }
//            }

            // Adjust dragging relative to where it started
            when (draggingStartedFrom) {
                CardSnapPosition.Top -> {
                    draggingValue += CardSnapPosition.Top.value
                    // don't update value when dragging up, when already in top position
                    if (initialYDragValue > draggingValue) {
                        // FIXME This is a too specific direct sync with scroll top of directEditingCardContent
                        // All Scroll container should be checked here
                        (document.getElementById(directEditingCardContentId) as? HTMLDivElement)?.let { directEditingCardContentDiv ->
                            // check scroll position and don't update yDrag.value when dragging higher than top position to allow scrolling
                            if (directEditingCardContentDiv.clientTop.toDouble() == directEditingCardContentDiv.scrollTop) {
                                yDrag.value = draggingValue
                            }
                        } ?: run {
                            yDrag.value = draggingValue
                        }
                    }
                }

                CardSnapPosition.Half -> {
                    draggingValue += CardSnapPosition.Half.value
                    yDrag.value = draggingValue
                }

                else -> {
                    draggingValue += CardSnapPosition.Bottom.value
                    // don't update value when dragging lower than bottom position
                    if (initialYDragValue < draggingValue) {
                        yDrag.value = draggingValue
                    }
                }
            }
        }
    }

    val endListener: (Any) -> Unit = {
        if (!isDragging) {
            Unit
        } else {
            isDragging = false

            // Snap logic - find drag amount
            val dragAmount = abs(yDrag.value - draggingStartedFrom.value)

            // Snap logic - find drag direction
            val isDraggingUp = yDrag.value - draggingStartedFrom.value > 0

            // Snap to the closest position
            val draggingSnappedTo =
                if (dragAmount > 20) {

                    // Snap logic - find the closest destination
                    val distanceFromBottom = abs(yDrag.value - CardSnapPosition.Bottom.value)
                    val distanceFromHalf = abs(yDrag.value - CardSnapPosition.Half.value)
                    val distanceFromTop = abs(yDrag.value - CardSnapPosition.Top.value)

                    when {
                        // Closest is Bottom position
                        distanceFromBottom <= distanceFromHalf && distanceFromBottom <= distanceFromTop -> {
                            if (isDraggingUp) {
                                CardSnapPosition.Half
                            } else {
                                CardSnapPosition.Bottom
                            }
                        }
                        // Closest is Half position
                        distanceFromHalf <= distanceFromBottom && distanceFromHalf <= distanceFromTop -> {
                            if (isDraggingUp && draggingStartedFrom == CardSnapPosition.Half) {
                                CardSnapPosition.Top
                            } else {
                                CardSnapPosition.Half
                            }
                        }
                        // Closest is Top position
                        else -> {
                            if (isDraggingUp) {
                                CardSnapPosition.Top
                            } else {
                                CardSnapPosition.Half
                            }
                        }
                    }
                } else draggingStartedFrom

            yDrag.value = draggingSnappedTo.value

            console.log("Ended dragging. -> Snapped from $draggingStartedFrom to $draggingSnappedTo.")

            // Restore scrolling after dragging is finished (except in the bottom position)
            if (yDrag.value == CardSnapPosition.Bottom.value) {
//                yDrag.draggableElement?.style?.overflowY = "hidden"
                (document.getElementById(draggableCardContentWrapperId) as? HTMLElement)?.style?.overflowY = "hidden"
                (document.getElementById("draggable-card-col") as? HTMLElement)?.style?.overflowY = "hidden"
            } else {
//                yDrag.draggableElement?.style?.overflowY = "auto"
                (document.getElementById(draggableCardContentWrapperId) as? HTMLElement)?.style?.overflowY = "auto"
                (document.getElementById("draggable-card-col") as? HTMLElement)?.style?.overflowY = "auto"
            }

            // Restore relative positioning of map container and card
            // Not needed, as setting "position" while scrolling breaks scrolling on iOS!
//            yDrag.draggableElement?.style?.position = "relative"
//            (document.getElementById(maplibreMap.targetId) as? HTMLElement)?.let {
//                it.style.position = "relative"
//                it.style.top = "unset"
//                it.style.bottom = "unset"
//            }
        }
    }

    // Side Panel sliding from the left
//    div(
//        baseClass = "fixed top-0 left-0 h-full w-64 bg-white shadow-lg transform -translate-x-full transition-transform duration-300 ease-in-out",
//        id = "side-panel",
//    ) {
//        div("p-4 text-black") {
//            h1 { +"Side Panel Content" }
//            p { +"This is the information inside the side panel." }
//        }
//    }
//
//    val sidePanelVisible = storeOf(false, Job())
//
//    button {
//        +"Toggle Side Panel"
//        clicks.map { !sidePanelVisible.current } handledBy sidePanelVisible.update
//    }
//
//    sidePanelVisible.data.render { isVisible ->
//        val sidePanelElement = document.getElementById("side-panel")
//        sidePanelElement?.classList?.toggle("translate-x-0", isVisible)
//        sidePanelElement?.classList?.toggle("-translate-x-full", !isVisible)
//    }

    div(
        baseClass = "relative flex flex-col grow bottom-0 left-0 right-0 md:right-auto md:h-full min-h-0 w-full md:w-100 max-w-full md:max-w-100 min-w-72 md:min-w-100 -mt-1.5 md:mt-0 bg-formationWhite rounded-t-2xl md:rounded-none shadow-tlHalfCard md:shadow-tlFullCard",
        id = draggableCardElementId,
    ) {
        className(if (isTouchDevice) "transition-none" else "transition-all duration-300 ease-in-out")
        inlineStyle("display:none;")
        MutationObserver { _, mutationObserver ->
            if (document.contains(domNode)) {
                yDrag.draggableElement = domNode
                domNode.style.height = "${CardSnapPosition.Half.value}px"
//                domNode.addEventListener("touchstart", startListener, obj { passive = false })
//                domNode.addEventListener("touchmove", moveListener, obj { passive = false })
//                domNode.addEventListener("touchend", endListener, obj { passive = false })
                touchstarts handledBy startListener
                touchmoves handledBy moveListener
                touchends handledBy endListener
                dragstarts handledBy startListener
                drags handledBy moveListener
                dragends handledBy endListener

                resizeObserver(domNode) handledBy breakPointListener
                mutationObserver.disconnect()
            }
        }.observe(
            document,
            MutationObserverInit(attributes = true, childList = true, characterData = false, subtree = true),
        )

        div(
            baseClass = "flex flex-col w-full h-full overflow-y-auto rounded-t-2xl",
            id = "draggable-card-col",
        ) {
            div(
                baseClass = "sticky top-0 flex min-h-7 md:min-h-0 items-center cursor-pointer md:cursor-unset z-10 rounded-t-2xl focus:bg-gray-200 overflow-hidden",
                id = "snapping-thumb-wrapper",
                // Ensure it's on top of the content
            ) {

                if (isTouchDevice) {
                    div(
                        baseClass = "md:hidden mx-auto rounded-full w-9 h-1 mt-2 bg-gray-200 shadow-[0_0_0_1px_white]", // overflow-hidden
                        id = "snapping-thumb",
                    ) { }
                } else {
                    // Arrow buttons as fallback for small screens, that are no touch devices
                    div("flex flex-row w-full items-stretch justify-center") { // rounded-tl-2xl overflow-hidden
                        className("md:hidden")
                        twFlatButton {
                            className("p-1 w-full") // rounded-tl-2xl
                            twIconMedium(icon = FormationUIIcons.ChevronDown)
                            clicks handledBy {
                                if (yDrag.draggableElement == null) {
                                    yDrag.draggableElement = document.getElementById(draggableCardElementId) as? HTMLElement
                                }
                                yDrag.value = when (yDrag.value) {
                                    CardSnapPosition.Top.value -> CardSnapPosition.Half.value
                                    CardSnapPosition.Half.value -> CardSnapPosition.Bottom.value
                                    else -> CardSnapPosition.Bottom.value
                                }
                            }
                        }
                        twFlatButton {
                            className("p-1 w-full") // rounded-tl-2xl
                            twIconMedium(icon = FormationUIIcons.ChevronUp)
                            clicks handledBy {
                                if (yDrag.draggableElement == null) {
                                    yDrag.draggableElement = document.getElementById(draggableCardElementId) as? HTMLElement
                                }
                                yDrag.value = when (yDrag.value) {
                                    CardSnapPosition.Half.value -> CardSnapPosition.Top.value
                                    else -> CardSnapPosition.Half.value
                                }
                            }
                        }
                    }
                }
            }

            div(
                baseClass = "flex flex-col w-full grow min-h-0 overflow-y-auto",
                id = draggableCardContentWrapperId,
            ) {
//                inlineStyle("-webkit-overflow-scrolling: touch;")
                content.invoke(this)
            }
        }
    }
}
