package mainmenu.termstexts

import dev.fritz2.core.RenderContext
import dev.fritz2.components.compat.div
import mainmenu.textParagraphs
import mainmenu.textPart
import mainmenu.textPartTitle
import mainmenu.textSubPart

fun RenderContext.termsTextDE() {
    div({
        width { full }
    }) {
        textPartTitle("Allgemeine Geschäftsbedingungen") {
            p {
                +"Stand: März 2023"
            }
        }
        textPart("PRÄAMBEL") {
            textParagraphs {
                p {
                    +"Die FORMATION GmbH Urbanstraße 71, 10967 Berlin (nachfolgend: „Anbieter“) bietet den Kunden eine innovative Workforce-Worksite Management Plattform, die sowohl über eine Web-Anwendung als auch über eine App zu erreichen ist (nachfolgend gemeinsam: „Plattform“). Wesenskern der Services ist es, im Rahmen der Funktionalitäten der Plattform, interaktive Karten zu erstellen und diese mit den für die jeweiligen Anwender (nachfolgend: „Nutzer“) relevanten Informationen zu individualisieren und nutzbar zu machen."
                }
                p {
                    +"Die nachfolgenden allgemeinen Nutzungs- und Lizenzbestimmungen (nachfolgend „AGB“) gelten für sämtliche vom Anbieter für die Nutzer zu erbringenden Services:"
                }
            }
        }
        textPart("VERTRAGSGRUNDLAGE", 1) {
            textSubPart(number = 1.1) {
                p {
                    +"Der Anbieter wird für die Nutzer während der Vertragslaufzeit die jeweils nach diesen AGB vereinbarten Services erbringen. Diese sind, sofern nicht abweichend vereinbart, für den Nutzer kostenfrei."
                }
            }
            textSubPart(number = 1.2) {
                p {
                    +"Die vom Anbieter konkret zu erbringenden Leistungen ergeben sich aus der jeweils gültigen Leistungsbeschreibung und im Übrigen aus diesen AGB."
                }
            }
        }
        textPart("LEISTUNGEN DES ANBIETERS", 2) {
            textSubPart(number = 2.1) {
                p {
                    +"Sofern nicht abweichend vereinbart, stellt der Anbieter die Plattform so bereit, wie diese im Zeitpunkt des Vertragsschlusses verfügbar gewesen ist („as is“)."
                }
            }
            textSubPart(number = 2.2) {
                p {
                    +"Der Anbieter ist für das Funktionieren und die Bereitstellung der Plattform verantwortlich. Die Bereitstellung der Plattform wird durch die Zugänglichmachung eines Online-Zugangs (SaaS-Lösung) oder eine App erfüllt. Eine darüberhinausgehende Zugänglichmachung oder Überlassung der Plattform bzw. des zugrundeliegenden Quell- oder Source-Codes ist nicht geschuldet."
                }
            }
            textSubPart(number = 2.3) {
                p {
                    +"Übergabepunkt für das Tool und die Datenpunkte und Datensätze ist der Routerausgang des vom Anbieter genutzten Servers."
                }
            }
        }
        textPart("RECHTE UND PFLICHTEN DER NUTZER", 3) {
            textSubPart(number = 3.1) {
                p {
                    +"Der Nutzer erkennt an, dass der Anbieter als technischer Dienstleister nur die im Rahmen der Ausführung der Services anfallenden Informationen für den jeweiligen Vertragspartner speichert."
                }
            }
            textSubPart(number = 3.2) {
                p {
                    +"Der Nutzer ist zur Geheimhaltung seines Passworts verpflichtet und hat dieses vor unbefugter Benutzung durch Dritte zu schützen. Ist dem Nutzer ein Missbrauch seiner Zugangsdaten bekannt geworden, besteht die Möglichkeit oder droht ein solcher Missbrauch, wird der Nutzer den Anbieter unverzüglich hierüber informieren. Für eine missbräuchliche Nutzung seines Profils haftet der Nutzer; dies gilt nicht, wenn der Nutzer die missbräuchliche Nutzung nicht zu vertreten hat."
                }
            }
            textSubPart(number = 3.3) {
                p {
                    +"Die vom Nutzer über die Plattform gespeicherten Inhalte können urheber- und datenschutzrechtlich geschützt sein. Der Nutzer räumt dem Anbieter hiermit das Recht ein, die so auf dem jeweiligen Server abgelegten Inhalte zur Erfüllung der Vertragszwecke zu vervielfältigen und zu übermitteln, für die Verbesserung der Services zu analysieren und zum Zwecke der Datensicherung zu vervielfältigen."
                }
            }
            textSubPart(number = 3.4) {
                p {
                    +"Der Nutzer unterstützt den Anbieter bei der Erfüllung seiner vertraglich geschuldeten Leistungen und erbringt alle zur Vertragsdurchführung erforderlichen Mitwirkungshandlungen vollständig, unaufgefordert, rechtzeitig und auf eigene Kosten."
                }
            }
            textSubPart(number = 3.5) {
                textParagraphs {
                    p {
                        +"Für die Einhaltung der gesetzlichen Vorschriften hinsichtlich vom Nutzer eingestellten Inhalte ist dieser selbst verantwortlich. Der Nutzer wird hierbei das geltende Recht beachten und die Rechte Dritter wahren. Dem Nutzer ist es insbesondere untersagt,"
                    }
                    ul {
                        li {
                            +"Schutzrechte Dritter wie Marken, Urheber-, Namens-, Persönlichkeits-, oder Datenschutzrechte zu verletzen sowie"
                        }
                        li {
                            +"über die auf der Plattform vom Anbieter bereitgestellten Funktionalitäten und Schnittstellen hinausgehende Mechanismen, Software und/oder Skripte einzusetzen, insbesondere wenn hierdurch die Plattform oder die Systeme des Anbieters blockiert, modifiziert, kopiert und/oder überschrieben."
                        }
                    }
                }

            }
            textSubPart(number = 3.6) {
                p {
                    +"Der Anbieter darf nach billigem Ermessen (§ 315 BGB) unter Beachtung der Interessen des Nutzers eine oder mehrere Sanktionen verhängen, wenn die vom Nutzer eingestellten Inhalte gegen diese AGB verstoßen hat oder ein wichtiger Grund vorliegt. Dies ist insbesondere dann anzunehmen, wenn er Nutzer schuldhaft gegen die Bestimmungen von 4.5 verstößt. Die Schwere der Sanktionen richtet sich dabei nach der Schwere der jeweiligen Zuwiderhandlung. Die Sanktion bleibt solange bestehen, bis die sanktionierte Handlung von dem jeweiligen Nutzer abgestellt und eine etwaige Wiederholungsgefahr ausgeräumt ist."
                }
            }
            textSubPart(number = 3.7) {
                p {
                    +"Als Sanktionen kommen in Betracht: (i) die Deaktivierung oder Sperrung einzelner Dienstleistungen oder Inhalte für den Nutzer (ii) die Löschung der von dem Nutzer eingestellten Inhalte, (iii) die vollständige Sperrung des Zugangs zur Plattform einschließlich des Profils sowie (iv) die vollständige Löschung eines Accounts. Das Recht zur außerordentlichen Kündigung aus wichtigem Grund durch den Anbieter bleibt hiervon ebenso unberührt wie die Geltendmachung von weitergehenden Ansprüchen."
                }
            }
            textSubPart(number = 3.8) {
                p {
                    +"Ist oder wird für den Nutzer erkennbar, dass im Rahmen der gesamten Vertragsbeziehung der Parteien Annahmen, Vorgaben und Angaben fehlerhaft, unvollständig oder nicht eindeutig sind oder deren Ausführung unmöglich ist oder wird, wird der Nutzer dem Anbieter diesen Umstand unverzüglich mitteilen."
                }
            }
        }
        textPart("NUTZUNGSRECHTE", 4) {
            textSubPart(number = 4.1) {
                p {
                    +"Der Anbieter räumt dem Nutzer das weltweite, nicht-ausschließliche und inhaltlich unbeschränkte sowie zeitlich auf das jeweilige Vertragsverhältnis mit dem Anbieter beschränkte Recht ein, die Plattform und – sofern zutreffend – die individuell erstellte Karte im Rahmen der jeweils verfügbaren Funktionalitäten zu nutzen. Eine auf Gewinnerzielung Unterlizensierung oder sonstige Überlassung oder Bereitstellung an Dritte ist nicht gestattet."
                }
            }
            textSubPart(number = 4.2) {
                p {
                    +"Nutzer sind nicht berechtigt die Plattform zu bearbeiten, insbesondere abzuändern, zu übersetzen, zurück und weiter zu entwickeln, zu dekompilieren oder zu disassemblieren und umzugestalten, sofern nicht in dieser Ziff. 4 gestattet oder ein gesetzlicher Erlaubnistatbestand vorliegt."
                }
            }
            textSubPart(number = 4.3) {
                p {
                    +"Eine Umarbeitung ist zulässig, wenn sie für die Beseitigung eines Mangels notwendig ist und sich der Anbieter mit der Berichtigung des Mangels in Verzug befindet, der Anbieter die Mängelbeseitigung unberechtigt ablehnt oder aus sonstigen, seinem Verantwortungsbereich zuzurechnenden Gründen zur unverzüglichen Mängelbeseitigung außerstande ist."
                }
            }
            textSubPart(number = 4.4) {
                p {
                    +"Die Dekompilierung der Plattform ist nur zulässig, wenn die in § 69 e Abs. 1 UrhG genannten Voraussetzungen und Bedingungen vorliegen. Die hierdurch gewonnenen Informationen dürfen nicht entgegen den Maßgaben von § 69 e Abs. 2 UrhG verwendet bzw. weitergegeben werden."
                }
            }
            textSubPart(number = 4.5) {
                p {
                    +"Die Einräumung von Nutzungsrechten, die ganz und teilweise Dritten zustehen, ist von Anbieter nicht geschuldet, sofern dies nicht ausdrücklich vereinbart ist."
                }
            }
        }
        textPart("LAUFZEIT UND KÜNDIGUNG", 5) {
            textSubPart(number = 5.1) {
                p {
                    +"Das Vertragsverhältnis beginnt im Zeitpunkt des Vertragsschlusses und läuft für unbestimmte Zeit."
                }
            }
            textSubPart(number = 5.2) {
                p {
                    +"Das Vertragsverhältnis kann von den Vertragsparteien jederzeit ohne die Einhaltung einer Frist und ohne die Angabe von Gründen ordentlich gekündigt werden."
                }
            }
        }
        textPart("HAFTUNG UND GEWÄHRLEISTUNG", 6) {
            textSubPart(number = 6.1) {
                p {
                    +"Die Parteien haften einander für von ihnen, ihren gesetzlichen Vertretern oder Erfüllungsgehilfen oder von ihnen eingeschalteten Dritten, insbesondere Subunternehmern, verursachte unmittelbare und mittelbare Sach- und Vermögensschäden nach Maßgabe der nachfolgenden Bestimmungen."
                }
            }
            textSubPart(number = 6.2) {
                p {
                    +"Der Anbieter haftet – außer bei Verletzung wesentlicher Vertragspflichten, bei Verletzung von Leben, Körper oder Gesundheit oder bei Ansprüchen aus dem Produkthaftungsgesetz – nur für Vorsatz und grobe Fahrlässigkeit. Wesentliche Vertragspflichten sind solche, deren Erfüllung zur Erreichung des Vertragszweckes notwendig ist. Im Übrigen gelten die Haftungsmaßstäbe der §§ 521ff. BGB."
                }
            }
            textSubPart(number = 6.3) {
                p {
                    +"Der Anbieter haftet nicht für Schäden, die auf Grund technischer Störungen oder Leistungsstörungen des Nutzers oder anderer Dritter entstehen. Der Anbieter haftet auch nicht für Schäden, die der Nutzer durch diesem zumutbare Maßnahmen, insbesondere regelmäßige Programm- und Datensicherung nach Maßgabe von Ziff. 3.5 hätte verhindern können. Die Haftung für Schäden, die durch Systemausfälle, Hackerangriffe oder sonstige von außen stattfindenden Angriffen entstehen, ist ausgeschlossen. Die verschuldensunabhängige Schadensersatzhaftung für Mängel, insbesondere nach § 536a BGB, die bereits bei Vertragsschluss vorlagen, ist ausgeschlossen."
                }
            }
            textSubPart(number = 6.4) {
                p {
                    +"Diese Ansprüche richten sich im Übrigen nach den gesetzlichen Bestimmungen."
                }
            }
        }
        textPart("DATENSCHUTZ UND COMPLIANCE", 7) {
            textSubPart(number = 7.1) {
                p {
                    +"Der Nutzer stellt in eigener Verantwortung sicher, dass im Rahmen der Vertragsbeziehung alle Compliance Anforderungen erfüllt und - sofern hierbei personenbezogene Daten verarbeitet werden - die Bestimmungen des Datenschutzes (DSGVO, BDSG, TTDSG, usw.) rechtskonform umgesetzt und fortlaufend beachtet werden. Zur Gewährleistung der Sicherheit und Vertraulichkeit der Daten, die dem Datenschutz unterliegen, wird der Nutzer insbesondere die erforderlichen technischen und organisatorischen Maßnahmen treffen und aufrechterhalten."
                }
            }
            textSubPart(number = 7.2) {
                p {
                    +"Für den Fall, dass der Anbieter im Rahmen des Vertragsverhältnisses als Auftragsverarbeiter nach Maßgabe von Art. 28 DSGVO tätig wird, so gilt hierfür die Zusatzvereinbarung über die Auftragsdatenvereinbarung. Im Fall von Widersprüchen zwischen diesem Vertrag und der Vereinbarung über die Auftragsdatenverarbeitung, geht Letztere Ersterem vor."
                }
            }
        }
        textPart("SCHLUSSBESTIMMUNGEN", 8) {
            textSubPart(number = 8.1) {
                p {
                    +"Auf die gesamte Vertragsbeziehung der Parteien sowie auf die von den Parteien hierunter erbrachten Leistungen, findet ausschließlich das Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts und des Kollisionsrechts Anwendung; Art. 3 EGBGB bleibt unberührt."
                }
            }
            textSubPart(number = 8.2) {
                p {
                    +"Im Verhältnis zu Unternehmern i.S.d. § 14 BGB gilt der Sitz des Anbieters als Erfüllungsort und Gerichtsstand für die beidseitigen Ansprüche aus der Vertragsbeziehung, soweit nichts anderes bestimmt ist. Im Verhältnis zu Unternehmern ist der Anbieter berechtigt, diese an deren allgemeinen Gerichtsstand gerichtlich in Anspruch zu nehmen."
                }
            }
            textSubPart(number = 8.3) {
                p {
                    +"Sollten einzelne Bestimmungen dieser Lizenzbestimmungen unwirksam sein oder die Wirksamkeit durch einen später eintretenden Umstand verlieren, bleibt die Wirksamkeit des jeweiligen Vertrags im Übrigen unberührt. Dasselbe gilt für Lücken dieser AGB."
                }
            }
        }
    }
}
