package data.connectableshapes

import apiclient.geoobjects.ConnectableGeoShapeChange
import apiclient.geoobjects.Connection
import apiclient.geoobjects.Connector
import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.ObjectChanges
import data.ObjectAndUserHandler
import data.objects.ActiveObjectStore
import dev.fritz2.core.RootStore
import koin.koinCtx
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.map
import maplibreGL.MaplibreMap
import model.L
import overlays.BusyStore
import search.searchlayer.MapSearchResultsStore

class ActiveSourceConnectorStore : RootStore<Connector?>(
    initialData = null,
    job = Job(),
)

class ActiveTargetConnectorStore : RootStore<Pair<GeoObjectDetails?, Connector?>>(
    initialData = Pair(null, null),
    job = Job(),
)

class DeleteConnectionConnectorIdStore : RootStore<String?>(
    initialData = null,
    job = Job(),
) {

    val activeObjectStore: ActiveObjectStore by koinCtx.inject()
    val busyStore: BusyStore by koinCtx.inject()
    val maplibreMap: MaplibreMap by koinCtx.inject()

    val selectConnectionByUniqueId = handle<String> { current, selectedUniqueConnectionId ->
        console.log("SELECTED CONNECTION", selectedUniqueConnectionId)
//        val idA = selectedUniqueConnectionId.split(";")[0]
//        val objIdA = idA.split(":")[0]
//        val idB = selectedUniqueConnectionId.split(";")[1]
//        val objIdB = idB.split(":")[0]
//
//        val activeObjectId = activeObjectStore.current.id
//
//        when {
//            activeObjectId == objIdA && idA != current -> idA
//            activeObjectId == objIdB && idB != current -> idB
//            else -> null
//        }
        if (selectedUniqueConnectionId == current) {
            null
        } else {
            selectedUniqueConnectionId
        }
    }

    private val refreshConnections = handle<String?> { _, data ->
        maplibreMap.syncMarkersNow()
        data
    }

    val disconnect = handle { currentUniqueConnectionId ->
        if (currentUniqueConnectionId != null) {
            console.log("Disconnect!", currentUniqueConnectionId.toString())
            val (objIdA, connectorIdA) = currentUniqueConnectionId.split(";")[0].split(":")
            val (objIdB, connectorIdB) = currentUniqueConnectionId.split(";")[1].split(":")

            when (activeObjectStore.current.id) {
                objIdA -> connectorIdA
                objIdB -> connectorIdB
                else -> null
            }?.let { connectorId ->
                activeObjectStore.applyObjectChanges(
                    ObjectChanges(
                        activeObjectStore.current.id,
                        ConnectableGeoShapeChange.DisConnectShape(
                            connectorId = connectorId,
                        ),
                    ),
                )
            }
        }
        null
    }

    init {
        data handledBy refreshConnections
    }
}

class NewConnectableShapeConnectionStore : RootStore<Connection>(
    initialData = EMPTY_CONNECTION,
    job = Job(),
) {
    val busyStore: BusyStore by koinCtx.inject()
    val activeObjectStore: ActiveObjectStore by koinCtx.inject()
    val objectAndUserHandler: ObjectAndUserHandler by koinCtx.inject()
    private val mapSearchResultsStore: MapSearchResultsStore by koinCtx.inject()
    val maplibreMap: MaplibreMap by koinCtx.inject()
    private val activeSourceConnectorStore: ActiveSourceConnectorStore by koinCtx.inject()
    private val activeTargetConnectorStore: ActiveTargetConnectorStore by koinCtx.inject()
    private val activeObjectId = activeObjectStore.map(GeoObjectDetails.L.id)

    val markObjAsActive = handle<GeoObjectDetails> { current, sourceObj ->
        maplibreMap.addActiveObjectOverride(sourceObj)
        maplibreMap.syncMarkersNow()
//        maplibreMap.geoObjectAddOrUpdate(sourceObj, clustered = true)
        current
    }

    val handleConnectorById = handle<String> { current, connectorId ->
        val objId = connectorId.split(":")[0]
        if (objId == activeObjectStore.current.id) {
            updateSourceConnectorById(connectorId)
        } else {
            updateTargetConnectorById(connectorId)
        }
        current
    }

    val updateSourceConnector = handle<Pair<GeoObjectDetails, Connector?>> { current, (sourceObject, sourceConnector) ->
        if (sourceConnector != null) {
            activeSourceConnectorStore.update(sourceConnector)
            markObjAsActive(sourceObject)
            current.copy(sourceConnectorId = "${sourceObject.id}:${sourceConnector.id}")
        } else current
    }

    val updateSourceConnectorById = handle<String> { current, sourceConnectorId ->
        mapSearchResultsStore.current.values.firstOrNull {
            it.id == sourceConnectorId.split(":")[0]
        }?.geoReferencedConnectableObject?.original?.connectors?.firstOrNull {
            it.id == sourceConnectorId.split(":")[1]
        }?.let { sourceConnector ->
            activeSourceConnectorStore.update(sourceConnector)
            activeTargetConnectorStore.update(Pair(null, null))
        }
        current.copy(sourceConnectorId = sourceConnectorId, targetConnectorId = "", targetMarkerId = "")
    }

    val updateTargetConnector =
        handle<Pair<GeoObjectDetails?, Connector?>> { current, (targetObject, targetConnector) ->
            if (targetObject != null && targetConnector != null) {
                activeTargetConnectorStore.update(Pair(targetObject, targetConnector))
                current.copy(
                    targetConnectorId = "${targetObject.id}:${targetConnector.id}",
                    targetMarkerId = targetObject.id,
                )
            } else current
        }

    val updateTargetConnectorById = handle<String> { current, targetConnectorId ->
        mapSearchResultsStore.current.mapNotNull { (id, obj) ->
            if (id != activeObjectStore.current.id) {
                obj.geoReferencedConnectableObject?.original?.connectors?.let { obj to it }
            } else null
        }.toMap().flatMap { connectorsOfShape ->
            connectorsOfShape.value.map { connector ->
                connectorsOfShape.key to connector
            }
        }.firstOrNull { (targetObject, connector) ->
            targetConnectorId.split(":")[0] == targetObject.id && targetConnectorId.split(":")[1] == connector.id
        }?.let { targetPair ->
            activeTargetConnectorStore.update(targetPair)
        }
        current.copy(targetConnectorId = targetConnectorId, targetMarkerId = targetConnectorId.split(":")[0])
    }

    val connect = handle { currentConnection ->
        console.log("Connect!", currentConnection.toString())
        activeObjectStore.applyObjectChanges(
            ObjectChanges(
                activeObjectStore.current.id,
                ConnectableGeoShapeChange.ConnectShape(
                    sourceConnectorId = currentConnection.sourceConnectorId.split(":")[1],
                    targetMarkerId = currentConnection.targetMarkerId,
                    targetConnectorId = currentConnection.targetConnectorId.split(":")[1],
                ),
            ),
        )
        EMPTY_CONNECTION
    }

    private val syncMap = handle { current ->
        maplibreMap.syncMarkersNow()
        current
    }

    val resetTarget = handle { current ->
        activeTargetConnectorStore.update(Pair(null, null))
        current.copy(targetConnectorId = "", targetMarkerId = "")
    }

    val reset = handle {
        activeSourceConnectorStore.update(null)
        activeTargetConnectorStore.update(Pair(null, null))
        EMPTY_CONNECTION
    }

    companion object {
        val EMPTY_CONNECTION = Connection("", "", "", "")
    }

    init {
        data.map { console.log("CONNECTION: $it") } handledBy syncMap
    }
}
