package twcomponents

import dev.fritz2.core.HtmlTag
import dev.fritz2.core.RenderContext
import kotlinx.dom.addClass
import markdown.MarkdownService
import org.w3c.dom.HTMLDivElement

val mdService by lazy {
    // use lazy initi to avoid repeated lookups in koin
    MarkdownService()
}

/**
 * Renders the string as markdown in a markdown_content div. The markdown_content class triggers styling we need.
 */
fun RenderContext.twMarkdownContent(content: String, block: (HtmlTag<HTMLDivElement>.() -> Unit)? = null) {
    // we need some custom css rules to ensure the innerHTML looks alright
    div("markdown-content") {
        domNode.innerHTML = mdService.markdown2html(content)
        block?.invoke(this)
    }
}
