package search.nestedObjects

import data.objects.ActiveObjectStore
import dev.fritz2.core.RootStore
import koin.koinCtx
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.map
import model.SearchPage

class SearchPageStore : RootStore<SearchPage>(
    initialData = SearchPage(),
    job = Job(),
) {

    val activeObjectStore: ActiveObjectStore by koinCtx.inject()

    val loadDefaultStepMore = handle { current ->
        with(current.copy()) {
            SearchPage(from = from + size)
        }
    }

    val reset = handle {
        SearchPage()
    }

    init {
        activeObjectStore.data.map { } handledBy reset
    }
}
