package objectrouting

import apiclient.geoobjects.GeoObjectDetails
import dev.fritz2.core.RootStore
import koin.koinCtx
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.map
import location.GeoPositionStore
import location.geolocation.latLon
import map.MapStateStore
import model.destinationLatLon
import model.destinationPointSource
import model.originLatLon
import model.originPointSource
import utils.Quintuple
import utils.ToggleStore

class NavigationToolToggleStore : ToggleStore()


class NavigationSearchHistoryStore : RootStore<Set<GeoObjectDetails>>(emptySet(), Job()) {
    val add = handle<GeoObjectDetails> { current, obj ->
        console.log("Add Object to history", obj.title, current.map { it.title }.toString())
        (setOf(obj) + current).take(5).toSet()
    }
    val updateEntry = handle<GeoObjectDetails?> { current, newObj ->
        if (newObj != null) {
            current.map { if (it.id == newObj.id) newObj else it }.toSet()
        } else current
    }

    val clear = handle { emptySet() }
}


class NavigationStore : RootStore<NavigationData>(initialData = NavigationData(), Job()) {

    private val lastKnownPositionStore: LastKnownPositionStore by koinCtx.inject()
    private val geoPositionStore: GeoPositionStore by koinCtx.inject()
    private val mapStateStore: MapStateStore by koinCtx.inject()

    val reset = handle { NavigationData() }

    val setOriginObjectWithSourceAndLatLon = handle<GeoObjectDetails?> { current, geoObject ->
        current.copy(
            originPointSource = geoObject?.let { NavigationPointSource.OtherObject },
            originLatLon = geoObject?.latLon,
            originObject = geoObject,
        )
    }

    val setDestinationObjectWithSourceAndLatLon = handle<GeoObjectDetails?> { current, geoObject ->
        current.copy(
            destinationPointSource = geoObject?.let { NavigationPointSource.OtherObject },
            destinationLatLon = geoObject?.latLon,
            destinationObject = geoObject,
        )
    }

    val reverse = handle { current ->
        NavigationData(
            originPointSource = current.destinationPointSource,
            originLatLon = current.destinationLatLon,
            originObject = current.destinationObject,
            destinationPointSource = current.originPointSource,
            destinationLatLon = current.originLatLon,
            destinationObject = current.originObject,
        )
    }

    init {
        // update origin/destination latLon directly, depending on the selected origin pointSource
        combine(
            map(NavigationData.originPointSource()).data,
            map(NavigationData.destinationPointSource()).data,
            lastKnownPositionStore.data.map { it?.latLon },
            geoPositionStore.data.map { it?.latLon() },
            mapStateStore.data.map { it?.center },
        ) { originSrc, destinationSrc, lastKnown, gps, map ->
            Quintuple(originSrc, destinationSrc, lastKnown, gps, map)
        } handledBy { (originSource, destinationSource, lastKnownLatLon, gpsLatLon, mapCenter) ->

            val originLatLonStore = map(NavigationData.originLatLon())
            val destinationLatLonStore = map(NavigationData.destinationLatLon())

            when (originSource) {
                NavigationPointSource.LastKnownLocation -> {
                    originLatLonStore.update(lastKnownLatLon)
                }

                NavigationPointSource.YourLocation -> {
                    originLatLonStore.update(gpsLatLon)
                }

                NavigationPointSource.MapCenter -> {
                    originLatLonStore.update(mapCenter)
                }

                else -> {}
            }

            when (destinationSource) {
                NavigationPointSource.LastKnownLocation -> {
                    destinationLatLonStore.update(lastKnownLatLon)
                }

                NavigationPointSource.YourLocation -> {
                    destinationLatLonStore.update(gpsLatLon)
                }

                NavigationPointSource.MapCenter -> {
                    destinationLatLonStore.update(mapCenter)
                }

                else -> {}
            }
        }
    }
}
