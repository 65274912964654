package map.views.workplacetools

import apiclient.analytics.customFieldClause
import apiclient.geoobjects.ObjectTags
import apiclient.geoobjects.ObjectType
import apiclient.geoobjects.SearchQueryContext
import apiclient.tags.tag
import auth.ApiUserStore
import dev.fritz2.core.RootStore
import koin.koinCtx
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.mapNotNull
import model.fieldValues
import model.getGroupIdsOrNull
import model.groupIds
import model.text

class ArchetypeSearchContextStore : RootStore<SearchQueryContext>(
    initialData = SearchQueryContext(
        objectTypes = listOf(ObjectType.GeneralMarker),
        tags = listOf(ObjectTags.IsArchetype.tag("true"))
    ),
    job = Job(),
) {
    private val apiUserStore: ApiUserStore by koinCtx.inject()
    private val archetypeSearchInputFieldStore: ArchetypeSearchInputFieldStore by koinCtx.inject()
    private val activeArchetypeSearchKeywordsStore: ActiveArchetypeSearchKeywordsStore by koinCtx.inject()
    private val activeArchetypeSearchFieldValuesStore: ActiveArchetypeSearchFieldValuesStore by koinCtx.inject()
    private val ctxGroupIds = this.map(SearchQueryContext.groupIds())
    private val ctxText = this.map(SearchQueryContext.text())
    private val ctxFieldValues = this.map(SearchQueryContext.fieldValues())

    val reset = handle { _ ->
        SearchQueryContext()
    }

    private val updateTags = handle<List<String>> { current, newTags ->

        val newVal = current.copy(tags = listOf(ObjectTags.IsArchetype.tag("true")) + newTags.map { ObjectTags.Keyword.tag(it) })
        console.warn("ArchetypeSearchCtx tags:", newVal.tags.toString())
        newVal
    }

    init {
        apiUserStore.data.mapNotNull { it.getGroupIdsOrNull() } handledBy ctxGroupIds.update
        archetypeSearchInputFieldStore.data handledBy ctxText.update
        activeArchetypeSearchKeywordsStore.data handledBy updateTags
        activeArchetypeSearchFieldValuesStore.data.map { fieldValues ->
            fieldValues.map { it.customFieldClause }
        } handledBy ctxFieldValues.update
    }
}
