package maplibreGL

import apiclient.geoobjects.Connection
import apiclient.geoobjects.Content
import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.LatLon
import apiclient.geoobjects.MarkerColor
import apiclient.geoobjects.MarkerIcon
import apiclient.geoobjects.MarkerShape
import apiclient.geoobjects.ObjectTags
import apiclient.geoobjects.ObjectType
import apiclient.geoobjects.connectableObjectExclusionZoneGeometry
import apiclient.geoobjects.connectableObjectGeometry
import apiclient.geoobjects.geometry
import apiclient.tags.getTagValues
import apiclient.tags.getUniqueTag
import apiclient.validations.parseEnumValue
import auth.ApiUserStore
import auth.FeatureFlagStore
import auth.Features
import com.jillesvangurp.geo.GeoGeometry
import com.jillesvangurp.geojson.Geometry
import com.jillesvangurp.geojson.GeometryType
import com.jillesvangurp.geojson.polygonGeometry
import com.jillesvangurp.serializationext.DEFAULT_JSON
import data.objects.objecthistory.changeType
import data.objects.views.cardinfo.getColorForOccupancy
import data.objects.views.cardinfo.getCustomFieldMap
import data.objects.views.cardinfo.numberColor
import data.users.ActiveUserStore
import data.users.UserListStore
import data.users.profile.MyProfileStore
import data.users.settings.LocalSettingsStore
import data.users.settings.zoneTypeColorMap
import data.users.settings.zoneTypeIconMap
import data.users.settings.zoneTypeShapeMap
import dev.fritz2.routing.MapRouter
import koin.koinCtx
import kotlin.collections.Map
import kotlin.js.Json
import kotlin.js.json
import kotlinx.serialization.Serializable
import kotlinx.serialization.builtins.ListSerializer
import localization.TL
import localization.Translation
import location.LocationFollowStore
import location.LocationUploadStore
import location.geolocation.GeoPosition
import map.views.MapStyleOnline
import model.LocalSettings
import model.User
import model.hideMarkerTitles
import notifications.GlobalNotificationResultsStore
import org.w3c.dom.HTMLElement
import svgmarker.MarkerSize
import svgmarker.SvgIconOptions
import svgmarker.areaMarkerSvgIconOptions
import svgmarker.areaSize
import svgmarker.buildingSize
import svgmarker.buildingSvgIconOptions
import svgmarker.connectableShapeSvgIconOptions
import svgmarker.defaultMarkerSize
import svgmarker.eventSize
import svgmarker.eventSvgIconOptions
import svgmarker.geoFenceMarkerSvgIconOptions
import svgmarker.geoFenceSize
import svgmarker.historyPathMarkerSize
import svgmarker.historyPathMarkerSvgIconOptions
import svgmarker.myUserSize
import svgmarker.myUserSvgIconOptions
import svgmarker.objectMarkerSvgIconOptions
import svgmarker.objectSize
import svgmarker.pointSize
import svgmarker.pointSvgIconOptions
import svgmarker.taskSize
import svgmarker.taskSvgIconOptions
import svgmarker.userSvgIconOptions
import svgmarker.zoneMarkerSvgIconOptions
import svgmarker.zoneSize
import theme.FormationColors
import utils.cutString
import utils.getColor
import utils.getColorForIcon
import utils.getColorForStatus
import utils.getIcon
import utils.isMobileOrTabletBrowser
import utils.jsApply
import utils.obj
import utils.roundTo
import websocket.MarkerClientStore

data class Event(
    val type: String,
    val target: dynamic,
    val sourceTarget: dynamic,
    val propagatedFrom: dynamic,
    val layer: dynamic
)

private const val mapLibreKey = "JVdYkhNXPAbL0vrP2Ble"

data class MaplibreMapOptions(
    val containerId: String? = null,
    val containerElement: HTMLElement? = null,
    val center: LngLat,
    val zoom: Double,
    val minZoom: Double = 0.0,
    val maxZoom: Int = 24,
    val minPitch: Double = 0.0,
    val maxPitch: Double = 60.0,
    val style: dynamic = MapStyleOnline.MapTilerBasic.url,
    val hash: Boolean = false,
    val interactive: Boolean = true,
    val bearingSnap: Double = 7.0,
    val pitchWithRotate: Boolean = true,
    val clickTolerance: Int = 3,
    val attributionControl: Boolean = false,
    val customAttribution: String? = null,
    val maplibreLogo: Boolean = false,
    val logoPosition: String? = null, //top-left , top-right , bottom-left , bottom-right
    val failIfMajorPerformanceCaveat: Boolean = false,
    val preserveDrawingBuffer: Boolean = false,


    val accessToken: String? = null,
    val locale: dynamic = null,

    )

// data class LngLat(val lng: Double, val lat: Double)
fun LngLat.toArray() = arrayOf(lng, lat)
fun LngLat.toLatLon() = LatLon(lat = lat, lon = lng)
fun LatLon.toLngLat(): LngLat = LngLat(lng = lon, lat = lat)

fun MaplibreMapOptions.toJs(): dynamic {
    return obj {
        if (containerId != null || containerElement != null) this.container = containerId ?: containerElement
        this.center = center // .toArray()
        this.zoom = zoom
        this.minZoom = minZoom
        this.maxZoom = maxZoom
        this.minPitch = minPitch
        this.maxPitch = maxPitch
        this.style = style
        this.hash = hash
        this.interactive = interactive
        this.bearingSnap = bearingSnap
        this.pitchWithRotate = pitchWithRotate
        this.clickTolerance = clickTolerance
        this.attributionControl = attributionControl
        if (customAttribution != null) this.customAttribution = customAttribution
        this.maplibreLogo = maplibreLogo
        if (logoPosition != null) this.logoPosition = logoPosition //top-left , top-right , bottom-left , bottom-right
        this.failIfMajorPerformanceCaveat = failIfMajorPerformanceCaveat
        this.preserveDrawingBuffer = preserveDrawingBuffer
        // ...
        // ...
        this.accessToken = accessToken
        this.locale = locale
    }
}

data class MaplibreMarker(
    override val type: ObjectType,
    override val id: String,
    val lnglat: LngLat,
    val geometry: Geometry?,
    val htmlPopup: String? = null,
    val htmlTooltip: String? = null,
    val svgIconOptions: SvgIconOptions,
    val markerOptions: MaplibreMarkerOptions,
//    val active: Boolean,
    val title: String? = null,
    val color: MarkerColor? = null,
    val icon: MarkerIcon? = null,
    val shape: MarkerShape? = null,
    val hasNotification: Boolean = false,
    val archived: Boolean = false,
    val flagged: Boolean = false,
    val isSharing: Boolean = false,
    val stateColor: String? = null,
    val stateIcon: String? = null,
    val isActive: Boolean = false,
    val showTitle: Boolean = false,
    val attachmentCount: Int = 0,
) : MaplibreElement

data class MaplibreMarkerOptions(
    val element: HTMLElement? = null,
    val anchor: String = "center", //Options are 'center' , 'top' , 'bottom' , 'left' , 'right' , 'top-left' , 'top-right' , 'bottom-left' , and 'bottom-right'
    val offset: Array<Int>? = null, //The offset in pixels as a PointLike object to apply relative to the element's center. Negatives indicate left and up.
    val color: String = "#3FB1CE",
    val scale: Int = 1,
    val draggable: Boolean = false,
    val clickTolerance: Int = 0,
    val rotation: Double = 0.0,
    val pitchAlignment: String = "auto", //"map" aligns the Marker to the plane of the map. "viewport" aligns the Marker to the plane of the viewport. "auto" automatically matches the value of rotationAlignment
    val rotationAlignment: String = "auto", //"map" aligns the Marker 's rotation relative to the map, maintaining a bearing as the map rotates. "viewport" aligns the Marker 's rotation relative to the viewport, agnostic to map rotations. "auto" is equivalent to "viewport".
) {

    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other == null || this::class.js != other::class.js) return false

        other as MaplibreMarkerOptions

        if (offset != null) {
            if (other.offset == null) return false
            if (!offset.contentEquals(other.offset)) return false
        } else if (other.offset != null) return false

        return true
    }

    override fun hashCode(): Int {
        return offset?.contentHashCode() ?: 0
    }
}

fun MaplibreMarkerOptions.toJs(): dynamic {
    return obj {
        if (element != null) this.element = element
        this.anchor = anchor
        this.offset = offset
        this.color = color
        this.scale = scale
        this.draggable = draggable
        this.clickTolerance = clickTolerance
        this.rotation = rotation
        this.pitchAlignment = pitchAlignment
        this.rotationAlignment = rotationAlignment
    }
}

data class ClusterProperties(
    val objectTypes: kotlin.collections.Map<ObjectType, Int>,
    val colors: kotlin.collections.Map<FormationColors, Int>,
)

interface Clusterable {
    fun key(): Int
}

sealed interface MapMarker : Clusterable {
    val id: String
    val lngLat: LngLat
    val objectType: ObjectType
    val title: String
    val showTitle: Boolean
    val svgIconOptions: SvgIconOptions?
    val geoJSONs: List<MaplibreGeoJSON>
    val imageOverlays: List<MaplibreImageOverlayRotated>
    val isActive: Boolean
    val isDesaturated: Boolean
    val isHighlighted: Boolean
    val bitmapImage: String?

    val bgColor: FormationColors? get() = svgIconOptions?.bgColor

    override fun key(): Int {
        return id.hashCode()
    }
}

abstract class MapMarkerGeoObject(
    //TODO: look up from cache or database instead
//    protected val geoObject: GeoObjectDetails,
    geoObject: GeoObjectDetails,
    showTitleOverride: Boolean? = null,
) : MapMarker {
    override val id: String = geoObject.id
    override val lngLat: LngLat = geoObject.latLon.toLngLat()
    override val objectType: ObjectType = geoObject.objectType
    override val title: String = geoObject.title

    override val geoJSONs: List<MaplibreGeoJSON> = emptyList()
    override val imageOverlays: List<MaplibreImageOverlayRotated> = emptyList()

    override val showTitle = showTitleOverride ?: !localSettingsStore.map(LocalSettings.hideMarkerTitles()).current

    override val bitmapImage = if (featureFlagStore.current[Features.AllowBitmapMarkerIcons] == true) {
        (geoObject.attachments?.firstOrNull { it is Content.Icon } as? Content.Icon)?.href
    } else null

    protected val hasUnreadNotifications get() = globalNotificationResultsStore.isOrHasUnreadNotification(objectType, id)
    protected val archived = geoObject.tags.getUniqueTag(ObjectTags.Archived).toBoolean()
    protected val flagged = geoObject.tags.getUniqueTag(ObjectTags.Flagged).toBoolean()

    protected val color = geoObject.color
    protected val icon = geoObject.iconCategory
    protected val shape = geoObject.shape

    companion object {
        private val localSettingsStore: LocalSettingsStore by koinCtx.inject()
        private val globalNotificationResultsStore: GlobalNotificationResultsStore by koinCtx.inject()
        private val featureFlagStore: FeatureFlagStore by koinCtx.inject()
    }
}

class MapMarkerPoint(
    geoObject: GeoObjectDetails,
    override val isActive: Boolean,
    override val isDesaturated: Boolean,
    override val isHighlighted: Boolean,
) : MapMarkerGeoObject(geoObject) {

    override val svgIconOptions: SvgIconOptions
        get() {
            return pointSvgIconOptions(
                size = pointSize,
                color = color,
                icon = icon,
                shape = shape,
                hasNotification = hasUnreadNotifications,
                archived = archived,
                flagged = flagged,
                desaturated = isDesaturated,
                highlighted = isHighlighted,
                bitmapImage = bitmapImage,
            )
        }
}

class MapMarkerUser(
    geoObject: GeoObjectDetails,
    override val isActive: Boolean,
    override val isDesaturated: Boolean,
    override val isHighlighted: Boolean,
) : MapMarkerGeoObject(geoObject) {
    override val title: String = geoObject.owner?.let { "${it.firstName} ${it.lastName}" } ?: geoObject.title
    private val ownerId = geoObject.ownerId
    override val svgIconOptions: SvgIconOptions
        get() {
            val userSharingState = markerClientStore.isUserSharing(ownerId)
            val userPicture = userListStore.getPublicUserProfile(ownerId)?.profilePhoto?.href

            return userSvgIconOptions(
                size = myUserSize,
                color = color,
                icon = icon,
                shape = shape,
                hasNotification = hasUnreadNotifications,
                archived = archived,
                flagged = flagged,
                desaturated = isDesaturated,
                highlighted = isHighlighted,
                sharing = userSharingState,
                picture = userPicture,
                bitmapImage = bitmapImage,
            )
        }

    companion object {
        private val markerClientStore: MarkerClientStore by koinCtx.inject()
        private val userListStore: UserListStore by koinCtx.inject()
    }
}


class MapMarkerTrackedObject(
    geoObject: GeoObjectDetails,
    override val isActive: Boolean,
    override val isDesaturated: Boolean,
    override val isHighlighted: Boolean,
) : MapMarkerGeoObject(geoObject) {
    private val extraData = geoObject.tags.getTagValues(ObjectTags.ExtraData)
    override val svgIconOptions: SvgIconOptions
        get() {

            val extraDataStatus = extraData.firstOrNull {
                it.split(":").firstOrNull() == "STATUS"
            }?.split(":")?.last()?.toIntOrNull()

            return objectMarkerSvgIconOptions(
                size = objectSize,
                color = color,
                icon = icon,
                shape = shape,
                hasNotification = hasUnreadNotifications,
                archived = archived,
                flagged = flagged,
                desaturated = isDesaturated,
                highlighted = isHighlighted,
                extraDataStateColor = extraDataStatus?.let { number -> numberColor(number) },
                bitmapImage = bitmapImage,
            )
        }
}

class MapMarkerTask(
    geoObject: GeoObjectDetails,
    override val isActive: Boolean,
    override val isDesaturated: Boolean,
    override val isHighlighted: Boolean,
) : MapMarkerGeoObject(geoObject) {
    private val taskState = geoObject.taskState
    override val svgIconOptions: SvgIconOptions
        get() {
            return taskSvgIconOptions(
                size = taskSize,
                color = color,
                icon = icon,
                shape = shape,
                hasNotification = hasUnreadNotifications,
                archived = archived,
                flagged = flagged,
                desaturated = isDesaturated,
                highlighted = isHighlighted,
                stateColor = taskState?.getColor(),
                stateIcon = taskState?.getIcon(),
                bitmapImage = bitmapImage,
            )
        }
}

class MapMarkerEvent(
    geoObject: GeoObjectDetails,
    override val isActive: Boolean,
    override val isDesaturated: Boolean,
    override val isHighlighted: Boolean,
) : MapMarkerGeoObject(geoObject) {
    private val attendees = geoObject.attendees

    override val svgIconOptions: SvgIconOptions
        get() {
            val currentUserId = apiUserStore.current.userId
            val meetingStateColor = attendees?.firstOrNull { it.userId == currentUserId }?.meetingInvitationStatus?.getColor()
            val meetingStateIcon = attendees?.firstOrNull { it.userId == currentUserId }?.meetingInvitationStatus?.getIcon()

            return eventSvgIconOptions(
                size = eventSize,
                color = color,
                icon = icon,
                shape = shape,
                hasNotification = hasUnreadNotifications,
                archived = archived,
                flagged = flagged,
                desaturated = isDesaturated,
                highlighted = isHighlighted,
                stateColor = meetingStateColor,
                stateIcon = meetingStateIcon,
                bitmapImage = bitmapImage,
            )
        }

    companion object {
        private val apiUserStore: ApiUserStore by koinCtx.inject()
    }
}


class MapMarkerBuilding(
    geoObject: GeoObjectDetails,
    override val isActive: Boolean,
    override val isDesaturated: Boolean,
    override val isHighlighted: Boolean,
) : MapMarkerGeoObject(geoObject) {
    override val svgIconOptions: SvgIconOptions
        get() {
            return buildingSvgIconOptions(
                size = buildingSize,
                color = color,
                icon = icon,
                shape = shape,
                hasNotification = hasUnreadNotifications,
                archived = archived,
                flagged = flagged,
                desaturated = isDesaturated,
                highlighted = isHighlighted,
                bitmapImage = bitmapImage,
            )
        }
}

class MapMarkerGeoJSON(
    override val id: String,
    override val lngLat: LngLat,
    override val objectType: ObjectType,
    override val title: String,
    override val geoJSONs: List<MaplibreGeoJSON>,
) : MapMarker {
    override val svgIconOptions: SvgIconOptions? = null
    override val showTitle: Boolean = false
    override val isActive: Boolean = false
    override val isDesaturated: Boolean = false
    override val isHighlighted: Boolean = false
    override val imageOverlays: List<MaplibreImageOverlayRotated> = emptyList()
    override val bitmapImage: String? = null
}

class MapMarkerFloorImage(
    override val id: String,
    override val lngLat: LngLat,
    override val objectType: ObjectType,
    override val title: String,
    override val imageOverlays: List<MaplibreImageOverlayRotated>,
) : MapMarker {
    override val svgIconOptions: SvgIconOptions? = null
    override val showTitle: Boolean = false
    override val isActive: Boolean = false
    override val isDesaturated: Boolean = false
    override val isHighlighted: Boolean = false
    override val geoJSONs: List<MaplibreGeoJSON> = emptyList()
    override val bitmapImage: String? = null
}

class MapMarkerArea(
    geoObject: GeoObjectDetails,
    override val isActive: Boolean,
    override val isDesaturated: Boolean,
    override val isHighlighted: Boolean,
) : MapMarkerGeoObject(geoObject) {
    override val svgIconOptions: SvgIconOptions
        get() {
            return areaMarkerSvgIconOptions(
                size = areaSize,
                color = color,
                icon = icon,
                shape = shape,
                hasNotification = hasUnreadNotifications,
                archived = archived,
                flagged = flagged,
                desaturated = isDesaturated,
                highlighted = isHighlighted,
                bitmapImage = bitmapImage,
            )
        }
}


class MapMarkerZone(
    geoObject: GeoObjectDetails,
    override val isActive: Boolean,
    override val isDesaturated: Boolean,
    override val isHighlighted: Boolean,
) : MapMarkerGeoObject(geoObject) {
    private val showZoneGeometry
        get() = with(ObjectType.ObjectMarker.name) {
            router.current["edit"] == this
                || router.current["add"] == this
                || router.current["editPosition"] == this
        }
    private val customFields = getCustomFieldMap(geoObject.tags.getTagValues(ObjectTags.CustomField))
    private val occupancy = geoObject.tags.getTagValues(ObjectTags.ChildCount).getUniqueTag(ObjectType.ObjectMarker)?.toIntOrNull() ?: 0
    private val statusColor = parseEnumValue<MarkerColor>(geoObject.tags.getUniqueTag(ObjectTags.StatusColor)).getColorForStatus()
    private val geometry = geoObject.geometry

    override val svgIconOptions: SvgIconOptions
        get() {
            val zoneType = customFields["Type"]?.fieldValue
            val capacity = customFields["Capacity"]?.fieldValue?.toIntOrNull()
            val fraction =
                capacity?.let { cap -> occupancy.let { occ -> if (cap > 0) occ.toDouble() / cap.toDouble() else -1.0 } } // -1.0 used to indicate unknown capacity

            return zoneMarkerSvgIconOptions(
                size = zoneSize,
                color = color ?: zoneTypeColorMap[customFields["Type"]?.fieldValue],
                icon = icon ?: zoneTypeIconMap[customFields["Type"]?.fieldValue],
                shape = shape ?: zoneTypeShapeMap[customFields["Type"]?.fieldValue],
                hasNotification = hasUnreadNotifications,
                archived = archived,
                flagged = flagged,
                desaturated = isDesaturated,
                highlighted = isHighlighted,
                stateColor = when (zoneType) {
                    "containers", "Container", "coils", "Coil" -> getColorForOccupancy(occupancyFraction = fraction)
                    "machine" -> statusColor
                    else -> null
                },
                bitmapImage = bitmapImage,
            )
        }
    override val geoJSONs: List<MaplibreGeoJSON>
        get() = listOfNotNull(
            zoneGeometry,
        )

    private val zoneGeometry
        get() =
            geometry?.let { geometry ->
                if (isActive || showZoneGeometry) {
                    // create zone geometry
                    MaplibreGeoJSON(
                        type = ObjectType.Zone,
                        id = "${id}-geometry",
                        geometry = geometry,
                        title = "<H3><b>${title}</b></H3>",
                        geoJSONType = "normal",
                        fillColor = color?.getColorForIcon()?.color ?: FormationColors.BlueDeep.color,
                        fillOpacity = 0.3,
                        lineType = "normal",
                        lineColor = color?.getColorForIcon()?.color ?: FormationColors.BlueDeep.color,
                        lineWidth = 3.0,
                        lineOpacity = 0.5,
                    )
                } else {
                    // basic (inactive) zone geometry
                    MaplibreGeoJSON(
                        type = ObjectType.Zone,
                        id = "${id}-geometry",
                        geometry = geometry,
                        title = null,
//                    fillType = null,
//                    fillColor = color?.getColorForIcon()?.color?: FormationColors.BlueDeep.color,
//                    fillOpacity = 0.0,
                        lineColor = FormationColors.BlueDeep.color,
                        lineWidth = 2.0,
                        lineOpacity = 0.5,
                        lineType = "dashed",
                    )
                }
            }

    companion object {
        private val router: MapRouter by koinCtx.inject()
    }
}

class MapMarkerGeofence(
    geoObject: GeoObjectDetails,
    override val isActive: Boolean,
    override val isDesaturated: Boolean,
    override val isHighlighted: Boolean,
) : MapMarkerGeoObject(geoObject) {
    override val svgIconOptions: SvgIconOptions
        get() {
            return geoFenceMarkerSvgIconOptions(
                size = geoFenceSize,
                color = color,
                icon = icon,
                shape = shape,
                hasNotification = hasUnreadNotifications,
                archived = archived,
                flagged = flagged,
                desaturated = isDesaturated,
                highlighted = isHighlighted,
                bitmapImage = bitmapImage,
            )
        }
}

class MapMarkerHistoryEntry(
    geoObject: GeoObjectDetails,
    override val isActive: Boolean,
    override val isDesaturated: Boolean,
    override val isHighlighted: Boolean,
    val activeHistoryEntry: Boolean,
) : MapMarkerGeoObject(
    geoObject = geoObject,
    showTitleOverride = activeHistoryEntry,
) {

    private val changeType = geoObject.changeType()
    private val geometry = geoObject.geometry
    override val svgIconOptions: SvgIconOptions
        get() {
            return historyPathMarkerSvgIconOptions(
                size = if (activeHistoryEntry) MarkerSize.S else historyPathMarkerSize,
                color = color,
                icon = icon,
                shape = shape,
                hasNotification = hasUnreadNotifications,
                archived = !activeHistoryEntry,
                flagged = flagged,
                desaturated = isDesaturated,
                highlighted = isHighlighted,
                changeType = changeType,
                bitmapImage = bitmapImage,
            )
        }

    private val historyPathGeometry
        get() = geometry?.let { geometry ->
            if (geometry.type == GeometryType.LineString) {
                // create history path geometry
                MaplibreGeoJSON(
                    type = ObjectType.HistoryEntry,
                    id = "${id}-history-path",
                    geometry = geometry,
                    title = null,
                    lineType = "gradient_green_red",
                    lineColor = FormationColors.MarkerYou.color,
                    lineWidth = 5.0,
                    lineOpacity = 1.0,
//                        lineStringLayer = true,
                )
            } else null
        }
    override val geoJSONs: List<MaplibreGeoJSON>
        get() = listOfNotNull(
            historyPathGeometry,
        )
}

class MapMarkerMyUser(
    val user: User,
    val position: GeoPosition,
) : MapMarker {
    override val objectType: ObjectType = ObjectType.CurrentUserMarker
    private val sharing get() = locationUploadStore.current.isActive
    private val follow get() = locationFollowStore.current.isActive
    private val isAnonymous = user.apiUser?.isAnonymous == true

    override val id = user.userId
    override val lngLat: LngLat = LngLat(position.coords.longitude, position.coords.latitude)

    override val title
        get() = cutString("${user.firstName} ${user.lastName}") + if (isAnonymous) {
            "(${
                translation.getString(TL.General.YOU, mapOf("case" to "nominative"))
                    .lowercase().replaceFirstChar { char ->
                        if (char.isLowerCase()) char.titlecase() else char.toString()
                    }
            })"
        } else {
            ""
        }
    override val showTitle: Boolean
        get() = !localSettingsStore.map(LocalSettings.hideMarkerTitles()).current


    override val svgIconOptions: SvgIconOptions
        get() {
            val profilePicture = myProfileStore.current.profilePhoto?.href
            return myUserSvgIconOptions(
                size = myUserSize,
                sharing = sharing,
                color = null,
                icon = null,
                shape = null,
                picture = profilePicture,
            )
        }

    override val isActive: Boolean
        get() = activeUserStore.current.userId == user.userId

    override val isDesaturated: Boolean = false
    override val isHighlighted: Boolean = false
    override val bitmapImage: String? = null

    override val geoJSONs: List<MaplibreGeoJSON> = listOfNotNull(
        if (follow && isMobileOrTabletBrowser()) {
            // create accuracy geometry
            val geometry = GeoGeometry.circle2polygon(
                segments = 100,
                centerLat = position.coords.latitude,
                centerLon = position.coords.longitude,
                radius = position.coords.accuracy,
            ).polygonGeometry()
            MaplibreGeoJSON(
                type = ObjectType.CurrentUserMarker,
                id = "${user.userId}-accuracy-circle",
                geometry = geometry,
                title = null,
                geoJSONType = "normal",
                fillColor = if (sharing) FormationColors.MarkerYou.color else FormationColors.GrayPrivate.color,
                fillOpacity = 0.3,
                lineType = "normal",
                lineColor = if (sharing) FormationColors.MarkerYou.color else FormationColors.GrayPrivate.color,
                lineOpacity = 0.5,
            )
        } else null,
    )

    override val imageOverlays: List<MaplibreImageOverlayRotated> = emptyList()

    companion object {
        private val activeUserStore: ActiveUserStore by koinCtx.inject()
        private val myProfileStore: MyProfileStore by koinCtx.inject()
        private val translation: Translation by koinCtx.inject()
        private val locationUploadStore: LocationUploadStore by koinCtx.inject()
        private val locationFollowStore: LocationFollowStore by koinCtx.inject()
        private val localSettingsStore: LocalSettingsStore by koinCtx.inject()
    }
}

class MapMarkerConnectableShape(
    val geoObject: GeoObjectDetails,
    val connectableShapes: Map<String, MaplibreMap.ConnectableAndPosition>,
    val overlappingShapeIds: Set<String>,
    val activeSourceConnectorId: String,
    val activeTargetConnectorId: String,
    val activeTargetObjectId: String,
    val activeConnectionDeleteConnectorId: String,
    val highlightedConnector: String,
    val highlightedConnection: String,
    override val isActive: Boolean,
    override val isDesaturated: Boolean,
    override val isHighlighted: Boolean,
) : MapMarkerGeoObject(geoObject) {

    override val bitmapImage = if (featureFlagStore.current[Features.AllowBitmapMarkerIcons] == true) {
        (geoObject.attachments?.firstOrNull { it is Content.Icon } as? Content.Icon)?.href
    } else null

    private val connectable = geoObject.geoReferencedConnectableObject
    private val position = geoObject.latLon
    private val isOverlapping = geoObject.id in overlappingShapeIds
    override val svgIconOptions: SvgIconOptions
        get() {
            return connectableShapeSvgIconOptions(
                size = defaultMarkerSize,
                color = color,
                icon = icon,
                shape = shape,
                hasNotification = hasUnreadNotifications,
                archived = archived,
                flagged = flagged,
                desaturated = isDesaturated,
                highlighted = isHighlighted,
                bitmapImage = bitmapImage,
            )
        }
    override val geoJSONs: List<MaplibreGeoJSON>
        get() = joinNullableLists(
            listOf(
                shapeGeometry?.let { listOf(it) },
                exclusionZoneGeometry?.let { listOf(it) },
                connectors,
                connections,
            ),
        )

    private val shapeGeometry
        get() =
            connectable?.let { connectableObject ->
                if (isActive) {
                    // style for connectable shape
                    MaplibreGeoJSON(
                        type = ObjectType.GeneralMarker,
                        id = "${id}-shape-geometry",
                        geometry = connectableObject.connectableObjectGeometry(geoObject.latLon),
                        title = null,
                        geoJSONType = "connectable-shape",
                        fillColor = if (isOverlapping) {
                            FormationColors.RedError.color
                        } else {
                            color?.getColorForIcon()?.color ?: FormationColors.BlueDeep.color
                        },
                        fillOpacity = if (isDesaturated) 0.2 else 0.5,
                        lineType = "normal",
                        lineColor = if (isOverlapping) {
                            FormationColors.RedError.color
                        } else {
                            color?.getColorForIcon()?.color ?: FormationColors.BlueDeep.color
                        },
                        lineWidth = 3.0,
                        lineOpacity = if (isDesaturated) 0.4 else 0.7,
                    )
                } else {
                    // basic (inactive) style of connectable shape
                    MaplibreGeoJSON(
                        type = ObjectType.GeneralMarker,
                        id = "${id}-shape-geometry",
                        geometry = connectableObject.connectableObjectGeometry(geoObject.latLon),
                        title = null,
                        geoJSONType = "connectable-shape",
                        fillColor = if (isOverlapping) {
                            FormationColors.RedError.color
                        } else {
                            color?.getColorForIcon()?.color ?: FormationColors.BlueDeep.color
                        },
                        fillOpacity = 0.2,
                        lineType = "normal",
                        lineColor = if (isOverlapping) {
                            FormationColors.RedError.color
                        } else {
                            color?.getColorForIcon()?.color ?: FormationColors.BlueDeep.color
                        },
                        lineWidth = 2.0,
                        lineOpacity = 0.3,
                    )
                }
            }

    private val exclusionZoneGeometry
        get() =
            connectable?.connectableObjectExclusionZoneGeometry(geoObject.latLon)?.let { exclusionZoneGeometry ->
                if (isActive) {
                    // style for connectable shape
                    MaplibreGeoJSON(
                        type = ObjectType.GeneralMarker,
                        id = "${id}-exclusion-zone-geometry",
                        geometry = exclusionZoneGeometry,
                        title = null,
                        geoJSONType = "connectable-exclusion-zone",
                        fillColor = null, //FormationColors.White.color,
                        fillOpacity = 0.2,
                        lineType = "dashed",
                        lineColor = FormationColors.BlueDeep.color, //color?.getColorForIcon()?.color?: FormationColors.BlueDeep.color,
                        lineWidth = 3.0,
                        lineOpacity = if (isDesaturated) 0.4 else 0.7,
                    )
                } else {
                    // basic (inactive) style of connectable shape
                    MaplibreGeoJSON(
                        type = ObjectType.GeneralMarker,
                        id = "${id}-exclusion-zone-geometry",
                        geometry = exclusionZoneGeometry,
                        title = null,
//                    geoJSONType = "connectable-exclusion-zone",
//                    fillColor = null, //FormationColors.White.color,
//                    fillOpacity = 0.0,
                        lineType = "dashed",
                        lineColor = FormationColors.BlueDeep.color, //color?.getColorForIcon()?.color?: FormationColors.BlueDeep.color,
                        lineWidth = 2.0,
                        lineOpacity = 0.3,
                    )
                }
            }
    private val connectors
        get() =
            connectable?.let { connectableObject ->
                connectableObject.original.connectors?.map { connector ->
                    val uniqueConnectorId = "${geoObject.id}:${connector.id}"
                    val isActiveConnector = activeSourceConnectorId == uniqueConnectorId || activeTargetConnectorId == uniqueConnectorId
                    val isHighlightedConnector = highlightedConnector == uniqueConnectorId
                    // style when active and in connection mode
                    MaplibreGeoJSON(
                        type = ObjectType.GeneralMarker,
                        id = uniqueConnectorId,
                        geometry = connector.geometry(connectableObject, geoObject.latLon),
                        title = null,
                        geoJSONType = "connector",
                        fillColor = when {
                            isDesaturated -> FormationColors.GrayPrivate.color
                            activeSourceConnectorId == uniqueConnectorId -> {
                                console.log("IDs:", activeSourceConnectorId, activeTargetConnectorId)
                                FormationColors.GreenActive.color
                            }

                            activeTargetConnectorId == uniqueConnectorId -> {
                                FormationColors.MarkerYou.color
                            }

                            else -> {
                                color?.getColorForIcon()?.color ?: FormationColors.BlueDeep.color
                            }
                        },
                        fillOpacity = if (isActiveConnector || isHighlightedConnector) 0.5 else 0.3,
                        circleRadius = if (isActiveConnector || isHighlightedConnector) 8.0 else 5.0,
                        lineType = "normal",
                        lineColor = when {
                            activeSourceConnectorId == uniqueConnectorId -> {
                                FormationColors.White.color
                            }

                            activeTargetConnectorId == uniqueConnectorId -> {
                                FormationColors.White.color
                            }

                            else -> {
                                color?.getColorForIcon()?.color ?: FormationColors.BlueDeep.color
                            }
                        },
                        lineWidth = 3.0,
                        lineOpacity = if (isActiveConnector || isHighlightedConnector) 0.7 else 0.5,
                    )
                }
            }
    private val connections
        get() =
            if (isActive) {
                connectable?.let { connectableObject ->
                    connectableObject.connections?.mapNotNull { connection ->

                        val isActiveConnection = activeConnectionDeleteConnectorId == getUniqueConnectionId(connection)
                        val isHighlightedConnection = highlightedConnection == getUniqueConnectionId(connection)

//                    val sourceConnectable = connectableShapes[connection.sourceMarkerId]
                        val sourceConnector = connectableObject.original.connectors?.firstOrNull {
                            it.id == connection.sourceConnectorId
                        }

                        val sourceCoordinate = sourceConnector?.geometry(connectableObject, geoObject.latLon)?.coordinates

                        val targetConnectableAndPosition = connectableShapes[connection.targetMarkerId]
                        val targetConnector = targetConnectableAndPosition?.geoRefConnectableObject?.original?.connectors?.firstOrNull {
                            it.id == connection.targetConnectorId
                        }

                        val targetCoordinate = targetConnector?.geometry(
                            targetConnectableAndPosition.geoRefConnectableObject,
                            targetConnectableAndPosition.position,
                        )?.coordinates

                        val lineGeometry = if (sourceCoordinate != null && targetCoordinate != null) {
                            Geometry.LineString(arrayOf(sourceCoordinate, targetCoordinate))
                        } else null

                        val distance = if (sourceCoordinate != null && targetCoordinate != null) GeoGeometry.distance(
                            sourceCoordinate,
                            targetCoordinate,
                        ) else null

                        val min = sourceConnector?.minDistance
                        val max = sourceConnector?.maxDistance
                        val isValid = when {
                            distance != null && min != null && max != null -> distance in min..max
                            distance != null && min != null && max == null -> distance >= min
                            distance != null && min == null && max != null -> distance <= max
                            else -> true // no restrictions
                        }

//                console.log("Connection:",  lineGeometry.toString(), distance, isValid)

                        if (lineGeometry != null) {
                            val id = getUniqueConnectionId(connection)
                            if (isValid) {
                                // style when connection is valid and matches constraints
                                MaplibreGeoJSON(
                                    type = ObjectType.GeneralMarker,
                                    id = id,
                                    geometry = lineGeometry,
                                    title = "${distance?.roundTo(1)}m",
                                    geoJSONType = "connection",
                                    lineType = "normal",
                                    lineColor = FormationColors.GreenActive.color,
                                    lineWidth = if (isActiveConnection || isHighlightedConnection) 5.0 else 3.0,
                                    lineOpacity = if (isActiveConnection || isHighlightedConnection) 1.0 else 0.5,
                                )
                            } else {
                                // style when connection is not valid
                                MaplibreGeoJSON(
                                    type = ObjectType.GeneralMarker,
                                    id = id,
                                    geometry = lineGeometry,
                                    title = "${distance?.roundTo(1)}m",
                                    geoJSONType = "connection",
                                    lineType = "normal",
                                    lineColor = FormationColors.RedError.color,
                                    lineWidth = if (isActiveConnection || isHighlightedConnection) 5.0 else 3.0,
                                    lineOpacity = if (isActiveConnection || isHighlightedConnection) 1.0 else 0.5,
                                )
                            }
                        } else null
                    }
                }
            } else {
                listOf()
            }

    companion object {
        private val featureFlagStore: FeatureFlagStore by koinCtx.inject()
    }
}

fun <T> joinNullableLists(lists: List<List<T>?>): List<T> {
    return lists.mapNotNull { list -> list }.flatten()
}

fun getUniqueSourceConnectorId(connection: Connection): String = "${connection.sourceMarkerId}:${connection.sourceConnectorId}"
fun getUniqueTargetConnectorId(connection: Connection): String = "${connection.targetMarkerId}:${connection.targetConnectorId}"
fun getUniqueConnectionId(connection: Connection): String {
    return listOf(
        getUniqueSourceConnectorId(connection),
        getUniqueTargetConnectorId(connection),
    ).sorted().joinToString(";")
}


sealed interface MaplibreElement {
    val type: ObjectType
    val id: String
}

data class MaplibreCircle(
    override val type: ObjectType,
    override val id: String,
    val latlon: LatLon,
    val options: dynamic = null,
) : MaplibreElement

@Serializable
data class MaplibreImageOverlayRotated(
    override val type: ObjectType,
    override val id: String,
    val imageUrl: String,
    val topLeft: LatLon,
    val topRight: LatLon,
    val bottomLeft: LatLon,
    val bottomRight: LatLon,
) : MaplibreElement {
    companion object {
        fun fromProps(props: dynamic): List<MaplibreImageOverlayRotated> {
            return props.image_overlays?.toString()?.let {
                DEFAULT_JSON.decodeFromString(
                    ListSerializer(MaplibreImageOverlayRotated.serializer()),
                    it,
                )
            }.orEmpty()
        }

        fun encode(json: Json, imageOverlays: List<MaplibreImageOverlayRotated>) {
            json["image_overlays"] = DEFAULT_JSON.encodeToString(
                ListSerializer(MaplibreImageOverlayRotated.serializer()),
                imageOverlays,
            )
        }
    }
}

@Serializable
data class MaplibreGeoJSON(
    override val type: ObjectType,
    override val id: String,
    val geometry: Geometry,
    val title: String? = null,
    val geoJSONType: String? = null,
    val fillColor: String? = null,
    val fillOpacity: Double? = null,
    val circleRadius: Double? = null,
    val lineType: String? = null,
    val lineColor: String? = null,
    val lineOpacity: Double? = null,
    val lineWidth: Double? = null,
    val lineDashed: Boolean = false,
//    val lineGradient: String? = null,
//    val lineJoin: String? = null,
//    val lineStringLayer: Boolean = false,
//    val fillLayer: Boolean = false,
//    val outlineLayer: Boolean = false,
) : MaplibreElement {
    val properties
        get() = json(
            "id" to id,
            "title" to title,
            "geoJSON_type" to geoJSONType, //"normal", "disabled", "connectable-shape", "connectable-exclusion-zone", "connector", "connection"
            "fill_color" to fillColor,
            "fill_opacity" to fillOpacity,
            "circle_radius" to circleRadius,
            "line_type" to lineType,
//        if(outlineLayer) {
//            when {
//                lineDashed -> "dashed"
//                lineGradient != null -> "gradient_$lineGradient"
//                else -> "normal"
//            }
//        } else "disabled",
            "line_color" to lineColor,
            "line_opacity" to lineOpacity,
            "line_width" to lineWidth,
        )

    val feature: GeoJSONFeature
        get() = jsApply<GeoJSONFeature> {
            type = "Feature"
            geometry = JSON.parse(
                DEFAULT_JSON.encodeToString(
                    Geometry.serializer(),
                    this@MaplibreGeoJSON.geometry,
                ),
            )
            this.properties = this@MaplibreGeoJSON.properties
        }
//    .also {
//        console.log("geoJSON feature", it)
//    }

    companion object {
        fun fromProps(props: dynamic): List<MaplibreGeoJSON> {
            return props.geo_jsons?.toString()?.let {
                DEFAULT_JSON.decodeFromString(
                    ListSerializer(MaplibreGeoJSON.serializer()),
                    it,
                )
            }.orEmpty()
        }

        fun encode(json: Json, imageOverlays: List<MaplibreGeoJSON>) {
            json["geo_jsons"] = DEFAULT_JSON.encodeToString(
                ListSerializer(MaplibreGeoJSON.serializer()),
                imageOverlays,
            )
        }
    }
}

/**
 * TESTING functions for alternative marker rendering -> via geojson source + painting layer
 */

@Serializable
data class FillOptions(
    val fillAntialias: Boolean = true,
    val fillColor: String = "#03f", // The color of the filled part of this layer. This color can be specified as rgba with an alpha component and the color's opacity will not affect the opacity of the 1px stroke, if it is used.
    val fillOpacity: Double = 1.0, // The opacity of the entire fill layer. In contrast to the fill-color, this value will also affect the 1px stroke around the fill, if the stroke is used.
    val fillOutlineColor: String? = null, // The outline color of the fill. Matches the value of fill-color if unspecified.
    val fillPattern: String? = null, // Name of image in sprite to use for drawing image fills. For seamless patterns, image width and height must be a factor of two (2, 4, 8, ..., 512). Note that zoom-dependent expressions will be evaluated only at integer zoom levels.
    val fillSortKey: Int? = null, // Sorts features in ascending order based on this value. Features with a higher sort key will appear above features with a lower sort key.
    val fillTranslate: IntArray = intArrayOf(0, 0), // The geometry's offset. Values are [x, y] where negatives indicate left and up, respectively.
    val fillTranslateAnchor: String = "map", // Controls the frame of reference for fill-translate. "map": The fill is translated relative to the map. "viewport": The fill is translated relative to the viewport.
    val visibility: String = "visible", // Whether this layer is displayed. "visible": The layer is shown. "none": The layer is not shown.
) {
    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other == null || this::class.js != other::class.js) return false

        other as FillOptions

        return fillTranslate.contentEquals(other.fillTranslate)
    }

    override fun hashCode(): Int {
        return fillTranslate.contentHashCode()
    }
}

fun FillOptions.toJs(): dynamic {
    val obj = obj { }
    obj["fill-antialias"] = fillAntialias
    obj["fill-color"] = fillColor
    obj["fill-opacity"] = fillOpacity
    obj["fill-translate"] = fillTranslate
    obj["fill-translate-anchor"] = fillTranslateAnchor
    obj["visibility"] = visibility
    fillOutlineColor?.let { obj["fill-outline-color"] = it }
    fillSortKey?.let { obj["fill-sort-key"] = it }
    fillPattern?.let { obj["fill-pattern"] = it }
    return obj
}

enum class SourceType(val id: String) {
    Vector("vector"),
    GeoJSON("geojson"),
    ;
}

data class TileSourceData(
    val id: String,
    val url: String
) {
    val type = SourceType.Vector
}

data class TileLayerData(
    val source: TileSourceData,
    val id: String,
    val styleLayerSpecification: Json,
)

fun StyleLayer.toJson(): Json {
    return json(
        "id" to id,
        "type" to type,
        "sourceId" to sourceId,
        "source" to source,
        "source-layer" to sourceLayer,
//        "filter" to filter,
        "paint" to paint,
//        "layout" to layout,
//        "maxzoom" to maxzoom,
//        "minzoom" to minzoom,
//        "metadata" to metadata,
//        "renderingMode" to renderingMode,
//        "visibility" to visibility,
    )
}

data class MaplibreStyleLayer(
    val id: String,
    val type: String = "symbol", //Required enum. One of "fill", "line", "symbol", "circle", "heatmap", "fill-extrusion", "raster", "hillshade", "background", "sky"
    val sourceId: String? = null,
    val source: dynamic = null,
    val sourceLayer: String? = null, //(optional) The name of the source layer within the specified layer.source to use for this style layer. This is only applicable for vector tile sources and is required when layer.source is of the type vector
    val filter: Array<Any>? = null,
    val paint: dynamic = null,
    val layout: dynamic = null,
    val maxzoom: Double? = null,
    val minzoom: Double? = null,
    val metadata: dynamic = null,
    val renderingMode: String? = null,
) {
    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other == null || this::class.js != other::class.js) return false

        other as MaplibreStyleLayer

        if (filter != null) {
            if (other.filter == null) return false
            if (!filter.contentEquals(other.filter)) return false
        } else if (other.filter != null) return false

        return true
    }

    override fun hashCode(): Int {
        return filter?.contentHashCode() ?: 0
    }
}

fun MaplibreStyleLayer.toJs(): dynamic {
    val jsonObj = obj {
        this.id = id
        this.type = type
        if (source != null) this.source = source
        if (filter != null) this.filter = filter
        if (paint != null) this.paint = paint
        if (layout != null) this.layout = layout
        if (maxzoom != null) this.maxzoom = maxzoom
        if (minzoom != null) this.minzoom = minzoom
        if (metadata != null) this.metadata = metadata
    }
    if (sourceLayer != null) jsonObj["source-layer"] = sourceLayer
    if (renderingMode != null) jsonObj["rendering-mode"] = renderingMode
    return jsonObj
}

/**
 * Displaying an imageOverlay via:
 * loadImage(imageUrl) -> addImage(imageId, loadedImage) -> defineSource(coordinate) -> addLayer(imageId, definedSource)
 */

/*
fun addImage(error: dynamic, image: dynamic){
    if(error != null) {
        console.log("failed to load image", error)
    } else {
        if (!(map?.hasImage(overlay.id) as Boolean)) {
            console.log("load image", overlay.imageUrl)
            map?.addImage(overlay.id, image, obj { this.pixelRatio = kotlinx.browser.window.devicePixelRatio })
        }
    }
}
map?.loadImage(overlay.imageUrl, ::addImage)

val src = if(overlay.topLeft != null && overlay.topRight != null && overlay.bottomLeft != null && overlay.bottomRight != null) {
//                makePointListSource(layerId = overlay.id, coordinateList = listOf(overlay.topLeft, overlay.topRight, overlay.bottomLeft, overlay.bottomRight))
    makePointSource(layerId = overlay.id, coordinate = overlay.topLeft)
} else null

if(src != null) { map?.addSource(overlay.id, src) }

val layoutDef = obj {  }
layoutDef["icon-image"] = overlay.id
layoutDef["icon-overlap"] = "always"
layoutDef["text-overlap"] = "always"

val imageL = jsApply<MapLayer> {
    id = overlay.id
    source = overlay.id
    type = "symbol"
    layout = layoutDef
}

if(map?.getSource(overlay.id) != null) map?.addLayer(imageL)
*/
