package localization

import auth.FeatureFlagStore
import auth.Features
import koin.koinCtx

fun Locales.Companion.getAllowedLocales(): List<Locales> {
    val featureFlagStore: FeatureFlagStore by koinCtx.inject()
    return if(featureFlagStore.current[Features.AllowFictionalLanguages] == true){
        Locales.entries
    } else {
        getNonFictional()
    }
}
