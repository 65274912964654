package data.objects

import apiclient.geoobjects.ObjectType
import apiclient.validations.parseEnumValue
import dev.fritz2.routing.MapRouter
import koin.koinCtx
import mainmenu.Pages
import mainmenu.RouterStore
import model.KeywordTag
import search.global.ActiveSearchKeywordsStore
import search.global.ActiveSearchObjectTypesStore
import search.global.ActiveSearchReadOnlyKeywordsStore
import search.nestedObjects.ActiveNestedObjectTypesStore
import search.nestedObjects.ActiveNestedObjectsReadOnlyKeywordsStore
import search.nestedObjects.ActiveNestedSearchKeywordStore
import search.searchFilterStores.ActiveKeywordsStore

class ActiveObjectKeywordsStore : ActiveKeywordsStore() {

    private val activeSearchKeyWordsStore: ActiveSearchKeywordsStore by koinCtx.inject()
    private val activeNestedSearchKeywordStore: ActiveNestedSearchKeywordStore by koinCtx.inject()
    private val activeSearchObjectTypesStore: ActiveSearchObjectTypesStore by koinCtx.inject()
    private val activeNestedObjectTypesStore: ActiveNestedObjectTypesStore by koinCtx.inject()
    private val activeSearchReadOnlyKeywordsStore: ActiveSearchReadOnlyKeywordsStore by koinCtx.inject()
    private val activeNestedObjectsReadOnlyKeywordsStore: ActiveNestedObjectsReadOnlyKeywordsStore by koinCtx.inject()
    val router: MapRouter by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()
    val activeObjectStore: ActiveObjectStore by koinCtx.inject()


    val addToActiveSearchKeywords = handle<String> { current, keyword ->
        activeSearchKeyWordsStore.add(keyword)
        activeObjectStore.resetStore(Unit)
        routerStore.validateInternalRoute(Pages.Search.route)
        current
    }

    val addToActiveNestedObjectKeywords = handle<String> { current, keyword ->
        activeNestedSearchKeywordStore.add(keyword)
        current
    }

    val addToActiveSearchObjectTypes = handle<String?> { current, objTypeString ->
        parseEnumValue<ObjectType>(objTypeString)?.let { activeSearchObjectTypesStore.add(it) }
        activeObjectStore.resetStore(Unit)
        routerStore.validateInternalRoute(Pages.Search.route)
        current
    }
    val addToActiveSearchObjectTypesWithoutRouting = handle<String?> { current, objTypeString ->
        parseEnumValue<ObjectType>(objTypeString)?.let { activeSearchObjectTypesStore.add(it) }
        current
    }

    val removeFromActiveSearchObjectTypes = handle<String?> { current, objTypeString ->
        parseEnumValue<ObjectType>(objTypeString)?.let { activeSearchObjectTypesStore.remove(it) }
        current
    }

    val addToActiveNestedObjectTypesWithoutRouting = handle<String?> { current, objTypeString ->
        parseEnumValue<ObjectType>(objTypeString)?.let { activeNestedObjectTypesStore.add(it) }
        current
    }

    val removeFromActiveNestedObjectTypes = handle<String?> { current, objTypeString ->
        parseEnumValue<ObjectType>(objTypeString)?.let { activeNestedObjectTypesStore.remove(it) }
        current
    }

    val addToActiveSearchReadOnlyTags = handle<KeywordTag> { current, otherTag ->
        activeSearchReadOnlyKeywordsStore.add(otherTag)
        routerStore.validateInternalRoute(Pages.Search.route)
        current
    }

    val removeFromActiveSearchReadOnlyTags = handle<KeywordTag> { current, otherTag ->
        activeSearchReadOnlyKeywordsStore.remove(otherTag)
        current
    }

    val addToActiveNestedObjectReadOnlyTags = handle<KeywordTag> { current, otherTag ->
        activeNestedObjectsReadOnlyKeywordsStore.add(otherTag)
        current
    }

    val removeFromActiveNestedObjectReadOnlyTags = handle<KeywordTag> { current, otherTag ->
        activeNestedObjectsReadOnlyKeywordsStore.remove(otherTag)
        current
    }
}

//class ActiveObjectKeywordsStore: RootStore<List<String>>(emptyList()) {
//
//    private val activeSearchKeyWordsStore: ActiveSearchKeywordsStore by koinCtx.inject()
//    private val activeSearchObjectTypesStore: ActiveSearchObjectTypesStore by koinCtx.inject()
//    private val activeSearchReadOnlyKeywordsStore: ActiveSearchReadOnlyKeywordsStore by koinCtx.inject()
//    private val suggestedTagsContextStore: SuggestedTagsContextStore by koinCtx.inject()
//    val router: MapRouter by koinCtx.inject()
//    val routerStore: RouterStore by koinCtx.inject()
//
//    val add = handle<String> { current, newKeyword ->
//        if(newKeyword !in current) {
//            current + newKeyword
//        } else current
//    }
//
//    val addObjectKeywordFromInput = handle { current ->
//        val prefix = suggestedTagsContextStore.current.prefix
//        if(prefix.isNotBlank()) {
//            suggestedTagsContextStore.resetPrefix()
//            (current + prefix).distinct()
//        } else current
//    }
//
//    val remove = handle<String> { current, newKeyword ->
//        if(newKeyword in current) {
//            (current - newKeyword).distinct()
//        } else current
//    }
//
//    val addToActiveSearchKeywords = handle<String> { current, keyword ->
//        activeSearchKeyWordsStore.add(keyword)
//        routerStore.validateInternalRoute(Pages.Search.route)
//        current
//    }
//
//    val addToActiveSearchObjectTypes = handle<String?> { current, objTypeString ->
//        parseEnumValue<ObjectType>(objTypeString)?.let { activeSearchObjectTypesStore.add(it) }
//        routerStore.validateInternalRoute(Pages.Search.route)
//        current
//    }
//    val addToActiveSearchObjectTypesWithoutRouting = handle<String?> { current, objTypeString ->
//        parseEnumValue<ObjectType>(objTypeString)?.let { activeSearchObjectTypesStore.add(it) }
//        current
//    }
//
//    val removeFromActiveSearchObjectTypes = handle<String?> { current, objTypeString ->
//        parseEnumValue<ObjectType>(objTypeString)?.let { activeSearchObjectTypesStore.remove(it) }
//        current
//    }
//
//    val addToActiveSearchReadOnlyTags = handle<KeywordTag> { current, otherTag ->
//        activeSearchReadOnlyKeywordsStore.add(otherTag)
//        routerStore.validateInternalRoute(Pages.Search.route)
//        current
//    }
//
//    val removeFromActiveSearchReadOnlyTags = handle<KeywordTag> { current, otherTag ->
//        activeSearchReadOnlyKeywordsStore.remove(otherTag)
//        current
//    }
//
//    val reset = handle {
//        emptyList()
//    }
//
//}
