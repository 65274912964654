package data.objects

import apiclient.FormationClient
import apiclient.users.PublicUserProfile
import apiclient.users.restGetPublicUserProfile
import dev.fritz2.core.RootStore
import koin.koinCtx
import kotlinx.coroutines.Job

class AssigneeSelectorStore : RootStore<List<PublicUserProfile>?>(
    initialData = null,
    job = Job(),
) {
    private val formationClient: FormationClient by koinCtx.inject()

    val reset = handle {
        null
    }

    val updateUserIds = handle<List<String>> { _, ids ->
        ids.mapNotNull { id ->
            formationClient.restGetPublicUserProfile(userId = id).getOrNull()
        }
    }

    val toggleSingleUserSelection = handle<PublicUserProfile> { current, newUser ->
        if (current.isNullOrEmpty() || !current.contains(newUser)) {
            listOf(newUser)
        } else {
            current // TODO change this back to null, if click should toggle select and deselect again
        }
    }
}
