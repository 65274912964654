package data.objects.building

import apiclient.FormationClient
import apiclient.geoobjects.BuildingAndFloorTags
import apiclient.geoobjects.BuildingFormat
import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.LatLon
import apiclient.geoobjects.pointCoordinates
import apiclient.tags.defaultFloorId
import apiclient.tags.floorLevel
import apiclient.tags.getUniqueTag
import auth.ApiUserStore
import com.jillesvangurp.geo.GeoGeometry
import com.jillesvangurp.geojson.Geometry
import data.heatmaplayer.ActiveHeatmapLayerDefinitionStore.Companion.json
import dev.fritz2.core.RootStore
import dev.fritz2.core.SimpleHandler
import dev.fritz2.core.invoke
import koin.koinCtx
import kotlinx.coroutines.Job
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.launch
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.builtins.nullable
import kotlinx.serialization.builtins.serializer
import layercache.floorsForBuilding
import map.MapLayersStore
import maplibreGL.MaplibreMap
import maplibreGL.toLatLon
import model.Building
import model.Floor
import model.LayerType
import model.toSearchResults

class CurrentBuildingsStore : RootStore<Map<String, Building>>(
    initialData = emptyMap(),
    job = Job(),
) {

    private val activeFloorLevelStore: ActiveFloorLevelStore by koinCtx.inject()
    private val activeFloorsStore: ActiveFloorsStore by koinCtx.inject()
    private val activeBuildingStore: ActiveBuildingStore by koinCtx.inject()
    private val activeBuildingOverrideStore: ActiveBuildingOverrideStore by koinCtx.inject()
    val formationClient: FormationClient by koinCtx.inject()
    val apiUserStore: ApiUserStore by koinCtx.inject()
    val mapLayersStore: MapLayersStore by koinCtx.inject()
    val maplibreMap: MaplibreMap by koinCtx.inject()

    val reset = handle { emptyMap() }

    val initialize = handle {
        emptyMap()
    }


    private suspend fun fetchFloorData(groupId: String, building: Building): MutableMap<Double, List<Floor>> {
        val floorData = mutableMapOf<Double, List<Floor>>()
        val result = formationClient.floorsForBuilding(groupIds = listOf(groupId), buildingId = building.buildingId)
        result?.forEach { (floor, units) ->
            val level = floor.hit.tags.floorLevel
            if (level != null) floorData[level] = (floorData[level] ?: emptyList()) + Floor(floor.hit, units.hits.map { it.hit })
        }
        return floorData
    }

    private suspend fun updateBuildingFloorData(
        currentBuildings: Map<String, Building>,
        newBuildings: Map<String, Building>
    ): Map<String, Building> {
        val groupId = apiUserStore.current.apiUser?.groups?.firstOrNull()?.groupId
        val newData = newBuildings.mapValues { (buildingId, building) ->
            if (groupId != null && building.floorData.isNullOrEmpty()) {
                currentBuildings[buildingId]?.floorData ?: fetchFloorData(groupId, building)
            } else building.floorData
        }.toMap()

        return currentBuildings + newBuildings.mapValues { (id, building) ->
            building.copy(
                defaultFloorLevel = building.copy(floorData = newData[id]).getFloorLevel(building.defaultFloorId),
                floorData = newData[id],
            )
        }
    }

    val updateBuildings = handle<List<GeoObjectDetails>> { current, newBuildings ->
        val new = newBuildings.associate { building ->
            building.id to (current[building.id]?.copy(
                defaultFloorId = building.tags.defaultFloorId,
            ) ?: Building(
                buildingId = building.id,
                buildingName = building.title,
                defaultFloorLevel = null,
                defaultFloorId = building.tags.defaultFloorId,
                activeFloorLevel = null,
                activeFloorIds = null,
                floorData = null,
                geoObjectDetails = building,
            ))
        }

        if ((newBuildings.map { it.id }.toSet() - current.keys).isNotEmpty()) {
            console.log("Update Buildings", current.keys.toString())

            val updatedData = updateBuildingFloorData(current, new)
            console.log(
                "Updated Buildings (${updatedData.size}):",
                updatedData.map { (_, building) ->
                    building.buildingName
                }.toString(),
            )
            setActiveBuilding(activeBuildingStore.current)
            updatedData
        } else current
    }

    /**
     * Handler to force the fetching of new floor plan data for the building.
     * Intended to be used when you click on the building marker.
     */
    val forceRefreshBuildingFloorData = handle<String> { current, buildingId ->
        val groupId = apiUserStore.current.apiUser?.groups?.firstOrNull()?.groupId
        if (!groupId.isNullOrBlank()) {
            val new = current.mapValues { (id, building) ->
                if (id == buildingId) {
                    console.log("Force refreshed floorData for ${building.buildingName}.")
                    building.copy(
                        floorData = fetchFloorData(groupId, building),
                    )
                } else building
            }
            // make sure floors are rendered
            showAllActiveFloors(new)
            new
        } else current
    }

    /**
     * Takes the active floor/units of each building and sets them in the map layer to be rendered.
     */
    private fun showAllActiveFloors(buildings: Map<String, Building>, useOverride: Boolean = false) {
        val floors = buildings.map { (id, building) ->
            val currentPrio = activeBuildingOverrideStore.current
            if (useOverride && currentPrio != null && building.buildingId == currentPrio.buildingId) {
                val prioLevel = building.getFloorLevel(currentPrio.floorId)
                activeFloorLevelStore.update(prioLevel)
                building.floorData?.get(prioLevel)
            } else building.getActiveFloors
        }
        mapLayersStore.setResults(
            mapOf(
                LayerType.CurrentFloor to floors.flatMap { floorList -> (floorList ?: emptyList()).map { it.floor } }.toSearchResults(),
                LayerType.CurrentUnits to floors.flatMap { floorList -> (floorList ?: emptyList()).flatMap { it.units ?: emptyList() } }.toSearchResults(),
            ),
        )
    }

    /**
     * Updates the activeFloorId and activeFloorLevel of a building.
     * The building is expected to be among the map of buildings that are parsed along with the other parameters
     */
    private fun updateFloorIdsAndSetActiveLevelForBuilding(
        buildings: Map<String, Building>,
        buildingId: String? = null,
        activeFloorIds: List<String>? = null,
        activeFloorLevel: Double? = null,
        useOverride: Boolean = false
    ): Map<String, Building> {
        val new = buildings.mapValues { (id, building) ->
            if (id == buildingId) {
                building.copy(
                    activeFloorIds = activeFloorIds,
                    activeFloorLevel = activeFloorLevel,
                )
            } else building
        }
        showAllActiveFloors(new)
        return new
    }

    /**
     * Handler that takes the buildingId (from the current active building) and updates the activeFloorStore and the
     * activeFloorLevelStore with the active values of that building
     */
    private val setActiveBuilding = SimpleHandler<String?> { data, _ ->
        data handledBy { buildingId ->
            if (current[buildingId] != null) {

                val floorLevelOverride: Double?

                if (activeBuildingOverrideStore.current?.buildingId == buildingId) {
                    floorLevelOverride = current[buildingId]?.getFloorLevel(activeBuildingOverrideStore.current?.floorId)
                } else {
                    console.log("Reset building override. (setActiveBuilding) $buildingId")
                    activeBuildingOverrideStore.reset()
                    floorLevelOverride = null
                }

                val floorIds = floorLevelOverride?.let { current[buildingId]?.floorData?.get(floorLevelOverride)?.map { it.floor.id } }
                    ?: current[buildingId]?.activeFloorIds
                    ?: listOfNotNull(current[buildingId]?.defaultFloorId)
                val floorLevel = floorLevelOverride ?: current[buildingId]?.getFloorLevel(floorIds.firstOrNull())

                console.log("Set floor (${floorIds.firstOrNull()}) and level ($floorLevel) for active building -> ${current[buildingId]?.buildingName} (setActiveBuilding)")
                floorIds.let { activeFloorsStore.update(it) }
                floorLevel?.let { activeFloorLevelStore.update(it) }

                update(
                    updateFloorIdsAndSetActiveLevelForBuilding(
                        buildings = current,
                        buildingId = buildingId,
                        activeFloorIds = floorIds,
                        activeFloorLevel = floorLevel,
                    ),
                )
            }
        }
    }

    /**
     * Handler that takes the activeFloorLevel and figures out the active floorId using the activeBuildingStore
     * and updates the activeFloorStore with that floorId
     */
    private val setLevelForActiveBuilding = SimpleHandler<Double?> { data, _ ->
        data handledBy { floorLevel ->
            if (floorLevel != null) {
                coroutineScope {
                    launch {
                        console.log("Reset building override. (setLevelForActiveBuilding)")
                        activeBuildingOverrideStore.reset()

                        val buildingId = activeBuildingStore.current
                        val floorIds = current[buildingId]?.floorData?.get(floorLevel)?.map { floor -> floor.floor.id }

                        console.log("Set level for active building -> $floorLevel with floors: $floorIds (setLevelForActiveBuilding)")
                        floorIds?.let { activeFloorsStore.update(it) }

                        update(
                            updateFloorIdsAndSetActiveLevelForBuilding(
                                buildings = current,
                                buildingId = buildingId,
                                activeFloorIds = floorIds,
                                activeFloorLevel = floorLevel,
                            ),
                        )
                    }
                }
            }
        }
    }

    val setFloorForBuilding = SimpleHandler<Pair<String?, String?>> { data, _ ->
        data handledBy { (buildingId, floorId) ->
            val building = current[buildingId]

            if (building != null) {
                val floorLevel = current[buildingId]?.getFloorLevel(floorId)
                val floorIds = current[buildingId]?.floorData?.get(floorLevel)?.map { floor -> floor.floor.id }
                console.log(
                    "Set floors for building: ${
                        building.buildingName
                    } -> $floorLevel (${
                        building.floorData?.get(floorLevel)?.map { it.floor.title }
                    }) (setFloorForBuilding)",
                )

                activeFloorLevelStore.update(floorLevel)
                activeFloorsStore.update(floorIds)

                update(
                    updateFloorIdsAndSetActiveLevelForBuilding(
                        buildings = current,
                        buildingId = buildingId,
                        activeFloorIds = floorIds,
                        activeFloorLevel = floorLevel,
                        useOverride = true,
                    ),
                )
            } else {
                console.log("Cannot set floor ($floorId) for building ($buildingId). Building not yet loaded. (setFloorForBuilding)")
            }
        }
    }

    fun pointWithinFloor(point: LatLon? = null): String? {
        val currentActiveBuilding = activeBuildingStore.current
        val floors = current[currentActiveBuilding]?.floorData?.get(activeFloorLevelStore.current)
        val currentCenter = point ?: maplibreMap.getCenter().toLatLon()
        return floors?.let { floorList ->
            val list = floorList.filter { floor ->
                when (floor.floor.tags.getUniqueTag(BuildingAndFloorTags.BuildingFormat)?.let { BuildingFormat.valueOf(it) }) {
                    BuildingFormat.Image -> {
                        val (tlLon, tlLat) = json.decodeFromString(
                            ListSerializer(Double.serializer().nullable),
                            floor.floor.tags.getUniqueTag(BuildingAndFloorTags.TopLeft) ?: "",
                        ).filterNotNull()
                        val (trLon, trLat) = json.decodeFromString(
                            ListSerializer(Double.serializer().nullable),
                            floor.floor.tags.getUniqueTag(BuildingAndFloorTags.TopRight) ?: "",
                        ).filterNotNull()
                        val (blLon, blLat) = json.decodeFromString(
                            ListSerializer(Double.serializer().nullable),
                            floor.floor.tags.getUniqueTag(BuildingAndFloorTags.BottomLeft) ?: "",
                        ).filterNotNull()
                        val (brLon, brLat) = json.decodeFromString(
                            ListSerializer(Double.serializer().nullable),
                            floor.floor.tags.getUniqueTag(BuildingAndFloorTags.BottomRight) ?: "",
                        ).filterNotNull()

                        GeoGeometry.polygonContains(
                            pointCoordinates = doubleArrayOf(currentCenter.lon, currentCenter.lat),
                            polygonCoordinatesPoints = arrayOf(
                                arrayOf(
                                    doubleArrayOf(tlLon, tlLat),
                                    doubleArrayOf(trLon, trLat),
                                    doubleArrayOf(brLon, brLat),
                                    doubleArrayOf(blLon, blLat),
                                ),
                            ),
                        )
                    }

                    BuildingFormat.GeoJson -> {
                        floor.floor.geometry?.let { geo ->
                            when (geo) {
                                is Geometry.Polygon -> {
                                    geo.coordinates?.let {
                                        GeoGeometry.Companion.polygonContains(
                                            currentCenter.pointCoordinates(),
                                            it,
                                        )
                                    } == true
                                }

                                is Geometry.MultiPolygon -> {
                                    geo.coordinates?.map {
                                        GeoGeometry.Companion.polygonContains(
                                            currentCenter.pointCoordinates(),
                                            it,
                                        )
                                    }?.reduce { a, b -> a || b } == true
                                }

                                else -> {
                                    false
                                }
                            }
                        } ?: false
                    }

                    else -> false
                }
            }
            if (list.isNotEmpty()) list.firstOrNull()?.floor?.id else null
        }

    }

    private fun pointWithinUnit(): String? {
        val currentActiveBuilding = activeBuildingStore.current
        val currentUnits = current[currentActiveBuilding]?.floorData?.get(activeFloorLevelStore.current)
            ?.flatMap { floor -> floor.units ?: emptyList() }
        val currentCenter = maplibreMap.getCenter().toLatLon()
        return currentUnits?.let { units ->
            val list = units.filter { unit ->
                unit.geometry?.let { geo ->
                    when (geo) {
                        is Geometry.Polygon -> {
                            geo.coordinates?.let { c ->
                                GeoGeometry.Companion.polygonContains(
                                    currentCenter.pointCoordinates(),
                                    c,
                                )
                            } == true
                        }

                        is Geometry.MultiPolygon -> {
                            geo.coordinates?.map { c ->
                                GeoGeometry.Companion.polygonContains(
                                    currentCenter.pointCoordinates(),
                                    c,
                                )
                            }
                                ?.reduce { a, b -> a || b } == true
                        }

                        else -> {
                            false
                        }
                    }
                } ?: false
            }
            if (list.isNotEmpty()) list.firstOrNull()?.id else null
        }
    }

    fun getConnectedToId(): String? {
        return if (!pointWithinFloor().isNullOrBlank()) {
            pointWithinUnit() ?: pointWithinFloor()
        } else null
    }

    init {
        activeBuildingStore.data handledBy setActiveBuilding
        activeFloorLevelStore.data handledBy setLevelForActiveBuilding
    }
}

val Building.getActiveFloors
    get()
    = floorData?.get(activeFloorLevel) ?: floorData?.get(defaultFloorLevel) ?: floorData?.firstNotNullOfOrNull { it.value }

val List<Building>.getActiveFloorIds
    get() = mapNotNull { building -> building.getActiveFloors }
        .flatMap { floors -> floors.map { floor -> floor.floor.id } }

fun Map<String, Building>.getBuilding(floorId: String): Building? {
    val building = values.firstOrNull {
        it.getFloor(floorId) != null
    }
    return building
}

fun Map<String, Building>.getFloorAndBuilding(floorId: String): Pair<Building, Floor>? {
    var floor: Floor? = null
    val building = values.firstOrNull {
        floor = it.getFloor(floorId)
        floor != null
    }
    return if (floor != null && building != null) {
        building to floor!!
    } else {
        null
    }
}

fun Building.getFloor(floorId: String): Floor? {
    return floorData?.values?.flatten()?.firstOrNull { it.floor.id == floorId }
}

fun Building.getFloorLevel(floorId: String?): Double? {
    return floorData?.entries?.firstOrNull { (level, floors) -> floors.map { floor -> floor.floor.id }.contains(floorId) }?.key
}
