package data.objects.views.cardinfo

import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.ObjectTags
import apiclient.tags.getUniqueTag
import data.objects.ActiveObjectStore
import data.objects.views.attachments.toPreAttachment
import data.objects.views.directediting.directEditingCardContent
import dev.fritz2.components.flexBox
import dev.fritz2.core.RenderContext
import koin.koinCtx
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.map
import localization.TL
import localization.Translation
import mainmenu.Pages
import model.L
import styling.primaryButtonStyleParams
import theme.FormationIcons
import utils.getIcon
import utils.getName
import utils.respectFeatureFlags
import webcomponents.baseLayout
import webcomponents.cardTitleWithSubtitle
import webcomponents.cardTitleWithSubtitleDirectEdit
import webcomponents.oneButtonFooter

fun RenderContext.otherTypeInfoCardContent() {

    val translation: Translation by koinCtx.inject()
    val activeObjectStore: ActiveObjectStore by koinCtx.inject()
    val title = activeObjectStore.map(GeoObjectDetails.L.title)
    val objType = activeObjectStore.map(GeoObjectDetails.L.objectType)
    val description = activeObjectStore.map(apiclient.geoobjects.GeoObjectDetails.L.description)
    val attachments =
        activeObjectStore.map(GeoObjectDetails.L.attachments).data.map { attachments ->
        attachments.respectFeatureFlags()?.mapNotNull { it.toPreAttachment() }

    }
    val externalId = activeObjectStore.map(GeoObjectDetails.L.tags).data.map { it.getUniqueTag(ObjectTags.ExternalId) }
    val canModify = activeObjectStore.map(GeoObjectDetails.L.canModify)
    val canManage = activeObjectStore.map(GeoObjectDetails.L.canManage)

    baseLayout(
        header = {
            flexBox({
                direction { row }
                justifyContent { spaceBetween }
                alignItems { start }
                width { full }
                height { maxContent }
                overflowX { hidden }
            }) {
                // CARD TITLE
                canManage.data.combine(canModify.data) { manage, modify -> manage || modify }
                    .render { editAccess ->
                        if (editAccess) {
                            cardTitleWithSubtitleDirectEdit(
                                titleFlow = title.data,
                                title = title.current,
                                subtitle = objType.data.map { it.getName() },
                                titleIconFlow = objType.data.map { it.getIcon().icon },
                                subtitleIconFlow = externalId.map { if (!it.isNullOrBlank()) FormationIcons.QRCode.icon else null },
                                changeHandler = activeObjectStore.editTitle
                            )
                        } else {
                            cardTitleWithSubtitle(
                                title = title.data,
                                subtitle = objType.data.map { it.getName() },
                                titleIconFlow = objType.data.map { it.getIcon().icon },
                                subtitleIconFlow = externalId.map { if (!it.isNullOrBlank()) FormationIcons.QRCode.icon else null },
                            )
                        }
                    }
                infoCardButtons()
            }
        },
        content = {
            directEditingCardContent()
        },
        footer = {
            oneButtonFooter(
                title = translation[TL.General.BACK],
                styleParams = primaryButtonStyleParams,
                routingMap = mapOf("page" to Pages.Map.name),
                value = Unit,
                clickHandlers = listOf(activeObjectStore.resetActiveObjectAndUserToMap),
            )
        }
    )
}
