@file:Suppress("KDocMissingDocumentation", "DEPRECATION")

package dev.fritz2.components.compat

import dev.fritz2.core.HtmlTag
import dev.fritz2.core.RenderContext
import dev.fritz2.core.Scope
import dev.fritz2.core.ScopeContext
import dev.fritz2.styling.StyleClass
import dev.fritz2.styling.params.BoxParams
import dev.fritz2.styling.params.Style
import dev.fritz2.styling.params.StyleParamsImpl
import org.w3c.dom.HTMLElement

@Deprecated("Use the member function RenderContext.a instead. Translate style using tailwind CSS classes.")
fun RenderContext.a(
    style: Style<BoxParams>,
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: A.() -> Unit,
): A = register(A("a", id, ruleOf(baseClass, prefix, style).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.a instead. Translate style using tailwind CSS classes.")
fun RenderContext.a(
    style: Style<BoxParams>,
    parentStyling: Style<BoxParams> = {},
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: A.() -> Unit,
): A = register(A("a", id, ruleOf(baseClass, prefix, style, parentStyling).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.button instead. Translate style using tailwind CSS classes.")
fun RenderContext.button(
    style: Style<BoxParams>,
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: Button.() -> Unit,
): Button = register(Button("button", id, ruleOf(baseClass, prefix, style).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.button instead. Translate style using tailwind CSS classes.")
fun RenderContext.button(
    style: Style<BoxParams>,
    parentStyling: Style<BoxParams> = {},
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: Button.() -> Unit,
): Button =
    register(Button("button", id, ruleOf(baseClass, prefix, style, parentStyling).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.canvas instead. Translate style using tailwind CSS classes.")
fun RenderContext.canvas(
    style: Style<BoxParams>,
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: Canvas.() -> Unit,
): Canvas =
    register(
        Canvas("canvas", id, ruleOf(baseClass, prefix, style).name, job, evalScope(scope)), content
    )

@Deprecated("Use the member function RenderContext.canvas instead. Translate style using tailwind CSS classes.")
fun RenderContext.canvas(
    style: Style<BoxParams>,
    parentStyling: Style<BoxParams> = {},
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: Canvas.() -> Unit,
): Canvas =
    register(Canvas("canvas", id, ruleOf(baseClass, prefix, style, parentStyling).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.div instead. Translate style using tailwind CSS classes.")
fun RenderContext.div(
    style: Style<BoxParams>,
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: Div.() -> Unit,
): Div =
    register(
        Div("div", id, ruleOf(baseClass, prefix, style).name, job, evalScope(scope)), content
    )

@Deprecated("Use the member function RenderContext.div instead. Translate style using tailwind CSS classes.")
fun RenderContext.div(
    style: Style<BoxParams>,
    parentStyling: Style<BoxParams> = {},
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: Div.() -> Unit,
): Div = register(Div("div", id, ruleOf(baseClass, prefix, style, parentStyling).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.form instead. Translate style using tailwind CSS classes.")
fun RenderContext.form(
    style: Style<BoxParams>,
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: Form.() -> Unit,
): Form = register(Form("form", id, ruleOf(baseClass, prefix, style).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.form instead. Translate style using tailwind CSS classes.")
fun RenderContext.form(
    style: Style<BoxParams>,
    parentStyling: Style<BoxParams> = {},
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: Form.() -> Unit,
): Form =
    register(Form("form", id, ruleOf(baseClass, prefix, style, parentStyling).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.h1 instead. Translate style using tailwind CSS classes.")
fun RenderContext.h1(
    style: Style<BoxParams>,
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: H1.() -> Unit,
): H1 = register(H1("h1", id, ruleOf(baseClass, prefix, style).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.h1 instead. Translate style using tailwind CSS classes.")
fun RenderContext.h1(
    style: Style<BoxParams>,
    parentStyling: Style<BoxParams> = {},
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: H1.() -> Unit,
): H1 = register(H1("h1", id, ruleOf(baseClass, prefix, style, parentStyling).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.h2 instead. Translate style using tailwind CSS classes.")
fun RenderContext.h2(
    style: Style<BoxParams>,
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: H2.() -> Unit,
): H2 = register(H2("h2", id, ruleOf(baseClass, prefix, style).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.h2 instead. Translate style using tailwind CSS classes.")
fun RenderContext.h2(
    style: Style<BoxParams>,
    parentStyling: Style<BoxParams> = {},
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: H2.() -> Unit,
): H2 = register(H2("h2", id, ruleOf(baseClass, prefix, style, parentStyling).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.h3 instead. Translate style using tailwind CSS classes.")
fun RenderContext.h3(
    style: Style<BoxParams>,
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: H3.() -> Unit,
): H3 = register(H3("h3", id, ruleOf(baseClass, prefix, style).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.h3 instead. Translate style using tailwind CSS classes.")
fun RenderContext.h3(
    style: Style<BoxParams>,
    parentStyling: Style<BoxParams> = {},
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: H3.() -> Unit,
): H3 = register(H3("h3", id, ruleOf(baseClass, prefix, style, parentStyling).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.h4 instead. Translate style using tailwind CSS classes.")
fun RenderContext.h4(
    style: Style<BoxParams>,
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: H4.() -> Unit,
): H4 = register(H4("h4", id, ruleOf(baseClass, prefix, style).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.h4 instead. Translate style using tailwind CSS classes.")
fun RenderContext.h4(
    style: Style<BoxParams>,
    parentStyling: Style<BoxParams> = {},
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: H4.() -> Unit,
): H4 = register(H4("h4", id, ruleOf(baseClass, prefix, style, parentStyling).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.h5 instead. Translate style using tailwind CSS classes.")
fun RenderContext.h5(
    style: Style<BoxParams>,
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: H5.() -> Unit,
): H5 = register(H5("h5", id, ruleOf(baseClass, prefix, style).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.h5 instead. Translate style using tailwind CSS classes.")
fun RenderContext.h5(
    style: Style<BoxParams>,
    parentStyling: Style<BoxParams> = {},
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: H5.() -> Unit,
): H5 = register(H5("h5", id, ruleOf(baseClass, prefix, style, parentStyling).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.h6 instead. Translate style using tailwind CSS classes.")
fun RenderContext.h6(
    style: Style<BoxParams>,
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: H6.() -> Unit,
): H6 = register(H6("h6", id, ruleOf(baseClass, prefix, style).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.h6 instead. Translate style using tailwind CSS classes.")
fun RenderContext.h6(
    style: Style<BoxParams>,
    parentStyling: Style<BoxParams> = {},
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: H6.() -> Unit,
): H6 = register(H6("h6", id, ruleOf(baseClass, prefix, style, parentStyling).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.img instead. Translate style using tailwind CSS classes.")
fun RenderContext.img(
    style: Style<BoxParams>,
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: Img.() -> Unit,
): Img = register(Img("img", id, ruleOf(baseClass, prefix, style).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.img instead. Translate style using tailwind CSS classes.")
fun RenderContext.img(
    style: Style<BoxParams>,
    parentStyling: Style<BoxParams> = {},
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: Img.() -> Unit,
): Img = register(Img("img", id, ruleOf(baseClass, prefix, style, parentStyling).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.input instead. Translate style using tailwind CSS classes.")
fun RenderContext.input(
    style: Style<BoxParams>,
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: Input.() -> Unit,
): Input = register(Input("input", id, ruleOf(baseClass, prefix, style).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.input instead. Translate style using tailwind CSS classes.")
fun RenderContext.input(
    style: Style<BoxParams>,
    parentStyling: Style<BoxParams> = {},
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: Input.() -> Unit,
): Input =
    register(Input("input", id, ruleOf(baseClass, prefix, style, parentStyling).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.label instead. Translate style using tailwind CSS classes.")
fun RenderContext.label(
    style: Style<BoxParams>,
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: Label.() -> Unit,
): Label = register(Label("label", id, ruleOf(baseClass, prefix, style).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.label instead. Translate style using tailwind CSS classes.")
fun RenderContext.label(
    style: Style<BoxParams>,
    parentStyling: Style<BoxParams> = {},
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: Label.() -> Unit,
): Label =
    register(Label("label", id, ruleOf(baseClass, prefix, style, parentStyling).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.video instead. Translate style using tailwind CSS classes.")
fun RenderContext.video(
    style: Style<BoxParams>,
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: Video.() -> Unit,
): Video =
    register(
        Video("video", id, ruleOf(baseClass, prefix, style).name, job, evalScope(scope)), content
    )

@Deprecated("Use the member function RenderContext.video instead. Translate style using tailwind CSS classes.")
fun RenderContext.video(
    style: Style<BoxParams>,
    parentStyling: Style<BoxParams> = {},
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: Video.() -> Unit,
): Video =
    register(Video("video", id, ruleOf(baseClass, prefix, style, parentStyling).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.p instead. Translate style using tailwind CSS classes.")
fun RenderContext.p(
    style: Style<BoxParams>,
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: P.() -> Unit,
): P = register(P("p", id, ruleOf(baseClass, prefix, style).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.p instead. Translate style using tailwind CSS classes.")
fun RenderContext.p(
    style: Style<BoxParams>,
    parentStyling: Style<BoxParams> = {},
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: P.() -> Unit,
): P = register(P("p", id, ruleOf(baseClass, prefix, style, parentStyling).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.select instead. Translate style using tailwind CSS classes.")
fun RenderContext.select(
    style: Style<BoxParams>,
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: Select.() -> Unit,
): Select = register(Select("select", id, ruleOf(baseClass, prefix, style).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.select instead. Translate style using tailwind CSS classes.")
fun RenderContext.select(
    style: Style<BoxParams>,
    parentStyling: Style<BoxParams> = {},
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: Select.() -> Unit,
): Select =
    register(Select("select", id, ruleOf(baseClass, prefix, style, parentStyling).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.span instead. Translate style using tailwind CSS classes.")
fun RenderContext.span(
    style: Style<BoxParams>,
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: Span.() -> Unit,
): Span = register(Span("span", id, ruleOf(baseClass, prefix, style).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.span instead. Translate style using tailwind CSS classes.")
fun RenderContext.span(
    style: Style<BoxParams>,
    parentStyling: Style<BoxParams> = {},
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: Span.() -> Unit,
): Span =
    register(Span("span", id, ruleOf(baseClass, prefix, style, parentStyling).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.th instead. Translate style using tailwind CSS classes.")
fun RenderContext.th(
    style: Style<BoxParams>,
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: Th.() -> Unit,
): Th = register(Th("th", id, ruleOf(baseClass, prefix, style).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.th instead. Translate style using tailwind CSS classes.")
fun RenderContext.th(
    style: Style<BoxParams>,
    parentStyling: Style<BoxParams> = {},
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: Th.() -> Unit,
): Th = register(Th("th", id, ruleOf(baseClass, prefix, style, parentStyling).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.td instead. Translate style using tailwind CSS classes.")
fun RenderContext.td(
    style: Style<BoxParams>,
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: Td.() -> Unit,
): Td = register(Td("td", id, ruleOf(baseClass, prefix, style).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.td instead. Translate style using tailwind CSS classes.")
fun RenderContext.td(
    style: Style<BoxParams>,
    parentStyling: Style<BoxParams> = {},
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: Td.() -> Unit,
): Td = register(Td("td", id, ruleOf(baseClass, prefix, style, parentStyling).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.table instead. Translate style using tailwind CSS classes.")
fun RenderContext.table(
    style: Style<BoxParams>,
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: Table.() -> Unit,
): Table = register(Table("table", id, ruleOf(baseClass, prefix, style).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.table instead. Translate style using tailwind CSS classes.")
fun RenderContext.table(
    style: Style<BoxParams>,
    parentStyling: Style<BoxParams> = {},
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: Table.() -> Unit,
): Table =
    register(Table("table", id, ruleOf(baseClass, prefix, style, parentStyling).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.tr instead. Translate style using tailwind CSS classes.")
fun RenderContext.tr(
    style: Style<BoxParams>,
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: Tr.() -> Unit,
): Tr = register(Tr("tr", id, ruleOf(baseClass, prefix, style).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.tr instead. Translate style using tailwind CSS classes.")
fun RenderContext.tr(
    style: Style<BoxParams>,
    parentStyling: Style<BoxParams> = {},
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: Tr.() -> Unit,
): Tr = register(Tr("tr", id, ruleOf(baseClass, prefix, style, parentStyling).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.thead instead. Translate style using tailwind CSS classes.")
fun RenderContext.thead(
    style: Style<BoxParams>,
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: THead.() -> Unit,
): THead = register(THead("thead", id, ruleOf(baseClass, prefix, style).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.thead instead. Translate style using tailwind CSS classes.")
fun RenderContext.thead(
    style: Style<BoxParams>,
    parentStyling: Style<BoxParams> = {},
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: THead.() -> Unit,
): THead =
    register(THead("thead", id, ruleOf(baseClass, prefix, style, parentStyling).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.tbody instead. Translate style using tailwind CSS classes.")
fun RenderContext.tbody(
    style: Style<BoxParams>,
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: TBody.() -> Unit,
): TBody = register(TBody("tbody", id, ruleOf(baseClass, prefix, style).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.tbody instead. Translate style using tailwind CSS classes.")
fun RenderContext.tbody(
    style: Style<BoxParams>,
    parentStyling: Style<BoxParams> = {},
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: TBody.() -> Unit,
): TBody =
    register(TBody("tbody", id, ruleOf(baseClass, prefix, style, parentStyling).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.textarea instead. Translate style using tailwind CSS classes.")
fun RenderContext.textarea(
    style: Style<BoxParams>,
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: TextArea.() -> Unit,
): TextArea = register(TextArea("textarea", id, ruleOf(baseClass, prefix, style).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.textarea instead. Translate style using tailwind CSS classes.")
fun RenderContext.textarea(
    style: Style<BoxParams>,
    parentStyling: Style<BoxParams> = {},
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: TextArea.() -> Unit,
): TextArea = register(
    TextArea("textarea", id, ruleOf(baseClass, prefix, style, parentStyling).name, job, evalScope(scope)),
    content
)

@Deprecated("Use the member function RenderContext.nav instead. Translate style using tailwind CSS classes.")
fun RenderContext.nav(
    style: Style<BoxParams>,
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: HtmlTag<HTMLElement>.() -> Unit,
): HtmlTag<HTMLElement> =
    register(HtmlTag("nav", id, ruleOf(baseClass, prefix, style).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.nav instead. Translate style using tailwind CSS classes.")
fun RenderContext.nav(
    style: Style<BoxParams>,
    parentStyling: Style<BoxParams> = {},
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: HtmlTag<HTMLElement>.() -> Unit,
): HtmlTag<HTMLElement> =
    register(HtmlTag("nav", id, ruleOf(baseClass, prefix, style, parentStyling).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.section instead. Translate style using tailwind CSS classes.")
fun RenderContext.section(
    style: Style<BoxParams>,
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: HtmlTag<HTMLElement>.() -> Unit,
): HtmlTag<HTMLElement> =
    register(HtmlTag("section", id, ruleOf(baseClass, prefix, style).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.section instead. Translate style using tailwind CSS classes.")
fun RenderContext.section(
    style: Style<BoxParams>,
    parentStyling: Style<BoxParams> = {},
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: HtmlTag<HTMLElement>.() -> Unit,
): HtmlTag<HTMLElement> = register(
    HtmlTag("section", id, ruleOf(baseClass, prefix, style, parentStyling).name, job, evalScope(scope)), content
)

@Deprecated("Use the member function RenderContext.summary instead. Translate style using tailwind CSS classes.")
fun RenderContext.summary(
    style: Style<BoxParams>,
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: HtmlTag<HTMLElement>.() -> Unit,
): HtmlTag<HTMLElement> =
    register(HtmlTag("summary", id, ruleOf(baseClass, prefix, style).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.summary instead. Translate style using tailwind CSS classes.")
fun RenderContext.summary(
    style: Style<BoxParams>,
    parentStyling: Style<BoxParams> = {},
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: HtmlTag<HTMLElement>.() -> Unit,
): HtmlTag<HTMLElement> = register(
    HtmlTag("summary", id, ruleOf(baseClass, prefix, style, parentStyling).name, job, evalScope(scope)), content
)

@Deprecated("Use the member function RenderContext.time instead. Translate style using tailwind CSS classes.")
fun RenderContext.time(
    style: Style<BoxParams>,
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: HtmlTag<HTMLElement>.() -> Unit,
): HtmlTag<HTMLElement> =
    register(HtmlTag("time", id, ruleOf(baseClass, prefix, style).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.time instead. Translate style using tailwind CSS classes.")
fun RenderContext.time(
    style: Style<BoxParams>,
    parentStyling: Style<BoxParams> = {},
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: HtmlTag<HTMLElement>.() -> Unit,
): HtmlTag<HTMLElement> =
    register(HtmlTag("time", id, ruleOf(baseClass, prefix, style, parentStyling).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.wbr instead. Translate style using tailwind CSS classes.")
fun RenderContext.wbr(
    style: Style<BoxParams>,
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: HtmlTag<HTMLElement>.() -> Unit,
): HtmlTag<HTMLElement> =
    register(HtmlTag("wbr", id, ruleOf(baseClass, prefix, style).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.wbr instead. Translate style using tailwind CSS classes.")
fun RenderContext.wbr(
    style: Style<BoxParams>,
    parentStyling: Style<BoxParams> = {},
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: HtmlTag<HTMLElement>.() -> Unit,
): HtmlTag<HTMLElement> =
    register(HtmlTag("wbr", id, ruleOf(baseClass, prefix, style, parentStyling).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.blockquote instead. Translate style using tailwind CSS classes.")
fun RenderContext.blockquote(
    style: Style<BoxParams>,
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: HtmlTag<HTMLElement>.() -> Unit,
): HtmlTag<HTMLElement> =
    register(HtmlTag("blockquote", id, ruleOf(baseClass, prefix, style).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.blockquote instead. Translate style using tailwind CSS classes.")
fun RenderContext.blockquote(
    style: Style<BoxParams>,
    parentStyling: Style<BoxParams> = {},
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: HtmlTag<HTMLElement>.() -> Unit,
): HtmlTag<HTMLElement> = register(
    HtmlTag("blockquote", id, ruleOf(baseClass, prefix, style, parentStyling).name, job, evalScope(scope)), content
)

@Deprecated("Use the member function RenderContext.em instead. Translate style using tailwind CSS classes.")
fun RenderContext.em(
    style: Style<BoxParams>,
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: HtmlTag<HTMLElement>.() -> Unit,
): HtmlTag<HTMLElement> =
    register(HtmlTag("em", id, ruleOf(baseClass, prefix, style).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.em instead. Translate style using tailwind CSS classes.")
fun RenderContext.em(
    style: Style<BoxParams>,
    parentStyling: Style<BoxParams> = {},
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: HtmlTag<HTMLElement>.() -> Unit,
): HtmlTag<HTMLElement> =
    register(HtmlTag("em", id, ruleOf(baseClass, prefix, style, parentStyling).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.strong instead. Translate style using tailwind CSS classes.")
fun RenderContext.strong(
    style: Style<BoxParams>,
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: HtmlTag<HTMLElement>.() -> Unit,
): HtmlTag<HTMLElement> =
    register(HtmlTag("strong", id, ruleOf(baseClass, prefix, style).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.strong instead. Translate style using tailwind CSS classes.")
fun RenderContext.strong(
    style: Style<BoxParams>,
    parentStyling: Style<BoxParams> = {},
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: HtmlTag<HTMLElement>.() -> Unit,
): HtmlTag<HTMLElement> = register(
    HtmlTag("strong", id, ruleOf(baseClass, prefix, style, parentStyling).name, job, evalScope(scope)), content
)

@Deprecated("Use the member function RenderContext.small instead. Translate style using tailwind CSS classes.")
fun RenderContext.small(
    style: Style<BoxParams>,
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: HtmlTag<HTMLElement>.() -> Unit,
): HtmlTag<HTMLElement> =
    register(HtmlTag("small", id, ruleOf(baseClass, prefix, style).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.small instead. Translate style using tailwind CSS classes.")
fun RenderContext.small(
    style: Style<BoxParams>,
    parentStyling: Style<BoxParams> = {},
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: HtmlTag<HTMLElement>.() -> Unit,
): HtmlTag<HTMLElement> =
    register(HtmlTag("small", id, ruleOf(baseClass, prefix, style, parentStyling).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.s instead. Translate style using tailwind CSS classes.")
fun RenderContext.s(
    style: Style<BoxParams>,
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: HtmlTag<HTMLElement>.() -> Unit,
): HtmlTag<HTMLElement> =
    register(HtmlTag("s", id, ruleOf(baseClass, prefix, style).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.s instead. Translate style using tailwind CSS classes.")
fun RenderContext.s(
    style: Style<BoxParams>,
    parentStyling: Style<BoxParams> = {},
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: HtmlTag<HTMLElement>.() -> Unit,
): HtmlTag<HTMLElement> =
    register(HtmlTag("s", id, ruleOf(baseClass, prefix, style, parentStyling).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.cite instead. Translate style using tailwind CSS classes.")
fun RenderContext.cite(
    style: Style<BoxParams>,
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: HtmlTag<HTMLElement>.() -> Unit,
): HtmlTag<HTMLElement> =
    register(HtmlTag("cite", id, ruleOf(baseClass, prefix, style).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.cite instead. Translate style using tailwind CSS classes.")
fun RenderContext.cite(
    style: Style<BoxParams>,
    parentStyling: Style<BoxParams> = {},
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: HtmlTag<HTMLElement>.() -> Unit,
): HtmlTag<HTMLElement> =
    register(HtmlTag("cite", id, ruleOf(baseClass, prefix, style, parentStyling).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.q instead. Translate style using tailwind CSS classes.")
fun RenderContext.q(
    style: Style<BoxParams>,
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: HtmlTag<HTMLElement>.() -> Unit,
): HtmlTag<HTMLElement> =
    register(HtmlTag("q", id, ruleOf(baseClass, prefix, style).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.q instead. Translate style using tailwind CSS classes.")
fun RenderContext.q(
    style: Style<BoxParams>,
    parentStyling: Style<BoxParams> = {},
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: HtmlTag<HTMLElement>.() -> Unit,
): HtmlTag<HTMLElement> =
    register(HtmlTag("q", id, ruleOf(baseClass, prefix, style, parentStyling).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.dfn instead. Translate style using tailwind CSS classes.")
fun RenderContext.dfn(
    style: Style<BoxParams>,
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: HtmlTag<HTMLElement>.() -> Unit,
): HtmlTag<HTMLElement> =
    register(HtmlTag("dfn", id, ruleOf(baseClass, prefix, style).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.dfn instead. Translate style using tailwind CSS classes.")
fun RenderContext.dfn(
    style: Style<BoxParams>,
    parentStyling: Style<BoxParams> = {},
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: HtmlTag<HTMLElement>.() -> Unit,
): HtmlTag<HTMLElement> =
    register(HtmlTag("dfn", id, ruleOf(baseClass, prefix, style, parentStyling).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.abbr instead. Translate style using tailwind CSS classes.")
fun RenderContext.abbr(
    style: Style<BoxParams>,
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: HtmlTag<HTMLElement>.() -> Unit,
): HtmlTag<HTMLElement> =
    register(HtmlTag("abbr", id, ruleOf(baseClass, prefix, style).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.abbr instead. Translate style using tailwind CSS classes.")
fun RenderContext.abbr(
    style: Style<BoxParams>,
    parentStyling: Style<BoxParams> = {},
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: HtmlTag<HTMLElement>.() -> Unit,
): HtmlTag<HTMLElement> =
    register(HtmlTag("abbr", id, ruleOf(baseClass, prefix, style, parentStyling).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.code instead. Translate style using tailwind CSS classes.")
fun RenderContext.code(
    style: Style<BoxParams>,
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: HtmlTag<HTMLElement>.() -> Unit,
): HtmlTag<HTMLElement> =
    register(HtmlTag("code", id, ruleOf(baseClass, prefix, style).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.code instead. Translate style using tailwind CSS classes.")
fun RenderContext.code(
    style: Style<BoxParams>,
    parentStyling: Style<BoxParams> = {},
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: HtmlTag<HTMLElement>.() -> Unit,
): HtmlTag<HTMLElement> =
    register(HtmlTag("code", id, ruleOf(baseClass, prefix, style, parentStyling).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.i instead. Translate style using tailwind CSS classes.")
fun RenderContext.i(
    style: Style<BoxParams>,
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: HtmlTag<HTMLElement>.() -> Unit,
): HtmlTag<HTMLElement> =
    register(HtmlTag("i", id, ruleOf(baseClass, prefix, style).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.i instead. Translate style using tailwind CSS classes.")
fun RenderContext.i(
    style: Style<BoxParams>,
    parentStyling: Style<BoxParams> = {},
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: HtmlTag<HTMLElement>.() -> Unit,
): HtmlTag<HTMLElement> =
    register(HtmlTag("i", id, ruleOf(baseClass, prefix, style, parentStyling).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.b instead. Translate style using tailwind CSS classes.")
fun RenderContext.b(
    style: Style<BoxParams>,
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: HtmlTag<HTMLElement>.() -> Unit,
): HtmlTag<HTMLElement> =
    register(HtmlTag("b", id, ruleOf(baseClass, prefix, style).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.b instead. Translate style using tailwind CSS classes.")
fun RenderContext.b(
    style: Style<BoxParams>,
    parentStyling: Style<BoxParams> = {},
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: HtmlTag<HTMLElement>.() -> Unit,
): HtmlTag<HTMLElement> =
    register(HtmlTag("b", id, ruleOf(baseClass, prefix, style, parentStyling).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.u instead. Translate style using tailwind CSS classes.")
fun RenderContext.u(
    style: Style<BoxParams>,
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: HtmlTag<HTMLElement>.() -> Unit,
): HtmlTag<HTMLElement> =
    register(HtmlTag("u", id, ruleOf(baseClass, prefix, style).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.u instead. Translate style using tailwind CSS classes.")
fun RenderContext.u(
    style: Style<BoxParams>,
    parentStyling: Style<BoxParams> = {},
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: HtmlTag<HTMLElement>.() -> Unit,
): HtmlTag<HTMLElement> =
    register(HtmlTag("u", id, ruleOf(baseClass, prefix, style, parentStyling).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.svg instead. Translate style using tailwind CSS classes.")
fun RenderContext.svg(
    style: Style<BoxParams>,
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: Svg.() -> Unit,
): Svg = register(Svg("svg", id, ruleOf(baseClass, prefix, style).name, job, evalScope(scope)), content)

@Deprecated("Use the member function RenderContext.svg instead. Translate style using tailwind CSS classes.")
fun RenderContext.svg(
    style: Style<BoxParams>,
    parentStyling: Style<BoxParams> = {},
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "css",
    scope: ScopeContext.() -> Unit = {},
    content: Svg.() -> Unit,
): Svg = register(Svg("svg", id, ruleOf(baseClass, prefix, style, parentStyling).name, job, evalScope(scope)), content)


private inline fun RenderContext.evalScope(context: (ScopeContext.() -> Unit)): Scope =
    ScopeContext(this.scope).apply(context).scope

private fun ruleOf(baseClass: StyleClass, prefix: String, vararg styles: Style<BoxParams>) =
    (baseClass + styles.fold(StyleParamsImpl()) { acc, style -> acc.apply(style) }.cssClasses(prefix))
