package data.objects.views.directediting

import apiclient.geoobjects.Content
import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.ObjectChanges
import apiclient.geoobjects.SetDescription
import apiclient.geoobjects.applyObjectChanges
import data.objects.ActiveObjectStore
import dev.fritz2.core.RenderContext
import dev.fritz2.core.SimpleHandler
import dev.fritz2.core.Store
import dev.fritz2.core.placeholder
import dev.fritz2.core.storeOf
import koin.withKoin
import kotlin.random.Random
import kotlin.random.nextULong
import kotlinx.coroutines.flow.combine
import localization.Translation
import model.L
import overlays.withBusyApiClient
import overlays.withBusyApplyObjectChange
import theme.FormationIcons
import theme.FormationUIIcons
import twcomponents.toggleClassOnElement
import twcomponents.twAddContentButton
import twcomponents.twColOf
import twcomponents.twContentBoxOf
import twcomponents.twFullWidthTextArea
import twcomponents.twIconMedium
import twcomponents.twMarkdownContent
import twcomponents.twMediumIconButtonHighlight
import twcomponents.twMediumIconButtonNeutral
import twcomponents.twMediumIconButtonRed
import twcomponents.twMultiLineTextareaTextfield
import workspacetools.usermanagement.confirm

val GeoObjectDetails.editable: Boolean get() = this.canManage || this.canModify
val GeoObjectDetails.manageable: Boolean get() = this.canManage

fun RenderContext.editableDescription() {
    withKoin {
        val translation = get<Translation>()
        val activeObjectStore = get<ActiveObjectStore>()
        val objectId = activeObjectStore.current.id
        val isEditable = activeObjectStore.current.editable
        val objAttachmentsSub = activeObjectStore.map(GeoObjectDetails.L.attachments)
        val objDescriptionSub = activeObjectStore.map(GeoObjectDetails.L.description)

        combine(objAttachmentsSub.data, objDescriptionSub.data) { a, d -> Pair(a, d) }.render { (attachments, desc) ->
            val description = attachments.orEmpty().firstOrNull {
                it.id == "description"
            }?.let {
                it as Content.Markdown
                it.text
            } ?: desc
            val editingStore = storeOf(false)
            editingStore.data.render { editing ->
                if (editing) {
                    descriptionEditor(objectId, description ?: "", editingStore)
                } else {
                    if (description.isNullOrBlank()) {
                        if (isEditable) {
                            div("p-2") {
                                twAddContentButton {
                                    twIconMedium(icon = FormationUIIcons.Add)
                                    +"Add a description"

                                    clicks handledBy {
                                        editingStore.update(true)
                                    }
                                }
                            }
                        }
                    } else {
                        twContentBoxOf {
                            className("p-2")
                            val editButtonId = Random.nextULong().toString()
                            // Edit/Delete buttons
                            if (isEditable) {
                                div("static flex flex-row h-0 w-full items-center justify-end pb-2") {
                                    div(
                                        "relative invisible flex flex-row py-2 px-4 gap-5 place-items-center bg-gray-300 rounded-xl shadow",
                                        id = editButtonId,
                                    ) {
                                        twMediumIconButtonNeutral(FormationIcons.Edit) {
                                            clicks handledBy {
                                                editingStore.update(true)
                                            }
                                        }
                                        twMediumIconButtonRed(FormationIcons.DeleteAlt) {
                                            clicks handledBy confirm(
                                                translation[ContentTexts.ARE_YOU_SURE_TO_DELETE_DESCRIPTION],
                                                okHandlers = listOf(
                                                    SimpleHandler { data, _ ->
                                                        data handledBy {
                                                            withBusyApplyObjectChange(objectId, SetDescription(""))
                                                        }
                                                    },
                                                ),
                                            )
                                        }
                                    }
                                }
                            }
                            twMarkdownContent(description)
                            toggleClassOnElement(
                                className = "invisible",
                                elementId = editButtonId,
                            )
                        }
                    }
                }
            }
        }
    }
}

fun RenderContext.descriptionEditor(objectId: String, description: String, editingStore: Store<Boolean>) {
    val descriptionStore = storeOf(description)
    div("p-2 bg-gray-300") {
        twColOf {
            div("static flex flex-row h-0 w-full items-center justify-end") {
                div("relative flex flex-row w-max gap-5 bg-gray-300 py-2 px-4 rounded-xl shadow-xl", id = id) {
                    twMediumIconButtonNeutral(icon = FormationUIIcons.Close) {
                        clicks handledBy {
                            editingStore.update(false)
                        }
                    }
                    twMediumIconButtonHighlight(icon = FormationUIIcons.Save) {
                        clicks handledBy {
                            withBusyApiClient(
                                { client ->
                                    client.applyObjectChanges(ObjectChanges(objectId, SetDescription(descriptionStore.current)))
                                },
                            ) { changed ->
                                withKoin {
                                    get<ActiveObjectStore>().update(changed.first())
                                    editingStore.update(false)
                                }
                            }
                        }
                    }
                }
            }
            h3 { +"Edit Description" }
        }
        twFullWidthTextArea(descriptionStore) {
            this.twMultiLineTextareaTextfield {
                placeholder("Describe things here")
            }
        }
    }
}


