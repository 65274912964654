package data.users.profile

import apiclient.FormationClient
import apiclient.users.PublicUserProfile
import apiclient.users.verifyAccessControlToken
import dev.fritz2.core.RootStore
import koin.koinCtx
import kotlin.time.Duration.Companion.seconds
import kotlinx.coroutines.Job
import kotlinx.coroutines.delay
import overlays.BusyStore
import utils.DeferredResult

class VerifiedUserStore : RootStore<DeferredResult<PublicUserProfile>>(
    initialData = DeferredResult.Pending,
    job = Job(),
) {
    val formationClient: FormationClient by koinCtx.inject()
    val busyStore: BusyStore by koinCtx.inject()
    suspend fun verifyAccessControlToken(token: String) {
        update(DeferredResult.Pending)
        busyStore.handleApiCall(
            supplier = suspend {
                delay(1.seconds)
                formationClient.verifyAccessControlToken(token)
            },
            processResult = { res ->
                if (res.verified) {
                    update(DeferredResult.Success(res.publicProfile ?: error("verified user should have a profile")))
                } else update(DeferredResult.Failure(Exception("verification failed")))
            },
            processError = { error ->
                console.log("Verify access of user failed.", error.message)
                update(DeferredResult.Failure(error))
            }
        )
    }
}
