package twcomponents

import data.objects.views.directediting.directEditingCardContentId
import dev.fritz2.core.HtmlTag
import dev.fritz2.core.RenderContext
import koin.koinCtx
import kotlin.math.abs
import kotlinx.browser.document
import kotlinx.browser.window
import maplibreGL.MaplibreMap
import objectrouting.NavigationStore
import org.w3c.dom.DragEvent
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import org.w3c.dom.MutationObserver
import org.w3c.dom.MutationObserverInit
import org.w3c.dom.Node
import org.w3c.dom.TouchEvent
import org.w3c.dom.events.Event
import org.w3c.dom.get
import theme.FormationUIIcons
import utils.isTouchDevice
import utils.resizeObserver

const val draggableCardContentWrapperId = "draggable-card-content-wrapper"

fun RenderContext.twPullCardWrapper(
    draggableCardElementId: String,
    content: HtmlTag<HTMLDivElement>.() -> Unit
) {

    val maplibreMap: MaplibreMap by koinCtx.inject()
    val navigationStore: NavigationStore by koinCtx.inject()

    var isDragging = false
    var initialDragClientY = 0
    var draggingStartedFrom = "bottom"

    // Get the viewport height
    var viewportHeight = window.innerHeight.toDouble()

    // Define the three destinations as percentages of the viewport height
    var startFromBottomDestination = viewportHeight * 0.2 // bottom at 20% of viewport height
    var startFromHalfDestination = viewportHeight * 0.5 // half at 50% of viewport height
    var startFromTopDestination = viewportHeight // top at full viewport height

    class YDrag {
        var draggableElement: HTMLElement? = null
        var dragValue: Double = startFromHalfDestination

        var value: Double
            get() = dragValue
            set(valueToSet) {
                val width = window.innerWidth
                when {
                    width > 786 -> {
                        dragValue = window.innerHeight.toDouble()
                        draggableElement?.style?.height = "100vh"
                        draggableElement?.style?.borderRadius = "unset"
                    }

                    else -> {
                        dragValue = valueToSet
                        draggableElement?.style?.height = "${valueToSet}px"
                        if (valueToSet == startFromTopDestination) {
                            draggableElement?.style?.borderRadius = "unset"
                        } else {
                            draggableElement?.style?.borderRadius = "1rem 1rem 0 0"
                        }
                    }
                }
            }
    }

    val yDrag = YDrag()

    val breakPointListener: (Any) -> Unit = {
        if (window.innerWidth > 768 && window.innerHeight.toDouble() != yDrag.value) {
//            console.log("Resize, set height from ${yDrag.value} to ${window.innerHeight}")
            yDrag.value = window.innerHeight.toDouble()
        }

    }

    navigationStore.data handledBy { _ ->
        viewportHeight = window.innerHeight.toDouble()
        startFromBottomDestination = viewportHeight * 0.2 // bottom at 20% of viewport height
        yDrag.value = startFromBottomDestination
    }

    val startListener: (Event) -> Unit = { event ->
        val eventParse = (event as? TouchEvent) ?: event as? DragEvent
//        eventParse?.preventDefault()
        eventParse?.stopPropagation()

        if (yDrag.draggableElement == null) {
            yDrag.draggableElement = document.getElementById(draggableCardElementId) as? HTMLElement
        }

        // update the three destinations as percentages of the viewport height, in case viewport has changed
        viewportHeight = window.innerHeight.toDouble()
        startFromBottomDestination = viewportHeight * 0.2 // bottom at 20% of viewport height
        startFromHalfDestination = viewportHeight * 0.5 // half at 50% of viewport height
        startFromTopDestination = viewportHeight // top at full viewport height

        isDragging = true
        draggingStartedFrom = when (yDrag.value) {
            startFromTopDestination -> "top"
            startFromHalfDestination -> "half"
            else -> "bottom"
        }

        // Prevent scrolling while dragging
//        yDrag.draggableElement?.style?.overflowY = "hidden"
        if (draggingStartedFrom == "half" || draggingStartedFrom == "bottom") {
            (document.getElementById(draggableCardContentWrapperId) as? HTMLElement)?.style?.overflowY = "hidden"
            (document.getElementById("draggable-card-col") as? HTMLElement)?.style?.overflowY = "hidden"
        }


        val clientY = when (eventParse) {
            is TouchEvent -> eventParse.touches[0]?.clientY
            is DragEvent -> eventParse.clientY
            else -> 0
        }
        initialDragClientY = clientY ?: 0
//        console.log("Start drag. Initial clientY == $clientY")
        console.log("Started dragging from $draggingStartedFrom.")
    }

    val moveListener: (Event) -> Unit = { event ->
        if (!isDragging) {
            Unit
        } else {
            val eventParse = (event as? TouchEvent) ?: event as? DragEvent
//            eventParse?.preventDefault()
            eventParse?.stopPropagation()

            val initialYDragValue = yDrag.value

            val clientY = when (eventParse) {
                is TouchEvent -> eventParse.touches[0]?.clientY
                is DragEvent -> eventParse.clientY
                else -> null
            }
            var draggingValue = (clientY?.let { initialDragClientY - it } ?: 0).toDouble()

            if (abs(draggingValue) > 20) {
                // set map and card to fixed positions, while moving the card, to smooth out the card pull movement
                yDrag.draggableElement?.style?.position = "fixed"
                (document.getElementById(maplibreMap.targetId) as? HTMLElement)?.let {
                    it.style.position = "fixed"
                    it.style.top = "0"
                    it.style.bottom = when (draggingStartedFrom) {
                        "bottom" -> "${startFromBottomDestination.toInt()}"
                        "half" -> "${startFromBottomDestination.toInt()}"
                        else -> "${startFromHalfDestination.toInt()}"
                    }
                }
            }


            // Adjust dragging relative to where it started
            when (draggingStartedFrom) {
                "top" -> {
                    draggingValue += startFromTopDestination
                    // don't update value when dragging higher than top position
                    if (initialYDragValue > draggingValue) {
                        // FIXME This is a too specific direct sync with scroll top of directEditingCardContent
                        // All Scroll container should be checked here
                        (document.getElementById(directEditingCardContentId) as? HTMLDivElement)?.let { directEditingCardContentDiv ->
                            // don't update value when dragging higher than top position
                            if (directEditingCardContentDiv.clientTop.toDouble() == directEditingCardContentDiv.scrollTop) {
                                yDrag.value = draggingValue
                            }
                        } ?: run {
                            yDrag.value = draggingValue
                        }
                    }
                }

                "half" -> {
                    draggingValue += startFromHalfDestination
                    yDrag.value = draggingValue
                }

                else -> {
                    draggingValue += startFromBottomDestination
                    // don't update value when dragging lower than bottom position
                    if (initialYDragValue < draggingValue) {
                        yDrag.value = draggingValue
                    }
                }
            }
        }
    }

    val endListener: (Any) -> Unit = {
        if (!isDragging) {
            Unit
        } else {
            isDragging = false

            // update the three destinations as percentages of the viewport height, in case viewport has changed
            viewportHeight = window.innerHeight.toDouble()
            startFromBottomDestination = viewportHeight * 0.2 // bottom at 20% of viewport height
            startFromHalfDestination = viewportHeight * 0.5 // half at 50% of viewport height
            startFromTopDestination = viewportHeight // top at full viewport height

            // Snap logic - find the closest destination
            val distanceFromBottom = abs(yDrag.value - startFromBottomDestination)
            val distanceFromHalf = abs(yDrag.value - startFromHalfDestination)
            val distanceFromTop = abs(yDrag.value - startFromTopDestination)

            // Snap to the closest position
            yDrag.value = when {
                distanceFromBottom <= distanceFromHalf && distanceFromBottom <= distanceFromTop -> {
                    console.log("Ended dragging. -> Snapped from $draggingStartedFrom to bottom.")
                    startFromBottomDestination
                }

                distanceFromHalf <= distanceFromBottom && distanceFromHalf <= distanceFromTop -> {
                    console.log("Ended dragging. -> Snapped from $draggingStartedFrom to half.")
                    startFromHalfDestination
                }

                else -> {
                    console.log("Ended dragging. -> Snapped from $draggingStartedFrom to top.")
                    startFromTopDestination
                }
            }

            // Restore scrolling after dragging is finished (except in the bottom position)
            if (yDrag.value == startFromBottomDestination) {
//                yDrag.draggableElement?.style?.overflowY = "hidden"
                (document.getElementById(draggableCardContentWrapperId) as? HTMLElement)?.style?.overflowY = "hidden"
                (document.getElementById("draggable-card-col") as? HTMLElement)?.style?.overflowY = "hidden"
            } else {
//                yDrag.draggableElement?.style?.overflowY = "auto"
                (document.getElementById(draggableCardContentWrapperId) as? HTMLElement)?.style?.overflowY = "auto"
                (document.getElementById("draggable-card-col") as? HTMLElement)?.style?.overflowY = "auto"
            }

            // Restore relative positioning of map container and card
            yDrag.draggableElement?.style?.position = "relative"
            (document.getElementById(maplibreMap.targetId) as? HTMLElement)?.let {
                it.style.position = "relative"
                it.style.top = "unset"
                it.style.bottom = "unset"
            }
        }
    }

    // Side Panel sliding from the left
//    div(
//        baseClass = "fixed top-0 left-0 h-full w-64 bg-white shadow-lg transform -translate-x-full transition-transform duration-300 ease-in-out",
//        id = "side-panel",
//    ) {
//        div("p-4 text-black") {
//            h1 { +"Side Panel Content" }
//            p { +"This is the information inside the side panel." }
//        }
//    }
//
//    val sidePanelVisible = storeOf(false, Job())
//
//    button {
//        +"Toggle Side Panel"
//        clicks.map { !sidePanelVisible.current } handledBy sidePanelVisible.update
//    }
//
//    sidePanelVisible.data.render { isVisible ->
//        val sidePanelElement = document.getElementById("side-panel")
//        sidePanelElement?.classList?.toggle("translate-x-0", isVisible)
//        sidePanelElement?.classList?.toggle("-translate-x-full", !isVisible)
//    }

    div(
        baseClass = "relative flex flex-col grow bottom-0 left-0 right-0 md:right-auto md:h-full min-h-0 w-full md:w-100 max-w-full md:max-w-100 min-w-72 md:min-w-100 -mt-1.5 md:mt-0 bg-formationWhite rounded-t-2xl md:rounded-none shadow-tlHalfCard md:shadow-tlFullCard",
        id = draggableCardElementId,
    ) {
        className(if (isTouchDevice) "transition-none" else "transition-all duration-300 ease-in-out")
        inlineStyle("display:none;")
        MutationObserver { _, mutationObserver ->
            if (document.contains(domNode)) {
                yDrag.draggableElement = domNode
                domNode.style.height = "${startFromHalfDestination}px"
                touchstarts handledBy startListener
                touchmoves handledBy moveListener
                touchends handledBy endListener
                dragstarts handledBy startListener
                drags handledBy moveListener
                dragends handledBy endListener

                resizeObserver(domNode) handledBy breakPointListener
                mutationObserver.disconnect()
            }
        }.observe(
            document,
            MutationObserverInit(attributes = true, childList = true, characterData = false, subtree = true),
        )

        div(
            baseClass = "flex flex-col w-full h-full overflow-y-auto rounded-t-2xl",
            id = "draggable-card-col",
        ) {
            div(
                baseClass = "sticky top-0 flex min-h-8 md:min-h-0 items-center cursor-pointer md:cursor-unset z-10 rounded-t-2xl focus:bg-gray-200",
                id = "snapping-thumb-wrapper",
                // Ensure it's on top of the content
            ) {

                if (isTouchDevice) {
                    div(
                        baseClass = "md:hidden mx-auto rounded-full w-9 h-1 mt-2 bg-gray-200 shadow-[0_0_0_1px_white] overflow-hidden",
                        id = "snapping-thumb",
                    ) {
                    }
                } else {
                    // Arrow buttons as fallback for small screens, that are no touch devices
                    div("flex flex-row w-full items-stretch justify-center rounded-t-2xl") {
                        className("md:hidden")
                        twFlatButton {
                            className("p-2 rounded-tl-2xl w-full")
                            twIconMedium(icon = FormationUIIcons.ChevronDown)
                            clicks handledBy {
                                if (yDrag.draggableElement == null) {
                                    yDrag.draggableElement = document.getElementById(draggableCardElementId) as? HTMLElement
                                }
                                yDrag.value = when (yDrag.value) {
                                    startFromTopDestination -> startFromHalfDestination
                                    startFromHalfDestination -> startFromBottomDestination
                                    else -> startFromBottomDestination
                                }
                            }
                        }
                        twFlatButton {
                            className("p-2 rounded-tr-2xl w-full")
                            twIconMedium(icon = FormationUIIcons.ChevronUp)
                            clicks handledBy {
                                if (yDrag.draggableElement == null) {
                                    yDrag.draggableElement = document.getElementById(draggableCardElementId) as? HTMLElement
                                }
                                yDrag.value = when (yDrag.value) {
                                    startFromTopDestination -> startFromTopDestination
                                    startFromHalfDestination -> startFromTopDestination
                                    else -> startFromHalfDestination
                                }
                            }
                        }
                    }
                }
            }

            div(
                baseClass = "flex flex-col w-full grow min-h-0 overflow-y-auto",
                id = draggableCardContentWrapperId,
            ) {
                content.invoke(this)
            }
        }
    }
}

fun doOnceWhenElementInDOM(elementId: String? = null, domNode: Node? = null, block: () -> Unit) {
    val observer = MutationObserver { _, mutationObserver ->
        domNode?.let {
            if (document.contains(domNode)) {
                block.invoke()
                mutationObserver.disconnect()
            }
        }
        elementId?.let {
            if (document.getElementById(elementId) != null) {
                block.invoke()
                mutationObserver.disconnect()
            }
        }
    }
    observer.observe(
        document,
        MutationObserverInit(attributes = true, childList = true, characterData = false, subtree = true),
    )
}

fun doContinuouslyWhenElementInDOM(elementId: String? = null, domNode: Node? = null, block: () -> Unit) {
    val observer = MutationObserver { _, _ ->
        domNode?.let {
            if (document.contains(domNode)) {
                block.invoke()
            }
        }
        elementId?.let {
            if (document.getElementById(elementId) != null) {
                block.invoke()
            }
        }
    }
    observer.observe(
        document,
        MutationObserverInit(attributes = true, childList = true, characterData = false, subtree = true),
    )
}

fun hideElement(elementId: String, message: String? = null) {
    message?.let { console.log(message) }
    (document.getElementById(elementId) as? HTMLElement)?.style?.display = "none"
}

fun showElement(elementId: String, message: String? = null) {
    message?.let { console.log(message) }
    (document.getElementById(elementId) as? HTMLElement)?.style?.display = "block"
}

fun RenderContext.twContentScrollBox(content: (HtmlTag<HTMLDivElement>.() -> Unit)? = null) {
    div("flex flex-col w-full h-full items-stretch justify-start overflow-y-auto") {
        content?.invoke(this)
    }
}

