package model

import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.LatLon
import kotlinx.serialization.Serializable

@Serializable
data class MapState(
    val center: LatLon,
    val zoom: Double,
    val tl: LatLon?,
    val br: LatLon?,
    val hasMoved: Boolean = false,
) {
    companion object
}

data class Building(
    val buildingId: String,
    val buildingName: String? = null,
    val defaultFloorLevel: Double? = null,
    val defaultFloorId: String? = null,
    val activeFloorLevel: Double? = null,
    val activeFloorIds: List<String>? = null,
    val floorData: MutableMap<Double, List<Floor>>? = null,
    val geoObjectDetails: GeoObjectDetails,
) {
    companion object
}

data class Floor(
    val floor: GeoObjectDetails,
    val units: List<GeoObjectDetails>? = null,
)
