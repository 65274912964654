package data.objects.views.attachments

import apiclient.geoobjects.AttachmentType
import apiclient.geoobjects.LatLon
import apiclient.geoobjects.TaskTemplate
import dev.fritz2.core.RootStore
import dev.fritz2.core.invoke
import dev.fritz2.history.history
import dev.fritz2.routing.MapRouter
import koin.koinCtx
import kotlinx.browser.window
import kotlinx.coroutines.CoroutineName
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.mapNotNull
import kotlinx.coroutines.launch
import kotlinx.datetime.Clock
import localization.TL
import localization.Translation
import map.MapStateStore
import maplibreGL.MaplibreMap
import markdown.MarkdownService
import model.GeoObjectData
import model.ImageFileData
import model.MarkdownData
import model.PollData
import model.TaskTemplateData
import model.WebLinkData
import model.latLon
import model.taskTemplate
import org.khronos.webgl.ArrayBuffer
import org.khronos.webgl.DataView
import org.khronos.webgl.Uint8Array
import org.khronos.webgl.set
import org.w3c.dom.Image
import org.w3c.dom.events.Event
import org.w3c.files.File
import org.w3c.files.FileReader
import overlays.AlertOverlayStore

// source: https://stackoverflow.com/questions/21797299/convert-base64-string-to-arraybuffer
fun base64ToArrayBuffer(base64: String): ArrayBuffer {
    val binaryString = window.atob(base64)
    val binaryLength = binaryString.length
    val bytes = Uint8Array(binaryLength)

    for (i in 0..binaryLength) {
        val ascii = binaryString[i].code.toByte()
        bytes[i] = ascii
    }
    return bytes.buffer
}

class FileStoreFritz2 : RootStore<dev.fritz2.components.File?>(
    initialData = null,
    job = Job(),
) {
    val history = history(capacity = 100)
    val loadPrevious = handle {
        if (history.current.isNotEmpty()) history.back() else null
    }
}

class FileStoreJS : RootStore<File?>(
    initialData = null,
    job = Job(),
) {
    val history = history(capacity = 100)
    val loadPrevious = handle {
        if (history.current.isNotEmpty()) history.back() else null
    }
}

class FileHandlerStore : RootStore<Boolean>(
    initialData = false,
    job = Job(),
) {

    private val fileStoreFritz2 by koinCtx.inject<FileStoreFritz2>()
    private val fileStoreJS by koinCtx.inject<FileStoreJS>()
    private val imageFileDataStore by koinCtx.inject<ImageFileDataStore>()
    private val alertOverlayStore by koinCtx.inject<AlertOverlayStore>()
    private val translation by koinCtx.inject<Translation>()

    fun initialize() {
        update(true)
    }

    /**
     * Handle two types of File classes in this store ("dev.fritz2.components.data.File" and "org.w3c.files.File")
     * -> fritz2-File for normal file upload via fritz2-DSL button
     * -> classic w3c-File is required for using the "capture:camera input attribute" on mobile devices,
     * which conveniently triggers the native camera app to take the photo
     */

    private fun validateImageFileType(fileJS: File? = null, fileFritz2: dev.fritz2.components.File? = null): Boolean {
        val fileName = fileJS?.name ?: fileFritz2?.name
        return if (!fileName.isNullOrBlank()) {
            val idxDot = fileName.lastIndexOf(".") + 1
            val fileExt = fileName.substring(idxDot, fileName.length).lowercase()
            console.log("Validate image:", fileName, "Ext:", fileExt)
            (fileExt == "jpg" || fileExt == "jpeg" || fileExt == "png" || fileExt == "gif")
        } else false
    }

    /**
     * Handler for fritz2 File
     */

    private val getContentFritz2 = handle<dev.fritz2.components.File?> { _, file ->
        if (file != null) {
            if (validateImageFileType(fileFritz2 = file)) {
                if (file.size.toInt().div(1024).div(1024) < 10) {
                    fileStoreJS.update(null)
                    val imageUrl = "data:${file.type};base64,${file.content}"
                    CoroutineScope(CoroutineName("get-image-bytes-and-dimensions")).launch {
                        val fileAsByteArray = base64ToArrayBuffer(file.content)
                        val view = DataView(fileAsByteArray)
                        val size = view.byteLength
                        val bytes = 0.until(size).map { view.getInt8(it) }.toByteArray()

                        //Initiate the JavaScript Image object to get width and height
                        val image = Image()
                        image.onload = { e: Event ->
                            val img = e.target as Image
                            console.log("load image: ${file.name}, ${img.width}x${img.height}px, $imageUrl")
                            imageFileDataStore.update(
                                imageFileDataStore.current.copy(
                                    prevName = file.name,
                                    mimeType = file.type,
                                    prevBytes = bytes,
                                    href = imageUrl,
                                    width = img.width,
                                    height = img.height,
                                ),
                            )
                        }
                        // Set the Base64 result from url FileReader as source to trigger "image.onload" event
                        image.src = imageUrl
                    }
                    // empty the current store value until reading completes
                    false
                } else {
                    alertOverlayStore.errorNotify(translation[TL.AlertNotifications.FILE_TOO_LARGE])
                    fileStoreFritz2.loadPrevious()
                    false
                }
            } else {
                alertOverlayStore.errorNotify(translation[TL.AlertNotifications.FILE_TYPE_NOT_SUPPORTED])
                fileStoreFritz2.loadPrevious()
                false
            }
        } else {
            false
        }
    }

    /**
     * Handler for w3c File
     */

    private var imageUrl = ""
    private var imageWidth = 0
    private var imageHeight = 0

    private val getContentJS = handle<File?> { _, file ->
        if (file != null) {
            if (validateImageFileType(fileJS = file)) {
                if (file.size.toInt().div(1024).div(1024) < 10) {
                    fileStoreFritz2.update(null)
                    file.let { selectedFile ->
                        val urlReader = FileReader()
                        val byteReader = FileReader()

                        // set up the callback that triggers when the url FileReader has read the file
                        urlReader.onload = {

                            val src = urlReader.result as String
                            imageUrl = src

                            //Initiate the JavaScript Image object to get width and height
                            val image = Image()
                            image.onload = { e: Event ->
                                val img = e.target as Image
                                imageWidth = img.width
                                imageHeight = img.height
                                console.log("load image: ${file.name}, ${img.width}x${img.height}px, $src")
                            }
                            //Set the Base64 result from url FileReader as source
                            image.src = src

                            // trigger the byte reader
                            byteReader.readAsArrayBuffer(selectedFile)
                        }

                        // set up the callback that triggers when the byte FileReader has read the file
                        byteReader.onload = { e: Event ->
                            val target = e.target as FileReader

                            // convert to ByteArray
                            val content = target.result as ArrayBuffer
                            val view = DataView(content)
                            val size = view.byteLength

                            CoroutineScope(CoroutineName("convert-file-to-bytes")).launch {
                                val bytes = 0.until(size).map { view.getInt8(it) }.toByteArray()
                                console.log("File bytes", bytes.toString())
                                imageFileDataStore.update(
                                    imageFileDataStore.current.copy(
                                        prevName = file.name,
                                        mimeType = file.type,
                                        prevBytes = bytes,
                                        href = imageUrl,
                                        width = imageWidth,
                                        height = imageHeight,
                                    ),
                                )
                            }
                        }
                        // trigger the url FileReader
                        urlReader.readAsDataURL(selectedFile)
                        // empty the current store value until reading completes
                        false
                    }
                } else {
                    alertOverlayStore.errorNotify(translation[TL.AlertNotifications.FILE_TOO_LARGE])
                    fileStoreJS.loadPrevious()
                    false
                }
            } else {
                alertOverlayStore.errorNotify(translation[TL.AlertNotifications.FILE_TYPE_NOT_SUPPORTED])
                fileStoreJS.loadPrevious()
                false
            }
        } else {
            false
        }
    }

    val takeFileFromBrowserCameraPhoto = handle<String?> { current, imageURL ->
        if (!imageURL.isNullOrBlank()) {
            val index = imageURL.indexOf("base64,")
            val content = if (index > -1) imageURL.substring(index + 7) else ""
            val type = if (index > -1) imageURL.substring(5, index - 1) else ""
            if (content.isNotBlank()) {
                val file = dev.fritz2.components.File(
                    name = "image-${Clock.System.now().toEpochMilliseconds()}.png",
                    size = base64ToArrayBuffer(content).byteLength.toLong(),
                    type = type,
                    content = content,
                )
                fileStoreFritz2.update(file)
            } else {
                console.log("content from imageURL is blank:")
            }
        } else {
            console.log("imageURL from photo is null or blank:", imageURL)
        }
        current
    }

    init {
        fileStoreFritz2.data handledBy getContentFritz2
        fileStoreJS.data handledBy getContentJS
    }

}

class ImageFileDataStore : RootStore<ImageFileData>(
    initialData = ImageFileData.EMPTY,
    job = Job(),
) {

    private val fileStoreFritz2 by koinCtx.inject<FileStoreFritz2>()
    private val fileStoreJS by koinCtx.inject<FileStoreJS>()

    val reset = handle {
        fileStoreFritz2.update(null)
        fileStoreFritz2.history.clear()
        fileStoreJS.update(null)
        fileStoreJS.history.clear()
        ImageFileData.EMPTY
    }
}

class MarkdownDataStore : RootStore<MarkdownData>(
    initialData = MarkdownData.EMPTY,
    job = Job(),
) {
    private val markdownService by koinCtx.inject<MarkdownService>()

    fun generateHtml(): MarkdownData {
        return current.copy(htmlPreview = markdownService.markdown2html(current.text))
    }

    val reset = handle {
        MarkdownData.EMPTY
    }
}

class WeblinkDataStore : RootStore<WebLinkData>(
    initialData = WebLinkData.EMPTY,
    job = Job(),
) {
    val reset = handle {
        WebLinkData.EMPTY
    }
}

class GeoObjectDataStore : RootStore<GeoObjectData>(
    initialData = GeoObjectData.EMPTY,
    job = Job(),
) {
    val reset = handle {
        GeoObjectData.EMPTY
    }
}

class PollDataStore : RootStore<PollData>(
    initialData = PollData.EMPTY,
    job = Job(),
) {
    val reset = handle {
        PollData.EMPTY
    }
}

class TaskTemplateDataStore : RootStore<TaskTemplateData>(
    initialData = TaskTemplateData.EMPTY,
    job = Job(),
) {
    private val taskTemplateStore: TaskTemplateStore by koinCtx.inject()
    private val taskTemplate = map(TaskTemplateData.taskTemplate())

    val setData = handle<TaskTemplateData> { _, taskTemplateData ->
        console.log("Pass data to TaskTemplateStore", taskTemplateData.taskTemplate)
        taskTemplateStore.setData(taskTemplateData.taskTemplate ?: TaskTemplate())
        taskTemplateData
    }
    val reset = handle {
        taskTemplateStore.reset()
        TaskTemplateData.EMPTY
    }

    init {
        setData(TaskTemplateData.EMPTY)
        taskTemplateStore.data handledBy taskTemplate.update
    }
}

class TaskTemplateUseMapCenterStore : RootStore<Boolean>(
    initialData = false,
    job = Job(),
) {

    val switch = handle { current ->
        val newState = !current
        update(newState)
        current
    }

    val reset = handle { false }
}

class TaskTemplateLatLonStore : RootStore<LatLon?>(
    initialData = null,
    job = Job(),
) {
    private val mapStateStore: MapStateStore by koinCtx.inject()
    private val maplibreMap: MaplibreMap by koinCtx.inject()
    private val taskTemplateUseMapCenterStore: TaskTemplateUseMapCenterStore by koinCtx.inject()
    private val router: MapRouter by koinCtx.inject()

    val updateLat = handle<String> { current, lat ->
        try {
            val double = (lat.ifBlank { "0.0" }).toDouble()
            current?.copy(lat = double) ?: LatLon(lat = double, lon = mapStateStore.current?.center?.lon ?: 0.0)
        } catch (e: NumberFormatException) {
            console.log("Value is no Double!", e.message)
            current?.copy()
        }
    }

    val updateLon = handle<String> { current, lon ->
        try {
            val double = (lon.ifBlank { "0.0" }).toDouble()
            current?.copy(lon = double) ?: LatLon(lat = mapStateStore.current?.center?.lat ?: 0.0, lon = double)
        } catch (e: NumberFormatException) {
            console.log("Value is no Double!", e.message)
            current?.copy()
        }
    }

    private val moveMap = handle<LatLon?> { _, coordinates ->
        coordinates?.let { maplibreMap.panTo(center = it) }
        coordinates
    }

    val reset = handle {
//        taskTemplateUseMapCenterStore.reset()
        null
    }

    init {
        data.mapNotNull { it } handledBy moveMap
        combine(mapStateStore.data, taskTemplateUseMapCenterStore.data) { mapState, useIt ->
            if (router.current["change"] == AttachmentType.ScanToCreateTask.name && useIt) {
                mapState?.center
            } else null
        }.mapNotNull { it } handledBy update
    }
}

class TaskTemplateStore : RootStore<TaskTemplate>(
    initialData = TaskTemplate(),
    job = Job(),
) {
    private val taskTemplateLatLonStore: TaskTemplateLatLonStore by koinCtx.inject()
    private val latLon = map(TaskTemplate.latLon())

    val setData = handle<TaskTemplate> { _, taskTemplate ->
        console.log("Pass data to TaskTemplateLatLonStore", taskTemplate.latLon)
        taskTemplateLatLonStore.update(taskTemplate.latLon)
        taskTemplate
    }

    val reset = handle {
        taskTemplateLatLonStore.reset()
        TaskTemplate()
    }

    init {
        taskTemplateLatLonStore.data handledBy latLon.update
    }
}
