package auth

import apiclient.auth.ApiUser
import apiclient.tags.getUniqueTag
import apiclient.users.UserTags
import dev.fritz2.core.RootStore
import dev.fritz2.core.SimpleHandler
import dev.fritz2.core.invoke
import dev.fritz2.core.storeOf
import dev.fritz2.tracking.tracker
import koin.koinCtx
import kotlinx.coroutines.CoroutineName
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.launch
import model.InitializationState
import model.NotificationType
import model.Overlay
import model.TermsState
import model.User
import overlays.AlertOverlayStore
import services.UserService
import theme.FormationColors
import utils.parseInstant

class TermsStore(private val apiUserStore: ApiUserStore) : RootStore<TermsState>(
    initialData = TermsState(),
    job = Job(),
) {

    private val userService: UserService = koinCtx.get()
    private val alertOverlayStore: AlertOverlayStore = koinCtx.get()

    val isBusyCheckingStore = storeOf(InitializationState.UNKNOWN, job)

    val read = handle { current ->
        val newReadState = !current.read
        update(current.copy(read = newReadState))
//        console.log("terms & conditions read!")
        current
    }

    val reset = handle {
        console.log("Reset TermsStore")
        TermsState()
    }

    val checkAndUpdate = SimpleHandler<ApiUser> { data, _ ->
        data handledBy { apiUser ->
            if (isBusyCheckingStore.current != InitializationState.RUNNING) {
                CoroutineScope(CoroutineName("check-terms")).launch {
                    isBusyCheckingStore.update(InitializationState.RUNNING)
                    val apiUserHasAgreed = apiUser.hasAcceptedLatest
                    val apiUserAgreeDate = apiUser.acceptedOn
                    console.log("User has agreed latest terms? Check:", apiUserHasAgreed)
                    if (!apiUserHasAgreed) console.log("-> Try with TermsAndConditionStatus from server")
                    if (apiUserHasAgreed) {
                        update(
                            TermsState(
                                read = apiUserHasAgreed,
                                agreedLatest = apiUserHasAgreed,
                                agreeDate = apiUserAgreeDate,
                            ),
                        )
                    } else {
                        if (!apiUser.isAnonymous) {
                            // fetch TermsAndConditionStatus from server if local stored data is out of date
                            val fetchedAgreeDate = userService.getTAndCStatus(apiUser.userId)?.acceptedOn
                            if (fetchedAgreeDate != null) {
                                val hasAgreedLatest = verifyAgreeDate(fetchedAgreeDate)
                                console.log(
                                    "Fetched TermsAndConditionStatus from server. Agreed latest terms? Check:",
                                    hasAgreedLatest,
                                )
                                if (hasAgreedLatest) {
                                    update(
                                        TermsState(
                                            read = hasAgreedLatest,
                                            agreedLatest = hasAgreedLatest,
                                            agreeDate = fetchedAgreeDate,
                                        ),
                                    )
                                } else {
                                    update(current.copy(read = false, agreedLatest = false, agreeDate = null))
                                }
                            } else {
                                update(current.copy(read = false, agreedLatest = false, agreeDate = null))
                            }
                        } else {
                            // satisfy terms check if user is anonymous
                            console.log("Satisfy TermsAndConditionStatus for anonymous user. Check.")
                            update(TermsState(read = true, agreedLatest = true, agreeDate = null))
                        }
                    }
                    isBusyCheckingStore.update(InitializationState.DONE)
                }
            }
        }
    }

    val agreeingTerms = tracker()

    val agree = handle { current ->
        CoroutineScope(CoroutineName("agreeing-terms")).launch {
            isBusyCheckingStore.update(InitializationState.RUNNING)
            agreeingTerms.track {
                val status = userService.acceptTAndC(apiUserStore.current.userId)
                if (status?.acceptedOn != null) {
//                    console.log("terms & conditions agreed!")
                    update(TermsState(read = true, agreedLatest = true, agreeDate = status.acceptedOn))
                } else {
                    update(TermsState(read = false, agreedLatest = false, agreeDate = null))
                    alertOverlayStore.show(
                        Overlay.NotificationToast(
                            notificationType = NotificationType.Alert,
                            text = flowOf("Connection timeout, please try again."),
                            bgColor = FormationColors.RedError.color,
                            durationSeconds = 3,
                        ),
                    )
                }
            }
            isBusyCheckingStore.update(InitializationState.DONE)
        }
        current
    }

    private val handleApiUser = handle<User?> { current, user ->
        if (user?.apiUser != null && user.apiUser.hasActivated) {
            console.log("ApiUser changed -> Check users terms agreement.")
            checkAndUpdate(user.apiUser)
        } else {
            reset()
        }
        current
    }

    init {
//        apiUserStore.data.mapNotNull { if(it.apiUser == null) Unit else null } handledBy reset
        apiUserStore.data handledBy handleApiUser
    }
}

fun verifyAgreeDate(userAgreeDate: String?, termsReleaseDate: String? = null): Boolean {
    return if (userAgreeDate != null) {
        val releaseDate = (termsReleaseDate ?: "2023-05-30T08:00:00.000Z").parseInstant() // latest terms date: "2023-05-30T08:00:00.000Z"
        val agreeDate = userAgreeDate.parseInstant()
        releaseDate?.let { release -> agreeDate?.let { agree -> release.toEpochMilliseconds() <= agree.toEpochMilliseconds() } } ?: false
    } else false
}

val ApiUser.acceptedOn: String?
    get() =
        this.tags.getUniqueTag(UserTags.AcceptedTermsAndConditions)

val ApiUser.hasAcceptedLatest: Boolean
    get() =
        verifyAgreeDate(this.acceptedOn)
