@file:Suppress("UNUSED_PARAMETER", "unused")

package network

import apiclient.validations.parseEnumValue
import dev.fritz2.components.stackUp
import dev.fritz2.core.RenderContext
import dev.fritz2.core.RootStore
import koin.koinCtx
import kotlinx.browser.window
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.flowOf
import org.w3c.dom.get
import utils.merge
import utils.roundTo
import webcomponents.cardSubtitle

/**
 * Using the Network Information API (https://wicg.github.io/netinfo/)
 */

enum class ConnectionType(val ctName: String) {
    Bluetooth("bluetooth"),
    Cellular("cellular"),
    Ethernet("ethernet"),
    Mixed("mixed"),
    None("none"),
    Other("other"),
    Unknown("unknown"),
    Wifi("wifi"),
    Wimax("wimax"),
}

enum class EffectiveConnectionType(val ectName: String, minimumRttMs: Int, maximumDownlinkKbps: Long) {
    ECTSlow2G("slow-2g", 2000, 50L),
    ECT2G("2g", 1400, 70L),
    ECT3G("3g", 270, 700L),
    ECT4G("4g", 0, -1L),
    Unknown("unknown", 0, 0)
}

fun getEffectiveConnectionType(effectiveType: String?) = EffectiveConnectionType.values().firstOrNull { ect ->
    ect.ectName == effectiveType
} ?: EffectiveConnectionType.Unknown


// source: https://wicg.github.io/netinfo/#dfn-table-of-maximum-downlink-speeds
fun getConnectionTech(connectionType: ConnectionType, downlinkMbps: Double): String {
    return when (connectionType) {
        ConnectionType.Wimax -> {
            when (downlinkMbps) {
                0.0 -> "none"
                in 0.0..37.0 -> "WiMAX 1 (rel 1)"
                in 37.0..141.0 -> "WiMAX 1.5 (rel 1.5)"
                in 141.0..365.0 -> "WiMAX 2 (rel 2)"
                else -> "WiMAX"
            }
        }

        ConnectionType.Cellular -> {
            when {
                downlinkMbps == 0.0 -> "none"
                downlinkMbps in 0.0..0.01 -> "GSM (2G)"
                downlinkMbps in 0.01..0.064 -> "IDEN (2G)"
                downlinkMbps in 0.064..0.115 -> "CDMA (2G)"
                downlinkMbps in 0.115..0.153 -> "1xRTT (2.5G)"
                downlinkMbps in 0.153..0.237 -> "GPRS (2.5G)"
                downlinkMbps in 0.237..0.384 -> "EDGE (2.75G)"
                downlinkMbps in 0.384..2.0 -> "UMTS (3G)"
                downlinkMbps in 2.0..2.46 -> "EVDO Rev 0 (3.5G)"
                downlinkMbps in 2.46..3.1 -> "EDVO Rev A (3.5G)"
                downlinkMbps in 3.1..3.6 -> "HSPA (3.75G)"
                downlinkMbps in 3.6..14.3 -> "HSDPA (3.75G)"
                downlinkMbps in 14.3..14.4 -> "HSUPA (3.75G)"
                downlinkMbps in 14.4..14.7 -> "EVDO Rev B (3.75G)"
                downlinkMbps in 14.7..21.0 -> "EHRPD (3.9G)"
                downlinkMbps in 21.0..42.0 -> "HSPAP (3.9G)"
                downlinkMbps in 42.0..100.0 -> "LTE (4G)"
                downlinkMbps > 100.0 -> "LTE Advanced (4G)"
                else -> "Cellular"
            }
        }

        ConnectionType.Bluetooth -> {
            when (downlinkMbps) {
                0.0 -> "none"
                in 0.0..1.0 -> "BT1.2 (1.2) / BT4.0 + Bluetooth Low Energy (BT4.0+BLE)"
                in 1.0..3.0 -> "BT2.1 + Enhanced Data Rate (2.1+EDR)"
                in 03.0..24.0 -> "BT3.0 + High Speed (3.0+HS)"
                else -> "Bluetooth"
            }
        }

        ConnectionType.Ethernet -> {
            when (downlinkMbps) {
                0.0 -> "none"
                in 0.0..10.0 -> "Ethernet (10)"
                in 10.0..100.0 -> "Ethernet (100)"
                in 100.0..1000.0 -> "Ethernet (GigE)"
                in 1000.0..10000.0 -> "Ethernet (10 GigE)"
                else -> "Ethernet"
            }
        }

        ConnectionType.Wifi -> {
            when (downlinkMbps) {
                0.0 -> "none"
                in 0.0..11.0 -> "Wi-Fi b (802.11b)"
                in 11.0..54.0 -> "Wi-Fi g (802.11g)"
                in 54.0..600.0 -> "Wi-Fi n (802.11n)"
                in 600.0..6933.0 -> "Wi-Fi ac (802.11ac)"
                in 6933.0..7000.0 -> "Wi-Fi ad (802.11ad)"
                else -> "Wi-Fi"
            }
        }

        ConnectionType.Mixed -> "mixed"
        ConnectionType.Unknown -> "unknown (+Infinity)"
        ConnectionType.None -> "none"
        ConnectionType.Other -> "user agent specific"
    }
}

data class NetworkInformation(
    val connectionType: ConnectionType,
    val effectiveType: EffectiveConnectionType,
    val downlinkMaxMbit: Double,
    val downlinkMbit: Double,
    val rttMs: Int,
    val connectionTech: String
)

class NetworkInformationStore : RootStore<NetworkInformation?>(
    initialData = null,
    job = Job(),
) {

    private val networkStateStore: NetworkStateStore by koinCtx.inject()

    private val network by lazy { if (window["navigator"] != null) js("navigator.connection") else null }

    private val fetchNetworkInformation = handle<NetworkState?> { _, networkState ->
        if (networkState == NetworkState.Online) {
            if (network != null) {
                console.log("ConnectionType:", network)
                val connectionType = parseEnumValue(network.type as? String) ?: ConnectionType.Unknown
                val downlinkMax = (network.downlinkMax as? Double) ?: 0.0
                NetworkInformation(
                    connectionType = connectionType,
                    effectiveType = getEffectiveConnectionType(network.effectiveType as? String),
                    downlinkMaxMbit = downlinkMax,
                    downlinkMbit = (network.downlink as? Double) ?: 0.0,
                    rttMs = (network.rtt as? Int) ?: 0,
                    connectionTech = getConnectionTech(connectionType, downlinkMax)
                )
            } else {
                NetworkInformation(
                    connectionType = ConnectionType.Unknown,
                    effectiveType = EffectiveConnectionType.Unknown,
                    downlinkMaxMbit = 0.0,
                    downlinkMbit = 0.0,
                    rttMs = 0,
                    connectionTech = getConnectionTech(ConnectionType.Unknown, 0.0)
                )
            }
        } else null
    }

    init {
        networkStateStore.data handledBy fetchNetworkInformation
    }
}


fun RenderContext.networkInfoBox() {
    val networkInformationStore by koinCtx.inject<NetworkInformationStore>()

    networkInformationStore.data.render { networkInfo ->

        if (networkInfo != null) {
            val downLink = (networkInfo.downlinkMbit / 8)
            val downLinkMax = (networkInfo.downlinkMaxMbit / 8)
            stackUp {
                spacing { small }
                items {
                    cardSubtitle(title = flowOf(networkInfo.connectionType.name).merge(flowOf("Connected via: "), asPrefix = true))
                    cardSubtitle(title = flowOf(networkInfo.connectionTech).merge(flowOf("Connection type: "), asPrefix = true))
                    cardSubtitle(
                        title = flowOf((if (downLink < 1.0) "${(downLink * 1024).roundTo(0)} KB/s" else "${downLink.roundTo(2)} MB/s").toString()).merge(
                            flowOf(
                                "Downlink: "
                            ), asPrefix = true
                        ).merge(
                            flowOf((if (downLinkMax < 1.0) " (Max: ${(downLinkMax * 1024).roundTo(0)} KB/s)" else " (Max: ${downLinkMax.roundTo(2)} MB/s)").toString())
                        )
                    )
                    cardSubtitle(
                        title = flowOf(networkInfo.rttMs.toString()).merge(flowOf("RTT: "), asPrefix = true).merge(
                            flowOf(" Ms")
                        )
                    )
                    cardSubtitle(title = flowOf(networkInfo.effectiveType.ectName).merge(flowOf("Effective connection speed is like: "), asPrefix = true))
                }
            }
        }
    }
}
