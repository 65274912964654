package twcomponents

import com.tryformation.localization.Translatable
import dev.fritz2.core.HtmlTag
import dev.fritz2.core.RenderContext
import dev.fritz2.core.ScopeContext
import dev.fritz2.core.Store
import dev.fritz2.core.disabled
import dev.fritz2.core.title
import koin.withKoin
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import localization.translate
import org.w3c.dom.HTMLAnchorElement
import org.w3c.dom.HTMLButtonElement
import org.w3c.dom.HTMLDivElement
import overlays.AlertOverlayStore
import theme.FormationUIIcons
import theme.IconEnum
import web.navigator.navigator
import webcomponents.Position

fun RenderContext.twSmallIconButton(icon: IconEnum, block: HtmlTag<HTMLButtonElement>.() -> Unit) =
    button("cursor-pointer disabled:text-gray-300") {
        twIconSmall(icon)
        block.invoke(this)
    }


fun RenderContext.twMediumIconButton(icon: IconEnum, block: HtmlTag<HTMLButtonElement>.() -> Unit) =
    button("cursor-pointer disabled:text-gray-300") {
        twIconMedium(icon)
        block.invoke(this)
    }

fun RenderContext.twLargeIconButton(icon: IconEnum, block: HtmlTag<HTMLButtonElement>.() -> Unit) =
    button("cursor-pointer disabled:text-gray-300") {
        twIconLarge(icon)
        block.invoke(this)
    }

fun RenderContext.twMediumIconButtonRed(icon: IconEnum, block: HtmlTag<HTMLButtonElement>.() -> Unit) =
    div("hover:text-red-500") {
        twMediumIconButton(icon) {
            block.invoke(this)
        }
    }

fun RenderContext.twMediumIconButtonHighlight(icon: IconEnum, block: HtmlTag<HTMLButtonElement>.() -> Unit) =
    div("hover:text-highlight") {
        twMediumIconButton(icon) {
            block.invoke(this)
        }
    }


fun RenderContext.twMediumIconButtonNeutral(icon: IconEnum, block: HtmlTag<HTMLButtonElement>.() -> Unit) =
    div("hover:text-gray-500") {
        twMediumIconButton(icon) {
            block.invoke(this)
        }
    }

fun RenderContext.twLargeIconButtonNeutral(icon: IconEnum, block: HtmlTag<HTMLButtonElement>.() -> Unit) =
    div("hover:text-gray-500") {
        twLargeIconButton(icon) {
            block.invoke(this)
        }
    }


fun RenderContext.twTagButton(
    text: String,
    icon: IconEnum,
    title: String? = null, // = icon.displayName,
    inverted: Boolean = false,
    block: HtmlTag<HTMLButtonElement>.() -> Unit
) = button(
    "cursor-pointer h-7.5 hover:border-highlight text-xs pr-2 pl-3 font-bold py-1 border rounded-full " +
        "flex flex-row gap-1 justify-between place-items-center disabled:opacity-50 transition ease-in-out delay-50",
) {
    if (inverted) {
        className("bg-highlight border-highlight text-white hover:bg-white hover:text-highlight")
    } else {
        className("bg-white hover:bg-highlight hover:text-white")
    }
    div {
        +text
    }
    twIconMedium(icon)
    title?.let { title ->
        title(title)
    }
    block.invoke(this)
}


fun RenderContext.twPrimaryButton(
    text: Translatable? = null,
    translationArgs: Map<String, Any>? = null,
    icon: IconEnum? = null,
    iconPosition: Position = Position.Left,
    disabledFlow: Flow<Boolean>? = null,
    block: HtmlTag<HTMLButtonElement>.() -> Unit
) = button(
    "cursor-pointer " +
        "px-4 py-2 h-10 " +
        "enabled:hover:bg-gray-700 " +
        "bg-formationBlack text-formationWhite " +
        "flex flex-row items-center justify-center gap-3 " +
        "disabled:opacity-50 disabled:pointer-events-none " +
        "transition-color duration-300 ease-in-out " +
        "rounded-xl",
) {
    disabledFlow?.let { disabled ->
        disabled(disabled)
    }
    if (iconPosition == Position.Left) {
        icon?.let {
            twIconMedium(icon)
        }
    }

    text?.let {
        div("flex text-sm font-bold items-center justify-center") {
            translate(text, translationArgs)
        }
    }

    block.invoke(this)

    if (iconPosition == Position.Right) {
        icon?.let {
            twIconMedium(icon)
        }
    }
}


fun RenderContext.twPrimaryButtonSmall(
    text: Translatable? = null,
    translationArgs: Map<String, Any>? = null,
    icon: IconEnum? = null,
    iconPosition: Position = Position.Left,
    disabledFlow: Flow<Boolean>? = null,
    block: HtmlTag<HTMLButtonElement>.() -> Unit
) = button(
    "cursor-pointer px-4 py-1 h-8 rounded-xl bg-formationBlack text-formationWhite " +
        "enabled:hover:bg-gray-300 enabled:hover:text-formationBlack " +
        "flex flex-row items-center justify-center gap-3 disabled:opacity-50 disabled:pointer-events-none transition ease-in-out delay-50 ",
) {
    disabledFlow?.let { disabled ->
        disabled(disabled)
    }
    if (iconPosition == Position.Left) {
        icon?.let {
            twIconSmall(icon)
        }
    }

    text?.let {
        div("flex w-full text-xs font-bold items-center justify-center") {
            translate(text, translationArgs)
        }
    }

    block.invoke(this)

    if (iconPosition == Position.Right) {
        icon?.let {
            twIconSmall(icon)
        }
    }
}


fun RenderContext.twSecondaryButton(
    text: Translatable? = null,
    translationArgs: Map<String, Any>? = null,
    icon: IconEnum? = null,
    iconPosition: Position = Position.Left,
    disabledFlow: Flow<Boolean>? = null,
    block: HtmlTag<HTMLButtonElement>.() -> Unit
) = button(
    "cursor-pointer " +
        "px-4 py-2 h-10 " +
        "enabled:hover:bg-gray-300 " +
        "bg-gray-100 text-formationBlack " +
        "flex flex-row items-center justify-center gap-3 " +
        "disabled:opacity-50 disabled:pointer-events-none " +
        "transition-color duration-300 ease-in-out " +
        "rounded-xl ", //border border-formationBlack
) {
    disabledFlow?.let { disabled ->
        disabled(disabled)
    }
    if (iconPosition == Position.Left) {
        icon?.let {
            twIconMedium(icon)
        }
    }

    text?.let {
        div("flex w-full text-sm font-bold items-center justify-center") {
            translate(text, translationArgs)
        }
    }

    block.invoke(this)

    if (iconPosition == Position.Right) {
        icon?.let {
            twIconMedium(icon)
        }
    }
}


fun RenderContext.twSecondaryButtonSmall(
    text: Translatable? = null,
    translationArgs: Map<String, Any>? = null,
    icon: IconEnum? = null,
    iconPosition: Position = Position.Left,
    disabledFlow: Flow<Boolean>? = null,
    block: HtmlTag<HTMLButtonElement>.() -> Unit
) = button(
    "cursor-pointer " +
        "px-4 py-1 h-8 " +
        "bg-gray-100 text-formationBlack " +
        "enabled:hover:bg-gray-300 " +
        "flex flex-row items-center justify-center gap-3 " +
        "disabled:opacity-50 disabled:pointer-events-none " +
        "transition-color duration-300 ease-in-out " +
        "rounded-xl ", //border border-formationBlack
) {
    disabledFlow?.let { disabled ->
        disabled(disabled)
    }
    if (iconPosition == Position.Left) {
        icon?.let {
            twIconSmall(icon)
        }
    }

    text?.let {
        div("flex w-full text-xs font-bold items-center justify-center") {
            translate(text, translationArgs)
        }
    }

    block.invoke(this)

    if (iconPosition == Position.Right) {
        icon?.let {
            twIconSmall(icon)
        }
    }
}

fun RenderContext.twPrimaryButtonBlue(
    text: Translatable? = null,
    translationArgs: Map<String, Any>? = null,
    icon: IconEnum? = null,
    iconPosition: Position = Position.Left,
    disabledFlow: Flow<Boolean>? = null,
    block: HtmlTag<HTMLButtonElement>.() -> Unit
) = button(
    "cursor-pointer " +
        "px-4 py-2 h-10 " +
        "enabled:hover:text-white enabled:hover:bg-sky-500 " +
        "text-white bg-sky-600 " +
        "flex flex-row items-center justify-center gap-3 " +
        "disabled:opacity-50 disabled:pointer-events-none " +
        "transition-color duration-300 ease-in-out " +
        "rounded-xl",
) {
    disabledFlow?.let { disabled ->
        disabled(disabled)
    }
    if (iconPosition == Position.Left) {
        icon?.let {
            twIconMedium(icon)
        }
    }

    text?.let {
        div("flex text-xs font-bold items-center justify-center") {
            translate(text, translationArgs)
        }
    }

    block.invoke(this)

    if (iconPosition == Position.Right) {
        icon?.let {
            twIconMedium(icon)
        }
    }
}


fun RenderContext.twCenteredLink(
    id: String? = null,
    scope: (ScopeContext.() -> Unit) = {},
    content: HtmlTag<HTMLAnchorElement>.() -> Unit

) = a(
    baseClass = "cursor-pointer underline text-center w-full block text-sm hover:opacity-25",
    id = id,
    scope = scope,
) {
    content(this)
}


fun RenderContext.twTextLinkButton(
    id: String? = null,
    scope: (ScopeContext.() -> Unit) = {},
    content: HtmlTag<HTMLAnchorElement>.() -> Unit
) = a(
    baseClass = "h-min cursor-pointer underline block text-xs hover:text-highlight break-all",
    id = id,
    scope = scope,
) {
    content(this)
}


fun <T> RenderContext.twRevertButton(
    store: Store<T>,
    revertTo: T,
    block: (HtmlTag<HTMLButtonElement>.() -> Unit)? = null
) = twCloseButton {
    store.data.render { current ->
        disabled(current == revertTo)
    }
    clicks handledBy {
        store.update(revertTo)
    }
    block?.invoke(this)
}

fun RenderContext.twCloseButton(block: (HtmlTag<HTMLButtonElement>.() -> Unit)? = null) =
    twMediumIconButtonNeutral(FormationUIIcons.Close) {
        block?.invoke(this)
    }

fun RenderContext.twLargeCloseButton(block: (HtmlTag<HTMLButtonElement>.() -> Unit)? = null) =
    twLargeIconButtonNeutral(FormationUIIcons.Close) {
        block?.invoke(this)
    }

fun RenderContext.twCheckButton(
    id: String? = null,
    scope: (ScopeContext.() -> Unit) = {},
    content: HtmlTag<HTMLButtonElement>.() -> Unit
) = button(
    "cursor-pointer text-green-500 fill-green-500 disabled:fill-gray-300 disabled:text-gray-300 hover:shadow-lg transition-shadow duration-300 ease-in-out",
    id,
    scope,
) {
    twIconMedium(FormationUIIcons.Check)
    title(FormationUIIcons.Check.icon.displayName)

    content(this)
}


fun RenderContext.twCopyClipboardButton(block: () -> String) =
    twMediumIconButton(FormationUIIcons.Copy) {
        clicks handledBy {
            withKoin {
                val content = block.invoke()
                val alertOverlayStore = get<AlertOverlayStore>()
                navigator.clipboard.writeText(content)
                alertOverlayStore.notify(flowOf("Copied $content"))
            }
        }
    }


fun RenderContext.twCopyClipboardButton(content: String) =
    twMediumIconButton(FormationUIIcons.Copy) {
        clicks handledBy {
            withKoin {
                val alertOverlayStore = get<AlertOverlayStore>()
                navigator.clipboard.writeText(content)
                alertOverlayStore.notify(flowOf("Copied $content"))
            }
        }
    }


fun RenderContext.twAddContentButton(block: (HtmlTag<HTMLDivElement>.() -> Unit)? = null) =
    div("flex flex-row w-full h-10 items-center justify-center p-2 rounded-xl cursor-pointer hover:text-gray-500") { // border-2 border-dashed border-gray-100 hover:border-formationBlack
        block?.invoke(this)
    }


fun RenderContext.twFlatBoxRow(block: HtmlTag<HTMLDivElement>.() -> Unit) =
    div("flex flex-row items-center justify-between max-w-full grow disabled:text-gray-300 bg-gray-100 text-slate-500 overflow-hidden") {
        block.invoke(this)
    }


fun RenderContext.twFlatBoxRowCenter(block: HtmlTag<HTMLDivElement>.() -> Unit) =
    div("flex flex-row items-center justify-center max-w-full grow p-2 disabled:text-gray-300 bg-gray-100 text-slate-500 overflow-hidden") {
        block.invoke(this)
    }


fun RenderContext.twFlatBoxColStart(block: HtmlTag<HTMLDivElement>.() -> Unit) =
    div("flex flex-col items-start justify-center max-w-full grow disabled:text-gray-300 bg-gray-100 text-slate-500") {
        block.invoke(this)
    }


fun RenderContext.twFlatIconBox(block: HtmlTag<HTMLDivElement>.() -> Unit) =
    div("flex items-center justify-center p-2 disabled:text-gray-300 bg-gray-100 text-slate-500") {
        block.invoke(this)
    }


fun RenderContext.twFlatButton(block: HtmlTag<HTMLDivElement>.() -> Unit) =
    div("flex flex-row items-center justify-center max-w-full grow disabled:text-gray-300 bg-gray-100 text-slate-500 cursor-pointer bg-gray-200 hover:bg-gray-300 hover:text-slate-700 overflow-hidden") {
        block.invoke(this)
    }


fun RenderContext.twFlatIconButton(
    icon: IconEnum,
    iconPosition: Position = Position.Right,
    disabled: Flow<Boolean> = flowOf(false),
    block: HtmlTag<HTMLDivElement>.() -> Unit,
) = div("flex items-center justify-center p-3 gap-2") {
    className(
        disabled.map {
            if (it) "bg-transparent border border-gray-300 text-gray-300"
            else "cursor-pointer bg-gray-200 hover:bg-gray-300 text-slate-500 hover:text-slate-700 border border-gray-200 hover:border-gray-300"
        },
    )
    if (iconPosition == Position.Right) block.invoke(this)
    twIconMedium(icon)
    if (iconPosition == Position.Left) block.invoke(this)
}

fun RenderContext.twFlatIconButtonLight(
    icon: IconEnum,
    iconPosition: Position = Position.Right,
    disabled: Flow<Boolean> = flowOf(false),
    block: HtmlTag<HTMLDivElement>.() -> Unit,
) = div("flex items-center justify-center p-3 gap-2") {
    className(
        disabled.map {
            if (it) "bg-transparent border border-gray-100 text-gray-100"
            else "cursor-pointer hover:bg-gray-100 text-slate-500 hover:text-slate-700 border border-transparent hover:border-gray-100"
        },
    )
    if (iconPosition == Position.Right) block.invoke(this)
    twIconMedium(icon)
    if (iconPosition == Position.Left) block.invoke(this)
}


fun RenderContext.twFlatCopyClipboardButton(content: String) =
    twFlatIconButton(FormationUIIcons.Copy) {
        clicks handledBy {
            withKoin {
                val alertOverlayStore = get<AlertOverlayStore>()
                navigator.clipboard.writeText(content)
                alertOverlayStore.notify(flowOf("Copied $content"))
            }
        }
    }



