package measuringTool

import analytics.AnalyticsCategory
import analytics.AnalyticsService
import dev.fritz2.core.RootStore
import koin.koinCtx
import kotlinx.coroutines.Job
import maplibreGL.MaplibreMap

class MeasuringToolStore : RootStore<Boolean>(false, Job()) {

    val maplibreMap: MaplibreMap by koinCtx.inject()
    val analyticsService by koinCtx.inject<AnalyticsService>()

    val toggle = handle { current ->
        analyticsService.createEvent(
            AnalyticsCategory.MeasuringTool,
        ) {
            recordAction("toggle", target = "state-${!current}")
        }
        if (!current) {

            maplibreMap.enableMeasuringTool()
        } else {
            maplibreMap.disableMeasuringTool()
        }
        !current
    }

    init {
        maplibreMap.disableMeasuringTool()
    }
}
