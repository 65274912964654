package network

import dev.fritz2.core.RootStore
import dev.fritz2.core.invoke
import generated.OFFLINE_MODE
import koin.koinCtx
import kotlinx.browser.window
import kotlinx.coroutines.Job
import localization.TL
import localization.Translation
import overlays.AlertOverlayStore
import overlays.SplashOverlayStore

enum class NetworkState {
    Online, Offline
}

class NetworkStateStore : RootStore<NetworkState?>(
    initialData = null,
    job = Job(),
) {

    private val alertOverlayStore: AlertOverlayStore by koinCtx.inject()
    private val splashOverlayStore: SplashOverlayStore by koinCtx.inject()
    private val translation: Translation by koinCtx.inject()

    fun checkOnlineStatus() {
        if (OFFLINE_MODE) {
            update(NetworkState.Online)
            console.log("App is in Offline Mode")
        } else {
            console.log("Check online status. User is online:", window.navigator.onLine)
            if (window.navigator.onLine) update(NetworkState.Online) else update(NetworkState.Offline)
        }
    }

    fun addNetworkListener() {
        if (!OFFLINE_MODE) {
            window.addEventListener("offline", {
                update(NetworkState.Offline)
                console.log("Offline")
            })
            window.addEventListener("online", {
                update(NetworkState.Online)
                console.log("Online")
                alertOverlayStore.notify(translation[TL.Network.YOU_ARE_BACK_ONLINE])
                splashOverlayStore.reset()
            })
        }
    }

    init {
        checkOnlineStatus()
    }
}
