package twcomponents

import dev.fritz2.core.RenderContext
import dev.fritz2.core.Store
import dev.fritz2.core.accept
import dev.fritz2.core.type
import js.buffer.ArrayBuffer
import js.typedarrays.Int8Array
import kotlin.random.Random
import kotlin.random.nextULong
import org.w3c.dom.HTMLInputElement
import org.w3c.files.FileReader
import org.w3c.files.get
import org.w3c.xhr.ProgressEvent
import utils.getImageDimensions
import utils.toDataUrl

data class SelectedFile(val name: String, val type: String, val size: Long, val content: ByteArray)

val SelectedFile.mimeType: String
    get() = when (type.lowercase()) {
        // Images
        "png" -> "image/png"
        "jpg", "jpeg" -> "image/jpeg"
        "gif" -> "image/gif"
        "webp" -> "image/webp"
        "bmp" -> "image/bmp"
        "tiff", "tif" -> "image/tiff"
        "svg" -> "image/svg+xml"

        // Documents
        "pdf" -> "application/pdf"
        "doc" -> "application/msword"
        "docx" -> "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        "xls" -> "application/vnd.ms-excel"
        "xlsx" -> "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        "ppt" -> "application/vnd.ms-powerpoint"
        "pptx" -> "application/vnd.openxmlformats-officedocument.presentationml.presentation"
        "txt" -> "text/plain"
        "csv" -> "text/csv"

        // Compressed files
        "zip" -> "application/zip"
        "rar" -> "application/x-rar-compressed"
        "7z" -> "application/x-7z-compressed"
        "tar" -> "application/x-tar"
        "gz" -> "application/gzip"

        // Audio
        "mp3" -> "audio/mpeg"
        "wav" -> "audio/wav"
        "ogg" -> "audio/ogg"
        "aac" -> "audio/aac"

        // Video
        "mp4" -> "video/mp4"
        "avi" -> "video/x-msvideo"
        "mov" -> "video/quicktime"
        "mkv" -> "video/x-matroska"
        "webm" -> "video/webm"

        // Fallback
        else -> "application/octet-stream" // generic binary file
    }

val SelectedFile.isImage get() = mimeType.startsWith("image/")

suspend fun SelectedFile.dimensions() = getImageDimensions(dataUrl)

val SelectedFile.dataUrl get() = content.toDataUrl(mimeType)



fun RenderContext.fileInput(
    byteStore: Store<SelectedFile?>,
    fileType: String = ".png,.gif,.jpg,.webp,.jpeg,.tiff",
    fileInputId: String = "file-input-${Random.nextULong()}",
    baseClass: String? = """
        file:my-2 file:text-formationWhite file:font-medium file:bg-formationBlack
        enabled:hover:file:bg-gray-300 enabled:hover:file:text-formationBlack
        focus:file:ring-button-300 focus:file:ring-4 file:rounded-lg file:px-5 file:py-2.5
        focus:file:outline-none enabled:hover:file:cursor-pointer
    """.trimIndent()
) {
    input(baseClass = baseClass, id = fileInputId) {
        type("file")
        accept(fileType)
        changes handledBy {
            it.currentTarget?.let { t ->
                val inputElement = t as HTMLInputElement
                inputElement.files?.get(0)?.let { file ->
                    val reader = FileReader()
                    reader.onload = {
                        val pe = it as ProgressEvent
                        val arrayBuffer = (pe.target as FileReader).result as ArrayBuffer
                        val int8Array = Int8Array(arrayBuffer)
                        val byteArray = int8Array.asByteArray()

                        byteStore.update(
                            SelectedFile(file.name, file.type, file.size.toLong(), byteArray),
                        )
                        it
                    }
                    reader.readAsArrayBuffer(file)
                }
            }
        }
    }
}
