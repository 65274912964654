package utils

/**
 * A sealed class representing the result of an asynchronous operation that can be in one of three states:
 * [Pending], [Success], or [Failure].
 *
 * @param T The type of the value returned in the [Success] state.
 */
sealed class DeferredResult<out T> {

    /**
     * Represents a successful result of an asynchronous operation.
     *
     * @param T The type of the successful result.
     * @property data The data returned from the successful operation.
     */
    data class Success<out T>(val data: T) : DeferredResult<T>()

    /**
     * Represents a failure result of an asynchronous operation, containing an error.
     *
     * @property error The [Throwable] that caused the failure.
     */
    data class Failure(val error: Throwable) : DeferredResult<Nothing>()

    /**
     * Represents the pending state of an asynchronous operation that has not completed yet.
     */
    data object Pending : DeferredResult<Nothing>()
}
