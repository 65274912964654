package workspacetools.workspaceoptions

import analytics.AnalyticsCategory
import analytics.AnalyticsService
import apiclient.FormationClient
import apiclient.geoobjects.LatLon
import apiclient.geoobjects.distanceTo
import apiclient.groups.Group
import apiclient.groups.GroupDetails
import apiclient.groups.restUpdateGroupDetails
import auth.CurrentWorkspaceStore
import com.tryformation.localization.Translatable
import dev.fritz2.core.HtmlTag
import dev.fritz2.core.Store
import dev.fritz2.core.disabled
import dev.fritz2.core.placeholder
import dev.fritz2.core.storeOf
import koin.withKoin
import kotlinx.coroutines.flow.filterNotNull
import kotlinx.coroutines.flow.map
import localization.translate
import map.MapStateStore
import org.w3c.dom.HTMLDivElement
import overlays.BusyStore
import twcomponents.twCheckButton
import twcomponents.twInputField
import twcomponents.twInputTextField
import twcomponents.twMarkdownContent
import twcomponents.twPrimaryButton
import twcomponents.twRevertButton
import twcomponents.twRightAlignedButtonRow
import twcomponents.twRowOf
import utils.getDistanceString

internal enum class CoordinateEditingState : Translatable {
    Same,
    CoordinateInvalid,
    Valid,
    ;

    override val prefix = "coordinate-editing-state"
}

enum class WorkspaceOptionsMapPositionEditorTexts : Translatable {
    UseCurrentMapPosition
    ;

    override val prefix = "workspace-options-map-position-editor-texts"
}

fun HtmlTag<HTMLDivElement>.defaultMapPositionEditor(group: Group) {
    withKoin {
        val currentLocation = group.defaultMapCenter?.let { "${it.lat}, ${it.lon}" }.orEmpty()
        val coordinateStore = storeOf(currentLocation)
        val coordinateEditorStateStore = storeOf(CoordinateEditingState.Same)


        coordinateStore.data handledBy { checkCoordinate(group.defaultMapCenter, it, coordinateEditorStateStore) }

        val mapStateStore = get<MapStateStore>()

        h2 {
            translate(WorkspaceOptionsTexts.WorkspaceDefaultMapLocation)
        }
        twMarkdownContent(WorkspaceOptionsTexts.WorkspaceDefaultMapLocationExplanation)

        div("flex flex-col gap-5") {
            div("w-full sm:w-[700px]") {
                twInputField(coordinateStore) {
                    twInputTextField {
                        placeholder("52.541165, 13.39084")
                    }

                    twRightAlignedButtonRow {
                        coordinateEditorStateStore.data.render { state ->
                            when (state) {
                                CoordinateEditingState.Same -> {
                                    div {
                                    }
                                    twRevertButton(coordinateStore, currentLocation)
                                    twCheckButton {
                                        disabled(true)
                                    }
                                }

                                CoordinateEditingState.CoordinateInvalid -> {
                                    div {
                                        translate(state)
                                    }
                                    twRevertButton(coordinateStore, currentLocation)
                                    twCheckButton {
                                        disabled(true)
                                    }
                                }

                                CoordinateEditingState.Valid -> {
                                    coordinateStore.data.map { it.parseLatLon() }.filterNotNull().render { newC ->
                                        val moveDistance = group.defaultMapCenter?.let { existing ->
                                            newC.distanceTo(existing)
                                        }
                                        div {
                                            +moveDistance.getDistanceString()
                                        }
                                    }
                                    twRevertButton(coordinateStore, currentLocation)
                                    twCheckButton {
                                        clicks handledBy {
                                            updateDefaultMapPosition(
                                                group,
                                                coordinateStore.current.parseLatLon() ?: error("should not be null if state validates"),
                                            )
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            twRowOf {
                coordinateStore.data.render { c ->
                    val cc = c.parseLatLon()
                    mapStateStore.data.render { mapState ->
                        val mapC = mapState?.center

                        val distance = (cc?.let { cc.distanceTo(mapC!!) } ?: 0.0).getDistanceString()
                        //FIXME translation "Use Current Map Center: $distance"
                        twPrimaryButton(
                            text = WorkspaceOptionsMapPositionEditorTexts.UseCurrentMapPosition,
                            translationArgs = mapOf(
                                "distance" to distance,
                            ),
                        ) {
                            clicks handledBy {
                                if (mapC != null) {
                                    coordinateStore.update(mapC.let { "${it.lat}, ${it.lon}" })
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

private suspend fun updateDefaultMapPosition(
    group: Group,
    coordinate: LatLon
) {
    withKoin {
        console.log("Set default map position  to $coordinate")
        val formationClient = get<FormationClient>()
        val busyStore = get<BusyStore>()
        val currentWorkspaceStore = get<CurrentWorkspaceStore>()
        val analyticsService = get<AnalyticsService>()
        busyStore.withBusy(
            {
                formationClient.restUpdateGroupDetails(
                    group.groupId,
                    GroupDetails(
                        name = group.name,
                        defaultMapCenter = coordinate,
                        defaultMapZoomLevel = 16.0,
                    ),
                )
            },
        ) {
            analyticsService.createEvent(
                AnalyticsCategory.WorkspaceOptions,
            ) {
                recordAction("change-default-pos", target = "${coordinate.lat},${coordinate.lon}")
            }

            currentWorkspaceStore.update(it)
        }
    }
}

internal fun String.parseLatLon(): LatLon? {
    val re = """\s*(-?\d+(\.\d+)?)\s*,\s*(-?\d+(\.\d+)?)\s*""".toRegex()
    return re.matchEntire(this)?.let { match ->
        val latitude = match.groups[1]?.value?.toDoubleOrNull()
        val longitude = match.groups[3]?.value?.toDoubleOrNull()
        if (latitude != null && longitude != null) {
            LatLon(lat = latitude, lon = longitude)
        } else {
            null
        }
    }
}

internal fun checkCoordinate(
    defaultMapCenter: LatLon?,
    newCoordinate: String,
    coordinateEditorStateStore: Store<CoordinateEditingState>
) {
    val c = newCoordinate.parseLatLon()
    if (c != null && c.lat in -90.0..90.0 && c.lon in -180.0..180.0) {
        if (defaultMapCenter?.lat == c.lat && defaultMapCenter.lon == c.lon) {
            coordinateEditorStateStore.update(CoordinateEditingState.Same)
        } else {
            coordinateEditorStateStore.update(CoordinateEditingState.Valid)
        }
    } else {
        coordinateEditorStateStore.update(CoordinateEditingState.CoordinateInvalid)
    }
}
