package mainmenu

import data.users.views.MyProfilePages
import dev.fritz2.components.flexBox
import dev.fritz2.core.RenderContext
import koin.koinCtx
import localization.TL
import localization.Translation
import dev.fritz2.components.compat.span
import routing.MainController
import webcomponents.*

fun RenderContext.pageNewPassword() {
    val translation: Translation by koinCtx.inject()
    val mainController: MainController by koinCtx.inject()

    baseLayout(
        expandable = false,
        content = {
            contentScrollBox {
                flexBox({ flex { grow { "1" } } }) { }

                flexBox({
                    width { full }
                    justifyContent { center }
                    textAlign { center }
                    flex { grow { "1" } }
                }) { mainTitle(translation[TL.UserProfile.NEW_PASSWORD_NEEDED]) }

                flexBox({
                    direction { column }
                    width { full }
                    alignItems { center }
                    justifyContent { spaceEvenly }
                    textAlign { center }
                    padding { normal }
                    flex { grow { "1" } }
                }) {
                    span({
                        fontSize { large }
//                        fontWeight { bold }
                        flex { grow { "1" } }
                    }) {
                        translation[TL.UserProfile.NEW_PASSWORD_INFO_TEXT].renderText(into = this)
                    }

                }

                flexBox({ flex { grow { "1" } } }) { }
            }
        },
        footer = {
            twoButtonFooter(
                primaryTitle = translation[TL.UserProfile.SET_NEW_PASSWORD],
                primaryValue = Unit,
                primaryAddOrReplaceRoute = Pages.MyProfile.route + MyProfilePages.ChangePasswordEnter.route,
                secondaryTitle = translation[TL.PageLogout.LOGOUT],
                secondaryClickHandlers = listOf(mainController.logoutWithParams),
            )
        }
    )
}
