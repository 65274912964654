package data.objects.views.cardinfo

import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.ObjectTags
import apiclient.geoobjects.ObjectType
import apiclient.tags.getUniqueTag
import auth.ApiUserStore
import data.objects.ActiveObjectStore
import data.objects.views.directediting.directEditingCardContent
import data.users.UserListStore
import dev.fritz2.components.flexBox
import dev.fritz2.components.icon
import dev.fritz2.core.RenderContext
import koin.koinCtx
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import kotlinx.datetime.Clock
import localization.TL
import localization.Translation
import mainmenu.RouterStore
import model.L
import theme.FormationColors
import theme.FormationIcons
import utils.formatEventTimeSpan
import utils.getColor
import utils.getIcon
import utils.getTitle
import utils.merge
import webcomponents.baseLayout
import webcomponents.cardTitleWithSubtitle
import webcomponents.cardTitleWithSubtitleDirectEdit
import webcomponents.ellipseText
import webcomponents.selectorButton
import webcomponents.selectorButtonFrame
import webcomponents.selectorContent

fun RenderContext.taskInfoCardContent() {
    val objectType = ObjectType.Task
    val translation: Translation by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()
    val activeObjectStore: ActiveObjectStore by koinCtx.inject()
    val apiUserStore: ApiUserStore by koinCtx.inject()
    val userListStore: UserListStore by koinCtx.inject()
    val title = activeObjectStore.map(GeoObjectDetails.L.title)
    val taskState = activeObjectStore.map(GeoObjectDetails.L.taskState)
    val startTime = activeObjectStore.map(GeoObjectDetails.L.atTime)
    val assigneeId = activeObjectStore.map(GeoObjectDetails.L.assignedTo)
    val assignee = assigneeId.data.map { userListStore.getPublicUserProfile(it) }
    val externalId = activeObjectStore.map(GeoObjectDetails.L.tags).data.map { it.getUniqueTag(ObjectTags.ExternalId) }
    val canModify = activeObjectStore.map(GeoObjectDetails.L.canModify)
    val canManage = activeObjectStore.map(GeoObjectDetails.L.canManage)

    baseLayout(
        header = {
            flexBox(
                {
                    direction { row }
                    justifyContent { spaceBetween }
                    alignItems { start }
                    width { full }
                    height { maxContent }
                },
            ) {
                // CARD TITLE
                combine(assignee, apiUserStore.data) { assigneeProfile, currentUser ->
                    translation[TL.ObjectTypeTitle.TASK].merge(
                        flow = translation[
                            TL.CardInfo.ASSIGNED_TO,
                            mapOf(
                                "user" to (
                                    assigneeProfile?.let { profile ->
                                        if (profile.userId == currentUser.userId) translation.getString(
                                            TL.General.YOU, mapOf("case" to "accusative"),
                                        )
                                        else "${profile.firstName} ${profile.lastName}"
                                    } ?: translation.getString(TL.General.NO_ONE)
                                    ),
                            ),
                        ],
                        separator = " - ",
                    )
                }.render { subtitle ->
                    canManage.data.combine(canModify.data) { manage, modify -> manage || modify }
                        .render { editAccess ->
                            if (editAccess) {
                                cardTitleWithSubtitleDirectEdit(
                                    titleFlow = title.data,
                                    title = title.current,
                                    subtitle = subtitle,
                                    titleIconFlow = flowOf(objectType.getIcon().icon),
                                    subtitleIconFlow = externalId.map { if (!it.isNullOrBlank()) FormationIcons.QRCode.icon else null },
                                    changeHandler = activeObjectStore.editTitle,
                                )
                            } else {
                                cardTitleWithSubtitle(
                                    title = title.data,
                                    subtitle = subtitle,
                                    titleIconFlow = flowOf(objectType.getIcon().icon),
                                    subtitleIconFlow = externalId.map { if (!it.isNullOrBlank()) FormationIcons.QRCode.icon else null },
                                )
                            }
                        }
                }
                infoCardButtons()
            }
        },
        content = {
            directEditingCardContent {
                // TASK DUE DATE
                selectorButtonFrame {
                    selectorContent {
                        icon({ margins { horizontal { smaller } }; size { normal } }) { fromTheme { FormationIcons.Time.icon } }
                        startTime.data.render { time ->
                            ellipseText(
                                styleParams = {
                                    if (time != null && time.toEpochMilliseconds() < Clock.System.now()
                                            .toEpochMilliseconds()
                                    ) {
                                        color { FormationColors.RedError.color }
                                    }
                                },
                            ) {
                                translation[TL.CardInfo.DUE_DATE].merge(
                                    formatEventTimeSpan(
                                        startTime.data,
                                        flowOf(null),
                                    ),
                                ).renderText(into = this)
                            }
                        }
                    }
                }

                // TASKSTATE BUTTON
                val disableButton = !activeObjectStore.current.canManage &&
                    apiUserStore.current.userId != activeObjectStore.current.ownerId &&
                    apiUserStore.current.userId != activeObjectStore.current.assignedTo

                taskState.data.render { taskState ->
                    if (taskState != null) {
                        selectorButton(disabled = disableButton) {
                            selectorContent(disabled = disableButton) {
                                icon(
                                    {
                                        margins { horizontal { smaller } }
                                        size { normal }
                                    },
                                ) { fromTheme { FormationIcons.TaskAlt.icon } }

                                ellipseText { translation[TL.CardInfo.STATUS].renderText(into = this) }
                                colorDot(taskState.getColor())
                                ellipseText { +taskState.getTitle() }

                            }
                            if (!disableButton) {
                                clicks.map {
                                    mapOf("show" to "taskState")
                                } handledBy routerStore.addOrReplaceRoute
                            }
                        }
                    }
                }

                // OPTIONAL ASSIGN / UNASSIGN YOURSELF
                assignee.render { assigneeProfile ->
                    when (assigneeProfile) {
                        null -> {
                            selectorButton(selectorIcon = null) {
                                selectorContent(margins = { horizontal { tiny } }) {
                                    icon({ margins { horizontal { smaller } }; size { normal } }) { fromTheme { FormationIcons.UserAlt.icon } }
                                    ellipseText(
                                        styleParams = {
                                            width { full }
                                            textAlign { left }
                                        },
                                    ) { translation[TL.CardInfo.ASSIGN_YOURSELF].renderText(into = this) }
                                }
                                clicks handledBy activeObjectStore.assignYourselfToTask
                            }
                        }

                        else -> {
                            if (apiUserStore.current.userId == assigneeId.current) {
                                selectorButton(selectorIcon = { close }) {
                                    selectorContent(margins = { horizontal { tiny } }) {
                                        icon({ margins { horizontal { smaller } }; size { normal } }) { fromTheme { FormationIcons.UserAlt.icon } }
                                        ellipseText(
                                            styleParams = {
                                                width { full }
                                                textAlign { left }
                                            },
                                        ) { translation[TL.CardInfo.UNASSIGN_YOURSELF].renderText(into = this) }
                                    }
                                    clicks handledBy activeObjectStore.unassignYourselfFromTask
                                }
                            }
                        }
                    }
                }
            }
        },
        footer = {
            // CARD BUTTONS FOOTER WITH: [BACK] [EDIT]
            infoCardEditFooter(objectType)
        },
    )
}
