@file:OptIn(FlowPreview::class)

package workspacetools.usermanagement

import analyticsdashboard.AnalyticsTextFilterStore
import analyticsdashboard.AnalyticsTimeFilterStore
import apiclient.FormationClient
import apiclient.geoobjects.ChangeType
import apiclient.geoobjects.HistoryTags
import apiclient.geoobjects.ObjectType
import apiclient.geoobjects.SearchQueryContext
import apiclient.geoobjects.SortByField
import apiclient.geoobjects.SortField
import apiclient.geoobjects.SortOrder
import apiclient.groups.MemberShipResponsePublic
import apiclient.groups.inviteNewUser
import apiclient.groups.restAddGroupRole
import apiclient.groups.restDeleteAndRemoveUser
import apiclient.groups.restListGroupMembersForGroup
import apiclient.groups.restRemoveGroupRole
import apiclient.parseResponseString
import apiclient.tags.tag
import apiclient.users.restGroupAdminSetNewPasswordForUser
import auth.CurrentWorkspaceStore
import dev.fritz2.core.RootStore
import dev.fritz2.core.invoke
import dev.fritz2.core.storeOf
import koin.koinCtx
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.FlowPreview
import kotlinx.coroutines.Job
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.debounce
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.mapNotNull
import kotlinx.coroutines.launch
import localization.Translation
import org.w3c.dom.events.MouseEvent
import overlays.AlertOverlayStore
import overlays.BusyStore
import overlays.handlerScope
import workspacetools.zoneexporter.ZoneTexts

class CopyToCLipboardStore : RootStore<String>(
    initialData = "",
    job = Job(),
) {
    val alertOverlayStore by koinCtx.inject<AlertOverlayStore>()

    val copyThat = handle<String> { _, new ->
        if (new.isNotBlank()) {
            window.navigator.clipboard.writeText(new)
            alertOverlayStore.notify(flowOf("Copied $new"))
        }
        new
    }
}

class SelectedGroupMemberStore : RootStore<MemberShipResponsePublic?>(
    initialData = null,
    job = Job(),
) {
    private val busyStore by koinCtx.inject<BusyStore>()
    private val formationClient by koinCtx.inject<FormationClient>()
    private val groupMembersStore by koinCtx.inject<GroupMembersStore>()
    private val alertOverlayStore by koinCtx.inject<AlertOverlayStore>()
    private val currentWorkspaceStore by koinCtx.inject<CurrentWorkspaceStore>()
    private val copyToClipboardStore by koinCtx.inject<CopyToCLipboardStore>()
    private val translation by koinCtx.inject<Translation>()

    val changeRole = handle<String> { old, role ->
        old?.let { m ->
            busyStore.handleApiCall(
                supplier = suspend {
                    m.roles.forEach { r ->
                        formationClient.restRemoveGroupRole(userId = m.userId, groupId = m.groupId, role = r)
                    }
                    formationClient.restAddGroupRole(userId = m.userId, groupId = m.groupId, role = role)
                },
                successMessage = translation[
                    DashboardTexts.ChangeRoleSuccessful, mapOf(
                        "role" to role,
                        "name" to (m.userProfile?.name ?: m.userId)
                    )
                ],
                processResult = {
                    groupMembersStore.loadMembers() // will indirectly update the store value
                },
                processError = {
                    console.log(it)
                    alertOverlayStore.errorNotify(
                        translation[
                            DashboardTexts.ChangeRoleFailed, mapOf(
                                "role" to role,
                                "message" to (it.message ?: "")
                            )
                        ]
                    )
                },
                withBusyState = true,
                busyStateMessage = translation[DashboardTexts.WaitMessage]
            )
        }
        old
    }

    val delete = handle {
        val groupId = currentWorkspaceStore.current?.groupId ?: error("no current group")
        val user = current ?: error("no current user")
        val userId = user.userId
        busyStore.handleApiCall(
            supplier = suspend {
                formationClient.restDeleteAndRemoveUser(userId = userId, groupId = groupId)
            },
            successMessage = translation[
                DashboardTexts.DeleteUserSuccessful, mapOf(
                    "name" to (user.userProfile?.name ?: user.userId)
                )
            ],
            processResult = {
                update(null)
            },
            processError = { t ->
                alertOverlayStore.errorNotify(
                    translation[
                        DashboardTexts.DeleteUserFailed, mapOf(
                            "name" to (user.userProfile?.name ?: user.userId),
                            "message" to (t.message ?: t::class.simpleName ?: t.toString())
                        )
                    ]
                )
            },
            withBusyState = true,
            busyStateMessage = translation[DashboardTexts.WaitMessage],
        )

        it
    }

    val setNewPassword = handle<String> { old, newPassword ->
        busyStore.withBusy(
            suspend {
                val groupId = currentWorkspaceStore.current?.groupId ?: error("no current group")
                val userId = current?.userId ?: error("no current group member")
                formationClient.restGroupAdminSetNewPasswordForUser(groupId, userId, newPassword)
            },
            waitMessage = translation[DashboardTexts.WaitMessage],
            processResult = {
                update(null)
                alertOverlayStore.notify(translation[DashboardTexts.SetNewPasswordSuccess])
                copyToClipboardStore.copyThat(newPassword)
            },
            processError = {
                alertOverlayStore.errorNotify(
                    translation[
                        DashboardTexts.SetNewPasswordFailed, mapOf(
                            "message" to (it.message ?: it)
                        )
                    ]
                )

            })
        old
    }

    private val refreshFromMemberList = handle<List<MemberShipResponsePublic>> { _, members ->
        members.firstOrNull {
            it.userId == current?.userId
        }
    }

    init {
        groupMembersStore.data handledBy refreshFromMemberList
    }
}

class GroupMembersStore : RootStore<List<MemberShipResponsePublic>>(
    initialData = emptyList(),
    job = Job(),
) {
    val formationClient by koinCtx.inject<FormationClient>()
    private val currentWorkspaceStore by koinCtx.inject<CurrentWorkspaceStore>()
    private val busyStore by koinCtx.inject<BusyStore>()
    val alertOverlayStore by koinCtx.inject<AlertOverlayStore>()
    private val translation by koinCtx.inject<Translation>()

    val enabledStore = storeOf(false, job)

    val loadMembers = handle { existing ->
        if (enabledStore.current) {
            console.log("fetching group members")
            val group = currentWorkspaceStore.current
            if (group != null) {
                busyStore.handleApiCall(
                    supplier = suspend {
                        formationClient.restListGroupMembersForGroup(groupId = group.groupId)
                    },
                    processResult = { members ->
                        update(members)
                    },
                    withBusyState = true,
                    busyStateMessage = translation[DashboardTexts.LoadingDashboard],
                )
            }
        }
        existing
    }

    init {
        // combine these triggers, so that it only loads members, if the dashboard is actually rendered
        enabledStore.data.combine(currentWorkspaceStore.data) { e, w -> Pair(e, w) }.mapNotNull { (enabled, _) ->
            if (enabled) Unit else null
        } handledBy loadMembers
    }
}

class InvitationEmailStore : RootStore<String>(
    initialData = "",
    job = Job(),
) {
    val formationClient by koinCtx.inject<FormationClient>()
    private val busyStore by koinCtx.inject<BusyStore>()
    private val currentWorkspaceStore by koinCtx.inject<CurrentWorkspaceStore>()
    val alertOverlayStore by koinCtx.inject<AlertOverlayStore>()
    val translation by koinCtx.inject<Translation>()


    val inviteNewUser = handle { old ->
        val groupId = currentWorkspaceStore.current?.groupId ?: error("group should not be null")
        if (current.isNotBlank()) {
            busyStore.handleApiCall(
                suspend {
                    formationClient.inviteNewUser(groupId, current)
                },
                processResult = {
                    update("")
                    alertOverlayStore.notify(
                        translation[
                            DashboardTexts.InviteUserSuccessful,
                            mapOf("email" to current)
                        ]
                    )

                },
                processError = {
                    alertOverlayStore.errorNotify(
                        translation[
                            DashboardTexts.InviteUserFailed,
                            mapOf(
                                "email" to current,
                                "message" to (it.message ?: it)
                            )
                        ]
                    )
                },
                withBusyState = true,
                busyStateMessage = translation[DashboardTexts.WaitMessage],
            )
        }
        old
    }
}

class TsvStore : RootStore<String>(
    initialData = "",
    job = Job(),
) {
    private val formationClient by koinCtx.inject<FormationClient>()
    private val busyStore by koinCtx.inject<BusyStore>()
    val translation by koinCtx.inject<Translation>()

    private val currentWorkspaceStore by koinCtx.inject<CurrentWorkspaceStore>()
    private val timeFilterStore: AnalyticsTimeFilterStore by koinCtx.inject()
    private val textFilterStore: AnalyticsTextFilterStore by koinCtx.inject()

    val linkId = "export_zone_tsv"
    val search = handle { old ->

        val group = currentWorkspaceStore.current
        if (group != null) {
            busyStore.handleApiCall(
                supplier = suspend {
                    formationClient.restPost(
                        body = formationClient.jsonBody(
                            serializationStrategy = SearchQueryContext.serializer(),
                            body = SearchQueryContext(
                                size = 10000,
                                groupIds = listOf(group.groupId),
                                objectTypes = listOf(ObjectType.HistoryEntry),
                                text = textFilterStore.current,
//                                fromTime = "now-${days}d",
                                fromTime = timeFilterStore.current.filterStartDate,
                                toTime = timeFilterStore.current.filterEndDate,
                                timeRangeOnCreatedAt = true,
                                orTags = listOf(
                                    HistoryTags.ChangeType.tag(ChangeType.TrackedObjectEnterZone),
                                    HistoryTags.ChangeType.tag(ChangeType.TrackedObjectExitZone),
//                                    HistoryTags.ChangeType.tag(ChangeType.ObjectMoved) // TODO decide if we want object movements outside of zones, too
                                ),
                                sort = listOf(
                                    SortByField(SortField.CREATED_AT, SortOrder.DESC)
                                ),
                            )
                        ),
                        params = null,
                        pathElements = arrayOf(
                            "export",
                            "custom",
                            "simple-exporter"
                        )
                    ).parseResponseString()
                },
                processResult = {
                    update(it)
                },
                processError = {
                    console.error(it)
                },
                withBusyState = true,
                busyStateMessage = translation[ZoneTexts.Generating],
            )
        }
        old
    }

    val download = handle { old ->
        handlerScope.launch {
            // wait a bit to give the browser the chance to update the href in response to the store update
            // the href will contain the tsv we just downloaded in plaintext form ...
            delay(200)
            val e = document.createEvent("MouseEvents") as MouseEvent
            e.initEvent("click", bubbles = true, cancelable = true)
            // issue a click on the link to cause the download to happen
            document.getElementById(linkId)?.dispatchEvent(e)
                ?: console.log("could not find link to click")
        }
        old
    }


    init {
        combine(timeFilterStore.data, textFilterStore.data) { _, _ -> }.debounce(200) handledBy search
    }
}
