package objectrouting

import apiclient.geoobjects.LatLon
import apiclient.users.UserFeatureFlag
import data.objects.ActiveObjectStore
import dev.fritz2.core.HtmlTag
import dev.fritz2.core.RenderContext
import dev.fritz2.core.href
import dev.fritz2.core.target
import dev.fritz2.styling.theme.IconDefinition
import koin.koinCtx
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.map
import mainmenu.RouterStore
import org.w3c.dom.HTMLDivElement
import search.titleSizedText
import theme.FormationIcons
import theme.FormationUIIcons
import twcomponents.doIfUserFeatureFlagEnabled
import twcomponents.twColOfNoGap
import twcomponents.twFlatIconButton
import twcomponents.twIconMedium
import twcomponents.twIconSmall
import utils.isIos
import utils.roundTo
import webcomponents.Position
import webcomponents.ellipseText

fun RenderContext.openObjectNavigationButton() {

    val navigationToolToggleStore: NavigationToolToggleStore by koinCtx.inject()
    val navigationStore: NavigationStore by koinCtx.inject()
    val activeObjectStore: ActiveObjectStore by koinCtx.inject()
    val navigationSearchHistoryStore: NavigationSearchHistoryStore by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()

    twFlatIconButton(
        icon = FormationUIIcons.NavigationArrow.icon,
        iconPosition = Position.Left,
    ) {
        p("text-sm truncate") {
            +"Open Navigation"
        }
        clicks handledBy {
            navigationToolToggleStore.update(true)
            navigationStore.setDestinationObjectWithSourceAndLatLon(activeObjectStore.current)
            navigationSearchHistoryStore.add(activeObjectStore.current)
            activeObjectStore.resetStore(Unit)
            routerStore.goToMap(Unit)
        }
    }
}

fun RenderContext.openExternalNavigationButton() {

    val activeObjectStore: ActiveObjectStore by koinCtx.inject()

    doIfUserFeatureFlagEnabled(UserFeatureFlag.EnableDevelopmentFeatures) {
        activeObjectStore.data.render { activeObject ->
            a("w-full") {
                twFlatIconButton(icon = if (isIos()) FormationIcons.Apple.icon else FormationIcons.Map.icon) {
                    className("grow")
                    p("text-sm truncate") {
                        +"Open with ${if (isIos()) "Apple Maps" else "Google Maps"}"
                    }
                }
                val mapsLink = if (isIos()) {
                    "maps://?daddr=${activeObject.latLon.lat},${activeObject.latLon.lon}&dirflg=d"
                } else {
                    "https://www.google.com/maps/dir/?api=1&destination=${activeObject.latLon.lat},${activeObject.latLon.lon}&travelmode=driving"
                }
                target("_blank")
                href(mapsLink)
            }
        }
    }
}

fun RenderContext.navigationPointSourceRichButton(
    title: String,
    icon: IconDefinition,
    subtitleBlock: (HtmlTag<HTMLDivElement>.() -> Unit)? = null,
    latLonAsSubtitleFlow: Flow<LatLon?>? = null,
    block: HtmlTag<HTMLDivElement>.() -> Unit,
) {
    div("flex w-full items-center justify-between py-1 pl-4 pr-2 gap-2 cursor-pointer bg-gray-100 hover:bg-gray-200 rounded-xl overflow-hidden") {

        div("flex flex-row items-center justify-start gap-4 shrink-0") {
            twIconMedium(icon)
            twColOfNoGap {
                className("p-0.5 self-start min-w-0")
                titleSizedText { +title }
                if (subtitleBlock != null) {
                    subtitleBlock.invoke(this)
                } else {
                    ellipseText(
                        {
                            fontSize(sm = { tiny }, md = { smaller })
                            fontStyle { italic }
                        },
                    ) {
                        latLonAsSubtitleFlow?.map { latLon ->
                            "(${latLon?.lat?.roundTo(5)}, ${latLon?.lon?.roundTo(5)})"
                        }?.renderText(into = this)
                    }
                }
            }
        }

        block.invoke(this)
    }
}

fun RenderContext.navigationPointSourceSmallButton(
    title: String,
    icon: IconDefinition,
    block: HtmlTag<HTMLDivElement>.() -> Unit,
) {
    div("flex items-center justify-start p-2 gap-2 cursor-pointer bg-gray-100 hover:bg-gray-200 rounded-xl overflow-hidden") {

        twIconSmall(icon)
        ellipseText(
            {
                fontSize { small }
            },
        ) { +title }

        block.invoke(this)
    }
}
