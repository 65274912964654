package data.objects.views.cardinfo

import apiclient.customfields.parseFieldValues
import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.MarkerIcon
import apiclient.geoobjects.MeetingInvitationStatus
import apiclient.geoobjects.ObjectTags
import apiclient.geoobjects.ObjectType
import apiclient.geoobjects.extractAddress
import apiclient.geoobjects.pointCoordinates
import apiclient.geoobjects.tagList
import apiclient.tags.getUniqueTag
import apiclient.util.isNotNullOrEmpty
import auth.ApiUserStore
import auth.CurrentWorkspaceStore
import com.jillesvangurp.geo.GeoGeometry.Companion.roundDecimals
import com.jillesvangurp.geo.toMgrs
import com.jillesvangurp.geo.toUtmOrUps
import data.ObjectAndUserHandler
import data.objects.ActiveObjectFieldValuesStore
import data.objects.ActiveObjectKeywordsStore
import data.objects.ActiveObjectStore
import data.objects.building.CurrentBuildingsStore
import data.objects.views.copyButtonWithContent
import data.objects.views.copyButtonWithTitle
import data.objects.views.detailsSection
import data.objects.views.dotNumber
import data.objects.views.iconNumber
import data.users.UserListStore
import dev.fritz2.components.compat.div
import dev.fritz2.components.compat.span
import dev.fritz2.components.flexBox
import dev.fritz2.components.icon
import dev.fritz2.components.lineUp
import dev.fritz2.core.RenderContext
import dev.fritz2.core.SimpleHandler
import koin.koinCtx
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.filter
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import localization.TL
import localization.Translation
import mainmenu.Pages
import mainmenu.RouterStore
import model.L
import model.User
import model.userId
import overlays.dotSpinner
import qrcodegeneration.toSvgQrCode
import search.nestedObjects.NestedObjectsResultsStore
import search.separationLine
import styling.primaryButtonStyleParams
import styling.secondaryButtonStyleParams
import theme.FormationColors
import theme.FormationIcons
import utils.extractReadOnlyTags
import utils.formatDateTime
import utils.getIcon
import utils.getName
import utils.getTitle
import utils.isSameTime
import utils.merge
import utils.mergeTagLists
import utils.respectFeatureFlags
import utils.toKeyWordTagsList
import webcomponents.KeywordTagActionType
import webcomponents.KeywordTagType
import webcomponents.cardSubtitle
import webcomponents.ellipseText
import webcomponents.keywordTag
import webcomponents.overFlowContent
import webcomponents.readOnlyTextArea
import webcomponents.selectorButton
import webcomponents.selectorContent
import webcomponents.twoButtonFooter
import workspacetools.usermanagement.urlEncode

fun RenderContext.cardInfo() {

    val activeObjectStore: ActiveObjectStore by koinCtx.inject()

    when (activeObjectStore.current.objectType) {
        ObjectType.POI -> pointInfoCardContent()
        ObjectType.Task -> taskInfoCardContent()
        ObjectType.Event -> eventInfoCardContent()
        ObjectType.Building -> buildingInfoCardContent()
        ObjectType.ObjectMarker -> trackedObjectInfoCardContent()
        ObjectType.Zone -> zoneInfoCardContent()
        ObjectType.Area -> areaInfoCardContent()
        ObjectType.GeoFence -> geoFenceInfoCardContent()
        ObjectType.GeneralMarker -> generalInfoCardContent()
        else -> otherTypeInfoCardContent()
    }
}

fun RenderContext.descriptionButton(
    text: Flow<String?>,
    icon: Flow<MarkerIcon?>? = null,
    asTextField: Boolean = false,
) {
    val translation by koinCtx.inject<Translation>()
    val routerStore: RouterStore by koinCtx.inject()

    text.render { desc ->
        if (!desc.isNullOrBlank()) {
            if (asTextField) {
                if (icon != null) {
                    icon.render { i ->
                        if (i != null) {
                            lineUp(
                                {
                                    width { full }
                                    alignItems { center }
                                    justifyContent { start }
                                },
                            ) {
                                spacing { small }
                                items {
                                    icon({ size { huge } }) { fromTheme { i.getIcon().icon } }
                                    readOnlyTextArea(
                                        value = desc,
                                        placeHolder = translation[TL.General.DESCRIPTION],
                                    )
                                }
                            }
                        } else {
                            readOnlyTextArea(
                                value = desc,
                                placeHolder = translation[TL.General.DESCRIPTION],
                            )
                        }
                    }
                } else {
                    readOnlyTextArea(
                        value = desc,
                        placeHolder = translation[TL.General.DESCRIPTION],
                    )
                }
            } else {
                selectorButton {
                    selectorContent {
                        icon(
                            {
                                margins { horizontal { smaller } }
                                size { normal }
                            },
                        ) {
                            fromTheme { FormationIcons.Description.icon }
                        }
                        ellipseText { text.renderText(into = this) }
                    }
                    clicks.map {
                        mapOf("show" to "description")
                    } handledBy routerStore.addOrReplaceRoute
                }
            }
        }
    }
}

fun RenderContext.infoCardDetails() {
    val routerStore: RouterStore by koinCtx.inject()
    val translation: Translation by koinCtx.inject()
    val userListStore: UserListStore by koinCtx.inject()
    val apiUserStore: ApiUserStore by koinCtx.inject()
    val currentUserId = apiUserStore.map(User.userId())
    val activeObjectStore: ActiveObjectStore by koinCtx.inject()
    val objectId = activeObjectStore.map(GeoObjectDetails.L.id)
    val objType = activeObjectStore.map(GeoObjectDetails.L.objectType)
    val createTime = activeObjectStore.map(GeoObjectDetails.L.createdAt)
    val updateTime = activeObjectStore.map(GeoObjectDetails.L.updatedAt)
    val updatedBy = userListStore.getPublicUserProfile(activeObjectStore.map(GeoObjectDetails.L.updatedBy).current)
    val owner = activeObjectStore.map(GeoObjectDetails.L.owner)
    val position = activeObjectStore.map(GeoObjectDetails.L.latLon)
    val taskState = activeObjectStore.map(GeoObjectDetails.L.taskState)
    val meetingInvitationStatus = activeObjectStore.map(GeoObjectDetails.L.attendees).data.map { attendees ->
        attendees?.find { it.userId == currentUserId.current }?.meetingInvitationStatus
    }
    val assigneeId = activeObjectStore.map(GeoObjectDetails.L.assignedTo)
    val assignee = assigneeId.data.map { userListStore.getPublicUserProfile(it) }
    val attendees = activeObjectStore.map(GeoObjectDetails.L.attendees)
    val currentBuildingsStore: CurrentBuildingsStore by koinCtx.inject()
    val floorData =
        currentBuildingsStore.data.combine(objectId.data) { buildings, objId -> buildings[objId]?.floorData }

    val attribution =
        activeObjectStore.map(GeoObjectDetails.L.tags).data.map { tags -> tags.getUniqueTag(ObjectTags.Attribution) }
    val externalId = activeObjectStore.map(GeoObjectDetails.L.tags).data.map { it.getUniqueTag(ObjectTags.ExternalId) }
    val address = activeObjectStore.map(GeoObjectDetails.L.tags).data.map { it.extractAddress() }

    separationLine(title = translation[TL.CardInfo.OBJECT_DETAILS])

    div(
        {
            color { FormationColors.GrayDisabled.color }
        },
    ) {
        // FULL TITLE
//        detailsSection(separation = false, topMargin = false) {
//            cardSubtitle(
//                translation[TL.CardInfo.TITLE].merge(title.data),
//                iconFlow = flowOf(FormationIcons.Title.icon)
//            )
//        }
        detailsSection(topMargin = false, separation = false) {
            // TYPE DEPENDENT CONTENT
            when (objType.current) {
                // TASK -> STATUS & ASSIGNMENT
                ObjectType.Task -> {
                    combine(assignee, apiUserStore.data) { assigneeProfile, currentUser ->
                        translation[
                            TL.CardInfo.ASSIGNED_TO,
                            mapOf(
                                "user" to (
                                    assigneeProfile?.let { profile ->
                                        if (profile.userId == currentUser.userId) translation.getString(
                                            TL.General.YOU, mapOf("case" to "accusative"),
                                        )
                                        else "${profile.firstName} ${profile.lastName}"
                                    } ?: translation.getString(TL.General.NO_ONE)
                                    ),
                            ),
                        ]
                    }.render { string ->
                        detailsSection(separation = false) {
                            cardSubtitle(
                                string,
                                iconFlow = flowOf(FormationIcons.UserAlt),
                            )
                        }
                    }
                    detailsSection(separation = false) {
                        cardSubtitle(
                            translation[TL.CardInfo.STATUS].merge(
                                taskState.data.map {
                                    it?.getTitle() ?: ""
                                },
                            ),
                            iconFlow = taskState.data.map { it?.getIcon() },
                        )
                    }
                }
                // MEETING -> STATUS & ATTENDEES
                ObjectType.Event -> {
                    meetingInvitationStatus.render { status ->
                        if (status != null) {
                            detailsSection(separation = false) {
                                cardSubtitle(
                                    translation[TL.CardInfo.STATUS].merge(
                                        meetingInvitationStatus.map {
                                            it.getName()
                                        },
                                    ),
                                    iconFlow = meetingInvitationStatus.map { it?.getIcon() },
                                )
                            }
                        }
                    }
                    attendees.data.render { attendeeList ->
                        val pending = attendeeList?.filter {
                            it.meetingInvitationStatus == MeetingInvitationStatus.Pending
                        }?.size
                        val accepted = attendeeList?.filter {
                            it.meetingInvitationStatus == MeetingInvitationStatus.Accepted
                        }?.size
                        val maybe = attendeeList?.filter {
                            it.meetingInvitationStatus == MeetingInvitationStatus.Maybe
                        }?.size
                        val rejected = attendeeList?.filter {
                            it.meetingInvitationStatus == MeetingInvitationStatus.Rejected
                        }?.size
                        detailsSection(separation = false) {
                            if (!attendeeList.isNullOrEmpty()) {
                                lineUp {
                                    spacing { small }
                                    items {
                                        cardSubtitle(
                                            translation[TL.CardInfo.ATTENDEES, mapOf("size" to attendeeList.size)],
                                            iconFlow = flowOf(FormationIcons.UserGroup),
                                        )
                                        iconNumber(pending) { FormationIcons.Wait.icon }
                                        dotNumber(accepted, FormationColors.GreenActive)
                                        dotNumber(maybe, FormationColors.YellowDoing)
                                        dotNumber(rejected, FormationColors.RedError)
                                    }
                                }
                            } else {
                                cardSubtitle(
                                    translation[
                                        TL.CardInfo.ATTENDEES,
                                        mapOf(
                                            "size" to translation.getString(TL.General.NONE),
                                        ),
                                    ],
                                    iconFlow = flowOf(FormationIcons.UserGroup),
                                )
                            }
                        }
                    }
                }
                // BUILDING -> FLOORS & CONTAINED OBJECTS)
                ObjectType.Building, ObjectType.Area, ObjectType.Zone -> {
                    floorData.render { floors ->
                        if (floors != null) {
                            detailsSection(separation = false) {
                                cardSubtitle(
                                    translation[TL.CardInfo.FLOORS]
                                        .merge(
                                            flowOf(
                                                floors.entries.sortedBy { it.key }
                                                    .joinToString(", ") { (lvl, floors) ->
                                                        "${lvl}: \"${
                                                            floors.map { floor -> floor.floor.title }
                                                        }\"${
                                                            floors.mapNotNull { floor ->
                                                                if (floor.units.isNotNullOrEmpty()) {
                                                                    floor.units?.let { units ->
                                                                        if (units.isNotEmpty()) {
                                                                            " (↪ ${units.size})"
                                                                        } else ""
                                                                    }
                                                                } else null
                                                            }.ifEmpty { "" }
                                                        }"
                                                    },
                                            ),
                                            separator = ": ",
                                        ),
                                    iconFlow = flowOf(FormationIcons.Floor),
                                )
                            }
                        }
                    }
                }

                else -> {}
            }
        }


        // CREATION INFO
        detailsSection(separation = false) {
            cardSubtitle(
                translation[TL.CardInfo.CREATED_BY, mapOf("user" to (owner.current?.name ?: ""), "time" to "${createTime.current?.formatDateTime()}")],
                iconFlow = flowOf(FormationIcons.Create),
            )
            // UPDATE INFO
            createTime.data.combine(updateTime.data) { created, updated ->
                //FIXME just use string equals?
                created != null && updated != null && !isSameTime(created, updated)
            }.render { isUpdated ->
                if (isUpdated) {
                    detailsSection(separation = false) {
                        updatedBy?.let {
                            cardSubtitle(
                                translation[TL.CardInfo.UPDATED_BY, mapOf("user" to it.name, "time" to "${updateTime.current?.formatDateTime()}")],
                                iconFlow = flowOf(FormationIcons.Edit),
                            )
                        } ?: run {
                            cardSubtitle(
                                translation[
                                    TL.CardInfo.UPDATED_AT,
                                    mapOf(
                                        "time" to (updateTime.current?.formatDateTime() ?: ""),
                                    ),
                                ],
                                iconFlow = flowOf(FormationIcons.Edit),
                            )
                        }
                    }
                }
            }
        }

        // ADDRESS, COORDINATES & OBJECT IDs
        detailsSection(separation = false, topMargin = false) {
            // ADDRESS
            address.filter { it?.tagList?.isNotEmpty() == true }.render { addr ->
                val addressStr = listOfNotNull(
                    if (!(addr?.name ?: "").startsWith(addr?.street ?: "xxxx")) {
                        addr?.name
                    } else {
                        null
                    },
                    addr?.street,
                    addr?.houseNumber,
                    addr?.postalCode,
                    addr?.neighborhood,
                    addr?.borrow,
                    addr?.city,
                    addr?.countryCode?.uppercase(),
                ).joinToString(", ")

                val copyStr = listOfNotNull(
                    addr?.street,
                    addr?.houseNumber,
                    addr?.postalCode,
                    addr?.city,
                    addr?.countryCode?.uppercase(),
                ).joinToString(", ")

                flexBox(
                    {
                        margins {
                            top { small }
                        }
                    },
                ) {
                    copyButtonWithTitle(
                        title = flowOf(addressStr),
                        icon = flowOf(FormationIcons.Building),
                        clipboardData = copyStr,
                        clipboardText = "Copied address to clipboard!",
                    )
                }
            }

            // GPS COORDINATES
            copyButtonWithTitle(
                title = position.data.map { "GPS: ${it.lat.roundDecimals(6)}, ${it.lon.roundDecimals(6)}" },
                icon = flowOf(FormationIcons.Location),
                clipboardData = position.current.let { ll ->
                    "${ll.lat.roundDecimals(6)}, ${
                        ll.lon.roundDecimals(
                            6,
                        )
                    }"
                },
                clipboardText = "Copied GPS coordinates to clipboard!",
            )
            // UTM COORDINATES
            copyButtonWithTitle(
                position.data.map { "UTM: " + it.pointCoordinates().toUtmOrUps().toString() },
                icon = flowOf(FormationIcons.Location),
                clipboardData = position.current.pointCoordinates().toUtmOrUps().toString(),
                clipboardText = "Copied UTM coordinates to clipboard!",
            )

            // MGRS COORDINATES
            copyButtonWithTitle(
                title = position.data.map { "MGRS: " + it.pointCoordinates().toMgrs().usng() },
                icon = flowOf(FormationIcons.Location),
                clipboardData = position.current.pointCoordinates().toMgrs().usng(),
                clipboardText = "Copied MGRS coordinates to clipboard!",
            )

            // INTERNAL OBJECT ID
            copyButtonWithContent(
                objectId.current,
                "Copied object id to clipboard!",
            ) {
                flexBox(
                    {
                        alignItems { center }
                    },
                ) {
                    span(
                        {
                            width { "20px" }
                            fontSize { normal }
                            fontWeight { bold }
                            margins { right { smaller } }
                            textAlign { center }
                            lineHeight { none }
                        },
                    ) { +"id" }
                    span(
                        {
                            fontSize { small }
                            fontWeight { lighter }
                        },
                    ) { objectId.data.renderText(into = this) }
                }
            }

            externalId.render { extId ->
                // EXTERNAL OBJECT ID
                if (!extId.isNullOrBlank()) {
                    flexBox(
                        {
                            margins {
                                top { small }
                            }
                        },
                    ) {
                        copyButtonWithTitle(
                            title = flowOf(extId),
                            icon = flowOf(FormationIcons.QRCode),
                            clipboardData = "https://app.tryformation.com/#id=${extId.urlEncode()}",
                            clipboardText = "Copied link to clipboard!",
                        )
                    }
                    // QR CODE
                    flowOf(
                        extId,
                    ) handledBy {
                        val svgContent = toSvgQrCode("https://app.tryformation.com/#id=${extId.urlEncode()}")
                        div(
                            {
                                width { full }
                                maxWidth { "300px" }
                                margins {
                                    horizontal { auto }
                                }
                            },
                        ) {
                            domNode.innerHTML = svgContent
                        }
                    }
                }
            }
        }
        attribution.render { attr ->
            if (!attr.isNullOrBlank()) {
                selectorButton {
                    selectorContent {
                        icon({ margins { horizontal { tiny } } }) { fromTheme { FormationIcons.Copyright.icon } }
                        ellipseText { +"$attr contributors" }
                    }
                    clicks.map {
                        mapOf("show" to "attribution")
                    } handledBy routerStore.addOrReplaceRoute
                }
            }
        }
    }
}

fun RenderContext.infoCardEditFooter(
    type: ObjectType,
    additionalSecondaryClickHandlers: List<SimpleHandler<Unit>> = listOf(),
) {

    val apiUser: ApiUserStore by koinCtx.inject()
    val translation: Translation by koinCtx.inject()
    val activeObjectStore: ActiveObjectStore by koinCtx.inject()
    val canModify = activeObjectStore.map(GeoObjectDetails.L.canModify)
    val canManage = activeObjectStore.map(GeoObjectDetails.L.canManage)

    canManage.data.combine(canModify.data) { manage, modify ->
        manage || modify || (type == ObjectType.ObjectMarker && !apiUser.current.isAnonymous)
    }
        .render { editAccess ->
            twoButtonFooter(
                secondaryTitle = translation[TL.General.CLOSE],
                secondaryStyleParams = secondaryButtonStyleParams,
                secondaryClickHandlers = listOf(activeObjectStore.resetActiveObjectAndUserToMap) + additionalSecondaryClickHandlers,
                primaryTitle = translation[TL.General.EDIT], // translation[TL.CardInfo.EDIT_TYPE, json("type" to type.getName().uppercase())]
//                primaryState = flowOf(editAccess || type == ObjectType.ObjectMarker),
                primaryState = flowOf(editAccess),
                primaryStyleParams = primaryButtonStyleParams,
                primaryValue = Unit,
//                primaryAddOrReplaceRoute = if (editAccess || type == ObjectType.ObjectMarker) {
                primaryAddOrReplaceRoute = if (editAccess) {
                    mapOf("page" to Pages.Map.name, "edit" to type.name)
                } else null,
                primaryClickHandlers = if (editAccess) {
                    listOf(
                        activeObjectStore.focusThisObject,
                        activeObjectStore.removeMapClickListener,
                        activeObjectStore.hideActiveObjectMarker,
                    )
                } else null,
            )
        }
}

enum class TagButtonType { SHOW, CREATE, EDIT }

fun RenderContext.keywordTagsButton(type: TagButtonType = TagButtonType.SHOW) {

    val routerStore: RouterStore by koinCtx.inject()
    val activeObjectStore: ActiveObjectStore by koinCtx.inject()
    val activeObjectKeywordsStore: ActiveObjectKeywordsStore by koinCtx.inject()
    val activeObjectFieldValuesStore: ActiveObjectFieldValuesStore by koinCtx.inject()
    val keywords = when (type) {
        TagButtonType.SHOW -> activeObjectStore.map(GeoObjectDetails.L.keywords).data
        else -> activeObjectKeywordsStore.data
    }
    val readOnlyKeywordTags = activeObjectStore.map(GeoObjectDetails.L.tags).data.extractReadOnlyTags()
    val currentWorkspaceStore: CurrentWorkspaceStore by koinCtx.inject()
    val fieldValueTags = when (type) {
        TagButtonType.SHOW -> activeObjectStore.map(GeoObjectDetails.L.tags).data
            .respectFeatureFlags().map { tags ->
                currentWorkspaceStore.current?.fieldDefinitions?.let { definitions ->
                    tags.parseFieldValues(definitions)
                }
            }.map { it?.toKeyWordTagsList(KeywordTagActionType.DefaultFieldValue) ?: emptyList() }

        else -> activeObjectFieldValuesStore.data.map { it.toKeyWordTagsList(KeywordTagActionType.DefaultFieldValue) }
    }


    val buttonAddOrReplaceRoute = when (type) {
        TagButtonType.SHOW -> mapOf("show" to "tags")
        TagButtonType.CREATE, TagButtonType.EDIT -> mapOf("change" to "tags")
    }

    val searchable = when (type) {
        TagButtonType.SHOW -> true
        TagButtonType.CREATE, TagButtonType.EDIT -> false
    }


    // KEYWORD TAG BUTTON
    mergeTagLists(
        readOnlyTags = readOnlyKeywordTags,
        keywords = keywords,
        readOnlyFirst = false,
        fieldValueTags = fieldValueTags,
    ).render { tagList ->
        val buttonVisible = when (type) {
            TagButtonType.SHOW, TagButtonType.EDIT -> tagList.isNotEmpty()
            TagButtonType.CREATE -> true
        }
        if (buttonVisible) {
            selectorButton {
                selectorContent(margins = { horizontal { tiny } }) {
                    icon({ margins { horizontal { smaller } }; size { normal } }) { fromTheme { FormationIcons.Tag.icon } }
                    +"Tags"
                    overFlowContent {
                        tagList.forEach { tag ->
                            keywordTag(
                                tag = tag,
                                searchable = searchable,
                                keywordTagType = KeywordTagType.ObjectTag,
                                condensed = true,
                            )
                        }
                    }
                }
                clicks.map {
                    buttonAddOrReplaceRoute
                } handledBy routerStore.addOrReplaceRoute
            }
        }
    }
}

fun RenderContext.nestedObjectsButton() {

    val translation: Translation by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()
    val nestedObjectsResultsStore: NestedObjectsResultsStore by koinCtx.inject()

    nestedObjectsResultsStore.data.render { nestedObjectData ->
        if (nestedObjectData.fetched.isNotEmpty()) {
            selectorButton {
                selectorContent {
                    icon({ margins { horizontal { smaller } }; size { normal } }) { fromTheme { FormationIcons.Boxes.icon } }
                    ellipseText(
                        styleParams = {
                            width { full }
                            textAlign { left }
                        },
                    ) {
                        translation[TL.CardNestedObjects.BUTTON_TITLE].merge(flowOf("(${nestedObjectData.total})"))
                            .renderText(into = this)
                    }
                }
                clicks.map {
                    mapOf("show" to "nestedObjects")
                } handledBy routerStore.addOrReplaceRoute
            }
        }
    }
}

////fun RenderContext.attachmentsButton() {
//
//    val translation: Translation by koinCtx.inject()
//    val routerStore: RouterStore by koinCtx.inject()
//    val activeObjectStore: ActiveObjectStore by koinCtx.inject()
//    val attachments = activeObjectStore.map(GeoObjectDetails.L.attachments)
//
//    // ATTACHMENTS
//    attachments.data.render { attachmentsList ->
//        if (!attachmentsList.isNullOrEmpty()) {
//            selectorButton {
//                selectorContent {
//                    icon({ margins { horizontal { smaller } }; size { normal } }) { fromTheme { FormationIcons.Clip.icon } }
//                    ellipseText { translation[TL.Attachments.ATTACHMENTS].merge(flowOf("(${attachmentsList.size})")).renderText(into = this) }
//                }
//                clicks.map {
//                    mapOf("show" to "attachments")
//                } handledBy routerStore.addOrReplaceRoute
//            }
//        }
//    }
//}

//fun RenderContext.attachmentsButtonAlt() {
//    val translation: Translation by koinCtx.inject()
//    val activeObjectStore: ActiveObjectStore by koinCtx.inject()
//    val attachments = activeObjectStore.map(GeoObjectDetails.L.attachments)
//
//    attachments.data.render { attachmentsList ->
//        val filteredAttachments = attachmentsList.respectFeatureFlags()
//        if (!filteredAttachments.isNullOrEmpty()) {
//            flexBox(
//                {
//                    alignItems { center }
//                    justifyContent { spaceBetween }
//                    fontSize { small }
//                    margins { top { small } }
//                },
//            ) {
//                flexBox(
//                    {
//                        margin { tiny }
//                    },
//                ) {
//                    icon({ margins { horizontal { smaller } }; size { normal } }) { fromTheme { FormationIcons.Clip.icon } }
//                    ellipseText {
//                        translation[TL.Attachments.ATTACHMENTS].merge(flowOf("(${filteredAttachments.size}) :"))
//                            .renderText(into = this)
//                    }
//                }
////                if(filteredAttachments.size > 3) {
////                    seeAllAttachmentsButton()
////                }
//            }
//        }
//    }
//}

//fun RenderContext.seeAllAttachmentsButton() {
//    val translation: Translation by koinCtx.inject()
//    val routerStore: RouterStore by koinCtx.inject()
//
//    genericSmallIconButton(
//        title = translation[TL.Attachments.SEE_ALL_ATTACHMENTS],
//        icon = { chevronRight },
//        value = mapOf("show" to "attachments"),
//        clickHandlers = listOf(routerStore.addOrReplaceRoute)
//    )
//}

fun RenderContext.infoCardButtons() {

    lineUp {
        spacing { tiny }
        items {
//            refreshObjectButton()
            secondaryMenuButton()
        }
    }

}

fun RenderContext.secondaryMenuButton() {

    val routerStore: RouterStore by koinCtx.inject()

    button {
        flexBox(
            {
                alignItems { center }
                justifyContent { center }
                width { "30px" }
                height { "30px" }
                radius { full }
                color { primary.main }
                padding { small }
                hover {
                    color { secondary.main }
                    background {
//                    color { makeRGBA(primary.main, 0.1) }
                        color { primary.main }
                    }
                }
            },
        ) {
            icon { fromTheme { FormationIcons.DotMenu.icon } }
            clicks.map { mapOf("show" to "menu") } handledBy routerStore.addOrReplaceRoute
        }
    }
}

fun RenderContext.refreshObjectButton() {

    val objectAndUserHandler: ObjectAndUserHandler by koinCtx.inject()

    button {
        flexBox(
            {
                alignItems { center }
                justifyContent { center }
                width { "30px" }
                height { "30px" }
                radius { full }
                color { primary.main }
                padding { small }
                hover {
                    color { secondary.main }
                    background {
//                    color { makeRGBA(primary.main, 0.1) }
                        color { primary.main }
                    }
                }
            },
        ) {
            objectAndUserHandler.refreshObjectTracker.data.render { loading ->
                if (loading) {
                    dotSpinner(pixelSize = 4)
                } else {
                    icon { fromTheme { FormationIcons.Update.icon } }
                }
            }
            clicks handledBy objectAndUserHandler.refreshObjectById
        }
    }
}

fun RenderContext.colorDot(color: FormationColors) {
    div(
        {
            width { "10px" }
            height { "10px" }
            flex {
                grow { "0" }
                shrink { "0" }
                basis { "10px" }
            }
            radius { full }
            background { color { color.color } }
            margins { horizontal { smaller } }
        },
    ) { }
}
