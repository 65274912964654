package map

import apiclient.geoobjects.LatLon
import apiclient.geoobjects.pointCoordinates
import auth.ApiUserStore
import auth.CurrentWorkspaceStore
import com.jillesvangurp.geo.GeoGeometry.Companion.distance
import data.users.settings.LocalSettingsStore
import dev.fritz2.core.RootStore
import dev.fritz2.core.invoke
import koin.koinCtx
import kotlinx.browser.window
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.distinctUntilChanged
import kotlinx.coroutines.flow.map
import kotlinx.serialization.SerializationException
import kotlinx.serialization.json.Json
import mainmenu.AppStateStore
import maplibreGL.MaplibreMap
import maplibreGL.toLatLon
import model.AppPhase
import model.AppState
import model.LocalSettings
import model.MapState
import model.clusterObjects

const val mapStatePrefix = "com.tryformation.webapp.mapState"

class MapStateStore(
    apiUserStore: ApiUserStore,
    private val json: Json,
) : RootStore<MapState?>(
    initialData = getStoredMapState(json),
    job = Job(),
) {

    private val maplibreMap: MaplibreMap by koinCtx.inject()
    private val mapObjectStore: MapObjectStore by koinCtx.inject()
    private val appStateStore: AppStateStore by koinCtx.inject()
    private val currentWorkspaceStore: CurrentWorkspaceStore by koinCtx.inject()
    private val localSettingsStore: LocalSettingsStore by koinCtx.inject()

    val resetMovedState = handle { current ->
        current?.copy(hasMoved = false)
    }

    private fun saveMap() {
        // beware that mapCenter may initially have undefined lat/lon, maplibre injects the values later
        val mapCenter = maplibreMap.getCenter()
        val mapBounds = maplibreMap.getCardinalDirectionBounds()!!
//        maplibreMap.updateBounds(mapBounds)
        val mapZoom = maplibreMap.getZoom()

//        console.log("Disabled Objects:", maplibreMap.disabledObjects.toString())

        val center = mapCenter.toLatLon()
        try {
            val tl = mapBounds.getNorthWest().toLatLon()
            val br = mapBounds.getSouthEast().toLatLon()

            val hasMoved = current?.let { lastState ->
                lastState.zoom != mapZoom || distance(
                    lastState.center.pointCoordinates(),
                    center.pointCoordinates(),
                ) >= 10.0
            } ?: false

            val newMapState = current?.copy(
                center = center,
                zoom = mapZoom,
                tl = tl,
                br = br,
                hasMoved = hasMoved,
            ) ?: MapState(
                center = center,
                zoom = mapZoom,
                tl = tl,
                br = br,
                hasMoved = hasMoved,
            )
            update(newMapState)
            window.localStorage.setItem(mapStatePrefix, json.encodeToString(MapState.serializer(), newMapState))
        } catch (e: IllegalArgumentException) {
            // ignore, happens when saveMap is called before mapCenter gets proper coordinates.
            // Some weird init logic in maplibre does a bait and switch
            // somehow center has undefined lat/lon and when you look at the lng it actually has coordinates?!
        }

//        console.log("Map state saved.", newMapState)

    }

    val insertMap = handle { currentMapState ->
        val apiUser = apiUserStore.current.apiUser
        val currentWorkspace = currentWorkspaceStore.current
        if (apiUser != null && currentWorkspace != null && apiUser.hasActivated && !apiUser.workspaceName.isNullOrBlank() && !maplibreMap.initialized) {
            val mapState = currentMapState ?: getStoredMapState(json) ?: MapState(
                currentWorkspace.defaultMapCenter ?: LatLon(52.530231145621116, 13.401964921206574),
                currentWorkspace.defaultMapZoomLevel ?: 15.0,
                null,
                null,
            )
            console.log("ApiUser changed, insert map with mapState:", mapState.toString())
            maplibreMap.insertMap(
                existingMapState = mapState,
                existingObjectLayers = mapObjectStore.current.objectLayers,
            )
            saveMap()

            /**
             * add an onClick listener to all object layers (layers will inherit it to their objects)
             * that loads the geoObject of the clicked element into the ActiveObjectStore
             */
            // TODO: maybe add objectLayerListeners to all staticObject layers here
            //maplibreMap.addObjectLayerListeners(type = "click", fn = mapObjectStore::fetchAndUpdateActiveObjectOrUser)

//            maplibreMap.on(type = "moveend", fn = ::saveMap, fnId = "saveMap")
            maplibreMap.on(type = "dragend", fn = ::saveMap, fnId = "saveMap-dragend")
            maplibreMap.on(type = "zoomend", fn = ::saveMap, fnId = "saveMap-zoomend")
//            maplibreMap.map?.on("dragend") { e: dynamic -> console.log("Event -> ${e.type as String}") }
            mapState
        } else {
            currentMapState
        }
    }

    private val manageMap = handle<AppState> { current, appState ->
        if (appState.appPhase == AppPhase.LoggedIn || appState.appPhase == AppPhase.LoggedInAnonymous) {
            insertMap()
        } else {
            maplibreMap.removeMap()
            update(null)
        }
        current
    }

    val zoomIn = handle { current ->
        maplibreMap.zoomIn()
        current
    }

    val zoomOut = handle { current ->
        maplibreMap.zoomOut()
        current
    }

    private val refreshMap = handle<Unit> { current, _ ->
        maplibreMap.syncMarkersNow(trigger = "refreshMap")
        current
    }

    init {
        appStateStore.data handledBy manageMap
        localSettingsStore.map(LocalSettings.clusterObjects()).data.distinctUntilChanged().map { } handledBy refreshMap
    }

    companion object {

        private fun getStoredMapState(json: Json): MapState? {
            val string = window.localStorage.getItem(mapStatePrefix) ?: return null
            return try {
                json.decodeFromString(MapState.serializer(), string)
            } catch (e: SerializationException) {
                console.error(e)
                null
            }
        }
    }
}
