package auth

import apiclient.FormationClient
import apiclient.groups.Group
import apiclient.groups.getGroup
import dev.fritz2.core.RootStore
import dev.fritz2.core.SimpleHandler
import koin.koinCtx
import kotlinx.coroutines.CoroutineName
import kotlinx.coroutines.Job
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.launch
import model.User

class WorkspacesStore : RootStore<List<Group>>(
    initialData = emptyList(),
    job = Job(),
) {

    val formationClient: FormationClient by koinCtx.inject()
    val apiUserStore: ApiUserStore by koinCtx.inject()
    val currentWorkspaceStore: CurrentWorkspaceStore = koinCtx.get()

    val reset = handle {
        console.log("Reset WorkspacesStore")
        update(emptyList())
        emptyList()
    }

    val fetchWorkspaces = SimpleHandler<User> { userData, _ ->
        userData handledBy { user ->
            val workspaceIds = user.apiUser?.groups?.map { it.groupId } ?: emptyList()
            coroutineScope {
                CoroutineName("fetch-workspaces-of-apiUser")
                launch {
                    console.log("Try to fetch workspaces of \"${user.apiUser?.name}\", with ids:", workspaceIds.toString())
                    val workspaces = workspaceIds.mapNotNull { workspaceId ->
                        // FIXME Can not wrap this api call, as using busyStore inside a map, firing multiple api calls to create a list with the results, is not possible
                        try {
                            formationClient.getGroup(workspaceId).getOrNull().also { workspace ->
                                console.log(workspace?.layerDefaultSettings?.joinToString(",") { w -> w.id })
                            }
                        } catch (e: Exception) {
                            console.log("Error fetching group", e.message)
                            null
                        }
                    }
                    console.log("Found workspaces:", workspaces.map { it.name }.ifEmpty { "none" }.toString())
                    if (workspaces.isNotEmpty()) {
                        updateWorkspaces(workspaces)
                        currentWorkspaceStore.updateCurrentWorkspace(workspaces)
                    } else {
                        console.warn("No workspaces found for ${user.apiUser?.name}")
                    }
                }
            }
        }
    }

    fun updateWorkspaces(newWorkspaces: List<Group>) {
        update(newWorkspaces.distinctBy { it.groupId })
    }

    init {
        data handledBy currentWorkspaceStore.updateCurrentWorkspace
    }
}
