package data.objects.views

import apiclient.geoobjects.GeoObjectDetails
import data.objects.ActiveObjectStore
import data.objects.DatePickerStore
import data.objects.DurationPickerStore
import data.objects.TimePickerStore
import dev.fritz2.components.flexBox
import dev.fritz2.components.stackUp
import dev.fritz2.core.RenderContext
import dev.fritz2.core.placeholder
import dev.fritz2.core.values
import koin.koinCtx
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.map
import localization.TL
import localization.Translation
import mainmenu.RouterStore
import model.L
import styling.primaryButtonStyleParams
import styling.secondaryButtonStyleParams
import theme.FormationIcons
import utils.formatDateForDatePickerFlow
import utils.formatTimeForTimePickerFlow
import webcomponents.baseLayout
import webcomponents.cardTitle
import webcomponents.contentScrollBox
import webcomponents.genericInput
import webcomponents.inputLabelWrapper
import webcomponents.smallSelectButton
import webcomponents.twoButtonFooter

fun RenderContext.cardDateTime() {
    val translation: Translation by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()
    val activeObjectStore: ActiveObjectStore by koinCtx.inject()
    val startTime = activeObjectStore.map(GeoObjectDetails.L.atTime)
    val datePickerStore: DatePickerStore by koinCtx.inject()
    val timePickerStore: TimePickerStore by koinCtx.inject()
    val durationPickerStore: DurationPickerStore by koinCtx.inject()

    baseLayout(
        header = {
            // TITLE
            flexBox({
                width { full }
                margins { bottom { smaller } }
            }) {
                cardTitle(translation[TL.CardDateTime.CARD_TITLE]) { FormationIcons.Calendar.icon }
            }
        },
        content = {
            contentScrollBox {
                stackUp {
                    spacing { smaller }
                    items {
                        // DATE INPUT
                        inputLabelWrapper(
                            title = translation[TL.CardDateTime.DATE],
                            visibilityFlow = startTime.data.formatDateForDatePickerFlow().map { it.isNotBlank() }
                        ) {
                            genericInput(
                                value = startTime.data.formatDateForDatePickerFlow(),
                                type = "date"
                            ) {
                                placeholder(translation[TL.CardDateTime.DATE])
                                changes.values() handledBy datePickerStore.update
                            }
                        }
                        // TIME INPUT
                        inputLabelWrapper(
                            title = translation[TL.CardDateTime.TIME],
                            visibilityFlow = formatTimeForTimePickerFlow(startTime.data).map { it.isNotBlank() }
                        ) {
                            genericInput(
                                value = formatTimeForTimePickerFlow(startTime.data),
                                type = "time"
                            ) {
                                placeholder(translation[TL.CardDateTime.TIME])
                                changes.values() handledBy timePickerStore.update
                            }
                        }
                        p("text-xs mt-3") {
                            translation[TL.CardDateTime.DURATION_IN_MINUTES].renderText(into = this)
                        }

                        // DURATION QUICK BUTTONS
                        flexBox({
                            direction { row }
                            width { full }
                            justifyContent { spaceBetween }
                            alignItems { center }
                        }) {
                            smallSelectButton(isActive = durationPickerStore.data.map { it == 10 }) {
                                +"10 ${translation.getString(TL.CardDateTime.MINUTE)}"
                                clicks.map { 10 } handledBy durationPickerStore.update
                            }
                            smallSelectButton(isActive = durationPickerStore.data.map { it == 20 }) {
                                +"20 ${translation.getString(TL.CardDateTime.MINUTE)}"
                                clicks.map { 20 } handledBy durationPickerStore.update
                            }
                            smallSelectButton(isActive = durationPickerStore.data.map { it == 30 }) {
                                +"30 ${translation.getString(TL.CardDateTime.MINUTE)}"
                                clicks.map { 30 } handledBy durationPickerStore.update
                            }
                        }
                        // DURATION INPUT
                        genericInput(
                            value = durationPickerStore.data.asString(),
                            type = "number"
                        ) {
                            inputs.values().map { it.toInt() } handledBy durationPickerStore.update
                        }
                    }
                }
            }
        },
        footer = {
            // CARD BUTTONS FOOTER
            twoButtonFooter(
                secondaryTitle = translation[TL.General.CANCEL],
                secondaryStyleParams = secondaryButtonStyleParams,
                secondaryClickHandlers = listOf(activeObjectStore.updateChangeInputStores, routerStore.back),
                primaryTitle = translation[TL.General.SET],
                primaryState = datePickerStore.data.combine(timePickerStore.data) { date, time -> date.isNotBlank() && time.isNotBlank() },
                primaryStyleParams = primaryButtonStyleParams,
                primaryValue = Unit,
                primaryClickHandlers = listOf(activeObjectStore.readFromChangeInputStores, routerStore.back),
            )
        }
    )
}
