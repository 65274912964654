package data.users.views

import apiclient.geoobjects.Content
import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.ObjectType
import auth.ApiUserStore
import data.objects.ActiveObjectStore
import data.objects.AssigneeSelectorStore
import data.objects.AttendeesSelectorStore
import data.users.UserListStore
import dev.fritz2.components.compat.div
import dev.fritz2.components.flexBox
import dev.fritz2.components.icon
import dev.fritz2.components.lineUp
import dev.fritz2.components.stackUp
import dev.fritz2.core.RenderContext
import koin.koinCtx
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import localization.TL
import localization.Translation
import mainmenu.RouterStore
import model.L
import styling.primaryButtonStyleParams
import styling.secondaryButtonStyleParams
import theme.FormationIcons
import theme.FormationUIIcons
import utils.mergeIfNotBlank
import webcomponents.baseLayout
import webcomponents.cardTitle
import webcomponents.contentScrollBox
import webcomponents.stateSelectorButton
import webcomponents.twoButtonFooter

fun RenderContext.cardUserSelect() {

    val translation: Translation by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()
    val attendeesSelectorStore: AttendeesSelectorStore = koinCtx.get()
    val assigneeSelectorStore: AssigneeSelectorStore = koinCtx.get()
    val userListStore: UserListStore = koinCtx.get()
    val activeObjectStore: ActiveObjectStore = koinCtx.get()
    val createdBy = activeObjectStore.map(GeoObjectDetails.L.createdBy)
    val type = activeObjectStore.map(GeoObjectDetails.L.objectType)
    val apiUserStore: ApiUserStore by koinCtx.inject()
    val currentUserId = apiUserStore.current.userId

    userListStore.fetchGroupMembers(null)

    when (type.current) {
        ObjectType.Task -> {
            val taskAssigneeId = activeObjectStore.map(GeoObjectDetails.L.assignedTo)
            taskAssigneeId.data.map {
                it?.let { assigneeId -> if (assigneeId.isNotBlank()) assigneeSelectorStore.updateUserIds(listOf(assigneeId)) } ?: assigneeSelectorStore.update(
                    null,
                )
            }
        }

        ObjectType.Event -> {
            val attendeesList = activeObjectStore.map(GeoObjectDetails.L.attendees)
            attendeesList.data.map { it?.let { attendees -> attendeesSelectorStore.update(attendees.map { attendee -> attendee.userId }) } }
        }

        else -> {}
    }

    baseLayout(
        header = {
            cardTitle(translation[TL.CardUserSelect.CARD_TITLE]) { FormationIcons.UserAlt.icon }
            flexBox(
                {
                    direction { row }
                    width { full }
                    justifyContent { flexEnd } // change to space between when enabling "Results" text again
                    alignItems { center }
                    paddings {
                        left { small }
                        right { tiny }
                    }
                    margins { bottom { small } }
                },
            ) {
                // TODO: add search bar for filtering users in group here
                //  enable "Results" text when search is done
//        h4({ color { FormationColors.GrayDisabled.c } }) { translation[TL.CardUserSelect.RESULTS].asText() }
                when (type.current) {
                    ObjectType.Event -> {
                        val allSelected = attendeesSelectorStore.data.map { selectedUsers ->
                            selectedUsers != null
                                && selectedUsers.containsAll((userListStore.current?.map { it.userId } ?: emptyList()))
                        }
                        allSelected.render { active ->
                            lineUp(
                                {
                                    alignItems { center }
                                },
                            ) {
                                spacing { small }
                                items {
                                    span { translation[TL.CardUserSelect.ALL].renderText(into = this) }
                                    button {
                                        div(
                                            {
                                                width { "40px" }
                                                height { "40px" }
                                                color { secondary.main }
                                                radius { full }
                                                padding { smaller }
                                                margins { right { smaller } }
                                                flex {
                                                    grow { "0" }
                                                    shrink { "0" }
                                                    basis { "40px" }
                                                }
                                                if (active) {
                                                    background { color { primary.main } }
                                                } else {
                                                    border {
//                                                    color { FormationColors.GrayPrivate.color }
                                                        color { primary.main }
                                                        width { "2px" }
                                                    }
                                                }
                                            },
                                        ) {
                                            if (active) icon({ size { larger } }) { fromTheme { FormationUIIcons.Check.icon } }
                                        }
                                        clicks.map { userListStore.current?.map { it.userId } ?: emptyList() } handledBy attendeesSelectorStore.toggleAll
                                    }
                                }
                            }
                        }
                    }

                    else -> {}
                }
            }
        },
        content = {
            contentScrollBox {
                stackUp(
                    {
                        width { full }
                        height { maxContent }
                        justifyContent { center }
                        alignItems { stretch }
                        display { flex }
                    },
                ) {
                    spacing { small }
                    items {
                        when (type.current) {
                            ObjectType.Task -> {
                                stateSelectorButton(
                                    active = assigneeSelectorStore.data.map { it == null },
                                    icon = { FormationIcons.UserGroup.icon },
                                    initials = null,
                                    title = translation[TL.CardUserSelect.ANYONE],
                                    subtitle = translation[TL.CardUserSelect.ANYONE_DESCRIPTION],
                                    value = Unit,
                                    valueHandlers = listOf(assigneeSelectorStore.reset),
                                )
                                userListStore.current?.forEach { userEntry ->
                                    val isCreator = createdBy.current == userEntry.userId
                                    val isYou = currentUserId == userEntry.userId
                                    stateSelectorButton(
                                        //active = assigneeSelectorStore.data.map { it?.contains(userEntry)?: false },
                                        active = assigneeSelectorStore.data.map { selected ->
                                            selected?.map { it.userId }?.contains(userEntry.userId) ?: false
                                        },
                                        profilePictureLink = (userEntry.profilePhoto?.thumbNail as? Content.Image)?.href ?: userEntry.profilePhoto?.href,
                                        icon = { FormationIcons.UserAlt.icon },
                                        initials = initials(userEntry.firstName, userEntry.lastName),
                                        title = flowOf(
                                            "${userEntry.firstName} ${userEntry.lastName}${
                                                when {
                                                    isCreator && isYou -> {
                                                        " (${
                                                            translation.getString(TL.General.YOU, mapOf("case" to "nominative"))
                                                                .lowercase().replaceFirstChar { if (it.isLowerCase()) it.titlecase() else it.toString() }
                                                        }, ${translation.getString(TL.CardUserSelect.CREATOR)})"
                                                    }

                                                    isCreator -> " (${translation.getString(TL.CardUserSelect.CREATOR)})"
                                                    isYou -> {
                                                        " (${
                                                            translation.getString(TL.General.YOU, mapOf("case" to "nominative"))
                                                                .lowercase().replaceFirstChar { if (it.isLowerCase()) it.titlecase() else it.toString() }
                                                        })"
                                                    }

                                                    else -> ""
                                                }
                                            }",
                                        ),
                                        subtitle = flowOf(userEntry.jobTitle.mergeIfNotBlank(userEntry.company, " | ")),
                                        value = userEntry,
                                        valueHandlers = listOf(assigneeSelectorStore.toggleSingleUserSelection),
                                    )
                                }
                            }

                            ObjectType.Event -> {
                                userListStore.current?.forEach { userEntry ->
                                    val isCreator = createdBy.current == userEntry.userId
                                    val isYou = currentUserId == userEntry.userId
                                    stateSelectorButton(
                                        active = attendeesSelectorStore.data.map { it?.contains(userEntry.userId) ?: false },
                                        profilePictureLink = (userEntry.profilePhoto?.thumbNail as? Content.Image)?.href ?: userEntry.profilePhoto?.href,
                                        icon = { FormationIcons.UserAlt.icon },
                                        initials = initials(userEntry.firstName, userEntry.lastName),
                                        title = flowOf(
                                            "${userEntry.firstName} ${userEntry.lastName}${
                                                when {
                                                    isCreator && isYou -> {
                                                        " (${
                                                            translation.getString(TL.General.YOU, mapOf("case" to "nominative"))
                                                                .lowercase().replaceFirstChar { if (it.isLowerCase()) it.titlecase() else it.toString() }
                                                        }, ${translation.getString(TL.CardUserSelect.CREATOR)})"
                                                    }

                                                    isCreator -> " (${translation.getString(TL.CardUserSelect.CREATOR)})"
                                                    isYou -> {
                                                        " (${
                                                            translation.getString(TL.General.YOU, mapOf("case" to "nominative"))
                                                                .lowercase().replaceFirstChar { if (it.isLowerCase()) it.titlecase() else it.toString() }
                                                        })"
                                                    }

                                                    else -> ""
                                                }
                                            }",
                                        ),
                                        subtitle = flowOf(userEntry.jobTitle.mergeIfNotBlank(userEntry.company, " | ")),
//                                        stateColor = attendees.data.map { list -> list?.firstOrNull { it.userId  == userEntry.userId }?.meetingInvitationStatus?.getColor() }, // TODO check with design team if enable this
//                                        stateIcon = attendees.data.map { list -> list?.firstOrNull { it.userId  == userEntry.userId }?.meetingInvitationStatus?.getIcon() },
                                        value = userEntry.userId,
                                        valueHandlers = listOf(attendeesSelectorStore.toggleMultiUserSelection),
                                    )
                                }
                            }

                            else -> {}
                        }
                    }
                }
            }
        },
        footer = {
            twoButtonFooter(
                primaryTitle = translation[TL.General.DONE],
                primaryStyleParams = primaryButtonStyleParams,
                primaryClickHandlers = listOf(activeObjectStore.readFromChangeInputStores, routerStore.back),
                secondaryTitle = translation[TL.General.BACK],
                secondaryStyleParams = secondaryButtonStyleParams,
                primaryValue = Unit,
                secondaryClickHandlers = listOf(activeObjectStore.updateChangeInputStores, routerStore.back),
            )
        },
    )
}

fun initials(firstName: String?, lastName: String?): String? {
    return if (firstName == null && lastName == null) null
    else "${firstName?.replaceFirstChar { if (it.isLowerCase()) it.titlecase() else it.toString() }?.get(0) ?: "?"}${
        lastName?.replaceFirstChar { if (it.isLowerCase()) it.titlecase() else it.toString() }?.get(0) ?: "?"
    }"
}
