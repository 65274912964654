package data.objects.views.cardinfo

import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.ObjectTags
import apiclient.geoobjects.ObjectType
import apiclient.tags.getUniqueTag
import data.objects.ActiveObjectStore
import data.objects.views.directediting.directEditingCardContent
import dev.fritz2.components.flexBox
import dev.fritz2.core.RenderContext
import koin.koinCtx
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import model.L
import theme.FormationIcons
import utils.getIcon
import utils.getName
import webcomponents.baseLayout
import webcomponents.cardTitleWithSubtitle
import webcomponents.cardTitleWithSubtitleDirectEdit

fun RenderContext.pointInfoCardContent() {

    val objectType = ObjectType.POI
    val activeObjectStore: ActiveObjectStore by koinCtx.inject()
    val title = activeObjectStore.map(GeoObjectDetails.L.title)
    val externalId = activeObjectStore.map(GeoObjectDetails.L.tags).data.map { it.getUniqueTag(ObjectTags.ExternalId) }
    val canModify = activeObjectStore.map(GeoObjectDetails.L.canModify)
    val canManage = activeObjectStore.map(GeoObjectDetails.L.canManage)

    val hasEditRights = canManage.data.combine(canModify.data) { manage, modify -> manage || modify }
    baseLayout(
        header = {
            flexBox(
                {
                    direction { row }
                    justifyContent { spaceBetween }
                    alignItems { start }
                    width { full }
                    height { maxContent }
                },
            ) {
                // CARD TITLE
                hasEditRights
                    .render { editAccess ->
                        if (editAccess) {
                            cardTitleWithSubtitleDirectEdit(
                                titleFlow = title.data,
                                title = title.current,
                                subtitle = externalId.map { extId ->
                                    if (extId != null) {
                                        "${objectType.getName()} - $extId"
                                    } else objectType.getName()
                                },
                                titleIconFlow = flowOf(objectType.getIcon().icon),
                                subtitleIconFlow = externalId.map { if (!it.isNullOrBlank()) FormationIcons.QRCode.icon else null },
                                changeHandler = activeObjectStore.editTitle,
                            )
                        } else {
                            cardTitleWithSubtitle(
                                title = title.data,
                                subtitle = externalId.map { extId ->
                                    if (extId != null) {
                                        "${objectType.getName()} - $extId"
                                    } else objectType.getName()
                                },
                                titleIconFlow = flowOf(objectType.getIcon().icon),
                                subtitleIconFlow = externalId.map { if (!it.isNullOrBlank()) FormationIcons.QRCode.icon else null },
                            )
                        }
                    }
                infoCardButtons()
            }
        },
        content = {
            directEditingCardContent()
        },
        footer = {
            // CARD BUTTONS FOOTER WITH: [BACK] [EDIT]
            infoCardEditFooter(objectType)
        },
    )
}

