package data.connectableshapes

import apiclient.geoobjects.GeoReferencedConnectableObject
import dev.fritz2.core.RootStore
import kotlinx.coroutines.Job

class ActiveConnectableShapeStore : RootStore<GeoReferencedConnectableObject?>(
    initialData = null,
    job = Job(),
)
