package camera.nimiq

import dev.fritz2.components.compat.canvas
import dev.fritz2.components.compat.div
import dev.fritz2.components.compat.video
import dev.fritz2.components.flexBox
import dev.fritz2.components.selectField
import dev.fritz2.core.HtmlTag
import dev.fritz2.core.RenderContext
import dev.fritz2.styling.params.SpacesContext
import koin.koinCtx
import kotlinx.browser.document
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import localization.TL
import localization.Translation
import model.ScanPurpose
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.MutationObserver
import org.w3c.dom.MutationObserverInit
import theme.FormationDefault.Companion.formationStyles
import theme.FormationIcons
import webcomponents.circleIconButton

fun RenderContext.fullCameraArea(
    cameraType: CameraType,
    scanPurpose: ScanPurpose,
    facingMode: FacingMode,
    headerOverlay: (HtmlTag<HTMLDivElement>.() -> Unit)? = null,
    footerPaddings: SpacesContext.() -> Unit = {
        vertical { normal }
        horizontal { normal }
    },
    footerOverlay: (HtmlTag<HTMLDivElement>.() -> Unit)? = null
) {
    val browserCamera: BrowserCamera by koinCtx.inject()
    flexBox(
        {
            width { full }
            height { full }
        },
    ) {
        flexBox(
            {
                width { full }
                height { full }
                position { relative { } }
            },
        ) {
            video(
                {
                    width { full }
                    height { full }
                    minHeight { "200px" }
                    maxWidth(sm = null, md = formationStyles.cardWidth)
                },
            ) {
                val observer = MutationObserver { _, observer ->
                    if (document.contains(domNode)) {
                        @Suppress("REDUNDANT_ELSE_IN_WHEN")
                        when (cameraType) {
                            CameraType.QRScanner -> {
                                when (scanPurpose) {
                                    ScanPurpose.OpenCode -> {
                                        browserCamera.insertQRScanCamera(
                                            domNode,
                                            resultHandler = browserCamera.openCodeScanResultHandler,
                                        )
                                    }

                                    ScanPurpose.ConnectQRToObject -> {
                                        browserCamera.insertQRScanCamera(
                                            domNode,
                                            resultHandler = browserCamera.connectCodeScanResultHandler,
                                        )
                                    }

                                    else -> console.log("Do not know what to do when ScanPurpose is ${scanPurpose.name}")
                                }
                            }

                            CameraType.Photo -> {
                                browserCamera.insertPhotoCamera(domNode, facingMode)
                            }

                            else -> console.log("Do not know what to do when CameraType is ${cameraType.name}")
                        }
                        observer.disconnect()
                    }
                }
                observer.observe(
                    document,
                    MutationObserverInit(attributes = false, childList = true, characterData = false, subtree = true),
                )
            }
            canvas(
                {
                    position { absolute { } }
                    width { "0px" }
                    height { "0px" }
                },
            ) {
                val observer = MutationObserver { _, observer ->
                    if (document.contains(domNode)) {
                        if (cameraType == CameraType.Photo) {
                            browserCamera.insertPhotoCanvas(domNode)
                        }
                        observer.disconnect()
                    }
                }
                observer.observe(
                    document,
                    MutationObserverInit(attributes = false, childList = true, characterData = false, subtree = true),
                )
            }
            flexBox(
                {
                    position { absolute { } }
                    width { full }
                    height { full }
                    direction { column }
                    alignItems { stretch }
                    justifyContent { spaceBetween }
                },
            ) {
                div(
                    {
                        background {
                            color { secondary.main }
                        }
                        radii { bottom { larger } }
                        padding { small }
                    },
                ) {
                    headerOverlay?.invoke(this)
                }
                div(
                    {
                        background {
                            color { secondary.main }
                        }
                        radii { top { larger } }
                        paddings(footerPaddings)
                    },
                ) {
                    footerOverlay?.invoke(this)
                }
            }
        }
    }
}

fun RenderContext.cameraButtons() {
    val browserCamera: BrowserCamera by koinCtx.inject()
    val activeCameraStore: ActiveCameraStore by koinCtx.inject()
    val switchCameraStore: SwitchCameraStore by koinCtx.inject()

    activeCameraStore.data.render { camera ->
        if (camera != null) {

            flexBox(
                {
                    direction { row }
                    alignItems { center }
                    justifyContent { spaceBetween }
                    flex {
                        grow { "1" }
                        shrink { "1" }
                        basis { maxContent }
                    }
                    margins { left { small } }
                },
            ) {
                circleIconButton(
                    iconFlow = flowOf { FormationIcons.ChangeCamera.icon },
                    iconSize = { larger },
                    size = { "40px" },
                    styleFlow = flowOf {
                        background { color { primary.main } }
                        color { secondary.main }
                        fontSize { small }
                        fontWeight { bold }
                        radius(formationStyles.buttonRadius)
                        hover {
                            color { primary.main }
                            background {
                                color { secondary.main }
                            }
                            border {
                                color { primary.main }
                                width(formationStyles.borderWidth)
                            }
                        }
                    },
                    value = Unit,
                    clickHandlers = listOf(switchCameraStore.switchCamera),
                )
                browserCamera.hasFlashlightStore.data.render { hasFlash ->
                    if (hasFlash) {
                        circleIconButton(
                            iconFlow = flowOf { FormationIcons.Lightbulb.icon },
                            iconSize = { larger },
                            size = { "40px" },
                            styleFlow = flowOf {
                                background { color { primary.main } }
                                color { secondary.main }
                                fontSize { small }
                                fontWeight { bold }
                                radius(formationStyles.buttonRadius)
                            },
                            value = Unit,
                            clickHandlers = listOf(browserCamera.toggleFlash),
                        )
                    }
                }
            }
        }
    }
}

fun RenderContext.cameraSelect() {
    val translation: Translation by koinCtx.inject()
    val cameraListStore: CameraListStore by koinCtx.inject()
    val activeCameraStore: ActiveCameraStore by koinCtx.inject()

    cameraListStore.data.render { cameraData ->
        selectField(
            {
                width { maxContent }
            },
            items = cameraData, value = activeCameraStore,
        ) {
            placeholder(translation.getString(TL.CardBrowserQrScanner.CAMERA_LIST_PLACEHOLDER))
            label { "${it?.label}" }
            events {
                selected.map { it } handledBy activeCameraStore.update
            }
        }
    }
}
