package workspacetools.buildingeditor

import com.jillesvangurp.geo.GeoGeometry
import com.jillesvangurp.geo.GeoGeometry.Companion.headingFromTwoPoints
import com.jillesvangurp.geojson.PointCoordinates
import apiclient.geoobjects.LatLon
import apiclient.geoobjects.pointCoordinates
import apiclient.geoobjects.toLatLon
import kotlin.math.pow
import kotlin.math.sqrt

data class ImageRotationAndScale(
    val imageResource: ImageResource,
    val angle: Double,
    val pixelsPerMeter: Double,
    val imageLocation: ImageLocation,
)

fun ImageRotationAndScale.rotate(d: Double): ImageRotationAndScale {
    return copy(angle = (angle + 180 + d) % 360 - 180)
}

fun ImageRotationAndScale.scale(s: Double): ImageRotationAndScale {
    return copy(
        pixelsPerMeter = pixelsPerMeter*s,
        imageLocation = imageLocation.scale(pixelsPerMeter, pixelsPerMeter*s)
    )
}

fun initialImageLocation(
    rp: LatLon,
    width: Int,
    height: Int,
    pixelsPerMeter: Double
): ImageLocation {
    val widthMeters = width / pixelsPerMeter
    val heightMeters = height / pixelsPerMeter
    val tl =
        GeoGeometry.translate(rp.lat, rp.lon, heightMeters / 2.0, -1 * widthMeters / 2.0).toLatLon()
    val bl =
        GeoGeometry.translate(rp.lat, rp.lon, -1 * heightMeters / 2.0, -1 * widthMeters / 2.0)
            .toLatLon()
    val br =
        GeoGeometry.translate(rp.lat, rp.lon, -1 * heightMeters / 2.0, widthMeters / 2.0).toLatLon()
    val tr = GeoGeometry.translate(rp.lat, rp.lon, heightMeters / 2.0, widthMeters / 2.0).toLatLon()
    return ImageLocation(topLeft = tl, topRight = tr, bottomLeft = bl, bottomRight = br)
}

fun ImageRotationAndScale.rotatedLocation(): ImageLocation {
    return imageLocation.rotated(angle)
}

data class ImageResource(val src: String, val widthPixels: Int, val heightPixels: Int)

data class ImageLocation(
    val topLeft: LatLon,
    val topRight: LatLon,
    val bottomLeft: LatLon,
    val bottomRight: LatLon
)

fun LatLon.scale(origin: LatLon, ratio: Double) =
    LatLon(
        lat = origin.lat + (lat - origin.lat) * ratio,
        lon = origin.lon + (lon - origin.lon) * ratio
    )

fun ImageLocation.scale(oldPixelsPerMeter: Double, newPixelsPermeters: Double): ImageLocation {
    val ratio = oldPixelsPerMeter / newPixelsPermeters
    val origin = centroid
    return ImageLocation(
        topLeft = topLeft.scale(origin, ratio),
        topRight = topRight.scale(origin, ratio),
        bottomLeft = bottomLeft.scale(origin, ratio),
        bottomRight = bottomRight.scale(origin, ratio)
    )
}

fun LatLon.tl(latDiff: Double, lonDiff: Double) = LatLon(lat = lat + latDiff, lon = lon + lonDiff)

fun ImageLocation.translate(newOrigin: LatLon): ImageLocation {
    val origin = centroid
    val latDiff = newOrigin.lat - origin.lat
    val lonDiff = newOrigin.lon - origin.lon
    return ImageLocation(
        topLeft = topLeft.tl(latDiff, lonDiff),
        topRight = topRight.tl(latDiff, lonDiff),
        bottomLeft = bottomLeft.tl(latDiff, lonDiff),
        bottomRight = bottomRight.tl(latDiff, lonDiff),
    )
}

val ImageLocation.coordinates
    get(): Array<Array<Double>> =
        arrayOf(
                topLeft,
                topRight,
                bottomRight,
                bottomLeft,
            )
            .map { arrayOf(it.lon, it.lat) }
            .toTypedArray()

private fun LatLon.rotated(rp: PointCoordinates, angle: Double): LatLon {
    return GeoGeometry.rotateAround(rp, pointCoordinates(), angle).toLatLon()
}

fun ImageLocation.rotated(angle: Double): ImageLocation {
    val rp = centroid.pointCoordinates()

    headingFromTwoPoints(rp, topLeft.pointCoordinates())
    val tl = topLeft.rotated(rp, angle)
    val tr = topRight.rotated(rp, angle)
    val bl = bottomLeft.rotated(rp, angle)
    val br = bottomRight.rotated(rp, angle)
    return ImageLocation(topLeft = tl, topRight = tr, bottomLeft = bl, bottomRight = br)
}

val ImageLocation.mapLibreImageCoordinates
    get() =
        arrayOf(topRight, topLeft, bottomLeft, bottomRight)
            .map { arrayOf(it.lon, it.lat) }
            .toTypedArray()

val ImageLocation.centroid: LatLon
    get() =
        LatLon(
            lat = mapLibreImageCoordinates.sumOf { it[1] } / 4.0,
            lon = mapLibreImageCoordinates.sumOf { it[0] } / 4.0
        )

fun ImageLocation.scale(widthPixels: Int, heightPixels: Int): Double {
    // by using the diagonal, we avoid confusion about x and y axis.
    return pythagorasHypotenuse(widthPixels.toDouble(), heightPixels.toDouble()) /
        GeoGeometry.distance(topLeft.pointCoordinates(), bottomRight.pointCoordinates())
}

fun pythagorasHypotenuse(side1: Double, side2: Double) = sqrt(side1.pow(2) + side2.pow(2))

