package camera.nfc

import apiclient.geoobjects.MarkerColor
import apiclient.geoobjects.MarkerIcon
import apiclient.geoobjects.MarkerShape
import apiclient.validations.parseEnumValue
import auth.ApiUserStore
import dev.fritz2.core.RootStore
import dev.fritz2.core.invoke
import dev.fritz2.styling.params.ColorProperty
import dev.fritz2.styling.theme.Colors
import koin.koinCtx
import kotlinx.browser.window
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.flowOf
import mainmenu.Pages
import mainmenu.RouterStore
import model.CodeTech
import model.NotificationType
import model.OperationType
import model.Overlay
import model.ScanPurpose
import model.ScannedCode
import org.w3c.dom.get
import overlays.AlertOverlayStore
import qrcode.ScannedCodeStore
import routing.MainController
import theme.FormationColors
import workspacetools.usermanagement.urlDecode
import workspacetools.usermanagement.urlEncode

enum class NFCStatus(val color: Colors.() -> ColorProperty) {
    ENABLED({ FormationColors.GreenActive.color }),
    DISABLED({ FormationColors.YellowDoing.color }),
    NOT_AVAILABLE({ FormationColors.RedError.color }),
    UNKNOWN({ FormationColors.YellowDoing.color }),
    ;
}

data class NdefMessage(
    val tnf: Int?,
    val type: String?,
    val id: String?,
    val stringPayload: String?
)

data class NdefTag(
    val id: String?,
    val techTypes: List<String>,
    val type: String?,
    val maxSize: Int?,
    val isWritable: Boolean?,
    val hasRecords: Boolean?,
    val ndefRecords: List<NdefMessage>,
    val canMakeReadOnly: Boolean?
)

class NfcService : RootStore<NFCStatus>(
    initialData = NFCStatus.UNKNOWN,
    job = Job(),
) {

    val scannedCodeStore: ScannedCodeStore by koinCtx.inject()
    val alertOverlayStore: AlertOverlayStore by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()

    private fun isNFCPluginEnabled() = nfcScanner != null

    fun checkAndUpdateNFCStatus(showAlerts: Boolean = false) {
        console.log("Check if NFC is available...")
        if (isNFCPluginEnabled()) {
            nfcScanner.enabled(
                {
                    if (current != NFCStatus.ENABLED) {
                        addNfcListeners()
                        update(NFCStatus.ENABLED)
                    }
                },
                { error ->
                    console.log(error)
                    handleNFCError(error, showAlerts)
                },
            )
        } else {
            console.log("No NFC plugin found. NFC is not available.")
            update(NFCStatus.NOT_AVAILABLE)
        }
    }

    private fun handleNFCError(error: dynamic, showAlerts: Boolean = false) {
        when (error as String) {
            "NO_NFC" -> {
                console.log("NFC is not available on device")
                update(NFCStatus.NOT_AVAILABLE)
                if (showAlerts) {
                    alertOverlayStore.show(
                        Overlay.NotificationToast(
                            notificationType = NotificationType.Alert,
                            durationSeconds = 5,
                            text = flowOf("NFC is not available on your device"),
                            bgColor = FormationColors.RedError.color,
                            primaryClickHandlers = listOf(showNFCSettings),
                        ),
                    )
                }
            }

            "NO_NFC_OR_NFC_DISABLED" -> {
                update(NFCStatus.UNKNOWN)
                console.log("NFC is not available; maybe just disabled")
            }

            "NFC_DISABLED" -> {
                console.log("NFC is disabled on device -> ask user to go to NFC settings to enable it")
                update(NFCStatus.DISABLED)
                if (showAlerts) {
                    alertOverlayStore.show(
                        Overlay.NotificationToast(
                            notificationType = NotificationType.Alert,
                            durationSeconds = 10,
                            text = flowOf("NFC is disabled on your device - Click here to got to your NFC settings"),
                            bgColor = FormationColors.YellowDoing.color,
                            primaryClickHandlers = listOf(showNFCSettings),
                        ),
                    )
                }
            }

            else -> {
                console.log("NFC ERROR:", error)
            }
        }
    }

    val showNFCSettings = handle { current ->
        if (isNFCPluginEnabled()) {
            console.log("show nfc settings on device")
            nfcScanner?.showSettings()
        }
        current
    }

    private fun showDeviceNFCSettings() = showNFCSettings()


    val addNfcListeners = handle { current ->
        addDiscoverableListener()
        addNdefListener()
        current
    }

    private fun addDiscoverableListener() {
        if (isNFCPluginEnabled()) {
            console.log("NFC reader enabled")

            nfcScanner?.addTagDiscoveredListener(
                { event ->
                    console.log("Tag detected via TagDiscoverableListener")
                    val tag = event.tag
                    console.log("NFC tag (no Ndef!)", JSON.stringify(tag))
                    val tagId = nfcScanner.bytesToHexString(tag.id) as? String
                    handleScanResult(
                        scanContent = null,
                        fallback = tagId,
                        type = CodeTech.NFC,
                        scanPurpose = ScanPurpose.OpenCode,
                    )
//                window.alert("""
//                NFC tag scanned! (no Ndef!)
//                id: $id
//                """.trimIndent()
//                )
                },
                {
                    console.log("added NFC-TagDiscoverableListener")
                    console.log("Waiting for tags ...")
                },
                { error ->
                    console.log("Error adding TagDiscoverableListener: ${JSON.stringify(error)}")
                    handleNFCError(error)
                },
            )
        } else {
            console.log("NFC plugin not available")
        }
    }

    private fun addNdefListener() {
        if (isNFCPluginEnabled()) {
            nfcScanner?.addNdefListener(
                { event ->
                    console.log("Tag detected via NdefListener")
                    val tag = event.tag

                    val ndefTag = NdefTag(
                        id = nfcScanner.bytesToHexString(tag.id) as? String,
                        techTypes = (tag.techTypes as? Array<String>)?.toList() ?: emptyList(),
                        type = tag.type as? String,
                        maxSize = tag.maxSize as? Int,
                        isWritable = tag.isWritable as? Boolean,
                        hasRecords = tag.ndefMessage[0] != null,
                        ndefRecords = (tag.ndefMessage as? Array<dynamic>)?.map { record ->
                            NdefMessage(
                                tnf = record.tnf as? Int,
                                type = nfcScanner.bytesToString(record.type) as? String,
                                id = nfcScanner.bytesToString(record.id) as? String,
                                stringPayload = getStringPayloadFromNdefRecord(record),
                            )
                        }?.toList() ?: emptyList(),
                        canMakeReadOnly = tag.canMakeReadOnly as? Boolean,
                    )
                    console.log("NFC Ndef tag:", ndefTag)
                    handleScanResult(
                        scanContent = ndefTag.ndefRecords.firstOrNull {
                            isValidFormationUrl(it.stringPayload) || isValidTryFormationUrl(it.stringPayload)
                        }?.stringPayload ?: ndefTag.ndefRecords.firstOrNull()?.stringPayload,
                        fallback = ndefTag.id,
                        type = CodeTech.NFC,
                        scanPurpose = ScanPurpose.OpenCode,
                    )
//                window.alert("""
//                NFC Ndef tag scanned!
//                id: ${ndefTag.id}
//                type: ${ndefTag.type}
//                supported types: ${ndefTag.techTypes}
//                maxSize: ${ndefTag.maxSize} Bytes
//                isWritable: ${ndefTag.isWritable}
//                canMakeReadOnly: ${ndefTag.canMakeReadOnly}
//                hasNdefRecords: ${ndefTag.hasRecords}
//                ${if(ndefTag.hasRecords == true){
//                    """
//                        1st NdefRecord details:
//                        typeNameFormat: ${ndefTag.ndefRecords.firstOrNull()?.tnf}
//                        ndefType: ${ndefTag.ndefRecords.firstOrNull()?.type}
//                        ndefId: ${ndefTag.ndefRecords.firstOrNull()?.id}
//                        ndefPayload: ${ndefTag.ndefRecords.firstOrNull()?.stringPayload}
//                        """.trimIndent()
//                } else """""".trimIndent()}
//                """.trimIndent()
//                )
                },
                {
                    console.log("added NFC-NdefListener")
                    console.log("Waiting for ndef tags ...")
                },
                { error ->
                    console.log("Error adding NdefListener: ${JSON.stringify(error)}")
                    handleNFCError(error)
                },
            )

        } else {
            console.log("NFC plugin not available")
        }
    }

    val readIntentFromLocalStorage = handle { current ->
        val intent = window.localStorage.getItem("com.tryformation.webapp.formationUrlIntent")
        window.localStorage.removeItem("com.tryformation.webapp.formationUrlIntent")
        if (!intent.isNullOrBlank()) {
//            window.alert("""
//                    Intent stored!
//                    url?: $intent
//                    """.trimIndent()
//            )
            val scanContent: String = JSON.parse(intent)
            getFullPathFromScanContent(scanContent)?.let { path ->
                console.log("PASS scanned content as route: $scanContent to Router")
                routerStore.validateExternalRoute(path)
            } ?: run {
                console.log("PASS scanned content as id: $scanContent to Router")
                getIdFromScanContent(scanContent)?.let { id ->
                    routerStore.validateExternalRoute(mapOf("id" to id.urlEncode()))
                }
                routerStore.redirectRoute = mapOf("id" to id.urlEncode())
            }

//            handleScanResult(
//                scanContent = JSON.parse(intent),
//                fallback = JSON.parse(intent),
//                type = CodeTech.LINK,
//                scanPurpose = ScanPurpose.OpenCode
//            )
        }
        current
    }

    private fun getStringPayloadFromNdefRecord(ndefRecord: dynamic): String? {

        val nfcScanner by lazy { if (window["cordova"] != null) js("nfc") else null }
        return if (nfcScanner != null) {
            when (ndefRecord.tnf as? Int) {
                0 -> null
                1 -> when (nfcScanner.bytesToString(ndefRecord.type) as? String) {
                    // URI Records (0x55/'U')
                    "U" -> {
                        val identifier = (ndefRecord.payload as? Array<Byte>)?.get(0)
                        val uri = nfcScanner.bytesToString(
                            (ndefRecord.payload as? Array<Byte>)?.drop(1)?.toByteArray(),
                        ) as? String
                        when (identifier) {
                            0.toByte() -> uri
                            1.toByte() -> "http://www.$uri"
                            2.toByte() -> "https://www.$uri"
                            3.toByte() -> "http://$uri"
                            4.toByte() -> "https://$uri"
                            5.toByte() -> "tel:$uri"
                            6.toByte() -> "mailto:$uri"
                            7.toByte() -> "ftp://anonymous:anonymous@$uri"
                            8.toByte() -> "ftp://ftp.$uri"
                            9.toByte() -> "ftps://$uri"
                            10.toByte() -> "sftp://$uri"
                            11.toByte() -> "smb://$uri"
                            12.toByte() -> "nfs://$uri"
                            13.toByte() -> "ftp://$uri"
                            14.toByte() -> "dav://$uri"
                            15.toByte() -> "news:$uri"
                            16.toByte() -> "telnet://$uri"
                            17.toByte() -> "imap:$uri"
                            18.toByte() -> "rtsp://$uri"
                            19.toByte() -> "urn:$uri"
                            20.toByte() -> "pop:$uri"
                            21.toByte() -> "sip:$uri"
                            22.toByte() -> "sips:$uri"
                            23.toByte() -> "tftp:$uri"
                            24.toByte() -> "btspp://$uri"
                            25.toByte() -> "btl2cap://$uri"
                            26.toByte() -> "btgoep://$uri"
                            27.toByte() -> "tcpobex://$uri"
                            28.toByte() -> "irdaobex://$uri"
                            29.toByte() -> "file://$uri"
                            30.toByte() -> "urn:epc:id:$uri"
                            31.toByte() -> "urn:epc:tag:$uri"
                            32.toByte() -> "urn:epc:pat:$uri"
                            33.toByte() -> "urn:epc:raw:$uri"
                            34.toByte() -> "urn:epc:$uri"
                            35.toByte() -> "urn:nfc:$uri"
                            else -> null
                        }
                    }
                    // TEXT Records (0x54/'T')
                    "T" -> nfcScanner.bytesToString(ndefRecord.payload) as? String
                    // TODO maybe add more RTDs (Record Type Definitions) here
                    else -> null
                }

                else -> null
            }
        } else null
    }

    private val nfcScanner by lazy { if (window["cordova"] != null) js("nfc") else null }
}

fun handleScanResult(scanContent: String?, fallback: String?, type: CodeTech, scanPurpose: ScanPurpose) {
    val scannedCodeStore: ScannedCodeStore by koinCtx.inject()
    val apiUserStore: ApiUserStore by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()
    val mainController: MainController by koinCtx.inject()

    val userIsAnonymous = apiUserStore.current.isAnonymous

    val objId = getIdFromScanContent(scanContent)
    val path = getPageAndWsPathFromScanContent(scanContent)
    val objParams = getParamsMapFromScanContent(scanContent)

    console.log("Scanned code content:", scanContent, objId, objParams.toString())

    if (!path.isNullOrEmpty() && objId.isNullOrBlank()) {
        // handle url from scan or intent that does not contain an objectId, but a valid path
        val page = parseEnumValue<Pages>(path["page"])
        if (page != null) {
            when {
                page.notForAnonymous && userIsAnonymous -> {
                    val ret = path.toMutableMap()
                    ret["page"] = Pages.Map.name
                    routerStore.validateInternalRoute(ret)
                }

                page.preLoginPage -> {
                    mainController.logoutToPage(path)
                }

                else -> {
                    routerStore.validateInternalRoute(path)
                }
            }
        }
    } else {
        // handle all other cases:
        // ObjectId, UserVerification or unknown url and/or params (using raw scanContent as fallback)
        scannedCodeStore.checkCodeActionFromScan(
            ScannedCode(
                extOrIntObjIdOrActionId = objId ?: scanContent?.urlDecode(),
                fallback = fallback,
                codeTech = type,
                scanPurpose = scanPurpose,
                operation = parseEnumValue<OperationType>(objParams["o"]),
                color = parseEnumValue<MarkerColor>(objParams["c"]),
                icon = parseEnumValue<MarkerIcon>(objParams["i"]),
                shape = parseEnumValue<MarkerShape>(objParams["s"]),
                token = objParams["token"],
                loginToken = objParams["logintoken"],
                tag = objParams["t"],
                page = parseEnumValue<Pages>(objParams["page"]),
                workspace = objParams["ws"] ?: objParams["workspace"],
                scan = true,
            ),
        )
    }
}


const val localHostPrefix = "http://localhost:8081/#"
const val formationPrefix = "formation://"
const val appPrefix = "https://app.tryformation.com/#"
const val faultyAppPrefix = "https://app.tryformation.com#"
const val appDevPrefix = "https://app-dev.tryformation.com/#"

fun isValidFormationUrl(url: String?): Boolean {
    return !url.isNullOrBlank() && url.startsWith(formationPrefix) && url.length > 12
}

fun isValidTryFormationUrl(url: String?): Boolean {
    return !url.isNullOrBlank()
        && (url.startsWith(appPrefix) || url.startsWith(appDevPrefix) || url.startsWith(localHostPrefix)
        || url.startsWith(faultyAppPrefix))
        && url.length > 30
}

fun getPageAndWsPathFromScanContent(content: String?): Map<String, String>? {
    return if (!content.isNullOrBlank()) {
        when {
            isValidTryFormationUrl(content) -> {
                val params = getParamsMapFromScanContent(content)
                val ret = mutableMapOf<String, String>()
                params["page"]?.let { p -> ret["page"] = p }
                (params["ws"] ?: params["workspace"])?.let { w -> ret["ws"] = w }
                ret.toMap().ifEmpty { null }
            }

            else -> null
        }
    } else null
}

fun getFullPathFromScanContent(content: String?): Map<String, String>? {
    return if (!content.isNullOrBlank()) {
        when {
            isValidTryFormationUrl(content) -> {
                getParamsMapFromScanContent(content).ifEmpty { null }
            }

            else -> null
        }
    } else null
}

fun getIdFromScanContent(content: String?): String? {
    return if (!content.isNullOrBlank()) {
        when {
            isValidFormationUrl(content) -> content.split("//")[1].urlDecode()
            isValidTryFormationUrl(content) -> getParamsMapFromScanContent(content)["id"]?.urlDecode()
            else -> null
        }
    } else null
}

fun getParamsMapFromScanContent(url: String?): Map<String, String> {
    return if (isValidTryFormationUrl(url) && !url.isNullOrBlank()) {
        when {
            url.startsWith(localHostPrefix) -> {
                val params = url.split(localHostPrefix)[1].split("&")
                params.associate {
                    val param = it.split("=")
                    param[0] to param[1]
                }
            }

            url.startsWith(formationPrefix) -> {
                val params = url.split(formationPrefix)[1].split("&")
                params.associate {
                    val param = it.split("=")
                    param[0] to param[1]
                }
            }

            url.startsWith(appPrefix) -> {
                val params = url.split(appPrefix)[1].split("&")
                params.associate {
                    val param = it.split("=")
                    param[0] to param[1]
                }
            }

            url.startsWith(faultyAppPrefix) -> {
                val params = url.split(faultyAppPrefix)[1].split("&")
                params.associate {
                    val param = it.split("=")
                    param[0] to param[1]
                }
            }

            url.startsWith(appDevPrefix) -> {
                val params = url.split(appDevPrefix)[1].split("&")
                params.associate {
                    val param = it.split("=")
                    param[0] to param[1]
                }
            }

            else -> emptyMap()
        }
    } else emptyMap()
}
