package localization

import com.tryformation.localization.Locale
import data.users.settings.SyncedUserPreferencesStore
import dev.fritz2.core.RootStore
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.map

class LocaleStore(
    syncedUserPreferencesStore: SyncedUserPreferencesStore,
    initialLocale: Locale
) : RootStore<Locale>(
    initialData = initialLocale,
    job = Job(),
) {
    init {
        syncedUserPreferencesStore.data.map { it.languageCode?.let { code -> Locales.findByIdOrNull(code) } ?: current } handledBy update
    }
}
