// GENERATED by fritz2 - NEVER CHANGE CONTENT MANUALLY!
package model

import dev.fritz2.core.Lens
import dev.fritz2.core.lensOf
import kotlinx.coroutines.Job
import web.geolocation.GeolocationWatchId

fun LocationUploadState.Companion.backgroundJob(): Lens<LocationUploadState, Job?> =
    lensOf(
        "backgroundJob",
        { it.backgroundJob },
        { p, v -> p.copy(backgroundJob = v) }
    )

fun LocationUploadState.Companion.positionEngineWatchId(): Lens<LocationUploadState, GeolocationWatchId?> = lensOf(
    "positionEngineWatchId",
    { it.positionEngineWatchId },
    { p, v -> p.copy(positionEngineWatchId = v) }
)
