package koin

import org.koin.core.Koin
import org.koin.core.context.GlobalContext

val koinCtx by lazy { GlobalContext.get() }

inline fun <T> withKoin(block: Koin.() -> T): T = with(koinCtx) {
    block(this)
}
