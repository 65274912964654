package maplibreGL

import apiclient.geoobjects.*
import apiclient.util.withDuration
import auth.ApiUserStore
import com.github.nwillc.ksvg.RenderMode
import com.github.nwillc.ksvg.elements.SVG
import com.jillesvangurp.geo.GeoGeometry
import com.jillesvangurp.geojson.*
import data.ObjectAndUserHandler
import data.connectableshapes.DeleteConnectionConnectorIdStore
import data.connectableshapes.NewConnectableShapeConnectionStore
import data.objects.ActiveObjectStore
import data.objects.building.ActiveFloorLevelStore
import data.objects.emptyGeoObjectDetails
import data.objects.objecthistory.ObjectHistoryResultsCache
import data.objects.views.CurrentClusterStore
import data.users.settings.LocalSettingsStore
import dev.fritz2.components.compat.Div
import dev.fritz2.core.Id
import dev.fritz2.core.RenderContext
import dev.fritz2.core.Scope
import dev.fritz2.core.invoke
import io.ktor.http.*
import io.ktor.serialization.*
import koin.koinCtx
import kotlin.coroutines.EmptyCoroutineContext
import kotlin.js.json
import kotlin.math.cos
import kotlin.math.max
import kotlin.math.sin
import kotlin.time.Duration.Companion.milliseconds
import kotlin.time.measureTime
import kotlin.time.measureTimedValue
import kotlinx.browser.window
import kotlinx.coroutines.*
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlinx.serialization.json.Json
import map.views.*
import model.*
import objectrouting.RoutePoint
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.events.Event
import org.w3c.dom.events.EventListener
import search.PathActiveHighlightedObjectMarkersStore
import search.searchlayer.MapSearchClientsStore
import search.searchlayer.MapSearchResultsStore
import svgmarker.*
import theme.FormationColors
import theme.FormationIcons
import utils.*

const val renderInfoOutput = false
const val ADDITIONAL_GEOMETRY = "additionalGeometry"
const val DISTANCE_MEASURE = "distance-measure"
const val ROUTING_PATH = "routing-path"
private const val CLUSTER_RADIUS_PIXEL = 35.0

class MaplibreMap(
    val targetId: String,
    private val centerLat: Double,
    private val centerLng: Double,
    private val initZoom: Double,
) {

    private val currentClusterStore: CurrentClusterStore by koinCtx.inject()
    val initialized: Boolean get() = map != null
    var map: Map? = null
        private set

    private var mapContext: RenderContext? = null

    private val clusteredFeaturesMap = mutableMapOf<String, Pair<GeoObjectDetails, MapMarker>>()
    private val unClusteredFeaturesMap = mutableMapOf<String, Pair<GeoObjectDetails, MapMarker>>()
    private val myUserMarker = mutableListOf<MapMarkerMyUser>()
    var disabledObjects = mutableMapOf<String, Pair<GeoObjectDetails, String>>()
    var disabledUsers = mutableSetOf<String>()

    val activeObjectIds = mutableSetOf<String>()
    private val geometryCenterOverrides = mutableMapOf<String, Geometry.Point>()
    private val geometryShapeOverrides = mutableMapOf<String, Geometry>()
    private val hiddenMarkerIds = mutableSetOf<String>()
    private val priorityOverrides = mutableSetOf<String>()
    private val pathToolHighlightedObjectIds = mutableSetOf<String>()
    private val highlightedObjectIds = mutableSetOf<String>()

    var activeListeners = mutableMapOf<String, dynamic>()

    val activeObjectStore: ActiveObjectStore by koinCtx.inject()

    private fun isDisabled(id: String, type: ObjectType): Boolean {
        return when (type) {
            ObjectType.CurrentUserMarker -> id in disabledUsers
            else -> id in disabledObjects
        }
    }

    private fun isEnabled(id: String, type: ObjectType): Boolean =
        !isDisabled(id, type)

    class MaplibreDynamicObjectLayer(
        val id: ObjectType,
        var objectPairs: MutableMap<String, Pair<MaplibreElement, dynamic>> = mutableMapOf(),
    )

    class MaplibreDynamicObjectLayers(val layers: List<MaplibreDynamicObjectLayer>?)

    /** stores and displays dynamically generated objects on the map. One for each object class */
    private var maplibreDynamicObjectLayers = MaplibreDynamicObjectLayers(listOf())

    /** stores and displays map tile layer. */
    var tileLayers: kotlin.collections.Map<TileLayerData, Boolean>? = mapOf()

    init {
        utils.require("maplibre-gl/dist/maplibre-gl.css")

        val protocol = pmtiles.Protocol()

//        console.log("addProtocol", addProtocol("pmtiles", protocol.tile))

//        val pmtiles = pmtiles.PMTiles(PMTILES_URL)
//        protocol.add(pmtiles)
    }

    fun getMapRenderContext() = mapContext

    fun setRenderContext(ctx: RenderContext) {
        mapContext = ctx
    }

    private fun getGeoJSONSource(id: String): GeoJSONSource? {
        return map?.getSource(id) as? GeoJSONSource
    }

    fun removeMap() {
        if (map != null) {
            console.log("No user logging in anymore -> Removed map")
            map?.remove()

            markers.clear()
            clusterMarkers.clear()
            markersOnScreen.clear()
            clusterMarkersOnScreen.clear()
            clusteredFeaturesMap.clear()
            unClusteredFeaturesMap.clear()
            disabledObjects.clear()
            disabledUsers.clear()
            activeObjectIds.clear()
            geometryCenterOverrides.clear()
            geometryShapeOverrides.clear()
            hiddenMarkerIds.clear()
            priorityOverrides.clear()
            pathToolHighlightedObjectIds.clear()
            lastMaplibreElements.clear()
        }
        map = null
    }

    private var mapLastMoved: Instant = Instant.DISTANT_PAST
    private val markers = mutableMapOf<Int, Marker>()
    private val clusterMarkers = mutableMapOf<Int, Marker>()
    private val markersOnScreen = mutableMapOf<Int, Marker>()
    private val clusterMarkersOnScreen = mutableMapOf<Int, Marker>()

    private fun Map.onceOnSourcedata(sourceId: String, block: (e: dynamic) -> Unit) {
        console.log("OnceOnSourceData")
        once("sourcedata") {
            if (it.sourceId == sourceId) {
                block(it)
            } else {
                onceOnSourcedata(sourceId, block)
            }
        }
    }

    /**
     * Routing Path
     */

    val routingPathLayerIds = listOf(
        "routing-path",
        "routing-path-dashed",
        "routing-path-point-distance-until",
        "routing-path-points",
        "routing-path-points-with-floor-change",
    )

    // Routing path colors

    private val navStartPointColor = "#2dbc94"//FormationColors.GreenBright.color
    private val navPointColor = "#4285f4" //"#008aa1"//FormationColors.GrayLight.color
    private val navPointOutlineColor = FormationColors.White.color //"#f5f5b8" //FormationColors.BlueDeep.color
    private val navPointTextColor = "#4285f4" //"#f5f5b8" //FormationColors.GrayLight.color
    private val navPointTextOutlineColor = FormationColors.White.color
    private val routingLineColor = "#4285f4"// "#008aa1" //FormationColors.MarkerYou.color
    private val dashedRoutingLineColor = "#2dbc94"

    // GeoJSON object to store the routing features
    private var routingPathFeatureData = json(
        "type" to "FeatureCollection",
        "features" to arrayOf<Json>(),
    )

    private fun addRoutingPathLayers() {
        if (map?.getLayer("routing-path") == null) {
            map?.addLayer(
                json(
                    "id" to "routing-path",
                    "type" to "line",
                    "source" to ROUTING_PATH,
                    "filter" to arrayOf("==", arrayOf("get", "lineType"), "normal"),
                    "metadata" to json(
                        "defaultFilter" to arrayOf("==", arrayOf("get", "lineType"), "normal"),
                    ),
                    "layout" to json(
                        "line-cap" to "round",
                        "line-join" to "round",
                    ),
                    "paint" to json(
                        "line-color" to routingLineColor,
                        "line-width" to 7.5,
                    ),
                ),
            )
        }
        if (map?.getLayer("routing-path-dashed") == null) {
            map?.addLayer(
                json(
                    "id" to "routing-path-dashed",
                    "type" to "line",
                    "source" to ROUTING_PATH,
                    "filter" to arrayOf("==", arrayOf("get", "lineType"), "dashed"),
                    "metadata" to json(
                        "defaultFilter" to arrayOf("==", arrayOf("get", "lineType"), "dashed"),
                    ),
                    "layout" to json(
                        "line-cap" to "round",
                        "line-join" to "round",
                    ),
                    "paint" to json(
                        "line-color" to dashedRoutingLineColor,
                        "line-width" to 7.5,
                        "line-dasharray" to arrayOf(1, 3),
                    ),
                ),
            )
        }
        if (map?.getLayer("routing-path-point-distance-until") == null) {
            map?.addLayer(
                json(
                    "id" to "routing-path-point-distance-until",
                    "type" to "symbol",
                    "source" to ROUTING_PATH,
                    "filter" to arrayOf("==", arrayOf("geometry-type"), "Point"),
                    "metadata" to json(
                        "defaultFilter" to arrayOf("==", arrayOf("geometry-type"), "Point"),
                    ),
                    "layout" to json(
                        "symbol-placement" to "point",
                        "text-field" to arrayOf("get", "title"),
                        "text-size" to 16,
                        "text-font" to arrayOf("Open Sans Bold", "Arial Unicode MS Bold"),
                        "text-justify" to "auto",
                        "text-allow-overlap" to false,
                        "text-rotation-alignment" to "viewport",
                        "text-offset" to arrayOf(0, 3),
                    ),
                    "paint" to json(
                        "text-color" to navPointTextColor,
                        "text-halo-color" to navPointTextOutlineColor,
                        "text-halo-width" to 1.0,
                        "text-halo-blur" to 0.5,
                    ),
                ),
            )
        }
        if (map?.getLayer("routing-path-points") == null) {
            map?.addLayer(
                json(
                    "id" to "routing-path-points",
                    "type" to "circle",
                    "source" to ROUTING_PATH,
                    "filter" to arrayOf("==", arrayOf("get", "isFloorChange"), false),
                    "metadata" to json(
                        "defaultFilter" to arrayOf("==", arrayOf("get", "isFloorChange"), false),
                    ),
                    "paint" to json(
                        "circle-radius" to arrayOf("get", "circleRadius"),
                        "circle-color" to arrayOf("get", "color"),
                        "circle-stroke-color" to arrayOf("get", "strokeColor"),
                        "circle-stroke-width" to arrayOf("get", "strokeWidth"),
                    ),
                ),
            )
        }
        if (map?.getLayer("routing-path-points-with-floor-change") == null) {
            map?.addLayer(
                json(
                    "id" to "routing-path-points-with-floor-change",
                    "type" to "symbol",
                    "source" to ROUTING_PATH,
                    "filter" to arrayOf("==", arrayOf("get", "isFloorChange"), true),
                    "metadata" to json(
                        "defaultFilter" to arrayOf("==", arrayOf("get", "isFloorChange"), true),
                    ),
                    "layout" to json(
                        "symbol-placement" to "point",
                        "text-field" to arrayOf("get", "title"),
                        "text-size" to 16,
                        "text-font" to arrayOf("Open Sans Bold", "Arial Unicode MS Bold"),
                        "text-justify" to "auto",
                        "text-allow-overlap" to false,
                        "text-rotation-alignment" to "viewport",
                        "text-offset" to arrayOf(0, 3),
                        "icon-image" to arrayOf(
                            "case",
                            arrayOf("==", arrayOf("get", "isLevelUp"), true),
                            "levelUp",
                            arrayOf("==", arrayOf("get", "isLevelUp"), false),
                            "levelDown",
                            "stairs",
                        ),
                        "icon-size" to 0.2645,
                    ),
                    "paint" to json(
                        "text-color" to navPointTextColor,
                        "text-halo-color" to navPointTextOutlineColor,
                        "text-halo-width" to 0.5,
                        "text-halo-blur" to 0.5,
                    ),
                ),
            )
        }
    }

    fun resetRoutingPathFeatureData() {
        // reset feature data
        routingPathFeatureData = json(
            "type" to "FeatureCollection",
            "features" to arrayOf<Json>(),
        )
        (map?.getSource(ROUTING_PATH) as? GeoJSONSource)?.setData(routingPathFeatureData)
    }

    fun showRoutingPath(routePoints: List<RoutePoint>) {

        val activeFloorLevelStore: ActiveFloorLevelStore by koinCtx.inject()

        // add source and layers for points and line strings
        if (map?.getSource(ROUTING_PATH) == null) {
            map?.addSource(
                ROUTING_PATH,
                json(
                    "type" to "geojson",
                    "data" to routingPathFeatureData,
                ),
            )
            map?.onceOnSourcedata(ROUTING_PATH) {
                console.log("loaded?", map?.isSourceLoaded(ROUTING_PATH))
                addRoutingPathLayers()
                console.log("# Added routing path layers!")
            }
        } else {
            resetRoutingPathFeatureData()
        }

        // draw points and line strings with timer
        if (routePoints.size > 1) {
            var totalDistance = 0.0
            var i = 1
            var timer = 0

            routePoints.forEachIndexed { index, routePoint ->
                val newPoint = json(
                    "type" to "Feature",
                    "geometry" to json(
                        "type" to "Point",
                        "coordinates" to arrayOf(routePoint.lon, routePoint.lat),
                    ),
                    "properties" to json(
                        "id" to Clock.System.now().toString(),
                        "circleRadius" to 6,
                        "color" to if (index > 0) "transparent" else navStartPointColor,
                        "strokeColor" to if (index > 0) "transparent" else navPointOutlineColor,
                        "strokeWidth" to 2.0,
                        "isFloorChange" to false,
                        "isLevelUp" to false,
                        "floorId" to routePoint.floorId,
                        "floorLevel" to routePoint.floorLevel,
                    ),
                )
                routingPathFeatureData["features"].asDynamic().push(newPoint)
            }

            val pointFeatures = routingPathFeatureData["features"].unsafeCast<Array<Json>>().filter {
                it.asDynamic().geometry.type == "Point"
            }.toTypedArray()

            timer = window.setInterval(
                timeout = 650,
                handler = {
                    if (i < pointFeatures.size) {
                        pointFeatures[i].let { point ->
                            val routePoint = routePoints[i]
                            val prevCords = pointFeatures[i - 1].asDynamic().geometry.coordinates
                            val pointCoords = point.asDynamic().geometry.coordinates
                            val distance = calcDistance(prevCords, pointCoords)
                            val heading = calcHeading(prevCords, pointCoords)

                            val newLine = json(
                                "type" to "Feature",
                                "geometry" to json(
                                    "type" to "LineString",
                                    "coordinates" to arrayOf(prevCords, pointCoords),
                                ),
                                "properties" to json(
                                    "lineType" to if (i == 1 || i == pointFeatures.size - 1) "dashed" else "normal",
                                    // attach floorId from previous point to line, so it gets filtered out
                                    // by the floor level filter, when previous point is outside
                                    "floorId" to pointFeatures[i].asDynamic().properties.floorId,
                                ),
                            )

                            totalDistance += distance

                            if (routePoint.isFloorChange) {
                                // set special point properties for floor change points
                                point.asDynamic().properties.title =
                                    "Change floor ${routePoint.floorChange?.previousLevel} → ${routePoint.floorChange?.newLevel} (${totalDistance.getDistanceString()})"
                                point.asDynamic().properties.strokeColor = navPointOutlineColor
                                point.asDynamic().properties.strokeWidth = 2.0
                                point.asDynamic().properties.circleRadius = 10.0
                                point.asDynamic().properties.isFloorChange = true
                                point.asDynamic().properties.isLevelUp = routePoint.floorChange?.isLevelUp ?: false
                                point.asDynamic().properties.previousLevel = routePoint.floorChange?.previousLevel
                                point.asDynamic().properties.newLevel = routePoint.floorChange?.newLevel

                                if (point.asDynamic().properties.newLevel != point.asDynamic().properties.floorLevel) {
                                    CoroutineScope(EmptyCoroutineContext).launch {
                                        delay(1000)
                                        activeFloorLevelStore.update(routePoint.floorChange?.newLevel)
                                    }
                                }
                            } else {
                                // set point properties
                                point.asDynamic().properties.title = totalDistance.getDistanceString()
                                point.asDynamic().properties.color = navPointColor
                                point.asDynamic().properties.strokeColor = navPointOutlineColor
                                point.asDynamic().properties.strokeWidth = 2.0
                            }

                            routingPathFeatureData["features"].asDynamic().push(newLine)
                            (map?.getSource(ROUTING_PATH) as GeoJSONSource).setData(routingPathFeatureData)
                            easeTo(
                                LatLon(lat = routePoint.lat, lon = routePoint.lon),
                                zoom = max(getZoom(), 19.0),
                                pitch = 45.0,
                                bearing = if (distance > 2) heading else null, // do not rotate if points are too close together (e.g. on floor change)
                                duration = 650.0,
                            )
                            i++
                        }
                    } else {
                        window.clearInterval(timer)
                        map?.fitBounds(
                            bounds = LngLatBounds(
                                sw = LngLat(
                                    lng = routePoints.minBy { it.lon }.lon,
                                    lat = routePoints.minBy { it.lat }.lat,
                                ),
                                ne = LngLat(
                                    lng = routePoints.maxBy { it.lon }.lon,
                                    lat = routePoints.maxBy { it.lat }.lat,
                                ),
                            ),
                            options = obj {
                                padding = obj {
                                    top = 250
                                    bottom = 100
                                    left = 100
                                    right = 100
                                }
                                pitch = 0.0
                                animate = true
                                duration = 2000.0
//                                zoom = getZoom()
                            },
                        )
                        addFloorChangeClickListener()
//                        easeTo(
//                            center = routePoints[0],
//                            zoom = getZoom(),
//                            pitch = 0.0,
//                            bearing = calcHeading(
//                                pointFeatures[0].asDynamic().geometry.coordinates,
//                                pointFeatures[1].asDynamic().geometry.coordinates
//                            ),
//                            duration = 1000.0
//                        )
                    }
                },
            )
        }
    }

    fun drawRoutingPath(routePoints: List<RoutePoint>) {

        val activeFloorLevelStore: ActiveFloorLevelStore by koinCtx.inject()

        // add source and layers for points and line strings
        if (map?.getSource(ROUTING_PATH) == null) {
            map?.addSource(
                ROUTING_PATH,
                json(
                    "type" to "geojson",
                    "data" to routingPathFeatureData,
                ),
            )
            map?.onceOnSourcedata(ROUTING_PATH) {
                console.log("loaded?", map?.isSourceLoaded(ROUTING_PATH))
                addRoutingPathLayers()
                console.log("# Added routing path layers!")
            }
        } else {
            resetRoutingPathFeatureData()
        }

        // draw points and line strings with timer
        if (routePoints.size > 1) {
            var totalDistance = 0.0

            routePoints.forEachIndexed { index, routePoint ->
                val newPoint = json(
                    "type" to "Feature",
                    "geometry" to json(
                        "type" to "Point",
                        "coordinates" to arrayOf(routePoint.lon, routePoint.lat),
                    ),
                    "properties" to json(
                        "id" to Clock.System.now().toString(),
                        "circleRadius" to 6,
                        "color" to if (index > 0) "transparent" else navStartPointColor,
                        "strokeColor" to if (index > 0) "transparent" else navPointOutlineColor,
                        "strokeWidth" to 2.0,
                        "isFloorChange" to false,
                        "isLevelUp" to false,
                        "floorId" to routePoint.floorId,
                        "floorLevel" to routePoint.floorLevel,
                    ),
                )
                routingPathFeatureData["features"].asDynamic().push(newPoint)
            }

            val pointFeatures = routingPathFeatureData["features"].unsafeCast<Array<Json>>().filter {
                it.asDynamic().geometry.type == "Point"
            }.toTypedArray()

            pointFeatures.forEachIndexed { index, point ->
                if (index > 0) {
                    val routePoint = routePoints[index]
                    val prevCords = pointFeatures[index - 1].asDynamic().geometry.coordinates
                    val pointCoords = point.asDynamic().geometry.coordinates
                    val distance = calcDistance(prevCords, pointCoords)
                    val heading = calcHeading(prevCords, pointCoords)

                    val newLine = json(
                        "type" to "Feature",
                        "geometry" to json(
                            "type" to "LineString",
                            "coordinates" to arrayOf(prevCords, pointCoords),
                        ),
                        "properties" to json(
                            "lineType" to if (index == 1 || index == pointFeatures.size - 1) "dashed" else "normal",
                            // attach floorId from previous point to line, so it gets filtered out
                            // by the floor level filter, when previous point is outside
                            "floorId" to pointFeatures[index].asDynamic().properties.floorId,
                        ),
                    )

                    totalDistance += distance

                    if (routePoint.isFloorChange) {
                        // set special point properties for floor change points
                        point.asDynamic().properties.title =
                            "Change floor ${routePoint.floorChange?.previousLevel} → ${routePoint.floorChange?.newLevel} (${totalDistance.getDistanceString()})"
                        point.asDynamic().properties.strokeColor = navPointOutlineColor
                        point.asDynamic().properties.strokeWidth = 2.0
                        point.asDynamic().properties.circleRadius = 10.0
                        point.asDynamic().properties.isFloorChange = true
                        point.asDynamic().properties.isLevelUp = routePoint.floorChange?.isLevelUp ?: false
                        point.asDynamic().properties.previousLevel = routePoint.floorChange?.previousLevel
                        point.asDynamic().properties.newLevel = routePoint.floorChange?.newLevel

                        if (point.asDynamic().properties.newLevel != point.asDynamic().properties.floorLevel) {
                            CoroutineScope(EmptyCoroutineContext).launch {
                                delay(1000)
                                activeFloorLevelStore.update(routePoint.floorChange?.newLevel)
                            }
                        }
                    } else {
                        // set point properties
                        point.asDynamic().properties.title = totalDistance.getDistanceString()
                        point.asDynamic().properties.color = navPointColor
                        point.asDynamic().properties.strokeColor = navPointOutlineColor
                        point.asDynamic().properties.strokeWidth = 2.0
                    }

                    routingPathFeatureData["features"].asDynamic().push(newLine)
                    (map?.getSource(ROUTING_PATH) as GeoJSONSource).setData(routingPathFeatureData)
                    easeTo(
                        LatLon(lat = routePoint.lat, lon = routePoint.lon),
                        zoom = max(getZoom(), 19.0),
                        pitch = 45.0,
                        bearing = if (distance > 2) heading else null, // do not rotate if points are too close together (e.g. on floor change)
                        duration = 650.0,
                    )
                }
            }
            map?.fitBounds(
                bounds = LngLatBounds(
                    sw = LngLat(
                        lng = routePoints.minBy { it.lon }.lon,
                        lat = routePoints.minBy { it.lat }.lat,
                    ),
                    ne = LngLat(
                        lng = routePoints.maxBy { it.lon }.lon,
                        lat = routePoints.maxBy { it.lat }.lat,
                    ),
                ),
                options = obj {
                    padding = obj {
                        top = 250
                        bottom = 100
                        left = 100
                        right = 100
                    }
                    pitch = 0.0
                    animate = true
                    duration = 2000.0
//                                zoom = getZoom()
                },
            )
            addFloorChangeClickListener()
        }
    }

    private fun addFloorChangeClickListener() {

        val activeFloorLevelStore: ActiveFloorLevelStore by koinCtx.inject()

        map?.on("mouseenter", "routing-path-points-with-floor-change") {
            map?.getCanvas().style.cursor = "pointer"
            if (activeListeners["resetOnMapClick"] != null) {
                off(type = "click", fnId = "resetOnMapClick")
            }
        }
        map?.on("mouseleave", "routing-path-points-with-floor-change") {
            map?.getCanvas().style.cursor = ""
            once(type = "click", fn = activeObjectStore::resetOnMapClick, fnId = "resetOnMapClick")
        }

        map?.on("click", "routing-path-points-with-floor-change") { routPoint ->
            routPoint.preventDefault()

            val prev = routPoint?.features[0]?.properties?.previousLevel as? Double
            val next = routPoint?.features[0]?.properties?.newLevel as? Double
            val isLevelUp = routPoint.features[0]?.properties?.isLevelUp as? Boolean

            activeFloorLevelStore.update(
                when (activeFloorLevelStore.current) {
                    prev -> next
                    next -> prev
                    else -> if (isLevelUp == true) prev else next
                },
            )
        }
    }


    /**
     * Distance Measuring Tool
     */

    private val newLineColor = "#2dbc94"//FormationColors.GreenBright.color
    private val startPointColor = "#2dbc94"//FormationColors.GreenBright.color
    private val pointColor = "#4285f4" //"#008aa1"//FormationColors.GrayLight.color
    private val pointOutlineColor = "#f5f5b8" //FormationColors.BlueDeep.color
    private val pointTextColor = "#f5f5b8" //FormationColors.GrayLight.color
    private val pointTextOutlineColor = FormationColors.BlueDeep.color
    private val lineColor = "#4285f4"// "#008aa1" //FormationColors.MarkerYou.color
    private val lineTextColor = "#4285f4" // "#008aa1" //FormationColors.MarkerYou.color
    private val lineTextOutlineColor = FormationColors.BlueDeep.color

    private fun addDynamicDashedLineLayers() {
        map?.addLayer(
            json(
                "id" to "dynamic-line",
                "type" to "line",
                "source" to DISTANCE_MEASURE,
                "layout" to json(
                    "line-cap" to "round",
                    "line-join" to "round",
                ),
                "paint" to json(
                    "line-color" to newLineColor,
                    "line-width" to 2.5,
                    "line-dasharray" to arrayOf(2, 4),
                ),
                "filter" to arrayOf("==", arrayOf("get", "lineType"), "dashed"),
            ),
        )
        map?.addLayer(
            json(
                "id" to "dynamic-line-distance",
                "type" to "symbol",
                "source" to DISTANCE_MEASURE,
                "filter" to arrayOf("==", arrayOf("get", "lineType"), "dashed"),
                "layout" to json(
                    "symbol-placement" to "line-center",
                    "text-field" to arrayOf("get", "title"),
//                        "text-font" to arrayOf("Open Sans Regular"),
                    "text-size" to 18,
                    "text-justify" to "auto",
                    "text-allow-overlap" to false,
                    "text-rotation-alignment" to "viewport",
                    "text-offset" to arrayOf(0, 0.5),
                ),
                "paint" to json(
                    "text-color" to startPointColor,
                    "text-halo-color" to lineTextOutlineColor,
                    "text-halo-width" to 0.5,
                    "text-halo-blur" to 0.5,
                ),
            ),
        )
    }

    fun stopMeasurement() {
        map?.let {
            it.getCanvas()?.style.cursor = "unset"
        }
        off("click", fnId = "measure-click")
        off(type = "mousemove", fnId = "measure-cursor")

        listOf(
            "dynamic-line",
            "dynamic-line-distance",
        ).forEach { layer ->
            if (map?.getLayer(layer) != null) {
                map?.removeLayer(layer)
            }
        }
    }

    fun resumeMeasurement() {
        addDynamicDashedLineLayers()
        pointAddListener()
        dynamicDashedLineListener()
    }

    fun disableMeasuringTool() {
        stopMeasurement()

        listOf(
            "measure-points",
            "measure-lines",
            "line-distances",
            "point-distance-until",
        ).forEach { layer ->
            if (map?.getLayer(layer) != null) {
                map?.removeLayer(layer)
            }
        }
        if (map?.getSource(DISTANCE_MEASURE) != null) {
            map?.removeSource(DISTANCE_MEASURE)
        }
    }

    fun resetMeasureFeatureData() {
        // reset feature data
        measureFeatureData = json(
            "type" to "FeatureCollection",
            "features" to arrayOf<Json>(),
        )
        (map?.getSource(DISTANCE_MEASURE) as GeoJSONSource).setData(measureFeatureData)
    }

    // GeoJSON object to store the measurement features
    private var measureFeatureData = json(
        "type" to "FeatureCollection",
        "features" to arrayOf<Json>(),
    )

    fun enableMeasuringTool() {

        map?.addSource(
            DISTANCE_MEASURE,
            json(
                "type" to "geojson",
                "data" to measureFeatureData,
            ),
        )

        map?.onceOnSourcedata(DISTANCE_MEASURE) {
            console.log("loaded?", map?.isSourceLoaded(DISTANCE_MEASURE))
            // Add styles to the map
            map?.addLayer(
                json(
                    "id" to "measure-lines",
                    "type" to "line",
                    "source" to DISTANCE_MEASURE,
                    "layout" to json(
                        "line-cap" to "round",
                        "line-join" to "round",
                    ),
                    "paint" to json(
                        "line-color" to lineColor,
                        "line-width" to 2.5,
                    ),
                    "filter" to arrayOf("==", arrayOf("get", "lineType"), "normal"),
                ),
            )

            map?.addLayer(
                json(
                    "id" to "measure-points",
                    "type" to "circle",
                    "source" to DISTANCE_MEASURE,
                    "paint" to json(
                        "circle-radius" to 5,
                        "circle-color" to arrayOf("get", "color"),
                        "circle-stroke-color" to pointOutlineColor,
                        "circle-stroke-width" to 0.5,
                    ),
                    "filter" to arrayOf("in", "\$type", "Point"),
                ),
            )
            map?.addLayer(
                json(
                    "id" to "line-distances",
                    "type" to "symbol",
                    "source" to DISTANCE_MEASURE,
//                    "filter" to arrayOf("in", "\$type", "LineString"),
                    "filter" to arrayOf("==", arrayOf("get", "lineType"), "normal"),
                    "layout" to json(
                        "symbol-placement" to "line-center",
                        "text-field" to arrayOf("get", "title"),
//                        "text-font" to arrayOf("Open Sans Regular"),
                        "text-size" to 18,
                        "text-justify" to "auto",
                        "text-allow-overlap" to false,
                        "text-rotation-alignment" to "viewport",
                        "text-offset" to arrayOf(0, 0.5),
                    ),
                    "paint" to json(
                        "text-color" to lineTextColor,
                        "text-halo-color" to lineTextOutlineColor,
                        "text-halo-width" to 0.5,
                        "text-halo-blur" to 0.5,
                    ),
                ),
            )

            map?.addLayer(
                json(
                    "id" to "point-distance-until",
                    "type" to "symbol",
                    "source" to DISTANCE_MEASURE,
                    "filter" to arrayOf("in", "\$type", "Point"),
                    "layout" to json(
                        "symbol-placement" to "point",
                        "text-field" to arrayOf("get", "title"),
                        "text-size" to 16,
                        "text-justify" to "auto",
                        "text-allow-overlap" to false,
                        "text-rotation-alignment" to "viewport",
                        "text-offset" to arrayOf(0, 1),
                    ),
                    "paint" to json(
                        "text-color" to pointTextColor,
                        "text-halo-color" to pointTextOutlineColor,
                        "text-halo-width" to 0.5,
                        "text-halo-blur" to 0.5,
                    ),
                ),
            )
            console.log("# Added distance lines label layer!")
        }
    }

    private fun pointAddListener() {
        console.log("Add pointAdd")
        on(
            type = "click", fnId = "measure-click",
            fn = { e: dynamic ->
                console.log("Triggerd pointAdd")
                val clickedPoint = map.asDynamic().queryRenderedFeatures(
                    e.point,
                    json(
                        "layers" to arrayOf("measure-points"),
                    ),
                )

                // Remove all lineStrings from the layer so we can redraw them based on the points collection
                if (measureFeatureData["features"].unsafeCast<Array<Json>>().size > 1) {
                    measureFeatureData["features"] = measureFeatureData["features"].unsafeCast<Array<Json>>().filter {
                        it.asDynamic().geometry.type != "LineString"
                    }.toTypedArray()
                }


                // If a point was clicked, remove it from the map
                if (clickedPoint.unsafeCast<Array<Json>>().isNotEmpty()) {
                    val clickedPointId = clickedPoint[0].properties.id
                    measureFeatureData["features"] =
                        measureFeatureData["features"].unsafeCast<Array<Json>>().filter { point ->
                            point.asDynamic().properties.id != clickedPointId
                        }.toTypedArray()
                } else {
                    val newPoint = json(
                        "type" to "Feature",
                        "geometry" to json(
                            "type" to "Point",
                            "coordinates" to arrayOf(e.lngLat.lng, e.lngLat.lat),
                        ),
                        "properties" to json(
                            "id" to Clock.System.now().toString(),
                            "color" to if (measureFeatureData["features"].unsafeCast<Array<Json>>()
                                    .isNotEmpty()
                            ) pointColor else startPointColor,
                        ),
                    )

                    measureFeatureData["features"].asDynamic().push(newPoint)
                }

                // New Linestring for every point
                val points = measureFeatureData["features"].unsafeCast<Array<Json>>()
                if (measureFeatureData["features"].unsafeCast<Array<Json>>().size > 1) {
                    var totalDistance = 0.0
                    // Iterate through all points and create LineStrings between them
                    points.forEachIndexed { index, point ->
                        if (index > 0) {
                            val prevCords =
                                measureFeatureData["features"].unsafeCast<Array<Json>>()[index - 1].asDynamic().geometry.coordinates
                            val pointCoords = point.asDynamic().geometry.coordinates

                            val distance = calcDistance(prevCords, pointCoords)

                            val newLine = json(
                                "type" to "Feature",
                                "geometry" to json(
                                    "type" to "LineString",
                                    "coordinates" to arrayOf(prevCords, pointCoords),
                                ),
                                "properties" to json(
                                    "lineType" to "normal",
                                    "title" to distance.getDistanceString(),
                                ),
                            )

                            totalDistance += distance
                            // add total distance until this point as title to each point
                            point.asDynamic().properties.title = totalDistance.getDistanceString()
                            point.asDynamic().properties.color = pointColor

                            measureFeatureData["features"].asDynamic().push(newLine)
                        } else {
                            point.asDynamic().properties.title = ""
                            point.asDynamic().properties.color = startPointColor
                        }
                    }
                    console.log("Total Distance", totalDistance.getDistanceString())
                }

                (map?.getSource(DISTANCE_MEASURE) as GeoJSONSource).setData(measureFeatureData)
            },
        )
    }

//    fun doubleClick() {
//        console.log("Add dblclick")
//        on(
//            type = "dblclick", fnId = "measure-disable-dblclick",
//            fn = { e: dynamic ->
//                console.log("Triggerd dblclick")
//                off(type = "click", fnId = "measure-click")
//                off(type = "mousemove", fnId = "measure-cursor")
//                lineClick()
//            },
//        )
//    }
//
//    fun lineClick() {
//        if (activeListeners["measure-enable-click"] == null) {
//            console.log("Add lineclick")
//            on(
//                type = "click", layerId = "measure-lines", fnId = "measure-enable-click",
//                fn = { e: dynamic ->
//                    console.log("Triggerd lineclick")
//                    pointAdd()
//                    dashedLine()
////                 remove this listener itself
//                    off(type = "click", fnId = "measure-enable-click")
//                },
//            )
//        }
//    }

    private fun dynamicDashedLineListener() {
        console.log("Add dashedLine")
        on(
            type = "mousemove", fnId = "measure-cursor",
            fn = { e: dynamic ->
                val clickedPoint = map.asDynamic().queryRenderedFeatures(
                    e.point,
                    json(
                        "layers" to arrayOf("measure-points"),
                    ),
                )
                // UI indicator for clicking/hovering a point on the map
                map?.getCanvas().style.cursor =
                    if (clickedPoint.unsafeCast<Array<Json>>().isNotEmpty()) "pointer" else "crosshair"

                // Draw dashed line from last point to cursor
                if (measureFeatureData["features"].unsafeCast<Array<Json>>().isNotEmpty()) {
                    // add newPoint and line to map
                    val lastPoint = measureFeatureData["features"].unsafeCast<Array<Json>>().lastOrNull {
                        it.asDynamic().geometry.type == "Point"
                    }
                    lastPoint?.let { point ->
                        val lastPointCoordinates = point.asDynamic().geometry.coordinates
                        val cursorCoordinates = arrayOf(e.lngLat.lng, e.lngLat.lat)

                        // new dashed line
                        val dashedLine = json(
                            "type" to "Feature",
                            "geometry" to json(
                                "type" to "LineString",
                                "coordinates" to arrayOf(lastPointCoordinates, cursorCoordinates),
                            ),
                            "properties" to json(
                                "lineType" to "dashed",
                                "title" to calcDistance(lastPointCoordinates, cursorCoordinates).getDistanceString(),
                            ),
                        )

                        measureFeatureData["features"] =
                            measureFeatureData["features"].unsafeCast<Array<Json>>().filter {
                                it.asDynamic().properties.lineType != "dashed"
                            }.toTypedArray()
                        measureFeatureData["features"].asDynamic().push(dashedLine)

                        (map?.getSource(DISTANCE_MEASURE) as GeoJSONSource).setData(measureFeatureData)
                    }
                }
            },
        )
    }

    private fun calcDistance(point1: dynamic, point2: dynamic): Double {
        val distance = GeoGeometry.distance(
            LatLon(
                point1.unsafeCast<Array<Double>>()[1],
                point1.unsafeCast<Array<Double>>()[0],
            ).pointCoordinates(),
            LatLon(
                point2.unsafeCast<Array<Double>>()[1],
                point2.unsafeCast<Array<Double>>()[0],
            ).pointCoordinates(),
        )
        return distance
    }

    private fun calcHeading(point1: dynamic, point2: dynamic): Double {
        val heading = GeoGeometry.headingFromTwoPoints(
            LatLon(
                point1.unsafeCast<Array<Double>>()[1],
                point1.unsafeCast<Array<Double>>()[0],
            ).pointCoordinates(),
            LatLon(
                point2.unsafeCast<Array<Double>>()[1],
                point2.unsafeCast<Array<Double>>()[0],
            ).pointCoordinates(),
        )
        return heading
    }

    private fun initGeoJsonLayers() {
        if (map?.getLayer("$ADDITIONAL_GEOMETRY-fill") == null) {
            map?.addLayer(
                json(
                    "id" to "$ADDITIONAL_GEOMETRY-fill",
                    "type" to "fill",
                    "source" to ADDITIONAL_GEOMETRY,
                    "filter" to arrayOf("==", arrayOf("get", "geoJSON_type"), "normal"),
                    "layout" to json(),
                    "paint" to json(
                        "fill-antialias" to true,
                        "fill-color" to arrayOf("get", "fill_color"),
                        "fill-opacity" to arrayOf("get", "fill_opacity"),
                    ),
                ),
            )
        }
        if (map?.getLayer("$ADDITIONAL_GEOMETRY-line") == null) {
            map?.addLayer(
                json(
                    "id" to "$ADDITIONAL_GEOMETRY-line",
                    "type" to "line",
                    "source" to ADDITIONAL_GEOMETRY,
                    "filter" to arrayOf("==", arrayOf("get", "line_type"), "normal"),
                    "layout" to json(),
                    "paint" to json(
                        "line-color" to arrayOf("get", "line_color"),
                        "line-width" to arrayOf("get", "line_width"),
                        "line-opacity" to arrayOf("get", "line_opacity"),
                    ),
                ),
            )
        }
        if (map?.getLayer("$ADDITIONAL_GEOMETRY-line-dashed") == null) {
            map?.addLayer(
                json(
                    "id" to "$ADDITIONAL_GEOMETRY-line-dashed",
                    "type" to "line",
                    "source" to ADDITIONAL_GEOMETRY,
                    "filter" to arrayOf("==", arrayOf("get", "line_type"), "dashed"),
                    "layout" to json(),
                    "paint" to json(
                        "line-color" to arrayOf("get", "line_color"),
                        "line-width" to arrayOf("get", "line_width"),
                        "line-opacity" to arrayOf("get", "line_opacity"),
                        "line-dasharray" to arrayOf(5, 10),
                    ),
                ),
            )
        }
        if (map?.getLayer("$ADDITIONAL_GEOMETRY-line-gradient-green-red") == null) {
            map?.addLayer(
                json(
                    "id" to "$ADDITIONAL_GEOMETRY-line-gradient-green-red",
                    "type" to "line",
                    "source" to ADDITIONAL_GEOMETRY,
                    "filter" to arrayOf("==", arrayOf("get", "line_type"), "gradient_green_red"),
                    "layout" to json(
                        "line-join" to "round",
                    ),
                    "paint" to json(
//                        "line-color" to arrayOf("get", "line_color"),
                        "line-width" to arrayOf("get", "line_width"),
                        "line-opacity" to arrayOf("get", "line_opacity"),
                        "line-gradient" to arrayOf(
                            "interpolate",
                            arrayOf("linear"),
                            arrayOf("line-progress"),
                            0,
                            makeRGBA("#00FF00", 1.0),
                            0.1,
                            makeRGBA("#95FF66", 0.9),
                            0.3,
                            makeRGBA("#C8FFB3", 0.7),
                            0.5,
                            makeRGBA("#FFC2A6", 0.6),
                            0.7,
                            makeRGBA("#FF6B5E", 0.5),
                            1.0,
                            makeRGBA("#FF0000", 0.3),
                        ),
                    ),
                ),
            )
        }

        if (map?.getLayer("$ADDITIONAL_GEOMETRY-connectable-exclusion-zones") == null) {
            map?.addLayer(
                json(
                    "id" to "$ADDITIONAL_GEOMETRY-connectable-exclusion-zones",
                    "type" to "fill",
                    "source" to ADDITIONAL_GEOMETRY,
                    "filter" to arrayOf("==", arrayOf("get", "geoJSON_type"), "connectable-exclusion-zone"),
                    "layout" to json(),
                    "paint" to json(
                        "fill-antialias" to true,
                        "fill-color" to arrayOf("get", "fill_color"),
                        "fill-opacity" to arrayOf("get", "fill_opacity"),
                    ),
                ),
            )
        }

        if (map?.getLayer("$ADDITIONAL_GEOMETRY-connectable-shapes") == null) {
            map?.addLayer(
                json(
                    "id" to "$ADDITIONAL_GEOMETRY-connectable-shapes",
                    "type" to "fill",
                    "source" to ADDITIONAL_GEOMETRY,
                    "filter" to arrayOf("==", arrayOf("get", "geoJSON_type"), "connectable-shape"),
                    "layout" to json(),
                    "paint" to json(
                        "fill-antialias" to true,
                        "fill-color" to arrayOf("get", "fill_color"),
                        "fill-opacity" to arrayOf("get", "fill_opacity"),
                    ),
                ),
            )
        }

        if (map?.getLayer("$ADDITIONAL_GEOMETRY-connectors") == null) {
            map?.addLayer(
                json(
                    "id" to "$ADDITIONAL_GEOMETRY-connectors",
                    "type" to "circle",
                    "source" to ADDITIONAL_GEOMETRY,
                    "filter" to arrayOf("==", arrayOf("get", "geoJSON_type"), "connector"),
                    "layout" to json(),
                    "paint" to json(
                        "circle-color" to arrayOf("get", "fill_color"),
                        "circle-opacity" to arrayOf("get", "fill_opacity"),
                        "circle-radius" to arrayOf("get", "circle_radius"),
                        "circle-stroke-color" to arrayOf("get", "line_color"),
                        "circle-stroke-width" to arrayOf("get", "line_width"),
                        "circle-stroke-opacity" to arrayOf("get", "line_opacity"),
                    ),
                ),
            )
            addConnectorClickListeners()
        }
        if (map?.getLayer("$ADDITIONAL_GEOMETRY-connections") == null) {
            map?.addLayer(
                json(
                    "id" to "$ADDITIONAL_GEOMETRY-connections",
                    "type" to "line",
                    "source" to ADDITIONAL_GEOMETRY,
                    "filter" to arrayOf("==", arrayOf("get", "geoJSON_type"), "connection"),
                    "layout" to json(
                        "line-join" to "round",
                        "line-cap" to "round",
                    ),
                    "paint" to json(
                        "line-color" to arrayOf("get", "line_color"),
                        "line-width" to arrayOf("get", "line_width"),
                        "line-opacity" to arrayOf("get", "line_opacity"),
                    ),
                ),
            )
            addConnectionClickListeners()
        }
        if (map?.getLayer("$ADDITIONAL_GEOMETRY-connections-distances") == null) {
            map?.addLayer(
                json(
                    "id" to "$ADDITIONAL_GEOMETRY-connections-distances",
                    "type" to "symbol",
                    "source" to ADDITIONAL_GEOMETRY,
                    "filter" to arrayOf("==", arrayOf("get", "geoJSON_type"), "connection"),
                    "layout" to json(
                        "symbol-placement" to "line-center",
                        "text-field" to arrayOf("get", "title"),
//                        "text-font" to arrayOf("Open Sans Regular"),
                        "text-size" to 20,
                        "text-justify" to "auto",
                        "text-allow-overlap" to false,
                        "text-rotation-alignment" to "viewport",
                        "text-offset" to arrayOf(0, 0.5),
                    ),
                ),
            )
        }
    }

    private fun Map.addGeojson() {
        if (getSource(ADDITIONAL_GEOMETRY) == null) {
            addSource(
                ADDITIONAL_GEOMETRY,
                json(
                    "type" to "geojson",
                    "maxzoom" to 24,
                    "lineMetrics" to true,
                    "cluster" to false,
                    "promoteId" to "id",
                    "data" to json(
                        "type" to "FeatureCollection",
                        "features" to arrayOf<dynamic>(),
                    ),
                ),
            )
            onceOnSourcedata(ADDITIONAL_GEOMETRY) {
                console.log("loaded?", isSourceLoaded(ADDITIONAL_GEOMETRY))
                initGeoJsonLayers()
            }
        } else {
            initGeoJsonLayers()
        }
    }

    suspend fun insertMap(existingMapState: MapState? = null, existingObjectLayers: List<ObjectLayer>? = null) {

        val mapSearchClientsStore: MapSearchClientsStore by koinCtx.inject()
        val mapStyleSelectionStore: MapStyleSelectionStore by koinCtx.inject()
        val center = LngLat(lng = centerLng, lat = centerLat)

        val map = Map(
            MaplibreMapOptions(
                containerId = targetId,
                center = center,
                zoom = initZoom,
                style = when (val current = mapStyleSelectionStore.current) {
                    is MapStyleOnline -> current.url
                    is MapStyleOffline -> run {
                        console.warn("changing mapstlye to ${current.name}", current.styleSpecification)
                        current.styleSpecification
                    }

                    else -> null
                },
            ).toJs(),
        )
        this.map = map
        map.once("load") { event ->
            map.addGeojson()
        }

//        on("movestart", fn = mapSearchClientsStore::cancelSearch, fnId = "cancel-search")
//        map.on("move", ::syncMaplibreMarkersHandler)
        map.on("moveend", ::syncMaplibreMarkersHandler)

        coroutineScope {
            promise {
                map.loadImage("assets/images/marker/levelUp_navBlue_128.png")
            }.then { levelUp: dynamic ->
                map.addImage("levelUp", levelUp.data)
                console.log("Added levelUp image", levelUp.data)
            }
            promise {
                map.loadImage("assets/images/marker/levelDown_navBlue_128.png")
            }.then { levelDown: dynamic ->
                map.addImage("levelDown", levelDown.data)
                console.log("Added levelDown image", levelDown.data)
            }
            promise {
                map.loadImage("assets/images/marker/stairs_navBlue_128.png")
            }.then { stairs: dynamic ->
                map.addImage("stairs", stairs.data)
                console.log("Added stairs image", stairs.data)
            }
        }

        if (existingMapState != null) {
            createMaps(existingMapState.center.toLngLat(), existingMapState.zoom, existingObjectLayers)
        } else createMaps()
    }

    private suspend fun createMaps(
        lastCenter: LngLat? = null,
        lastZoom: Double? = null,
        existingObjectLayers: List<ObjectLayer>? = null,
    ) {
        if (lastCenter != null) map?.setCenter(lastCenter)
        if (lastZoom != null) map?.setZoom(lastZoom)

        maplibreDynamicObjectLayers = MaplibreDynamicObjectLayers(
            ObjectType.entries.map {
                MaplibreDynamicObjectLayer(id = it)
            },
        )

        map?.addControl(AttributionControl(obj { }), "top-right")
        insertTopRightSideControls()
        map?.addControl(
            NavigationControl(
                obj {
                    this.showCompass = true
                    this.showZoom = false
                    this.visualizePitch = true
                },
            ),
        )
        map?.addControl(ScaleControl(obj { this.unit = "metric" }), "bottom-right")
//        map.addControl(GeolocateControl(obj {
//            this.trackUserLocation = true
//            this.showAccuracyCircle = true
//            this.showUserLocation = true
//        }), "bottom-right")
        insertBottomRightSideControls()
        insertBottomLeftSideControls()

        if (existingObjectLayers != null && isStyleLoaded()) updateMap(existingObjectLayers)

        // Create vector tile layers initially
        if (isStyleLoaded()) updateTileLayersSourceToken()
    }

    private fun geoJsonUpdateData(diff: GeoJSONSourceDiff, sourceId: String) {
        val source = getGeoJSONSource(sourceId)
        if (source != null) {
            source.updateData(diff = diff)
        } else {
            console.warn("failed to get GeoJSON source", sourceId)
        }
    }

    private fun geoJSONReplaceData(features: List<GeoJSONFeature>, sourceId: String) {
//        console.log("replaceData on '$sourceId'")
        val source = getGeoJSONSource(sourceId)
//        console.log("replaceData on source:", source)
        if (source != null) {
            val jsonFeatureCollection = json(
                "type" to "FeatureCollection",
                "features" to features,
            )
            source.setData(
                jsonFeatureCollection,
            )
        } else {
            // only print this warning if it actually tried to replace data
            if (features.isNotEmpty()) {
                console.warn("Cannot replace data of non-existing GeoJSON source: $sourceId.")
            }
        }
    }

    private fun geoJSONRemove(ids: Array<String>, sourceId: String) {
        geoJsonUpdateData(
            jsApply<GeoJSONSourceDiff> {
                remove = ids
            },
//            json(
//                "remove" to ids
//            ),
            sourceId,
        )
    }

    fun clearClustersFromMap() {
        console.warn("Refresh clusters and markers!!!")
        clusterMarkersOnScreen.forEach { (_, marker) ->
            marker.remove()
        }
        clusterMarkers.forEach { (_, marker) ->
            marker.remove()
        }
//        markers.forEach { (_, marker) ->
//            marker.remove()
//        }
//        markersOnScreen.forEach { (_, marker) ->
//            marker.remove()
//        }
        clusterMarkers.clear()
        clusterMarkersOnScreen.clear()
//        markers.clear()
//        markersOnScreen.clear()
//        syncMarkersNow()
    }

    fun geoObjectDetailsSetData(geoObjects: Set<GeoObjectDetails>, clustered: Boolean) {
        val connectableGeoShapes = extractConnectableGeoShapesMap(geoObjects)
        val overlappingShapeIds = geoObjects.extractOverlappingGeoShapeIds
        val features = measureTimedValue {
            geoObjects.mapNotNull { geoObject ->
                val desaturated =
                    pathToolHighlightedObjectIds.isNotEmpty() && geoObject.id !in pathToolHighlightedObjectIds
                val highlighted = highlightedObjectIds.isNotEmpty() && geoObject.id in highlightedObjectIds
                GeojsonConverter.fromGeoObject(
//                    mapRenderContext = mapRenderContext,
                    geoObject = geoObject,
                    desaturated = desaturated,
                    highlighted = highlighted,
                    connectables = connectableGeoShapes,
                    overlappingShapeIds = overlappingShapeIds,
                )?.let {
                    geoObject.id to (geoObject to it)
                }
            }.toMap()
        }.withDuration {
//            if (value.isNotEmpty()) {
//                console.log("converted", value.size, "elements in", duration.toString())
//            }
        }

        if (clustered) {
            clusteredFeaturesMap.clear()
            clusteredFeaturesMap.putAll(features)
        } else {
            unClusteredFeaturesMap.clear()
            unClusteredFeaturesMap.putAll(features)
        }
    }

    fun geoObjectAddOrUpdate(geoObject: GeoObjectDetails, clustered: Boolean) {

        val geoObjects = (clusteredFeaturesMap + unClusteredFeaturesMap).values.map { it.first }.toSet()
        val connectableGeoShapes = extractConnectableGeoShapesMap(geoObjects)
        val overlappingShapeIds = geoObjects.extractOverlappingGeoShapeIds
        val desaturated = pathToolHighlightedObjectIds.isNotEmpty() && geoObject.id !in pathToolHighlightedObjectIds
        val highlighted = highlightedObjectIds.isNotEmpty() && geoObject.id in highlightedObjectIds
        val mapMarker = GeojsonConverter.fromGeoObject(
//                mapRenderContext = mapRenderContext,
            geoObject = geoObject,
            desaturated = desaturated,
            highlighted = highlighted,
            connectables = connectableGeoShapes,
            overlappingShapeIds = overlappingShapeIds,
        ) ?: return

        if (clustered) {
            clusteredFeaturesMap[geoObject.id] = geoObject to mapMarker
        } else {
            unClusteredFeaturesMap[geoObject.id] = geoObject to mapMarker
        }
        syncMarkersNow(trigger = "geoObjectAddOrUpdate")
    }

    fun geoObjectDetailsRemove(geoObjectId: String, clustered: Boolean) {
        // remove geoJSON children of object first, then the object
        if (clustered) {

            clusteredFeaturesMap[geoObjectId]?.second?.geoJSONs?.forEach { geoJSONChild ->
                clusteredFeaturesMap.remove(geoJSONChild.id)
            }
            clusteredFeaturesMap.remove(geoObjectId)
        } else {
            unClusteredFeaturesMap[geoObjectId]?.second?.geoJSONs?.forEach { geoJSONChild ->
                unClusteredFeaturesMap.remove(geoJSONChild.id)
            }
            unClusteredFeaturesMap.remove(geoObjectId)
        }
        syncMarkersNow(trigger = "geoObjectDetailsRemove")
    }

    private fun polarToCartesian(center: Double, radius: Double, angleInDegrees: Double): Pair<Double, Double> {
        val radians = (angleInDegrees - 90) * kotlin.math.PI / 180
        return Pair(
            center + (radius * cos(radians)),
            center + (radius * sin(radians)),
        )
    }

    private fun getPath(center: Double, radius: Double, startAngle: Double, endAngle: Double): String {
        var endAngle = endAngle
        val isCircle = endAngle - startAngle == 360.0
        if (isCircle) {
            endAngle -= 2.0 // trick to get a almost full circle to render
        }
        val start = polarToCartesian(center, radius, startAngle);
        val end = polarToCartesian(center, radius, endAngle)
        val largeArcFlag = if ((endAngle - startAngle) <= 180) 0 else 1
        val d = mutableListOf(
            "M", start.first, start.second,
            "A", radius, radius, 0, largeArcFlag, 1, end.first, end.second,
        )
        if (isCircle) {
            d += "Z"
        } else {
            d += listOf(
                "L", radius, radius,
                "L", start.first, start.second,
                "Z",
            )
        }
        return d.joinToString(" ")
    }

    private fun piePaths(center: Double, radius: Double, segments: List<Double>): List<String> {
        if (segments.isEmpty()) return emptyList()
        val total = segments.sum()
        return segments
            .let {
                listOf(0.0) + it
            }
            .map {
                (it / total) * 360.0
            }
            .runningReduce { acc, it ->
                acc + it
            }.zipWithNext { a, b ->
                a to b
            }.map { (from, to) ->
                getPath(center, radius, from, to)
            }
    }

    fun makeClusterMarkerSvg(
        count: Int,
        props: ClusterProperties,
        withShadow: Boolean = true,
        desaturated: Boolean = false,
    ): String {
        val colors = props.colors.entries
            .map { (k, v) -> k to v }

        val paddingRatio = 1.3
        val bgWidth = pointSize.smPixel
        val wholeWidth = 44 // (bgWidth * paddingRatio).toInt()

        val svg = SVG.svg(false) {
            width = "$wholeWidth"
            height = "$wholeWidth"
            if (withShadow) cssClass = "drop-shadow-md"

            attributes["preserveAspectRatio"] = "xMidYMid meet"
            attributes["cursor"] = "pointer"

            val lineWidth = 8
            val center = (wholeWidth / 2)
            val radius = center

            val colors = colors.let {
                if (it.size == 1) {
                    it + it
                } else {
                    it
                }
            }
            if (colors.isEmpty()) {
                console.warn("cluster marker had no colors", props)
            }

            val pieSegments = colors.zip(
                piePaths(
                    center = center.toDouble(),
                    radius = radius.toDouble(),
                    segments = colors.map { (_, it) -> it.toDouble() },
                ),
            ) { (color, _), path ->
                Pair(color, path)
            }

            pieSegments.forEach { (color, path) ->
                path {
                    if (desaturated) {
                        attributes["filter"] = "grayscale(100%)"
                        attributes["opacity"] = "0.5"
                    }
                    this.id = color.name
                    this.d = path
                    this.fill = color.color
                }
            }

            //background
            circle {
                if (desaturated) {
                    attributes["filter"] = "grayscale(100%)"
                    attributes["opacity"] = "0.5"
                }
                cx = center.toString()
                cy = center.toString()
                r = (radius - lineWidth).toString()
                fill = FormationColors.GrayLight.color
            }
            if (count > 0) {
                g {
                    cssClass = "chart-text"
                    text {
                        if (desaturated) {
                            attributes["filter"] = "grayscale(100%)"
                            attributes["opacity"] = "0.5"
                        }
                        cssClass = "chart-number"
                        this.x = "50%"
                        this.y = "50%"
                        body = "$count"
                    }
                }
            }
        }
        val svgContent = buildString {
            svg.render(this, RenderMode.INLINE)
        }
        return svgContent
    }

    private var coordinatePointers: kotlin.collections.MutableMap<String, Marker> = mutableMapOf()

    fun insertTemporaryMarker(title: String, coordinates: LatLon) {
        val newId = "coordinate-${Id.next(9)}"
        val renderContext = getMapRenderContext() ?: return

        val svgIconOptions = SvgIconOptions(
            icon = FormationIcons.Location,
        )

        val coordinateMarkerEventListener = EventListener { event ->
            event.stopPropagation()
            coordinatePointers[getObjectOrUserIdFromEvent(event)]?.remove()
        }

        val markerOptions = MaplibreMarkerOptions(
            element = getDomElement(newId, renderContext) {
                makeSvgMarker(
                    objectId = newId,
                    objectType = ObjectType.POI,
                    title = title,
                    svgIconOptions = svgIconOptions,
                    active = false,
                    showTitle = true,
                )
            }.also { element ->
                element.addEventListener("click", coordinateMarkerEventListener)
            },
        )
        val marker = Marker(markerOptions.toJs())
        marker.setLngLat(coordinates.toLngLat())
        coordinatePointers[newId] = marker
        marker.addTo(map)
    }

    private fun syncMaplibreMarkersHandler(e: dynamic) {
        val eventType = e.type as String
//        console.log("syncMaplibreMarkers", e.type)
        val doRateLimiting = when (eventType) {
            "move" -> true
            "sourcedata" -> true
            else -> false
        }
        val updateObjectLayers = when (eventType) {
            "moveend" -> true
            "sourcedata" -> true
            else -> false
        }
        val updateGeoJSON = when (eventType) {
            else -> true
        }
        syncMarkerScope.launch {
            try {
                measureTime {
                    syncMarkers(
                        source = "event $eventType",
                        doRateLimiting = doRateLimiting,
                        updateImageLayers = updateObjectLayers,
                        updateGeoJSON = updateGeoJSON,
                        activeObjectId = activeObjectStore.current.id,
                    )
                }.also {
//                    console.info("sync-markers-event took", it.toString())
                }
            } catch (e: ClassCastException) {
                console.error("it borked", e)
                e.printStackTrace()
            }
        }

    }

    val syncMarkerScope = CoroutineScope(
        CoroutineName("sync-markers"),
    )

    fun syncMarkersNow(
        updateGeoJSON: Boolean = true,
        updateImageLayers: Boolean = false, //TODO CHEKL
        trigger: String = "?",
        doRateLimiting: Boolean = false,
    ) {
        syncMarkerScope.launch {
            try {
                syncMarkers(
                    source = "$trigger and sync",
                    doRateLimiting = doRateLimiting,
                    updateImageLayers = updateImageLayers,
                    updateGeoJSON = updateGeoJSON,
                    activeObjectId = activeObjectStore.current.id,
                )
            } catch (e: ClassCastException) {
                console.error("it borked", e)
            }
        }
    }

    private var lastActiveConnectorIds: Set<String> = setOf()

    private fun applyOverridesAndConvert(
        geoObjects: kotlin.collections.Map<GeoObjectDetails, MapMarker>,
        activeObjectId: String,
    ): List<MapMarker> {
        val activeObjectStore: ActiveObjectStore by koinCtx.inject()
        val newConnectableShapeConnectionStore: NewConnectableShapeConnectionStore by koinCtx.inject()
        val activeConnectorIds =
            listOf(
                newConnectableShapeConnectionStore.current.sourceConnectorId,
                newConnectableShapeConnectionStore.current.targetConnectorId,
            )
        val oldConnectableGeoShapes = extractConnectableGeoShapesMap(geoObjects.keys)
        val oldOverlappingShapeIds = geoObjects.keys.extractOverlappingGeoShapeIds
//        console.log("Overlapping Ids:", overlappingShapeIds.toString())

        // regenerate active object first
        val activeObj = geoObjects.entries.firstOrNull { it.key.id == activeObjectId }

        val updatedActiveObj = activeObj?.let { (obj, marker) ->
            val activeObject = activeObjectStore.current.takeIf { it != emptyGeoObjectDetails } ?: obj

            val newCenterPoint = geometryCenterOverrides[activeObjectId]
            val newGeometryShape = geometryShapeOverrides[activeObjectId]
            val desaturated = pathToolHighlightedObjectIds.isNotEmpty() && obj.id !in pathToolHighlightedObjectIds
            val highlighted = highlightedObjectIds.isNotEmpty() && obj.id in highlightedObjectIds
            if (newCenterPoint != null || newGeometryShape != null) {
                // update position
                val newObj = activeObject.copy(
                    latLon = newCenterPoint?.let { center -> LatLon(center.coordinates!!) } ?: activeObject.latLon,
                    geometry = newCenterPoint?.let { center ->
                        (newGeometryShape ?: activeObject.geometry)?.translate(center)
                    } ?: (newGeometryShape ?: activeObject.geometry),
//                        tags = obj.tags + "hidden:true",
                )
                val updatedMarker = GeojsonConverter.fromGeoObject(
                    geoObject = newObj,
                    desaturated = desaturated,
                    highlighted = highlighted,
                    connectables = oldConnectableGeoShapes.plus(setOf(newObj).extractConnectableGeoShapesMap),
                    overlappingShapeIds = oldOverlappingShapeIds.plus(setOf(newObj).extractOverlappingGeoShapeIds),
                    activeOverride = true,
                )
//                console.log("UPDATED Active Object!", newObj.title, " -> ", newObj.latLon.toString())
                newObj to (updatedMarker ?: marker)
            } else obj to marker
        }

        val newGeoObjectsMap = (updatedActiveObj?.let {
            geoObjects.filter { (obj, _) ->
                obj.id != activeObjectId
            }.plus(it)
        } ?: geoObjects)

        val connectableGeoShapes = newGeoObjectsMap.keys.extractConnectableGeoShapesMap
        val overlappingIds = newGeoObjectsMap.keys.extractOverlappingGeoShapeIds

        // iterate through all other objects including the updated active object
        return newGeoObjectsMap
            .asSequence()
            .distinctBy { it.key.id }
            .mapNotNull { (obj, feature) ->
                val active = obj.id in activeObjectIds
                val desaturated = pathToolHighlightedObjectIds.isNotEmpty() && obj.id !in pathToolHighlightedObjectIds
                val highlighted = highlightedObjectIds.isNotEmpty() && obj.id in highlightedObjectIds

                val drawConnectors = feature.geoJSONs.map { it.geoJSONType }.contains("connector") &&
                    feature.geoJSONs.any { geoJSON -> geoJSON.id in activeConnectorIds }
                val removeConnectors = feature.geoJSONs.map { it.geoJSONType }.contains("connector") &&
                    feature.geoJSONs.any { geoJSON -> geoJSON.id !in lastActiveConnectorIds }

//                val newMarker = if ((active && !feature.isActive) || drawConnectors || removeConnectors) {
                val newMarker =
                    if (
                        active
                        || drawConnectors
                        || removeConnectors
                        || (desaturated && !feature.isDesaturated)
                        || (!desaturated && feature.isDesaturated)
                        || (highlighted && !feature.isHighlighted)
                        || (!highlighted && feature.isHighlighted)
                    ) {
                        GeojsonConverter.fromGeoObject(
                            geoObject = obj,
                            connectables = connectableGeoShapes,
                            overlappingShapeIds = overlappingIds,
                            activeOverride = active,
                            desaturated = desaturated,
                            highlighted = highlighted,
                        )
                    } else {
                        feature
                    }
                lastActiveConnectorIds = activeObjectIds
                newMarker
            }
            .sortedBy { feature ->
                if (feature.id in priorityOverrides) {
                    999
                } else {
                    val priority = when {
                        feature.isActive -> 999
                        feature.objectType == ObjectType.Building -> 800
                        else -> 0
                    }
                    priority
                }
            }
            .toList()
    }

    fun extractConnectableGeoShapesMap(geoObjects: Set<GeoObjectDetails>): kotlin.collections.Map<String, ConnectableAndPosition> {
        return geoObjects.mapNotNull { geoObjectDetails ->
            geoObjectDetails.geoReferencedConnectableObject?.let { connectableShape ->
                geoObjectDetails.id to ConnectableAndPosition(connectableShape, geoObjectDetails.latLon)
            }
        }.toMap()
    }

    data class ConnectableAndPosition(
        val geoRefConnectableObject: GeoReferencedConnectableObject,
        val position: LatLon,
    )

    private val Set<GeoObjectDetails>.extractConnectableGeoShapesMap
        get() = extractConnectableGeoShapesMap(
            this,
        )


    private val Set<GeoObjectDetails>.extractOverlappingGeoShapeIds
        get() =
            this.filter { selfObj ->
                this.map { otherObj ->
                    val shapeCoordinates = selfObj.connectableGeometry()?.coordinates
                    val otherCoordinates = otherObj.connectableGeometry()?.coordinates
                    val otherExclusionZoneCoordinates =
                        otherObj.geoReferencedConnectableObject?.connectableObjectExclusionZoneGeometry(position = otherObj.latLon)?.coordinates
                    if (selfObj.id != otherObj.id && otherCoordinates != null && shapeCoordinates != null) {
                        GeoGeometry.overlap(otherCoordinates, shapeCoordinates) || otherExclusionZoneCoordinates?.let {
                            GeoGeometry.overlap(
                                it,
                                shapeCoordinates,
                            )
                        } ?: false
                    } else false
                }.reduceOrNull { acc, value -> acc || value } ?: false
            }.map { it.id }.toSet()


    private fun syncMarkers(
        source: String,
        doRateLimiting: Boolean,
        updateImageLayers: Boolean,
        updateGeoJSON: Boolean,
        activeObjectId: String,
    ) {
        if (doRateLimiting) {
            val now = Clock.System.now()
            if (now - mapLastMoved > 500.milliseconds) {
                mapLastMoved = now
            } else {
                return
            }
        }
        val localSettingsStore: LocalSettingsStore by koinCtx.inject()
        console.debug("syncMarkers triggered by", source)
        val map = map ?: return
        val renderContext = getMapRenderContext() ?: return
        val desaturateClusters = pathToolHighlightedObjectIds.isNotEmpty()
        measureTime {
            val clusteredFeatures: List<MapMarker> =
                applyOverridesAndConvert(clusteredFeaturesMap.values.toMap(), activeObjectId)

            val connectableGeoShapes =
                (clusteredFeaturesMap + unClusteredFeaturesMap).filter { (_, geoObjectPair) ->
                    geoObjectPair.first.geoReferencedConnectableObject != null
                }.toMutableMap()

            val connectableGeoShapeIds = connectableGeoShapes.keys

            val excludedIds =
                hiddenMarkerIds + connectableGeoShapeIds + pathToolHighlightedObjectIds + highlightedObjectIds

            val excludedFromClustering = excludedIds.mapNotNull { id ->
                clusteredFeatures.firstOrNull { it.id == id }
            } + applyOverridesAndConvert((unClusteredFeaturesMap + connectableGeoShapes).values.toMap(), activeObjectId)
            val clusterInput = clusteredFeatures.filter { it.id !in excludedIds }

            val (clusters, outsideClusterFeatures) = if (localSettingsStore.clusteringEnabled) {
                measureTimedValue {
                    clusterDbScan(
                        input = clusterInput,
                        minPointsPerCluster = 2,
                        neighborhoodRadius = CLUSTER_RADIUS_PIXEL * CLUSTER_RADIUS_PIXEL,
                        coordinateConverter = { feature ->
                            val point = map.project(
                                lngLat = feature.lngLat,
                            )
                            arrayOf(point.x, point.y)
                        },
                        getLngLat = { feature ->
                            feature.lngLat
                        },
                        getLabel = { feature ->
                            "${feature.id} ${feature.title}"
                        },
                        distanceFunction = { a: Array<Double>, b: Array<Double> ->
                            val (x1, y1) = a
                            val (x2, y2) = b
                            val dx = x1 - x2
                            val dy = y1 - y2

                            (dx * dx) + (dy * dy)
                        },
                    ) { clusterIndex, features ->
                        ClusterProperties(
                            objectTypes = ObjectType.entries.associateWith { type ->
                                features.count {
                                    it.objectType == type
                                }
                            },
                            colors = FormationColors.entries.associateWith { color ->
                                features.count {
                                    it.bgColor == color
                                }
                            },
                        )
                    }
                }.withDuration {
//                    console.info("clustering took", duration.toString())
                }
            } else {
                emptyList<Cluster<MapMarker, ClusterProperties>>() to clusterInput
            }

            val newMarkers = mutableMapOf<Int, Marker>()
            val newClusterMarkers = mutableMapOf<Int, Marker>()

            val maplibreGeoJSONList = mutableListOf<MaplibreGeoJSON>()
            val maplibreImageOverlayList = mutableListOf<MaplibreImageOverlayRotated>()

            clusters.forEach { cluster ->
                val key = cluster.key()
                var clusterMarker = clusterMarkers[key]

                if (clusterMarker == null) {
                    val element =
                        getDomElement(key.toString(), renderContext) {
                            svg {}.domNode.apply {
                                outerHTML = makeClusterMarkerSvg(
                                    cluster.pointCount,
                                    cluster.properties,
                                    desaturated = desaturateClusters,
                                )
                            }
                        }

                    val markerOptions = MaplibreMarkerOptions(
                        element = element,
                    )
                    clusterMarker = Marker(markerOptions.toJs())
                    clusterMarker.setLngLat(cluster.center)
                    clusterMarkers[key] = clusterMarker

                    if (!desaturateClusters) { // make clusters non-clickable when desaturated (= disabled)
                        element.onclick = { event ->
                            event.stopPropagation()
                            event.stopImmediatePropagation()
                            currentClusterStore.onClusterClick(cluster)
                        }
                    }
                }

                newClusterMarkers[key] = clusterMarker
                if (key !in clusterMarkersOnScreen) {
                    clusterMarker.addTo(map)
                }
            }

            // remove clusters that are no longer on screen
            clusterMarkersOnScreen.forEach { (key, marker) ->
                if (key !in newClusterMarkers) {
                    marker.remove()
                }
            }
            clusterMarkersOnScreen.clear()
            clusterMarkersOnScreen.putAll(newClusterMarkers)

            (excludedFromClustering + outsideClusterFeatures + myUserMarker).forEachIndexed { i, feature ->
                val key: Int = feature.hashCode()
                var marker = markers[key]

                val objectType = feature.objectType

                val hidden =
                    feature.id in hiddenMarkerIds //|| feature.id in geometryCenterOverrides || feature.id in geometryShapeOverrides
                val svgIconOptions = feature.svgIconOptions
                if (!hidden && svgIconOptions != null) {
                    if (marker == null) {
                        val markerOptions = MaplibreMarkerOptions(
                            element = getDomElement(feature.id, renderContext) {
                                makeSvgMarker(
                                    title = feature.title,
                                    svgIconOptions = svgIconOptions,
                                    active = feature.isActive,
                                    objectType = objectType,
                                    objectId = feature.id,
                                    showTitle = feature.showTitle,
                                )
                            }.also { element ->
                                if (!feature.isDesaturated) { // make non-clickable when desaturated (= disabled)
                                    if (objectType == ObjectType.HistoryEntry) {
                                        element.addEventListener("click", historyMarkerEventListener)
                                    } else if (objectType == ObjectType.ObjectMarker && feature.id in pathToolHighlightedObjectIds) {
                                        element.addEventListener("click", pathToolTrackedObjectMarkerEventListener)
                                    } else if (objectType == ObjectType.UserMarker || objectType == ObjectType.CurrentUserMarker) {
                                        element.addEventListener("click", userMarkerEventListener)
                                    } else {
                                        element.addEventListener("click", markerEventListener)
                                    }
                                }
                            },
                        )
                        marker = Marker(markerOptions.toJs())
                        marker.setLngLat(feature.lngLat)
                        markers[key] = marker
                    }

                    newMarkers[key] = marker
                    if (key !in markersOnScreen) {
                        marker.addTo(map)
                    }

//                    val redrawOnTopOverride = redrawOnTopOverride == props.id
                    if (feature.isActive || feature.id in priorityOverrides || !feature.isDesaturated || feature.isHighlighted) {
                        marker.remove()
                        marker.addTo(map)
                    }
                }

                if (updateGeoJSON) {
                    maplibreGeoJSONList += feature.geoJSONs
                }
                if (updateImageLayers) {
                    maplibreImageOverlayList += feature.imageOverlays
                }
            }


            // remove markers that are no longer on screen
            markersOnScreen.forEach { (key, marker) ->
                if (key !in newMarkers) {
                    marker.remove()
                }
            }
            markersOnScreen.clear()
            markersOnScreen.putAll(newMarkers)

            if (updateGeoJSON) {
                geoJSONReplaceData(
                    maplibreGeoJSONList.distinctBy { it.id }.map { it.feature },
                    ADDITIONAL_GEOMETRY,
                )
            }
            if (updateImageLayers) {
                val newObjectLayers = maplibreImageOverlayList
                    .groupBy { it.type }
                    .mapNotNull { (type, list) ->
                        if (lastMaplibreElements[type]?.toSet() != list.toSet()) {
                            lastMaplibreElements[type] = list
                            ObjectLayer(
                                id = type,
                                objects = list,
                            )
                        } else null
                    }
                updateMap(newObjectLayers = newObjectLayers)
            }
        }.also { duration ->
//            console.log("updatedMarkers in", duration.toString(), "event: ", source)
        }
    }

    private val lastMaplibreElements: MutableMap<ObjectType, List<MaplibreElement>> = mutableMapOf()

    private fun insertTopRightSideControls() {

        val topRightSideControl = Div(
            tagName = "div",
            id = "topRightSideControls",
            baseClass = "maplibregl-ctrl",
            job = Job(parent = null),
            scope = Scope(),
        ).apply { topRightSideControls() }.domNode

        fun addTopRightSideControl(): dynamic = topRightSideControl
        fun removeTopRightSideControl() = obj { }
        val newTopRightControlElement: dynamic = obj { }
        newTopRightControlElement.onAdd = ::addTopRightSideControl
        newTopRightControlElement.onRemove = ::removeTopRightSideControl
        map?.addControl(newTopRightControlElement.unsafeCast<IControl>(), "top-right")
    }

    private fun insertBottomRightSideControls() {
        val bottomRightSideControl = Div(
            tagName = "div",
            id = "bottomRightSideControls",
            baseClass = "maplibregl-ctrl",
            job = Job(parent = null),
            scope = Scope(),
        ).apply { bottomRightSideControls() }.domNode

        fun addBottomRightSideControl(): dynamic = bottomRightSideControl
        fun removeBottomRightSideControl() = obj { }
        val newRightControlElement: dynamic = obj { }
        newRightControlElement.onAdd = ::addBottomRightSideControl
        newRightControlElement.onRemove = ::removeBottomRightSideControl
        map?.addControl(newRightControlElement, "bottom-right")
    }

    private fun insertBottomLeftSideControls() {

        val bottomLeftSideControl = Div(
            tagName = "div",
            id = "bottomLeftSideControls",
            baseClass = "maplibregl-ctrl",
            job = Job(parent = null),
            scope = Scope(),
        ).apply { bottomLeftSideControls() }.domNode

        fun addBottomLeftSideControl(): dynamic = bottomLeftSideControl
        fun removeBottomLeftSideControl() = obj { }
        val newLeftControlElement: dynamic = obj { }
        newLeftControlElement.onAdd = ::addBottomLeftSideControl
        newLeftControlElement.onRemove = ::removeBottomLeftSideControl
        map?.addControl(newLeftControlElement, "bottom-left")
    }

    /**
     * Adds a specified listener to all StaticObjectLayers of the MaplibreMap that calls the specified function (fn)
     * @param type listener type (e.g.: "click", "dblclick", "move")
     * @param fn function to call
     */
    fun addObjectLayerListeners(type: String, fn: () -> Unit) {
        maplibreDynamicObjectLayers.layers?.forEach { dynamicObjectLayer ->
            if (dynamicObjectLayer.id != ObjectType.TransientMarker
                && dynamicObjectLayer.id != ObjectType.Floor
                && dynamicObjectLayer.id != ObjectType.Unit
            ) {
                val fnId = "${dynamicObjectLayer.id.name}-$type-listener"
                on(type = type, fn = fn, layerId = dynamicObjectLayer.id.name, fnId = fnId)
                activeListeners[fnId] = fn
            }
        }
    }

    fun changeMapStyle(url: String) {
        disableMeasuringTool()
        map?.setStyle(url)
        map?.once("idle") {
            map?.addGeojson()
            map?.once("idle") {
                updateTileLayerStates(tileLayers ?: emptyMap())
                redrawSourceLayers()
                syncMarkersNow(trigger = "changeMapStyle")
            }
        }
    }


    fun changeMapStyleDynamic(newStyle: dynamic) {
        disableMeasuringTool()
        map?.setStyle(newStyle)
        map?.once("idle") {
            map?.addGeojson()
            map?.once("idle") {
                updateTileLayerStates(tileLayers ?: emptyMap())
                redrawSourceLayers()
                syncMarkersNow(trigger = "changeMapStyle")
            }
        }
    }

//    private fun getMapObjectAndLayer(objId: String, objType: ObjectType): Triple<MaplibreElement, dynamic, MaplibreDynamicObjectLayer>? {
//        val objLayer = maplibreDynamicObjectLayers.layers?.find { it.id == objType }
//        return objLayer?.objectPairs?.filter { it.key == objId }?.values?.firstOrNull()?.let { (obj, dynamicObj) ->
//            Triple(obj, dynamicObj, objLayer)
//        }
//    }

//    private fun getMapObject(objId: String, objType: ObjectType): Pair<MaplibreElement, dynamic>? {
//        return getMapObjectAndLayer(objId, objType)?.let { (obj, dynamicObj, _) ->
//            Pair(obj, dynamicObj)
//        }
//    }

//    fun disableUserMarker(userId: String) {
//        disabledUsers += userId
//        getMapObject(userId, ObjectType.CurrentUserMarker)?.let { (obj, dynamicObj) ->
//            dynamicObj.remove()
//            console.log("disable", obj.type.name, (obj as? MaplibreMarker)?.title)
//        }
//    }

//    fun enableUserMarker(userId: String) {
//        if(userId in disabledUsers) {
//            disabledUsers.remove(userId)
//            getMapObject(userId, ObjectType.CurrentUserMarker)?.let { (obj, dynamicObj) ->
//                dynamicObj.addTo(map)
//                console.log("enable user", (obj as? MaplibreMarker)?.title)
//            }
//        }
//    }

    private val markerEventListener = EventListener { event ->
        val objectAndUserHandler: ObjectAndUserHandler by koinCtx.inject()
        val newConnectableShapeConnectionStore: NewConnectableShapeConnectionStore by koinCtx.inject()
        event.stopImmediatePropagation()
        event.stopPropagation()
        newConnectableShapeConnectionStore.reset()
        objectAndUserHandler.fetchAndShowObject(objectId = getObjectOrUserIdFromEvent(event))
    }

    private val userMarkerEventListener = EventListener { event ->
        val objectAndUserHandler: ObjectAndUserHandler by koinCtx.inject()
        val newConnectableShapeConnectionStore: NewConnectableShapeConnectionStore by koinCtx.inject()
        event.stopImmediatePropagation()
        event.stopPropagation()
        newConnectableShapeConnectionStore.reset()
        objectAndUserHandler.fetchAndShowUser(userId = getObjectOrUserIdFromEvent(event))
    }

    private val pathToolTrackedObjectMarkerEventListener = EventListener { event ->
        val pathActivehighlightedObjectMarkersStore: PathActiveHighlightedObjectMarkersStore by koinCtx.inject()
        event.stopImmediatePropagation()
        event.stopPropagation()
        getObjectOrUserIdFromEvent(event)?.let {
            pathActivehighlightedObjectMarkersStore.selectTrackedObject(it)
        }
    }

    private val historyMarkerEventListener = EventListener { event ->
        val objectHistoryResultsCache: ObjectHistoryResultsCache by koinCtx.inject()
        event.stopImmediatePropagation()
        event.stopPropagation()
        objectHistoryResultsCache.selectHistoryEntry(objectHistoryEntryId = getObjectOrUserIdFromEvent(event))
    }

    var mouseOverConnector: String? = null
    var mouseOverConnection: String? = null

    private fun highlightConnector(id: String?) {
        mouseOverConnector = id
        syncMarkersNow()
    }

    private fun highlightConnection(uniqueConnectionId: String?) {
        mouseOverConnection = uniqueConnectionId
        syncMarkersNow()
    }

    private fun selectConnector(id: String?) {
        val newConnectableShapeConnectionStore: NewConnectableShapeConnectionStore by koinCtx.inject()
        val objectAndUserHandler: ObjectAndUserHandler by koinCtx.inject()

        id?.let { connectorId ->
            newConnectableShapeConnectionStore.current.let { connection ->
                when {
                    connection.sourceConnectorId.isEmpty() -> {
                        objectAndUserHandler.fetchAndShowObject(connectorId.split(":")[0])
                        newConnectableShapeConnectionStore.updateSourceConnectorById(connectorId)
                    }

                    connection.sourceConnectorId == connectorId -> {
                        newConnectableShapeConnectionStore.reset()
                    }

                    connection.sourceConnectorId.isNotEmpty() && connection.targetConnectorId.isEmpty() -> {
                        newConnectableShapeConnectionStore.handleConnectorById(connectorId)
                    }

                    connection.sourceConnectorId.isNotEmpty() && connection.targetConnectorId == connectorId -> {
                        newConnectableShapeConnectionStore.resetTarget()
                    }

                    connection.sourceConnectorId.isNotEmpty() && connection.targetConnectorId.isNotEmpty() -> {
                        newConnectableShapeConnectionStore.handleConnectorById(connectorId)
                    }

                    else -> {
                        newConnectableShapeConnectionStore.reset()
                    }
                }
            }
        }
    }

    private fun addConnectorClickListeners() {

        val mapSearchResultsStore: MapSearchResultsStore by koinCtx.inject()

        map?.on("mouseenter", "$ADDITIONAL_GEOMETRY-connectors") { connector ->
            val connectorId = connector.features[0].properties.id as? String
            val isAlreadyConnected = mapSearchResultsStore.current.values.mapNotNull { geoObj ->
                geoObj.hit.geoReferencedConnectableObject?.connections?.map { i -> "${i.sourceMarkerId}:${i.sourceConnectorId}" }
            }.flatten().contains(connectorId)
            if (!isAlreadyConnected) {
                highlightConnector(connectorId)
                map?.getCanvas().style.cursor = "pointer"
            }
        }
        map?.on("mouseleave", "$ADDITIONAL_GEOMETRY-connectors") {
            highlightConnector(null)
            map?.getCanvas().style.cursor = ""
        }
        map?.on("click", "$ADDITIONAL_GEOMETRY-connectors") { connector ->
            val connectorId = connector.features[0].properties.id as? String
            val isAlreadyConnected = mapSearchResultsStore.current.values.mapNotNull { geoObj ->
                geoObj.hit.geoReferencedConnectableObject?.connections?.map { i -> "${i.sourceMarkerId}:${i.sourceConnectorId}" }
            }.flatten().contains(connectorId)
            if (!isAlreadyConnected) {
//                console.log("CONNECTOR CLICK EVENT", connector)
//                console.log("CONNECTOR EVENT", connector.features[0].properties)
//                console.log("CONNECTOR EVENT AS EVENT", connector as? Event)
//            connector.stopPropagation()
//            console.log("Layer clicked")
//            (connector as? Event)?.preventDefault()
//            (connector as? Event)?.stopPropagation()
                selectConnector(connectorId)
            }
        }
    }

    private fun selectConnection(id: String?) {
        val deleteConnectionConnectorIdStore: DeleteConnectionConnectorIdStore by koinCtx.inject()

        id?.let { uniqueConnectionId ->
            deleteConnectionConnectorIdStore.selectConnectionByUniqueId(uniqueConnectionId)
        }
    }

    private fun addConnectionClickListeners() {

        map?.on("mouseenter", "$ADDITIONAL_GEOMETRY-connections") { connection ->
            highlightConnection(connection.features[0].properties.id as? String)
            map?.getCanvas().style.cursor = "pointer"
        }
        map?.on("mouseleave", "$ADDITIONAL_GEOMETRY-connections") {
            highlightConnection(null)
            map?.getCanvas().style.cursor = ""
        }
        map?.on("click", "$ADDITIONAL_GEOMETRY-connections") { connection ->
//            console.log("CONNECTION CLICK EVENT", connection)
//            console.log("CONNECTION EVENT", connection.features[0].properties)
//            console.log("CONNECTION EVENT AS EVENT", connection as? Event)
            (connection as? Event)?.preventDefault()
            (connection as? Event)?.stopImmediatePropagation()
            (connection as? Event)?.stopPropagation()
            selectConnection(connection.features[0].properties.id as? String)
        }
    }

    private fun getObjectOrUserIdFromEvent(event: Event): String? {
        return (event.currentTarget as? HTMLDivElement)?.id
    }

    fun updateMap(newObjectLayers: List<ObjectLayer>) {
        newObjectLayers.forEach { newObjectLayer ->
            maplibreDynamicObjectLayers.layers?.map { dynamicObjectLayer ->
                if (dynamicObjectLayer.id == newObjectLayer.id) {
                    updateDynamicObjectLayer(dynamicObjectLayer, newObjectLayer.objects)
                }
            }
        }
    }

    private fun updateDynamicObjectLayer(
        maplibreDynamicObjectLayer: MaplibreDynamicObjectLayer,
        newObjectsList: List<MaplibreElement>?,
    ) {
        if (newObjectsList != null) {
            val markers = mutableListOf<MaplibreMarker>()
            val circles = mutableListOf<MaplibreCircle>()
            val imageOverlays = mutableListOf<MaplibreImageOverlayRotated>()
            val geoJSONs = mutableListOf<MaplibreGeoJSON>()
            val newIds = mutableListOf<String>()

            with(maplibreDynamicObjectLayer) {
                newObjectsList.forEach { obj ->
                    when (obj) {
                        is MaplibreMarker -> {
                            console.warn("list should not contain MaplibreMarkers")
                            if (isEnabled(obj.id, obj.type)) {
                                markers.add(obj)
                                newIds.add(obj.id)
                            }
                        }

                        is MaplibreCircle -> {
                            if (isEnabled(obj.id, obj.type)) {
                                circles.add(obj)
                                newIds.add(obj.id)
                            }
                        }

                        is MaplibreImageOverlayRotated -> {
                            if (isEnabled(obj.id, obj.type)) {
                                imageOverlays.add(obj)
                                newIds.add(obj.id)
                            }
                        }

                        is MaplibreGeoJSON -> {
                            if (isEnabled(obj.id, obj.type)) {
//                                console.log("adding", obj)
                                geoJSONs.add(obj)
                                newIds.add(obj.id)
                            }
                        }
                    }
                }
                // REMOVE OLD OBJECTS
                cleanUpMapObjects(this, newIds)

                // UPDATE EXISTING OR ADD NEW OBJECTS
                // MaplibreMarker
                if (markers.isNotEmpty()) {
                    console.warn("list should not contain MaplibreMarkers", markers.toTypedArray())
//                    updateMarkers(this, markers)
//                    addMarkers(this, markers)
                }
                // MaplibreCircle
//                if(circles.isNotEmpty()) {
//                    updateCircles(this, circles)
//                    addCircles(this, circles)
//                }
                // MaplibreImageOverlayRotated
                if (imageOverlays.isNotEmpty()) {
                    updateImageOverlayRotated(this, imageOverlays)
                    addImageOverlayRotated(this, imageOverlays)
                    updateTileLayerStates(tileLayers ?: emptyMap())
                }
                // MaplibreGeoJSON
                if (geoJSONs.isNotEmpty()) {
//                    updateGeoJSON(this, geoJSONs)
                    addGeoJSON(this, geoJSONs)
                }
                // Continue to update or add other object classes here...

            }
        } else {
            // NO OBJECTS? -> CLEAR ALL

            // INFO OUTPUT
            if (renderInfoOutput) {
                with(maplibreDynamicObjectLayer.objectPairs.size) {
                    if (this > 0) console.info("remove $this ${maplibreDynamicObjectLayer.id}(s)")
                }
            }

            when (maplibreDynamicObjectLayer.id) {
                // clear all image overlays and geojson overlays
                ObjectType.Floor, ObjectType.Unit, ObjectType.GeoFence -> {
                    maplibreDynamicObjectLayer.objectPairs.forEach { objectPair ->
//                        console.log("REMOVED IMAGE OR GEOJSON LAYER", objectPair.key)
                        removeImageOrGeoJSONLayers(objectPair.key)
                        maplibreDynamicObjectLayer.objectPairs.remove(objectPair.key)
                    }
                }

                ObjectType.Zone -> {
                    maplibreDynamicObjectLayer.objectPairs.forEach { objectPair ->
                        if (objectPair.value.first is MaplibreGeoJSON) {
                            geoJSONRemove(
                                ids = arrayOf(objectPair.key),
                                sourceId = ADDITIONAL_GEOMETRY,
                            )
                        } else objectPair.value.second.remove()
                        maplibreDynamicObjectLayer.objectPairs.remove(objectPair.key)
                    }
                }
                /*ObjectType.Zone,*/ ObjectType.CurrentUserMarker, ObjectType.HistoryEntry -> {
                maplibreDynamicObjectLayer.objectPairs.forEach { objectPair ->
                    if (objectPair.value.first is MaplibreGeoJSON) {
                        removeImageOrGeoJSONLayers(objectPair.key)
                    } else objectPair.value.second.remove()
                    maplibreDynamicObjectLayer.objectPairs.remove(objectPair.key)
                }
            }
                // clear all markers
                else -> {
                    maplibreDynamicObjectLayer.objectPairs.forEach { objectPair ->
                        objectPair.value.second.remove()
                        maplibreDynamicObjectLayer.objectPairs.remove(objectPair.key)
                    }
                }
            }
        }
    }

    private fun removeImageOrGeoJSONLayers(
        objectId: String,
    ) {
        listOf(
            objectId,
            "${objectId}-fill",
            "${objectId}-outline",
            "${objectId}-line",
        ).forEach { id ->
            if (map?.getLayer(id) != null) map?.removeLayer(id)
        }
        if (map?.getSource(objectId) != null) map?.removeSource(objectId)
    }

    /**
     * Takes a list of objects and a specified DynamicObjectsLayer and removes all map objects that are on the layer
     * but not in the objectsList
     * @param maplibreDynamicObjectLayer layer to remove objects from
     * @param objectIdsList list of objects to remove, if they are not in the layer
     */
    private fun cleanUpMapObjects(maplibreDynamicObjectLayer: MaplibreDynamicObjectLayer, objectIdsList: List<String>) {
        val processList = maplibreDynamicObjectLayer.objectPairs.filterKeys { objectId ->
            objectId !in objectIdsList
        }
        removeMapObjects(maplibreDynamicObjectLayer, processList)
    }

    private fun removeMapObjects(
        maplibreDynamicObjectLayer: MaplibreDynamicObjectLayer,
        objectsToRemove: kotlin.collections.Map<String, Pair<Any, dynamic>>,
    ) {
        // remove objects
        objectsToRemove.forEach { objectPair ->
            if (objectPair.key !in disabledObjects && objectPair.key !in disabledUsers) {
                when (maplibreDynamicObjectLayer.id) {
                    ObjectType.Floor, ObjectType.Unit, ObjectType.GeoFence -> {
                        removeImageOrGeoJSONLayers(objectPair.key)
                    }

                    ObjectType.Zone -> {
                        if (objectPair.value.first is MaplibreGeoJSON) {
                            geoJSONRemove(ids = arrayOf(objectPair.key), sourceId = ADDITIONAL_GEOMETRY)
                        }
                    }

                    ObjectType.CurrentUserMarker, ObjectType.HistoryEntry -> {
                        if (objectPair.value.first is MaplibreGeoJSON) {
                            removeImageOrGeoJSONLayers(objectPair.key)
                        } else objectPair.value.second.remove()
                    }

                    else -> {
                        objectPair.value.second.remove()
                    }
                }
                maplibreDynamicObjectLayer.objectPairs.remove(objectPair.key)
            }
        }

        // INFO OUTPUT
        if (renderInfoOutput) {
            with(objectsToRemove.size) {
                if (this > 0) console.info("removed $this ${maplibreDynamicObjectLayer.id}(s)")
            }
        }
    }

    private fun addMarkerEventListener(marker: MaplibreMarker) {
        if (marker.type == ObjectType.HistoryEntry) {
            marker.markerOptions.element?.addEventListener("click", historyMarkerEventListener)
        } else {
            marker.markerOptions.element?.addEventListener("click", markerEventListener)
        }
    }

//    /**
//     * Adds Points (Points of Interest) as dynamic objects to the specified DynamicObjectsLayer (if not yet existent).
//     * @param maplibreDynamicObjectLayer layer to add objects to
//     * @param markerList list of LeafletMarkers (that will be added, if they are not yet in the layer)
//     */
//    private fun addMarkers(
//        maplibreDynamicObjectLayer: MaplibreDynamicObjectLayer,
//        markerList: List<MaplibreMarker>,
//    ) {
//
//        val processList = markerList.filter { marker -> marker.id !in maplibreDynamicObjectLayer.objectPairs.keys }
//
//        processList.forEach { marker ->
//            // add Eventlistener to the HTMLElement of the marker
//            addMarkerEventListener(marker)
//            // create new marker
//            val newMarker = Marker(marker.markerOptions.toJs())
//            // add new marker to map
//            newMarker.setLngLat(marker.lnglat)
//            if(map != null) newMarker.addTo(map)
//            maplibreDynamicObjectLayer.objectPairs[marker.id] = Pair(marker, newMarker)
//        }
//
//        // INFO OUTPUT
//        if(renderInfoOutput) {
//            with(processList.size) {
//                if (this > 0) console.info("added $this ${maplibreDynamicObjectLayer.id}(s)")
//            }
//        }
//    }

//    /**
//     * Updates Points (Points of Interest) on the specified DynamicObjectsLayer (if existent).
//     * @param maplibreDynamicObjectLayer layer to add objects to
//     * @param markerList list of LeafletMarkers (that will be updated, if they are existing in the layer)
//     */
//    private fun updateMarkers(maplibreDynamicObjectLayer: MaplibreDynamicObjectLayer, markerList: List<MaplibreMarker>){
//
//        val processList = markerList.filter { marker -> marker.id in maplibreDynamicObjectLayer.objectPairs.keys }
//        var updateCounter = 0
//
//        processList.forEach { marker ->
//            val iconRefresh = with(maplibreDynamicObjectLayer.objectPairs[marker.id]?.first) {
//                this is MaplibreMarker
//                        && (title != marker.title
//                        || color != marker.color
//                        || icon != marker.icon
//                        || shape != marker.shape
//                        || hasNotification != marker.hasNotification
//                        || archived != marker.archived
//                        || flagged != marker.flagged
//                        || isSharing != marker.isSharing
//                        || stateColor != marker.stateColor
//                        || stateIcon != marker.stateIcon
//                        || isActive != marker.isActive
//                        || showTitle != marker.showTitle
//                        )
//            }
//            val positionRefresh = with(maplibreDynamicObjectLayer.objectPairs[marker.id]?.first) {
//                this is MaplibreMarker && lnglat != marker.lnglat
//            }
//            // INFO OUTPUT
//            if(renderInfoOutput){ if(iconRefresh) console.log("update icon from ${marker.title}", iconRefresh) }
//            with(maplibreDynamicObjectLayer.objectPairs[marker.id]?.second) {
//                when {
//                    this != null && positionRefresh -> {
//                        // update existing marker
//                        setLngLat(marker.lnglat)
//                        console.log("Updated position of \"${marker.title}\"")
//                        updateCounter += 1
//                        maplibreDynamicObjectLayer.objectPairs[marker.id] = Pair(marker, this)
//                    }
//                    this != null && marker.markerOptions.element != null && iconRefresh -> {
//                        remove()
//                        addMarkerEventListener(marker)
//                        val newMarker: dynamic = Marker(marker.markerOptions.toJs())
//                        newMarker.setLngLat(marker.lnglat.toArray())
//                        if(map != null) newMarker.addTo(map)
//                        console.log("Added new marker for \"${marker.title}\" due to icon-update")
//                        updateCounter += 1
//                        maplibreDynamicObjectLayer.objectPairs[marker.id] = Pair(marker, newMarker)
//                    }
//                }
//            }
//        }
//
//        // INFO OUTPUT
//        if(renderInfoOutput){
//            with(processList.size) {
//                if(this > 0) console.info("checked $this ${maplibreDynamicObjectLayer.id}(s)${if(updateCounter > 0) ", $updateCounter needed an update" else ""}")
//            }
//        }
//
//    }

    //TODO add circle in maplibre:

    /**
     * Adds Circles (radius in meters) as dynamic objects to the specified DynamicObjectsLayer (if not yet existent).
     * @param maplibreDynamicObjectLayer layer to add objects to
     * @param circleList list of LeafletCircles (that will be added if they are not yet in the layer)
     */
//    private fun addCircles(maplibreDynamicObjectLayer: MaplibreDynamicObjectLayer, circleList: List<MaplibreCircle>) {
//        with(maplibreDynamicObjectLayer) {
//            // INFO OUTPUT
//            if(renderInfoOutput){
//                with(circleList.filter { circle -> circle.id !in objectPairs.keys }.size) {
//                    if (this > 0) console.info("add $this ${maplibreDynamicObjectLayer.id}(s)")
//                }
//            }
//            circleList.filter { circle -> circle.id !in objectPairs.keys }.forEach { circle ->
//                // create new circle
//                val newCircle = leaflet.circle(circle.latLng, circle.options.toJs())
//                // add new circle
//                maplibreDynamicObjectLayer.dynamicObjectsList.add(newCircle)
//                objectPairs[circle.id] = Pair(circle, newCircle)
//            }
//        }
//    }

    /**
     * Updates Circles (radius in meters) on the specified DynamicObjectsLayer (if existent).
     * @param dynamicObjectLayer layer to update objects on
     * @param circleList list of LeafletCircles (that will be updated if they are existing in the layer)
     */
//    private fun updateCircles(maplibreDynamicObjectLayer: MaplibreDynamicObjectLayer, circleList: List<MaplibreCircle>){
//        with(maplibreDynamicObjectLayer) {
//            // INFO OUTPUT
//            if(renderInfoOutput){
//                with(circleList.filter { circle -> circle.id in objectPairs.keys }.size){
//                    if(this > 0) console.info("update $this ${maplibreDynamicObjectLayer.id}(s)")
//                }
//            }
//            circleList.filter { circle -> circle.id in objectPairs.keys }.forEach { circle ->
//                // update existing circle
//                with(objectPairs[circle.id]?.second) {
//                    setLatLng(circle.latLng)
//                    setRadius(circle.options.radiusM)
//                    setStyle(circle.options.pathOptions.toJs())
//                    objectPairs[circle.id] = Pair(circle, this)
//                }
//            }
//        }
//    }

    /**
     * Adds a rotated image overlay.
     * @param maplibreDynamicObjectLayer layer to add objects to
     * @param overlayList list of LeafletImageOverlayRotated (that will be added, if they are not yet in the layer)
     */
    private fun addImageOverlayRotated(
        maplibreDynamicObjectLayer: MaplibreDynamicObjectLayer,
        overlayList: List<MaplibreImageOverlayRotated>,
    ) {
        val processList = overlayList.filter { overlay -> overlay.id !in maplibreDynamicObjectLayer.objectPairs.keys }

        map?.addGeojson()

        processList.forEach { overlay ->
            // create imageSource definition for new overlay
            val srcDef = jsApply<ImageSourceOptions> {
                type = "image"
                url = overlay.imageUrl
                coordinates = arrayOf(
                    overlay.topLeft,
                    overlay.topRight,
                    overlay.bottomRight,
                    overlay.bottomLeft,
                ).map { arrayOf(it.lon, it.lat) }.toTypedArray()
            }

            val imageOverlayLayer = jsApply<MapLayer> {
                id = overlay.id
                source = srcDef //embed source directly instead of refer source id
                type = "raster"
                paint = json(
                    "raster-opacity" to 1.0,
                    "raster-fade-duration" to 0,
                )
            }

            try {
                map?.addLayer(imageOverlayLayer, "$ADDITIONAL_GEOMETRY-fill")
            } catch (e: Exception) {
                console.log("Could not add ImageOverlay before $ADDITIONAL_GEOMETRY-fill", e.message)
                map?.addLayer(imageOverlayLayer)
            }

            maplibreDynamicObjectLayer.objectPairs[overlay.id] = Pair(overlay, srcDef)
        }

        // INFO OUTPUT
        if (renderInfoOutput) {
            with(processList.size) {
                if (this > 0) console.info("added $this ${maplibreDynamicObjectLayer.id}(s)")
            }
        }

    }

    /**
     * Updates a rotated image overlay.
     * @param maplibreDynamicObjectLayer layer to update objects on
     * @param overlayList list of LeafletImageOverlayRotated (that will be updated, if they are existing in the layer)
     */
    private fun updateImageOverlayRotated(
        maplibreDynamicObjectLayer: MaplibreDynamicObjectLayer,
        overlayList: List<MaplibreImageOverlayRotated>,
    ) {

        val processList = overlayList.filter { overlay -> overlay.id in maplibreDynamicObjectLayer.objectPairs.keys }
        var updateCounter = 0

        processList.forEach { overlay ->
            // create new and updated source for overlay
            val oldSrc = map?.getSource(overlay.id) as? ImageSource
            if (oldSrc != null) {
                val oldSrcDef = maplibreDynamicObjectLayer.objectPairs[overlay.id]?.second
                val newSrcDef = jsApply<UpdateImageSourceOptions> {
                    url = overlay.imageUrl
                    coordinates = arrayOf(
                        overlay.topLeft,
                        overlay.topRight,
                        overlay.bottomRight,
                        overlay.bottomLeft,
                    ).map { arrayOf(it.lon, it.lat) }.toTypedArray()
                }
                if (newSrcDef != oldSrcDef) {
                    // update existing overlay source
                    oldSrc.updateImage(newSrcDef)
                    updateCounter += 1
                    maplibreDynamicObjectLayer.objectPairs[overlay.id] = Pair(overlay, newSrcDef)
                } else {
//                    if(newSrcDef == null) {
//                        // remove image overlay when new source is null
//                        map?.removeLayer(overlay.id)
//                        map?.removeSource(overlay.id)
//                        maplibreDynamicObjectLayer.objectPairs.remove(overlay.id)
//                    }
                }
            } else {
                maplibreDynamicObjectLayer.objectPairs.remove(overlay.id)
                addImageOverlayRotated(maplibreDynamicObjectLayer, listOf(overlay))
            }
        }

        // INFO OUTPUT
        if (renderInfoOutput) {
            with(processList.size) {
                if (this > 0) console.info("checked $this ${maplibreDynamicObjectLayer.id}(s)${if (updateCounter > 0) ", $updateCounter needed an update" else ""}")
            }
        }
    }

    /**
     * Adds geoJSON objects as dynamic objects to the specified DynamicObjectsLayer (if not yet existent).
     * @param maplibreDynamicObjectLayer layer to add objects to
     * @param geoJSONList list of LeafletGeoJSONs (that will be added, if they are not yet in the layer)
     */
    private fun addGeoJSON(maplibreDynamicObjectLayer: MaplibreDynamicObjectLayer, geoJSONList: List<MaplibreGeoJSON>) {
        if (geoJSONList.isNotEmpty()) {
            geoJsonUpdateData(
                diff = jsApply<GeoJSONSourceDiff> {
                    add = geoJSONList.map { geoJSON ->
                        geoJSON.feature
                    }.toTypedArray()
                },
//                diff = json(
//                    "add" to geoJSONList.map { geoJSON ->
//                        geoJSON.feature
//                    }.toTypedArray()
//                ),
                sourceId = ADDITIONAL_GEOMETRY,
            )

            geoJSONList.forEach { geoJSON ->
                maplibreDynamicObjectLayer.objectPairs[geoJSON.id] = Pair(geoJSON, null)
            }
        }

        // INFO OUTPUT
        if (renderInfoOutput) {
            with(geoJSONList.size) {
                if (this > 0) console.info("added $this ${maplibreDynamicObjectLayer.id}(s)")
            }
        }
    }

    private fun removeTileSource(tileSourceId: String) {
        if (map?.getSource(tileSourceId) != null) {
            map?.removeSource(tileSourceId)
//            console.log("Removed maplibre tile source", tileSourceId)
        }
    }

    private fun addOrUpdateTileSource(tileSource: TileSourceData, token: String? = null) {
        val apiUserStore by koinCtx.inject<ApiUserStore>()
        (token ?: apiUserStore.current.apiUser?.apiAccessToken?.token)?.let { accessToken ->
            removeTileSource(tileSource.id)

            val tiles = arrayOf(
                URLBuilder(tileSource.url).apply {
                    apiUserStore.current.apiUser?.groups?.firstOrNull()?.groupId?.let { groupId ->
                        parameters.append("groupId", groupId)
                    }
                    parameters.append("token", accessToken)
                }.buildString(),
            )
            val source = map?.getSource(tileSource.id) as? VectorTileSource
            if (source != null) {
                source.setTiles(tiles)
            } else {
                val tileSourceData = json(
                    "type" to tileSource.type.id,
                    "tiles" to tiles,
                )
                map?.addSource(tileSource.id, tileSourceData)
            }

//            console.log("Added maplibre", tileSource.type.id, "source", tileSource.id, tileSource.url)
        } ?: kotlin.run {
            // no access token -> remove tile layers that are using this source layer
            val filtered = tileLayers?.mapValues { (layer, active) ->
                if (layer.source.id == tileSource.id) {
                    removeTileLayer(layer.styleLayerSpecification["id"] as String)
                    false
                } else active
            }?.toMap() ?: emptyMap()
            updateTileLayerStates(filtered)
            // remove the source layer
            removeTileSource(tileSource.id)
        }
    }

    fun updateTileLayersSourceToken(token: String? = null) {
        tileLayers?.forEach { (layer, active) ->
            // refresh token from source of the layer
            addOrUpdateTileSource(tileSource = layer.source, token = token)
            if (active) {
                // refresh the Layer
                addOrUpdateTileLayer(tileLayer = layer)
            }
        }
    }

    private fun removeTileLayer(tileLayerId: String): Boolean {
        if (map?.getLayer(tileLayerId) != null) {
            map?.removeLayer(tileLayerId)
//            console.log("Removed maplibre tile layer", tileLayerId)
        }
        val layer = map?.getLayer(tileLayerId)
        return layer == null || layer == undefined
    }

    private fun addOrUpdateTileLayer(tileLayer: TileLayerData): Boolean {
        removeTileLayer(tileLayer.styleLayerSpecification["id"] as String)
        if (map?.getSource(tileLayer.source.id) != null) {
            if (map?.getLayer(tileLayer.id) == null) {
                map?.addLayer(tileLayer.styleLayerSpecification)
            }
        } else {
            addOrUpdateTileSource(tileLayer.source)
            map?.onceOnSourcedata(tileLayer.source.id) {
                if (map?.getLayer(tileLayer.id) == null) {
                    map?.addLayer(tileLayer.styleLayerSpecification)
                }
            }
        }
//        console.log("Added maplibre tile layer", tileLayer.source.id, tileLayer.id)
        return true // map?.getLayer(tileLayer.styleLayer.id)?.id == tileLayer.styleLayer.id
    }

    fun updateTileLayerStates(tileLayerMap: kotlin.collections.Map<TileLayerData, Boolean>): kotlin.collections.Map<TileLayerData, Boolean> {
        tileLayers = tileLayerMap
        return tileLayerMap.mapValues { (layer, enabled) ->
            if (enabled) {
                addOrUpdateTileLayer(layer)
            } else {
                removeTileLayer(layer.styleLayerSpecification["id"] as String)
            }
        }.toMap()
    }

    private fun redrawSourceLayers() {
        if (renderInfoOutput) {
            console.info("Redraw source layers...")
        }
        maplibreDynamicObjectLayers.layers?.filter {
            it.id == ObjectType.Floor
                || it.id == ObjectType.Unit
                || it.id == ObjectType.GeoFence
                || it.id == ObjectType.Zone
                || it.id == ObjectType.GeneralMarker
        }?.forEach { dynLayer ->
            if (renderInfoOutput) {
                console.info("redraw ${dynLayer.objectPairs.size} ${dynLayer.id.name}(s)")
            }
            val geoJSONs = dynLayer.objectPairs.filterValues { it.first is MaplibreGeoJSON }
            removeMapObjects(dynLayer, geoJSONs)
            addGeoJSON(dynLayer, geoJSONs.map { it.value.first as MaplibreGeoJSON })

            val images = dynLayer.objectPairs.filterValues { it.first is MaplibreImageOverlayRotated }
            updateImageOverlayRotated(dynLayer, images.map { it.value.first as MaplibreImageOverlayRotated })
        }
        updateTileLayerStates(tileLayers ?: emptyMap())
    }

    fun updateRedrawOnTopOverride(objId: String) {
        if (objId !in priorityOverrides && objId !in activeObjectIds) {
            priorityOverrides += objId
            syncMarkersNow(false, trigger = "updateRedrawOnTopOverride")
        }
    }

    fun removeRedrawOnTopOverride(objId: String) {
        if (objId in priorityOverrides) {
            priorityOverrides -= objId
            syncMarkersNow(false, trigger = "removeRedrawOnTopOverride")
        }
    }

    fun addActiveObjectOverride(obj: GeoObjectDetails? = null, objId: String? = null) {
        if ((obj?.id ?: objId).isNullOrEmpty()) {
            return
        } else {
            (obj?.id ?: objId)?.let { id ->
//                console.log("addActiveObjectOverride", obj?.id ?: objId, obj?.title)
//                hiddenMarkerIds -= id
                activeObjectIds += id
            }
        }

        syncMarkersNow(trigger = "addActiveObjectOverride")
    }

    fun removeAllActiveObjectOverrides(sync: Boolean = true) {
//        console.log("removeAllActiveObjectOverrides")
        activeObjectIds.clear()
        if (sync) syncMarkersNow(trigger = "removeAllActiveObjectOverrides")
    }

    fun removeActiveObjectOverride(id: String, sync: Boolean = true) {
//        console.log("removeActiveObjectOverride", id)
        activeObjectIds -= id
        if (sync) syncMarkersNow(trigger = "removeActiveObjectOverride")
    }

    fun addGeometryCenterOverride(obj: GeoObjectDetails, newCenterPoint: Geometry.Point, sync: Boolean = true) {
        geometryCenterOverrides[obj.id] = newCenterPoint
//        hiddenMarkerIds += obj.id
        if (sync) syncMarkersNow(trigger = "addGeometryCenterOverride")
    }

    fun removeGeometryCenterOverride(obj: GeoObjectDetails, sync: Boolean = true) {
        geometryCenterOverrides -= obj.id
//        hiddenMarkerIds -= obj.id
        if (sync) syncMarkersNow(updateGeoJSON = true, trigger = "removeGeometryCenterOverride")
    }

    fun addGeometryShapeOverride(obj: GeoObjectDetails, newGeometry: Geometry, sync: Boolean = true) {
        geometryShapeOverrides[obj.id] = newGeometry
        if (sync) syncMarkersNow(trigger = "addGeometryShapeOverride")
    }

    fun removeGeometryShapeOverride(obj: GeoObjectDetails, sync: Boolean = true) {
        geometryShapeOverrides -= obj.id
        if (sync) syncMarkersNow(updateGeoJSON = true, trigger = "removeGeometryShapeOverride")
    }

    fun addHiddenOverride(objId: String, sync: Boolean = true) {
        hiddenMarkerIds.add(objId)
        if (sync) syncMarkersNow(trigger = "addHiddenOverride")
    }

    fun removeHiddenOverride(objId: String, sync: Boolean = true) {
        hiddenMarkerIds.remove(objId)
        if (sync) syncMarkersNow(trigger = "removeHiddenOverride")
    }

    fun setPathToolHighlightOverrides(objIds: Set<String>, sync: Boolean = true) {
//        console.log("setPathToolHighlightOverrides", objIds.toTypedArray())
        pathToolHighlightedObjectIds.clear()
        pathToolHighlightedObjectIds.addAll(objIds)
        if (sync) syncMarkersNow(trigger = "setPathToolHighlightOverrides")
    }

    fun addPathToolHighlightOverrides(objIds: Set<String>, sync: Boolean = true) {
//        console.log("addPathToolHighlightOverrides", objIds.toTypedArray())
        pathToolHighlightedObjectIds.addAll(objIds)
        if (sync) syncMarkersNow(trigger = "addPathToolHighlightOverrides")
    }

    fun setHighlightOverrides(objIds: Set<String>, sync: Boolean = true) {
//        console.log("setHighlightOverrides", objIds.toTypedArray())
        highlightedObjectIds.clear()
        highlightedObjectIds.addAll(objIds)
        if (sync) syncMarkersNow(trigger = "setHighlightOverrides")
    }

    fun addHighlightOverrides(objIds: Set<String>, sync: Boolean = true) {
//        console.log("addHighlightOverrides", objIds.toTypedArray())
        highlightedObjectIds.addAll(objIds)
        if (sync) syncMarkersNow(trigger = "addHighlightOverrides")
    }

    /** Returns the geographical center of the map view */
    fun getCenter(): LngLat = map?.getCenter() ?: LngLat(0.0, 0.0)

    /** Returns the current zoom level of the map view */
    fun getZoom(): Double = map?.getZoom() ?: -1.0

    /**
     * Increases the zoom of the map by 1.
     * @param options
     */
    fun zoomIn(options: AnimationOptions? = null) {
        map?.zoomIn(options)
    }

    /**
     * Decreases the zoom of the map by 1.
     * @param options
     */
    fun zoomOut(options: AnimationOptions? = null) {
        map?.zoomOut(options)
    }

    /**
     * Zooms the map to the specified zoom level, with an animated transition.
     * @param options
     */
    fun zoomTo(zoom: Double, options: AnimationOptions? = null) {
        map?.zoomTo(zoom, options)
    }

    /**
     * Returns the geographical bounds visible in the current map view
     */

    fun getCardinalDirectionBounds(): LngLatBounds? {
        val bounds = map?.getBounds()
        return bounds
    }

    /**
     * Checks if the map container size changed and updates the map if so — call it after you've
     * changed the map size dynamically, also animating pan by default.
     */
    fun invalidateSize() {
        map?.resize()
    }

//    fun on(obj: dynamic = map, eventMap: dynamic){
//        obj?.on(eventMap)
//    }

    fun on(type: String, fn: () -> Unit, layerId: String? = null, fnId: String) {
        activeListeners[fnId] = fn
        if (layerId != null) {
            map?.on(type, layerId, fn)
        } else {
            map?.on(type, fn)
        }
    }

    fun on(type: String, fn: dynamic, layerId: String? = null, fnId: String) {
        activeListeners[fnId] = fn
        if (layerId != null) {
            map?.on(type, layerId, fn)
        } else {
            map?.on(type, fn)
        }
    }

    fun off(type: String, fn: dynamic = null, fnId: String? = null) {
        if (fn != null) {
            map?.off(type, fn)
        } else {
            if (fnId != null) {
                val listener = activeListeners[fnId]
                if (listener != null) {
                    activeListeners.remove(fnId)
                    map?.off(type, listener)
                }
            }
        }
    }

//    fun off(obj: dynamic = mapDynamic, eventMap: dynamic){
//        obj?.off(eventMap)
//    }

    fun once(type: String, fn: dynamic, fnId: String) {
        if (activeListeners[fnId] == null) {
            activeListeners[fnId] = fn
            map?.once(type, fn)
        }
    }

//    fun once(eventMap: dynamic){
//        map?.once(eventMap)
//    }

    fun isNotMoving(): Boolean {
        return if (map != null) {
            !(map?.isMoving() ?: false)
        } else true
    }

    fun isStyleLoaded(): Boolean {
        return if (map != null) {
            map?.isStyleLoaded() ?: false
        } else false
    }

    fun jumpTo(center: LatLon, zoom: Double, bearing: Double? = null, pitch: Double? = null) {
        map?.jumpTo(
            obj {
                this.center = doubleArrayOf(center.lon, center.lat).toTypedArray()
                this.zoom = zoom
                if (bearing != null) this.bearing = bearing
                if (pitch != null) this.pitch = pitch
            },
        )
    }

    fun panTo(center: LatLon, duration: Long? = null) {
        map?.panTo(
            lnglat = center.toLngLat(),
            options = jsApply {
                if (duration != null) this.duration = duration
            },
        )
    }

    /**
     * Changes any combination of center, zoom, bearing, and pitch,
     * animating the transition along a curve that evokes flight.
     * The animation seamlessly incorporates zooming and panning to help the user
     * maintain her bearings even after traversing a great distance.
     * @param center The desired center
     * @param zoom The desired zoom level
     * @param curve The zooming "curve" that will occur along the flight path.
     * A high value maximizes zooming for an exaggerated animation, while a low value minimizes zooming
     * for an effect closer to Map#easeTo. 1.42 is the average value selected by participants in a user study.
     * @param maxZoom The zero-based zoom level at the peak of the flight path. If options.curve is specified,
     * this option is ignored
     * @param speed The average speed of the animation defined in relation to options.curve.
     * A speed of 1.2 means that the map appears to move along the flight path by 1.2 times options.curve
     * screenfuls every second. A screenful is the map's visible span. It does not correspond to a fixed
     * physical distance, but varies by zoom level.
     * @param screenSpeed The average speed of the animation measured in screenfuls per second,
     * assuming a linear timing curve. If options.speed is specified, this option is ignored
     * @param maxDuration The animation's maximum duration, measured in milliseconds.
     * If duration exceeds maximum duration, it resets to 0.
     */

    fun flyTo(
        center: LatLon, zoom: Double, curve: Double = 1.42, maxZoom: Double? = null,
        speed: Double = 2.0, screenSpeed: Double? = null, maxDuration: Double? = null,
    ) {
        map?.flyTo(
            obj {
                this.center = doubleArrayOf(center.lon, center.lat).toTypedArray()
                this.zoom = zoom
                this.curve = curve
                if (maxZoom != null) this.maxZoom = maxZoom
                this.speed = speed
                if (screenSpeed != null) this.screenSpeed = screenSpeed
                if (maxDuration != null) this.maxDuration = maxDuration
            },
        )
    }

    /**
     * Changes any combination of center, zoom, bearing, pitch, and padding
     * with an animated transition between old and new values.
     * The map will retain its current values for any details not specified in options.
     * @param center The desired center
     * @param zoom The desired zoom level
     * - CameraOptions:
     * @param bearing The desired bearing in degrees. The bearing is the compass direction that is "up".
     * For example, bearing: 90 orients the map so that east is up
     * @param pitch The desired pitch in degrees. The pitch is the angle towards the horizon measured in degrees with
     * a range between 0 and 60 degrees. For example, pitch: 0 provides the appearance of looking straight down
     * at the map, while pitch: 60 tilts the user's perspective towards the horizon. Increasing the pitch value is
     * often used to display 3D active.objects.
     * @param around  If "zoom" is specified, around determines the point around which the zoom is centered
     * @param padding Dimensions in pixels applied on each side of the viewport for shifting the vanishing point
     * (PaddingOptions)
     * - AnimationOptions:
     * @param duration The animation's duration, measured in milliseconds.
     * @param animate If false, no animation will occur.
     */

    fun easeTo(
        center: LatLon, zoom: Double, bearing: Double? = null, pitch: Double? = null,
        around: LatLon? = null, padding: dynamic = null, duration: Double? = null, animate: Boolean? = null,
    ) {
        map?.easeTo(
            obj {
                this.center = doubleArrayOf(center.lon, center.lat).toTypedArray()
                this.zoom = zoom
                if (bearing != null) this.bearing = bearing
                if (pitch != null) this.pitch = pitch
                if (around != null) this.around = doubleArrayOf(around.lon, around.lat).toTypedArray()
                if (padding != null) this.padding = padding
                if (duration != null) this.duration = duration
                if (animate != null) this.animate = animate
            },
        )
    }

    fun setMyUserMarker(newUserMarker: MapMarkerMyUser) {
        myUserMarker.clear()
        myUserMarker.add(
            newUserMarker,
        )
        syncMarkersNow(updateGeoJSON = true, trigger = "setMyUserMarker")
    }
}
