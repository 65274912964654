package notifications

import dev.fritz2.components.flexBox
import dev.fritz2.components.stackUp
import dev.fritz2.core.RenderContext
import koin.koinCtx
import kotlinx.coroutines.flow.map
import localization.TL
import localization.Translation
import mainmenu.RouterStore
import search.global.searchInput
import styling.primaryButtonStyleParams
import theme.FormationDefault
import utils.focusInputObserver
import webcomponents.baseLayout
import webcomponents.contentScrollBox
import webcomponents.mainTitle
import webcomponents.oneButtonFooter

fun RenderContext.pageNotificationCenter() {

    val notificationSearchInputFieldStore: NotificationSearchInputFieldStore by koinCtx.inject()
    val globalNotificationResultsStore: GlobalNotificationResultsStore by koinCtx.inject()
    val notificationFilterStore: NotificationFilterStore by koinCtx.inject()
    val translation: Translation by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()

    baseLayout(
        header = {
            stackUp({
                width { full }
            }) {
                spacing { tiny }
                items {
                    // PAGE TITLE
                    flexBox({
                        width { full }
                        margins {
                            bottom { small }
                        }
//                        display( sm = { none }, md = { flex })
                        alignItems { center }
                        justifyContent { center }
                    }) { mainTitle(translation[TL.MainMenu.NOTIFICATION_CENTER]) }

                    // SEARCH INPUT FIELD
                    div(
                        "w-full flex items-center px-3 mb-1 gap-3"
                    ) {
                        inlineStyle("height: ${FormationDefault.formationStyles.inputHeight};")
                        searchInput("inputNotificationSearch", notificationSearchInputFieldStore)
                        focusInputObserver(
                            { js("document.getElementById('inputNotificationSearch').focus()") },
                            domNode
                        )
                    }

                    // filter buttons
//                    notificationFilterBar()

                    // Mark as Read button
                    globalNotificationResultsStore.data.map { it.unreadNumber }.render { unreadNumber ->
                        if (unreadNumber > 0) markAllAsReadButton()
                    }

                }
            }
        },
        content = {
            contentScrollBox {
                notificationResultsList(results = globalNotificationResultsStore.data)
            }
        },
        footer = {
            oneButtonFooter(
                title = translation[TL.General.BACK],
                styleParams = primaryButtonStyleParams,
                value = Unit,
                clickHandlers = listOf(
                    notificationFilterStore.reset,
                    notificationSearchInputFieldStore.reset,
                    routerStore.back
                ),
            )
        }
    )
}
