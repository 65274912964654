package dev.fritz2.components

import dev.fritz2.components.compat.Input
import dev.fritz2.components.compat.input
import dev.fritz2.components.foundations.Component
import dev.fritz2.components.foundations.DynamicComponentProperty
import dev.fritz2.components.foundations.ElementMixin
import dev.fritz2.components.foundations.ElementProperties
import dev.fritz2.components.foundations.EventMixin
import dev.fritz2.components.foundations.EventProperties
import dev.fritz2.components.foundations.InputFieldMixin
import dev.fritz2.components.foundations.InputFieldProperties
import dev.fritz2.components.foundations.InputFormMixin
import dev.fritz2.components.foundations.InputFormProperties
import dev.fritz2.core.HtmlTag
import dev.fritz2.core.RenderContext
import dev.fritz2.core.Store
import dev.fritz2.core.disabled
import dev.fritz2.core.readOnly
import dev.fritz2.core.step
import dev.fritz2.core.type
import dev.fritz2.core.value
import dev.fritz2.core.values
import dev.fritz2.styling.StyleClass
import dev.fritz2.styling.params.BasicParams
import dev.fritz2.styling.params.BoxParams
import dev.fritz2.styling.staticStyle
import dev.fritz2.styling.theme.Theme
import kotlinx.coroutines.flow.flowOf
import org.w3c.dom.HTMLInputElement


/**
 * This component generates a text based input field.
 *
 * You can optionally pass in a store in order to set the value and react to updates _automatically_.
 *
 * There are options to choose from predefined sizes and some variants from the Theme.
 *
 * To enable or disable it or to make it readOnly just use the well known attributes of the HTML
 * [input element](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/Input). To manually set the value or
 * react to a change refer also to its event's. All that can be achieved via the [ElementMixin.element] property!
 *
 * Basic usage
 * ```
 * val text = storeOf("")
 * inputField(value = text) {
 * }
 * ```
 *
 * @see InputFieldComponent
 *
 * @param styling a lambda expression for declaring the styling as fritz2's styling DSL
 * @param value optional [Store] that holds the data of the input
 * @param baseClass optional CSS class that should be applied to the element
 * @param id the ID of the element
 * @param prefix the prefix for the generated CSS class resulting in the form ``$prefix-$hash``
 * @param build a lambda expression for setting up the component itself. Details in [InputFieldComponent]
 */
fun RenderContext.inputField(
    styling: BasicParams.() -> Unit = {},
    value: Store<String>? = null,
    baseClass: StyleClass = StyleClass.None,
    id: String? = value?.id,
    prefix: String = "inputField",
    build: InputFieldComponent.() -> Unit = {}
): Input = InputFieldComponent(value).apply(build).render(this, styling, baseClass, id, prefix)

/**
 * This class deals with the configuration and rendering of an input element.
 *
 * The inputField can be configured for the following aspects:
 *  - the size of the element
 *  - some predefined styling variants
 *  - the element options of the HTML input element can be set.
 *    [Attributes](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Attributes)
 *
 * You can optionally pass in a store in order to set the value and react to updates _automatically_.
 *
 * Example usages:
 * ```
 * inputField(value = dataStore /* inject a store so all user inputs are automatically reflected! */) {
 * }
 *
 * // all state management can also be done manually if needed:
 * val someStore = storeOf("")
 * inputField {
 *     placeholder("Enter text")
 *     value(someStore.data) // connect a flow to the component for setting its value
 *     events {
 *         changes.values() handledBy someStore.update // connect an handler for emitting the user input made
 *     }
 *     element {
 *         // exposes the underlying HTML input element for direct access. Use with caution!
 *     }
 * }
 *
 * // apply predefined size and variant
 * inputField(value = dataStore) {
 *      size { small } // render a smaller input
 *      variant { filled } // fill the background with ``light`` color
 * }
 *
 * // Of course you can apply custom styling as well
 * inputField({ // just use the ``styling`` parameter!
 *      background {
 *          color { dark }
 *      }
 *      radius { "1rem" }
 * },
 * value = dataStore) {
 *      size { small } // render a smaller input
 * }
 * ```
 */
open class InputFieldComponent(protected val valueStore: Store<String>?) :
    Component<HtmlTag<HTMLInputElement>>,
    EventProperties<HTMLInputElement> by EventMixin(),
    ElementProperties<HtmlTag<HTMLInputElement>> by ElementMixin(),
    InputFormProperties by InputFormMixin(),
    InputFieldProperties by InputFieldMixin() {

    companion object {
        val staticCss = staticStyle(
            "inputBox",
            """
                display: inline-flex;
                position: relative;
                vertical-align: middle;
                height: 2.5rem;
                appearance: none;
                align-items : center;
                justify-content: center;
                transition: all 250ms;
                white-space: nowrap;
                outline: none;
                width: 100%;
                -webkit-appearance: none;
            """
        )
    }

    val value = DynamicComponentProperty<String>()
    val type = DynamicComponentProperty(flowOf("text"))
    val step = DynamicComponentProperty<String>()

    override fun render(
        context: RenderContext,
        styling: BoxParams.() -> Unit,
        baseClass: StyleClass,
        id: String?,
        prefix: String
    ): HtmlTag<HTMLInputElement> {
        return with(context) {
            input({
                this@InputFieldComponent.size.value.invoke(Theme().input.sizes)()
                this@InputFieldComponent.variant.value.invoke(Theme().input.variants)()
            }, styling, baseClass + staticCss, id, prefix) {
                disabled(this@InputFieldComponent.disabled.values)
                readOnly(this@InputFieldComponent.readonly.values)
                value(this@InputFieldComponent.value.values)
                type(this@InputFieldComponent.type.values)
                step(this@InputFieldComponent.step.values)
                this@InputFieldComponent.valueStore?.let {
                    value(it.data)
                    changes.values() handledBy it.update
                }
                this@InputFieldComponent.events.value.invoke(this)
                this@InputFieldComponent.element.value.invoke(this)
            }
        }
    }
}
