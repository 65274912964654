package data.keywordlayer

import apiclient.groups.FilterCategory
import apiclient.groups.LayerMetaData
import apiclient.groups.LayerType
import apiclient.geoobjects.MarkerColor
import apiclient.geoobjects.MarkerIcon
import apiclient.geoobjects.MarkerShape
import dev.fritz2.core.RootStore
import kotlinx.coroutines.Job

class ActiveKeywordLayerMetaDataStore : RootStore<LayerMetaData>(
    initialData = emptyLayerMetaData,
    job = Job(),
) {

    var initialState = emptyLayerMetaData

    companion object {
        val emptyLayerMetaData = LayerMetaData(
            layerType = LayerType.Keyword,
            id = "",
            title = "",
            translatable = true,
            defaultIconCategory = MarkerIcon.Tag,
            defaultColor = MarkerColor.Grey,
            defaultShape = MarkerShape.Trapezoid,
            filterCategory = FilterCategory.Things,
            defaultOn = true
        )
    }

    val reset = handle {
        initialState = emptyLayerMetaData
        emptyLayerMetaData
    }

    fun hasChanged(): Boolean {
        return current != initialState
    }
}
