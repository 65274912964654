package workspacetools.buildingeditor

import com.tryformation.localization.Translatable

enum class BuildingEditorTranslatables : Translatable {
    AddABuilding,
    AddAFloor,
    /*
    This creates a new floor and adds it to the building.
     */
    AddAFloorExplanation,
    BuildingName,
    Buildings,
    DeleteBuilding,
    DeleteFloor,
    FetchBuildings,
    FirstFloor,
    FloorLevel,
    FloorName,
    FloorPlanImage,
    Move,
    MyBuilding,
    Opacity,
    PickFloorPlanImage,
    /*
                            The building and floor will be added right where the map is centered.
                        You can move, scale, and rotate it after it is created.

     */
    PickFloorPlanImageExplanation,
    ReplaceImage,
    Rotate,
    Scale,
    SelectAFloor,
    ToggleSideBar,
    UseAsDefaultFloor,
    ;

    override val prefix = "building-editor"
}
