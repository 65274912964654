package data.objects

import analyticsdashboard.AnalyticSideSubPage
import analyticsdashboard.AnalyticsSideSubPageStore
import apiclient.customfields.FieldValue
import dev.fritz2.core.RootStore
import koin.koinCtx
import kotlinx.coroutines.Job
import mainmenu.RouterStore

class CurrentActiveFieldValueStore : RootStore<FieldValue?>(
    initialData = null,
    job = Job(),
) {
    val routerStore: RouterStore by koinCtx.inject()
    private val analyticsSideSubPageStore: AnalyticsSideSubPageStore by koinCtx.inject()

    val addOrChangeFieldValue = handle<FieldValue?> { _, fieldValue ->
        routerStore.addOrReplaceRoute(mapOf("change" to "fieldValue"))
        fieldValue
    }

    val addOrChangeFieldValueWithoutRouting = handle<FieldValue?> { _, fieldValue ->
        fieldValue
    }

    val addOrChangeHistoryPathFieldValue = handle<FieldValue?> { _, fieldValue ->
        analyticsSideSubPageStore.update(AnalyticSideSubPage.PathsSearchFieldValue)
        fieldValue
    }
}
