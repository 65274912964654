package data.objects.building

import dev.fritz2.core.RootStore
import koin.koinCtx
import kotlinx.coroutines.Job
import maplibreGL.MaplibreMap

class ActiveFloorLevelStore : RootStore<Double?>(
    initialData = null,
    job = Job(),
)

class ActiveFloorsStore : RootStore<List<String>?>(
    initialData = null,
    job = Job(),
) {
    val maplibreMap: MaplibreMap by koinCtx.inject()

    init {
        data handledBy { floors ->
            maplibreMap.map?.let {
                maplibreMap.routingPathLayerIds.forEach { layerId ->
                    maplibreMap.map?.getLayer(layerId)?.let { routingPathLayer ->
                        maplibreMap.map?.setFilter(
                            layerId,
                            arrayOf(
                                "case",
                                // if floorId is null, keep sefault filter
                                arrayOf("==", arrayOf("get", "floorId"), null),
                                routingPathLayer.metadata.defaultFilter,
                                // if floorId is not null, add filter by floorId
                                arrayOf("!=", arrayOf("get", "floorId"), null),
                                arrayOf(
                                    "all",
                                    routingPathLayer.metadata.defaultFilter,
                                    arrayOf("in", arrayOf("get", "floorId"), arrayOf("literal", floors?.toTypedArray())),
                                ),
                                // fallback to default filter
                                routingPathLayer.metadata.defaultFilter,
                            ),
                        )
                    }
                }
            }
        }
    }
}

class ActiveBuildingStore : RootStore<String?>(
    initialData = null,
    job = Job(),
)

data class BuildingOverride(
    val buildingId: String,
    val floorId: String,
)

class ActiveBuildingOverrideStore : RootStore<BuildingOverride?>(
    initialData = null,
    job = Job(),
) {
    val reset = handle {
        null
    }
}
