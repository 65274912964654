package search.global

import analytics.AnalyticsCategory
import analytics.AnalyticsService
import analytics.AnalyticsTags
import apiclient.FormationClient
import apiclient.geoobjects.ObjectTags
import apiclient.geoobjects.ObjectType
import apiclient.geoobjects.ObjectVisibility
import apiclient.geoobjects.restSearch
import apiclient.search.ObjectSearchResult
import apiclient.search.ObjectSearchResults
import apiclient.tags.floorId
import apiclient.tags.init
import apiclient.tags.tag
import auth.ApiUserStore
import data.objects.building.CurrentBuildingsStore
import data.objects.building.getActiveFloorIds
import dev.fritz2.core.RootStore
import dev.fritz2.core.invoke
import dev.fritz2.routing.MapRouter
import koin.koinCtx
import kotlin.coroutines.EmptyCoroutineContext
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.conflate
import kotlinx.coroutines.flow.debounce
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.launch
import mainmenu.Pages
import map.MapLayersStore
import map.MapStateStore
import model.LayerType
import model.SearchContexts
import model.globalSearch
import overlays.BusyStore
import search.SearchContextsStore
import search.SearchResultsCoordinator
import search.SearchType

class GlobalSearchResultsStore : RootStore<List<ObjectSearchResult>?>(
    initialData = null,
    job = Job(),
) {

    private val router: MapRouter by koinCtx.inject()
    private val mapLayersStore: MapLayersStore by koinCtx.inject()
    val apiUserStore: ApiUserStore by koinCtx.inject()
    val formationClient: FormationClient by koinCtx.inject()
    private val searchContextsStore: SearchContextsStore by koinCtx.inject()
    private val mapStateStore: MapStateStore by koinCtx.inject()
    private val currentBuildingsStore: CurrentBuildingsStore by koinCtx.inject()
    private val analyticsService by koinCtx.inject<AnalyticsService>()
    private val searchResultsCoordinator: SearchResultsCoordinator by koinCtx.inject()
    private val busyStore: BusyStore by koinCtx.inject()

    val reset = handle {
        console.log("Reset GlobalSearchResultsStore")
        null
    }

    private val globalSearch = handle { current ->
        val currentCentroid = mapStateStore.current?.center
        // trigger search (but only when search page is up)
        if (router.current["page"] == Pages.Search.name) {
            console.log("-> GLOBAL-SEARCH")
            CoroutineScope(EmptyCoroutineContext).launch {
//                val activeClients = current.activeClientIds.mapNotNull { current.clients[it] }
                val context = searchContextsStore.current
                if (currentCentroid != null) {
                    busyStore.handleApiCall(
                        supplier = suspend {
                            formationClient.restSearch(
                                context.globalSearch.copy(
                                    // construct searchCtx
                                    objectTypes = context.globalSearch.objectTypes
                                        ?.filter { it.includeInSearch }
                                        ?.takeUnless { it.isEmpty() }
                                        ?: ObjectType.entries.filter { it.includeInSearch },
                                    centroid = currentCentroid,
                                    excludeTags = context.globalSearch.excludeTags.init() + listOfNotNull(
                                        ObjectTags.Visibility.tag(ObjectVisibility.Hidden),
                                        ObjectTags.Flagged.tag(true),
                                        ObjectTags.IsArchetype.tag(true),
                                    ),
                                ).also {
                                    console.log("searching objTypes", context.globalSearch.objectTypes?.toTypedArray())
                                    console.log("searching", it)
                                },
                            )
                        },
                        processResult = { results ->
                            val filteredForStore = results.hits.filter {
                                when (it.hit.objectType) {
                                    // filter out ObjectTypes we don't want to see in search
                                    ObjectType.Floor,
                                    ObjectType.Unit,
                                    ObjectType.Notification,
                                    ObjectType.HistoryEntry -> false

                                    else -> true
                                }
                            }
                            searchResultsCoordinator.renderSearchResults(Pair(SearchType.Global, filteredForStore.map { it.hit }))
                            update(filteredForStore) // store is used for displaying results in the searchResultsList
                            analyticsService.withAnalytics(AnalyticsCategory.DoSearch) {
                                val q = context.globalSearch.text.takeIf { !it.isNullOrEmpty() } ?: "-"
                                val tags = context.globalSearch.tags?.map { AnalyticsTags.Kw.tag(it) }
                                search(target = q, value = filteredForStore.size.toDouble(), tags = tags ?: listOf())
                            }
                        },
                        processError = { throwable ->
                            console.log("GlobalSearch failed.", throwable)
                        },
                    )
                }
            }
        }
        current
    }

    private val searchPageListener = handle<Map<String, String>> { current, route ->
        if (route["page"] == Pages.Search.name && route.keys.size <= 2) globalSearch()
        current
    }

    private val changeFloorResults =
        handle<Pair<List<String>?, List<ObjectSearchResult>?>> { current, (activeFloorIds, globalResults) ->
            if (mapLayersStore.isActive(LayerType.GlobalSearchResults) && activeFloorIds != null && !globalResults.isNullOrEmpty()) {
                val floorResults = globalResults.filter { it.hit.tags.floorId in activeFloorIds }
                mapLayersStore.setResults(
                    LayerType.FloorResults,
                    ObjectSearchResults(from = 0, pageSize = 0, total = floorResults.size, hits = floorResults),
                )
            }
            current
        }

    init {
        router.data handledBy searchPageListener
        searchContextsStore.map(SearchContexts.globalSearch()).data.map { }.debounce(400)
            .conflate() handledBy globalSearch
        currentBuildingsStore.data.map { buildings -> buildings.values.toList().getActiveFloorIds }.combine(data) { activeFloorIds, globalResults ->
            Pair(activeFloorIds, globalResults)
        } handledBy changeFloorResults
    }
}
