package analyticsdashboard

import dev.fritz2.components.flexBox
import dev.fritz2.components.icon
import dev.fritz2.core.RenderContext
import dev.fritz2.core.RootStore
import dev.fritz2.core.autofocus
import dev.fritz2.core.placeholder
import dev.fritz2.core.values
import koin.koinCtx
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.flowOf
import webcomponents.genericInput

class AnalyticsTextFilterStore : RootStore<String>(
    initialData = "",
    job = Job(),
)

fun RenderContext.textFilter() {
    val analyticsTextFilterStore: AnalyticsTextFilterStore by koinCtx.inject()

    flexBox({
        direction { row }
        width { maxContent }
        alignItems { center }
        justifyContent { start }
        margins {
            left { smaller }
        }
    }) {
        genericInput(
            value = analyticsTextFilterStore.data,
            width = { "250px" },
            rightContentBox = {
                icon({
                    size { large }
                    margins { horizontal { small } }
                }) { fromTheme { filterAlt } }
            }
        ) {
            placeholder(flowOf("Filter by text")) // TODO translate
            autofocus(true)
            attr("tabindex", "0")
            inputs.values() handledBy analyticsTextFilterStore.update
        }
    }
}
