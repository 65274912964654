package map

import auth.CurrentWorkspaceStore
import dev.fritz2.core.RenderContext
import koin.koinCtx
import kotlinx.browser.document
import kotlinx.coroutines.flow.combine
import mainmenu.AppStateStore
import mainmenu.cookiePopup
import map.views.crossHair
import map.views.mapProcessSpinnerOverlay
import map.views.pointerRoute
import map.views.searchManuallyButton
import map.views.workplacetools.ArchetypesStore
import maplibreGL.MaplibreMap
import model.AppPhase
import objectrouting.navigationComponent
import org.w3c.dom.MutationObserver
import org.w3c.dom.MutationObserverInit
import wizard.onbaording.actionOButtonOverlayContainer

fun RenderContext.maplibreMapComponent() {

    val maplibreMap: MaplibreMap = koinCtx.get()
    val mapStateStore: MapStateStore = koinCtx.get()
    val appStateStore: AppStateStore = koinCtx.get()
    val currentWorkspaceStore: CurrentWorkspaceStore = koinCtx.get()
    val archetypesStore: ArchetypesStore = koinCtx.get()

    div(
        baseClass = "flex w-full grow",
        id = maplibreMap.targetId,
    ) {
        inlineStyle(
            """
            .maplibregl-ctrl .mapboxgl-ctrl {
                background-color: white;
                color: black;
                border-color: black;
                border-width: 1px;
                radius: 100%;
            }
        """.trimIndent(),
        )

        combine(appStateStore.data, currentWorkspaceStore.data) { a, w -> Pair(a, w) }.render { (appState, workspace) ->
            if (workspace != null && (appState.appPhase == AppPhase.LoggedIn || appState.appPhase == AppPhase.LoggedInAnonymous)) {
                mapProcessSpinnerOverlay()
                archetypesStore.data.render {
                    if (it.isNotEmpty()) {
                        crossHair()
                    }
                }
                actionOButtonOverlayContainer()
                div("absolute top-0 left-0 right-0 flex flex-col w-full justify-start") {
//                    overlayNavigator()
                    navigationComponent()
                }
                // POINTER OVERLAY -> absolute positioned in the center of this box
                pointerRoute()
                searchManuallyButton()

                cookiePopup()

                // handle resize events of map container and adjust map canvas size accordingly
                val observer = MutationObserver { _, mutationObserver ->
                    if (document.contains(domNode)) {
                        mapStateStore.insertMap(Unit)
                        maplibreMap.setRenderContext(this)
                        mutationObserver.disconnect()
                    }
                }
                observer.observe(
                    document,
                    MutationObserverInit(attributes = true, childList = true, characterData = false, subtree = true),
                )
            } else {
                maplibreMap.removeMap()
            }
        }
    }
}
