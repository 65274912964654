// GENERATED by fritz2 - NEVER CHANGE CONTENT MANUALLY!
package model

import dev.fritz2.core.Lens
import dev.fritz2.core.lensOf

fun Credentials.Companion.email(): Lens<Credentials, String> = lensOf(
    "email",
    { it.email },
    { p, v -> p.copy(email = v) }
)

fun Credentials.Companion.password(): Lens<Credentials, String> = lensOf(
    "password",
    { it.password },
    { p, v -> p.copy(password = v) }
)

fun Credentials.Companion.rememberMe(): Lens<Credentials, Boolean> = lensOf(
    "rememberMe",
    { it.rememberMe },
    { p, v -> p.copy(rememberMe = v) }
)
