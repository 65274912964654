package data.objects.views.attachments

import apiclient.geoobjects.Content
import dev.fritz2.core.RootStore
import koin.koinCtx
import kotlinx.coroutines.Job

class AttachmentsStore : RootStore<Map<String, Content>>(
    initialData = emptyMap(),
    job = Job(),
) {

    val preAttachmentsStore: PreAttachmentsStore by koinCtx.inject()
    private val removedAttachmentsStore: RemovedAttachmentsStore by koinCtx.inject()

    val edit = handle<String> { current, attachmentId ->
        if (preAttachmentsStore.current[attachmentId] != null) {
            preAttachmentsStore.edit(attachmentId)
        } else {
            current[attachmentId]?.toPreAttachment()?.let { convertedAttachment ->
                remove(attachmentId)
//            removedOrEditedAttachmentsStore.add(attachmentId)
                console.log("removed attachemnt from AttachmentsStore")
                preAttachmentsStore.addOrUpdate(convertedAttachment)
                console.log("updated PreAttachmentsStore with", convertedAttachment)
                preAttachmentsStore.edit(attachmentId)
            }
        }
        current
    }

    val remove = handle<String> { current, attachmentId ->
        if (preAttachmentsStore.current[attachmentId] != null) {
            preAttachmentsStore.remove(attachmentId)
            current
        } else {
            val currentAttachments = current.toMutableMap()
            currentAttachments.remove(attachmentId)
            current[attachmentId]?.let { removedAttachmentsStore.add(it) }
            currentAttachments.toMap()
        }
    }

    val reset = handle {
        emptyMap()
    }

}
