package dev.fritz2.components.foundations

import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flowOf

/**
 * Generic container for modeling a property for a component class that could be either consist on a nullable value
 * or on a [Flow] of a nullable value. This specific implementation could be useful for properties where the distinction
 * between some states and the "not yet set" state is important.
 *
 * Use it like this:
 * ```
 * open class SomeComponent<T> {
 *      val selectedItem = NullableDynamicComponentProperty<T>(emptyFlow())
 * }
 * // within your UI declaration and static values:
 * val selectedStore = storeOf<String>(null)
 * someComponent<String> {
 *      selectedItem(selectedStore.data) // no selection at start up!
 * }
 * ```
 */
class NullableDynamicComponentProperty<T>(var values: Flow<T?>) {
    operator fun invoke(newValue: T?) {
        values = flowOf(newValue)
    }

    operator fun invoke(newValues: Flow<T?>) {
        values = newValues
    }
}
