package styling

import dev.fritz2.styling.params.BasicParams
import dev.fritz2.styling.params.Style
import kotlinx.coroutines.ExperimentalCoroutinesApi
import theme.FormationDefault.Companion.formationStyles

/**
 * ADMIN VIEWS
 */

val viewRoleBoxParams: Style<BasicParams> = {
    width { "60%" }
    height { "40px" }
    background { color { "#E3E3E3" } }
    border {
        width { "2px" }
        color { "#cccccc" }
    }
    paddings {
        left { small }
        top { smaller }
    }
}

val updateGroupEditButtonParams: Style<BasicParams> = {
    height { full }
    color { primary.main }
    padding { smaller }
    hover {
        background { color { "#949494" } }
        color { "black" }
    }
    radius { "14px" }
}

val roleRemoveButtonParams: Style<BasicParams> = {
    height { "40px" }
    paddings {
        left { small }
        right { small }

    }
    background { color { primary.main } }
    color { secondary.main }
}

val passwordInputStyleParams: Style<BasicParams> = {
    background { color { "white" } }
    border {
        width { "2px" }
        color { "black" }
    }
}

val modalStyleParams: Style<BasicParams> = {
    background { color { "white" } }
    border {
        width { "2px" }
        color { "black" }
    }
    overflow { auto }
}

val textInputStyleParams: Style<BasicParams> = {
    background { color { "white" } }
    border {
        width { "2px" }
        color { "black" }
    }
}

val selectFieldSimulatorStyleParams: Style<BasicParams> = {
    overflowY { "auto" }
}

val adminSideNavPushButtonStyleParams: Style<BasicParams> = {
    fontSize { "14px" }
    padding { "10px 35px" }
    border {
        width { "0px" }
    }
    radius { "0px" }
    color { "black" }
    width { "100%" }
    background { color { "#d6d6d6" } }
    hover {
        background { color { "#d6d6d6" } }
        color { "black" }
    }
}


val adminCardsStyleParams: Style<BasicParams> = {
    background { color { "#c5c5c5" } }
    radius { "8px" }
    width { "530px" }
    overflow { auto }
    boxShadow { "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" }
    paddings {
        left { small }
        right { small }
    }
    border {
        width { "2px" }
        style { "solid" }
        color { "black" }
    }
}

val adminCardsSimulatorStyleParams: Style<BasicParams> = {
    height { "800px" }
    background { color { "#c5c5c5" } }
    radius { "8px" }
    width { "550px" }
    boxShadow { "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" }
    paddings {
        left { small }
        right { small }
    }
    border {
        width { "2px" }
        style { "solid" }
        color { "black" }
    }
}

val adminTableHoveringParams: Style<BasicParams> = {
    color { "black" }
    borders {
        bottom { color { "black" } }
        left { color { "#c5c5c5" } }
        right { color { "#c5c5c5" } }
    }
    background { color { "#b0b0b0" } }
}

val adminTableColumnsParams: Style<BasicParams> = {
    color { "black" }
    borders {
        bottom { color { "black" } }
        top { color { "black" } }
        left { color { "#c5c5c5" } }
        right { color { "#c5c5c5" } }
    }
}
val adminTableHeaderParams: Style<BasicParams> = {
    background { color { "grey" } } //doesn't seem to work
    color { "black" }
    borders {
        bottom { color { "black" } }
        left { color { "#c5c5c5" } }
        right { color { "#c5c5c5" } }
    }
}
val adminTables: Style<BasicParams> = {
    radius { "14px" }
    hover {
        background { color { "#b0b0b0" } }
    }
}
val dangerButtonStyleParams: Style<BasicParams> = {
    margins {
        top { smaller }
    }
    background { color { "red" } }
    color { secondary.main }
    fontSize { small }
    fontWeight { bold }
    radius(formationStyles.buttonRadius)
}


/**
 * BASIC COMPONENTS
 */

val genericButtonStyleParams: Style<BasicParams>
    get() = {
        margins {
            top { smaller }
        }
        fontSize { small }
        fontWeight { bold }
        radius(formationStyles.buttonRadius)
    }

val primaryButtonStyleParams: Style<BasicParams> = {
    margins {
        top { smaller }
    }
    background { color { primary.main } }
    color { secondary.main }
    fontSize { small }
    fontWeight { bold }
    radius(formationStyles.buttonRadius)
}

val secondaryButtonStyleParams: Style<BasicParams> = {
    margins { top { smaller } }
    border {
        width(formationStyles.borderWidth)
        color { primary.main }
    }
    background { color { secondary.main } }
    color { primary.main }
    fontSize { small }
    fontWeight { bold }
    radius(formationStyles.buttonRadius)
}
