package search.searchFilterStores

import apiclient.geoobjects.ObjectTags
import apiclient.geoobjects.ObjectVisibility
import apiclient.tags.tag
import com.tryformation.localization.Translatable
import dev.fritz2.core.RootStore
import kotlinx.coroutines.Job

enum class SearchFilterOption(val defaultValue: Boolean, val tagToToggle: String) : Translatable {
    IncludeArchived(
        defaultValue = false,
        tagToToggle = ObjectTags.Archived.tag("true"),
    ),
    IncludeNavigationPoints(
        defaultValue = false,
        tagToToggle = ObjectTags.Keyword.tag("navigation-point"),
    ),
    IncludeObjectsWithHiddenMarker(
        defaultValue = false,
        tagToToggle = ObjectTags.Visibility.tag(ObjectVisibility.MarkerHidden),
    )
    ;

    override val prefix = "searchfilteroption"
}

open class ActiveSearchOptionsStore(init: Map<SearchFilterOption, Boolean>? = null) : RootStore<Map<SearchFilterOption, Boolean>>(
    initialData = init ?: SearchFilterOption.entries.associateWith { it.defaultValue }, job = Job(),
) {

    val checkOptions = handle<Map<SearchFilterOption, Boolean>> { current, options ->
        current + options
    }
}
