package model

import apiclient.passwords.PasswordEvaluation

data class ValidatePassword(
    val firstPass: String = "",
    val secondPass: String = "",
    val oldPass: String = "",
    val responseStatus: StatusResult? = null,
    val passwordEval: PasswordEvaluation? = null,
    val isMatching: Boolean = false,
    val isValid: Boolean = false,
) {
    companion object
}

data class SignUpFromInvite(
    val firstName: String,
    val lastName: String,
    val password: String,
    val inviteToken: String,
    val inviter: String? = null,
) {
    companion object {
        val EMPTY = SignUpFromInvite(
            firstName = "",
            lastName = "",
            password = "",
            inviteToken = ""
        )
    }
}
