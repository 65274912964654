package data.objects.views.cardinfo

import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.ObjectType
import data.objects.ActiveObjectStore
import data.objects.views.directediting.directEditingCardContent
import dev.fritz2.components.flexBox
import dev.fritz2.core.RenderContext
import koin.koinCtx
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.flowOf
import localization.TL
import localization.Translation
import mainmenu.Pages
import model.L
import styling.primaryButtonStyleParams
import utils.getIcon
import utils.getName
import webcomponents.baseLayout
import webcomponents.cardTitleWithSubtitle
import webcomponents.cardTitleWithSubtitleDirectEdit
import webcomponents.oneButtonFooter

fun RenderContext.buildingInfoCardContent() {

    val objectType = ObjectType.Building
    val translation: Translation by koinCtx.inject()
    val activeObjectStore: ActiveObjectStore by koinCtx.inject()
    val title = activeObjectStore.map(apiclient.geoobjects.GeoObjectDetails.L.title)
    val canModify = activeObjectStore.map(GeoObjectDetails.L.canModify)
    val canManage = activeObjectStore.map(GeoObjectDetails.L.canManage)

    baseLayout(
        header = {
            flexBox(
                {
                    direction { row }
                    justifyContent { spaceBetween }
                    alignItems { start }
                    width { full }
                    height { maxContent }
                },
            ) {
                canManage.data.combine(canModify.data) { manage, modify -> manage || modify }
                    .render { editAccess ->
                        if (editAccess) {
                            cardTitleWithSubtitleDirectEdit(
                                titleFlow = title.data,
                                title = title.current,
                                subtitle = flowOf(objectType.getName()),
                                titleIconFlow = flowOf(objectType.getIcon().icon),
                                changeHandler = activeObjectStore.editTitle,
                            )
                        } else {
                            cardTitleWithSubtitle(
                                title = title.data,
                                subtitle = flowOf(objectType.getName()),
                                titleIconFlow = flowOf(objectType.getIcon().icon),
                            )
                        }
                    }
                infoCardButtons()
            }
        },
        content = {
            directEditingCardContent {
                nestedObjectsButton()
            }
        },
        footer = {
            oneButtonFooter(
                title = translation[TL.General.BACK],
                styleParams = primaryButtonStyleParams,
                routingMap = mapOf("page" to Pages.Map.name),
                value = Unit,
                clickHandlers = listOf(activeObjectStore.resetActiveObjectAndUserToMap),
            )
        },
    )
}
