package utils

import kotlinx.browser.window

fun isIos(): Boolean {
    val platform = window.navigator.platform ?: ""
    val maxTouchPoints = window.navigator.maxTouchPoints ?: 0
    val userAgent = window.navigator.userAgent

    // List of iOS platforms
    val iOSPlatforms = listOf("iPhone", "iPad", "iPod")

    // Check if the platform matches any iOS device
    if (iOSPlatforms.any { platform.contains(it, ignoreCase = true) }) {
        return true
    }

    // Additional check for iPadOS 13+ devices (which identify as "MacIntel")
    if (platform == "MacIntel" && maxTouchPoints > 1) {
        return true
    }

    // Fallback to userAgent check
    val iosRegex = Regex("iPad|iPhone|iPod", RegexOption.IGNORE_CASE)
    if (iosRegex.containsMatchIn(userAgent)) {
        return true
    }

    return false
}
