package auth.permissions

import dev.fritz2.components.modal
import dev.fritz2.core.RootStore
import dev.fritz2.core.invoke
import koin.koinCtx
import kotlinx.coroutines.Job
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import overlays.handlerScope
import web.permissions.PermissionState

class PermissionModalStore : RootStore<Boolean>(
    initialData = false,
    job = Job(),
) {
    private val permissionsService: PermissionsService by koinCtx.inject()

    fun startPermissionModal(permission: PermissionType) {
        val state = permissionsService.current[permission]?.permissionState
        if (state != PermissionState.granted) {
            update(true)
            if (!current) {
                val spinnerHandler = modal(
                    {
                        height { none }
                        width { none }
                    },
                ) {
                    placement { center }
                    hasCloseButton(false)
                    content { close ->
                        handlerScope.launch {
                            while (current) {
                                delay(20)
                            }
                            delay(500)
                            close.invoke()
                        }
                        when (permission) {
                            PermissionType.Geolocation -> {
                                locationPermissionPrompt(listOf(close, reset))
                            }

                            else -> {}
                        }

                    }
                }
                spinnerHandler.invoke()
            }
        }
    }

    fun stopPermissionModal() {
        update(false)
    }

    val reset = handle {
        false
    }
}
