package map.views.workplacetools

import apiclient.customfields.FieldValue
import koin.koinCtx
import mainmenu.RouterStore
import map.Cards
import search.searchFilterStores.ActiveFieldValuesStore

class ActiveArchetypeSearchFieldValuesStore: ActiveFieldValuesStore() {

    val routerStore: RouterStore by koinCtx.inject()

    val addAndNavToToolsOverview = handle<FieldValue?> { current, fieldValue ->
        add(fieldValue)
        routerStore.validateInternalRoute(Cards.Tools.route)
        current
    }
}
