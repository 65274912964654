package search

import analyticsdashboard.ActiveHistoryPathSearchFieldValuesStore
import analyticsdashboard.ActiveHistoryPathSearchKeywordsStore
import analyticsdashboard.ActiveHistoryPathSearchObjectTypesStore
import analyticsdashboard.ActiveHistoryPathSearchOptionsStore
import analyticsdashboard.ActiveHistoryPathSearchReadOnlyKeywordsStore
import analyticsdashboard.PathToolSearchInputStore
import apiclient.customfields.FieldValue
import apiclient.customfields.tagValues
import apiclient.geoobjects.LatLon
import apiclient.geoobjects.ObjectTags
import apiclient.geoobjects.ObjectType
import apiclient.geoobjects.ReadOnlyTags
import apiclient.geoobjects.SearchQueryContext
import apiclient.localizations.LocalizationArg
import apiclient.markers.MapLayerContext
import apiclient.tags.addMultivaluedTag
import apiclient.tags.init
import apiclient.tags.removeTag
import apiclient.tags.tag
import auth.ApiUserStore
import auth.WorkspacesStore
import data.objects.building.CurrentBuildingsStore
import dev.fritz2.core.RootStore
import koin.koinCtx
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.mapNotNull
import location.GeoPositionStore
import location.geolocation.latLon
import map.MapStateStore
import model.Building
import model.KeywordTag
import model.SearchContexts
import notifications.NotificationSearchInputFieldStore
import search.global.ActiveGlobalSearchOptionsStore
import search.global.ActiveSearchFieldValuesStore
import search.global.ActiveSearchKeywordsStore
import search.global.ActiveSearchObjectTypesStore
import search.global.ActiveSearchReadOnlyKeywordsStore
import search.global.SearchInputFieldStore
import search.hub.HubDistanceToCentroidStore
import search.hub.HubObjectTypeFilterStore
import search.searchFilterStores.SearchFilterOption

class SearchContextsStore(apiUserStore: ApiUserStore) : RootStore<SearchContexts>(
    initialData = SearchContexts(
        mapSearch = MapLayerContext(
            groupIds = apiUserStore.current.apiUser?.groups?.map { it.groupId } ?: emptyList(),
            userId = apiUserStore.current.userId,
            excludeTags = listOf(LocalizationArg.OBJECT_TYPE.tag(ObjectType.HistoryEntry.name)),
        ),
        globalSearch = SearchQueryContext(
            groupIds = apiUserStore.current.apiUser?.groups?.map { it.groupId } ?: emptyList(),
            userId = apiUserStore.current.userId,
            objectTypes = ObjectType.entries.filter { objectType ->
                when (objectType) {
                    // filter out ObjectTypes we don't want to see in search
                    ObjectType.Floor,
                    ObjectType.Unit,
                    ObjectType.Notification,
                    ObjectType.HistoryEntry -> false

                    else -> true
                }
            },
        ),
        notificationSearch = MapLayerContext(
            groupIds = emptyList(),
            userId = apiUserStore.current.userId,
        ),
        areaSearch = MapLayerContext(
            groupIds = apiUserStore.current.apiUser?.groups?.map { it.groupId } ?: emptyList(),
            userId = apiUserStore.current.userId,
        ),
        hubSearch = SearchQueryContext(
            groupIds = apiUserStore.current.apiUser?.groups?.map { it.groupId } ?: emptyList(),
            userId = apiUserStore.current.userId,
            objectTypes = listOf(
                ObjectType.Event,
                ObjectType.Task,
                ObjectType.POI,
                ObjectType.Building,
                ObjectType.Zone,
            ),
            centroid = apiUserStore.current.apiUser?.groups?.firstOrNull()?.defaultMapCenter,
        ),
        analyticsPaths = SearchQueryContext(
            groupIds = apiUserStore.current.apiUser?.groups?.map { it.groupId } ?: emptyList(),
            userId = apiUserStore.current.userId,
            objectTypes = listOf(ObjectType.ObjectMarker),
            excludeTags = listOf(ObjectTags.Archived.tag("true")),
        ),
        analyticsHeatmaps = SearchQueryContext(
            groupIds = apiUserStore.current.apiUser?.groups?.map { it.groupId } ?: emptyList(),
            userId = apiUserStore.current.userId,
        ),
    ),
    job = Job(),
) {
    private val workspacesStore: WorkspacesStore by koinCtx.inject()
    private val searchInputFieldStore: SearchInputFieldStore by koinCtx.inject()
    private val notificationSearchInputFieldStore: NotificationSearchInputFieldStore by koinCtx.inject()
    private val currentBuildingsStore: CurrentBuildingsStore by koinCtx.inject()

    private val activeSearchKeyWordsStore: ActiveSearchKeywordsStore by koinCtx.inject()
    private val activeSearchObjectTypesStore: ActiveSearchObjectTypesStore by koinCtx.inject()
    private val activeSearchReadOnlyKeywordsStore: ActiveSearchReadOnlyKeywordsStore by koinCtx.inject()
    private val activeSearchFieldValuesStore: ActiveSearchFieldValuesStore by koinCtx.inject()
    private val activeGlobalSearchOptionsStore: ActiveGlobalSearchOptionsStore by koinCtx.inject()

    private val activeHistoryPathSearchKeywordsStore: ActiveHistoryPathSearchKeywordsStore by koinCtx.inject()
    private val activeHistoryPathSearchObjectTypesStore: ActiveHistoryPathSearchObjectTypesStore by koinCtx.inject()
    private val activeHistoryPathSearchReadOnlyKeywordsStore: ActiveHistoryPathSearchReadOnlyKeywordsStore by koinCtx.inject()
    private val activeHistoryPathSearchFieldValuesStore: ActiveHistoryPathSearchFieldValuesStore by koinCtx.inject()
    private val activeHistoryPathSearchOptionsStore: ActiveHistoryPathSearchOptionsStore by koinCtx.inject()

    private val mapStateStore: MapStateStore by koinCtx.inject()
    private val geoPositionStore: GeoPositionStore by koinCtx.inject()
    private val hubDistanceToCentroidStore: HubDistanceToCentroidStore by koinCtx.inject()
    private val hubObjectTypeFilterStore: HubObjectTypeFilterStore by koinCtx.inject()
    private val pathToolSearchInputStore: PathToolSearchInputStore by koinCtx.inject()

    private val updateWorkspaceIds = handle<List<String>> { current, workspaceIds ->
        current.copy(
            mapSearch = current.mapSearch.copy(groupIds = workspaceIds),
            globalSearch = current.globalSearch.copy(groupIds = workspaceIds),
            areaSearch = current.areaSearch.copy(groupIds = workspaceIds),
            hubSearch = current.hubSearch.copy(groupIds = workspaceIds),
            analyticsPaths = current.analyticsPaths.copy(groupIds = workspaceIds),
            analyticsHeatmaps = current.analyticsHeatmaps.copy(groupIds = workspaceIds),
        )
    }

    private val updateUserId = handle<String?> { current, userId ->
        current.copy(
            mapSearch = current.mapSearch.copy(userId = userId),
            globalSearch = current.globalSearch.copy(userId = userId),
            notificationSearch = current.notificationSearch.copy(userId = userId),
            areaSearch = current.areaSearch.copy(userId = userId),
            hubSearch = current.hubSearch.copy(userId = userId),
            analyticsPaths = current.analyticsPaths.copy(userId = userId),
            analyticsHeatmaps = current.analyticsHeatmaps.copy(userId = userId),
        )
    }

    private val updateIncludeArchivedInGlobalSearch = handle<Boolean> { current, includeArchived ->
        console.log("Set Include Archived in GlobalSearch to", includeArchived)
        current.copy(
            globalSearch = current.globalSearch.copy(
                excludeTags =
                if (includeArchived) {
                    current.globalSearch.excludeTags?.removeTag(ObjectTags.Archived)
                } else {
                    current.globalSearch.excludeTags.init() +
                        ObjectTags.Archived.tag("true")
                },
            ),
        )
    }
    private val updateIncludeArchivedInHistoryPathSearch = handle<Boolean> { current, includeArchived ->
        console.log("Set Include Archived in HistoryPathsSearch to", includeArchived)
        current.copy(
            analyticsPaths = current.analyticsPaths.copy(
                excludeTags =
                if (includeArchived) {
                    current.analyticsPaths.excludeTags?.removeTag(ObjectTags.Archived)
                } else {
                    current.analyticsPaths.excludeTags.init() +
                        ObjectTags.Archived.tag("true")
                },
            ),
        )
    }

    private val updateGlobalSearchTextQuery = handle<String> { current, textQuery ->
        if (textQuery.isBlank()) {
            current.copy(globalSearch = current.globalSearch.copy(text = null))
        } else current.copy(globalSearch = current.globalSearch.copy(text = textQuery))
    }

    private val updateGlobalSearchTagsQuery = handle<List<String>?> { current, tags ->
        val currentOtherTags = current.globalSearch.tags?.removeTag(ObjectTags.Keyword) ?: emptyList()
        if (!tags.isNullOrEmpty()) {
            current.copy(
                globalSearch = current.globalSearch.copy(tags = currentOtherTags + tags.map { ObjectTags.Keyword.tag(it) }),
            )
        } else {
            current.copy(
                globalSearch = current.globalSearch.copy(tags = currentOtherTags),
            )
        }
    }

    private val updateGlobalSearchObjectTypes = handle<List<ObjectType>?> { current, objTypes ->
        console.log("UPDATE Search ctx objectType with", objTypes.toString())
        console.log("UPDATED Search ctx:", current.copy(globalSearch = current.globalSearch.copy(objectTypes = objTypes)).toString())
        current.copy(
            globalSearch = current.globalSearch.copy(
                objectTypes =
                objTypes?.takeUnless { it.isEmpty() } ?: ObjectType.entries.filter { it.includeInSearch },
            ),
        )
    }
    private val updateGlobalSearchOtherTags = handle<List<KeywordTag>?> { current, otherTags ->
        val currentOtherTags = current.globalSearch.tags?.removeTag(ReadOnlyTags.Assignee)?.removeTag(ReadOnlyTags.Creator) ?: emptyList()
        if (!otherTags.isNullOrEmpty()) {
            current.copy(
                globalSearch = current.globalSearch.copy(
                    tags = currentOtherTags + otherTags.map { keywordTag ->
                        if (!keywordTag.readOnlyStringValue.isNullOrBlank()) {
                            when (keywordTag.readOnlyType) {
                                ReadOnlyTags.Assignee -> ReadOnlyTags.Assignee.tag(keywordTag.readOnlyStringValue)
                                ReadOnlyTags.Creator -> ReadOnlyTags.Creator.tag(keywordTag.readOnlyStringValue)
                                else -> keywordTag.fieldText
                            }
                        } else keywordTag.fieldText
                    },
                ),
            )
        } else {
            current.copy(
                globalSearch = current.globalSearch.copy(tags = currentOtherTags),
            )
        }
    }
    private val updateGlobalSearchFieldValueTags = handle<List<FieldValue>> { current, fieldValues ->
        val currentOtherTags = current.globalSearch.tags?.removeTag(ObjectTags.CustomField) ?: emptyList()
        if (fieldValues.isNotEmpty()) {
            current.copy(globalSearch = current.globalSearch.copy(tags = currentOtherTags.addMultivaluedTag(ObjectTags.CustomField, fieldValues.tagValues())))
        } else {
            current.copy(globalSearch = current.globalSearch.copy(tags = currentOtherTags))
        }
    }

    private val updatePathToolSearchTextQuery = handle<String> { current, textQuery ->
        if (textQuery.isBlank()) {
            current.copy(analyticsPaths = current.analyticsPaths.copy(text = null))
        } else current.copy(analyticsPaths = current.analyticsPaths.copy(text = textQuery))
    }

    private val updateHistoryPathSearchTagsQuery = handle<List<String>?> { current, tags ->
        val currentOtherTags = current.analyticsPaths.tags?.removeTag(ObjectTags.Keyword) ?: emptyList()
        if (!tags.isNullOrEmpty()) {
            current.copy(
                analyticsPaths = current.analyticsPaths.copy(tags = currentOtherTags + tags.map { ObjectTags.Keyword.tag(it) }),
            )
        } else {
            current.copy(
                analyticsPaths = current.analyticsPaths.copy(tags = currentOtherTags),
            )
        }
    }

    private val updateHistoryPathSearchObjectTypes = handle<List<ObjectType>?> { current, objTypes ->
        val currentOtherTags = current.analyticsPaths.tags?.removeTag(ReadOnlyTags.ObjectType) ?: emptyList()
        if (!objTypes.isNullOrEmpty()) {
            current.copy(analyticsPaths = current.analyticsPaths.copy(tags = currentOtherTags + objTypes.map { ReadOnlyTags.ObjectType.tag(it.name) }))
        } else {
            current.copy(analyticsPaths = current.analyticsPaths.copy(tags = currentOtherTags))
        }
    }
    private val updateHistoryPathSearchOtherTags = handle<List<KeywordTag>?> { current, otherTags ->
        val currentOtherTags = current.analyticsPaths.tags?.removeTag(ReadOnlyTags.Assignee)?.removeTag(ReadOnlyTags.Creator) ?: emptyList()
        if (!otherTags.isNullOrEmpty()) {
            current.copy(
                analyticsPaths = current.analyticsPaths.copy(
                    tags = currentOtherTags + otherTags.map { keywordTag ->
                        if (!keywordTag.readOnlyStringValue.isNullOrBlank()) {
                            when (keywordTag.readOnlyType) {
                                ReadOnlyTags.Assignee -> ReadOnlyTags.Assignee.tag(keywordTag.readOnlyStringValue)
                                ReadOnlyTags.Creator -> ReadOnlyTags.Creator.tag(keywordTag.readOnlyStringValue)
                                else -> keywordTag.fieldText
                            }
                        } else keywordTag.fieldText
                    },
                ),
            )
        } else {
            current.copy(
                analyticsPaths = current.analyticsPaths.copy(tags = currentOtherTags),
            )
        }
    }
    private val updateHistoryPathSearchFieldValueTags = handle<List<FieldValue>> { current, fieldValues ->
        val currentOtherTags = current.analyticsPaths.tags?.removeTag(ObjectTags.CustomField) ?: emptyList()
        if (fieldValues.isNotEmpty()) {
            current.copy(
                analyticsPaths = current.analyticsPaths.copy(
                    tags = currentOtherTags.addMultivaluedTag(
                        ObjectTags.CustomField,
                        fieldValues.tagValues(),
                    ),
                ),
            )
        } else {
            current.copy(analyticsPaths = current.analyticsPaths.copy(tags = currentOtherTags))
        }
    }

    private val updateNotificationSearchTextQuery = handle<String> { current, textQuery ->
        if (textQuery.isBlank()) {
            current.copy(notificationSearch = current.notificationSearch.copy(textQuery = null))
        } else current.copy(notificationSearch = current.notificationSearch.copy(textQuery = textQuery))
    }

    // Only update floorIds, buildingIds are not needed and not created on every object anymore
    private val updateFloorIds = handle<List<Building>> { current, buildings ->
        val floorIds = buildings.flatMap { it.activeFloorIds ?: emptyList() }
        console.log("SearchCtx Updated with", floorIds.toTypedArray())
        current.copy(
            mapSearch = current.copy().mapSearch.copy(buildingIds = null, floorIds = floorIds),
            areaSearch = current.areaSearch.copy(buildingIds = null, floorIds = floorIds),
        )
    }

    private val updateHubSearchObjectTypes = handle<Set<ObjectType>> { current, objectTypes ->
        if (objectTypes.isNotEmpty()) {
            current.copy(hubSearch = current.hubSearch.copy(objectTypes = objectTypes.toList()))
        } else current.copy(hubSearch = current.hubSearch.copy(objectTypes = null))
    }

    private val updateHubSearchCentroid = handle<LatLon?> { current, latLon ->
        if (latLon != null) {
            if (latLon != current.hubSearch.centroid) {
                current.copy(hubSearch = current.hubSearch.copy(centroid = latLon))
            } else current
        } else current.copy(hubSearch = current.hubSearch.copy(centroid = null))
    }

    private val updateUnitId = handle<String> { current, unitId ->
        current.copy(areaSearch = current.areaSearch.copy(unitId = unitId))
    }

    private val updateZoneId = handle<String> { current, zoneId ->
        current.copy(areaSearch = current.areaSearch.copy(zoneId = zoneId))
    }

    private val setIncludeOutdoors = handle<Boolean> { current, includeOutdoors ->
        current.copy(areaSearch = current.areaSearch.copy(includeOutdoors = includeOutdoors))
    }


    init {
        apiUserStore.data.map { it.userId } handledBy updateUserId
        workspacesStore.data.map { workspaces -> workspaces.map { it.groupId } } handledBy updateWorkspaceIds
        notificationSearchInputFieldStore.data handledBy updateNotificationSearchTextQuery
        currentBuildingsStore.data.map { buildings ->
            buildings.values.toList()
        } handledBy updateFloorIds

        searchInputFieldStore.data handledBy updateGlobalSearchTextQuery
        activeSearchKeyWordsStore.data handledBy updateGlobalSearchTagsQuery
        activeSearchObjectTypesStore.data handledBy updateGlobalSearchObjectTypes
        activeSearchReadOnlyKeywordsStore.data handledBy updateGlobalSearchOtherTags
        activeSearchFieldValuesStore.data handledBy updateGlobalSearchFieldValueTags
        activeGlobalSearchOptionsStore.data.map {
            it[SearchFilterOption.IncludeArchived] ?: false
        } handledBy updateIncludeArchivedInGlobalSearch


        pathToolSearchInputStore.data handledBy updatePathToolSearchTextQuery
        activeHistoryPathSearchKeywordsStore.data handledBy updateHistoryPathSearchTagsQuery
        activeHistoryPathSearchObjectTypesStore.data handledBy updateHistoryPathSearchObjectTypes
        activeHistoryPathSearchReadOnlyKeywordsStore.data handledBy updateHistoryPathSearchOtherTags
        activeHistoryPathSearchFieldValuesStore.data handledBy updateHistoryPathSearchFieldValueTags
        activeHistoryPathSearchOptionsStore.data.map {
            it[SearchFilterOption.IncludeArchived] ?: false
        } handledBy updateIncludeArchivedInHistoryPathSearch

        combine(hubDistanceToCentroidStore.data, mapStateStore.data, geoPositionStore.data) { hubDistanceTo, m, g ->
            when (hubDistanceTo) {
                DistanceTo.User -> g?.latLon()
                else -> m?.center
            }
        }.mapNotNull { it } handledBy updateHubSearchCentroid
        hubObjectTypeFilterStore.data.map { objTypes -> objTypes.filter { it.value }.keys.toSet() } handledBy updateHubSearchObjectTypes
    }
}

fun MapLayerContext.hasUserAndGroupIds(): Boolean {
    return hasUserId() && hasGroupId()
}

fun MapLayerContext.hasUserId(): Boolean {
    return !userId.isNullOrBlank()
}

fun MapLayerContext.isNotAnonymous(): Boolean {
    return !userId.isNullOrBlank() && userId != "anonymous"
}

fun MapLayerContext.hasGroupId(): Boolean {
    return groupIds.isNotEmpty()
}
