package data

import analytics.AnalyticsCategory
import analytics.analyticsEvent
import apiclient.FormationClient
import apiclient.geoobjects.Content
import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.ObjectTags
import apiclient.geoobjects.ObjectType
import apiclient.geoobjects.restGetObjectById
import apiclient.tags.buildingId
import apiclient.tags.floorId
import apiclient.tags.getUniqueTag
import apiclient.users.PublicUserProfile
import apiclient.users.restGetPublicUserProfile
import auth.ApiUserStore
import data.objects.ActiveObjectStore
import data.objects.building.ActiveBuildingOverrideStore
import data.objects.building.BuildingOverride
import data.objects.building.CurrentBuildingsStore
import data.users.ActiveUserStore
import data.users.UserListStore
import data.users.views.maskEmailAddress
import dev.fritz2.core.RootStore
import dev.fritz2.core.invoke
import dev.fritz2.tracking.tracker
import koin.koinCtx
import kotlinx.coroutines.CoroutineName
import kotlinx.coroutines.Job
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import layercache.GeoObjectDetailsCache
import location.LocationFollowStore
import mainmenu.Pages
import mainmenu.RouterStore
import maplibreGL.MaplibreMap
import maplibreGL.toLatLon
import model.getFirstGroupIdOrNull
import notifications.GlobalNotificationResultsStore
import overlays.BusyStore
import poll.ActivePollStore
import services.GeoPositionService
import utils.insertObjectInCachesAndMap
import utils.removeObjectsInCachesAndMap
import utils.respectFeatureFlags
import websocket.UserAndObjectResultsStore

class ObjectAndUserHandler : RootStore<Boolean>(
    initialData = false,
    job = Job(),
) {
    private val maplibreMap: MaplibreMap by koinCtx.inject()
    private val apiUserStore: ApiUserStore by koinCtx.inject()
    private val activeObjectStore: ActiveObjectStore by koinCtx.inject()
    private val routerStore: RouterStore by koinCtx.inject()
    private val userListStore: UserListStore by koinCtx.inject()
    private val currentBuildingsStore: CurrentBuildingsStore by koinCtx.inject()
    private val formationClient: FormationClient by koinCtx.inject()
    private val activeUserStore: ActiveUserStore by koinCtx.inject()
    private val globalNotificationResultsStore: GlobalNotificationResultsStore by koinCtx.inject()
    private val userAndObjectResultsStore: UserAndObjectResultsStore by koinCtx.inject()
    private val geoPositionService: GeoPositionService by koinCtx.inject()
    private val locationFollowStore: LocationFollowStore by koinCtx.inject()
    private val busyStore: BusyStore by koinCtx.inject()
    private val geoObjectDetailsCache: GeoObjectDetailsCache by koinCtx.inject()

    // use this to prevent another api call if lookup is already running
    private var busyGettingObjectById = false

    val refreshObjectTracker = tracker()

    private fun routeToUserCard(userId: String?) {
        val maplibreMap: MaplibreMap by koinCtx.inject()
        if (userId != null) {
            maplibreMap.off(type = "click", fnId = "resetOnMapClick")
            maplibreMap.once(type = "click", fn = activeObjectStore::resetOnMapClick, fnId = "resetOnMapClick")
            // TODO route to "myUserCard" here when it is implemented
            routerStore.validateInternalRoute(
                Pages.UserProfile.route,
            )
        }
    }

    private fun routeToObjectCard(objectId: String?, objectType: ObjectType) {
        if (objectId != null) {
            if (objectType != ObjectType.GeneralMarker) {
                maplibreMap.off(type = "click", fnId = "resetOnMapClick")
                maplibreMap.once(type = "click", fn = activeObjectStore::resetOnMapClick, fnId = "resetOnMapClick")
            }
            routerStore.validateInternalRoute(
                routerStore.baseRoute() + Pages.Map.route + mapOf("id" to objectId),
            )
        }
    }

    fun fetchAndShowUser(userId: String?) {
        console.log("Fetched userId:", userId)
        if (userId != null) {
            if (apiUserStore.current.userId == userId) {
                // is my user
                updateAndLocateActiveUserById(userId)
            } else {
                //is other user?
                userListStore.getPublicUserProfile(userId)?.let { user ->
                    updateAndLocateActiveUser(user)
                } ?: run { updateAndLocateActiveUserById(userId) }
            }
        }
    }

    fun fetchAndShowObject(objectId: String?) {
        console.log("Fetched objectId:", objectId)
        if (objectId != null) {
            MainScope().launch {
                updateAndLocateActiveObjectByInternalId(objectId, true)
            }
        }
    }

    private suspend fun panToUser(userId: String) {
        if (userId == apiUserStore.current.userId) {
            geoPositionService.locateMe()
        } else {
            userAndObjectResultsStore.current.renderData[userId]?.let { user ->
                maplibreMap.panTo(center = user.latLon)
            }
        }
        // trigger panTo again, if routing was too slow
        delay(200)
        if (userId == apiUserStore.current.userId) {
            geoPositionService.locateMe()
        } else {
            userAndObjectResultsStore.current.renderData[userId]?.let { user ->
                if (user.latLon != maplibreMap.getCenter().toLatLon()) {
                    maplibreMap.panTo(center = user.latLon)
                }
            }
        }
    }

    val locateCurrentActiveUser = handle { current ->
        coroutineScope {
            CoroutineName("locate-current-active-user")
            launch {
                locationFollowStore.stopFollow()
                panToUser(activeUserStore.current.userId)
            }
        }
        current
    }

    val updateAndLocateActiveUser = handle<PublicUserProfile> { current, publicUserProfile ->
        if (publicUserProfile.userId == apiUserStore.current.userId) {
            routerStore.validateInternalRoute(Pages.MyProfile.route)
            console.log("User is me -> route to MyProfile")
        } else {
            routeToUserCard(publicUserProfile.userId)
            activeUserStore.update(publicUserProfile)
            console.log(
                "updated currentActiveUser with:",
                publicUserProfile.copy(emailAddresses = publicUserProfile.emailAddresses.map { maskEmailAddress(it) }),
            )
        }
        locationFollowStore.stopFollow()
        coroutineScope {
            CoroutineName("locate-user")
            launch {
                panToUser(publicUserProfile.userId)
            }
        }
        current
    }

    val updateAndLocateActiveUserById = handle<String> { current, userId ->
        if (userId.isNotBlank()) {
            busyStore.handleApiCall(
                supplier = suspend {
                    formationClient.restGetPublicUserProfile(userId = userId)
                },
                processResult = { publicUserProfile ->
                    updateAndLocateActiveUser(publicUserProfile)
                },
                processError = { error ->
                    console.error("Error, user not found: $userId", error.message)
                    routerStore.back()
                    // Also clear redirect route here?
                },
            )
        }
        current
    }

    val updateAndLocateActiveObject = handle<GeoObjectDetails> { current, geoObjectDetails ->
        // Remove all relevant overrides from old marker, to display it again
        insertObjectInCachesAndMap(geoObjectDetails)
        val oldObject = activeObjectStore.current
        maplibreMap.removeGeometryCenterOverride(oldObject, false)
        maplibreMap.removeGeometryShapeOverride(oldObject, false)
        maplibreMap.removeHiddenOverride(oldObject.id, false)
        maplibreMap.removeActiveObjectOverride(oldObject.id)
        activeObjectStore.updateActiveObject(geoObjectDetails)
        locationFollowStore.stopFollow()
        selectFloorForObject(
            buildingId = geoObjectDetails.tags.buildingId,
            floorId = geoObjectDetails.tags.floorId,
        )
        console.log("updated currentActiveObject with:", geoObjectDetails)
        console.log("current object tags:", geoObjectDetails.tags.map { "\n$it" }.toString())
//        routeToObjectCard(
//            objectId = geoObjectDetails.tags.getUniqueTag(ObjectTags.ExternalId)
//                ?: geoObjectDetails.id,
//            objectType = geoObjectDetails.objectType,
//        )
        when (geoObjectDetails.objectType) {
            ObjectType.Floor -> {
                console.log("OBJECT is floor") // TODO: add here what should happen when clicking on a geoJSON floor polygon
            }

            ObjectType.Unit -> {
                console.log("OBJECT is unit") // TODO: add here what should happen when clicking on a geoJSON unit polygon
            }
            // add actions for other ObjectTypes (Unit, Area)
            else -> {
                when (geoObjectDetails.objectType) {
                    ObjectType.Task, ObjectType.Event -> {
                        userListStore.fetchGroupMembers(null)
                    }

                    ObjectType.Building -> {
                        geoObjectDetails.id.let { buildingId ->
                            currentBuildingsStore.forceRefreshBuildingFloorData(buildingId)
                        }
                    }

                    else -> {}
                }

                if (maplibreMap.getCenter().toLatLon() != geoObjectDetails.latLon) {
                    val isConnected =
                        !geoObjectDetails.tags.getUniqueTag(ObjectTags.ConnectedTo).isNullOrBlank()
                    val currentZoom = maplibreMap.getZoom()
                    if (currentZoom < 19.0) {
                        maplibreMap.flyTo(
                            center = geoObjectDetails.latLon,
                            zoom = if (isConnected) 20.0 else currentZoom,
                        )
                    } else {
                        maplibreMap.panTo(
                            center = geoObjectDetails.latLon,
                        )
                    }
                }
            }
        }
        maplibreMap.addActiveObjectOverride(geoObjectDetails)
        current
    }

    val refreshObjectById = handle { current ->
        console.log("Refresh active object: ${activeObjectStore.current.title}")
        refreshObjectTracker.track {
            updateAndLocateActiveObjectByInternalId(activeObjectStore.current.id)
            delay(200) // only to make sure that the spinner shows briefly
        }
        current
    }

    suspend fun updateAndLocateActiveObjectByInternalId(geoObjectId: String?, sendAnalyticsEvent: Boolean = false) {
//    val updateAndLocateActiveObjectByInternalId = handle<String?> { current, geoObjectId ->
        if (!geoObjectId.isNullOrEmpty()) {
            // use this to prevent another api call if lookup is already running
            busyGettingObjectById = true
            busyStore.handleApiCall(
                supplier = suspend {
                    geoObjectDetailsCache.fetchResult(id = geoObjectId, formationClient, skipCache = true)
                },
                processResult = { (geoObjectDetails, isCachedResult) ->
                    console.log("isCached", isCachedResult)
                    updateAndLocateActiveObject(geoObjectDetails)
                    busyGettingObjectById = false
                    if(sendAnalyticsEvent) {
                        analyticsEvent(AnalyticsCategory.OpenMarker, "open", target = "${geoObjectDetails.objectType} | ${geoObjectDetails.title}", location = geoObjectDetails.latLon)
                    }
                },
                processError = { error ->
                    console.warn("Error, object not found: $geoObjectId", error.message)
                    busyGettingObjectById = false
                    removeObjectsInCachesAndMap(setOf(geoObjectId))
                    maplibreMap.syncMarkersNow()
                    routerStore.goToMap()
                    // Also clear redirect route here?
                },
            )
        }
        current
    }

    val updateAndLocateActiveObjectByExternalIdOrInternalId = handle<String?> { current, objId ->
        console.log("update And Locate ActiveObject By ExternalId Or InternalId")
        if (!objId.isNullOrBlank() && !busyGettingObjectById) {
            val groupIdOrNull = apiUserStore.current.getFirstGroupIdOrNull()
            groupIdOrNull?.let { groupId ->
                // use this to prevent another api call if lookup is already running
                busyGettingObjectById = true
                busyStore.handleApiCall(
                    supplier = suspend {
                        formationClient.restGetObjectById(objId)
                    },
                    processResult = { geoObjectDetails ->
                        val geoObjectId = geoObjectDetails.id ?: objId
                        if (geoObjectId.isNotBlank()) {
                            console.log(geoObjectId)
                            updateAndLocateActiveObject(geoObjectDetails)
                        } else {
                            routerStore.back()
                        }
                    },
                    processError = { error ->
                        console.error("Object not found by external id: $objId", error.message)
                        busyGettingObjectById = false
                    },
                )
            }
        }
        current
    }

    val updateAndLocateObjectOrUserFromList = handle<GeoObjectDetails> { current, geoObject ->
        when (geoObject.objectType) {
            ObjectType.Notification -> {
                geoObject.connectedTo?.let { connectedToId ->
                    updateAndLocateActiveObjectByInternalId(connectedToId)
                }
                globalNotificationResultsStore.markAsRead(geoObject.id)
            }

            else -> {
                when (geoObject.objectType) {
                    ObjectType.UserMarker -> {
                        userListStore.getPublicUserProfile(geoObject.ownerId)?.let { user ->
                            updateAndLocateActiveUser(user)
                        }
                    }

                    else -> updateAndLocateActiveObject(geoObject)
                }
            }
        }
        current
    }

    val showPollDirectly = handle<Pair<GeoObjectDetails, String>> { current, (obj, pollId) ->

        console.log("SHOW POLL DIRECTLY", pollId)
        val activePollStore: ActivePollStore by koinCtx.inject()

        locationFollowStore.stopFollow()
        activeObjectStore.updateActiveObject(obj)
        selectFloorForObject(
            buildingId = obj.tags.buildingId,
            floorId = obj.tags.floorId,
        )

        val pollAttachment = obj.attachments.respectFeatureFlags()?.firstOrNull {
            it is Content.Poll && it.id == pollId
        } as? Content.Poll

        console.log("POLL:", pollAttachment)
        activePollStore.update(pollAttachment)

        maplibreMap.off(type = "click", fnId = "resetOnMapClick")
        maplibreMap.once(type = "click", fn = activeObjectStore::resetOnMapClick, fnId = "resetOnMapClick")
//        routerStore.validateInternalRoute(
//            mapOf(
//                "page" to Pages.Map.name,
//                "id" to (obj.tags.getUniqueTag(ObjectTags.ExternalId) ?: obj.id),
//            ),
//        )
//        // intention was to rout to object first, then to poll, so that back button from poll is going to object afterwards
//        delay(300)
//        routerStore.addOrReplaceRouteDirectly(
//            mapOf("show" to "poll"),
//        )

        // new approach, insert rout to object inti router history, and the navigate directly to poll
        routerStore.history.back()
        routerStore.history.add(
            mapOf(
                "page" to Pages.Map.name,
                "id" to (obj.tags.getUniqueTag(ObjectTags.ExternalId) ?: obj.id),
            ),
        )
        console.log("Squeezed route to object into history.")
        routerStore.validateInternalRoute(
            mapOf(
                "page" to Pages.Map.name,
                "id" to (obj.tags.getUniqueTag(ObjectTags.ExternalId) ?: obj.id),
                "show" to "poll",
            ),
        )

        delay(50)
        val isConnected =
            !obj.tags.getUniqueTag(ObjectTags.ConnectedTo).isNullOrBlank()
        val currentZoom = maplibreMap.getZoom()
        maplibreMap.panTo(center = obj.latLon)
        if (isConnected && currentZoom < 20.0) {
            maplibreMap.zoomTo(20.0)
        }
//        maplibreMap.flyTo(
//            center = obj.latLon,
//            zoom = if (isConnected && currentZoom < 20.0) 20.0 else currentZoom
//        )
        // trigger flyTo again, if routing was too slow
//        delay(300)
//        if (obj.latLon != maplibreMap.getCenter().toLatLon()) {
//            maplibreMap.flyTo(
//                center = obj.latLon,
//                zoom = if (isConnected && currentZoom < 20.0) 20.0 else currentZoom
//            )
//        }
        current
    }

    private fun selectFloorForObject(buildingId: String? = null, floorId: String? = null) {

        val currentBuildingsStore: CurrentBuildingsStore by koinCtx.inject()
        val activeBuildingOverrideStore: ActiveBuildingOverrideStore by koinCtx.inject()

        if (!buildingId.isNullOrBlank() && !floorId.isNullOrBlank()) {
            activeBuildingOverrideStore.update(BuildingOverride(buildingId, floorId))
            currentBuildingsStore.setFloorForBuilding(Pair(buildingId, floorId))
        }
    }
}
