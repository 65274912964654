package localization

import apiclient.util.createHttpClient
import com.tryformation.localization.Locale
import com.tryformation.localization.LocalizedTranslationBundleSequence
import com.tryformation.localization.LocalizedTranslationBundleSequenceProvider
import com.tryformation.localization.Translatable
import com.tryformation.localization.TranslatedValue
import dev.fritz2.core.HtmlTag
import dev.fritz2.core.RenderContext
import dev.fritz2.core.RootStore
import dev.fritz2.core.Store
import io.ktor.client.*
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import koin.withKoin
import kotlinx.browser.window
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.map
import org.w3c.dom.HTMLElement

private val missingTranslations = mutableSetOf<Translatable>()

fun RenderContext.showMissingTranslations() {
    pre {
        missingTranslations.sortedBy { it.messageId }.forEach { translatable ->
            +"${translatable.messageId} = ${translatable.humanReadable}\n"
        }
    }
}

val TranslatedValue.value: String
    get() {
        if (this.noTranslationFound) {
            console.warn("missing translation $messageId")
            translatable?.let {
                missingTranslations.add(it)
            }
        }

        return this.message
    }

typealias Translation = TranslationStore

private val Translatable.humanReadable: String
    get() {
        val postFix = messageId.replace("$prefix-", "")

        return postFix.split('-') // Split on CamelCase or underscore
            .joinToString(" ") { it.lowercase().replaceFirstChar { char -> char.uppercase() } }
    }

class TranslationStore(
    bundleSequence: LocalizedTranslationBundleSequence,
    languageCodeSettingStore: Store<String?>,
) : RootStore<LocalizedTranslationBundleSequence>(
    initialData = bundleSequence,
    job = Job(),
) {

    operator fun get(translatable: Translatable): Flow<String> = data.map {
        it.format(
            translatable = translatable,
            args = null,
            fallback = translatable.humanReadable,
        ).value
    }

    operator fun get(translatable: Translatable, args: Map<String, Any>): Flow<String> = data.map {
        it.format(
            translatable = translatable,
            args = args,
            fallback = translatable.humanReadable,
        ).value
    }

    operator fun get(translatable: Translatable, args: Flow<Map<String, Any>>): Flow<String> =
        data.combine(args) { tl, json -> tl.format(translatable = translatable, args = json, fallback = translatable.humanReadable).value }


    fun get(stringId: String): Flow<String> = data.map { it.format(stringId, null).value }
    fun get(stringId: String, args: Map<String, Any>): Flow<String> = data.map { it.format(stringId, args).value }
    fun get(stringId: String, args: Flow<Map<String, Any>>): Flow<String> = data.combine(args) { tl, json -> tl.format(stringId, json).value }

    fun getString(translatable: Translatable, json: Map<String, Any>? = null): String = current.format(translatable, json, translatable.humanReadable).value
    fun getString(stringId: String, json: Map<String, Any>? = null): String = current.format(stringId, json).value

    private val setLocale = handle<String?> { current, locale ->
        if (locale != null) {
            console.log("switching locale", locale)
            provider.loadBundleSequence(listOf(locale), fallbackLocale = Locales.EN_GB.id, ::fetchFtl)
        } else current
    }

    init {
        languageCodeSettingStore.data handledBy setLocale
    }

    companion object {
        private val provider = LocalizedTranslationBundleSequenceProvider()

        suspend fun load(languageCodeSettingStore: Store<String?>, localeStore: Store<Locale>): TranslationStore {
            return withKoin {

                val intialBundleSequence = provider.loadBundleSequence(
                    locales = listOfNotNull(
                        languageCodeSettingStore.current,
                        window.navigator.language,
                    ) + window.navigator.languages,
                    fallbackLocale = Locales.EN_GB.id,
                    fetch = ::fetchFtl,
                )
                intialBundleSequence.bundles.firstNotNullOfOrNull {
                    it.locale.first().let { localeCode ->
                        Locales.findByIdOrNull(localeCode)
                    }
                }?.let {

                    localeStore.update(it)
                }

                TranslationStore(intialBundleSequence, languageCodeSettingStore)
            }

        }
    }
}

private val translationStore by lazy {
    withKoin {
        get<TranslationStore>()
    }
}

fun HtmlTag<HTMLElement>.translate(translatable: Translatable, args: Map<String, Any>? = null) =
    translationStore[translatable, args ?: mapOf()].renderText()

fun HtmlTag<HTMLElement>.translate(translatable: Translatable, vararg args: Pair<String, Any>) =
    translationStore[translatable, mapOf(*args)].renderText()

fun Translatable?.getString(args: Map<String, Any>? = null) = this?.let {
    translationStore.getString(it, args ?: mapOf())
}

fun Translatable?.getString(vararg args: Pair<String, Any>) = this?.let {
    translationStore.getString(it, mapOf(*args))
}

fun Translatable.getTranslationFlow(args: Map<String, Any>? = null) = translationStore[this, args.orEmpty()]


// we need this to work without koin
private val client: HttpClient by lazy { createHttpClient() }

suspend fun fetchFtl(id: String): String? {
    return try {
        val baseUrl = window.location.let { l ->
            when (l.protocol) {
                "file:" -> {
                    "https://app.tryformation.com/lang"
                }

                else -> {
                    l.protocol + "//" + l.host + "/lang"
                }
            }
        }
        // Look up Locales and find best matching one otherwise return null right away
        Locales.findByIdOrNull(id)?.fileName?.let { file ->
            val url = "${baseUrl}/${file}"
            val response = client.get(url)
            // not using response.bodyAsText() here because that fails on webpack because of a missing content-type header
            // readBytes ignores the content-type
            val bytes = response.readBytes()
            bytes.decodeToString()
        }
    } catch (e: ClientRequestException) {
        console.error(e)
        null
    } catch (e: Throwable) {
        // 404? Webpack is a bit funny
        console.error(e)
        null
    }
}
