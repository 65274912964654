// GENERATED by fritz2 - NEVER CHANGE CONTENT MANUALLY!
package model

import dev.fritz2.core.Lens
import dev.fritz2.core.lensOf

fun Feedback.Companion.subject(): Lens<Feedback, String> = lensOf(
    "subject",
    { it.subject },
    { p, v -> p.copy(subject = v) }
)

fun Feedback.Companion.message(): Lens<Feedback, String> = lensOf(
    "message",
    { it.message },
    { p, v -> p.copy(message = v) }
)
