package workspacetools.buildingeditor

import analytics.AnalyticsCategory
import analytics.AnalyticsService
import apiclient.FormationClient
import apiclient.files.createFile
import apiclient.geoobjects.BuildingAndFloorTags
import apiclient.geoobjects.BuildingDataSource
import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.LatLon
import apiclient.geoobjects.NewBuilding
import apiclient.geoobjects.NewFloor
import apiclient.geoobjects.UpdateBuildingDetails
import apiclient.geoobjects.UpdateImageFloorDetails
import apiclient.geoobjects.addImageFloorToBuilding
import apiclient.geoobjects.createBuilding
import apiclient.geoobjects.deleteBuildingAndFloors
import apiclient.geoobjects.deleteFloor
import apiclient.geoobjects.updateBuildingDetails
import apiclient.geoobjects.updateFloorDetails
import apiclient.tags.buildingId
import apiclient.tags.defaultFloorId
import apiclient.tags.externalIds
import apiclient.tags.floorLevel
import apiclient.tags.getUniqueTag
import apiclient.tags.setUniqueTag
import auth.CurrentWorkspaceStore
import dev.fritz2.core.RenderContext
import dev.fritz2.core.Store
import dev.fritz2.core.alt
import dev.fritz2.core.checked
import dev.fritz2.core.disabled
import dev.fritz2.core.lensOf
import dev.fritz2.core.placeholder
import dev.fritz2.core.src
import dev.fritz2.core.storeOf
import dev.fritz2.core.title
import dev.fritz2.core.type
import koin.koinCtx
import koin.withKoin
import kotlin.random.Random
import kotlin.random.nextULong
import kotlin.time.Duration.Companion.milliseconds
import kotlin.time.Duration.Companion.seconds
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.debounce
import kotlinx.coroutines.flow.filterNotNull
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.launch
import localization.TL
import localization.getTranslationFlow
import localization.translate
import maplibreGL.LngLat
import model.Building
import model.Floor
import model.replaceFloor
import overlays.withBusyApiClient
import theme.FormationIcons
import theme.FormationUIIcons
import twcomponents.SelectedFile
import twcomponents.dataUrl
import twcomponents.dimensions
import twcomponents.fileInput
import twcomponents.mimeType
import twcomponents.slider
import twcomponents.twButtonRow
import twcomponents.twColOf
import twcomponents.twInputField
import twcomponents.twInputTextField
import twcomponents.twModal
import twcomponents.twPrimaryButton
import twcomponents.twPrimaryButtonSmall
import twcomponents.twRowOfJustifyBetween
import twcomponents.twSecondaryButton
import webcomponents.inputLabelWrapper
import webcomponents.inputLabelWrapperForToolbar

enum class SideBarView {
    Hide,
    ShowBuildingsAndFloors,
}

/*
     FIXME gotchas:
     Deletes and orphaned content? What do we want to do here? Disallow?
     add more user friendly APIs to server
     updating the maps in the main app
     get better building store abstraction so I don't have to update multiple stores.
     prevent deleting last floor (delete building instead)
     UX:
        not enough room for floor selector & building selector
        confirm on delete (needs work on tw component restructuring)
        icons
 */

fun pickBestFloor(building: Building, selectedFloorStore: SelectedFloorStore): Floor? {
    return if (selectedFloorStore.current == null || building.buildingId != selectedFloorStore.current?.tags?.buildingId) {
        // update the selected floor with the best default for the selected building
        val buildingFloors = building.floorData?.values.orEmpty().flatten()
        val selectedFloor = if (building.defaultFloorId != null) {
            buildingFloors.firstOrNull {
                it.floor.id == building.defaultFloorId
            }
        } else {
            buildingFloors.minByOrNull {
                it.floor.tags.floorLevel ?: 0.0
            }
        }
        selectedFloor
    } else {
        null
    }
}

fun addBuildingDialog(show: Store<Boolean>, buildingEditorMapManager: BuildingEditorMapManager, buildingsStore: AllBuildingsStore) {
    twModal(show) { closeHandler, _, _ ->
        div("bg-white p-5 min-h-24 max-h-80% w-96 overflow-y-auto") {
            twColOf {
                h1 { translate(BuildingEditorTranslatables.AddABuilding) }

                val newBuildingNameStore = storeOf("")
                val newFloorNameStore = storeOf("")
                val newFloorLevelStore = storeOf("")
                val imageByteStore = storeOf<SelectedFile?>(null)

                inputLabelWrapper(
                    title = BuildingEditorTranslatables.BuildingName,
                ) {
                    twInputField(newBuildingNameStore) {
                        twInputTextField {
                            placeholder(flowOf("My Building"))
                        }
                    }
                }
                inputLabelWrapper(
                    title = BuildingEditorTranslatables.FloorName,
                ) {
                    twInputField(newFloorNameStore) {
                        twInputTextField {
                            placeholder(flowOf("First Floor"))
                        }
                    }
                }
                inputLabelWrapper(
                    title = BuildingEditorTranslatables.FloorLevel,
                ) {
                    twInputField(newFloorLevelStore) {
                        twInputTextField {
                            type("number")
                            placeholder(flowOf("42"))
                        }
                    }
                }

                imageByteStore.data.render { file ->
                    (file.takeIf { it != null }?.dataUrl)?.let {
                        img("h-24 w-min") {
                            src(it)
                            alt(BuildingEditorTranslatables.FloorPlanImage.getTranslationFlow())
                        }
                    }
                }


                label {
                    translate(BuildingEditorTranslatables.PickFloorPlanImage)
                    fileInput(imageByteStore)
                }
                p {
                    translate(BuildingEditorTranslatables.PickFloorPlanImageExplanation)
                }
                twButtonRow {
                    twSecondaryButton {
                        translate(TL.General.CANCEL)
                        clicks handledBy closeHandler
                    }
                    newBuildingNameStore.data.render { buildingName ->
                        newFloorNameStore.data.render { floorName ->
                            newFloorLevelStore.data.render { floorLevel ->
                                imageByteStore.data.render { file ->
                                    twPrimaryButton {
                                        translate(TL.General.CREATE)
                                        disabled(buildingName.isBlank() || floorLevel.isBlank() || file == null)

                                        clicks handledBy {
                                            val mapState = buildingEditorMapManager.buildingEditorMapStateStore.current ?: error("no position")
                                            withBusyApiClient(
                                                { client ->
                                                    client.createNewBuilding(
                                                        mapState.lngLat,
                                                        buildingName,
                                                        floorName.takeIf { it.isNotBlank() } ?: floorLevel,
                                                        floorLevel,
                                                        file ?: error("file should not be null here"),
                                                    )
                                                },
                                            ) { newBuilding ->
                                                val analyticsService = koinCtx.get<AnalyticsService>()
                                                analyticsService.createEvent(
                                                    AnalyticsCategory.BuildingEditor,
                                                ) {
                                                    recordAction("created-building", target = newBuilding.id)
                                                }

                                                // give enrichment a chance
                                                delay(5.seconds)
                                                buildingsStore.fetchBuildings()
                                                closeHandler.invoke(Unit)
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


fun RenderContext.buildingSelector(
    buildingsStore: AllBuildingsStore,
    buildingEditorMapManager: BuildingEditorMapManager,
    selectedFloorStore: SelectedFloorStore
) {
    val showAddBuildingDialogStore = storeOf(false)
    addBuildingDialog(showAddBuildingDialogStore, buildingEditorMapManager, buildingsStore)
    twRowOfJustifyBetween {
        h3("text-lg font-semibold mb-2") {
            translate(BuildingEditorTranslatables.Buildings)
        }
        twPrimaryButtonSmall(icon = FormationIcons.Add) {
            title(BuildingEditorTranslatables.AddABuilding.getTranslationFlow())
            clicks handledBy {
                showAddBuildingDialogStore.update(true)
            }
        }
    }

    ul("space-y-2") {
        buildingsStore.selectedBuildingStore.data.render { currentBuilding ->
            buildingsStore.data.filterNotNull().render { buildings ->
                val currentFloor = selectedFloorStore.current
                console.log("buildings: ${buildings.size}")
                buildings
                    .sortedBy { building -> building.buildingName?.lowercase() ?: building.buildingId }
                    .forEach { building ->
                        if (building.buildingId == currentBuilding?.buildingId) {
                            // make sure the building is update if buildingsStore is updated
                            buildingsStore.selectedBuildingStore.update(building)
                            if (currentFloor != null) {
                                val newFloor = building.floorData?.values?.flatten()?.firstOrNull { it.floor.id == currentFloor.id }
                                selectedFloorStore.update(newFloor?.floor)
                            }
                            li("font-bold") {
                                +(building.buildingName ?: building.buildingId)
                            }

                        } else {
                            li {
                                +(building.buildingName ?: building.buildingId)
                                clicks handledBy {
                                    buildingsStore.selectedBuildingStore.update(building)
                                    pickBestFloor(building, selectedFloorStore)?.let { floor ->
                                        selectedFloorStore.setFloor(floor.floor)
                                    }
                                }
                            }
                        }
                    }
            }
        }
    }
}


fun addFloorDialog(show: Store<Boolean>, selectedFloorStore: SelectedFloorStore, buildingsStore: AllBuildingsStore) {
    twModal(show) { closeHandler, _, _ ->
        div("bg-white p-5 min-h-24 max-h-80% w-96 overflow-y-auto") {
            twColOf {
                selectedFloorStore.data.filterNotNull().render { currentFloor ->
                    val level = currentFloor.tags.floorLevel?.let { it + 1 }?.toString() ?: "99"
                    val newFloorNameStore = storeOf("Floor $level")
                    val newFloorLevelStore = storeOf(level)
                    val imageByteStore = storeOf<SelectedFile?>(null)

                    h1 {
                        translate(BuildingEditorTranslatables.AddAFloor)
                    }
                    inputLabelWrapper(
                        title = BuildingEditorTranslatables.FloorName,

                    ) {
                        twInputField(newFloorNameStore) {
                            twInputTextField {
                                placeholder(BuildingEditorTranslatables.FirstFloor.getTranslationFlow())
                            }
                        }
                    }
                    inputLabelWrapper(
                        title = BuildingEditorTranslatables.FloorLevel,
                    ) {
                        twInputField(newFloorLevelStore) {
                            twInputTextField {
                                type("number")
                                placeholder(flowOf("42"))
                            }
                        }
                    }
                    imageByteStore.data.render { file ->
                        (file.takeIf { it != null }?.dataUrl)?.let {
                            img("h-24 w-min") {
                                src(it)
                                alt(BuildingEditorTranslatables.FloorPlanImage.getTranslationFlow())
                            }
                        }
                    }


                    label {
                        translate(BuildingEditorTranslatables.PickFloorPlanImage)
                        fileInput(imageByteStore)
                    }


                    p {
                        translate(BuildingEditorTranslatables.AddAFloorExplanation)
                    }
                    twButtonRow {
                        twSecondaryButton {
                            translate(TL.General.CANCEL)
                            clicks handledBy closeHandler
                        }
                        imageByteStore.data.render { file ->

                            twPrimaryButton {
                                translate(BuildingEditorTranslatables.AddAFloor)
                                disabled(file == null)
                                clicks handledBy {
                                    withKoin {
                                        val currentWorkspaceStore = get<CurrentWorkspaceStore>()

                                        withBusyApiClient(
                                            { client ->
                                                val created = client.createFile(currentWorkspaceStore.groupId, file!!.content, file.mimeType).getOrThrow()
                                                val (width, height) = file.dimensions()

                                                currentFloor.latLon
                                                val loc =
                                                    initialImageLocation(
                                                        currentFloor.latLon,
                                                        width,
                                                        height,
                                                        // ballpark appropriate for Ahoy sized buildings
                                                        25.0,
                                                    )

                                                client.addImageFloorToBuilding(
                                                    currentWorkspaceStore.groupId,
                                                    currentFloor.tags.buildingId ?: error("current floor should have a buildingId"),
                                                    NewFloor.ImageFloor(
                                                        title = newFloorNameStore.current,
                                                        level = newFloorLevelStore.current.toDoubleOrNull() ?: 0.0,
                                                        imageUrl = created.url,
                                                        topLeft = loc.topLeft,
                                                        topRight = loc.topRight,
                                                        bottomRight = loc.bottomRight,
                                                        bottomLeft = loc.bottomLeft,
                                                    ),
                                                )
                                            },
                                        ) { newFloor ->
                                            // give enrichment a chance
                                            val analyticsService= koinCtx.get<AnalyticsService>()
                                            analyticsService.createEvent(
                                                AnalyticsCategory.BuildingEditor,
                                            ) {
                                                recordAction("add-floor", target = currentFloor.tags.buildingId)
                                            }
                                            delay(5.seconds)

                                            buildingsStore.fetchBuildings()
                                            closeHandler.invoke(Unit)
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


fun RenderContext.floorSelector(selectedFloorStore: SelectedFloorStore, buildingsStore: AllBuildingsStore) {
    val showFloorAddDialog = storeOf(false)
    addFloorDialog(showFloorAddDialog, selectedFloorStore, buildingsStore)
    // Floor selector
    twRowOfJustifyBetween {
        h3("text-lg font-semibold mt-4 mb-2") { +"Floors" }
        twPrimaryButtonSmall(icon = FormationUIIcons.Add) {
            title(BuildingEditorTranslatables.AddAFloor.getTranslationFlow())

            clicks handledBy {
                showFloorAddDialog.update(true)
            }
        }
    }

    ul("space-y-2") {
        buildingsStore.selectedBuildingStore.data.render { building ->
            if(building != null) {
                selectedFloorStore.data.render { currentFloor ->
                    building.floorData?.entries
                        ?.sortedBy { (level, _) -> level }
                        ?.forEach { (level, floors) ->
                            floors.sortedBy { it.floor.title.lowercase() }.forEach { floor ->
                                if (floor.floor.id == currentFloor?.id) {
                                    li("font-bold") {
                                        +"$level ${
                                            floor.floor.title.takeIf { it.isNotBlank() }.let {
                                                " - $it"
                                            }
                                        }"
                                    }
                                } else {
                                    li {
                                        +"$level ${
                                            floor.floor.title.takeIf { it.isNotBlank() }.let {
                                                " - $it"
                                            }
                                        }"
                                        clicks handledBy {
                                            selectedFloorStore.setFloor(floor.floor)
                                        }
                                    }
                                }
                            }
                        }
                }
            } else {
                console.log("current building null")
            }
        }
    }
}

fun RenderContext.buildingAndFloorEditor(
    buildingTitleStore: Store<String>,
    floorTitleStore: Store<String>,
    floorLevelStore: Store<String>,
    defaultFloorIdStore: Store<String?>,
    buildingsStore: AllBuildingsStore,
    selectedFloorStore: SelectedFloorStore,
    imageRotationAndScaleStore: ImageRotationAndScaleStore
) {
    twColOf {
        selectedFloorStore.data.render { floor ->

            if (floor == null) {
                p {
                    translate(BuildingEditorTranslatables.SelectAFloor)
                }
                // make sure the map is cleared
                imageRotationAndScaleStore.update(null)
            } else {
                inputLabelWrapper(
                    title = BuildingEditorTranslatables.BuildingName
                ) {
                    twInputField(buildingTitleStore) {
                        twInputTextField {
                            placeholder(BuildingEditorTranslatables.MyBuilding.getTranslationFlow())
                        }
                    }
                }
                inputLabelWrapper(
                    title = BuildingEditorTranslatables.FloorName,
                ) {
                    twInputField(floorTitleStore) {
                        twInputTextField {
                            placeholder(BuildingEditorTranslatables.FirstFloor.getTranslationFlow())
                        }
                    }
                }
                inputLabelWrapper(
                    title = BuildingEditorTranslatables.FloorLevel,
                ) {
                    twInputField(floorLevelStore) {
                        twInputTextField {
                            type("number")
                            placeholder(flowOf("42"))
                        }
                    }
                }
                buildingsStore.selectedBuildingStore.data.filterNotNull().render { selectedBuilding ->
                    selectedFloorStore.data.filterNotNull().render { selectedFloor ->
                        val useAsDefaultFloor = storeOf(selectedBuilding.defaultFloorId == selectedFloor.id)
                        useAsDefaultFloor.data handledBy { useAsDefault ->
                            if (useAsDefault) {
                                defaultFloorIdStore.update(selectedFloor.id)
                            }
                        }
                        inputLabelWrapper(BuildingEditorTranslatables.UseAsDefaultFloor) {
                            input("display: block") {
                                type("checkbox")
                                checked(useAsDefaultFloor.data)

                                clicks handledBy {
                                    useAsDefaultFloor.update(!useAsDefaultFloor.current)
                                }
                            }
                        }
                    }
                }
                floorImageEditor(
                    buildingsStore,
                    selectedFloorStore,
                    imageRotationAndScaleStore,
                    floorLevelStore,
                    floorTitleStore,
                )
                twPrimaryButton {
                    translate(BuildingEditorTranslatables.DeleteFloor)
                    clicks handledBy {
                        withBusyApiClient(
                            {
                                it.deleteFloor(floor.id)
                            },
                        ) {
                            val analyticsService= koinCtx.get<AnalyticsService>()
                            analyticsService.createEvent(
                                AnalyticsCategory.BuildingEditor,
                            ) {
                                recordAction("deleted-floor", target = floor.id)
                            }

                            // give enrichment a chance
                            delay(5.seconds)
                            selectedFloorStore.setFloor(null)
                            buildingsStore.fetchBuildings()
                        }
                    }
                }
            }


            buildingsStore.selectedBuildingStore.data.render { currentBuilding ->
                twPrimaryButton {
                    translate(BuildingEditorTranslatables.DeleteBuilding)
                    disabled(currentBuilding == null)
                    clicks handledBy {
                        withBusyApiClient(
                            {
                                it.deleteBuildingAndFloors(currentBuilding!!.buildingId)
                            },
                        ) {
                            val analyticsService= koinCtx.get<AnalyticsService>()
                            analyticsService.createEvent(
                                AnalyticsCategory.BuildingEditor,
                            ) {
                                recordAction("delete-building", target = currentBuilding!!.buildingId)
                            }

                            // give enrichment a chance
                            delay(5.seconds)
                            buildingsStore.selectedBuildingStore.update(null)
                            selectedFloorStore.setFloor(null)

                            buildingsStore.fetchBuildings()
                        }
                    }
                }
            }

        }
    }
}


fun RenderContext.buildingsAndFloorEditorScreen() {
    withKoin {
        val buildingsStore = AllBuildingsStore()
        val angleStore = storeOf("0.0")
        val pixelsPerMeterStore = storeOf("0.0")
        val buildingEditorMapStateStore = BuildingEditorMapStateStore()
        val showSideBarStore = storeOf(SideBarView.ShowBuildingsAndFloors)
        val imageRotationAndScaleStore = ImageRotationAndScaleStore(
            buildingEditorMapStateStore = buildingEditorMapStateStore,
            angleStore = angleStore,
            pixelsPerMeterStore = pixelsPerMeterStore,
        )
        val opacityStore = storeOf<Number>(0.5)
        val buildingEditorMapManager = BuildingEditorMapManager(
            buildingEditorMapStateStore = buildingEditorMapStateStore,
            imagePositionStore = imageRotationAndScaleStore,
        )
        imageRotationAndScaleStore.data.debounce(1.milliseconds) handledBy { pos ->
            // debounce to avoid getting a lot of abort exceptions in the log
            // setting null will also clean up the map
            buildingEditorMapManager.updateFloorImage(pos)
        }
        opacityStore.data.debounce(1.milliseconds) handledBy { opacity ->
            // debounce to avoid getting a lot of abort exceptions in the log
            buildingEditorMapManager.setLayerOpacityForFloor(opacity)
        }

        val selectedFloorStore = SelectedFloorStore(buildingEditorMapManager, imageRotationAndScaleStore)

        MainScope().launch {
            // kickoff search when the component loads
            buildingsStore.fetchBuildings()
            // store update take some time to propagate, yay async stuff
            delay(50.milliseconds)
            if (buildingsStore.selectedBuildingStore.current == null) {
                buildingsStore.current?.sortedBy { it.buildingName }?.firstOrNull()?.let { firstBuilding ->

                    buildingsStore.selectedBuildingStore.update(firstBuilding)
                    pickBestFloor(firstBuilding, selectedFloorStore)?.floor?.let { floor ->
                        selectedFloorStore.setFloor(floor)
                    }
                }
            }
        }

        val buildingUpdateStore = storeOf<GeoObjectDetails?>(null)
        // maintain a copy for editing to prevent flickering
        buildingsStore.selectedBuildingStore.data handledBy {
            buildingUpdateStore.update(it?.geoObjectDetails)
        }
        val floorUpdateStore = storeOf<GeoObjectDetails?>(null)
        // maintain a copy for editing to prevent flickering
        selectedFloorStore.data handledBy {
            floorUpdateStore.update(it)
        }

        floorUpdateStore.data handledBy imageRotationAndScaleStore.floorUpdatedHandler

        val floorTitleStore = floorUpdateStore.map(lensOf("title-${Random.nextULong()}", { it?.title ?: "" }, { o, v -> o?.copy(title = v) }))
        val floorLevelStore = floorUpdateStore.map(
            lensOf(
                "level-${Random.nextULong()}",
                { (it?.tags?.getUniqueTag(BuildingAndFloorTags.FloorLevel) ?: "-") },
                { o, v ->
                    console.log("UPDATING LVL '$v'")
                    o?.copy(tags = o.tags.setUniqueTag(BuildingAndFloorTags.FloorLevel, v))
                },
            ),
        )
        val buildingTitleStore = buildingUpdateStore.map(
            lensOf(
                "buildingTitle-${Random.nextULong()}",
                {
                    it?.title ?: ""
                },
                { o, v ->
                    o?.copy(title = v)
                },
            ),
        )
        val defaultFloorIdStore = buildingUpdateStore.map(
            lensOf(
                "defaultFloorId-${Random.nextULong()}",
                {
                    it?.tags?.defaultFloorId
                },
                { o, v ->
                    o?.let { b ->
                        v?.let { fid ->
                            b.copy(
                                tags = b.tags.setUniqueTag(BuildingAndFloorTags.DefaultFloorId, fid),
                            )
                        }
                    }
                },
            ),
        )

        div("flex h-full") {
            // Sidebar with building and floor selectors
            showSideBarStore.data.render { sideBarView ->
                if (sideBarView != SideBarView.Hide) {
                    custom("sidebar", "flex flex-col w-72 bg-gray-200 p-4 overflow-y-auto") {
                        when (sideBarView) {
                            SideBarView.ShowBuildingsAndFloors -> {
                                buildingSelector(buildingsStore, buildingEditorMapManager, selectedFloorStore)
                                floorSelector(selectedFloorStore, buildingsStore)
                                buildingAndFloorEditor(
                                    buildingTitleStore = buildingTitleStore,
                                    floorTitleStore = floorTitleStore,
                                    floorLevelStore = floorLevelStore,
                                    defaultFloorIdStore = defaultFloorIdStore,
                                    buildingsStore = buildingsStore,
                                    selectedFloorStore = selectedFloorStore,
                                    imageRotationAndScaleStore = imageRotationAndScaleStore,
                                )
                            }

                            else -> p {
                                +"Unsupported view $sideBarView"
                            }
                        }
                    }
                }
            }

            // Main content area for map and toolbar
            div("flex flex-col flex-grow bg-gray-100 relative") {
                // Toolbar at the top
                div("bg-white p-3 flex flex-row gap-3 place-items-center") {
                    showSideBarStore.data.render { sideBarView ->
                        when (sideBarView) {
                            SideBarView.ShowBuildingsAndFloors, SideBarView.Hide -> {
                                // Toggle button for sidebar collapsibility (logic to be added later)
                                twSecondaryButton {
                                    translate(BuildingEditorTranslatables.ToggleSideBar)

                                    disabled(showSideBarStore.data.map { it !in setOf(SideBarView.Hide, SideBarView.ShowBuildingsAndFloors) })
                                    clicks handledBy {
                                        val newView = when (showSideBarStore.current) {
                                            SideBarView.Hide -> SideBarView.ShowBuildingsAndFloors
                                            SideBarView.ShowBuildingsAndFloors -> SideBarView.Hide
                                        }
                                        showSideBarStore.update(newView)
                                    }
                                }

                                twSecondaryButton {
                                    translate(BuildingEditorTranslatables.FetchBuildings)

                                    disabled(buildingsStore.fetching.data)
                                    clicks handledBy {
                                        withBusyApiClient(
                                            {
                                                buildingsStore.fetchBuildings()
                                                Result.success(true)
                                            },
                                        )
                                    }
                                }

                                inputLabelWrapperForToolbar(BuildingEditorTranslatables.Opacity) {
                                    slider(opacityStore, 0.0, 1.0)
                                }

                                buildingsStore.selectedBuildingStore.data.filterNotNull().render { selectedBuilding ->
                                    selectedFloorStore.data.filterNotNull().render { selectedFloor ->
                                        twPrimaryButton(icon = FormationUIIcons.Save) {
                                            imageRotationAndScaleStore.data.render { rotation ->
                                                disabled(rotation == null)
                                            }
                                            clicks handledBy {
                                                saveBuildingAndFloor(
                                                    imageRotationAndScaleStore = imageRotationAndScaleStore,
                                                    floorLevelStore = floorLevelStore,
                                                    floorTitleStore = floorTitleStore,
                                                    buildingTitleStore = buildingTitleStore,
                                                    defaultFloorIdStore = defaultFloorIdStore,
                                                    allBuildingsStore = buildingsStore,
                                                    selectedFloorStore = selectedFloorStore,
                                                    selectedBuilding = selectedBuilding,
                                                    selectedFloor = selectedFloor,
                                                )
                                            }
                                        }
                                    }
                                }
                            }
                        }

                    }
                }

                // Map area
                div("flex-grow bg-gray-400 relative", id = buildingEditorMapManager.mapContainerId) {
                    +"Loading map"
                }

            }
        }
        val (imageCenter, zoomLevel) =
            selectedFloorStore.current?.toImageLocation()?.centroid?.let {
                it to 17.0
            } ?: withKoin {
                val mst = get<map.MapStateStore>()
                // if we're doing null island, at least zoom out a bit
                (mst.current?.let { currentMapState -> currentMapState.center to currentMapState.zoom } ?: (LatLon(0.0, 0.0) to 5.0))
            }

        buildingEditorMapManager.init(imageCenter, zoomLevel)
    }
}

fun RenderContext.floorImageEditor(
    buildingsStore: AllBuildingsStore,
    selectedFloorStore: SelectedFloorStore,
    imageRotationAndScaleStore: ImageRotationAndScaleStore,
    floorLevelStore: Store<String>,
    floorTitleStore: Store<String>,
) {
    selectedFloorStore.data.filterNotNull().render { selectedFloor ->

        val imageByteStore = storeOf<SelectedFile?>(null)
        imageByteStore.data.render { file ->
            selectedFloor.tags.getUniqueTag(BuildingAndFloorTags.FloorPlanImageUrl).let { imageUrl ->
                (file.takeIf { it != null }?.dataUrl ?: imageUrl)?.let {
                    img("h-24 w-min") {
                        src(it)
                        alt(selectedFloor.title)
                    }
                }
            }
        }
        label {
            translate(BuildingEditorTranslatables.PickFloorPlanImage)
            fileInput(imageByteStore)
        }
        imageByteStore.data.render { bytes ->
            twPrimaryButton {
                translate(BuildingEditorTranslatables.ReplaceImage)
                disabled(bytes == null)
                clicks handledBy {
                    withKoin {
                        val currentWorkspaceStore = get<CurrentWorkspaceStore>()
                        val groupId = currentWorkspaceStore.current?.groupId ?: error("current group cannot be null here")
                        val building = buildingsStore.selectedBuildingStore.current ?: error("building was null")

                        withBusyApiClient(
                            { client ->
                                val file = bytes ?: error("selected file should not be null")
                                val created = client.createFile(groupId, file.content, file.mimeType).getOrThrow()
                                val rotationAndScale = imageRotationAndScaleStore.current ?: error("no rotation")

                                val title = floorTitleStore.current
                                client.updateFloorDetails(
                                    UpdateImageFloorDetails(
                                        buildingId = building.buildingId,
                                        floorId = selectedFloor.id,
                                        topLeft = rotationAndScale.imageLocation.topLeft,
                                        topRight = rotationAndScale.imageLocation.topRight,
                                        bottomRight = rotationAndScale.imageLocation.bottomRight,
                                        bottomLeft = rotationAndScale.imageLocation.bottomLeft,
                                        level = floorLevelStore.current.toDoubleOrNull() ?: -100.0,
                                        externalFloorId = selectedFloor.tags.externalIds.firstOrNull(),
                                        buildingDataSource = selectedFloor.tags.getUniqueTag(BuildingAndFloorTags.BuildingDataSource)
                                            ?.let { BuildingDataSource.valueOf(it) }
                                            ?: BuildingDataSource.FormationAdminTool,
                                        imageUrl = created.url,
                                        title = title,
                                        center = rotationAndScale.imageLocation.centroid,
                                    ),
                                )
                            },
                        ) { updatedFloor ->
                            val fixedBuilding = building.copy(
                                floorData = building.floorData.replaceFloor(updatedFloor),
                            )
                            val analyticsService= koinCtx.get<AnalyticsService>()
                            analyticsService.createEvent(
                                AnalyticsCategory.BuildingEditor,
                            ) {
                                recordAction("replace-image", target = updatedFloor.id)
                            }

                            buildingsStore.update(
                                buildingsStore.current?.map {
                                    if (it.buildingId == fixedBuilding.buildingId) fixedBuilding else it
                                },
                            )
                            buildingsStore.selectedBuildingStore.update(
                                fixedBuilding,
                            )
                            selectedFloorStore.setFloor(updatedFloor)
                        }
                    }
                }
            }
        }
    }
}

suspend fun saveBuildingAndFloor(
    imageRotationAndScaleStore: ImageRotationAndScaleStore,
    floorLevelStore: Store<String>,
    floorTitleStore: Store<String>,
    buildingTitleStore: Store<String>,
    defaultFloorIdStore: Store<String?>,
    allBuildingsStore: AllBuildingsStore,
    selectedFloorStore: SelectedFloorStore,
    selectedBuilding: Building,
    selectedFloor: GeoObjectDetails
) {
    withKoin {
        val client = get<FormationClient>()
        val analyticsService = get<AnalyticsService>()

        imageRotationAndScaleStore.current?.let { rotationAndScale ->

            withBusyApiClient(
                {
                    val newFLoor = client.updateFloorDetails(
                        UpdateImageFloorDetails(
                            buildingId = selectedBuilding.buildingId,
                            floorId = selectedFloor.id,
                            topLeft = rotationAndScale.imageLocation.topLeft,
                            topRight = rotationAndScale.imageLocation.topRight,
                            bottomRight = rotationAndScale.imageLocation.bottomRight,
                            bottomLeft = rotationAndScale.imageLocation.bottomLeft,
                            level = floorLevelStore.current.toDoubleOrNull() ?: -100.0,
                            externalFloorId = selectedFloor.tags.externalIds.firstOrNull(),
                            buildingDataSource = selectedFloor.tags.getUniqueTag(BuildingAndFloorTags.BuildingDataSource)
                                ?.let { BuildingDataSource.valueOf(it) }
                                ?: BuildingDataSource.FormationAdminTool,
                            imageUrl = selectedFloor.tags.getUniqueTag(BuildingAndFloorTags.FloorPlanImageUrl),
                            title = floorTitleStore.current,
                            center = rotationAndScale.imageLocation.centroid,
                        ),
                    ).getOrThrow()

                    val floorCenters = selectedBuilding.floorData?.entries?.map { it.value }?.flatten()?.let { floors ->
                        floors.map {
                            if (it.floor.id == selectedFloor.id) {
                                rotationAndScale.imageLocation.centroid
                            } else {
                                it.floor.latLon
                            }
                        }
                    } ?: listOf(rotationAndScale.imageLocation.centroid)

                    val avgLat = floorCenters.sumOf { it.lat } / floorCenters.size
                    val avgLon = floorCenters.sumOf { it.lon } / floorCenters.size

                    val buildingCenter = LatLon(lon = avgLon, lat = avgLat)
                    val newBuilding = client.updateBuildingDetails(
                        UpdateBuildingDetails(
                            buildingId = selectedBuilding.buildingId,
                            title = buildingTitleStore.current,
                            buildingDataSource = selectedBuilding.geoObjectDetails.tags.getUniqueTag(
                                BuildingAndFloorTags.BuildingDataSource,
                            )
                                ?.let { BuildingDataSource.valueOf(it) }
                                ?: BuildingDataSource.FormationAdminTool,
                            externalId = selectedBuilding.geoObjectDetails.tags.externalIds.firstOrNull(),
                            center = buildingCenter,
                            defaultFloorId = defaultFloorIdStore.current,
                        ),
                    ).getOrThrow()
                    Result.success(newBuilding to newFLoor)
                },
            ) { (updatedBuilding, updatedFloor) ->
                analyticsService.createEvent(
                    AnalyticsCategory.BuildingEditor,
                ) {
                    recordAction("save-building-and-floor", target = updatedFloor.id)
                }

                val oldBuilding = allBuildingsStore.selectedBuildingStore.current
                val fixedBuilding = Building(
                    buildingId = updatedBuilding.id,
                    buildingName = updatedBuilding.title,
                    defaultFloorLevel = updatedBuilding.tags.floorLevel,
                    defaultFloorId = updatedBuilding.tags.defaultFloorId,
                    activeFloorLevel = oldBuilding?.activeFloorLevel,
                    activeFloorIds = oldBuilding?.activeFloorIds,
                    floorData = oldBuilding?.floorData.replaceFloor(updatedFloor),
                    geoObjectDetails = updatedBuilding,
                )
                allBuildingsStore.selectedBuildingStore.update(
                    fixedBuilding,
                )
                allBuildingsStore.update(
                    allBuildingsStore.current?.map {
                        if (it.buildingId == fixedBuilding.buildingId) fixedBuilding else it
                    },
                )

                selectedFloorStore.setFloor(updatedFloor)
            }
        }
    }
}

suspend fun FormationClient.createNewBuilding(
    position: LngLat,
    buildingName: String,
    floorName: String,
    floorLevel: String,
    file: SelectedFile
): Result<GeoObjectDetails> {
    return withKoin {
        val currentWorkspaceStore = get<CurrentWorkspaceStore>()
        val (width, height) = file.dimensions()
        val groupId = currentWorkspaceStore.current?.groupId ?: error("no group found")
        val createdFile = createFile(groupId, file.content, file.mimeType).getOrThrow()
        val loc =
            initialImageLocation(
                LatLon(position.lat, position.lng),
                width,
                height,
                // ballpark appropriate for Ahoy sized buildings
                25.0,
            )
        createBuilding(
            groupId,
            NewBuilding(
                title = buildingName,
                floors =
                listOf(
                    NewFloor.ImageFloor(
                        title = floorName,
                        level = floorLevel.toDoubleOrNull() ?: 42.0,
                        topRight = loc.topRight,
                        topLeft = loc.topLeft,
                        bottomLeft = loc.bottomLeft,
                        bottomRight = loc.bottomRight,
                        imageUrl = createdFile.url,
                    ),
                ),
                buildingDataSource = BuildingDataSource.Custom,
                externalId = null,
                center = loc.centroid,
            ),
        )
    }
}


