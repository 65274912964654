// GENERATED by fritz2 - NEVER CHANGE CONTENT MANUALLY!
package model

import dev.fritz2.core.Lens
import dev.fritz2.core.lensOf

fun ImageFileData.Companion.id(): Lens<ImageFileData, String?> = lensOf(
    "id",
    { it.id },
    { p, v -> p.copy(id = v) }
)

fun ImageFileData.Companion.prevName(): Lens<ImageFileData, String> = lensOf(
    "prevName",
    { it.prevName },
    { p, v -> p.copy(prevName = v) }
)

fun ImageFileData.Companion.mimeType(): Lens<ImageFileData, String?> = lensOf(
    "mimeType",
    { it.mimeType },
    { p, v -> p.copy(mimeType = v) }
)

fun ImageFileData.Companion.prevBytes(): Lens<ImageFileData, ByteArray?> = lensOf(
    "prevBytes",
    { it.prevBytes },
    { p, v -> p.copy(prevBytes = v) }
)

fun ImageFileData.Companion.href(): Lens<ImageFileData, String?> = lensOf(
    "href",
    { it.href },
    { p, v -> p.copy(href = v) }
)

fun ImageFileData.Companion.width(): Lens<ImageFileData, Int?> = lensOf(
    "width",
    { it.width },
    { p, v -> p.copy(width = v) }
)

fun ImageFileData.Companion.height(): Lens<ImageFileData, Int?> = lensOf(
    "height",
    { it.height },
    { p, v -> p.copy(height = v) }
)

fun ImageFileData.Companion.title(): Lens<ImageFileData, String> = lensOf(
    "title",
    { it.title },
    { p, v -> p.copy(title = v) }
)
