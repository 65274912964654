package data.users.profile

import auth.FeatureFlagStore
import auth.Features
import apiclient.FormationClient
import apiclient.auth.Token
import apiclient.users.getAccessControlToken
import dev.fritz2.core.RootStore
import dev.fritz2.core.invoke
import dev.fritz2.routing.MapRouter
import koin.koinCtx
import kotlinx.coroutines.CoroutineName
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.cancel
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import mainmenu.Pages
import mainmenu.isBasePage
import overlays.BusyStore
import utils.isExpired

class VerificationTokenStore : RootStore<Token?>(
    initialData = null,
    job = Job(),
) {
    val formationClient: FormationClient by koinCtx.inject()
    val router: MapRouter by koinCtx.inject()
    val featureFlagStore: FeatureFlagStore by koinCtx.inject()
    val busyStore: BusyStore by koinCtx.inject()

    private var autoRefreshJob: Job? = null

    private val getAccessControlToken = handle { current ->
        busyStore.handleApiCall(
            supplier = suspend {
                formationClient.getAccessControlToken()
            },
            processResult = { token ->
                update(token)
                console.log("Fetched new verification token.")
            },
            processError = { error ->
                console.log("Fetching access control token failed.", error.message)
            },
        )
        current
    }

    private val autoRefreshOrCancel = handle<Token?> { _, token ->
        if (token != null) {
            autoRefreshJob?.cancel("Stop auto-refresh of verification token to start a new one.")
            val newJob = CoroutineScope(CoroutineName("verification-auto-refresh")).launch {
                console.log("Start auto-refresh of verification token.")
                while (!token.isExpired) {
                    delay(1000)
                }
                console.log("Verification token expired, get new one.")
                getAccessControlToken()
            }
            autoRefreshJob = newJob
            token
        } else {
            console.log("Stop auto-refresh of verification token.")
            autoRefreshJob?.cancel("Stop auto-refresh of verification token.")
            autoRefreshJob = null
            null
        }
    }

    private val fetchOrResetToken = handle<Map<String, String>> { token, route ->
        when {
            route.isBasePage(Pages.MyProfile) -> {
                if (featureFlagStore.current[Features.AllowQRVerification] == true) {
                    getAccessControlToken()
                    token
                } else {
                    console.log("Experimental feature blocked: Verification QR code")
                    null
                }
            }

            else -> null
        }
    }

    init {
        getAccessControlToken()
        router.data handledBy fetchOrResetToken
        data handledBy autoRefreshOrCancel
    }
}
