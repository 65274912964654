package utils

import kotlin.js.Console

fun Console.debug(arg0: Any?) {
    asDynamic().debug(arg0)
}

fun Console.debug(arg0: Any?, arg1: Any?) {
    asDynamic().debug(arg0, arg1)
}

fun Console.debug(arg0: Any?, arg1: Any?, arg2: Any?) {
    asDynamic().debug(arg0, arg1, arg2)
}
fun Console.debug(arg0: Any?, arg1: Any?, arg2: Any?, arg3: Any?) {
    asDynamic().debug(arg0, arg1, arg2, arg3)
}
