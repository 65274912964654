package search

import analyticsdashboard.AnalyticSidePage
import analyticsdashboard.AnalyticsSidePageStore
import analyticsdashboard.PathActiveHighlightedObjectStore
import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.LatLon
import apiclient.geoobjects.ObjectType
import data.objects.objecthistory.DisplayedPathObjectResultsStore
import data.objects.objecthistory.ObjectHistoryResultsStore
import data.objects.objecthistory.ShowObjectHistoryPathStore
import dev.fritz2.core.RootStore
import dev.fritz2.core.SimpleHandler
import koin.koinCtx
import kotlin.js.json
import kotlinx.browser.document
import kotlinx.coroutines.Job
import maplibreGL.MaplibreMap

class PathActiveHighlightedObjectMarkersStore : RootStore<Set<GeoObjectDetails>>(
    initialData = emptySet(),
    job = Job(),
) {

    val maplibreMap: MaplibreMap by koinCtx.inject()
    private val objectHistoryResultsStore: ObjectHistoryResultsStore by koinCtx.inject()
    private val pathActiveHighlightedObjectStore: PathActiveHighlightedObjectStore by koinCtx.inject()
    private val showObjectHistoryPathStore: ShowObjectHistoryPathStore by koinCtx.inject()
    private val analyticsSidePageStore: AnalyticsSidePageStore by koinCtx.inject()
    private val displayedPathObjectResultsStore: DisplayedPathObjectResultsStore by koinCtx.inject()

    val initialize = handle {
        maplibreMap.clearClustersFromMap()
        showObjectHistoryPathStore.update(false)
        maplibreMap.setPathToolHighlightOverrides(emptySet())
        emptySet()
    }

    val refresh = handle { current ->
        maplibreMap.clearClustersFromMap()
        showObjectHistoryPathStore.update(true)
        val currentHistoryEventIds = objectHistoryResultsStore.current.map { (_, objAndResults) -> objAndResults.results.map { it.hit.id } }.flatten().toSet()
        maplibreMap.setPathToolHighlightOverrides((current.map { it.id } + currentHistoryEventIds).toSet()) // do not lose currently visible and clickable history event markers
        current
    }

    val selectTrackedObject = handle<String> { current, selectedId ->
        if (!displayedPathObjectResultsStore.current.keys.contains(selectedId)) {
            analyticsSidePageStore.update(AnalyticSidePage.PathsSearch)
        }

        current.firstOrNull { (it.id == selectedId) }?.let { obj ->
            maplibreMap.removeAllActiveObjectOverrides()
            maplibreMap.addActiveObjectOverride(obj)
            maplibreMap.panTo(center = LatLon(obj.latLon.lat, obj.latLon.lon))
            pathActiveHighlightedObjectStore.update(selectedId)
        }
        val scrollOptions = json(
            "behavior" to "smooth",
            "block" to "center",
//                "inline" to "nearest"
        )
        document.getElementById("${selectedId}-selectable-list-entry-button")?.scrollIntoView(scrollOptions)
        current
    }

    private val updateMap = SimpleHandler<Set<GeoObjectDetails>> { data, _ ->
        data handledBy { newHighlighted ->
            console.log("HIGHLIGHTED OBJECT MARKER DATA", newHighlighted.filter { it.objectType == ObjectType.ObjectMarker }.map { it.title }.toString())
            val currentHistoryEventIds =
                objectHistoryResultsStore.current.map { (_, objAndResults) -> objAndResults.results.map { it.hit.id } }.flatten().toSet()
            maplibreMap.setPathToolHighlightOverrides((newHighlighted.map { it.id } + currentHistoryEventIds).toSet()) // do not lose currently visible and clickable history event markers
            maplibreMap.clearClustersFromMap()
        }
    }

    init {
        data handledBy updateMap
    }
}
