package data.heatmaplayer

import apiclient.groups.FilterCategory
import apiclient.groups.LayerMetaData
import apiclient.groups.LayerType
import apiclient.geoobjects.MarkerColor
import apiclient.geoobjects.MarkerIcon
import apiclient.geoobjects.MarkerShape
import dev.fritz2.core.RootStore
import kotlinx.coroutines.Job
import model.defaultOn

class ActiveHeatmapLayerMetaDataStore : RootStore<LayerMetaData>(
    initialData = emptyLayerMetaData,
    job = Job(),
) {

    var initialState = emptyLayerMetaData

    val deflaultOnSub = map(LayerMetaData.defaultOn())

    companion object {
        val emptyLayerMetaData = LayerMetaData(
            layerType = LayerType.Heatmap,
            id = "",
            title = "",
            translatable = true,
            defaultIconCategory = MarkerIcon.Heatmap,
            defaultColor = MarkerColor.Grey,
            defaultShape = MarkerShape.Trapezoid,
            filterCategory = FilterCategory.Things,
            defaultOn = false
        )
    }

    val reset = handle {
        initialState = emptyLayerMetaData
        emptyLayerMetaData
    }

    fun hasChanged(): Boolean {
        return current != initialState
    }
}
