package overlays

import dev.fritz2.core.invoke
import kotlinx.coroutines.CoroutineName
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import model.Overlay
import model.OverlayState
import kotlin.coroutines.EmptyCoroutineContext
import kotlin.time.Duration.Companion.seconds

class TickerOverlayStore : OverlayStore(initialData = OverlayState()) {

    override val reset = handle { current ->
        update(OverlayState())
        current
    }

    override val trigger = handle { current ->
        //console.log("show overlay for ${current.overlay?.durationSeconds} seconds")
        current.timer?.cancel()
        val newTimer = CoroutineScope(EmptyCoroutineContext).launch(CoroutineName("ticker-timer")) {
            current.overlay?.durationSeconds?.seconds?.let {
                delay(it)
                reset()
            }
        }
        update(current.copy(enabled = true, timer = newTimer))
        current.copy(enabled = true, timer = newTimer)
    }

    override val remove = handle { current ->
        //console.log("remove overlay")
        if (current.enabled) {
            current.timer?.cancel()
            update(OverlayState(enabled = false, timer = null))
            OverlayState(enabled = false, timer = null)
        } else {
            //console.log("overlay was already removed")
            current
        }
    }

    override fun show(overlay: Overlay) {
        remove()
        update(OverlayState(overlay = overlay))
        trigger()
    }
}
