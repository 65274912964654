package map.views.workplacetools

import analytics.AnalyticsAction
import analytics.AnalyticsCategory
import auth.ApiUserStore
import auth.FeatureFlagStore
import auth.Features
import data.objects.ActiveObjectStore
import dev.fritz2.components.flexBox
import dev.fritz2.core.RenderContext
import koin.koinCtx
import kotlinx.browser.document
import kotlinx.coroutines.flow.map
import localization.TL
import localization.Translation
import mainmenu.RouterStore
import map.Cards
import maplibreGL.MaplibreMap
import org.w3c.dom.MutationObserver
import org.w3c.dom.MutationObserverInit
import styling.primaryButtonStyleParams
import styling.secondaryButtonStyleParams
import webcomponents.baseLayout
import webcomponents.cardSubtitle
import webcomponents.cardTitle
import webcomponents.twoButtonFooter

fun RenderContext.cardToolSelect() {

    val translation: Translation by koinCtx.inject()
    val activeObjectStore: ActiveObjectStore by koinCtx.inject()
    val apiUserStore: ApiUserStore by koinCtx.inject()
    val maplibreMap: MaplibreMap by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()
    val featureFlagStore: FeatureFlagStore by koinCtx.inject()
    val archeTypeStore: ArchetypesStore by koinCtx.inject()

    archeTypeStore.fetchArchetypes(null)

    if (maplibreMap.activeListeners.keys.contains("resetOnMapClick")) {
        maplibreMap.once(type = "click", fn = maplibreMap.activeListeners["resetOnMapClick"], fnId = "resetOnMapClick")
    }

    val scrollEnabled = ToolSelection.entries.size > 3

    baseLayout(
        expandable = false,
        header = {
            flexBox(
                {
                    direction { column }
                    justifyContent { center }
                    alignItems { center }
                },
            ) {
                flexBox(
                    {
                        css("white-space: nowrap;")
                        overflowX { auto }
                        width { full }
                        justifyContent { center }
                    },
                ) {
                    cardTitle(translation[TL.CardToolSelect.HEADER])
                }
                cardSubtitle(translation[TL.CardToolSelect.DESCRIPTION])
            }
        },
        content = {
            flexBox(
                {
                    direction { row }
                    alignItems { center }
                    width { full }
                    height { full }
                    maxHeight { full }
                    if (scrollEnabled) {
                        overflowY { hidden }
                        overflowX { scroll }
                        css(
                            """-ms-overflow-style: none;  /* IE and Edge */
                            scrollbar-width: none;  /* Firefox */
                        """.trimIndent(),
                        )
                        css("scroll-snap-type: x mandatory;")
                        css("scroll-behavior: smooth;")
                    } else {
                        justifyContent { center }
                    }
                },
                id = "scroll-snap-container",
            ) {

                if (scrollEnabled) {
                    // scroll button left
                    scrollArrowButton(
                        id = "scroll-button-left",
                        icon = { chevronLeft },
                        scrollStep = -110,
                        pos = { left { none } },
                        buttonWidth = toolButtonWidth,
                        buttonHeight = toolButtonHeight,
                    )
                    // dynamic whitespace start
                    scrollSnapElement {
                        flexBox(
                            {
                                width(
                                    sm = { "calc(50vw - ${toolButtonWidth / 2 + 6 + 16}px)" },
                                    md = { "${(toolButtonWidth * 2) - (toolButtonWidth / 2 + 6 + 16)}px" },
                                )
                                height { "${toolButtonHeight}px" }
                                css("scroll-snap-align: end;")
                            },
                        ) { }
                    }
                }

                // legacy tool items
                ToolSelection.entries.forEach { item ->
                    if (item == ToolSelection.Zone) {
                        if (featureFlagStore.current[Features.AllowZoneEditing] == true) {
                            scrollSnapElement {
                                item.toolButton.invoke(this)
                            }
                        }
                    } else {
                        scrollSnapElement {
                            item.toolButton.invoke(this)
                        }
                    }
                }

                // archetype tool items
                archeTypeStore.data.render { archetypes ->
                    if (!apiUserStore.current.isAnonymous) {
                        archetypes.forEach { archetype ->
                            scrollSnapElement {
                                archetypeToolButton(
                                    archetype,
                                    valueHandlers = listOf(archeTypeStore.createFromArchetype),
                                    analyticsEventProvider = AnalyticsCategory.CreateFromArchetype.click(
                                        label = "Create object from archetype: ${archetype.title} (${archetype.id})",
                                    ),
                                    disabled = apiUserStore.data.map { user -> user.isAnonymous },
                                )
                            }
                        }
                    }
                }

                if (scrollEnabled) {
                    // dynamic whitespace end
                    scrollSnapElement {
                        flexBox(
                            {
                                width(
                                    sm = { "calc(50vw - ${toolButtonWidth / 2 + 6 + 16}px)" },
                                    md = { "${(toolButtonWidth * 2) - (toolButtonWidth / 2 + 6 + 16)}px" },
                                )
                                height { "${toolButtonHeight}px" }
                                css("scroll-snap-align: end;")
                            },
                        ) { }
                    }
                    // scroll button right
                    scrollArrowButton(
                        id = "scroll-button-right",
                        icon = { chevronRight },
                        scrollStep = 110,
                        pos = { right { none } },
                        buttonWidth = toolButtonWidth,
                        buttonHeight = toolButtonHeight,
                    )
                    // set default scroll position
                    val observer = MutationObserver { _, observer ->
                        if (document.contains(domNode)) {
                            this.domNode.scrollTo(x = 118.0, y = 0.0)
                            // TODO addEventListener that checks if left/right scroll-end is reached
                            //  and disabled the left/right scroll button
                            observer.disconnect()
                        }
                    }
                    observer.observe(
                        document,
                        MutationObserverInit(attributes = false, childList = true, characterData = false, subtree = true),
                    )
                }
            }
        },
        footer = {
            twoButtonFooter(
                secondaryTitle = translation[TL.General.CLOSE],
                secondaryStyleParams = secondaryButtonStyleParams,
                secondaryClickHandlers = listOf(activeObjectStore.resetStore, routerStore.goToMap),
                secondaryAnalyticsEventProvider = AnalyticsCategory.CloseButton.provider(AnalyticsAction.Click, "cardTool"),
                primaryTitle = translation[TL.CardMapLayerSelection.SEE_ALL],
                primaryStyleParams = primaryButtonStyleParams,
                primaryValue = Unit,
                primaryAddOrReplaceRoute = mapOf("card" to Cards.Tools.name),
            )
        },
    )
}
