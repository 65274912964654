package data.heatmaplayer

import apiclient.FormationClient
import apiclient.analytics.customFieldClauses
import apiclient.customfields.CategoryFieldDefinition
import apiclient.customfields.CategoryReference
import apiclient.customfields.CustomFieldDefinition
import apiclient.customfields.DateTimeFieldDefinition
import apiclient.customfields.DoubleFieldDefinition
import apiclient.customfields.FieldValue
import apiclient.customfields.LongFieldDefinition
import apiclient.customfields.StringFieldDefinition
import apiclient.geoobjects.ChangeType
import apiclient.geoobjects.CustomFieldClause
import apiclient.geoobjects.HistoryTags
import apiclient.geoobjects.ObjectType
import apiclient.geoobjects.SearchQueryContext
import apiclient.groups.Group
import apiclient.groups.HeatmapLayerDefinition
import apiclient.groups.LayerMetaData
import apiclient.groups.LayerMetaDataUpdate
import apiclient.groups.heatmapLayersDefinitionsMap
import apiclient.groups.restCreateHeatmapLayer
import apiclient.groups.restDeleteLayer
import apiclient.groups.restUpdateOrCreateHeatmapLayer
import apiclient.groups.restUpdatelayerSetting
import apiclient.groups.searchQueryContext
import apiclient.tags.*
import apiclient.validations.parseIsoDate
import auth.ApiUserStore
import auth.WorkspacesStore
import data.keywordlayer.MapLayerTypeSwitchStore
import data.objects.CurrentActiveFieldValueStore
import dev.fritz2.core.Id
import dev.fritz2.core.RootStore
import dev.fritz2.core.invoke
import koin.koinCtx
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.map
import kotlinx.serialization.json.Json
import localization.TL
import localization.Translation
import mainmenu.RouterStore
import model.fieldValues
import model.keywords
import model.objectTypes
import model.orTags
import model.searchQueryCtx
import model.title
import overlays.ApiNullResult
import overlays.BusyStore

class ActiveHeatmapLayerDefinitionStore : RootStore<HeatmapLayerDefinitionData>(
    initialData = HeatmapLayerDefinitionData(),
    job = Job(),
) {

    private val routerStore: RouterStore by koinCtx.inject()
    private val apiUserStore: ApiUserStore by koinCtx.inject()
    private val workspacesStore: WorkspacesStore by koinCtx.inject()
    private val mapLayerTypeSwitchStore: MapLayerTypeSwitchStore by koinCtx.inject()
    private val activeHeatmapLayerMetaDataStore: ActiveHeatmapLayerMetaDataStore by koinCtx.inject()
    private val formationClient: FormationClient by koinCtx.inject()
    private val translation: Translation by koinCtx.inject()
    private val currentActiveFieldValueStore: CurrentActiveFieldValueStore by koinCtx.inject()
    private val busyStore: BusyStore by koinCtx.inject()

    private var initialState = HeatmapLayerDefinitionData()

    // HeatmapDefinition subStores
//    val layerIdSub = map(HeatmapLayerDefinitionData.layerId())
    val titleSub = map(HeatmapLayerDefinitionData.title())
    val fieldValuesSub = map(HeatmapLayerDefinitionData.fieldValues())
    val keywordsSub = map(HeatmapLayerDefinitionData.keywords())
//    val orSub = map(HeatmapLayerDefinitionData.or())
//    val excludeKeywordsSub = map(HeatmapLayerDefinitionData.excludeKeywords())

//    val opacitySub = map(HeatmapLayerDefinitionData.opacity())
//    val colorPaletteSub = map(HeatmapLayerDefinitionData.colorPalette())
//    val styleSub = map(HeatmapLayerDefinitionData.style())

    private val searchQueryContextSub = map(HeatmapLayerDefinitionData.searchQueryCtx())
    private val fieldValuesCtxSub = searchQueryContextSub.map(SearchQueryContext.fieldValues())

    //    val tagsCtxSub = searchQueryContextSub.map(SearchQueryContext.tags())
    private val orTagsCtxSub = searchQueryContextSub.map(SearchQueryContext.orTags())
    val objectTypesCtxSub = searchQueryContextSub.map(SearchQueryContext.objectTypes())

    companion object {
        val json by koinCtx.inject<Json>()
        val emptyHeatmapLayerDefinition = HeatmapLayerDefinition(
            layerId = "new layer",
            title = "",
            searchQueryString = json.encodeToString(
                SearchQueryContext.serializer(),
                SearchQueryContext(
                    size = 1000,
                    objectTypes = listOf(ObjectType.HistoryEntry),
                    orTags = listOf(
                        HistoryTags.ChangeType.tag(ChangeType.OccupantEnterZone),
                        HistoryTags.ChangeType.tag(ChangeType.OccupantExitZone),
                        HistoryTags.ChangeType.tag(ChangeType.TrackedObjectLocationUpdate),
                    )
                )
            ),
        )
    }

    val addFieldValue = handle<FieldValue?> { current, newFieldValue ->
        if (newFieldValue != null && !current.fieldValues.map { it.field }.contains(newFieldValue.field)) {
            current.copy(fieldValues = current.fieldValues + newFieldValue)
        } else current
    }

    val removeFieldValue = handle<FieldValue?> { current, newFieldValue ->
        if (newFieldValue != null && current.fieldValues.map { it.field }.contains(newFieldValue.field)) {
            current.copy(fieldValues = (current.fieldValues - newFieldValue).distinct())
        } else current
    }

    val addOrChangeFieldValue = handle { current ->
        console.log("AddOrChange Field value to heatmap layer")
//        console.log("Color Test, rgba() -> hex", "rgba(33,102,172,0)", "hex: ${rgbaToHexColor("rgba(33,102,172,0)")}")
//        console.log("Color Test, rgb() -> hex", "rgb(103,169,207)", "hex: ${rgbToHexColor("rgb(103,169,207)")}")

        val activeFieldValue = currentActiveFieldValueStore.current
        if (activeFieldValue != null) {
            if (current.fieldValues.map { it.field }.contains(activeFieldValue.field)) {
                val newFieldValues = current.fieldValues.map { fieldValue ->
                    if (fieldValue.field == activeFieldValue.field) {
                        when {
                            fieldValue is FieldValue.CategoryValue
                                    && activeFieldValue is FieldValue.CategoryValue -> {
                                fieldValue.copy(value = activeFieldValue.value)
                            }

                            fieldValue is FieldValue.DoubleValue
                                    && activeFieldValue is FieldValue.DoubleValue -> {
                                fieldValue.copy(value = activeFieldValue.value)
                            }

                            fieldValue is FieldValue.EmptyValue
                                    && activeFieldValue is FieldValue.EmptyValue -> {
                                fieldValue
                            }

                            fieldValue is FieldValue.InstantValue
                                    && activeFieldValue is FieldValue.InstantValue -> {
                                fieldValue.copy(value = activeFieldValue.value)
                            }

                            fieldValue is FieldValue.LongValue
                                    && activeFieldValue is FieldValue.LongValue -> {
                                fieldValue.copy(value = activeFieldValue.value)
                            }

                            fieldValue is FieldValue.StringValue
                                    && activeFieldValue is FieldValue.StringValue -> {
                                fieldValue.copy(value = activeFieldValue.value)
                            }

                            else -> fieldValue
                        }
                    } else fieldValue
                }.distinct()
                current.copy(fieldValues = newFieldValues)
            } else current.copy(fieldValues = current.fieldValues + activeFieldValue)
        } else current
    }

    private fun composeHeatmapLayerDefinition(data: HeatmapLayerDefinitionData, newLayer: Boolean = false): HeatmapLayerDefinition {
        val layerId = if (newLayer) "${data.title.substring(0, 6)}-${Id.next(9)}" else data.layerId

        // compose searchQueryContextString
        val searchQueryString = json.encodeToString(SearchQueryContext.serializer(), current.searchQueryCtx)

        // compose heatmap style object
//        val style = if(newLayer) defaultHeatmapStyle else current.style?.toMutableMap()?: defaultHeatmapStyle
//        style["heatmap-opacity"] = data.opacity
//        style["heatmap-color"] = setColorPalette(data.colorPalette)

        return HeatmapLayerDefinition(
            layerId = layerId,
            title = current.title.ifBlank { layerId },
            searchQueryString = searchQueryString,
            style = data.style
        )
    }

    val createHeatmapLayer = handle { current ->
        val groupId = apiUserStore.current.apiUser?.groups?.firstOrNull()?.groupId

        val newHeatmapLayer = composeHeatmapLayerDefinition(current, newLayer = true)
        groupId?.let { gId ->
            busyStore.handleApiCall(
                supplier = suspend {
                    formationClient.restCreateHeatmapLayer(
                        groupId = gId,
                        heatmapLayerDefinition = newHeatmapLayer,
                        metaData = with(activeHeatmapLayerMetaDataStore.current) {
                            LayerMetaDataUpdate(
                                title = newHeatmapLayer.title,
                                defaultIconCategory = defaultIconCategory,
                                defaultColor = defaultColor,
                                defaultShape = defaultShape,
                                defaultOn = defaultOn,
                                layerVisibility = layerVisibility,
                                layerCacheSettings = layerCacheSettings
                            )
                        },
                    )
                },
                successMessage = translation[TL.AlertNotifications.MAP_LAYER_SUCCESSFULLY_CREATED, mapOf(
                    "layerName" to current.title
                )],
                processResult = { group ->
                    console.log("Layer ${newHeatmapLayer.layerId} successfully created.")
                    console.log("Layer:", current.title, newHeatmapLayer)
                    console.log("Updated Group:", group.name, group)
                    workspacesStore.updateWorkspaces(listOf(group))
                    reset()
                },
                errorMessage = translation[TL.AlertNotifications.MAP_LAYER_CREATION_FAILED, mapOf(
                    "layerName" to current.title
                )],
                processError = { response ->
                    console.warn(response.message)
                }
            )
        }
        current
    }

    val updateHeatmapLayer = handle { current ->
        if (current.layerId.isNotBlank()) {
            val groupId = apiUserStore.current.apiUser?.groups?.firstOrNull()?.groupId

            val currentMetaData = activeHeatmapLayerMetaDataStore.current
            val heatmapLayerDefinition = composeHeatmapLayerDefinition(current)

            groupId?.let { gId ->
                busyStore.handleApiCall(
                    supplier = suspend {
                        if (hasChanged()) {
                            formationClient.restUpdateOrCreateHeatmapLayer(
                                groupId = gId,
                                heatmapLayerDefinition = heatmapLayerDefinition,
                            )
                        } else {
                            console.log("LayerDefinition has not changed, nothing to update.")
                            null
                        }
                    },
                    processResult = { groupOne ->
                        metaDataUpdateApiCall(
                            groupId = gId,
                            heatmapLayerDefinitionData = current,
                            layerMetaData = currentMetaData,
                            heatmapLayerDefinition = heatmapLayerDefinition,
                            nullResultMessage = ApiNullResult.IsSuccess,
                            processNullResult = {
                                heatmapLayerSuccess(current, groupOne)
                            }
                        )
                    },
                    processNullResult = {
                        // heatmapLayerDefinition has not changed
                        metaDataUpdateApiCall(
                            groupId = gId,
                            heatmapLayerDefinitionData = current,
                            layerMetaData = currentMetaData,
                            heatmapLayerDefinition = heatmapLayerDefinition,
                            nullResultMessage = ApiNullResult.IsSuccess,
                        )
                    },
                    errorMessage = translation[TL.AlertNotifications.MAP_LAYER_UPDATE_FAILED, mapOf(
                        "layerName" to (currentMetaData.title ?: "")
                    )],
                )
            }
        }
        current
    }

    private suspend fun metaDataUpdateApiCall(
        groupId: String,
        heatmapLayerDefinitionData: HeatmapLayerDefinitionData,
        layerMetaData: LayerMetaData,
        heatmapLayerDefinition: HeatmapLayerDefinition,
        nullResultMessage: ApiNullResult,
        processNullResult: suspend () -> Unit = {}
    ) {
        busyStore.handleApiCall(
            supplier = suspend {
                if (activeHeatmapLayerMetaDataStore.hasChanged() || titleHasChanged()) {
                    formationClient.restUpdatelayerSetting(
                        groupId = groupId,
                        layerId = heatmapLayerDefinitionData.layerId,
                        update = with(layerMetaData) {
                            LayerMetaDataUpdate(
                                title = heatmapLayerDefinition.title,
                                defaultIconCategory = defaultIconCategory,
                                defaultColor = defaultColor,
                                defaultShape = defaultShape,
                                defaultOn = defaultOn,
                                layerVisibility = layerVisibility,
                                layerCacheSettings = layerCacheSettings
                            )
                        },
                    )
                } else {
                    console.log("LayerMetaData has not changed, nothing to update.")
                    null
                }
            },
            successMessage = translation[TL.AlertNotifications.MAP_LAYER_SUCCESSFULLY_UPDATED, mapOf(
                "layerName" to (layerMetaData.title ?: "")
            )],
            processResult = { groupTwo ->
                heatmapLayerSuccess(heatmapLayerDefinitionData, groupTwo)
            },
            apiNullResultMessage = nullResultMessage,
            processNullResult = processNullResult,
            errorMessage = translation[TL.AlertNotifications.MAP_LAYER_UPDATE_FAILED, mapOf(
                "layerName" to (layerMetaData.title ?: "")
            )],
        )
    }

    private fun heatmapLayerSuccess(
        current: HeatmapLayerDefinitionData,
        group: Group
    ) {
        console.log("Layer ${current.layerId} successfully updated.")
        console.log("Updated Group:", group.name, group)
        workspacesStore.updateWorkspaces(listOf(group))
        reset()
    }

    val deleteHeatmapLayer = handle { current ->
        if (current.layerId.isNotBlank()) {
            val groupId = apiUserStore.current.apiUser?.groups?.firstOrNull()?.groupId
            groupId?.let { gId ->
                busyStore.handleApiCall(
                    supplier = suspend {
                        formationClient.restDeleteLayer(
                            groupId = gId,
                            layerId = current.layerId,
                        )
                    },
                    successMessage = translation[TL.AlertNotifications.MAP_LAYER_SUCCESSFULLY_DELETED, mapOf(
                        "layerName" to current.title
                    )],
                    processResult = { group ->
                        console.log("HeatmapLayer ${current.layerId} successfully deleted.")
                        routerStore.backXLevels(2)
                        console.log("Updated Group:", group.name, group)
                        workspacesStore.updateWorkspaces(listOf(group))
                        reset()
                    },
                    errorMessage = translation[TL.AlertNotifications.MAP_LAYER_DELETION_FAILED, mapOf(
                        "layerName" to current.title
                    )],
                )
            }
        }
        current
    }

    val updateHeatmapStores = handle<LayerMetaData> { current, layerMetaData ->
        val currentGroup = workspacesStore.current.firstOrNull()
        if (currentGroup != null) {
            // toggle MapLayerTypeSwitch
            mapLayerTypeSwitchStore.update(layerMetaData.layerType)

            // update HeatmapLayerMetaData
            activeHeatmapLayerMetaDataStore.initialState = layerMetaData
            activeHeatmapLayerMetaDataStore.update(layerMetaData)

            // get HeatmapLayerDefinition
            val heatmapDefinition =
                currentGroup.heatmapLayersDefinitionsMap[layerMetaData.id] ?: emptyHeatmapLayerDefinition

            current.copy(
                layerId = heatmapDefinition.layerId,
                title = heatmapDefinition.title,
                searchQueryCtx = heatmapDefinition.searchQueryContext,
                fieldValues = heatmapDefinition.searchQueryContext.fieldValues?.parseFieldValues(currentGroup.fieldDefinitions) ?: emptyList()
            )
        } else current
    }

    val resetHeatmapStores = handle {
        activeHeatmapLayerMetaDataStore.reset()
        current
    }

    val reset = handle {
        resetHeatmapStores()
        initialState = HeatmapLayerDefinitionData()
        HeatmapLayerDefinitionData()
    }

    private fun hasChanged(): Boolean {
        return current != initialState
    }

    private fun titleHasChanged(): Boolean {
        return current.title != initialState.title
    }

    init {
        fieldValuesSub.data.map { it.customFieldClauses } handledBy fieldValuesCtxSub.update
        keywordsSub.data handledBy orTagsCtxSub.update
    }

}

fun List<CustomFieldClause>.parseFieldValues(definitions: List<CustomFieldDefinition>?): List<FieldValue> {
    val definitionMap = definitions?.associateBy { it.name }
    return this.mapNotNull { customFieldClause ->
        val splitted = customFieldClause.tag.split(":")
        val fieldName = customFieldClause.field
        val value = customFieldClause.tag
        definitionMap?.get(fieldName)?.let { customFieldDefinition ->
            try {
                when (customFieldDefinition) {
                    is CategoryFieldDefinition -> {
                        if (splitted.size == 2) {
                            FieldValue.CategoryValue(
                                fieldName, CategoryReference(
                                    namespace = splitted[0],
                                    categoryName = splitted[1]
                                )
                            )
                        } else {
                            null
                        }
                    }

                    is StringFieldDefinition -> FieldValue.StringValue(fieldName, value)
                    is DateTimeFieldDefinition -> FieldValue.InstantValue(fieldName, value.parseIsoDate())
                    is DoubleFieldDefinition -> FieldValue.DoubleValue(fieldName, value.toDouble())
                    is LongFieldDefinition -> FieldValue.LongValue(fieldName, value.toLong())
                }
            } catch (e: Exception) {
                // malformed dates, numbers, etc. are ignored
                null
            }
        }
    }
}
