// GENERATED by fritz2 - NEVER CHANGE CONTENT MANUALLY!
package model

import apiclient.geoobjects.ObjectType
import apiclient.search.ObjectSearchResult
import dev.fritz2.core.Lens
import dev.fritz2.core.lensOf

fun NestedObjects.Companion.activeParentObjectId(): Lens<NestedObjects, String?> = lensOf(
    "activeParentObjectId",
    { it.activeParentObjectId },
    { p, v -> p.copy(activeParentObjectId = v) }
)

fun NestedObjects.Companion.activeParentObjectType(): Lens<NestedObjects, ObjectType?> =
    lensOf(
        "activeParentObjectType",
        { it.activeParentObjectType },
        { p, v -> p.copy(activeParentObjectType = v) }
    )

fun NestedObjects.Companion.total(): Lens<NestedObjects, Int?> = lensOf(
    "total",
    { it.total },
    { p, v -> p.copy(total = v) }
)

fun NestedObjects.Companion.fetched(): Lens<NestedObjects, List<ObjectSearchResult>> =
    lensOf(
        "fetched",
        { it.fetched },
        { p, v -> p.copy(fetched = v) }
    )
