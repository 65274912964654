@file:Suppress("unused")

package svgmarker

import apiclient.geoobjects.ChangeType
import apiclient.geoobjects.MarkerColor
import apiclient.geoobjects.MarkerIcon
import apiclient.geoobjects.MarkerShape
import dev.fritz2.styling.params.ColorProperty
import dev.fritz2.styling.params.SizesProperty
import dev.fritz2.styling.theme.IconDefinition
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.descriptors.buildClassSerialDescriptor
import kotlinx.serialization.descriptors.element
import kotlinx.serialization.encoding.*
import theme.FormationColors
import theme.FormationIcons
import theme.FormationShapes
import theme.FormationUIIcons
import utils.getColorForIcon
import utils.getIcon
import utils.getShape
import utils.isBrightColor

@Serializable
data class SvgIconOptions(
    val size: MarkerSize = MarkerSize.S,
    val icon: FormationIcons = FormationIcons.Office,
    val profilePicture: String? = null,
    val bitmapPicture: String? = null,
    val bgShape: FormationShapes = FormationShapes.Circle,
    val iconColor: FormationColors = FormationColors.BlueDeep,
    val bgColor: FormationColors = FormationColors.White,
    val borderColor: ColorProperty? = null,
    val iconOpacity: Double = 1.0,
    val bgOpacity: Double = 1.0,
    val borderOpacity: Double = 1.0,
    val hasNotification: Boolean = false,
    val stateColor: FormationColors? = null,
    @Serializable(StateIconSerializer::class)
    val stateIcon: IconDefinition? = null,
    val archived: Boolean = false,
    val flagged: Boolean = false,
    val desaturated: Boolean = false,
    val highlighted: Boolean = false,
)

object StateIconSerializer : KSerializer<IconDefinition> {
    override val descriptor: SerialDescriptor
        get() = buildClassSerialDescriptor("Color") {
            element<String>("displayName")
            element<String>("viewBox")
            element<String>("svg")
        }

    override fun deserialize(decoder: Decoder): IconDefinition {
        return decoder.decodeStructure(descriptor) {
            var displayName = ""
            var viewBox = ""
            var svg = ""
            while (true) {
                when (val index = decodeElementIndex(descriptor)) {
                    0 -> displayName = decodeStringElement(descriptor, 0)
                    1 -> viewBox = decodeStringElement(descriptor, 1)
                    2 -> svg = decodeStringElement(descriptor, 2)
                    CompositeDecoder.DECODE_DONE -> break
                    else -> error("Unexpected index: $index")
                }
            }
            require(displayName.isNotEmpty() && viewBox.isNotEmpty() && svg.isNotEmpty())
            IconDefinition(
                displayName = displayName,
                viewBox = viewBox,
                svg = svg,
            )
        }
    }

    override fun serialize(encoder: Encoder, value: IconDefinition) {
        encoder.encodeStructure(descriptor) {
            encodeStringElement(descriptor, 0, value.displayName)
            encodeStringElement(descriptor, 1, value.viewBox)
            encodeStringElement(descriptor, 2, value.svg)
        }
    }

}

enum class MarkerSize {
    XS {
        override val smPixel = 24
        override val mdPixel = 34
        override val smIconSize: SizesProperty = { small }
        override val mdIconSize: SizesProperty = { normal }
    },
    S {
        override val smPixel = XS.mdPixel
        override val mdPixel = 42
        override val smIconSize: SizesProperty = XS.mdIconSize
        override val mdIconSize: SizesProperty = { large }
    },
    M {
        override val smPixel = S.mdPixel
        override val mdPixel = 48
        override val smIconSize: SizesProperty = S.mdIconSize
        override val mdIconSize: SizesProperty = { larger }
    },
    L {
        override val smPixel = M.mdPixel
        override val mdPixel = 54
        override val smIconSize: SizesProperty = M.mdIconSize
        override val mdIconSize: SizesProperty = { huge }
    },
    XL {
        override val smPixel = L.mdPixel
        override val mdPixel = 58
        override val smIconSize: SizesProperty = L.mdIconSize
        override val mdIconSize: SizesProperty = { giant }
    },
    XXL {
        override val smPixel = XL.mdPixel
        override val mdPixel = 68
        override val smIconSize: SizesProperty = XL.mdIconSize
        override val mdIconSize: SizesProperty = { "68px" }
    };

    abstract val smPixel: Int
    abstract val mdPixel: Int
    abstract val smIconSize: SizesProperty
    abstract val mdIconSize: SizesProperty
}

/**
 * COMMON MARKER DEFINITIONS
 */

val defaultMarkerSize = MarkerSize.S
val defaultMarkerSizeSearch = MarkerSize.L

val defaultIconBoxSizeSearch =
    (defaultMarkerSizeSearch.smPixel * 1.3).toInt() // depends on the paddingRatio (1.3) used in the makeSvgIcon function
val userInitialsIconSizeSearch =
    defaultMarkerSizeSearch.smPixel * (0.9681) // somehow accurate when defaultSizeSearch = MarkerSize.L

val pointSize = defaultMarkerSize
fun pointSvgIconOptions(
    size: MarkerSize,
    color: MarkerColor? = null,
    icon: MarkerIcon? = null,
    shape: MarkerShape? = null,
    hasNotification: Boolean = false,
    archived: Boolean = false,
    flagged: Boolean = false,
    desaturated: Boolean = false,
    highlighted: Boolean = false,
    bitmapImage: String? = null
) = SvgIconOptions(
    icon = icon?.getIcon() ?: FormationIcons.Location,
    bgShape = shape?.getShape() ?: FormationShapes.Circle,
    size = size,
    bgColor = color?.getColorForIcon() ?: MarkerColor.DarkBlue.getColorForIcon(),
    iconColor = if (isBrightColor((color?.getColorForIcon()?.color ?: MarkerColor.DarkBlue.getColorForIcon().color))) {
        FormationColors.BlueDeep
    } else FormationColors.White,
    borderColor = null,
    hasNotification = hasNotification,
    archived = archived,
    flagged = flagged,
    desaturated = desaturated,
    highlighted = highlighted,
    stateColor = when {
        highlighted -> FormationColors.MarkerYou
        flagged -> FormationColors.RedError
        else -> null
    },
    stateIcon = when {
        highlighted -> FormationUIIcons.LinkAlt.icon
        flagged -> FormationIcons.Caution.icon
        else -> null
    },
    bitmapPicture = bitmapImage,
)

val taskSize = defaultMarkerSize
fun taskSvgIconOptions(
    size: MarkerSize,
    color: MarkerColor? = null,
    icon: MarkerIcon? = null,
    shape: MarkerShape? = null,
    hasNotification: Boolean = false,
    archived: Boolean = false,
    flagged: Boolean = false,
    desaturated: Boolean = false,
    highlighted: Boolean = false,
    stateColor: FormationColors? = null,
    stateIcon: IconDefinition? = null,
    bitmapImage: String? = null
) = SvgIconOptions(
    icon = icon?.getIcon() ?: FormationIcons.Task,
    bgShape = shape?.getShape() ?: FormationShapes.Circle,
    size = size,
    bgColor = color?.getColorForIcon() ?: FormationColors.MarkerTask,
    iconColor = if (isBrightColor((color?.getColorForIcon()?.color ?: FormationColors.MarkerTask.color))) {
        FormationColors.BlueDeep
    } else FormationColors.White,
    borderColor = null,
    hasNotification = hasNotification,
    archived = archived,
    flagged = flagged,
    desaturated = desaturated,
    highlighted = highlighted,
    stateColor = when {
        highlighted -> FormationColors.MarkerYou
        flagged -> FormationColors.RedError
        else -> stateColor
    },
    stateIcon = when {
        highlighted -> FormationUIIcons.LinkAlt.icon
        flagged -> FormationIcons.Caution.icon
        else -> stateIcon
    },
    bitmapPicture = bitmapImage,
)

val eventSize = defaultMarkerSize
fun eventSvgIconOptions(
    size: MarkerSize,
    color: MarkerColor? = null,
    icon: MarkerIcon? = null,
    shape: MarkerShape? = null,
    hasNotification: Boolean = false,
    archived: Boolean = false,
    flagged: Boolean = false,
    desaturated: Boolean = false,
    highlighted: Boolean = false,
    stateColor: FormationColors? = null,
    stateIcon: IconDefinition? = null,
    bitmapImage: String? = null
) = SvgIconOptions(
    icon = icon?.getIcon() ?: FormationIcons.Time,
    bgShape = shape?.getShape() ?: FormationShapes.Circle,
    size = size,
    bgColor = color?.getColorForIcon() ?: FormationColors.MarkerMeeting,
    iconColor = if (isBrightColor((color?.getColorForIcon()?.color ?: FormationColors.MarkerMeeting.color))) {
        FormationColors.BlueDeep
    } else FormationColors.White,
    borderColor = null,
    hasNotification = hasNotification,
    archived = archived,
    flagged = flagged,
    desaturated = desaturated,
    highlighted = highlighted,
    stateColor = when {
        highlighted -> FormationColors.MarkerYou
        flagged -> FormationColors.RedError
        else -> stateColor
    },
    stateIcon = when {
        highlighted -> FormationUIIcons.LinkAlt.icon
        flagged -> FormationIcons.Caution.icon
        else -> stateIcon
    },
    bitmapPicture = bitmapImage,
)

val buildingSize = defaultMarkerSize
fun buildingSvgIconOptions(
    size: MarkerSize,
    color: MarkerColor? = null,
    icon: MarkerIcon? = null,
    shape: MarkerShape? = null,
    hasNotification: Boolean = false,
    archived: Boolean = false,
    flagged: Boolean = false,
    desaturated: Boolean = false,
    highlighted: Boolean = false,
    bitmapImage: String? = null
) = SvgIconOptions(
    icon = icon?.getIcon() ?: FormationIcons.Building,
    bgShape = shape?.getShape() ?: FormationShapes.Circle,
    size = size,
    bgColor = color?.getColorForIcon() ?: FormationColors.White,
    iconColor = if (isBrightColor((color?.getColorForIcon()?.color ?: FormationColors.White.color))) {
        FormationColors.Black
    } else FormationColors.White,
    borderColor = FormationColors.Black.color,
    hasNotification = hasNotification,
    archived = archived,
    flagged = flagged,
    desaturated = desaturated,
    highlighted = highlighted,
    stateColor = when {
        highlighted -> FormationColors.MarkerYou
        flagged -> FormationColors.RedError
        else -> null
    },
    stateIcon = when {
        highlighted -> FormationUIIcons.LinkAlt.icon
        flagged -> FormationIcons.Caution.icon
        else -> null
    },
    bitmapPicture = bitmapImage,
)

val userSize = defaultMarkerSize
fun userSvgIconOptions(
    size: MarkerSize,
    color: MarkerColor? = null,
    icon: MarkerIcon? = null,
    shape: MarkerShape? = null,
    hasNotification: Boolean = false,
    archived: Boolean = false,
    flagged: Boolean = false,
    desaturated: Boolean = false,
    highlighted: Boolean = false,
    sharing: Boolean,
    picture: String? = null,
    bitmapImage: String? = null
) = SvgIconOptions(
    icon = icon?.getIcon() ?: FormationIcons.UserAlt,
    bgShape = shape?.getShape() ?: FormationShapes.Circle,
    size = size,
    bgColor = if (sharing) color?.getColorForIcon() ?: FormationColors.BlueDeep else FormationColors.GrayPrivate,
    iconColor = if (isBrightColor((color?.getColorForIcon()?.color ?: FormationColors.BlueDeep.color))) {
        FormationColors.Black
    } else FormationColors.White,
    borderColor = null,
    hasNotification = hasNotification,
    archived = archived,
    flagged = flagged,
    desaturated = desaturated,
    highlighted = highlighted,
    stateColor = when {
        highlighted -> FormationColors.MarkerYou
        flagged -> FormationColors.RedError
        else -> null
    },
    stateIcon = when {
        highlighted -> FormationUIIcons.LinkAlt.icon
        flagged -> FormationIcons.Caution.icon
        else -> null
    },
    profilePicture = picture,
    bitmapPicture = bitmapImage,
)

val objectSize = defaultMarkerSize
fun objectMarkerSvgIconOptions(
    size: MarkerSize,
    color: MarkerColor? = null,
    icon: MarkerIcon? = null,
    shape: MarkerShape? = null,
    hasNotification: Boolean = false,
    archived: Boolean = false,
    flagged: Boolean = false,
    desaturated: Boolean = false,
    highlighted: Boolean = false,
    extraDataStateColor: FormationColors? = null,
    bitmapImage: String? = null
) = SvgIconOptions(
    icon = icon?.getIcon() ?: FormationIcons.TrackedObject,
    bgShape = shape?.getShape() ?: FormationShapes.SquarewithPointer,
    size = size,
    bgColor = color?.getColorForIcon() ?: FormationColors.Black,
    iconColor = if (isBrightColor((color?.getColorForIcon()?.color ?: FormationColors.Black.color))) {
        FormationColors.Black
    } else FormationColors.White,
    borderColor = null,
    hasNotification = hasNotification,
    archived = archived,
    flagged = flagged,
    desaturated = desaturated,
    highlighted = highlighted,
    stateColor = when {
        highlighted -> FormationColors.MarkerYou
        flagged -> FormationColors.RedError
        else -> extraDataStateColor
    },
    stateIcon = when {
        highlighted -> FormationUIIcons.LinkAlt.icon
        flagged -> FormationIcons.Caution.icon
        else -> null
    },
    bitmapPicture = bitmapImage,
)

val areaSize = defaultMarkerSize
fun areaMarkerSvgIconOptions(
    size: MarkerSize,
    color: MarkerColor? = null,
    icon: MarkerIcon? = null,
    shape: MarkerShape? = null,
    hasNotification: Boolean = false,
    archived: Boolean = false,
    flagged: Boolean = false,
    desaturated: Boolean = false,
    highlighted: Boolean = false,
    bitmapImage: String? = null
) = SvgIconOptions(
    icon = icon?.getIcon() ?: FormationIcons.Area,
    bgShape = shape?.getShape() ?: FormationShapes.Pentagon,
    size = size,
    bgColor = color?.getColorForIcon() ?: FormationColors.White,
    iconColor = if (isBrightColor((color?.getColorForIcon()?.color ?: FormationColors.White.color))) {
        FormationColors.Black
    } else FormationColors.White,
    borderColor = FormationColors.Black.color,
    hasNotification = hasNotification,
    archived = archived,
    flagged = flagged,
    desaturated = desaturated,
    highlighted = highlighted,
    stateColor = when {
        highlighted -> FormationColors.MarkerYou
        flagged -> FormationColors.RedError
        else -> null
    },
    stateIcon = when {
        highlighted -> FormationUIIcons.LinkAlt.icon
        flagged -> FormationIcons.Caution.icon
        else -> null
    },
    bitmapPicture = bitmapImage,
)

val zoneSize = defaultMarkerSize
fun zoneMarkerSvgIconOptions(
    size: MarkerSize,
    color: MarkerColor? = null,
    icon: MarkerIcon? = null,
    shape: MarkerShape? = null,
    hasNotification: Boolean = false,
    archived: Boolean = false,
    flagged: Boolean = false,
    desaturated: Boolean = false,
    highlighted: Boolean = false,
    stateColor: FormationColors? = null,
    bitmapImage: String? = null
) = SvgIconOptions(
    icon = icon?.getIcon() ?: FormationIcons.Zone,
    bgShape = shape?.getShape() ?: FormationShapes.Diamond,
    size = size,
    bgColor = color?.getColorForIcon() ?: FormationColors.White,
    iconColor = if (isBrightColor((color?.getColorForIcon()?.color ?: FormationColors.White.color))) {
        FormationColors.Black
    } else FormationColors.White,
    borderColor = FormationColors.Black.color,
    hasNotification = hasNotification,
    archived = archived,
    flagged = flagged,
    desaturated = desaturated,
    highlighted = highlighted,
    stateColor = when {
        highlighted -> FormationColors.MarkerYou
        flagged -> FormationColors.RedError
        else -> stateColor
    },
    stateIcon = when {
        highlighted -> FormationUIIcons.LinkAlt.icon
        flagged -> FormationIcons.Caution.icon
        else -> null
    },
    bitmapPicture = bitmapImage,
)

val geoFenceSize = defaultMarkerSize
fun geoFenceMarkerSvgIconOptions(
    size: MarkerSize,
    color: MarkerColor? = null,
    icon: MarkerIcon? = null,
    shape: MarkerShape? = null,
    hasNotification: Boolean = false,
    archived: Boolean = false,
    flagged: Boolean = false,
    desaturated: Boolean = false,
    highlighted: Boolean = false,
    bitmapImage: String? = null
) = SvgIconOptions(
    icon = icon?.getIcon() ?: FormationIcons.Zone,
    bgShape = shape?.getShape() ?: FormationShapes.Circle,
    size = size,
    bgColor = color?.getColorForIcon() ?: FormationColors.White,
    iconColor = if (isBrightColor((color?.getColorForIcon()?.color ?: FormationColors.White.color))) {
        FormationColors.Black
    } else FormationColors.White,
    borderColor = FormationColors.Black.color,
    hasNotification = hasNotification,
    archived = archived,
    flagged = flagged,
    desaturated = desaturated,
    highlighted = highlighted,
    stateColor = when {
        highlighted -> FormationColors.MarkerYou
        flagged -> FormationColors.RedError
        else -> null
    },
    stateIcon = when {
        highlighted -> FormationUIIcons.LinkAlt.icon
        flagged -> FormationIcons.Caution.icon
        else -> null
    },
    bitmapPicture = bitmapImage,
)

val historyPathMarkerSize = MarkerSize.XS
fun historyPathMarkerSvgIconOptions(
    size: MarkerSize,
    color: MarkerColor? = null,
    icon: MarkerIcon? = null,
    shape: MarkerShape? = null,
    hasNotification: Boolean = false,
    archived: Boolean = false,
    flagged: Boolean = false,
    desaturated: Boolean = false,
    highlighted: Boolean = false,
    changeType: ChangeType?,
    bitmapImage: String? = null
) = SvgIconOptions(
    icon = icon?.getIcon() ?: changeType.getIcon(),
    bgShape = shape?.getShape() ?: FormationShapes.Circle,
    size = size,
    bgColor = color?.getColorForIcon() ?: FormationColors.BlueDeep,
    iconColor = if (isBrightColor((color?.getColorForIcon()?.color ?: FormationColors.BlueDeep.color))) {
        FormationColors.Black
    } else FormationColors.White,
    borderColor = null, //FormationColors.Black.color,
    hasNotification = hasNotification,
    archived = archived,
    flagged = flagged,
    desaturated = desaturated,
    highlighted = highlighted,
    stateColor = if (flagged) FormationColors.RedError else null,
    stateIcon = if (flagged) FormationIcons.Caution.icon else null,
    bitmapPicture = bitmapImage,
)

val myUserSize = defaultMarkerSize
fun myUserSvgIconOptions(
    size: MarkerSize, sharing: Boolean, color: MarkerColor? = null,
    icon: MarkerIcon? = null, shape: MarkerShape? = null, picture: String? = null
) = SvgIconOptions(
    icon = icon?.getIcon() ?: FormationIcons.UserAlt,
    bgShape = shape?.getShape() ?: FormationShapes.Circle,
    size = size,
    bgColor = (if (sharing) color?.getColorForIcon() ?: FormationColors.MarkerYou else FormationColors.GrayPrivate),
    iconColor = if (color?.getColorForIcon()?.color?.let { isBrightColor(it) } == true) {
        FormationColors.BlueDeep
    } else FormationColors.White,
    borderColor = null,
    profilePicture = picture,
)

fun connectableShapeSvgIconOptions(
    size: MarkerSize, color: MarkerColor? = null,
    icon: MarkerIcon? = null,
    shape: MarkerShape? = null,
    hasNotification: Boolean = false,
    archived: Boolean = false,
    flagged: Boolean = false,
    desaturated: Boolean = false,
    highlighted: Boolean = false,
    bitmapImage: String? = null
) = SvgIconOptions(
    icon = icon?.getIcon() ?: FormationIcons.GeoCaching,
    bgShape = shape?.getShape() ?: FormationShapes.Pentagon,
    size = size,
    bgColor = color?.getColorForIcon() ?: FormationColors.GraySilver,
    iconColor = if (isBrightColor((color?.getColorForIcon()?.color ?: FormationColors.White.color))) {
        FormationColors.Black
    } else FormationColors.White,
//    borderColor = FormationColors.Black.color,
    hasNotification = hasNotification,
    archived = archived,
    flagged = flagged,
    desaturated = desaturated,
    highlighted = highlighted,
    stateColor = when {
        highlighted -> FormationColors.MarkerYou
        flagged -> FormationColors.RedError
        else -> null
    },
    stateIcon = when {
        highlighted -> FormationUIIcons.LinkAlt.icon
        flagged -> FormationIcons.Caution.icon
        else -> null
    },
    bitmapPicture = bitmapImage,
)
