package auth

import kotlin.random.Random
import kotlin.random.nextULong
import kotlinx.datetime.Clock
import kotlinx.serialization.Serializable
import localstorage.LocalStoringStore

@Serializable
data class SessionId(
    val id: String,
    val creationTIme: Long
) {
    companion object {
        fun createNew() = SessionId(
            id = Random.nextULong().toString(),
            creationTIme = Clock.System.now().toEpochMilliseconds(),
        )
    }
}

class SessionIdStore : LocalStoringStore<SessionId>(
    initialData = null,
    emptyValue = SessionId(id = "no-id", creationTIme = 0),
    key = "formation-session-id",
    serializer = SessionId.serializer(),
) {
    fun ensureHasSession() {
        console.log("Current Session id:", current)
        if (current == null) {
            val newSession = SessionId.createNew()
            update(newSession)
            console.log("Created new Session id:", newSession)
        }
    }
}
