package utils

import apiclient.customfields.CategoryFieldDefinition
import apiclient.customfields.CategoryReference
import apiclient.customfields.CustomFieldDefinition
import apiclient.customfields.DateTimeFieldDefinition
import apiclient.customfields.DoubleFieldDefinition
import apiclient.customfields.FieldValue
import apiclient.customfields.LongFieldDefinition
import apiclient.customfields.StringFieldDefinition
import apiclient.geoobjects.Content
import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.ObjectTags
import apiclient.geoobjects.ObjectType
import apiclient.geoobjects.ReadOnlyTags
import apiclient.tags.TagList
import apiclient.tags.getTagValues
import apiclient.tags.getUniqueTag
import apiclient.tags.removeTag
import apiclient.validations.parseEnumValue
import auth.CurrentWorkspaceStore
import auth.FeatureFlagStore
import auth.Features
import data.users.settings.keywordPriorities
import koin.koinCtx
import kotlin.io.encoding.Base64
import kotlin.math.PI
import kotlin.math.cos
import kotlin.math.sin
import kotlinx.browser.window
import kotlinx.coroutines.CoroutineName
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.launch
import kotlinx.datetime.Clock
import kotlinx.serialization.Serializable
import layercache.GeoObjectDetailsCache
import localization.TL
import localization.Translation
import localization.getString
import location.geolocation.Coordinates
import location.geolocation.GeoPosition
import map.MapLayersStore
import model.KeywordTag
import model.PreAttachment
import org.w3c.dom.MutationObserver
import org.w3c.dom.MutationObserverInit
import org.w3c.dom.Node
import search.searchlayer.MapSearchResultsStore
import theme.FormationColors
import web.navigator.navigator
import webcomponents.KeywordTagActionType

fun makeRGBA(hexColor: String, opacity: Double): String {
    val r = hexColor.drop(1).substring(0, 2).toInt(16) // 16 for hex
    val g = hexColor.drop(1).substring(2, 4).toInt(16) // 16 for hex
    val b = hexColor.drop(1).substring(4, 6).toInt(16) // 16 for hex
    return "rgba($r,$g,$b,${if (opacity == 1.0) "1.0" else opacity % 1})"
}

fun makeRGB(hexColor: String): String {
    val r = hexColor.drop(1).substring(0, 2).toInt(16) // 16 for hex
    val g = hexColor.drop(1).substring(2, 4).toInt(16) // 16 for hex
    val b = hexColor.drop(1).substring(4, 6).toInt(16) // 16 for hex
    return "rgb($r,$g,$b)"
}

// FIXME these 3 functions are not working correctly
fun getHexDigit(number: Int): String {
    val letters = listOf("A", "B", "C", "D", "E", "F")
    return when (number) {
        in 0..9 -> number.toString()
        in 10..15 -> letters[number - 10]
        else -> number.toString() //error("Number \"$number\" is out of range. To convert to hex digit it needs to be between 0 and 15.")
    }
}

fun rgbToHexColor(rgbColor: String): String {
    val (r, g, b) = rgbColor.drop(4).dropLast(1).split(",")
    val rHex = "${getHexDigit(r.toInt().div(16))}${getHexDigit((r.toInt().mod(16) * 16))}"
    val gHex = "${getHexDigit(g.toInt().div(16))}${getHexDigit((g.toInt().mod(16) * 16))}"
    val bHex = "${getHexDigit(b.toInt().div(16))}${getHexDigit((b.toInt().mod(16) * 16))}"
    return "$rHex$gHex$bHex"
}

fun rgbaToHexColor(rgbaColor: String): String {
    val (r, g, b, a) = rgbaColor.drop(5).dropLast(1).split(",")
    val rHex = "${getHexDigit(r.toInt().div(16))}${getHexDigit((r.toInt().mod(16) * 16))}"
    val gHex = "${getHexDigit(g.toInt().div(16))}${getHexDigit((g.toInt().mod(16) * 16))}"
    val bHex = "${getHexDigit(b.toInt().div(16))}${getHexDigit((b.toInt().mod(16) * 16))}"
    return "$rHex$gHex$bHex"
}

fun getRandomString(length: Int): String {
    val allowedChars = ('A'..'Z') + ('a'..'z') + ('0'..'9')
    return (1..length).map { allowedChars.random() }.joinToString("")
}

fun getRandomPercentage(): Double {
    val allowedChars = ('0'..'9')
    return ((1..2).map { allowedChars.random() }.joinToString("") + ".0").toDouble()
}

// source: https://en.wikipedia.org/wiki/Relative_luminance | https://stackoverflow.com/a/596243
fun getLuminance(hexColor: String): Int {
    val r = hexColor.drop(1).substring(0, 2).toInt(16) // 16 for hex
    val g = hexColor.drop(1).substring(2, 4).toInt(16) // 16 for hex
    val b = hexColor.drop(1).substring(4, 6).toInt(16) // 16 for hex
    return (0.2126 * r + 0.7152 * g + 0.0722 * b).toInt()
//    alternative calculation
//    return (0.299*r + 0.587*g + 0.1142*b).toInt()
}

fun isDarkColor(hexString: String): Boolean = getLuminance(hexString) < 55
fun isBrightColor(hexString: String): Boolean = getLuminance(hexString) > 200

fun isDarkColor(color: FormationColors): Boolean = isDarkColor(color.color)
fun isBrightColor(color: FormationColors): Boolean = isBrightColor(color.color)

fun calcXOnCircle(centerX: Double, radius: Double, degrees: Int): Double {
    return centerX + (radius * cos(degrees * (PI / 180)))
}

fun calcYOnCircle(centerY: Double, radius: Double, degrees: Int): Double {
    return centerY + (radius * sin(degrees * (PI / 180)))
}

fun getPreviewKeywordTags(keywords: List<KeywordTag>, maxLength: Int = 25, maxNumber: Int = 3): List<KeywordTag> {
    val previews: MutableList<KeywordTag> = mutableListOf()
    for (i in keywords.indices) {
        if (
            previews.size < maxNumber - 1
            && (previews.sumOf { it.tagLength() } + keywords[i].tagLength()) <= maxLength
        ) {
            previews += keywords[i]
        }
    }
    return previews.toList()
}

fun KeywordTag.tagLength(): Int {
    return when (this.actionType) {
        KeywordTagActionType.Default, KeywordTagActionType.Add, KeywordTagActionType.Remove -> {
            this.fieldText.length
        }

        else -> {
            this.fieldText.length + (this.valueText?.length ?: 0)
        }
    }
}

fun List<KeywordTag>.sortKeywordTags(priorities: List<String> = keywordPriorities): List<KeywordTag> {
    return if (priorities.isNotEmpty()) {
        this.sortedByDescending { keyword ->
            var match = false
            for (priority in priorities) {
                if (!match) match = keyword.fieldText.contains(priority)
            }
            match
        }
    } else this
}

fun List<String>.extractReadOnlyTags(): List<KeywordTag> {
    val translation: Translation by koinCtx.inject()
    return (ReadOnlyTags.entries.mapNotNull { readOnlyTagType ->
        when (readOnlyTagType) {
            ReadOnlyTags.ObjectType -> this.getUniqueTag(readOnlyTagType)?.let { objType ->
                parseEnumValue<ObjectType>(objType)?.let {
                    KeywordTag(
                        fieldText = it.getName(),
                        actionType = KeywordTagActionType.ReadOnly,
                        readOnlyType = readOnlyTagType,
                        readOnlyStringValue = it.name,
                    )
                }
            }

            ReadOnlyTags.Assignee -> this.getUniqueTag(readOnlyTagType)?.let { tag ->
                val assigneeName = tag.split(":").getOrNull(1) ?: tag
                KeywordTag(
                    fieldText = translation.getString(TL.ReadOnlyTagTranslations.ASSIGNEE),
                    valueText = assigneeName,
                    actionType = KeywordTagActionType.ReadOnly,
                    readOnlyType = readOnlyTagType,
                    readOnlyStringValue = tag,
                )
            }

            ReadOnlyTags.Creator -> this.getUniqueTag(readOnlyTagType)?.let { tag ->
                val creatorName = tag.split(":").getOrNull(1) ?: tag
                KeywordTag(
                    fieldText = translation.getString(TL.ReadOnlyTagTranslations.CREATOR),
                    valueText = creatorName,
                    actionType = KeywordTagActionType.ReadOnly,
                    readOnlyType = readOnlyTagType,
                    readOnlyStringValue = tag,
                )
            }

            ReadOnlyTags.AssigneeName -> null
            else -> this.getUniqueTag(readOnlyTagType)?.let { tag ->
                val tagName = tag.split(":").getOrNull(1) ?: tag
                KeywordTag(
                    fieldText = tagName,
                    actionType = KeywordTagActionType.ReadOnly,
                    readOnlyType = readOnlyTagType,
                    readOnlyStringValue = tag,
                )
            }
        }
    } +
        getTagValues(ObjectTags.ExternalId).map { externalId ->
            KeywordTag(
                fieldText = ObjectTags.ExternalId.name,
                actionType = KeywordTagActionType.ReadOnly,
                readOnlyType = ObjectTags.ExternalId,
                readOnlyStringValue = externalId,
            )
        }).distinct()
}

fun Flow<List<String>>.extractReadOnlyTags(): Flow<List<KeywordTag>> {
    return this.map { tags ->
        tags.filterReadOnlyTags()
    }
}

private fun List<String>.filterReadOnlyTags(

) = ReadOnlyTags.entries.mapNotNull { readOnlyTagType ->
    when (readOnlyTagType) {
        ReadOnlyTags.ObjectType -> getUniqueTag(readOnlyTagType)?.let { objType ->
            parseEnumValue<ObjectType>(objType)?.let {
                KeywordTag(
                    fieldText = it.getName(),
                    actionType = KeywordTagActionType.ReadOnly,
                    readOnlyType = readOnlyTagType,
                    readOnlyStringValue = it.name,
                )
            }
        }

        ReadOnlyTags.Assignee -> getUniqueTag(readOnlyTagType)?.let { tag ->
            val assigneeName = tag.split(":").getOrNull(1) ?: tag
            KeywordTag(
                fieldText = TL.ReadOnlyTagTranslations.ASSIGNEE.getString().orEmpty(),
                valueText = assigneeName,
                actionType = KeywordTagActionType.ReadOnly,
                readOnlyType = readOnlyTagType,
                readOnlyStringValue = tag,
            )
        }

        ReadOnlyTags.Creator -> getUniqueTag(readOnlyTagType)?.let { tag ->
            val creatorName = tag.split(":").getOrNull(1) ?: tag
            KeywordTag(
                fieldText = TL.ReadOnlyTagTranslations.CREATOR.getString().orEmpty(),
                valueText = creatorName,
                actionType = KeywordTagActionType.ReadOnly,
                readOnlyType = readOnlyTagType,
                readOnlyStringValue = tag,
            )
        }

        ReadOnlyTags.AssigneeName -> null
        else -> getUniqueTag(readOnlyTagType)?.let { tag ->
            val tagName = tag.split(":").getOrNull(1) ?: tag
            KeywordTag(
                fieldText = tagName,
                actionType = KeywordTagActionType.ReadOnly,
                readOnlyType = readOnlyTagType,
                readOnlyStringValue = tag,
            )
        }
    }
}.distinct()

fun mergeTagLists(
    readOnlyTags: List<KeywordTag> = emptyList(),
    keywords: List<String>?,
    readOnlyFirst: Boolean = true,
    fieldValueTags: List<KeywordTag> = emptyList()
): List<KeywordTag> {
    return if (readOnlyFirst) {
        readOnlyTags + (keywords?.distinct()?.toKeyWordTagsList(type = KeywordTagActionType.Default)
            ?: emptyList()).sortKeywordTags() + fieldValueTags
    } else {
        (keywords?.distinct()?.toKeyWordTagsList(type = KeywordTagActionType.Default)
            ?: emptyList()).sortKeywordTags() + readOnlyTags + fieldValueTags
    }
}

fun mergeTagLists(
    readOnlyTags: Flow<List<KeywordTag>>,
    keywords: Flow<List<String>?>,
    keywordTagActionType: KeywordTagActionType = KeywordTagActionType.Default,
    readOnlyFirst: Boolean = true,
    fieldValueTags: Flow<List<KeywordTag>> = flowOf(emptyList())
) = combine(readOnlyTags, keywords, fieldValueTags) { r, k, f ->
    Triple(r, (k?.distinct()?.toKeyWordTagsList(type = keywordTagActionType) ?: emptyList()).sortKeywordTags(), f)
}.map { (readOnly, keywords, fieldValues) ->
    if (readOnlyFirst) readOnly + keywords + fieldValues
    else keywords + readOnly + fieldValues
}

fun List<String>.toKeyWordTagsList(type: KeywordTagActionType) = this.map { keyWord ->
    when (type) {
        KeywordTagActionType.ReadOnly, KeywordTagActionType.RemoveReadOnly -> KeywordTag(
            fieldText = parseEnumValue<ObjectType>(keyWord)?.getName() ?: keyWord,
            readOnlyStringValue = keyWord,
            actionType = type,
            readOnlyType = parseEnumValue<ObjectType>(keyWord)?.let { ReadOnlyTags.ObjectType },
        )

        else -> {
            KeywordTag(
                fieldText = keyWord,
                readOnlyStringValue = keyWord,
                actionType = type,
            )
        }
    }
}

fun FieldValue.toKeywordTag(type: KeywordTagActionType, optionalText: String? = null) = when (val fieldValue = this) {
    is FieldValue.CategoryValue -> {
        KeywordTag(
            fieldText = optionalText ?: fieldValue.field,
            valueText = if (optionalText == null) fieldValue.value.categoryName else null,
            actionType = type,
            customFieldValue = fieldValue,
        )
    }

    is FieldValue.DoubleValue -> {
        KeywordTag(
            fieldText = optionalText ?: fieldValue.field,
            valueText = if (optionalText == null) fieldValue.value.toString() else null,
            actionType = type,
            customFieldValue = fieldValue,
        )
    }

    is FieldValue.EmptyValue -> {
        KeywordTag(
            fieldText = optionalText ?: fieldValue.field,
            actionType = type,
            customFieldValue = fieldValue,
        )
    }

    is FieldValue.InstantValue -> {
        KeywordTag(
            fieldText = optionalText ?: fieldValue.field,
            valueText = if (optionalText == null) fieldValue.value.toString() else null,
            actionType = type,
            customFieldValue = fieldValue,
        )
    }

    is FieldValue.LongValue -> {
        KeywordTag(
            fieldText = optionalText ?: fieldValue.field,
            valueText = if (optionalText == null) fieldValue.value.toString() else null,
            actionType = type,
            customFieldValue = fieldValue,
        )
    }

    is FieldValue.StringValue -> {
        KeywordTag(
            fieldText = optionalText ?: fieldValue.field,
            valueText = if (optionalText == null) fieldValue.value else null,
            actionType = type,
            customFieldValue = fieldValue,
        )
    }

    else -> {
        KeywordTag(
            fieldText = optionalText ?: fieldValue.field,
            actionType = type,
            customFieldValue = fieldValue,
        )
    }
}

fun List<FieldValue>.toKeyWordTagsList(type: KeywordTagActionType) = this.map { fieldValue ->
    fieldValue.toKeywordTag(type)
}

fun List<CustomFieldDefinition>.toKeyWordTagsList(type: KeywordTagActionType) = this.mapNotNull { fieldDefinition ->
    val currentWorkspaceStore: CurrentWorkspaceStore by koinCtx.inject()
    when (fieldDefinition) {
        is CategoryFieldDefinition -> {
            val namespace = currentWorkspaceStore.current?.categoryNamespaces?.firstOrNull { categoryNamespace ->
                categoryNamespace.name == fieldDefinition.namespace
            }
            val category = namespace?.categories?.firstOrNull()
            KeywordTag(
                fieldText = fieldDefinition.name,
                actionType = type,
                customFieldValue = namespace?.let { ns ->
                    category?.let { cat ->
                        FieldValue.CategoryValue(fieldDefinition.name, CategoryReference(ns.name, cat.name))
                    }
                },
            )
        }

        is DateTimeFieldDefinition -> {
            KeywordTag(
                fieldText = fieldDefinition.name,
                actionType = type,
                customFieldValue = FieldValue.InstantValue(fieldDefinition.name, value = Clock.System.now()),
            )
        }

        is DoubleFieldDefinition -> {
            KeywordTag(
                fieldText = fieldDefinition.name,
                actionType = type,
                customFieldValue = FieldValue.DoubleValue(fieldDefinition.name, value = 0.0),
            )
        }

        is LongFieldDefinition -> {
            KeywordTag(
                fieldText = fieldDefinition.name,
                actionType = type,
                customFieldValue = FieldValue.LongValue(fieldDefinition.name, value = 0L),
            )
        }

        is StringFieldDefinition -> {
            KeywordTag(
                fieldText = fieldDefinition.name,
                actionType = type,
                customFieldValue = FieldValue.StringValue(fieldDefinition.name, value = ""),
            )
        }

        else -> null
    }
}

fun Flow<TagList>.respectFeatureFlags(): Flow<TagList> {
    val featureFlagStore by koinCtx.inject<FeatureFlagStore>()
    return this.map { tags ->
        if (featureFlagStore.current[Features.AllowCustomFields] == true) {
            tags
        } else tags.removeTag(ObjectTags.CustomField)
    }
}

fun TagList.respectFeatureFlags(): TagList {
    val featureFlagStore by koinCtx.inject<FeatureFlagStore>()
    return if (featureFlagStore.current[Features.AllowCustomFields] == true) {
        this
    } else this.removeTag(ObjectTags.CustomField)
}

fun cutString(string: String, maxChars: Int = 25): String {
//    return if (string.length > maxChars) "${string.take(maxChars)}..." else string
    return if (string.length > maxChars) {
        val words = string.split(" ")
        var ret = words[0]
        var count = 1
        while (ret.length < maxChars) {
            ret += " ${words[count]}"
            count += 1
        }
        ret
    } else {
        string
    }
}

fun cutString(stringFlow: Flow<String>, maxChars: Int = 25): Flow<String> {
//    return stringFlow.map { if (it.length > maxChars) "${it.take(maxChars)}..." else it }
    return stringFlow.map { cutString(it, maxChars) }
}

// sources: https://stackoverflow.com/questions/11381673/detecting-a-mobile-browser
//          https://stackoverflow.com/questions/50195475/detect-if-device-is-tablet
fun isMobileOrTabletBrowser(): Boolean {
    val regex = Regex(
        "(android|bb\\\\d+|meego).+mobile|avantgo|bada\\\\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\\\\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk",
        RegexOption.IGNORE_CASE,
    )
    val regexTwo = Regex(
        "1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\\\\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\\\\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\\\\-(n|u)|c55\\\\/|capi|ccwa|cdm\\\\-|cell|chtm|cldc|cmd\\\\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\\\\-s|devi|dica|dmob|do(c|p)o|ds(12|\\\\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\\\\-|_)|g1 u|g560|gene|gf\\\\-5|g\\\\-mo|go(\\\\.w|od)|gr(ad|un)|haie|hcit|hd\\\\-(m|p|t)|hei\\\\-|hi(pt|ta)|hp( i|ip)|hs\\\\-c|ht(c(\\\\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\\\\-(20|go|ma)|i230|iac( |\\\\-|\\\\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\\\\/)|klon|kpt |kwc\\\\-|kyo(c|k)|le(no|xi)|lg( g|\\\\/(k|l|u)|50|54|\\\\-[a-w])|libw|lynx|m1\\\\-w|m3ga|m50\\\\/|ma(te|ui|xo)|mc(01|21|ca)|m\\\\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\\\\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\\\\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\\\\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\\\\-2|po(ck|rt|se)|prox|psio|pt\\\\-g|qa\\\\-a|qc(07|12|21|32|60|\\\\-[2-7]|i\\\\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\\\\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\\\\-|oo|p\\\\-)|sdk\\\\/|se(c(\\\\-|0|1)|47|mc|nd|ri)|sgh\\\\-|shar|sie(\\\\-|m)|sk\\\\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\\\\-|v\\\\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\\\\-|tdg\\\\-|tel(i|m)|tim\\\\-|t\\\\-mo|to(pl|sh)|ts(70|m\\\\-|m3|m5)|tx\\\\-9|up(\\\\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\\\\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\\\\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\\\\-|your|zeto|zte\\\\-",
        RegexOption.IGNORE_CASE,
    )
//    console.log("Check if mobile, UserAgend: ${window.navigator.userAgent}, Vendor: ${window.navigator.vendor}")
//    console.log(
//        "Is mobile:",
//        regex.containsMatchIn(window.navigator.userAgent) || regex.containsMatchIn(window.navigator.vendor) || regexTwo.containsMatchIn(
//            window.navigator.userAgent.substring(0, 4)
//        ) || regexTwo.containsMatchIn(window.navigator.vendor.substring(0, 4))
//    )
    return regex.containsMatchIn(window.navigator.userAgent) || regex.containsMatchIn(window.navigator.vendor) || regexTwo.containsMatchIn(
        window.navigator.userAgent.substring(0, 4),
    ) || regexTwo.containsMatchIn(window.navigator.vendor.substring(0, 4))
}

var isTouchDevice = (window.asDynamic().ontouchstart != null) ||
    (navigator.maxTouchPoints > 0) ||
    (navigator.asDynamic().msMaxTouchPoints > 0)

class Stack<T> {
    val elements: MutableList<T> = mutableListOf()
    fun isEmpty() = elements.isEmpty()
    fun count() = elements.size
    fun push(item: T) = elements.add(item)
    fun pop(): T? {
        val item = elements.lastOrNull()
        if (!isEmpty()) {
            elements.removeAt(elements.size - 1)
        }
        return item
    }

    fun peek(): T? = elements.lastOrNull()

    override fun toString(): String = elements.toString()
}

fun <T> Stack<T>.push(items: Collection<T>) = items.forEach { this.push(it) }


fun List<Content>?.respectFeatureFlags(): List<Content>? {
    val featureFlagStore by koinCtx.inject<FeatureFlagStore>()
    return this?.filter { attachment ->
        when (attachment) {
            is Content.Image -> featureFlagStore.current[Features.DisablePhotoAttachments] != true
            is Content.Poll -> featureFlagStore.current[Features.AllowPolls] == true
            is Content.ScanToCreateTask -> featureFlagStore.current[Features.AllowScanToX] == true
            else -> true
        }
    }
}

fun Map<String, Content>.respectFeatureFlags(): Map<String, Content> {
    val featureFlagStore by koinCtx.inject<FeatureFlagStore>()
    return this.filter { (_, attachment) ->
        when (attachment) {
            is Content.Image -> featureFlagStore.current[Features.DisablePhotoAttachments] != true
            is Content.Poll -> featureFlagStore.current[Features.AllowPolls] == true
            is Content.ScanToCreateTask -> featureFlagStore.current[Features.AllowScanToX] == true
            else -> true
        }
    }
}

fun Map<String, PreAttachment>.respectFeatureFlags(): Map<String, PreAttachment> {
    val featureFlagStore by koinCtx.inject<FeatureFlagStore>()
    return this.filter { (_, preAttachment) ->
        when (preAttachment) {
            is PreAttachment.PreImage -> featureFlagStore.current[Features.DisablePhotoAttachments] != true
            is PreAttachment.PrePoll -> featureFlagStore.current[Features.AllowPolls] == true
            is PreAttachment.PreScanToCreateTask -> featureFlagStore.current[Features.AllowScanToX] == true
            else -> true
        }
    }
}

fun focusInputObserver(jsFocusCode: Unit.() -> dynamic, domNode: Node, desktopOnly: Boolean = true) {
    val observer = MutationObserver { _, observer ->
        if (kotlinx.browser.document.contains(domNode)) {
            jsFocusCode.invoke(Unit)
            observer.disconnect()
        }
    }
    if (desktopOnly) {
        if (!isMobileOrTabletBrowser()) {
            observer.observe(
                kotlinx.browser.document,
                MutationObserverInit(
                    attributes = true, childList = true, characterData = false,
                    subtree = true,
                ),
            )
        }
    } else {
        observer.observe(
            kotlinx.browser.document,
            MutationObserverInit(
                attributes = true, childList = true, characterData = false,
                subtree = true,
            ),
        )
    }
}

// https://en.wikipedia.org/wiki/VCard has good overview of supported fields and their structure
fun vCard(
    firstName: String?,
    lastName: String?,
    jobTitle: String? = null,
    emailAddress: String? = null,
    phoneNumbers: List<String>,
    company: String? = null,
    linkedInLink: String? = null,
    websiteLink: String? = null,
    photoBytes: ByteArray? = null
): String {
    return """
        BEGIN:VCARD
        VERSION:3.0
        FN:$firstName $lastName
        N:$lastName;$firstName;;;
        ${
        if (!jobTitle.isNullOrBlank()) {
            "TITLE:$jobTitle"
        } else ""
    }
        ${
        if (!emailAddress.isNullOrBlank()) {
            "EMAIL;TYPE=work:$emailAddress"
        } else ""
    }
        ${
        phoneNumbers.mapIndexedNotNull { index, number ->
            if (number.isNotBlank()) {
                when (index) {
                    0 -> "TEL;TYPE=cell:${number}"
                    1 -> "TEL;TYPE=work:${number}"
                    else -> null
                }
            } else null
        }.joinToString(" ")
    }
        ${
        if (!company.isNullOrBlank()) {
            "ORG;TYPE=work:$company"
        } else ""
    }
        ${
        if (!linkedInLink.isNullOrBlank()) {
            "URL;TYPE=work:$linkedInLink"
        } else ""
    }
        ${
        if (!websiteLink.isNullOrBlank()) {
            "URL;TYPE=home:$websiteLink"
        } else ""
    }
        ${
        if (photoBytes != null) {
            "PHOTO;TYPE=PNG;ENCODING=b:${Base64.encode(photoBytes)}"
        } else ""
    }
        END:VCARD
    """.trimIndent()
}

fun convertToGeoPosition(text: String): GeoPosition? {
    return if (text.contains(',')) {
        text.split(',').let {
            GeoPosition(
                timestamp = Clock.System.now().toEpochMilliseconds(),
                coords = Coordinates(
                    latitude = it[0].trim().toDoubleOrNull() ?: 0.0,
                    longitude = it[1].trim().toDoubleOrNull() ?: 0.0,
                    altitude = 0.0,
                    accuracy = 1.0,
                    altitudeAccuracy = 1.0,
                    heading = 0.0,
                    speed = 42.0,
                ),
            )
        }
    } else {
        null
    }
}

fun insertObjectsInCachesAndMap(objects: List<GeoObjectDetails>) {
    val mapSearchResultsStore: MapSearchResultsStore by koinCtx.inject()
    val mapLayersStore: MapLayersStore by koinCtx.inject()
    val geoObjectDetailsCache: GeoObjectDetailsCache by koinCtx.inject()

    console.log("Updating objects in layer caches")
    mapSearchResultsStore.add(objects.filter { it.objectType.includeInSearch })

    CoroutineScope(CoroutineName("update-objects-in-cache")).launch {
        objects.forEach { obj ->
            geoObjectDetailsCache.update(obj.id, obj)
            if (obj.objectType.includeInSearch) {
                mapLayersStore.setUpdatedHit(obj)
            }
        }
    }
}

fun insertObjectInCachesAndMap(obj: GeoObjectDetails) {
    insertObjectsInCachesAndMap(listOf(obj))
}

suspend fun removeObjectsInCachesAndMap(objectIds: Set<String>) {
    val mapSearchResultsStore: MapSearchResultsStore by koinCtx.inject()
    val mapLayersStore: MapLayersStore by koinCtx.inject()
    val geoObjectDetailsCache: GeoObjectDetailsCache by koinCtx.inject()

    console.log("Removing objects in layer caches", objectIds.toString())
    mapSearchResultsStore.remove(objectIds)
    objectIds.forEach { objId ->
        geoObjectDetailsCache.delete(objId)
        mapLayersStore.deleteHit(objId)
    }
}

@Serializable
data class Quadruple<out A, out B, out C, out D>(
    val first: A,
    val second: B,
    val third: C,
    val fourth: D
) {

    /**
     * Returns string representation of the [Quadruple] including its [first], [second], [third] and [fourth] values.
     */
    override fun toString(): String = "($first, $second, $third, $fourth)"
}

/**
 * Converts this quadruple into a list.
 */
public fun <T> Quadruple<T, T, T, T>.toList(): List<T> = listOf(first, second, third, fourth)


@Serializable
data class Quintuple<out A, out B, out C, out D, out E>(
    val first: A,
    val second: B,
    val third: C,
    val fourth: D,
    val fifth: E
) {

    /**
     * Returns string representation of the [Quintuple] including its [first], [second], [third], [fourth] and [fifth] values.
     */
    override fun toString(): String = "($first, $second, $third, $fourth, $fifth)"
}

/**
 * Converts this quintuple into a list.
 */
public fun <T> Quintuple<T, T, T, T, T>.toList(): List<T> = listOf(first, second, third, fourth, fifth)
