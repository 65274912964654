@file:Suppress("REDUNDANT_ELSE_IN_WHEN")

package data.objects.views.attachments

import apiclient.validations.parseEnumValue
import apiclient.geoobjects.AttachmentType
import apiclient.geoobjects.Content
import apiclient.geoobjects.LinkRel
import apiclient.geoobjects.TaskTemplate
import dev.fritz2.styling.theme.IconDefinition
import dev.fritz2.styling.theme.Icons
import koin.koinCtx
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flowOf
import localization.TL
import localization.Translation
import model.PreAttachment
import theme.FormationIcons
import theme.FormationUIIcons

fun AttachmentType.getIcon(): Icons.() -> IconDefinition {
    return when (this) {
        AttachmentType.Image -> {
            { FormationUIIcons.Image.icon }
        }

        AttachmentType.Markdown -> {
            { document }
        }

        AttachmentType.WebLink -> {
            { FormationUIIcons.ExternalLink.icon }
        }

        AttachmentType.GeoObject -> {
            { FormationIcons.Location.icon }
        }

        AttachmentType.Poll -> {
            { FormationIcons.Clipboard.icon }
        }

        AttachmentType.ScanToCreateTask -> {
            { FormationIcons.Task.icon }
        }

        else -> {
            { FormationIcons.Clip.icon }
        }
    }
}

fun AttachmentType.getTitle(): Flow<String> {
    val translation: Translation by koinCtx.inject()
    return when (this) {
        AttachmentType.Image -> translation[TL.AttachmentTypeTrans.IMAGE]
        AttachmentType.Markdown -> translation[TL.AttachmentTypeTrans.MARKDOWN]
        AttachmentType.WebLink -> translation[TL.AttachmentTypeTrans.WEBLINK]
        AttachmentType.GeoObject -> translation[TL.AttachmentTypeTrans.GEO_OBJECT]
        AttachmentType.Poll -> translation[TL.AttachmentTypeTrans.POLL]
        AttachmentType.ScanToCreateTask -> translation[TL.AttachmentTypeTrans.SCAN_TO_TASK_TEMPLATE]
        else -> flowOf("Unknown Attachment")
    }
}

fun AttachmentType.getSubtitle(): Flow<String> {
    val translation: Translation by koinCtx.inject()

    return when (this) {
        AttachmentType.Image -> translation[TL.AttachmentTypeTrans.IMAGE_SUBTITLE]
        AttachmentType.Markdown -> translation[TL.AttachmentTypeTrans.MARKDOWN_SUBTITLE]
        AttachmentType.WebLink -> translation[TL.AttachmentTypeTrans.WEBLINK_SUBTITLE]
        AttachmentType.GeoObject -> translation[TL.AttachmentTypeTrans.GEO_OBJECT_SUBTITLE]
        AttachmentType.Poll -> translation[TL.AttachmentTypeTrans.POLL_SUBTITLE]
        AttachmentType.ScanToCreateTask -> translation[TL.AttachmentTypeTrans.SCAN_TO_TASK_TEMPLATE_SUBTITLE]
        else -> flowOf("Attachment type is not yet supported")
    }
}

fun PreAttachment.toAttachment(): Content? {
    return when (this) {
        is PreAttachment.PreImage -> Content.Image(
            id = this.id,
            href = this.href ?: "",
            width = this.width ?: 0,
            height = this.height ?: 0,
            key = "",
            thumbNail = null,
            mimeType = this.mimeType ?: "",
            title = this.title,
        )

        is PreAttachment.PreSvgImage -> Content.SvgImage(
            id = this.id,
            svgContent = this.svgContent,
            title = this.title,
        )

        is PreAttachment.PreMarkdown -> Content.Markdown(
            id = this.id,
            text = this.text,
            htmlPreview = this.htmlPreview,
            title = this.title,
        )

        is PreAttachment.PreWebLink -> Content.WebLink(
            id = this.id,
            href = this.href,
            mimeType = this.mimeType ?: "",
            rel = this.rel?.name ?: "",
            title = this.title,
            htmlPreview = null,
            actionId = actionId,
        )

        is PreAttachment.PreGeoObject -> Content.GeoObject(
            id = this.id,
            objectId = this.objectId,
            rel = this.rel?.name ?: "",
            title = this.title,
            htmlPreview = null,
        )

        is PreAttachment.PrePoll -> Content.Poll(
            id = this.id,
            title = this.title,
            actionId = this.actionId,
            options = this.options,
            maxVotesPerUser = this.maxVotesPerUser,
        )

        is PreAttachment.PreScanToCreateTask -> Content.ScanToCreateTask(
            id = this.id,
            actionId = this.actionId ?: "",
//            title = this.title?: "", // TODO nikky to add title field for attachment itself
            taskTemplate = this.taskTemplate ?: TaskTemplate(),
        )

        else -> null
    }
}

fun Content.toPreAttachment(): PreAttachment? {
    return when (this) {
        is Content.Image -> {

//            PreAttachment.PreImage(
//                id = this.id,
//                isConverted = markConverted,
//                href = this.href,
//                width = this.width,
//                height = this.height,
//                key = this.key,
//                thumbNail = this.thumbNail,
//                mimeType = this.mimeType,
//                prevName = "",
//                prevBytes = byteArrayOf(),
//                title = this.title,
//            )
            // FIXME: fetch the image bytes and add them to the pre image; this has to be a suspend function for that to work
            // we don't support duplicating images for now
            null
        }

        is Content.SvgImage -> PreAttachment.PreSvgImage(
            id = this.id,
            svgContent = this.svgContent,
            title = this.title,
        )

        is Content.Markdown -> PreAttachment.PreMarkdown(
            id = this.id,
            text = this.text,
            htmlPreview = this.htmlPreview,
            title = this.title,
        )

        is Content.WebLink -> PreAttachment.PreWebLink(
            id = this.id,
            href = this.href,
            mimeType = this.mimeType,
            rel = parseEnumValue<LinkRel>(this.rel),
            title = this.title ?: "",
            htmlPreview = this.htmlPreview,
            openGraphMetadata = this.openGraphMetadata,
            actionId = actionId,
        )

        is Content.GeoObject -> PreAttachment.PreGeoObject(
            id = this.id,
            objectId = this.objectId,
            rel = parseEnumValue<LinkRel>(this.rel),
            title = this.title ?: "",
            htmlPreview = null,
        )

        is Content.Poll -> PreAttachment.PrePoll(
            id = this.id,
            title = this.title,
            actionId = this.actionId,
            options = this.options,
            maxVotesPerUser = this.maxVotesPerUser,
        )

        is Content.ScanToCreateTask -> PreAttachment.PreScanToCreateTask(
            id = this.id,
            actionId = this.actionId,
            title = this.title,
            taskTemplate = this.taskTemplate,
        )

        else -> null
    }
}
