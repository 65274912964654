package markdown

import org.intellij.markdown.flavours.gfm.GFMFlavourDescriptor
import org.intellij.markdown.html.HtmlGenerator
import org.intellij.markdown.parser.MarkdownParser

class MarkdownService {
    private val flavour = GFMFlavourDescriptor()
    private val markdownParser = MarkdownParser(flavour)

    fun markdown2html(source: String): String {
        val parsedTree = markdownParser.buildMarkdownTreeFromString(source)
        val htmlGenerator = HtmlGenerator(source, parsedTree, flavour, false)

        return htmlGenerator.generateHtml().replace("body>","div>")
    }
}
