// GENERATED by fritz2 - NEVER CHANGE CONTENT MANUALLY!
package model

import apiclient.passwords.PasswordEvaluation
import dev.fritz2.core.Lens
import dev.fritz2.core.lensOf

fun ValidatePassword.Companion.firstPass(): Lens<ValidatePassword, String> = lensOf(
    "firstPass",
    { it.firstPass },
    { p, v -> p.copy(firstPass = v) }
)

fun ValidatePassword.Companion.secondPass(): Lens<ValidatePassword, String> = lensOf(
    "secondPass",
    { it.secondPass },
    { p, v -> p.copy(secondPass = v) }
)

fun ValidatePassword.Companion.oldPass(): Lens<ValidatePassword, String> = lensOf(
    "oldPass",
    { it.oldPass },
    { p, v -> p.copy(oldPass = v) }
)

fun ValidatePassword.Companion.responseStatus(): Lens<ValidatePassword, StatusResult?> =
    lensOf(
        "responseStatus",
        { it.responseStatus },
        { p, v -> p.copy(responseStatus = v) }
    )

fun ValidatePassword.Companion.passwordEval(): Lens<ValidatePassword, PasswordEvaluation?> =
    lensOf(
        "passwordEval",
        { it.passwordEval },
        { p, v -> p.copy(passwordEval = v) }
    )

fun ValidatePassword.Companion.isMatching(): Lens<ValidatePassword, Boolean> = lensOf(
    "isMatching",
    { it.isMatching },
    { p, v -> p.copy(isMatching = v) }
)

fun ValidatePassword.Companion.isValid(): Lens<ValidatePassword, Boolean> = lensOf(
    "isValid",
    { it.isValid },
    { p, v -> p.copy(isValid = v) }
)
