package signup

import apiclient.users.Signup
import auth.WorkspaceOptionsStore
import auth.emptyWorkSpaceOptions
import dev.fritz2.components.compat.form
import dev.fritz2.components.compat.span
import dev.fritz2.components.flexBox
import dev.fritz2.components.stackUp
import dev.fritz2.core.RenderContext
import dev.fritz2.core.autofocus
import dev.fritz2.core.id
import dev.fritz2.core.invoke
import dev.fritz2.core.placeholder
import dev.fritz2.core.storeOf
import dev.fritz2.core.type
import dev.fritz2.core.values
import dev.fritz2.routing.MapRouter
import koin.koinCtx
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.mapNotNull
import localization.TL
import localization.Translation
import login.WorkspaceInputStore
import mainmenu.Pages
import model.ConfirmationType
import model.L
import model.Overlay
import model.ValidatePassword
import model.firstPass
import model.secondPass
import overlays.ConfirmationOverlayStore
import routing.MainController
import styling.primaryButtonStyleParams
import theme.FormationColors
import theme.FormationIcons
import utils.makeRGBA
import webcomponents.baseLayout
import webcomponents.cardSubtitle
import webcomponents.cardTitle
import webcomponents.fullWidthFullContainer
import webcomponents.genericInput
import webcomponents.inputIconToggleButton
import webcomponents.inputLabelWrapper
import webcomponents.mainTitle
import webcomponents.oneButtonFooter

fun RenderContext.pageSignUp() {
    val signupStore: SignupStore by koinCtx.inject()
    val validatePasswordStore: ValidatePasswordStore by koinCtx.inject()
    val translation: Translation by koinCtx.inject()
    val email = signupStore.map(Signup.L.email)
    val firstName = signupStore.map(Signup.L.firstName)
    val lastName = signupStore.map(Signup.L.lastName)
    val workspace = signupStore.map(Signup.L.workspace)
    val workspaceInputStore by koinCtx.inject<WorkspaceInputStore>()
    val mainController by koinCtx.inject<MainController>()
    val confirmationOverlayStore by koinCtx.inject<ConfirmationOverlayStore>()

    val firstPass = validatePasswordStore.map(ValidatePassword.firstPass())
    val secondPass = validatePasswordStore.map(ValidatePassword.secondPass())
    val router: MapRouter by koinCtx.inject()

    val workspaceOptionsStore: WorkspaceOptionsStore by koinCtx.inject()

    // propagate the param value to the store if is there
    router.data.mapNotNull { route ->
        (route["ws"] ?: route["workspace"]).takeIf { workspace -> !workspace.isNullOrBlank() }
    } handledBy workspace.update
    router.data.map { route -> route["ws"] ?: route["workspace"] } handledBy workspaceInputStore.update
    router.data.map { route -> route["email"] ?: "" } handledBy email.update

    workspaceOptionsStore.data.render { workspaceOptions ->
        if (workspaceOptions != null) {
            val workspaceExists = workspaceOptions != emptyWorkSpaceOptions
            val signUpsAllowed = workspaceOptions.signupsAllowed
            if (!workspaceExists || !signUpsAllowed) {
                confirmationOverlayStore.show(
                    Overlay.ConfirmationScreen(
                        confirmationType = ConfirmationType.Close,
                        text = if (!workspaceExists) {
                            flowOf("We are very sorry, but this workspace does not seem to exist.")
                        } else {
                            flowOf("We are very sorry, but this workspace does not allow new registrations at the moment.")
                        },
                        primaryActionName = translation[TL.Signup.GO_TO_LOGIN],
                        primaryClickHandlers = listOf(mainController.logoutWithParams),
                    ),
                )
            } else confirmationOverlayStore.reset()
        }
    }

    baseLayout(
        expandable = false,
        header = { },
        content = {
            form(
                {
                    width { full }
                    height { full }
                    margin { none }
                    overflowY { auto }
                },
            ) {
                fullWidthFullContainer {
                    div("flex grow-2") { }

                    mainTitle(translation[TL.Signup.SIGN_UP])

                    div("flex grow-2") { }

                    // WORKSPACE
                    stackUp(
                        {
                            width { full }
                            alignItems { center }
                            justifyContent { center }
                            margins { bottom { small } }
                        },
                    ) {
                        spacing { tiny }
                        items {
                            cardSubtitle(translation[TL.Signup.WORKSPACE])
                            cardTitle(
                                title = workspace.data,
                                margins = { },
                                iconSize = { larger },
                                iconFlow = workspace.data.map { if (it.isBlank()) null else FormationIcons.Map.icon },
                            )
                            workspace.data.render { currentWorkspace ->
                                if (currentWorkspace == "formation4ukraine") {
                                    textLinkButton(
                                        text = translation[TL.Signup.READ_MORE_ABOUT].map { "($it)" },
                                        fontSize = { small },
                                        link = "https://tryformation.com/formation4ukraine",
                                        attributes = listOf("tabindex" to "-1"),
                                    )
                                }
                            }
                        }
                    }

                    div("flex grow-2") { }

                    // FIRST NAME & LAST NAME
                    stackUp(
                        {
                            width { full }
                            display { flex }
                            alignItems { stretch }
                            margins { bottom { small } }
                        },
                    ) {
                        spacing { smaller }
                        items {
                            inputLabelWrapper(
                                title = translation[TL.Signup.FIRST_NAME],
                                visibilityFlow = firstName.data.map { !it.isNullOrBlank() },
                            ) {
                                genericInput(
                                    value = firstName.data.mapNotNull { it },
                                ) {
                                    id("inputFirstName")
                                    placeholder(translation[TL.Signup.FIRST_NAME])
                                    autofocus(true)
                                    attr("tabindex", "1")
                                    inputs.values() handledBy firstName.update
                                }
                            }
//                            focusInputObserver({ js("document.getElementById('inputFirstName').focus()") }, domNode)

                            inputLabelWrapper(
                                title = translation[TL.Signup.LAST_NAME],
                                visibilityFlow = lastName.data.map {
                                    !it.isNullOrBlank(
                                    )
                                },
                            ) {
                                genericInput(
                                    value = lastName.data.mapNotNull { it },
                                ) {
                                    id("inputLastName")
                                    placeholder(translation[TL.Signup.LAST_NAME])
                                    attr("tabindex", "2")
                                    inputs.values() handledBy lastName.update
                                }
                            }
                        }
                    }

                    div("flex grow") { }

                    // EMAIL & PASSWORD
                    stackUp(
                        {
                            width { full }
                            display { flex }
                            alignItems { stretch }
                        },
                    ) {
                        spacing { smaller }
                        items {
                            inputLabelWrapper(
                                title = translation[TL.Login.EMAIL],
                                visibilityFlow = email.data.map { it.isNotBlank() },
                            ) {
                                genericInput(
                                    value = email.data,
                                    type = "email",
                                ) {
                                    id("inputEmail")
                                    placeholder(translation[TL.Login.EMAIL])
                                    attr("tabindex", "3")
                                    inputs.values() handledBy email.update
                                }
                            }

                            inputLabelWrapper(
                                title = translation[TL.Login.PASSWORD],
                                visibilityFlow = firstPass.data.map { it.isNotBlank() },
                            ) {
                                val showFirstPassStore = storeOf(false, job)
                                genericInput(
                                    value = firstPass.data,
                                    type = "password",
                                    rightContentBox = {
                                        inputIconToggleButton(
                                            iconFalse = { eye },
                                            iconTrue = { eyeOff },
                                            boolStore = showFirstPassStore,
                                            attributes = listOf("tabindex" to "-1"),
                                        )
                                    },
                                ) {
                                    id("inputPassword")
                                    placeholder(translation[TL.Login.PASSWORD])
                                    attr("tabindex", "4")
                                    type(showFirstPassStore.data.map { if (it) "text" else "password" })
                                    inputs.values() handledBy firstPass.update
                                }
                                passwordStrengthIndicator(validatePasswordStore.data)
                            }

                            inputLabelWrapper(
                                title = translation[TL.Signup.RE_ENTER_PASSWORD],
                                visibilityFlow = secondPass.data.map { it.isNotBlank() },
                            ) {
                                val showSecondPassStore = storeOf(false, job)
                                genericInput(
                                    value = secondPass.data,
                                    type = "password",
                                    rightContentBox = {
                                        inputIconToggleButton(
                                            iconFalse = { eye },
                                            iconTrue = { eyeOff },
                                            boolStore = showSecondPassStore,
                                            attributes = listOf("tabindex" to "-1"),
                                        )
                                    },
                                ) {
                                    id("inputPassword-Re-enter")
                                    placeholder(translation[TL.Signup.RE_ENTER_PASSWORD])
                                    attr("tabindex", "5")
                                    type(showSecondPassStore.data.map { if (it) "text" else "password" })
                                    inputs.values() handledBy secondPass.update
                                }
                            }
                        }
                    }

                    flexBox(
                        {
                            width { full }
                            height { minContent }
                            justifyContent { center }
                            padding { small }
                        },
                    ) {
                        span(
                            {
                                color { FormationColors.RedError.color }
                                fontSize { smaller }
                                textAlign { center }
                                background {
                                    color { makeRGBA(FormationColors.RedError.color, 0.1) }
                                }
                                radius { larger }
                                paddings {
                                    vertical { tiny }
                                    horizontal { smaller }
                                }
                            },
                        ) {
                            className(
                                validatePasswordStore.data.map { pwStack ->
                                    if (pwStack.firstPass.isNotBlank()
                                        && pwStack.secondPass.isNotBlank()
                                        && !pwStack.isMatching
                                    ) {
                                        "visible"
                                    } else {
                                        "invisible"
                                    }
                                },
                            )
                            translation[TL.UserProfile.PASSWORD_NO_MATCH].renderText(into = this)
                        }
                    }

                    stackUp(
                        {
                            flex { grow { "2" } }
                            alignItems { center }
                            justifyContent { center }
                        },
                    ) {
                        spacing { small }
                        items {
                            textButton(
                                text = translation[TL.Signup.ALREADY_HAVE_ACCOUNT],
//                                          .merge(flow = flowOf(if(anonymousLogin){"(Anonymous login possible)"} else ""), separator = "\n"),
                                attributes = listOf(
                                    "tabindex" to "-1",
                                    "type" to "button",
                                    "onClick" to "event.preventDefault(); blurInput();",
                                ),
                                replaceRoute = workspaceInputStore.current?.let { workspace ->
                                    mapOf(
                                        "page" to Pages.Login.name,
                                        "ws" to workspace,
                                    )
                                } ?: mapOf("page" to Pages.Login.name),
                                clickHandlers = listOf(mainController.logoutWithParams),
                            )
                        }
                    }
                }
            }
        },
        footer = {
            oneButtonFooter(
                title = translation[TL.Signup.CREATE_ACCOUNT],
                state = signupStore.watchInputs(),
                styleParams = primaryButtonStyleParams,
                value = Unit,
                clickHandlers = listOf(signupStore.createAccount),
                attributes = listOf(
                    "tabindex" to "7",
                    "onClick" to "event.preventDefault(); blurInput();",
                ),
                tracker = signupStore.signingUpTracker,
                loadingText = translation[TL.Signup.CREATE_ACCOUNT_ONGOING],
            )
        },
    )
}
