package notifications

import apiclient.FormationClient
import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.NotificationState
import apiclient.geoobjects.NotificationTags
import apiclient.geoobjects.ObjectTags
import apiclient.geoobjects.ObjectType
import apiclient.geoobjects.restSearch
import apiclient.localizations.LocalizationArg
import apiclient.markers.toSearchContext
import apiclient.notifications.deleteNotification
import apiclient.notifications.setNotificationState
import apiclient.tags.getUniqueTag
import apiclient.tags.setUniqueTag
import apiclient.tags.tag
import apiclient.validations.parseEnumValue
import auth.ApiUserStore
import data.users.settings.LocalSettingsStore
import dev.fritz2.core.RootStore
import dev.fritz2.core.invoke
import koin.koinCtx
import kotlin.coroutines.EmptyCoroutineContext
import kotlinx.coroutines.CoroutineName
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.conflate
import kotlinx.coroutines.flow.debounce
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.mapNotNull
import kotlinx.coroutines.launch
import mainmenu.AppStateStore
import map.MapStateStore
import model.AppPhase
import model.LocalSettings
import model.NotificationNumbers
import model.manualSearch
import overlays.BusyStore
import search.SearchContextsStore
import search.hasUserId
import search.isNotAnonymous
import search.searchlayer.MapSearchClientsStore

class GlobalNotificationResultsStore : RootStore<NotificationNumbers>(
    initialData = NotificationNumbers(),
    job = Job(),
) {

    val formationClient: FormationClient by koinCtx.inject()
    private val searchContextsStore: SearchContextsStore by koinCtx.inject()
    private val notificationSearchInputFieldStore: NotificationSearchInputFieldStore by koinCtx.inject()
    private val mapStateStore: MapStateStore by koinCtx.inject()
    private val mapSearchClientsStore: MapSearchClientsStore by koinCtx.inject()
    private val localSettingsStore: LocalSettingsStore by koinCtx.inject()
    private val searchManually = localSettingsStore.map(LocalSettings.manualSearch())
    private val appStateStore by koinCtx.inject<AppStateStore>()
    private val apiUserStore by koinCtx.inject<ApiUserStore>()
    private val busyStore by koinCtx.inject<BusyStore>()
    private val isAnonymous = apiUserStore.data.map { it.apiUser?.isAnonymous ?: true }

    val reset = handle {
        console.log("Reset GlobalNotificationResultsStore")
        NotificationNumbers()
    }

    val insertNewNotification = handle<GeoObjectDetails> { current, newNotification ->
        current.copy(
            unread = listOf(newNotification).plus(current.unread),
            unreadNumber = current.unreadNumber + 1
        )
    }

    private val globalNotificationSearch = handle { current ->
        val notificationCtx = searchContextsStore.current.notificationSearch
        if (notificationCtx.hasUserId() && notificationCtx.isNotAnonymous()) { // double check here to not fire notification search if user is anonymous
            busyStore.handleApiCall(
                supplier = suspend {
                    formationClient.restSearch(
                        searchQueryContext = notificationCtx.toSearchContext(restrictIndoorOutdoor = false).copy(
                            objectTypes = listOf(ObjectType.Notification),
                        )
                    )
                },
                processResult = { result ->
                    val searchResults = result.hits.map { it.hit }
                    val read =
                        searchResults.filter { it.tags.contains(NotificationTags.NotificationState.tag(NotificationState.READ)) }
                    val unread =
                        searchResults.filter { !it.tags.contains(NotificationTags.NotificationState.tag(NotificationState.READ)) }
                    val newData = NotificationNumbers(
                        read = read,
                        readNumber = read.size,
                        unread = unread,
                        unreadNumber = unread.size,
                    )
                    update(newData)
                },
                processError = { throwable ->
                    console.log("Notification search failed", throwable)
                }
            )
        }
        current
    }

    val triggerSearch = handle { current ->
        console.log("Notification search triggered!")
        CoroutineScope(EmptyCoroutineContext).launch {
            globalNotificationSearch()
        }
        current
    }

    fun isOrHasUnreadNotification(obj: GeoObjectDetails): Boolean {
        return if (obj.objectType == ObjectType.Notification) {
            obj.id in current.unread.map { it.id }
        } else obj.id in current.unread.map { it.connectedTo }
    }

    fun isOrHasUnreadNotification(objType: ObjectType, objId: String): Boolean {
        return if (objType == ObjectType.Notification) {
            objId in current.unread.map { it.id }
        } else objId in current.unread.map { it.connectedTo }
    }

    fun totalUnreadNotifications(): Int {
        return current.unreadNumber
    }

    fun totalObjectNotifications(objId: String): Int {
        return (current.read + current.unread).filter { it.tags.getUniqueTag(ObjectTags.ConnectedTo) == objId }.size
    }

    fun totalUnreadObjectNotifications(objId: String): Int {
        return current.unread.filter {
            !it.tags.contains(NotificationTags.NotificationState.tag(NotificationState.READ))
                    && it.tags.getUniqueTag(ObjectTags.ConnectedTo) == objId
        }.size
    }

    val markAllAsRead = handle { current ->
        val newResults = current.unread
        CoroutineScope(CoroutineName("mark-all-notifications-as-read")).launch {
            console.log("Mark all new notification as read:", newResults.map { it.id }.toString())
            newResults.reversed().forEach { notification ->
                formationClient.setNotificationState(id = notification.id, notificationState = NotificationState.READ)
            }
        }.invokeOnCompletion {
            newResults.map { notificationObj ->
                parseEnumValue<ObjectType>(notificationObj.tags.getUniqueTag(LocalizationArg.OBJECT_TYPE))
            }.toSet().forEach { objType ->
                objType?.let {
                    mapSearchClientsStore.invalidateLayerCaches(it)
                }
            }
        }
        val newReadResults = newResults.map {
            it.copy(
                tags = it.tags.setUniqueTag(
                    NotificationTags.NotificationState,
                    NotificationState.READ
                )
            )
        }

        NotificationNumbers(
            read = newReadResults + current.read,
            readNumber = (newReadResults + current.read).size,
            unread = emptyList(),
            unreadNumber = 0,
        )
    }

    val delete = handle<String> { current, notificationId ->

        busyStore.handleApiCall(
            supplier = suspend {
                formationClient.deleteNotification(id = notificationId)
            },
            processResult = { result ->
                console.log("Delete Notification", result)
                triggerSearch()
            },
            processError = { throwable ->
                console.log("Deleting Notification failed", throwable)
            }
        )
        current
    }

    suspend fun markAsRead(notificationId: String) {
        busyStore.handleApiCall(
            supplier = suspend {
                formationClient.setNotificationState(id = notificationId, notificationState = NotificationState.READ)
            },
            processResult = { result ->
                console.log("Set NotificationState", result)
                (current.unread + current.read).firstOrNull { it.id == notificationId }?.let { notificationObj ->
                    parseEnumValue<ObjectType>(notificationObj.tags.getUniqueTag(LocalizationArg.OBJECT_TYPE))?.let { connectedObjType ->
                        mapSearchClientsStore.invalidateLayerCaches(connectedObjType)
                    }
                }
                triggerSearch()
            },
            processError = { throwable ->
                console.log("Marking Notification as read failed", throwable)
            }
        )
    }

    init {
        apiUserStore.data.map { } handledBy reset
        combine(
            notificationSearchInputFieldStore.data,
            mapStateStore.data,
            searchManually.data,
            appStateStore.data,
        ) { _, _, manualSearch, loginState ->
            if (manualSearch || loginState.appPhase != AppPhase.LoggedIn) null else Unit
        }.mapNotNull { it }.conflate().debounce(5000) handledBy triggerSearch
    }

}
