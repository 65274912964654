package workspacetools.usermanagement

import com.tryformation.localization.Translatable

enum class DashboardTexts : Translatable {
    LoadingDashboard,
    DashboardTitle,
    DashboardExplainerShort,
    DashboardExplainerLong,
    OpenEditButton,
    WaitMessage,
    ZoneHistoryButton,
    Data,
    DataTableName,
    DataTableEmail,
    DataTablePhone,
    DataTableAction,
    DataRole,
    DashboardWorkspaceMembers,
    NoMemberSelected,
    InviteNewMemberButton,
    InviteUserTitle,
    InviteUserEmail,
    InviteUserInviteButton,
    InviteUserExplainer,
    InviteUserSuccessful,
    InviteUserFailed,
    SetNewPasswordTitle,
    SetNewPasswordButton,
    SetNewPasswordSuccess,
    SetNewPasswordFailed,
    GeneratePasswordButton,
    ConfirmationTitle,
    DeleteUserButton,
    DeleteUserAreYouSure,
    DeleteUserSuccessful,
    DeleteUserFailed,
    ChangeRoleButton,
    ChangeRoleTitle,
    ChangeRoleExplainer,
    ChangeRoleCurrentRole,
    ChangeRoleSuccessful,
    ChangeRoleFailed,
    ;

    override val prefix = "admin-dashboard"
}
