package workspacetools.buildingeditor

import apiclient.geoobjects.LatLon
import dev.fritz2.core.RootStore
import kotlin.random.nextULong
import kotlin.time.Duration
import kotlinx.coroutines.Job
import map.views.MapStyleOnline
import maplibreGL.ImageSource
import maplibreGL.ImageSourceOptions
import maplibreGL.LngLat
import maplibreGL.Map
import maplibreGL.MapLayer
import maplibreGL.Marker
import maplibreGL.NavigationControl
import org.w3c.dom.HTMLDivElement
import utils.obj

class BuildingEditorMapState(val lngLat: LngLat = LngLat(0.0, 0.0), val zoom: Number = 1) {
    override fun toString(): String {
        return "lat: ${lngLat.lat},lon: ${lngLat.lng} @ $zoom"
    }
}

class BuildingEditorMapStateStore : RootStore<BuildingEditorMapState?>(null, Job())

private const val sourceId = "buildingAndFloorEditor"
private const val layerId = "$sourceId-currentFloor"

class BuildingEditorMapManager(
    val buildingEditorMapStateStore: BuildingEditorMapStateStore,
    val imagePositionStore: ImageRotationAndScaleStore,
) {
    val mapContainerId by lazy { "map-${kotlin.random.Random.nextULong()}" }

    var map: Map? = null
    private var initialized = false

    private val state: BuildingEditorMapState
        get() = BuildingEditorMapState(map?.getCenter() ?: LngLat(0.0, 0.0), map?.getZoom() ?: 1)

    fun init(imageCenter: LatLon, zoomLevel: Double) {
        console.log("init map")
        // FIXME make this configurable and changable
        val url = MapStyleOnline.MapTilerBasic.url
        val theMap = maplibreGL.Map(
            obj {
                container = mapContainerId
                style = url
                center = arrayOf(imageCenter.lon, imageCenter.lat)
                zoom = zoomLevel
            },
        )
        theMap.on("move") { buildingEditorMapStateStore.update(state) }

        theMap.on("drag") { buildingEditorMapStateStore.update(state) }

        theMap.addControl(NavigationControl(obj { }), "top-left")
        theMap.on("load") {
            if (!initialized) {
                initialized = true
                console.log("map load")
                buildingEditorMapStateStore.update(state)
                updateFloorImage(imagePositionStore.current)
            }
        }

        map = theMap
    }

    /**
     * Animates the map's center, zoom level, and optionally the bearing and pitch, to a new target using an easing curve.
     *
     * @param center The target coordinates (latitude and longitude) for the map center as a Pair of Doubles.
     * @param zoom The target zoom level for the map. A higher value indicates a closer zoom.
     *             The unit is a floating-point number where larger numbers represent more zoomed-in views.
     * @param curve Controls the curvature of the zooming motion. Higher values create a more curved path.
     *              A default value of 1.42 is typically smooth.
     * @param maxZoom The maximum zoom level that should be allowed during the animation.
     *                If null, no limit is applied. Unit is the zoom level as a floating-point number.
     * @param speed Defines how fast the zooming animation occurs. Higher values make the animation faster.
     *              Default is 2.0, and the unit is a factor where 1.0 is normal speed.
     * @param screenSpeed Optional. If provided, this defines the speed relative to the screen size, in pixels per second.
     *                    This overrides the regular speed setting. Unit is pixels/second.
     * @param maxDuration Optional. If provided, this sets the maximum allowed duration for the animation as a [Duration].
     *                    If null, the duration will be calculated based on the speed and distance.
     * @param bearing The target bearing (rotation) of the map in degrees. Positive values rotate clockwise, negative counterclockwise.
     *                Default is 0.0 (north). Unit is degrees.
     * @param pitch The target pitch (tilt) of the map in degrees. Default is 0.0 for a flat view, higher values tilt the view.
     *              Unit is degrees.
     * @param essential Marks whether the animation is essential (if true) to accommodate system-wide reduced motion settings.
     * @param animate Whether the transition should animate smoothly or jump instantly to the new target. Default is true.
     */
    fun flyTo(
        center: LatLon,
        zoom: Double = 17.0,
        curve: Double = 1.42,
        maxZoom: Double? = null,
        speed: Double = 3.0,
        screenSpeed: Double? = null,
        maxDuration: Duration? = null,
        bearing: Double? = null,
        pitch: Double? = null,
        essential: Boolean? = null,
        animate: Boolean = true,
    ) {
        map = map?.flyTo(
            obj {
                this.center = arrayOf(center.lon, center.lat)
                this.zoom = zoom
                this.curve = curve
                if (maxZoom != null) this.maxZoom = maxZoom
                this.speed = speed
                if (screenSpeed != null) this.screenSpeed = screenSpeed
                if (maxDuration != null) this.maxDuration = maxDuration.inWholeMilliseconds
                if (bearing != null) this.bearing = bearing
                if (pitch != null) this.pitch = pitch
                if (essential != null) this.essential = essential
                this.animate = animate
            },
        )
    }

    fun updateFloorImage(imagePosition: ImageRotationAndScale?) {
        runCatching {
            if (initialized) {
                // always remove layer before re-adding it
                // this allows us to reliably add/remove the layer
                if (imagePosition == null) {
                    runCatching {
                        map?.getLayer(layerId)?.let {
                            map = map?.removeLayer(layerId)
                        }
                    }
                    runCatching {
                        map?.getSource(sourceId)?.let {
                            map = map?.removeSource(sourceId)
                        }
                    }
                } else {
                    @Suppress("UnsafeCastFromDynamic") val srcDef: ImageSourceOptions = obj {
                        type = "image"
                        url = imagePosition.imageResource.src
                        coordinates = imagePosition.rotatedLocation().coordinates
                    }

                    val existing = map?.getSource(sourceId)
                    if (map?.getSource(sourceId) != null && existing is ImageSource) {
                        console.log("UPDATING SOURCE")
                        existing.updateImage(srcDef)
                    } else {
                        console.log("ADDING SOURCE")
                        map = map?.addSource(sourceId, srcDef)
                    }
                    val paintConf = js("({})")
                    paintConf["raster-opacity"] = 0.5
                    @Suppress("UnsafeCastFromDynamic") val conf: MapLayer = obj {
                        id = layerId
                        source = sourceId
                        type = "raster"
                        paint = paintConf
                    }
                    val existingLayer = map?.getLayer(layerId)
                    if (existingLayer == null) {
                        console.log("ADDING LAYER")
                        map = map?.addLayer(conf)
                    }
                }
            }
        }
    }

    fun setLayerOpacityForFloor(opacity: Number) {
        runCatching {
            if(map?.getLayer(layerId) != null) {
                map?.setPaintProperty(layerId, "raster-opacity", opacity)
            }
        }
    }

    fun addDraggableMarker(initialPosition: LngLat, block: HTMLDivElement.() -> Unit): Marker {
        val markerElement = kotlinx.browser.document.createElement("div") as HTMLDivElement
        block.invoke(markerElement)

        // Create a draggable marker
        val marker = Marker(
            obj {
                draggable = true
                element = markerElement
            },
        )
        marker.setLngLat(initialPosition)

        marker.addTo(map)

        return marker
    }
}
