package auth.permissions

import dev.fritz2.core.RenderContext
import dev.fritz2.core.SimpleHandler
import dev.fritz2.core.viewBox
import koin.koinCtx
import kotlinx.coroutines.flow.flowOf
import localization.TL
import localization.Translation
import theme.FormationColors
import theme.FormationDefault.Companion.formationStyles
import theme.FormationIcons
import twcomponents.twCardSectionH3
import web.permissions.PermissionState
import webcomponents.circleIconButton
import webcomponents.fullPageConfirmation
import webcomponents.fullPageConfirmationContainer

fun RenderContext.locationPermissionPrompt(closeHandlers: List<SimpleHandler<Unit>>) {
    val permissionsService by koinCtx.inject<PermissionsService>()
    val translation by koinCtx.inject<Translation>()

    // modal dialog closes by itself once login checks complete
    fullPageConfirmation {
        fullPageConfirmationContainer(
            width = { "400px" },
            paddings = {
                top { huge }
                horizontal { normal }
                bottom { normal }
            },
        ) {
            // LOGO
            svg("h-24") {
                viewBox(FormationIcons.LocationAccess.icon.iconDefViewBox)
                content(FormationIcons.LocationAccess.icon.iconDefSvg)
            }
            twCardSectionH3 {
                translation[TL.PermissionPromptTexts.LOCATION_PERMISSION_TITLE].renderText(into = this)
            }

            p("p-4 text-center") { translation[TL.PermissionPromptTexts.LOCATION_PERMISSION_TEXT].renderText(into = this) }

            permissionsService.data.render { permissionStates ->
                val state = permissionStates[PermissionType.Geolocation]?.permissionState
                when (state) {
                    PermissionState.granted -> {
                        p("my-2 p-2 text-center bg-green-200 rounded-xl") { translation[TL.PermissionPromptTexts.LOCATION_PERMISSION_GRANTED].renderText(into = this) }
                    }

                    PermissionState.prompt -> {
                        p("my-2 p-2 text-center bg-green-200 rounded-xl") { translation[TL.PermissionPromptTexts.LOCATION_PERMISSION_TEXT].renderText(into = this) }
                    }

                    PermissionState.denied -> {
                        p("my-2 p-2 text-center bg-red-200 rounded-xl") { translation[TL.PermissionPromptTexts.LOCATION_PERMISSION_DENIED].renderText(into = this) }
                    }

                    else -> {}
                }
                // Close button
                circleIconButton(
                    iconFlow = flowOf { close },
                    iconSize = { huge },
                    size = { "40px" },
                    styleFlow = flowOf {
                        background { color { secondary.main } }
                        color { primary.main }
                        border {
                            width(formationStyles.borderWidth)
                            color { primary.main }
                        }
                        hover {
                            background { color { FormationColors.GrayLight.color } }
                            color { primary.main }
                        }
                        flex {
                            shrink { "0" }
                        }
                    },
                    value = Unit,
                    clickHandlers = closeHandlers,
                )
            }
        }
    }
}

fun RenderContext.cameraPermissionPrompt(closeHandlers: List<SimpleHandler<Unit>>) {
    val permissionsService by koinCtx.inject<PermissionsService>()

    // modal dialog closes by itself once login checks complete
    fullPageConfirmation {
        fullPageConfirmationContainer(
            width = { "400px" },
            paddings = {
                top { huge }
                horizontal { normal }
                bottom { normal }
            },
        ) {
            // LOGO
            svg("h-24") {
                viewBox(FormationIcons.Camera.icon.iconDefViewBox)
                content(FormationIcons.Camera.icon.iconDefSvg)
            }
            twCardSectionH3 {
                +"LOCATION ACCESS"
            }

            p("p-4 text-center") { +"FORMATION requires this devices camera to scan codes of tracked assets." }

            permissionsService.data.render { permissionStates ->
                val state = permissionStates[PermissionType.Camera]?.permissionState
                when (state) {
                    PermissionState.granted -> {
                        p("my-2 p-2 text-center bg-green-200 rounded-xl") { +"Permission granted. Thank you! :)" }
                    }

                    PermissionState.prompt -> {

                        p("my-2 p-2 text-center bg-green-200 rounded-xl") { +"Please grant the permission. Thank you! :)" }
                    }

                    PermissionState.denied -> {
                        p("my-2 p-2 text-center bg-red-200 rounded-xl") { +"Permission is currently denied. Please reset the permissions in your Browser for this web app and click allow next time you get the prompt." }
                    }

                    else -> {}
                }
                // Close button
                circleIconButton(
                    iconFlow = flowOf { close },
                    iconSize = { huge },
                    size = { "40px" },
                    styleFlow = flowOf {
                        background { color { secondary.main } }
                        color { primary.main }
                        border {
                            width(formationStyles.borderWidth)
                            color { primary.main }
                        }
                        hover {
                            background { color { FormationColors.GrayLight.color } }
                            color { primary.main }
                        }
                        flex {
                            shrink { "0" }
                        }
                    },
                    value = Unit,
                    clickHandlers = closeHandlers,
                )
            }
        }
    }
}
