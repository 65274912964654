// GENERATED by fritz2 - NEVER CHANGE CONTENT MANUALLY!
package model

import apiclient.geoobjects.SearchQueryContext
import apiclient.markers.MapLayerContext
import dev.fritz2.core.Lens
import dev.fritz2.core.lensOf

fun SearchContexts.Companion.mapSearch(): Lens<SearchContexts, MapLayerContext> = lensOf(
    "mapSearch",
    { it.mapSearch },
    { p, v -> p.copy(mapSearch = v) }
)

fun SearchContexts.Companion.globalSearch(): Lens<SearchContexts, SearchQueryContext> =
    lensOf(
        "globalSearch",
        { it.globalSearch },
        { p, v -> p.copy(globalSearch = v) }
    )

fun SearchContexts.Companion.notificationSearch(): Lens<SearchContexts, MapLayerContext> =
    lensOf(
        "notificationSearch",
        { it.notificationSearch },
        { p, v -> p.copy(notificationSearch = v) }
    )

fun SearchContexts.Companion.areaSearch(): Lens<SearchContexts, MapLayerContext> = lensOf(
    "areaSearch",
    { it.areaSearch },
    { p, v -> p.copy(areaSearch = v) }
)

fun SearchContexts.Companion.hubSearch(): Lens<SearchContexts, SearchQueryContext> = lensOf(
    "hubSearch",
    { it.hubSearch },
    { p, v -> p.copy(hubSearch = v) }
)

fun SearchContexts.Companion.analyticsPaths(): Lens<SearchContexts, SearchQueryContext> = lensOf(
    "analyticsPaths",
    { it.analyticsPaths },
    { p, v -> p.copy(analyticsPaths = v) }
)

fun SearchContexts.Companion.analyticsHeatmaps(): Lens<SearchContexts, SearchQueryContext> = lensOf(
    "analyticsHeatmaps",
    { it.analyticsHeatmaps },
    { p, v -> p.copy(analyticsHeatmaps = v) }
)
