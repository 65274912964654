package theme

import dev.fritz2.styling.params.ColorProperty

enum class FormationColors {
    // PRIMARY COLORS
    BlueDeep {
        override val color: ColorProperty = "#0E292F"
    },
    BlueLight {
        override val color: ColorProperty = "#4BC3DE"
    },
    BlueMarine {
        override val color: ColorProperty = "#256B7A"
    },
    BlueUltramarine {
        override val color: ColorProperty = "#12333B"
    },
    GreenBright {
        override val color: ColorProperty = "#03DD62"
    },
    GreenLight {
        override val color: ColorProperty = "#45ED8B"
    },
    GreenForest {
        override val color: ColorProperty = "#02C456"
    },
    GreenDeepForest {
        override val color: ColorProperty = "#015E29"
    },
    Black {
        override val color: ColorProperty = "#000000"
    },
    White {
        override val color: ColorProperty = "#FFFFFF"
    },

    // GRAYS
    GrayLight {
        override val color: ColorProperty = "#F1F1F2"
    },
    GrayPrivate {
        override val color: ColorProperty = "#C4C4C4"
    },
    GrayDisabled {
        override val color: ColorProperty = "#848484"
    },

    // SIGNAL COLORS
    GreenActive {
        override val color: ColorProperty = "#02C456" // = GreenForest
    },
    RedError {
        override val color: ColorProperty = "#FF5030"
    },
    YellowDoing {
        override val color: ColorProperty = "#FFAD1C"
    },

    // DEFAULT MARKER COLORS
    MarkerTask {
        override val color: ColorProperty = "#4B5ADE"
    },
    MarkerYou {
        override val color: ColorProperty = "#1AA3FC"
    },
    MarkerDevices {
        override val color: ColorProperty = "#00A497"
    },
    MarkerPoint {
        override val color: ColorProperty = "#12333B" // = BlueUltramarine
    },
    MarkerMeeting {
        override val color: ColorProperty = "#256B7A" // = BlueMarine
    },

    // CUSTOM MARKER COLORS
    GreenLightCustom {
        override val color: ColorProperty = "#A2F816"
    },
    GreenCustom {
        override val color: ColorProperty = "#65E363"
    },
    TurquoiseCustom {
        override val color: ColorProperty = "#00D7B0"
    },
    GreenDarkCustom {
        override val color: ColorProperty = "#00AE1C"
    },
    BlueLightCustom {
        override val color: ColorProperty = "#ACDAFC"
    },
    BlueAuqamarineCustom {
        override val color: ColorProperty = "#16C1F8"
    },
    BlueCustom {
        override val color: ColorProperty = "#067BBD"
    },
    BlueDarkCustom {
        override val color: ColorProperty = "#085A88"
    },
    YellowCustom {
        override val color: ColorProperty = "#F8E116"
    },
    OrangeCustom {
        override val color: ColorProperty = "#FFA927"
    },
    RedCustom {
        override val color: ColorProperty = "#FF2727"
    },
    RedDarkCustom {
        override val color: ColorProperty = "#B71C2F"
    },
    GrayLightCustom {
        override val color: ColorProperty = "#F1F1F1"
    },
    GrayCustom {
        override val color: ColorProperty = "#5E5E5E"
    },
    LightGreenAltCustom {
        override val color: ColorProperty = "#cede42"
    },
    GreenAltCustom {
        override val color: ColorProperty = "#50bb68"
    },
    LightBlueAltCustom {
        override val color: ColorProperty = "#43a2da"
    },
    DarkOrangeCustom {
        override val color: ColorProperty = "#d06f28"
    },
    DarkMagentaCustom {
        override val color: ColorProperty = "#a63259"
    },

    // OTHERS
    FontColor {
        override val color: ColorProperty = "#000000"
    },
    BackgroundColor {
        override val color: ColorProperty = "#FFFFFF"
    },
    BlueMidnight {
        override val color: ColorProperty = "#484E98"
    },
    BlueSky {
        override val color: ColorProperty = "#7B83EB"
    },
    BlueLavender {
        override val color: ColorProperty = "#B2B7FC"
    },
    GraySilver {
        override val color: ColorProperty = "#C7D1D3"
    },
    GraySteel {
        override val color: ColorProperty = "#888E8F"
    },
    GraySlate {
        override val color: ColorProperty = "#696E70"
    },
    GreenMoss {
        override val color: ColorProperty = "#1B594E"
    },
    GreenTurquoise {
        override val color: ColorProperty = "#2CC6AB"
    },
    GreenMint {
        override val color: ColorProperty = "#A7E6DB"
    },
    GreenSoft {
        override val color: ColorProperty = "#ACECC8"
    },
    GreenVibrant {
        override val color: ColorProperty = "#3EE185"
    },
    GreenFresh {
        override val color: ColorProperty = "#19944F"
    },
    RedDarkCrimson {
        override val color: ColorProperty = "#8A0D0D"
    },
    RedFire {
        override val color: ColorProperty = "#E35757"
    },
    RedSalmon {
        override val color: ColorProperty = "#F98787"
    },
    OrangePeach {
        override val color: ColorProperty = "#F9CE8E"
    },
    OrangeAmber {
        override val color: ColorProperty = "#FBA31F"
    },
    OrangeRust {
        override val color: ColorProperty = "#B06B02"
    },
    ;

    abstract val color: ColorProperty
    val inverseColor: ColorProperty get() = color.inverseColor()
}

private fun String.inverseColor(): String {
    // Remove the hash at the start if it's there
    val hex = this.removePrefix("#")

    // Convert hex to RGB
    val r = hex.substring(0, 2).toInt(16)
    val g = hex.substring(2, 4).toInt(16)
    val b = hex.substring(4, 6).toInt(16)

    // Invert each component
    val invertedR = (255 - r).toString(16).padStart(2, '0')
    val invertedG = (255 - g).toString(16).padStart(2, '0')
    val invertedB = (255 - b).toString(16).padStart(2, '0')

    // Return the inverted color as a hex string with a leading hash
    return "#$invertedR$invertedG$invertedB"
}
