package webcomponents

import dev.fritz2.components.compat.button
import dev.fritz2.components.compat.input
import dev.fritz2.components.compat.span
import dev.fritz2.components.flexBox
import dev.fritz2.components.icon
import dev.fritz2.components.lineUp
import dev.fritz2.components.stackUp
import dev.fritz2.core.HtmlTag
import dev.fritz2.core.RenderContext
import dev.fritz2.core.SimpleHandler
import dev.fritz2.core.storeOf
import dev.fritz2.core.value
import dev.fritz2.core.values
import dev.fritz2.styling.params.FontWeightProperty
import dev.fritz2.styling.params.FontWeights
import dev.fritz2.styling.params.ScaledValueProperty
import dev.fritz2.styling.params.SizesProperty
import dev.fritz2.styling.theme.IconDefinition
import dev.fritz2.styling.theme.Icons
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.mapNotNull
import org.w3c.dom.HTMLDivElement
import theme.FormationColors
import theme.FormationUIIcons

fun RenderContext.cardTitleWithSubtitleDirectEdit(
    titleFlow: Flow<String>,
    title: String,
    titleMaxLines: Int = 1,
    titleIconFlow: Flow<IconDefinition>? = null,
    titleEllipses: Boolean = true,
    subtitle: Flow<String>,
    subtitleMaxLines: Int = 2,
    subtitleIconFlow: Flow<IconDefinition?>? = null,
    subtitleEllipses: Boolean = false,
    iconBox: (HtmlTag<HTMLDivElement>.() -> Unit)? = null,
    bigIcon: (Icons.() -> IconDefinition)? = null,
    changeHandler: SimpleHandler<String>,
) {
    flexBox(
        {
            width { full }
            alignItems { center }
            margins { right { small } }
            overflowX { hidden }
        },
    ) {
        if (bigIcon != null) {
            icon(
                {
                    margins { right { smaller } }
                    size { "42px" }
                },
            ) { fromTheme(bigIcon) }
        }
        if (iconBox != null) {
            flexBox(
                {
                    margins { right { smaller } }
                },
            ) {
                iconBox.invoke(this)
            }
        }
        stackUp(
            {
                width { full }
                alignItems { flexStart }
                overflowX { hidden }
            },
        ) {
            spacing { none }
            items {
                flexBox(
                    {
                        width { full }
                        height { maxContent }
                        alignItems { center }
                        overflow { hidden }
                    },
                ) {
                    if (titleIconFlow != null) {
                        titleIconFlow.render { titleIcon ->
                            icon(
                                {
                                    size { normal }
                                    margins { right { smaller } }
                                },
                            ) { fromTheme { titleIcon } }
                        }
                    } else if (subtitleIconFlow != null) {
                        // dummy icon to match indent
                        icon(
                            {
                                size { normal }
                                margins { right { smaller } }
                            },
                        ) { }
                    }
                    directEditInput(
                        width = { full },
                        fontSize = { large },
                        fontWeight = { bold },
                        ellipses = titleEllipses,
                        changeHandler = changeHandler,
                        initValue = title,
                        initValueFlow = titleFlow,
                    )
                }
                flexBox(
                    {
                        width { full }
                        height { maxContent }
                        alignItems { center }
                        overflow { hidden }
                    },
                ) {
                    if (subtitleIconFlow != null) {
                        subtitleIconFlow.render { subtitleIcon ->
                            if (subtitleIcon != null) {
                                icon(
                                    {
                                        size { normal }
                                        margins { right { smaller } }
                                    },
                                ) { fromTheme { subtitleIcon } }
                            } else if (titleIconFlow != null) {
                                // dummy icon to match indent
                                icon(
                                    {
                                        size { normal }
                                        margins { right { smaller } }
                                    },
                                ) { }
                            }
                        }
                    } else if (titleIconFlow != null) {
                        // dummy icon to match indent
                        icon(
                            {
                                size { normal }
                                margins { right { smaller } }
                            },
                        ) { }
                    }
                    if (subtitleEllipses) {
                        ellipseText(
                            {
                                width { full }
                                fontSize { small }
                                fontWeight { lighter }
                            },
                            maxLines = subtitleMaxLines,
                        ) { subtitle.renderText(into = this) }
                    } else {
                        span(
                            {
                                width { full }
                                fontSize { small }
                                fontWeight { lighter }
                                css(
                                    """
                                overflow-wrap: break-word;
                                word-wrap: break-word;
                            """.trimIndent(),
                                )
                            },
                        ) { subtitle.renderText(into = this) }
                    }
                }
            }
        }
    }
}


fun <T> RenderContext.directEditInput(
    width: SizesProperty = { auto },
    height: SizesProperty = { auto },
    titleMaxLines: Int = 1,
    fontSize: ScaledValueProperty = { normal },
    fontWeight: FontWeights.() -> FontWeightProperty = { normal },
    ellipses: Boolean = true,
    changeHandler: SimpleHandler<T>,
    initValueFlow: Flow<T>,
    initValue: T,
) {
    val valueStore = storeOf<T>(initValue)
    val editState = storeOf(false)
    initValueFlow handledBy valueStore.update

    lineUp(
        {
            width(width)
            alignItems { center }
            justifyContent { spaceBetween }
        },
    ) {
        spacing { small }
        items {
            button(
                {
                    width(width)
                },
            ) {
                editState.data.render { edit ->
                    if (edit) {
                        input(
                            {
                                fontSize(fontSize)
                                fontWeight(fontWeight)
                                width(width)
                                height(height)
                                border {
                                    width { "1px" }
                                    color { FormationColors.MarkerYou.color }
                                }
                                radius { large }
                                paddings { horizontal { tiny } }
                            },
                            id = "directEditTitleInput",
                        ) {
                            value(initValueFlow.mapNotNull { it as? String })
                            inputs.values().mapNotNull { value -> value as? T } handledBy valueStore.update

                            // TODO fix on focus not triggering, when save button was pressed
//                            with(focusouts) {
//                                this.map { initValue } handledBy valueStore.update
//                                this.map { false } handledBy editState.update
//                            }
                        }
                    } else {
                        if (ellipses) {
                            ellipseText(
                                {
                                    width(width)
                                    height(height)
                                    fontSize(fontSize)
                                    fontWeight(fontWeight)
                                    radius { large }
                                    border {
                                        width { "1px" }
                                        color { secondary.main }
                                    }
                                    hover {
                                        border {
                                            width { "1px" }
                                            color { FormationColors.MarkerYou.color }
                                        }
                                    }
                                },
                                maxLines = titleMaxLines,
                            ) {
                                initValueFlow.renderText(into = this)
                            }
                        } else {
                            span(
                                {
                                    width(width)
                                    height(height)
                                    fontSize(fontSize)
                                    fontWeight(fontWeight)
                                    border {
                                        width { "1px" }
                                        color { secondary.main }
                                    }
                                },
                            ) {
                                initValueFlow.renderText(into = this)
                            }
                        }
                    }
                }
                clicks.map { true } handledBy editState.update
            }
            editState.data.render { edit ->
                if (edit) {
                    button(
                        {
                            height { "32px" }
                            margins { left { tiny } }
                            border {
                                width { "1px" }
                            }
                            fontSize { smaller }
                            paddings {
                                vertical { tiny }
                                horizontal { smaller }
                            }
                            radius { large }
                            hover {
                                color { secondary.main }
                                background {
                                    color { FormationColors.MarkerYou.color }
                                }
                            }
                        },
                    ) {
                        lineUp(
                            {
                                alignItems { center }
                                justifyContent { spaceBetween }
                            },
                        ) {
                            spacing { tiny }
                            items {
//                                span { +"SAVE" }
                                icon(
                                    {
                                        size { normal }
                                    },
                                ) { fromTheme { FormationUIIcons.Check.icon } }
                            }
                        }
                        with(clicks) {
                            this.map { valueStore.current } handledBy changeHandler
                            this.map { false } handledBy editState.update
                        }
                    }
                    button(
                        {
                            height { "32px" }
                            margins { left { tiny } }
                            border {
                                width { "1px" }
                            }
                            fontSize { smaller }
                            paddings {
                                vertical { tiny }
                                horizontal { smaller }
                            }
                            radius { large }
                            hover {
                                color { secondary.main }
                                background {
                                    color { FormationColors.BlueDeep.color }
                                }
                            }
                        },
                    ) {
                        icon(
                            {
                                size { normal }
                            },
                        ) { fromTheme { close } }
                        with(clicks) {
                            this.map { initValue } handledBy valueStore.update
                            this.map { false } handledBy editState.update
                        }
                    }
                }
            }
        }
    }
}
