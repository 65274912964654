package layercache

import apiclient.geoobjects.GeoObjectDetails
import apiclient.util.formatIsoDate
import indexeddb.IDBRepository
import kotlin.time.Duration.Companion.days
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.take
import kotlinx.coroutines.flow.toList
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import web.idb.IDBKeyRange


@Serializable
data class GeoObjectDetailsCacheEntry(
    val id: String,
    val retrieveTime: String,
    val obj: GeoObjectDetails,
    ) {

    constructor(obj: GeoObjectDetails) : this(obj.id, Clock.System.now().formatIsoDate(), obj)
}

val GeoObjectDetails.newCacheEntry get() = GeoObjectDetailsCacheEntry(this)

// FIXME before switching, find a way to keep track of fetched time so we can auto-refresh stale content as well
class GeoObjectDetailsDatabase(
    val idb: IDBRepository<String, GeoObjectDetailsCacheEntry>
) {

    suspend fun getOutdated(limit: Int = 500): List<String> {
        return idb.queryKeyRange(
            IDBKeyRange.bound("", (Clock.System.now() - 2.days).formatIsoDate()),
            web.idb.IDBCursorDirection.prev,
            GeoObjectDetailsCacheEntry::retrieveTime.name,
        ).take(limit).map { it.id }.toList()
    }

    suspend fun size(): Int {
        return idb.count()
    }

    suspend fun clear() {
//        val ids  = idb.query().toList()
//        ids.forEach {
//            idb.delete(it.id)
//        }
        idb.clearStore()
    }

    suspend fun putAll(entries: List<GeoObjectDetails>) {
        val now = Clock.System.now()
        entries.forEach {
            idb.put(it.newCacheEntry)
        }
    }

    suspend fun putAll(entries: List<GeoObjectDetailsCacheEntry>) {
        val now = Clock.System.now()
        entries.forEach {
            idb.put(it)
        }
    }

    suspend fun put(
        v: GeoObjectDetails,
        now: Instant = Clock.System.now(),
    ) {
        idb.put(v.newCacheEntry)
    }

    suspend fun getOrFetchById(
        k: String,
        skipCache: Boolean = false,
        fetchBlock: suspend (String) -> GeoObjectDetails?,
    ): Pair<GeoObjectDetails, Boolean>? {
        if (skipCache) {
            val fetched = fetchBlock(k)
            if (fetched != null) {
                put(fetched)
            }
            return fetched?.let { it to false }
        } else {
            val value = get(k)
            if (value == null) {
                val fetched = fetchBlock(k)
                if (fetched != null) {
                    put(fetched)
                }
                return fetched?.let { it to false }
            } else {
                return value to true
            }
        }
    }

    suspend fun get(k: String): GeoObjectDetails? {
        return idb.get(k)?.obj
    }

    suspend fun set(k: String, v: GeoObjectDetails) {
        put(v)
    }

    suspend fun delete(id: String) {
        idb.delete(id)
    }
}
