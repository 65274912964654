package services

import apiclient.FormationClient
import apiclient.groups.*

class GroupService(private val formationClient: FormationClient) {


    suspend fun getMembersOfGroup(groupId: String): Result<List<MemberShipResponsePublic>> {
        return formationClient.restListGroupMembersForGroup(groupId = groupId)
    }
    suspend fun listGroups(): Result<List<Group>> {

        val result = formationClient.restListGroups()
        return result.also {r ->
            if (r.isFailure){
                console.error("listGroups: ", r.exceptionOrNull())
            }
        }
    }
    suspend fun createNewGroup(groupName: String):Result<Group>{
        val result = formationClient.restCreateNewGroup(name = groupName)
        return result.also {r ->
            if (r.isFailure){
                console.error("createNewGroup: ", r.exceptionOrNull())
            }
        }
    }
    suspend fun deleteGroup(groupId: String): Result<String>{
        val result = formationClient.restAdminDeleteGroup(groupId = groupId)
        return result.also {r ->
            if (r.isFailure){
                console.error("adminDeleteGroup: ", r.exceptionOrNull())
            }
        }
    }
    suspend fun addUserToGroup(
        userId: String,
        groupId: String,
        roles: List<String>,
    ): Result<MemberShipResponseFull>
    {
        val result = formationClient.restAddUserToGroup(userId = userId, groupId = groupId, roles = roles)
        return result.also {r ->
            if (r.isFailure){
                console.error("addUserToGroup: ", r.exceptionOrNull())
            }
        }
    }
}
