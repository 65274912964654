package data.objects.objecthistory

import analyticsdashboard.AnalyticSidePage
import analyticsdashboard.AnalyticsSidePageStore
import analyticsdashboard.PathActiveHighlightedObjectStore
import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.LatLon
import apiclient.geoobjects.ObjectTags
import apiclient.tags.getUniqueTag
import dev.fritz2.core.RootStore
import dev.fritz2.routing.MapRouter
import koin.koinCtx
import kotlin.js.json
import kotlinx.browser.document
import kotlinx.coroutines.Job
import mainmenu.RouterStore
import map.MapLayersStore
import maplibreGL.MaplibreMap

class ActiveHistoryEntryStore : RootStore<GeoObjectDetails?>(
    initialData = null,
    job = Job(),
) {

    val router: MapRouter by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()
    val maplibreMap: MaplibreMap by koinCtx.inject()
    val mapLayersStore: MapLayersStore by koinCtx.inject()
    private val showObjectHistoryPathStore: ShowObjectHistoryPathStore by koinCtx.inject()
    private val pathActiveHighlightedObjectStore: PathActiveHighlightedObjectStore by koinCtx.inject()
    private val analyticsSidePageStore: AnalyticsSidePageStore by koinCtx.inject()

    val selectHistoryEntry = handle<GeoObjectDetails> { current, selected ->
        // also switch analytics side page
        analyticsSidePageStore.update(AnalyticSidePage.PathsHistory)

        current?.let { maplibreMap.removeActiveObjectOverride(it.id) }
        console.log("History Event:", selected)
        maplibreMap.addActiveObjectOverride(selected)
        maplibreMap.panTo(center = LatLon(selected.latLon.lat, selected.latLon.lon))
        val connectedObjectId = selected.tags.getUniqueTag(ObjectTags.ConnectedTo)
        connectedObjectId?.let { objId ->
            console.log("Connected ID is: $objId")
            pathActiveHighlightedObjectStore.current?.let { maplibreMap.removeActiveObjectOverride(it) }
            maplibreMap.addActiveObjectOverride(objId = objId)
            pathActiveHighlightedObjectStore.update(objId)
        }
        val scrollOptions = json(
            "behavior" to "smooth",
            "block" to "center",
//                "inline" to "nearest"
        )
        document.getElementById("${selected.id}-history-button")?.scrollIntoView(scrollOptions)
        showObjectHistoryPathStore.update(true)
        selected
    }
}
