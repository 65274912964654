package data.objects.objecthistory

import auth.FeatureFlagStore
import auth.Features
import dev.fritz2.core.RootStore
import koin.koinCtx
import kotlinx.coroutines.Job
import map.MapLayersStore
import maplibreGL.MaplibreMap
import model.LayerType

class ShowObjectHistoryPathStore : RootStore<Boolean>(
    initialData = false,
    job = Job(),
) {
    val mapLayersStore by koinCtx.inject<MapLayersStore>()
    val featureFlagStore by koinCtx.inject<FeatureFlagStore>()
    val maplibreMap: MaplibreMap by koinCtx.inject()

    private val flipObjectHistory = handle<Boolean> { current, newValue ->
        if (featureFlagStore.current[Features.AllowHistoryPaths] == true) {
            mapLayersStore.flipLayer(mapOf(LayerType.HistoryPath to newValue))
            maplibreMap.syncMarkersNow(trigger = "flipObjectHistory")
            newValue
        } else current
    }

    val reset = handle { false }

    init {
        data handledBy flipObjectHistory
    }
}
