package camera.nimiq

import camera.nfc.handleScanResult
import data.objects.views.attachments.FileHandlerStore
import dev.fritz2.core.RootStore
import dev.fritz2.core.invoke
import dev.fritz2.core.storeOf
import koin.koinCtx
import kotlin.js.Promise
import kotlinx.coroutines.Job
import localization.TL
import localization.Translation
import model.Camera
import model.CodeTech
import model.ScanPurpose
import org.w3c.dom.CanvasRenderingContext2D
import org.w3c.dom.HTMLCanvasElement
import org.w3c.dom.HTMLVideoElement
import utils.jsApply

class BrowserCamera : RootStore<String?>(
    initialData = null,
    job = Job(),
) {
    private val translation: Translation by koinCtx.inject()
    private val cameraListStore: CameraListStore by koinCtx.inject()
    private val activeCameraStore: ActiveCameraStore by koinCtx.inject()
    private val inversionModeStore: InversionModeStore by koinCtx.inject()
    private val fileHandlerStore: FileHandlerStore by koinCtx.inject()

    var qrScanner: QrScanner? = null
    var video: HTMLVideoElement? = null
    val hasFlashlightStore = storeOf(false, job)
    private var canvas: HTMLCanvasElement? = null

    fun insertPhotoCanvas(canvasElem: HTMLCanvasElement) {
        canvas = canvasElem
    }

    fun insertPhotoCamera(videoElem: HTMLVideoElement, facingMode: FacingMode = FacingMode.Front) {
        destroy()
        // Prepare video element for Photo
        video = videoElem
        val defaultBackCamera = Camera(
            id = "environment",
            label = translation.getString(TL.CardBrowserQrScanner.DEFAULT_BACK_CAMERA),
        )
        val defaultFrontCamera = Camera(
            id = "user",
            label = translation.getString(TL.CardBrowserQrScanner.DEFAULT_FRONT_CAMERA),
        )
        val qrScannerOptions = jsApply<QrScannerOptions> { }
        if (qrScanner == null) qrScanner = QrScanner(video = videoElem, onDecode = { }, options = qrScannerOptions)
        qrScanner?.start()?.then {
            console.log("insert camera for photo")
            hasFlash()?.then { hasIt -> hasFlashlightStore.update(hasIt) }
            listCameras(true).then { res ->
                val cameras = listOf(defaultBackCamera, defaultFrontCamera) + res.map { Camera(it.id, it.label) }
                cameraListStore.update(cameras)
                setCamera(facingMode.captureName)
                @Suppress("REDUNDANT_ELSE_IN_WHEN") // defensive move
                activeCameraStore.update(
                    when (facingMode) {
                        FacingMode.Front -> defaultFrontCamera
                        FacingMode.Back -> defaultBackCamera
                        else -> defaultFrontCamera
                    },
                )
            }
        }?.catch { error -> console.log(error) }
    }

    private fun takePhoto(): String? {
        if (video != null && canvas != null) {
            with(canvas) {
                if (this != null) {
                    this.width = video!!.videoWidth
                    this.height = video!!.videoHeight
                    (this.getContext("2d") as CanvasRenderingContext2D).drawImage(video!!, 0.0, 0.0, this.width.toDouble(), this.height.toDouble())
                    val imageURL = this.toDataURL("image/png")
                    stop()
                    return imageURL
                } else return null
            }
        } else {
            console.log("video or canvas element is null", this)
            return null
        }
    }

    val takePhoto = handle { current ->
        fileHandlerStore.takeFileFromBrowserCameraPhoto(takePhoto())
        stop()
        current
    }

    val openCodeScanResultHandler = { code: QrScanner.ScanResult ->
        if (code.data.isNotBlank()) {
            qrScanner?.stop()
            destroy()
            console.log("QR code scanned via browser webcam '${code}'")
            handleScanResult(
                scanContent = code.data,
                fallback = code.data,
                type = CodeTech.QR,
                scanPurpose = ScanPurpose.OpenCode,
            )
        }
    }

    val connectCodeScanResultHandler = { code: QrScanner.ScanResult ->
        if (code.data.isNotBlank()) {
            qrScanner?.stop()
            destroy()
            console.log("QR code scanned via browser webcam '${code}'")
            handleScanResult(
                scanContent = code.data,
                fallback = code.data,
                type = CodeTech.QR,
                scanPurpose = ScanPurpose.ConnectQRToObject,
            )
        }
    }

    fun insertQRScanCamera(videoElem: HTMLVideoElement, resultHandler: (QrScanner.ScanResult) -> Unit) {
        destroy()
        val defaultBackCamera = Camera(
            id = "environment",
            label = translation.getString(TL.CardBrowserQrScanner.DEFAULT_BACK_CAMERA),
        )
        val defaultFrontCamera = Camera(
            id = "user",
            label = translation.getString(TL.CardBrowserQrScanner.DEFAULT_FRONT_CAMERA),
        )
        val qrScannerOptions = jsApply<QrScannerOptions> {
            highlightScanRegion = true
        }
        qrScanner = QrScanner(video = videoElem, onDecode = resultHandler, options = qrScannerOptions)
        qrScanner?.start()?.then {
            console.log("insert camera")
            hasFlash()?.then { hasIt -> hasFlashlightStore.update(hasIt) }
            listCameras(true).then { res ->
                val cameras = listOf(defaultBackCamera, defaultFrontCamera) + res.map { Camera(it.id, it.label) }
                cameraListStore.update(cameras)
                cameras.firstOrNull { it.id == qrScanner?._preferredCamera }?.let { activeCameraStore.update(it) }
            }
            setInversionMode(InversionMode.Both.key)
        }?.catch { error -> console.log(error) }
    }

    /** Start Scanning
     * Call it when you're ready to scan, for example on a button click or directly on page load.
     * It will prompt the user for permission to use a camera. Note: to read from a Web Cam stream,
     * your page must be served via HTTPS.
     */
    fun start() = qrScanner?.start()?.catch { error -> console.log(error) }

    /**
     * Stop Scanning
     * If you want, you can stop scanning anytime and resume it by calling start() again.
     */

    val stop = handle { current ->
        qrScanner?.stop()
        current
    }

    /**
     * Checking for Camera availability
     * This library provides a utility method for checking whether the device has a camera.
     * This can be useful for determining whether to offer the QR webcam scanning functionality to a user.
     */

    fun hasCamera(): Promise<Boolean> = QrScanner.hasCamera().then { result -> result }.catch { error ->
        console.log("", error)
        false
    }

    /**
     * Single Image Scanning
     * Supported image sources are: HTMLImageElement, SVGImageElement, HTMLVideoElement, HTMLCanvasElement,
     * ImageBitmap, OffscreenCanvas, File / Blob, Data URIs, URLs pointing to an image
     * (if they are on the same origin or CORS enabled)
     */

    /**
     * Getting the list of available Cameras
     * This library provides a utility method for getting a list of the device's cameras, defined via their id and
     * label. This can be useful for letting a user choose a specific camera to use.
     *
     * You can optionally request the camera's labels. Note that this however requires the user's permission to access
     * the cameras, which he will be asked for if not granted already. If not specifically requested, device labels are
     * determined on a best effort basis, i.e. actual labels are returned if permissions were already granted and
     * fallback labels otherwise. If you want to request camera labels, it's recommendable to call listCameras after a
     * QrScanner instance was successfully started, as by then the user will already have given his permission.
     */

    private fun listCameras(requestLabels: Boolean = false): Promise<Array<QrScanner.Camera>> = if (requestLabels) QrScanner.listCameras(
        requestLabels,
    ) else QrScanner.listCameras()

    /**
     * Specifying which camera to use
     * You can change the preferred camera to be used.
     * The preference can be either a device id as returned by listCameras or a facing mode specified as
     * 'environment' or 'user'. Note that there is no guarantee that the preference can actually be fulfilled.
     */
    fun setCamera(facingModeOrId: String) {
        qrScanner?.setCamera(facingModeOrId)?.catch { error -> console.log(error) }
    }

    /**
     * *Color Inverted Mode* -
     * The scanner by default scans for dark QR codes on a bright background.
     * You can change this behavior to scan for bright QR codes on dark background or for both at the same time.
     * Where inversionMode can be "original", "invert" or "both".
     * The default for webcam scanning is original and for single image scanning both.
     */
    private fun setInversionMode(mode: String = "both") = qrScanner?.setInversionMode(mode)

    /**
     * Flashlight support
     * On supported browsers, you can check whether the currently used camera has a flash and turn it on or off.
     * Note that hasFlash should be called after the scanner was successfully started to avoid the need to open a
     * temporary camera stream just to query whether it has flash support, potentially asking the user for camera access.
     */
    private fun hasFlash(): Promise<Boolean>? = qrScanner?.hasFlash()?.then { it }?.catch { error ->
        console.log(error)
        false
    }

    private fun isFlashOn(): Boolean = (qrScanner?.isFlashOn()) ?: false
    val turnFlashOn = handle { current ->
        qrScanner?.turnFlashOn()?.catch { error -> console.log(error) }
        current
    }
    val turnFlashOff = handle { current ->
        qrScanner?.turnFlashOff()?.catch { error -> console.log(error) }
        current
    }
    val toggleFlash = handle { current ->
        if (isFlashOn()) qrScanner?.turnFlashOff()?.catch { error -> console.log(error) } else {
            qrScanner?.turnFlashOn()?.catch { error -> console.log(error) }
        }
//        qrScanner?.toggleFlash()?.catch { error -> console.log(error) }  // TODO check if toggle light works reliably
        current
    }


    /**
     * Clean Up
     * You can destroy the QR scanner if you don't need it anymore.
     */
    fun destroy() {
        qrScanner?.destroy()
        qrScanner = null
    }

    init {
        activeCameraStore.data handledBy { selected ->
            console.log("Change camera to:", selected?.label)
            selected?.let { setCamera(it.id) }
        }
        inversionModeStore.data handledBy { selected ->
            console.log("Change inversion mode to:", selected.name)
            setInversionMode(selected.key)
        }
    }

}
