package notifications

import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.NotificationState
import apiclient.geoobjects.NotificationTags
import apiclient.geoobjects.TranslationTags
import apiclient.localizations.LocalizationArg
import apiclient.localizations.TranslationKey
import apiclient.tags.getUniqueTag
import apiclient.validations.parseEnumValue
import data.ObjectAndUserHandler
import dev.fritz2.components.compat.button
import dev.fritz2.components.compat.div
import dev.fritz2.components.compat.span
import dev.fritz2.components.flexBox
import dev.fritz2.components.icon
import dev.fritz2.components.lineUp
import dev.fritz2.components.stackUp
import dev.fritz2.core.RenderContext
import dev.fritz2.core.SimpleHandler
import dev.fritz2.core.title
import koin.koinCtx
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import localization.TL
import localization.Translation
import model.NotificationNumbers
import search.filterButtonDisabledStyle
import search.filterButtonOffStyle
import search.filterButtonOnStyle
import search.iconBox
import search.listEntryBox
import search.separationLine
import search.subtitleBox
import search.subtitleInfoText
import search.titleBox
import search.titleSizedText
import search.titleSubtitleStack
import styling.secondaryButtonStyleParams
import theme.FormationDefault
import utils.formatDateTime
import utils.getShortMessage
import utils.parseInstant
import webcomponents.genericButton

fun RenderContext.notificationFilterBar() {
    val notificationFilterStore: NotificationFilterStore by koinCtx.inject()
    val translation: Translation by koinCtx.inject()
    val globalNotificationResultsStore: GlobalNotificationResultsStore by koinCtx.inject()

    div({
        width { full }
        paddings {
            top { tiny }
        }
    }) {
        flexBox({
            width { full }
            direction { row }
            justifyContent { spaceBetween }
            alignItems { center }
            border {
                color { primary.main }
                width(FormationDefault.formationStyles.borderWidth)
            }
            radius(FormationDefault.formationStyles.inputRadius)
            background { color { secondary.main } }
        }) {
            notificationFilterStore.data.combine(globalNotificationResultsStore.data.map {
                globalNotificationResultsStore.totalUnreadNotifications()
            }) { filter, number -> Pair(filter, number) }.render { data ->
                button({
                    if (data.first == NotificationFilter.NOTIFICATIONS) filterButtonOnStyle() else filterButtonOffStyle()
                    fontSize { small }
                }) {
                    +"${translation.getString(TL.Notifications.NOTIFICATIONS, mapOf("number" to 2))} ${if (data.second > 0) "(${data.second})" else ""}"

                    clicks.map { NotificationFilter.NOTIFICATIONS } handledBy notificationFilterStore.selectFilter
                }
                // TODO change style, when button is used and remove tooltip
                button({
                    if (data.first == NotificationFilter.MESSAGES) filterButtonOnStyle() else filterButtonDisabledStyle()
                }) {
                    title(translation.getString(TL.General.COMING_SOON))
                    +translation.getString(TL.Notifications.MESSAGES)
//                    clicks.map { NotificationFilter.MESSAGES } handledBy notificationFilterStore.selectFilter
                }
            }
        }
    }
}

fun RenderContext.markAllAsReadButton() {
    val translation: Translation by koinCtx.inject()
    val globalNotificationResultsStore: GlobalNotificationResultsStore by koinCtx.inject()
    flexBox({
        width { full }
        paddings {
            bottom { smaller }
        }
        justifyContent { flexEnd }
    }) {
        genericButton(
            title = translation[TL.Notifications.MARK_ALL_NEW_AS_READ],
            width = { minContent },
            styleFlow = flowOf(secondaryButtonStyleParams),
            styleType = { secondary },
            clickHandlers = listOf(globalNotificationResultsStore.markAllAsRead),
            value = Unit,
        )
    }
}

fun RenderContext.notificationResultsList(results: Flow<NotificationNumbers>) {

    val translation: Translation by koinCtx.inject()
    val notificationFilterStore: NotificationFilterStore by koinCtx.inject()
    val notificationSearchInputFieldStore: NotificationSearchInputFieldStore by koinCtx.inject()

    results.render { (reads, _, unreads, _) ->
        if ((unreads + reads).isNotEmpty()) {
            stackUp({
                width { full }
                alignItems { center }
            }, id = "list-entry-stack-up") {
                spacing { small }
                items {
                    unreads.forEach { result ->
                        notificationListEntry(
                            result, clickHandlers = listOf(
                                notificationFilterStore.reset,
                                notificationSearchInputFieldStore.reset,
                            )
                        )
                    }
                    separationLine(notificationFilterStore.data.map { filter ->
                        when (filter) {
                            NotificationFilter.NOTIFICATIONS -> translation.getString(TL.Notifications.OLDER_ALERTS)
                            NotificationFilter.MESSAGES -> translation.getString(TL.Notifications.OLDER_MESSAGES)
                        }
                    })
                    reads.forEach { result ->
                        notificationListEntry(
                            result, clickHandlers = listOf(
                                notificationFilterStore.reset,
                                notificationSearchInputFieldStore.reset,
                            )
                        )
                    }
                }
            }
        } else {
            flexBox({
                width { full }
                height { full }
                alignItems { center }
                justifyContent { center }
            }) {
                span {
                    when (notificationFilterStore.current) {
                        NotificationFilter.NOTIFICATIONS -> translation[TL.Search.NO_NOTIFICATIONS].renderText(into = this)
                        NotificationFilter.MESSAGES -> translation[TL.Search.NO_MESSAGES].renderText(into = this)
                    }
                }
            }
        }
    }
}

fun RenderContext.notificationInfo(geoObject: GeoObjectDetails) {

    val translation: Translation by koinCtx.inject()

    lineUp {
        spacing { tiny }
        items {
            span({
                fontSize { "9px" }
            }) {
                geoObject.createdAt.parseInstant()?.let { createdAtTime ->
                    +createdAtTime.formatDateTime()
                }
            }
            if (parseEnumValue<NotificationState>(geoObject.tags.getUniqueTag(NotificationTags.NotificationState)) == NotificationState.UNREAD)
                span({
                    fontSize { "9px" }
                    fontWeight { bold }
                }) { translation[TL.Notifications.NEW].renderText(into = this) }
        }

    }
}

fun RenderContext.notificationListEntry(
    notificationGeoObject: GeoObjectDetails,
    clickHandlers: List<SimpleHandler<Unit>>? = null
) {
    val objectAndUserHandler: ObjectAndUserHandler by koinCtx.inject()
    val hasConnectedObject = !notificationGeoObject.connectedTo.isNullOrEmpty()
    val connectedObjectTitle = notificationGeoObject.tags.getUniqueTag(LocalizationArg.OBJECT_TITLE)
//    console.log(notificationGeoObject)
    button({
        width { full }
        height { maxContent }
        overflow { visible }
    }, id = "list-entry-button") {
        if (hasConnectedObject) {
            listEntryBox(additionalStyle = {
                if (parseEnumValue<NotificationState>(notificationGeoObject.tags.getUniqueTag(NotificationTags.NotificationState)) == NotificationState.UNREAD) {
                    border {
                        color { primary.main }
                        width(FormationDefault.formationStyles.borderWidth)
                    }
                }
            }) {
                iconBox(notificationGeoObject, showStateIndicator = true)
                flexBox({
                    width { full }
                    height { full }
                    direction { column }
                    alignItems { start }
                    justifyContent { start }
                    overflow { hidden }
                }) {
                    notificationInfo(notificationGeoObject)
                    titleSubtitleStack {
                        connectedObjectTitle?.let { title ->
                            titleBox(
                                titleText = {
                                    titleSizedText { +title }
                                }
                            )
                        }
                        subtitleBox(
                            subtitleText = {
                                subtitleInfoText(wrapText = true) {
                                    parseEnumValue<TranslationKey>(
                                        notificationGeoObject.tags.getUniqueTag(
                                            TranslationTags.TitleTranslationKey
                                        )
                                    )?.getShortMessage(notificationGeoObject)
                                        ?.renderText()
                                }
                            }
                        )
                    }
                }
                icon { fromTheme { chevronRight } }
            }
        }
        with(clicks) {
            clickHandlers?.forEach { handler ->
                this handledBy handler
            }
            this.map { notificationGeoObject } handledBy objectAndUserHandler.updateAndLocateObjectOrUserFromList

        }
    }
}
