package dev.fritz2.components

import dev.fritz2.components.compat.div
import dev.fritz2.core.HtmlTag
import dev.fritz2.core.RenderContext
import dev.fritz2.core.ScopeContext
import dev.fritz2.core.classes
import dev.fritz2.styling.StyleClass
import dev.fritz2.styling.params.FlexParams
import org.w3c.dom.HTMLDivElement

@Deprecated("Use the member function RenderContext.div instead. Translate style using tailwind CSS classes.")
fun RenderContext.flexBox(
    baseClass: String? = null,
    id: String? = null,
    scope: ScopeContext.() -> Unit = {},
    content: HtmlTag<HTMLDivElement>.() -> Unit,
): HtmlTag<HTMLDivElement> = div(classes("flex", baseClass), id, scope, content)

@Deprecated("Use the member function RenderContext.div instead. Translate style using tailwind CSS classes.")
fun RenderContext.flexBox(
    styling: FlexParams.() -> Unit = {},
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "flex-box",
    scope: ScopeContext.() -> Unit = {},
    content: HtmlTag<HTMLDivElement>.() -> Unit
): HtmlTag<HTMLDivElement> = div({ display { flex } }, styling, baseClass, id, prefix, scope) { content() }
