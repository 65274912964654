@file:Suppress("OPT_IN_USAGE")

package workspacetools.usermanagement

import com.tryformation.localization.Translatable
import dev.fritz2.components.modal
import dev.fritz2.components.stackUp
import dev.fritz2.core.SimpleHandler
import dev.fritz2.styling.params.plus
import koin.koinCtx
import kotlinx.coroutines.flow.Flow
import localization.Translation
import styling.primaryButtonStyleParams
import styling.secondaryButtonStyleParams
import webcomponents.cardTitle
import webcomponents.fullPageConfirmation
import webcomponents.fullPageConfirmationContainer
import webcomponents.oneButtonFooter
import webcomponents.twoButtonFooter

fun confirm(
    text: Flow<String>,
    okHandlers: List<SimpleHandler<Unit>>,
    title: Flow<String>? = null,
    withCancelButton: Boolean = true,
): SimpleHandler<Unit> {

    val translation by koinCtx.inject<Translation>()

    return modal(
        {
            width { none }
            height { none }
        },
    ) {
        placement { center }
        hasCloseButton(false)
        content { close -> // use the close handler provided by the modal
            fullPageConfirmation {
                fullPageConfirmationContainer {
                    stackUp {
                        spacing { small }
                        items {
                            cardTitle(title ?: translation[DashboardTexts.ConfirmationTitle])
                            p {
                                text.renderText(into = this)
                            }
                            if (withCancelButton) {
                                twoButtonFooter(
                                    secondaryTitle = translation[ConfirmTexts.Cancel],
                                    secondaryStyleParams = secondaryButtonStyleParams,
                                    primaryValue = Unit,
                                    secondaryClickHandlers = listOf(close),
                                    primaryTitle = translation[ConfirmTexts.OK],
                                    primaryStyleParams = primaryButtonStyleParams.plus {
                                        background {
                                            color { warning.main }
                                        }
                                    },
                                    primaryStyleType = { warning },
                                    primaryClickHandlers = listOf(close).plus(okHandlers),
                                )
                            } else {
                                oneButtonFooter(
                                    title = translation[ConfirmTexts.OK],
                                    styleParams = primaryButtonStyleParams.plus {
                                        background {
                                            color { warning.main }
                                        }
                                    },
                                    value = Unit,
                                    clickHandlers = listOf(close).plus(okHandlers),
                                )
                            }
                        }
                    }
                }
            }
        }
    }
}

enum class ConfirmTexts : Translatable {
    OK,
    Cancel,
    ;

    override val prefix = "confirm-dialog"
}
