package objectrouting

import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.LatLon
import dev.fritz2.styling.theme.IconDefinition
import theme.FormationIcons

enum class NavigationInput {
    Origin, Destination
}

enum class NavigationPointSource(val icon: IconDefinition) {
    YourLocation(FormationIcons.Position.icon),
    MapCenter(FormationIcons.MapCenter.icon),
    LastKnownLocation(FormationIcons.LocationAlt.icon),
    OtherObject(FormationIcons.TrackedObject.icon)
}

data class NavigationData(
    val originPointSource: NavigationPointSource? = null,
    val originLatLon: LatLon? = null,
    val originObject: GeoObjectDetails? = null,
    val destinationPointSource: NavigationPointSource? = null,
    val destinationLatLon: LatLon? = null,
    val destinationObject: GeoObjectDetails? = null,
) {
    companion object
}

data class RoutePoint(
    val lat: Double,
    val lon: Double,
    val floorId: String? = null,
    val floorLevel: Double? = null,
    val isFloorChange: Boolean = false,
    val floorChange: FloorChange? = null
)

data class FloorChange(
    val previousLevel: Double,
    val newLevel: Double
) {
    val isLevelUp get() = previousLevel < newLevel
}
