package mainmenu

import apiclient.users.NotificationSetting
import apiclient.users.NotificationSettingValue
import apiclient.users.UserFeatureFlag
import apiclient.users.UserPreferences
import auth.ApiUserStore
import auth.FeatureFlagStore
import auth.Features
import com.tryformation.localization.Translatable
import data.objects.objecthistory.ShowObjectHistoryPathStore
import data.users.profile.MyProfileStore
import data.users.settings.LocalSettingsStore
import data.users.settings.NotificationPreferencesStore
import data.users.settings.SyncedUserPreferencesStore
import dev.fritz2.components.compat.div
import dev.fritz2.components.compat.span
import dev.fritz2.components.flexBox
import dev.fritz2.components.inputField
import dev.fritz2.components.lineUp
import dev.fritz2.components.pushButton
import dev.fritz2.components.selectField
import dev.fritz2.components.stackUp
import dev.fritz2.core.RenderContext
import dev.fritz2.core.RootStore
import dev.fritz2.core.Tag
import dev.fritz2.core.invoke
import dev.fritz2.core.placeholder
import dev.fritz2.core.title
import dev.fritz2.core.values
import dev.fritz2.styling.StyleClass
import dev.fritz2.styling.params.ScaledValueProperty
import dev.fritz2.styling.params.SpacesContext
import dev.fritz2.styling.theme.IconDefinition
import generated.ServerConfig
import koin.koinCtx
import kotlin.time.Duration
import kotlin.time.Duration.Companion.days
import kotlin.time.Duration.Companion.hours
import kotlin.time.Duration.Companion.minutes
import kotlin.time.Duration.Companion.seconds
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import localization.LocaleStore
import localization.Locales
import localization.TL
import localization.Translation
import localization.getAllowedLocales
import localization.value
import location.GeoPositionStore
import location.geolocation.GeoPosition
import map.MapStateStore
import model.LocalSettings
import model.clusterObjects
import model.hideMarkerTitles
import model.manualSearch
import model.periodicSearch
import model.rememberLocationSharing
import model.stringCoordinates
import model.timeZone
import org.w3c.dom.HTMLElement
import search.DistanceTo
import search.searchlayer.PeriodicSearchStore
import styling.genericButtonStyleParams
import styling.primaryButtonStyleParams
import theme.AppThemeStore
import theme.FormationColors
import theme.FormationDefault
import theme.FormationIcons
import twcomponents.twFeatureFlagDiv
import utils.TimeZones
import utils.convertToGeoPosition
import utils.generateVersionCodeFromTimeStamp
import webcomponents.baseLayout
import webcomponents.cardSubtitle
import webcomponents.cardTitle
import webcomponents.contentScrollBox
import webcomponents.genericInput
import webcomponents.mainTitle
import webcomponents.oneButtonFooter
import webcomponents.switchBox

fun RenderContext.pageUserSettings() {

    val translation: Translation by koinCtx.inject()
    val localeStore: LocaleStore by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()
    val syncedUserPreferencesStore: SyncedUserPreferencesStore by koinCtx.inject()
    val rememberSharingState = syncedUserPreferencesStore.map(UserPreferences.rememberLocationSharing())
    val timeZone = syncedUserPreferencesStore.map(UserPreferences.timeZone())
    val localSettingsStore: LocalSettingsStore by koinCtx.inject()
    val manualSearch = localSettingsStore.map(LocalSettings.manualSearch())
    val periodicSearch = localSettingsStore.map(LocalSettings.periodicSearch())
    val hideMarkerTitles = localSettingsStore.map(LocalSettings.hideMarkerTitles())
    val clusterObjects = localSettingsStore.map(LocalSettings.clusterObjects())
    val periodicSearchTimeValueStore by koinCtx.inject<PeriodicSearchTimeValueStore>()
    val periodicSearchTimeUnitStore by koinCtx.inject<PeriodicSearchTimeUnitStore>()
    val periodicSearchStore by koinCtx.inject<PeriodicSearchStore>()
    val featureFlagStore by koinCtx.inject<FeatureFlagStore>()
    val notificationPreferencesStore by koinCtx.inject<NotificationPreferencesStore>()
    val showObjectHistoryPathStore by koinCtx.inject<ShowObjectHistoryPathStore>()
    val apiUserStore by koinCtx.inject<ApiUserStore>()
    val geoPositionStore by koinCtx.inject<GeoPositionStore>()
    val myProfileStore by koinCtx.inject<MyProfileStore>()
    val mapStateStore by koinCtx.inject<MapStateStore>()
    val themeStore by koinCtx.inject<AppThemeStore>()


    periodicSearchStore.initialize() // just make sure periodic search store is really initialized and working

    baseLayout(
        header = {
            mainTitle(translation[TL.ApplicationSettings.HEADER])
        },
        content = {
            contentScrollBox {
                stackUp(
                    {
                        width { full }
                        justifyContent { center }
                        alignItems { stretch }
                        margins { vertical { smaller } }
                    },
                    baseClass = StyleClass("text-formationBlack bg-formationWhite"),
                ) {
                    spacing { large }
                    items {
                        // THEME SETTING
//                            settingsCategory(
//                                title = translation[TL.ApplicationSettings.THEME],
//                                subtitle = translation[TL.ApplicationSettings.THEME_SUBTITLE]
//                            ) {
//                                selectField({
//                                    fontSize { small }
//                                }, items = AppTheme.entries, value = themeStore) {
//                                    label { theme -> theme.name.replaceFirstChar { if (it.isLowerCase()) it.titlecase() else it.toString() } }
//                                    events {
//                                        selected.map { it } handledBy themeStore.update
//                                    }
//                                }
//                            }
                        // LANGUAGE AND TIMEZONE SETTINGS
                        settingsCategory(
                            title = translation[TL.ApplicationSettings.LOCALIZATION],
                            subtitle = translation[TL.ApplicationSettings.LOCALIZATION_SUBTITLE],
                            spacing = { none },
                        ) {
                            translation.data.render { currentBundleSequence ->
                                selectField(
                                    {
                                        fontSize { small }
                                    },
                                    items = Locales.getAllowedLocales(), value = localeStore,
                                ) {
//                                        placeholder(currentBundleSequence.format(TL.ApplicationSettings.SELECT_LOCALE_PLACEHOLDER).value)
                                    label { locale -> "${currentBundleSequence.format(locale).value} (${locale.id})" }
                                    events {
                                        selected.map { it.id } handledBy syncedUserPreferencesStore.updateLanguage
                                    }
                                }
                            }
                            timeZone.data.render { timeZonePreference ->
                                div(
                                    {
                                        margins { top { small } }
                                    },
                                ) {
                                    selectField(
                                        {
                                            fontSize { small }
                                        },
                                        items = TimeZones.entries.map { it.zoneId }.toList(),
                                    ) {
//                                                placeholder(currentBundleSequence.format(TL.ApplicationSettings.SELECT_TIMEZONE_PLACEHOLDER).value)
                                        selectedItem(
                                            TimeZones.entries.firstOrNull { it.zoneId == timeZonePreference }?.zoneId
                                                ?: TimeZones.Europe_Berlin.zoneId,
                                        )
                                        label { timeZone -> timeZone }
                                        events {
                                            with(this@pageUserSettings) {
                                                selected handledBy syncedUserPreferencesStore.updateTimeZone
                                            }
                                        }
                                    }
                                }
                            }
                        }

//                        // LOCATION SPOOFING
                        myProfileStore.data.render { profile ->
                            if (profile.featureFlags?.get(UserFeatureFlag.EnableLocationSpoofing) == true) {
                                settingsCategory(
                                    title = translation[TL.ApplicationSettings.LOCATION_SPOOFING],
                                    subtitle = translation[TL.ApplicationSettings.LOCATION_SPOOFING_SUBTITLE],
                                    spacing = { small },
                                    margins = { top { small } },
                                ) {
                                    val coordsStore =
                                        geoPositionStore.spoofedLocationStore.map(GeoPosition.stringCoordinates())
                                    inputField(
                                        styling = {
                                            color { primary.main }
                                            background {
                                                color { FormationColors.GrayLight.color }
                                            }
                                            radius(FormationDefault.formationStyles.inputRadius)
                                        },
                                        value = coordsStore,
                                    ) {
                                        element {
                                            placeholder("52.50,13.50")
                                        }
                                        events {
                                            inputs.map { convertToGeoPosition(coordsStore.current) } handledBy geoPositionStore.spoofedLocationStore.update
                                        }
                                    }
                                    lineUp(
                                        {
                                            width { full }
                                            alignItems { center }
                                            justifyContent { spaceBetween }
                                        },
                                    ) {
                                        spacing { small }
                                        items {
                                            pushButton(
                                                {
                                                    primaryButtonStyleParams()
                                                },
                                            ) {
                                                type { primary }
                                                icon { FormationIcons.MapCenter.icon }
                                                text(translation[DistanceTo.Map])
                                                events {
                                                    clicks.map {
                                                        convertToGeoPosition("${mapStateStore.current?.center?.lat},${mapStateStore.current?.center?.lon}")
                                                    } handledBy geoPositionStore.spoofedLocationStore.update
                                                }
                                            }
                                            coordsStore.data.map { it.isNotBlank() }.render { spoofActive ->
                                                pushButton(
                                                    {
                                                        genericButtonStyleParams()
                                                    },
                                                ) {
                                                    type { if (spoofActive) danger else secondary }
                                                    icon { close }
                                                    text(if (spoofActive) "Stop spoofing" else "Not spoofing") // Todo translate
                                                    disabled(!spoofActive)
                                                    events {
                                                        clicks.map {
                                                            null
                                                        } handledBy geoPositionStore.spoofedLocationStore.update
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        // LOCATION SHARING SETTINGS
                        twFeatureFlagDiv(flag = Features.DisableLocationSharing, flagValue = false) {
                            settingsCategory(
                                title = translation[TL.ApplicationSettings.LOCATION_SHARING],
                                subtitle = translation[TL.ApplicationSettings.LOCATION_SHARING_SUBTITLE],
                            ) {
                                title(translation[TL.ApplicationSettings.REMEMBER_LOCATION_SHARING_STATE_DESC])
                                switchBox(
                                    store = rememberSharingState,
                                    clickHandlers = listOf(syncedUserPreferencesStore.savePreferences),
                                ) {
                                    translation[TL.ApplicationSettings.REMEMBER_LOCATION_SHARING_STATE].renderText()
                                }
                            }
                        }
                        // MAP SETTINGS
                        settingsCategory(
                            title = translation[TL.ApplicationSettings.MAP],
                            subtitle = translation[TL.ApplicationSettings.MAP_SUBTITLE],
                        ) {
                            switchBox(
                                content = {
                                    translation[TL.ApplicationSettings.TRIGGER_REFRESH_MANUALLY].renderText()
                                },
                                store = manualSearch,
                            )
                            stackUp {
                                spacing { small }
                                items {
                                    switchBox(
                                        content = {
                                            translation[TL.ApplicationSettings.TRIGGER_REFRESH_PERIODICALLY].renderText()
                                        },
                                        store = periodicSearch,
                                    )
                                    div {
                                        periodicSearch.data.render { enabled ->
                                            lineUp(
                                                {
                                                    alignItems { center }
                                                },
                                            ) {
                                                spacing { small }
                                                items {
                                                    genericInput(
                                                        value = periodicSearchTimeValueStore.data.map { it.toString() },
                                                        type = "number",
                                                        disabled = !enabled,
                                                        width = { "85px" },
                                                    ) {
                                                        inputs.values()
                                                            .map { it.toInt() } handledBy periodicSearchTimeValueStore.update
                                                    }
                                                    div {
                                                        translation.data.render { currentBundleSequence ->
                                                            selectField(
                                                                styling = {
                                                                    width { full }
                                                                    fontSize { smaller }
                                                                },
                                                                items = TimeUnit.entries,
                                                                value = periodicSearchTimeUnitStore,
                                                            ) {
                                                                disabled(!enabled)
                                                                label { timeUnit ->
                                                                    currentBundleSequence.format(
                                                                        timeUnit,
                                                                    ).value
                                                                }
                                                                events {
                                                                    selected.map { it } handledBy periodicSearchTimeUnitStore.update
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    // Show/hide marker titles
                                    switchBox(
                                        content = {
                                            translation[TL.ApplicationSettings.HIDE_MARKER_TITLES].renderText()
                                        },
                                        store = hideMarkerTitles,
                                    )
                                    twFeatureFlagDiv(flag = Features.DisableClustering, flagValue = false) {
                                        // Enable/disable clustering of markers
                                        switchBox(
                                            content = {
                                                translation[TL.ApplicationSettings.CLUSTER_OBJECTS].renderText()
                                            },
                                            store = clusterObjects,
                                        )
                                    }
//                                    twFeatureFlagDiv(flag = Features.AllowHistoryPaths, flagValue = true) {
//                                        /// Show/hide object history path
//                                        switchBox(
//                                            content = {
//                                                translation[TL.ApplicationSettings.SHOW_HISTORY_PATH].renderText()
//                                            },
//                                            store = showObjectHistoryPathStore,
//                                        )
//                                    }
                                }
                            }
                        }
                        if (!apiUserStore.current.isAnonymous) {
                            // NOTIFICATION SETTINGS
                            settingsCategory(
                                title = translation[TL.ApplicationSettings.NOTIFICATIONS],
                                subtitle = translation[TL.ApplicationSettings.NOTIFICATIONS_SUBTITLE],
                            ) {
                                NotificationSetting.entries.forEach { notificationSetting ->
                                    lineUp(
                                        {
                                            alignItems { center }
                                            justifyContent { spaceBetween }
                                        },
                                    ) {
                                        spacing { small }
                                        items {
                                            span(
                                                {
                                                    fontSize { small }
                                                    textAlign { left }
                                                },
                                            ) {
                                                translation[notificationSetting].renderText(into = this)
                                            }

                                            div {
                                                combine(
                                                    translation.data,
                                                    notificationPreferencesStore.data,
                                                ) { l, n -> Pair(l, n) }
                                                    .render { (currentBundleSequence, notificationPreferences) ->
                                                        selectField(
                                                            styling = {
                                                                width { full }
                                                                fontSize { small }
                                                            },
                                                            items = NotificationSettingValue.entries,
                                                        ) {
                                                            selectedItem(notificationPreferences[notificationSetting])
                                                            label { settingValue ->
                                                                currentBundleSequence.format(
                                                                    settingValue,
                                                                ).value
                                                            }
                                                            events {
                                                                selected.map { newValue ->
                                                                    notificationPreferences + Pair(
                                                                        notificationSetting,
                                                                        newValue,
                                                                    )
                                                                } handledBy syncedUserPreferencesStore.updateNotificationSettings
                                                            }
                                                        }
                                                    }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                flexBox(
                    {
                        flex { grow { "1" } }
                        direction { column }
                        width { full }
                        alignItems { center }
                        justifyContent { flexEnd }
                        css("align-self: end;")
                        margins { top { small } }
                    },
                ) {
                    // Webapp build number
                        cardSubtitle(flowOf("FORMATION  App"))

                    cardSubtitle(flowOf("build_${generateVersionCodeFromTimeStamp(ServerConfig.buildTimestamp)}.0"))
                }
            }
        },
        footer = {
            oneButtonFooter(
                title = translation[TL.General.BACK],
                styleParams = primaryButtonStyleParams,
                value = Unit,
                clickHandlers = listOf(routerStore.back),
            )
        },
    )
}

fun RenderContext.settingsCategory(
    title: Flow<String>,
    iconFlow: Flow<IconDefinition>? = null,
    subtitle: Flow<String>?,
    spacing: ScaledValueProperty = { small },
    margins: (SpacesContext.() -> Unit)? = null,
    content: (Tag<HTMLElement>.() -> Unit)? = null,
) {
    div(
        {
            margins?.let { margins(margins) }
        },
    ) {
        cardTitle(
            title = title,
            iconFlow = iconFlow,
            iconSize = { large },
        )
        subtitle?.let { sub ->
            span(
                {
                    fontSize { smaller }
                    fontWeight { lighter }
                },
            ) {
                sub.renderText(into = this)
            }
        }
        stackUp(
            {
                width { full }
                justifyContent { center }
                alignItems { stretch }
                margins { top { small } }
            },
        ) {
            spacing(spacing)
            items {
                content?.invoke(this)
            }
        }
    }
}

enum class TimeUnit : Translatable {
    Seconds,
    Minutes,
    Hours,
    Days,
    ;

    fun asDuration(number: Int): Duration {
        return when (this) {
            Seconds -> number.seconds
            Minutes -> number.minutes
            Hours -> number.hours
            Days -> number.days
        }
    }

    override val prefix = "time-unit"
}

class PeriodicSearchTimeUnitStore : RootStore<TimeUnit>(
    initialData = TimeUnit.Seconds,
    job = Job(),
)

class PeriodicSearchTimeValueStore : RootStore<Int>(
    initialData = 30,
    job = Job(),
) {

    private val validateInput = handle<Int> { _, new ->
        // Validate input duration. Make sure duration is >= 1 second
        if (new > 0) new else 1
    }

    init {
        data handledBy validateInput
    }
}
