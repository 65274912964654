package qrcode.userverification

import dev.fritz2.core.RootStore
import dev.fritz2.core.Store
import kotlinx.coroutines.Job
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.launch
import qrcodegeneration.dataUrlImage

class QrCodeLinkStore<T>(linkSource: Store<T>, extractor: (T) -> String?) : RootStore<String?>(
    initialData = null,
    job = Job(),
) {

    val convert = handle<String?> { _, link ->
        coroutineScope {
            launch {
                if (link == null) {
                    update(null)
                } else {
                    update(dataUrlImage(link))
                }
            }
        }
        link
    }

    init {
        linkSource.data.map { extractor(it) } handledBy convert
    }
}
