package data.objects.views.task

import apiclient.geoobjects.GeoObjectDetails
import data.objects.ActiveObjectStore
import data.objects.DatePickerStore
import data.objects.TimePickerStore
import dev.fritz2.components.stackUp
import dev.fritz2.core.RenderContext
import dev.fritz2.core.placeholder
import dev.fritz2.core.values
import koin.koinCtx
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.map
import kotlinx.datetime.Clock
import localization.TL
import localization.Translation
import mainmenu.RouterStore
import model.L
import styling.primaryButtonStyleParams
import styling.secondaryButtonStyleParams
import theme.FormationIcons
import utils.formatDateForDatePicker
import utils.formatTimeForTimePicker
import utils.nextQuarterOfHour
import webcomponents.baseLayout
import webcomponents.cardTitle
import webcomponents.contentScrollBox
import webcomponents.genericInput
import webcomponents.inputLabelWrapper
import webcomponents.twoButtonFooter

fun RenderContext.cardDueDate() {
    val translation: Translation by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()
    val activeObjectStore: ActiveObjectStore by koinCtx.inject()
    val startTime = activeObjectStore.map(GeoObjectDetails.L.atTime)
    val datePickerStore: DatePickerStore by koinCtx.inject()
    val timePickerStore: TimePickerStore by koinCtx.inject()

    val dueDateFlow = startTime.data.map { start ->
        if (start == null) {
            val nextQuarterOfHour = Clock.System.now().nextQuarterOfHour()
            // prefill fields and update stores with current time
            datePickerStore.update(nextQuarterOfHour.formatDateForDatePicker())
            timePickerStore.update(nextQuarterOfHour.formatTimeForTimePicker())
            nextQuarterOfHour
        } else start
    }

    baseLayout(
        header = {
            cardTitle(translation[TL.CardDueDate.CARD_TITLE]) { FormationIcons.Calendar.icon }
        },
        content = {
            contentScrollBox {
                stackUp {
                    spacing { small }
                    items {
                        // DATE INPUT
                        inputLabelWrapper(
                            title = translation[TL.CardDateTime.DATE],
                            visibilityFlow = dueDateFlow.map { it.formatDateForDatePicker().isNotBlank() }
                        ) {
                            genericInput(
                                value = dueDateFlow.map { it.formatDateForDatePicker() },
                                type = "date"
                            ) {
                                placeholder(translation[TL.CardDateTime.DATE])
                                changes.values() handledBy datePickerStore.update
                            }
                        }
                        // TIME INPUT
                        inputLabelWrapper(
                            title = translation[TL.CardDateTime.TIME],
                            visibilityFlow = dueDateFlow.map { it.formatTimeForTimePicker().isNotBlank() }
                        ) {
                            genericInput(
                                value = dueDateFlow.map { it.formatTimeForTimePicker() },
                                type = "time"
                            ) {
                                placeholder(translation[TL.CardDateTime.TIME])
                                changes.values() handledBy timePickerStore.update
                            }
                        }
                    }
                }
            }
        },
        footer = {
            twoButtonFooter(
                secondaryTitle = translation[TL.General.CANCEL],
                secondaryStyleParams = secondaryButtonStyleParams,
                secondaryClickHandlers = listOf(activeObjectStore.updateChangeInputStores, routerStore.back),
                primaryTitle = translation[TL.General.SET],
                primaryState = datePickerStore.data.combine(timePickerStore.data) { date, time ->
                    (date.isNotBlank() && time.isNotBlank()) || (date.isBlank() && time.isBlank())
                },
                primaryStyleParams = primaryButtonStyleParams,
                primaryValue = Unit,
                primaryClickHandlers = listOf(activeObjectStore.readFromChangeInputStores, routerStore.back),
            )
        }
    )
}
