package data.objects

import apiclient.FormationClient
import apiclient.customfields.parseFieldValues
import apiclient.customfields.tagValues
import apiclient.geoobjects.AddKeywords
import apiclient.geoobjects.ChangeTitle
import apiclient.geoobjects.ConnectableGeoShapeChange
import apiclient.geoobjects.Content
import apiclient.geoobjects.DeleteContent
import apiclient.geoobjects.EventInvitation
import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.LatLon
import apiclient.geoobjects.MarkerShape
import apiclient.geoobjects.MeetingAttendee
import apiclient.geoobjects.MeetingInvitationStatus
import apiclient.geoobjects.NewEvent
import apiclient.geoobjects.NewPoi
import apiclient.geoobjects.NewTask
import apiclient.geoobjects.ObjectChange
import apiclient.geoobjects.ObjectChanges
import apiclient.geoobjects.ObjectTags
import apiclient.geoobjects.ObjectType
import apiclient.geoobjects.ObjectVisibility
import apiclient.geoobjects.PoiUpdate
import apiclient.geoobjects.RemoveField
import apiclient.geoobjects.RemoveKeywords
import apiclient.geoobjects.ReplaceFieldValue
import apiclient.geoobjects.SetDescription
import apiclient.geoobjects.SetMarkerColor
import apiclient.geoobjects.SetMarkerIcon
import apiclient.geoobjects.SetMarkerShape
import apiclient.geoobjects.SetObjectVisibility
import apiclient.geoobjects.TaskState
import apiclient.geoobjects.TimeTrigger
import apiclient.geoobjects.TrackedObjectDetails
import apiclient.geoobjects.UpdateEvent
import apiclient.geoobjects.UpdatePointLocation
import apiclient.geoobjects.UpdateTask
import apiclient.geoobjects.UpsertMarkdown
import apiclient.geoobjects.UpsertObjectLink
import apiclient.geoobjects.UpsertPoll
import apiclient.geoobjects.UpsertSvgImage
import apiclient.geoobjects.UpsertTaskTemplate
import apiclient.geoobjects.UpsertWebLink
import apiclient.geoobjects.Zone
import apiclient.geoobjects.ZoneType
import apiclient.geoobjects.addExternalIds
import apiclient.geoobjects.addInvitees
import apiclient.geoobjects.applyObjectChanges
import apiclient.geoobjects.attachImageToGeoObject
import apiclient.geoobjects.changeInviteStatus
import apiclient.geoobjects.changeTaskAssignment
import apiclient.geoobjects.changeTaskState
import apiclient.geoobjects.createEvent
import apiclient.geoobjects.createPOI
import apiclient.geoobjects.createTask
import apiclient.geoobjects.deleteGeneralMarker
import apiclient.geoobjects.parseReminderTags
import apiclient.geoobjects.pointCoordinates
import apiclient.geoobjects.releaseTrackedObject
import apiclient.geoobjects.removeExternalIds
import apiclient.geoobjects.removeInvitees
import apiclient.geoobjects.restArchiveObject
import apiclient.geoobjects.restChangeType
import apiclient.geoobjects.restDeleteObjectById
import apiclient.geoobjects.restFlagObject
import apiclient.geoobjects.restGetObjectById
import apiclient.geoobjects.restUnArchiveObject
import apiclient.geoobjects.restUnFlagObject
import apiclient.geoobjects.updateEvent
import apiclient.geoobjects.updatePOI
import apiclient.geoobjects.updateTask
import apiclient.geoobjects.updateZone
import apiclient.markers.ZoneService
import apiclient.reversegeocoder.reverseGeocode
import apiclient.search.ObjectSearchResults
import apiclient.tags.floorId
import apiclient.tags.getTagValues
import apiclient.tags.getUniqueTag
import apiclient.tags.parseTag
import apiclient.tags.setUniqueTag
import apiclient.users.PublicUserProfile
import apiclient.users.UserProfileSummary
import apiclient.users.toUserProfileSummary
import apiclient.util.isNotNullOrEmpty
import auth.ApiUserStore
import auth.CurrentWorkspaceStore
import com.jillesvangurp.geo.GeoGeometry
import com.jillesvangurp.geojson.Geometry
import data.connectableshapes.NewConnectableShapeConnectionStore
import data.objects.building.ActiveFloorsStore
import data.objects.building.CurrentBuildingsStore
import data.objects.views.ColorSelectionStore
import data.objects.views.IconSelectionStore
import data.objects.views.PointStyleToolSelectorStore
import data.objects.views.ShapeSelectionStore
import data.objects.views.attachments.AttachmentsStore
import data.objects.views.attachments.PreAttachmentsStore
import data.objects.views.attachments.RemovedAttachmentsStore
import data.objects.views.attachments.toPreAttachment
import data.users.ActiveUserStore
import dev.fritz2.core.RootStore
import dev.fritz2.core.SimpleHandler
import dev.fritz2.core.invoke
import dev.fritz2.core.storeOf
import dev.fritz2.tracking.tracker
import koin.koinCtx
import kotlin.time.Duration.Companion.days
import kotlinx.coroutines.CoroutineName
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.mapNotNull
import kotlinx.coroutines.launch
import kotlinx.datetime.Clock
import kotlinx.serialization.json.Json
import layercache.GeoObjectDetailsCache
import localization.TL
import localization.Translation
import mainmenu.Pages
import mainmenu.RouterStore
import map.MapLayersStore
import maplibreGL.MaplibreMap
import maplibreGL.joinNullableLists
import model.L
import model.LayerType
import model.PreAttachment
import model.ScannedCode
import model.getFirstGroupIdOrNull
import model.toSearchResult
import overlays.BusyStore
import overlays.BusyStoreTexts
import qrcode.ManualCodeInputStore
import qrcode.ScannedCodeStore
import search.searchlayer.MapSearchClientsStore
import utils.calculateEndDate
import utils.combineToInstant
import utils.extractDurationInMinutes
import utils.formatDateForDatePicker
import utils.formatTimeForTimePicker
import utils.getName
import utils.apiScope
import utils.insertObjectInCachesAndMap
import utils.parseInstant
import utils.removeObjectsInCachesAndMap
import utils.roundTo

val emptyUserProfileSummary = UserProfileSummary(
    userId = "",
    firstName = null,
    lastName = null,
    jobTitle = null,
)
val emptyGeoObjectDetails = GeoObjectDetails(
    id = "",
    ownerId = "",
    objectType = ObjectType.TransientMarker,
    createdAt = "",
    updatedAt = "",
    latLon = LatLon(0.0, 0.0),
    title = "",
    owner = emptyUserProfileSummary,
)
private val defaultTaskReminder = 1.days.inWholeMinutes.toInt()
private val defaultEventReminders = listOf(10, 60)

class ActiveObjectStore : RootStore<GeoObjectDetails>(
    initialData = emptyGeoObjectDetails,
    job = Job(),
) {

    private val json: Json by koinCtx.inject()
    private val maplibreMap: MaplibreMap by koinCtx.inject()
    private val activeUserStore: ActiveUserStore by koinCtx.inject()
    private val formationClient: FormationClient by koinCtx.inject()
    private val apiUserStore: ApiUserStore by koinCtx.inject()
    private val descriptionInputFieldStore: DescriptionInputFieldStore by koinCtx.inject()
    private val datePickerStore: DatePickerStore by koinCtx.inject()
    private val timePickerStore: TimePickerStore by koinCtx.inject()
    private val durationPickerStore: DurationPickerStore by koinCtx.inject()
    private val activeObjectKeywordsStore: ActiveObjectKeywordsStore by koinCtx.inject()
    private val activeObjectFieldValuesStore: ActiveObjectFieldValuesStore by koinCtx.inject()
    private val attendeesSelectorStore: AttendeesSelectorStore by koinCtx.inject()
    private val assigneeSelectorStore: AssigneeSelectorStore by koinCtx.inject()
    private val currentBuildingsStore: CurrentBuildingsStore by koinCtx.inject()
    private val activeFloorsStore: ActiveFloorsStore by koinCtx.inject()
    private val colorSelectionStore: ColorSelectionStore by koinCtx.inject()
    private val iconSelectionStore: IconSelectionStore by koinCtx.inject()
    private val shapeSelectionStore: ShapeSelectionStore by koinCtx.inject()
    private val attachmentsStore: AttachmentsStore by koinCtx.inject()
    private val preAttachmentsStore: PreAttachmentsStore by koinCtx.inject()
    private val removedAttachmentsStore: RemovedAttachmentsStore by koinCtx.inject()
    private val pointStyleToolSelectorStore: PointStyleToolSelectorStore by koinCtx.inject()
    private val translation: Translation by koinCtx.inject()
    private val routerStore: RouterStore by koinCtx.inject()
    private val mapSearchClientsStore: MapSearchClientsStore by koinCtx.inject()
    private val scannedCodeStore: ScannedCodeStore by koinCtx.inject()
    private val newConnectableShapeConnectionStore: NewConnectableShapeConnectionStore by koinCtx.inject()
    private val mapLayersStore: MapLayersStore by koinCtx.inject()
    private val zoneService: ZoneService by koinCtx.inject()
    private val currentWorkspaceStore by koinCtx.inject<CurrentWorkspaceStore>()
    private val busyStore by koinCtx.inject<BusyStore>()
    private val geoObjectCache: GeoObjectDetailsCache by koinCtx.inject()

    private val activeObjectId = map(GeoObjectDetails.L.id)
    private val attachments = map(GeoObjectDetails.L.attachments)
    private val tags = map(GeoObjectDetails.L.tags)

    val createTracker = tracker()
    val updateTracker = tracker()
    val deleteTracker = tracker()
    val attachmentTracker = tracker()

    var initialObject = emptyGeoObjectDetails

    val updateActiveObject = handle<GeoObjectDetails> { _, newGeoObject ->
        initialObject = if (newGeoObject.objectType == ObjectType.GeneralMarker) {
            newGeoObject.copy(shape = newGeoObject.shape ?: MarkerShape.Pentagon)
        } else {
            newGeoObject
        }
        newGeoObject
    }

    val duplicate = handle { current ->
        updateChangeInputStores()
        current.attachments?.let { attachments ->
            preAttachmentsStore.addOrUpdateMultiple(
                attachments.mapNotNull {
                    it.toPreAttachment()
                },
            )
            preAttachmentsStore.saveAsInterimState()
        }
        attachmentsStore.reset()
        val duplicate = initialObject.copy(
            id = "", taskState = null, attachments = null,
            tags = initialObject.tags.filter {
                val (tagName, _) = it.parseTag()
                tagName !in listOf(
                    ObjectTags.ExternalId,
                    ObjectTags.ActionId,
                    ObjectTags.Flagged,
                    ObjectTags.Archived,
                    ObjectTags.NoAutoArchive,
                    ObjectTags.Deleted,
                ).map { t -> t.name }
            },
        )
        initialObject = duplicate.copy(assignedTo = null)
        update(duplicate)
        routerStore.validateInternalRoute(mapOf("add" to current.objectType.name))
        duplicate
    }

    val createNew = handle { current ->
        val currentLat = maplibreMap.getCenter().lat
        val currentLon = maplibreMap.getCenter().lng
        val newPosition = LatLon(lat = currentLat, lon = currentLon)
        val assignee = assigneeSelectorStore.current?.firstOrNull()?.userId
        val attendees = attendeesSelectorStore.current?.map {
            EventInvitation(userId = it, remindBeforeMinutes = defaultEventReminders)
        }
        val now = Clock.System.now()
        val currentObjectType = current.objectType

        console.log("Create object:", current)

        routerStore.goToMap()

        apiScope.launch {
            createTracker.track {
                val groupId = apiUserStore.current.getFirstGroupIdOrNull() ?: ""
                busyStore.handleApiCall(
                    supplier = suspend {
                        when (currentObjectType) {
                            ObjectType.POI -> formationClient.createPOI(
                                groupId = groupId,
                                poi = NewPoi(
                                    latLon = newPosition,
                                    connectedToId = currentBuildingsStore.getConnectedToId(),
                                    title = current.title,
                                    description = current.description?.ifBlank { null },
                                    keywords = current.keywords,
                                    fieldValueTags = activeObjectFieldValuesStore.current.tagValues(),
                                    color = current.color,
                                    iconCategory = current.iconCategory,
                                    shape = current.shape,
                                ),
                            )

                            ObjectType.Task -> {
                                formationClient.createTask(
                                    group = groupId,
                                    task = NewTask(
                                        title = current.title,
                                        latLon = newPosition,
                                        connectedToId = currentBuildingsStore.getConnectedToId(),
                                        description = current.description?.ifBlank { null },
                                        atTime = current.atTime,
                                        assignedTo = assignee,
                                        keywords = current.keywords,
                                        fieldValueTags = activeObjectFieldValuesStore.current.tagValues(),
                                        reminders = listOfNotNull(
                                            assignee?.let { TimeTrigger(it, defaultTaskReminder) },
                                            apiUserStore.current.userId.let {
                                                TimeTrigger(
                                                    it,
                                                    defaultTaskReminder,
                                                )
                                            },
                                        ).distinct(),
                                    ),
                                )
                            }

                            ObjectType.Event -> formationClient.createEvent(
                                groupId = groupId,
                                event = NewEvent(
                                    title = current.title,
                                    latLon = newPosition,
                                    connectedToId = currentBuildingsStore.getConnectedToId(),
                                    atTime = current.atTime ?: now.toString(),
                                    endTime = current.endTime,
                                    description = current.description?.ifBlank { null },
                                    attendees = attendees,
                                    keywords = current.keywords,
                                    fieldValueTags = activeObjectFieldValuesStore.current.tagValues(),
                                ),
                            )

                            ObjectType.ObjectMarker -> {
                                (scannedCodeStore.current.extOrIntObjIdOrActionId
                                    ?: current.tags.getUniqueTag(ObjectTags.ExternalId))?.let { code ->
                                    val trackedObjectDetails = TrackedObjectDetails(
                                        externalId = code,
                                        title = current.title,
                                        description = current.description?.ifBlank { null },
                                        keywords = current.keywords,
                                        fieldValueTags = activeObjectFieldValuesStore.current.tagValues(),
                                        iconCategory = current.iconCategory,
                                        color = current.color,
                                        shape = current.shape,
                                    )
                                    // NOT USING THE USER POSITION ANYMORE
                                    // FIXME replace with archetype
                                    zoneService.scanObjectWithZoneTracking(
                                        groupId = groupId,
                                        trackedObjectDetails = trackedObjectDetails,
                                        position = newPosition,
                                        floorId = currentBuildingsStore.pointWithinFloor(newPosition), // FIXME figure out a UX to deal with zones on multiple floors
                                        clearNewObjectTag = true,
                                    )
                                }
                            }

                            ObjectType.Zone -> formationClient.createPOI(
                                groupId = groupId,
                                poi = NewPoi(
                                    latLon = newPosition,
                                    connectedToId = currentBuildingsStore.getConnectedToId(),
                                    title = current.title,
                                    description = current.description?.ifBlank { null },
                                    keywords = current.keywords,
                                    fieldValueTags = activeObjectFieldValuesStore.current.tagValues(),
                                ),
                            )

                            else -> {
                                console.log("ObjectType not yet supported!")
                                null
                            }
                        }
                    },
                    successMessage = translation[
                        TL.AlertNotifications.OBJECT_SUCCESSFULLY_CREATED,
                        mapOf(
                            "object" to currentObjectType.getName(),
                        ),
                    ],
                    processResult = { response ->
                        println("done!\n$response")
                        applyAttachments(response.id)
                        insertObjectInCachesAndMap(response)
                        scannedCodeStore.reset()

                        // FIXME Hack to create zone
                        if (current.objectType == ObjectType.Zone && response.objectType == ObjectType.POI) {
                            // convert POI to zone
                            busyStore.handleApiCall(
                                supplier = suspend {
                                    formationClient.restChangeType(response.id, ObjectType.Zone)
                                },
                                processResult = { zone ->
                                    insertObjectInCachesAndMap(zone)
                                },
                                processError = { error ->
                                    console.log("Failed to change POI to Zone", error)
                                },
                            )
                        }
                    },
                    errorMessage = translation[TL.AlertNotifications.OBJECT_CREATION_FAILED, mapOf("object" to currentObjectType.getName())],
                )
            }
        }
        saveChanges()
        resetStore()
        current
    }

    val applyObjectChanges = handle<ObjectChanges?> { original, changes ->
        if (changes != null) {
            busyStore.handleApiCall(
                supplier = {
                    formationClient.applyObjectChanges(listOf(changes))
                },
                processResult = { geoObjects ->
                    if (geoObjects.isNotEmpty()) {
                        // update the current active geoObject
                        val updated = geoObjects.firstOrNull { it.id == current.id }
                        console.log("Applied ObjectChanges successfully.", changes)
                        updated?.let { updatedActiveObj ->
                            update(updatedActiveObj)
                            insertObjectInCachesAndMap(updatedActiveObj)
                        }
                        applyLegacyAttachments(original.id)
                        // also update other geoObjects
                        geoObjects.filter { it.id != current.id }.forEach { otherUpdated ->
                            insertObjectInCachesAndMap(otherUpdated)
                        }
                        maplibreMap.syncMarkersNow()
                    }
                },
                processError = { error ->
                    console.warn("Failed to apply ObjectChange:", changes.changes, "With error:", error)
                },
            )
        }
        original
    }

    val edit = handle { current ->
        val currentLat = maplibreMap.getCenter().lat
        val currentLon = maplibreMap.getCenter().lng
        val newPosition = LatLon(lat = currentLat, lon = currentLon)
        val currentObjectType = current.objectType

        console.log("Edit object:", current)

        val init = initialObject

        routerStore.goToMap()

        apiScope.launch {
            updateTracker.track {
                if (current.id.isNotBlank()) {
                    when (currentObjectType) {
                        ObjectType.GeneralMarker, ObjectType.ObjectMarker -> {
                            apiScope.launch {
                                apiUserStore.current.apiUser?.groups?.firstOrNull()?.groupId?.let { groupId ->
                                    busyStore.handleApiCall(
                                        supplier = {
                                            formationClient.reverseGeocode(
                                                groupId = groupId,
                                                position = newPosition,
                                                parentId = currentBuildingsStore.getConnectedToId(),
                                            )
                                        },
                                        successMessage = translation[
                                            TL.AlertNotifications.OBJECT_SUCCESSFULLY_UPDATED,
                                            mapOf(
                                                "object" to currentObjectType.getName(),
                                            ),
                                        ],
                                        processResult = { response ->
                                            console.log("Reversed Geocode Success!", response)
                                            val connectedToId = response.best?.id

                                            val changeList = joinNullableLists(
                                                listOf(
                                                    getFieldModifications(init),
                                                    getContentChanges(),
                                                    listOfNotNull(
                                                        if (current.title != init.title) {
                                                            ChangeTitle(current.title)
                                                        } else {
                                                            null
                                                        },
                                                        if ((current.description?.trim() ?: "") != (init.description?.trim() ?: "")) {
                                                            SetDescription(current.description?.trim().takeIf { it.isNotNullOrEmpty() })
                                                        } else {
                                                            null
                                                        },
                                                        if (current.keywords != init.keywords) {
                                                            val removeKeywords =
                                                                init.keywords.orEmpty() - current.keywords.orEmpty()
                                                                    .toSet()
                                                            if (removeKeywords.isNotEmpty()) {
                                                                RemoveKeywords(removeKeywords)
                                                            } else {
                                                                null
                                                            }
                                                        } else {
                                                            null
                                                        },
                                                        if (current.keywords != init.keywords) {
                                                            val addKeywords =
                                                                current.keywords.orEmpty() - init.keywords.orEmpty()
                                                                    .toSet()
                                                            if (addKeywords.isNotEmpty()) {
                                                                AddKeywords(addKeywords)
                                                            } else {
                                                                null
                                                            }
                                                        } else {
                                                            null
                                                        },
                                                        if (current.iconCategory != init.iconCategory) {
                                                            SetMarkerIcon(current.iconCategory)
                                                        } else {
                                                            null
                                                        },
                                                        if (current.color != init.color) {
                                                            SetMarkerColor(current.color)
                                                        } else {
                                                            null
                                                        },
                                                        current.geoReferencedConnectableObject?.let { geoObj ->
                                                            ConnectableGeoShapeChange.RotateShape(geoObj.rotation)
                                                        },
                                                        UpdatePointLocation(
                                                            zoneId = connectedToId,
                                                            newPosition,
                                                            enableGeocoding = true,
                                                        ),
                                                    ),
                                                    // Currently these cannot be changed for markers created from archetype
                                                    if (currentObjectType != ObjectType.GeneralMarker) {
                                                        listOfNotNull(
                                                            if (current.shape != init.shape) {
                                                                SetMarkerShape(current.shape)
                                                            } else {
                                                                null
                                                            },
                                                        )
                                                    } else null,
                                                ),
                                            )

                                            busyStore.handleApiCall(
                                                supplier = {
                                                    formationClient.applyObjectChanges(
                                                        ObjectChanges(
                                                            current.id,
                                                            changeList,
                                                        ),
                                                    )
                                                },
                                                processResult = { geoObjects ->
                                                    if (geoObjects.isNotEmpty()) {
                                                        // update the current active geoObject
                                                        val updated = geoObjects.firstOrNull { it.id == current.id }
                                                        console.log("Applied ObjectChanges successfully.", changeList)
                                                        updated?.let { updatedActiveObj ->
                                                            update(updatedActiveObj)
                                                            insertObjectInCachesAndMap(updatedActiveObj)
                                                        }
                                                        // TODO remove this, once we have objectChanges for these too
                                                        applyLegacyAttachments(current.id)

                                                        // also update other geoObjects
                                                        geoObjects.filter { it.id != current.id }
                                                            .forEach { otherUpdated ->
                                                                insertObjectInCachesAndMap(otherUpdated)
                                                            }
                                                        maplibreMap.removeGeometryCenterOverride(current)
                                                        maplibreMap.removeGeometryShapeOverride(current)
                                                        maplibreMap.removeHiddenOverride(current.id)
                                                        resetStore()
                                                        maplibreMap.syncMarkersNow()
                                                    }
                                                },
                                                processError = { error ->
                                                    console.warn(
                                                        "Failed to apply ObjectChanges:",
                                                        changeList.toTypedArray(),
                                                        "With error:",
                                                        error,
                                                    )
                                                },
                                            )

                                        },
                                        errorMessage = translation[TL.AlertNotifications.OBJECT_UPDATE_FAILED, mapOf("object" to currentObjectType.getName())],
                                        processError = { error ->
                                            console.warn("Failed to get reversed geocode.", error.message)
                                        },
                                    )
                                }
                            }
                        }

                        else -> {
                            busyStore.handleApiCall(
                                supplier = suspend {
                                    when (currentObjectType) {
                                        ObjectType.POI -> {
                                            apiUserStore.current.apiUser?.groups?.firstOrNull()?.groupId?.let { groupId ->
                                                formationClient.updatePOI(
                                                    id = current.id,
                                                    poiUpdate = PoiUpdate(
                                                        latLon = newPosition,
                                                        connectedToId = currentBuildingsStore.getConnectedToId(),
                                                        title = current.title,
                                                        description = current.description?.ifBlank { null },
                                                        keywords = current.keywords,
                                                        fieldValueTags = gatherNewAndDeletedFieldValueTags(),
                                                        color = current.color,
                                                        iconCategory = current.iconCategory,
                                                        shape = current.shape,
                                                    ),
                                                )
                                            }
                                        }

                                        ObjectType.Task -> formationClient.updateTask(
                                            updateTask = UpdateTask(
                                                id = current.id,
                                                latLon = newPosition,
                                                connectedToId = currentBuildingsStore.getConnectedToId(),
                                                title = current.title,
                                                description = current.description?.ifBlank { null },
                                                atTime = current.atTime,
                                                keywords = current.keywords,
                                                fieldValueTags = gatherNewAndDeletedFieldValueTags(),
                                                reminders = current.tags.parseReminderTags()
                                                    .map { TimeTrigger(it.subjectId, it.timeBeforeMinutes) },
                                            ),
                                        )

                                        ObjectType.Event -> formationClient.updateEvent(
                                            event = UpdateEvent(
                                                id = current.id,
                                                latLon = newPosition,
                                                connectedToId = currentBuildingsStore.getConnectedToId(),
                                                title = current.title,
                                                atTime = current.atTime,
                                                endTime = current.endTime,
                                                description = current.description?.ifBlank { null },
                                                keywords = current.keywords,
                                                fieldValueTags = gatherNewAndDeletedFieldValueTags(),
                                            ),
                                        )

                                        ObjectType.Area -> formationClient.updateZone(
                                            id = current.id,
                                            zone = Zone(
                                                geometryString = json.encodeToString(
                                                    Geometry.serializer(),
                                                    Geometry.Point(
                                                        coordinates = LatLon(
                                                            lat = currentLat,
                                                            lon = currentLon,
                                                        ).pointCoordinates(),
                                                    ),
                                                ), // TODO replace with polygon if available
                                                title = current.title,
                                                description = current.description?.ifBlank { null },
                                                connectedToId = current.connectedTo,
                                                objectType = ZoneType.Area,
                                                keywords = current.keywords,
                                                fieldValueTags = gatherNewAndDeletedFieldValueTags(),
                                                color = current.color,
                                                iconCategory = current.iconCategory,
                                                shape = current.shape,
                                            ),
                                        )

                                        ObjectType.Zone -> formationClient.updateZone(
                                            id = current.id,
                                            zone = Zone(
                                                geometryString = json.encodeToString(
                                                    Geometry.serializer(),
                                                    Geometry.Point(
                                                        coordinates = LatLon(
                                                            lat = currentLat,
                                                            lon = currentLon,
                                                        ).pointCoordinates(),
                                                    ),
                                                ), // TODO replace with polygon if available
                                                title = current.title,
                                                description = current.description?.ifBlank { null },
                                                connectedToId = current.connectedTo,
                                                objectType = ZoneType.Zone,
                                                keywords = current.keywords,
                                                fieldValueTags = gatherNewAndDeletedFieldValueTags(),
                                                color = current.color,
                                                iconCategory = current.iconCategory,
                                                shape = current.shape,
                                            ),
                                        )

                                        ObjectType.GeoFence -> formationClient.updateZone(
                                            id = current.id,
                                            zone = Zone(
                                                geometryString = json.encodeToString(
                                                    Geometry.serializer(),
                                                    Geometry.Point(
                                                        coordinates = LatLon(
                                                            lat = currentLat,
                                                            lon = currentLon,
                                                        ).pointCoordinates(),
                                                    ),
                                                ), // TODO replace with polygon if available
                                                title = current.title,
                                                description = current.description?.ifBlank { null },
                                                connectedToId = current.connectedTo,
                                                objectType = ZoneType.GeoFence,
                                                keywords = current.keywords,
                                                fieldValueTags = gatherNewAndDeletedFieldValueTags(),
                                                color = current.color,
                                                iconCategory = current.iconCategory,
                                                shape = current.shape,
                                            ),
                                        )

                                        else -> {
                                            console.log("ObjectType not yet supported.")
                                            null
                                        }
                                    }
                                },
                                successMessage = translation[
                                    TL.AlertNotifications.OBJECT_SUCCESSFULLY_UPDATED,
                                    mapOf(
                                        "object" to currentObjectType.getName(),
                                    ),
                                ],
                                processResult = { updated ->
                                    GeoGeometry.distance(
                                        current.latLon.pointCoordinates(),
                                        updated.latLon.pointCoordinates(),
                                    ).let { d ->
                                        if (d > 0.0) {
                                            console.error("object moved by $d meters")
                                        } else {
                                            console.error("OBJECT DID NOT MOVE")
                                        }
                                    }
                                    insertObjectInCachesAndMap(updated)
                                    maplibreMap.removeGeometryCenterOverride(updated)
                                    maplibreMap.removeGeometryShapeOverride(updated)
                                    maplibreMap.removeHiddenOverride(updated.id)
                                    applyAttachments(updated.id)
                                    refresh()
                                    when (currentObjectType) {
                                        ObjectType.Task -> updateTaskAssignment()
                                        ObjectType.Event -> updateAttendees()
                                        else -> {
                                            resetStore()
                                        }
                                    }
                                },
                                errorMessage = translation[TL.AlertNotifications.OBJECT_UPDATE_FAILED, mapOf("object" to currentObjectType.getName())],
                                processError = {
                                    maplibreMap.removeGeometryCenterOverride(current)
                                    maplibreMap.removeGeometryShapeOverride(current)
                                    maplibreMap.removeHiddenOverride(current.id)
                                },
                                processNullResult = {
                                    maplibreMap.removeGeometryCenterOverride(current)
                                    maplibreMap.removeGeometryShapeOverride(current)
                                    maplibreMap.removeHiddenOverride(current.id)
                                    console.log("Response was null.")
                                },
                            )
                        }
                    }
                } else {
                    maplibreMap.removeGeometryCenterOverride(current)
                    maplibreMap.removeGeometryShapeOverride(current)
                    maplibreMap.removeHiddenOverride(current.id)
                    console.log("Object has no id!")
                }
            }
            maplibreMap.removeHiddenOverride(apiUserStore.current.userId)
            maplibreMap.off(type = "click", fnId = "resetOnMapClick")
        }
        maplibreMap.removeHiddenOverride(current.id)
        current.copy(latLon = newPosition)
    }

    private fun getFieldModifications(initObject: GeoObjectDetails): List<ObjectChange> {
        // FIXME, with direct editing this gets easier. We have AddFieldValue, ReplaceFieldValue, and RemoveFieldValue
        return currentWorkspaceStore.current?.fieldDefinitions?.let { definitions ->
            initObject.tags.parseFieldValues(definitions)
        }?.let { initialFieldValues ->

            console.log("Initial fieldValues", initialFieldValues.toString())

            val newFieldValues = activeObjectFieldValuesStore.current
            console.log("New Or Changed fieldValues", newFieldValues.toString())

            val removedFieldStrings = initialFieldValues.mapNotNull { oldFieldValue ->
                if (oldFieldValue.field !in newFieldValues.map { it.field }) {
                    oldFieldValue.field
                } else {
                    null
                }
            }
            console.log("Removed fieldValues", removedFieldStrings.toString())

            val removeFields = removedFieldStrings.let { if (it.isNotEmpty()) listOf(RemoveField(it)) else null }

            val fvMap = initialFieldValues.groupBy { it.field }.toMap()
            // Note this won't be able to deal with multiValued fields -> direct editing is the solution for this
            val replaceFieldValue = newFieldValues.mapNotNull { fieldValue ->
                if (fvMap[fieldValue.field]?.contains(fieldValue) != true || fieldValue.tag != initialFieldValues.first { it.field == fieldValue.field }.tag) {
                    ReplaceFieldValue(fieldValue)
                } else {
                    null
                }
            }

            val objectChanges = joinNullableLists(
                listOf(replaceFieldValue, removeFields),
            )
            objectChanges
        }.orEmpty()
    }

    private fun getContentChanges(): List<ObjectChange>? {

        // Removed Content
        val removed = removedAttachmentsStore.current.map { (attachmentId, attachment) ->
            when (attachment) {
                is Content.Image -> DeleteContent(attachmentId)
                is Content.GeoObject -> UpsertObjectLink(
                    id = attachmentId,
                    objectId = null,
                    title = "",
                )

                is Content.GeoReferencedImage -> DeleteContent(attachmentId)
                is Content.Markdown -> UpsertMarkdown(attachmentId)
                is Content.Poll -> UpsertPoll(
                    id = attachmentId,
                    title = "",
                    pollOptions = null,
                )

                is Content.ScanToCreateTask -> DeleteContent(attachmentId)
                is Content.SvgImage -> UpsertSvgImage(
                    id = attachmentId,
                    svgContent = null,
                    title = null,
                )

                is Content.WebLink -> UpsertWebLink(
                    id = attachmentId,
                    href = null,
                    title = "",
                )

                else -> DeleteContent(attachmentId)
            }
        }.takeIf { it.isNotEmpty() }

        // Changed or Added Content
        val changedOrAdded = preAttachmentsStore.current.values.mapNotNull { preAttachment ->
            when (preAttachment) {
                is PreAttachment.PreMarkdown -> {
                    UpsertMarkdown(
                        id = preAttachment.id,
                        title = preAttachment.title,
                        content = preAttachment.text,
                    )
                }

                is PreAttachment.PreWebLink -> {
                    UpsertWebLink(
                        id = preAttachment.id,
                        href = preAttachment.href,
                        title = preAttachment.title,
                        rel = preAttachment.rel?.name,
                        actionId = preAttachment.actionId,
                    )
                }

                is PreAttachment.PrePoll -> {
                    UpsertPoll(
                        id = preAttachment.id,
                        title = preAttachment.title,
                        pollOptions = preAttachment.options,
                        actionId = preAttachment.actionId,
                    )
                }

                is PreAttachment.PreGeoObject -> UpsertObjectLink(
                    id = preAttachment.id,
                    objectId = preAttachment.objectId,
                    title = preAttachment.title,
                    rel = preAttachment.rel?.name,
                )

                is PreAttachment.PreImage -> null
                is PreAttachment.PreScanToCreateTask -> {
                    val template = preAttachment.taskTemplate
                    template?.let {
                        UpsertTaskTemplate(
                            id = preAttachment.id,
                            title = preAttachment.title,
                            taskTemplate = template,
                            actionId = null,
                        )
                    }
                }

                is PreAttachment.PreSvgImage -> null
            }
        }.takeIf { it.isNotEmpty() }

        return joinNullableLists(listOf(removed, changedOrAdded)).takeIf { it.isNotEmpty() }
    }

    private fun gatherNewAndDeletedFieldValueTags(): List<String> {
        return currentWorkspaceStore.current?.fieldDefinitions?.let { definitions ->
            initialObject.tags.parseFieldValues(definitions)
        }?.let { initialFieldValues ->
            val changedFieldValues = activeObjectFieldValuesStore.current
            // Get all deleted fieldValues and map them to empty values
            val deletedFieldValues = initialFieldValues.filter { oldFieldValue ->
                oldFieldValue.field !in changedFieldValues.map { newFieldValue -> newFieldValue.field }
            }.map { "${it.field}:" }
            changedFieldValues.tagValues() + deletedFieldValues
        } ?: emptyList()
    }

    val delete = handle { current ->
        console.log("Delete object:", current)

        val currentObjectType = current.objectType

        routerStore.goToMap()

        apiScope.launch {
            deleteTracker.track {
                if (current.id.isNotBlank()) {
                    busyStore.handleApiCall(
                        supplier = suspend {
                            when (currentObjectType) {
                                ObjectType.GeneralMarker -> {
                                    apiUserStore.current.apiUser?.groups?.firstOrNull()?.groupId?.let { groupId ->
                                        formationClient.deleteGeneralMarker(workspaceId = groupId, id = current.id)
                                    }
                                }

                                else -> {
                                    formationClient.restDeleteObjectById(id = current.id)
                                }
                            }

//                            when (currentObjectType) {
//                                ObjectType.POI -> formationClient.deletePOI(id = current.id)
//                                ObjectType.Task -> formationClient.deleteTask(id = current.id)
//                                ObjectType.Event -> formationClient.deleteEvent(id = current.id)
//                                ObjectType.ObjectMarker -> formationClient.deleteTrackedObject(id = current.id)
//                                ObjectType.Area, ObjectType.Zone, ObjectType.GeoFence -> formationClient.deleteZone(
//                                    id = current.id
//                                )
//                                else -> {
//                                    console.log("ObjectType not yet supported!")
//                                    null
//                                }
//                            }?
                        },
                        successMessage = translation[
                            TL.AlertNotifications.OBJECT_SUCCESSFULLY_DELETED,
                            mapOf(
                                "object" to currentObjectType.getName(),
                            ),
                        ],
                        processResult = { response ->
                            println("done!\n${response}")
                            geoObjectCache[current.id] =
                                current.copy(tags = current.tags.setUniqueTag(ObjectTags.Deleted, true))
                            mapLayersStore.deleteHit(current.id)
                            removeObjectsInCachesAndMap(setOf(current.id))
                            maplibreMap.syncMarkersNow()
                        },
                        errorMessage = translation[
                            TL.AlertNotifications.OBJECT_DELETION_FAILED,
                            mapOf(
                                "object" to currentObjectType.getName(),
                            ),
                        ],
                        processError = { response ->
                            println("object deletion failed!\n${response.message}")
                        },
                    )
                }
            }
        }
        resetStore()
        maplibreMap.off(type = "click", fnId = "resetOnMapClick")
        current
    }

    val resetStore = handle { current ->
        mapLayersStore.flipLayer(mapOf(LayerType.ActiveObjectOrUserCopy to true))
        mapLayersStore.setResults(LayerType.ActiveObjectOrUserCopy, null)
        maplibreMap.removeHiddenOverride(apiUserStore.current.userId)
        maplibreMap.removeActiveObjectOverride(current.id)
        initialObject = emptyGeoObjectDetails
        resetChangeInputStores()
        emptyGeoObjectDetails
    }

    private val saveChanges = handle { current ->
        initialObject = current
        current
    }

    val resetActiveObjectAndUser = handle { current ->
        maplibreMap.removeActiveObjectOverride(current.id)
//        maplibreMap.removeOverride(current.id, current.objectType)
        resetStore()
        activeUserStore.reset()
        scannedCodeStore.reset()
        newConnectableShapeConnectionStore.reset()
        maplibreMap.off(type = "click", fnId = "resetOnMapClick")
        current
    }

    val resetActiveObjectAndUserToMap = handle { current ->
        coroutineScope {
            launch {
                routerStore.goToMap()
                resetActiveObjectAndUser()
            }
        }
        current
    }

    fun resetOnMapClick() {
        console.log("Map click -> resetOnMapClick")
        resetActiveObjectAndUserToMap()
    }

    val revertChanges = handle { current ->
        val revertObj = initialObject.copy(taskState = current.taskState)
        insertObjectInCachesAndMap(revertObj)
        maplibreMap.removeGeometryCenterOverride(revertObj)
        maplibreMap.removeGeometryShapeOverride(revertObj)
        maplibreMap.addActiveObjectOverride(revertObj)
        maplibreMap.removeHiddenOverride(revertObj.id)
        maplibreMap.off(type = "click", fnId = "resetOnMapClick")
        maplibreMap.once(type = "click", fn = ::resetOnMapClick, fnId = "resetOnMapClick")
        setActiveObjectCopy(revertObj)
        // TODO: investigate: unused ?
        mapLayersStore.flipLayer(mapOf(LayerType.ActiveObjectOrUserCopy to true))
        maplibreMap.removeHiddenOverride(apiUserStore.current.userId)
        update(revertObj)
        resetChangeInputStores()
        removedAttachmentsStore.reset()
        preAttachmentsStore.reset()
        revertObj
    }

    private val resetChangeInputStores = handle { current ->
        descriptionInputFieldStore.reset()
        datePickerStore.reset()
        timePickerStore.reset()
        attendeesSelectorStore.reset()
        assigneeSelectorStore.reset()
        colorSelectionStore.resetTo(current.objectType)
        iconSelectionStore.resetTo(current.objectType)
        shapeSelectionStore.resetTo(current.objectType)
        pointStyleToolSelectorStore.reset()
        activeObjectKeywordsStore.reset()
        activeObjectFieldValuesStore.reset()
        current
    }

    val updateChangeInputStores = handle { current ->
        current.description?.let { description -> descriptionInputFieldStore.update(description) }
            ?: descriptionInputFieldStore.update("")
        current.atTime?.parseInstant()?.let { atTime ->
            datePickerStore.update(atTime.formatDateForDatePicker())
            timePickerStore.update(atTime.formatTimeForTimePicker())
            current.endTime?.parseInstant()?.let { endTime ->
                durationPickerStore.update(extractDurationInMinutes(atTime, endTime).toInt())
            } ?: durationPickerStore.update(30)
        } ?: run {
            datePickerStore.update("")
            timePickerStore.update("")
            durationPickerStore.update(30)
        }
        current.attendees?.let { attendees -> attendeesSelectorStore.update(attendees.map { it.userId }) }
            ?: attendeesSelectorStore.update(null)
        current.assignedTo?.let { assignee -> assigneeSelectorStore.updateUserIds(listOf(assignee)) }
            ?: assigneeSelectorStore.update(null)
        current.color?.let { color -> colorSelectionStore.update(color) }
            ?: colorSelectionStore.resetTo(current.objectType)
        current.iconCategory?.let { icon -> iconSelectionStore.update(icon) }
            ?: iconSelectionStore.resetTo(current.objectType)
        current.shape?.let { shape -> shapeSelectionStore.update(shape) }
            ?: shapeSelectionStore.resetTo(current.objectType)
        current.keywords?.let { keywords -> activeObjectKeywordsStore.update(keywords) }
            ?: activeObjectKeywordsStore.reset()
        currentWorkspaceStore.current?.fieldDefinitions?.let { definitions ->
            current.tags.parseFieldValues(definitions)
        }?.let { fieldValues ->
            activeObjectFieldValuesStore.update(fieldValues)
        } ?: run { activeObjectFieldValuesStore.reset() }
        current.attachments?.let { attachments ->
            attachmentsStore.update(
                attachments.filter { attachment ->
                    attachment.id !in removedAttachmentsStore.interimState
                }.associateBy { it.id },
            )
        } ?: attachmentsStore.reset()
        current
    }

    val readFromChangeInputStores = handle { current ->
        val newAttendeeList = with(attendeesSelectorStore.current) {
            if (!this.isNullOrEmpty()) {
                val remaining = current.attendees?.filter { attendee -> this.contains(attendee.userId) }
                val remainingIds = remaining?.map { attendee -> attendee.userId }
                val new = this.filter { id -> !(remainingIds ?: listOf()).contains(id) }
                    .map { id -> MeetingAttendee(userId = id, MeetingInvitationStatus.Pending, optional = false) }
                (remaining ?: listOf()) + new
            } else null
        }
        val newAssignee =
            with(assigneeSelectorStore.current) { if (!this.isNullOrEmpty()) this.firstOrNull() else null }

        current.copy(
            description = descriptionInputFieldStore.current,
            atTime = combineToInstant(datePickerStore.current, timePickerStore.current)?.toString(),
            endTime = calculateEndDate(
                combineToInstant(datePickerStore.current, timePickerStore.current),
                durationPickerStore.current,
            ),
            assignedToUser = newAssignee?.toUserProfileSummary(),
            assignedTo = newAssignee?.userId,
            attendees = newAttendeeList,
            keywords = activeObjectKeywordsStore.current,
            color = colorSelectionStore.current,
            iconCategory = iconSelectionStore.current,
            shape = shapeSelectionStore.current,
        )
    }

    val setAttendee = handle<String> { current, attendeeId ->
        current.copy(
            attendees = listOf(
                MeetingAttendee(
                    userId = attendeeId,
                    MeetingInvitationStatus.Pending,
                    optional = false,
                ),
            ),
        )
    }

    val setAssignee = handle<PublicUserProfile> { current, userProfile ->
        current.copy(
            assignedToUser = userProfile.toUserProfileSummary(),
            assignedTo = userProfile.userId,
        )
    }

    var lastInputRotation = storeOf(current.geoReferencedConnectableObject?.rotation ?: 0.0, job = job)

    val setConnectedObjectRotation = SimpleHandler<Double> { data, _ ->
        data handledBy { rotation ->
            val rotated = if (current.geoReferencedConnectableObject?.rotation != rotation) {
//                console.log("UPDATE ROTATION", rotation, rotation.roundTo(2))
                current.copy(
                    geoReferencedConnectableObject = current.geoReferencedConnectableObject?.copy(
                        rotation = rotation.roundTo(2),
                    ),
                )
            } else current
            setActiveObjectOnMap(rotated)
            update(rotated)
        }
    }

    val setConnectedObjectRotationFromInput = handle<Double> { current, rotation ->
        if (current.geoReferencedConnectableObject?.rotation != rotation) {
            console.log("UPDATE ROTATION FROM INPUT", rotation, rotation.roundTo(2))
            lastInputRotation.update(rotation)
            current.copy(
                geoReferencedConnectableObject = current.geoReferencedConnectableObject?.copy(
                    rotation = rotation.roundTo(2),
                ),
            )
        } else current
    }

    val centerMapToObject = handle { current ->
        if (current.latLon != LatLon(0.0, 0.0)) {
            maplibreMap.panTo(center = LatLon(current.latLon.lat, current.latLon.lon))
        }
        current
    }

    val focusThisObject = SimpleHandler<Unit> { data, _ ->
        data handledBy {
            maplibreMap.flyTo(center = LatLon(current.latLon.lat, current.latLon.lon), zoom = maplibreMap.getZoom())
            updateChangeInputStores()
        }
    }

    val removeMapClickListener = handle { current ->
        maplibreMap.off(type = "click", fnId = "resetOnMapClick")
        current
    }

    val releaseTrackedObject = handle { old ->
        busyStore.handleApiCall(
            supplier = suspend {
                formationClient.releaseTrackedObject(
                    ownerId = currentWorkspaceStore.current?.groupId ?: error("groupId should not be null"),
                    objectId = old.id,
                )
            },
            successMessage = translation[BusyStoreTexts.Success],
            processResult = {
                mapLayersStore.deleteHit(it.id)
                resetStore()
                CoroutineScope(CoroutineName("refresh-objects")).launch {
                    delay(1500)
                    mapSearchClientsStore.updateMapSearchClients(null)
                }
                routerStore.goToMap()
            },
        )
        old
    }

    val archiveObject = handle { old ->
        busyStore.handleApiCall(
            supplier = suspend {
                formationClient.restArchiveObject(objectId = old.id)
            },
            successMessage = translation[BusyStoreTexts.Success],
            processResult = {
                mapLayersStore.deleteHit(it.id)
                resetStore()
                CoroutineScope(CoroutineName("refresh-objects")).launch {
                    delay(1500)
                    mapSearchClientsStore.updateMapSearchClients(null)
                }
                routerStore.goToMap()
            },
        )
        old
    }

    val unArchiveObject = handle { old ->
        busyStore.handleApiCall(
            supplier = suspend {
                formationClient.restUnArchiveObject(objectId = old.id)
            },
            successMessage = translation[BusyStoreTexts.Success],
            processResult = {
                resetStore()
                CoroutineScope(CoroutineName("refresh-objects")).launch {
                    delay(1500)
                    mapSearchClientsStore.updateMapSearchClients(null)
                }
                routerStore.goToMap()
            },
            withBusyState = true,
        )
        old
    }

    val flagObject = handle { old ->
        busyStore.handleApiCall(
            supplier = suspend {
                formationClient.restFlagObject(objectId = old.id)
            },
            successMessage = translation[BusyStoreTexts.Success],
            processResult = {
                mapLayersStore.deleteHit(it.id)
                resetStore()
                CoroutineScope(CoroutineName("refresh-objects")).launch {
                    delay(1500)
                    mapSearchClientsStore.updateMapSearchClients(null)
                }
                routerStore.goToMap()
            },
        )
        old
    }

    val unflagObject = handle { old ->
        busyStore.handleApiCall(
            supplier = suspend {
                formationClient.restUnFlagObject(objectId = old.id)
            },
            successMessage = translation[BusyStoreTexts.Success],
            processResult = {
                resetStore()
                CoroutineScope(CoroutineName("refresh-objects")).launch {
                    delay(1500)
                    mapSearchClientsStore.updateMapSearchClients(null)
                }
                routerStore.goToMap()
            },
        )
        old
    }

    val hideObjectMarker = handle { old ->
        busyStore.handleApiCall(
            supplier = suspend {
                formationClient.applyObjectChanges(
                    ObjectChanges(
                        current.id,
                        listOf(SetObjectVisibility(ObjectVisibility.MarkerHidden)),
                    ),
                )
            },
            successMessage = translation[BusyStoreTexts.Success],
            processResult = { geoObjects ->
                geoObjects.firstOrNull()?.tags?.let { tagList ->
                    tags.update(tagList)
                }
                CoroutineScope(CoroutineName("refresh-objects")).launch {
                    mapSearchClientsStore.updateMapSearchClients(null)
                }
            },
        )
        old
    }

    val showObjectMarker = handle { old ->
        busyStore.handleApiCall(
            supplier = suspend {
                formationClient.applyObjectChanges(
                    ObjectChanges(
                        current.id,
                        listOf(SetObjectVisibility(null)),
                    ),
                )
            },
            successMessage = translation[BusyStoreTexts.Success],
            processResult = { geoObjects ->
                geoObjects.firstOrNull()?.tags?.let { tagList ->
                    tags.update(tagList)
                }
                CoroutineScope(CoroutineName("refresh-objects")).launch {
                    mapSearchClientsStore.updateMapSearchClients(null)
                }
            },
        )
        old
    }

    val connectQRCodeToObject = handle { current ->
        val oldExternalIds = current.tags.getTagValues(ObjectTags.ExternalId)
        if (oldExternalIds.isNotEmpty()) {
            removeQRcode()
            addQRcode()
        } else {
            addQRcode()
        }
        current
    }

    private suspend fun addQRcode() {
        val groupId = currentWorkspaceStore.current?.groupId
        val scannedCodeStore by koinCtx.inject<ScannedCodeStore>()
        val manualCodeInputStore by koinCtx.inject<ManualCodeInputStore>()
        scannedCodeStore.current.extOrIntObjIdOrActionId?.let { externalId ->
            groupId?.let { gId ->
                busyStore.handleApiCall(
                    suspend {
                        formationClient.addExternalIds(gId, current.id, listOf(externalId))
                    },
                    processResult = {
                        resetStore()
                        manualCodeInputStore.reset()
                        CoroutineScope(CoroutineName("refresh-objects")).launch {
                            delay(1500)
                            mapSearchClientsStore.updateMapSearchClients(null)
                        }
                        scannedCodeStore.reset()
                        routerStore.goToMap()
                    },
                    withBusyState = true,
                    successMessage = flowOf("QR code successfully connected."),
                    errorMessage = flowOf("QR code connection failed."),
                )
            }
        }
    }

    private suspend fun removeQRcode() {
        val groupId = currentWorkspaceStore.current?.groupId
        groupId?.let { gId ->
            val oldExternalIds = current.tags.getTagValues(ObjectTags.ExternalId)
            if (oldExternalIds.size > 1) {
                console.warn("would remove multiple externalIds")
            } else {
                busyStore.handleApiCall(
                    suspend {
                        formationClient.removeExternalIds(gId, current.id, oldExternalIds)
                    },
                    processResult = {
                        console.log("Removed externalIds:", oldExternalIds.toString())
                    },
                    withBusyState = true,
                    successMessage = flowOf("Removed externalIds: $oldExternalIds"),
                    errorMessage = flowOf("Removing QR code failed."),
                )
            }
        }
    }

    val releaseQRCodeFromObject = handle { old ->
        val oldExternalIds = current.tags.getTagValues(ObjectTags.ExternalId)
        currentWorkspaceStore.current?.groupId?.let { gId ->
            busyStore.handleApiCall(
                suspend {
                    formationClient.removeExternalIds(
                        ownerId = gId,
                        objectId = old.id,
                        externalIds = oldExternalIds,
                    )
                },
                processResult = {
                    resetStore()
                    CoroutineScope(CoroutineName("refresh-objects")).launch {
                        delay(1500)
                        mapSearchClientsStore.updateMapSearchClients(null)
                    }
                    routerStore.goToMap()
                },
                withBusyState = true,
                successMessage = translation[BusyStoreTexts.Success],
                errorMessage = flowOf("Removing QR code failed."),
            )
        }
        old
    }

    private fun refresh() = apiScope.launch {
        delay(1000)
        mapSearchClientsStore.mapSearch(null)
    }

    val setTaskStatus = handle<TaskState> { current, newStatus ->
        if (current.canModify && current.taskState != newStatus) { //current.canManage &&
            current.copy(taskState = newStatus)
        } else current
    }

    val resetTaskStatus = handle { current ->
        if (current.canModify) {
            current.copy(taskState = initialObject.taskState)
        } else current
    }

    val changeTaskStatus = handle { current ->
        if (current.taskState != null) {
            apiScope.launch {
                updateTracker.track {
                    if (current.id != "") {
                        busyStore.handleApiCall(
                            supplier = suspend {
                                formationClient.changeTaskState(id = current.id, state = current.taskState!!)
                            },
                            successMessage = translation[
                                TL.AlertNotifications.OBJECT_STATE_SUCCESSFULLY_CHANGED,
                                mapOf(
                                    "object" to current.objectType.getName(),
                                ),
                            ],
                            processResult = { response ->
                                println("task state changed!\n$response")
                                mapSearchClientsStore.invalidateLayerCaches(current.objectType)
                                initialObject = response
                                mapLayersStore.setUpdatedHit(response)
                            },
                            errorMessage = translation[
                                TL.AlertNotifications.OBJECT_STATE_CHANGE_FAILED,
                                mapOf(
                                    "object" to current.objectType.getName(),
                                ),
                            ],
                        )
                    }
                }
            }
        }
        current
    }

    val updateTaskAssignment = handle { current ->
        val currentAssignee = assigneeSelectorStore.current?.firstOrNull()
        val initialAssigneeId = initialObject.assignedTo
        apiScope.launch {
            updateTracker.track {
                if (currentAssignee?.userId != initialAssigneeId) {
                    if (current.id.isNotBlank()) {
                        busyStore.handleApiCall(
                            supplier = suspend {
                                formationClient.changeTaskAssignment(
                                    id = current.id,
                                    userId = currentAssignee?.userId,
                                    timeBeforeMinutes = defaultTaskReminder,
                                )
                            },
                            successMessage = if (currentAssignee != null) {
                                translation[
                                    TL.AlertNotifications.TASK_ASSIGNED_TO_USER,
                                    mapOf(
                                        "firstname" to (currentAssignee.firstName ?: ""),
                                        "lastname" to (currentAssignee.lastName ?: ""),
                                    ),
                                ]
                            } else translation[TL.AlertNotifications.TASK_UNASSIGNED],
                            processResult = { response ->
                                if (currentAssignee != null) {
                                    println("Task assignment changed!\n$response")
                                } else {
                                    println("Task assignment removed!\n$response")
                                }
                                update(
                                    current.copy(
                                        assignedTo = currentAssignee?.userId,
                                        taskState = if (currentAssignee != null) TaskState.Pending else TaskState.UnAssigned,
                                    ),
                                )
                                mapLayersStore.setUpdatedHit(response)
                                saveChanges()
                            },
                            errorMessage = if (currentAssignee != null) {
                                translation[TL.AlertNotifications.TASK_ASSIGNMENT_FAILED]
                            } else translation[TL.AlertNotifications.TASK_UNASSIGNMENT_FAILED],
                            processError = {

                            },
                        )
                    }
                }
                resetStore()
            }
        }
        current
    }

    val assignYourselfToTask = handle { current ->
        val user = apiUserStore.current
        apiScope.launch {
            updateTracker.track {
                if (current.id.isNotBlank() && user.userId.isNotBlank()) {
                    busyStore.handleApiCall(
                        supplier = suspend {
                            formationClient.changeTaskAssignment(
                                id = current.id,
                                userId = user.userId,
                                timeBeforeMinutes = defaultTaskReminder,
                            )
                        },
                        successMessage = translation[
                            TL.AlertNotifications.TASK_ASSIGNED_TO_USER,
                            mapOf(
                                "firstname" to translation.getString(
                                    TL.General.YOU,
                                    mapOf("case" to "dative"),
                                ),
                                "lastname" to "",
                            ),
                        ],
                        processResult = { response ->
                            println("Task assigned to yourself!\n$response")
                            update(
                                current.copy(
                                    assignedTo = user.userId,
                                    taskState = TaskState.Pending,
                                ),
                            )
                            mapLayersStore.setUpdatedHit(response)
                            saveChanges()
                        },
                        errorMessage = translation[TL.AlertNotifications.TASK_ASSIGNMENT_FAILED],
                    )
                }
            }
        }
        current
    }

    val unassignYourselfFromTask = handle { current ->
        val user = apiUserStore.current
        apiScope.launch {
            updateTracker.track {
                if (current.id.isNotBlank() && user.userId == current.assignedTo) {
                    busyStore.handleApiCall(
                        supplier = suspend {
                            formationClient.changeTaskAssignment(
                                id = current.id,
                                userId = null,
                                timeBeforeMinutes = defaultTaskReminder,
                            )
                        },
                        successMessage = translation[TL.AlertNotifications.TASK_UNASSIGNED],
                        processResult = { response ->
                            println("Task unassigned from yourself!\n$response")
                            update(
                                current.copy(
                                    assignedTo = null,
                                    taskState = TaskState.UnAssigned,
                                ),
                            )
                            mapLayersStore.setUpdatedHit(response)
                            saveChanges()
                        },
                        errorMessage = translation[TL.AlertNotifications.TASK_UNASSIGNMENT_FAILED],
                    )
                }
            }
        }
        current
    }

    val updateAttendees = handle { current ->
        console.log("initial State", initialObject)
        console.log("current Attendees", initialObject.attendees?.map { it.userId }.toString())
        console.log("current Selected", attendeesSelectorStore.current.toString())

        val selectedUsers = attendeesSelectorStore.current
        if (current.id.isNotBlank()) {
            apiScope.launch {
                updateTracker.track {
                    val newInvites = if (!selectedUsers.isNullOrEmpty()) {
                        selectedUsers.filterNot { id ->
                            ((initialObject.attendees ?: listOf()).map { attendee -> attendee.userId }).contains(id)
                        }.map { EventInvitation(userId = it, remindBeforeMinutes = defaultEventReminders) }
                    } else null

                    if (newInvites != null) {
                        console.log("new", newInvites.map { it.userId }.toString())
                    }

                    val obsoleteInvites = if (!selectedUsers.isNullOrEmpty()) {
                        initialObject.attendees?.filterNot { attendee ->
                            selectedUsers.contains(attendee.userId)
                        }?.map { it.userId }
                    } else initialObject.attendees?.map { it.userId }

                    console.log("remove", obsoleteInvites.toString())
                    busyStore.handleApiCall(
                        supplier = suspend {
                            val addResp = if (!newInvites.isNullOrEmpty()) {
                                formationClient.addInvitees(current.id, newInvites)
                            } else null

                            val removeResp = if (!obsoleteInvites.isNullOrEmpty()) {
                                formationClient.removeInvitees(current.id, obsoleteInvites)
                            } else null

                            when {
                                removeResp != null -> removeResp
                                addResp != null -> addResp
                                else -> null
                            }
                        },
                        successMessage = translation[TL.AlertNotifications.MEETING_INVITATIONS_SUCCESSFULLY_UPDATED],
                        processResult = { response ->
                            println("Changed meeting invitations!\n$response")
                            mapLayersStore.setUpdatedHit(response)
                            saveChanges()
                        },
                        errorMessage = translation[TL.AlertNotifications.MEETING_INVITATIONS_UPDATE_FAILED],
                    )
                    resetStore()
                }
            }
        } else println("I did nothing")
        current
    }

    val setMeetingStatus = handle<MeetingInvitationStatus> { current, newStatus ->
        val currentUserId = apiUserStore.current.userId
        val userIsAttendee = currentUserId in (current.attendees?.map { it.userId } ?: emptyList())
        if (userIsAttendee) {
            current.copy(
                attendees = (current.attendees?.filter { it.userId != currentUserId } ?: emptyList())
                    + listOf(MeetingAttendee(currentUserId, newStatus, optional = false)),
            )
        } else current
    }

    val resetMeetingStatus = handle { current ->
        val currentUserId = apiUserStore.current.userId
        val userIsAttendee = currentUserId in (current.attendees?.map { it.userId } ?: emptyList())
        val initialMeetingState =
            initialObject.attendees?.firstOrNull { it.userId == currentUserId }?.meetingInvitationStatus
        if (userIsAttendee && initialMeetingState != null) {
            current.copy(
                attendees = (current.attendees?.filter { it.userId != currentUserId } ?: emptyList())
                    + listOf(MeetingAttendee(currentUserId, initialMeetingState, optional = false)),
            )
        } else current
    }

    val changeMeetingStatus = handle { current ->
        val currentUserId = apiUserStore.current.userId
        val userIsAttendee = currentUserId in (current.attendees?.map { it.userId } ?: emptyList())
        if (userIsAttendee) {
            val currentStatus = current.attendees?.find { it.userId == currentUserId }?.meetingInvitationStatus
            apiScope.launch {
                updateTracker.track {
                    busyStore.handleApiCall(
                        supplier = suspend {
                            currentStatus?.let { status ->
                                formationClient.changeInviteStatus(
                                    current.id,
                                    currentUserId,
                                    status,
                                )
                            }
                        },
                        successMessage = translation[
                            TL.AlertNotifications.OBJECT_SUCCESSFULLY_UPDATED,
                            mapOf(
                                "object" to current.objectType.getName(),
                            ),
                        ],
                        processResult = { response ->
                            initialObject = response
                            mapLayersStore.setUpdatedHit(response)
                        },
                        errorMessage = translation[
                            TL.AlertNotifications.OBJECT_UPDATE_FAILED,
                            mapOf(
                                "object" to current.objectType.getName(),
                            ),
                        ],
                    )
                }
            }
        }
        current
    }

    val hideActiveObjectMarker = handle { current ->
        maplibreMap.removeActiveObjectOverride(current.id)
        maplibreMap.addHiddenOverride(current.id)
        //TODO: investigate: unused ?
        mapLayersStore.flipLayer(mapOf(LayerType.ActiveObjectOrUserCopy to false))
        current
    }

    val prefillTitleWithCode = handle { current ->
        prepareMarkerCustomizationStores(ObjectType.ObjectMarker, scannedCodeStore.current)
        scannedCodeStore.current.extOrIntObjIdOrActionId?.let { code -> current.copy(title = code) } ?: current
    }

    private fun prepareMarkerCustomizationStores(objType: ObjectType? = null, scannedCode: ScannedCode? = null) {
        val type = objType ?: current.objectType
        scannedCode?.color?.let { colorSelectionStore.update(it) } ?: kotlin.run { colorSelectionStore.resetTo(type) }
        scannedCode?.icon?.let { iconSelectionStore.update(it) } ?: kotlin.run { iconSelectionStore.resetTo(type) }
        scannedCode?.shape?.let { shapeSelectionStore.update(it) } ?: kotlin.run { shapeSelectionStore.resetTo(type) }
        scannedCode?.tag?.let { activeObjectKeywordsStore.add(it) }
    }

    private val setActiveObjectCopy = SimpleHandler<GeoObjectDetails> { data, _ ->
        data handledBy { newObject ->
            setActiveObjectCopyResult(obj = newObject, active = newObject.id.isNotBlank())
            if (newObject.id.isNotBlank()) {
                update(newObject)
            }
        }
    }

    private val showOrHideActiveObjectCopy = handle<List<String>?> { current, floorIds ->
        if (current.id.isNotBlank()) {
            setActiveObjectCopyResult(
                obj = current,
//                active = currentBuildingsStore.pointWithinFloor(current.latLon)
//                    .isNullOrBlank() || (floorIds != null && current.tags.floorId in floorIds),
                active = current.tags.floorId == null || (floorIds != null && current.tags.floorId in floorIds),
            )
        }
        current
    }

    fun setActiveObjectCopyResult(obj: GeoObjectDetails, active: Boolean) {
        if (obj.id !in maplibreMap.disabledObjects) {
            if (active) {
                mapLayersStore.setResults(
                    LayerType.ActiveObjectOrUserCopy,
                    ObjectSearchResults(from = 0, pageSize = 0, total = 1, hits = listOf(obj.toSearchResult())),
                )
            } else {
                mapLayersStore.setResults(LayerType.ActiveObjectOrUserCopy, null)
            }
        } else {
            mapLayersStore.setResults(LayerType.ActiveObjectOrUserCopy, null)
        }
    }

    val readFieldValues = handle<List<String>> { current, tags ->
        currentWorkspaceStore.current?.fieldDefinitions?.let { definitions ->
            tags.parseFieldValues(definitions)
        }?.let { fieldValues ->
            activeObjectFieldValuesStore.update(fieldValues)
        }
        current
    }

    private fun applyLegacyAttachments(activeObjectId: String) {
        console.log("APPLY legacy attachments")
        val groupId = apiUserStore.current.getFirstGroupIdOrNull()
        apiScope.launch {
            CoroutineName("apply-legacy-attachments")
            attachmentTracker.track {
                // apply other attachemnts apart from object changes
                preAttachmentsStore.current.values.forEach { preAttachment ->
                    when (preAttachment) {
                        is PreAttachment.PreImage -> {
                            CoroutineScope(CoroutineName("attach-image")).launch {
                                groupId?.let { gId ->
                                    busyStore.handleApiCall(
                                        supplier = suspend {
                                            formationClient.attachImageToGeoObject(
                                                ownerId = gId,
                                                objectId = activeObjectId,
                                                title = preAttachment.title,
                                                imageBytes = preAttachment.prevBytes,
                                                attachmentId = preAttachment.id,
                                            )
                                        },
                                        processResult = { geoObject ->
                                            geoObject.attachments?.let { attachmentList ->
                                                attachments.update(attachmentList)
                                            }
                                            insertObjectInCachesAndMap(geoObject)
                                        },
                                        processError = { error ->
                                            console.log(error)
                                        },
                                    )
                                }
                            }
                        }

                        is PreAttachment.PreGeoObject -> {
                            CoroutineScope(CoroutineName("attach-geoObject")).launch {
                                // get object by provided id, to get internal id
                                groupId?.let { gId ->
                                    busyStore.handleApiCall(
                                        supplier = suspend {
                                            // TODO use Lookup API here
                                            formationClient.restGetObjectById(preAttachment.objectId)
                                        },
                                        processResult = { geoObjectbyExternal ->
                                            busyStore.handleApiCall(
                                                supplier = suspend {
                                                    formationClient.restGetObjectById(preAttachment.objectId)
                                                },
                                                processResult = { geoObject ->
                                                    attachGeoObjectApiCall(
                                                        activeObjectId,
                                                        geoObject.id,
                                                        preAttachment,
                                                    )
                                                },
                                                withBusyState = true,
                                                busyStateMessage = flowOf("Attaching objects..."),
                                            )
                                        },
                                    )
                                }
                            }
                        }

                        is PreAttachment.PreScanToCreateTask -> {
                            CoroutineScope(CoroutineName("attach-scan-to-task-template")).launch {
                                groupId?.let { gId ->
                                    preAttachment.actionId?.let { actionId ->
                                        preAttachment.taskTemplate?.let { taskTemplate ->
                                            busyStore.handleApiCall(
                                                supplier = suspend {
                                                    formationClient.applyObjectChanges(
                                                        ObjectChanges(
                                                            activeObjectId,
                                                            UpsertTaskTemplate(
                                                                id = preAttachment.id,
                                                                actionId = actionId,
                                                                taskTemplate = taskTemplate,
                                                                // FIXME title
                                                            ),
                                                        ),
                                                    )
//                                                    formationClient.attachScanToCreateTask(
//                                                        ownerId = gId,
//                                                        objectId = activeObjectId,
//                                                        actionId = actionId,
//                                                        taskTemplate = taskTemplate,
//                                                        attachmentId = preAttachment.id,
//                                                    )
                                                },
                                                processResult = { geoObject ->
                                                    geoObject.first().attachments?.let { attachmentList ->
                                                        attachments.update(attachmentList)
                                                    }
                                                    insertObjectInCachesAndMap(geoObject.first())
                                                },
                                                processError = { error ->
                                                    console.log(error)
                                                },
                                            )
                                        }
                                    }
                                }
                            }
                        }

                        else -> {}
                    }
                }
                preAttachmentsStore.reset()
                attachmentsStore.reset()
            }
        }
    }

    private suspend fun applyAttachments(activeObjectId: String) {
        console.log("APPLY attachments")
        console.log("PreAttachments", preAttachmentsStore.current.values.map { it.id })

        apiScope.launch {
            CoroutineName("apply-all-attachments")
            attachmentTracker.track {

                busyStore.handleApiCall(
                    supplier = suspend {
                        getContentChanges()?.let { changes ->
                            formationClient.applyObjectChanges(
                                ObjectChanges(
                                    objectId = activeObjectId,
                                    changes,
                                ),
                            )
                        }
                    },
                    processResult = { geoObjects ->
                        val newObj = geoObjects.firstOrNull()
                        newObj?.let {
                            it.attachments?.let { attachmentList ->
                                attachments.update(attachmentList)
                            }
                            insertObjectInCachesAndMap(newObj)
                        }
                        (geoObjects - newObj).forEach { otherObj ->
                            otherObj?.let {
                                insertObjectInCachesAndMap(it)
                            }
                        }
                        removedAttachmentsStore.reset()
                        console.log("Attachments removed successfully")
                    },
                    processError = { error ->
                        console.log("failed to remove attachments", error)
                    },
                )

                applyLegacyAttachments(activeObjectId)
            }
        }
    }

    private suspend fun attachGeoObjectApiCall(
        activeObjectId: String,
        geoObjectIdToAttach: String,
        preAttachment: PreAttachment.PreGeoObject,
    ) {
        busyStore.handleApiCall(
            supplier = suspend {
                formationClient.applyObjectChanges(
                    ObjectChanges(
                        objectId = activeObjectId,
                        UpsertObjectLink(
                            id = preAttachment.id,
                            objectId = geoObjectIdToAttach,
                            title = preAttachment.title,
                            rel = preAttachment.rel?.name,
                        ),
                    ),
                )
            },
            processResult = { geoObjects ->
                val newObj = geoObjects.firstOrNull()
                newObj?.let {
                    it.attachments?.let { attachmentList ->
                        attachments.update(attachmentList)
                    }
                    insertObjectInCachesAndMap(newObj)
                }
                (geoObjects - newObj).forEach { otherObj ->
                    otherObj?.let {
                        insertObjectInCachesAndMap(it)
                    }
                }
            },
            processError = { error ->
                console.log(error)
            },
        )
    }

    private val setActiveObjectOnMap = SimpleHandler<GeoObjectDetails> { data, _ ->
        data handledBy { obj ->
            if (obj.id.isBlank()) {
                // TODO: remove active object override
                // how to get id from last active object ?
                maplibreMap.removeAllActiveObjectOverrides()
            } else {
                maplibreMap.addActiveObjectOverride(obj)
            }
        }
    }

    val editTitle = handle<String> { current, newTitle ->
        newTitle.takeIf { it != current.title }?.let { title ->
            applyObjectChanges(ObjectChanges(current.id, ChangeTitle(title)))
            current.copy(title = title)
        } ?: current
    }

    init {
        attachments.data.map { attachments ->
            attachments?.associateBy { it.id } ?: emptyMap()
        } handledBy attachmentsStore.update
        tags.data handledBy readFieldValues
        activeFloorsStore.data handledBy showOrHideActiveObjectCopy
        // using id to fire handlers if whole active object changes
        activeObjectId.data.map { current } handledBy setActiveObjectCopy
        activeObjectId.data.mapNotNull {
            val objId = current.tags.getUniqueTag(ObjectTags.ExternalId) ?: current.id
            if (objId.isNotBlank()) {
                if (current.objectType != ObjectType.GeneralMarker) {
                    maplibreMap.off(type = "click", fnId = "resetOnMapClick")
                    maplibreMap.once(type = "click", fn = ::resetOnMapClick, fnId = "resetOnMapClick")
                }
//                mapOf("id" to objId)
                routerStore.baseRoute() + Pages.Map.route + mapOf("id" to objId)
            } else null
        } handledBy routerStore.validateInternalRoute
        activeObjectId.data.map { current } handledBy setActiveObjectOnMap
    }
}
