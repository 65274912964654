package workspacetools.usermanagement

import org.koin.dsl.module

val userManagementModule = module {
    single { GroupMembersStore() }
    single { CopyToCLipboardStore() }
    single { SelectedGroupMemberStore() }
    single { InvitationEmailStore() }
    single { TsvStore() }
}
