package map.views.workplacetools

import analytics.AnalyticsCategory
import apiclient.geoobjects.ObjectType
import dev.fritz2.core.RenderContext
import koin.koinCtx
import kotlinx.coroutines.flow.map
import localization.Translation
import mainmenu.Pages
import map.Cards
import objectrouting.NavigationToolToggleStore
import qrcode.ManualEnterStore

enum class ToolSelection(val toolButton: RenderContext.() -> Unit) {
    Event(
        toolButton = {
            val apiUserStore: auth.ApiUserStore by koinCtx.inject()
            objectToolButton(
                ObjectType.Event,
                disabled = apiUserStore.data.map { user -> user.isAnonymous },
                analyticsEventProvider = AnalyticsCategory.CreateEventButton.click(),
            )
        },
    ) {},
    Point(
        toolButton = {
            val apiUserStore: auth.ApiUserStore by koinCtx.inject()
            objectToolButton(
                ObjectType.POI,
                disabled = apiUserStore.data.map { user -> user.isAnonymous },
                analyticsEventProvider = AnalyticsCategory.CreatePointButton.click(),
            )
        },
    ) {},
    Task(
        toolButton = {
            val apiUserStore: auth.ApiUserStore by koinCtx.inject()
            objectToolButton(
                ObjectType.Task,
                disabled = apiUserStore.data.map { user -> user.isAnonymous },
                analyticsEventProvider = AnalyticsCategory.CreateTaskButton.click(),
            )
        },
    ) {},
    Zone(
        toolButton = {
            val apiUserStore: auth.ApiUserStore by koinCtx.inject()
            objectToolButton(
                ObjectType.Zone,
                disabled = apiUserStore.data.map { user -> user.isAnonymous },
                analyticsEventProvider = AnalyticsCategory.CreateZoneButton.click(),
            )
        },
    ) {},
    Track(
        toolButton = {
            val translation: Translation by koinCtx.inject()
            val manualEnterStore: ManualEnterStore by koinCtx.inject()
            val apiUserStore: auth.ApiUserStore by koinCtx.inject()
            genericToolButton(
                icon = { theme.FormationIcons.TrackedObject.icon },
                displayName = translation[localization.TL.WorkPlaceTools.TRACKING],
                toolTitle = translation[
                    localization.TL.CardToolSelect.ADD_TOOLTIP,
                    mapOf(
                        "tool" to translation.getString(localization.TL.WorkPlaceTools.TRACKING).lowercase()
                            .replaceFirstChar { if (it.isLowerCase()) it.titlecase() else it.toString() },
                    ),
                ],
                disabled = apiUserStore.data.map { user -> user.isAnonymous },
                value = Unit,
                clickHandlers = listOf(manualEnterStore.disable),
                addOrReplaceRoute = mapOf(
                    "page" to Pages.Map.name,
                    "card" to Cards.Track.name,
                ),
                analyticsEventProvider = AnalyticsCategory.ScanContentButton.click(),
            )
        },
    ) {},
    Navigation(
        toolButton = {
            val translation: Translation by koinCtx.inject()
            val apiUserStore: auth.ApiUserStore by koinCtx.inject()
            val navigationToolToggleStore: NavigationToolToggleStore by koinCtx.inject()
            genericToolButton(
                icon = { theme.FormationUIIcons.NavigationArrow.icon },
                displayName = translation[localization.TL.WorkPlaceTools.ROUTING],
                toolTitle = translation[
                    localization.TL.CardToolSelect.ADD_TOOLTIP,
                    mapOf(
                        "tool" to translation.getString(localization.TL.WorkPlaceTools.ROUTING).lowercase()
                            .replaceFirstChar { if (it.isLowerCase()) it.titlecase() else it.toString() },
                    ),
                ],
                disabled = apiUserStore.data.map { user -> user.isAnonymous },
                value = true,
                valueHandlers = listOf(navigationToolToggleStore.update),
                routingMap = mapOf("page" to Pages.Map.name),
                analyticsEventProvider = AnalyticsCategory.NavigationToolButton.click(),
            )
        },
    ) {},
    ;
}
