package data.objects

import dev.fritz2.core.RootStore
import kotlinx.coroutines.Job

class AttendeesSelectorStore : RootStore<List<String>?>(
    initialData = null,
    job = Job(),
) {

    val reset = handle {
        null
    }

    val toggleMultiUserSelection = handle<String> { current, newUser ->
        if (current.isNullOrEmpty() || !current.contains(newUser)) {
            (current ?: listOf()) + newUser
        } else {
            current.minus(newUser).ifEmpty { null }
        }
    }

    val toggleAll = handle<List<String>> { current, list ->
        if (current.isNullOrEmpty() || !current.containsAll(list)) {
            list
        } else {
            null
        }
    }

}
