package utils

import analyticsdashboard.TimeFilter
import analyticsdashboard.TimeFilterOption
import apiclient.util.leadingZero
import data.users.settings.SyncedUserPreferencesStore
import koin.koinCtx
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.map
import kotlinx.datetime.*
import localization.TL
import localization.Translation
import kotlin.math.absoluteValue
import kotlin.time.Duration.Companion.days
import kotlin.time.Duration.Companion.hours
import kotlin.time.Duration.Companion.minutes
import kotlinx.datetime.format.FormatStringsInDatetimeFormats
import kotlinx.datetime.format.byUnicodePattern


fun Instant.localDT(): LocalDateTime {
    val syncedUserPreferencesStore by koinCtx.inject<SyncedUserPreferencesStore>()
    require("@js-joda/timezone")
    return toLocalDateTime(syncedUserPreferencesStore.current.timeZone?.let {
        TimeZone.of(it)
    }?: TimeZone.currentSystemDefault()
    )
}

fun LocalDateTime.formatTime(): String {
    val hour = "$hour".padStart(2, '0')
    val minute = "$minute".padStart(2, '0')
    return "$hour:$minute"
}
fun LocalDateTime.formatTimeWithSeconds(): String {
    val hour = "$hour".padStart(2, '0')
    val minute = "$minute".padStart(2, '0')
    val second = "$second".padStart(2, '0')
    return "$hour:$minute:$second"
}
fun Instant.formatTime(): String = localDT().formatTime()

fun LocalDateTime.formatDate(separator: String = ".", reverse: Boolean = false): String {
    // FIXME there are better ways to format dates, we should not reinvent this
    //   wait for kotlinx-datetime to provide formatting
    val month = "$monthNumber".padStart(2, '0')
    val dayOfMonth = "$dayOfMonth".padStart(2, '0')
    return if (reverse) {
        "${year}$separator${month}$separator${dayOfMonth}"
    } else {
        "${dayOfMonth}$separator${month}$separator${year}"
    }
}
fun Instant.formatDate(separator: String = ".", reverse: Boolean = false): String =
    localDT().formatDate(separator, reverse)

fun LocalDateTime.formatDateLong(): String {

    val translation: Translation by koinCtx.inject()

    val month = when (month) {
        Month.JANUARY -> translation.getString(TL.MonthAbbreviations.JAN)
        Month.FEBRUARY -> translation.getString(TL.MonthAbbreviations.FEB)
        Month.MARCH -> translation.getString(TL.MonthAbbreviations.MAR)
        Month.APRIL -> translation.getString(TL.MonthAbbreviations.APR)
        Month.MAY -> translation.getString(TL.MonthAbbreviations.MAY)
        Month.JUNE -> translation.getString(TL.MonthAbbreviations.JUN)
        Month.JULY -> translation.getString(TL.MonthAbbreviations.JUL)
        Month.AUGUST -> translation.getString(TL.MonthAbbreviations.AUG)
        Month.SEPTEMBER -> translation.getString(TL.MonthAbbreviations.SEP)
        Month.OCTOBER -> translation.getString(TL.MonthAbbreviations.OCT)
        Month.NOVEMBER -> translation.getString(TL.MonthAbbreviations.NOV)
        Month.DECEMBER -> translation.getString(TL.MonthAbbreviations.DEC)
    }
    return "${dayOfMonth}. $month. $year"
}
fun Instant.formatDateLong(): String = localDT().formatDateLong()

/**
 * DateTimeFormat for use in info card and notification info
 * @return "9:45 am | 04.04.2021" or "3:00 pm | 12.10.2022"
 */
fun Instant.formatDateTime(): String {
    // FIXME we should rely on system locale formatting of date times and not reinvent that. Convention in Germany is 24 hour time
    val dateTime = localDT()
    return "${dateTime.formatTime()} | ${dateTime.formatDate()}"
}

/**
 * DateTimeFormat for use in object history
 * @return "04.04.2021 9:45" or "12.10.2022 20:45"
 */
fun Instant.formatDateTimeForObjectHistory(): String {
    // FIXME we should rely on system locale formatting of date times and not reinvent that. Convention in Germany is 24 hour time
    val dateTime = localDT()
    return "${dateTime.formatDate()} ${dateTime.formatTimeWithSeconds()}"
}

@OptIn(FormatStringsInDatetimeFormats::class)
private val dateTimeFormat by lazy {
    LocalDateTime.Format {
        byUnicodePattern("yyyy.MM.dd', 'HH:mm")
    }
}

fun Instant.formatForComments(): String {
    return localDT().format(dateTimeFormat)
}

fun Instant.formatDateForSearch(): String {
    return formatDate(separator = ".", reverse = false)
}

fun isSameDay(a: LocalDateTime, b: LocalDateTime): Boolean = a.year == b.year && a.dayOfYear == b.dayOfYear

fun isSameTime(a: Instant, b: Instant): Boolean {
    return (a - b).inWholeSeconds.absoluteValue < 2
}

fun isToday(localDateTime: LocalDateTime): Boolean {
    return isSameDay(Clock.System.now().localDT(), localDateTime)
}

fun isInFuture(localDateTime: LocalDateTime): Boolean {
    return Clock.System.now().localDT() < localDateTime
}

fun isInPast(localDateTime: LocalDateTime): Boolean {
    return Clock.System.now().localDT() > localDateTime
}

/**
 * DateTimeFormat for use in create event card and info card
 * @param startFlow start time of event (expected as isoString)
 * @param endFlow end time of event (expected as isoString)
 * @return "Today 04. Mar. 2021 09:45 - 10:30" (if start and end are today)
 * "04. Mar. 2021 09:45 - 05. Mar. 2021 10:30" (if start and end are on different days)
 */
fun formatEventTimeSpan(startFlow: Flow<Instant?>, endFlow: Flow<Instant?>): Flow<String> {
    val translation: Translation by koinCtx.inject()

    return startFlow.combine(endFlow) { start, end ->
        if (start != null) {
            val startDt = start.localDT()
            val startDate = startDt.formatDateLong()
            val startTime = startDt.formatTime()
            val startIsToday = isToday(startDt)

            if (end != null) {
                val endDT = end.localDT()
                val endDate = endDT.formatDateLong()
                val endTime = endDT.formatTime()
                val endIsToday = isToday(endDT)
                val sameDay = isSameDay(startDt, endDT)

                when {
                    startIsToday && sameDay -> "${translation.getString(TL.DateTime.TODAY)} $startDate $startTime - $endTime"
                    sameDay -> "$startDate $startTime - $endTime"
                    startIsToday -> "${translation.getString(TL.DateTime.TODAY)} $startDate $startTime - $endDate $endTime"
                    endIsToday -> "$startDate $startTime - ${translation.getString(TL.DateTime.TODAY)} $endDate $endTime"
                    else -> "$startDate $startTime - $endDate $endTime"
                }
            } else {
                when {
                    startIsToday -> "${translation.getString(TL.DateTime.TODAY)} $startDate $startTime"
                    else -> "$startDate $startTime"
                }
            }
        } else translation.getString(TL.DateTime.TIME_NOT_SET)
    }
}
fun Instant.formatDateForDatePicker(): String { //output: 2021-03-17
    val localDT = localDT()
    // FIXME there are better ways to format dates, we should not reinvent this
    //   wait for kotlinx-datetime to provide formatting
    val month = "${localDT.monthNumber}".padStart(2, '0')
    val dayOfMonth = "${localDT.dayOfMonth}".padStart(2, '0')
    return "${localDT.year}${"-"}${month}${"-"}${dayOfMonth}"
}

fun Flow<Instant?>.formatDateForDatePickerFlow(): Flow<String> { //output: 2021-03-17
    return map { instant ->
        instant?.formatDateForDatePicker() ?: ""
    }
}

fun Instant.formatTimeForTimePicker() = formatTime()

fun formatTimeForTimePickerFlow(instantFlow: Flow<Instant?>): Flow<String> { // output: 10:20 or 22:20
    return instantFlow.map { instant ->
        instant?.formatTimeForTimePicker() ?: ""
    }
}

fun combineToInstant(date: String?, time: String?): Instant? { // output: 2021-03-09T16:20:00.273Z
    val syncedUserPreferencesStore by koinCtx.inject<SyncedUserPreferencesStore>()
    return if (!date.isNullOrBlank() && !time.isNullOrBlank()) {
        LocalDateTime.parse( "${date}T${time}:00.00")
            .toInstant(syncedUserPreferencesStore.current.timeZone?.let {
                TimeZone.of(it)
            }?: TimeZone.currentSystemDefault()
            )
    } else null
}

fun extractDurationInMinutes(startInstant: Instant, endInstant: Instant): Long {
    return endInstant.minus(startInstant).inWholeMinutes
}

fun calculateEndDate(startDate: Instant?, durationMinutes: Int): String? {
    return startDate?.plus(durationMinutes.minutes)?.toString()
}

/**
 * equivalent to `this.toInstant()`
 */
fun String.parseInstant(): Instant? = try {
    Instant.parse(this)
} catch (e: Throwable) {
    null
}

fun generateVersionCodeFromTimeStamp(timeStamp: String): String {
    val (year, month, day) = timeStamp.split("-")
    return "${year.substring(2,4)}$month.${if(day.startsWith("0")) day[1] else day}"
}

fun Instant.nextQuarterOfHour(): Instant {
    return this.let { time ->
        val currentMinutes = time.toJSDate().getMinutes().minutes
        when {
            currentMinutes.inWholeMinutes.toInt() in 0..14 -> {
                time.plus(30.minutes - currentMinutes)
            }
            currentMinutes.inWholeMinutes.toInt() in 15..29 -> {
                time.plus(45.minutes - currentMinutes)
            }
            currentMinutes.inWholeMinutes.toInt() in 30..44 -> {
                time.plus(60.minutes - currentMinutes)
            }
            currentMinutes.inWholeMinutes.toInt() in 45..59 -> {
                time.plus(75.minutes - currentMinutes)
            }
            else -> {
                time
            }
        }
    }
}

enum class PartOfDay(val stringKey: String, val startHour: Int, val endHour: Int) {
    Morning("morning", 0, 11),
    Afternoon("afternoon", 12, 16),
    Evening("evening", 17, 23),
    Unknown("unknown", 0, 0)
    ;
}

fun Instant.getPartOfDay(): PartOfDay {
    return PartOfDay.entries.firstOrNull {
        this.localDT().hour in it.startHour..it.endHour
    }?: PartOfDay.Unknown
}

fun Instant.startOfDay(): String {
    val d = this.toString().substringBefore("T")
    return LocalDateTime.parse("${d}T00:00:00.000").toInstant(TimeZone.currentSystemDefault()).toString()
}

fun Instant.endOfDay(): String {
    val d = this.toString().substringBefore("T")
    return LocalDateTime.parse("${d}T23:59:00.000").toInstant(TimeZone.currentSystemDefault()).toString()
}

fun Instant.startOfMonth(): String {
    val year =  this.toString().substringBefore("-")
    val month = this.toString().substring (5, 7).toInt()
    return LocalDateTime.parse("${year}-${month.leadingZero()}-01T00:00:00.000").toInstant(TimeZone.currentSystemDefault()).toString()
}

fun Instant.endOfMonth(): String {
    val year = this.toString().substringBefore("-")
    val month = this.toString().substring (5, 7).toInt()
    val lastDayOfMonth = monthDays(month, year.toInt().isLeapYear())
    return LocalDateTime.parse("${year}-${month.leadingZero()}-${lastDayOfMonth}T00:00:00.000").toInstant(TimeZone.currentSystemDefault()).toString()
}

fun Instant.startOfYear(): String {
    val year = this.toString().substringBefore("-")
    return LocalDateTime.parse("$year-01-01T00:00:00.000").toInstant(TimeZone.currentSystemDefault()).toString()
}

fun Instant.endOfYear(): String {
    val year = this.toString().substringBefore("-")
    return LocalDateTime.parse("$year-12-31T00:00:00.000").toInstant(TimeZone.currentSystemDefault()).toString()
}
fun monthDays(monthNumber: Int, isLeapYear: Boolean): String {
    return when(monthNumber) {
        1, 3, 5, 7, 8, 10, 12 -> "31"
        2 -> if(isLeapYear) "29" else "28"
        4, 6, 9, 11 -> "30"
        else -> "30"
    }
}

fun Int.isLeapYear(): Boolean = this % 400 == 0 || (this % 4 == 0 && this % 100 != 0)

// 2021-03-09T16:20:00.273Z
fun Instant.wipeToMinutes(): Instant {
    val string = this.toString()
    return if(string.length > 22) {
        string.replaceRange(IntRange(17, 22), "00.000").toInstant()
    } else if(string.length > 20) {
        string.replaceRange(IntRange(17, 20), "00.0").toInstant()
    } else if(string.length > 17) {
        string.replaceRange(IntRange(17, 18), "00").toInstant()
    } else this
}
fun getTimeFilter(timeFilter: TimeFilterOption): TimeFilter {
    val now = Clock.System.now().wipeToMinutes()
    val nowString = now.toString()

    val weekday= LocalDateTime.parse(now.toString().dropLast(1)).toInstant(TimeZone.currentSystemDefault()).localDT().dayOfWeek.isoDayNumber
    val monthDay = now.toString().substring (8, 10).toInt()
    val yearDay = LocalDateTime.parse(now.toString().dropLast(1)).toInstant(TimeZone.currentSystemDefault()).localDT().dayOfYear
    val dateWithinLastMonth = now - (monthDay + 1).days
    val dateWithinLastYear = now - (yearDay + 1).days

    return when(timeFilter) {
        TimeFilterOption.AllTime -> TimeFilter(
            filterStartDate = null,
            filterEndDate = null
        )
        TimeFilterOption.LastHour -> TimeFilter(
            filterStartDate = (now - 1.hours).toString(), // "now-1h/h",
            filterEndDate = nowString
        )
        TimeFilterOption.LastThreeHours -> TimeFilter(
            filterStartDate = (now - 3.hours).toString(), // "now-3h/h",
            filterEndDate = nowString
        )
        TimeFilterOption.Today -> TimeFilter(
            filterStartDate = now.startOfDay(), // 00:00 until now,
            filterEndDate = nowString
        )
        TimeFilterOption.Yesterday -> TimeFilter(
            filterStartDate = (now - 1.days).startOfDay(), // start of yesterday,
            filterEndDate = now.startOfDay() // until start of today
        )
        TimeFilterOption.LastThreeDays -> TimeFilter(
            filterStartDate = (now - 3.days).startOfDay(), // last 3 days until now,
            filterEndDate = nowString
        )
        TimeFilterOption.LastWeek -> TimeFilter(
                filterStartDate = (now - (weekday + 7).days).startOfDay(), // start of the last week
                filterEndDate = (now - (weekday + 1).days).startOfDay() // until end of last week
            )
        TimeFilterOption.LastThreeWeeks -> TimeFilter(
                filterStartDate = (now - (weekday + 21).days).startOfDay(), // start of the week, 3 week ago
                filterEndDate = (now - (weekday + 1).days).startOfDay() // until end of last week
            )
        TimeFilterOption.ThisMonth -> TimeFilter(
            filterStartDate = now.startOfMonth(), // first day of this month
            filterEndDate = nowString // now
        )
        TimeFilterOption.LastMonth -> TimeFilter(
                filterStartDate = dateWithinLastMonth.startOfMonth(), // first day of last month
                filterEndDate = now.startOfMonth() // until first day of this month
            )
        TimeFilterOption.LastThreeMonth -> TimeFilter(
            filterStartDate = (dateWithinLastMonth - 90.days).startOfMonth(), // first day of the month, 3 month ago
            filterEndDate = now.startOfMonth() // until first day of this month
        )
        TimeFilterOption.LastSixMonth -> TimeFilter(
            filterStartDate = (dateWithinLastMonth - 180.days).startOfMonth(), // first day of the month, 6 month ago
            filterEndDate = now.startOfMonth() // until first day of this month
        )
        TimeFilterOption.ThisYear -> TimeFilter(
            filterStartDate = now.startOfYear(), // first day of this year
            filterEndDate = nowString // until now
        )
        TimeFilterOption.LastYear -> TimeFilter(
            filterStartDate = dateWithinLastYear.startOfYear(), // first day of the last year
            filterEndDate = now.startOfYear() // until first day of this year
        )
        TimeFilterOption.LastThreeYears -> TimeFilter(
            filterStartDate = (dateWithinLastYear - 1095.days).startOfYear(), // first day of the last year
            filterEndDate = now.startOfYear() // until first day of this year
        )
        TimeFilterOption.CustomTime -> TimeFilter(
            filterStartDate = nowString, // configured via custom input
            filterEndDate = nowString
        )
    }
}
