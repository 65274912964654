package data.objects.views

import analyticsdashboard.ActiveHistoryPathSearchFieldValuesStore
import analyticsdashboard.AnalyticsSideSubPageStore
import apiclient.customfields.CategoryReference
import apiclient.customfields.FieldValue
import data.heatmaplayer.ActiveHeatmapLayerDefinitionStore
import data.keywordlayer.ActiveKeywordLayerDefinitionStore
import data.keywordlayer.MapLayerTypeSwitchStore
import data.objects.ActiveObjectFieldValuesStore
import data.objects.CurrentActiveFieldValueStore
import data.objects.WorkspaceCategoryNamespacesStore
import dev.fritz2.components.compat.button
import dev.fritz2.components.compat.div
import dev.fritz2.components.compat.span
import dev.fritz2.components.flexBox
import dev.fritz2.components.icon
import dev.fritz2.components.lineUp
import dev.fritz2.components.stackUp
import dev.fritz2.core.HtmlTag
import dev.fritz2.core.RenderContext
import dev.fritz2.core.RootStore
import dev.fritz2.core.SimpleHandler
import dev.fritz2.core.storeOf
import dev.fritz2.core.values
import dev.fritz2.styling.params.SizesProperty
import dev.fritz2.styling.theme.IconDefinition
import dev.fritz2.styling.theme.Icons
import koin.koinCtx
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import kotlinx.datetime.Instant
import kotlinx.datetime.toInstant
import localization.TL
import localization.Translation
import mainmenu.RouterStore
import map.views.workplacetools.ActiveArchetypeSearchFieldValuesStore
import org.w3c.dom.HTMLDivElement
import search.global.ActiveSearchFieldValuesStore
import search.nestedObjects.ActiveNestedSearchFieldValuesStore
import theme.FormationColors
import theme.FormationDefault.Companion.formationStyles
import theme.FormationIcons
import theme.FormationUIIcons
import utils.makeRGBA
import utils.toKeywordTag
import webcomponents.KeywordTagActionType
import webcomponents.KeywordTagType
import webcomponents.Position
import webcomponents.baseLayout
import webcomponents.contentScrollBox
import webcomponents.genericInput
import webcomponents.keywordTag
import webcomponents.twoButtonFooter

fun RenderContext.cardManageFieldValueTag(tagType: KeywordTagType? = null) {

    val workspaceCategoryNamespacesStore: WorkspaceCategoryNamespacesStore by koinCtx.inject()
    val activeObjectFieldValuesStore: ActiveObjectFieldValuesStore by koinCtx.inject()
    val activeSearchFieldValuesStore: ActiveSearchFieldValuesStore by koinCtx.inject()
    val activeArchetypeSearchFieldValuesStore: ActiveArchetypeSearchFieldValuesStore by koinCtx.inject()
    val activeNestedSearchFieldValuesStore: ActiveNestedSearchFieldValuesStore by koinCtx.inject()
    val activeHistoryPathSearchFieldValuesStore: ActiveHistoryPathSearchFieldValuesStore by koinCtx.inject()
    val activeHeatmapLayerDefinitionStore: ActiveHeatmapLayerDefinitionStore by koinCtx.inject()
    val activeKeywordlayerDefinitionsStore: ActiveKeywordLayerDefinitionStore by koinCtx.inject()
    val analyticsSideSubPageStore: AnalyticsSideSubPageStore by koinCtx.inject()
    val translation: Translation by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()
    val mapLayerTypeSwitchStore: MapLayerTypeSwitchStore by koinCtx.inject()

    val currentActiveFieldValueStore: CurrentActiveFieldValueStore by koinCtx.inject()

    val saveActiveFieldValueHandler: SimpleHandler<Unit>
    var optionalSaveValueHandler: SimpleHandler<Unit>? = null

    val valueValidationMessageStore = storeOf("")

    // if keywordTagType is not set by param, then try to get it from mapLayerTypeSwitch
    val type = tagType ?: mapLayerTypeSwitchStore.keyWordTagType

    console.log("CardManageFieldValueTag, for:", type.name)
    @Suppress("UNCHECKED_CAST")
    class ValueStore<T>(fieldValue: FieldValue, value: T) : RootStore<T>(
        initialData = value,
        job = Job(),
    ) {
        val saveValue = handle { current ->
            currentActiveFieldValueStore.addOrChangeFieldValueWithoutRouting(
                when {
                    fieldValue is FieldValue.CategoryValue && value is CategoryReference -> {
                        fieldValue.copy(value = current as CategoryReference)
                    }

                    fieldValue is FieldValue.DoubleValue && value is Double -> {
                        fieldValue.copy(value = current as Double)
                    }

                    fieldValue is FieldValue.LongValue && value is Long -> {
                        fieldValue.copy(value = current as Long)
                    }

                    fieldValue is FieldValue.StringValue && value is String -> {
                        fieldValue.copy(value = current as String)
                    }

                    fieldValue is FieldValue.InstantValue && value is Instant -> {
                        fieldValue.copy(value = current as Instant)
                    }

                    else -> {
                        fieldValue
                    }
                },
            )
            current
        }

        val updateValue = handle<String> { current, stringValue ->
            when {
                fieldValue is FieldValue.DoubleValue && value is Double -> {
                    try {
                        val double = (stringValue.ifBlank { "0.0" }).toDouble()
                        valueValidationMessageStore.update("")
                        currentActiveFieldValueStore.update(fieldValue.copy(value = double))
                        double as T
                    } catch (e: NumberFormatException) {
                        console.log("Value is no Double!", e.message)
                        valueValidationMessageStore.update("Please choose a number like 87 or -35.204.")
                        0.0 as T
                    }
                }

                fieldValue is FieldValue.LongValue && value is Long -> {
                    try {
                        val long = (stringValue.ifBlank { "0" }).toLong()
                        valueValidationMessageStore.update("")
                        currentActiveFieldValueStore.update(fieldValue.copy(value = long))
                        long as T
                    } catch (e: NumberFormatException) {
                        console.log("Value is no Long!", e.message)
                        valueValidationMessageStore.update("Please choose a number between ${Long.MIN_VALUE} and ${Long.MAX_VALUE}.")
                        0 as T
                    }
                }

                fieldValue is FieldValue.InstantValue && value is Instant -> {
                    try {
                        val instant = stringValue.toInstant()
                        valueValidationMessageStore.update("")
                        currentActiveFieldValueStore.update(fieldValue.copy(value = instant))
                        instant as T
                    } catch (e: Error) {
                        console.log("Value is no Instant:", stringValue)
                        valueValidationMessageStore.update("Value is no Instant!")
                        current
                    }
                }

                fieldValue is FieldValue.StringValue && value is String -> {
                    valueValidationMessageStore.update("")
                    currentActiveFieldValueStore.update(fieldValue.copy(value = stringValue))
                    @Suppress("UNCHECKED_CAST")
                    stringValue as T
                }

                else -> {
                    current
                }
            }
        }
    }

    when (type) {
        KeywordTagType.ObjectTag -> {
            saveActiveFieldValueHandler = activeObjectFieldValuesStore.addOrChangeFieldValue
        }

        KeywordTagType.SearchTag -> {
            saveActiveFieldValueHandler = activeSearchFieldValuesStore.addOrChangeFieldValue
        }

        KeywordTagType.AnalyticsPathViewTag -> {
            saveActiveFieldValueHandler = activeHistoryPathSearchFieldValuesStore.addOrChangeFieldValue
        }

        KeywordTagType.ArchetypeTag -> {
            saveActiveFieldValueHandler = activeArchetypeSearchFieldValuesStore.addOrChangeFieldValue
        }

        KeywordTagType.NestedObjectTag -> {
            saveActiveFieldValueHandler = activeNestedSearchFieldValuesStore.addOrChangeFieldValue
        }

        KeywordTagType.HeatmapLayerTag -> {
            saveActiveFieldValueHandler = activeHeatmapLayerDefinitionStore.addOrChangeFieldValue
        }

        KeywordTagType.KeywordLayerTag -> {
            saveActiveFieldValueHandler = activeKeywordlayerDefinitionsStore.addOrChangeFieldValue
        }
    }

    baseLayout(
        header = {
            lineUp(
                {
                    width { full }
                    alignItems { center }
                    justifyContent { start }
                    margins {
                        left { tiny }
                        bottom { small }
                    }
                },
            ) {
                spacing { small }
                items {
                    icon(
                        {
                            size { giant }
                        },
                    ) { fromTheme { FormationIcons.Star.icon } }
                    span(
                        {
                            fontSize { normal }
                            fontWeight { medium }
                        },
                    ) { translation[TL.KeywordTags.SELECT_A_VALUE].renderText(into = this) }
                }

            }
        },
        content = {
            contentScrollBox {
                // Re-render tag to display current selected/typed value of the fieldValue
                currentActiveFieldValueStore.data.render { fieldValue ->
                    if (fieldValue != null) {
                        flexBox(
                            {
                                width { full }
                                alignItems { start }
                                margins { bottom { large } }
                            },
                        ) {
                            keywordTag(
                                tag = fieldValue.toKeywordTag(KeywordTagActionType.DefaultFieldValue),
                                keywordTagType = type,
                            )
                        }
                    }
                }

                // Re-render the value, if it's a CategoryValue
                currentActiveFieldValueStore.data.render { fieldValue ->
                    if (fieldValue != null && fieldValue is FieldValue.CategoryValue) {
                        stackUp(
                            {
                                width { full }
                                alignItems { center }
                            },
                        ) {
                            spacing { normal }
                            items {
                                workspaceCategoryNamespacesStore.data.render { wsCategoryNamespaces ->
                                    val categoryNamespace =
                                        wsCategoryNamespaces?.firstOrNull { it.name == fieldValue.value.namespace }
                                    categoryNamespace?.categories?.forEach { categoryDefinition ->
                                        flexBox(
                                            {
                                                width { full }
                                                alignItems { stretch }
                                                justifyContent { spaceBetween }
                                                margins { bottom { small } }
                                                paddings {
//                                                left { tiny }
//                                                right { large }
                                                    horizontal { smaller }
                                                    vertical { smaller }
                                                }
                                                hover {
                                                    radius(formationStyles.buttonRadius)
                                                    background {
                                                        color { FormationColors.GrayLight.color }
                                                    }
                                                }
                                                css("cursor:pointer;")
                                            },
                                        ) {
                                            iconRadioButton(
                                                icon = { FormationUIIcons.Check.icon },
                                                selected = flowOf(categoryDefinition.name == fieldValue.value.categoryName),
                                                position = Position.Right,
                                                label = flowOf(categoryDefinition.title),
                                                value = fieldValue.copy(
                                                    value = CategoryReference(
                                                        namespace = categoryNamespace.name,
                                                        categoryName = categoryDefinition.name,
                                                    ),
                                                ),
                                                clickHandlers = listOf(currentActiveFieldValueStore.update),
                                            )
                                            clicks.map {
                                                fieldValue.copy(
                                                    value = CategoryReference(
                                                        namespace = categoryNamespace.name,
                                                        categoryName = categoryDefinition.name,
                                                    ),
                                                )
                                            } handledBy currentActiveFieldValueStore.update
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                // Do not re-render input fields for other fieldValues
                if (currentActiveFieldValueStore.current != null) {
                    when (val fieldValue = currentActiveFieldValueStore.current) {
                        is FieldValue.DoubleValue -> {
                            val doubleValueStore = ValueStore(fieldValue, fieldValue.value)
                            optionalSaveValueHandler = doubleValueStore.saveValue
                            genericInput(
                                value = doubleValueStore.data.map { it.toString() },
                            ) {
                                inputs.values() handledBy doubleValueStore.updateValue
                            }
                        }

                        is FieldValue.InstantValue -> {
                            val instantValueStore = ValueStore(fieldValue, fieldValue.value)
                            optionalSaveValueHandler = instantValueStore.saveValue
                            // TODO add date time picker here
                        }

                        is FieldValue.LongValue -> {
                            val longValueStore = ValueStore(fieldValue, fieldValue.value)
                            optionalSaveValueHandler = longValueStore.saveValue
                            genericInput(
                                value = longValueStore.data.map { it.toString() },
                            ) {
                                inputs.values() handledBy longValueStore.updateValue
                            }
                        }

                        is FieldValue.StringValue -> {
                            val stringValueStore = ValueStore(fieldValue, fieldValue.value)
                            optionalSaveValueHandler = stringValueStore.saveValue
                            genericInput(
                                value = stringValueStore.data,
                            ) {
                                inputs.values() handledBy stringValueStore.updateValue
                            }
                        }

                        is FieldValue.EmptyValue -> TODO()
                        else -> {}
                    }
                }
                // validation message
                valueValidationMessageStore.data.render { message ->
                    if (message.isNotBlank()) {
                        span(
                            {
                                fontSize { smaller }
                                color { FormationColors.RedError.color }
                                margins { vertical { small } }
                                padding { small }
                                radius { large }
                                background {
                                    color { makeRGBA(FormationColors.RedError.color, 0.1) }
                                }
                            },
                        ) {
                            +message
                        }
                    }
                }
            }
        },
        footer = {
            if (type == KeywordTagType.AnalyticsPathViewTag) {
                twoButtonFooter(
                    secondaryTitle = translation[TL.General.CANCEL],
                    secondaryClickHandlers = listOf(analyticsSideSubPageStore.back),
                    secondaryValue = Unit, //AnalyticSideSubPage.PathsSearchTags,
//                    secondaryValueHandlers = listOf(analyticsSideSubPageStore.changeIfNotNull),
                    primaryTitle = translation[TL.General.SAVE],
                    primaryState = valueValidationMessageStore.data.map { it.isBlank() },
                    primaryValue = Unit,//AnalyticSideSubPage.PathsSearchTags,
//                    primaryValueHandlers = listOf(analyticsSideSubPageStore.changeIfNotNull),
                    primaryClickHandlers = optionalSaveValueHandler?.let {
                        listOf(it, saveActiveFieldValueHandler, analyticsSideSubPageStore.back)
                    } ?: listOf(saveActiveFieldValueHandler, analyticsSideSubPageStore.back),
                )
            } else {
                twoButtonFooter(
                    secondaryTitle = translation[TL.General.CANCEL],
                    secondaryClickHandlers = listOf(routerStore.back),
                    primaryTitle = translation[TL.General.SAVE],
                    primaryState = valueValidationMessageStore.data.map { it.isBlank() },
                    primaryValue = Unit,
                    primaryClickHandlers =
                    optionalSaveValueHandler?.let {
                        listOf(it, saveActiveFieldValueHandler, routerStore.back)
                    } ?: listOf(saveActiveFieldValueHandler, routerStore.back),
                )
            }
        },
    )
}

fun <T> RenderContext.iconRadioButton(
    dotSize: SizesProperty = { larger },
    icon: (Icons.() -> IconDefinition)? = null,
    iconSize: SizesProperty = { normal },
    position: Position = Position.Left,
    selected: Flow<Boolean>,
    label: Flow<String>? = null,
    value: T,
    clickHandlers: List<SimpleHandler<T>>? = null,
    content: (HtmlTag<HTMLDivElement>.() -> Unit)? = null,
) {
    button(
        {
            width { full }
        },
    ) {
        flexBox(
            {
                direction { row }
                width { full }
                height { auto }  //At some point define a size for all the switch boxes
                justifyContent { spaceBetween }
                alignItems { center }
                fontSize { small }
                textAlign { left }
            },
        ) {
            if (position == Position.Right) {
                div(
                    {
                        margins { right { smaller } }
                    },
                ) {
                    content?.invoke(this) ?: span(
                        {
                            fontSize { normal }
                            fontWeight { semiBold }
                        },
                    ) {
                        label?.renderText(into = this)
                    }
                }

            }
            selected.render { isSelected ->
                flexBox(
                    {
                        size(dotSize)
                        radius { full }
                        border {
                            width { "2px" }
                            color { primary.main }
                        }
                        alignItems { center }
                        justifyContent { center }
                        if (isSelected) {
                            color { secondary.main }
                            background {
                                color { primary.main }
                            }
                        }
                    },
                ) {
                    if (isSelected) {
                        icon?.let { icon({ size(iconSize) }) { fromTheme(it) } }
                    }
                }
            }
            if (position == Position.Left) {
                div(
                    {
                        margins { left { smaller } }
                    },
                ) {
                    content?.invoke(this) ?: span {
                        label?.renderText(into = this)
                    }
                }
            }
        }
        clickHandlers?.forEach { handler ->
            clicks.map { value } handledBy handler
        }
    }
}
