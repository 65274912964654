package mainmenu

import analytics.AnalyticsCategory
import apiclient.FormationClient
import apiclient.feedback.CreateFeedback
import apiclient.feedback.sendFeedback
import apiclient.validations.parseEnumValue
import apiclient.websocket.WebsocketApiClient
import auth.ApiUserStore
import dev.fritz2.components.compat.div
import dev.fritz2.components.compat.p
import dev.fritz2.components.flexBox
import dev.fritz2.components.icon
import dev.fritz2.components.textArea
import dev.fritz2.core.RenderContext
import dev.fritz2.core.RootStore
import dev.fritz2.core.id
import dev.fritz2.core.invoke
import dev.fritz2.core.placeholder
import dev.fritz2.core.values
import dev.fritz2.routing.MapRouter
import generated.ServerConfig
import koin.koinCtx
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.map
import localization.TL
import localization.Translation
import model.Feedback
import model.message
import model.subject
import overlays.AlertOverlayStore
import overlays.BusyStore
import styling.primaryButtonStyleParams
import styling.secondaryButtonStyleParams
import theme.FormationDefault
import theme.FormationIcons
import utils.focusInputObserver
import utils.generateVersionCodeFromTimeStamp
import webcomponents.baseLayout
import webcomponents.contentScrollBox
import webcomponents.genericInput
import webcomponents.mainTitle
import webcomponents.oneButtonFooter
import webcomponents.twoButtonFooter

class FeedbackStore : RootStore<Feedback>(
    initialData = Feedback("", ""),
    job = Job(),
) {
    val formationClient: FormationClient by koinCtx.inject()
    val apiUserStore: ApiUserStore by koinCtx.inject()
    private val websocketApiClient: WebsocketApiClient by koinCtx.inject()
    val router: MapRouter by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()
    val alertOverlayStore: AlertOverlayStore by koinCtx.inject()
    val translation: Translation by koinCtx.inject()
    val busyStore: BusyStore by koinCtx.inject()

    val reset = handle { current ->
        update(Feedback("", ""))
        current
    }

    val sendFeedback = handle { current ->
        apiUserStore.current.apiUser?.groups?.firstOrNull()?.groupId?.let { groupId ->
            busyStore.handleApiCall(
                supplier = suspend {
                    formationClient.sendFeedback(
                        feedback = CreateFeedback(
                            groupId = groupId,
                            sessionId = websocketApiClient.sessionIdProvider.invoke(),
                            subject = current.subject,
                            message = current.message,
                            version = "App " + generateVersionCodeFromTimeStamp(ServerConfig.buildTimestamp) + ".0",
                        ),
                    )
                },
                successMessage = translation[TL.AlertNotifications.FEEDBACK_SEND_SUCCESSFULLY],
                processResult = { result ->
                    routerStore.validateInternalRoute(
                        mapOf(
                            "page" to Pages.Feedback.name,
                            "feedback" to FeedbackPages.Send.name,
                        ),
                    )
                    console.log("Feedback send!", result)
                    reset()
                },
                errorMessage = translation[TL.AlertNotifications.FEEDBACK_NOT_SEND],
                processError = { throwable ->
                    console.warn("Failed to send feedback", throwable)
                },
            )
        }
        current
    }

    val cancelFeedback = handle { current ->
        if (current.subject.isBlank() && current.message.isBlank()) {
            reset()
        } else {
            routerStore.validateExternalRoute(
                mapOf(
                    "page" to Pages.Feedback.name,
                    "feedback" to FeedbackPages.Cancel.name,
                ),
            )
        }
        current
    }

    fun isNotBlank(): Flow<Boolean> = data.map { it.subject.isNotBlank() && it.message.isNotBlank() }
}

enum class FeedbackPages { Cancel, Send }

fun RenderContext.pageFeedback() {
    val router: MapRouter by koinCtx.inject()

    router.select("feedback").render { (feedbackPage, _) ->
        when (parseEnumValue<FeedbackPages>(feedbackPage)) {
            FeedbackPages.Cancel -> pageFeedbackCancel()
            FeedbackPages.Send -> pageFeedbackSend()
            else -> pageFeedbackWrite()
        }
    }
}


fun RenderContext.pageFeedbackWrite() {

    val translation: Translation by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()
    val feedbackStore: FeedbackStore = koinCtx.get()
    val subject = feedbackStore.map(Feedback.subject())
    val message = feedbackStore.map(Feedback.message())

    baseLayout(
        header = {
            flexBox(
                {
                    width { full }
                    margins { bottom { smaller } }
                },
            ) {
                mainTitle(translation[TL.Feedback.CARD_TITLE])
            }
            genericInput(
                value = subject.data,
            ) {
                id("feedbackSubjectInput")
                placeholder(translation[TL.Feedback.SUBJECT_PLACEHOLDER])
                inputs.values() handledBy subject.update
            }
            focusInputObserver({ js("document.getElementById('feedbackSubjectInput').focus()") }, domNode)
        },
        content = {
            div(
                {
                    display { flex }
                    justifyContent { center }
                    alignItems { stretch }
                    height { full }
                    width { full }
                    paddings { horizontal { normal } }
                },
            ) {
                textArea(
                    {
                        height { full }
                        width { full }
                        display { flex }
                        border {
                            width(FormationDefault.formationStyles.borderWidth)
                            color { primary.main }
                        }
                        radius { large }
                        padding { smaller }
                        fontSize { small }
                    },
                ) {
                    resizeBehavior { none }
                    value(message.data)
                    element {
                        placeholder(translation[TL.Feedback.MESSAGE_PLACEHOLDER])
                        inputs.values() handledBy message.update
                    }
                }
            }
        },
        footer = {
            twoButtonFooter(
                secondaryTitle = translation[TL.General.CANCEL],
                secondaryStyleParams = secondaryButtonStyleParams,
                secondaryClickHandlers = listOf(feedbackStore.cancelFeedback, routerStore.back),
                primaryTitle = translation[TL.Feedback.SEND],
                primaryState = feedbackStore.isNotBlank(),
                primaryStyleParams = primaryButtonStyleParams,
                primaryValue = Unit,
                primaryClickHandlers = listOf(feedbackStore.sendFeedback, routerStore.back),
                primaryAnalyticsEventProvider = AnalyticsCategory.SendFeedback.click(),
            )
        },
    )
}

fun RenderContext.pageFeedbackCancel() {

    val feedbackStore: FeedbackStore = koinCtx.get()
    val translation: Translation by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()

    baseLayout(
        content = {
            flexBox(
                {
                    width { full }
                    margins { vertical { large } }
                    justifyContent { center }
                    alignItems { center }
                    paddings { horizontal { normal } }
                    flex { grow { "1" } }
                },
            ) { mainTitle(translation[TL.Feedback.CANCEL_TITLE]) }
            p(
                {
                    margins { vertical { large } }
                    fontSize { large }
                    textAlign { center }
                    paddings { horizontal { normal } }
                    flex { grow { "1" } }
                },
            ) { translation[TL.Feedback.CANCEL_QUESTION].renderText(into = this) }
            flexBox({ flex { grow { "1" } } }) { }
        },
        footer = {
            twoButtonFooter(
                primaryTitle = translation[TL.General.NO],
                primaryValue = Unit,
                primaryClickHandlers = listOf(routerStore.back),
                secondaryTitle = translation[TL.General.YES],
                secondaryClickHandlers = listOf(feedbackStore.reset, routerStore.goToMap),
                secondaryAnalyticsEventProvider = AnalyticsCategory.DiscardFeedback.click(),
            )
        },
    )
}

fun RenderContext.pageFeedbackSend() {

    val translation: Translation by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()

    baseLayout(
        content = {
            contentScrollBox(margins = { top { normal } }) {
                flexBox({ flex { grow { "2" } } }) { }

                icon(
                    {
                        size { "4rem" }
                        color { primary.main }
                        css("align-self: center;")
                        flex { grow { "1" } }
                    },
                ) { fromTheme { FormationIcons.MailAlt.icon } }

                flexBox(
                    {
                        width { full }
                        margins { vertical { large } }
                        justifyContent { center }
                        alignItems { center }
                        flex { grow { "1" } }
                    },
                ) {
                    mainTitle(translation[TL.Feedback.THANK_YOU])
                }
                p(
                    {
                        margins { vertical { large } }
                        fontSize { large }
                        textAlign { center }
                        flex { grow { "1" } }
                    },
                ) {
                    translation[TL.Feedback.THANK_TEXT].renderText(into = this)
                }

                flexBox({ flex { grow { "2" } } }) { }
            }

        },
        footer = {
            oneButtonFooter(
                title = translation[TL.General.DONE],
                value = Unit,
                clickHandlers = listOf(routerStore.goToMap),
            )
        },
    )
}
