package model

import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.LatLon
import apiclient.tags.floorLevel
import kotlinx.serialization.Serializable

@Serializable
data class MapState(
    val center: LatLon,
    val zoom: Double,
    val tl: LatLon?,
    val br: LatLon?,
    val hasMoved: Boolean = false,
) {
    companion object
}

data class Building(
    val buildingId: String,
    val buildingName: String? = null,
    val defaultFloorLevel: Double? = null,
    val defaultFloorId: String? = null,
    val activeFloorLevel: Double? = null,
    val activeFloorIds: List<String>? = null,
    val floorData: MutableMap<Double, List<Floor>>? = null,
    val geoObjectDetails: GeoObjectDetails,
) {
    companion object
}

fun MutableMap<Double, List<Floor>>?.replaceFloor(floor: GeoObjectDetails): MutableMap<Double, List<Floor>> {
    val floorMap = this?.map { (l,fs) ->
        l to  fs.filter { it.floor.id != floor.id }
    }?.toMap().orEmpty().toMutableMap()
    val floorLevel = floor.tags.floorLevel ?: 0.0
    val floors = floorMap[floorLevel].orEmpty()
    floorMap[floorLevel] = floors + Floor(
        floor = floor
    )
    return floorMap
}

data class Floor(
    val floor: GeoObjectDetails,
    val units: List<GeoObjectDetails>? = null,
)
