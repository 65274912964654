package model

import analyticsdashboard.TimeFilter
import apiclient.auth.ApiUser
import apiclient.customfields.FieldValue
import apiclient.geoobjects.Comment
import apiclient.geoobjects.ConnectableObject
import apiclient.geoobjects.Connection
import apiclient.geoobjects.Content
import apiclient.geoobjects.CustomFieldClause
import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.GeoReferencedConnectableObject
import apiclient.geoobjects.LatLon
import apiclient.geoobjects.MarkerColor
import apiclient.geoobjects.MarkerIcon
import apiclient.geoobjects.MarkerShape
import apiclient.geoobjects.MeetingAttendee
import apiclient.geoobjects.ObjectType
import apiclient.geoobjects.SearchQueryContext
import apiclient.geoobjects.TaskState
import apiclient.geoobjects.TaskTemplate
import apiclient.groups.HeatmapLayerDefinition
import apiclient.groups.KeyWordLayerDefinition
import apiclient.groups.LayerCacheSettings
import apiclient.groups.LayerMetaData
import apiclient.groups.LayerType
import apiclient.groups.LayerVisibility
import apiclient.groups.WorkSpaceOptions
import apiclient.polls.PollOption
import apiclient.tags.TagList
import apiclient.users.PublicUserProfile
import apiclient.users.Signup
import apiclient.users.UserPreferences
import apiclient.users.UserProfileSummary
import camera.cameraWrapper.CameraMeta
import com.jillesvangurp.geojson.Geometry
import data.heatmaplayer.HeatmapLayerDefinitionData
import dev.fritz2.core.Lens
import dev.fritz2.core.lensOf
import kotlinx.datetime.Instant
import kotlinx.datetime.toInstant
import kotlinx.serialization.json.JsonObject
import location.geolocation.GeoPosition
import objectrouting.NavigationData
import objectrouting.NavigationPointSource
import signup.WorkspaceCreateData
import utils.convertToGeoPosition

object GeoObjectDetailsLenses {
    val id: Lens<GeoObjectDetails, String> = lensOf("id", { it.id }, { p, v -> p.copy(id = v) })

    val ownerId: Lens<GeoObjectDetails, String> = lensOf("ownerId", { it.ownerId }, { p, v -> p.copy(ownerId = v) })

    val objectType: Lens<GeoObjectDetails, ObjectType> =
        lensOf("objectType", { it.objectType }, { p, v -> p.copy(objectType = v) })

    val createdAt: Lens<GeoObjectDetails, Instant?> =
        lensOf(
            "createdAt",
            {
                try {
                    it.createdAt.toInstant()
                } catch (e: Exception) {
                    null
                }
            },
            { p, v -> p.copy(createdAt = v.toString()) },
        )

    val updatedAt: Lens<GeoObjectDetails, Instant?> =
        lensOf(
            "updatedAt",
            {
                try {
                    it.updatedAt.toInstant()
                } catch (e: Exception) {
                    null
                }
            },
            { p, v -> p.copy(updatedAt = v.toString()) },
        )

    val latLon: Lens<GeoObjectDetails, LatLon> = lensOf("latLon", { it.latLon }, { p, v -> p.copy(latLon = v) })

    val title: Lens<GeoObjectDetails, String> = lensOf("title", { it.title }, { p, v -> p.copy(title = v) })

    val description: Lens<GeoObjectDetails, String?> =
        lensOf("description", { it.description }, { p, v -> p.copy(description = v) })

    val tags: Lens<GeoObjectDetails, TagList> = lensOf("tags", { it.tags }, { p, v -> p.copy(tags = v) })

    val taskState: Lens<GeoObjectDetails, TaskState?> =
        lensOf("taskState", { it.taskState }, { p, v -> p.copy(taskState = v) })

    val connectedTo: Lens<GeoObjectDetails, String?> =
        lensOf("connectedTo", { it.connectedTo }, { p, v -> p.copy(connectedTo = v) })

    val atTime: Lens<GeoObjectDetails, Instant?> =
        lensOf("atTime", { it.atTime?.toInstant() }, { p, v -> p.copy(atTime = v?.toString()) })

    val endTime: Lens<GeoObjectDetails, Instant?> =
        lensOf(
            "endTime",
            {
                try {
                    it.endTime?.toInstant()
                } catch (e: Exception) {
                    null
                }
            },
            { p, v -> p.copy(endTime = v?.toString()) },
        )

    val assignedTo: Lens<GeoObjectDetails, String?> =
        lensOf("assignedTo", { it.assignedTo }, { p, v -> p.copy(assignedTo = v) })

    val attendees: Lens<GeoObjectDetails, List<MeetingAttendee>?> =
        lensOf("attendees", { it.attendees }, { p, v -> p.copy(attendees = v) })

    val canModify: Lens<GeoObjectDetails, Boolean> =
        lensOf("canModify", { it.canModify }, { p, v -> p.copy(canModify = v) })

    val canManage: Lens<GeoObjectDetails, Boolean> =
        lensOf("canManage", { it.canManage }, { p, v -> p.copy(canManage = v) })

    val geometry: Lens<GeoObjectDetails, Geometry?> =
        lensOf("geometry", { it.geometry }, { p, v -> p.copy(geometry = v) })

    val keywords: Lens<GeoObjectDetails, List<String>?> = lensOf("keywords", { it.keywords }, { p, v -> p.copy(keywords = v) })

    val color: Lens<GeoObjectDetails, MarkerColor?> = lensOf("color", { it.color }, { p, v -> p.copy(color = v) })

    val iconCategory: Lens<GeoObjectDetails, MarkerIcon?> = lensOf(
        "iconCategory",
        { it.iconCategory }, { p, v -> p.copy(iconCategory = v) },
    )

    val shape: Lens<GeoObjectDetails, MarkerShape?> = lensOf("shape", { it.shape }, { p, v -> p.copy(shape = v) })

    val updatedBy: Lens<GeoObjectDetails, String?> =
        lensOf("updatedBy", { it.updatedBy }, { p, v -> p.copy(updatedBy = v) })

    val createdBy: Lens<GeoObjectDetails, String?> =
        lensOf("createdBy", { it.createdBy }, { p, v -> p.copy(createdBy = v) })

    val owner: Lens<GeoObjectDetails, UserProfileSummary?> =
        lensOf("owner", { it.owner }, { p, v -> p.copy(owner = v) })

    val assignedToUser: Lens<GeoObjectDetails, UserProfileSummary?> =
        lensOf("assignedToUser", { it.assignedToUser }, { p, v -> p.copy(assignedToUser = v) })

    val deleted: Lens<GeoObjectDetails, Boolean> = lensOf("deleted", { it.deleted }, { p, v -> p.copy(deleted = v) })

    val attachments: Lens<GeoObjectDetails, List<Content>?> = lensOf(
        "attachments", { it.attachments },
        { p, v ->
            p.copy(
                attachments = v,
            )
        },
    )

    val connectableObject: Lens<GeoObjectDetails, ConnectableObject?> = lensOf(
        "connectableObject", { it.connectableObject },
        { p, v ->
            p.copy(
                connectableObject = v,
            )
        },
    )

    val geoReferencedConnectableObject: Lens<GeoObjectDetails, GeoReferencedConnectableObject?> =
        lensOf(
            "geoReferencedConnectableObject", { it.geoReferencedConnectableObject },
            { p, v ->
                p.copy(
                    geoReferencedConnectableObject = v,
                )
            },
        )

    val comments: Lens<GeoObjectDetails, List<Comment>?> = lensOf("comments", { it.comments }, { p, v -> p.copy(comments = v) })
}

val GeoObjectDetails.Companion.L get() = GeoObjectDetailsLenses

object PublicUserProfileLenses {
    val emailAddresses: Lens<PublicUserProfile, List<String>> =
        lensOf("emailAddresses", { it.emailAddresses }, { p, v -> p.copy(emailAddresses = v) })

    val firstName: Lens<PublicUserProfile, String?> = lensOf(
        "firstName",
        {
            it.firstName
        },
        { p, v -> p.copy(firstName = v) },
    )

    val jobTitle: Lens<PublicUserProfile, String?> = lensOf(
        "jobTitle",
        {
            it.jobTitle
        },
        { p, v -> p.copy(jobTitle = v) },
    )

    val lastName: Lens<PublicUserProfile, String?> = lensOf(
        "lastName",
        {
            it.lastName
        },
        { p, v -> p.copy(lastName = v) },
    )

    val phoneNumbers: Lens<PublicUserProfile, List<String>> = lensOf(
        "phoneNumbers",
        { it.phoneNumbers }, { p, v -> p.copy(phoneNumbers = v) },
    )

    val profilePhoto: Lens<PublicUserProfile, Content.Image?> = lensOf(
        "profilePhoto",
        { it.profilePhoto }, { p, v -> p.copy(profilePhoto = v) },
    )

    val userId: Lens<PublicUserProfile, String> = lensOf("userId", { it.userId }, { p, v -> p.copy(userId = v) })
}

val PublicUserProfile.Companion.L get() = PublicUserProfileLenses

fun UserPreferences.Companion.languageCode(): Lens<UserPreferences, String?> =
    lensOf(
        "languageCode",
        { it.languageCode },
        { p, v ->
            p.copy(languageCode = v)
        },
    )

fun UserPreferences.Companion.timeZone(): Lens<UserPreferences, String?> =
    lensOf(
        "timeZone",
        { it.timeZone },
        { p, v ->
            p.copy(timeZone = v)
        },
    )

fun UserPreferences.Companion.locationSharingState(): Lens<UserPreferences, Boolean> =
    lensOf(
        "locationSharingState",
        { it.locationSharingState ?: false },
        { p, v ->
            p.copy(locationSharingState = v)
        },
    )

fun UserPreferences.Companion.rememberLocationSharing(): Lens<UserPreferences, Boolean> =
    lensOf(
        "rememberLocationSharing",
        { it.rememberLocationSharing ?: false },
        { p, v ->
            p.copy(rememberLocationSharing = v)
        },
    )

fun UserPreferences.Companion.notificationPreferences(): Lens<UserPreferences, List<String>?> =
    lensOf(
        "notificationPreferences",
        { it.notificationPreferences },
        { p, v ->
            p.copy(notificationPreferences = v)
        },
    )

object SignupLenses {
    val email: Lens<Signup, String> = lensOf(
        "email", { it.email },
        { p, v ->
            p.copy(email = v)
        },
    )

    val firstName: Lens<Signup, String?> = lensOf(
        "firstName", { it.firstName },
        { p, v ->
            p.copy(firstName = v)
        },
    )

    val lastName: Lens<Signup, String?> = lensOf(
        "lastName", { it.lastName },
        { p, v ->
            p.copy(lastName = v)
        },
    )

    val password: Lens<Signup, String> = lensOf(
        "password", { it.password },
        { p, v ->
            p.copy(password = v)
        },
    )

    val workspace: Lens<Signup, String> = lensOf(
        "workspace", { it.workspace },
        { p, v ->
            p.copy(workspace = v)
        },
    )
}

val Signup.Companion.L get() = SignupLenses


fun KeyWordLayerDefinition.Companion.layerId(): Lens<KeyWordLayerDefinition, String> =
    lensOf(
        "layerId",
        { it.layerId },
        { p, v -> p.copy(layerId = v) },
    )

fun KeyWordLayerDefinition.Companion.keywords(): Lens<KeyWordLayerDefinition, List<String>> =
    lensOf(
        "keywords",
        { it.keywords },
        { p, v -> p.copy(keywords = v) },
    )

fun KeyWordLayerDefinition.Companion.or(): Lens<KeyWordLayerDefinition, Boolean> = lensOf(
    "or",
    { it.or },
    { p, v -> p.copy(or = v) },
)

fun KeyWordLayerDefinition.Companion.excludeKeywords(): Lens<KeyWordLayerDefinition, List<String>?> =
    lensOf(
        "excludeKeywords",
        { it.excludeKeywords },
        { p, v -> p.copy(excludeKeywords = v) },
    )

fun KeyWordLayerDefinition.Companion.fieldValues(): Lens<KeyWordLayerDefinition, List<String>?> {
    return lensOf(
        "fieldValues",
        { it.fieldValues },
        { p, v -> p.copy(fieldValues = v) },
    )
}

fun LayerMetaData.Companion.layerType(): Lens<LayerMetaData, LayerType> = lensOf(
    "layerType",
    { it.layerType },
    { p, v -> p.copy(layerType = v) },
)

fun LayerMetaData.Companion.id(): Lens<LayerMetaData, String> = lensOf(
    "id",
    { it.id },
    { p, v -> p.copy(id = v) },
)

fun LayerMetaData.Companion.title(): Lens<LayerMetaData, String> = lensOf(
    "title",
    { it.title ?: "" },
    { p, v -> p.copy(title = v) },
)

fun LayerMetaData.Companion.translatable(): Lens<LayerMetaData, Boolean> = lensOf(
    "translatable",
    { it.translatable },
    { p, v -> p.copy(translatable = v) },
)

fun LayerMetaData.Companion.defaultIconCategory(): Lens<LayerMetaData, MarkerIcon> =
    lensOf(
        "defaultIconCategory",
        { it.defaultIconCategory },
        { p, v -> p.copy(defaultIconCategory = v) },
    )

fun LayerMetaData.Companion.defaultColor(): Lens<LayerMetaData, MarkerColor> = lensOf(
    "defaultColor",
    { it.defaultColor },
    { p, v -> p.copy(defaultColor = v) },
)

fun LayerMetaData.Companion.defaultShape(): Lens<LayerMetaData, MarkerShape> = lensOf(
    "defaultShape",
    { it.defaultShape },
    { p, v -> p.copy(defaultShape = v) },
)

fun LayerMetaData.Companion.defaultOn(): Lens<LayerMetaData, Boolean> = lensOf(
    "defaultOn",
    { it.defaultOn },
    { p, v -> p.copy(defaultOn = v) },
)

fun LayerMetaData.Companion.layerVisibility(): Lens<LayerMetaData, LayerVisibility> =
    lensOf(
        "layerVisibility",
        { it.layerVisibility },
        { p, v -> p.copy(layerVisibility = v) },
    )

fun LayerMetaData.Companion.layerCacheSettings(): Lens<LayerMetaData, LayerCacheSettings?> =
    lensOf(
        "layerCacheSettings",
        { it.layerCacheSettings },
        { p, v -> p.copy(layerCacheSettings = v) },
    )

fun HeatmapLayerDefinitionData.Companion.layerId(): Lens<HeatmapLayerDefinitionData, String> =
    lensOf(
        "layerId",
        { it.layerId },
        { p, v -> p.copy(layerId = v) },
    )

fun HeatmapLayerDefinitionData.Companion.title(): Lens<HeatmapLayerDefinitionData, String> =
    lensOf(
        "title",
        { it.title },
        { p, v -> p.copy(title = v) },
    )

fun HeatmapLayerDefinitionData.Companion.searchQueryCtx(): Lens<HeatmapLayerDefinitionData, SearchQueryContext> =
    lensOf(
        "searchQueryCtx",
        { it.searchQueryCtx },
        { p, v -> p.copy(searchQueryCtx = v) },
    )

fun HeatmapLayerDefinitionData.Companion.keywords(): Lens<HeatmapLayerDefinitionData, List<String>> =
    lensOf(
        "keywords",
        { it.keywords },
        { p, v -> p.copy(keywords = v) },
    )

fun HeatmapLayerDefinitionData.Companion.or(): Lens<HeatmapLayerDefinitionData, Boolean> = lensOf(
    "or",
    { it.or },
    { p, v -> p.copy(or = v) },
)

fun HeatmapLayerDefinitionData.Companion.excludeKeywords(): Lens<HeatmapLayerDefinitionData, List<String>?> =
    lensOf(
        "excludeKeywords",
        { it.excludeKeywords },
        { p, v -> p.copy(excludeKeywords = v) },
    )

fun HeatmapLayerDefinitionData.Companion.fieldValues(): Lens<HeatmapLayerDefinitionData, List<FieldValue>> =
    lensOf(
        "fieldValues",
        { it.fieldValues },
        { p, v -> p.copy(fieldValues = v) },
    )

fun HeatmapLayerDefinitionData.Companion.opacity(): Lens<HeatmapLayerDefinitionData, Double> =
    lensOf(
        "opacity",
        { it.opacity },
        { p, v -> p.copy(opacity = v) },
    )

fun HeatmapLayerDefinitionData.Companion.colorPalette(): Lens<HeatmapLayerDefinitionData, List<String>> =
    lensOf(
        "colorPalette",
        { it.colorPalette },
        { p, v -> p.copy(colorPalette = v) },
    )

fun HeatmapLayerDefinitionData.Companion.style(): Lens<HeatmapLayerDefinitionData, JsonObject?> =
    lensOf(
        "style",
        { it.style },
        { p, v -> p.copy(style = v) },
    )

fun SearchQueryContext.Companion.groupIds(): Lens<SearchQueryContext, List<String>?> =
    lensOf(
        "groupIds",
        { it.groupIds },
        { p, v -> p.copy(groupIds = v) },
    )

fun SearchQueryContext.Companion.text(): Lens<SearchQueryContext, String?> =
    lensOf(
        "text",
        { it.text },
        { p, v -> p.copy(text = v) },
    )

fun SearchQueryContext.Companion.fieldValues(): Lens<SearchQueryContext, List<CustomFieldClause>?> =
    lensOf(
        "fieldValues",
        { it.fieldValues },
        { p, v -> p.copy(fieldValues = v) },
    )

fun SearchQueryContext.Companion.tags(): Lens<SearchQueryContext, TagList?> =
    lensOf(
        "tags",
        { it.tags },
        { p, v -> p.copy(tags = v) },
    )

fun SearchQueryContext.Companion.orTags(): Lens<SearchQueryContext, TagList?> =
    lensOf(
        "orTags",
        { it.orTags },
        { p, v -> p.copy(orTags = v) },
    )

fun SearchQueryContext.Companion.objectTypes(): Lens<SearchQueryContext, List<ObjectType>?> =
    lensOf(
        "objectTypes",
        { it.objectTypes },
        { p, v -> p.copy(objectTypes = v) },
    )

fun HeatmapLayerDefinition.Companion.title(): Lens<HeatmapLayerDefinition, String> =
    lensOf(
        "title",
        { it.title },
        { p, v -> p.copy(title = v) },
    )

fun WorkSpaceOptions.Companion.groupId(): Lens<WorkSpaceOptions?, String> = lensOf(
    "groupId",
    { it?.groupId ?: "" },
    { p, v -> p?.copy(groupId = v) },
)

fun WorkSpaceOptions.Companion.name(): Lens<WorkSpaceOptions?, String> = lensOf(
    "name",
    { it?.name ?: "" },
    { p, v -> p?.copy(name = v) },
)

fun WorkSpaceOptions.Companion.anonymousAccessAllowed(): Lens<WorkSpaceOptions?, Boolean> =
    lensOf(
        "anonymousAccessAllowed",
        { it?.anonymousAccessAllowed ?: false },
        { p, v -> p?.copy(anonymousAccessAllowed = v) },
    )

fun WorkSpaceOptions.Companion.signupsAllowed(): Lens<WorkSpaceOptions?, Boolean> = lensOf(
    "signupsAllowed",
    { it?.signupsAllowed ?: false },
    { p, v -> p?.copy(signupsAllowed = v) },
)

fun AppState.Companion.apiUserBusy(): Lens<AppState, InitializationState?> = lensOf(
    "apiUserBusy",
    { it.apiUserBusy },
    { p, v -> p.copy(apiUserBusy = v) },
)

fun AppState.Companion.termsBusy(): Lens<AppState, InitializationState?> = lensOf(
    "termsBusy",
    { it.apiUserBusy },
    { p, v -> p.copy(termsBusy = v) },
)

fun AppState.Companion.apiUser(): Lens<AppState, ApiUser?> = lensOf(
    "apiUserBusy",
    { it.apiUser },
    { p, v -> p.copy(apiUser = v) },
)

fun AppState.Companion.localSettings(): Lens<AppState, LocalSettings?> = lensOf(
    "localSettings",
    { it.localSettings },
    { p, v -> p.copy(localSettings = v) },
)

fun AppState.Companion.acceptCookies(): Lens<AppState, Boolean?> = lensOf(
    "acceptCookies",
    { it.acceptCookies },
    { p, v -> p.copy(acceptCookies = v) },
)

fun AppState.Companion.acceptDisclaimer(): Lens<AppState, Boolean?> = lensOf(
    "acceptDisclaimer",
    { it.acceptDisclaimer },
    { p, v -> p.copy(acceptDisclaimer = v) },
)

fun AppState.Companion.agreedTermsState(): Lens<AppState, Boolean?> = lensOf(
    "agreedTermsState",
    { it.agreedTermsState },
    { p, v -> p.copy(agreedTermsState = v) },
)

// PollData lenses
fun PollData.Companion.id(): Lens<PollData, String?> = lensOf(
    "id",
    { it.id },
    { p, v -> p.copy(id = v) },
)

fun PollData.Companion.title(): Lens<PollData, String> = lensOf(
    "title",
    { it.title },
    { p, v -> p.copy(title = v) },
)

fun PollData.Companion.externalId(): Lens<PollData, String> = lensOf(
    "externalId",
    { it.actionId },
    { p, v -> p.copy(actionId = v) },
)

fun PollData.Companion.options(): Lens<PollData, List<PollOption>> = lensOf(
    "options",
    { it.options },
    { p, v -> p.copy(options = v) },
)


// TaskTemplateDate lenses
fun TaskTemplateData.Companion.id(): Lens<TaskTemplateData, String?> = lensOf(
    "id",
    { it.id },
    { p, v -> p.copy(id = v) },
)

fun TaskTemplateData.Companion.title(): Lens<TaskTemplateData, String> = lensOf(
    "title",
    { it.title },
    { p, v -> p.copy(title = v) },
)

fun TaskTemplateData.Companion.actionId(): Lens<TaskTemplateData, String?> = lensOf(
    "actionId",
    { it.actionId },
    { p, v -> p.copy(actionId = v) },
)

fun TaskTemplateData.Companion.taskTemplate(): Lens<TaskTemplateData, TaskTemplate?> = lensOf(
    "taskTemplate",
    { it.taskTemplate },
    { p, v -> p.copy(taskTemplate = v) },
)


//TaskTemplate lenses
fun TaskTemplate.Companion.title(): Lens<TaskTemplate, String?> = lensOf(
    "title",
    { it.title },
    { p, v -> p.copy(title = v) },
)

fun TaskTemplate.Companion.latLon(): Lens<TaskTemplate, LatLon?> = lensOf(
    "latLon",
    { it.latLon },
    { p, v -> p.copy(latLon = v) },
)

fun TaskTemplate.Companion.textAttachment(): Lens<TaskTemplate, String> = lensOf(
    "textAttachment",
    { it.textAttachment ?: "" },
    { p, v -> p.copy(textAttachment = v) },
)

//fun TaskTemplate.Companion.textAttachmentTitle(): Lens<TaskTemplate, String?> = lensOf(
//    "textAttachmentTitle",
//    { it.textAttachmentTitle },
//    { p, v -> p.copy(textAttachmentTitle = v) }
//)

fun TaskTemplate.Companion.keywords(): Lens<TaskTemplate, List<String>?> = lensOf(
    "keywords",
    { it.keywords },
    { p, v -> p.copy(keywords = v) },
)

fun TaskTemplate.Companion.atTime(): Lens<TaskTemplate, String?> = lensOf(
    "atTime",
    { it.atTime },
    { p, v -> p.copy(atTime = v) },
)

fun TaskTemplate.Companion.assignedTo(): Lens<TaskTemplate, String?> = lensOf(
    "assigendTo",
    { it.assignedTo },
    { p, v -> p.copy(assignedTo = v) },
)

fun TaskTemplate.Companion.fieldValueTags(): Lens<TaskTemplate, List<String>?> = lensOf(
    "fieldValueTags",
    { it.fieldValueTags },
    { p, v -> p.copy(fieldValueTags = v) },
)

fun TaskTemplate.Companion.iconCategory(): Lens<TaskTemplate, MarkerIcon?> = lensOf(
    "iconCategory",
    { it.iconCategory },
    { p, v -> p.copy(iconCategory = v) },
)

fun TaskTemplate.Companion.color(): Lens<TaskTemplate, MarkerColor?> = lensOf(
    "color",
    { it.color },
    { p, v -> p.copy(color = v) },
)

fun TaskTemplate.Companion.shape(): Lens<TaskTemplate, MarkerShape?> = lensOf(
    "shape",
    { it.shape },
    { p, v -> p.copy(shape = v) },
)

fun LatLon.Companion.lat(): Lens<LatLon, Double> = lensOf(
    "lat",
    { it.lat },
    { p, v -> p.copy(lat = v) },
)

fun LatLon.Companion.lon(): Lens<LatLon, Double> = lensOf(
    "lon",
    { it.lon },
    { p, v -> p.copy(lon = v) },
)

fun GeoPosition.Companion.stringCoordinates(): Lens<GeoPosition?, String> = lensOf(
    "stringCoordinates",
    { it?.coords?.let { c -> "${c.latitude}, ${c.longitude}" } ?: "" },
    { p, v -> convertToGeoPosition(v) },
)

fun TimeFilter.Companion.dateFrom(): Lens<TimeFilter, String?> = lensOf(
    "dateFrom",
    { it.filterStartDate },
    { p, v -> p.copy(filterStartDate = v) },
)

fun TimeFilter.Companion.dateTo(): Lens<TimeFilter, String?> = lensOf(
    "dateTo",
    { it.filterEndDate },
    { p, v -> p.copy(filterEndDate = v) },
)


fun Connection.Companion.sourceConnectorId(): Lens<Connection, String> = lensOf(
    "sourceConnectorId",
    { it.sourceConnectorId },
    { p, v -> p.copy(sourceConnectorId = v) },
)

fun Connection.Companion.targetMarkerId(): Lens<Connection, String> = lensOf(
    "targetMarkerId",
    { it.targetMarkerId },
    { p, v -> p.copy(targetMarkerId = v) },
)

fun Connection.Companion.targetConnectorId(): Lens<Connection, String> = lensOf(
    "targetConnectorId",
    { it.targetConnectorId },
    { p, v -> p.copy(targetConnectorId = v) },
)

fun WorkspaceCreateData.Companion.workspaceName(): Lens<WorkspaceCreateData, String> = lensOf(
    "workspaceName",
    { it.workspaceName },
    { p, v -> p.copy(workspaceName = v) },
)

fun WorkspaceCreateData.Companion.email(): Lens<WorkspaceCreateData, String> = lensOf(
    "email",
    { it.email },
    { p, v -> p.copy(email = v) },
)

fun WorkspaceCreateData.Companion.isCreated(): Lens<WorkspaceCreateData, Boolean> = lensOf(
    "isCreated",
    { it.isCreated },
    { p, v -> p.copy(isCreated = v) },
)

fun CameraMeta.Companion.id(): Lens<CameraMeta?, String> = lensOf(
    "id",
    { it?.id ?: "" },
    { p, v -> p?.copy(id = v) },
)

fun NavigationData.Companion.originPointSource(): Lens<NavigationData, NavigationPointSource?> = lensOf(
    "originPointSource",
    { it.originPointSource },
    { p, v -> p.copy(originPointSource = v) },
)

fun NavigationData.Companion.originLatLon(): Lens<NavigationData, LatLon?> = lensOf(
    "fromLatLon",
    { it.originLatLon },
    { p, v -> p.copy(originLatLon = v) },
)

fun NavigationData.Companion.originObject(): Lens<NavigationData, GeoObjectDetails?> = lensOf(
    "fromObject",
    { it.originObject },
    { p, v -> p.copy(originObject = v) },
)

fun NavigationData.Companion.destinationPointSource(): Lens<NavigationData, NavigationPointSource?> = lensOf(
    "destinationPointSource",
    { it.destinationPointSource },
    { p, v -> p.copy(destinationPointSource = v) },
)

fun NavigationData.Companion.destinationLatLon(): Lens<NavigationData, LatLon?> = lensOf(
    "toLatLon",
    { it.destinationLatLon },
    { p, v -> p.copy(destinationLatLon = v) },
)

fun NavigationData.Companion.destinationObject(): Lens<NavigationData, GeoObjectDetails?> = lensOf(
    "toObject",
    { it.destinationObject },
    { p, v -> p.copy(destinationObject = v) },
)
