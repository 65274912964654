package data.users.settings

import apiclient.geoobjects.MarkerColor
import apiclient.geoobjects.MarkerIcon
import apiclient.geoobjects.MarkerShape

val kamaxKeywordPriorities = listOf("matnr", "charg")

val keywordPriorities: List<String> = kamaxKeywordPriorities

val kamaxZoneTypeIconMap = mapOf(
    "containers" to MarkerIcon.Container,
    "Container" to MarkerIcon.Container,
    "coils" to MarkerIcon.Coil,
    "Coil" to MarkerIcon.Coil,
    "machine" to MarkerIcon.Gear
)
val kamaxZoneTypeShapeMap = mapOf(
    "containers" to MarkerShape.Diamond,
    "Container" to MarkerShape.Diamond,
    "coils" to MarkerShape.Diamond,
    "Coil" to MarkerShape.Diamond,
    "machine" to MarkerShape.Egg
)

val kamaxZoneTypeColorMap = mapOf(
//    "containers" to MarkerColor.LightGrey,
//    "Container" to MarkerColor.LightGrey,
//    "coils" to MarkerColor.LightGrey,
//    "Coil" to MarkerColor.LightGrey,
    "machine" to MarkerColor.Grey
)

val zoneTypeIconMap: Map<String, MarkerIcon> = kamaxZoneTypeIconMap
val zoneTypeShapeMap: Map<String, MarkerShape> = kamaxZoneTypeShapeMap
val zoneTypeColorMap: Map<String, MarkerColor> = kamaxZoneTypeColorMap
