package data.objects.views

import data.heatmaplayer.ActiveHeatmapLayerDefinitionStore
import data.keywordlayer.ActiveKeywordLayerDefinitionStore
import data.objects.ActiveObjectStore
import dev.fritz2.core.RenderContext
import dev.fritz2.components.compat.p
import koin.koinCtx
import localization.TL
import localization.Translation
import mainmenu.RouterStore
import webcomponents.twoButtonFooter

enum class Delete { Object, KeywordLayer, HeatmapLayer }

fun RenderContext.cardDeleteConfirmation(type: Delete) {

    val translation: Translation by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()
    val activeObjectStore: ActiveObjectStore by koinCtx.inject()
    val activeKeywordLayerDefinitionStore: ActiveKeywordLayerDefinitionStore by koinCtx.inject()
    val activeHeatmapLayerDefinitionStore: ActiveHeatmapLayerDefinitionStore by koinCtx.inject()

    p({
        fontSize { larger }
        fontWeight { bold }
        margins { vertical { large } }
    }) { translation[TL.CardDeleteConfirmation.CARD_TITLE].renderText(into = this) }
    p({
        margins { vertical { large } }
    }) { translation[TL.CardDeleteConfirmation.ARE_YOU_SURE].renderText(into = this) }
    twoButtonFooter(
        primaryTitle = translation[TL.General.DELETE],
        primaryValue = Unit,
        primaryClickHandlers = when (type) {
            Delete.Object -> listOf(activeObjectStore.delete)
            Delete.KeywordLayer -> listOf(activeKeywordLayerDefinitionStore.deleteKeyWordLayer)
            Delete.HeatmapLayer -> listOf(activeHeatmapLayerDefinitionStore.deleteHeatmapLayer)
        },
        primaryStyleType = { danger },
        secondaryTitle = translation[TL.General.KEEP],
        secondaryClickHandlers = listOf(routerStore.back)
    )
}
