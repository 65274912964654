package login

import analytics.AnalyticsCategory
import analytics.AnalyticsService
import apiclient.auth.LoginAndTokenRefreshService
import apiclient.auth.LoginOutcome
import apiclient.auth.restLoginToWorkspace
import apiclient.users.restRequestResetPasswordLink
import dev.fritz2.core.RootStore
import dev.fritz2.core.invoke
import dev.fritz2.routing.MapRouter
import dev.fritz2.tracking.tracker
import koin.koinCtx
import kotlinx.browser.window
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.flowOf
import localization.TL
import localization.Translation
import mainmenu.Pages
import mainmenu.RouterStore
import model.Credentials
import model.NotificationType
import model.Overlay
import model.SplashType
import overlays.AlertOverlayStore
import overlays.BusyStore
import overlays.SplashOverlayStore
import theme.FormationColors
import utils.merge


/**
 * Store object for temporarily store user credentials at login
 */
class CredentialsStore : RootStore<Credentials>(
    initialData = Credentials(),
    job = Job(),
) {

    private val loginAndTokenRefreshService: LoginAndTokenRefreshService by koinCtx.inject()
    private val alertOverlayStore: AlertOverlayStore by koinCtx.inject()
    private val splashOverlayStore: SplashOverlayStore by koinCtx.inject()
    private val translation: Translation by koinCtx.inject()
    private val workspaceInputStore: WorkspaceInputStore by koinCtx.inject()
    private val router: MapRouter by koinCtx.inject()
    private val routerStore: RouterStore by koinCtx.inject()
    private val hostConfigStore: HostConfigStore by koinCtx.inject()
    private val busyStore: BusyStore by koinCtx.inject()
    private val analyticsService by koinCtx.inject<AnalyticsService>()

    private val anonymousFormationClient = hostConfigStore.anonymousClient()

    val reset = handle {
        Credentials()
    }

    val loggingIn = tracker()

    val login = handle { credentials ->
        loggingIn.track {
            try {
                busyStore.handleApiCall(
                    supplier = {
                        workspaceInputStore.current?.let { workspace ->
                            anonymousFormationClient.restLoginToWorkspace(
                                workspace,
                                credentials.email,
                                credentials.password,
                            )
                        }
                    },
                    processResult = { loggedInUser ->
                        analyticsService.createEvent(
                            AnalyticsCategory.ChangeLoginState,
                        ) {
                            recordAction("logged-in-successfully")
                        }

                        loginAndTokenRefreshService.apiUserItem.set(loggedInUser)
                        console.log(loggedInUser.firstName, "logged in successfully!")
                        loggedInUser.workspaceName?.let { routerStore.updateWsOfRedirectRoute(it) }
//                        workspaceInputStore.current?.let { routerStore.updateWsOfRedirectRoute(it) }
                        workspaceInputStore.reset()
                        splashOverlayStore.show(
                            Overlay.SplashScreen(
                                splashType = SplashType.LoggingInSplash,
                                durationSeconds = 2,
                            ),
                        )
                        alertOverlayStore.show(
                            Overlay.NotificationToast(
                                notificationType = NotificationType.Alert,
                                durationSeconds = 4,
                                text = translation[TL.AlertNotifications.LOGGED_IN_SUCCESSFULLY],
                                bgColor = FormationColors.GreenActive.color,
                            ),
                        )
                    },
                )
            } catch (e: Exception) {
                console.error(":-(", e)
                analyticsService.createEvent(
                    AnalyticsCategory.ChangeLoginState,
                ) {
                    recordAction("logged-in-failure")
                }

                alertOverlayStore.show(
                    Overlay.NotificationToast(
                        notificationType = NotificationType.Alert,
                        durationSeconds = 4,
                        text = translation[TL.AlertNotifications.LOGIN_ERROR],
                        bgColor = FormationColors.RedError.color,
                    ),
                )
            }
        }
        /**
         * if login was successful, clear credentials immediately to avoid re-login with in-memory credentials
         * if login failed, keep 'wrong' credentials to preserve consistence with the visible input field states
         */
        if (loginAndTokenRefreshService.apiUserItem.getIfExists() != null) Credentials("", "") else credentials
    }

    val signInWithRefreshToken = handle<String> { current, token ->
        router.navTo(Pages.Map.route)

        when (val loginOutcome = loginAndTokenRefreshService.loginWithSignInToken(token)) {
            LoginOutcome.Success -> {
                analyticsService.createEvent(
                    AnalyticsCategory.ChangeLoginState,
                ) {
                    recordAction("token-refresh")
                }

                window.location.reload()
                alertOverlayStore.show(
                    Overlay.NotificationToast(
                        notificationType = NotificationType.Alert,
                        durationSeconds = 4,
                        text = translation[TL.AlertNotifications.LOGGED_IN_SUCCESSFULLY],
                        bgColor = FormationColors.GreenActive.color,
                    ),
                )
            }

            LoginOutcome.WrongUserOrPassword,
            LoginOutcome.IncorrectVersion,
            LoginOutcome.OtherFailure -> {
                analyticsService.createEvent(
                    AnalyticsCategory.ChangeLoginState,
                ) {
                    recordAction("token-refresh-failure", target = loginOutcome.name)
                }

                alertOverlayStore.show(
                    Overlay.NotificationToast(
                        notificationType = NotificationType.Alert,
                        durationSeconds = 4,
                        text = translation[TL.AlertNotifications.LOGIN_ERROR].merge(flowOf("(${loginOutcome.name})")),
                        bgColor = FormationColors.RedError.color,
                    ),
                )
            }
        }

        current
    }

    val sendPasswordResetLink = handle { credentials ->
        workspaceInputStore.current?.let { workspace ->
            val res = anonymousFormationClient.restRequestResetPasswordLink(workspaceName = workspace, credentials.email)
            res.fold(
                { success ->
                    analyticsService.createEvent(
                        AnalyticsCategory.ChangeLoginState,
                    ) {
                        recordAction("send-password-reset-email")
                    }

                    console.log("Reset password link was send to \"${credentials.email}\" in \"$workspace\".", success)
                    alertOverlayStore.notify(flowOf("Reset password link was send to \"${credentials.email}\" in \"$workspace\"."))
                    routerStore.addOrReplaceRoute(mapOf("show" to "resetPasswordLinkSend"))
                },
                { error ->
                    analyticsService.createEvent(
                        AnalyticsCategory.ChangeLoginState,
                    ) {
                        recordAction("send-password-reset-email-failed")
                    }

                    alertOverlayStore.errorNotify(flowOf("Sending reset password link failed. ${error.message}"))
                },
            )
        }
//            ?: run {
//            alertOverlayStore.errorNotify("Sending reset password link failed. No workspace defined.")
//        }
        credentials
    }

//    val flipRememberMe = handle { current ->
//        val newSwitchState = !current.rememberMe
//        console.log("\"remember me\" check state -> $newSwitchState")
//        current.copy(
//            rememberMe = newSwitchState
//        )
//    }
}
