package workspacetools.usermanagement

import apiclient.groups.MemberShipResponsePublic
import auth.CurrentWorkspaceStore
import dev.fritz2.components.compat.img
import dev.fritz2.components.compat.table
import dev.fritz2.components.icon
import dev.fritz2.components.lineUp
import dev.fritz2.components.modal
import dev.fritz2.components.pushButton
import dev.fritz2.components.radioGroup
import dev.fritz2.components.stackUp
import dev.fritz2.core.RenderContext
import dev.fritz2.core.SimpleHandler
import dev.fritz2.core.height
import dev.fritz2.core.placeholder
import dev.fritz2.core.src
import dev.fritz2.core.storeOf
import dev.fritz2.core.values
import dev.fritz2.routing.decodeURIComponent
import dev.fritz2.routing.encodeURIComponent
import koin.koinCtx
import kotlin.random.Random
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.mapNotNull
import localization.TL
import localization.Translation
import mainmenu.RouterStore
import overlays.dotSpinner
import signup.textLinkButton
import styling.primaryButtonStyleParams
import styling.secondaryButtonStyleParams
import theme.FormationIcons
import theme.FormationUIIcons
import utils.merge
import webcomponents.baseLayout
import webcomponents.cardTitle
import webcomponents.contentScrollBox
import webcomponents.genericInput
import webcomponents.inputLabelWrapper
import webcomponents.oneButtonFooter

val MemberShipResponsePublic.search
    get() = (this.userId + " " + this.roles.joinToString(" ") + " " + this.userProfile?.let {
        "${it.firstName} ${it.lastName} ${it.emailAddresses.joinToString(" ")} ${it.jobTitle} ${
            it.phoneNumbers.joinToString(
                " ",
            )
        }"
    }).lowercase()

private fun MemberShipResponsePublic.include(filter: String) = this.search.contains(filter, true)

fun String.urlEncode() = encodeURIComponent(this)
fun String.urlDecode() = decodeURIComponent(this)

fun RenderContext.adminDashboardPage() {
    val translation: Translation by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()
    val currentWorkspaceStore by koinCtx.inject<CurrentWorkspaceStore>()

    baseLayout(
        expandable = false,
        header = {
            cardTitle(
                title = combine(
                    currentWorkspaceStore.data,
                    translation[DashboardTexts.LoadingDashboard],
                    translation[DashboardTexts.DashboardWorkspaceMembers],
                ) { workspace, loading, membersTitle ->
                    if (workspace == null) {
                        loading
                    } else {
                        membersTitle + " (${workspace.name})"
                    }
                },
            ) { FormationIcons.UserGroup.icon }
        },
        content = {
            contentScrollBox {
                userManagement()
            }
        },
        footer = {
            oneButtonFooter(
                title = translation[TL.General.CLOSE],
                value = Unit,
                clickHandlers = listOf(routerStore.back),
                width = { "320px" },
            )
        },
    )
}

fun RenderContext.userManagement() {
    val currentWorkspaceStore by koinCtx.inject<CurrentWorkspaceStore>()
    val groupMembersStore by koinCtx.inject<GroupMembersStore>()
    val selectedGroupMemberStore by koinCtx.inject<SelectedGroupMemberStore>()
    val translation by koinCtx.inject<Translation>()

    val memberFilterStore = storeOf("")
    groupMembersStore.enabledStore.update(true) // start fetching only when this renders the first time
    stackUp(
        {
            height { full }
            width { full }
            overflow { auto }
            paddings {
                horizontal { small }
            }
        },
    ) {
        items {
            currentWorkspaceStore.data.render { workspace ->
                if (workspace == null) {
                    dotSpinner()
                } else {
//                    p {
//                        translation[DashboardTexts.DashboardExplainerShort].renderText(into = this)
//                    }
                    lineUp(
                        {
                            width { full }
                            alignItems { center }
                            justifyContent { spaceBetween }
                            margins { vertical { normal } }
                            wrap { wrap }
                        },
                    ) {
                        spacing { small }
                        items {
                            pushButton(
                                {
                                    primaryButtonStyleParams()
                                    margins {
                                        top { none }
                                    }
                                    flex {
                                        shrink { "0" }
                                    }
                                },
                            ) {
                                type { primary }
                                icon { FormationIcons.PersonAdd.icon }
                                text(translation[DashboardTexts.InviteNewMemberButton])
                                events {
                                    clicks handledBy modal(
                                        {
                                            radius { "15px" }
                                        },
                                    ) {
                                        content { close ->
                                            inviteNewUser(close)
                                        }
                                    }
                                }
                            }
                            inputLabelWrapper(
                                title = flowOf("Filter Users by name"), // TODO translate
                                visibilityFlow = memberFilterStore.data.map { it.isNotBlank() },
                            ) {
                                genericInput(
                                    value = memberFilterStore.data,
                                    minWidth = { "100px" },
                                    maxWidth = { "300px" },
                                    rightContentBox = {
                                        icon(
                                            {
                                                size { large }
                                                margins { horizontal { small } }
                                            },
                                        ) { fromTheme { filterAlt } }
                                    },
                                ) {
                                    placeholder(flowOf("Alice"))
                                    inputs.values() handledBy memberFilterStore.update
                                }
                            }
                        }
                    }

                    table(
                        {
                            width { full }
                            justifyContent { spaceBetween }
                            textAlign { left }
                        },
                    ) {
                        tr {
                            th {
                                translation[DashboardTexts.DataTableName].renderText(into = this)
                            }
                            th {
                                translation[DashboardTexts.DataTableEmail].renderText(into = this)
                            }
                            th {
                                translation[DashboardTexts.DataTableAction].renderText(into = this)
                            }
                        }
                        combine(memberFilterStore.data, groupMembersStore.data) { f, m ->
                            Pair(
                                f,
                                m,
                            )
                        }.render { (filter, members) ->
                            members.sortedBy { it.userProfile?.name ?: "" }.forEach { member ->
                                if (filter.isBlank() || member.include(filter)) {
                                    tr {
                                        td {
                                            +"${member.userProfile?.firstName} ${member.userProfile?.lastName}"
                                        }
                                        td {
                                            +(member.userProfile?.emailAddresses?.joinToString(
                                                ",",
                                            ) ?: "")
                                        }
                                        td {
                                            pushButton(
                                                {
                                                    secondaryButtonStyleParams()
                                                },
                                            ) {
                                                type { secondary }
                                                icon { FormationIcons.Edit.icon }
                                                text(translation[DashboardTexts.OpenEditButton])
                                                events {
                                                    with(this@userManagement) {
                                                        clicks.map { member } handledBy selectedGroupMemberStore.update
                                                        clicks handledBy modal(
                                                            {
                                                                radius { "15px" }
                                                            },
                                                        ) {
                                                            content {
                                                                showUser()
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

fun RenderContext.inviteNewUser(close: SimpleHandler<Unit>) {
    val translation by koinCtx.inject<Translation>()

    val invitationEmailStore by koinCtx.inject<InvitationEmailStore>()

    stackUp(
        {
            paddings {
                horizontal { small }
            }
        },
    ) {
        spacing { small }
        items {
            cardTitle(translation[DashboardTexts.InviteUserTitle]) { FormationIcons.PersonAdd.icon }
            inputLabelWrapper(
                title = flowOf("Email address ( e.g. alice@domain.com)"), // TODO translate
                visibilityFlow = invitationEmailStore.data.map { it.isNotBlank() },
            ) {
                genericInput(
                    value = invitationEmailStore.data,
                    minWidth = { "100px" },
                    maxWidth = { "300px" },
                ) {
                    placeholder("alice@domain.com")
                    inputs.values() handledBy invitationEmailStore.update
                }
            }
            pushButton(
                {
                    primaryButtonStyleParams()
                },
            ) {
                type { primary }
                icon { FormationIcons.Mail.icon }
                text(translation[DashboardTexts.InviteUserInviteButton])
                enabled(invitationEmailStore.data.map { email -> email.isNotBlank() })
                events {
                    clicks handledBy close
                    clicks handledBy invitationEmailStore.inviteNewUser
                }
            }
            p {
                translation[DashboardTexts.InviteUserExplainer].renderText(this)
            }
        }
    }
}

fun RenderContext.showUser() {
    val copyToCLipboardStore by koinCtx.inject<CopyToCLipboardStore>()
    val selectedGroupMemberStore by koinCtx.inject<SelectedGroupMemberStore>()
    val translation by koinCtx.inject<Translation>()

    selectedGroupMemberStore.data.render { member ->
        if (member != null) {
            val profile = member.userProfile ?: error("should have a profile")
            stackUp(
                {
                    paddings {
                        horizontal { small }
                    }
                },
            ) {
                spacing { small }
                items {
                    cardTitle(flowOf("${profile.firstName} ${profile.lastName}")) { FormationIcons.UserAlt.icon }
                    profile.profilePhoto?.let { photo ->
                        img(
                            {
                            },
                        ) {
                            src(photo.href)
                            height(300)
                        }
                    }
                    p {
                        translation[DashboardTexts.DataTableEmail]
                            .merge(flowOf(": ${profile.emailAddresses.joinToString(",")}"))
                            .renderText(into = this)
                    }
                    profile.phoneNumbers.let {
                        if (it.isNotEmpty()) {
                            p {
                                translation[DashboardTexts.DataTablePhone]
                                    .merge(flowOf(": ${it.joinToString(" ")}"))
                                    .renderText(into = this)
                            }
                        }
                    }
                    lineUp(
                        {
                            alignItems { center }
                        },
                    ) {
                        spacing { small }
                        items {
                            p { +"ID: " }
                            pre {
                                +member.userId
                            }
                            pushButton {
                                icon { FormationUIIcons.Copy.icon }
                                size { small }
                                events {
                                    clicks.map { member.userId } handledBy copyToCLipboardStore.copyThat
                                }
                            }
                        }
                    }
                    val link = "https://app.tryformation.com/to/${member.groupName.urlEncode()}/${
                        member.userProfile?.emailAddresses?.first()?.urlEncode()
                    }"
                    lineUp(
                        {
                            alignItems { center }
                        },
                    ) {
                        spacing { small }
                        items {
                            p { +"Login link: " }
                            textLinkButton(
                                text = flowOf(link),
                                link = link,
                            )
                            pushButton {
                                icon { FormationUIIcons.Copy.icon }
                                size { small }
                                events {
                                    clicks.map { link } handledBy copyToCLipboardStore.copyThat
                                }
                            }
                        }
                    }
                    p {
                        translation[DashboardTexts.DataRole].merge(flowOf(": ${member.roles.joinToString(",")}"))
                            .renderText(into = this)
                    }
                    lineUp(
                        {
                            width { full }
                            wrap { wrap }
                        },
                    ) {
                        spacing { none }
                        items {
                            pushButton(
                                {
                                    primaryButtonStyleParams()
                                    margins {
                                        vertical { tiny }
                                        right { small }
                                    }
                                },
                            ) {
                                type { primary }
                                icon { FormationIcons.DeleteAlt.icon }
                                text(translation[DashboardTexts.DeleteUserButton])
                                events {
                                    clicks handledBy confirm(
                                        translation[
                                            DashboardTexts.DeleteUserAreYouSure,
                                            mapOf(
                                                "name" to (member.userProfile?.name ?: member.userId),
                                            ),
                                        ],
                                        listOf(selectedGroupMemberStore.delete),
                                    )
                                }
                            }
                            pushButton(
                                {
                                    primaryButtonStyleParams()
                                    margins {
                                        vertical { tiny }
                                        right { small }
                                    }
                                },
                            ) {
                                type { primary }
                                icon { FormationIcons.Lock.icon }
                                text(translation[DashboardTexts.SetNewPasswordButton])
                                events {
                                    clicks handledBy modal(
                                        {
                                            radius { "15px" }
                                        },
                                    ) {
                                        content { closeHandler ->
                                            setNewPassword(closeHandler)
                                        }
                                    }
                                }
                            }
                            pushButton(
                                {
                                    primaryButtonStyleParams()
                                    margins {
                                        vertical { tiny }
                                        right { small }
                                    }
                                },
                            ) {
                                type { primary }
                                icon { FormationIcons.PersonChange.icon }
                                text(translation[DashboardTexts.ChangeRoleButton])
                                events {
                                    clicks handledBy modal(
                                        {
                                            radius { "15px" }
                                        },
                                    ) {
                                        // will close after because setRole causes the member store to update and this to re-render
                                        content {
                                            setRole()
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        } else {
            p {
                translation[DashboardTexts.NoMemberSelected].renderText(into = this)
            }
        }
    }
}

val groupRoles = listOf("group_member", "group_guest", "group_admin", "group_owner", "tracker")
private const val acceptableCharacters = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!$-#%^"
fun generatePassword(length: Int = 10) = (0..length)
    .map { Random.nextInt(0, acceptableCharacters.length) }
    .map { acceptableCharacters[it] }
    .joinToString("") { "$it" }

fun RenderContext.setNewPassword(close: SimpleHandler<Unit>) {
    val translation by koinCtx.inject<Translation>()
    val selectedGroupMemberStore by koinCtx.inject<SelectedGroupMemberStore>()

    val passwordStore = storeOf("")
    stackUp(
        {
            margins { bottom { small } }
            paddings {
                horizontal { small }
            }
        },
    ) {
        spacing { small }
        items {
            cardTitle(translation[DashboardTexts.SetNewPasswordTitle]) { FormationIcons.Lock.icon }
            inputLabelWrapper(
                title = flowOf("Choose a password"), // TODO translate
                visibilityFlow = passwordStore.data.map { it.isNotBlank() },
            ) {
                genericInput(
                    value = passwordStore.data,
                    minWidth = { "100px" },
                    maxWidth = { "300px" },
                ) {
                    placeholder("secret")
                    inputs.values() handledBy passwordStore.update
                }
            }
        }
    }
    passwordStore.data.render { password ->
        lineUp(
            {
                width { full }
                paddings {
                    horizontal { small }
                }
                wrap { wrap }
            },
        ) {
            spacing { none }
            items {
                pushButton(
                    {
                        primaryButtonStyleParams()
                        margins {
                            vertical { tiny }
                            right { small }
                        }
                    },
                ) {
                    type { primary }
                    text(translation[DashboardTexts.SetNewPasswordButton])
                    enabled(password.isNotBlank())
                    icon { FormationIcons.Lock.icon }
                    events {
                        clicks.map { password } handledBy selectedGroupMemberStore.setNewPassword
                        clicks handledBy close
                    }
                }
                pushButton(
                    {
                        secondaryButtonStyleParams()
                        margins {
                            vertical { tiny }
                            right { small }
                        }
                    },
                ) {
                    type { secondary }
                    text(translation[DashboardTexts.GeneratePasswordButton])
                    icon { FormationIcons.Update.icon }
                    events {
                        clicks.map { generatePassword() } handledBy passwordStore.update
                    }
                }
            }
        }
    }
}

fun RenderContext.setRole() {
    val translation by koinCtx.inject<Translation>()
    val selectedGroupMemberStore by koinCtx.inject<SelectedGroupMemberStore>()
    stackUp(
        {
            margins { bottom { small } }
            paddings {
                horizontal { small }
            }
        },
    ) {
        spacing { small }
        items {
            cardTitle(translation[DashboardTexts.ChangeRoleTitle]) { FormationIcons.PersonChange.icon }
            p { translation[DashboardTexts.ChangeRoleExplainer].renderText(this) }
        }
    }
    selectedGroupMemberStore.data.mapNotNull { it }.render { member ->
        val selectedGroupRoleStore = storeOf(member.roles.first())
        stackUp(
            {
                margins { bottom { small } }
                paddings {
                    horizontal { small }
                }
            },
        ) {
            spacing { small }
            items {
                p {
                    translation[
                        DashboardTexts.ChangeRoleCurrentRole,
                        mapOf(
                            "role" to member.roles.joinToString(","),
                        ),
                    ].renderText(this)
                }
                radioGroup(items = groupRoles, value = selectedGroupRoleStore)
                pushButton(
                    {
                        primaryButtonStyleParams()
                    },
                ) {
                    type { primary }
                    text(translation[DashboardTexts.ChangeRoleButton])
                    icon { FormationIcons.PersonChange.icon }
                    enabled(
                        selectedGroupRoleStore.data.map { selectedRole ->
                            selectedRole != member.roles.firstOrNull()
                        },
                    )
                    events {
                        clicks.map { selectedGroupRoleStore.current } handledBy selectedGroupMemberStore.changeRole
                    }
                }
            }
        }
    }
}
