// GENERATED by fritz2 - NEVER CHANGE CONTENT MANUALLY!
package model

import dev.fritz2.core.Lens
import dev.fritz2.core.lensOf

fun ResetPasswordData.Companion.email(): Lens<ResetPasswordData, String> = lensOf(
    "email",
    { it.email },
    { p, v -> p.copy(email = v) }
)

fun ResetPasswordData.Companion.token(): Lens<ResetPasswordData, String> = lensOf(
    "token",
    { it.token },
    { p, v -> p.copy(token = v) }
)
