package services

import auth.ApiUserStore
import apiclient.FormationClient
import apiclient.geoobjects.ObjectType
import apiclient.geoobjects.SearchQueryContext
import apiclient.geoobjects.keywordsForQueryContext
import dev.fritz2.core.RootStore
import koin.koinCtx
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.mapNotNull
import model.SuggestedTagsContext
import model.getGroupIdsOrNull
import overlays.BusyStore

class SuggestedTagsContextStore : RootStore<SuggestedTagsContext>(
    initialData = SuggestedTagsContext(SearchQueryContext(), ""),
    job = Job(),
) {

    val apiUserStore: ApiUserStore by koinCtx.inject()

    private val updateGroupIds = handle<List<String>> { current, groupIds ->
        current.copy(ctx = current.ctx.copy(groupIds = groupIds))
    }

    val updateTags = handle<List<String>?> { current, tagsList ->
        current.copy(ctx = current.ctx.copy(tags = tagsList))
    }

    val updateObjTypes = handle<List<ObjectType>?> { current, objTypes ->
        current.copy(ctx = current.ctx.copy(objectTypes = objTypes))
    }

    val resetPrefix = handle { current ->
        current.copy(prefix = "")
    }

    val resetCtxTags = handle { current ->
        current.copy(ctx = current.ctx.copy(tags = null))
    }

    init {
        apiUserStore.data.mapNotNull { it.getGroupIdsOrNull() } handledBy updateGroupIds
    }
}

class SuggestedTagsStore : RootStore<List<String>>(
    initialData = emptyList(),
    job = Job(),
) {

    private val suggestedTagsContextStore: SuggestedTagsContextStore by koinCtx.inject()
    private val formationClient: FormationClient by koinCtx.inject()
    private val busyStore: BusyStore by koinCtx.inject()

    private val getSuggestedTags = handle<SuggestedTagsContext> { current, context ->
        if (!context.ctx.groupIds.isNullOrEmpty()) {
            busyStore.handleApiCall(
                supplier = {
                    formationClient.keywordsForQueryContext(searchQueryContext = context.ctx, prefix = context.prefix, numberOfKeywords = context.limit)
                },
                processResult = { keywords ->
                    update(keywords)
                },
                processError = { error ->
                    console.warn("Fetching suggested keywords failed", error.message)
                }
            )
            current
        } else emptyList()
    }

    init {
        suggestedTagsContextStore.data handledBy getSuggestedTags
    }
}
