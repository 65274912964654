package dev.fritz2.components

import dev.fritz2.components.foundations.Component
import dev.fritz2.components.foundations.ComponentProperty
import dev.fritz2.components.foundations.EventMixin
import dev.fritz2.components.foundations.EventProperties
import dev.fritz2.core.HtmlTag
import dev.fritz2.core.RenderContext
import dev.fritz2.core.Tag
import dev.fritz2.styling.StyleClass
import dev.fritz2.styling.params.BoxParams
import dev.fritz2.styling.params.FlexParams
import dev.fritz2.styling.params.ScaledValueProperty
import dev.fritz2.styling.params.Style
import dev.fritz2.styling.staticStyle
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement

fun RenderContext.stackUp(
    styling: FlexParams.() -> Unit = {},
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "stack-up",
    build: StackUpComponent.() -> Unit = {}
): HtmlTag<HTMLDivElement> = StackUpComponent().apply(build).render(this, styling, baseClass, id, prefix)

fun RenderContext.lineUp(
    styling: FlexParams.() -> Unit = {},
    baseClass: StyleClass = StyleClass.None,
    id: String? = null,
    prefix: String = "line-up",
    build: LineUpComponent.() -> Unit = {}
): HtmlTag<HTMLDivElement> = LineUpComponent().apply(build).render(this, styling, baseClass, id, prefix)

abstract class StackComponent : Component<HtmlTag<HTMLDivElement>>, EventProperties<HTMLDivElement> by EventMixin() {
    companion object {
        val staticCss = staticStyle(
            "stack",
            "align-items: flex-start;"
        )
    }

    val reversed = ComponentProperty(false)
    val spacing = ComponentProperty<ScaledValueProperty> { normal }
    val items = ComponentProperty<(Tag<HTMLElement>.() -> Unit)> {}

    abstract val stackStyles: Style<FlexParams>

    override fun render(
        context: RenderContext,
        styling: BoxParams.() -> Unit,
        baseClass: StyleClass,
        id: String?,
        prefix: String
    ) = context.flexBox({
        this@StackComponent.stackStyles()
        styling(this as BoxParams)
    }, baseClass = baseClass + staticCss, prefix = prefix, id = id) {
        this@StackComponent.items.value(this)
        this@StackComponent.events.value(this)
    }
}

/**
 * This component class just defines the core styling in order to render child items within a flexBox layout
 * vertically.
 *
 * @see StackComponent
 */
class StackUpComponent : StackComponent() {
    override val stackStyles: Style<FlexParams> = {
        if (this@StackUpComponent.reversed.value) {
            direction { columnReverse }
            children(" > :not(:first-child)") {
                margins { bottom(this@StackUpComponent.spacing.value) }
            }
        } else {
            direction { column }
            children(" > :not(:first-child)") {
                margins { top(this@StackUpComponent.spacing.value) }
            }
        }
    }
}

/**
 * This component class just defines the core styling in order to render child items within a flexBox layout
 * horizontally.
 *
 * @see StackComponent
 */
class LineUpComponent : StackComponent() {
    override val stackStyles: Style<FlexParams> = {
        if (this@LineUpComponent.reversed.value) {
            direction { rowReverse }
            children(" > :not(:first-child)") {
                margins { right(this@LineUpComponent.spacing.value) }
            }
        } else {
            direction { row }
            children(" > :not(:first-child)") {
                margins { left(this@LineUpComponent.spacing.value) }
            }
        }
    }
}
