package search.searchFilterStores

import analytics.AnalyticsCategory
import analytics.AnalyticsService
import dev.fritz2.core.RootStore
import koin.koinCtx
import kotlinx.coroutines.Job
import model.SuggestedTagsContext
import model.prefix
import services.SuggestedTagsContextStore

abstract class ActiveKeywordsStore : RootStore<List<String>>(
    initialData = emptyList(),
    job = Job(),
) {

    private val analyticsService: AnalyticsService by koinCtx.inject()
    private val suggestedTagsContextStore: SuggestedTagsContextStore by koinCtx.inject()
    private val prefixStore = suggestedTagsContextStore.map(SuggestedTagsContext.prefix())

    val addKeywordFromInput = handle { current ->
        val prefix = prefixStore.current
        if (prefix.isNotBlank()) {
            prefixStore.update("")
            (current + prefix).distinct()
        } else current
    }

    val add = handle<String> { current, newKeyword ->
        if (newKeyword.isNotBlank() && newKeyword !in current) {
            analyticsService.createEvent(AnalyticsCategory.AddKeyWord) {
                click(newKeyword)
            }
            (current + newKeyword).distinct()
        } else current
    }

    val remove = handle<String> { current, keyWord ->
        if (current.isNotEmpty() && keyWord in current) (current - keyWord).distinct()
        else current
    }

    val reset = handle {
        emptyList()
    }
}
