package data.objects.views.directediting

import apiclient.FormationClient
import apiclient.geoobjects.Content
import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.attachImageToGeoObject
import auth.ApiUserStore
import camera.cameraWrapper.cameraModal
import camera.photo.photoCamera
import com.tryformation.localization.Translatable
import data.objects.ActiveObjectStore
import data.objects.views.attachments.FileHandlerStore
import data.objects.views.attachments.FileStoreFritz2
import data.objects.views.attachments.FileStoreJS
import data.objects.views.attachments.ImageFileDataStore
import data.objects.views.attachments.imagePrevData
import dev.fritz2.core.RenderContext
import dev.fritz2.core.SimpleHandler
import dev.fritz2.core.accept
import dev.fritz2.core.alt
import dev.fritz2.core.disabled
import dev.fritz2.core.files
import dev.fritz2.core.id
import dev.fritz2.core.placeholder
import dev.fritz2.core.src
import dev.fritz2.core.storeOf
import dev.fritz2.core.type
import koin.koinCtx
import koin.withKoin
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.debounce
import kotlinx.coroutines.flow.map
import localization.TL
import localization.Translation
import localization.translate
import model.ImageFileData
import model.L
import model.getFirstGroupIdOrNull
import model.title
import org.w3c.files.get
import overlays.BusyStore
import theme.FormationIcons
import theme.FormationUIIcons
import twcomponents.twColOf
import twcomponents.twInputField
import twcomponents.twInputTextField
import twcomponents.twMediumIconButtonNeutral
import twcomponents.twRowOfJustifyBetween
import twcomponents.twSecondaryButtonSmall
import utils.insertObjectInCachesAndMap
import utils.isMobileOrTabletBrowser
import utils.roundTo
import webcomponents.inputLabelWrapper

enum class ImageEditor : Translatable {
    ReplaceWith,
    ;

    override val prefix = "image-editor"
}

fun RenderContext.imageEditor(
    objectId: String,
    content: Content.Image? = null,
    editorCloseHandler: SimpleHandler<Unit>? = null,
    header: Translatable? = null,
    isCreateNew: Boolean = false,
) {
    val translation: Translation by koinCtx.inject()
    val fileStoreJS: FileStoreJS by koinCtx.inject()
    val fileStoreFritz2: FileStoreFritz2 by koinCtx.inject()
    val fileHandlerStore: FileHandlerStore by koinCtx.inject()
    val imageFileDataStore by koinCtx.inject<ImageFileDataStore>()
    val title = imageFileDataStore.map(ImageFileData.title())

    fileHandlerStore.initialize()
    imageFileDataStore.reset(Unit)
    content?.title?.let { title.update(it) }

    twColOf {
        contentCreateHeader(if (isCreateNew) editorCloseHandler else null, header) {
            twMediumIconButtonNeutral(icon = FormationUIIcons.Save) {
                if (content == null) {
                    combine(fileStoreJS.data, fileStoreFritz2.data) { f1, f2 ->
                        f1 == null && f2 == null
                    }.render { noFile ->
                        disabled(noFile)
                    }
                }

                clicks handledBy {
                    editorCloseHandler?.let { closeEditor ->
                        closeEditor(Unit)
                    }
                    attachOrReplaceImage(objectId, content?.id, imageFileDataStore.current)
                    imageFileDataStore.reset(Unit)
                }
            }
        }

        inputLabelWrapper(
            title = translation[TL.Attachments.IMAGE_TITLE_PLACEHOLDER],
            visibilityFlow = title.data.map { it.isNotBlank() },
        ) {
            twInputField(title) {
                twInputTextField {
                    id("attachedImageTitleInput")
                    placeholder(translation[TL.Attachments.IMAGE_TITLE_PLACEHOLDER])
                }
            }
        }

        // IF IN IMAGE EDIT MODE, DISPLAY ORIGINAL IMAGE, WHICH WILL BE REPLACED BY NEW IMAGE
        if (content != null) {
            img("w-max max-w-full object-scale-down rounded-xl") {
                src(content.href)
                alt(content.title ?: "image ${content.width}x${content.height}")
            }
        }

        // PREVIEW THE TAKEN OR UPLOADED PICTURE
        imageFileDataStore.data.debounce(500).render { fileData ->
            fileStoreFritz2.data.combine(fileStoreJS.data) { f, j -> Pair(f, j) }
                .render { (fileFritz2, fileJS) ->
                    if (fileFritz2 != null || fileJS != null) {
                        val fileName = fileFritz2?.name ?: fileJS?.name ?: ""
                        val fileSize = fileFritz2?.size ?: fileJS?.size ?: 0
                        val fileType = fileFritz2?.type ?: fileJS?.type ?: ""

                        val size = fileSize.toDouble() / 1024 / 1024
                        if (fileData.href != null) {
                            if (content != null) {
                                p { translate(ImageEditor.ReplaceWith) }
                            }
                            img("w-max max-w-full mt-2 object-scale-down rounded-xl") {
                                src(fileData.href)
                            }
                            div("flex flex-row flex-wrap w-full items-center justify-start") {
                                imagePrevData(value = fileName)
                                imagePrevData(value = fileType.removePrefix("image/"))
                                imagePrevData(value = "${fileData.width} x ${fileData.height} px")
                                imagePrevData(
                                    value = if (size < 1.0) {
                                        "${(size * 1024).roundTo(0)} KB"
                                    } else {
                                        "${size.roundTo(2)} MB"
                                    },
                                )
                            }
                        }
                    } else if (fileData.href != null) {
                        val fileSize = (fileData.prevBytes?.size?.toDouble() ?: 0.0) / 1024 / 1024
                        img("max-w-96 rounded-xl") {
                            src(fileData.href)
                        }
                        div("flex flex-row flex-wrap max-w-full md:max-w-96 items-center justify-start") {
                            if (fileData.prevName.isNotBlank()) {
                                imagePrevData(value = fileData.prevName)
                            }
                            imagePrevData(value = fileData.mimeType?.removePrefix("image/") ?: "")
                            imagePrevData(value = "${fileData.width} x ${fileData.height} px")
                            if (fileSize > 0.0) {
                                imagePrevData(
                                    value = if (fileSize < 1.0) {
                                        "${(fileSize * 1024).roundTo(0)} KB"
                                    } else {
                                        "${fileSize.roundTo(2)} MB"
                                    },
                                )
                            }
                        }
                    }
                }
        }

        twRowOfJustifyBetween {
            // TAKE PHOTO BUTTON FOR MOBILE BROWSER ONLY - Starts devices camera directly (input attr > capture)
            if (isMobileOrTabletBrowser()) {
                twSecondaryButtonSmall(
                    text = TL.Attachments.TAKE_A_PHOTO,
                    icon = FormationIcons.CameraPlus,
                ) {
                    className("relative")
                    input("absolute top-0 bottom-0 left-0 right-0 pl-[100%] opacity-0 m-0 cursor-pointer") {
                        type("file")
                        accept("image/png, image/gif, image/jpeg")
                        attr("capture", "environment")
                        changes.files().map { files ->
                            files?.get(0)?.let { file ->
                                console.log("image picked:", file)
                                file
                            }
                        } handledBy fileStoreJS.update
                    }
                }
            } else {
                // TAKE PHOTO BUTTON FOR DESKTOP  - Opens camera modal
                val toggleCamera = storeOf(false, Job())
                cameraModal(toggleCamera) { close, opened, video, canvas ->
                    photoCamera(close, opened, video, canvas)
                }

                twSecondaryButtonSmall(
                    text = TL.Attachments.TAKE_A_PHOTO,
                    icon = FormationIcons.CameraPlus,
                ) {
                    clicks handledBy {
                        toggleCamera.update(true)
                    }
                }
            }

            // UPLOAD IMAGE BUTTON
            twSecondaryButtonSmall(
                text = TL.Attachments.UPLOAD_IMAGE,
                icon = FormationIcons.Upload,
            ) {
                className("relative overflow-hidden")
                input("absolute top-0 bottom-0 left-0 right-0 pl-[100%] opacity-0 m-0 cursor-pointer") {
                    type("file")
                    accept("image/png, image/gif, image/jpeg")
                    changes.files().map { files ->
                        files?.get(0)?.let { file ->
                            console.log("image picked:", file)
                            file
                        }
                    } handledBy fileStoreJS.update
                }
            }
        }
    }
}

suspend fun attachOrReplaceImage(objectId: String, replaceImgId: String? = null, imageData: ImageFileData) {
    withKoin {
        val apiUserStore: ApiUserStore by koinCtx.inject()
        val client = get<FormationClient>()
        val busyStore = get<BusyStore>()
        val activeObjectStore = get<ActiveObjectStore>()
        val groupId = apiUserStore.current.getFirstGroupIdOrNull()
        val attachments = activeObjectStore.map(GeoObjectDetails.L.attachments)
        groupId?.let { gId ->
            imageData.prevBytes?.let { bytes ->
                busyStore.withBusy(
                    block = suspend {
                        client.attachImageToGeoObject(
                            ownerId = gId,
                            objectId = objectId,
                            title = imageData.title,
                            imageBytes = bytes,
                            attachmentId = replaceImgId,
                        )
                    },
                    processResult = { geoObject ->
                        geoObject.attachments?.let { attachmentList ->
                            attachments.update(attachmentList)
                        }
                        insertObjectInCachesAndMap(geoObject)
                    },
                    processError = { error ->
                        console.log(error)
                    },
                )
            }
        }
    }
}
