package map

import apiclient.geoobjects.GeoObjectDetails
import apiclient.search.ObjectSearchResults
import dev.fritz2.core.RootStore
import dev.fritz2.core.SimpleHandler
import koin.koinCtx
import kotlinx.coroutines.Job
import kotlinx.datetime.Instant
import maplibreGL.MaplibreMap
import model.LayerType
import model.MapLayer
import model.MapLayers
import model.toSearchResult

class MapLayersStore : RootStore<MapLayers>(
    initialData = MapLayers(layers = initialMapLayerSet),
    job = Job(),
) {
    private val maplibreMap: MaplibreMap by koinCtx.inject()

    companion object {
        val initialMapLayerSet = listOf(
            MapLayer(id = LayerType.MapSearchResults, enabled = true, title = "Map Search Results", results = null),
            MapLayer(id = LayerType.GlobalSearchResults, enabled = true, title = "Global Search Results", results = null),
            MapLayer(id = LayerType.HubSearchResults, enabled = true, title = "Hub Search Results", results = null),
            MapLayer(id = LayerType.MarkerClientUsers, enabled = true, title = "MarkerClient Markers", results = null),
            MapLayer(id = LayerType.MarkerClientObjects, enabled = true, title = "MarkerClient Objects", results = null),
            MapLayer(id = LayerType.CurrentFloor, enabled = true, title = "Current Floor", results = null, clustered = false),
            MapLayer(id = LayerType.CurrentUnits, enabled = true, title = "Current Units", results = null, clustered = false),
            MapLayer(id = LayerType.FloorResults, enabled = true, title = "Search Results for this floor", results = null),
            MapLayer(id = LayerType.ActiveObjectOrUserCopy, enabled = true, title = "Active object", results = null, clustered = false),
            MapLayer(id = LayerType.HistoryPath, enabled = false, title = "Object history", results = null, clustered = false),
        )
    }

    // use var latest to mirror the current store value to handle almost simultaneous updates of the store
    var latest: MapLayers = MapLayers(layers = initialMapLayerSet)

    fun setResults(layerId: LayerType, results: ObjectSearchResults?) {
        val layers = latest.layers.map { layer ->
            if (layer.id == layerId) {
                val oldLayerResults = layer.results?.hits?.associateBy { it.hit.id }
                val mergedHits = results?.hits?.map { result ->
                    val old = oldLayerResults?.get(result.hit.id)
                    if (old != null && Instant.parse(result.hit.updatedAt) < Instant.parse(old.hit.updatedAt)) {
                        old
                    } else {
                        result
                    }
                }
                layer.copy(results = results?.copy(hits = mergedHits ?: listOf()))
            } else {
                layer
            }
        }
        maplibreMap.geoObjectDetailsSetData(layersToGeoObjectList(layers, clustered = true), clustered = true)
        maplibreMap.geoObjectDetailsSetData(layersToGeoObjectList(layers, clustered = false), clustered = false)

        maplibreMap.syncMarkersNow()
        latest = MapLayers(layers = layers)
        update(latest)
    }

    fun setResults(layerMap: Map<LayerType, ObjectSearchResults?>) {
        val layers = latest.layers.map { layer ->
            if (layerMap.keys.contains(layer.id)) {
//                console.log("SET RESULTS (${layer.id.name})", layerMap[layer.id]?.hits?.map { it.hit.title }.toString())
                val oldLayerResults = layer.results?.hits?.associateBy { it.hit.id }
                val new = layerMap[layer.id]
                val mergedHits = new?.hits?.map { result ->
                    val old = oldLayerResults?.get(result.hit.id)
                    if (old != null && Instant.parse(result.hit.updatedAt) < Instant.parse(old.hit.updatedAt)) {
                        old
                    } else {
                        result
                    }
                }
                layer.copy(results = new?.copy(hits = mergedHits ?: listOf()))
            } else layer
        }
        maplibreMap.geoObjectDetailsSetData(layersToGeoObjectList(layers, clustered = true), clustered = true)
        maplibreMap.geoObjectDetailsSetData(layersToGeoObjectList(layers, clustered = false), clustered = false)

        maplibreMap.syncMarkersNow(
            updateGeoJSON = true,
            updateImageLayers = true,
            trigger = "setResults",
        )

        latest = MapLayers(layers = layers)
        update(latest)
    }

    fun insertHit(layerId: LayerType, geoObject: GeoObjectDetails) {
        val layers = latest.layers.map { layer ->
            if (layer.id == layerId) {
//                console.log("Insert new object in ${layerId.name}", geoObject)
                layer.copy(
                    results = layer.copy().results?.copy(
                        hits = layer.copy().results?.hits?.plus(geoObject.toSearchResult()) ?: emptyList(),
                    ),
                )
            } else {
                layer
            }
        }

        when (latest.layers.firstOrNull { layer -> layer.id == layerId }?.clustered) {
            true -> maplibreMap.geoObjectAddOrUpdate(geoObject, clustered = true)
            false -> maplibreMap.geoObjectAddOrUpdate(geoObject, clustered = false)
            else -> {}
        }
        maplibreMap.syncMarkersNow()
        latest = MapLayers(layers = layers)
        update(latest)
    }

    fun setUpdatedHit(geoObject: GeoObjectDetails) {
        var updateClustered = false
        var updateUnClustered = false

        val updatedLayers = latest.layers.map { mapLayer ->
            val updatedResults = mapLayer.results?.let { results ->
//                console.log("updating ${results.hits.size} objects in layer ${mapLayer.title}")
                results.hits.map { result ->
                    if (result.hit.id == geoObject.id) {
                        if (mapLayer.clustered) {
                            updateClustered = true
                        }
                        if (!mapLayer.clustered) {
                            updateUnClustered = true
                        }
                        result.copy(hit = geoObject)
                    } else {
                        result
                    }
                }.let {
                    results.copy(hits = it)
                }
            }
            mapLayer.copy(results = updatedResults)
        }
        if (updateClustered) {
            maplibreMap.geoObjectAddOrUpdate(geoObject, clustered = true)
        }
        if (updateUnClustered) {
            maplibreMap.geoObjectAddOrUpdate(geoObject, clustered = false)
        }
        // if object not found in any layer -> insert it
        if (!updateClustered && !updateUnClustered) {
            insertHit(
                layerId = LayerType.MapSearchResults,
                geoObject,
            )
        }
        latest = MapLayers(layers = updatedLayers)
        update(latest)
    }

    fun deleteHit(geoObjectId: String) {
//        recentObjectMutationsStore.remove(geoObjectId)
        var deleteClustered = false
        var deleteUnClustered = false
        val updatedLayers = latest.layers.map { mapLayer ->
            val updatedResults = mapLayer.results?.let { results ->
                results.hits.filter { result ->
                    if (result.hit.id != geoObjectId) {
                        true
                    } else {
                        if (mapLayer.clustered) {
                            deleteClustered = true
                        }
                        if (!mapLayer.clustered) {
                            deleteUnClustered = true
                        }
                        console.log("delete object \"$geoObjectId\" from ${mapLayer.id.name}")
                        false
                    }
                }.let {
                    results.copy(hits = it)
                }
            }
            mapLayer.copy(results = updatedResults)
        }
        if (deleteClustered) {
            maplibreMap.geoObjectDetailsRemove(geoObjectId, clustered = true)
        }
        if (deleteUnClustered) {
            maplibreMap.geoObjectDetailsRemove(geoObjectId, clustered = false)
        }
        latest = MapLayers(layers = updatedLayers)
        update(latest)
    }

    val flipLayer = SimpleHandler<Map<LayerType, Boolean?>> { data, _ ->
        data handledBy { layerMap ->
            val layers = latest.layers.map { layer ->
                if (layerMap.keys.contains(layer.id)) {
                    if (layer.enabled != layerMap[layer.id]) {
                        val newState = layerMap[layer.id] ?: !layer.enabled
                        console.log("flipped layer:", layer.id.name, "to", newState)//, layer.results?.hits?.map { it.hit.title }.toString())
                        layer.copy(enabled = newState)
                    } else layer
                } else layer
            }
            maplibreMap.geoObjectDetailsSetData(layersToGeoObjectList(layers, clustered = true), clustered = true)
            maplibreMap.geoObjectDetailsSetData(layersToGeoObjectList(layers, clustered = false), clustered = false)

            latest = MapLayers(layers = layers)
            update(latest)
        }
    }

    val flipFloorLayer = handle<Boolean> { _, flip ->
        val layers = latest.layers.map { layer ->
            if (layer.id == LayerType.FloorResults && layer.enabled != flip) {
                console.log("floorLayer active:", flip)
                layer.copy(enabled = flip)
            } else {
                layer
            }
        }
        maplibreMap.geoObjectDetailsSetData(layersToGeoObjectList(layers, clustered = true), clustered = true)
        maplibreMap.geoObjectDetailsSetData(layersToGeoObjectList(layers, clustered = false), clustered = false)

//        maplibreMap.syncMaplibreMarkersNow()

        MapLayers(layers = layers)
    }

    fun isActive(layer: LayerType): Boolean {
        return current.layers.firstOrNull { it.id == layer }?.enabled ?: false
    }

    val reset = handle {
        console.log("Reset MapLayersStore")
        maplibreMap.geoObjectDetailsSetData(layersToGeoObjectList(initialMapLayerSet, clustered = true), clustered = true)
        maplibreMap.geoObjectDetailsSetData(layersToGeoObjectList(initialMapLayerSet, clustered = false), clustered = false)
//        maplibreMap.syncMaplibreMarkersNow()
        latest = MapLayers(layers = initialMapLayerSet)
        MapLayers(layers = initialMapLayerSet)
    }

    private fun layersToGeoObjectList(layers: List<MapLayer>, clustered: Boolean): Set<GeoObjectDetails> {
        return layers.filter { it.enabled && it.clustered == clustered }.flatMap { it.results?.hits.orEmpty() }.map { it.hit }.toSet()
    }
}
