package search.searchFilterStores

import apiclient.customfields.FieldValue
import data.objects.CurrentActiveFieldValueStore
import dev.fritz2.core.RootStore
import koin.koinCtx
import kotlinx.coroutines.Job

open class ActiveFieldValuesStore : RootStore<List<FieldValue>>(
    initialData = emptyList(),
    job = Job(),
) {

    private val currentActiveFieldValueStore: CurrentActiveFieldValueStore by koinCtx.inject()

    val add = handle<FieldValue?> { current, newFieldValue ->
        if (newFieldValue != null) {
            if (newFieldValue.field !in current.map { it.field }) {
                current + newFieldValue
            } else {
                current.filterNot { it.field == newFieldValue.field } + newFieldValue
            }
        } else current
    }

    val remove = handle<FieldValue?> { current, newFieldValue ->
        if (newFieldValue != null && newFieldValue.field in current.map { it.field }) {
            (current - newFieldValue).distinct()
        } else current
    }

    val addOrChangeFieldValue = handle { current ->
        val currentFieldValue = currentActiveFieldValueStore.current
        if (currentFieldValue != null) {
            if (currentFieldValue.field in current.map { it.field }) {
                current.map { fieldValue ->
                    if (fieldValue.field == currentFieldValue.field) {
                        when {
                            fieldValue is FieldValue.CategoryValue
                                    && currentFieldValue is FieldValue.CategoryValue -> {
                                fieldValue.copy(value = currentFieldValue.value)
                            }

                            fieldValue is FieldValue.DoubleValue
                                    && currentFieldValue is FieldValue.DoubleValue -> {
                                fieldValue.copy(value = currentFieldValue.value)
                            }

                            fieldValue is FieldValue.EmptyValue
                                    && currentFieldValue is FieldValue.EmptyValue -> {
                                fieldValue
                            }

                            fieldValue is FieldValue.InstantValue
                                    && currentFieldValue is FieldValue.InstantValue -> {
                                fieldValue.copy(value = currentFieldValue.value)
                            }

                            fieldValue is FieldValue.LongValue
                                    && currentFieldValue is FieldValue.LongValue -> {
                                fieldValue.copy(value = currentFieldValue.value)
                            }

                            fieldValue is FieldValue.StringValue
                                    && currentFieldValue is FieldValue.StringValue -> {
                                fieldValue.copy(value = currentFieldValue.value)
                            }

                            else -> fieldValue
                        }
                    } else fieldValue
                }.distinct()
            } else current + currentFieldValue
        } else current
    }

    val reset = handle {
        console.log("Reset ${this::class.simpleName}")
        emptyList()
    }

//    private val print = handle<List<FieldValue>> { _, fieldValues ->
//        console.log("${this::class.simpleName} -> ", fieldValues.map { it.tag })
//        fieldValues
//    }
//
//    init {
//        data handledBy print
//    }
}
