package data.objects.views

import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.ObjectTags
import apiclient.geoobjects.ObjectType
import apiclient.tags.getUniqueTag
import apiclient.validations.parseEnumValue
import data.objects.ActiveObjectStore
import dev.fritz2.components.compat.div
import dev.fritz2.components.flexBox
import dev.fritz2.core.RenderContext
import koin.koinCtx
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import localization.TL
import localization.Translation
import mainmenu.RouterStore
import map.MapStateStore
import model.CodeTech
import model.L
import qrcode.ScannedCodeStore
import styling.primaryButtonStyleParams
import styling.secondaryButtonStyleParams
import utils.getIcon
import utils.getName
import webcomponents.baseLayout
import webcomponents.cardSubtitle
import webcomponents.cardTitle
import webcomponents.contentScrollBox
import webcomponents.twoButtonFooter

fun RenderContext.cardEditPosition(type: ObjectType) {
    val translation: Translation by koinCtx.inject()
    val mapStateStore: MapStateStore by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()
    val scannedCodeStore: ScannedCodeStore by koinCtx.inject()
    val activeObjectStore: ActiveObjectStore by koinCtx.inject()
    val latLon = activeObjectStore.map(GeoObjectDetails.L.latLon)
    val keywords = activeObjectStore.map(GeoObjectDetails.L.keywords)
    val externalId = activeObjectStore.map(GeoObjectDetails.L.tags).data.map { it.getUniqueTag(ObjectTags.ExternalId) }
    val externalCodeTech = keywords.data.map { parseEnumValue<CodeTech>(it?.firstOrNull { keyword -> keyword in CodeTech.values().map { type -> type.name } }) }

    baseLayout(
        header = {
            flexBox({
                direction { row }
                justifyContent { spaceBetween }
                alignItems { stretch }
                width { full }
                height { auto }
            }) {
                // CARD TITLE
                cardTitle(translation[TL.CardEdit.EDIT_EVENT, mapOf("objectType" to type.getName())])
            }

            externalId.render { extId ->
                if (type == ObjectType.ObjectMarker && extId != null) {
                    div({
                        margins { bottom { small } }
                    }) {
                        cardSubtitle(flowOf(extId), externalCodeTech.map { it?.getIcon() })
                    }
                }
            }
        },
        content = {
            contentScrollBox {
                flexBox({ flex { grow { "1" } } }) { }
                cardSubtitle(translation[TL.CardEditPosition.EDIT_POSITION_INSTRUCTIONS])
                flexBox({ flex { grow { "1" } } }) { }
            }
        },
        footer = {
            // CARD BUTTONS FOOTER
            twoButtonFooter(
                secondaryTitle = translation[TL.General.CANCEL],
                secondaryStyleParams = secondaryButtonStyleParams,
                secondaryClickHandlers = listOf(activeObjectStore.revertChanges, routerStore.back),
                primaryTitle = translation[TL.General.UPDATE],
                primaryState = mapStateStore.data.map { it?.center != latLon.current },
                primaryStyleParams = primaryButtonStyleParams,
                primaryValue = Unit,
                primaryClickHandlers = listOf(activeObjectStore.edit, scannedCodeStore.reset, routerStore.goToMap),
            )
        }
    )
}
