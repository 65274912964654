package map

import auth.ApiUserStore
import apiclient.auth.Token
import apiclient.groups.Group
import apiclient.groups.MapLayerUserSettings
import dev.fritz2.core.RootStore
import io.ktor.http.URLBuilder
import io.ktor.http.URLProtocol
import io.ktor.http.encodedPath
import koin.koinCtx
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import kotlinx.serialization.json.JsonObject
import login.HostConfigStore
import maplibreGL.MaplibreMap
import maplibreGL.TileLayerData
import maplibreGL.TileSourceData
import model.NotificationType
import model.Overlay
import overlays.AlertOverlayStore
import theme.FormationColors
import utils.makeRGB
import utils.makeRGBA
import utils.rgbaToHexColor
import kotlin.js.json

class MaplibreTileLayersStore : RootStore<Map<String, TileLayerData>>(
    initialData = emptyMap(),
    job = Job(),
) {
    val maplibreMap by koinCtx.inject<MaplibreMap>()
    val apiUserStore by koinCtx.inject<ApiUserStore>()
    val alertOverlayStore by koinCtx.inject<AlertOverlayStore>()
    private val mapLayerUserSettingsStore by koinCtx.inject<MapLayerUserSettingsStore>()

    val initializeWithWorkspaceHeatmaps = handle<List<Group>> { current, groups ->
        console.log("Fetch HeatmapLayerDefinitions from Group(s): ${groups.map { it.name }}")
        val heatmapLayerDefinitions = groups.flatMap { group ->
            group.heatmapLayerDefinitions ?: emptyList()
        }
        val tileLayers = heatmapLayerDefinitions.associate {
            val layerSource = createTileSource(it.layerId)
            val tileLayer = createTileLayer(sourceData = layerSource, style = it.style)
            tileLayer.id to tileLayer
        }
        if (!apiUserStore.current.isAnonymous) {
            console.log("Create Tile layers from HeatmapLayerDefinitions: ${tileLayers.values.map { it.source.id }}")
        }
        current + tileLayers
    }

    private val updateTileLayersOnMap = handle<List<MapLayerUserSettings>> { current, mapLayerUserSettingsList ->
        val settingsMap = mapLayerUserSettingsList.associate { it.layerId to it.getStatus() }
        val newTileLayerStates = current.values.associateWith { settingsMap[it.source.id] ?: false }
        val resultMap = maplibreMap.updateTileLayerStates(newTileLayerStates)
        resultMap.map { (tileLayer, state) ->
            if (!state) {
                console.log("Maplibre tile layer ${tileLayer.styleLayerSpecification["id"]} could not be flipped.")
                alertOverlayStore.show(
                    Overlay.NotificationToast(
                        notificationType = NotificationType.Alert,
                        durationSeconds = 3,
                        text = flowOf("Maplibre tile layer ${tileLayer.styleLayerSpecification["id"]} could not be flipped."),
                        bgColor = FormationColors.RedError.color
                    )
                )
            }
        }
        current
    }

    private val updateAccessToken = handle<Token?> { current, accessToken ->
        maplibreMap.updateTileLayersSourceToken(accessToken?.token)
        current
    }

    init {
        mapLayerUserSettingsStore.data handledBy updateTileLayersOnMap
        apiUserStore.data.map { it.apiUser?.apiAccessToken } handledBy updateAccessToken
    }
}

fun createTileSource(layerId: String): TileSourceData {
    val apiUserStore by koinCtx.inject<ApiUserStore>()
    val hostConfigStore by koinCtx.inject<HostConfigStore>()
    val groupId = apiUserStore.current.apiUser?.groups?.firstOrNull()?.groupId
    val currentConfig = hostConfigStore.current
    return if (groupId != null) TileSourceData(
        id = layerId,
        url = URLBuilder().apply {
            protocol = if (currentConfig.ssl) URLProtocol.HTTPS else URLProtocol.HTTP
            host = currentConfig.host
            port = currentConfig.port
            encodedPath = "/mvt/$groupId/$layerId/{z}/{x}/{y}.mvt"
        }.buildString()
    ) else {
        error("groupId is null")
    }
}

val defaultHeatmapStyle = json(
//    "heatmap-opacity" to 0.75,
    "heatmap-opacity" to arrayOf(
        "interpolate",
        arrayOf("linear"),
        arrayOf("zoom"),
        7, 1,
        24, 0.3
    ),
    "heatmap-weight" to arrayOf(
        "interpolate",
        arrayOf("linear"),
        arrayOf("zoom"),
        0, 1,
        22, 0.05,
    ),
    // Increase the heatmap color weight by zoom level
    // heatmap-intensity is a multiplier on top of heatmap-weight
    "heatmap-intensity" to arrayOf(
        "interpolate",
        arrayOf("linear"),
        arrayOf("zoom"),
        0, 6,
        7, 5,
        12, 4,
        16, 3,
        22, 2,
        24, 1,
    ),
    "heatmap-color" to arrayOf(
        "interpolate",
        arrayOf("linear"),
        arrayOf("heatmap-density"),
        0,
        "rgba(33,102,172,0)",
        0.2,
        "rgb(103,169,207)",
        0.4,
        "rgb(209,229,240)",
        0.6,
        "rgb(253,219,199)",
        0.8,
        "rgb(239,138,98)",
        1,
        "rgb(178,24,43)"
    ),
    // Adjust the heatmap radius by zoom level
//    "heatmap-radius" to arrayOf(
//        "interpolate",
//        arrayOf("linear"),
//        arrayOf("zoom"),
//        0, 2,
//        24, 20
//    ),
)

fun getColorPalette(): List<String> {
    val colors = defaultHeatmapStyle["heatmap-color"] as Array<*>
    return listOf(
        rgbaToHexColor(colors[4].toString()),
        rgbaToHexColor(colors[6].toString()),
        rgbaToHexColor(colors[8].toString()),
        rgbaToHexColor(colors[10].toString()),
        rgbaToHexColor(colors[12].toString()),
        rgbaToHexColor(colors[14].toString()),
    )
}

fun setColorPalette(colors: List<String>): Array<*> {
    return arrayOf(
        "interpolate",
        arrayOf("linear"),
        arrayOf("heatmap-density"),
        0,
        makeRGBA(colors[0], 0.0),
        0.2,
        makeRGB(colors[1]),
        0.4,
        makeRGB(colors[2]),
        0.6,
        makeRGB(colors[3]),
        0.8,
        makeRGB(colors[4]),
        1,
        makeRGB(colors[5])
    )
}

fun createTileLayer(sourceData: TileSourceData, style: JsonObject?): TileLayerData {
    return TileLayerData(
        source = sourceData,
//        styleLayer = MaplibreStyleLayer(
//            id = "${sourceData.id}-layer",
//            type = "heatmap",
//            source = sourceData.id,
//            sourceId = sourceData.id,
//            sourceLayer = "hits",
//            paint = style?: defaultHeatmapStyle
//        ),
        id = "${sourceData.id}-layer",
        styleLayerSpecification = json(
            "id" to "${sourceData.id}-layer",
            "type" to "heatmap",
            "sourceId" to sourceData.id,
            "source" to sourceData.id,
            "source-layer" to "hits",
            "paint" to (style ?: defaultHeatmapStyle),
        )
    )
}
