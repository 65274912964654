package data.objects

import auth.CurrentWorkspaceStore
import apiclient.customfields.CategoryNamespace
import apiclient.groups.Group
import dev.fritz2.core.RootStore
import koin.koinCtx
import kotlinx.coroutines.Job

class WorkspaceCategoryNamespacesStore : RootStore<List<CategoryNamespace>?>(
    initialData = null,
    job = Job(),
) {

    private val currentWorkspaceStore: CurrentWorkspaceStore by koinCtx.inject()

    private val getGroupCategories = handle<Group?> { _, group ->
        group?.categoryNamespaces
    }

    init {
        currentWorkspaceStore.data handledBy getGroupCategories
    }
}
