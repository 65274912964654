package mainmenu

import apiclient.geoobjects.CodeLookupResult
import apiclient.geoobjects.MarkerColor
import apiclient.geoobjects.MarkerIcon
import apiclient.geoobjects.MarkerShape
import apiclient.geoobjects.ObjectTags
import apiclient.geoobjects.lookupCodeAnonymously
import apiclient.groups.getGroupNameByExternalCode
import apiclient.tags.getUniqueTag
import apiclient.validations.parseEnumValue
import auth.ApiUserStore
import auth.CurrentWorkspaceStore
import data.ObjectAndUserHandler
import data.keywordlayer.ActiveKeywordLayerDefinitionStore
import data.objects.ActiveObjectStore
import data.users.ActiveUserStore
import dev.fritz2.components.compat.history
import dev.fritz2.core.RootStore
import dev.fritz2.core.SimpleHandler
import dev.fritz2.core.invoke
import dev.fritz2.routing.MapRouter
import koin.koinCtx
import kotlin.math.min
import kotlinx.coroutines.Job
import login.ChangeWorkspaceStore
import login.CredentialsStore
import login.WorkspaceInputStore
import map.Cards
import maplibreGL.MaplibreMap
import model.AppPhase
import model.AppState
import model.CodeTech
import model.Credentials
import model.OperationType
import model.ScanPurpose
import model.ScannedCode
import model.email
import overlays.BusyStore
import qrcode.ScannedCodeStore
import routing.MainController
import workspacetools.usermanagement.urlEncode


class RouterStore(val router: MapRouter) : RootStore<Map<String, String>>(
    initialData = router.current,
    job = Job(),
) {

    // router variables
    private val appStateStore by koinCtx.inject<AppStateStore>()
    private val currentWorkspaceStore by koinCtx.inject<CurrentWorkspaceStore>()

    // controlled by the router
    private val activeKeywordLayerDefinitionStore: ActiveKeywordLayerDefinitionStore by koinCtx.inject()
    private val maplibreMap by koinCtx.inject<MaplibreMap>()

    var latest: Map<String, String> = router.current

    var redirectRoute: Map<String, String>? = null
    var preLoginRedirectRoute: Map<String, String>? = null

    fun resetPreLoginRedirect() {
        preLoginRedirectRoute = null
//        console.log("RESET REDIRECT ->", preLoginRedirectRoute)
    }

    private fun mapRoute() = currentWorkspaceStore.current?.let { workspace ->
        Pages.Map.route.toMutableMap() + mapOf("ws" to workspace.name)
    } ?: Pages.Map.route

    fun baseRoute(): Map<String, String> {
        val ret = mutableMapOf<String, String>()
        current["page"]?.let { page -> ret["page"] = page }
        current["ws"]?.let { ws -> ret["ws"] = ws }
        return ret
    }

    val history = history(
        maxSize = 100,
        initialValue = listOf(mapRoute()),
    ).sync(this)

    val back = handle { current ->
        console.log("** HISTORY BACK")
        try {
            val previous = history.back()
            if (previous.isNotEmpty()) {
                validateExternalRoute(previous)
            } else {
                validateInternalRoute(mapRoute())
            }
        } catch (e: IndexOutOfBoundsException) {
            validateInternalRoute(mapRoute())
        }
        current
    }

    val backXLevels = handle<Int> { current, levels ->
        console.log("** HISTORY BACK X LEVELS")
        val number = min(history.current.size, levels)
        if (history.current.size >= number) {
            val previous = history.current.elementAtOrNull(history.current.size - number)
            if (!previous.isNullOrEmpty()) {
                validateExternalRoute(previous)
            } else validateInternalRoute(mapRoute())
        } else validateInternalRoute(mapRoute())
        current
    }

    val goToMap = handle { current ->
        console.log("** GO TO MAP")
        validateInternalRoute(mapRoute())
        current
    }

    private fun navToMap() {
        console.log("Map click -> ** NAV TO MAP")
        goToMap()
    }

    val addToMapClickListener = handle { current ->
        maplibreMap.off(type = "click", fnId = "resetOnMapClick")
        maplibreMap.once(type = "click", fn = ::navToMap, fnId = "go-to-map-listener")
        current
    }
    val removeToMapClickListener = handle { current ->
        maplibreMap.off(type = "click", fnId = "go-to-map-listener")
        current
    }

    val backTo = handle<Map<String, String>> { current, destination ->
        console.log("** HISTORY BACK TO $destination")
        if (history.current.size > 1) {
            for (i in 2..history.current.size) {
                val previous = history.last()
                if (destination.entries.all { entry ->
                        previous[entry.key] == entry.value
                    }) {
                    break
                } else {
                    history.back()
                }
            }
            val keyDestination = history.back()
            if (keyDestination.isNotEmpty()) {
                validateExternalRoute(keyDestination)
            } else validateInternalRoute(mapRoute())
        } else validateInternalRoute(mapRoute())
        current
    }

    val addOrReplaceRoute = handle<Map<String, String>> { current, newRoute ->
        val currentRoute = router.current
        if (currentRoute != newRoute) {
            val mutable = currentRoute.toMutableMap()
            newRoute.forEach { entry ->
                mutable[entry.key] = entry.value
            }
            val modified = mutable.toMap()
            if (current != modified) {
//                console.log("Unmodified", currentRoute.toString())
//                console.log("Modified", modified.toString())
                validateExternalRoute(modified)
            }
        }
        current
    }

    val addOrReplaceRouteDirectly = SimpleHandler<Map<String, String>> { newRouteData, _ ->
        newRouteData handledBy { newRoute ->
            val currentRoute = router.current
            if (current != newRoute) {
                val mutable = currentRoute.toMutableMap()
                newRoute.forEach { entry ->
                    mutable[entry.key] = entry.value
                }
                val modified = mutable.toMap()
                if (current != modified) {
//                console.log("Unmodified", currentRoute.toString())
//                console.log("Modified", modified.toString())
                    router.navTo(modified)
                }
            }
        }
    }

    val toggleMapLayersCard = handle { current ->
        val currentRoute = router.current
        if (currentRoute["page"] == Pages.Map.name && currentRoute["card"] == Cards.MapLayers.name) {
            activeKeywordLayerDefinitionStore.reset()
            validateInternalRoute(mapRoute())
            current
        } else {
            validateInternalRoute(
                mapOf(
                    "page" to Pages.Map.name,
                    "card" to Cards.MapLayers.name,
                ),
            )
            current
        }
    }

    fun updateWsOfRedirectRoute(workspace: String) {
        console.log(
            "~~~~ UPDATE WS of REDIRECT ROUTE (${redirectRoute?.toString()}) with:", workspace,
            "-> ${
                (redirectRoute?.plus(
                    mapOf("ws" to workspace),
                ))
            }",
        )
        val oldRedirect = redirectRoute ?: mapOf()
        redirectRoute = oldRedirect + mapOf("ws" to workspace)
//        if(oldRedirect.keys.size > 2) redirectRoute = mapOf(
//            "page" to (oldRedirect["page"]?: Pages.Map.name),
//            "ws" to workspace
//        )
    }

    private fun isNotForAnonymous(route: Map<String, String>): Boolean {
        return parseEnumValue<Pages>(route["page"])?.notForAnonymous ?: true
    }

    fun isPreLoginPage(route: Map<String, String>): Boolean {
        return parseEnumValue<Pages>(route["page"])?.preLoginPage ?: false
    }

    fun preLoginRedirect(route: Map<String, String>): Boolean {
        return parseEnumValue<Pages>(route["page"])?.preLoginRedirect ?: false
    }

    fun resetHistory() {
        history.reset()
        history.add(mapRoute())
    }

    private fun addWorkspaceToRoute(
        route: Map<String, String>? = null,
        newWorkspace: String? = null,
    ): Map<String, String> {
        val currentRoute = route ?: router.current
        (newWorkspace ?: currentWorkspaceStore.current?.name)?.let { workspace ->
            return if (workspace.isNotBlank()) {
                console.log("## Workspace added to route -> ", (currentRoute + mapOf("ws" to workspace)).toString())
                currentRoute + mapOf("ws" to workspace)
            } else currentRoute
        } ?: return currentRoute
    }

    private fun fixRoute(route: Map<String, String>): Map<String, String> {
        val returnRoute = route.toMutableMap()
        if (route["page"] == null || parseEnumValue<Pages>(route["page"]) == null) {
            console.log("FIX route -> add page=Map")
            returnRoute += Pages.Map.route
        }
        return returnRoute.toMap()
    }

    val validateExternalRoute = handle<Map<String, String>> { current, urlRoute ->
        console.log("## Validate route from url:", urlRoute.toString())
        firstStep(current, urlRoute, internal = false)
        current
    }

    val validateInternalRoute = handle<Map<String, String>> { current, newRoute ->
        console.log("## Validate internal route ", newRoute.toString())
        firstStep(current, newRoute, internal = true)
        current
    }

    private val validateAppState = handle<AppState> { current, appState ->
        console.log("## Validate appState", appState.appPhase.name, current.toString())

        if (appState.appPhase == AppPhase.LoggedIn || appState.appPhase == AppPhase.LoggedInAnonymous) {
//            console.log("## AppPhase:", phase.name, "-> Reset History")
            history.reset()
            history.add(mapRoute())
        }
        firstStep(latest, latest, appState, internal = false)
        current
    }

    init {
        validateExternalRoute(router.current)
        router.data handledBy validateExternalRoute
        appStateStore.data handledBy validateAppState
    }

    // NEW ROUTING SEQUENCE

    // ** STEP 1 ** Check redundancy and fix Route

    private suspend fun firstStep(
        currentRoute: Map<String, String>,
        newRoute: Map<String, String>,
        appState: AppState? = null,
        internal: Boolean = false,
    ) {
        console.log("** STEP 1 ** Check redundancy and fix Route", newRoute.toString())
        latest = newRoute
        if (appState != null || newRoute != currentRoute) {
            checkAppState(currentRoute = currentRoute, newRoute = fixRoute(newRoute), appState, internal)
        } else {
            updateRoute(newRoute, currentRoute)
        }
    }

    // ** STEP 2 ** Check appState
    private suspend fun checkAppState(
        currentRoute: Map<String, String>,
        newRoute: Map<String, String>,
        appState: AppState? = null,
        internal: Boolean = false,
    ) {

        val credentialsStore by koinCtx.inject<CredentialsStore>()
        val workspaceInputStore by koinCtx.inject<WorkspaceInputStore>()
        val apiUserStore by koinCtx.inject<ApiUserStore>()
        val mainController by koinCtx.inject<MainController>()
        val appStateStore by koinCtx.inject<AppStateStore>()
        val busyStore by koinCtx.inject<BusyStore>()
        val activeUserStore: ActiveUserStore by koinCtx.inject()

        val state = appState ?: appStateStore.current

        console.log("** STEP 2 ** Check appState: ${state.appPhase}", newRoute.toString())

        // Check for login token
        newRoute["logintoken"]?.let { signInToken ->
            credentialsStore.signInWithRefreshToken(signInToken)
            return
        }

        // Populate email input on login page, when email found in route
        newRoute["email"]?.let { email ->
            if (state.appPhase == AppPhase.NotLoggedIn) credentialsStore.map(Credentials.email()).update(email)
        }
        val page = parseEnumValue<Pages>(newRoute["page"])

        when (state.appPhase) {
            AppPhase.LoggedInAnonymous, AppPhase.LoggedIn -> {
                when (page) {
                    Pages.ResetPassword, Pages.SignUp, Pages.CreateAccount, Pages.CreateWorkspace -> {
                        mainController.logout()
                        checkWorkspaceChange(
                            currentRoute = currentRoute,
                            newRoute = newRoute,
                            false,
                            internal = internal,
                        )
                    }

                    else -> {
                        val redirect = newRoute + (redirectRoute ?: emptyMap())
                        console.log("Merge redirect route $redirectRoute into route $newRoute -> $redirect")
                        val nextRoute = if (isPreLoginPage(redirect)) {
                            redirect //+ Pages.Map.route
                        } else {
                            console.log("Nav to $redirect and reset redirect route")
                            redirectRoute = null // reset redirect route to null
                            redirect
                        }
                        checkWorkspaceChange(
                            currentRoute = currentRoute,
                            newRoute = nextRoute,
                            true,
                            internal = internal,
                        )
                    }
                }

            }

            AppPhase.NotLoggedIn -> {
                when (page) {
                    Pages.ResetPassword,
                    Pages.SignUp,
                    Pages.CreateAccount,
                    Pages.LoginWithEmail,
                    Pages.CreateWorkspace -> updateRoute(newRoute + page.route)

                    else -> {
//                        console.log("PAGE IS ->", page?.name)
                        val routeId = newRoute["id"]
                        if (!routeId.isNullOrEmpty()) {
                            busyStore.handleApiCall(
                                suspend {
                                    apiUserStore.anonymousGraphQLClient.lookupCodeAnonymously(routeId.urlEncode())
                                },
                                processResult = { result ->
                                    when (result) {
                                        is CodeLookupResult.NoAccess -> {
                                            val workspace = result.workspaceName
                                            console.log("Looked up workspace name for id: ${result.code} -> $workspace")
                                            workspaceInputStore.update(workspace)
                                            updateRoute(newRoute + Pages.Login.route + mapOf("ws" to workspace))
                                            // TODO Alternative: show scanned QR landing page,
                                            //  display Success or Failure message with then option to go to login
                                        }

                                        is CodeLookupResult.User -> {
                                            console.log("User:", result.result.name)
                                            activeUserStore.update(result.result)
                                            updateRoute(newRoute + Pages.PublicProfile.route)
                                        }

                                        else -> {
                                            console.log("Tried to look up workspace name for id: ${result.code} -> not found")
                                            val scannedCodeStore by koinCtx.inject<ScannedCodeStore>()
                                            scannedCodeStore.updateCode(result.code)
//                                            updateRoute(Pages.Map.route + Cards.CreateTrackedObject.route)
                                        }
                                    }
                                },
                            )
                        } else updateRoute(newRoute + Pages.Login.route)
                    }
                }
            }

            else -> {
                val checkedRoute = when (state.appPhase) {
                    AppPhase.Checking -> null
                    AppPhase.NotAcceptedCookies -> {
                        when (page) {
                            Pages.AppPaused -> newRoute + Pages.AppPaused.route
                            else -> newRoute + Pages.Cookies.route
                        }
                    }

                    AppPhase.NotAcceptedDisclaimer -> preLoginRedirectRoute
                    AppPhase.NoValidToken -> newRoute + Pages.TokenExpired.route
                    AppPhase.NotActivated -> newRoute + Pages.Activate.route
                    AppPhase.NotAcceptedTerms -> newRoute + Pages.Terms.route
                    AppPhase.NewPasswordNeeded -> {
                        // special case that you can go to MyProfile to be able to change the password
                        if (page != Pages.MyProfile) {
                            newRoute + Pages.NewPasswordNeeded.route
                        } else newRoute.toMap()
                    }

                    else -> newRoute
                }

                checkedRoute?.let { updateRoute(route = it) }
            }

        }
    }


    // ** STEP 3 ** Check workspace changes
    private suspend fun checkWorkspaceChange(
        currentRoute: Map<String, String>,
        newRoute: Map<String, String>,
        userIsLoggedIn: Boolean = true,
        internal: Boolean = false,
    ) {
        val workspaceInputStore by koinCtx.inject<WorkspaceInputStore>()
        val changeWorkspaceStore by koinCtx.inject<ChangeWorkspaceStore>()
        val appStateStore by koinCtx.inject<AppStateStore>()
        val apiUserStore by koinCtx.inject<ApiUserStore>()

        val workspace = newRoute["ws"] ?: newRoute["id"]?.let { id ->
            apiUserStore.anonymousGraphQLClient.getGroupNameByExternalCode(id).getOrNull()
        }
        val appState = appStateStore.current

        console.log(
            "** STEP 3 ** Check workspace changes (url says -> $workspace)",
            newRoute.toString(),
            "User logged in:",
            userIsLoggedIn,
        )

        val nextRoute = if (workspace != null) {
            if (userIsLoggedIn) {
                if (!currentWorkspaceStore.current?.name.isNullOrBlank()) {
                    console.log("Read workspace from currentWorkspaceStore")
                    val currentWorkspace = currentWorkspaceStore.current?.name
                    if (currentWorkspace != workspace) {
                        console.log(
                            "HANDLE WORKSPACE - LOGGED IN",
                            newRoute.toString(),
                            "Current WS:",
                            currentWorkspace,
                            "RouteWS:",
                            workspace,
                        )
                        console.log("NEW Workspace detected: $workspace. Should show change workspace dialog now.")
                        changeWorkspaceStore.changeWorkspacePopup(
                            oldRoute = if (history.current.isNotEmpty()) history.last() else mapRoute(),
                            oldWS = currentWorkspace,
                            newRoute = newRoute,
                            newWS = workspace,
                        )
                        currentRoute
                    } else {
                        newRoute
                    }
                } else {
                    // User just logged in
                    console.log("Read workspace from appState.apiUser")
                    val currentWorkspace = appState.apiUser?.workspaceName
                    console.log(
                        "HANDLE WORKSPACE - JUST LOGGED IN",
                        newRoute.toString(),
                        "Current WS:",
                        currentWorkspace,
                        "RouteWS:",
                        workspace,
                    )

                    addWorkspaceToRoute(newRoute, workspace)
                }
            } else if (!workspaceInputStore.current.isNullOrBlank()) {
                console.log("Read workspace from workspaceInputStore")
                val currentWorkspace = workspaceInputStore.current
                console.log(
                    "HANDLE WORKSPACE - NOT LOGGED IN",
                    newRoute.toString(),
                    "Current WS:",
                    currentWorkspace,
                    "RouteWS:",
                    workspace,
                )
                addWorkspaceToRoute(newRoute, workspace)
            } else {
                newRoute
            }
        } else {
            console.log("Route has no workspace:", newRoute.toString())
            val currentWorkspace = currentWorkspaceStore.current?.name.takeIf { !it.isNullOrBlank() }
                .also { console.log("WS from currentWorkspaceStore") }
                ?: appState.apiUser?.workspaceName.takeIf { !it.isNullOrBlank() }
                    .also { console.log("WS from appState.apiUser") }
                ?: workspaceInputStore.current.takeIf { !it.isNullOrBlank() }
                    .also { console.log("WS from workspaceInputStore") }
            addWorkspaceToRoute(newRoute, currentWorkspace)
        }

        checkRouteParams(nextRoute, internal = internal)
    }

    // ** STEP 4 ** Check route params
    private suspend fun checkRouteParams(route: Map<String, String>, internal: Boolean = false) {

        val activeObjectStore by koinCtx.inject<ActiveObjectStore>()
        val activeUserStore by koinCtx.inject<ActiveUserStore>()
        val scannedCodeStore by koinCtx.inject<ScannedCodeStore>()
        val objectAndUserHandler by koinCtx.inject<ObjectAndUserHandler>()

        console.log("** STEP 4 ** Check route params", route.toString())

        if (internal) {
            updateRoute(route = route)
            route["id"]?.let { objId ->
                val currentId =
                    activeObjectStore.current.tags.getUniqueTag(ObjectTags.ExternalId) ?: activeObjectStore.current.id
                if (objId.isNotBlank() && currentId != objId) {
                    console.log("RouteParamCheck:", "currentId from route:", objId, "activeObjectId:", currentId)
                    console.log("UpdateAndLocate object")
                    objectAndUserHandler.updateAndLocateActiveObjectByExternalIdOrInternalId(objId)
                }
            }
            route["userId"]?.let { userId ->
                val currentUserId = activeUserStore.current.userId
                if (userId.isNotBlank() && currentUserId != userId) {
                    objectAndUserHandler.updateAndLocateActiveUserById(userId)
                }
            }
        } else {
            updateRoute(route = route)
            scannedCodeStore.checkCodeActionFromScanOrUrl(
                scannedCode = ScannedCode(
                    extOrIntObjIdOrActionId = route["id"],
                    userId = route["userId"],
                    codeTech = CodeTech.QR,
                    scanPurpose = ScanPurpose.OpenCode,
                    operation = parseEnumValue<OperationType>(route["o"]),
                    color = parseEnumValue<MarkerColor>(route["c"]),
                    icon = parseEnumValue<MarkerIcon>(route["i"]),
                    shape = parseEnumValue<MarkerShape>(route["s"]),
                    token = route["token"],
                    loginToken = route["logintoken"],
                    tag = route["t"],
                    page = parseEnumValue<Pages>(route["page"]),
                    workspace = route["ws"] ?: route["workspace"],
                ),
                route = route,
            )
        }
    }

    private fun updateRoute(route: Map<String, String>, currentRoute: Map<String, String>? = null) {
        if (route != (currentRoute ?: current)) {
            update(route)
            console.log("## Route changed -> update RouterStore", route.toString())
        } else console.log("## Validated route is same as current RouterStore route. Do nothing.")
        if (route != router.current) {
            router.update(route)
            console.log("## Route changed -> nav to", route.toString())
        } else console.log("## Validated route is same as current URL route. Do nothing.")
    }
}

val Map<String, String>.isBasePageRoute
    get() = !this["page"].isNullOrEmpty()
        && this.keys.size <= 2
        && (this.keys.minus("page") == setOf("ws") || this.keys.minus("page").isEmpty())

fun Map<String, String>.isBasePage(page: Pages) = this.isBasePageRoute && this["page"] == page.name
