package auth

import apiclient.groups.Group
import dev.fritz2.core.RootStore
import dev.fritz2.core.invoke
import koin.koinCtx
import kotlinx.coroutines.Job
import login.HostConfigStore
import map.MapLayerUserSettingsStore
import map.MapStateStore
import map.MaplibreTileLayersStore
import search.searchlayer.MapLayerMetadataListStore
import search.searchlayer.MapSearchClientsStore

class CurrentWorkspaceStore : RootStore<Group?>(
    initialData = null,
    job = Job(),
) {
    private val hostConfigStore by koinCtx.inject<HostConfigStore>()
    private val apiUserStore by koinCtx.inject<ApiUserStore>()
    private val mapLayerMetaDataListStore: MapLayerMetadataListStore by koinCtx.inject()
    private val mapSearchClientsStore: MapSearchClientsStore by koinCtx.inject()
    private val mapLayerUserSettingsStore: MapLayerUserSettingsStore by koinCtx.inject()
    private val maplibreTileLayersStore: MaplibreTileLayersStore by koinCtx.inject()
    private val mapStateStore: MapStateStore by koinCtx.inject()

    val groupId get() = current?.groupId?: error("workspace should have groupId")

    val reset = handle { null }

    val updateCurrentWorkspace = handle<List<Group>> { _, apiUserWsList ->
        apiUserStore.current.apiUser?.workspaceName?.let { apiUserWs ->
            apiUserWsList.firstOrNull { it.name.lowercase() == apiUserWs.lowercase() }?.let { currentWorkspace ->
                hostConfigStore.updateHostConfig(currentWorkspace.name)
                mapLayerMetaDataListStore.updateLayerMetadataList(listOf(currentWorkspace))
                mapLayerUserSettingsStore.initializeWithWorkspaceDefaults(listOf(currentWorkspace))
                maplibreTileLayersStore.initializeWithWorkspaceHeatmaps(listOf(currentWorkspace))
                console.log("UPDATE MAP SEARCH CLIENTS FOR:", currentWorkspace.name)
                mapSearchClientsStore.updateMapSearchClients(listOf(currentWorkspace))
                mapStateStore.insertMap()
                currentWorkspace
            }
        }
    }
}
