package search.searchFilterStores

import analytics.AnalyticsCategory
import analytics.AnalyticsService
import apiclient.geoobjects.ReadOnlyTags
import dev.fritz2.core.RootStore
import koin.koinCtx
import kotlinx.coroutines.Job
import model.KeywordTag

abstract class ActiveReadOnlyKeywordsStore : RootStore<List<KeywordTag>>(
    initialData = emptyList(),
    job = Job(),
) {

    private val analyticsService by koinCtx.inject<AnalyticsService>()

    val add = handle<KeywordTag> { current, otherTag ->
        when (otherTag.readOnlyType) {
            ReadOnlyTags.Assignee -> {
                analyticsService.createEvent(AnalyticsCategory.AddAssignee) {
                    click(otherTag.fieldText)
                }
            }

            ReadOnlyTags.Creator -> {
                analyticsService.createEvent(AnalyticsCategory.AddCreator) {
                    click(otherTag.fieldText)
                }
            }

            else -> analyticsService.createEvent(AnalyticsCategory.AddKeyWord) {
                click(otherTag.fieldText)
            }
        }
        // Make sure only one tag of each type is active
        if (otherTag.readOnlyType in current.map { it.readOnlyType }) {
            (current.filter { it.readOnlyType != otherTag.readOnlyType } + otherTag).distinct()
        } else (current + otherTag).distinct()
    }

    val remove = handle<KeywordTag> { current, otherTag ->
        if (current.isNotEmpty() && otherTag.readOnlyType in current.map { it.readOnlyType }) {
            (current.filter { it.readOnlyType != otherTag.readOnlyType }).distinct()
        } else current
    }

    val reset = handle {
        emptyList()
    }
}
