package location.geolocation

import auth.permissions.PermissionModalStore
import auth.permissions.PermissionType
import apiclient.geoobjects.LatLon
import koin.koinCtx
import kotlin.coroutines.resume
import kotlin.coroutines.suspendCoroutine
import kotlinx.coroutines.delay
import utils.obj
import web.geolocation.GeolocationCoordinates
import web.geolocation.GeolocationPositionError
import web.geolocation.GeolocationWatchId
import web.navigator.navigator

/**
 * Geolocation coordinates values.
 */
class Coordinates(
    val latitude: Double,
    val longitude: Double,
    val altitude: Double?,
    val accuracy: Double,
    val altitudeAccuracy: Double?,
    val heading: Double?,
    val speed: Double?
)

val Coordinates.latlon get() = LatLon(latitude, longitude)

fun GeolocationCoordinates.convert() = Coordinates(
    longitude = longitude,
    latitude = latitude,
    accuracy = accuracy,
    altitude = altitude,
    altitudeAccuracy = altitudeAccuracy,
    heading = heading,
    speed = speed,
)

/**
 * Geolocation position value.
 */
class GeoPosition(
    val coords: Coordinates,
    val timestamp: Long
) {
    companion object
}

fun GeoPosition.latLon(): LatLon = LatLon(lat = this.coords.latitude, lon = this.coords.longitude)

/**
 * Geolocaton error codes.
 */
enum class PositionError {
    PERMISSION_DENIED,
    POSITION_UNAVAILABLE,
    TIMEOUT
}

fun codeToEnum(code: GeolocationPositionError.Code): PositionError {
    return when (code) {
        GeolocationPositionError.PERMISSION_DENIED -> PositionError.PERMISSION_DENIED
        GeolocationPositionError.POSITION_UNAVAILABLE -> PositionError.POSITION_UNAVAILABLE
        else -> PositionError.TIMEOUT
    }
}

/**
 * Exception class for geolocation errors.
 */
class GeolocationException(val code: PositionError, message: String) : Exception(message)

/**
 * Main geolocation object based on web-view api.
 */
object Geolocation {

    private val permissionModalStore: PermissionModalStore by koinCtx.inject()

    /**
     * Get current location.
     * @param enableHighAccuracy provides a hint that the application needs the best possible results
     * @param timeout the maximum length of time (milliseconds) that is allowed to pass before getting the result
     * @param maximumAge accept a cached position whose age is no greater than the specified time in milliseconds
     */
    @Suppress("UnsafeCastFromDynamic")
    suspend fun getCurrentPosition(
        enableHighAccuracy: Boolean = true,
        timeout: Long? = null,
        maximumAge: Long? = null,
        promptModal: Boolean = true
    ): Result<GeoPosition> {
        var waitingForResult = true
        var errorResult = false

        val res = suspendCoroutine<Result<GeoPosition>> { continuation ->
            navigator.geolocation.getCurrentPosition(
                { geoPosition ->
                    waitingForResult = false
                    // work around kotlin weirdness, throws errors when trying to get this via js. IR compiler
                    val pos = GeoPosition(
                        timestamp = geoPosition.timestamp.toLong(),
                        coords = geoPosition.coords.convert(),
//                    coords = Coordinates(
//
//                        longitude = geoPosition.coords.longitude,
//                        latitude = geoPosition.coords.latitude,
//                        accuracy = geoPosition.coords.accuracy,
//                        altitude = geoPosition.coords.altitude,
//                        altitudeAccuracy = geoPosition.coords.altitudeAccuracy,
//                        heading = geoPosition.coords.heading,
//                        speed = geoPosition.coords.speed,
//                    )
                    )
                    continuation.resume(
                        Result.success(
                            pos,
                        ),
                    )
                },
                { error ->
                    waitingForResult = false
                    errorResult = true
                    continuation.resume(Result.failure(GeolocationException(codeToEnum(error.code), error.message)))
                },
                obj {
                    this.enableHighAccuracy = enableHighAccuracy
                    if (timeout != null) this.timeout = timeout
                    if (maximumAge != null) this.maximumAge = maximumAge
                },
            )
        }
        if (promptModal) {
            permissionModalStore.startPermissionModal(PermissionType.Geolocation)
            while (waitingForResult) {
                delay(100)
            }
            if (!errorResult) {
                permissionModalStore.stopPermissionModal()
            }
        }
        return res
    }

    /**
     * Watch location changes.
     * @param enableHighAccuracy provides a hint that the application needs the best possible results
     * @param timeout the maximum length of time (milliseconds) that is allowed to pass before getting the result
     * @param maximumAge accept a cached position whose age is no greater than the specified time in milliseconds
     * @param resultCallback a callback function receiving the result
     * @return watch identifier (can be removed with a [clearWatch] function)
     */
    @Suppress("UnsafeCastFromDynamic")
    suspend fun watchPosition(
        enableHighAccuracy: Boolean = true,
        timeout: Long? = null,
        maximumAge: Long? = null,
        promptModal: Boolean = true,
        resultCallback: (Result<GeoPosition>) -> Unit
    ): GeolocationWatchId {
        var waitingForResult = true
        var errorResult = false
        val res = suspendCoroutine { continuation ->
            val watchId = navigator.geolocation.watchPosition(
                { geoPosition ->
                    waitingForResult = false
                    // work around kotlin weirdness, throws errors when trying to get this via js. IR compiler
                    val pos = GeoPosition(
                        timestamp = geoPosition.timestamp.toLong(),
                        coords = geoPosition.coords.convert(),
//                    coords = Coordinates(
//                        longitude = geoPosition.coords.longitude,
//                        latitude = geoPosition.coords.latitude,
//                        accuracy = geoPosition.coords.accuracy,
//                        altitude = geoPosition.coords.altitude,
//                        altitudeAccuracy = geoPosition.coords.altitudeAccuracy,
//                        heading = geoPosition.coords.heading,
//                        speed = geoPosition.coords.speed,
//                    )
                    )
                    resultCallback(Result.success(pos))
                },
                { error ->
                    waitingForResult = false
                    errorResult = true
                    resultCallback(Result.failure(GeolocationException(codeToEnum(error.code), error.message)))
                },
                obj {
                    this.enableHighAccuracy = enableHighAccuracy
                    if (timeout != null) this.timeout = timeout
                    if (maximumAge != null) this.maximumAge = maximumAge
                },
            )
            continuation.resume(watchId)
        }
        if (promptModal) {
            permissionModalStore.startPermissionModal(PermissionType.Geolocation)
            while (waitingForResult) {
                delay(100)
            }
            if (!errorResult) {
                permissionModalStore.stopPermissionModal()
            }
        }
        return res
    }

    /**
     * Clear the given watch.
     * @param watchId watch identifier returned from [watchPosition] function
     */
    @Suppress("UnsafeCastFromDynamic")
    fun clearWatch(watchId: GeolocationWatchId) {
        navigator.geolocation.clearWatch(watchId)
    }

}


