// GENERATED by fritz2 - NEVER CHANGE CONTENT MANUALLY!
package model

import dev.fritz2.core.Lens
import dev.fritz2.core.lensOf
import wizard.onbaording.ActionButton
import wizard.onbaording.Wizard

fun LocalSettings.Companion.acceptCookies(): Lens<LocalSettings, Boolean> = lensOf(
    "acceptCookies",
    { it.acceptCookies },
    { p, v -> p.copy(acceptCookies = v) },
)

fun LocalSettings.Companion.readDisclaimer(): Lens<LocalSettings, Boolean> = lensOf(
    "readDisclaimer",
    { it.readDisclaimer },
    { p, v -> p.copy(readDisclaimer = v) },
)

fun LocalSettings.Companion.mapStyle(): Lens<LocalSettings, String> = lensOf(
    "mapStyle",
    { it.mapStyle },
    { p, v -> p.copy(mapStyle = v) },
)

fun LocalSettings.Companion.manualSearch(): Lens<LocalSettings, Boolean> = lensOf(
    "manualSearch",
    { it.manualSearch },
    { p, v -> p.copy(manualSearch = v) },
)

fun LocalSettings.Companion.periodicSearch(): Lens<LocalSettings, Boolean> = lensOf(
    "periodicSearch",
    { it.periodicSearch },
    { p, v -> p.copy(periodicSearch = v) },
)

fun LocalSettings.Companion.hideMarkerTitles(): Lens<LocalSettings, Boolean> = lensOf(
    "hideMarkerTitles",
    { it.hideMarkerTitles },
    { p, v -> p.copy(hideMarkerTitles = v) },
)

fun LocalSettings.Companion.clusterObjects(): Lens<LocalSettings, Boolean> = lensOf(
    "clusterObjects",
    { it.clusterObjects },
    { p, v -> p.copy(clusterObjects = v) },
)

fun LocalSettings.Companion.wizardStates(): Lens<LocalSettings, Map<Wizard, Int>> = lensOf(
    "wizardStates",
    { it.wizardStates },
    { p, v -> p.copy(wizardStates = v) },
)

fun LocalSettings.Companion.actionButtonStates(): Lens<LocalSettings, Map<ActionButton, Boolean>> = lensOf(
    "actionButtonStates",
    { it.actionButtonStates },
    { p, v -> p.copy(actionButtonStates = v) },
)
