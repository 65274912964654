package data.objects.views

import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.ObjectTags
import apiclient.tags.getUniqueTag
import data.objects.ActiveObjectStore
import dev.fritz2.components.compat.img
import dev.fritz2.components.compat.span
import dev.fritz2.components.flexBox
import dev.fritz2.components.lineUp
import dev.fritz2.components.stackUp
import dev.fritz2.core.RenderContext
import dev.fritz2.core.src
import koin.koinCtx
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import localization.TL
import localization.Translation
import mainmenu.RouterStore
import model.L
import signup.textLinkButton
import theme.FormationDefault.Companion.formationStyles
import theme.FormationIcons
import utils.merge
import webcomponents.baseLayout
import webcomponents.cardTitle
import webcomponents.contentScrollBox
import webcomponents.oneButtonFooter

fun RenderContext.cardAttribution() {
    val translation: Translation by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()
    val activeObjectStore: ActiveObjectStore by koinCtx.inject()
    val title = activeObjectStore.map(GeoObjectDetails.L.tags).data.map { tags -> tags.getUniqueTag(ObjectTags.Attribution) ?: "" }
    baseLayout(
        expandable = false,
        header = {
            cardTitle(title = title.merge(flowOf("contributors"))) { FormationIcons.Copyright.icon }
        },
        content = {
            contentScrollBox {
                stackUp({
                    padding { small }
                    radius(formationStyles.buttonRadius)
                    alignItems { center }
                }) {
                    items {
                        img({
                            width { "100px" }
                        }) {
                            src("https://wiki.openstreetmap.org/w/images/7/79/Public-images-osm_logo.svg")
                        }
                        p {
                            +"Open Street Map data, available under the Open Database Licence." // TODO translate
                        }
                        lineUp({
                            width { full }
                            alignItems { flexStart }
                            margins { top { large } }
                            justifyContent { center }
                        }) {
                            spacing { tiny }
                            items {
                                span({
                                    wrap { nowrap }
                                }) {
                                    +"Links:" // TODO translate
                                }
                                flexBox({
                                    direction { row }
                                    wrap { wrap }
                                }) {
                                    textLinkButton(
                                        text = flowOf("openstreetmap.org"),
                                        link = "https://www.openstreetmap.org/copyright",
                                        addStyling = {
                                            margins { horizontal { tiny } }
                                        }
                                    )
                                    textLinkButton(
                                        text = flowOf("opendatacommons.org"),
                                        link = "https://opendatacommons.org/licenses/odbl/",
                                        addStyling = {
                                            margins { horizontal { tiny } }
                                        }
                                    )
                                }
                            }
                        }
                    }
                }
            }
        },
        footer = {
            oneButtonFooter(
                title = translation[TL.General.BACK],
                value = Unit,
                clickHandlers = listOf(routerStore.back)
            )
        }
    )
}
