package dev.fritz2.components

import dev.fritz2.components.compat.TextArea
import dev.fritz2.components.compat.textarea
import dev.fritz2.components.foundations.Component
import dev.fritz2.components.foundations.ComponentProperty
import dev.fritz2.components.foundations.DynamicComponentProperty
import dev.fritz2.components.foundations.ElementMixin
import dev.fritz2.components.foundations.ElementProperties
import dev.fritz2.components.foundations.EventMixin
import dev.fritz2.components.foundations.EventProperties
import dev.fritz2.components.foundations.InputFormMixin
import dev.fritz2.components.foundations.InputFormProperties
import dev.fritz2.core.RenderContext
import dev.fritz2.core.Store
import dev.fritz2.core.disabled
import dev.fritz2.core.readOnly
import dev.fritz2.core.value
import dev.fritz2.core.values
import dev.fritz2.styling.StyleClass
import dev.fritz2.styling.params.BasicParams
import dev.fritz2.styling.params.BoxParams
import dev.fritz2.styling.params.Style
import dev.fritz2.styling.staticStyle
import dev.fritz2.styling.theme.FormSizesStyles
import dev.fritz2.styling.theme.TextAreaResize
import dev.fritz2.styling.theme.TextAreaVariants
import dev.fritz2.styling.theme.Theme
import kotlinx.coroutines.flow.flowOf
import org.w3c.dom.HTMLTextAreaElement

/**
 * This component generates a TextArea.
 *
 * You can optionally pass a store in order to set the value and react to updates automatically.
 *
 * Basic usage
 * ```
 * textArea(value = dataStore) {
 * }
 * ```
 *
 * @see TextAreaComponent
 *
 * @param styling a lambda expression for declaring the styling as fritz2's styling DSL
 * @param value optional [Store] that holds the data of the textarea
 * @param baseClass optional CSS class that should be applied to the element
 * @param id the ID of the element
 * @param prefix the prefix for the generated CSS class resulting in the form ``$prefix-$hash``
 * @param build a lambda expression for setting up the component itself. Details in [TextAreaComponent]
 */
fun RenderContext.textArea(
    styling: BasicParams.() -> Unit = {},
    value: Store<String>? = null,
    baseClass: StyleClass = StyleClass.None,
    id: String? = value?.id,
    prefix: String = "textArea",
    build: TextAreaComponent.() -> Unit
) = TextAreaComponent(value).apply(build).render(this, styling, baseClass, id, prefix)

/**
 * This class handles the configuration of a textarea element.
 *
 * Possible values to set are( default *) :
 *  - size : small | normal * | large
 *  - resizeBehavior : none | vertical *| horizontal
 *  - disable : Boolean | Flow<Boolean>
 *  - value -> maybe you want to set an initial value instead of a placeholder
 *  - events -> access the DOM events of the underlying HTML element
 *  - element -> basic properties of the textarea html element; use with caution!
 *  - the base options of the HTML input element can be set.
 *    [Attributes](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/textarea#Attributes)
 *
 * Example usage
 * ```
 * // Basic usage
 * textArea(value = dataStore) {
 * }
 *
 * // Styling and options
 * textArea({ // use the styling parameter
 *     background {
 *         color { dark }
 *     }
 *     color { light }
 *     radius { "1rem" }}, store = dataStore) {
 *     disable(true) // textarea is disabled
 *     resizeBehavior { none } // resizing is not possible
 *     size { large } // render a large textarea
 * }
 *
 * // disabled
 * textArea {
 *     value { dataStore.data } // value depends on value in store
 *     disable(true) // editing is disabled, but resizing still works
 * }
 *
 * // all state management can also be done manually if needed:
 * val someStore = storeOf("some initial text")
 * textArea {
 *     value(someStore.data)
 *     events {
 *         changes.values() handledBy someStore.update
 *     }
 * }
 * ```
 */
open class TextAreaComponent(protected val valueStore: Store<String>? = null) :
    Component<TextArea>,
    EventProperties<HTMLTextAreaElement> by EventMixin(),
    ElementProperties<TextArea> by ElementMixin(),
    InputFormProperties by InputFormMixin() {

    companion object {
        val staticCss = staticStyle(
            "textAreaContainer",
            """
            outline: 0px;
            position: relative;
            appearance: none;
            transition: all 0.2s ease 0s;
            min-height: 80px;
            -webkit-appearance: none;
            """
        )
    }

    val value = DynamicComponentProperty(flowOf(""))
    val variant = ComponentProperty<TextAreaVariants.() -> Style<BasicParams>> { basic }
    val resizeBehavior = ComponentProperty<TextAreaResize.() -> Style<BasicParams>> { Theme().textArea.resize.both }
    val size = ComponentProperty<FormSizesStyles.() -> Style<BasicParams>> { Theme().textArea.sizes.normal }

    override fun render(
        context: RenderContext,
        styling: BoxParams.() -> Unit,
        baseClass: StyleClass,
        id: String?,
        prefix: String
    ): TextArea {
        return with(context) {
            textarea({
                this@TextAreaComponent.resizeBehavior.value.invoke(Theme().textArea.resize)()
                this@TextAreaComponent.size.value.invoke(Theme().textArea.sizes)()
                this@TextAreaComponent.variant.value.invoke(Theme().textArea.variants)()
            }, styling, baseClass + staticCss, id, prefix) {
                disabled(this@TextAreaComponent.disabled.values)
                readOnly(this@TextAreaComponent.readonly.values)
                value(this@TextAreaComponent.value.values)
                this@TextAreaComponent.valueStore?.let {
                    value(it.data)
                    changes.values() handledBy it.update
                }
                this@TextAreaComponent.events.value.invoke(this)
                this@TextAreaComponent.element.value.invoke(this)
            }
        }
    }
}
