package data.objects.views.attachments

import apiclient.geoobjects.Content
import dev.fritz2.components.flexBox
import dev.fritz2.core.RenderContext
import dev.fritz2.core.RootStore
import koin.koinCtx
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.map
import localization.TL
import localization.Translation
import mainmenu.RouterStore
import webcomponents.baseLayout
import webcomponents.cardTitle
import webcomponents.contentScrollBox
import webcomponents.oneButtonFooter

class MarkdownPreviewStore : RootStore<Content.Markdown?>(
    initialData = null,
    job = Job(),
)

fun RenderContext.cardMarkdownPreview() {

    val translation: Translation by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()
    val markdownPreviewStore: MarkdownPreviewStore by koinCtx.inject()

    baseLayout(
        header = {
            cardTitle(markdownPreviewStore.data.map {
                it?.title?.let { title -> title.ifBlank { translation.getString(TL.Attachments.ATTACHED_TEXT_NOTE) } }
                    ?: translation.getString(TL.Attachments.ATTACHED_TEXT_NOTE)
            }) { document }
        },
        content = {
            contentScrollBox {
                markdownPreviewStore.data.render { attachment ->
                    if (attachment != null && !attachment.htmlPreview.isNullOrEmpty()) {
                        div { }.domNode.innerHTML = attachment.htmlPreview!!
                    } else {
                        flexBox({
                            width { full }
                            height { full }
                            alignItems { center }
                            justifyContent { center }
                        }) {
                            span { translation[TL.Attachments.NO_PREVIEW_AVAILABLE].renderText(into = this) }
                        }
                    }
                }
            }
        },
        footer = {
            oneButtonFooter(
                title = translation[TL.General.BACK],
                value = Unit,
                clickHandlers = listOf(routerStore.back)
            )
        }
    )

}
