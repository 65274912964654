package map.views

import auth.ApiUserStore
import dev.fritz2.components.compat.button
import dev.fritz2.components.compat.div
import dev.fritz2.components.flexBox
import dev.fritz2.components.stackUp
import dev.fritz2.core.RenderContext
import dev.fritz2.core.RootStore
import dev.fritz2.core.invoke
import generated.OFFLINE_MODE
import koin.koinCtx
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.map
import localization.TL
import localization.Translation
import mainmenu.RouterStore
import model.isInternalAdminGroupAdminOrGroupOwner
import search.filterButtonOffStyle
import search.filterButtonOnStyle
import search.searchlayer.MapLayerMetadataListStore
import styling.primaryButtonStyleParams
import theme.FormationDefault
import utils.horizontalSwipeListener
import webcomponents.BigButtonOption
import webcomponents.baseLayout
import webcomponents.contentScrollBox
import webcomponents.genericBigButtonSwitch
import webcomponents.oneButtonFooter

enum class MapLayerTool { Style, Layer }

object MapLayerToolSelectorStore : RootStore<MapLayerTool>(
    initialData = MapLayerTool.Layer,
    job = Job()
) {

    val selectTool = handle<MapLayerTool> { _, tool ->
        update(tool)
        tool
    }

    val nextTool = handle { current ->
        if (current.ordinal < MapLayerTool.values().size - 1) {
            MapLayerTool.values()[current.ordinal + 1]
        } else current
    }

    val previousTool = handle { current ->
        if (current.ordinal > 0) {
            MapLayerTool.values()[current.ordinal - 1]
        } else current
    }

    val reset = handle {
        update(MapLayerTool.Layer)
        MapLayerTool.Layer
    }
}

fun RenderContext.mapLayerToolbar() {
    val translation: Translation by koinCtx.inject()

    div({
        width { full }
        paddings { vertical { small } }
    }) {
        flexBox({
            width { full }
            direction { row }
            justifyContent { spaceBetween }
            alignItems { center }
            border { color { primary.main }; width(FormationDefault.formationStyles.borderWidth) }
            radius(FormationDefault.formationStyles.inputRadius)
        }) {
            MapLayerToolSelectorStore.data.render { tool ->
                with(MapLayerTool.Layer) {
                    button({ if (tool == this@with) filterButtonOnStyle() else filterButtonOffStyle() }) {
                        translation[TL.CardMapLayerSelection.MAP_LAYERS].renderText(into = this)
                        clicks.map { this@with } handledBy MapLayerToolSelectorStore.selectTool
                    }
                }
                with(MapLayerTool.Style) {
                    button({ if (tool == this@with) filterButtonOnStyle() else filterButtonOffStyle() }) {
                        translation[TL.CardMapLayerSelection.MAP_STYLES].renderText(into = this)
                        clicks.map { this@with } handledBy MapLayerToolSelectorStore.selectTool
                    }
                }
            }
        }
    }
}

fun RenderContext.cardMapLayerSelection() {
    val translation: Translation by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()
    val apiUserStore: ApiUserStore by koinCtx.inject()
    val mapLayerMetaDataListStore: MapLayerMetadataListStore by koinCtx.inject()
    val canAdd = apiUserStore.current.isInternalAdminGroupAdminOrGroupOwner()

    baseLayout(
        header = {
//            mapLayerToolbar()
            // mapLayerToolSwitch
            genericBigButtonSwitch(
                MapLayerToolSelectorStore, options = listOf(
                    BigButtonOption(
                        title = translation[TL.CardMapLayerSelection.MAP_LAYERS],
                        value = MapLayerTool.Layer,
                        selectHandler = MapLayerToolSelectorStore.selectTool
                    ),
                    BigButtonOption(
                        title = translation[TL.CardMapLayerSelection.MAP_STYLES],
                        value = MapLayerTool.Style,
                        selectHandler = MapLayerToolSelectorStore.selectTool
                    ),
                )
            )
        },
        content = {
            MapLayerToolSelectorStore.data.render { tool ->
                contentScrollBox {
                    when (tool) {
                        MapLayerTool.Style -> {
                            stackUp({
                                width { full }
                                justifyContent { spaceBetween }
                                alignItems { center }
                            }) {
                                spacing { smaller }
                                items {
                                    val mapStyleEntries = if (OFFLINE_MODE) {
                                        MapStyleOffline.entries
                                    } else {
                                        MapStyleOnline.entries
                                    }
                                    // calculate number of rows matching the given number of columns
                                    val columns = 3
                                    val rest = mapStyleEntries.size % columns
                                    val rows = mapStyleEntries.size / columns + (if (rest > 0) 1 else 0)
                                    for (i in 0 until rows) {
                                        mapStyleGridRow(
                                            items = mapStyleEntries
                                                .filter { it.ordinal in i * columns until i * columns + columns }
                                                .sortedBy { it.ordinal },
                                            columns = columns,
                                            itemSize = { "90px" },
                                            deviceIconSize = { normal },
                                            fontSize = { smaller }
                                        )
                                    }
                                }
                            }
                        }

                        MapLayerTool.Layer -> {
                            flexBox({
                                margins { top { small } }
                                direction { column }
                                alignItems { center }
                                height { full }
                                width { full }
                            }) {
                                mapLayerList(mapLayerMetaDataListStore.data)
                                if (canAdd) {
                                    addKeyWordLayerButton()
                                }
                            }
                        }
                    }

                    horizontalSwipeListener(
                        domNode,
                        onSwipedRight = { MapLayerToolSelectorStore.nextTool() },
                        onSwipedLeft = { MapLayerToolSelectorStore.previousTool() }
                    )
                }
            }
        },
        footer = {
            oneButtonFooter(
                title = translation[TL.General.CLOSE],
                styleParams = primaryButtonStyleParams,
                value = Unit,
                clickHandlers = listOf(routerStore.goToMap)
            )
        }
    )
}
