package geofenceeditor

import com.jillesvangurp.geojson.Geometry
import apiclient.geoobjects.GeoObjectDetails
import data.objects.ActiveObjectStore
import dev.fritz2.core.RootStore
import dev.fritz2.core.storeOf
import koin.koinCtx
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.map
import model.L
import utils.roundTo

class GeoShapeStore(geometry: Geometry?) : RootStore<GeoShape?>(
    initialData = geometry.getGeoShape(),
    job = Job(),
) {

    val activeObjectStore: ActiveObjectStore by koinCtx.inject()

    val selectedGeoShape = storeOf(geometry.getGeoShape(), job)

    val setXMetersFromSlider = handle<Double> { original, meters ->
        when {
            original is GeoShape.Rectangle -> {
                original.copy(xMeters = meters.roundTo(1))
            }

            else -> original
        }
    }
    var lastXMetersFromInput = storeOf((current as? GeoShape.Rectangle)?.xMeters ?: 0.0, job)
    val setXMetersFromInput = handle<Double> { original, meters ->
        val roundedMeters = meters.roundTo(1)
        when {
            original is GeoShape.Rectangle -> {
                lastXMetersFromInput.update(roundedMeters)
                original.copy(xMeters = roundedMeters)
            }

            else -> original
        }
    }

    val setYMetersFromSlider = handle<Double> { original, meters ->
        when {
            original is GeoShape.Rectangle -> {
                original.copy(yMeters = meters.roundTo(1))
            }

            else -> original
        }
    }

    var lastYMetersFromInput = storeOf((current as? GeoShape.Rectangle)?.yMeters ?: 0.0, job)
    val setYMetersFromInput = handle<Double> { original, meters ->
        val roundedMeters = meters.roundTo(1)
        when {
            original is GeoShape.Rectangle -> {
                lastYMetersFromInput.update(roundedMeters)
                original.copy(yMeters = roundedMeters)
            }

            else -> original
        }
    }

    val setRotationFromSlider = handle<Double> { original, degrees ->
        val roundedDegrees = degrees.roundTo(1)
        when (original) {
            is GeoShape.Circle -> {
                original.copy(rotationDegrees = roundedDegrees)
            }

            is GeoShape.Rectangle -> {
                original.copy(rotationDegrees = roundedDegrees)
            }

            is GeoShape.Isogon -> {
                original.copy(rotationDegrees = roundedDegrees)
            }

            else -> original
        }
    }

    var lastRotationFromInput = storeOf(current?.rotationDegrees?.roundTo(1) ?: 0.0, job)
    val setRotationFromInput = handle<Double> { original, degrees ->
        val roundedDegrees = degrees.roundTo(1)
        when (original) {
            is GeoShape.Circle -> {
                lastRadiusMetersFromInput.update(roundedDegrees)
                original.copy(rotationDegrees = roundedDegrees)
            }

            is GeoShape.Rectangle -> {
                lastRadiusMetersFromInput.update(roundedDegrees)
                original.copy(rotationDegrees = roundedDegrees)
            }

            is GeoShape.Isogon -> {
                lastRadiusMetersFromInput.update(roundedDegrees)
                original.copy(rotationDegrees = roundedDegrees)
            }

            else -> original
        }
    }

    val setRadiusMetersFromSlider = handle<Double> { original, meters ->
        val roundedMeters = meters.roundTo(1)
        when (original) {
            is GeoShape.Circle -> {
                original.copy(radius = roundedMeters)
            }

            is GeoShape.Isogon -> {
                original.copy(radius = roundedMeters)
            }

            else -> original
        }
    }

    var lastRadiusMetersFromInput = storeOf(
        initialData = (current as? GeoShape.Circle)?.radius
            ?: (current as? GeoShape.Isogon)?.radius
            ?: 0.0,
        job = job,
    )
    val setRadiusMetersFromInput = handle<Double> { original, meters ->
        val roundedMeters = meters.roundTo(1)
        when (original) {
            is GeoShape.Circle -> {
                lastRadiusMetersFromInput.update(roundedMeters)
                original.copy(radius = roundedMeters)
            }

            is GeoShape.Isogon -> {
                lastRadiusMetersFromInput.update(roundedMeters)
                original.copy(radius = roundedMeters)
            }

            else -> original
        }
    }

    val setVerticesFromSlider = handle<Int> { original, vertices ->
        when (original) {
            is GeoShape.Isogon -> {
                if (vertices >= 5) (
                    original.copy(vertices = vertices)
                    ) else original
            }

            else -> original
        }
    }

    var lastVerticesFromInput = storeOf((current as? GeoShape.Isogon)?.vertices ?: 0, job)
    val setVerticesFromInput = handle<Int> { original, vertices ->
        when (original) {
            is GeoShape.Isogon -> {
                if (vertices >= 5) {
                    lastVerticesFromInput.update(vertices)
                    original.copy(vertices = vertices)
                } else original
            }

            else -> original
        }
    }

    val rectangle = handle<Pair<Double, Double>> { original, (x, y) ->
        val newRectangle = GeoShape.Rectangle(
            xMeters = when (original) {
                is GeoShape.Rectangle -> original.xMeters
                is GeoShape.Circle -> original.radius * 2
                is GeoShape.Isogon -> original.radius * 2
                else -> x
            }.roundTo(1),
            yMeters = when (original) {
                is GeoShape.Rectangle -> original.yMeters
                is GeoShape.Circle -> original.radius * 2
                is GeoShape.Isogon -> original.radius * 2
                else -> y
            }.roundTo(1),
            rotationDegrees = original?.rotationDegrees?.roundTo(1) ?: 0.0,
        )
        selectedGeoShape.update(newRectangle)
        newRectangle
    }

    val isogon = handle<Pair<Int, Double>> { original, (corners, radius) ->
        val newIsogon = GeoShape.Isogon(
            vertices = corners,
            radius = when (original) {
                is GeoShape.Circle -> original.radius
                is GeoShape.Isogon -> original.radius
                is GeoShape.Rectangle -> original.xMeters / 2
                else -> radius
            }.roundTo(1),
            rotationDegrees = original?.rotationDegrees?.roundTo(1) ?: 0.0,
        )
        selectedGeoShape.update(newIsogon)
        newIsogon
    }

    val circle = handle<Double> { original, radius ->
        val newCirle = GeoShape.Circle(
            radius = when (original) {
                is GeoShape.Circle -> original.radius
                is GeoShape.Isogon -> original.radius
                is GeoShape.Rectangle -> original.xMeters / 2
                else -> radius
            }.roundTo(1),
            rotationDegrees = original?.rotationDegrees?.roundTo(1) ?: 0.0,
        )
        selectedGeoShape.update(newCirle)
        newCirle
    }

    val parseStringCode = handle<String> { original, stringCode ->
        val parsedShape = parseGeoShapebyCode(stringCode)
        when (parsedShape) {
            is GeoShape.Circle -> {
                lastRadiusMetersFromInput.update(parsedShape.radius.roundTo(1))
            }

            is GeoShape.Rectangle -> {
                lastXMetersFromInput.update(parsedShape.xMeters.roundTo(1))
                lastYMetersFromInput.update(parsedShape.yMeters.roundTo(1))
            }

            is GeoShape.Isogon -> {
                lastRadiusMetersFromInput.update(parsedShape.radius.roundTo(1))
                lastVerticesFromInput.update(parsedShape.vertices)
            }
        }
        lastRotationFromInput.update(parsedShape.rotationDegrees.roundTo(1))
        selectedGeoShape.update(parsedShape)
        parsedShape
    }

    private val updateData = handle<GeoShape> { _, newGeoShape ->
        console.log("New GeoShape!", newGeoShape.shapeSelect.name)
        selectedGeoShape.update(newGeoShape)
        newGeoShape
    }

    init {
        activeObjectStore.map(GeoObjectDetails.L.geometry).data.map {
            it.getGeoShape()
        } handledBy updateData
    }
}
