package mainmenu

import apiclient.auth.ApiUser
import auth.ApiUserStore
import auth.TermsStore
import data.users.settings.LocalSettingsStore
import dev.fritz2.core.RootStore
import koin.koinCtx
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.map
import model.AppState
import model.InitializationState

class AppStateStore : RootStore<AppState>(
    initialData = AppState(),
    job = Job(),
) {
    private val apiUserStore: ApiUserStore by koinCtx.inject()
    private val termsStore: TermsStore by koinCtx.inject()
    private val localSettingsStore: LocalSettingsStore by koinCtx.inject()

    private val updateApiUserBusyState = handle<InitializationState> { current, apiUserBusy ->
        if (current.apiUserBusy != apiUserBusy) {
            console.log("ApiUser busy", apiUserBusy)
            current.copy(apiUserBusy = apiUserBusy)
        } else current
    }

    private val updateTermsBusyState = handle<InitializationState> { current, termsBusy ->
        if (current.termsBusy != termsBusy) {
            console.log("Terms busy", termsBusy)
            current.copy(termsBusy = termsBusy)
        } else current
    }

    private val updateApiUserState = handle<ApiUser?> { current, apiUser ->
        if (current.apiUser != apiUser) {
            console.log("New ApiUser", apiUser?.userId)
            current.copy(apiUser = apiUser)
        } else current
    }

    private val updateTermsState = handle<Boolean?> { current, agreedTermsState ->
        if (current.agreedTermsState != agreedTermsState) {
            console.log("New terms agree state", agreedTermsState.toString())
            current.copy(agreedTermsState = agreedTermsState)
        } else current
    }

    private val updateCookieState = handle<Boolean?> { current, cookieState ->
        if (current.acceptCookies != cookieState) {
            console.log("User accepted cookies:", cookieState.toString())
            current.copy(acceptCookies = cookieState)
        } else current
    }

    private val updateDisclaimerState = handle<Boolean?> { current, disclaimerState ->
        if (current.acceptDisclaimer != disclaimerState) {
            console.log("User accepted disclaimer:", disclaimerState.toString())
            current.copy(acceptDisclaimer = disclaimerState)
        } else current
    }

    init {
        apiUserStore.isBusyCheckingStore.data handledBy updateApiUserBusyState
        apiUserStore.data.map { if (it.valid) it.apiUser else null } handledBy updateApiUserState
        termsStore.isBusyCheckingStore.data handledBy updateTermsBusyState
        combine(termsStore.isBusyCheckingStore.data, termsStore.data) { isBusy, terms ->
            if (isBusy == InitializationState.RUNNING) terms.agreedLatest else terms.agreedLatest ?: false
        } handledBy updateTermsState
        localSettingsStore.data.map { it.acceptCookies } handledBy updateCookieState
        localSettingsStore.data.map { it.readDisclaimer } handledBy updateDisclaimerState
    }
}
