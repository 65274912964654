package data.users.settings

import apiclient.users.NotificationSetting
import apiclient.users.NotificationSettingValue
import apiclient.users.UserPreferences
import apiclient.users.notificationPreferencesMap
import dev.fritz2.core.RootStore
import koin.koinCtx
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.mapNotNull
import model.notificationPreferences

val defaultNotificationSettings = NotificationSetting.entries.associateWith {
    NotificationSettingValue.All
}


class NotificationPreferencesStore : RootStore<Map<NotificationSetting, NotificationSettingValue>>(
    initialData = defaultNotificationSettings,
    job = Job(),
) {
    private val syncedUserPreferencesStore by koinCtx.inject<SyncedUserPreferencesStore>()

    init {
        syncedUserPreferencesStore.map(UserPreferences.notificationPreferences()).data.mapNotNull {
            syncedUserPreferencesStore.current.notificationPreferencesMap
        } handledBy update
    }
}
