package data.users.views

import data.users.profile.VerifiedUserStore
import dev.fritz2.components.flexBox
import dev.fritz2.components.icon
import dev.fritz2.components.stackUp
import dev.fritz2.core.RenderContext
import dev.fritz2.components.compat.div
import koin.koinCtx
import kotlinx.coroutines.flow.flowOf
import localization.TL
import localization.Translation
import mainmenu.RouterStore
import styling.primaryButtonStyleParams
import theme.FormationColors
import theme.FormationDefault
import theme.FormationIcons
import theme.FormationUIIcons
import utils.DeferredResult
import webcomponents.*

fun RenderContext.pageUserVerificationResult() {
    val translation: Translation by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()
    val verifiedUserStore: VerifiedUserStore by koinCtx.inject()

    baseLayout(
        expandable = false,
        header = null,
        content = {
            contentScrollBox {
                verifiedUserStore.data.render { verificationResult ->
                    stackUp({
                        width { full }
                        height { full }
                        alignItems { center }
                        justifyContent { center }
                        padding { small }
                        overflowY { auto }
                        textAlign { center }
                    }) {
                        spacing { huge }
                        items {
                            when(verificationResult) {
                                is DeferredResult.Failure -> {
                                    // Verification failed
                                    userOrStateIcon(
                                        pixelSize = 50.0,
                                        shadow = false,
                                        icon = { close },
                                        iconBackground = { FormationColors.RedError.color }
                                    )
                                    cardTitle(
                                        title = translation[TL.UserVerification.IDENTITY_NOT_VERIFIED],
                                        maxLines = 2
                                    )
                                    span { translation[TL.UserVerification.NOT_VERIFIED_HELP_TEXT].renderText(into = this) }
                                }
                                DeferredResult.Pending -> {
                                    // Verification failed
                                    userOrStateIcon(
                                        pixelSize = 50.0,
                                        shadow = false,
                                        icon = { close },
                                        iconBackground = { FormationColors.YellowDoing.color }
                                    )
                                    cardTitle(
                                        title = translation[TL.UserVerification.IDENTITY_CHECKING],
                                        maxLines = 2
                                    )
                                    span { translation[TL.UserVerification.CHECKING_HELP_TEXT].renderText(into = this) }
                                }
                                is DeferredResult.Success -> {
                                    val userProfile = verificationResult.data
                                    div({
                                        margins { bottom { huge } }
                                    }) {
                                        mainTitle(flowOf(userProfile.name))
                                    }
                                    // Profile Photo
                                    flexBox({
                                        position { relative { } }
                                        flex {
                                            grow { "0" }
                                            shrink { "0" }
                                            basis { "200px" }
                                        }
                                        width { "200px" }
                                        height { "200px" }
                                        radius { full }
                                        justifyContent { center }
                                        alignItems { center }
                                        textAlign { center }
                                        if (userProfile.profilePhoto != null) {
                                            background {
                                                image { userProfile.profilePhoto?.href ?: "" }
                                                size { cover }
                                                position { center }
                                            }
                                        } else {
                                            border {
                                                width(FormationDefault.formationStyles.borderWidth)
                                                color { FormationColors.GrayDisabled.color }
                                            }
                                            color { FormationColors.GrayDisabled.color }
                                        }
                                    }) {
                                        if (userProfile.profilePhoto == null) {
                                            icon({
                                                size { "100px" }
                                            }) { fromTheme { FormationIcons.UserAlt.icon } }
                                        }
                                        // Check Icon
                                        div({
                                            position {
                                                absolute {
                                                    bottom { none }
                                                    right { none }
                                                }
                                            }
                                        }) {
                                            userOrStateIcon(
                                                pixelSize = 50.0,
                                                shadow = false,
                                                icon = { FormationUIIcons.Check.icon },
                                                iconBackground = { FormationColors.GreenActive.color }
                                            )
                                        }
                                    }
                                    cardTitle(
                                        title = translation[TL.UserVerification.IDENTITY_VERIFIED],
                                        maxLines = 2
                                    )

                                }
                            }
                        }
                    }
                }
            }
        },
        footer = {
            oneButtonFooter(
                title = translation[TL.General.CLOSE],
                styleParams = primaryButtonStyleParams,
                value = Unit,
                clickHandlers = listOf(routerStore.goToMap),
                analyticsEventProvider = null,
            )
        }
    )

}
