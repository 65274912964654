package model

import kotlinx.serialization.Serializable
import wizard.onbaording.ActionButton
import wizard.onbaording.Wizard

@Serializable
data class LocalSettings(
    val acceptCookies: Boolean = false,
    val readDisclaimer: Boolean = false,
    val mapStyle: String = "MapTilerBasic",
    val manualSearch: Boolean = false,
    val periodicSearch: Boolean = false,
    val hideMarkerTitles: Boolean = false,
    val clusterObjects: Boolean = true,
    val wizardStates: Map<Wizard, Int> = Wizard.entries.associateWith { -1 },
    val actionButtonStates: Map<ActionButton, Boolean> = ActionButton.entries.associateWith { false }
) {
    companion object {
        val DEFAULT: LocalSettings get() = LocalSettings()
    }
}
