package overlays

import dev.fritz2.core.invoke
import kotlin.coroutines.EmptyCoroutineContext
import kotlin.time.Duration.Companion.seconds
import kotlinx.coroutines.CoroutineName
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.launch
import model.NotificationType
import model.Overlay
import model.OverlayState
import theme.FormationColors

class AlertOverlayStore : OverlayStore(initialData = OverlayState()) {

    override val reset = handle { current ->
        update(OverlayState())
        current
    }

    override val trigger = handle { current ->
        //console.log("show overlay for ${current.overlay?.durationSeconds} seconds")
        current.timer?.cancel()
        val newTimer = CoroutineScope(EmptyCoroutineContext).launch(CoroutineName("alert-timer")) {
            current.overlay?.durationSeconds?.seconds?.let {
                delay(it)
                reset()
            }
        }
        update(current.copy(enabled = true, timer = newTimer))
        current.copy(enabled = true, timer = newTimer)
    }

    override val remove = handle { current ->
        //console.log("remove overlay")
        if (current.enabled) {
            current.timer?.cancel()
            update(OverlayState(enabled = false, timer = null))
            OverlayState(enabled = false, timer = null)
        } else {
            //console.log("overlay was already removed")
            current
        }
    }

    override fun show(overlay: Overlay) {
        remove()
        update(OverlayState(overlay = overlay))
        trigger()
    }

    override val flip = handle { current ->
        val newSwitchState = !current.enabled
        //console.log("show overlay: $newSwitchState")
        if (newSwitchState) {
            trigger()
        } else {
            remove()
        }
        current
    }

    fun notify(message: Flow<String>, durationSeconds: Int = 6) {
        show(
            overlay = Overlay.NotificationToast(
                notificationType = NotificationType.Alert,
                durationSeconds = durationSeconds,
                text = message,
                bgColor = FormationColors.GreenActive.color,
            ),
        )
    }

    fun warnNotify(message: Flow<String>) {
        show(
            overlay = Overlay.NotificationToast(
                notificationType = NotificationType.Alert,
                durationSeconds = 6,
                text = message,
                bgColor = FormationColors.YellowDoing.color,
            ),
        )
    }

    fun errorNotify(message: Flow<String>) {
        show(
            overlay = Overlay.NotificationToast(
                notificationType = NotificationType.Alert,
                durationSeconds = 10,
                text = message,
                bgColor = FormationColors.RedError.color,
            ),
        )
    }
}
