package camera.photo

import camera.nimiq.BrowserCamera
import camera.nimiq.CameraType
import camera.nimiq.FacingMode
import camera.nimiq.cameraButtons
import camera.nimiq.cameraSelect
import camera.nimiq.fullCameraArea
import dev.fritz2.components.flexBox
import dev.fritz2.core.RenderContext
import koin.koinCtx
import localization.TL
import localization.Translation
import mainmenu.RouterStore
import model.ScanPurpose
import webcomponents.twoButtonFooter

fun RenderContext.cardBrowserPhotoCamera(facingMode: FacingMode = FacingMode.Front) {
    val translation: Translation by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()
    val browserCamera: BrowserCamera by koinCtx.inject()

    fullCameraArea(
        cameraType = CameraType.Photo,
        scanPurpose = ScanPurpose.TakePhoto,
        facingMode = facingMode,
        headerOverlay = null,
        footerOverlay = {
            flexBox(
                {
                    width { full }
                    direction { row }
                    alignItems { center }
                    justifyContent { spaceBetween }
                    wrap { wrap }
                    margins { bottom { small } }
                },
            ) {
                cameraSelect()
                cameraButtons()
            }
            twoButtonFooter(
                primaryTitle = translation[TL.CardBrowserPhotoCamera.TAKE_PHOTO],
                primaryValue = Unit,
                primaryClickHandlers = listOf(browserCamera.takePhoto, routerStore.back),
                primaryAnalyticsEventProvider = null,
                secondaryTitle = translation[TL.General.CANCEL],
                secondaryClickHandlers = listOf(browserCamera.stop, routerStore.back),
                secondaryAnalyticsEventProvider = null,
            )
        },
    )
}
