// GENERATED by fritz2 - NEVER CHANGE CONTENT MANUALLY!
package model

import dev.fritz2.core.Lens
import dev.fritz2.core.lensOf

fun TermsState.Companion.read(): Lens<TermsState, Boolean> = lensOf(
    "read",
    { it.read },
    { p, v -> p.copy(read = v) }
)

fun TermsState.Companion.agreedLatest(): Lens<TermsState, Boolean?> = lensOf(
    "agreedLatest",
    { it.agreedLatest },
    { p, v -> p.copy(agreedLatest = v) }
)

fun TermsState.Companion.agreeDate(): Lens<TermsState, String?> = lensOf(
    "agreeDate",
    { it.agreeDate },
    { p, v -> p.copy(agreeDate = v) }
)
