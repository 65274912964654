package data.users.views

import apiclient.users.PublicUserProfile
import apiclient.util.isNotNullOrEmpty
import data.users.ActiveUserStore
import dev.fritz2.components.stackUp
import dev.fritz2.core.RenderContext
import dev.fritz2.core.storeOf
import koin.koinCtx
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.mapNotNull
import localization.TL
import localization.Translation
import mainmenu.Pages
import model.KeywordTag
import model.allowEmailInPublicProfile
import model.allowVCardInPublicProfile
import model.company
import model.emailAddresses
import model.firstName
import model.jobTitle
import model.keywords
import model.lastName
import model.linkedInLink
import model.phoneNumbers
import model.profilePhoto
import model.webLinks
import model.websiteLink
import styling.primaryButtonStyleParams
import theme.FormationIcons
import theme.FormationUIIcons
import twcomponents.twCardSectionTitle
import twcomponents.twColOf
import twcomponents.twColOfCenter
import twcomponents.twIconCustomSize
import twcomponents.twIconSmall
import utils.mergeIfNotBlank
import webcomponents.BigButtonOption
import webcomponents.KeywordTagActionType
import webcomponents.KeywordTagType
import webcomponents.baseLayout
import webcomponents.cardSubtitle
import webcomponents.cardTitle
import webcomponents.contentScrollBox
import webcomponents.genericBigButtonSwitch
import webcomponents.inputLabelWrapper
import webcomponents.keywordTagList
import webcomponents.oneButtonFooter

fun RenderContext.pagePublicUserProfile() {

    val translation: Translation by koinCtx.inject()
    val activeUserStore: ActiveUserStore by koinCtx.inject()
    val firstName = activeUserStore.map(PublicUserProfile.firstName())
    val lastName = activeUserStore.map(PublicUserProfile.lastName())
    val jobTitle = activeUserStore.map(PublicUserProfile.jobTitle())
    val emails = activeUserStore.map(PublicUserProfile.emailAddresses())
    val phoneNumbers = activeUserStore.map(PublicUserProfile.phoneNumbers())
    val profilePhoto = activeUserStore.map(PublicUserProfile.profilePhoto())
    val company = activeUserStore.map(PublicUserProfile.company())
    val linkedInLink = activeUserStore.map(PublicUserProfile.linkedInLink())
    val websiteLink = activeUserStore.map(PublicUserProfile.websiteLink())
    val webLinks = activeUserStore.map(PublicUserProfile.webLinks())
    val keywords = activeUserStore.map(PublicUserProfile.keywords())
    val allowEmailInPublicProfile = activeUserStore.map(PublicUserProfile.allowEmailInPublicProfile())
    val allowVCardInPublicProfile = activeUserStore.map(PublicUserProfile.allowVCardInPublicProfile())

    val profileswitchStore = storeOf(ProfileContent.Details)

    baseLayout(
        expandable = false,
        header = null,
        content = {
            contentScrollBox {
                profilePhoto.data.render { image ->
                    twColOfCenter {
                        // Profile Photo
                        div("flex items-center justify-center text-center grow-0 shrink-0 w-52 h-52 my-4 rounded-full") {
                            if (image == null) {
                                className("text-gray-300 border border-gray-300")
                                twIconCustomSize(icon = FormationIcons.UserAlt, size = "100px")
                            } else {
                                inlineStyle("--image-url:url(${image.href})")
                                className("bg-[image:var(--image-url)] bg-center bg-cover")
                            }
                        }
                    }
                }
                stackUp(
                    {
                        width { full }
                        alignItems { center }
                        justifyContent { center }
                        margins {
                            bottom { small }
                        }
                    },
                ) {
                    spacing { tiny }
                    items {
                        cardTitle(firstName.data.combine(lastName.data) { f, l -> "$f $l" })
                        cardSubtitle(jobTitle.data.mergeIfNotBlank(company.data, " | ").mapNotNull { it })
                    }
                }
                allowVCardInPublicProfile.data.render { showVCard ->
                    if (showVCard == true) {
                        genericBigButtonSwitch(
                            store = profileswitchStore,
                            options = listOf(
                                BigButtonOption(
                                    title = translation[ProfileContent.Details],
                                    icon = { ProfileContent.Details.icon.icon },
                                    value = ProfileContent.Details,
                                    selectHandler = profileswitchStore.update,
                                ),
                                BigButtonOption(
                                    title = translation[ProfileContent.VCard],
                                    icon = { ProfileContent.VCard.icon.icon },
                                    value = ProfileContent.VCard,
                                    selectHandler = profileswitchStore.update,
                                    disabled = flowOf(showVCard),
                                ),
                            ),
                        )
                    } else {
                        twCardSectionTitle {
                            twIconSmall(ProfileContent.Details.icon)
                            translation[ProfileContent.Details].renderText()
                        }
                    }
                }
                profileswitchStore.data.render { content ->
                    when (content) {
                        ProfileContent.Details -> {
                            twColOf {
                                // Email addresses
                                emails.data.render { mails ->
                                    profileEmailSection(
                                        mails,
                                        userName = activeUserStore.current.name,
                                        showFullEmail = allowEmailInPublicProfile.current == true,
                                    )
                                }

                                // Phone numbers
                                phoneNumbers.data.render { numbers ->
                                    profilePhoneNumberSection(numbers)
                                }

                                // LinkedIn link
                                linkedInLink.data.render { link ->
                                    link?.let { linkedIn ->
                                        if (linkedIn.isNotBlank()) {
                                            inputLabelWrapper(
                                                title = translation[TL.UserProfile.LINKEDIN],
                                                visibilityFlow = flowOf(true),
                                            ) {
                                                profileLinkButton(link = linkedIn, icon = FormationUIIcons.LinkedIn, target = "_blank")
                                            }
                                        }
                                    }
                                }

                                // Keyword tags
                                keywords.data.renderIf({ it.isNotNullOrEmpty() }) {
                                    twCardSectionTitle {
                                        twIconSmall(FormationIcons.Tag)
                                        translation[TL.UserProfile.KEYWORDS].renderText()
                                    }
                                }
                                keywordTagList(
                                    keywords = keywords.data.map { keywordList ->
                                        (keywordList ?: emptyList()).map {
                                            KeywordTag(it, actionType = KeywordTagActionType.Default)
                                        }
                                    },
                                    keywordTagType = KeywordTagType.SearchTag,
                                    searchable = true,
                                )

                                // Website preview
                                websiteLink.data.render { website ->
                                    twCardSectionTitle {
                                        twIconSmall(FormationIcons.Website)
                                        translation[TL.UserProfile.WEBSITE].renderText()
                                    }
//                                    cardTitle(translation[TL.UserProfile.WEBSITE]) { FormationIcons.Website }
                                    profileLinkPreview(website)
                                }

                                // Web link previews
                                webLinks.data.render { links ->
                                    twCardSectionTitle {
                                        twIconSmall(FormationUIIcons.ExternalLink)
                                        translation[TL.UserProfile.WEBLINK].renderText()
                                    }
//                                    cardTitle(translation[TL.UserProfile.WEBLINK]) { FormationUIIcons.ExternalLink }
                                    profileLinkPreviewList(links)
                                }
                            }
                        }

                        ProfileContent.VCard -> {
                            activeUserStore.data.render { profile ->
                                profileVCardSection(profile)
                            }
                        }

                        else -> {}
                    }
                }
            }
        },
        footer = {
            oneButtonFooter(
                title = translation[TL.General.CLOSE],
                styleParams = primaryButtonStyleParams,
                routingMap = Pages.Login.route,
                value = Unit,
//                primaryClickHandlers = listOf(routerStore.back),
            )
        },
    )
}
