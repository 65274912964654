package data.heatmaplayer

import apiclient.customfields.FieldValue
import apiclient.geoobjects.SearchQueryContext
import kotlinx.serialization.json.JsonObject

data class HeatmapLayerDefinitionData(
    val layerId: String = "new_layer",
    val title: String = "",
    // SearchQueryContext
    val searchQueryCtx: SearchQueryContext = SearchQueryContext(),
    // searchQueryContext params
    val keywords: List<String> = emptyList(),
    val or: Boolean = true,
    val excludeKeywords: List<String>? = null,
    val fieldValues: List<FieldValue> = emptyList(),
    // Style
    val style: JsonObject? = null, //defaultHeatmapStyle,
    // style params
    val opacity: Double = 0.75,
    val colorPalette: List<String> =
        emptyList()
//        listOf(rgbaToHexColor("rgba(33,102,172,0)"))
//            + listOf("rgb(103,169,207)","rgb(209,229,240)","rgb(253,219,199)","rgb(239,138,98)","rgb(178,24,43)").map {
//                rgbToHexColor(it)
//            }
) {
    companion object
}
