package db

import dev.fritz2.components.modal
import dev.fritz2.components.stackUp
import koin.koinCtx
import kotlin.coroutines.EmptyCoroutineContext
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.launch
import layercache.GeoObjectDetailsCache
import layercache.GeoObjectDetailsDatabase
import mainmenu.mainMenuButton
import theme.FormationColors
import theme.FormationDefault
import theme.FormationIcons
import webcomponents.cardTitle
import webcomponents.fullPageConfirmation
import webcomponents.fullPageConfirmationContainer

val opfsErrorPopup = modal(
    {
        width { none }
        height { none }
    },
) {
    placement { center }
    hasCloseButton(false)
    content { close ->
        fullPageConfirmation {
            fullPageConfirmationContainer(
                width = FormationDefault.formationStyles.cardWidth,
                additionalStyleParams = {
                    background {
                        color { "lightcoral" }
                    }
                },
                additionalInnerStyleParams = {
                    background {
                        color {
                            "white"
                        }
                    }
                },
            ) {
                stackUp {
                    spacing { small }
                    items {
                        cardTitle(flowOf("Oops, we ran into an error!"))
                        p { +"Failed to initialize database. (opfs)" }
                        button {
                            mainMenuButton(
                                icon = { FormationIcons.InformationAlt.icon },
                                iconBackgroudColor = { FormationColors.BlueDeep.color },
                                titleLiteral = flowOf("Wipe caches and refresh page"),
                                subtitle = flowOf("This cleans up and re-initializes the app."),
                                clickHandlers = listOf(close),
                            )
                            this.domNode.onclick = {
                                CoroutineScope(EmptyCoroutineContext).launch {
                                    wipeOPFS()
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

suspend fun wipeOPFS() {
    val geoObjectDetailsDatabase: GeoObjectDetailsDatabase by koinCtx.inject()
    val geoObjectDetailsCache: GeoObjectDetailsCache by koinCtx.inject()

    geoObjectDetailsCache.clear()
    geoObjectDetailsDatabase.clear()


//    val directory = navigator.storage.getDirectory() //Async().await()
//    val entriesIterator = directory.entries().iterator()
//    while (entriesIterator.hasNext()) {
//        val (key, handle) = entriesIterator.next()
//        directory.removeEntry(key)
//        console.log("Removed opfs", key, handle.name)
//    }
//
//    window.location.reload()
}
