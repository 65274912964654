package map.views.workplacetools

import koin.koinCtx
import mainmenu.RouterStore
import map.Cards
import search.searchFilterStores.ActiveKeywordsStore

class ActiveArchetypeSearchKeywordsStore: ActiveKeywordsStore() {

    val routerStore: RouterStore by koinCtx.inject()

    val addToActiveArchetypeSearchKeywords = handle<String> { current, keyword ->
        add(keyword)
        routerStore.validateInternalRoute(Cards.Tools.route)
        current
    }
}