package mainmenu

import dev.fritz2.components.flexBox
import dev.fritz2.core.RenderContext
import dev.fritz2.components.compat.p
import koin.koinCtx
import localization.TL
import localization.Translation
import routing.MainController
import webcomponents.baseLayout
import webcomponents.contentScrollBox
import webcomponents.mainTitle
import webcomponents.twoButtonFooter

fun RenderContext.pageSignOut() {
    val mainController: MainController by koinCtx.inject()
    val translation: Translation by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()

    baseLayout(
        expandable = false,
        content = {
            contentScrollBox(margins = { top { normal } }) {
                flexBox({
                    width { full }
                    margins { vertical { large } }
                    justifyContent { center }
                    alignItems { center }
                    flex { grow { "1" } }
                }) { mainTitle(translation[TL.PageLogout.CARD_TITLE]) }
                p({
                    margins { vertical { large } }
                    fontSize { large }
                    textAlign { center }
                    flex { grow { "1" } }
                }) { translation[TL.PageLogout.ARE_YOU_SURE].renderText(into = this) }
                flexBox({ flex { grow { "1" } } }) { }
            }
        },
        footer = {
            twoButtonFooter(
                primaryTitle = translation[TL.PageLogout.LOGOUT],
                primaryValue = Unit,
                primaryClickHandlers = listOf(mainController.logout),
                secondaryTitle = translation[TL.PageLogout.STAY],
                secondaryClickHandlers = listOf(routerStore.back)
            )
        }
    )
}
