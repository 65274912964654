package data.objects.objecthistory

import analyticsdashboard.AnalyticsTimeFilterStore
import analyticsdashboard.TimeFilter
import apiclient.FormationClient
import apiclient.geoobjects.ChangeType
import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.HistoryTags
import apiclient.geoobjects.LatLon
import apiclient.geoobjects.ObjectTags
import apiclient.geoobjects.ObjectType
import apiclient.geoobjects.SearchQueryContext
import apiclient.geoobjects.SortByField
import apiclient.geoobjects.SortField
import apiclient.geoobjects.SortOrder
import apiclient.geoobjects.restSearch
import apiclient.search.ObjectSearchResult
import apiclient.search.ObjectSearchResults
import apiclient.tags.getUniqueTag
import apiclient.tags.tag
import apiclient.validations.parseEnumValue
import auth.ApiUserStore
import auth.FeatureFlagStore
import data.objects.ActiveObjectStore
import dev.fritz2.core.RootStore
import dev.fritz2.core.SimpleHandler
import koin.koinCtx
import kotlinx.coroutines.CoroutineName
import kotlinx.coroutines.Job
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import layercache.GeoObjectDetailsCache
import map.MapLayersStore
import maplibreGL.MaplibreMap
import overlays.BusyStore

/**
 * Defines the [ObjectType]'s that should have interactive history paths
 */
val historyPathObjectTypeCriteria =
    listOf(ObjectType.GeneralMarker, ObjectType.ObjectMarker, ObjectType.POI, ObjectType.Task, ObjectType.Event)

/**
 * Defines history [ChangeType]'s per [ObjectType] to be displayed in analytics path tool
 */
fun ObjectType.historyEntryTypes() = when (this) {
    ObjectType.ObjectMarker, ObjectType.GeneralMarker -> listOf(
//        ChangeType.Create,
//        ChangeType.Update,
        ChangeType.TrackedObjectLocationUpdate,
        ChangeType.TrackedObjectEnterZone,
        ChangeType.TrackedObjectExitZone,
        ChangeType.ObjectMoved,
    )


    ObjectType.Zone -> listOf(
        ChangeType.Create,
        ChangeType.Update,
        ChangeType.OccupantEnterZone,
        ChangeType.OccupantExitZone,
        ChangeType.ObjectMoved,
        ChangeType.TrackedObjectLocationUpdate,
    )

    else -> listOf(
        ChangeType.Create,
        ChangeType.Update,
        ChangeType.ObjectMoved,
    )
}

fun ObjectSearchResult.hasLocation(): Boolean = hit.latLon != LatLon(0.0, 0.0)

fun ObjectSearchResult.parentHasHistoryPath(): Boolean = parseEnumValue<ObjectType>(
    hit.tags.getUniqueTag(ObjectTags.ConnectedObjectType),
)?.let { objType -> objType in historyPathObjectTypeCriteria } ?: false

fun ObjectSearchResult.changeType() = parseEnumValue<ChangeType>(this.hit.tags.getUniqueTag(HistoryTags.ChangeType))
fun GeoObjectDetails.changeType() = parseEnumValue<ChangeType>(this.tags.getUniqueTag(HistoryTags.ChangeType))


class ObjectHistoryResultsCache : RootStore<Map<String, ObjectAndResults>>(
    initialData = emptyMap(),
    job = Job(),
) {
    val apiUserStore: ApiUserStore by koinCtx.inject()
    val activeObjectStore: ActiveObjectStore by koinCtx.inject()
    val geoObjectDetailsCache: GeoObjectDetailsCache by koinCtx.inject()
    val mapLayersStore: MapLayersStore by koinCtx.inject()
    val maplibreMap: MaplibreMap by koinCtx.inject()
    val formationClient: FormationClient by koinCtx.inject()
    val featureFlagStore: FeatureFlagStore by koinCtx.inject()
    val busyStore: BusyStore by koinCtx.inject()
    private val analyticsTimeFilterStore: AnalyticsTimeFilterStore by koinCtx.inject()
    private val activeHistoryEntryStore: ActiveHistoryEntryStore by koinCtx.inject()
    private val displayedPathObjectResultsStore: DisplayedPathObjectResultsStore by koinCtx.inject()

    fun selectHistoryEntry(objectHistoryEntryId: String?) {
        current.values.map { it.results }.flatten().firstOrNull { (it.hit.id == objectHistoryEntryId) }?.hit?.let { obj ->
            activeHistoryEntryStore.selectHistoryEntry(obj)
        }
    }

    /**
     * Select tracked Object and fetch, filter and display its history events
     */
    val getTrackedObjectHistory = SimpleHandler<String> { data, _ ->
        data handledBy { newObjectId ->
            // toggle object in DisplayedHistoryResultsStore with current available cached data or empty data
            geoObjectDetailsCache[newObjectId]
                ?.let { newObject ->
                    displayedPathObjectResultsStore.displayOrRemoveTrackedObjectHistory(
                        data = current[newObjectId]?.let { newObjectId to it }
                            ?.applyFilters(analyticsTimeFilterStore.current)
                            ?: Pair(newObjectId, ObjectAndResults(newObject, emptyList())),
                    )
                    // check if object is cached
                    if (newObjectId !in current.keys) {
                        // if not, fetch results of object and add them to current cache
                        fetch(newObject) { res ->
                            // updates the cache and the DisplayedHistoryResultsStore after fetch is done
                            current.add(ObjectAndResults(newObject, res.hits)).also { updatedCache ->
                                update(updatedCache)
                                displayedPathObjectResultsStore.updateFromCache(updatedCache.applyFilters(analyticsTimeFilterStore.current))
                            }
                            console.log("Add object history results to cache", res.hits.map { it.hit })
                        }
                    }
                }
        }
    }

    /**
     * Fetches HistoryResults of an [obj] and forwards the data to the specified [resultHandler]
     */
    private suspend fun fetch(obj: GeoObjectDetails, resultHandler: suspend (ObjectSearchResults) -> Unit) {
        coroutineScope {
            CoroutineName("get-history-for-object")
            launch {
                val groupIds = apiUserStore.current.apiUser?.groups?.map { it.groupId }
                if (obj.id.isNotBlank() && !groupIds.isNullOrEmpty()) {
                    busyStore.handleApiCall(
                        supplier = suspend {
                            formationClient.restSearch(
                                SearchQueryContext(
                                    groupIds = groupIds,
                                    tags = listOf(
                                        ObjectTags.ConnectedTo.tag(obj.id),
                                    ),
                                    objectTypes = listOf(ObjectType.HistoryEntry),
                                    sort = listOf(
                                        SortByField(SortField.CREATED_AT, SortOrder.DESC),
                                    ),
                                    orTags = obj.objectType.historyEntryTypes()
                                        .map { HistoryTags.ChangeType.tag(it) },
                                ),
                            )
                        },
                        processResult = resultHandler,
                    )
                }

            }
        }
    }

    suspend fun refetchAll() {
        val fetchedResults: MutableMap<String, ObjectAndResults> = mutableMapOf()
        var counter = 0

        coroutineScope {
            launch {
                val currentData = current
                if (currentData.isNotEmpty()) {
                    geoObjectDetailsCache.multiGet(
                        currentData.keys.toList(),
                        formationClient,
                        forceUpdate = true,
                    ).forEach { obj ->
                        fetch(obj) { res ->
                            console.log("Refetch history events for ${obj.title}, found: ${res.hits.size}")
                            fetchedResults[obj.id] = ObjectAndResults(obj, res.hits)
                            counter += 1
                        }
                    }
                    while (counter < currentData.size) {
                        delay(100)
                    }
                } else {
                    fetchedResults.clear()
                    console.log("Cache is empty, nothing to refetch.")
                }
            }.invokeOnCompletion {
                console.log("Refetched cache of object history events, for the currently: ${fetchedResults.size} cached objects.")
                console.log("Refetched Data:  ${fetchedResults.values.map { (obj, results) -> obj.title to results.size }.toTypedArray()}")
                // update cache with new fetched results of all objects
                update(fetchedResults)
                displayedPathObjectResultsStore.updateFromCache(fetchedResults.applyFilters(analyticsTimeFilterStore.current))
            }
        }
    }

    val clearObjectHistoryResultsCache = handle {
        console.log("Clear path tool ObjectHistoryResultsCache")
//        stopPeriodicCacheUpdate.invoke()
        emptyMap()
    }

//    private fun Pair<GeoObjectDetails, List<ObjectSearchResult>>.applyFilters(
//        timeFilter: TimeFilter = analyticsTimeFilterStore.current
//    ): Pair<GeoObjectDetails, List<ObjectSearchResult>> {
//        return this.let { (obj, results) ->
//            obj to results.filter { result ->
//                // HistoryChangeTypeFilter
//                result.hasLocation() && result.changeType() in obj.objectType.historyEntryTypes()
//
//                    // TimeFilter
//                    && result.hit.createdAt.parseInstant()?.toEpochMilliseconds()?.let { eventTime ->
//                    val start = timeFilter.filterStartDate?.parseInstant()?.toEpochMilliseconds()
//                    val end = timeFilter.filterEndDate?.parseInstant()?.toEpochMilliseconds()
//                    end?.let {
//                        eventTime <= end && start?.let { eventTime >= start } ?: true
//                    } ?: start?.let { eventTime >= start } ?: true
//                } ?: true
//            }
//        }
//    }
//
//    private fun Map<GeoObjectDetails, List<ObjectSearchResult>>.applyFilters(
//        timeFilter: TimeFilter = analyticsTimeFilterStore.current
//    ): Map<GeoObjectDetails, List<ObjectSearchResult>> {
//        return this.map { entry ->
//            entry.toPair().applyFilters(timeFilter)
//        }.toMap()
//    }

    private val filterByTime = handle<TimeFilter> { current, timeFilter ->
        displayedPathObjectResultsStore.updateFromCache(current.applyFilters(timeFilter))
        current
    }

    init {
        analyticsTimeFilterStore.data handledBy filterByTime
    }

}
