package auth.permissions

import dev.fritz2.core.RootStore
import dev.fritz2.core.SimpleHandler
import koin.koinCtx
import kotlinx.coroutines.Job
import services.GeoPositionService
import utils.obj
import web.events.EventHandler
import web.navigator.navigator
import web.permissions.PermissionDescriptor
import web.permissions.PermissionName
import web.permissions.PermissionState

enum class PermissionType(val jsName: String) {
    Geolocation("geolocation"),
    Camera("camera"), // not supported by Firefox as PermissionName
    /*Notifications("notifications"),
    PersistentStorage("persistent-storage"),
    Push("push"),
    ScreenWakeLock("screen-wake-lock"),
    XrSpatialTracking("xr-spatial-tracking"),*/
}

data class WebPermission(
    val permissionDescriptor: PermissionDescriptor,
    val permissionState: PermissionState,
)

class PermissionsService : RootStore<Map<PermissionType, WebPermission>>(DEFAULT, Job()) {

    val startWatchingPermissionStates = SimpleHandler<Unit> { data, _ ->
        console.log("** Start watching permission states..")
        data handledBy {
            val mutable = mutableMapOf<PermissionType, WebPermission>()

            DEFAULT.entries.forEach { (permissionType, webPermission) ->
                try {
                    val status = navigator.permissions.query(webPermission.permissionDescriptor)
                    mutable[permissionType] = webPermission.copy(permissionState = status.state)

                    console.log("** Permission status for ${status.name} is:", status.state)

                    // Set event handle to update this store whenever the permission state changes
                    status.onchange = EventHandler { eventWithStatus ->
                        val mutableMap = current.toMutableMap()
                        mutableMap[permissionType] = webPermission.copy(permissionState = eventWithStatus.currentTarget.state)
                        update(mutableMap)
                    }
                    update(mutable)
                } catch (e: Exception) {
                    console.log("Error getting permission states", e.message)
                    update(mutable.ifEmpty { DEFAULT })
                }
            }
        }
    }

    val requestLocationPermission = SimpleHandler<Unit> { data, _ ->
        val geoPositionService: GeoPositionService by koinCtx.inject()
        data handledBy {
//            geoPositionService.getPosition()
            geoPositionService.getActiveWatchIdOrNew()
        }
    }

//    val manuallyUpdateCameraPermission = handle<PermissionState> { current, cameraPermissionState ->
//        val mutableMap = current.toMutableMap()
//        val cameraWebPermission = mutableMap[PermissionType.Camera]
//        cameraWebPermission?.let { webPermission ->
//            mutableMap[PermissionType.Camera] = webPermission.copy(permissionState = cameraPermissionState)
//        }
//        mutableMap
//    }

    val print = SimpleHandler<Map<PermissionType, WebPermission>> { data, _ ->
        data handledBy { permissions ->
            console.log("Permission DATA", permissions.map { it.key.jsName to it.value.permissionState }.toString())
        }

    }

    init {
        startWatchingPermissionStates(Unit)
        data handledBy print
    }

    companion object {

        private val geolocationPermission = WebPermission(
            permissionDescriptor = PermissionDescriptor(PermissionName.geolocation),
            permissionState = PermissionState.prompt,
        )

        @Suppress("UNCHECKED_CAST_TO_EXTERNAL_INTERFACE")
        private val cameraPermission = WebPermission(
            permissionDescriptor = obj {
                this.name = "camera"
            } as PermissionDescriptor,
            permissionState = PermissionState.prompt,
        )
        /*private val notificationsPermission = WebPermission(
            permissionDescriptor = obj<PermissionDescriptor> {
                name = PermissionName.notifications
            },
            permissionState = PermissionState.prompt,
        )
        private val persistentStoragePermission = WebPermission(
            permissionDescriptor = obj<PermissionDescriptor> {
                name = PermissionName.persistentStorage
            },
            permissionState = PermissionState.prompt,
        )
        private val pushPermission = WebPermission(
            permissionDescriptor = obj<PermissionDescriptor> {
                name = PermissionName.push
            },
            permissionState = PermissionState.prompt,
        )
        private val screenWakeLockPermission = WebPermission(
            permissionDescriptor = obj<PermissionDescriptor> {
                name = PermissionName.screenWakeLock
            },
            permissionState = PermissionState.prompt,
        )
        private val xrSpatialTrackingPermission = WebPermission(
            permissionDescriptor = obj<PermissionDescriptor> {
                name = PermissionName.xrSpatialTracking
            },
            permissionState = PermissionState.prompt,
        )*/

        val DEFAULT = mapOf(
            PermissionType.Geolocation to geolocationPermission,
            PermissionType.Camera to cameraPermission,
            /*PermissionType.Notifications to notificationsPermission,
            PermissionType.PersistentStorage to persistentStoragePermission,
            PermissionType.Push to pushPermission,
            PermissionType.ScreenWakeLock to screenWakeLockPermission,
            PermissionType.XrSpatialTracking to xrSpatialTrackingPermission,*/
        )
    }
}
