package data.users.views

import apiclient.users.PublicUserProfile
import apiclient.util.isNotNullOrEmpty
import auth.CurrentWorkspaceStore
import auth.FeatureFlagStore
import auth.Features
import data.ObjectAndUserHandler
import data.users.ActiveUserStore
import data.users.UserListStore
import dev.fritz2.core.RenderContext
import dev.fritz2.core.storeOf
import koin.koinCtx
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.mapNotNull
import localization.TL
import localization.Translation
import mainmenu.RouterStore
import model.KeywordTag
import model.allowEmailInPublicProfile
import model.allowVCardInPublicProfile
import model.company
import model.emailAddresses
import model.firstName
import model.jobTitle
import model.keywords
import model.lastName
import model.linkedInLink
import model.phoneNumbers
import model.profilePhoto
import model.userId
import model.webLinks
import model.websiteLink
import styling.primaryButtonStyleParams
import styling.secondaryButtonStyleParams
import theme.FormationColors
import theme.FormationIcons
import theme.FormationUIIcons
import twcomponents.twCardSectionTitle
import twcomponents.twColOf
import twcomponents.twColOfCenter
import twcomponents.twIconCustomSize
import twcomponents.twIconSmall
import utils.mergeIfNotBlank
import webcomponents.BigButtonOption
import webcomponents.KeywordTagActionType
import webcomponents.KeywordTagType
import webcomponents.baseLayout
import webcomponents.cardSubtitle
import webcomponents.cardTitle
import webcomponents.contentScrollBox
import webcomponents.fullCard
import webcomponents.genericBigButtonSwitch
import webcomponents.inputLabelWrapper
import webcomponents.keywordTagList
import webcomponents.oneButtonFooter
import webcomponents.twoButtonFooter
import webcomponents.userOrStateIcon
import websocket.MarkerClientStore


fun RenderContext.pageOtherUserProfile() {

    val translation: Translation by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()
    val activeUserStore: ActiveUserStore by koinCtx.inject()
    val objectAndUserHandler: ObjectAndUserHandler by koinCtx.inject()
    val markerClientStore: MarkerClientStore by koinCtx.inject()
    val featureFlagStore: FeatureFlagStore by koinCtx.inject()
    val userListStore: UserListStore by koinCtx.inject()
    val currentWorkspaceStore: CurrentWorkspaceStore by koinCtx.inject()
    val userId = activeUserStore.map(PublicUserProfile.userId())
    val firstName = activeUserStore.map(PublicUserProfile.firstName())
    val lastName = activeUserStore.map(PublicUserProfile.lastName())
    val jobTitle = activeUserStore.map(PublicUserProfile.jobTitle())
    val emails = activeUserStore.map(PublicUserProfile.emailAddresses())
    val phoneNumbers = activeUserStore.map(PublicUserProfile.phoneNumbers())
    val profilePhoto = activeUserStore.map(PublicUserProfile.profilePhoto())
    val company = activeUserStore.map(PublicUserProfile.company())
    val linkedInLink = activeUserStore.map(PublicUserProfile.linkedInLink())
    val websiteLink = activeUserStore.map(PublicUserProfile.websiteLink())
    val webLinks = activeUserStore.map(PublicUserProfile.webLinks())
    val keywords = activeUserStore.map(PublicUserProfile.keywords())
    val allowEmailInPublicProfile = activeUserStore.map(PublicUserProfile.allowEmailInPublicProfile())
    val allowVCardInPublicProfile = activeUserStore.map(PublicUserProfile.allowVCardInPublicProfile())

    val profileswitchStore = storeOf(ProfileContent.Details)

    val isWorkspaceMember = combine(currentWorkspaceStore.data, userId.data) { ws, id ->
        ws?.let { id }
    }.mapNotNull { id ->
        userListStore.getPublicUserProfile(id) != null
    }

    fullCard {
        baseLayout(
            expandable = false,
            header = null,
            content = {
                contentScrollBox {
                    profilePhoto.data.render { image ->
                        twColOfCenter {
                            className("my-4")
                            // Profile Photo
                            div("relative flex items-center justify-center text-center grow-0 shrink-0 w-52 h-52 rounded-full ") {
                                if (image == null) {
                                    className("text-gray-300 border border-gray-300")
                                    twIconCustomSize(icon = FormationIcons.UserAlt, size = "100px")
                                } else {
                                    inlineStyle("--image-url:url(${image.href})")
                                    className("bg-[image:var(--image-url)] bg-center bg-cover")
                                }
                                isWorkspaceMember.render { isMember ->
                                    // Check Icon
                                    div("absolute bottom-2.5 right-2.5") {
                                        if (isMember) {
                                            twColOf {
                                                className("items-end justify-center")
                                                userOrStateIcon(
                                                    pixelSize = 40.0,
                                                    shadow = false,
                                                    icon = { FormationUIIcons.Check.icon },
                                                    iconBackground = { FormationColors.GreenActive.color },
                                                    iconSize = { larger },
                                                )
                                            }
                                        } else {
                                            twColOf {
                                                className("items-end justify-center")
                                                userOrStateIcon(
                                                    pixelSize = 40.0,
                                                    shadow = false,
                                                    icon = { FormationIcons.Caution.icon },
                                                    iconBackground = { FormationColors.GrayDisabled.color },
                                                    iconSize = { larger },
                                                )
                                            }
                                        }
                                    }
                                }
                            }
                            isWorkspaceMember.render { isMember ->
                                if (isMember) {
                                    p("text-green-500 text-xs font-light") { +"Member of ${currentWorkspaceStore.current?.name?.let { "\"$it\"" } ?: "this"} workspace" }
                                } else {
                                    p("text-gray-400 text-xs font-light") { +" Not a member of ${currentWorkspaceStore.current?.name?.let { "\"$it\"" } ?: "this"} workspace" }
                                }
                            }
                        }
                    }
                    twColOfCenter {
                        className("mb-2")
                        cardTitle(firstName.data.combine(lastName.data) { f, l -> "$f $l" })
                        cardSubtitle(jobTitle.data.mergeIfNotBlank(company.data, " | ").mapNotNull { it })
                    }
                    allowVCardInPublicProfile.data.render { showVCard ->
                        if (showVCard == true) {
                            genericBigButtonSwitch(
                                store = profileswitchStore,
                                options = listOf(
                                    BigButtonOption(
                                        title = translation[ProfileContent.Details],
                                        icon = { ProfileContent.Details.icon.icon },
                                        value = ProfileContent.Details,
                                        selectHandler = profileswitchStore.update,
                                    ),
                                    BigButtonOption(
                                        title = translation[ProfileContent.VCard],
                                        icon = { ProfileContent.VCard.icon.icon },
                                        value = ProfileContent.VCard,
                                        selectHandler = profileswitchStore.update,
                                        disabled = flowOf(!showVCard),
                                    ),
                                ),
                            )
                        } else {
                            twCardSectionTitle {
                                twIconSmall(ProfileContent.Details.icon)
                                translation[ProfileContent.Details].renderText()
                            }
                        }
                    }

                    profileswitchStore.data.render { content ->
                        when (content) {
                            ProfileContent.Details -> {
                                twColOf {
                                    // Email addresses
                                    emails.data.render { mails ->
                                        profileEmailSection(
                                            mails,
                                            userName = activeUserStore.current.name,
                                            showFullEmail = allowEmailInPublicProfile.current == true,
                                        )
                                    }

                                    // Phone numbers
                                    phoneNumbers.data.render { numbers ->
                                        profilePhoneNumberSection(numbers)
                                    }

                                    // LinkedIn link
                                    linkedInLink.data.render { link ->
                                        link?.let { linkedIn ->
                                            if (linkedIn.isNotBlank()) {
                                                inputLabelWrapper(
                                                    title = translation[TL.UserProfile.LINKEDIN],
                                                    visibilityFlow = flowOf(true),
                                                ) {
                                                    profileLinkButton(link = linkedIn, icon = FormationUIIcons.LinkedIn, target = "_blank")
                                                }
                                            }
                                        }
                                    }

                                    // Keyword tags
                                    keywords.data.renderIf({ it.isNotNullOrEmpty() }) {
                                        twCardSectionTitle {
                                            twIconSmall(FormationIcons.Tag)
                                            translation[TL.UserProfile.KEYWORDS].renderText()
                                        }
                                    }
                                    keywordTagList(
                                        keywords = keywords.data.map { keywordList ->
                                            (keywordList ?: emptyList()).map {
                                                KeywordTag(it, actionType = KeywordTagActionType.Default)
                                            }
                                        },
                                        keywordTagType = KeywordTagType.SearchTag,
                                        searchable = true,
                                    )

                                    // Website preview
                                    websiteLink.data.render { website ->
                                        if (website.isNotNullOrEmpty()) {
                                            twCardSectionTitle {
                                                twIconSmall(FormationIcons.Website)
                                                translation[TL.UserProfile.WEBSITE].renderText()
                                            }
//                                            cardTitle(translation[TL.UserProfile.WEBSITE]) { FormationIcons.Website }
                                            profileLinkPreview(website)
                                        }
                                    }

                                    // Web link previews
                                    webLinks.data.render { links ->
                                        if (links.isNotNullOrEmpty()) {
                                            twCardSectionTitle {
                                                twIconSmall(FormationUIIcons.ExternalLink)
                                                translation[TL.UserProfile.WEBLINK].renderText()
                                            }
//                                            cardTitle(translation[TL.UserProfile.WEBLINK]) { FormationUIIcons.ExternalLink }
                                            profileLinkPreviewList(links)
                                        }
                                    }
                                }
                            }

                            ProfileContent.VCard -> {
                                activeUserStore.data.render { profile ->
                                    profileVCardSection(profile)
                                }
                            }

                            else -> {}
                        }
                    }
                }
            },
            footer = {
                featureFlagStore.data.render { features ->
                    val sharing = features[Features.DisableLocationSharing] == false
                    if (sharing) {
                        twoButtonFooter(
                            secondaryTitle = translation[TL.General.BACK],
                            secondaryStyleParams = secondaryButtonStyleParams,
                            secondaryClickHandlers = listOf(routerStore.back),
                            primaryTitle = translation[TL.UserProfile.LOCATE_USER],
                            primaryState = markerClientStore.data.map { markerClientStore.isUserSharing(activeUserStore.current.userId) },
                            primaryStyleParams = primaryButtonStyleParams,
                            primaryValue = Unit,
                            primaryClickHandlers = listOf(objectAndUserHandler.locateCurrentActiveUser),
                        )
                    } else {
                        oneButtonFooter(
                            title = translation[TL.General.BACK],
                            styleParams = primaryButtonStyleParams,
                            value = Unit,
                            clickHandlers = listOf(routerStore.back),
                        )
                    }
                }

            },
        )
    }
}
