package data.objects.views.meeting

import apiclient.geoobjects.MeetingInvitationStatus
import data.objects.ActiveObjectStore
import dev.fritz2.components.compat.button
import dev.fritz2.components.compat.div
import dev.fritz2.components.compat.h6
import dev.fritz2.components.flexBox
import dev.fritz2.components.icon
import dev.fritz2.components.lineUp
import dev.fritz2.components.stackUp
import dev.fritz2.core.RenderContext
import dev.fritz2.styling.theme.IconDefinition
import dev.fritz2.styling.theme.Icons
import koin.koinCtx
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.map
import theme.FormationColors
import theme.FormationDefault.Companion.formationStyles
import theme.FormationIcons
import utils.getColor
import utils.getIcon
import utils.getName
import utils.makeRGBA
import webcomponents.ellipseText
import webcomponents.userOrStateIcon

fun RenderContext.attendeeInfoButton(
    invitationStatus: MeetingInvitationStatus? = null,
    profilePictureLink: String? = null,
    icon: (Icons.() -> IconDefinition)? = null,
    initials: String? = null,
    title: Flow<String>,
    subtitle: Flow<String?>,
) {
    flexBox(
        {
            height { maxContent }
            width { full }
            justifyContent { spaceBetween }
            alignItems { center }
            display { flex }
            padding { tiny }
            radius(formationStyles.buttonRadius)
            hover {
                background { color { FormationColors.GrayLight.color } }
            }
            background { color { secondary.main } }
            border {
                width(formationStyles.borderWidth)
                color { primary.main }
            }
        },
    ) {
        lineUp(
            {
                alignItems { center }
                overflowX { hidden }
            },
        ) {
            spacing { small }
            items {
                // ICON BOX
                flexBox(
                    {
                        flex {
                            grow { "0" }
                            shrink { "0" }
                            basis { "60px" }
                        }
                        width { "60px" }
                        height { "60px" }
                        justifyContent { center }
                        alignItems { center }
                    },
                ) {
                    // ICON ON CIRCLE
                    userOrStateIcon(
                        pixelSize = 48.0,
                        shadow = false,
                        picture = profilePictureLink,
                        initials = initials,
                        icon = icon ?: { FormationIcons.UserAlt.icon },
                    )
                }
                // TITLE & SUBTITLE
                stackUp(
                    {
                        alignItems { start }
                        overflowX { hidden }
                    },
                ) {
                    spacing { tiny }
                    items {
                        ellipseText(
                            styleParams = {
                                width { full }
                                fontSize { normal }
                                fontWeight { bold }
                                textAlign { left }
                            },
                        ) { title.renderText(into = this) }
                        subtitle.render { sub -> if (sub != null) h6({ textAlign { left } }) { +sub } }
                    }
                }
            }
        }
        // ICON ON CIRCLE
        div(
            {
                color { secondary.main }
                background { color { invitationStatus?.getColor()?.color ?: primary.main } }
                radius { full }
                padding { smaller }
                margins { right { smaller } }
                flex {
                    grow { "0" }
                    shrink { "0" }
                    basis { "40px" }
                }
            },
        ) {
            icon({ size { larger } }) { fromTheme { invitationStatus?.getIcon()?.icon ?: ban } }
        }
    }
}

fun RenderContext.invitationStatusBar(activeStatus: MeetingInvitationStatus? = null) {
    val sortedStatusList = listOf(
        MeetingInvitationStatus.Accepted,
        MeetingInvitationStatus.Maybe,
        MeetingInvitationStatus.Rejected,
    )
    lineUp(
        {
            width { full }
            alignItems { center }
            justifyContent { center }
            margins { bottom { small } }
        },
    ) {
        spacing { small }
        items {
            sortedStatusList.forEach { status ->
                invitationStatusButton(
                    isActive = activeStatus == status,
                    status = status,
                )
            }
        }
    }
}

fun RenderContext.invitationStatusButton(
    isActive: Boolean = false,
    status: MeetingInvitationStatus,
    clickable: Boolean = true,
    colored: Boolean = false,
    withIcon: Boolean = false,
) {
    val activeObjectStore: ActiveObjectStore by koinCtx.inject()
    button(
        {
            if (!clickable) css("cursor: default;")
        },
    ) {
        lineUp(
            {
                alignItems { center }
                justifyContent { center }
                radius { normal }
                paddings { horizontal { smaller } }
                fontSize { small }
                if (isActive) {
                    color { secondary.main }
                    background {
                        if (colored) color { status.getColor().color } else color { primary.main }
                    }
                } else {
                    color { primary.main }
                    background {
                        color { makeRGBA(primary.main, 0.1) }
                    }
                }
            },
        ) {
            spacing { smaller }
            items {
                span {
                    +status.getName()
                }
                if (withIcon) icon(
                    {
                        size { small }
                    },
                ) { fromTheme { status.getIcon().icon } }
            }
        }
        if (clickable) clicks.map { status } handledBy activeObjectStore.setMeetingStatus
    }
}
