// GENERATED by fritz2 - NEVER CHANGE CONTENT MANUALLY!
package model

import dev.fritz2.core.Lens
import dev.fritz2.core.lensOf

fun SignUpFromInvite.Companion.firstName(): Lens<SignUpFromInvite, String> = lensOf(
    "firstName",
    { it.firstName },
    { p, v -> p.copy(firstName = v) }
)

fun SignUpFromInvite.Companion.lastName(): Lens<SignUpFromInvite, String> = lensOf(
    "lastName",
    { it.lastName },
    { p, v -> p.copy(lastName = v) }
)

fun SignUpFromInvite.Companion.password(): Lens<SignUpFromInvite, String> = lensOf(
    "password",
    { it.password },
    { p, v -> p.copy(password = v) }
)

fun SignUpFromInvite.Companion.inviteToken(): Lens<SignUpFromInvite, String> = lensOf(
    "inviteToken",
    { it.inviteToken },
    { p, v -> p.copy(inviteToken = v) }
)

fun SignUpFromInvite.Companion.inviter(): Lens<SignUpFromInvite, String?> = lensOf(
    "inviter",
    { it.inviter },
    { p, v -> p.copy(inviter = v) }
)
