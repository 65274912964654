package data.keywordlayer

import apiclient.customfields.CustomFieldDefinition
import apiclient.customfields.FieldValue
import apiclient.groups.KeyWordLayerDefinition
import apiclient.groups.LayerMetaData
import apiclient.groups.LayerType
import auth.CurrentWorkspaceStore
import auth.FeatureFlagStore
import auth.Features
import data.heatmaplayer.ActiveHeatmapLayerDefinitionStore
import data.heatmaplayer.ActiveHeatmapLayerMetaDataStore
import data.objects.views.getSuggestedFieldDefinitions
import dev.fritz2.components.compat.button
import dev.fritz2.components.compat.div
import dev.fritz2.components.compat.span
import dev.fritz2.components.flexBox
import dev.fritz2.components.icon
import dev.fritz2.components.lineUp
import dev.fritz2.components.pushButton
import dev.fritz2.components.stackUp
import dev.fritz2.core.Handler
import dev.fritz2.core.HtmlTag
import dev.fritz2.core.RenderContext
import dev.fritz2.core.RootStore
import dev.fritz2.core.SimpleHandler
import dev.fritz2.core.Store
import dev.fritz2.core.autofocus
import dev.fritz2.core.id
import dev.fritz2.core.placeholder
import dev.fritz2.core.storeOf
import dev.fritz2.core.values
import dev.fritz2.styling.params.SizesProperty
import koin.koinCtx
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import localization.TL
import localization.Translation
import mainmenu.Pages
import mainmenu.RouterStore
import map.Cards
import model.SuggestedTagsContext
import model.defaultOn
import model.fieldValues
import model.keywords
import model.or
import model.prefix
import model.title
import org.w3c.dom.HTMLDivElement
import search.separationLine
import services.SuggestedTagsContextStore
import services.SuggestedTagsStore
import styling.primaryButtonStyleParams
import styling.secondaryButtonStyleParams
import theme.FormationColors
import theme.FormationDefault.Companion.formationStyles
import theme.FormationIcons
import utils.getName
import utils.toKeyWordTagsList
import webcomponents.BigButtonOption
import webcomponents.KeywordTagActionType
import webcomponents.KeywordTagType
import webcomponents.Position
import webcomponents.baseLayout
import webcomponents.cardTitle
import webcomponents.contentScrollBox
import webcomponents.genericBigButtonSwitch
import webcomponents.genericInput
import webcomponents.genericSmallIconButton
import webcomponents.inputLabelWrapper
import webcomponents.keywordTagList
import webcomponents.switchBox
import webcomponents.twoButtonFooter

class MapLayerTypeSwitchStore : RootStore<LayerType?>(
    initialData = LayerType.Keyword,
    job = Job(),
) {
    val keyWordTagType
        get() = when (current) {
            LayerType.Heatmap -> KeywordTagType.HeatmapLayerTag
            else -> KeywordTagType.KeywordLayerTag
        }.also { console.log("Get KeywordTagType:", it) }
}

fun RenderContext.cardManageMapLayer(pageKey: TL.LayerAction = TL.LayerAction.CREATE_NEW_LAYER) {
    val routerStore: RouterStore by koinCtx.inject()
    val translation: Translation by koinCtx.inject()
    val mapLayerTypeSwitchStore: MapLayerTypeSwitchStore by koinCtx.inject()
    val suggestedTagsStore: SuggestedTagsStore by koinCtx.inject()
    val suggestedTagsContextStore: SuggestedTagsContextStore by koinCtx.inject()
    val featureFlagStore by koinCtx.inject<FeatureFlagStore>()
    val currentWorkspaceStore: CurrentWorkspaceStore by koinCtx.inject()

    // HeatmapLayer stores
    val activeHeatmapLayerDefinitionStore: ActiveHeatmapLayerDefinitionStore by koinCtx.inject()
    val activeHeatmapLayerMetaDataStore: ActiveHeatmapLayerMetaDataStore by koinCtx.inject()
    val heatmapLayerTitle = activeHeatmapLayerDefinitionStore.titleSub
    val heatmapLayerDefaultOn = activeHeatmapLayerMetaDataStore.deflaultOnSub
    val addedHeatmapLayerFieldValuesStore = activeHeatmapLayerDefinitionStore.fieldValuesSub
    val suggestedHeatmapFieldDefinitionsFlow =
        getSuggestedFieldDefinitions(addedHeatmapLayerFieldValuesStore.data, currentWorkspaceStore)
    val heatmapLayerKeywords = activeHeatmapLayerDefinitionStore.keywordsSub
    val heatmapLayerObjectTypes = activeHeatmapLayerDefinitionStore.objectTypesCtxSub

    // KeywordLayer stores
    val activeKeywordLayerDefinitionStore: ActiveKeywordLayerDefinitionStore by koinCtx.inject()
    val activeKeywordLayerMetaDataStore: ActiveKeywordLayerMetaDataStore by koinCtx.inject()
    val keywordLayerTitle = activeKeywordLayerMetaDataStore.map(LayerMetaData.title())
    val keywordLayerDefaultOn = activeKeywordLayerMetaDataStore.map(LayerMetaData.defaultOn())
    val addedKeywordLayerFieldValuesFlow =
        activeKeywordLayerDefinitionStore.map(KeyWordLayerDefinition.fieldValues()).data.map { it.getFieldValueList() }
    val suggestedKeywordFieldDefinitionsFlow =
        getSuggestedFieldDefinitions(addedKeywordLayerFieldValuesFlow, currentWorkspaceStore)
    val keywordLayerKeywords = activeKeywordLayerDefinitionStore.map(KeyWordLayerDefinition.keywords())
    val tagPrefix = suggestedTagsContextStore.map(SuggestedTagsContext.prefix())

    val tagsMatching = activeKeywordLayerDefinitionStore.map(KeyWordLayerDefinition.or())

    // TODO make version of this card to edit standard layers
    //  -> only title and defaultOn editable

    // Shared handlers
    var title: Store<String>
    var defaultOn: Store<Boolean>

    mapLayerTypeSwitchStore.data.render { layerType ->

        when (layerType) {
            LayerType.Keyword -> {
                title = keywordLayerTitle
                defaultOn = keywordLayerDefaultOn
            }

            LayerType.Heatmap -> {
                title = heatmapLayerTitle
                defaultOn = heatmapLayerDefaultOn
            }

            else -> {
                title = storeOf("")
                defaultOn = storeOf(false)
            }
        }

        featureFlagStore.data.render { features ->
            baseLayout(
                header = {
                    if (features[Features.DisableHeatmapEditUI] == false) {
                        if (pageKey == TL.LayerAction.CREATE_NEW_LAYER) {
                            cardTitle(translation[pageKey])
                            genericBigButtonSwitch(
                                mapLayerTypeSwitchStore, options = listOf(
                                    BigButtonOption(
                                        title = translation[TL.LayerTypeTranslation.OBJECT].map { it.uppercase() },
                                        value = LayerType.Keyword,
                                        selectHandler = mapLayerTypeSwitchStore.update
                                    ),
                                    BigButtonOption(
                                        title = translation[TL.LayerTypeTranslation.HEATMAP].map { it.uppercase() },
                                        value = LayerType.Heatmap,
                                        selectHandler = mapLayerTypeSwitchStore.update
                                    )
                                )
                            )
                        } else {
                            // -> TL.LayerAction.EDIT_LAYER
                            cardTitle(
                                translation[
                                    pageKey,
                                    mapOf(
                                        "layerType" to when (layerType) {
                                            LayerType.Keyword -> translation.getString(TL.LayerTypeTranslation.OBJECT)
                                            LayerType.Heatmap -> translation.getString(TL.LayerTypeTranslation.HEATMAP)
                                            else -> translation.getString(TL.LayerTypeTranslation.OBJECT)
                                        }
                                    )
                                ]
                            )
                        }
                    } else {
                        cardTitle(
                            translation[
                                pageKey,
                                mapOf("layerType" to translation.getString(TL.LayerTypeTranslation.OBJECT))
                            ]
                        )
                    }
                },
                content = {
                    contentScrollBox {
                        stackUp {
                            spacing { normal }
                            items {
                                // LAYER TITLE
                                inputLabelWrapper(
                                    title = translation[TL.CardManageKeywordLayer.TITLE_INPUT_PLACEHOLDER],
                                    visibilityFlow = title.data.map { it.isNotBlank() }
                                ) {
                                    genericInput(
                                        value = title.data,
                                    ) {
                                        id("customLayerTitle")
                                        placeholder(translation[TL.CardManageKeywordLayer.TITLE_INPUT_PLACEHOLDER])
                                        inputs.values() handledBy title.update
                                    }
                                }
                                // DEFAULT VISIBILITY SWITCH
                                lineUp {
                                    spacing { small }
                                    items {
                                        switchBox(
                                            position = Position.Right,
                                            store = defaultOn,
                                        ) {
                                            span({
                                                margins { left { smaller } }
                                            }) {
                                                translation[TL.CardManageKeywordLayer.DEFAULT_VISIBILITY_DESC].renderText(
                                                    into = this
                                                )
                                            }
                                        }
                                    }
                                }

                                separationLine()

                                when {
                                    // HEATMAP LAYER
                                    layerType == LayerType.Heatmap && features[Features.DisableHeatmapEditUI] == false -> {

                                        // FieldValue tags section
                                        fieldValueTagsSection(
                                            addedHeatmapLayerFieldValuesStore.data,
                                            suggestedHeatmapFieldDefinitionsFlow,
                                            KeywordTagType.HeatmapLayerTag
                                        )

                                        // Show the history event keyword tags that are in use for filtering
                                        heatmapLayerKeywords.data.render { rawTags ->
                                            if (rawTags.isNotEmpty()) {
                                                separationLine(margins = { top { normal } })
                                                lineUp({
                                                    alignItems { center }
                                                    justifyContent { start }
                                                    color { FormationColors.GrayDisabled.color }
                                                    margins {
                                                        vertical { normal }
                                                    }
                                                }) {
                                                    spacing { tiny }
                                                    items {
                                                        icon { fromTheme { FormationIcons.Tag.icon } }
                                                        span({
                                                            fontSize { normal }
                                                            fontWeight { bold }
                                                        }) {
                                                            translation[TL.KeywordTags.TAGS].renderText(into = this)
                                                        }
                                                    }
                                                }
                                                span({
                                                    color { FormationColors.GrayDisabled.color }
                                                }) {
                                                    stackUp {
                                                        spacing { small }
                                                        items {
                                                            rawTags.forEach {
                                                                span({
                                                                    fontSize { smaller }
                                                                    fontFamily { mono }
                                                                }) { +it }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        // Show history event objectTypes that are in use for filtering
                                        heatmapLayerObjectTypes.data.render { objectTypes ->
                                            if (!objectTypes.isNullOrEmpty()) {
                                                span({
                                                    color { FormationColors.GrayDisabled.color }
                                                }) {
                                                    stackUp {
                                                        spacing { small }
                                                        items {
                                                            objectTypes.forEach {
                                                                span({
                                                                    fontSize { smaller }
                                                                    fontFamily { mono }
                                                                }) { +it.getName() }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    // KEYWORD LAYER
                                    layerType == LayerType.Keyword -> {

                                        // FieldValue tags section
                                        fieldValueTagsSection(
                                            addedKeywordLayerFieldValuesFlow,
                                            suggestedKeywordFieldDefinitionsFlow,
                                            KeywordTagType.KeywordLayerTag
                                        )

                                        // Keyword tags section
                                        tagsSection(
                                            keywordLayerKeywords,
                                            suggestedTagsStore,
                                            tagPrefix,
                                            activeKeywordLayerDefinitionStore.addLayerKeywordFromInput,
                                            KeywordTagType.KeywordLayerTag
                                        )

                                        separationLine()

                                        // Tag matching section
                                        stackUp {
                                            spacing { small }
                                            items {
                                                radioButton(
                                                    selected = tagsMatching.data,
                                                    value = true,
                                                    label = translation[TL.CardManageKeywordLayer.ANY_TAG_CAN_MATCH],
                                                    clickHandlers = listOf(tagsMatching.update)
                                                )
                                                radioButton(
                                                    selected = tagsMatching.data.map { !it },
                                                    value = false,
                                                    label = translation[TL.CardManageKeywordLayer.ALL_TAGS_MUST_MATCH],
                                                    clickHandlers = listOf(tagsMatching.update)
                                                )
                                            }
                                        }
                                    }

                                    else -> {

                                    }
                                }

                                // DELETE BUTTON
                                if (pageKey == TL.LayerAction.EDIT_LAYER) {

                                    separationLine()

                                    lineUp({
                                        alignItems { center }
                                    }) {
                                        spacing { small }
                                        items {
                                            pushButton({
                                                radius { full }
                                                padding { tiny }
//                                            margins {
//                                                right { small }
//                                            }
                                            }) {
                                                size { large }
                                                type { danger }
                                                variant { outline }
                                                icon { FormationIcons.DeleteAlt.icon }
                                                events {
                                                    clicks.map {
                                                        mapOf(
                                                            "page" to Pages.Map.name,
                                                            "card" to Cards.MapLayers.name,
                                                            "mapLayer" to when (layerType) {
                                                                LayerType.Keyword -> "deleteKeywordLayer"
                                                                LayerType.Heatmap -> "deleteHeatmapLayer"
                                                                else -> ""
                                                            },
                                                        )
                                                    } handledBy routerStore.validateInternalRoute
                                                }
                                            }
                                            span {
                                                translation[TL.LayerAction.DELETE_LAYER].renderText(into = this)
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                },
                footer = {
                    twoButtonFooter(
                        secondaryTitle = translation[TL.General.CANCEL],
                        secondaryStyleParams = secondaryButtonStyleParams,
                        secondaryClickHandlers = listOf(
                            activeKeywordLayerDefinitionStore.reset,
                            activeHeatmapLayerDefinitionStore.reset,
                            routerStore.back
                        ),
                        primaryTitle = when (pageKey) {
                            TL.LayerAction.CREATE_NEW_LAYER -> translation[TL.General.CREATE]
                            else -> translation[TL.General.SAVE]
                        },
                        primaryStyleParams = primaryButtonStyleParams,
                        primaryValue = Unit,
                        primaryClickHandlers = when (layerType) {
                            LayerType.Keyword -> {
                                when (pageKey) {
                                    TL.LayerAction.CREATE_NEW_LAYER -> listOf(
                                        activeKeywordLayerDefinitionStore.createKeyWordLayer,
                                        routerStore.back
                                    )

                                    TL.LayerAction.EDIT_LAYER -> listOf(
                                        activeKeywordLayerDefinitionStore.updateKeyWordLayer,
                                        routerStore.back
                                    )

                                    else -> listOf()
                                }
                            }

                            LayerType.Heatmap -> {
                                when (pageKey) {
                                    TL.LayerAction.CREATE_NEW_LAYER -> listOf(
                                        activeHeatmapLayerDefinitionStore.createHeatmapLayer,
                                        routerStore.back
                                    )

                                    TL.LayerAction.EDIT_LAYER -> listOf(
                                        activeHeatmapLayerDefinitionStore.updateHeatmapLayer,
                                        routerStore.back
                                    )

                                    else -> listOf()
                                }
                            }

                            else -> listOf()
                        },
                    )
                }
            )

        }
    }
}

fun <T> RenderContext.radioButton(
    size: SizesProperty = { huge },
    dotSize: SizesProperty = { large },
    position: Position = Position.Left,
    selected: Flow<Boolean>,
    label: Flow<String>? = null,
    value: T,
    clickHandlers: List<Handler<T>>? = null,
    content: (HtmlTag<HTMLDivElement>.() -> Unit)? = null,
) {
    button({
        width { full }
    }) {
        flexBox({
            direction { row }
            width { full }
            height { auto }  //At some point define a size for all the switch boxes
            justifyContent { flexStart }
            alignItems { center }
            fontSize { small }
            textAlign { left }
        }) {
            if (position == Position.Right) {
                div({
                    margins { right { smaller } }
                }) {
                    content?.invoke(this) ?: span {
                        label?.renderText(into = this)
                    }
                }

            }
            flexBox({
                size(size)
                radius { full }
                border {
                    width(formationStyles.borderWidth)
                    color { primary.main }
                }
                background {
                    color { secondary.main }
                }
                alignItems { center }
                justifyContent { center }
            }) {
                selected.render { isSelected ->
                    if (isSelected) {
                        div({
                            size(dotSize)
                            radius { full }
                            background {
                                color { primary.main }
                            }
                        }) { }
                    }
                }
            }
            if (position == Position.Left) {
                div({
                    margins { left { smaller } }
                }) {
                    content?.invoke(this) ?: span {
                        label?.renderText(into = this)
                    }
                }
            }
        }
        clickHandlers?.forEach { handler ->
            clicks.map { value } handledBy handler
        }
    }
}

fun RenderContext.fieldValueTagsSection(
    addedFieldValuesFlow: Flow<List<FieldValue>>,
    suggestedFieldDefinitions: Flow<List<CustomFieldDefinition>>,
    keywordTagType: KeywordTagType,
) {
    val translation by koinCtx.inject<Translation>()

    // FieldValue Tags
    lineUp({
        alignItems { center }
        justifyContent { start }
    }) {
        spacing { tiny }
        items {
            icon { fromTheme { grid } }
            span({
                fontSize { normal }
                fontWeight { bold }
            }) {
                translation[TL.KeywordTags.VALUE_TAGS].renderText(into = this)
            }
        }
    }

    // Added fieldValue tags
    div({
//      margins { vertical { small } }
        css("align-self: start;")
    }) {
        lineUp {
            spacing { tiny }
            items {
                span({ fontSize { normal } }) {
                    translation[TL.KeywordTags.ADDED].renderText(into = this)
                }
                span({
                    color { primary.main }
                    fontSize { normal }
                    fontWeight { bold }
                }) {
                    addedFieldValuesFlow.map { it.size }.renderText(into = this)
                }
            }
        }
    }
    flexBox({
        width { full }
//      margins { bottom { small } }
    }) {
        keywordTagList(
            keywords = addedFieldValuesFlow.map {
                it.toKeyWordTagsList(KeywordTagActionType.RemoveFieldValue)
            },
            keywordTagType = keywordTagType
        )
    }

    // Suggested fieldValue tags (workspace specific)
    suggestedFieldDefinitions.render { workspaceFieldDefinitions ->
        div({
            margins { vertical { small } }
            css("align-self: start;")
        }) {
            span({ fontSize { normal } }) {
                translation[TL.KeywordTags.SUGGESTED].renderText(into = this)
            }
        }
        if (workspaceFieldDefinitions.isNotEmpty()) {
            flexBox({
                width { full }
                flex {
                    grow { "1" }
                    shrink { "1" }
                    basis { auto }
                }
                overflowY { auto }
            }) {
                keywordTagList(
                    keywords = flowOf(
                        workspaceFieldDefinitions.toKeyWordTagsList(
                            KeywordTagActionType.AddFieldValue
                        )
                    ),
                    keywordTagType = keywordTagType
                )
            }
        } else {
            flexBox({
                width { full }
                flex {
                    grow { "1" }
                    shrink { "1" }
                    basis { "50px" }
                }
                alignItems { center }
                justifyContent { center }
                direction { column }
            }) {
                flexBox({ flex { grow { "1" } } }) { }
                span { translation[TL.Search.NO_RESULTS].renderText(into = this) }
                flexBox({ flex { grow { "3" } } }) { }
            }
        }
    }
}

fun RenderContext.tagsSection(
    keywordTagsStore: Store<List<String>>,
    suggestedKeywordTagsStore: Store<List<String>>,
    tagPrefixStore: Store<String>,
    addKeywordHandler: SimpleHandler<Unit>,
    keywordTagType: KeywordTagType,
) {
    val translation by koinCtx.inject<Translation>()

    // Tags
    lineUp({
        alignItems { center }
        justifyContent { start }
    }) {
        spacing { tiny }
        items {
            icon { fromTheme { FormationIcons.Tag.icon } }
            span({
                fontSize { normal }
                fontWeight { bold }
            }) {
                translation[TL.KeywordTags.TAGS].renderText(into = this)
            }
        }
    }

    // Added tags
    div({
//      margins { top { small } }
        css("align-self: start;")
    }) {
        lineUp {
            spacing { tiny }
            items {
                span({ fontSize { normal } }) {
                    translation[TL.KeywordTags.ADDED].renderText(into = this)
                }
                span({
                    color { FormationColors.MarkerYou.color }
                    fontSize { normal }
                    fontWeight { bold }
                }) { keywordTagsStore.data.map { it.size }.renderText(into = this) }
            }
        }
    }
    flexBox({
        width { full }
        flex {
            grow { "1" }
            shrink { "1" }
            basis { auto }
        }
        overflowY { auto }
    }) {
        keywordTagList(
            keywords = keywordTagsStore.data.map { it.toKeyWordTagsList(type = KeywordTagActionType.Remove) },
            keywordTagType = KeywordTagType.KeywordLayerTag
        )
    }

    // Search input to filter suggested tags
    inputLabelWrapper(
        title = translation[TL.KeywordTags.TAG_INPUT_PLACEHOLDER_KEYWORD_LAYER],
        visibilityFlow = tagPrefixStore.data.map { it.isNotBlank() }
    ) {
        genericInput(
            value = tagPrefixStore.data,
            enterKeyHandlers = listOf(addKeywordHandler),
        ) {
            id("inputKeywords")
            placeholder(translation[TL.KeywordTags.TAG_INPUT_PLACEHOLDER_KEYWORD_LAYER])
            autofocus(true)
            attr("tabindex", "0")
            inputs.values() handledBy tagPrefixStore.update
        }
    }

    // Create tag button
    flexBox({
        width { full }
        alignItems { center }
        justifyContent { end }
    }) {
        genericSmallIconButton(
            title = flowOf("Create tag"),
            icon = { FormationIcons.Create.icon },
            iconPosition = Position.Left,
            hrefOrValue = Unit,
            clickHandlers = listOf(addKeywordHandler),
            disabledFlow = combine(
                tagPrefixStore.data,
                suggestedKeywordTagsStore.data,
                keywordTagsStore.data
            ) { typed, suggested, added ->
                typed.isBlank() || suggested.contains(typed) || added.contains(
                    typed
                )
            }
        )
    }

    // Suggested tags
    div({
        margins { vertical { small } }
        css("align-self: start;")
    }) {
        span({ fontSize { normal } }) {
            translation[TL.KeywordTags.SUGGESTED].renderText(into = this)
        }
    }
    keywordTagsStore.data.combine(suggestedKeywordTagsStore.data) { selected, suggested ->
        suggested - (selected).toSet()
    }.render { suggestedTags ->
        if (suggestedTags.isNotEmpty()) {
            flexBox({
                width { full }
                flex {
                    grow { "1" }
                    shrink { "1" }
                    basis { auto }
                }
                overflowY { auto }
            }) {
                keywordTagList(
                    keywords = flowOf(
                        suggestedTags.toKeyWordTagsList(
                            KeywordTagActionType.Add
                        )
                    ),
                    keywordTagType = keywordTagType
                )
            }
        } else {
            flexBox({
                width { full }
                flex {
                    grow { "1" }
                    shrink { "1" }
                    basis { auto }
                }
                alignItems { center }
                justifyContent { center }
                direction { column }
            }) {
                flexBox({ flex { grow { "1" } } }) { }
                span { translation[TL.Search.NO_RESULTS].renderText(into = this) }
                flexBox({ flex { grow { "3" } } }) { }
            }
        }
    }
}
