@file:Suppress("RedundantUnitExpression")

package webcomponents

import analytics.AnalyticsService
import apiclient.websocket.MessageToServer
import com.tryformation.localization.Translatable
import dev.fritz2.components.compat.Span
import dev.fritz2.components.compat.button
import dev.fritz2.components.compat.div
import dev.fritz2.components.compat.input
import dev.fritz2.components.compat.span
import dev.fritz2.components.compat.textarea
import dev.fritz2.components.flexBox
import dev.fritz2.components.icon
import dev.fritz2.components.lineUp
import dev.fritz2.components.pushButton
import dev.fritz2.components.stackUp
import dev.fritz2.core.Handler
import dev.fritz2.core.HtmlTag
import dev.fritz2.core.RenderContext
import dev.fritz2.core.Store
import dev.fritz2.core.autofocus
import dev.fritz2.core.disabled
import dev.fritz2.core.download
import dev.fritz2.core.href
import dev.fritz2.core.maxLength
import dev.fritz2.core.placeholder
import dev.fritz2.core.readOnly
import dev.fritz2.core.required
import dev.fritz2.core.storeOf
import dev.fritz2.core.target
import dev.fritz2.core.title
import dev.fritz2.core.type
import dev.fritz2.core.value
import dev.fritz2.styling.params.BasicParams
import dev.fritz2.styling.params.BorderContext
import dev.fritz2.styling.params.BoxParams
import dev.fritz2.styling.params.ColorProperty
import dev.fritz2.styling.params.FlexParams
import dev.fritz2.styling.params.ScaledValueProperty
import dev.fritz2.styling.params.SizesProperty
import dev.fritz2.styling.params.SpacesContext
import dev.fritz2.styling.params.Style
import dev.fritz2.styling.params.plus
import dev.fritz2.styling.params.rgba
import dev.fritz2.styling.params.shadow
import dev.fritz2.styling.theme.ColorScheme
import dev.fritz2.styling.theme.Colors
import dev.fritz2.styling.theme.IconDefinition
import dev.fritz2.styling.theme.Icons
import dev.fritz2.styling.theme.Property
import dev.fritz2.styling.theme.PushButtonTypes
import dev.fritz2.tracking.Tracker
import koin.koinCtx
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.mapNotNull
import localization.TL
import localization.Translation
import localization.translate
import mainmenu.RouterStore
import org.w3c.dom.HTMLButtonElement
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLInputElement
import search.filterButtonDisabledStyle
import search.filterButtonOffStyle
import search.filterButtonOnStyle
import styling.genericButtonStyleParams
import styling.primaryButtonStyleParams
import styling.secondaryButtonStyleParams
import theme.FormationColors
import theme.FormationDefault.Companion.formationStyles
import theme.FormationIcons
import theme.FormationUIIcons
import utils.Quadruple
import utils.makeRGBA


fun RenderContext.inputLabelWrapper(
    title: Translatable,
    visibilityFlow: Flow<Boolean> = flowOf(true),
    input: HtmlTag<HTMLElement>.() -> Unit = {},
) {
    label("w-full") {
        span("text-xs font-light text-gray-400 ml-4") {
            className(visibilityFlow.map { if (it) "visible" else "invisible" })
            translate(title)
        }
        input()
    }
}

@Deprecated("Use version with Translatable")
fun RenderContext.inputLabelWrapper(
    title: Flow<String>,
    visibilityFlow: Flow<Boolean> = flowOf(true),
    input: HtmlTag<HTMLElement>.() -> Unit = {},
) {
    label("w-full") {
        span("text-xs font-light text-gray-400 ml-4") {
            className(visibilityFlow.map { if (it) "visible" else "invisible" })
            title.renderText(into = this)
        }
        input()
    }
}

fun RenderContext.inputLabelWrapperForToolbar(
    title: Translatable,
    visibilityFlow: Flow<Boolean> = flowOf(true),
    input: HtmlTag<HTMLElement>.() -> Unit = {},
) {
    label("flex flex-col gap-1") {
        div("text-xs font-light text-gray-400 ml-4") {
            className(visibilityFlow.map { if (it) "visible" else "invisible" })
            translate(title)
        }
        input()
    }
}


fun RenderContext.genericInput(
    value: Flow<String>? = null,
    enterKeyHandlers: List<Handler<Unit>>? = null,
    type: String? = null,
    maxLength: Int? = null,
    disabled: Boolean = false,
    width: SizesProperty = { full },
    minWidth: SizesProperty? = null,
    maxWidth: SizesProperty? = null,
    leftContentBox: (HtmlTag<HTMLElement>.() -> Unit)? = null,
    rightContentBox: (HtmlTag<HTMLElement>.() -> Unit)? = null,
    content: HtmlTag<HTMLInputElement>.() -> Unit = {},
) {
    flexBox(
        {
            width(width)
            minWidth?.let { minWidth(it) }
            maxWidth?.let { maxWidth(it) }
            height(formationStyles.inputHeight)
            minHeight(formationStyles.inputHeight)
            maxHeight(formationStyles.inputHeight)
            if (disabled) {
                color { FormationColors.GrayDisabled.color }
                background { color { FormationColors.GrayPrivate.color } }
            } else {
                color { primary.main }
                background { color { FormationColors.GrayLight.color } }
            }
            radius(formationStyles.inputRadius)
            direction { row }
            alignItems { center }
            justifyContent { spaceBetween }
        },
    ) {
        // left content box
        if (leftContentBox != null) {
            leftContentBox()
        }
        // Input field
        input(
            {
                width { full }
                height { full }
                radii {
                    if (leftContentBox == null) {
                        left(formationStyles.inputRadius)
                    }
                    if (rightContentBox == null) {
                        right(formationStyles.inputRadius)
                    }
                }
                paddings {
                    horizontal { small }
                    if (leftContentBox != null) {
                        left { none }
                    }
                    if (rightContentBox != null) {
                        right { none }
                    }
                }
                if (disabled) {
                    color { FormationColors.GrayDisabled.color }
                    background { color { FormationColors.GrayPrivate.color } }
                } else {
                    color { primary.main }
                    background { color { FormationColors.GrayLight.color } }
                }
                fontSize { small }
            },
        ) {
            value?.also { value(it) }
            maxLength?.let { maxLength(it) }
            disabled(disabled)
            // hack to show a custom placeholder in date and time fields (especially for mobile)
            if (type != null && type != "password") {
                if (type == "number") {
                    attr("min", "1")
                    attr("step", "1")
                    attr("oninput", "this.value|=0")
                }
                if (value == null) type("text")
                else type(value.map { if (it.isNotBlank()) type else "text" })
                //attr("onmouseenter", "(this.type='$type')")
                attr("onClick", "(this.type='$type')")
                attr("onmouseover", "(this.type='$type')")
                //attr("onmouseout", "(this.value == '' ? this.type='text' : this.type='$type')")
                attr("onblur", "(this.value == '' ? this.type='text' : this.type='$type')")

            } else if (type == "password") {
                type("password")
            }
            required(false)
            autofocus(false)
            enterKeyHandlers?.forEach { keyHandler ->
                keydowns.mapNotNull {
                    if (it.keyCode == 13) {
                        Unit
                    } else null
                } handledBy keyHandler
            }
            content()
        }
        if (rightContentBox != null) {
            rightContentBox()
        }
    }
}

fun RenderContext.inputIconButton(
    iconFlow: Flow<(Icons.() -> IconDefinition)>,
    iconColor: Flow<ColorProperty?> = flowOf(null),
    iconSize: SizesProperty = formationStyles.inputIconButtonSize,
    content: HtmlTag<HTMLButtonElement>.() -> Unit = {},
) {
    button(
        {
            height { full }
            radii {
                left { none }
                right(formationStyles.inputRadius)
            }
            hover {
                background { color { makeRGBA(primary.main, 0.1) } }
            }
        },
    ) {
        type("button")
        iconFlow.combine(iconColor) { i, c -> Pair(i, c) }.render { (icon, color) ->
            icon(
                {
                    size(iconSize)
                    color?.let { color { it } }
                    margins { horizontal { smaller } }
                },
            ) {
                fromTheme(icon)
            }
        }
        content()
    }
}

fun RenderContext.inputIconToggleButton(
    iconFalse: (Icons.() -> IconDefinition),
    iconTrue: (Icons.() -> IconDefinition) = iconFalse,
    iconSize: SizesProperty = formationStyles.inputIconButtonSize,
    styleFlow: Flow<Style<BasicParams>>? = null,
    defaultValue: Boolean = false,
    boolStore: Store<Boolean> = storeOf(defaultValue, job),
    optBoolFlow: Flow<Boolean>? = null,
    optToggleHandlers: List<Handler<Boolean>>? = null,
    attributes: List<Pair<String, String>> = emptyList(),
) {
    (optBoolFlow ?: boolStore.data).render { boolValue ->
        button(
            {
                height { full }
                radii {
                    left { none }
                    right(formationStyles.inputRadius)
                }
                hover {
                    background { color { makeRGBA(primary.main, 0.1) } }
                }
                styleFlow?.render { style ->
                    style()
                }
            },
        ) {
            type("button")
            attributes.forEach { (key, value) ->
                attr(key, value)
            }
            icon(
                {
                    size(iconSize)
                    margins { horizontal { smaller } }
                },
            ) {
                fromTheme(if (boolValue) iconTrue else iconFalse)
            }
            optToggleHandlers?.forEach { handler ->
                clicks.map { !boolValue } handledBy handler
            }
            clicks.map { !boolValue } handledBy boolStore.update
        }
    }
}

fun <T> RenderContext.circleIconButton(
    iconFlow: Flow<(Icons.() -> IconDefinition)>,
    size: SizesProperty,
    iconSize: SizesProperty,
    styleFlow: Flow<Style<BasicParams>>,
    hoverStyle: Style<BasicParams>? = null,
    tooltipText: Flow<String>? = null,
    disabled: Boolean = false,
    onlyDisabledLook: Boolean = false,
    value: T,
    clickHandlers: List<Handler<T>>? = null,
    clickHandlersFlow: Flow<List<Handler<T>>>? = null,
    routingMap: Map<String, String>? = null,
    addOrReplaceRoute: Map<String, String>? = null,
    analyticsEventProvider: (() -> MessageToServer.AnalyticsEvent)? = null,
) {
    val routerStore: RouterStore = koinCtx.get()
    val analyticsService by koinCtx.inject<AnalyticsService>()
    combine(styleFlow, clickHandlersFlow ?: flowOf(clickHandlers)) { s, c -> Pair(s, c) }.render { (buttonStyle, handlers) ->
        flexBox(
            {
                buttonStyle()
                radius { full }
                height(size)
                width(size)
                if (hoverStyle != null) {
                    hover { hoverStyle() }
                }
                if (disabled || onlyDisabledLook) {
                    color { secondary.main }
                    background { color { FormationColors.GrayPrivate.color } }
                    border { color { FormationColors.GrayPrivate.color } }
                    hover {
                        color { secondary.main }
                        background { color { FormationColors.GrayPrivate.color } }
                        border { color { FormationColors.GrayPrivate.color } }
                    }
                }
                css("cursor: pointer;")
                justifyContent { center }
                alignItems { center }
                padding { smaller }
            },
        ) {
            attr("onClick", "blur();")
            iconFlow.render { icon ->
                icon(
                    {
                        size(iconSize)
//                        margin { tiny }
                    },
                ) { fromTheme(icon) }
            }
            tooltipText?.also { title(it) }
            if (!disabled) {
                with(clicks) {
                    handlers?.forEach { handler ->
                        this.map { value } handledBy handler
                    }
                    if (routingMap != null) {
                        this.map { routingMap } handledBy routerStore.validateInternalRoute
                    }
                    if (addOrReplaceRoute != null) {
                        this.map { addOrReplaceRoute } handledBy routerStore.addOrReplaceRoute
                    }
                    analyticsEventProvider?.let { analytics -> this.map { analytics.invoke() } handledBy analyticsService.analyticsEvent }
                }
            }
        }
    }
}

fun RenderContext.multiSelectorButtonHolder(block: (HtmlTag<HTMLDivElement>.() -> Unit)? = null) {
    div {
        stackUp(
            {
                width { full }
                //height { auto }
                justifyContent { center }
                alignItems { stretch }
            },
        ) {
            spacing { tiny }
            items {
                block?.invoke(this@div)
            }
        }
    }
}

fun RenderContext.readOnlyTextArea(
    value: String,
    placeHolder: Flow<String>,
    resizable: Boolean = false,
    height: SizesProperty? = { maxContent },
    maxHeight: SizesProperty? = { full },
    margins: SpacesContext.() -> Unit = {},
) {
    textarea(
        {
            height(height)
            maxHeight(maxHeight)
            paddings {
                vertical { tiny }
                horizontal { smaller }
            }
            fontSize { small }
            radius { normal }
            background { color { secondary.main } }
            border {
                color { FormationColors.GrayPrivate.color }
                width { hair }
            }
            margins(margins)
            flex { shrink { "0" } }
        },
    ) {
        readOnly(true)
        if (!resizable) inlineStyle("resize: none;")
        placeholder(placeHolder)
        value(value)
    }
}

fun RenderContext.selectorButton(
    disabled: Boolean = false,
    width: SizesProperty = { full },
    radius: ScaledValueProperty? = null,
    margins: SpacesContext.() -> Unit = { top { small } },
    paddings: (SpacesContext.() -> Unit)? = null,
    border: (BorderContext.() -> Unit)? = null,
    selectorIcon: (Icons.() -> IconDefinition)? = { chevronRight },
    selectorIconColor: (Colors.() -> ColorProperty)? = { primary.main },
    selectorIconMargins: SpacesContext.() -> Unit = { horizontal { tiny } },
    additionalStyle: Style<BasicParams>? = null,
    content: (HtmlTag<HTMLDivElement>.() -> Unit)? = null,
) {
    button(
        {
            width(width)
            minHeight(formationStyles.inputHeight)
            margins(margins)
            overflowX { hidden }
        },
        id = "selector-button",
    ) {
        flexBox(
            {
                direction { row }
                width { full }
                height { full }
                minHeight(formationStyles.inputHeight)
                justifyContent { spaceBetween }
                alignItems { center }
                radius(radius ?: formationStyles.inputRadius)
                border(
                    border ?: {
                        width(formationStyles.borderWidth)
                        if (!disabled) {
                            color { primary.main }
                        } else {
                            color { FormationColors.GrayDisabled.color }
                        }

                    },
                )
                if (disabled) {
                    color { FormationColors.GrayDisabled.color }
                }
                hover {
                    background { color { FormationColors.GrayLight.color } }
                }
                overflowX { hidden }
                paddings?.let { paddings(it) }
                additionalStyle?.let { it() }
            },
            id = "selector-button-box",
        ) {
            content?.invoke(this)
            selectorIcon?.let { icon ->
                icon(
                    {
                        margins(selectorIconMargins)
                        selectorIconColor?.let { color ->
                            color(color)
                        }
                    },
                ) { fromTheme(icon) }
            }
        }
    }
}

fun RenderContext.selectorButtonFrame(
    width: SizesProperty = { full },
    radius: ScaledValueProperty? = null,
    margins: SpacesContext.() -> Unit = { top { small } },
    paddings: (SpacesContext.() -> Unit)? = null,
    border: (BorderContext.() -> Unit)? = null,
    content: (HtmlTag<HTMLDivElement>.() -> Unit)? = null,
) {
    flexBox(
        {
            width(width)
            minHeight(formationStyles.inputHeight)
            margins(margins)
            overflowX { hidden }
        },
    ) {
        flexBox(
            {
                direction { row }
                width { full }
                height { full }
                minHeight(formationStyles.inputHeight)
                justifyContent { spaceBetween }
                alignItems { center }
                radius(radius ?: formationStyles.inputRadius)
                border(
                    border ?: {
                        width(formationStyles.borderWidth)
                        color { primary.main }
                    },
                )
                overflowX { hidden }
                paddings?.let { paddings(it) }
            },
        ) {
            content?.invoke(this)
        }
    }
}

@Deprecated("Try not to use for text ellipsis and figure to do it with tailwindCSS")
fun RenderContext.ellipseText(
    styleParams: Style<BoxParams>? = null,
    maxLines: Int = 1,
    content: (Span.() -> Unit)? = null,
) {
    span(
        {
            display { block }
            overflowX { hidden }
            css("text-overflow: ellipsis; white-space: nowrap;")
            // see https://stackoverflow.com/questions/5269713/css-ellipsis-on-second-line
            css(
                """
            @supports (-webkit-line-clamp: $maxLines) {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: initial;
                display: -webkit-box;
                -webkit-line-clamp: $maxLines;
                -webkit-box-orient: vertical;
            }
        """.trimIndent(),
            )
            textAlign { left }
            styleParams?.let { it() }
        },
    ) {
        content?.invoke(this)
    }
}

fun RenderContext.overFlowContent(
    styleParams: Style<FlexParams>? = null,
    maxLines: Int = 1,
    content: (HtmlTag<HTMLDivElement>.() -> Unit)? = null,
) {
    flexBox(
        {
            direction { row }
            wrap { nowrap }
            width { full }
            margins {
                left { tiny }
            }
            overflowX { scroll }
            if (maxLines > 1) {
                css(
                    """
            @supports (-webkit-line-clamp: $maxLines) {
                overflow: hidden;
                white-space: initial;
                display: -webkit-box;
                -webkit-line-clamp: $maxLines;
                -webkit-box-orient: vertical;
            }
        """.trimIndent(),
                )
            }

            css(
                """
                    -ms-overflow-style: none;  /* IE and Edge */
                    scrollbar-width: none;  /* Firefox */
                """.trimIndent(),
            )
            styleParams?.let { it() }
        },
        id = "overflow-content",
    ) {
        content?.invoke(this)
    }
}

fun <T> RenderContext.stateSelectorButton(
    active: Flow<Boolean>? = null,
    profilePictureLink: String? = null,
    icon: (Icons.() -> IconDefinition)? = null,
    iconBackground: (Colors.() -> Property)? = null,
    iconShadow: Boolean = false,
    initials: String? = null,
    title: Flow<String>,
    textColor: (Colors.() -> Property)? = null,
    subtitle: Flow<String?>? = null,
    stateColor: Flow<FormationColors?>? = null,
    stateIcon: Flow<IconDefinition?>? = null,
    routingMap: Map<String, String>? = null,
    clickHandlers: List<Handler<Unit>>? = null,
    value: T,
    valueHandlers: List<Handler<T>>? = null,
) {
    val routerStore: RouterStore by koinCtx.inject()

    button(
        {
            width { full }
            height { auto } // maybe define fixed height of button later
        },
    ) {
        flexBox(
            {
                height { maxContent }
                width { full }
                justifyContent { spaceBetween }
                alignItems { center }
                display { flex }
                padding { tiny }
                radius(formationStyles.buttonRadius)
                hover {
                    background { color { FormationColors.GrayLight.color } }
                }
                background { color { secondary.main } }
                border {
                    width(formationStyles.borderWidth)
                    color { primary.main }
                }
            },
        ) {
            lineUp(
                {
                    alignItems { center }
                    overflowX { hidden }
                },
            ) {
                spacing { small }
                items {
                    // ICON BOX
                    flexBox(
                        {
                            flex {
                                grow { "0" }
                                shrink { "0" }
                                basis { "60px" }
                            }
                            width { "60px" }
                            height { "60px" }
                            justifyContent { center }
                            alignItems { center }
                        },
                    ) {
                        // STATE OR USER ICON ON CIRCLE
                        userOrStateIcon(
                            pixelSize = 48.0,
                            shadow = iconShadow,
                            picture = profilePictureLink,
                            initials = initials,
                            icon = icon ?: { FormationIcons.UserAlt.icon },
                            iconBackground = iconBackground,
                            textColor = textColor,
                        )
                    }
                    // TITLE & SUBTITLE
                    stackUp(
                        {
                            overflowX { hidden }
                        },
                    ) {
                        spacing { tiny }
                        items {
                            ellipseText(
                                {
                                    width { full }
                                    fontSize { normal }
                                    fontWeight { bold }
                                    textAlign { left }
                                },
                            ) { title.renderText(into = this) }
                            subtitle?.render { sub ->
                                if (!sub.isNullOrBlank()) {
                                    div("text-left") {
                                        cardSubtitle(flowOf(sub))
                                    }
                                }
                            }
                        }
                    }
                }
            }
            lineUp(
                {
                    alignItems { center }
                    justifyContent { center }
                },
            ) {
                spacing { giant }
                items {
                    stateColor?.render { formationColor ->
                        if (formationColor != null) {
                            flexBox(
                                {
                                    width { "30px" }
                                    height { "30px" }
                                    color { secondary.main }
                                    background {
                                        color { formationColor.color }
                                    }
                                    radius { full }
                                    padding { smaller }
                                    margin { tiny }
                                    flex {
                                        grow { "0" }
                                        shrink { "0" }
                                        basis { "30px" }
                                    }
                                    alignItems { center }
                                    justifyContent { center }
                                },
                            ) {
                                stateIcon?.render { iconFromState ->
                                    if (iconFromState != null) {
                                        icon({ size { normal } }) { fromTheme { iconFromState } }
                                    }
//                                    else {
//                                        icon?.let { icon({ size { normal } }) { fromTheme(it) } }
//                                    }
                                }
                            }
                        }
                    }
                    active?.render { active ->
                        // ICON ON CIRCLE
                        div(
                            {
                                width { "40px" }
                                height { "40px" }
                                color { secondary.main }
                                radius { full }
                                padding { smaller }
                                margins {
                                    vertical { tiny }
                                    left { tiny }
                                    right { smaller }
                                }
                                flex {
                                    grow { "0" }
                                    shrink { "0" }
                                    basis { "40px" }
                                }
                                if (active) {
                                    background { color { primary.main } }
                                } else {
                                    border {
//                            color { FormationColors.GrayPrivate.color }
                                        color { primary.main }
                                        width { "2px" }
                                    }
                                }
                            },
                        ) {
                            if (active) icon({ size { larger } }) { fromTheme { FormationUIIcons.Check.icon } }
                        }
                    }
                }
            }
        }
        with(clicks) {
            clickHandlers?.forEach { handler ->
                this handledBy handler
            }
            valueHandlers?.forEach { handler ->
                this.map { value } handledBy handler
            }
            if (routingMap != null) {
                this.map {
                    routingMap
                } handledBy routerStore.validateInternalRoute
            }
        }
    }
}

fun RenderContext.iconInfoField(
    icon: (Icons.() -> IconDefinition)? = null,
    iconBackground: (Colors.() -> Property)? = null,
    iconShadow: Boolean = false,
    initials: String? = null,
    pretitle: Flow<String?>? = null,
    title: Flow<String>,
    subtitle: Flow<String?>? = null,
    ellipsed: Boolean = true,
    hoverStyle: Style<BasicParams>? = null,
    additionalStyle: Style<BasicParams>? = null,
) {
    lineUp(
        {
            height { maxContent }
            width { full }
            alignItems { center }
            display { flex }
            paddings {
                vertical { smaller }
                horizontal { tiny }
            }
            radius(formationStyles.buttonRadius)
            background { color { FormationColors.GrayLight.color } }
            if (ellipsed) {
                overflowX { hidden }
            }
            hoverStyle?.let { it() }
            additionalStyle?.let { it() }
        },
    ) {
        spacing { tiny }
        items {
            if (icon != null || initials != null) {
                // ICON BOX
                flexBox(
                    {
                        flex {
                            grow { "0" }
                            shrink { "0" }
                            basis { "60px" }
                        }
                        width { "60px" }
                        height { "60px" }
                        justifyContent { center }
                        alignItems { center }
                    },
                ) {
                    // ICON ON CIRCLE
                    flexBox(
                        {
                            width { "40px" }
                            height { "40px" }
                            color { secondary.main }
                            background { color(iconBackground ?: { primary.main }) }
                            radius { full }
                            padding { tiny }
                            justifyContent { center }
                            alignItems { center }
                            flex {
                                grow { "0" }
                                shrink { "0" }
                                basis { "40px" }
                            }
                            if (iconShadow) {
                                boxShadow {
                                    shadow("2px", "4px", "4px", color = rgba(0, 0, 0, 0.25))
                                }
                            }
                        },
                    ) {
                        if (initials != null) {
                            span({ fontSize { large } }) { +initials }
                        } else {
                            if (icon != null) {
                                icon({ size { larger } }) { fromTheme(icon) }
                            }
                        }
                    }
                }
            }
            // TITLE & SUBTITLE
            stackUp(
                {
                    if (ellipsed) {
                        overflowX { hidden }
                    }
                    margins { horizontal { tiny } }
                },
            ) {
                spacing { none }
                items {
                    pretitle?.render { pre ->
                        if (!pre.isNullOrBlank()) {
                            span(
                                {
                                    width { full }
                                    fontSize { smaller }
                                    textAlign { left }
                                    if (ellipsed) {
                                        overflowX { hidden }
                                        css("text-overflow: ellipsis; white-space: nowrap;")
                                    }
                                },
                            ) { +pre }
                        }
                    }

                    span(
                        {
                            width { full }
                            fontSize { small }
                            fontWeight { bold }
                            textAlign { left }
                            if (ellipsed) {
                                overflowX { hidden }
                                css("text-overflow: ellipsis; white-space: nowrap;")
                            }
                        },
                    ) { title.renderText(into = this) }

                    subtitle?.render { sub ->
                        if (!sub.isNullOrBlank()) {
                            span(
                                {
                                    width { full }
                                    fontSize { smaller }
                                    textAlign { left }
                                    if (ellipsed) {
                                        overflowX { hidden }
                                        css("text-overflow: ellipsis; white-space: nowrap;")
                                    }
                                },
                            ) { +sub }
                        }
                    }
                }
            }
        }
    }
}

fun RenderContext.selectorContent(
    disabled: Boolean = false,
    margins: SpacesContext.() -> Unit? = { vertical { tiny }; horizontal { tiny } },
    element: (HtmlTag<HTMLDivElement>.() -> Unit)? = null,
) {
    flexBox(
        {
            direction { row }
            width { full }
            justifyContent { start }
            alignItems { center }
            margins { margins.invoke(this) }
            fontSize { small }
            if (disabled) {
                color { FormationColors.GrayDisabled.color }
            }
            overflowX { hidden }
        },
        id = "selector-content",
    ) { element?.invoke(this) }
}

fun RenderContext.smallSelectButton(
    isActive: Flow<Boolean> = flowOf(false),
    content: (HtmlTag<HTMLDivElement>.() -> Unit)? = null,
) {
    isActive.render { active ->
        button(
            {
                width { auto }
                height { auto } // maybe define fixed height of button later
            },
        ) {
            flexBox(
                {
                    direction { row }
                    width { full }
                    height { auto }
                    justifyContent { spaceBetween }
                    alignItems { center }
                    radius(formationStyles.inputRadius)
                    if (active) {
                        color { secondary.main }
                        border {
                            width(formationStyles.borderWidth)
                            color { primary.main }
                        }
                        background {
                            color { primary.main }
                        }
                        hover {
                            background { color { makeRGBA(primary.main, 0.9) } }
                        }

                    } else {
                        color { primary.main }
                        border {
                            width(formationStyles.borderWidth)
                            color { primary.main }
                        }
                        hover {
                            background { color { FormationColors.GrayLight.color } }
                        }
                    }
                    paddings { horizontal { small }; vertical { tiny } }
                    fontSize { small }
                },
            ) {
                content?.invoke(this)
            }
        }
    }
}

fun <T> RenderContext.genericButton(
    title: Flow<String>,
    icon: (Icons.() -> IconDefinition)? = null,
    iconFlow: Flow<(Icons.() -> IconDefinition)>? = null,
    iconPosition: Position = Position.Left,
    state: Flow<Boolean>? = null,
    width: SizesProperty,
    styleFlow: Flow<Style<BasicParams>>,
    styleType: (PushButtonTypes.() -> ColorScheme)? = null,
    routingMap: Map<String, String>? = null,
    routingMapBackTo: Map<String, String>? = null,
    addOrReplaceRoute: Map<String, String>? = null,
    clickHandlers: List<Handler<Unit>>? = null,
    clickHandlersFlow: Flow<List<Handler<Unit>>>? = null,
    value: T? = null,
    valueFlow: Flow<T>? = null,
    valueHandlers: List<Handler<T?>>? = null,
    tracker: Tracker? = null,
    trackerLoadingText: Flow<String>? = null,
    attributes: List<Pair<String, String>> = emptyList(),
    analyticsEventProvider: (() -> MessageToServer.AnalyticsEvent)? = null,
) {
    val routerStore: RouterStore by koinCtx.inject()
    val analyticsService: AnalyticsService by koinCtx.inject()
    combine(
        styleFlow,
        clickHandlersFlow ?: flowOf(clickHandlers),
        iconFlow ?: flowOf(icon),
        valueFlow ?: flowOf(value),
    ) { s, c, i, v ->
        Quadruple(s, c, i, v)
    }.render { (style, handlers, flowIcon, flowValue) ->
        pushButton(
            style.plus {
                minWidth(width)
                height(formationStyles.buttonHeight)
                flex {
                    shrink { "0" }
                }
            },
        ) {
            element {
                attr("onClick", "event.preventDefault(); blur();")
                attributes.forEach { (key, value) ->
                    attr(key, value)
                }
            }
            if (flowIcon != null) {
                icon(flowIcon)
                iconPlacement {
                    when (iconPosition) {
                        Position.Left -> left
                        Position.Right -> right
                    }
                }
            }
            text(title)
            if (styleType != null) {
                type(styleType)
            }
            if (state != null) {
                enabled(state)
            }
            events {
                with(clicks) {
                    valueHandlers?.forEach { valueHandler ->
                        this.map { flowValue } handledBy valueHandler
                    }
                    handlers?.forEach { handler ->
                        this handledBy handler
                    }
                    if (addOrReplaceRoute != null) {
                        this.map {
                            addOrReplaceRoute
                        } handledBy routerStore.addOrReplaceRoute
                    }
                    if (routingMapBackTo != null && routingMap == null) {
                        this.map {
                            routingMapBackTo
                        } handledBy routerStore.backTo
                    }
                    if (routingMap != null && routingMapBackTo == null) {
                        this.map {
                            routingMap
                        } handledBy routerStore.validateInternalRoute
                    }
                    if (analyticsEventProvider != null) {
                        this.map { analyticsEventProvider.invoke() } handledBy analyticsService.analyticsEvent
                    }
                }
            }
            tracker?.let { tracker ->
                loading(tracker.data)
                trackerLoadingText?.let { text -> loadingText(text) }
            }

        }
    }
}

fun <T> RenderContext.oneButtonFooter(
    title: Flow<String>,
    icon: (Icons.() -> IconDefinition)? = null,
    state: Flow<Boolean>? = null,
    width: SizesProperty = { full },
    styleParams: Style<BasicParams> = primaryButtonStyleParams,
    routingMap: Map<String, String>? = null,
    routingMapBackTo: Map<String, String>? = null,
    addOrReplaceRoute: Map<String, String>? = null,
    clickHandlers: List<Handler<Unit>>? = null,
    clickHandlersFlow: Flow<List<Handler<Unit>>>? = null,
    value: T? = null,
    valueHandlers: List<Handler<T?>>? = null,
    tracker: Tracker? = null,
    loadingText: Flow<String>? = null,
    attributes: List<Pair<String, String>> = emptyList(),
    analyticsEventProvider: (() -> MessageToServer.AnalyticsEvent)? = null,
) {
    flexBox(
        {
            minWidth { "240px" }
            width { full }
            alignItems { center }
            justifyContent { center }
        },
    ) {
        genericButton(
            title = title,
            icon = icon,
            state = state,
            width = width,
            styleFlow = flowOf(styleParams),
            styleType = { primary },
            routingMap = routingMap,
            routingMapBackTo = routingMapBackTo,
            addOrReplaceRoute = addOrReplaceRoute,
            clickHandlers = clickHandlers,
            clickHandlersFlow = clickHandlersFlow,
            value = value,
            valueHandlers = valueHandlers,
            attributes = attributes,
            tracker = tracker,
            trackerLoadingText = loadingText,
            analyticsEventProvider = analyticsEventProvider,
        )
    }
}

fun <T> RenderContext.twoButtonFooter(
    secondaryTitle: Flow<String>,
    secondaryIcon: (Icons.() -> IconDefinition)? = null,
    secondaryState: Flow<Boolean>? = null,
    secondaryStyleParams: Style<BasicParams> = secondaryButtonStyleParams,
    secondaryStyleType: (PushButtonTypes.() -> ColorScheme)? = { secondary },
    secondaryRoutingMap: Map<String, String>? = null,
    secondaryRoutingMapBackTo: Map<String, String>? = null,
    secondaryAddOrReplaceRoute: Map<String, String>? = null,
    secondaryClickHandlers: List<Handler<Unit>>? = null,
    secondaryValue: T? = null,
    secondaryValueHandlers: List<Handler<T?>>? = null,
    secondaryAttributes: List<Pair<String, String>> = emptyList(),
    secondaryTracker: Tracker? = null,
    secondaryLoadingText: Flow<String>? = null,
    secondaryAnalyticsEventProvider: (() -> MessageToServer.AnalyticsEvent)? = null,
    primaryTitle: Flow<String>,
    primaryIcon: (Icons.() -> IconDefinition)? = null,
    primaryState: Flow<Boolean>? = null,
    primaryStyleParams: Style<BasicParams> = genericButtonStyleParams,
    primaryStyleType: (PushButtonTypes.() -> ColorScheme)? = { primary },
    primaryRoutingMap: Map<String, String>? = null,
    primaryRoutingMapBackTo: Map<String, String>? = null,
    primaryAddOrReplaceRoute: Map<String, String>? = null,
    primaryClickHandlers: List<Handler<Unit>>? = null,
    primaryValue: T? = null,
    primaryValueHandlers: List<Handler<T?>>? = null,
    primaryAttributes: List<Pair<String, String>> = emptyList(),
    primaryTracker: Tracker? = null,
    primaryLoadingText: Flow<String>? = null,
    primaryAnalyticsEventProvider: (() -> MessageToServer.AnalyticsEvent)? = null,
) {
    div(
        {
            width { full }
        },
    ) {
        flexBox(
            {
                width { full }
                direction { row }
                justifyContent { spaceBetween }
                wrap { nowrap }
            },
        ) {
            genericButton(
                title = secondaryTitle,
                icon = secondaryIcon,
                state = secondaryState,
                width = { "120px" },
                styleFlow = flowOf(secondaryStyleParams),
                styleType = secondaryStyleType,
                routingMap = secondaryRoutingMap,
                routingMapBackTo = secondaryRoutingMapBackTo,
                addOrReplaceRoute = secondaryAddOrReplaceRoute,
                clickHandlers = secondaryClickHandlers,
                value = secondaryValue,
                valueHandlers = secondaryValueHandlers,
                attributes = secondaryAttributes,
                tracker = secondaryTracker,
                trackerLoadingText = secondaryLoadingText,
                analyticsEventProvider = secondaryAnalyticsEventProvider,
            )
            genericButton(
                title = primaryTitle,
                icon = primaryIcon,
                state = primaryState,
                width = { "150px" },
                styleFlow = flowOf(primaryStyleParams),
                styleType = primaryStyleType,
                routingMap = primaryRoutingMap,
                routingMapBackTo = primaryRoutingMapBackTo,
                addOrReplaceRoute = primaryAddOrReplaceRoute,
                clickHandlers = primaryClickHandlers,
                value = primaryValue,
                valueHandlers = primaryValueHandlers,
                attributes = primaryAttributes,
                tracker = primaryTracker,
                trackerLoadingText = primaryLoadingText,
                analyticsEventProvider = primaryAnalyticsEventProvider,
            )
        }
    }
}

fun RenderContext.genericSwitch(
    width: SizesProperty = { "64px" },
    dotSize: SizesProperty = { "22px" },
    dotColor: Colors.() -> ColorProperty = { secondary.main },
    switchState: Flow<Boolean>,
    clickHandlers: List<Handler<Unit>>? = null,
    boolHandlers: List<Handler<Boolean>>? = null,
) {

    val translation: Translation by koinCtx.inject()

    switchState.render { state ->
        button {
            flexBox(
                {
                    direction { row }
                    justifyContent { spaceBetween }
                    alignItems { center }
                    width(width)
                    height { maxContent }
                    radius { full }
                    color { FormationColors.White.color }
                    background {
                        color { if (state) primary.main else FormationColors.GrayDisabled.color }
                    }
                    flex {
                        shrink { "0" }
                        grow { "0" }
                    }
                    padding { "2px" }
                    margins { }
                },
            ) {

                if (state) {
                    span(
                        {
                            fontSize { smaller }
                            fontWeight { medium }
                            flex { grow { "1" } }
                            textAlign { center }
                        },
                    ) { translation[TL.GenericSwitch.ON].renderText(into = this) }
                }

                div(
                    {
                        width(dotSize)
                        height(dotSize)
                        radius { full }
                        background { color(dotColor) }
                        flex {
                            grow { "0" }
                            shrink { "0" }
                        }
                    },
                ) {}

                if (!state) {
                    span(
                        {
                            fontSize { smaller }
                            fontWeight { medium }
                            flex { grow { "1" } }
                            textAlign { center }
                        },
                    ) { translation[TL.GenericSwitch.OFF].renderText(into = this) }
                }
            }
            with(clicks) {
                clickHandlers?.forEach { handler ->
                    this handledBy handler
                }
                boolHandlers?.forEach { handler ->
                    this.map { !state } handledBy handler
                }
            }
        }
    }
}

fun RenderContext.mapLayerButton(content: (HtmlTag<HTMLDivElement>.() -> Unit)? = null) {
    button(
        {
            width { full }
            height { auto } // maybe define fixed height of button later
        },
    ) {
        flexBox(
            {
                direction { row }
                width { full }
                height { auto }
                justifyContent { spaceBetween }
                alignItems { center }
                radius(formationStyles.inputRadius)
                border {
                    width(formationStyles.borderWidth)
                    color { primary.main }
                }
                hover {
                    background { color { FormationColors.GrayLight.color } }
                }
                paddings { left { tiny } }
            },
        ) {
            content?.invoke(this)
        }
    }
}

fun <T> RenderContext.genericSmallIconButton(
    title: Flow<String>? = null,
    icon: (Icons.() -> IconDefinition)? = null,
    iconPosition: Position = Position.Right,
    hrefOrValue: T,
    downloadValue: String? = null,
    clickHandlers: List<Handler<T>>? = null,
    isLink: Boolean = false,
    target: String = "_blank",
    disabledFlow: Flow<Boolean> = flowOf(false),
) {
    disabledFlow.render { disabled ->
        val buttonContent: RenderContext.() -> HtmlTag<HTMLDivElement> = {
            lineUp(
                {
                    alignItems { center }
                    justifyContent { center }
                    radius(formationStyles.inputRadius)
                    paddings {
                        left {
                            title?.let {
                                icon?.let { if (iconPosition == Position.Right) small else smaller } ?: smaller
                            } ?: smaller
                        }
                        right {
                            title?.let {
                                icon?.let { if (iconPosition == Position.Left) small else smaller } ?: smaller
                            } ?: smaller
                        }
                        vertical { tiny }
                    }
                    fontSize { small }
                    if (disabled) {
                        color { FormationColors.GrayDisabled.color }
                        background { color { FormationColors.GrayLight.color } }
                        border {
                            color { FormationColors.GrayLight.color }
                            width(formationStyles.borderWidth)
                        }
                    } else {
                        color { primary.main }
                        background {
                            color { secondary.main }
                        }
                        border {
                            color { primary.main }
                            width(formationStyles.borderWidth)
                        }
                        hover {
                            color { secondary.main }
                            background {
                                color { primary.main }
                            }
                        }
                    }
                },
            ) {
                spacing { tiny }
                items {
                    if (iconPosition == Position.Left) {
                        icon?.let { icon { fromTheme(it) } }
                    }
                    title?.let { span { it.renderText(into = this) } }
                    if (iconPosition == Position.Right) {
                        icon?.let { icon { fromTheme(it) } }
                    }
                }
            }
        }

        if (disabled) {
            buttonContent()
        } else {
            if (isLink && hrefOrValue is String) {
                a {
                    href(hrefOrValue)
                    target(target)
                    downloadValue?.let {
                        download(it)
                    }
                    buttonContent()
                }
            } else {
                button {
                    buttonContent()
                    clickHandlers?.forEach { handler ->
                        clicks.map { hrefOrValue } handledBy handler
                    }
                }
            }
        }
    }
}

fun RenderContext.userOrStateIcon(
    pixelSize: Double,
    shadow: Boolean = true,
    margins: (SpacesContext.() -> Unit)? = null,
    picture: String? = null,
    initials: String? = null,
    icon: (Icons.() -> IconDefinition)? = { FormationIcons.UserAlt.icon },
    iconSize: SizesProperty = { huge },
    iconBackground: (Colors.() -> Property)? = { primary.main },
    textColor: (Colors.() -> Property)? = { secondary.main },
) {
    // USER ICON ON CIRCLE
    flexBox(
        {
            flex {
                grow { "0" }
                shrink { "0" }
                basis { "${pixelSize}px" }
            }
            width { "${pixelSize}px" }
            height { "${pixelSize}px" }
            margins?.let { margins(margins) }
            textColor?.let { color(it) } ?: color { secondary.main }
            background { iconBackground?.let { color(it) } ?: color { primary.main } }
            radius { full }
            padding { tiny }
            justifyContent { center }
            alignItems { center }
            textAlign { center }
            if (shadow) {
                boxShadow {
                    shadow("2px", "4px", "2px", color = rgba(0, 0, 0, 0.25))
                }
            }
            if (!picture.isNullOrBlank()) {
                background {
                    image { picture }
                    size { cover }
                    position { center }
                }
                border {
                    width(formationStyles.borderWidth)
                    color { primary.main }
                }
            }
        },
    ) {
        if (picture.isNullOrBlank()) {
            if (!initials.isNullOrBlank()) {
                span({ fontSize { "${pixelSize / 3}" } }) { +initials }
            } else {
                icon?.let { icon({ size(iconSize) }) { fromTheme(icon) } }
            }
        }
    }
}


data class BigButtonOption<T>(
    val title: Flow<String>,
    val icon: (Icons.() -> IconDefinition)? = null,
    val value: T,
    val selectHandler: Handler<T>,
    val disabled: Flow<Boolean> = flowOf(false),
)

fun <T> RenderContext.genericBigButtonSwitch(store: Store<T>, options: List<BigButtonOption<T>>) {
    div(
        {
            width { full }
            paddings { vertical { small } }
        },
    ) {
        flexBox(
            {
                width { full }
                direction { row }
                justifyContent { spaceBetween }
                alignItems { center }
                border { color { primary.main }; width(formationStyles.borderWidth) }
                radius(formationStyles.inputRadius)
            },
        ) {
            store.data.render { selectedValue ->
                options.forEach { option ->
                    option.disabled.render { disabled ->
                        button(
                            {
                                if (disabled) {
                                    filterButtonDisabledStyle()
                                } else if (option.value == selectedValue) filterButtonOnStyle() else filterButtonOffStyle()
                            },
                        ) {
                            disabled(disabled)
                            lineUp(
                                {
                                    alignItems { center }
                                    justifyContent { center }
                                },
                            ) {
                                spacing { tiny }
                                items {
                                    option.icon?.let { icon({ size { normal } }) { fromTheme(it) } }
                                    span { option.title.renderText(into = this) }
                                }
                            }
                            clicks.map { option.value } handledBy option.selectHandler
                        }
                    }
                }
            }
        }
    }
}
