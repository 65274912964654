package data.objects.views

import apiclient.geoobjects.GeoObjectDetails
import dev.fritz2.components.flexBox
import dev.fritz2.core.RenderContext
import koin.koinCtx
import kotlinx.coroutines.ExperimentalCoroutinesApi
import localization.TL
import localization.Translation
import model.L
import mainmenu.RouterStore
import data.objects.ActiveObjectStore
import theme.FormationIcons
import webcomponents.*

fun RenderContext.cardViewDescription() {

    val translation: Translation by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()
    val activeObjectStore: ActiveObjectStore = koinCtx.get()
    val description = activeObjectStore.map(GeoObjectDetails.L.description)

    baseLayout(
        header = {
            cardTitle(translation[TL.CardDescription.CARD_TITLE]) { FormationIcons.Description.icon }
        },
        content = {
            description.data.render { desc ->
                if (desc != null) {
                    flexBox({
                        width { full }
                        height { full }
                        direction { column }
                        alignItems { stretch }
                        justifyContent { spaceBetween }
                        paddings { horizontal { normal } }
                    }) {
                        readOnlyTextArea(
                            value = desc,
                            placeHolder = translation[TL.General.DESCRIPTION],
                            height = { full },
                        )
                    }
                }
            }
        },
        footer = {
            oneButtonFooter(
                title = translation[TL.General.BACK],
                value = Unit,
                clickHandlers = listOf(routerStore.back)
            )
        }
    )
}
