package objectrouting

import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.LatLon
import com.tryformation.localization.Translatable
import theme.FormationIcons
import theme.IconEnum

enum class NavigationInput {
    Origin, Destination
}

enum class NavigationPointSource(val icon: IconEnum) : Translatable {
    YourLocation(FormationIcons.Position),
    MapCenter(FormationIcons.MapCenter),
    LastKnownLocation(FormationIcons.LocationAlt),
    OtherObject(FormationIcons.TrackedObject),
    GpsCoordinates(FormationIcons.Globe),
    UtmCoordinates(FormationIcons.Globe),
    MgrsCoordinates(FormationIcons.Globe),
    ;

    override val prefix: String = "navigationpointsource"
}

data class NavigationData(
    val originPointSource: NavigationPointSource? = null,
    val originLatLon: LatLon? = null,
    val originObject: GeoObjectDetails? = null,
    val destinationPointSource: NavigationPointSource? = null,
    val destinationLatLon: LatLon? = null,
    val destinationObject: GeoObjectDetails? = null,
) {
    companion object
}

data class RoutePoint(
    val lat: Double,
    val lon: Double,
    val floorId: String? = null,
    val floorLevel: Double? = null,
    val isFloorChange: Boolean = false,
    val floorChange: FloorChange? = null
)

data class FloorChange(
    val previousLevel: Double,
    val newLevel: Double
) {
    val isLevelUp get() = previousLevel < newLevel
}

data class ParsedCoordinates(
    val pointSource: NavigationPointSource,
    val humanReadable: String,
    val latLon: LatLon,
)
