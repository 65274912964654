package login

import dev.fritz2.core.RootStore
import koin.koinCtx
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.distinctUntilChanged
import kotlinx.coroutines.flow.mapNotNull

class WorkspaceInputStore : RootStore<String?>(
    initialData = null,
    job = Job(),
) {
    private val hostConfigStore by koinCtx.inject<HostConfigStore>()
    val reset = handle { null }

    init {
        data.distinctUntilChanged().mapNotNull { it } handledBy hostConfigStore.updateHostConfig
    }
}
