package geofenceeditor

import com.jillesvangurp.geo.GeoGeometry
import com.jillesvangurp.geojson.Geometry
import com.jillesvangurp.geojson.centroid
import com.jillesvangurp.geojson.translate
import apiclient.geoobjects.toLatLon
import utils.roundTo
import kotlin.math.acos

fun Geometry?.isRectangle(): Boolean = this != null
        && this is Geometry.Polygon
        && this.coordinates?.get(0)?.size == 5
        && this.coordinates!![0][0].contentEquals(this.coordinates!![0][4])

fun Geometry?.isCircle(): Boolean = this != null
        && this is Geometry.Polygon
        && (this.coordinates?.get(0)?.size?: 0) >= 50

fun Geometry?.isPolygon(): Boolean = this != null
        && this is Geometry.Polygon
        && (this.coordinates?.get(0)?.size?: 0) > 5
        && (this.coordinates?.get(0)?.size?: 0) < 50

fun getX(tl: DoubleArray, tr: DoubleArray): Double = GeoGeometry.distance(tl, tr).roundTo(1)
fun getY(tl: DoubleArray, bl: DoubleArray): Double = GeoGeometry.distance(tl, bl).roundTo(1)
fun getRadius(cnt: DoubleArray, pnt: DoubleArray): Double = GeoGeometry.distance(cnt, pnt).roundTo(1)
fun Geometry?.getXMeters(): Double? {
    return if(this.isRectangle()) {
        (this as? Geometry.Polygon)?.let { geometry ->
            geometry.coordinates?.get(0)?.get(0)?.let { tl ->
                getX(tl = tl, tr = geometry.coordinates?.get(0)?.get(3)!!)
            }
        }
    } else null
}
fun Geometry?.getYMeters(): Double? {
    return if(this.isRectangle()) {
        (this as? Geometry.Polygon)?.let { geometry ->
            geometry.coordinates?.get(0)?.get(0)?.let { tl ->
                getY(tl = tl, bl = geometry.coordinates?.get(0)?.get(1)!!)
            }
        }
    } else null
}
fun calcRot(cornerNorth: DoubleArray, cornerRotated: DoubleArray, center: DoubleArray): Double {
    val a = GeoGeometry.distance(center, cornerNorth) // distance to tl corner of new generated rectangle
    val b = GeoGeometry.distance(cornerNorth, cornerRotated) // distance from generated tl corner to tl corner of rotated rectangle
    val northHeading = GeoGeometry.headingFromTwoPoints(center, cornerNorth) // heading of generated tl corner
    val rotHeading = GeoGeometry.headingFromTwoPoints(center, cornerRotated) // heading of rotated tl corner
    val rotationFactor = if(rotHeading in 180.0..northHeading) 1.0 else -1.0 // factor indicates the rotation direction
    return rotationFactor * GeoGeometry.fromRadians(acos(1 - ((b*b)/(2*a*a)))) // rotation angle calculated with cosine theorem
}
fun Geometry?.getRotation(geoShapeShortname: String): Double? {
    return (this as Geometry.Polygon).coordinates?.get(0)!!.let { polygonPoints ->
        val cornerNorth = when(geoShapeShortname) {
            GeoShape.Rectangle.SHORTNAME -> {
                // get first (tl) corner of north facing rectangle
                polygonPoints.centroid().translate(
                    latDistance = getY(tl = polygonPoints[0], bl = polygonPoints[1]) / 2.0,
                    lonDistance = getX(tl = polygonPoints[0], tr = polygonPoints[3]) / -2.0
                )
            }
            GeoShape.Isogon.SHORTNAME, GeoShape.Circle.SHORTNAME -> {
                // get second point of north facing isogon or circle
                GeoGeometry.circle2polygon(
                    segments = polygonPoints.size - 1,
                    centerLat = polygonPoints.centroid().toLatLon().lat,
                    centerLon = polygonPoints.centroid().toLatLon().lon,
                    radius = getRadius(polygonPoints.centroid(), polygonPoints[0])
                )[0][1]
            }
            else -> null
        }
        cornerNorth?.let { cn ->
            calcRot(cornerNorth = cn, cornerRotated = polygonPoints[0], center = polygonPoints.centroid())
        }
    }
}

fun Geometry?.getGeoShape(): GeoShape {
    return when {
        this.isRectangle() -> {
            GeoShape.Rectangle(
                xMeters = this.getXMeters()?: 5.0,
                yMeters = this.getYMeters()?: 5.0,
                rotationDegrees = this.getRotation(GeoShape.Rectangle.SHORTNAME)?: 0.0
            )
        }
        this.isCircle() -> {
            (this as Geometry.Polygon).coordinates?.get(0)!!.let { circlePoints ->
                GeoShape.Circle(
                    radius = getRadius(circlePoints.centroid(), circlePoints[0]),
                    rotationDegrees = getRotation(GeoShape.Circle.SHORTNAME)?: 0.0
                )
            }
        }
        this.isPolygon() -> {
            (this as Geometry.Polygon).coordinates?.get(0)!!.let { polygonPoints ->
                GeoShape.Isogon(
                    vertices = polygonPoints.size - 1,
                    radius = getRadius(polygonPoints.centroid(), polygonPoints[0]),
                    rotationDegrees = getRotation(GeoShape.Isogon.SHORTNAME)?: 0.0
                )
            }
        }
        else -> GeoShape.Circle.DEFAULT
    }
}
