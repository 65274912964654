// GENERATED by fritz2 - NEVER CHANGE CONTENT MANUALLY!
package model

import apiclient.geoobjects.SearchQueryContext
import dev.fritz2.core.Lens
import dev.fritz2.core.lensOf

fun SuggestedTagsContext.Companion.ctx(): Lens<SuggestedTagsContext, SearchQueryContext> = lensOf(
    "ctx",
    { it.ctx },
    { p, v -> p.copy(ctx = v) }
)

fun SuggestedTagsContext.Companion.prefix(): Lens<SuggestedTagsContext, String> = lensOf(
    "prefix",
    { it.prefix },
    { p, v -> p.copy(prefix = v) }
)

fun SuggestedTagsContext.Companion.limit(): Lens<SuggestedTagsContext, Int?> = lensOf(
    "limit",
    { it.limit },
    { p, v -> p.copy(limit = v) }
)
