package localstorage

import dev.fritz2.core.RootStore
import dev.fritz2.core.SimpleHandler
import koin.koinCtx
import kotlinx.browser.window
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.map
import kotlinx.serialization.KSerializer
import kotlinx.serialization.json.Json

open class LocalStoringStore<T>(
    val initialData: T?,
    emptyValue: T,
    val key: String,
    private val serializer: KSerializer<T>,
) : RootStore<T?>(
    initialData = initialData,
    job = Job(),
) {

    val json: Json by koinCtx.inject()

    private var latest: T? = null


    val nonNullableStore by lazy {
        val store = RootStore<T>(emptyValue, Job())
        data.map { store } handledBy propagationHandler
        store
    }

    private val storeHandler = SimpleHandler<T?> { vFlow, _ ->
        vFlow handledBy { v ->
            if (latest != v) {
                if (v == null || v == emptyValue) {
                    console.log("delete $key")
                    window.localStorage.removeItem(key)
                } else {
                    window.localStorage.setItem(key, json.encodeToString(serializer, v))
                }
                latest = v
            }
        }
    }

    private val propagationHandler = handle<RootStore<T>> { v, s ->
        if (v == null || v == emptyValue) {
            s.update(emptyValue)
        } else {
            s.update(v)
        }
        v
    }

    init {
        try {
            val item = window.localStorage.getItem(key)?.let {
                json.decodeFromString(serializer, it).also { v ->
                    console.log("initializing $key with stored value $v")
                    latest = v
                }
            }
            if (item != null) {
                update(item)
            }
            data handledBy storeHandler
        } catch (e: Exception) {
            console.log(e)
            console.log(key)
        }
    }
}

open class NonNullableLocalStoringStore<T>(
    val initialData: T,
    emptyValue: T,
    val key: String,
    val serializer: KSerializer<T>,
) : RootStore<T>(
    initialData = initialData,
    job = Job(),
) {

    val json: Json by koinCtx.inject()

    private var latest: T = emptyValue

    private val storeHandler = SimpleHandler<T> { vFlow, _ ->
        vFlow handledBy { v ->
            if (latest != v) {
                if (v == null || v == emptyValue) {
                    console.log("delete $key")
                    window.localStorage.removeItem(key)
                } else {
                    console.log("store $key")
                    window.localStorage.setItem(key, json.encodeToString(serializer, v))
                }
                latest = v
            }
        }
    }

    init {
        try {
            val item = window.localStorage.getItem(key)?.let {
                json.decodeFromString(serializer, it).also { v ->
                    console.log("initializing $key with stored value")
                    latest = v
                }
            }
            if (item != null) {
                update(item)
            }
            data handledBy storeHandler
        } catch (e: Exception) {
            console.log(e)
            console.log(key)
        }
    }
}
