package poll

import apiclient.geoobjects.MarkerShape
import apiclient.geoobjects.ObjectTags
import apiclient.polls.PollOption
import apiclient.polls.pollResults
import apiclient.tags.getUniqueTag
import apiclient.util.isNotNullOrEmpty
import auth.ApiUserStore
import auth.SessionIdStore
import data.objects.ActiveObjectStore
import dev.fritz2.components.compat.div
import dev.fritz2.components.compat.span
import dev.fritz2.components.flexBox
import dev.fritz2.components.lineUp
import dev.fritz2.components.stackUp
import dev.fritz2.core.RenderContext
import dev.fritz2.core.disabled
import dev.fritz2.styling.theme.DefaultTheme
import koin.koinCtx
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.mapNotNull
import localization.TL
import localization.Translation
import localization.translate
import mainmenu.RouterStore
import model.isInternalAdminGroupAdminOrGroupOwner
import search.titleSubtitleStack
import signup.textButton
import styling.primaryButtonStyleParams
import svgmarker.MarkerSize
import svgmarker.SvgIconOptions
import svgmarker.pointSvgIconOptions
import svgmarker.renderSvgIcon
import theme.FormationColors
import theme.FormationDefault
import theme.FormationIcons
import theme.FormationShapes
import twcomponents.twPrimaryButton
import utils.getColorForIcon
import utils.getIcon
import utils.isBrightColor
import utils.makeRGBA
import utils.roundTo
import webcomponents.baseLayout
import webcomponents.cardSubtitle
import webcomponents.cardTitle
import webcomponents.contentScrollBox
import webcomponents.ellipseText
import webcomponents.mainTitle
import webcomponents.oneButtonFooter
import webcomponents.selectorButton
import webcomponents.selectorContent
import workspacetools.usermanagement.confirm

fun RenderContext.cardPoll(showBack: Boolean, expandable: Boolean, activePollStore: ActivePollStore) {
    val routerStore: RouterStore by koinCtx.inject()
    val translation: Translation by koinCtx.inject()
    val apiUserStore: ApiUserStore by koinCtx.inject()
    val selectedPollOptionsStore: SelectedPollOptionsStore by koinCtx.inject()
    val activeObjectStore: ActiveObjectStore by koinCtx.inject()
    val sessionIdStore: SessionIdStore by koinCtx.inject()

    combine(activeObjectStore.data, activePollStore.data) { activeObj, activePoll ->
        Pair(activeObj, activePoll)
    }.render { (activeObj, activePoll) ->
        if (activePoll != null) {
            val pollResults = activeObj.pollResults(activePoll.id)
            val userOrSessionId = if (apiUserStore.current.isAnonymous) {
                "anonymous-${sessionIdStore.current?.id}"
            } else {
                apiUserStore.current.userId
            }
            val userVotedFor = pollResults?.userVotes?.get(userOrSessionId)
            val userHasVoted = userVotedFor.isNotNullOrEmpty()
            val allowedToReset = apiUserStore.current.isInternalAdminGroupAdminOrGroupOwner()
                || activeObj.tags.getUniqueTag(ObjectTags.OwnerId) == apiUserStore.current.userId

            if (pollResults != null && userHasVoted) {
                // SHOW POLL RESULTS
                baseLayout(
                    expandable = expandable,
                    header = {
                        if (allowedToReset) {
                            titleSubtitleStack {
                                cardTitle(flowOf(activePoll.title))
                                textButton(
                                    text = translation[TL.Poll.RESET_POLL],
                                    fontSize = { small },
                                    attributes = listOf(
                                        "tabindex" to "-1",
                                        "type" to "button",
                                        "onClick" to "event.preventDefault();",
                                    ),
                                    clickHandlers = listOf(
                                        confirm(
                                            text = translation[TL.Poll.RESET_POLL_QUESTION],
                                            okHandlers = listOf(activePollStore.resetPoll),
                                        ),
                                    ),
                                )
                            }
                        } else mainTitle(flowOf(activePoll.title))
                    },
                    content = {
                        contentScrollBox {
                            flexBox({ flex { grow { "1" } } }) { }
                            stackUp(
                                {
                                    width { full }
                                    alignItems { stretch }
                                    flex { grow { "3" } }
                                },
                            ) {
                                spacing { normal }
                                items {
                                    activePoll.options.forEach { pollOption ->
                                        div(
                                            {
                                                height { maxContent }
                                            },
                                        ) {
                                            pollOptionResult(
                                                pollOption,
                                                votes = pollResults.votesByOption[pollOption] ?: 0,
                                                percentage = ((pollResults.relativeByOption[pollOption] ?: 0.0) * 100).roundTo(0),
                                                isSelected = userVotedFor?.any { it.id == pollOption.id } ?: false,
                                            )
                                        }
                                    }
                                }
                            }
                            flexBox({ flex { grow { "1" } } }) { }
                            if (userHasVoted) {
                                flexBox(
                                    {
                                        margins {
                                            vertical { small }
                                        }
                                        alignItems { center }
                                        justifyContent { center }
                                    },
                                ) {
                                    textButton(
                                        text = translation[TL.Poll.RETRACT_VOTE],
                                        fontSize = { small },
                                        attributes = listOf(
                                            "tabindex" to "-1",
                                            "type" to "button",
                                            "onClick" to "event.preventDefault();",
                                        ),
                                        clickHandlers = listOf(activePollStore.retractVote),
                                    )
                                }
                                flexBox({ flex { grow { "1" } } }) { }
                            }
                        }
                    },
                    footer = {
                        if (showBack) {
                            oneButtonFooter(
                                title = translation[TL.General.BACK],
                                styleParams = primaryButtonStyleParams,
                                value = Unit,
                                clickHandlers = listOf(routerStore.back),
                            )
                        }
                    },
                )
            } else {
                // SHOW THE POLL
                baseLayout(
                    expandable = expandable,
                    header = {
                        mainTitle(activePollStore.data.mapNotNull { it?.title })
                        cardSubtitle(
                            activePollStore.data.mapNotNull { poll ->
                                if (poll != null && poll.maxVotesPerUser > 1) {
                                    translation.getString(
                                        TL.Poll.CHOOSE_UP_TO_X_OPTIONS,
                                        mapOf("number" to poll.maxVotesPerUser),
                                    )
                                } else {
                                    translation.getString(TL.Poll.CHOOSE_ONE_OPTION)
                                }
                            },
                        )
                    },
                    content = {
                        contentScrollBox {
                            flexBox({ flex { grow { "1" } } }) { }
                            stackUp(
                                {
                                    width { full }
                                    alignItems { stretch }
                                    flex { grow { "3" } }
                                },
                            ) {
                                spacing { small }
                                items {
                                    activePoll.options.forEach { pollOption ->
                                        div {
                                            pollOptionButton(
                                                pollOption = pollOption,
                                                activeOptionIds = selectedPollOptionsStore.data,
                                                maxVotes = activePoll.maxVotesPerUser,
                                            )
                                        }
                                    }
                                }
                            }
                        }
                    },
                    footer = {
                        if (showBack) {
                            oneButtonFooter(
                                title = combine(
                                    selectedPollOptionsStore.data.map { activeOptionIds ->
                                        activeOptionIds.isNotNullOrEmpty()
                                            && activePoll.options.map { it.id }.containsAll(activeOptionIds)
                                    },
                                    translation[TL.Poll.VOTE], translation[TL.General.BACK],
                                ) { optionVotable, vote, back ->
                                    if (optionVotable) vote else back
                                },
                                styleParams = primaryButtonStyleParams,
                                value = Unit,
                                clickHandlersFlow = selectedPollOptionsStore.data.map { activeOptionIds ->
                                    activeOptionIds.isNotNullOrEmpty()
                                        && activePoll.options.map { it.id }.containsAll(activeOptionIds)
                                }.map { optionVotable ->
                                    if (optionVotable) listOf(activePollStore.submitVote) else listOf(routerStore.back, activePollStore.reset)
                                },
                            )
                        } else {
                            // new direct editing flow
                            twPrimaryButton {
                                translate(TL.Poll.VOTE)
                                selectedPollOptionsStore.data.render { selectedVote ->
                                    disabled(selectedVote.isEmpty())
                                }
                                clicks handledBy activePollStore.submitVote
                            }
                        }
                    },
                )
            }
        }
    }
}

fun RenderContext.pollOptionButton(
    pollOption: PollOption,
    activeOptionIds: Flow<Set<String>>,
    maxVotes: Int = 1
) {
    val selectedPollOptionsStore by koinCtx.inject<SelectedPollOptionsStore>()
    val pollOptionIcon =
        pollOption.iconCategory?.getIcon()?.icon
            ?: FormationIcons.ArrowRight.icon  // TODO check icon mapping replace default icon with numbers
    val pollOptionColor = pollOption.color.getColorForIcon().color// TODO check color mapping

    activeOptionIds.render { activeOptions ->
        val selected = pollOption.id in activeOptions
        val selectableInMultiselect = activeOptions.size < maxVotes

        selectorButton(
            radius = FormationDefault.formationStyles.buttonRadius,
            margins = { },
            selectorIcon = null,
            additionalStyle = {
                border {
                    width { none }
                }
                when {
                    selected -> {
                        color { if (isBrightColor(pollOptionColor)) primary.main else secondary.main }
                        background {
                            color { pollOptionColor }
                        }
                        hover {
                            background {
                                color { makeRGBA(pollOptionColor, 0.9) }
                            }
                        }
                    }

                    !selected && selectableInMultiselect -> {
                        hover {
                            background {
                                color { FormationColors.GrayPrivate.color }
                            }
                        }
                        color { pollOptionColor }
                    }

                    else -> { // not selected
                        color { FormationColors.GrayLight.color }
                        hover {
                            background {
                                color { FormationColors.GrayPrivate.color }
                            }
                        }
                    }
                }
            },
        ) {
            selectorContent(
                disabled = !selected && !selectableInMultiselect,
            ) {
                lineUp(
                    {
                        margin { tiny }
                        alignItems { center }
                        overflowX { hidden }
                    },
                ) {
                    spacing { small }
                    items {
                        // ICON BOX
                        flexBox(
                            {
                                height { full }
                                alignItems { center }
                                justifyContent { center }
                            },
                        ) {
                            renderSvgIcon(
                                svgIconOptions = SvgIconOptions(
                                    size = MarkerSize.XXL,
                                    iconColor = if (selected) pollOption.color.getColorForIcon() else FormationColors.White,
                                    bgColor = if (selected) FormationColors.White else pollOption.color.getColorForIcon(),
                                    icon = pollOption.iconCategory?.getIcon() ?: FormationIcons.ArrowRight,
                                    bgShape = FormationShapes.Circle,
                                    hasNotification = selected,
                                ),
                                notificationIcon = DefaultTheme().icons.check,
                            )
                        }
                        // ICON ON CIRCLE
//                        flexBox(
//                            {
//                                width { "48px" }
//                                height { "48px" }
//                                when {
//
//                                    selected -> {
//                                        color { pollOptionColor }
//                                        background { color { if (isBrightColor(pollOptionColor)) primary.main else secondary.main } }
//                                    }
//
//                                    !selected && selectable -> {
//                                        color { secondary.main }
//                                        background { color { pollOptionColor } }
//                                    }
//
//                                    else -> { // not selected
//                                        color { secondary.main }
//                                        background { color { FormationColors.GrayDisabled.color } }
//                                    }
//                                }
//                                radius { full }
//                                padding { smaller }
//                                flex {
//                                    grow { "0" }
//                                    shrink { "0" }
//                                    basis { "48px" }
//                                }
//                                alignItems { center }
//                                justifyContent { center }
//                            },
//                        ) {
//                            icon({ size { larger } }) { fromTheme { pollOptionIcon } }
//                        }
                        // TITLE & SUBTITLE
                        stackUp(
                            {
                                overflowX { hidden }
                            },
                        ) {
                            spacing { tiny }
                            items {
                                ellipseText(
                                    styleParams = {
                                        width { full }
                                        fontSize { normal }
                                        fontWeight { bold }
                                        textAlign { left }
                                    },
                                ) { +pollOption.title }
                                ellipseText(
                                    styleParams = {
                                        width { full }
                                        fontSize { smaller }
                                        textAlign { left }
                                    },
                                    maxLines = 4,
                                ) { +(pollOption.subtitle ?: "") }
                            }
                        }
                    }
                }
            }
            if (maxVotes > 1 && (selectableInMultiselect || selected)) {
                clicks.map { pollOption.id } handledBy
                    selectedPollOptionsStore.toggleOption
            } else {
                clicks.map { pollOption.id } handledBy
                    selectedPollOptionsStore.toggleOrSetSingleOption
            }
        }
    }
}

fun RenderContext.pollOptionResult(
    pollOption: PollOption,
    votes: Int,
    percentage: Double,
    isSelected: Boolean = false,
) {
    val translation: Translation by koinCtx.inject()

    flexBox(
        {
            width { full }
            height { full }
            direction { row }
            alignItems { center }
            justifyContent { start }
            padding { smaller }
        },
    ) {
        // ICON BOX
        flexBox(
            {
                height { full }
                alignItems { center }
                justifyContent { center }
            },
        ) {
            renderSvgIcon(
                svgIconOptions = pointSvgIconOptions(
                    size = MarkerSize.XXL,
                    color = pollOption.color,
                    icon = pollOption.iconCategory,
                    shape = MarkerShape.Circle,
                    hasNotification = isSelected,
                ),
                notificationIcon = FormationIcons.User.icon,
            )
//            icon({
//                height { "60px" }
//                width { "60px" }
//                color { pollOption.color.getColorForStatus().color }
//                margins { right { small } }
//            }) { fromTheme { pollOption.iconCategory?.getIcon()?.icon?: FormationIcons.ArrowRight.icon } } // TODO replace default icon
        }

        flexBox(
            {
                direction { column }
                width { full }
                height { full }
                alignItems { start }
                justifyContent { spaceBetween }
            },
        ) {
            //title
            span(
                {
                    fontWeight { semiBold }
                    fontSize { small }
                    lineHeight { "1" }
                },
            ) { +pollOption.title }
            // subtitle
            ellipseText(
                styleParams = {
                    width { full }
                    fontSize { smaller }
                    textAlign { left }
                },
                maxLines = 4,
            ) { +(pollOption.subtitle ?: "") }

            // dynamic bar
            flexBox(
                {
                    direction { row }
                    width { full }
                    alignItems { center }
                    justifyContent { spaceBetween }
                    paddings { right { "64.1px" } }
                },
            ) {
                // bar
                flexBox(
                    {
                        width { full }
                        minWidth { "100px" }
                        height { "25px" }
                        radius { large }
                        direction { row }
                    },
                    id = "progress-outer-box",
                ) {
                    div(
                        {
                            width { "${percentage}%" }
                            height { full }
                            css(
                                """
                            @keyframes bar {
                              0% { width: 0px; }
                              100% { width: (${percentage}*width)px; }
                            }
                            animation: bar 2s ease 1 normal;
                            """.trimIndent(),
                            )
                            background {
                                color { pollOption.color.getColorForIcon().color }
                            }
                            radius { large }
                        },
                        id = "progress-inner-bar",
                    ) { }

                    flexBox(
                        {
                            minWidth { "64.1px" }
                            justifyContent { end }
                            margins {
                                right { "-64.1px" }
                            }
                        },
                    ) {
                        flexBox(
                            {
                                width { full }
                                direction { column }
                                justifyContent { spaceBetween }
                                alignItems { start }
                                margins { left { small } }
                            },
                        ) {
                            // percentage
                            span(
                                {
                                    fontSize { large }
                                    lineHeight { "1" }
                                },
                            ) { +"$percentage%" }
                            // votes
                            span(
                                {
                                    fontSize { "8px" }
                                    lineHeight { "1" }
                                },
                            ) { translation[TL.Poll.NUMBER_VOTES, mapOf("number" to votes)].renderText(into = this) }
                        }
                    }
                }
            }
        }
    }
}
