package overlays

import dev.fritz2.core.RootStore
import dev.fritz2.core.SimpleHandler
import kotlinx.coroutines.Job
import model.Overlay
import model.OverlayState

open class OverlayStore(initialData: OverlayState) : RootStore<OverlayState>(
    initialData = initialData,
    job = Job(),
) {

    open val reset: SimpleHandler<Unit>? = null

    open val trigger: SimpleHandler<Unit>? = null

    open val remove: SimpleHandler<Unit>? = null

    open fun show(overlay: Overlay) {}

    open val flip: SimpleHandler<Unit>? = null
}
