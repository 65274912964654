package utils

private external fun setLogLevel(lvl: Int)

enum class JsLogLevel {
    OFF,
    ERROR,
    WARN,
    INFO,
    DEBUG,
}

fun setJsLogLevel(level: JsLogLevel) {
    val window = kotlinx.browser.window
    window.asDynamic().setLogLevel(level.ordinal)
//    setLogLevel(level.ordinal)
}