package data.objects.objecthistory

import analyticsdashboard.AnalyticsTimeFilterStore
import analyticsdashboard.TimeFilter
import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.ObjectType
import apiclient.geoobjects.pointCoordinates
import apiclient.search.ObjectSearchResult
import apiclient.util.formatIsoDate
import auth.ApiUserStore
import auth.FeatureFlagStore
import auth.Features
import com.jillesvangurp.geojson.Geometry
import com.jillesvangurp.geojson.LineStringCoordinates
import data.objects.ActiveObjectStore
import dev.fritz2.core.RootStore
import koin.koinCtx
import kotlinx.coroutines.Job
import kotlinx.datetime.Clock
import map.MapLayersStore
import maplibreGL.MaplibreMap
import model.LayerType
import model.toSearchResult
import model.toSearchResults
import utils.parseInstant

class DisplayedPathObjectResultsStore : RootStore<Map<String, ObjectAndResults>>(
    initialData = emptyMap(),
    job = Job(),
) {

    val maplibreMap: MaplibreMap by koinCtx.inject()
    val apiUserStore: ApiUserStore by koinCtx.inject()
    val activeObjectStore: ActiveObjectStore by koinCtx.inject()
    val mapLayersStore: MapLayersStore by koinCtx.inject()
    val featureFlagStore: FeatureFlagStore by koinCtx.inject()
    private val analyticsTimeFilterStore: AnalyticsTimeFilterStore by koinCtx.inject()

    val displayOrRemoveTrackedObjectHistory = handle<Pair<String, ObjectAndResults>> { current, (id, data) ->
        if (id in current) {
            maplibreMap.removeActiveObjectOverride(id)
            (current - id).applyFilters(analyticsTimeFilterStore.current).also { filteredResults ->
                filteredResults.renderHistoryResultsOnMap()
            }
        } else {
            // filter results and display on map
            current.add(data).applyFilters(analyticsTimeFilterStore.current).also { filteredResults ->
                filteredResults.renderHistoryResultsOnMap()
            }
        }
    }

    /**
     * updates this store [DisplayedPathObjectResultsStore]
     * with the newly fetched data of any displayed object
     */
    val updateFromCache = handle<Map<String, ObjectAndResults>> { current, newData ->
        current.mapNotNull { (id, data) ->
            if (data.geoObject.objectType in historyPathObjectTypeCriteria && featureFlagStore.current[Features.AllowHistoryPaths] == true) {
                newData[id]?.let { id to it }
            } else {
                null
            }
        }.toMap().also { filteredResults ->
            console.log(
                "Render updated and filtered results from cache!",
                "${filteredResults.map { it.value.geoObject.title to it.value.results.size }.toTypedArray()}",
            )
            update(filteredResults)
            filteredResults.renderHistoryResultsOnMap()
        }
    }

    fun Map<String, ObjectAndResults>.renderHistoryResultsOnMap() {

        maplibreMap.addPathToolHighlightOverrides(
            this.map { (_, data) -> data.results.map { it.hit.id } }.flatten().toSet(),
        )

        val lines = this.map { (id, data) ->
            createHistoryPathObject(
                id,
                (data.results.reversed() + data.geoObject.toSearchResult()).reversed(),
            ) // add location of object as last point of the path, to always connect the history events to the object
        }
        mapLayersStore.setResults(
            LayerType.HistoryPath,
            (lines + this.values.flatMap { (_, results) -> results.map { it.hit } }).toSearchResults(),
        )
    }

    private fun createHistoryPathObject(objId: String, events: List<ObjectSearchResult>): GeoObjectDetails {
        val activeObject = activeObjectStore.current
        val coordinates: LineStringCoordinates = events.map {
            it.hit.latLon.pointCoordinates()
        }.toTypedArray()
        val geometry: Geometry = Geometry.LineString(coordinates)
        return GeoObjectDetails(
            id = "${objId}-history-path",
            ownerId = apiUserStore.current.userId,
            objectType = ObjectType.HistoryEntry,
            createdAt = Clock.System.now().formatIsoDate(),
            updatedAt = Clock.System.now().formatIsoDate(),
            latLon = activeObject.latLon,
            title = "", //"Object History Path",
            tags = emptyList(),
            canManage = false,
            canModify = false,
            geometry = geometry,
        )
    }

}

/**
 * Helper function to add an entry Pair<[GeoObjectDetails], List<[ObjectSearchResult]>
 * to a Map<[String], [ObjectAndResults]>
 */
fun Map<String, ObjectAndResults>.add(
    data: ObjectAndResults
): Map<String, ObjectAndResults> {
    return data.let { (obj, data) ->
        val mutable = this.toMutableMap()
        mutable[obj.id] = ObjectAndResults(obj, data)
        mutable
    }
}

/**
 * Applies pre-defined type filters and time filter to the Tracked Object history events
 */

fun Map<String, ObjectAndResults>.applyFilters(
    timeFilter: TimeFilter
): Map<String, ObjectAndResults> {
    return this.map { entry ->
        entry.toPair().applyFilters(timeFilter)
    }.toMap()
}

fun Pair<String, ObjectAndResults>.applyFilters(
    timeFilter: TimeFilter
): Pair<String, ObjectAndResults> {
    return this.let { (id, data) ->
        id to ObjectAndResults(
            geoObject = data.geoObject,
            results = data.results.filter { result ->
                // HistoryChangeTypeFilter
                result.hasLocation() && result.changeType() in data.geoObject.objectType.historyEntryTypes()
                    // TimeFilter
                    && result.hit.createdAt.parseInstant()?.toEpochMilliseconds()?.let { eventTime ->
                    val start = timeFilter.filterStartDate?.parseInstant()?.toEpochMilliseconds()
                    val end = timeFilter.filterEndDate?.parseInstant()?.toEpochMilliseconds()
                    end?.let {
                        eventTime <= end && start?.let { eventTime >= start } ?: true
                    } ?: start?.let { eventTime >= start } ?: true
                } ?: true
            },
        )
    }
}
