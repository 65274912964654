// generated from build.gradle.kts, don't modify
package generated

interface IServerConfig {
    val ssl: Boolean
    val host: String
    val port: Int
    val buildTimestamp: String
    val apiVersion: String
    val developmentMode: Boolean
}

val ServerConfig = object : IServerConfig {
    override val ssl = true
    override val host = "api.tryformation.com"
    override val port = 443
    override val buildTimestamp="2024-10-24"
    override val apiVersion="0.1.88"
    override val developmentMode=false
}

const val OFFLINE_MODE = false
