@file:OptIn(ExperimentalCoroutinesApi::class)

package workspacetools.zoneexporter

import analyticsdashboard.AnalyticsDashboardTexts
import com.tryformation.localization.Translatable
import dev.fritz2.components.compat.a
import dev.fritz2.components.compat.table
import dev.fritz2.components.compat.td
import dev.fritz2.components.compat.th
import dev.fritz2.components.compat.thead
import dev.fritz2.components.compat.tr
import dev.fritz2.components.flexBox
import dev.fritz2.components.lineUp
import dev.fritz2.components.pushButton
import dev.fritz2.components.stackUp
import dev.fritz2.core.RenderContext
import dev.fritz2.core.download
import dev.fritz2.core.href
import dev.fritz2.routing.encodeURIComponent
import koin.koinCtx
import kotlinx.coroutines.ExperimentalCoroutinesApi
import localization.Translation
import styling.primaryButtonStyleParams
import theme.FormationDefault
import webcomponents.cardTitle
import workspacetools.usermanagement.DashboardTexts
import workspacetools.usermanagement.TsvStore

enum class ZoneTexts : Translatable {
    Title,
    DaysLabel,
    ExportButton,
    Generating,
    ExportZoneHistory
    ;

    override val prefix = "zone-exporter"
}

fun RenderContext.zoneHistory() {
    val translation by koinCtx.inject<Translation>()
    val tsvStore by koinCtx.inject<TsvStore>()
    stackUp({
        width { full }
        height { full }
        paddings {
            horizontal { small }
        }
        overflow { auto }
    }) {
        spacing { small }
        items {
            cardTitle(translation[ZoneTexts.ExportZoneHistory])

            p {
                translation[DashboardTexts.DashboardExplainerLong].renderText(into = this)
            }

            tsvStore.data.render { tsv ->
                lineUp({
                    margins { bottom { small } }
                }) {
                    spacing { none }
                    items {
                        a(id = tsvStore.linkId, style = {
                            display { none }
                        }) {
                            href("data:text/plain;charset=utf-8,${encodeURIComponent(tsv)}")
                            download("zone-history.tsv")
                        }
                        pushButton({
                            primaryButtonStyleParams()
                        }) {
                            icon { download }
                            type { primary }
                            text(translation.getString(ZoneTexts.ExportButton))
                            enabled(tsv.isNotBlank())
                            events {
                                clicks handledBy tsvStore.download
                            }
                        }
                    }
                }

                tsv.lines().map { it.split('\t') }.let { all ->
                    flexBox({
                        border {
                            width(FormationDefault.formationStyles.borderWidth)
                            color { primary.main }
                        }
                        radius { huge }
                        maxWidth { full }
                        overflow { auto }
                    }) {
                        if (all.size > 1) {
                            val header = all.first()
                            val rows = all.slice(1 until all.size)
                            table({
                                width { full }
                                overflow { hidden }
                            }, id = "zone-export-list") {
                                // TABLE HEADER
                                thead({
                                    color { secondary.main }
                                    position { sticky { top { none } } }
                                }) {
                                    header.forEach { columnName ->
                                        val columnIndex = header.indexOf(columnName)

                                        th({
                                            margins { horizontal { tiny } }
                                            padding { tiny }
                                            css("cursor:pointer;")
                                            // use box-shadow instead of borders, as sticky header makes normal borders invisible
                                            when (columnIndex) {
                                                0 -> css("box-shadow: inset -0.5px 0px #555;")
                                                in 1 until header.size -> css("box-shadow: inset 0.5px 0px #555, inset -0.5px 0px #555;")
                                                header.size -> css("box-shadow: inset 0.5px 0px #555;")
                                            }
                                            // Coloured active columns?
                                            background {
                                                color { primary.main }
                                            }
                                            hover {
                                                background {
                                                    color { primary.highlight }
                                                }
                                            }
                                        }) {
                                            +columnName.replace("\"", "")
                                        }
                                    }
                                }
                                // NO DATA
                                if (rows.isEmpty()) {
                                    tr {
                                        td({
                                            padding { tiny }
                                            borders {
                                                top { width(FormationDefault.formationStyles.borderWidth) }
                                            }
                                        }) {
                                            translation[AnalyticsDashboardTexts.NO_DATA].renderText(into = this)
                                        }
                                        for (i in 2..header.size) {
                                            td({
                                                padding { tiny }
                                                borders {
                                                    top { width(FormationDefault.formationStyles.borderWidth) }
                                                    left { width(FormationDefault.formationStyles.borderWidth) }
                                                }
                                            }) { }
                                        }
                                    }
                                }

                                rows.map { rows.indexOf(it) to it }.forEach { (index, row) ->
                                    if (row.isNotEmpty() && row.joinToString("").isNotEmpty()) {
                                        tr({
                                            if (index % 2 != 0) {
                                                background {
                                                    color { gray100 }
                                                }
                                            }
                                        }) {
                                            row.map { row.indexOf(it) to it }.forEach { (columnIndex, column) ->
                                                td({
                                                    margins { horizontal { tiny } }
                                                    padding { tiny }
                                                    borders {
                                                        top { width(FormationDefault.formationStyles.borderWidth) }
                                                        if (columnIndex != 0) left { width(FormationDefault.formationStyles.borderWidth) }
                                                    }
                                                }) {
                                                    +column.replace("\"", "")
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
