package analyticsdashboard


import analyticsdashboard.importexport.objectImportExport
import apiclient.FormationClient
import apiclient.analytics.AnalyticsData
import apiclient.analytics.Dashboard
import apiclient.analytics.DashboardElement
import apiclient.analytics.DashboardParamNames
import apiclient.analytics.StandardDashboards
import apiclient.analytics.columNames
import apiclient.analytics.produceAnalyticsForDashboardElement
import apiclient.analytics.rows
import apiclient.analytics.toDashBoards
import apiclient.groups.GroupFeatureFlags
import apiclient.groups.LayerType
import apiclient.groups.featureFlags
import auth.CurrentWorkspaceStore
import auth.FeatureFlagStore
import auth.Features
import data.objects.objecthistory.DisplayedPathObjectResultsStore
import data.objects.objecthistory.ObjectHistoryResultsCache
import data.objects.objecthistory.ObjectHistoryResultsStore
import data.objects.objecthistory.ShowObjectHistoryPathStore
import data.objects.views.cardManageFieldValueTag
import data.objects.views.objectHistoryList
import data.objects.views.tagManagement
import dev.fritz2.components.clickButton
import dev.fritz2.components.compat.div
import dev.fritz2.components.compat.span
import dev.fritz2.components.compat.table
import dev.fritz2.components.compat.td
import dev.fritz2.components.compat.th
import dev.fritz2.components.compat.thead
import dev.fritz2.components.compat.tr
import dev.fritz2.components.flexBox
import dev.fritz2.components.icon
import dev.fritz2.components.lineUp
import dev.fritz2.components.modal
import dev.fritz2.components.pushButton
import dev.fritz2.components.stackUp
import dev.fritz2.core.Handler
import dev.fritz2.core.Id
import dev.fritz2.core.RenderContext
import dev.fritz2.core.RootStore
import dev.fritz2.core.SimpleHandler
import dev.fritz2.core.Store
import dev.fritz2.core.invoke
import dev.fritz2.core.src
import dev.fritz2.core.storeOf
import dev.fritz2.core.title
import dev.fritz2.history.history
import dev.fritz2.routing.MapRouter
import dev.fritz2.styling.params.SizesProperty
import dev.fritz2.styling.theme.IconDefinition
import dev.fritz2.styling.theme.Icons
import dev.fritz2.tracking.Tracker
import dev.fritz2.tracking.tracker
import koin.koinCtx
import kotlinx.browser.document
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.mapNotNull
import kotlinx.serialization.json.longOrNull
import localization.TL
import localization.Translation
import mainmenu.Pages
import mainmenu.RouterStore
import map.views.mapLayerList
import maplibreGL.MaplibreMap
import org.koin.dsl.module
import org.w3c.dom.HTMLIFrameElement
import overlays.BusyStore
import search.PathActiveHighlightedObjectMarkersStore
import search.SearchDistanceCalculationStore
import search.distanceCalcSwitch
import search.global.coordinatesButtons
import search.global.searchHeader
import search.global.searchInput
import search.global.searchTagButton
import search.global.selectedSearchTagsList
import search.pathToolSearchResultsList
import search.searchlayer.MapLayerMetadataListStore
import search.searchlayer.MapSearchClientsStore
import services.SuggestedTagsContextStore
import styling.primaryButtonStyleParams
import theme.FormationColors
import theme.FormationDefault.Companion.formationStyles
import theme.FormationIcons
import theme.FormationUIIcons
import theme.IconEnum
import twcomponents.twIconMedium
import utils.formatDateTimeForObjectHistory
import utils.makeRGBA
import utils.parseInstant
import webcomponents.BigButtonOption
import webcomponents.KeywordTagType
import webcomponents.Position
import webcomponents.contentScrollBox
import webcomponents.genericBigButtonSwitch
import webcomponents.genericButton
import webcomponents.oneButtonFooter
import workspacetools.zoneexporter.zoneHistory

fun analyticsDashboardModule() = module {
    single { WorkspaceAnalyticsDashboardsStore() }
    single { SelectedAnalyticsDashboardStore() }
    single { DashboardTableElementsSortingStore() }
    single { AnalyticsTimeFilterStore() }
    single { AnalyticsTextFilterStore() }
    single { SelectedTimeFilterOptionStore() }
}

fun getFieldValueTitle(fieldValueTag: String): String {
    val workspaceStore by koinCtx.inject<CurrentWorkspaceStore>()
    val tagValues = fieldValueTag.split(":")
    return if (tagValues.size == 2) {
        val (catNamespace, catValue) = tagValues
        workspaceStore.current?.categoryNamespaces?.firstOrNull {
            it.name == catNamespace
        }?.categories?.firstOrNull {
            it.name == catValue
        }?.title ?: catValue
    } else {
        fieldValueTag.parseInstant()?.let { it.formatDateTimeForObjectHistory() }
            ?: fieldValueTag
    }
}

class WorkspaceAnalyticsDashboardsStore : RootStore<List<Dashboard>?>(
    initialData = null,
    job = Job(),
) {
    val currentWorkspaceStore by koinCtx.inject<CurrentWorkspaceStore>()
    private val selectedAnalyticsDashboardStore by koinCtx.inject<SelectedAnalyticsDashboardStore>()

    private val autoSelectFirst = handle<List<Dashboard>> { _, workspaceDashboards ->
        workspaceDashboards.firstOrNull()?.let { dashboard ->
            selectedAnalyticsDashboardStore.update(dashboard)
        }
        workspaceDashboards
    }

    init {
        currentWorkspaceStore.data.map { workspace ->
            val standardDashboards =
                if (workspace?.featureFlags()?.get(GroupFeatureFlags.AllowStandardDashboards) == true) {
                    listOf(
                        StandardDashboards.objectMovements,
                        StandardDashboards.contentCreation,
                        StandardDashboards.taskDashboard,
                    )
                } else {
                    listOf()
                }

            standardDashboards + (workspace?.dashboards.toDashBoards() ?: emptyList())
        } handledBy update
        data.mapNotNull { it } handledBy autoSelectFirst
    }
}

class DashboardTableElementsSortingStore : RootStore<Map<DashboardElement, DashboardTableSortedColumnState?>>(
    initialData = emptyMap(),
    job = Job(),
) {

    val addOrUpdate =
        handle<Pair<DashboardElement, DashboardTableSortedColumnState?>> { current, (element, sortingState) ->
            val currentMutable = current.toMutableMap()
            currentMutable[element] = sortingState
            currentMutable.toMap()
        }
}

class SelectedAnalyticsDashboardStore : RootStore<Dashboard?>(
    initialData = null,
    job = Job(),
) {

    val currentWorkspaceStore by koinCtx.inject<CurrentWorkspaceStore>()
    val formationClient by koinCtx.inject<FormationClient>()
    val busyStore by koinCtx.inject<BusyStore>()
    val analyticsTimeFilterStore: AnalyticsTimeFilterStore by koinCtx.inject()
    val analyticsTextFilterStore: AnalyticsTextFilterStore by koinCtx.inject()
    private val dashboardTableElementsSortingStore by koinCtx.inject<DashboardTableElementsSortingStore>()

    val computedAnalyticsStore: Store<Map<DashboardElement, AnalyticsData>> = storeOf(emptyMap(), Job())

    val refreshTracker = tracker()

    private fun DashboardElement.addTimeFilter(): DashboardElement {
        val timefilter = analyticsTimeFilterStore.current
        return this.copy(
            searchQueryContext = this.searchQueryContext.copy(
                timeRangeOnCreatedAt = true,
                fromTime = timefilter.filterStartDate,
                toTime = timefilter.filterEndDate,
            ),
        )
    }

    private suspend fun computeDataForElement(elementId: String, debug: Boolean): AnalyticsData? {
        val groupId = currentWorkspaceStore.current?.groupId
        val dashboard = current
        return if (groupId != null && dashboard != null) {
            dashboard.elements.firstOrNull { it.id == elementId }?.let { element ->
                val newData =
                    formationClient.produceAnalyticsForDashboardElement(groupId, element.addTimeFilter(), debug = debug)
                        .fold(
                            onSuccess = { data ->
                                data
                            },
                            onFailure = { e ->
                                console.warn("No data available for dashboard element: ${element.title}", e)
                                null
                            },
                        )
                newData
            }
        } else null
    }

    val computeAllElementData = handle { current ->
        refreshTracker.track {
            current?.elements?.mapNotNull { element ->
                computeDataForElement(element.id, debug = false)?.let { element to it }
            }?.toMap()?.let { elementDataMap ->
                computedAnalyticsStore.update(elementDataMap)
            }
        }
        current
    }

    val computeSingleElementData = handle<Pair<DashboardElement, Tracker>> { current, (element, elementTracker) ->
        elementTracker.track {
            computeDataForElement(element.id, debug = true)?.let { elementData ->
                val updatedElementDataMap = computedAnalyticsStore.current.toMutableMap()
                updatedElementDataMap[element] = elementData
                computedAnalyticsStore.update(updatedElementDataMap)
            }
        }
        current
    }

    val printElement = handle<DashboardElement> { current, element ->
        if (current != null) {
            computedAnalyticsStore.current[element]?.let { data ->
                when (data) {
                    is AnalyticsData.JsonPrimitiveTable -> {
                        val filteredData = filterDashboardTableData(analyticsTextFilterStore.current, data)
                        val sortedData = dashboardTableElementsSortingStore.current[element]?.let { sorting ->
                            sortDashboardTableRows(element.analyticsType, filteredData, sorting)
                        } ?: filteredData
                        val html = getPrintableTable(element, sortedData, current)
                        val iframe = document.createElement("iframe") as HTMLIFrameElement
                        iframe.style.display = "none"
                        document.body?.appendChild(iframe)
                        val pri = iframe.contentWindow
                        pri?.let {
                            pri.document.open()
                            pri.document.write(html)
                            pri.document.close()
                            pri.focus()
                            pri.onafterprint = {
                                document.body?.removeChild(iframe)
                            }
                            pri.print()
                        }
                    }

                    else -> {}
                }
            }
        }
        current
    }

    init {
        data.map { } handledBy computeAllElementData
        analyticsTimeFilterStore.data.mapNotNull { filter ->
            if ((filter.filterStartDate != null) == (filter.filterEndDate != null)) {
                console.log("Choose time filter -> ${filter.filterStartDate} to ${filter.filterEndDate}")
            } else {
                null
            }
        } handledBy computeAllElementData
    }
}

enum class AnalyticPage {
    Stats {
        override val icon: IconEnum = FormationUIIcons.BarChartAlt
        override val mapInteraction: Boolean = false
        override val sidePages: Set<AnalyticSidePage> = setOf()
    },
    Paths {
        override val icon: IconEnum = FormationIcons.Zone
        override val mapInteraction: Boolean = true
        override val sidePages: Set<AnalyticSidePage> = setOf(
            AnalyticSidePage.PathsSearch,
            AnalyticSidePage.PathsHistory,
        )
    },
    Heatmaps {
        override val icon: IconEnum = FormationIcons.HeatmapAlt
        override val mapInteraction: Boolean = true
        override val sidePages: Set<AnalyticSidePage> = setOf()
    },
    Exports {
        override val icon: IconEnum = FormationUIIcons.Export
        override val mapInteraction: Boolean = false
        override val sidePages: Set<AnalyticSidePage> = setOf(
            AnalyticSidePage.ExportZoneHistory,
            AnalyticSidePage.ObjectImportExport,
        )
    },
    ;

    abstract val icon: IconEnum
    abstract val mapInteraction: Boolean
    abstract val sidePages: Set<AnalyticSidePage>
}

enum class AnalyticSidePage {
    PathsSearch, PathsHistory, ExportZoneHistory, ObjectImportExport
}

enum class AnalyticSideSubPage {
    PathsSearchFieldValue, PathsSearchTags
}

class AnalyticsPageStore : RootStore<AnalyticPage?>(
    initialData = AnalyticPage.Stats,
    job = Job(),
) {

    val maplibreMap: MaplibreMap by koinCtx.inject()
    val router: MapRouter by koinCtx.inject()
    val showObjectHistoryPathStore: ShowObjectHistoryPathStore by koinCtx.inject()
    val pathActivehighlightedObjectMarkersStore: PathActiveHighlightedObjectMarkersStore by koinCtx.inject()
    val pathSearchResultsStore: PathSearchResultsStore by koinCtx.inject()
    val objectHistoryResultsCache: ObjectHistoryResultsCache by koinCtx.inject()
    val pathActiveHighlightedObjectStore: PathActiveHighlightedObjectStore by koinCtx.inject()
    val activeHistoryPathSearchKeywordsStore: ActiveHistoryPathSearchKeywordsStore by koinCtx.inject()
    val activeHistoryPathSearchObjectTypesStore: ActiveHistoryPathSearchObjectTypesStore by koinCtx.inject()
    val activeHistoryPathSearchReadOnlyKeywordsStore: ActiveHistoryPathSearchReadOnlyKeywordsStore by koinCtx.inject()
    val activeHistoryPathSearchFieldValuesStore: ActiveHistoryPathSearchFieldValuesStore by koinCtx.inject()
    val analyticsSidePageStore: AnalyticsSidePageStore by koinCtx.inject()
    val analyticsSideSubPageStore: AnalyticsSideSubPageStore by koinCtx.inject()
    val objectHistoryResultsStore: ObjectHistoryResultsStore by koinCtx.inject()
    val mapSearchClientStore: MapSearchClientsStore by koinCtx.inject()

    val initialize = handle {
        console.log("Initialized AnalyticsPageStore")
        AnalyticPage.Stats
    }

    val prepare = SimpleHandler<AnalyticPage?> { newPageData, _ ->
        newPageData handledBy { newPage ->
            // auto select first sidePage for corresponding AnalyticPage
            newPage?.sidePages?.firstOrNull()?.let { analyticsSidePageStore.update(it) }

            when (newPage) {
                AnalyticPage.Stats -> {
                    pathActivehighlightedObjectMarkersStore.initialize()
                    showObjectHistoryPathStore.update(false)
                }

                AnalyticPage.Paths -> {
                    pathSearchResultsStore.triggerSearch()
                    pathActivehighlightedObjectMarkersStore.refresh()
                    showObjectHistoryPathStore.update(true)
                }

                AnalyticPage.Heatmaps -> {
                    pathActivehighlightedObjectMarkersStore.initialize()
                    showObjectHistoryPathStore.update(false)
                }

                AnalyticPage.Exports -> {
                    pathActivehighlightedObjectMarkersStore.initialize()
                    showObjectHistoryPathStore.update(false)
                }

                else -> {
                    pathActivehighlightedObjectMarkersStore.initialize()
                    showObjectHistoryPathStore.update(false)
                }
            }
        }
    }

    private val analyticsPageListener = handle<Map<String, String>> { _, route ->
        if (route["page"] == Pages.AnalyticsDashboard.name) {
            // initialize analytics stores
            pathSearchResultsStore.triggerSearch()
            pathActivehighlightedObjectMarkersStore.refresh()
            showObjectHistoryPathStore.update(true)
        } else {
            // reset analytics stores
            pathSearchResultsStore.reset()
            objectHistoryResultsCache.clearObjectHistoryResultsCache() // TODO: necessary or keep cache longer?
            pathActiveHighlightedObjectStore.reset()
            activeHistoryPathSearchKeywordsStore.reset()
            activeHistoryPathSearchObjectTypesStore.reset()
            activeHistoryPathSearchReadOnlyKeywordsStore.reset()
            activeHistoryPathSearchFieldValuesStore.reset()
            maplibreMap.clearClustersFromMap()
            showObjectHistoryPathStore.update(false)
            maplibreMap.setPathToolHighlightOverrides(emptySet())
            analyticsSidePageStore.reset()
            analyticsSideSubPageStore.reset()
            objectHistoryResultsStore.reset()
            maplibreMap.removeAllActiveObjectOverrides()
        }
        AnalyticPage.Stats
    }

    init {
        data handledBy prepare
        router.data handledBy analyticsPageListener
    }
}

class AnalyticsSidePageStore : RootStore<AnalyticSidePage?>(
    initialData = AnalyticSidePage.PathsSearch,
    job = Job(),
) {
    val reset = handle { AnalyticSidePage.PathsSearch }
}

class AnalyticsSideSubPageStore : RootStore<AnalyticSideSubPage?>(
    initialData = null,
    job = Job(),
) {
    val history = history(
        capacity = 100,
        initialEntries = listOf(null),
    )

    val back = handle { _ ->
        if (history.current.isNotEmpty()) {
            val previous = history.back()
            previous
        } else {
            history.push(null)
            null
        }
    }

    val reset = handle { null }
}

fun RenderContext.pageAnalyticsDashboard() {
    val translation: Translation by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()

    // Filter
    val analyticsTimeFilterStore: AnalyticsTimeFilterStore by koinCtx.inject()

    // StatsTool
    val workspaceAnalyticsDashboardsStore: WorkspaceAnalyticsDashboardsStore by koinCtx.inject()
    val selectedAnalyticsDashboardStore: SelectedAnalyticsDashboardStore by koinCtx.inject()

    // PathToolSearch
    val pathToolSearchInputStore: PathToolSearchInputStore by koinCtx.inject()
    val pathSearchResultsStore: PathSearchResultsStore by koinCtx.inject()
    val displayedPathObjectResultsStore: DisplayedPathObjectResultsStore by koinCtx.inject()
    val pathActivehighlightedObjectMarkersStore: PathActiveHighlightedObjectMarkersStore by koinCtx.inject()
    val activeHistoryPathSearchKeywordsStore: ActiveHistoryPathSearchKeywordsStore by koinCtx.inject()
    val activeHistoryPathSearchObjectTypesStore: ActiveHistoryPathSearchObjectTypesStore by koinCtx.inject()
    val activeHistoryPathSearchReadOnlyKeywordsStore: ActiveHistoryPathSearchReadOnlyKeywordsStore by koinCtx.inject()
    val activeHistoryPathSearchFieldValuesStore: ActiveHistoryPathSearchFieldValuesStore by koinCtx.inject()
    val suggestedTagsContextStore: SuggestedTagsContextStore by koinCtx.inject()
    val searchDistanceCalculationStore: SearchDistanceCalculationStore by koinCtx.inject()
    val mapLayerMetaDataListStore: MapLayerMetadataListStore by koinCtx.inject()
    val featureFlagStore: FeatureFlagStore by koinCtx.inject()

    // Main navigation
    val analyticsPageStore: AnalyticsPageStore by koinCtx.inject()
    // Sidebar navigation
    val analyticsSidePageStore: AnalyticsSidePageStore by koinCtx.inject()
    val analyticsSideSubPageStore: AnalyticsSideSubPageStore by koinCtx.inject()

    analyticsPageStore.initialize()

    combine(analyticsPageStore.data, analyticsSidePageStore.data) { p, s -> Pair(p, s) }
        .render { (analyticPage, sidePage) ->
            // Main grid
            div("grid w-full h-full fixed inset-0 z-[1035]") {
                inlineStyle(
                    """
                    grid-gap: 12px;
                    grid-template-columns: minmax(200px, 400px) minmax(50px, 2fr) minmax(50px, 2fr) minmax(50px, 2fr);
                    grid-template-rows: auto auto 1fr;
                    grid-template-areas:
                    "header  header  header header"
                    "filter filter filter filter"
                    "sidebar content content content"
                """.trimIndent(),
                )

                if (analyticPage?.mapInteraction == false) {
                    // hide map completely for some pages
                    className("bg-gray-200 pointer-events-auto")
                } else {
                    // show map and keep it interactive
                    className("pointer-events-none")
                }

                // MAIN HEADER
                div("flex flex-row w-full items-center justify-between py-0.5 pl-0.5 pr-3 bg-formationWhite pointer-events-auto overflow-x-scroll") {
                    inlineStyle("grid-area:header; grid-row:1;")
                    // Title
                    div("flex flex-row w-max items-center justify-center mr-3") {
                        // Logo
                        img("h-10 md:h-15 w-auto p-2 mx-3") {
                            src("assets/images/logo-blue_1.svg")
                        }
                        // InsightsSuite
                        div("flex flex-row flex-wrap items-start justify-start") {
                            span("text-xs md:text-xl font-bold") { +"Insights" }
                            span("text-xs md:text-xl font-light") { +"Suite" }
                        }
                    }

                    // Box for AnalyticsPage navigation buttons
                    div("flex flex-row items-center justify-center flex-wrap text-xs md:text-base") {
                        AnalyticPage.entries.forEach { page ->
                            analyticsMainPageButton(
                                analyticsPage = page,
                                icon = page.icon,
                                iconSize = { huge },
                                title = flowOf(page.name),
                                active = analyticPage == page,
                            )
                        }
                    }
                    // Back button
                    dashboardIconButton(
                        icon = { FormationUIIcons.ArrowLeft.icon },
                        value = Unit,
                        clickHandlers = listOf(
                            pathActivehighlightedObjectMarkersStore.initialize,
                            analyticsTimeFilterStore.reset,
                            routerStore.back,
                        ),
                    )
                }
                // SIDEBAR
                // Sidebar grid container
                div("flex flex-col bg-formationWhite items-stretch justify-start grow-1 shrink-1 basis-min p-1 ml-3 mb-3 rounded-xl pointer-events-auto overflow-y-auto") {
                    inlineStyle("grid-area:sidebar; grid-row:3;")
                    // Sidebar content navigation via AnalyticPage and AnalyticSidePage
                    when (analyticPage) {
                        AnalyticPage.Stats -> {
                            contentScrollBox {
                                combine(
                                    workspaceAnalyticsDashboardsStore.data,
                                    selectedAnalyticsDashboardStore.data,
                                ) { ds, d ->
                                    Pair(ds, d)
                                }.render(into = this) { (dashbaords, selected) ->
                                    flexBox(
                                        {
                                            direction { column }
                                            alignItems { stretch }
                                            justifyContent { center }
                                            margins { top { small } }
                                            children("div > button") {
                                                margins {
                                                    bottom { tiny }
                                                }
                                            }
                                        },
                                    ) {
                                        // Dashboards tabs
                                        dashbaords?.forEach { dashboard ->
                                            dashboardTab(dashboard, active = selected == dashboard)
                                        }
                                    }
                                }
                            }
                        }

                        AnalyticPage.Paths -> {
                            div(
                                {
                                    width { full }
                                    paddings {
                                        horizontal { small }
                                    }
                                },
                            ) {
                                genericBigButtonSwitch(
                                    analyticsSidePageStore,
                                    listOf(
                                        BigButtonOption(
                                            title = flowOf("Assets"),
                                            value = AnalyticSidePage.PathsSearch,
                                            selectHandler = analyticsSidePageStore.update,
                                        ),
                                        BigButtonOption(
                                            title = displayedPathObjectResultsStore.data.map { "History (${it.size})" },
                                            value = AnalyticSidePage.PathsHistory,
                                            selectHandler = analyticsSidePageStore.update,
                                            disabled = displayedPathObjectResultsStore.data.map { it.isNullOrEmpty() },
                                        ),
                                    ),
                                )
                            }
                            if (sidePage in analyticPage.sidePages) {
                                when (sidePage) {
                                    AnalyticSidePage.PathsSearch -> {
                                        analyticsSideSubPageStore.data.render { sideSubPage ->
                                            when (sideSubPage) {
                                                AnalyticSideSubPage.PathsSearchTags -> {
                                                    contentScrollBox {
                                                        tagManagement(type = KeywordTagType.AnalyticsPathViewTag)
                                                    }
                                                    oneButtonFooter(
                                                        title = translation[TL.General.SET],
                                                        value = null,
                                                        valueHandlers = listOf(analyticsSideSubPageStore.update),
                                                        clickHandlers = listOf(suggestedTagsContextStore.resetPrefix),
                                                    )
                                                }

                                                AnalyticSideSubPage.PathsSearchFieldValue -> {
                                                    cardManageFieldValueTag(tagType = KeywordTagType.AnalyticsPathViewTag)
                                                }

                                                else -> {
                                                    div("flex flex-col items-stretch justify-center px-4") {
                                                        // SEARCH INPUT FIELD & TAG BUTTON
                                                        div(
                                                            "w-full flex items-center mb-1 gap-3",
                                                        ) {
                                                            inlineStyle("height: ${formationStyles.inputHeight};")
                                                            searchInput(
                                                                id = "pathtool-search-input",
                                                                pathToolSearchInputStore,
                                                            )
                                                            searchTagButton(
                                                                navValue = AnalyticSideSubPage.PathsSearchTags,
                                                                navHandlers = listOf(analyticsSideSubPageStore.update),
                                                            )
                                                        }

                                                        // COORDINATE BUTTONS
                                                        coordinatesButtons(pathToolSearchInputStore)

                                                        div("w-full pb-1") {
                                                            // SELECTED TAGS
                                                            selectedSearchTagsList(
                                                                activeSearchKeywordsStore = activeHistoryPathSearchKeywordsStore,
                                                                activeSearchObjectTypesStore = activeHistoryPathSearchObjectTypesStore,
                                                                activeSearchReadOnlyKeywordsStore = activeHistoryPathSearchReadOnlyKeywordsStore,
                                                                activeSearchFieldValuesStore = activeHistoryPathSearchFieldValuesStore,
                                                                keywordTagType = KeywordTagType.AnalyticsPathViewTag,
                                                            )
                                                        }

                                                        div("w-full pb-1") {
                                                            // distance calculation switch
                                                            distanceCalcSwitch(
                                                                distanceToSelectStore = searchDistanceCalculationStore,
                                                                switchHandler = searchDistanceCalculationStore.select,
                                                            )
                                                        }
                                                    }
                                                    contentScrollBox {
                                                        // SEARCH RESULTS
                                                        pathToolSearchResultsList(
                                                            results = pathSearchResultsStore.data.mapNotNull { it },
                                                            selectedIds = displayedPathObjectResultsStore.data.mapNotNull { it.keys.toList() },
                                                        )
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    AnalyticSidePage.PathsHistory -> {
                                        contentScrollBox {
                                            objectHistoryList(
                                                results = displayedPathObjectResultsStore.data,
                                                multiList = true,
                                                interactive = true,
                                            )
                                        }
                                    }

                                    else -> {
                                        searchHeader(pathToolSearchInputStore)
                                    }
                                }
                            }
                        }

                        AnalyticPage.Heatmaps -> {
                            div("flex flex-col items-center h-full w-full mt-3 px-3") {
                                // list of heatmaplayers here
                                mapLayerList(mapLayerMetaDataListStore.data.map { it.filter { it.layerType == LayerType.Heatmap } })
                            }
                        }

                        AnalyticPage.Exports -> {
                            contentScrollBox {
                                div("flex flex-col items-stretch justify-start h-full w-full mt-3 gap-2") {
                                    featureFlagStore.data.render { featureflags ->
                                        if (featureflags[Features.AllowZoneHistoryExport] == true) {
                                            genericButton(
                                                title = flowOf("Zone History Export"), // TODO translate
                                                icon = { FormationIcons.History.icon },
                                                width = { auto },
                                                styleFlow = flowOf {
                                                    if (sidePage == AnalyticSidePage.ExportZoneHistory) {
                                                        primaryButtonStyleParams()
                                                    } else {
                                                        color { FormationColors.GrayDisabled.color }
                                                        background { color { secondary.main } }
                                                        fontSize { small }
                                                        fontWeight { bold }
                                                        radius(formationStyles.buttonRadius)
                                                    }
                                                    margins {
                                                        vertical { none }
                                                    }
                                                },
                                                valueHandlers = listOf(analyticsSidePageStore.update),
                                                value = AnalyticSidePage.ExportZoneHistory,
                                            )
                                        }
                                        if (featureflags[Features.AllowObjectImportExport] == true) {
                                            genericButton(
                                                title = flowOf("Object Import/Export"), // TODO translate
                                                icon = { FormationUIIcons.Select.icon },
                                                width = { auto },
                                                styleFlow = flowOf {
                                                    if (sidePage == AnalyticSidePage.ObjectImportExport) {
                                                        primaryButtonStyleParams()
                                                    } else {
                                                        color { FormationColors.GrayDisabled.color }
                                                        background { color { secondary.main } }
                                                        fontSize { small }
                                                        fontWeight { bold }
                                                        radius(formationStyles.buttonRadius)
                                                    }
                                                    margins {
                                                        vertical { none }
                                                    }
                                                },
                                                valueHandlers = listOf(analyticsSidePageStore.update),
                                                value = AnalyticSidePage.ObjectImportExport,
                                            )
                                        }
                                    }
                                    // Add more Buttons here for other Export Tools
                                }
                            }
                        }

                        else -> {}
                    }
                }

                // Filterbar
                div("flex flex-row grow-1 shrink-1 basis-max items-center justify-start bg-formationWhite p-2 flex-wrap rounded-xl pointer-events-auto") {
                    inlineStyle("grid-area:filter; grid-row:2;")
                    className(
                        if (analyticPage?.mapInteraction != true) {
                            "mx-3"
                        } else {
                            "ml-3 mr-15"
                        },
                    )
                    timeFilter(startPickerId = "pickerStartInput", endPickerId = "pickerEndInput")
                    if (analyticPage == AnalyticPage.Stats) {
                        dashboardIconButton(
                            title = translation[AnalyticsDashboardTexts.REFRESH_ALL_DATA],
                            icon = { refresh },
                            value = Unit,
                            tracker = selectedAnalyticsDashboardStore.refreshTracker,
                            clickHandlers = listOf(selectedAnalyticsDashboardStore.computeAllElementData),
                        )
                    }
                    textFilter()
                }

                // Dashboard scrollable content
                div("flex grow-1 shrink-1 bg-formationWhite overflow-y-auto p-1 rounded-xl") {
                    inlineStyle("grid-area:content; grid-row:3;")
                    className(
                        if (analyticPage?.mapInteraction != true) {
                            "mr-3 mb-3 pointer-events-auto"
                        } else {
                            "bg-transparent mb-13 mr-15 pointer-events-none"
                        },
                    )
                    when (analyticPage) {
                        AnalyticPage.Stats -> {
                            selectedAnalyticsDashboardStore.computedAnalyticsStore.data.render { elementMap ->
                                div("flex h-max items-start justify-start flex-wrap") {
                                    elementMap.forEach { (element, elementData) ->
                                        dashboardElement(element, elementData)
                                    }
                                }
                            }
                        }

                        AnalyticPage.Paths, AnalyticPage.Heatmaps -> {
                            /* No content here, only direct interaction with map underneath */
                        }

                        AnalyticPage.Exports -> {
                            featureFlagStore.data.render { featureflags ->
                                if (sidePage in analyticPage.sidePages) {
                                    when (sidePage) {
                                        AnalyticSidePage.ExportZoneHistory -> {
                                            if (featureflags[Features.AllowZoneHistoryExport] == true) {
                                                contentScrollBox {
                                                    zoneHistory()
                                                }
                                            }
                                        }

                                        AnalyticSidePage.ObjectImportExport -> {
                                            if (featureflags[Features.AllowObjectImportExport] == true) {
                                                contentScrollBox {
                                                    objectImportExport()
                                                }
                                            }
                                        }

                                        else -> {}
                                    }
                                }
                            }
                        }

                        else -> {}
                    }
                }
            }
        }
}

fun RenderContext.analyticsMainPageButton(
    analyticsPage: AnalyticPage,
    icon: IconEnum,
    iconSize: SizesProperty,
    title: Flow<String>,
    routingMap: Map<String, String>? = null,
    clickHandlers: List<SimpleHandler<Unit>>? = null,
    disabled: Boolean = false,
    prefix: String = "analyticsMainButton",
    active: Boolean = false,
) {
    val routerStore: RouterStore by koinCtx.inject()
    val analyticsPageStore by koinCtx.inject<AnalyticsPageStore>()

    button("h-full px-2 py-1", id = "$prefix-${Id.next()}") {
        className(
            if (disabled) {
                "text-gray-300"
            } else {
                "text-formationBlack"
            },
        )
        div("flex flex-row h-full items-center justify-center flex-nowrap") {
            className(
                if (disabled) {
                    "text-gray-300"
                } else {
                    if (active) {
                        "text-highlight"
                    } else {
                        "text-formationBlack"
                    }

                },
            )
//        }
//        flexBox(
//            {
//                height { full }
//                direction { row }
//                justifyContent { center }
//                alignItems { center }
//                wrap { nowrap }
//                if (disabled) {
//                    color { FormationColors.GrayDisabled.color }
//                } else {
//                    color {
//                        if (active) {
//                            FormationColors.MarkerYou.color
//                        } else primary.main
//                    }
//                }
//
//            },
//        ) {
            attr("onClick", "blur();")
            title(
                title.map { title ->
                    title.lowercase()
                        .replaceFirstChar { if (it.isLowerCase()) it.titlecase() else it.toString() }
                },
            )
            twIconMedium(icon = icon)
            span("font-semibold ml-2 hover:underline") {
                className(
                    if (active) {
                        "underline"
                    } else "",
                )
                title.renderText(into = this)
            }
        }
        if (!disabled) {
            with(clicks) {
                this.map { analyticsPage } handledBy analyticsPageStore.update
                clickHandlers?.forEach { handler ->
                    this handledBy handler
                }
            }
            if (routingMap != null) {
                clicks.map { routingMap } handledBy routerStore.validateInternalRoute
            }
        }
    }
}


fun RenderContext.dashboardElement(element: DashboardElement, elementData: AnalyticsData) {
    stackUp(
        {
            margin { small }
            border {
                width(formationStyles.borderWidth)
                color { gray300 }
                style { dashed }
            }
            padding { small }
            radius { huge }
            maxWidth { full }
            maxHeight { full }
            overflow { auto }
        },
        id = "dashboardElement",
    ) {
        spacing { small }
        items {
            lineUp(
                {
                    alignItems { center }
                },
            ) {
                spacing { small }
                items {
                    dashboardElementTitle(element, elementData)
                    dashboardElementExpandButton(element, elementData)
                }
            }
            when (elementData) {
                is AnalyticsData.JsonPrimitiveTable -> {
                    dashboardElementTable(element, elementData)
                }

                else -> {}
            }
        }
    }
}

fun RenderContext.dashboardElementTitle(element: DashboardElement, data: AnalyticsData) {
    val translation by koinCtx.inject<Translation>()
    val selectedAnalyticsDashboardStore by koinCtx.inject<SelectedAnalyticsDashboardStore>()
    val refreshTracker = tracker()

    lineUp(
        {
            alignItems { center }
        },
        id = "dashboardElementTitle",
    ) {
        spacing { small }
        items {
            span(
                {
                    fontSize { normal }
                    fontWeight { bold }
                },
            ) {
                when (data) {
                    is AnalyticsData.JsonPrimitiveTable -> +data.title
                    else -> +"unknown data type"
                }
            }
            // refresh button
            dashboardIconButton(
                icon = { refresh },
                tooltip = translation[AnalyticsDashboardTexts.REFRESH],
                value = Pair(element, refreshTracker),
                tracker = refreshTracker,
                clickHandlers = listOf(selectedAnalyticsDashboardStore.computeSingleElementData),
            )
            // print button
            dashboardIconButton(
                icon = { FormationIcons.Printer.icon },
                tooltip = translation[AnalyticsDashboardTexts.PRINT],
                value = element,
                clickHandlers = listOf(selectedAnalyticsDashboardStore.printElement),
            )
        }
    }
}

fun RenderContext.dashboardElementTable(element: DashboardElement, data: AnalyticsData.JsonPrimitiveTable) {
    val translation by koinCtx.inject<Translation>()
    val analyticsTextFilterStore: AnalyticsTextFilterStore by koinCtx.inject()
    val dashboardTableElementsSortingStore by koinCtx.inject<DashboardTableElementsSortingStore>()
    val tableSortingStateStore = storeOf(dashboardTableElementsSortingStore.current[element])
    val tableDataStore = storeOf(
        dashboardTableElementsSortingStore.current[element]?.let { columnSortingState ->
            sortDashboardTableRows(element.analyticsType, data, columnSortingState)
        } ?: data,
    )

    dashboardTableElementsSortingStore.data.mapNotNull { it[element] } handledBy tableSortingStateStore.update

    div(
        {
            border {
                width(formationStyles.borderWidth)
                color { primary.main }
            }
            radius { huge }
            maxWidth { full }
            overflow { auto }
        },
        id = "dashboardElementTable",
    ) {
        combine(
            tableDataStore.data,
            tableSortingStateStore.data,
            analyticsTextFilterStore.data,
        ) { data, sorting, textQuery ->
            val filteredData = filterDashboardTableData(textQuery, data)
            Pair(
                (sorting?.let { sortDashboardTableRows(element.analyticsType, filteredData, sorting) }
                    ?: filteredData),
                sorting,
            )
        }.render { (tableData, currentSortingState) ->
            table(
                {
                    width { full }
                    overflow { hidden }
                },
                id = element.id,
            ) {
                // TABLE HEADER
                thead(
                    {
                        color { secondary.main }
                        position { sticky { top { none } } }
                    },
                ) {
                    tableData.columNames.forEach { columnName ->
                        val columnIndex = data.columNames.indexOf(columnName)
                        val currentSorting =
                            if (currentSortingState?.columnName == columnName) currentSortingState.sorting else Sorting.None
                        th(
                            {
                                margins { horizontal { tiny } }
                                padding { tiny }
                                css("cursor:pointer;")
                                // use box-shadow instead of borders, as sticky header makes normal borders invisible
                                when (columnIndex) {
                                    0 -> css("box-shadow: inset -0.5px 0px #555;")
                                    in 1 until tableData.columNames.size -> css("box-shadow: inset 0.5px 0px #555, inset -0.5px 0px #555;")
                                    tableData.columNames.size -> css("box-shadow: inset 0.5px 0px #555;")
                                }
                                // Coloured active columns?
                                when (currentSorting) {
                                    Sorting.Ascending, Sorting.Descending -> {
                                        background {
                                            color { FormationColors.MarkerYou.color }
                                        }
                                        hover {
                                            background {
                                                color { makeRGBA(FormationColors.MarkerYou.color, 0.8) }
                                            }
                                        }
                                    }

                                    else -> {
                                        background {
                                            color { primary.main }
                                        }
                                        hover {
                                            background {
                                                color { primary.highlight }
                                            }
                                        }
                                    }
                                }
//                            background {
//                                color { primary.main }
//                            }
//                            hover {
//                                background {
//                                    color { primary.highlight }
//                                }
//                            }
                            },
                        ) {
                            lineUp(
                                {
                                    alignItems { center }
                                    justifyContent { start }
                                },
                            ) {
                                spacing { tiny }
                                items {
                                    icon(
                                        {
//                                        size { small }
                                            when (currentSorting) {
                                                Sorting.Ascending, Sorting.Descending -> color { FormationColors.White.color }
                                                else -> color { FormationColors.GrayDisabled.color }
                                            }
                                        },
                                    ) { fromTheme { currentSorting.icon } }
                                    span {
                                        val directName = element.columnNameMapping?.get(columnName)
                                        when {
                                            directName != null -> {
                                                +directName
                                            }

                                            columnName == "analytics-field-value" -> {
                                                translation.get(
                                                    columnName,
                                                    mapOf(
                                                        "fieldValueName" to (element.elementParams[DashboardParamNames.FieldName]
                                                            ?: columnName),
                                                    ),
                                                ).renderText()
                                            }

                                            else -> {
                                                translation.get(columnName).renderText()
                                            }
                                        }
                                    }
                                }
                            }
                            clicks.map {
                                val tableSorting =
                                    DashboardTableSortedColumnState(columnName, columnIndex, currentSorting.next())
                                tableSortingStateStore.update(tableSorting)
                                dashboardTableElementsSortingStore.addOrUpdate(Pair(element, tableSorting))
                                sortDashboardTableRows(element.analyticsType, data, tableSorting)
                            } handledBy tableDataStore.update
                        }
                    }
                }
                // NO DATA
                if (tableData.rows.isEmpty()) {
                    tr {
                        td(
                            {
                                padding { tiny }
                                borders {
                                    top { width(formationStyles.borderWidth) }
                                }
                            },
                        ) {
                            translation[AnalyticsDashboardTexts.NO_DATA].renderText(into = this)
                        }
                        for (i in 2..data.columns.size) {
                            td(
                                {
                                    padding { tiny }
                                    borders {
                                        top { width(formationStyles.borderWidth) }
                                        left { width(formationStyles.borderWidth) }
                                    }
                                },
                            ) { }
                        }
                    }
                }
                // TABLE DATA ROWS
                tableData.rows.map { tableData.rows.indexOf(it) to it }.forEach { (index, row) ->
                    tr(
                        {
                            if (index % 2 != 0) {
                                background {
                                    color { gray100 }
                                }
                            }
                        },
                    ) {
                        row.map { row.indexOf(it) to it }.forEach { (columnIndex, column) ->
                            td(
                                {
                                    margins { horizontal { tiny } }
                                    padding { tiny }
                                    borders {
                                        top { width(formationStyles.borderWidth) }
                                        if (columnIndex != 0) left { width(formationStyles.borderWidth) }
                                    }
                                },
                            ) {
                                try {
                                    if (tableData.columns[columnIndex].columnName == "analytics-time-span-ms" || tableData.columns[columnIndex].columnName == "analytics-time-span-per-parent-per-field") {
                                        +(column.longOrNull?.let { msTowdhms(it) }
                                            ?: getFieldValueTitle(column.content))
                                    } else {
                                        +getFieldValueTitle(column.content)
                                    }
                                } catch (e: IndexOutOfBoundsException) {
                                    console.warn("Could not get column name", e)
                                    +getFieldValueTitle(column.content)
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

fun RenderContext.dashboardElementExpandButton(element: DashboardElement, data: AnalyticsData) {
    val translation by koinCtx.inject<Translation>()
    // expand button

    clickButton(
        {
            height { auto }
            width { auto }
            padding { small }
            radius(formationStyles.buttonRadius)
            color { primary.main }
            background {
                color { secondary.main }
            }
            hover {
                color { secondary.main }
                background {
                    color { primary.main }
                }
            }
        },
    ) {
        icon { expand }
        element {
            title(translation[AnalyticsDashboardTexts.FULL_SCREEN])
        }
    } handledBy modal(
        {
            radius { huge }
        },
        id = "dashboardElementExpanded",
    ) {
        content { close ->
            div(
                {
                    position(
                        sm = {
                            fixed {
                                horizontal { none }
                                vertical { none }
                            }
                        },
                        md = {
                            fixed {
                                horizontal { "10px" }
                                vertical { "10px" }
                            }
                        },
                    )
                    background { color { secondary.main } }
                    radius(
                        sm = { none },
                        md = { huge },
                    )
                    padding(
                        sm = { none },
                        md = { normal },
                    )
                    overflow { auto }
                },
            ) {
                stackUp(
                    {
                        justifyContent { spaceBetween }
                        alignItems { stretch }
                        width { full }
                        height { full }
                        padding { normal }
                    },
                ) {
                    spacing { small }
                    items {
                        flexBox(
                            {
                                width { full }
                                alignItems { center }
                                justifyContent { spaceBetween }
                            },
                        ) {
                            dashboardElementTitle(element, data)
//                            dashboardPrintButton(element, true)
                        }
                        when (data) {
                            is AnalyticsData.JsonPrimitiveTable -> {
                                flexBox(
                                    {
                                        alignItems { stretch }
                                        overflow { auto }
                                    },
                                ) {
                                    dashboardElementTable(element, data)
                                }
                            }

                            else -> {}
                        }
                        flexBox({ flex { grow { "1" } } }) { }
                        oneButtonFooter(
                            title = translation[TL.General.CLOSE],
                            value = Unit,
                            clickHandlers = listOf(close),
                            width = { "320px" },
                        )
                    }
                }
            }
        }
    }
}

fun RenderContext.dashboardPrintButton(element: DashboardElement, withText: Boolean = false) {
    val selectedAnalyticsDashboardStore by koinCtx.inject<SelectedAnalyticsDashboardStore>()
    val translation by koinCtx.inject<Translation>()
    clickButton(
        {
            primaryButtonStyleParams()
            margins {
                top { none }
                left { small }
            }
        },
    ) {
        element {
            attr("onClick", "event.preventDefault(); blur();")
        }
        icon { FormationIcons.Printer.icon }
        if (withText) text(translation[AnalyticsDashboardTexts.PRINT])
    }.map { element } handledBy selectedAnalyticsDashboardStore.printElement
}

fun <T> RenderContext.dashboardIconButton(
    title: Flow<String>? = null,
    tooltip: Flow<String>? = null,
    icon: (Icons.() -> IconDefinition)? = null,
    iconPosition: Position = Position.Left,
    value: T,
    tracker: Tracker? = null,
    trackerLoadingText: Flow<String>? = null,
    clickHandlers: List<Handler<T>>? = null,
    disabled: Boolean = false,
) {
    pushButton(
        {
            height { auto }
            width { auto }
            padding { small }
            radius(formationStyles.buttonRadius)
            if (disabled) {
                color { FormationColors.GrayDisabled.color }
                background { color { FormationColors.GrayLight.color } }
            } else {
                color { primary.main }
                background {
                    color { secondary.main }
                }
                hover {
                    color { secondary.main }
                    background {
                        color { primary.main }
                    }
                }
            }
            fontSize { small }
        },
    ) {
        element {
            attr("onClick", "event.preventDefault(); blur();")
            tooltip?.let { title(it) }
        }
        icon(icon)
        iconPlacement { if (iconPosition == Position.Left) left else right }
        title?.let { text(it) }
        tracker?.let { loading(tracker.data) }
        trackerLoadingText?.let { loadingText(it) }
        events {
            clickHandlers?.forEach { handler ->
                clicks.map { value } handledBy handler
            }
        }
    }
}
