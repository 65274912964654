package map.views.workplacetools

import auth.ApiUserStore
import auth.CurrentWorkspaceStore
import apiclient.FormationClient
import apiclient.geoobjects.CreateObjectFromArchetype
import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.SearchQueryContext
import apiclient.geoobjects.UpdatePointLocation
import apiclient.geoobjects.createObjectFromArchetype
import apiclient.geoobjects.restSearch
import apiclient.reversegeocoder.reverseGeocode
import data.ObjectAndUserHandler
import data.objects.ActiveObjectStore
import data.objects.building.CurrentBuildingsStore
import dev.fritz2.core.RootStore
import dev.fritz2.core.invoke
import koin.koinCtx
import kotlinx.coroutines.Job
import layercache.GeoObjectDetailsCache
import mainmenu.RouterStore
import map.MapLayersStore
import maplibreGL.MaplibreMap
import maplibreGL.toLatLon
import model.LayerType
import model.getFirstGroupIdOrNull
import overlays.BusyStore
import search.searchlayer.MapSearchClientsStore

class ArchetypesStore : RootStore<List<GeoObjectDetails>>(
    initialData = emptyList(),
    job = Job(),
) {

    val formationClient by koinCtx.inject<FormationClient>()
    val busyStore by koinCtx.inject<BusyStore>()
    val apiUserStore by koinCtx.inject<ApiUserStore>()
    val maplibreMap by koinCtx.inject<MaplibreMap>()
    val currentWorkspaceStore by koinCtx.inject<CurrentWorkspaceStore>()
    val activeObjectStore by koinCtx.inject<ActiveObjectStore>()
    val mapSearchClientsStore: MapSearchClientsStore by koinCtx.inject()
    val mapLayersStore: MapLayersStore by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()
    val objectAndUserHandler: ObjectAndUserHandler by koinCtx.inject()
    val currentBuildingsStore: CurrentBuildingsStore by koinCtx.inject()
    private val geoObjectCache: GeoObjectDetailsCache by koinCtx.inject()
    private val archetypeSearchContextStore: ArchetypeSearchContextStore by koinCtx.inject()

    val fetchArchetypes = handle<SearchQueryContext?> { current, ctx ->
        ctx?.let { searchCtx ->
            // fetch archetype objects
            busyStore.handleApiCall(
                supplier = suspend {
                    formationClient.restSearch(searchCtx)
                },
                processResult = { results ->
                    console.log("Fetched Archetypes:", results.hits.map { it.hit.archetypeDefinition?.name })
                    update(results.hits.map { it.hit })
                }
            )
        }
        current
    }

    val createFromArchetype = handle<String> { current, archetypeId ->
        val groupId = apiUserStore.current.getFirstGroupIdOrNull()
        val currentPosition = maplibreMap.getCenter().toLatLon()
        groupId?.let { group ->
            busyStore.handleApiCall(
                supplier = {
                    formationClient.reverseGeocode(group, currentPosition, currentBuildingsStore.getConnectedToId())
                },
                processResult = { response ->
                    val connectedToId = response.best?.id
                    busyStore.handleApiCall(
                        supplier = suspend {
                            formationClient.createObjectFromArchetype(
                                group,
                                CreateObjectFromArchetype(
                                    archetypeId = archetypeId,
                                    initialChanges = listOf(
                                        UpdatePointLocation(
                                            zoneId = connectedToId,
                                            position = currentPosition
                                        )
                                    )
                                )
                            )
                        },
                        processResult = { result ->
                            routerStore.back()
                            geoObjectCache.update(result.id, result)
                            mapSearchClientsStore.insertId(result.id, result.latLon)
                            mapLayersStore.insertHit(LayerType.MapSearchResults, result)
                            objectAndUserHandler.updateAndLocateActiveObject(result)
                        },
                        processError = { error ->
                            console.warn("Could not create object from archetype.", error.message)
                        }
                    )
                },
                processError = { error ->
                    console.warn("Failed to get reversed geocode. Object could not be created.", error.message)
                }
            )
        }
        current
    }

    init {
        archetypeSearchContextStore.data handledBy fetchArchetypes
    }
}
