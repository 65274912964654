package layercache

import apiclient.FormationClient
import apiclient.geoobjects.SearchQueryContext
import apiclient.geoobjects.msearchForIds
import apiclient.search.IdResults
import apiclient.util.withDuration
import mu.KotlinLogging
import kotlin.time.measureTimedValue

private val logger = KotlinLogging.logger {}

suspend fun <T : Any> FormationClient.pagedSearchIdOnly(
    searchQueryContexts: Map<T, SearchQueryContext>,
    defaultPageSize: Int = 100,
): Map<T, List<IdResults>> {
    val results: Map<T, MutableList<IdResults>> = searchQueryContexts.keys.associateWith {
        mutableListOf<IdResults>()
    }.toMap()

    val mutableSearchQueryContexts: MutableMap<T, SearchQueryContext> = searchQueryContexts.toMutableMap()

    var page = 0

    while (mutableSearchQueryContexts.isNotEmpty()) {
        val pageKeys = mutableSearchQueryContexts.keys.toList()
        val mSearchQueryContexts = pageKeys.mapNotNull { key ->
            mutableSearchQueryContexts[key]
        }.map { context ->
            // FIXME when we switch to kt-search, we should use the search_after support for deep paging
            // actually keeping track of page locally
            context.copy(
                from = page * (context.size ?: defaultPageSize),
                size = context.size ?: defaultPageSize
            )
        }
        val pageResult = measureTimedValue {
            if (mSearchQueryContexts.isNotEmpty()) {
                msearchForIds(mSearchQueryContexts).getOrThrow()
            } else emptyList()
        }.withDuration {
            logger.debug { "mSearch took $duration" }
        }
        pageResult.forEachIndexed { index, objectSearchResults ->
            val pageKey = pageKeys[index]
            val resultsLists = results[pageKey] ?: run {
                logger.error { "cannot look up results accumulator for $pageKey" }
                return@forEachIndexed
            }
            resultsLists += objectSearchResults
            val searchQueryContext = mutableSearchQueryContexts[pageKey] ?: run {
                logger.error { "already removed $pageKey from search contexts" }
                return@forEachIndexed
            }

            if (objectSearchResults.hits.size < (searchQueryContext.size ?: defaultPageSize)) {
                mutableSearchQueryContexts -= pageKey
            } else if (objectSearchResults.pageSize == 0) {
                mutableSearchQueryContexts -= pageKey
            }
        }
        page++
    }

    return results
}
