package data.objects.views.cardinfo

import analytics.AnalyticsCategory
import apiclient.customfields.parseFieldValues
import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.ObjectTags
import apiclient.geoobjects.ObjectType
import apiclient.tags.getTagValues
import apiclient.tags.getUniqueTag
import auth.CurrentWorkspaceStore
import auth.permissions.PermissionsService
import data.objects.ActiveObjectStore
import data.objects.views.attachments.toPreAttachment
import data.objects.views.directediting.directEditingCardContent
import dev.fritz2.components.compat.div
import dev.fritz2.components.flexBox
import dev.fritz2.components.icon
import dev.fritz2.components.lineUp
import dev.fritz2.components.stackUp
import dev.fritz2.core.RenderContext
import koin.koinCtx
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import localization.TL
import localization.Translation
import location.GeoPositionStore
import location.geolocation.latLon
import mainmenu.Pages
import mainmenu.RouterStore
import model.L
import qrcode.ScannedCodeStore
import styling.primaryButtonStyleParams
import styling.secondaryButtonStyleParams
import theme.FormationColors
import theme.FormationIcons
import utils.extractReadOnlyTags
import utils.getIcon
import utils.getName
import utils.merge
import utils.respectFeatureFlags
import utils.toKeyWordTagsList
import webcomponents.KeywordTagActionType
import webcomponents.baseLayout
import webcomponents.cardSubtitle
import webcomponents.cardTitleWithSubtitle
import webcomponents.cardTitleWithSubtitleDirectEdit
import webcomponents.ellipseText
import webcomponents.oneButtonFooter
import webcomponents.selectorButton
import webcomponents.selectorContent

fun RenderContext.trackedObjectInfoCardContent() {

    val objectType = ObjectType.ObjectMarker
    val translation: Translation by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()
    val activeObjectStore: ActiveObjectStore by koinCtx.inject()
    val scannedCodeStore: ScannedCodeStore by koinCtx.inject()
    val permissionsService: PermissionsService by koinCtx.inject()
    val geoPositionStore: GeoPositionStore by koinCtx.inject()
    val title = activeObjectStore.map(GeoObjectDetails.L.title)
    val objType = activeObjectStore.map(GeoObjectDetails.L.objectType)
    val description = activeObjectStore.map(GeoObjectDetails.L.description)
    val attachments =
        activeObjectStore.map(GeoObjectDetails.L.attachments).data.map { attachments ->
            attachments.respectFeatureFlags()?.mapNotNull { it.toPreAttachment() }
        }
    val keywords = activeObjectStore.map(GeoObjectDetails.L.keywords)
    val externalId = activeObjectStore.map(GeoObjectDetails.L.tags).data.map { it.getUniqueTag(ObjectTags.ExternalId) }
    val ephemeral =
        activeObjectStore.map(GeoObjectDetails.L.tags).data.map { it.getUniqueTag(ObjectTags.Ephemeral).toBoolean() }
    val rawCustomData =
        activeObjectStore.map(GeoObjectDetails.L.tags).data.map { it.getTagValues(ObjectTags.ExtraData) }
    val customDataFieldsMapFlow =
        getCustomFieldMap(activeObjectStore.map(GeoObjectDetails.L.tags).data.map { it.getTagValues(ObjectTags.CustomField) })
    val readOnlyKeywordTags = activeObjectStore.map(GeoObjectDetails.L.tags).data.extractReadOnlyTags()
    val currentWorkspaceStore: CurrentWorkspaceStore by koinCtx.inject()
    val fieldValueTags = activeObjectStore.map(GeoObjectDetails.L.tags).data.respectFeatureFlags().map { tags ->
        currentWorkspaceStore.current?.fieldDefinitions?.let { definitions ->
            tags.parseFieldValues(definitions)
        }
    }.map { it?.toKeyWordTagsList(KeywordTagActionType.DefaultFieldValue) ?: emptyList() }
    val canModify = activeObjectStore.map(GeoObjectDetails.L.canModify)
    val canManage = activeObjectStore.map(GeoObjectDetails.L.canManage)

    baseLayout(
        header = {
            flexBox(
                {
                    direction { row }
                    justifyContent { spaceBetween }
                    alignItems { start }
                    width { full }
                    height { maxContent }
                },
            ) {
                // CARD TITLE
                canManage.data.combine(canModify.data) { manage, modify -> manage || modify }
                    .render { editAccess ->
                        if (editAccess) {
                            cardTitleWithSubtitleDirectEdit(
                                titleFlow = title.data,
                                title = title.current,
                                subtitle = externalId.map { extId ->
                                    if (extId != null) {
                                        "${objectType.getName()} - $extId"
                                    } else objectType.getName()
                                },
                                titleIconFlow = flowOf(objectType.getIcon().icon),
                                subtitleIconFlow = externalId.map { if (!it.isNullOrBlank()) FormationIcons.QRCode.icon else null },
                                changeHandler = activeObjectStore.editTitle,
                            )
                        } else {
                            cardTitleWithSubtitle(
                                title = title.data,
                                subtitle = externalId.map { extId ->
                                    if (extId != null) {
                                        "${objectType.getName()} - $extId"
                                    } else objectType.getName()
                                },
                                titleIconFlow = flowOf(objectType.getIcon().icon),
                                subtitleIconFlow = externalId.map { if (!it.isNullOrBlank()) FormationIcons.QRCode.icon else null },
                            )
                        }
                    }
                infoCardButtons()
            }
        },
        content = {
            directEditingCardContent {
                showCustomData(customDataFieldsMapFlow)
                // OPTIONAL EXTRA DATA BUTTON
                rawCustomData.render { data ->
                    if (data.isNotEmpty()) {
                        if (data.size < 4) {
                            showExtraData(data)
                        } else {
                            selectorButton {
                                selectorContent {
                                    icon({ margins { horizontal { smaller } }; size { normal } }) { fromTheme { FormationIcons.API.icon } }
                                    ellipseText { translation[TL.CardCustomData.BUTTON_TITLE].renderText(into = this) }
                                }
                                clicks.map {
                                    mapOf(
                                        "show" to "objectData",
                                    )
                                } handledBy routerStore.addOrReplaceRoute
                            }
                        }
                    }
                }
                // DESCRIPTION OR DESCRIPTION BUTTON
                descriptionButton(description.data)
                // OPTIONAL BUTTON TO CREATE A NEW OBJECT FROM A MARKER THAT HAS NO OBJECT YET
                ephemeral.render { isEphemeral ->
                    if (isEphemeral) {
                        oneButtonFooter(
                            title = translation[TL.General.CREATE].merge(translation[TL.ObjectTypeTitle.OBJECT_MARKER]),
                            icon = { FormationIcons.TrackedObject.icon },
                            value = Unit,
                            clickHandlers = listOf(
                                activeObjectStore.focusThisObject,
                                activeObjectStore.hideActiveObjectMarker,
                            ),
                            addOrReplaceRoute = mapOf("add" to ObjectType.ObjectMarker.name),
                            analyticsEventProvider = AnalyticsCategory.CreateObjectFromTrackingEvent.click(),
                        )
                    }
                }
                if (!scannedCodeStore.current.extOrIntObjIdOrActionId.isNullOrEmpty()) {
                    geoPositionStore.data.render { geoPosition ->
                        if (geoPosition != null) {
                            // UPDATE LOCATION BUTTON (only when user sees this card after scanning a code <-> scannedCodeStore is not null or blank)
                            oneButtonFooter(
                                title = translation[TL.CodeScanning.UPDATE_LOCATION],
                                icon = { FormationIcons.Location.icon },
                                value = Unit,
                                clickHandlers = listOf(scannedCodeStore.updateLocation),
                                analyticsEventProvider = AnalyticsCategory.QuickUpdateTrackedObjectLocation.click(
                                    location = geoPosition.latLon(),
                                    target = scannedCodeStore.current.extOrIntObjIdOrActionId
                                ),
                            )
                        } else {
                            cardSubtitle(translation[TL.CodeScanning.LOCATION_PERMISSION_QUESTION])
                            oneButtonFooter(
                                title = translation[TL.CodeScanning.ALLOW_LOCATION_PERMISSION],
                                styleParams = secondaryButtonStyleParams,
                                icon = { FormationIcons.LocationAlt.icon },
                                value = Unit,
                                clickHandlers = listOf(permissionsService.requestLocationPermission),
                                analyticsEventProvider = null,
                            )
                        }
                        cardSubtitle(translation[TL.CodeScanning.LOCATION_PERMISSION_ALTERNATIVE])
                        oneButtonFooter(
                            title = translation[TL.CodeScanning.UPDATE_LOCATION_MANUALLY],
                            styleParams = secondaryButtonStyleParams,
                            icon = { FormationIcons.LocationAlt.icon },
                            addOrReplaceRoute = mapOf(
                                "page" to Pages.Map.name,
                                "editPosition" to ObjectType.ObjectMarker.name,
                            ),
                            value = Unit,
                            clickHandlers = listOf(
                                activeObjectStore.focusThisObject,
                                activeObjectStore.removeMapClickListener,
                                activeObjectStore.hideActiveObjectMarker,

                                ),
                        )
                    }
                }
            }
        },
        footer = {
            ephemeral.render { isEphemeral ->
                if (isEphemeral) {
                    oneButtonFooter(
                        title = translation[TL.General.BACK],
                        styleParams = primaryButtonStyleParams,
                        routingMap = mapOf("page" to Pages.Map.name),
                        value = Unit,
                        clickHandlers = listOf(activeObjectStore.resetActiveObjectAndUserToMap),
                    )
                } else {
                    // CARD BUTTONS FOOTER WITH: [BACK] [EDIT]
                    infoCardEditFooter(objectType)
                }
            }
        },
    )
}

fun RenderContext.showCustomData(data: Flow<Map<String, CustomEntry>>) {

    val translation: Translation by koinCtx.inject()

    data.render { customDataMap ->
        val filtered = customDataMap.filter { it.key != "Type" }
        if (filtered.isNotEmpty()) {
            cardSubtitle(translation[TL.CardCustomData.CARD_TITLE], flowOf(FormationIcons.API))
            div(
                {
                    background {
                        color { FormationColors.GrayLight.color }
                    }
                    radius { normal }
                    maxHeight { full }
                    minHeight { maxContent }
                    paddings { vertical { tiny } }
                    margins { vertical { tiny } }
                },
            ) {
                stackUp {
                    spacing { tiny }
                    items {
                        filtered.forEach { customDataEntry ->
                            //Short:0:Batch Number:112934565
                            //Short:1:Material Number:0192386787
                            //Status:2:Status:Free (0)
                            lineUp(
                                {
                                    alignItems { center }
                                    paddings { horizontal { small } }
                                },
                            ) {
                                spacing { tiny }
                                items {
                                    span { +"${customDataEntry.value.fieldName}: ${customDataEntry.value.fieldValue}" }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

fun RenderContext.showExtraData(data: List<String>) {
    div(
        {
            background {
                color { FormationColors.GrayLight.color }
            }
            radius { normal }
            maxHeight { full }
            minHeight { "112px" }
            overflow { auto }
        },
    ) {
        stackUp(
            {
                padding { normal }
                height { maxContent }
            },
        ) {
            spacing { tiny }
            items {
                data.forEach { extraData ->
                    val key = extraData.split(":").firstOrNull()
                    val value = extraData.split(":").last()
                    lineUp(
                        {
                            alignItems { center }
                            paddings { horizontal { small } }
                        },
                    ) {
                        spacing { normal }
                        items {
                            span { +"\"$key\": \"$value\"" }
                            // Display a colored dot, whenever the value is a small number
                            if ("[0-9]+".toRegex().matches(value)) {
                                try {
                                    colorDot(numberColor(value.toInt()))
                                } catch (e: NumberFormatException) {
                                    console.log(e)
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

fun numberColor(number: Int): FormationColors {
    return when (number) {
        0 -> FormationColors.GrayDisabled
        1 -> FormationColors.YellowDoing
        2 -> FormationColors.GreenActive
        else -> FormationColors.RedError
    }
}
