package analyticsdashboard

import com.tryformation.localization.Translatable

enum class AnalyticsDashboardTexts: Translatable {
    ANALYTICS_DASHBOARD_TITLE,
    NO_DATA,
    REFRESH_ALL_DATA,
    REFRESH,
    PRINT,
    FULL_SCREEN
    ;

    override val prefix: String
        get() = "analyticsdashboardtexts"
}