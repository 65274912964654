package workspacetools

import com.tryformation.localization.Translatable
import mainmenu.Pages
import routing.DestinationRoute
import theme.FormationIcons
import theme.IconEnum

enum class WorkspaceToolsMenu(val icon: IconEnum) : DestinationRoute, Translatable {
    WorkspaceOptions(FormationIcons.Settings),
    UserManagement(FormationIcons.User),
    Buildings(FormationIcons.Building)
    ;

    override val prefix = "workspace-tools-menu"

    override val route = mapOf(
        "page" to Pages.AdminDashboard.name,
        "subPage" to name,
    )


    override val routeKey: String = "subPage"

    companion object {
        val routeKey = "subPage"
    }
}
