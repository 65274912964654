package utils

import io.ktor.client.fetch.*
import kotlinx.coroutines.*
import org.khronos.webgl.ArrayBuffer
import org.khronos.webgl.DataView
import org.w3c.files.FileReader
import kotlin.time.Duration.Companion.seconds

val apiScope = CoroutineScope(CoroutineName("api-call"))

suspend fun loadImageData(url: String): ByteArray {
    fetch(url).await().let { response ->
        val blob = response.blob().await()
        if(blob.type.startsWith("image")) {
            // define urlReader (1st to trigger) to read blob as dataUrl
            val urlReader = FileReader()
            // define byteReader (2nd to trigger) to read blob as arrayBuffer
            val byteReader = FileReader()

            var imageDataUrl: String? = null
            var bytes: ByteArray? = null
            // set up callback that fires when urlReader is triggered
            urlReader.onload = {

                // read blob as dataUrl (String) and store in var
                imageDataUrl = urlReader.result as String

                // 2nd) After reading the blob as dataUrl, trigger the byteReader inside here,
                // to make sure they are triggered in order
                byteReader.readAsArrayBuffer(blob)
            }

            // set up callback that fires when byteReader is triggered
            byteReader.onload = {

                // read blob as ArrayBuffer and get size
                val content = byteReader.result as ArrayBuffer
                val view = DataView(content)
                val size = view.byteLength

                // convert ArrayBuffer to ByteArray (Coroutine)
                CoroutineScope(CoroutineName("convert-array-to-bytes")).launch {
                    bytes = 0.until(size).map { view.getInt8(it) }.toByteArray()
                }
            }

            // 1st) trigger urlReader
            urlReader.readAsDataURL(blob)
            withTimeout(10.seconds) {
                while(bytes == null) {
                    delay(100)
                }
            }
            return bytes ?: error("image should have loaded by now")
        } else {
            error("not an image")
        }
    }
}
