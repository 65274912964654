package search.hub

import apiclient.FormationClient
import apiclient.geoobjects.HubSearchResults
import apiclient.geoobjects.ObjectType
import apiclient.geoobjects.getHubSeparate
import apiclient.geoobjects.getHubUnified
import apiclient.search.ObjectSearchResults
import dev.fritz2.core.RootStore
import dev.fritz2.core.invoke
import dev.fritz2.routing.MapRouter
import koin.koinCtx
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.mapNotNull
import location.GeoPositionStore
import location.geolocation.GeoPosition
import mainmenu.AppStateStore
import mainmenu.Pages
import model.AppPhase
import model.SearchContexts
import model.hubSearch
import network.NetworkState
import network.NetworkStateStore
import overlays.BusyStore
import search.DistanceTo
import search.SearchContextsStore
import search.SearchResultsCoordinator
import search.SearchType

class HubDistanceToCentroidStore : RootStore<DistanceTo?>(
    initialData = null,
    job = Job(),
) {
    private val geoPositionStore: GeoPositionStore by koinCtx.inject()

    private val updateToUserOnce = handle<GeoPosition?> { current, geoPosition ->
        if (geoPosition != null) {
            current ?: DistanceTo.User
        } else current
    }

    val switch = handle { current ->
        if (current == DistanceTo.User) DistanceTo.Map else DistanceTo.User
    }

    val select = handle<DistanceTo> { _, new ->
        new
    }

    init {
        geoPositionStore.data handledBy updateToUserOnce
    }
}

val filterableObjectTypes = listOf(
    ObjectType.POI,
    ObjectType.Event,
    ObjectType.Task,
    ObjectType.Building,
    ObjectType.Zone,
//    ObjectType.Area,
//    ObjectType.Unit,
//    ObjectType.GeoFence
)

val initialSetting = filterableObjectTypes.associateWith { objType ->
    when (objType) {
        ObjectType.Task, ObjectType.Event -> true
        else -> false
    }
}

class HubObjectTypeFilterStore : RootStore<Map<ObjectType, Boolean>>(
    initialData = initialSetting,
    job = Job(),
) {

    val allOn = handle {
        filterableObjectTypes.associateWith { true }
    }

    val flip = handle<ObjectType> { current, new ->
        val modified = current.toMutableMap()
        modified[new] = !(current[new] ?: false)
        modified
    }

    val flipAll = handle { current ->
        when {
            current.any { it.value } || current.none { it.value } -> {
                filterableObjectTypes.associateWith { true }
            }

            else -> filterableObjectTypes.associateWith { false }
        }

    }
}

class HubSeparateResultsStore : RootStore<HubSearchResults?>(
    initialData = null,
    job = Job(),
) {
    val formationClient by koinCtx.inject<FormationClient>()
    private val searchContextsStore by koinCtx.inject<SearchContextsStore>()
    private val busyStore by koinCtx.inject<BusyStore>()

    private val appStateStore by koinCtx.inject<AppStateStore>()
    private val networkStateStore by koinCtx.inject<NetworkStateStore>()

    val search = handle { current ->
        val online = networkStateStore.current == NetworkState.Online
        val loggedIn =
            appStateStore.current.appPhase.let { it == AppPhase.LoggedIn || it == AppPhase.LoggedInAnonymous }

        if (online && loggedIn) {
            busyStore.handleApiCall(
                supplier = suspend {
                    formationClient.getHubSeparate(searchContextsStore.current.hubSearch)
                },
                processResult = { hubSearchResults ->
                    update(hubSearchResults)
                },
                processError = { error ->
                    console.log("HubSearch for separate results failed", error.message)
                    update(null)
                },
            )
            current
        } else null
    }

    val reset = handle { null }
}

class HubUnifiedResultsStore : RootStore<ObjectSearchResults?>(
    initialData = null,
    job = Job(),
) {
    val formationClient by koinCtx.inject<FormationClient>()
    private val router by koinCtx.inject<MapRouter>()
    private val searchContextsStore by koinCtx.inject<SearchContextsStore>()
    private val searchResultsCoordinator: SearchResultsCoordinator by koinCtx.inject()
    private val busyStore by koinCtx.inject<BusyStore>()
    private val appStateStore by koinCtx.inject<AppStateStore>()
    private val networkStateStore by koinCtx.inject<NetworkStateStore>()

    val hubSearch = handle { current ->
        val online = networkStateStore.current == NetworkState.Online
        val loggedIn =
            appStateStore.current.appPhase.let { it == AppPhase.LoggedIn }

        if (online && loggedIn) {
            busyStore.handleApiCall(
                supplier = suspend {
                    formationClient.getHubUnified(searchContextsStore.current.hubSearch)
                },
                processResult = { hubSearchResults ->
                    searchResultsCoordinator.renderSearchResults(Pair(SearchType.Hub, hubSearchResults.hits.map { it.hit }))
                    update(hubSearchResults)
                },
                processError = { error ->
                    console.log("HubSearch for unified results failed", error.message)
                    update(null)
                },
            )
            current
        } else null
    }

    private val hubPageListener = handle<Map<String, String>> { current, route ->
        if (route["page"] == Pages.Hub.name && route.keys.size <= 2) hubSearch()
        current
    }

    val reset = handle { null }

    init {
        router.data handledBy hubPageListener
        searchContextsStore.map(SearchContexts.hubSearch()).data.combine(appStateStore.data) { _, appState ->
            if (appState.appPhase == AppPhase.LoggedIn) Unit else null
        }.mapNotNull { } handledBy hubSearch
    }
}
