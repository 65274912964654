package qrcodegeneration

import dev.fritz2.core.HtmlTag
import dev.fritz2.headless.foundation.utils.floatingui.obj
import kotlin.js.Promise
import kotlinx.coroutines.await
import kotlinx.coroutines.flow.flowOf
import org.w3c.dom.HTMLDivElement

suspend fun dataUrlImage(url: String): String {
    val p = toDataURL(url) as Promise<String>
    return p.await()
}

suspend fun toSvgQrCode(content: String, colorDark: String = "#000000", colorLight: String = "#FFFFFF", margin: Int = 0, errorCorrection: String = "M"): String {

    return toString(
        content,
        obj {
            this.type = "svg"
            // high error correction
            this.errorCorrectionLevel = errorCorrection
            color = obj {
                this.light = colorLight
                this.dark = colorDark
            }
            this.margin = margin
        },
    ).await()
}

@Deprecated("use toSvgQrCode", ReplaceWith("toSvgQrCode"))
suspend fun urlToSvgQrCode(url: String, ) =
    toSvgQrCode(url)

fun HtmlTag<HTMLDivElement>.showQRCode(content: String, colorDark: String = "#000000", colorLight: String = "#FFFFFF", margin: Int = 0, errorCorrection: String = "M", ) {
    flowOf(content) handledBy { c ->
        val svg = toSvgQrCode(content, colorDark, colorLight, margin, errorCorrection)
        // hack in the tailwind class
        domNode.innerHTML = svg.replace("""<svg""","""<svg class="h-full w-full" """)
    }
}
