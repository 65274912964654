package workspacetools.buildingeditor

import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.distanceTo
import apiclient.geoobjects.pointCoordinates
import com.jillesvangurp.geo.GeoGeometry
import com.tryformation.localization.Translatable
import dev.fritz2.core.RenderContext
import dev.fritz2.core.RootStore
import dev.fritz2.core.render
import dev.fritz2.core.title
import dev.fritz2.core.viewBox
import kotlinx.coroutines.Job
import localization.getTranslationFlow
import localization.translate
import maplibreGL.LngLat
import maplibreGL.Marker
import maplibreGL.toLatLon
import maplibreGL.toLngLat
import theme.FormationIcons
import theme.FormationUIIcons
import theme.IconEnum



fun RenderContext.mapButton(
    icon: IconEnum,
    title: Translatable?=null,
) {
    button("relative flex items-center justify-center w-12 h-12 rounded-full bg-white/50 hover:bg-white/75") {
        title?.let {
            title(it.getTranslationFlow())
        }
        svg("h-8 flex-shrink-0") {
            viewBox(icon.viewBox)
            content(icon.svg)
        }
    }
}

class SelectedFloorStore(val buildingEditorMapManager: BuildingEditorMapManager, val imageRotationAndScaleStore: ImageRotationAndScaleStore):
    RootStore<GeoObjectDetails?>(null, Job()) {
    var floorCenterMarker: Marker? = null
    var floorScaleMarker: Marker? = null
    var floorRotateMarker: Marker? = null

    suspend fun setFloor(floor: GeoObjectDetails?) {
        update(floor)

        floorCenterMarker?.remove()
        floorScaleMarker?.remove()
        floorRotateMarker?.remove()
        val pos = floor?.toImagePosition()
        imageRotationAndScaleStore.update(pos)

        pos?.let { imagePosition ->
            buildingEditorMapManager.flyTo(
                floor.latLon, animate = true, speed = 2.5
            )
            floorCenterMarker = buildingEditorMapManager.addDraggableMarker(floor.latLon.toLngLat()) {
                render(this) {
                    mapButton(FormationUIIcons.Move,BuildingEditorTranslatables.Move)
                }
            }
            floorScaleMarker = buildingEditorMapManager.addDraggableMarker(imagePosition.imageLocation.topRight.toLngLat()) {
                render(this) {
                    mapButton(FormationUIIcons.DoubleArrow, BuildingEditorTranslatables.Scale)
                }
            }

            floorRotateMarker = buildingEditorMapManager.addDraggableMarker(imagePosition.imageLocation.topLeft.toLngLat()) {
                render(this) {
                    mapButton(FormationIcons.Update, BuildingEditorTranslatables.Rotate)
                }
            }

            var oldTopRight = imagePosition.imageLocation.topRight
            var oldTopLeft = imagePosition.imageLocation.topLeft

            floorCenterMarker?.on("drag") { event ->
                val lngLat = event.target.getLngLat() as LngLat
                imageRotationAndScaleStore.current?.let { old ->
                    val newPos = old.copy(
                        imageLocation = old.imageLocation.translate(lngLat.toLatLon())
                    )
                    oldTopRight = newPos.imageLocation.topRight
                    floorScaleMarker?.setLngLat(oldTopRight.toLngLat())
                    oldTopLeft = newPos.imageLocation.topLeft
                    floorRotateMarker?.setLngLat(oldTopLeft.toLngLat())

                    imageRotationAndScaleStore.update(newPos)
                }
            }

            floorScaleMarker?.on("drag") { event ->
                var oldDist = oldTopRight.distanceTo(imagePosition.imageLocation.centroid)
                val lngLat = event.target.getLngLat() as LngLat

                val newDist = lngLat.toLatLon().distanceTo(imagePosition.imageLocation.centroid)
                val ratio = oldDist/newDist

                imageRotationAndScaleStore.current?.let { old ->
                    val newPos = old.copy(
                        imageLocation = old.imageLocation.scale(old.pixelsPerMeter, old.pixelsPerMeter * ratio)
                    )
                    oldTopRight = newPos.imageLocation.topRight
                    floorScaleMarker?.setLngLat(oldTopRight.toLngLat())
                    oldTopLeft = newPos.imageLocation.topLeft
                    floorRotateMarker?.setLngLat(oldTopLeft.toLngLat())
                    imageRotationAndScaleStore.update(newPos)
                }
            }

            floorRotateMarker?.on("drag") { event ->
                val lngLat = event.target.getLngLat() as LngLat
                val center = imagePosition.imageLocation.centroid

                val ogHeading =
                    GeoGeometry.headingFromTwoPoints(center.pointCoordinates(), oldTopLeft.pointCoordinates())
                val newHeading =
                    GeoGeometry.headingFromTwoPoints(center.pointCoordinates(), lngLat.toLatLon().pointCoordinates())

                val angle = ogHeading - newHeading
                imageRotationAndScaleStore.current?.let { old ->
                    val newPos = old.copy(
                        imageLocation = old.imageLocation.rotated(angle)
                    )
                    oldTopRight = newPos.imageLocation.topRight
                    floorScaleMarker?.setLngLat(oldTopRight.toLngLat())
                    oldTopLeft = newPos.imageLocation.topLeft
                    floorRotateMarker?.setLngLat(oldTopLeft.toLngLat())
                    imageRotationAndScaleStore.update(newPos)
                }
            }
        }
    }
}
