package data.objects.views.directediting

import apiclient.FormationClient
import apiclient.geoobjects.Content
import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.LinkRel
import apiclient.geoobjects.UpsertObjectLink
import apiclient.geoobjects.restGetObjectById
import com.tryformation.localization.Translatable
import dev.fritz2.core.RenderContext
import dev.fritz2.core.SimpleHandler
import dev.fritz2.core.disabled
import dev.fritz2.core.placeholder
import dev.fritz2.core.selected
import dev.fritz2.core.storeOf
import dev.fritz2.core.value
import koin.withKoin
import kotlin.random.Random
import kotlin.random.nextULong
import kotlinx.coroutines.CoroutineName
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.filterNotNull
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.launch
import localization.getTranslationFlow
import localization.translate
import overlays.withBusyApplyContentChange
import search.generalListEntry
import theme.FormationUIIcons
import twcomponents.twColOf
import twcomponents.twInputField
import twcomponents.twInputTextField
import twcomponents.twMediumIconButtonNeutral
import twcomponents.twObjectSelector
import twcomponents.twSelect
import webcomponents.KeywordTagType
import webcomponents.inputLabelWrapper

enum class GeoObjectEditor : Translatable {
    OptionalTitleForThisLink,
    TitlePlaceholder,
    LinkRelation,
    ;

    override val prefix = "geo-object-editor"
}

private val geoObjectEditorScope = CoroutineScope(CoroutineName("geo-object-editor"))
fun RenderContext.geoObjectEditor(
    objectId: String,
    content: Content.GeoObject,
    editorCloseHandler: SimpleHandler<Unit>? = null,
    header: Translatable? = null,
    isCreateNew: Boolean = false,
) {
    withKoin {
        val titleStore = storeOf(content.title.orEmpty())
        val relStore = storeOf(content.rel.orEmpty())

        val selectedObjectStore = storeOf<GeoObjectDetails?>(null)

        if (content.objectId.isNotBlank()) {
            geoObjectEditorScope.launch {
                withKoin {
                    val client = get<FormationClient>()
                    selectedObjectStore.update(client.restGetObjectById(content.objectId).getOrNull())
                }
            }
        }

        twColOf {
            contentCreateHeader(if (isCreateNew) editorCloseHandler else null, header) {
                twMediumIconButtonNeutral(icon = FormationUIIcons.Save) {
                    selectedObjectStore.data.render {
                        disabled(it == null)
                    }
                    clicks handledBy {
                        val rel = relStore.current.takeIf { it.isNotBlank() }
                        val selectedId = selectedObjectStore.current?.id ?: error("selectedId should not be null")
                        withBusyApplyContentChange(
                            objectId,
                            UpsertObjectLink(
                                id = content.id.takeIf { it.isNotBlank() } ?: Random.nextULong().toString(),
                                objectId = selectedId,
                                title = titleStore.current,
                                rel = rel,
                            ),
                        )
                        editorCloseHandler?.let { closeEditor ->
                            closeEditor(Unit)
                        }
                    }
                }
            }

            twObjectSelector(selectedObjectStore)

            selectedObjectStore.data.filterNotNull().render { obj ->
                generalListEntry(
                    obj,
                    withBorder = false,
                    tagsSearchable = false,
                    keywordTagType = KeywordTagType.ObjectTag,
                )
            }

            inputLabelWrapper(
                title = GeoObjectEditor.OptionalTitleForThisLink.getTranslationFlow(),
                visibilityFlow = titleStore.data.map { it.isNotBlank() },
            ) {
                twInputField(titleStore) {
                    twInputTextField {
                        placeholder(GeoObjectEditor.TitlePlaceholder.getTranslationFlow())
                    }
                }
            }

            inputLabelWrapper(
                title = GeoObjectEditor.LinkRelation.getTranslationFlow(),
                visibilityFlow = flowOf(true),
            ) {
                twSelect(store = relStore) {
                    option {
                        value("")
                        +""
                        selected(relStore.data.map { it == "" })
                    }
                    LinkRel.entries.forEach { rel ->
                        option {
                            value(rel.name)
                            translate(rel.translatable)
                            selected(relStore.data.map { it == rel.name })
                        }

                    }
                }
            }
        }
    }
}

val LinkRel.translatable: Translatable
    get() {
        val n = name
        return object : Translatable {
            override val name: String
                get() = n
            override val prefix: String
                get() = "link-rel"
        }
    }
