package svgmarker

import apiclient.geoobjects.ObjectType
import com.github.nwillc.ksvg.RenderMode
import com.github.nwillc.ksvg.elements.SVG
import dev.fritz2.components.compat.div
import dev.fritz2.components.compat.span
import dev.fritz2.components.flexBox
import dev.fritz2.core.RenderContext
import dev.fritz2.core.src
import dev.fritz2.styling.params.rgba
import dev.fritz2.styling.params.shadow
import dev.fritz2.styling.theme.IconDefinition
import koin.koinCtx
import kotlin.math.max
import kotlinx.browser.document
import maplibreGL.MaplibreMap
import org.w3c.dom.Element
import org.w3c.dom.HTMLDivElement
import theme.FormationColors
import theme.FormationDefault
import theme.FormationIcons
import utils.calcXOnCircle
import utils.calcYOnCircle
import utils.getOffsetInt

private fun Double.asPercent() = "${this * 100}%"

fun RenderContext.renderSvgIcon(
    svgIconOptions: SvgIconOptions,
    notificationIcon: IconDefinition = FormationIcons.NotificationsOn.icon,
    active: Boolean = false,
    debug: Boolean = false,
): Element {
    val profilePicture = svgIconOptions.profilePicture
    val bitmapPicture = svgIconOptions.bitmapPicture

    return if (profilePicture != null) {
        img("w-10 max-h-10 object-cover cursor-pointer p-0.5 m-0.5 rounded-full shadow-xl") {
            className(if (active) "bg-highlight" else "bg-formationWhite")
            src(profilePicture)
        }.domNode
    } else if (bitmapPicture != null) {
        img("max-w-16 max-h-10 object-scale-down cursor-pointer bg-formationWhite p-1.5 rounded-lg shadow-xl") {
            if (active) className("ring ring-highlight") else className("ring ring-transparent")
            src(bitmapPicture)
        }.domNode
    } else {
        val svgContent = makeSvgIcon(
            svgIconOptions = svgIconOptions,
            renderMode = RenderMode.INLINE,
            debug = debug,
            notificationIcon = notificationIcon,
        )
        svg {}.domNode.apply {
            outerHTML = svgContent
        }
    }
}

private fun makeSvgIcon(
    svgIconOptions: SvgIconOptions,
    renderMode: RenderMode = RenderMode.FILE,
    debug: Boolean = false,
    notificationIcon: IconDefinition = FormationIcons.NotificationsOn.icon,
): String {
    val paddingRatio = 1.3

    val bgWidth = svgIconOptions.size.smPixel

    val wholeWidth = (bgWidth * paddingRatio).toInt()

    val extraCircleDiameter = (1.0 / 3.0) / paddingRatio
    val extraCircleRadius = extraCircleDiameter / 2.0
    val notificationAngle = if (svgIconOptions.stateColor != null) 45 - 18 else 45
    val stateAngle = if (svgIconOptions.hasNotification) 45 + 18 else 45

    val svg = SVG.svg(false) {
        width = "$wholeWidth"
        height = "$wholeWidth"
        cssClass = "drop-shadow-md"

        attributes["preserveAspectRatio"] = "xMidYMid meet"

        if (debug) {
            rect {
                id = "debug"
                height = "100%"
                width = "100%"
                fill = "lightgrey"
            }
            line {
                x1 = "0%"
                y1 = "0%"
                x2 = "100%"
                y2 = "100%"
                stroke = "red"
                strokeWidth = "0.5"
            }
            line {
                x1 = "0%"
                y1 = "100%"
                x2 = "100%"
                y2 = "0%"
                stroke = "red"
                strokeWidth = "0.5"
            }
        }

        val bgShapeWidth = (1.0 / paddingRatio)
        g {
            val (_, _, width, height) = svgIconOptions.bgShape.shape.viewBox // viewBox = (minX, minY, width, height)
                .split(" ").map { it.toDouble() }
            val scale = max(width / wholeWidth.toDouble(), height / wholeWidth.toDouble())

            val s = bgShapeWidth / scale
            val tX = (wholeWidth - (width * s)) / 2.0
            val tY = (wholeWidth - (height * s)) / 2.0

            attributes["transform"] = "translate($tX, $tY) scale($s)"

            id = "shape"

            attributes["color"] = svgIconOptions.bgColor.color
            body = "replace_shape"


            if (debug) {
                circle {
                    r = "5%"
                    cx = "50%"
                    cy = "50%"
                    fill = "pink"
                    stroke = "purple"

                }
            }

            attributes["stroke"] = svgIconOptions.borderColor
            attributes["stroke-width"] = "4%" //"2"
            attributes["stroke-opacity"] = svgIconOptions.borderOpacity.toString()
            attributes["fill-opacity"] = svgIconOptions.bgOpacity.toString()
        }

        val iconWidth = bgShapeWidth * (0.5)
        val yOffset = svgIconOptions.bgShape.getOffsetInt() ?: 0
        g {
            id = "icon"
            val (_, _, width, height) = svgIconOptions.icon.icon.viewBox // viewBox = (minX, minY, width, height)
                .split(" ").map { it.toDouble() }
            val scale = max(width / wholeWidth.toDouble(), height / wholeWidth.toDouble())

            val s = iconWidth / scale
            val tXIcon = (wholeWidth - (width * s)) / 2.0
            val tYIcon = ((wholeWidth - (height * s)) / 2.0) - yOffset
            attributes["transform"] = "translate($tXIcon, $tYIcon) scale($s)"

            attributes["color"] = svgIconOptions.iconColor.color
            body = "replace_icon"

            attributes["fill-opacity"] = svgIconOptions.iconOpacity.toString()
            if (debug) {
                rect {
                    this.width = 1.0.asPercent()
                    this.height = 1.0.asPercent()
                    attributes["x"] = 0.0.asPercent()
                    attributes["y"] = 0.0.asPercent()

                    fill = "none"
                    stroke = "red"
                    strokeWidth = 0.02.asPercent()
                }
            }
        }
        if (svgIconOptions.hasNotification) {
            g {
                id = "notification"

                val tXNotification =
                    (calcXOnCircle(0.5, 0.55 / paddingRatio, -notificationAngle) - extraCircleRadius) * wholeWidth
                val tYNotification =
                    (calcYOnCircle(0.5, 0.55 / paddingRatio, -notificationAngle) - extraCircleRadius) * wholeWidth

                attributes["transform"] =
                    "translate($tXNotification, $tYNotification) scale(${extraCircleDiameter}, ${extraCircleDiameter})"


                circle {
                    r = "45%"
                    cx = "50%"
                    cy = "50%"
                    fill = "rgb(14, 41, 47)"
                    stroke = "rgb(255, 255, 255)"
                    strokeWidth = "5%"
                }

                g {
                    id = "notification_icon"

                    val ratio = (8.0 / 14.0)
                    val (_, _, width, height) = notificationIcon.viewBox.split(" ")
                        .map { it.toDouble() } // viewBox = (minX, minY, width, height)
                    val scale = max(width / wholeWidth.toDouble(), height / wholeWidth.toDouble())

                    val s = ratio / scale
                    val tXNotificationIcon = ((wholeWidth - (width * s)) / 2.0)
                    val tYNotificationIcon = ((wholeWidth - (height * s)) / 2.0)
                    attributes["transform"] = "translate($tXNotificationIcon, $tYNotificationIcon) scale($s)"

                    attributes["color"] = "rgb(255, 255, 255)"

                    body = "replace_notifications_on"
                }
            }
        }
        svgIconOptions.stateColor?.let { stateColor ->
            g {
                id = "state"
                val tXState = (calcXOnCircle(0.5, 0.55 / paddingRatio, -stateAngle) - extraCircleRadius) * wholeWidth
                val tYState = (calcYOnCircle(0.5, 0.55 / paddingRatio, -stateAngle) - extraCircleRadius) * wholeWidth

                attributes["transform"] =
                    "translate($tXState, $tYState) scale($extraCircleDiameter, $extraCircleDiameter)"

                circle {
                    r = "45%"
                    cx = "50%"
                    cy = "50%"
                    fill = stateColor.color
                    stroke = "rgb(255, 255, 255)"
                    strokeWidth = "5%"
                }

                svgIconOptions.stateIcon?.let { stateIcon ->
                    g {
                        id = "state_icon"

                        val ratio = (8.0 / 14.0)
                        val (_, _, width, height) = stateIcon.viewBox.split(" ")
                            .map { it.toDouble() } // viewBox = (minX, minY, width, height)
                        val scale = max(width / wholeWidth.toDouble(), height / wholeWidth.toDouble())

                        val s = ratio / scale
                        val tXStateIcon = ((wholeWidth - (width * s)) / 2.0)
                        val tYStateIcon = ((wholeWidth - (height * s)) / 2.0)
                        attributes["transform"] = "translate($tXStateIcon, $tYStateIcon) scale(${s})"

                        attributes["color"] = "rgb(255, 255, 255)"

                        body = "replace_state_icon_on"
                    }
                }
            }
        }
    }

    val svgContent = buildString {
        svg.render(this, renderMode)
    }
        .replace("replace_shape", svgIconOptions.bgShape.shape.svg.let { "\n$it\n" })
        .replace("replace_icon", svgIconOptions.icon.icon.svg.let { "\n$it\n" })
        .replace("replace_notifications_on", notificationIcon.svg.let { "\n$it\n" })
        .replace("replace_state_icon_on", svgIconOptions.stateIcon?.svg.let { "\n$it\n" })

    return svgContent
}

fun RenderContext.makeSvgMarker(
    objectId: String,
    objectType: ObjectType,
    title: String,
    svgIconOptions: SvgIconOptions,
    active: Boolean = false,
    showTitle: Boolean = true,
) {
    val maplibreMap by koinCtx.inject<MaplibreMap>()
    flexBox(
        {
            justifyContent { center }
            css("cursor: pointer;")
            if (active) color { FormationColors.MarkerYou.color }
            if (svgIconOptions.flagged) color { FormationColors.RedError.color }
            if (svgIconOptions.archived || svgIconOptions.flagged || svgIconOptions.desaturated) opacity { light }
            if (svgIconOptions.desaturated) css("filter: grayscale(100%);")
        },
        prefix = "marker-stack",
    ) {
        if ((active || showTitle) && svgIconOptions.bitmapPicture == null) {
            // Exclude GeneralMarkers from redraw on top overrides, to be able to re-render their connectable shapes,
            // without re-rendering the marker, which would create and event loop on mouseover
            if (objectType != ObjectType.GeneralMarker) {
                (domNode as? HTMLDivElement)?.onmouseenter = {
                    maplibreMap.updateRedrawOnTopOverride(objectId)
                }
                (domNode as? HTMLDivElement)?.onmouseleave = {
                    maplibreMap.removeRedrawOnTopOverride(objectId)
                }
            }
            // MARKER TITLE
            div(
                {
                    textAlign { center }
                    width { maxContent }
                    maxWidth { "200px" }
                    position { absolute { } }
                    fontSize { small }
                    fontWeight { bold }
                    css("overflow-wrap: break-word;")
//                    css("white-space:nowrap;")
                    margins { top { "${(svgIconOptions.size.smPixel * 1.15) + 1}" } }
                },
                prefix = "marker-title",
            ) {
                span(
                    {
                        css("text-shadow: -1px -1px 0 #FFF, 1px -1px 0 #FFF, -1px 1px 0 #FFF, 1px 1px 0 #FFF;")
                    },
                ) { +title /*cutString(title)*/ }
            }
        } else {
            val titleId = "$objectId-marker-title"
            (domNode as? HTMLDivElement)?.onmouseenter = {
                if (objectType != ObjectType.GeneralMarker) {
                    maplibreMap.updateRedrawOnTopOverride(objectId)
                }
                if (!active) {
                    div(
                        {
                            textAlign { center }
                            width { maxContent }
                            maxWidth { "200px" }
                            position { absolute { } }
                            fontSize { small }
                            fontWeight { bold }
                            css("overflow-wrap: break-word;")
//                            css("white-space:nowrap;")
                            margins { top { "${(svgIconOptions.size.smPixel * 1.15) + 1}" } }
                        },
                        id = titleId,
                    ) {
                        span(
                            {
                                css("text-shadow: -1px -1px 0 #FFF, 1px -1px 0 #FFF, -1px 1px 0 #FFF, 1px 1px 0 #FFF;")
                            },
                        ) { +title /*cutString(title)*/ }
                    }
                }
            }
            (domNode as? HTMLDivElement)?.onmouseleave = {
                // TODO: remove showTitle override
                document.getElementById(titleId)?.remove()
                maplibreMap.removeRedrawOnTopOverride(objectId)
            }
        }
        // MARKER ICON
        renderSvgIcon(svgIconOptions, active = active)
    }
}

fun RenderContext.makeSvgClusterMarker(title: String, svgIconOptions: SvgIconOptions) {
    // CLUSTER MARKER
    flexBox(
        {
            width { "${svgIconOptions.size.smPixel}" }
            height { "${svgIconOptions.size.smPixel}" }
            justifyContent { center }
            alignItems { center }
            radius { full }
            boxShadow {
                shadow("2px", "4px", "2px", color = rgba(0, 0, 0, 0.25))
            }
            color { svgIconOptions.iconColor.color }
            background { color { svgIconOptions.bgColor.color } }
            svgIconOptions.borderColor?.let { border { color { it }; width(FormationDefault.formationStyles.borderWidth) } }
            paddings {
                horizontal { tiny }
                vertical { tiny }
            }
        },
    ) {
        // Number instead of icon
        span(
            {
                fontSize { normal }
                fontWeight { bold }
                fontSize { "0.9rem" }
            },
        ) { +title }
    }
}

//fun RenderContext.makeProfilePictureMarker(
//    title: String,
//    active: Boolean = false,
//    picture: String
//) {
//    div("flex flex-col items-center justify-center") {
//        img("w-14 h-auto object-cover cursor-pointer bg-formationWhite p-0.5 rounded-full shadow-xl") {
//            src(picture)
//        }
//        span("mt-1 w-max max-w-[200px] text-sm font-bold break-words text-shadow-markerTitle shadow-formationWhite") {
//            if (active) className("text-highlight") else className("text-formationBlack")
//            +title
//        }
//    }
//}
//
//fun RenderContext.makeBitmapMarker(
//    title: String,
//    active: Boolean = false,
//    bitmap: String
//) {
//    div("flex flex-col items-center justify-center") {
//        img("w-16 h-auto object-scale-down cursor-pointer bg-formationWhite p-1 rounded-2xl shadow-xl") {
////            src(logo)
//            val rand = kotlin.random.Random.nextInt()
//            if (rand % 2 == 0) {
//                src("/assets/images/GITEX_EUROPE with Dates Color Horizontal_128px.png")
//            } else {
//                src("/assets/images/2024_Unite_Logo_4_512x153.jpg")
//            }
//        }
//        span("mt-1 w-max max-w-[200px] text-sm font-bold break-words text-shadow-markerTitle shadow-formationWhite") {
//            if (active) className("text-highlight") else className("text-formationBlack")
//            +title
//        }
//    }
//}


/**
 * Deprecated functions to make HTMLMarkers
 */

////fun RenderContext.makeSvgIconV1(svgIconOptions: SvgIconOptions) {
//    // default background shape: circle & iconstack holder
//    flexBox({
//        width { "${svgIconOptions.size.smPixel}" }
//        height { "${svgIconOptions.size.smPixel}" }
//        justifyContent { center }
//        alignItems { center }
//        position { relative {  } }
//        if (svgIconOptions.bgShape == FormationShapes.Circle) {
//            radius { full }
//            boxShadow {
//                shadow("2px", "4px", "4px", color = rgba(0, 0, 0, 0.25))
//            }
//            color { svgIconOptions.iconColor.color }
//            background { color { svgIconOptions.bgColor.color } }
//            svgIconOptions.borderColor?.let { border { color { it }; width(FormationDefault.formationStyles.borderWidth) } }
//        }
//    }) {
//        if (svgIconOptions.bgShape != FormationShapes.Circle) {
//            // Background-Icon
//            icon({
//                size { "${svgIconOptions.size.smPixel}" }
//                color { svgIconOptions.bgColor.color }
//                svgIconOptions.borderColor?.let {
//                    css("""
//                        path {
//                          stroke: $it;
//                          stroke-width: 2px;
//                          stroke-linejoin: round;
//                        }
//                        """.trimIndent()
//                    )
//                }
//                position { absolute {
//                    with(svgIconOptions.bgShape.getOffset()){
//                        if(this != null) top(this@with)
//                    }
//                } }
//                css("filter: drop-shadow(2px 4px 4px rgba(0, 0, 0, 0.25));")
//            }) {
//                fromTheme { svgIconOptions.bgShape.shape }
//            }
//        }
//        // Icon
//        icon({
//            if (svgIconOptions.bgShape != FormationShapes.Circle) {
//                position { absolute { } }
//            }
//            size(svgIconOptions.size.smIconSize)
//            color { svgIconOptions.iconColor.color }
//        }) { fromTheme { svgIconOptions.icon.icon } }
//        // Notification indicator
//        makeSvgNotificationIndicator(svgIconOptions)
//        // TaskState indicator
//        makeSvgStateIndicator(svgIconOptions)
//    }
//}
//
////fun RenderContext.makeSvgNotificationIndicator(svgIconOptions: SvgIconOptions) {
//    val angle = if(svgIconOptions.stateColor != null) 20 else 45
//    val smSize = svgIconOptions.size.smPixel
//    // NOTIFICATION INDICATOR
//    if(svgIconOptions.hasNotification) {
//        flexBox ({
//            justifyContent { center }
//            alignItems { center }
//            width { "${smSize/3}" }
//            height { "${smSize/3}" }
//            position{ absolute {
//                left { "${calcXOnCircle(smSize/2.0/1.3, smSize/2.0, angle)}"}
//                bottom { "${calcYOnCircle(smSize/2.0/1.3, smSize/2.0, angle)}" }
//            } }
//            background { color { primary.main } }
//            radius { full }
//            border {
//                color { secondary.main }
//                width { "1px" }
//            }
//            padding { "2px" }
//            color { secondary.main }
//            zIndex { "300" }
//        }) {
//            icon({
//                size { full }
//            }) { fromTheme { FormationIcons.NotificationsOn.icon } }
//        }
//    }
//}
//
////fun RenderContext.makeSvgStateIndicator(svgIconOptions: SvgIconOptions) {
//    val angle = if(svgIconOptions.hasNotification) 60 else 45
//    val smSize = svgIconOptions.size.smPixel
//    // STATE INDICATOR
//    if(svgIconOptions.stateColor != null) {
//        div ({
//            width { "${smSize/3}" }
//            height { "${smSize/3}" }
//            position { absolute {
//                left { "${calcXOnCircle(smSize/2.0/1.3, smSize/2.0, angle)}"}
//                bottom { "${calcYOnCircle(smSize/2.0/1.3, smSize/2.0, angle)}" }
//            } }
//            background { color { svgIconOptions.stateColor.color } }
//            radius { full }
//            border {
//                color { secondary.main }
//                width { "1px" }
//            }
//            color { secondary.main }
//            zIndex { "300" }
//        }) {  }
//    }
//}
