package data.users

import apiclient.FormationClient
import apiclient.geoobjects.Content
import apiclient.search.ObjectSearchResult
import apiclient.search.ObjectSearchResults
import apiclient.users.PublicUserProfile
import apiclient.users.restGetPublicUserProfile
import data.objects.ActiveObjectStore
import data.objects.AssigneeSelectorStore
import data.objects.AttendeesSelectorStore
import dev.fritz2.core.RootStore
import dev.fritz2.core.storeOf
import dev.fritz2.routing.MapRouter
import io.ktor.util.*
import koin.koinCtx
import kotlinx.coroutines.CoroutineName
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.mapNotNull
import kotlinx.coroutines.launch
import mainmenu.RouterStore
import map.MapLayersStore
import maplibreGL.MaplibreMap
import model.LayerType
import model.userId
import overlays.BusyStore
import qrcodegeneration.toSvgQrCode
import utils.loadImageData
import websocket.UserAndObjectResultsStore

val emptyPublicUserProfile = PublicUserProfile(
    userId = "",
    firstName = null,
    lastName = null,
    jobTitle = null,
    emailAddresses = emptyList(),
    phoneNumbers = emptyList(),
)
val handlerScope = CoroutineScope(CoroutineName("handler"))

class ActiveUserStore : RootStore<PublicUserProfile>(
    initialData = emptyPublicUserProfile,
    job = Job(),
) {
    val vcardQrCodeStore = storeOf<String?>(null, Job())
    val vcardStore = storeOf<String?>(null, Job())
    private val assigneeSelectorStore: AssigneeSelectorStore by koinCtx.inject()
    private val attendeesSelectorStore: AttendeesSelectorStore by koinCtx.inject()
    private val activeObjectStore: ActiveObjectStore by koinCtx.inject()
    private val maplibreMap: MaplibreMap by koinCtx.inject()
    private val mapLayersStore: MapLayersStore by koinCtx.inject()
    private val userAndObjectResultsStore: UserAndObjectResultsStore by koinCtx.inject()
    private val router: MapRouter by koinCtx.inject()
    private val routerStore: RouterStore by koinCtx.inject()
    private val busyStore: BusyStore by koinCtx.inject()
    private val formationClient: FormationClient by koinCtx.inject()

    private val activeUserId = map(PublicUserProfile.userId())

    suspend fun fetchPublicProfile(userId: String) {
        busyStore.handleApiCall(
            suspend {
                formationClient.restGetPublicUserProfile(userId = userId)
            },
            processResult = { userProfile ->
                console.log("Fetched user ->", userProfile.name)
                update(userProfile)
            },
            errorMessage = flowOf("Failed to fetch user profile"),
            processError = { e: Throwable ->
                console.log("Failed to fetch PublicUserProfile for userId: $userId", e)
            },
        )
    }

    private fun PublicUserProfile.vCard(photoBytes: ByteArray? = null): String {
        return utils.vCard(
            firstName,
            lastName,
            jobTitle,
            emailAddresses.firstOrNull(),
            phoneNumbers,
            company = company,
            linkedInLink = linkedInLink,
            websiteLink = websiteLink,
            photoBytes = photoBytes,
        )
    }

    private val generateVcardQRCode = handle { old ->
        handlerScope.launch {
            val vcardWithPhoto = current.vCard(
                current.profilePhoto?.thumbNail?.let {
                    it as Content.Image
                    loadImageData(it.href)
                },
            )
//            console.log("VCARD with photo for download", vcardWithPhoto)
            vcardStore.update(vcardWithPhoto)
            val vcardLite = current.vCard()
//            console.log("VCARD lite for qr", vcardLite)
            val svg = toSvgQrCode(vcardLite)
            vcardQrCodeStore.update("data:image/svg+xml;base64,${svg.encodeBase64()}")
        }
        old
    }

    val reset = handle {
        emptyPublicUserProfile
    }

    val setAsAssignee = handle {
        current
        assigneeSelectorStore.toggleSingleUserSelection(current)
        activeObjectStore.setAssignee(current)
        current
    }

    val setAsAttendee = handle {
        current
        attendeesSelectorStore.toggleMultiUserSelection(current.userId)
        activeObjectStore.setAttendee(current.userId)
        current
    }

    private val setActiveUserCopy = handle<PublicUserProfile> { current, newUser ->
        with(userAndObjectResultsStore.current.renderData[newUser.userId]) {
            if (this != null) {
                if (this.ownerId !in maplibreMap.disabledObjects) {
                    mapLayersStore.setResults(
                        LayerType.ActiveObjectOrUserCopy,
                        ObjectSearchResults(from = 0, pageSize = 0, total = 1, hits = listOf(ObjectSearchResult(hit = this))),
                    )
                } else {
                    mapLayersStore.setResults(LayerType.ActiveObjectOrUserCopy, null)
                }
            } else {
                mapLayersStore.setResults(LayerType.ActiveObjectOrUserCopy, null)
            }
        }
        if (newUser.userId.isNotBlank()) {
            newUser
        } else current
    }

    init {
        data.map { } handledBy generateVcardQRCode
        activeUserId.data.map { current } handledBy setActiveUserCopy
        activeUserId.data.mapNotNull { userId ->
            if (userId.isNotBlank()) {
                if (router.current.keys == setOf("page", "userId") || router.current.keys == setOf("page")) {
                    router.current.plus("userId" to userId)
                } else null
            } else router.current.minus("userId")
        } handledBy routerStore.validateInternalRoute
    }
}
