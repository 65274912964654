package data.objects.views

import data.objects.ActiveObjectStore
import data.objects.DescriptionInputFieldStore
import dev.fritz2.components.compat.div
import dev.fritz2.components.textArea
import dev.fritz2.core.RenderContext
import dev.fritz2.core.placeholder
import koin.koinCtx
import localization.TL
import localization.Translation
import mainmenu.RouterStore
import styling.primaryButtonStyleParams
import styling.secondaryButtonStyleParams
import theme.FormationDefault
import theme.FormationIcons
import webcomponents.baseLayout
import webcomponents.cardTitle
import webcomponents.twoButtonFooter

fun RenderContext.cardDescription() {

    val translation: Translation by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()
    val activeObjectStore: ActiveObjectStore = koinCtx.get()
    val descriptionInputFieldStore: DescriptionInputFieldStore = koinCtx.get()

    baseLayout(
        header = {
            cardTitle(translation[TL.CardDescription.CARD_TITLE]) { FormationIcons.Description.icon }
        },
        content = {
            // INPUT AREA
            div({
                display { flex }
                justifyContent { center }
                alignItems { stretch }
                height { full }
                width { full }
                paddings { horizontal { normal } }
            }) {
                textArea({
                    height { full }
                    width { full }
                    display { flex }
                    border {
                        width(FormationDefault.formationStyles.borderWidth)
                        color { primary.main }
                    }
                    radius { large }
                    padding { smaller }
                    fontSize { small }
                }, value = descriptionInputFieldStore) {
                    element {
                        placeholder(translation.getString(TL.General.DESCRIPTION))
                    }
                    resizeBehavior { none }
                }
            }
        },
        footer = {
            twoButtonFooter(
                secondaryTitle = translation[TL.General.CANCEL],
                secondaryStyleParams = secondaryButtonStyleParams,
                secondaryClickHandlers = listOf(activeObjectStore.updateChangeInputStores, routerStore.back),
                primaryTitle = translation[TL.General.SAVE],
                primaryStyleParams = primaryButtonStyleParams,
                primaryValue = Unit,
                primaryClickHandlers = listOf(activeObjectStore.readFromChangeInputStores, routerStore.back),
            )
        }
    )
}
