package search.hub

import auth.ApiUserStore
import apiclient.geoobjects.ObjectType
import dev.fritz2.components.compat.button
import dev.fritz2.components.compat.div
import dev.fritz2.components.compat.span
import dev.fritz2.components.flexBox
import dev.fritz2.components.stackUp
import dev.fritz2.core.HtmlTag
import dev.fritz2.core.RenderContext
import koin.koinCtx
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.mapNotNull
import kotlinx.datetime.Clock
import localization.TL
import localization.Translation
import map.bottombar.inlinedBottomBar
import org.w3c.dom.HTMLDivElement
import search.distanceCalcSwitch
import search.searchResultsList
import theme.FormationColors
import theme.FormationDefault
import utils.getPartOfDay
import utils.makeRGBA
import utils.translateName
import webcomponents.baseLayout
import webcomponents.cardSubtitle
import webcomponents.contentScrollBox
import webcomponents.expandState
import webcomponents.mainTitle

fun RenderContext.pageHub() {

    val apiUserStore: ApiUserStore by koinCtx.inject()
    val hubUnifiedResultsStore: HubUnifiedResultsStore by koinCtx.inject()
    val translation: Translation by koinCtx.inject()
    val hubDistanceToCentroidStore: HubDistanceToCentroidStore by koinCtx.inject()

    val searchHeader: RenderContext.() -> HtmlTag<HTMLDivElement> = {
        stackUp(
            {
                width { full }
            },
        ) {
            spacing { tiny }
            items {
                // PAGE TITLE (ONLY VISIBLE ON DESKTOP)
                flexBox(
                    {
                        width { full }
                        margins {
                            bottom { small }
                        }
                        alignItems { start }
                        justifyContent { center }
                        direction { column }
                    },
                ) {
                    mainTitle(
                        title = translation[
                            TL.PageHub.GREETING,
                            mapOf(
                                "partOfDay" to Clock.System.now().getPartOfDay().stringKey,
                                "userName" to apiUserStore.current.firstName,
                            ),
                        ],
                        color = { FormationColors.MarkerYou.color },
                    )
                    cardSubtitle(translation[TL.PageHub.HERE_IS_WHATS_HAPPENING])
                }

                // Filter tag buttons
                hubTagFilterBar()

                // distance calculation switch
                distanceCalcSwitch(
                    distanceToSelectStore = hubDistanceToCentroidStore,
                    switchHandler = hubDistanceToCentroidStore.select,
                )
            }
        }
    }

    baseLayout(
        header = {
            expandState.render { expanded ->
                if (expanded == true) searchHeader()
            }
        },
        content = {
            contentScrollBox {
                expandState.render { expanded ->
                    if (expanded != true) {
                        div(
                            {
                                margins { bottom { small } }
                            },
                        ) {
                            searchHeader()
                        }
                    }
                }
                // search results
                searchResultsList(results = hubUnifiedResultsStore.data.mapNotNull { it?.hits })
            }
        },
        footerPaddings = {},
        footer = {
            inlinedBottomBar()
        },
    )
}

fun RenderContext.hubTagFilterBar() {
    val hubObjectTypeFilterStore by koinCtx.inject<HubObjectTypeFilterStore>()

    // TODO make this expandable and show only first three when collapsed

    flexBox(
        {
            width { full }
            height { maxContent }
            wrap { wrap }
        },
    ) {
        hubObjectTypeFilterStore.data.render { filterTypes ->
            filterTypes.forEach { (objectType, active) ->
                hubTagFilterButton(objectType = objectType, active = active, text = objectType.translateName())
            }
        }
    }
}

fun RenderContext.hubTagFilterButton(
    objectType: ObjectType,
    active: Boolean,
    text: Flow<String>
) {
    val hubObjectTypeFilterStore by koinCtx.inject<HubObjectTypeFilterStore>()
    button(
        {
            radius(FormationDefault.formationStyles.inputRadius)
            margin { tiny }
            if (active) {
                color { secondary.main }
                background {
                    color { FormationColors.MarkerYou.color }
                }
                border {
                    color { FormationColors.MarkerYou.color }
                    width(FormationDefault.formationStyles.borderWidth)
                }
                hover {
//                color { secondary.main }
                    background {
                        color { makeRGBA(FormationColors.MarkerYou.color, 0.7) }
                    }
                    border {
                        color { makeRGBA(FormationColors.MarkerYou.color, 0.7) }
                        width(FormationDefault.formationStyles.borderWidth)
                    }
                }
                focus {
                    color { FormationColors.MarkerYou.color }
                    background {
                        color { secondary.main }
                    }
                    border {
                        width(FormationDefault.formationStyles.borderWidth)
                        color { FormationColors.MarkerYou.color }
                    }
                }
            } else {
                color { FormationColors.MarkerYou.color }
                background {
                    color { secondary.main }
                }
                border {
                    color { FormationColors.MarkerYou.color }
                    width(FormationDefault.formationStyles.borderWidth)
                }
                hover {
                    color { makeRGBA(FormationColors.MarkerYou.color, 0.7) }
//                background {
//                    color { secondary.main }
//                }
                    border {
                        width(FormationDefault.formationStyles.borderWidth)
                        color { makeRGBA(FormationColors.MarkerYou.color, 0.7) }
                    }
                }
                focus {
                    color { secondary.main }
                    background {
                        color { FormationColors.MarkerYou.color }
                    }
                    border {
                        color { FormationColors.MarkerYou.color }
                        width(FormationDefault.formationStyles.borderWidth)
                    }
                }
            }
        },
    ) {
        flexBox(
            {
                direction { row }
                width { maxContent }
                height { maxContent }
                alignItems { center }
                justifyContent { center }
                radius(FormationDefault.formationStyles.inputRadius)
                paddings {
                    vertical { tiny }
                    horizontal { smaller }
                }
            },
        ) {
            span(
                {
                    fontWeight { bold }
                    fontSize { smaller }
                },
            ) { text.renderText(into = this) }
        }
        clicks.map { objectType } handledBy hubObjectTypeFilterStore.flip
    }
}
