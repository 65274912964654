package camera.zxing

import camera.cameraWrapper.SelectedCameraDeviceStore
import koin.koinCtx
import kotlin.js.Promise
import kotlinx.coroutines.await

class ZXingCodeScanService() {

    private val selectedCameraDeviceStore: SelectedCameraDeviceStore by koinCtx.inject()

//    val hints = mapOf(
//        DecodeHintType.TRY_HARDER.name to true,
//        DecodeHintType.POSSIBLE_FORMATS.name to listOf(
//            ScanFormat.QR_CODE,
//            ScanFormat.AZTEC,
//            ScanFormat.CODE_128,
//            ScanFormat.DATA_MATRIX,
//            ScanFormat.MAXICODE,
//            ScanFormat.PDF_417,
//            ScanFormat.CODABAR,
//
//            ).map { it.ordinal }.toTypedArray()
//    ).asJsReadonlyMapView()

    private val zxing = BrowserMultiFormatReader(
        hints = null,
        timeBetweenScansMillis = 300,
    )

    suspend fun scan(
        deviceId: String?, videoElementId: String?,
    ): ZXingResult {
        return zxing.decodeOnceFromVideoDevice(deviceId, videoElementId).await()
    }

    // FIXME Does work, but can lead to performance issues and endless ques of store updates when holding a not accepted code to long in front of the camera
    fun scanContinuousUntilAccepted(
        deviceId: String?, videoSource: String?
    ): Promise<ZXingResult> {
        return Promise { resolve, _ ->
            zxing.decodeFromInputVideoDeviceContinuously(deviceId, videoSource) { result, error ->
                if (error != null || result?.formatMeta?.policy == ScanFormatPolicy.Reject) {
                    error?.let {
//                        console.log("Error while scanning", it.message)
                    }
                    result?.let {
                        console.log("Result not accepted", result.text)
                        rejectedScanResultStore.update(result)
                    }
                } else if (result != null && result.formatMeta.policy == ScanFormatPolicy.Accept) {
                    resolve(result)
                }
            }
        }
    }

    fun reset() {
        selectedCameraDeviceStore.stopTracksAndReset()
        zxing.reset()
    }
}

val ZXingResult.formatMeta: ScanFormat
    get() {
        return ScanFormat.entries[format]
    }

enum class ScanFormatPolicy {
    Reject,
    Warn,
    Accept,
}


@Suppress("unused")
internal enum class DecodeHintType {
    /**
     * Unspecified, application-specific hint. Maps to an unspecified Object.
     */
    OTHER,  /*(Object.class)*/

    /**
     * Image is a pure monochrome image of a barcode. Doesn't matter what it maps to;
     * use Boolean.TRUE.
     */
    PURE_BARCODE,  /*(Void.class)*/

    /**
     * Image is known to be of one of a few possible formats.
     * Maps to a List of BarcodeFormats.
     */
    POSSIBLE_FORMATS,  /*(List.class)*/

    /**
     * Spend more time to try to find a barcode; optimize for accuracy, not speed.
     * Doesn't matter what it maps to; use Boolean.TRUE.
     */
    TRY_HARDER,  /*(Void.class)*/

    /**
     * Specifies what character encoding to use when decoding, where applicable (type String)
     */
    CHARACTER_SET,  /*(String.class)*/

    /**
     * Allowed lengths of encoded data -- reject anything else. Maps to an `Int32Array`.
     */
    ALLOWED_LENGTHS,  /*(Int32Array.class)*/

    /**
     * Assume Code 39 codes employ a check digit. Doesn't matter what it maps to;
     * use Boolean.TRUE.
     */
    ASSUME_CODE_39_CHECK_DIGIT,  /*(Void.class)*/

    /**
     * Enable extended mode for Code 39 codes. Doesn't matter what it maps to;
     * use Boolean.TRUE.
     */
    ENABLE_CODE_39_EXTENDED_MODE,  /*(Void.class)*/

    /**
     * Assume the barcode is being processed as a GS1 barcode, and modify behavior as needed.
     * For example this affects FNC1 handling for Code 128 (aka GS1-128). Doesn't matter what it maps to;
     * use Boolean.TRUE.
     */
    ASSUME_GS1,  /*(Void.class)*/

    /**
     * If true, return the start and end digits in a Codabar barcode instead of stripping them. They
     * are alpha, whereas the rest are numeric. By default, they are stripped, but this causes them
     * to not be. Doesn't matter what it maps to; use Boolean.TRUE.
     */
    RETURN_CODABAR_START_END,  /*(Void.class)*/

    /**
     * The caller needs to be notified via callback when a possible ResultPoint
     * is found. Maps to a ResultPointCallback.
     */
    NEED_RESULT_POINT_CALLBACK,  /*(ResultPointCallback.class)*/


    /**
     * Allowed extension lengths for EAN or UPC barcodes. Other formats will ignore this.
     * Maps to an `Int32Array` of the allowed extension lengths, for example 2, 5, or 2, 5.
     * If it is optional to have an extension, do not set this hint. If this is set,
     * and a UPC or EAN barcode is found but an extension is not, then no result will be returned
     * at all.
     */
    ALLOWED_EAN_EXTENSIONS,  /*(Int32Array.class)*/ // End of enumeration values.
}
