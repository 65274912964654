package data.objects.views.task

import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.TaskState
import dev.fritz2.components.stackUp
import dev.fritz2.core.RenderContext
import dev.fritz2.components.compat.div
import koin.koinCtx
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import localization.TL
import localization.Translation
import model.L
import mainmenu.RouterStore
import data.objects.ActiveObjectStore
import styling.primaryButtonStyleParams
import styling.secondaryButtonStyleParams
import utils.getColor
import utils.getIcon
import utils.getSubtitle
import utils.getTitle
import webcomponents.*

fun RenderContext.cardTaskState() {

    val translation: Translation by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()
    val activeObjectStore: ActiveObjectStore by koinCtx.inject()
    val objectTaskState = activeObjectStore.map(GeoObjectDetails.L.taskState)
    val title = activeObjectStore.map(GeoObjectDetails.L.title)

    baseLayout(
        header = {
            cardTitle(title.data)
            mainSubTitle(translation[TL.CardTaskState.CARD_TITLE])
        },
        content = {
            div({
                width { full }
                height { maxContent }
                overflow { auto }
            }) {
                stackUp({
                    width { full }
                    height { maxContent }
                    justifyContent { center }
                    alignItems { stretch }
                    paddings { horizontal { normal } }
                }) {
                    spacing { small }
                    items {
                        TaskState.values().forEach { taskState ->
                            with(taskState) {
                                if (this == TaskState.Pending || this == TaskState.InProgress || this == TaskState.Completed || this == TaskState.Problem) {
                                    stateSelectorButton(
                                        active = objectTaskState.data.map { it == this },
                                        icon = { this@with.getIcon().icon },
                                        iconBackground = { this@with.getColor().color },
                                        title = flowOf(this.getTitle()),
                                        textColor = { primary.main },
                                        subtitle = flowOf(this.getSubtitle()),
                                        value = this,
                                        valueHandlers = listOf(activeObjectStore.setTaskStatus)
                                    )
                                }
                            }
                        }
                    }
                }
            }
        },
        footer = {
            twoButtonFooter(
                primaryTitle = translation[TL.General.UPDATE],
                primaryStyleParams = primaryButtonStyleParams,
                primaryState = objectTaskState.data.map { it != activeObjectStore.initialObject.taskState },
                primaryClickHandlers = listOf(activeObjectStore.changeTaskStatus, routerStore.back),
                secondaryTitle = translation[TL.General.BACK],
                secondaryStyleParams = secondaryButtonStyleParams,
                primaryValue = Unit,
                secondaryClickHandlers = listOf(activeObjectStore.resetTaskStatus, routerStore.back)
            )
        }
    )
}
