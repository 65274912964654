package dev.fritz2.components.foundations

import dev.fritz2.core.RootStore
import kotlinx.coroutines.Job

/**
 * This store can be used for components with an *internal* store that has to deal with a single element *selection*
 * from a collection of predefined values (like for a [selectField] or [radioGroup] component)
 *
 * It is based upon the *index* of an item from the list represented by the [Int] type.
 *
 * RFC: Never ever expose the internal store directly to the client side! Only accept values or [Flow]s and return
 * those in order to exchange data with the client!
 */
open class SingleSelectionStore : RootStore<Int?>(
    initialData = null,
    job = Job(),
) {
    val toggle = this.handleAndEmit<Int, Int> { _, new ->
        emit(new)
        new
    }
}
