package mainmenu

import routing.DestinationRoute

enum class Pages(val preLoginRedirect: Boolean = false, val notForAnonymous: Boolean = false, val preLoginPage: Boolean = false) : DestinationRoute {
    DataRights(preLoginPage = true),
    Terms,
    Login(preLoginPage = true),
    LoginWithEmail(preLoginPage = true),
    CreateWorkspace(preLoginPage = true),
    Cookies(preLoginPage = true),
    AppPaused(preLoginPage = true),
    SignUp(preLoginRedirect = true, preLoginPage = true),
    CreateAccount(preLoginRedirect = true, preLoginPage = true),
    Activate(preLoginPage = true),
    TokenExpired(preLoginPage = true),
    MyProfile(notForAnonymous = true),
    ViewTerms(notForAnonymous = true),
    UserSettings,
    SignOut,
    UserProfile,
    NotificationCenter(notForAnonymous = true),
    Feedback,
    Help,
    OnBoarding,
    NewPasswordNeeded,
    AdminDashboard(notForAnonymous = true),
    ResetPassword(preLoginPage = true),
    Debug,
    UserVerificationResult,

    // Main pages (bottom bar)
    Menu,
    Hub,
    Map,
    Scan,
    Search,
    AnalyticsDashboard,
    PublicProfile(preLoginRedirect = true, preLoginPage = true)
    ;

    companion object {
        val routeKey = "page"
    }

    override val routeKey = "page"
    override val route = mapOf(routeKey to name)
}
