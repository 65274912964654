package data.objects

import dev.fritz2.core.RootStore
import kotlinx.coroutines.Job

class DescriptionInputFieldStore : RootStore<String>(
    initialData = "",
    job = Job(),
) {

    val reset = handle { current ->
        update("")
        current
    }
}
