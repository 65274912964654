// GENERATED by fritz2 - NEVER CHANGE CONTENT MANUALLY!
package model

import apiclient.geoobjects.MarkerColor
import apiclient.geoobjects.MarkerIcon
import apiclient.geoobjects.MarkerShape
import dev.fritz2.core.Lens
import dev.fritz2.core.lensOf

fun ScannedCode.Companion.extObjId(): Lens<ScannedCode, String?> = lensOf(
    "extObjId",
    { it.extOrIntObjIdOrActionId },
    { p, v -> p.copy(extOrIntObjIdOrActionId = v) },
)

fun ScannedCode.Companion.type(): Lens<ScannedCode, CodeTech?> = lensOf(
    "type",
    { it.codeTech },
    { p, v -> p.copy(codeTech = v) },
)

fun ScannedCode.Companion.scanPurpose(): Lens<ScannedCode, ScanPurpose?> = lensOf(
    "scanPurpose",
    { it.scanPurpose },
    { p, v -> p.copy(scanPurpose = v) },
)

fun ScannedCode.Companion.operation(): Lens<ScannedCode, OperationType?> = lensOf(
    "operation",
    { it.operation },
    { p, v -> p.copy(operation = v) },
)

fun ScannedCode.Companion.color(): Lens<ScannedCode, MarkerColor?> = lensOf(
    "color",
    { it.color },
    { p, v -> p.copy(color = v) },
)

fun ScannedCode.Companion.icon(): Lens<ScannedCode, MarkerIcon?> = lensOf(
    "icon",
    { it.icon },
    { p, v -> p.copy(icon = v) },
)

fun ScannedCode.Companion.shape(): Lens<ScannedCode, MarkerShape?> = lensOf(
    "shape",
    { it.shape },
    { p, v -> p.copy(shape = v) },
)

fun ScannedCode.Companion.token(): Lens<ScannedCode, String?> = lensOf(
    "token",
    { it.token },
    { p, v -> p.copy(token = v) },
)

fun ScannedCode.Companion.logintoken(): Lens<ScannedCode, String?> = lensOf(
    "logintoken",
    { it.loginToken },
    { p, v -> p.copy(loginToken = v) },
)

fun ScannedCode.Companion.tag(): Lens<ScannedCode, String?> = lensOf(
    "tag",
    { it.tag },
    { p, v -> p.copy(tag = v) },
)
