package localization

enum class Locales(
    override val languageCode: String,
    override val countryCode: String?,
    override val aliases: Array<String>,
    val flagImagePath: String? = null,
    val fictional: Boolean = false
) : com.tryformation.localization.Locale {
    DE_DE("de", "DE", arrayOf("de"), "assets/images/locales/de_de_flag_24px.png"),
    EN_GB("en", "GB", arrayOf("en", "en-IE"), "assets/images/locales/en_gb_flag_24px.png"),
    EN_US("en", "US", arrayOf("en"), "assets/images/locales/en_us_flag_24px.png"),
    ES_ES("es", "ES", arrayOf("es"), "assets/images/locales/es_es_flag_24px.png"),
    PT_PT("pt", "PT", arrayOf("pt"), "assets/images/locales/pt_pt_flag_24px.png"),
    NL_NL("nl", "NL", arrayOf("nl"), "assets/images/locales/nl_nl_flag_24px.png"),
    UA_UA("ua", "UA", arrayOf("ua"), "assets/images/locales/ua_ua_flag_24px.png"),
    EN_PIRATE("en", "pirate", arrayOf("pi"), "assets/images/locales/en_pirate_flag_24px.png", fictional = true),
    ;

    override val prefix = "locale"

    companion object {
        fun findByIdOrNull(id: String): Locales? {
            val values = entries.toTypedArray()
            return values.firstOrNull { it.id == id }
                ?: values.firstOrNull { id in it.aliases }
        }

        fun getNonFictional(): List<Locales> {
            return entries.toTypedArray().filterNot { lang -> lang.fictional }
        }
    }
}

val Locales.fileName get() = "$languageCode-$countryCode.ftl"
