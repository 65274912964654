package data.users

import auth.ApiUserStore
import apiclient.users.PublicUserProfile
import dev.fritz2.core.RootStore
import koin.koinCtx
import kotlinx.coroutines.CoroutineName
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import model.User
import model.getFirstGroupIdOrNull
import services.GroupService

class UserListStore : RootStore<List<PublicUserProfile>?>(
    initialData = null,
    job = Job(),
) {

    private val apiUserStore: ApiUserStore by koinCtx.inject()
    private val groupService: GroupService by koinCtx.inject()

    val fetchGroupMembers = handle<User?> { current, user ->
        val currentUser = user ?: apiUserStore.current
        CoroutineScope(CoroutineName("fetch-users-for-userListStore")).launch {
            try {
                val resp =
                    currentUser.getFirstGroupIdOrNull()?.let { groupService.getMembersOfGroup(it) }

                resp?.fold({ memberList ->
                    update(memberList.mapNotNull { it.userProfile })
//                console.log("members",memberList.mapNotNull { it.userProfile })
                }, {
                    console.log("Fetching group members failed", it.message)
                })
            } catch (e: Exception) {
                console.log("API call to fetch group members failed", e.message)
            }

        }
        current
    }

    val updateUserProfile = handle<PublicUserProfile> { list, newProfile ->
        (list?.filter { it.userId != newProfile.userId } ?: emptyList()) + newProfile
    }

    fun getPublicUserProfile(userId: String?): PublicUserProfile? = userId?.let { id -> current?.firstOrNull { it.userId == id } }
}
