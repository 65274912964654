package workspacetools.buildingeditor

import apiclient.FormationClient
import apiclient.geoobjects.ObjectTags
import apiclient.geoobjects.ObjectType
import apiclient.geoobjects.SearchQueryContext
import apiclient.geoobjects.newContext
import apiclient.geoobjects.restSearch
import apiclient.tags.buildingId
import apiclient.tags.defaultFloorId
import apiclient.tags.floorLevel
import apiclient.tags.tag
import auth.CurrentWorkspaceStore
import dev.fritz2.core.RootStore
import dev.fritz2.core.storeOf
import koin.withKoin
import kotlinx.coroutines.Job
import model.Building
import model.Floor

class AllBuildingsStore : RootStore<List<Building>?>(listOf(), Job()) {
    val selectedBuildingStore = storeOf<Building?>(null, job)

    var fetching = storeOf(false, job)

    suspend fun fetchBuildings() {
        if(!fetching.current) {
            try {
                fetching.update(true)
                update(null)
                withKoin {
                    val client = get<FormationClient>()
                    val currentWorkspaceStore = get<CurrentWorkspaceStore>()
                    currentWorkspaceStore.current?.let { group ->
                        val results = client.restSearch(
                            SearchQueryContext.newContext(listOf(group.groupId)).copy(
                                objectTypes = listOf(ObjectType.Building, ObjectType.Floor),
                                excludeTags = listOfNotNull(
                                    ObjectTags.Deleted.tag(true),
                                    ObjectTags.Archived.tag(true),
                                ),
                                size = 1000
                            )
                        ).getOrThrow()

                        val buildings = results.hits.filter {
                            it.hit.objectType == ObjectType.Building
                        }
                        val floors = results.hits.filter {
                            it.hit.objectType == ObjectType.Floor && it.hit.tags.buildingId != null
                        }.groupBy {
                            it.hit.tags.buildingId
                        }

                        buildings.map { it.hit }.map { building ->
                            val buildingFloors = floors[building.id].orEmpty().map {
                                Floor(it.hit)
                            }.groupBy {
                                it.floor.tags.floorLevel ?: 0.0
                            }

                            Building(
                                buildingId = building.id,
                                buildingName = building.title,
                                defaultFloorLevel = null,
                                defaultFloorId = building.tags.defaultFloorId,
                                activeFloorLevel = null,
                                activeFloorIds = null,
                                floorData = buildingFloors.toMutableMap(),
                                geoObjectDetails = building,
                            )
                        }

                    }.orEmpty().let {
                        update(it)
                    }
                }
            } finally {
                fetching.update(false)
            }
        }
    }
}
