package map.views.workplacetools

import apiclient.FormationClient
import apiclient.geoobjects.keywordsForQueryContext
import dev.fritz2.core.RootStore
import koin.koinCtx
import kotlinx.coroutines.Job
import model.SuggestedTagsContext
import overlays.BusyStore
import services.SuggestedTagsContextStore

class SuggestedArchetypeTagsStore : RootStore<List<String>>(
    initialData = emptyList(),
    job = Job(),
) {
    private val formationClient: FormationClient by koinCtx.inject()
    private val busyStore by koinCtx.inject<BusyStore>()
    private val suggestedTagsContextStore: SuggestedTagsContextStore by koinCtx.inject()

    private val fetchSuggestedTags = handle<SuggestedTagsContext> { current, ctx ->
        // fetch archetype tags
        busyStore.handleApiCall(
            supplier = suspend {
                formationClient.keywordsForQueryContext(
                    searchQueryContext = ctx.ctx,
                    prefix = ctx.prefix,
                    numberOfKeywords = ctx.limit,
                )
            },
            processResult = { results ->
                console.log("Fetched Archetype tags:", results)
                update(results)
            },
        )
        current
    }

    init {
        suggestedTagsContextStore.data handledBy fetchSuggestedTags
    }
}
