package data.objects.views

import apiclient.geoobjects.ObjectTags
import apiclient.geoobjects.ObjectType
import apiclient.geoobjects.ObjectVisibility
import apiclient.tags.getTagValues
import apiclient.tags.getUniqueTag
import apiclient.validations.parseEnumValue
import auth.ApiUserStore
import data.objects.ActiveObjectStore
import data.objects.objecthistory.ObjectHistoryResultsStore
import dev.fritz2.components.stackUp
import dev.fritz2.core.RenderContext
import koin.koinCtx
import kotlinx.coroutines.flow.combine
import localization.TL
import localization.Translation
import mainmenu.RouterStore
import mainmenu.mainMenuButton
import model.isInternalAdminGroupAdminOrGroupOwner
import qrcode.ManualEnterStore
import qrcode.ScannedCodeStore
import qrcode.ScannedCodeTypeStore
import theme.FormationIcons
import theme.FormationUIIcons
import utils.getCase
import utils.getName
import webcomponents.baseLayout
import webcomponents.contentScrollBox
import webcomponents.oneButtonFooter
import workspacetools.usermanagement.confirm

fun RenderContext.cardSecondaryMenu() {

    val routerStore: RouterStore by koinCtx.inject()
    val translation: Translation by koinCtx.inject()
    val activeObjectStore: ActiveObjectStore by koinCtx.inject()
    val objectHistoryResultsStore: ObjectHistoryResultsStore by koinCtx.inject()
    val apiUserStore: ApiUserStore by koinCtx.inject()
    val scannedCodeStore: ScannedCodeStore by koinCtx.inject()
    val scannedCodeTypeStore: ScannedCodeTypeStore by koinCtx.inject()
    val manualEnterStore: ManualEnterStore by koinCtx.inject()

    val allowedToArchive = activeObjectStore.current.canModify || activeObjectStore.current.canManage
    val allowedToUnflag = apiUserStore.current.isInternalAdminGroupAdminOrGroupOwner()

    baseLayout(
        content = {
            activeObjectStore.data
                .combine(objectHistoryResultsStore.data) { t, h -> t to h }
                .render { (obj, history) ->
                    val objType = obj.objectType
                    val archived = obj.tags.getUniqueTag(ObjectTags.Archived).toBoolean()
                    val flagged = obj.tags.getUniqueTag(ObjectTags.Flagged).toBoolean()
                    val hasExternalId = obj.tags.getTagValues(ObjectTags.ExternalId).isNotEmpty()
                    val visibility = parseEnumValue<ObjectVisibility>(obj.tags.getUniqueTag(ObjectTags.Visibility))

                    contentScrollBox(margins = { top { normal } }) {
                        stackUp(
                            {
                                width { full }
                                height { maxContent }
                                justifyContent { center }
                                alignItems { stretch }
                            },
                        ) {
                            spacing { small }
                            items {
                                // Details
                                mainMenuButton(
                                    icon = { FormationIcons.Description.icon },
                                    titleLiteral = translation[TL.CardSecondaryMenu.DETAILS],
                                    subtitle = translation[
                                        TL.CardSecondaryMenu.SHOW_DETAILS,
                                        mapOf(
                                            "case" to objType.getCase(),
                                            "objectType" to objType.getName(),
                                        ),
                                    ],
                                    addOrReplaceRoute = mapOf("show" to "details"),
                                )
                                if (!apiUserStore.current.isAnonymous) {
                                    // Duplicate (only for points, tasks, meetings)
                                    when (objType) {
                                        ObjectType.POI, ObjectType.Task, ObjectType.Event -> {
                                            mainMenuButton(
                                                icon = { FormationUIIcons.Copy.icon },
                                                titleLiteral = translation[TL.CardSecondaryMenu.DUPLICATE],
                                                subtitle = translation[
                                                    TL.CardSecondaryMenu.DUPLICATE_THIS,
                                                    mapOf(
                                                        "case" to objType.getCase(),
                                                        "objectType" to objType.getName(),
                                                    ),
                                                ],
                                                clickHandlers = listOf(activeObjectStore.duplicate),
                                            )
                                        }

                                        else -> {}
                                    }

                                    if (hasExternalId) {
                                        when (objType) {
                                            ObjectType.ObjectMarker -> {
                                                // Release TrackedObject from QR code and archive object (only for tracked objects)
                                                mainMenuButton(
                                                    icon = { FormationIcons.QRCode.icon },
                                                    titleLiteral = translation[TL.CardSecondaryMenu.RELEASE_AND_ARCHIVE_CODE],
                                                    subtitle = translation[
                                                        TL.CardSecondaryMenu.RELEASE_AND_ARCHIVE_CODE_SUBTITLE,
                                                        mapOf(
                                                            "objectType" to obj.objectType.getName(),
                                                        ),
                                                    ],
                                                    clickHandlers = listOf(
                                                        confirm(
                                                            translation[
                                                                TL.CardSecondaryMenu.RELEASE_AND_ARCHIVE_CODE_ARE_YOU_SURE,
                                                                mapOf(
                                                                    "objectType" to obj.objectType.getName(),
                                                                ),
                                                            ],
                                                            listOf(activeObjectStore.releaseTrackedObject),
                                                        ),
                                                    ),
                                                )
                                            }
                                            // Remove QR code from object
                                            ObjectType.POI, ObjectType.Task, ObjectType.Event, ObjectType.Zone -> {
                                                mainMenuButton(
                                                    icon = { FormationIcons.QRCode.icon },
                                                    titleLiteral = translation[TL.CardSecondaryMenu.RELEASE_CODE],
                                                    subtitle = translation[
                                                        TL.CardSecondaryMenu.RELEASE_CODE_SUBTITLE,
                                                        mapOf(
                                                            "case" to objType.getCase(),
                                                            "objectType" to obj.objectType.getName(),
                                                        ),
                                                    ],
                                                    clickHandlers = listOf(
                                                        confirm(
                                                            translation[
                                                                TL.CardSecondaryMenu.RELEASE_CODE_ARE_YOU_SURE,
                                                                mapOf(
                                                                    "objectType" to obj.objectType.getName(),
                                                                ),
                                                            ],
                                                            listOf(activeObjectStore.releaseQRCodeFromObject),
                                                        ),
                                                    ),
                                                )
                                            }

                                            else -> {
                                                // don't show this button
                                            }
                                        }
                                    }
                                    // Connect object with QR code
                                    else {
                                        when (objType) {
                                            ObjectType.ObjectMarker, ObjectType.POI, ObjectType.Task, ObjectType.Event, ObjectType.Zone -> {
                                                mainMenuButton(
                                                    icon = { FormationIcons.QRCode.icon },
                                                    titleLiteral = translation[TL.CardSecondaryMenu.CONNECT_TO_QR_CODE],
                                                    subtitle = translation[
                                                        TL.CardSecondaryMenu.CONNECT_TO_QR_CODE_SUBTITLE,
                                                        mapOf(
                                                            "case" to objType.getCase(),
                                                            "objectType" to obj.objectType.getName(),
                                                        ),
                                                    ],
                                                    addOrReplaceRoute = mapOf("show" to "connectQR"),
                                                    clickHandlers = listOf(
                                                        scannedCodeStore.reset,
                                                        scannedCodeTypeStore.reset,
                                                        manualEnterStore.reset,
                                                    ),
                                                )
                                            }

                                            else -> {
                                                // don't show this button
                                            }
                                        }
                                    }
                                    // Share
//                            mainMenuButton(
//                                icon = { share },
//                                titleLiteral = translation[TL.CardSecondaryMenu.SHARE],
//                                subtitle = translation[TL.CardSecondaryMenu.SHARE_THIS, json("case" to objType.getCase(), "objectType" to objType.getName())],
//                                clickHandlers = listOf()
//                            )
                                    // Archive object
                                    if (!archived && allowedToArchive) {
                                        mainMenuButton(
                                            icon = { FormationIcons.Archive.icon },
                                            titleLiteral = translation[TL.CardSecondaryMenu.ARCHIVE, mapOf("objectType" to obj.objectType.getName())],
                                            subtitle = translation[TL.CardSecondaryMenu.ARCHIVE_SUBTITLE, mapOf("objectType" to obj.objectType.getName())],
                                            clickHandlers = listOf(
                                                confirm(
                                                    translation[
                                                        TL.CardSecondaryMenu.ARCHIVE_ARE_YOU_SURE,
                                                        mapOf(
                                                            "case" to objType.getCase(),
                                                            "objectType" to objType.getName(),
                                                        ),
                                                    ],
                                                    listOf(activeObjectStore.archiveObject),
                                                ),
                                            ),
                                        )
                                    }
                                    // Restore object
                                    if (archived && allowedToArchive) {
                                        mainMenuButton(
                                            icon = { FormationIcons.UnArchive.icon },
                                            titleLiteral = translation[TL.CardSecondaryMenu.UNARCHIVE],
                                            subtitle = translation[TL.CardSecondaryMenu.UNARCHIVE_SUBTITLE, mapOf("objectType" to obj.objectType.getName())],
                                            clickHandlers = listOf(
                                                confirm(
                                                    translation[
                                                        TL.CardSecondaryMenu.UNARCHIVE_ARE_YOU_SURE,
                                                        mapOf(
                                                            "case" to objType.getCase(),
                                                            "objectType" to objType.getName(),
                                                        ),
                                                    ],
                                                    listOf(activeObjectStore.unArchiveObject),
                                                ),
                                            ),
                                        )
                                    }
                                    when (visibility) {
                                        ObjectVisibility.MarkerHidden -> {
                                            // Show Marker on Map
                                            mainMenuButton(
                                                icon = { FormationUIIcons.Eye.icon },
                                                titleLiteral = translation[TL.CardSecondaryMenu.SHOW_MARKER, mapOf("objectType" to obj.objectType.getName())],
                                                subtitle = translation[TL.CardSecondaryMenu.SHOW_MARKER_SUBTITLE],
                                                clickHandlers = listOf(activeObjectStore.showObjectMarker),
                                            )
                                        }

                                        else -> {
                                            // Hide Marker on Map
                                            mainMenuButton(
                                                icon = { FormationUIIcons.EyeOff.icon },
                                                titleLiteral = translation[TL.CardSecondaryMenu.HIDE_MARKER, mapOf("objectType" to obj.objectType.getName())],
                                                subtitle = translation[TL.CardSecondaryMenu.HIDE_MARKER_SUBTITLE],
                                                clickHandlers = listOf(activeObjectStore.hideObjectMarker),
                                            )
                                        }
                                    }
                                }
                                // Flag object
                                if (!flagged && objType != ObjectType.Building) {
                                    mainMenuButton(
                                        icon = { FormationIcons.Caution.icon },
                                        titleLiteral = translation[TL.CardSecondaryMenu.FLAG, mapOf("objectType" to obj.objectType.getName())],
                                        subtitle = translation[TL.CardSecondaryMenu.FLAG_SUBTITLE],
                                        clickHandlers = listOf(
                                            confirm(
                                                translation[
                                                    TL.CardSecondaryMenu.FLAG_ARE_YOU_SURE,
                                                    mapOf(
                                                        "case" to objType.getCase(),
                                                        "objectType" to objType.getName(),
                                                    ),
                                                ],
                                                listOf(activeObjectStore.flagObject),
                                            ),
                                        ),
                                    )
                                }
                                // Unflag object
                                if (flagged && allowedToUnflag && objType != ObjectType.Building) {
                                    mainMenuButton(
                                        icon = { FormationIcons.Caution.icon },
                                        titleLiteral = translation[TL.CardSecondaryMenu.UN_FLAG, mapOf("objectType" to obj.objectType.getName())],
                                        subtitle = translation[TL.CardSecondaryMenu.UN_FLAG_SUBTITLE],
                                        clickHandlers = listOf(
                                            confirm(
                                                translation[
                                                    TL.CardSecondaryMenu.UN_FLAG_ARE_YOU_SURE,
                                                    mapOf(
                                                        "case" to objType.getCase(),
                                                        "objectType" to objType.getName(),
                                                    ),
                                                ],
                                                listOf(activeObjectStore.unflagObject),
                                            ),
                                        ),
                                    )
                                }
                                // Object history
                                if (!history[activeObjectStore.current.id]?.results.isNullOrEmpty()) {
                                    mainMenuButton(
                                        icon = { FormationIcons.History.icon },
                                        titleLiteral = translation[TL.CardSecondaryMenu.OBJECT_HISTORY, mapOf("objectType" to obj.objectType.getName())],
                                        subtitle = translation[
                                            TL.CardSecondaryMenu.OBJECT_HISTORY_SUBTITLE,
                                            mapOf(
                                                "case" to objType.getCase(),
                                                "objectType" to objType.getName(),
                                            ),
                                        ],
                                        addOrReplaceRoute = mapOf("show" to "objectHistory"),
                                    )
                                }
                            }
                        }
                    }
                }
        },
        footer = {
            // Close
            oneButtonFooter(
                title = translation[TL.General.CLOSE],
                value = Unit,
                clickHandlers = listOf(routerStore.back),
            )
        },
    )
}
