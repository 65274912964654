package model

import apiclient.geoobjects.GeoObjectDetails
import apiclient.markers.Marker

data class LiveData(
    val renderData: Map<String, GeoObjectDetails> = emptyMap(),
    val websocketData: Map<String, Marker> = emptyMap(),
    val searchData: Map<String, GeoObjectDetails> = emptyMap()
)
