package maplibreGL

import apiclient.util.withDuration
import kotlin.time.measureTimedValue

 data class Cluster<T: Clusterable, P>(
    val pointCount: Int,
    val features: List<T>,
    val properties: P,
    val center: LngLat,
) {
     fun key(): Int {
         var result = pointCount
         result = 31 * result + features.map { it.key() }.hashCode()
         result = 31 * result + center.toArray().toList().hashCode()
         return result
     }
 }

fun <T: Clusterable, P> clusterDbScan(
    input: List<T>,
    minPointsPerCluster: Int = 2,
    neighborhoodRadius: Double,
    coordinateConverter: (feature: T) -> Array<Double>,
    distanceFunction: (p: Array<Double>, q: Array<Double>) -> Double,
    getLngLat: (feature: T) -> LngLat,
    getLabel: (feature: T) -> String,
    propertiesGenerator: (clusterIndex: Int, featuresIndices: List<T>) -> P
): Pair<List<Cluster<T, P>>, List<T>> {
    try {
        val dbscan = DBSCAN()
        val clusterMap = mutableMapOf<Int, Int>()

        val dataset = input.map {
            coordinateConverter(it)
        }.toTypedArray()
//        console.log("dataset", dataset)
        measureTimedValue {
            dbscan.run(
                dataset = dataset,
                neighborhoodRadius = neighborhoodRadius,
                minPointsPerCluster = minPointsPerCluster,
                distanceFunction = distanceFunction
            )
        }.withDuration {
//            console.log("clustered", dataset.size, "elements in", duration.toString())
        }.forEachIndexed { clusterIndex, featureIndices ->
            featureIndices.forEach {
                clusterMap[it] = clusterIndex
            }
        }
        val clusters = clusterMap
            .entries
            .groupBy { (featureId, clusterId) -> clusterId }
            .map { (clusterId, entries) ->
                val featureIndices = entries.map { (featureIndex, clusterId) -> featureIndex }

                val features = featureIndices.map { featureIndex ->
                    input[featureIndex]
                }

                val coordinates = features.map { feature ->
                    getLngLat(feature)
                }

                val center = LngLat(
                    lng = coordinates.map { it.lng }.average(),
                    lat = coordinates.map { it.lat }.average(),
                )

                Cluster(
                    pointCount = featureIndices.size,
                    features = features,
                    properties = propertiesGenerator(clusterId, features),
                    center = center,
                )
            }

        val unclustered = dbscan.noise.map {
            input[it]
        }

        return clusters to unclustered
    } catch (e: Exception) {
        console.error("clustering borked", e)
        throw e
    }
}
