package data.objects.views.objectrouting

import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.LatLon
import apiclient.geoobjects.ObjectTags
import apiclient.tags.getUniqueTag
import apiclient.users.UserFeatureFlag
import auth.Features
import data.objects.ActiveObjectStore
import data.objects.building.ActiveBuildingStore
import data.objects.building.ActiveFloorLevelStore
import data.objects.building.CurrentBuildingsStore
import data.objects.building.getBuilding
import data.objects.building.getFloorLevel
import dev.fritz2.core.RenderContext
import dev.fritz2.core.Store
import dev.fritz2.core.href
import dev.fritz2.core.invoke
import dev.fritz2.core.storeOf
import dev.fritz2.core.target
import koin.koinCtx
import koin.withKoin
import kotlin.math.max
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.launch
import location.GeoPositionStore
import location.geolocation.latLon
import maplibreGL.MaplibreMap
import maplibreGL.toLatLon
import objectrouting.FloorChange
import objectrouting.LastKnownPositionStore
import objectrouting.NavigationPointSource
import objectrouting.NavigationToolToggleStore
import objectrouting.PositionType
import objectrouting.RoutePoint
import point2pointnavigation.RoutingService
import services.GeoPositionService
import theme.FormationIcons
import theme.FormationUIIcons
import twcomponents.doIfUserFeatureFlagEnabled
import twcomponents.twColOf
import twcomponents.twColOfNoGap
import twcomponents.twFeatureFlagDiv
import twcomponents.twFlatBoxColStart
import twcomponents.twFlatBoxRowCenter
import twcomponents.twFlatIconBox
import twcomponents.twFlatIconButton
import twcomponents.twIconMedium
import twcomponents.twObjectSelector
import twcomponents.twRowOfNoGap
import twcomponents.twRowOfWrap
import utils.ToggleStore
import utils.getIcon
import utils.getName
import utils.isIos
import webcomponents.Position


fun RenderContext.objectRouting() {
    twFeatureFlagDiv(flag = Features.EnableRouteToObject) {
        twColOfNoGap {
            className("w-full")
            withKoin {
                val activeObjectStore: ActiveObjectStore = koinCtx.get()
                val maplibreMap: MaplibreMap = koinCtx.get()
                val routingMenuToggle = ToggleStore()
                val objectSelectorToggle = ToggleStore()
                val selectedObjectStore = storeOf<GeoObjectDetails?>(null)
                val selectedNavigationPointSourceStore = storeOf<NavigationPointSource?>(null)
                val selectedNavigationOriginCoordinatesStore = storeOf<LatLon?>(null)
                val geoPositionService: GeoPositionService = koinCtx.get()
                val lastKnownPositionStore: LastKnownPositionStore = koinCtx.get()
                val currentBuildingsStore: CurrentBuildingsStore = koinCtx.get()
                val activeFloorLevelStore: ActiveFloorLevelStore = koinCtx.get()

                activeObjectStore.data.render { destinationObject ->

                    // Temporary
                    val navigationToolToggleStore: NavigationToolToggleStore = koinCtx.get()

                    routingMenuToggle.data handledBy navigationToolToggleStore.update

                    twRowOfNoGap {
                        twFlatIconBox {
                            className(
                                routingMenuToggle.data.map { enabled ->
                                    if (enabled) "bg-sky-100" else "bg-gray-100"
                                },
                            )
                            twIconMedium(FormationUIIcons.Routing.icon)
                        }
                        twFlatBoxRowCenter {
                            className(
                                routingMenuToggle.data.map { enabled ->
                                    if (enabled) "bg-sky-100" else "bg-gray-100"
                                },
                            )
                            p("text-sm truncate") {
                                +"Navigation to this ${destinationObject.objectType.getName()}"
                            }
                        }
                        routingMenuToggle.data.render { navEnabled ->
                            twFlatIconButton(
                                icon = if (navEnabled) {
                                    FormationUIIcons.Close.icon
                                } else FormationUIIcons.ArrowDown.icon,
                            ) {
                                className(if (navEnabled) "text-white hover:text-white bg-sky-600 hover:bg-sky-500 border-sky-600 hover:border-sky-500" else "text-slate-500 hover:text-slate-700 bg-gray-200 hover:bg-gray-300 border-gray-200 hover:border-gray-300")
                                p("text-xs truncate") {
                                    routingMenuToggle.data.render { navEnabled ->
                                        if (navEnabled) {
                                            +"Close"
                                        } else {
                                            +"Open"
                                        }
                                    }
                                }
                                clicks handledBy {
                                    selectedNavigationOriginCoordinatesStore.update(null)
                                    selectedNavigationPointSourceStore.update(null)
                                    maplibreMap.showRoutingPath(listOf())
                                    routingMenuToggle.toggle()
                                }
                            }
                        }
                    }

                    routingMenuToggle.data.render { showNavSettings ->
                        if (showNavSettings) {
                            // check last known position store here and update selectedNavigationOriginStore with it
                            lastKnownPositionStore.current?.latLon?.let { lastPosition ->
                                selectedNavigationPointSourceStore.update(NavigationPointSource.LastKnownLocation)
                                selectedNavigationOriginCoordinatesStore.update(lastPosition)
                            }

                            twFlatBoxColStart {
                                className("px-2 bg-sky-100")
                                span("text-sm py-2") {
                                    +"From: "
                                }
                                selectedNavigationPointSourceStore.data.render { navOrigin ->
                                    navOrigin?.let {
                                        twFlatIconButton(icon = FormationUIIcons.Select.icon) {
                                            className("mb-2 text-white hover:text-white bg-sky-600 hover:bg-sky-500 border-sky-600 hover:border-sky-500")
                                            twIconMedium(
                                                icon = when (navOrigin) {
                                                    NavigationPointSource.LastKnownLocation -> {
                                                        when (lastKnownPositionStore.current?.type) {
                                                            PositionType.GPS -> FormationIcons.Position.icon
                                                            PositionType.ScannedObject -> FormationIcons.QRCode.icon
                                                            null -> navOrigin.icon
                                                        }
                                                    }

                                                    NavigationPointSource.OtherObject -> {
                                                        selectedObjectStore.current?.iconCategory?.getIcon()?.icon
                                                            ?: navOrigin.icon
                                                    }

                                                    else -> navOrigin.icon
                                                },
                                            )
                                            p("text-xs truncate") {
                                                +when (navOrigin) {
                                                    NavigationPointSource.OtherObject -> selectedObjectStore.current?.title
                                                        ?: ""

                                                    else -> navOrigin.name
                                                }
                                            }
                                            clicks handledBy {
                                                selectedNavigationPointSourceStore.update(null)
                                            }
                                        }
                                    }
                                }

                                selectedNavigationPointSourceStore.data.render { navOrigin ->
                                    if (navOrigin == null) {
                                        twColOf {
                                            className("w-full")
                                            // select origin: last known location, GPS, Map center or other object
                                            twRowOfWrap {
                                                className("mb-2")
                                                NavigationPointSource.entries.forEach { entry ->
                                                    twFlatIconButton(
                                                        icon = when (entry) {
                                                            NavigationPointSource.LastKnownLocation -> {
                                                                when (lastKnownPositionStore.current?.type) {
                                                                    PositionType.GPS -> FormationIcons.Position.icon
                                                                    PositionType.ScannedObject -> FormationIcons.QRCode.icon
                                                                    null -> entry.icon
                                                                }
                                                            }

                                                            else -> entry.icon
                                                        },
                                                        iconPosition = Position.Left,
                                                        disabled = when (entry) {
                                                            NavigationPointSource.LastKnownLocation -> {
                                                                lastKnownPositionStore.data.map { it?.latLon == null }
                                                            }

                                                            else -> flowOf(false)
                                                        },
                                                    ) {
                                                        p("text-xs truncate") {
                                                            +entry.name
                                                        }
                                                        clicks handledBy {
                                                            when (entry) {
                                                                NavigationPointSource.LastKnownLocation -> {
                                                                    if (lastKnownPositionStore.current != null) {
                                                                        selectedNavigationPointSourceStore.update(entry)
                                                                    }
                                                                }

                                                                NavigationPointSource.YourLocation -> {
                                                                    geoPositionService.getActiveWatchIdOrNew()
                                                                    selectedNavigationPointSourceStore.update(entry)
                                                                }

                                                                NavigationPointSource.OtherObject -> {
                                                                    selectedObjectStore.update(null)
                                                                    objectSelectorToggle.toggle()
                                                                    coroutineScope {
                                                                        launch {
                                                                            selectedObjectStore.data.handledBy { obj ->
                                                                                obj?.let {
                                                                                    selectedNavigationPointSourceStore.update(
                                                                                        entry,
                                                                                    )
                                                                                    objectSelectorToggle.toggle()
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }

                                                                else -> {
                                                                    selectedNavigationPointSourceStore.update(entry)
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            objectSelectorToggle.data.render { open ->
                                                if (open) {
                                                    twColOf {
                                                        className("w-full mb-2")
                                                        twObjectSelector(selectedObjectStore)
                                                    }
                                                } else {
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            twRowOfNoGap {
                                twFlatIconButton(icon = FormationIcons.Position.icon) {
                                    className("grow")
                                    p("text-sm truncate select-none") {
                                        +"Go to Start"
                                    }
                                    clicks handledBy {

                                        val latLon = selectedNavigationOriginCoordinatesStore.current

                                        val floorId = getFloorIdForNavigationPointSource(
                                            navigationPointSource = selectedNavigationPointSourceStore.current,
                                            latLon = latLon,
                                            selectedObjectStore = selectedObjectStore,
                                        )

                                        // switch floor to known level of starting point
                                        getLevelFromFloorId(floorId)?.let { floorLevel ->
                                            activeFloorLevelStore.update(floorLevel)
                                        }

                                        latLon?.let {
                                            delay(500) // give the map a little time to trigger a search
                                            maplibreMap.easeTo(
                                                center = it,
                                                zoom = max(maplibreMap.getZoom(), 19.0),
                                                duration = 1000.0,
                                            )
                                        }
                                    }
                                }

                                twFlatIconButton(icon = FormationUIIcons.NavigationArrow.icon) {

                                    className("grow text-white hover:text-white bg-sky-600 hover:bg-sky-500 border-sky-600 hover:border-sky-500")

                                    p("text-sm truncate select-none") {
                                        +"Show the Route"
                                    }

                                    clicks handledBy {

                                        val latLon: LatLon? = getLatestLatLon(
                                            navigationPointSource = selectedNavigationPointSourceStore.current,
                                            selectedObjectStore = selectedObjectStore,
                                        )

                                        val startFloorId: String? = getFloorIdForNavigationPointSource(
                                            navigationPointSource = selectedNavigationPointSourceStore.current,
                                            latLon = latLon,
                                            selectedObjectStore = selectedObjectStore,
                                        )

                                        val targetFloorId = destinationObject.tags.getUniqueTag(ObjectTags.ConnectedTo)

                                        // switch floor to known level of starting point
                                        getLevelFromFloorId(startFloorId)?.let { floorLevel ->
                                            activeFloorLevelStore.update(floorLevel)
                                        }

                                        // update coordinates store
                                        selectedNavigationOriginCoordinatesStore.update(latLon)

                                        // calculate new route points and display navigation path on the map
                                        latLon?.let { from ->
                                            RoutingService.navigate(
                                                from = from,
                                                to = destinationObject.latLon,
                                                startFloorId = startFloorId,
                                                targetFloorId = targetFloorId,
                                            )
                                        }?.let { routingPoints ->
                                            val listOfRoutePoints = routingPoints.mapIndexed { index, routePoint ->
                                                val floorId = routePoint.tags.getUniqueTag(ObjectTags.ConnectedTo)
                                                val floorLevel = floorId?.let {
                                                    currentBuildingsStore.current.getBuilding(it)?.getFloorLevel(it)
                                                }
                                                val previousFloorId = if (index > 0) {
                                                    routingPoints[index - 1].tags.getUniqueTag(ObjectTags.ConnectedTo)
                                                } else null
                                                val previousLevel = previousFloorId?.let {
                                                    currentBuildingsStore.current.getBuilding(it)?.getFloorLevel(it)
                                                }
                                                val nextFloorId = if (index < routingPoints.size - 1) {
                                                    routingPoints[index + 1].tags.getUniqueTag(ObjectTags.ConnectedTo)
                                                } else null
                                                val nextLevel = nextFloorId?.let {
                                                    currentBuildingsStore.current.getBuilding(it)?.getFloorLevel(it)
                                                }
                                                val isLevelDown = previousFloorId?.let { previous -> floorId?.let { pointFloorId -> previous != pointFloorId } }
                                                    ?: false
                                                val isLevelUp = nextFloorId?.let { next -> floorId?.let { pointFloorId -> next != pointFloorId } }
                                                    ?: false

                                                RoutePoint(
                                                    lat = routePoint.latLon.lat,
                                                    lon = routePoint.latLon.lon,
                                                    floorId = floorId,
                                                    floorLevel = floorLevel,
                                                    isFloorChange = isLevelUp || isLevelDown,
                                                    floorChange = floorLevel?.let {
                                                        if (isLevelUp) {
                                                            nextLevel?.let {
                                                                FloorChange(
                                                                    previousLevel = floorLevel,
                                                                    newLevel = nextLevel,
                                                                )
                                                            }
                                                        } else if (isLevelDown) {
                                                            previousLevel?.let {
                                                                FloorChange(
                                                                    previousLevel = previousLevel,
                                                                    newLevel = floorLevel,
                                                                )
                                                            }
                                                        } else null
                                                    },
                                                )
                                            }

                                            maplibreMap.showRoutingPath(
                                                routePoints =
                                                listOfNotNull(
                                                    RoutePoint(
                                                        lat = latLon.lat,
                                                        lon = latLon.lon,
                                                        floorId = startFloorId,
                                                    ),
                                                ) + listOfRoutePoints + listOfNotNull(
                                                    RoutePoint(
                                                        lat = destinationObject.latLon.lat,
                                                        lon = destinationObject.latLon.lon,
                                                        floorId = targetFloorId,
                                                    ),
                                                ),
                                            )
                                        }
                                    }
                                }
                            }

                            doIfUserFeatureFlagEnabled(UserFeatureFlag.EnableDevelopmentFeatures) {
                                twFlatBoxColStart {
                                    className("bg-sky-100 py-2")
                                    a("w-full") {
                                        twFlatIconButton(icon = if (isIos()) FormationIcons.Apple.icon else FormationIcons.Map.icon) {
                                            className("grow")
                                            p("text-sm truncate") {
                                                +"Open with ${if (isIos()) "Apple Maps" else "Google Maps"}"
                                            }
                                        }
                                        val mapsLink = if (isIos()) {
                                            "maps://?daddr=${destinationObject.latLon.lat},${destinationObject.latLon.lon}&dirflg=d"
                                        } else {
                                            "https://www.google.com/maps/dir/?api=1&destination=${destinationObject.latLon.lat},${destinationObject.latLon.lon}&travelmode=driving"
                                        }
                                        target("_blank")
                                        href(mapsLink)
                                    }
                                }
//                            div {
//                                +"Open with "
//                                a("text-blue-600 hover:underline") {
//                                    val mapsLink = if(isIos()) {
//                                        +"Apple Maps"
//                                        "maps://?daddr=${destinationObject.latLon.lat},${destinationObject.latLon.lon}&dirflg=d"
//                                    } else {
//                                        +"Google Maps"
//                                        "https://www.google.com/maps/dir/?api=1&destination=${destinationObject.latLon.lat},${destinationObject.latLon.lon}&travelmode=driving"
//                                    }
//                                    target("_blank")
//                                    href(mapsLink)
//                                }
//                            }
                            }
                        }
                    }
                }
            }
        }
    }
}

fun getLatestLatLon(navigationPointSource: NavigationPointSource?, selectedObjectStore: Store<GeoObjectDetails?>): LatLon? {
    val maplibreMap: MaplibreMap = koinCtx.get()
    val geoPositionStore: GeoPositionStore = koinCtx.get()
    val lastKnownPositionStore: LastKnownPositionStore = koinCtx.get()

    // gather latest coordinates for new route
    return when (navigationPointSource) {
        NavigationPointSource.LastKnownLocation -> lastKnownPositionStore.current?.latLon
        NavigationPointSource.YourLocation -> geoPositionStore.current?.latLon()
        NavigationPointSource.MapCenter -> maplibreMap.getCenter().toLatLon()
        NavigationPointSource.OtherObject -> selectedObjectStore.current?.latLon
        null -> null
    }
}

fun getFloorIdForNavigationPointSource(navigationPointSource: NavigationPointSource?, latLon: LatLon?, selectedObjectStore: Store<GeoObjectDetails?>): String? {
    val lastKnownPositionStore: LastKnownPositionStore = koinCtx.get()
    val currentBuildingsStore: CurrentBuildingsStore = koinCtx.get()

    return when (navigationPointSource) {
        NavigationPointSource.LastKnownLocation -> {
            when (lastKnownPositionStore.current?.type) {
                PositionType.ScannedObject -> {
                    lastKnownPositionStore.current?.geoObjectDetails?.tags
                        ?.getUniqueTag(ObjectTags.ConnectedTo)
                }

                else -> {
                    currentBuildingsStore.pointWithinFloor(latLon)
                }
            }
        }

        NavigationPointSource.YourLocation -> currentBuildingsStore.pointWithinFloor(latLon)
        NavigationPointSource.MapCenter -> currentBuildingsStore.pointWithinFloor(latLon)
        NavigationPointSource.OtherObject -> selectedObjectStore.current?.tags?.getUniqueTag(ObjectTags.ConnectedTo)
        null -> null
    }
}

/**
 * Get the floorLevel for the given floorId using level of current selected
 * floor or the building default level as fallback (e.g. when origin is MapCenter or GPS)
 */

fun getLevelFromFloorId(floorId: String? = null): Double? {

    val currentBuildingsStore: CurrentBuildingsStore by koinCtx.inject()
    val activeBuildingsStore: ActiveBuildingStore by koinCtx.inject()

    return (floorId?.let {
        currentBuildingsStore.current.getBuilding(floorId)
    } ?: currentBuildingsStore.current[activeBuildingsStore.current]
        )?.let { building ->
            (building.getFloorLevel(floorId)
                ?: building.floorData?.keys?.filter { it >= 0 }?.min()
                ?: building.defaultFloorLevel)
        }
}
