package dev.fritz2.components.foundations

import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.emptyFlow
import kotlinx.coroutines.flow.flowOf

/**
 * Generic container for modeling a property for a component class that could be either consist on a value or on a
 * [Flow] of a value.
 *
 * Use it like this:
 * ```
 * open class SomeComponent {
 *      val myProp = DynamicComponentProperty("some text")
 *      val myBooleanProp = DynamicComponentProperty(false)
 * }
 * // within your UI declaration and static values:
 * someComponent {
 *      myProp("Some specific content")
 *      myBooleanProp(true)
 * }
 * // within your UI declaration and dynamic values:
 * val contentStore = storeOf("Empty")
 * val toggle = storeOf(false)
 * someComponent {
 *      myProp(contentStore.data)
 *      myBooleanProp(toggle.data)
 * }
 * ```
 */
class DynamicComponentProperty<T>(var values: Flow<T> = emptyFlow()) {
    operator fun invoke(newValue: T) {
        values = flowOf(newValue)
    }

    operator fun invoke(newValues: Flow<T>) {
        values = newValues
    }
}
