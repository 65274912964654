package webcomponents

import dev.fritz2.components.compat.button
import dev.fritz2.components.compat.div
import dev.fritz2.components.flexBox
import dev.fritz2.core.HtmlTag
import dev.fritz2.core.RenderContext
import dev.fritz2.core.SimpleHandler
import dev.fritz2.core.Store
import dev.fritz2.styling.params.ScaledValueProperty
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.map
import org.w3c.dom.HTMLDivElement
import theme.FormationColors
import theme.FormationDefault.Companion.formationStyles

enum class Position { Left, Right }

fun RenderContext.switchBox(
    radius: ScaledValueProperty? = null,
    position: Position = Position.Right,
    store: Store<Boolean>? = null,
    clickHandlers: List<SimpleHandler<Unit>>? = null,
    content: (HtmlTag<HTMLDivElement>.() -> Unit)? = null,
) {
    if (store != null) {
        button(
            {
                width { full }
            },
        ) {
            store.data.render { switchState ->
                flexBox(
                    {
                        direction { row }
                        width { full }
                        height { auto }  //At some point define a size for all the switch boxes
                        justifyContent { spaceBetween }
                        alignItems { center }
                        radius(radius ?: formationStyles.inputRadius)
                        fontSize { small }
                        textAlign { left }
                        if (!switchState) color { FormationColors.GrayDisabled.color }
                    },
                ) {

                    if (position == Position.Right) content?.invoke(this)

                    // SWITCH
                    div(
                        {
                            margins {
                                when (position) {
                                    Position.Left -> right { tiny }
                                    Position.Right -> left { tiny }
                                }
                            }
                        },
                    ) {
                        genericSwitch(switchState = store.data)
                    }

                    if (position == Position.Left) content?.invoke(this)
                }
            }
            clicks.map { !store.current } handledBy store.update
            clickHandlers?.forEach { handler ->
                clicks handledBy handler
            }
        }
    }
}

fun <T> RenderContext.switchBoxWithFlow(
    radius: ScaledValueProperty? = null,
    position: Position = Position.Right,
    valueFlow: Flow<Boolean>? = null,
    clickValueFlow: Flow<T>,
    valueUpdateHandler: SimpleHandler<T>,
    clickHandlers: List<SimpleHandler<Unit>>? = null,
    content: (HtmlTag<HTMLDivElement>.() -> Unit)? = null,
) {
    valueFlow?.let { nonNullableValueFlow ->
        combine(clickValueFlow, nonNullableValueFlow) { c, v -> Pair(c, v) }.render { (clickValue, switchState) ->
            button(
                {
                    width { full }
                },
            ) {
                flexBox(
                    {
                        direction { row }
                        width { full }
                        height { auto }  //At some point define a size for all the switch boxes
                        justifyContent { spaceBetween }
                        alignItems { center }
                        radius(radius ?: formationStyles.inputRadius)
                        fontSize { small }
                        textAlign { left }
                        if (!switchState) color { FormationColors.GrayDisabled.color }
                    },
                ) {

                    if (position == Position.Right) content?.invoke(this)

                    // SWITCH
                    div(
                        {
                            margins {
                                when (position) {
                                    Position.Left -> right { tiny }
                                    Position.Right -> left { tiny }
                                }
                            }
                        },
                    ) {
                        genericSwitch(switchState = valueFlow)
                    }

                    if (position == Position.Left) content?.invoke(this)
                }
            }
            clicks.map { clickValue } handledBy valueUpdateHandler
            clickHandlers?.forEach { handler ->
                clicks handledBy handler
            }
        }
    }
}
