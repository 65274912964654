// generated from build.gradle.kts, don't modify
package generated

interface IServerConfig {
    val ssl: Boolean
    val host: String
    val port: Int
    val buildTimestamp: String
    val apiVersion: String
    val developmentMode: Boolean
    val mapTilerKey: String
}

val ServerConfig = object : IServerConfig {
    override val ssl = true
    override val host = "api.tryformation.com"
    override val port = 443
    override val buildTimestamp="2024-11-28"
    override val apiVersion="0.1.89"
    override val developmentMode=false
    override val mapTilerKey = "JVdYkhNXPAbL0vrP2Ble"
}

const val OFFLINE_MODE = false
