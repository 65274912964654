package data.objects.views.attachments

import dev.fritz2.components.compat.button
import dev.fritz2.components.compat.img
import dev.fritz2.components.compat.input
import dev.fritz2.components.compat.span
import dev.fritz2.components.file
import dev.fritz2.components.flexBox
import dev.fritz2.components.icon
import dev.fritz2.components.lineUp
import dev.fritz2.core.HtmlTag
import dev.fritz2.core.RenderContext
import dev.fritz2.core.accept
import dev.fritz2.core.files
import dev.fritz2.core.id
import dev.fritz2.core.placeholder
import dev.fritz2.core.src
import dev.fritz2.core.type
import dev.fritz2.core.values
import dev.fritz2.styling.theme.IconDefinition
import dev.fritz2.styling.theme.Icons
import koin.koinCtx
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import localization.TL
import localization.Translation
import mainmenu.RouterStore
import model.ImageFileData
import model.title
import org.w3c.dom.HTMLDivElement
import org.w3c.files.get
import styling.primaryButtonStyleParams
import styling.secondaryButtonStyleParams
import theme.FormationColors
import theme.FormationDefault.Companion.formationStyles
import theme.FormationIcons
import theme.FormationUIIcons
import utils.isMobileOrTabletBrowser
import utils.roundTo
import webcomponents.baseLayout
import webcomponents.cardTitle
import webcomponents.contentScrollBox
import webcomponents.ellipseText
import webcomponents.expandState
import webcomponents.genericButton
import webcomponents.genericInput
import webcomponents.inputLabelWrapper
import webcomponents.twoButtonFooter

fun RenderContext.cardManageImageAttachment() {

    val routerStore: RouterStore by koinCtx.inject()
    val translation: Translation by koinCtx.inject()
    val preAttachmentsStoreStore: PreAttachmentsStore by koinCtx.inject()
    val fileHandlerStore: FileHandlerStore by koinCtx.inject()
    val imageFileDataStore by koinCtx.inject<ImageFileDataStore>()
    val fileStoreFritz2 by koinCtx.inject<FileStoreFritz2>()
    val fileStoreJS by koinCtx.inject<FileStoreJS>()
    val title = imageFileDataStore.map(ImageFileData.title())
    title.data.map { console.log(it) }

    fileHandlerStore.initialize()

    val header: RenderContext.() -> HtmlTag<HTMLDivElement> = {
        flexBox(
            {
                direction { column }
                width { full }
                justifyContent { flexStart }
                alignItems { stretch }
                overflowX { auto }
                margins { vertical { small } }
            },
        ) {
            // TAKE PHOTO BUTTON FOR MOBILE BROWSER ONLY
            if (isMobileOrTabletBrowser()) {
                button(
                    {
                        height(formationStyles.buttonHeight)
                        radius(formationStyles.buttonRadius)
                        primaryButtonStyleParams()
                        position { relative { } }
                        overflow { hidden }
                    },
                ) {
                    icon(
                        {
                            margins { right { smaller } }
                        },
                    ) { fromTheme { FormationIcons.CameraPlus.icon } }
                    span(
                        {
                            css("cursor: pointer;")
                        },
                    ) { translation[TL.Attachments.TAKE_A_PHOTO].renderText(into = this) }
                    input(
                        {
                            position {
                                absolute {
                                    top { "0" }
                                    right { "0" }
                                    left { "0" }
                                    bottom { "0" }
                                }
                            }
                            paddings { left { "100%" } }
                            margin { "0" }
                            opacity { "0" }
                            css("filter: alpha(opacity=0);")
                            css("cursor: pointer;")
                        },
                    ) {
                        type("file")
                        accept("image/png, image/gif, image/jpeg")
                        attr("capture", "environment")
                        changes.files().map { files ->
                            files?.get(0)?.let { file ->
                                console.log("image picked:", file)
                                file
                            }
                        } handledBy fileStoreJS.update
                    }
                }
            } else {
                // TAKE PHOTO BUTTON FOR DESKTOP
                genericButton(
                    title = translation[TL.Attachments.TAKE_A_PHOTO],
                    icon = { FormationIcons.CameraPlus.icon },
                    width = { auto },
                    styleFlow = flowOf(primaryButtonStyleParams),
                    styleType = { primary },
                    addOrReplaceRoute = mapOf("change" to "takePhoto"),
                    value = Unit,
                )
            }

            // UPLOAD IMAGE BUTTON
            file {
                button(
                    {
                        primaryButtonStyleParams()
                        width { full }
                    },
                ) {
                    icon { FormationIcons.Upload.icon }
                    text(translation[TL.Attachments.UPLOAD_IMAGE])
                }
                accept("image/png, image/gif, image/jpeg")
            }.map { file ->
                console.log("image picked:", file)
                file
            } handledBy fileStoreFritz2.update
        }
    }

    baseLayout(
        header = {
            cardTitle(translation[TL.Attachments.ADD_IMAGE]) { FormationUIIcons.Image.icon }
            expandState.render { expanded ->
                if (expanded == true) header()
            }
        },
        content = {
            contentScrollBox {
                expandState.render { expanded ->
                    if (expanded != true) header()
                }
                div("flex w-full mb-3") {
                    inputLabelWrapper(
                        title = translation[TL.Attachments.IMAGE_TITLE_PLACEHOLDER],
                        visibilityFlow = title.data.map { it.isNotBlank() },
                    ) {
                        genericInput(
                            value = title.data,
                        ) {
                            id("attachedImageTitleInput")
                            placeholder(translation[TL.Attachments.IMAGE_TITLE_PLACEHOLDER])
                            inputs.values() handledBy title.update
                        }
                    }
//                    focusInputObserver({ js("document.getElementById('attachedImageTitleInput').focus()") }, domNode)
                }
                imageFileDataStore.data.render { fileData ->
                    console.log("FileData:", fileData.title)
                    fileStoreFritz2.data.combine(fileStoreJS.data) { f, j -> Pair(f, j) }
                        .render { (fileFritz2, fileJS) ->
                            if (fileFritz2 != null || fileJS != null) {
                                val fileName = fileFritz2?.name ?: fileJS?.name ?: ""
                                val fileSize = fileFritz2?.size ?: fileJS?.size ?: 0
                                val fileType = fileFritz2?.type ?: fileJS?.type ?: ""

                                val size = fileSize.toDouble() / 1024 / 1024
                                if (fileData.href != null) {
                                    img(
                                        {
                                            maxWidth(formationStyles.cardWidth)
                                            border {
                                                color { primary.main }
                                                width(formationStyles.borderWidth)
                                            }
                                            radius { larger }
                                        },
                                    ) {
                                        src(fileData.href)
                                    }
                                    flexBox(
                                        {
                                            maxWidth(sm = { full }, md = formationStyles.cardWidth)
                                            wrap { wrap }
                                            alignItems { center }
                                            justifyContent { flexStart }
                                        },
                                    ) {
                                        imagePrevData(value = fileName)
                                        imagePrevData(value = fileType.removePrefix("image/"))
                                        imagePrevData(value = "${fileData.width} x ${fileData.height} px")
                                        imagePrevData(
                                            value = if (size < 1.0) "${(size * 1024).roundTo(0)} KB" else "${
                                                size.roundTo(
                                                    2,
                                                )
                                            } MB",
                                        )
                                    }
                                }
                            } else if (fileData.href != null) {
                                val fileSize = (fileData.prevBytes?.size?.toDouble() ?: 0.0) / 1024 / 1024
                                img(
                                    {
                                        maxWidth(formationStyles.cardWidth)
                                        border {
                                            color { primary.main }
                                            width(formationStyles.borderWidth)
                                        }
                                        radius { larger }
                                    },
                                ) {
                                    src(fileData.href)
                                }
                                flexBox(
                                    {
                                        maxWidth(sm = { full }, md = formationStyles.cardWidth)
                                        wrap { wrap }
                                        alignItems { center }
                                        justifyContent { flexStart }
                                    },
                                ) {
                                    if (fileData.prevName.isNotBlank()) imagePrevData(value = fileData.prevName)
                                    imagePrevData(value = fileData.mimeType?.removePrefix("image/") ?: "")
                                    imagePrevData(value = "${fileData.width} x ${fileData.height} px")
                                    if (fileSize > 0.0) imagePrevData(
                                        value = if (fileSize < 1.0) "${
                                            (fileSize * 1024).roundTo(
                                                0,
                                            )
                                        } KB" else "${fileSize.roundTo(2)} MB",
                                    )
                                }
                            }
                        }
                }
            }
        },
        footer = {
            twoButtonFooter(
                secondaryTitle = translation[TL.General.CANCEL],
                secondaryStyleParams = secondaryButtonStyleParams,
                secondaryClickHandlers = listOf(imageFileDataStore.reset, routerStore.back),
                primaryTitle = translation[TL.General.SAVE],
                primaryState = imageFileDataStore.data.map { fileData -> fileData.prevBytes != null },
                primaryStyleParams = primaryButtonStyleParams,
                primaryValue = Unit,
                primaryClickHandlers = listOf(preAttachmentsStoreStore.addOrUpdatePreImageAttachment, routerStore.back),
            )
        },
    )
}

fun RenderContext.imagePrevData(title: String? = null, value: String, icon: (Icons.() -> IconDefinition)? = null) {
    lineUp(
        {
            alignItems { center }
            justifyContent { center }
            radius { normal }
            paddings {
                horizontal { smaller }
                //vertical { tiny }
            }
            fontSize { smaller }
            color { secondary.main }
            border {
//            color { primary.main }
                color { FormationColors.GrayPrivate.color }
                width(formationStyles.borderWidth)
            }
            background {
                color { FormationColors.GrayPrivate.color }
            }
            margin { tiny }
            overflowX { hidden }
        },
    ) {
        spacing { smaller }
        items {
            icon?.let {
                icon { fromTheme(it) }
            }
            ellipseText {
                +"${title?.let { "$it: " } ?: ""}$value"
            }
        }
    }
}
