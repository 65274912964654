package webcomponents

import dev.fritz2.components.compat.button
import dev.fritz2.components.compat.div
import dev.fritz2.components.compat.span
import dev.fritz2.components.flexBox
import dev.fritz2.components.icon
import dev.fritz2.components.stackUp
import dev.fritz2.core.HtmlTag
import dev.fritz2.core.Id
import dev.fritz2.core.RenderContext
import dev.fritz2.styling.params.BackgroundBlendModes.overlay
import dev.fritz2.styling.params.BasicParams
import dev.fritz2.styling.params.ColorProperty
import dev.fritz2.styling.params.OverflowXValues
import dev.fritz2.styling.params.SizesProperty
import dev.fritz2.styling.params.SpacesContext
import dev.fritz2.styling.params.Style
import dev.fritz2.styling.params.rgba
import dev.fritz2.styling.params.shadow
import dev.fritz2.styling.theme.Colors
import dev.fritz2.styling.theme.IconDefinition
import dev.fritz2.styling.theme.Icons
import dev.fritz2.styling.theme.Property
import dev.fritz2.styling.theme.Sizes
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.map
import org.w3c.dom.HTMLDivElement
import theme.FormationColors
import theme.FormationDefault.Companion.formationStyles
import theme.FormationUIIcons
import utils.isMobileOrTabletBrowser
import utils.makeRGBA

val expandState: MutableStateFlow<Boolean?> = MutableStateFlow(null)

fun RenderContext.expandCard(
    prefix: String = "expandCard",
    openExpended: Boolean? = null,
    expendDynamically: Boolean = isMobileOrTabletBrowser(),
    content: (HtmlTag<HTMLDivElement>.() -> Unit)? = null
) {

    if (openExpended != null) {
        expandState.value = openExpended
    } else if (expandState.value == null) {
        expandState.value = !expendDynamically
    }

//    if (expandState.value == null || openExpended == !expendDynamically) {
//        expandState.value = !expendDynamically
//    } else {
//        openExpended?.let {
//            expandState.value = it
//        }
//    }


//    expandState.render { expand ->
//        if (expand != true) {
//            halfCard(prefix = prefix) {
//                content?.invoke(this)
//            }
//        } else {
//            fullCard(prefix = prefix) {
//                content?.invoke(this)
//            }
//        }
//    }

    div(
        "flex md:fixed bottom-0 left-0 right-0 md:right-auto w-full md:w-100 max-w-full md:max-w-100 min-w-72 md:min-w-100 min-h-72 z-[1025] -mt-1.5 bg-formationWhite transition-all duration-300 rounded-t-2xl md:rounded-tr-2xl shadow-tlHalfCard md:shadow-tlFullCard",
//        "relative flex bottom-0 left-0 right-0 md:right-auto w-full md:w-100 max-w-full md:max-w-100 min-w-72 md:min-w-100 min-h-72 z-[1025] -mt-1.5 bg-formationWhite transition-all duration-300 rounded-t-2xl md:rounded-tr-2xl shadow-tlHalfCard md:shadow-tlFullCard",
        id = "$prefix-${Id.next()}",
    ) {
        classList(expandState.map { if (it != true) listOf("relative", "h-[50vh]", "max-h-[50vh]") else listOf("fixed", "h-full", "max-h-full") })
//        className(expandState.map { if (it != true) "relative h-[50vh] max-h-[50vh]" else "fixed h-full max-h-full" })
        content?.invoke(this)
    }
}

fun RenderContext.halfCard(
    prefix: String = "halfCard",
    content: (HtmlTag<HTMLDivElement>.() -> Unit)? = null
) {

//    flexBox(
//        {
//            position(
//                sm = {
//                    relative {
//                        bottom { none }
//                        horizontal { none }
//                    }
//                },
//                md = {
//                    fixed {
//                        bottom { none }
//                        left { none }
//                    }
//                },
//            )
//            width(sm = { full }, md = formationStyles.cardWidth)
//            maxWidth(sm = { full }, md = formationStyles.cardWidth)
//            minWidth(sm = { "280px" }, md = formationStyles.cardWidth)
//            height(sm = { "50vh" }, md = { "50vh" })
//            maxHeight { "50vh" }
//            minHeight { "280px" }
//            zIndex { "1025" }
//            background { color { secondary.main } }
//            radii(
//                sm = { top { "15px" } },
//                md = { topRight { "15px" }; topLeft { "0px" } },
//            )
//            boxShadow(
//                sm = {
//                    shadow("0px", "1px", "1px", color = rgba(0, 0, 0, 0.25))
//                    shadow("-5px", "-5px", "20px", color = rgba(0, 0, 0, 0.25))
//                },
//                md = {
//                    shadow("-1px", "1px", "1px", color = rgba(0, 0, 0, 0.25))
//                    shadow("5px", "-5px", "20px", color = rgba(0, 0, 0, 0.25))
//                },
//            )
//            margins(sm = { top { "-5px" } }) // TODO: adjust this overlap and play with attribution not at the bottom
//        },
//        prefix = prefix,
//    ) {
//        content?.invoke(this)
//    }

    div(
        "flex relative h-[50vh] max-h-[50vh] bottom-0 left-0 right-0 md:fixed md:right-auto w-full md:w-100 max-w-full md:max-w-100 min-w-72 md:min-w-100 min-h-72 z-[1025] bg-formationWhite rounded-t-2xl md:rounded-tr-2xl -mt-1.5 shadow-tlHalfCard",
        id = "$prefix-${Id.next()}",
    ) {
        content?.invoke(this)
    }
}

fun RenderContext.fullCard(prefix: String = "fullCard", content: (HtmlTag<HTMLDivElement>.() -> Unit)?) {

//    div(
//        {
//            height { full }
//            width(sm = { full }, md = formationStyles.cardWidth)
//            minWidth(sm = { "280px" }, md = formationStyles.cardWidth)
//            maxWidth(sm = { full }, md = formationStyles.cardWidth)
//            position(
//                sm = {
//                    fixed {
//                        horizontal { none }
//                        bottom { none }
//                    }
//                },
//                md = {
//                    fixed {
//                        left { none }
//                        bottom { none }
//                    }
//                },
//            )
//            background { color { secondary.main } }
//            boxShadow {
//                shadow("-1px", "1px", "1px", color = rgba(0, 0, 0, 0.25))
//                shadow("5px", "-5px", "20px", color = rgba(0, 0, 0, 0.25))
//            }
//        },
//        prefix = prefix,
//    ) {
//        content?.invoke(this)
//    }

    div(
        "flex fixed h-full max-h-full bottom-0 left-0 right-0 md:fixed md:right-auto w-full md:w-100 max-w-full md:max-w-100 min-w-72 md:min-w-100 min-h-72 z-[1025] bg-formationWhite rounded-t-2xl md:rounded-tr-2xl -mt-1.5 shadow-tlFullCard",
        id = "$prefix-${Id.next()}",
    ) {
        content?.invoke(this)
    }
}

fun RenderContext.fullPage(prefix: String = "fp", content: (HtmlTag<HTMLDivElement>.() -> Unit)?) {
    div(
        {
            position {
                fixed {
                    horizontal { none }
                    vertical { none }
                }
            }
            maxHeight { borderBox }
            width { full }
            background { color { secondary.main } }
        },
        prefix = prefix,
    ) {
        content?.invoke(this)
    }
}

fun RenderContext.fullWidthFullContainer(content: (HtmlTag<HTMLDivElement>.() -> Unit)?) {
    flexBox(
        {
            width { full }
            height { full }
            direction { column }
            justifyContent { spaceBetween }
            alignItems { center }
            padding { normal }
        },
        prefix = "fpFullContainer",
    ) {
        content?.invoke(this)
    }
}

fun RenderContext.fullWidthCenterContainer(
    prefix: String = "fpCenterContainer",
    width: SizesProperty? = formationStyles.cardWidth,
    bgColor: Colors.() -> Property = { secondary.main },
    content: (HtmlTag<HTMLDivElement>.() -> Unit)?
) {

    expandState.render { expanded ->
        flexBox(
            {
                width { full }
                height { full }
                justifyContent { center }
                alignItems { center }
                background { color(bgColor) }
                if (expanded != true) {
                    radii(
                        sm = { top { "15px" } },
                        md = { topRight { "15px" }; topLeft { "0px" } },
                    )
                }
            },
            prefix = prefix,
        ) {
            flexBox(
                {
                    width(sm = { full }, md = width)
                    height { full }
                    direction { column }
                    justifyContent { spaceBetween }
                    alignItems { center }
                },
            ) {
                content?.invoke(this)
            }
        }
    }

}

fun RenderContext.fakeHalfCard(prefix: String = "fpFakeCard", content: (HtmlTag<HTMLDivElement>.() -> Unit)?) {
    div(
        {
            position { relative { } }
            width { full }
            height { "60vh" }
            minHeight { "50vh" }
            maxHeight { "70vh" }
            radius { larger }
            background {
                color { secondary.main }
            }
            boxShadow {
                shadow("0px", "1px", "1px", color = rgba(0, 0, 0, 0.25))
                shadow("-5px", "-5px", "20px", color = rgba(0, 0, 0, 0.25))
            }
            justifyContent { center }
            alignItems { center }
            margins {
                top { "-10px" }
                bottom { none }
            }
            zIndex { overlay }
        },
        prefix = prefix,
    ) {
        content?.invoke(this)
    }
}

fun RenderContext.fullPageConfirmation(prefix: String = "fpc", content: (HtmlTag<HTMLDivElement>.() -> Unit)?) {
    div(
        {
            position {
                fixed {
                    horizontal { none }
                    vertical { none }
                }
            }
            maxHeight { borderBox }
            width { full }
            background { color { makeRGBA(primary.main, 0.5) } }
            zIndex { "2000" }
        },
        prefix = prefix,
    ) {
        content?.invoke(this)
    }
}

fun RenderContext.fullPageConfirmationContainer(
    prefix: String = "fpConfContainer",
    width: SizesProperty? = { "320px" },
    margins: (SpacesContext.() -> Unit)? = { horizontal { huge } },
    paddings: (SpacesContext.() -> Unit)? = {
        horizontal { normal }
        vertical { normal }
    },
    additionalStyleParams: Style<BasicParams> = { },
    additionalInnerStyleParams: Style<BasicParams> = { },
    content: (HtmlTag<HTMLDivElement>.() -> Unit)?
) {
    flexBox(
        {
            width { full }
            height { full }
            justifyContent { center }
            alignItems { center }
            additionalStyleParams()
        },
        prefix = prefix,
    ) {
        flexBox(
            {
                direction { column }
                margins(sm = margins)
                width(width)
                minWidth { "100px" }
                maxWidth { full }
                maxHeight { full }
                justifyContent { spaceBetween }
                alignItems { center }
                paddings(paddings)
                background { color { secondary.main } }
                radius { "15px" }
                boxShadow {
                    shadow("4px", "4px", "4px", "0px", color = rgba(0, 0, 0, 0.25))
                }
                additionalInnerStyleParams()
            },
        ) {
            content?.invoke(this)
        }
    }
}

fun RenderContext.baseLayout(
    expandable: Boolean? = false,
    header: (HtmlTag<HTMLDivElement>.() -> Unit)? = null,
    content: (HtmlTag<HTMLDivElement>.() -> Unit)? = null,
    headerPaddings: SpacesContext.() -> Unit = {
        if (expandable != true) {
            top { normal }
        }
        horizontal { small }
        bottom { tiny }
    },
    footerPaddings: SpacesContext.() -> Unit = {
        vertical { smaller }
        horizontal { normal }
        bottom { normal }
    },
    footer: (HtmlTag<HTMLDivElement>.() -> Unit)? = null
) {

    flexBox(
        {
            direction { column }
            justifyContent { spaceBetween }
            alignItems { stretch }
            width { full }
            height { full }
        },
        prefix = "base-layout",
    ) {
        if (expandable == true) {
            expandState.render { expand ->
                button(
                    {
                        radius { inherit }
                        width { full }
                        paddings {
                            horizontal { normal }
                            vertical { tiny }
                        }
                        if (expand != true) {
                            radii(
                                sm = { top { "15px" } },
                                md = { topRight { "15px" }; topLeft { "0px" } },
                            )
                        }
                        fontSize { small }
                        color { FormationColors.GrayDisabled.color }
                        background {
                            color { secondary.main }
                        }
                        hover {
                            color { secondary.main }
                            background {
                                color { FormationColors.MarkerYou.color }
                            }
                        }
                    },
                ) {
                    icon {
                        fromTheme(
                            if (expand == true) {
                                { FormationUIIcons.ChevronDown.icon }
                            } else {
                                { FormationUIIcons.ChevronUp.icon }
                            },
                        )
                    }
                    clicks.map { expand != true } handledBy { newState ->
                        expandState.value = newState
                    }
                }
            }
        }
        // HEADER
        if (header != null) {
            flexBox(
                {
                    direction { column }
                    justifyContent { spaceBetween }
                    alignItems { stretch }
                    width { full }
                    paddings(headerPaddings)
                },
            ) {
                header.invoke(this)
            }
        }

        // SCROLLABLE CONTENT
        content?.invoke(this)

        // FOOTER
        if (footer != null) {
            flexBox(
                {
                    direction { column }
                    justifyContent { spaceBetween }
                    alignItems { stretch }
                    width { full }
                    paddings(footerPaddings)
                },
            ) {
                footer.invoke(this)
            }
        }
    }
}

fun RenderContext.contentScrollBox(margins: (SpacesContext.() -> Unit)? = null, content: (HtmlTag<HTMLDivElement>.() -> Unit)? = null) {
    flexBox(
        {
            direction { column }
            justifyContent { flexStart }
            alignItems { stretch }
            width { full }
            height { full }
            paddings { horizontal { small } }
            margins?.let { margins(margins) }
            overflowY { auto }
        },
    ) {
        content?.invoke(this)
    }
}

fun RenderContext.cardTitle(
    title: Flow<String>,
    maxLines: Int = 1,
    margins: SpacesContext.() -> Unit = { right { small } },
    iconFlow: Flow<IconDefinition?>? = null,
    iconSize: (Sizes.() -> Property)? = { normal },
    icon: (Icons.() -> IconDefinition)? = null
) {
    flexBox(
        {
            width { maxContent }
            maxWidth { full }
            height { maxContent }
            alignItems { center }
            margins(margins)
            flex { shrink { "0" } }
            overflow { hidden }
        },
    ) {
        if (iconFlow != null) {
            iconFlow.render { titleIcon ->
                if (titleIcon != null) {
                    icon(
                        {
                            margins { right { smaller } }
                            size(iconSize)
                        },
                    ) { fromTheme { titleIcon } }
                }
            }
        } else {
            if (icon != null) {
                icon(
                    {
                        margins { right { smaller } }
                        size(iconSize)
                    },
                ) { fromTheme(icon) }
            }
        }
        ellipseText(
            {
                width { full }
                fontSize { large }
                fontWeight { bold }
            },
            maxLines = maxLines,
        ) {
            title.renderText(into = this)
        }
    }
}

fun RenderContext.cardSubtitle(
    title: Flow<String>,
    iconFlow: Flow<IconDefinition?>? = null,
    ellipses: Boolean = false,
    iconPosition: Position = Position.Left
) {
    fun RenderContext.textContent() {
        if (ellipses) {
            ellipseText(
                {
                    fontSize { small }
                    fontWeight { lighter }
                },
            ) {
                title.renderText(into = this)
            }
        } else {
            span(
                {
                    fontSize { small }
                    fontWeight { lighter }
                },
            ) { title.renderText(into = this) }
        }
    }

    if (iconFlow != null) {
        iconFlow.render { currentIcon ->
            flexBox(
                {
                    alignItems { center }
                    if (ellipses) overflowX { hidden }
                },
            ) {
                if (currentIcon != null && iconPosition == Position.Left) icon({ margins { right { smaller } } }) { fromTheme { currentIcon } }
                textContent()
                if (currentIcon != null && iconPosition == Position.Right) icon({ margins { left { smaller } } }) { fromTheme { currentIcon } }
            }
        }
    } else {
        textContent()
    }
}

fun RenderContext.cardTitleWithSubtitle(
    title: Flow<String>,
    titleMaxLines: Int = 1,
    titleIconFlow: Flow<IconDefinition>? = null,
    titleEllipses: Boolean = true,
    subtitle: Flow<String>,
    subtitleMaxLines: Int = 2,
    subtitleIconFlow: Flow<IconDefinition?>? = null,
    subtitleEllipses: Boolean = false,
    iconBox: (HtmlTag<HTMLDivElement>.() -> Unit)? = null,
    bigIcon: (Icons.() -> IconDefinition)? = null,
) {
    flexBox(
        {
            width { full }
            alignItems { center }
            margins { right { small } }
            overflowX { hidden }
        },
    ) {
        if (bigIcon != null) {
            icon(
                {
                    margins { right { smaller } }
                    size { "42px" }
                },
            ) { fromTheme(bigIcon) }
        }
        if (iconBox != null) {
            flexBox(
                {
                    margins { right { smaller } }
                },
            ) {
                iconBox.invoke(this)
            }
        }
        stackUp(
            {
                width { full }
                alignItems { flexStart }
                overflowX { hidden }
            },
        ) {
            spacing { none }
            items {
                flexBox(
                    {
                        width { full }
                        height { maxContent }
                        alignItems { center }
                        overflow { hidden }
                    },
                ) {
                    if (titleIconFlow != null) {
                        titleIconFlow.render { titleIcon ->
                            icon(
                                {
                                    size { normal }
                                    margins { right { smaller } }
                                },
                            ) { fromTheme { titleIcon } }
                        }
                    } else if (subtitleIconFlow != null) {
                        // dummy icon to match indent
                        icon(
                            {
                                size { normal }
                                margins { right { smaller } }
                            },
                        ) { }
                    }
                    if (titleEllipses) {
                        ellipseText(
                            {
                                width { full }
                                fontSize { large }
                                fontWeight { bold }
                            },
                            maxLines = titleMaxLines,
                        ) { title.renderText(into = this) }
                    } else {
                        span(
                            {
                                fontSize { large }
                                fontWeight { bold }
                            },
                        ) { title.renderText(into = this) }
                    }
                }
                flexBox(
                    {
                        width { full }
                        height { maxContent }
                        alignItems { center }
                        overflow { hidden }
                    },
                ) {
                    if (subtitleIconFlow != null) {
                        subtitleIconFlow.render { subtitleIcon ->
                            if (subtitleIcon != null) {
                                icon(
                                    {
                                        size { normal }
                                        margins { right { smaller } }
                                    },
                                ) { fromTheme { subtitleIcon } }
                            } else if (titleIconFlow != null) {
                                // dummy icon to match indent
                                icon(
                                    {
                                        size { normal }
                                        margins { right { smaller } }
                                    },
                                ) { }
                            }
                        }
                    } else if (titleIconFlow != null) {
                        // dummy icon to match indent
                        icon(
                            {
                                size { normal }
                                margins { right { smaller } }
                            },
                        ) { }
                    }
                    if (subtitleEllipses) {
                        ellipseText(
                            {
                                width { full }
                                fontSize { small }
                                fontWeight { lighter }
                            },
                            maxLines = subtitleMaxLines,
                        ) { subtitle.renderText(into = this) }
                    } else {
                        span(
                            {
                                width { full }
                                fontSize { small }
                                fontWeight { lighter }
                                css(
                                    """
                                overflow-wrap: break-word;
                                word-wrap: break-word;
                            """.trimIndent(),
                                )
                            },
                        ) { subtitle.renderText(into = this) }
                    }
                }
            }
        }
    }
}

fun RenderContext.cardTitleWithSubtitleAlt(
    title: Flow<String>,
    titleIcon: Flow<IconDefinition>? = null,
    subtitle: Flow<String>,
    subTitleIcon: Flow<IconDefinition>? = null
) {
    stackUp(
        {
            width { maxContent }
            alignItems { flexStart }
            overflowX { OverflowXValues.hidden }
        },
    ) {
        spacing { none }
        items {
            cardTitle(
                title = title,
                iconFlow = titleIcon,
            )
            cardSubtitle(
                title = subtitle,
                iconFlow = subTitleIcon,
            )
        }
    }
}


fun RenderContext.mainSubTitle(title: Flow<String>, icon: Flow<IconDefinition?>? = null) {
    if (icon != null) {
        icon.render { currentIcon ->
            flexBox(
                {
                    alignItems { center }
                    overflowX { hidden }
                },
            ) {
                if (currentIcon != null) icon({ margins { right { smaller } } }) { fromTheme { currentIcon } }
                ellipseText(
                    {
                        fontSize { normal }
                        fontWeight { bold }
                    },
                ) { title.renderText(into = this) }
            }
        }
    } else {
        ellipseText(
            {
                fontSize { normal }
                fontWeight { bold }
            },
        ) { title.renderText(into = this) }
    }
}

fun RenderContext.mainTitle(
    title: Flow<String>,
    color: Colors.() -> ColorProperty = { primary.main },
    icon: (Icons.() -> IconDefinition)? = null
) {
    if (icon != null) {
        flexBox(
            {
                alignItems { center }
                color(color)
            },
        ) {
            icon({ margins { right { smaller } } }) { fromTheme(icon) }
            span(
                {
                    fontSize { huge }
                    fontWeight { bold }
                },
            ) { title.renderText(into = this) }
        }
    } else {
        span(
            {
                fontSize { huge }
                fontWeight { bold }
                color(color)
            },
        ) { title.renderText(into = this) }
    }
}
