package workspacetools.workspaceoptions

import analytics.AnalyticsCategory
import analytics.AnalyticsService
import auth.CurrentWorkspaceStore
import apiclient.FormationClient
import apiclient.groups.Group
import apiclient.groups.GroupFeatureFlags
import apiclient.groups.featureFlags
import apiclient.groups.restSetGroupFeatureFlag
import com.tryformation.localization.Translatable
import dev.fritz2.core.RenderContext
import dev.fritz2.core.checked
import dev.fritz2.core.type
import koin.withKoin
import localization.translate
import org.w3c.dom.HTMLInputElement
import overlays.BusyStore
import twcomponents.twColOf
import twcomponents.twRowOf

enum class WorkspaceFeatureFlagTexts : Translatable {
    Header,
    ;

    override val prefix = "workspace-options-feature-flag-texts"

}

fun RenderContext.featureFlagsEditor(group: Group) {
    h2 {
        translate(WorkspaceFeatureFlagTexts.Header)
    }

    twColOf {
        GroupFeatureFlags.entries.filter { it.editableByWorkspaceAdmin }.forEach { flag ->
            flagEditor(group, flag)
        }
    }
}

val GroupFeatureFlags.descriptionTranslatable: Translatable
    get() {
        val parentMessageId = messageId
        return object : Translatable {
            override val name = "description"
            override val prefix = parentMessageId
        }
    }

private fun RenderContext.flagEditor(group: Group, flag: GroupFeatureFlags) {
    val checked = group.featureFlags()[flag]

    twRowOf {
        input("block") {
            type("checkbox")
            this.checked(checked == true)

            inputs handledBy {
                val element = it.target as HTMLInputElement
                console.log("toggle $flag to ${element.checked}")
                toggle(group, flag, element.checked)
            }
        }
        p {
            span("font-bold") {
                translate(flag)
            }
            +" ("
            translate(flag.descriptionTranslatable)
            +")"
        }
    }
}

private suspend fun toggle(group: Group, flag: GroupFeatureFlags, state: Boolean) {
    withKoin {
        val formationClient = get<FormationClient>()
        val busyStore = get<BusyStore>()
        val currentWorkspaceStore = get<CurrentWorkspaceStore>()
        val analyticsService = get<AnalyticsService>()

        busyStore.withBusy(
            {
                formationClient.restSetGroupFeatureFlag(group.groupId, flag, state)
            },
        ) { updatedGroup ->
            analyticsService.createEvent(
                AnalyticsCategory.WorkspaceOptions,
            ) {
                recordAction("change-flag", target = "$flag-$state")
            }
            currentWorkspaceStore.update(updatedGroup)
        }
    }
}
