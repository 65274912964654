package data.users

import apiclient.FormationClient
import apiclient.users.PublicUserProfile
import apiclient.users.restGetPublicUserProfile
import apiclient.util.simpleCache

import kotlin.time.Duration.Companion.hours
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.filterNotNull
import kotlinx.coroutines.flow.map

class PublicUserProfileCache(val formationClient: FormationClient) {

    private val cache = simpleCache<String, PublicUserProfile>(capacity = 1000, maxAgeInMillis = 1.hours.inWholeMilliseconds)

    fun <T> getProfile(flow: Flow<T>, extractor: (T) -> String?): Flow<PublicUserProfile?> {
        return flow.map { element ->
            extractor(element)?.let { userId ->
                getProfile(userId)
            }
        }.filterNotNull()
    }

    suspend fun getProfile(id: String): PublicUserProfile? {
        return cache.getOrFetch(id) {
            formationClient.restGetPublicUserProfile(id).getOrNull()
        }
    }
}
