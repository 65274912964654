package login

import dev.fritz2.components.modal
import dev.fritz2.components.stackUp
import dev.fritz2.core.RootStore
import dev.fritz2.core.invoke
import koin.koinCtx
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.flowOf
import mainmenu.mainMenuButton
import routing.MainController
import theme.FormationColors
import theme.FormationDefault
import theme.FormationIcons
import webcomponents.cardTitle
import webcomponents.fullPageConfirmation
import webcomponents.fullPageConfirmationContainer

data class WorkSpaceChange(
    val oldRoute: Map<String, String>,
    val oldWorkspace: String?,
    val newRoute: Map<String, String>,
    val newWorkspace: String
)

class ChangeWorkspaceStore : RootStore<WorkSpaceChange?>(
    initialData = null,
    job = Job(),
) {

    val reset = handle { null }

    fun changeWorkspacePopup(oldRoute: Map<String, String>, oldWS: String?, newRoute: Map<String, String>, newWS: String) {
        val mainController by koinCtx.inject<MainController>()

        if (oldWS != null && oldWS != current?.oldWorkspace) {
            update(
                WorkSpaceChange(
                    oldRoute = oldRoute,
                    oldWorkspace = oldWS,
                    newRoute = newRoute,
                    newWorkspace = newWS,
                ),
            )

            console.log("New workspace detected:", newWS)
            val handler = modal(
                {
                    width { none }
                    height { none }
                },
            ) {
                placement { center }
                hasCloseButton(false)
                content { close ->
                    fullPageConfirmation {
                        fullPageConfirmationContainer(
                            width = FormationDefault.formationStyles.cardWidth,
                        ) {
                            stackUp {
                                spacing { small }
                                items {
                                    cardTitle(flowOf("Change Workspace?"))
                                    p { +"The link you opened refers to another workspace (\"$newWS\"). Would you like to login to that workspace or stay in your current workspace (\"$oldWS\")?" }
                                    mainMenuButton(
                                        icon = { FormationIcons.ArrowRight.icon },
                                        iconBackgroudColor = { FormationColors.GreenBright.color },
                                        titleLiteral = flowOf("Login to \"$newWS\""),
                                        subtitle = flowOf("New workspace detected from the link"),
                                        clickHandlers = listOf(close, mainController.changeWorkspace),
                                    )
                                    mainMenuButton(
                                        icon = { FormationIcons.Star.icon },
//                                        iconBackgroudColor = { FormationColors.YellowDoing.color },
                                        titleLiteral = flowOf("Stay in \"$oldWS\""),
                                        subtitle = flowOf("Your current workspace"),
                                        clickHandlers = listOf(close, mainController.stayInWorkspace, reset),
                                    )
//                                    twoButtonFooter(
//                                        secondaryTitle = translation[TL.PageLogout.STAY],
//                                        secondaryStyleParams = secondaryButtonStyleParams,
//                                        secondaryClickHandlers = listOf(close, mainController.stayInWorkspace, reset),
//                                        primaryTitle = translation[TL.General.CHANGE],
//                                        primaryStyleParams = primaryButtonStyleParams,
//                                        primaryClickHandlers = listOf(close, mainController.changeWorkspace, reset),
//                                    )
                                }
                            }
                        }
                    }
                }
            }
            handler.invoke()
        }
    }
}
