package data.objects.objecthistory

import apiclient.FormationClient
import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.ObjectTags
import apiclient.geoobjects.ObjectType
import apiclient.geoobjects.SearchQueryContext
import apiclient.geoobjects.SortByField
import apiclient.geoobjects.SortField
import apiclient.geoobjects.SortOrder
import apiclient.geoobjects.restSearch
import apiclient.search.ObjectSearchResult
import apiclient.tags.tag
import auth.ApiUserStore
import auth.FeatureFlagStore
import data.objects.ActiveObjectStore
import dev.fritz2.core.RootStore
import koin.koinCtx
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.map
import map.MapLayersStore
import maplibreGL.MaplibreMap
import model.L
import overlays.BusyStore

data class ObjectAndResults(
    val geoObject: GeoObjectDetails,
    val results: List<ObjectSearchResult>
)

class ObjectHistoryResultsStore : RootStore<Map<String, ObjectAndResults>>(
    initialData = emptyMap(),
    job = Job(),
) {

    val apiUserStore: ApiUserStore by koinCtx.inject()
    val activeObjectStore: ActiveObjectStore by koinCtx.inject()
    val mapLayersStore: MapLayersStore by koinCtx.inject()
    val maplibreMap: MaplibreMap by koinCtx.inject()
    val formationClient: FormationClient by koinCtx.inject()
    val featureFlagStore: FeatureFlagStore by koinCtx.inject()
    private val activeObjectId = activeObjectStore.map(GeoObjectDetails.L.id)
    val busyStore: BusyStore by koinCtx.inject()

    private val getObjectHistoryForInfoCard = handle<GeoObjectDetails> { current, obj ->
        val groupIds = apiUserStore.current.apiUser?.groups?.map { it.groupId }
        if (obj.id.isNotBlank() && !groupIds.isNullOrEmpty()) {
            busyStore.handleApiCall(
                supplier = suspend {
                    formationClient.restSearch(
                        SearchQueryContext(
                            groupIds = groupIds,
                            tags = listOf(
                                ObjectTags.ConnectedTo.tag(obj.id),
                            ),
                            objectTypes = listOf(ObjectType.HistoryEntry),
                            sort = listOf(
                                SortByField(SortField.CREATED_AT, SortOrder.DESC),
                            ),
                        ),
                    )
                },
                processResult = { res ->
                    update(mapOf(obj.id to ObjectAndResults(obj, res.hits)))
                    console.log("Object History results", res.hits.map { it.hit })
                },
            )
        }
        current
    }

    val reset = handle {
        emptyMap()
    }

    init {
        activeObjectId.data.map { activeObjectStore.current } handledBy getObjectHistoryForInfoCard
    }

}
