package twcomponents

import apiclient.users.UserFeatureFlag
import auth.FeatureFlagStore
import auth.Features
import data.users.profile.MyProfileStore
import dev.fritz2.core.RenderContext
import koin.koinCtx
import koin.withKoin
import kotlinx.coroutines.flow.filterNotNull
import kotlinx.coroutines.flow.map

fun RenderContext.doIfUserFeatureFlagEnabled(flag: UserFeatureFlag, skipCheck: Boolean=false, elseBlock: (() -> Unit)?=null, block: () -> Unit) {
    if(skipCheck) {
        block()
    } else {
        koinCtx.get<MyProfileStore>().data.map { it.featureFlags }.filterNotNull().render { flags ->
            if (flags[flag] == true) {
                block()
            } else {
                elseBlock?.invoke()
            }
        }
    }
}

fun RenderContext.doIfFeatureFlagEnabled(flag: Features, skipCheck: Boolean=false,elseBlock: (() -> Unit)?=null, block: () -> Unit) {
    if(skipCheck) {
        block()
    } else {
        withKoin {
            get<FeatureFlagStore>().data.render { features ->
                if (features[flag] == true) {
                    block()
                } else {
                    elseBlock?.invoke()
                }
            }
        }
    }
}
