package search.global

import com.jillesvangurp.geo.findUTMCoordinates
import com.jillesvangurp.geo.parseMgrs
import com.jillesvangurp.geo.toPointCoordinate
import com.jillesvangurp.geo.toPointCoordinates
import com.jillesvangurp.geojson.latitude
import com.jillesvangurp.geojson.longitude
import apiclient.customfields.FieldValue
import apiclient.geoobjects.ObjectType
import apiclient.geoobjects.toLatLon
import apiclient.util.parsePoint
import dev.fritz2.components.flexBox
import dev.fritz2.components.inputField
import dev.fritz2.components.stackUp
import dev.fritz2.core.RenderContext
import dev.fritz2.core.SimpleHandler
import dev.fritz2.core.Store
import dev.fritz2.core.autocomplete
import dev.fritz2.core.placeholder
import dev.fritz2.core.values
import koin.koinCtx
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.filterNotNull
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.mapNotNull
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.plus
import localization.TL
import localization.Translation
import mainmenu.RouterStore
import map.bottombar.inlinedBottomBar
import maplibreGL.MaplibreMap
import model.KeywordTag
import search.SearchDistanceCalculationStore
import search.distanceCalcSwitch
import search.searchResultsList
import styling.primaryButtonStyleParams
import theme.FormationColors
import theme.FormationDefault.Companion.formationStyles
import utils.toKeyWordTagsList
import webcomponents.KeywordTagActionType
import webcomponents.KeywordTagType
import webcomponents.baseLayout
import webcomponents.circleIconButton
import webcomponents.contentScrollBox
import webcomponents.expandState
import webcomponents.genericButton
import webcomponents.keywordTagList
import webcomponents.mainTitle

fun RenderContext.pageSearch() {
    val globalSearchResultsStore: GlobalSearchResultsStore by koinCtx.inject()
    val searchInputFieldStore: SearchInputFieldStore by koinCtx.inject()
    val searchDistanceCalculationStore: SearchDistanceCalculationStore by koinCtx.inject()

    baseLayout(
        header = {
            expandState.render { expanded ->
                if (expanded == true) {
                    stackUp(
                        {
                            width { full }
                        },
                    ) {
                        spacing { tiny }
                        items {
                            searchHeader(searchInputFieldStore)
                            // filter buttons
//                            searchFilterBar()
                            // distance calculation switch
                            distanceCalcSwitch(
                                distanceToSelectStore = searchDistanceCalculationStore,
                                switchHandler = searchDistanceCalculationStore.select,
                            )
                        }
                    }
                }
            }
        },
        content = {
            contentScrollBox {
                expandState.render { expanded ->
                    if (expanded != true) {
                        stackUp(
                            {
                                width { full }
                            },
                        ) {
                            spacing { tiny }
                            items {
                                searchHeader(searchInputFieldStore)
                                // filter buttons
//                                searchFilterBar()
                                // distance calculation switch
                                distanceCalcSwitch(
                                    distanceToSelectStore = searchDistanceCalculationStore,
                                    switchHandler = searchDistanceCalculationStore.select,
                                )
                            }
                        }
                    }
                }
                // search results
                searchResultsList(results = globalSearchResultsStore.data.mapNotNull { it })
            }
        },
        footerPaddings = {},
        footer = {
            inlinedBottomBar()
        },
    )
}


fun RenderContext.searchHeader(
    searchInputFieldStore: Store<String>,
) {
    val translation: Translation by koinCtx.inject()
    val activeSearchKeywordsStore: ActiveSearchKeywordsStore by koinCtx.inject()
    val activeSearchObjectTypesStore: ActiveSearchObjectTypesStore by koinCtx.inject()
    val activeSearchReadOnlyKeywordsStore: ActiveSearchReadOnlyKeywordsStore by koinCtx.inject()
    val activeSearchFieldValuesStore: ActiveSearchFieldValuesStore by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()

    stackUp(
        {
            width { full }
            margins {
                bottom { tiny }
            }
        },
    ) {
        spacing { tiny }
        items {
            flexBox(
                {
                    width { full }// PAGE TITLE (ONLY VISIBLE ON DESKTOP)

//                display( sm = { none }, md = { flex })
                    alignItems { center }
                    justifyContent { center }
                },
            ) { mainTitle(translation[TL.Search.PAGE_SEARCH_TITLE]) }

            // SEARCH INPUT FIELD
            div("w-full flex items-center justify-center gap-3") {
                searchInput("inputSearch", searchInputFieldStore)
                // tags button
                searchTagButton(
                    navValue = mapOf("change" to "tags"),
                    navHandlers = listOf(routerStore.addOrReplaceRoute),
                )
            }

            coordinatesButtons(searchInputFieldStore)

            selectedSearchTagsList(
                activeSearchKeywordsStore = activeSearchKeywordsStore,
                activeSearchObjectTypesStore = activeSearchObjectTypesStore,
                activeSearchReadOnlyKeywordsStore = activeSearchReadOnlyKeywordsStore,
                activeSearchFieldValuesStore = activeSearchFieldValuesStore,
                keywordTagType = KeywordTagType.SearchTag,
            )
        }
    }
}

fun RenderContext.searchInput(id: String, valueStore: Store<String>, disabled: Boolean = false) {
    val translation: Translation by koinCtx.inject()
    inputField(
        {
            width { full }
            height(formationStyles.inputHeight)
            radius(formationStyles.inputRadius)
            color { primary.main }
            background { color { FormationColors.GrayLight.color } }
        },
        id = id,
    ) {
        size { small }
        disabled(disabled)
        type("search")
        value(valueStore.data)
        element {
            autocomplete("off")
            placeholder(translation.getString(TL.Search.FIND_IT_FAST))
            inputs.values() handledBy valueStore.update
        }
    }
}

fun <T> RenderContext.searchTagButton(
    navValue: T,
    navHandlers: List<SimpleHandler<T>>,
) {
    val translation: Translation by koinCtx.inject()

    circleIconButton(
        size = formationStyles.inputHeight,
        iconFlow = flowOf { filterAlt },
        iconSize = { large },
        styleFlow = flowOf {
            background {
                color { secondary.main }
            }
            border {
                color { primary.main }
                width(formationStyles.borderWidth)
            }
            hover {
                color { secondary.main }
                background {
                    color { FormationColors.MarkerYou.color }
                }
                border {
                    color { FormationColors.MarkerYou.color }
                }
            }
            radius(formationStyles.inputRadius)
        },
        tooltipText = translation[TL.Search.MANAGE_SEARCH_FILTERS],
        value = navValue,
        clickHandlers = navHandlers,
    )
}

fun RenderContext.coordinatesButtons(inputStore: Store<String>) {
    val maplibreMap: MaplibreMap by koinCtx.inject()

    inputStore.data.render { searchQuery ->
        searchQuery.findUTMCoordinates().takeIf { it.isNotEmpty() }?.let { utmCoordinates ->
            flexBox(
                {
                    width { full }
                    padding { small }
                    alignItems { center }
                    justifyContent { center }
                },
            ) {
                val utmCoordinate = utmCoordinates.first()
                genericButton(
                    title = flowOf("Go to: $utmCoordinate"),
                    styleFlow = flowOf(primaryButtonStyleParams),
                    width = { maxContent },
                    value = Unit,
                    valueHandlers = listOf(
                        SimpleHandler { flow, job ->
                            flow.filterNotNull().onEach { _ ->
                                maplibreMap.panTo(utmCoordinate.toPointCoordinates().toLatLon(), 1000)
                            }.launchIn(MainScope() + job)
                        },
                    ),
                )
            }
        }
        searchQuery.parsePoint()?.let { point ->
            flexBox(
                {
                    width { full }
                    padding { small }
                    alignItems { center }
                    justifyContent { center }
                },
            ) {
                genericButton(
                    title = flowOf("Go to: ${point.latitude}, ${point.longitude}"),
                    styleFlow = flowOf(primaryButtonStyleParams),
                    width = { maxContent },
                    value = Unit,
                    valueHandlers = listOf(
                        SimpleHandler { flow, job ->
                            flow.filterNotNull().onEach { _ ->
                                maplibreMap.panTo(point.toLatLon(), 1000)
                            }.launchIn(MainScope() + job)
                        },
                    ),
                )
            }
        }
        searchQuery.parseMgrs()?.let { mgrs ->
            flexBox(
                {
                    width { full }
                    padding { small }
                    alignItems { center }
                    justifyContent { center }
                },
            ) {
                genericButton(
                    title = flowOf("Go to: ${mgrs.usng()}"),
                    styleFlow = flowOf(primaryButtonStyleParams),
                    width = { maxContent },
                    value = Unit,
                    valueHandlers = listOf(
                        SimpleHandler { flow, job ->
                            flow.filterNotNull().onEach { _ ->
                                maplibreMap.panTo(mgrs.toPointCoordinate().toLatLon(), 1000)
                            }.launchIn(MainScope() + job)
                        },
                    ),
                )
            }
        }
    }
}

fun RenderContext.selectedSearchTagsList(
    activeSearchKeywordsStore: Store<List<String>>,
    activeSearchObjectTypesStore: Store<List<ObjectType>>,
    activeSearchReadOnlyKeywordsStore: Store<List<KeywordTag>>,
    activeSearchFieldValuesStore: Store<List<FieldValue>>,
    keywordTagType: KeywordTagType = KeywordTagType.SearchTag,
) {
    // optional Tags
    combine(
        activeSearchObjectTypesStore.data,
        activeSearchKeywordsStore.data,
        activeSearchReadOnlyKeywordsStore.data,
        activeSearchFieldValuesStore.data,
    ) { objTypes, keywords, otherTags, fieldValues ->
        objTypes.map { it.name }.toKeyWordTagsList(KeywordTagActionType.RemoveReadOnly) +
            otherTags.map { it.copy(actionType = KeywordTagActionType.RemoveReadOnly) } +
            keywords.toKeyWordTagsList(KeywordTagActionType.Remove) +
            fieldValues.toKeyWordTagsList(KeywordTagActionType.RemoveFieldValue)
    }.render { combinedKeywords ->
        if (combinedKeywords.isNotEmpty()) {
            div {
                keywordTagList(
                    keywords = flowOf(combinedKeywords),
                    keywordTagType = keywordTagType,
                )
            }
        }
    }
}
