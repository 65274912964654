package signup

import auth.WorkspaceOptionsStore
import auth.emptyWorkSpaceOptions
import dev.fritz2.components.compat.form
import dev.fritz2.components.compat.span
import dev.fritz2.components.flexBox
import dev.fritz2.components.stackUp
import dev.fritz2.core.RenderContext
import dev.fritz2.core.autofocus
import dev.fritz2.core.id
import dev.fritz2.core.invoke
import dev.fritz2.core.placeholder
import dev.fritz2.core.storeOf
import dev.fritz2.core.type
import dev.fritz2.core.values
import dev.fritz2.routing.MapRouter
import koin.koinCtx
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.mapNotNull
import localization.TL
import localization.Translation
import login.WorkspaceInputStore
import mainmenu.Pages
import model.ConfirmationType
import model.Overlay
import model.SignUpFromInvite
import model.ValidatePassword
import model.firstName
import model.firstPass
import model.inviteToken
import model.inviter
import model.lastName
import model.secondPass
import overlays.ConfirmationOverlayStore
import routing.MainController
import styling.primaryButtonStyleParams
import theme.FormationColors
import utils.makeRGBA
import webcomponents.baseLayout
import webcomponents.cardSubtitle
import webcomponents.cardTitle
import webcomponents.fullWidthFullContainer
import webcomponents.genericInput
import webcomponents.inputIconToggleButton
import webcomponents.inputLabelWrapper
import webcomponents.mainTitle
import webcomponents.oneButtonFooter

fun RenderContext.pageSignUpFromInvite() {
    val router: MapRouter by koinCtx.inject()
    val translation: Translation by koinCtx.inject()
    val signupFromInviteStore: SignupFromInviteStore by koinCtx.inject()
    val validatePasswordStore: ValidatePasswordStore by koinCtx.inject()
    val workspaceInputStore by koinCtx.inject<WorkspaceInputStore>()
    val workspaceOptionsStore by koinCtx.inject<WorkspaceOptionsStore>()
    val confirmationOverlayStore by koinCtx.inject<ConfirmationOverlayStore>()
    val mainController by koinCtx.inject<MainController>()
    val firstName = signupFromInviteStore.map(SignUpFromInvite.firstName())
    val lastName = signupFromInviteStore.map(SignUpFromInvite.lastName())
    val inviteToken = signupFromInviteStore.map(SignUpFromInvite.inviteToken())
    val inviter = signupFromInviteStore.map(SignUpFromInvite.inviter())
    val firstPass = validatePasswordStore.map(ValidatePassword.firstPass())
    val secondPass = validatePasswordStore.map(ValidatePassword.secondPass())

    // propagate the param value to the store if is there
    router.data.map { route -> route["ws"] ?: route["workspace"] } handledBy workspaceInputStore.update
    router.data.mapNotNull { route -> route["token"] } handledBy inviteToken.update
    router.data.map { route -> route["inviter"]?.replace("+", " ") } handledBy inviter.update

    workspaceOptionsStore.data.render { workspaceOptions ->
        if (workspaceOptions != null) {
            val workspaceExists = workspaceOptions != emptyWorkSpaceOptions
            if (!workspaceExists) {
                confirmationOverlayStore.show(
                    Overlay.ConfirmationScreen(
                        confirmationType = ConfirmationType.Close,
                        text = flowOf("We are very sorry, but this workspace does not seem to exist."),
                        primaryActionName = translation[TL.Signup.GO_TO_LOGIN],
                        primaryClickHandlers = listOf(mainController.logoutWithParams),
                    ),
                )
            } else confirmationOverlayStore.reset()
        }
    }

    baseLayout(
        expandable = false,
        content = {
            form(
                {
                    width { full }
                    height { full }
                    margin { none }
                    overflowY { auto }
                },
            ) {
                fullWidthFullContainer {
                    div("flex grow-2") { }

                    mainTitle(translation[TL.Signup.SIGN_UP])

                    div("flex grow-2") { }

                    // WORKSPACE
                    stackUp(
                        {
                            width { full }
                            alignItems { center }
                            justifyContent { center }
                            margins { bottom { small } }
                            textAlign { center }
                        },
                    ) {
                        spacing { tiny }
                        items {
                            inviter.data.render { inviterName ->
                                if (!inviterName.isNullOrEmpty()) {
                                    cardSubtitle(translation[TL.Signup.GOT_INVITED_BY, mapOf("userName" to inviterName)])
                                } else cardSubtitle(translation[TL.Signup.GOT_INVITED])

                            }
                            cardTitle(title = workspaceInputStore.data.mapNotNull { it }, margins = { })
                            workspaceInputStore.data.render { currentWorkspace ->
                                if (currentWorkspace == "formation4ukraine") {
                                    textLinkButton(
                                        text = translation[TL.Signup.READ_MORE_ABOUT].map { "($it)" },
                                        fontSize = { small },
                                        link = "https://tryformation.com/formation4ukraine",
                                        attributes = listOf("tabindex" to "-1"),
                                    )
                                }
                            }
                        }
                    }

                    div("flex grow-2") { }

                    // FIRST NAME & LAST NAME
                    stackUp(
                        {
                            width { full }
                            display { flex }
                            alignItems { stretch }
                            margins { bottom { small } }
                        },
                    ) {
                        spacing { smaller }
                        items {
                            inputLabelWrapper(
                                title = translation[TL.Signup.FIRST_NAME],
                                visibilityFlow = firstName.data.map {
                                    it.isNotBlank(
                                    )
                                },
                            ) {
                                genericInput(
                                    value = firstName.data.mapNotNull { it },
                                ) {
                                    id("inputFirstName")
                                    placeholder(translation[TL.Signup.FIRST_NAME])
                                    autofocus(true)
                                    attr("tabindex", "1")
                                    inputs.values() handledBy firstName.update
                                }
                            }
//                            focusInputObserver({ js("document.getElementById('inputFirstName').focus()") }, domNode)

                            inputLabelWrapper(
                                title = translation[TL.Signup.LAST_NAME],
                                visibilityFlow = lastName.data.map { it.isNotBlank() },
                            ) {
                                genericInput(
                                    value = lastName.data.mapNotNull { it },
                                ) {
                                    id("inputLastName")
                                    placeholder(translation[TL.Signup.LAST_NAME])
                                    attr("tabindex", "2")
                                    inputs.values() handledBy lastName.update
                                }
                            }
                        }
                    }

                    div("flex grow") { }

                    // PASSWORD
                    stackUp(
                        {
                            width { full }
                            display { flex }
                            alignItems { stretch }
                        },
                    ) {
                        spacing { smaller }
                        items {
                            inputLabelWrapper(
                                title = translation[TL.Login.PASSWORD],
                                visibilityFlow = firstPass.data.map { it.isNotBlank() },
                            ) {
                                val showFirstPassStore = storeOf(false, job)
                                genericInput(
                                    value = firstPass.data,
                                    type = "password",
                                    rightContentBox = {
                                        inputIconToggleButton(
                                            iconFalse = { eye },
                                            iconTrue = { eyeOff },
                                            boolStore = showFirstPassStore,
                                            attributes = listOf("tabindex" to "-1"),
                                        )
                                    },
                                ) {
                                    id("inputPassword")
                                    placeholder(translation[TL.Login.PASSWORD])
                                    attr("tabindex", "3")
                                    type(showFirstPassStore.data.map { if (it) "text" else "password" })
                                    inputs.values() handledBy firstPass.update
                                }
                                passwordStrengthIndicator(validatePasswordStore.data)
                            }
                            inputLabelWrapper(
                                title = translation[TL.Signup.RE_ENTER_PASSWORD],
                                visibilityFlow = secondPass.data.map {
                                    it.isNotBlank(
                                    )
                                },
                            ) {
                                val showSecondPassStore = storeOf(false, job)
                                genericInput(
                                    value = secondPass.data,
                                    type = "password",
                                    rightContentBox = {
                                        inputIconToggleButton(
                                            iconFalse = { eye },
                                            iconTrue = { eyeOff },
                                            boolStore = showSecondPassStore,
                                            attributes = listOf("tabindex" to "-1"),
                                        )
                                    },
                                ) {
                                    id("inputPassword-Re-enter")
                                    placeholder(translation[TL.Signup.RE_ENTER_PASSWORD])
                                    attr("tabindex", "4")
                                    type(showSecondPassStore.data.map { if (it) "text" else "password" })
                                    inputs.values() handledBy secondPass.update
                                }
                            }
                        }
                    }

                    flexBox(
                        {
                            width { full }
                            height { minContent }
                            justifyContent { center }
                            padding { small }
                        },
                    ) {
                        span(
                            {
                                color { FormationColors.RedError.color }
                                fontSize { smaller }
                                textAlign { center }
                                background {
                                    color { makeRGBA(FormationColors.RedError.color, 0.1) }
                                }
                                radius { larger }
                                paddings {
                                    vertical { tiny }
                                    horizontal { smaller }
                                }
                            },
                        ) {
                            className(
                                validatePasswordStore.data.map { pwStack ->
                                    if (pwStack.firstPass.isNotBlank()
                                        && pwStack.secondPass.isNotBlank()
                                        && !pwStack.isMatching
                                    ) {
                                        "visible"
                                    } else {
                                        "invisible"
                                    }
                                },
                            )
                            translation[TL.UserProfile.PASSWORD_NO_MATCH].renderText(into = this)
                        }
                    }

                    stackUp(
                        {
                            flex { grow { "3" } }
                            alignItems { center }
                            justifyContent { spaceAround }
                        },
                    ) {
                        spacing { giant }
                        items {
                            textButton(
                                text = translation[TL.Signup.ALREADY_HAVE_ACCOUNT],
                                attributes = listOf(
                                    "tabindex" to "-1",
                                    "type" to "button",
                                    "onClick" to "event.preventDefault(); blurPassword();",
                                ),
                                replaceRoute = workspaceInputStore.current?.let { workspace ->
                                    mapOf(
                                        "page" to Pages.Login.name,
                                        "ws" to workspace,
                                    )
                                } ?: mapOf("page" to Pages.Login.name),
                            )
                        }
                    }
                }
            }
        },
        footer = {
            oneButtonFooter(
                title = translation[TL.Signup.CREATE_ACCOUNT],
                state = signupFromInviteStore.watchInputs(),
                styleParams = primaryButtonStyleParams,
                value = Unit,
                clickHandlers = listOf(signupFromInviteStore.signUpFromInvite),
                attributes = listOf(
                    "tabindex" to "5",
                    "onClick" to "event.preventDefault(); blurPassword();",
                ),
                tracker = signupFromInviteStore.signingUpTracker,
                loadingText = translation[TL.Signup.CREATE_ACCOUNT_ONGOING],
            )
        },
    )
}
