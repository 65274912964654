package maplibreGL

import apiclient.geoobjects.BuildingAndFloorTags
import apiclient.geoobjects.BuildingFormat
import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.LatLon
import apiclient.geoobjects.ObjectType
import apiclient.tags.getUniqueTag
import dev.fritz2.components.compat.Div
import dev.fritz2.core.HtmlTag
import dev.fritz2.core.RenderContext
import dev.fritz2.core.Scope
import koin.koinCtx
import kotlin.collections.Map
import kotlinx.coroutines.Job
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.builtins.nullable
import kotlinx.serialization.builtins.serializer
import kotlinx.serialization.json.Json
import location.geolocation.GeoPosition
import model.User
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import theme.FormationColors

fun getDomElement(id: String, context: RenderContext, content: HtmlTag<HTMLDivElement>.() -> Unit): HTMLElement {
    return Div(tagName = "div", id = id, job = Job(parent = context.job), scope = Scope()).apply {
        content()
    }.domNode
}

private val json: Json by koinCtx.inject()

fun generateMapMarker(
    geoObject: GeoObjectDetails,
    connectables: Map<String, MaplibreMap.ConnectableAndPosition>? = null,
    overlappingShapeIds: Set<String>? = null,
    activeObjectId: String? = null,
    activeUserId: String? = null,
    activeHistoryEventId: String? = null,
    desaturated: Boolean = false,
    highlighted: Boolean = false,
    activeSourceConnectorId: String? = null,
    activeTargetConnectorId: String? = null,
    activeTargetId: String? = null,
    activeConnectionDeleteConnectorId: String? = null,
    highlightedConnector: String? = null,
    highlightedConnection: String? = null,
): MapMarker? {
    val activeObject = activeObjectId == geoObject.id
    val activeUser = activeUserId == geoObject.ownerId
    val activeHistoryEntry = activeHistoryEventId == geoObject.id

    return when (geoObject.objectType) {
        ObjectType.POI -> {
            MapMarkerPoint(
                geoObject = geoObject,
                isActive = activeObject,
                isDesaturated = desaturated,
                isHighlighted = highlighted,
            )
        }

        ObjectType.UserMarker -> {
            MapMarkerUser(
                geoObject,
                isActive = activeUser,
                isDesaturated = desaturated,
                isHighlighted = highlighted,
            )
        }

        ObjectType.ObjectMarker -> {
            MapMarkerTrackedObject(
                geoObject = geoObject,
                isActive = activeObject,
                isDesaturated = desaturated,
                isHighlighted = highlighted,
            )
        }

        ObjectType.Task -> {
            MapMarkerTask(
                geoObject = geoObject,
                isActive = activeObject,
                isDesaturated = desaturated,
                isHighlighted = highlighted,
            )
        }

        ObjectType.Event -> {
            MapMarkerEvent(
                geoObject = geoObject,
                isActive = activeObject,
                isDesaturated = desaturated,
                isHighlighted = highlighted,
            )
        }

        ObjectType.Building -> {
            MapMarkerBuilding(
                geoObject = geoObject,
                isActive = activeObject,
                isDesaturated = desaturated,
                isHighlighted = highlighted,
            )
        }

        ObjectType.Floor -> {
            when (geoObject.tags.getUniqueTag(BuildingAndFloorTags.BuildingFormat)) {
                BuildingFormat.Image.name -> {
                    val (tlLon, tlLat) = json.decodeFromString(
                        deserializer = ListSerializer(Double.serializer().nullable),
                        string = geoObject.tags.getUniqueTag(BuildingAndFloorTags.TopLeft) ?: "",
                    ).filterNotNull()
                    val (trLon, trLat) = json.decodeFromString(
                        deserializer = ListSerializer(Double.serializer().nullable),
                        string = geoObject.tags.getUniqueTag(BuildingAndFloorTags.TopRight) ?: "",
                    ).filterNotNull()
                    val (blLon, blLat) = json.decodeFromString(
                        deserializer = ListSerializer(Double.serializer().nullable),
                        string = geoObject.tags.getUniqueTag(BuildingAndFloorTags.BottomLeft) ?: "",
                    ).filterNotNull()
                    val (brLon, brLat) = json.decodeFromString(
                        deserializer = ListSerializer(Double.serializer().nullable),
                        string = geoObject.tags.getUniqueTag(BuildingAndFloorTags.BottomRight) ?: "",
                    ).filterNotNull()
                    val url = geoObject.tags.getUniqueTag(BuildingAndFloorTags.FloorPlanImageUrl)
                    if (url != null) {
                        MapMarkerFloorImage(
                            id = geoObject.id,
                            lngLat = geoObject.latLon.toLngLat(),
                            objectType = geoObject.objectType,
                            title = geoObject.title,
                            imageOverlays = listOf(
                                MaplibreImageOverlayRotated(
                                    type = ObjectType.Floor,
                                    id = geoObject.id,
                                    imageUrl = url, // + if (url != null && url.startsWith("https://cdn.indooratlas.com")) { ".png" } else { "" },
                                    topLeft = LatLon(tlLat, tlLon),
                                    topRight = LatLon(trLat, trLon),
                                    bottomLeft = LatLon(blLat, blLon),
                                    bottomRight = LatLon(brLat, brLon),
                                ),
                            ),
                        )
                    } else {
                        console.warn("geoObject did not provide a valid URL for floorplan")
                        null
                    }
                }

                BuildingFormat.GeoJson.name -> {
                    MapMarkerGeoJSON(
                        id = geoObject.id,
                        lngLat = geoObject.latLon.toLngLat(),
                        objectType = geoObject.objectType,
                        title = geoObject.title,
                        geoJSONs = listOf(
                            MaplibreGeoJSON(
                                type = ObjectType.Floor,
                                id = geoObject.id,
                                title = "<H3><b>${geoObject.title}</b></H3>",
                                geometry = geoObject.geometry!!,
                                geoJSONType = "normal",
                                fillColor = FormationColors.BlueDeep.color,
                                fillOpacity = 0.5,
                                lineType = "normal",
                                lineColor = FormationColors.BlueDeep.color,
                                lineWidth = 2.0,
                                lineOpacity = 1.0,
                            ),
                        ),
                    )
                }

                else -> null
            }
        }

        ObjectType.Unit -> {
            MapMarkerGeoJSON(
                id = geoObject.id,
                lngLat = geoObject.latLon.toLngLat(),
                objectType = geoObject.objectType,
                title = geoObject.title,
                geoJSONs = listOf(
                    MaplibreGeoJSON(
                        type = ObjectType.Unit,
                        id = geoObject.id,
                        geometry = geoObject.geometry!!,
                        title = "<H5><b>${geoObject.title}</b></H5>",
                        geoJSONType = "normal",
                        fillColor = FormationColors.White.color,
                        fillOpacity = 1.0,
                        lineType = "normal",
                        lineColor = FormationColors.BlueDeep.color,
                        lineWidth = 2.0,
                        lineOpacity = 1.0,
                    ),
                ),
            )
        }

        ObjectType.Area -> {
            MapMarkerArea(
                geoObject = geoObject,
                isActive = activeObject,
                isDesaturated = desaturated,
                isHighlighted = highlighted,
            )
        }

        ObjectType.Zone -> {
            MapMarkerZone(
                geoObject = geoObject,
                isActive = activeObject,
                isDesaturated = desaturated,
                isHighlighted = highlighted,
            )
        }

        ObjectType.GeoFence -> {
            MapMarkerGeofence(
                geoObject = geoObject,
                isActive = activeObject,
                isDesaturated = desaturated,
                isHighlighted = highlighted,
            )
        }

        ObjectType.Notification -> null // listOf()
        ObjectType.TransientMarker -> null // listOf()
        ObjectType.CurrentUserMarker -> null // listOf()
        ObjectType.HistoryEntry -> {
            MapMarkerHistoryEntry(
                geoObject = geoObject,
                isActive = activeHistoryEntry,
                isDesaturated = desaturated,
                isHighlighted = highlighted,
                activeHistoryEntry = activeHistoryEntry,
            )
        }

        ObjectType.GeneralMarker -> {
            // TODO change to not only connectableShapes but, generalMarker
            MapMarkerConnectableShape(
                geoObject = geoObject,
                connectableShapes = connectables ?: emptyMap(),
                overlappingShapeIds = overlappingShapeIds ?: emptySet(),
                activeSourceConnectorId = activeSourceConnectorId ?: "",
                activeTargetConnectorId = activeTargetConnectorId ?: "",
                activeTargetObjectId = activeTargetId ?: "",
                activeConnectionDeleteConnectorId = activeConnectionDeleteConnectorId ?: "",
                highlightedConnector = highlightedConnector ?: "",
                highlightedConnection = highlightedConnection ?: "",
                isActive = activeObject,
                isDesaturated = desaturated,
                isHighlighted = highlighted,
            )
        }

        else -> null
    }
}

fun generateMyMaplibreUserMarker(
    user: User,
    position: GeoPosition,
): MapMarkerMyUser {
    return MapMarkerMyUser(
        user = user,
        position = position,
    )
}
