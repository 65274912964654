package signup

import apiclient.groups.CreateWorkspaceAndUser
import apiclient.groups.workspaceCreationRequest
import apiclient.users.restSignupEmailAddressAvailable
import apiclient.util.randomWorkspaceNames
import com.tryformation.localization.Translatable
import dev.fritz2.core.RenderContext
import dev.fritz2.core.RootStore
import dev.fritz2.core.SimpleHandler
import dev.fritz2.core.Store
import dev.fritz2.core.disabled
import dev.fritz2.core.placeholder
import dev.fritz2.core.storeOf
import dev.fritz2.core.type
import dev.fritz2.routing.MapRouter
import koin.koinCtx
import koin.withKoin
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.debounce
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.mapNotNull
import localization.TL
import localization.Translation
import login.HostConfigStore
import mainmenu.Pages
import mainmenu.RouterStore
import model.email
import model.isCreated
import model.workspaceName
import overlays.BusyStore
import overlays.dotSpinner
import theme.FormationIcons
import twcomponents.twCardSectionH1
import twcomponents.twCenteredLink
import twcomponents.twIconMedium
import twcomponents.twInputField
import twcomponents.twInputTextField
import twcomponents.twMarkdownContent
import twcomponents.twMediumIconButton
import twcomponents.twPrimaryButton
import twcomponents.twRevertButton
import twcomponents.twRightAlignedButtonRow
import twcomponents.twTooltip
import webcomponents.inputLabelWrapper
import workspacetools.workspaceoptions.WorkspaceNameState
import workspacetools.workspaceoptions.checkName
import workspacetools.workspaceoptions.normalizeName

enum class EmailAvailabilityState : Translatable {
    Empty,
    Checking,
    Invalid,
    Unavailable,
    Available,
    ;

    override val prefix: String = "emailavailabilitystate"
}

data class WorkspaceCreateData(
    val workspaceName: String,
    val email: String,
    val isCreated: Boolean = false,
) {
    companion object
}

class WorkspaceCreationStore : RootStore<WorkspaceCreateData>(WorkspaceCreateData("", ""), Job()) {

    val busyStore: BusyStore by koinCtx.inject()
    val hostConfigStore: HostConfigStore by koinCtx.inject()
    private val anonymousClient = hostConfigStore.anonymousClient()

    private val isCreated = map(WorkspaceCreateData.isCreated())

    private val randomNames = randomWorkspaceNames()
    private var randomCounter = 0

    val getGeneratedWorkspaceName = handle { current ->
        randomCounter += 1
        current.copy(
            workspaceName = randomNames.elementAt(randomCounter),
        )
    }

    val createWsAndUser = SimpleHandler<Unit> { data, _ ->
        data handledBy {
            busyStore.handleApiCall(
                supplier = {
                    anonymousClient.workspaceCreationRequest(
                        CreateWorkspaceAndUser(
                            baseUrl = "",
                            workspaceName = current.workspaceName,
                            email = current.email,
                            password = null,
                            firstName = null,
                            lastName = null,
                        ),
                    )
                },
                processResult = { result ->
                    console.log("Workspace created successfully!", result)
                    if (result == "Ok") {
                        isCreated.update(true)
                    }
                },
                processError = { error ->
                    console.log("Workspace creation failed!", error.message)
                },
            )
        }
    }

    init {
        getGeneratedWorkspaceName(Unit)
    }
}

fun RenderContext.pageCreateWS() {

    val workspaceCreationStore: WorkspaceCreationStore by koinCtx.inject()

    val translation: Translation by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()
    val router: MapRouter by koinCtx.inject()
    val workspaceNameStateStore = storeOf(WorkspaceNameState.NotModified)
    val email = workspaceCreationStore.map(WorkspaceCreateData.email())
    val workspace = workspaceCreationStore.map(WorkspaceCreateData.workspaceName())
    val isCreated = workspaceCreationStore.map(WorkspaceCreateData.isCreated())
    val emailAvailabilityStateStore = storeOf(EmailAvailabilityState.Invalid)
    val workspaceNotExists = workspaceNameStateStore.data.map { state ->
        state != WorkspaceNameState.Available
    }
    email.data.debounce(500) handledBy { mail ->
        checkEmail(mail, emailAvailabilityStateStore)
    }
    workspace.data.debounce(500) handledBy { workspaceName ->
        checkName("", workspaceName.normalizeName(), workspaceNameStateStore)
    }

    router.data.mapNotNull { route -> route["email"] } handledBy email.update

    div("flex flex-col w-full h-full items-center justify-center") {
        div("flex flex-col h-full w-full sm:w-[500px] items-center justify-between overflow-y-auto") {

            // Title Formation
            span(
                "flex flex-grow place-items-center text-2xl sm:text-3xl font-thin tracking-widest px-2",
            ) {
                +"F O R M A T I O N"
            }

            // Page content
            isCreated.data.render { wsCreated ->
                div("flex flex-grow flex-col items-center w-full gap-4 px-4") {

                    // Section A -> Confirmation after workspace creation request is send
                    if (wsCreated) {
                        // Title
                        twCardSectionH1 { translation[TL.CreateWorkspaceTexts.WORKSPACE_CREATED_TITLE].renderText() }

                        // Text after workspace creation request is send
                        div("flex items-stretch w-full text-center") {
                            twMarkdownContent(TL.CreateWorkspaceTexts.WORKSPACE_CREATED_TEXT)
                        }

                        div("flex flex-grow flex-col items-center w-full gap-4 px-4 mt-4") {

                            div("flex items-stretch justify-center w-full text-center") {
                                twMarkdownContent(TL.CreateWorkspaceTexts.WORKSPACE_CREATED_CLOSE_PAGE)
                            }

                            twCenteredLink {
                                translation[TL.CreateWorkspaceTexts.RESEND_CREATE_WORKSPACE_REQUEST_BUTTON].renderText(into = this)
                                clicks handledBy workspaceCreationStore.createWsAndUser
                            }

                            twCenteredLink {
                                translation[TL.CreateWorkspaceTexts.CHANGE_EMAIL_OR_WS_BUTTON].renderText(into = this)
                                clicks.map { false } handledBy isCreated.update
                            }
                        }
                    }

                    // Section B -> Form with inputs to send a workspace creation request
                    else {
                        // Title
                        twCardSectionH1 { translation[TL.CreateWorkspaceTexts.CREATE_WORKSPACE_TITLE].renderText() }
                        // Explanation text
                        div("flex items-center w-full text-center") {
                            twMarkdownContent(TL.CreateWorkspaceTexts.WORKSPACE_CHOOSE_TEXT)
                        }
                        // Form elements to create workspace
                        // Email input
                        div("flex flex-col items-center w-full gap-2") {
//                    h2 {
//                        translate(TL.Login.EMAIL)
//                    }

                            inputLabelWrapper(
                                title = translation[TL.Login.EMAIL],
                                visibilityFlow = email.data.map { it.isNotBlank() },
                            ) {
                                twInputField(email) {
                                    twInputTextField {
                                        placeholder(translation[TL.Login.EMAIL])
                                        type("email")
                                    }
                                    twRightAlignedButtonRow {
                                        emailAvailabilityStateStore.data.render { state ->
                                            when (state) {
                                                EmailAvailabilityState.Checking -> {
                                                    dotSpinner(6)
                                                }

                                                EmailAvailabilityState.Invalid -> {
                                                    div("text-red-500 whitespace-nowrap") {
                                                        twIconMedium(FormationIcons.Caution)
                                                    }.twTooltip {
                                                        translation[state].renderText()
                                                    }
                                                }

                                                EmailAvailabilityState.Unavailable -> {
                                                    div("text-red-500 whitespace-nowrap") {
                                                        twIconMedium(FormationIcons.Block)
                                                    }.twTooltip {
                                                        translation[state].renderText()
                                                    }
                                                }

                                                EmailAvailabilityState.Available -> {
                                                    div("text-green-500 whitespace-nowrap") {
                                                        twIconMedium(FormationIcons.Available)
                                                    }.twTooltip {
                                                        translation[state].renderText()
                                                    }
                                                }
                                                // EmailAvailabilityState.Empty
                                                else -> {}
                                            }
                                            twRevertButton(email, "").twTooltip {
                                                +"Clear"
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        // Workspace input
                        div("flex flex-col items-center w-full gap-2") {
//                    h2 {
//                        translate(WorkspaceOptionsTexts.WorkspaceName)
//                    }

                            inputLabelWrapper(
                                title = translation[TL.CreateWorkspaceTexts.WORKSPACE_INPUT_PLACEHOLDER],
                                visibilityFlow = workspace.data.map { it.isNotBlank() },
                            ) {
                                twInputField(workspace) {
                                    twInputTextField {
                                        placeholder(translation[TL.Login.WORKSPACE])
                                    }
                                    twRightAlignedButtonRow {
                                        combine(workspace.data, workspaceNameStateStore.data.debounce(300)) { workspace, nameState ->
                                            Pair(workspace.isNotBlank(), nameState)
                                        }.render { (isNotBlank, nameState) ->
                                            if (isNotBlank) {
                                                when (nameState) {
                                                    WorkspaceNameState.Checking -> {
                                                        dotSpinner(6)
                                                    }

                                                    WorkspaceNameState.Invalid -> {
                                                        div("text-red-500 whitespace-nowrap") {
                                                            twIconMedium(FormationIcons.Caution)
                                                        }.twTooltip {
                                                            translation[nameState].renderText()
                                                        }
                                                    }

                                                    WorkspaceNameState.Unavailable -> {
                                                        div("text-red-500 whitespace-nowrap") {
                                                            twIconMedium(FormationIcons.Block)
                                                        }.twTooltip {
                                                            translation[nameState].renderText()
                                                        }
                                                    }

                                                    WorkspaceNameState.Available -> {
                                                        div("text-green-500 whitespace-nowrap") {
                                                            twIconMedium(FormationIcons.Available)
                                                        }.twTooltip {
                                                            translation[nameState].renderText()
                                                        }
                                                    }
                                                    // WorkspaceNameState.NotModified
                                                    else -> {}
                                                }
                                            }
                                            twRevertButton(workspace, "").twTooltip {
                                                +"Clear"
                                            }
                                            twMediumIconButton(icon = FormationIcons.Update) {
                                                clicks handledBy workspaceCreationStore.getGeneratedWorkspaceName
                                            }.twTooltip {
                                                +"Generate a name"
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        // Button to create workspace and account
                        twPrimaryButton(
                            text = TL.CreateWorkspaceTexts.CREATE_WORKSPACE_BUTTON,
                        ) {
                            disabled(
                                combine(emailAvailabilityStateStore.data, workspaceNotExists) { emailAvailabilityState, workspaceNotExists ->
                                    emailAvailabilityState != EmailAvailabilityState.Available || workspaceNotExists
                                },
                            )
                            clicks handledBy workspaceCreationStore.createWsAndUser
                        }

                        div("flex flex-col flex-grow items-center justify-center gap-4") {
                            // Go back to login with password
                            twCenteredLink {
                                translation[TL.CreateWorkspaceTexts.LOGIN_TO_EXISTING_BUTTON].renderText(into = this)
                                clicks.map { Pages.Login.route } handledBy routerStore.addOrReplaceRoute
                            }
                        }
                    }
                }
            }
        }
    }
}

suspend fun checkEmail(email: String, emailAvailabilityStateStore: Store<EmailAvailabilityState>) {
    withKoin {
        when {
            email.isBlank() -> emailAvailabilityStateStore.update(EmailAvailabilityState.Empty)

            email.matchesEmailPattern() -> {
                emailAvailabilityStateStore.update(EmailAvailabilityState.Checking)
                if (isEmailAvailable(email)) {
                    emailAvailabilityStateStore.update(EmailAvailabilityState.Available)
                } else {
                    emailAvailabilityStateStore.update(EmailAvailabilityState.Unavailable)
                }
            }

            else -> {
                emailAvailabilityStateStore.update(EmailAvailabilityState.Invalid)
            }
        }
    }
}

suspend fun isEmailAvailable(email: String): Boolean {
    val hostConfigStore: HostConfigStore by koinCtx.inject()
    val anonymousFormationClient = hostConfigStore.anonymousClient()

    return anonymousFormationClient.restSignupEmailAddressAvailable(email).fold(
        { result ->
            console.log("Checked Email ($email) is ${if (result) "" else "not"} availabe.", result)
            result
        },
        { error ->
            console.log("Error checking email availability.", error.message)
            false
        },
    )
}

fun String.matchesEmailPattern(): Boolean {
    return "^[a-zA-Z0-9.!#\$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*\$"
        .toRegex().matches(this)
}
