package login

import data.users.profile.UpdatePasswordStore
import dev.fritz2.components.compat.div
import dev.fritz2.components.compat.span
import dev.fritz2.components.flexBox
import dev.fritz2.core.RenderContext
import dev.fritz2.core.RootStore
import dev.fritz2.core.id
import dev.fritz2.core.placeholder
import dev.fritz2.core.storeOf
import dev.fritz2.core.type
import dev.fritz2.core.values
import dev.fritz2.routing.MapRouter
import koin.koinCtx
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.map
import localization.TL
import localization.Translation
import mainmenu.Pages
import model.ResetPasswordData
import model.ValidatePassword
import model.email
import model.firstPass
import model.isValid
import model.secondPass
import model.token
import signup.passwordStrengthIndicator
import styling.primaryButtonStyleParams
import styling.secondaryButtonStyleParams
import theme.FormationColors
import utils.focusInputObserver
import utils.makeRGBA
import webcomponents.baseLayout
import webcomponents.cardTitle
import webcomponents.contentScrollBox
import webcomponents.genericInput
import webcomponents.inputIconToggleButton
import webcomponents.twoButtonFooter

class ResetPasswordDataStore : RootStore<ResetPasswordData>(
    initialData = ResetPasswordData("", ""),
    job = Job(),
)

fun RenderContext.pageResetPasswort() {
    val updatePasswordStore = koinCtx.get<UpdatePasswordStore>()
    val firstPass = updatePasswordStore.map(ValidatePassword.firstPass())
    val secondPass = updatePasswordStore.map(ValidatePassword.secondPass())
    val isValid = updatePasswordStore.map(ValidatePassword.isValid())
    val router: MapRouter by koinCtx.inject()
    val translation: Translation by koinCtx.inject()
    val workspaceInputStore: WorkspaceInputStore by koinCtx.inject()
    val resetPasswordDataStore: ResetPasswordDataStore by koinCtx.inject()
    val email = resetPasswordDataStore.map(ResetPasswordData.email())
    val token = resetPasswordDataStore.map(ResetPasswordData.token())

    router.current["token"]?.let { token.update(it) }
    router.current["email"]?.let { email.update(it) }
    router.current["ws"]?.let { workspaceInputStore.update(it) }

    baseLayout(
        expandable = false,
        content = {
            contentScrollBox(margins = { top { normal } }) {
                div("flex grow") { }
                flexBox({
                    width { full }
                    justifyContent { center }
                    flex { grow { "1" } }
                }) { cardTitle(translation[TL.UserProfile.PASSWORD_VIEW2_HEADER]) }
                flexBox({
                    direction { column }
                    width { full }
                    alignItems { center }
                    justifyContent { spaceEvenly }
                    textAlign { center }
                    padding { normal }
                    flex { grow { "1" } }
                }) {
                    p { translation[TL.UserProfile.PASSWORD_VIEW2_TITLE1].renderText(into = this) }
                    div("w-full h-[52px}") {
                        val showFirstPassStore = storeOf(false, job)
                        genericInput(
                            value = firstPass.data,
                            type = "password",
                            rightContentBox = {
                                inputIconToggleButton(
                                    iconFalse = { eye },
                                    iconTrue = { eyeOff },
                                    boolStore = showFirstPassStore,
                                    attributes = listOf("tabindex" to "-1")
                                )
                            }
                        ) {
                            id("firstPasswordInput")
                            placeholder(translation[TL.UserProfile.NEW_PASSWORD])
                            type(showFirstPassStore.data.map { if (it) "text" else "password" })
                            attr("tabindex", "1")
                            inputs.values() handledBy firstPass.update
                        }
                        focusInputObserver({ js("document.getElementById('firstPasswordInput').focus()") }, domNode)
                        passwordStrengthIndicator(updatePasswordStore.data)
                    }
                }
                flexBox({
                    direction { column }
                    width { full }
                    alignItems { center }
                    justifyContent { spaceEvenly }
                    textAlign { center }
                    padding { normal }
                    flex { grow { "1" } }
                }) {
                    p { translation[TL.UserProfile.PASSWORD_VIEW2_TITLE2].renderText(into = this) }
                    val showSecondPassStore = storeOf(false, job)
                    genericInput(
                        value = secondPass.data,
                        type = "password",
                        rightContentBox = {
                            inputIconToggleButton(
                                iconFalse = { eye },
                                iconTrue = { eyeOff },
                                boolStore = showSecondPassStore,
                                attributes = listOf("tabindex" to "-1")
                            )
                        }
                    ) {
                        id(secondPass.id)
                        placeholder(translation[TL.UserProfile.NEW_PASSWORD_RE])
                        type(showSecondPassStore.data.map { if (it) "text" else "password" })
                        attr("tabindex", "2")
                        inputs.values() handledBy secondPass.update
                    }
                }
                div({
                    css("align-self: center;")
                    height { "25px" }
                    margins { top { small } }
                }) {
                    span({
                        color { FormationColors.RedError.color }
                        fontSize { smaller }
                        textAlign { center }
                        background {
                            color { makeRGBA(FormationColors.RedError.color, 0.1) }
                        }
                        radius { larger }
                        paddings {
                            vertical { tiny }
                            horizontal { smaller }
                        }
                    }) {
                        className(updatePasswordStore.data.map { pwStack ->
                            if (pwStack.firstPass.isNotBlank()
                                && pwStack.secondPass.isNotBlank()
                                && !pwStack.isMatching
                            ) {
                                "visible"
                            } else {
                                "invisible"
                            }
                        })
                        translation[TL.UserProfile.PASSWORD_NO_MATCH].renderText(into = this)
                    }
                }
                div("flex grow") { }
            }
        },
        footer = {
            twoButtonFooter(
                secondaryTitle = translation[TL.General.CANCEL],
                secondaryStyleParams = secondaryButtonStyleParams,
                secondaryClickHandlers = listOf(updatePasswordStore.reset),
                secondaryRoutingMap = Pages.Login.route,
                secondaryAttributes = listOf("tabindex" to "3"),
                primaryTitle = translation[TL.Login.RESET_PASSWORD],
                primaryState = isValid.data,
                primaryStyleParams = primaryButtonStyleParams,
                primaryValue = Unit,
                primaryClickHandlers = listOf(updatePasswordStore.resetPasswordWithToken),
                primaryRoutingMap = null,
                primaryAttributes = listOf("tabindex" to "4")
            )
        }
    )

    firstPass.data.map { } handledBy updatePasswordStore.updateValidation
    secondPass.data.map { } handledBy updatePasswordStore.updateValidation
}
