@file:Suppress("REDUNDANT_ELSE_IN_WHEN")

package analyticsdashboard

import apiclient.analytics.AnalyticsData
import apiclient.analytics.AnalyticsType
import apiclient.analytics.ColumnType
import apiclient.analytics.Dashboard
import apiclient.analytics.DashboardElement
import apiclient.analytics.DashboardParamNames
import apiclient.analytics.columNames
import apiclient.analytics.rows
import dev.fritz2.styling.theme.IconDefinition
import flatpickr.flatpickr
import koin.koinCtx
import kotlinx.serialization.json.JsonPrimitive
import kotlinx.serialization.json.doubleOrNull
import kotlinx.serialization.json.intOrNull
import kotlinx.serialization.json.longOrNull
import localization.TL
import localization.Translation
import theme.FormationUIIcons


data class JsonPrimitiveTableWithRows(
    val title: String,
    val columnNames: List<String>,
    val rows: List<List<JsonPrimitive>>
) {
    override fun toString(): String {
        return "$title\n\n${columnNames.joinToString("\t")}\n${rows.joinToString("\n") { it.joinToString("\t") }}"
    }
}

fun AnalyticsData.JsonPrimitiveTable.toJsonPrimitiveTableWithRows(): JsonPrimitiveTableWithRows {
    return JsonPrimitiveTableWithRows(
        title = title,
        columnNames = columNames,
        rows = (0 until (columns.firstOrNull()?.values?.size ?: 0)).map { index ->
            columns.map { it.values[index] }
        },
    )
}

fun JsonPrimitiveTableWithRows.toJsonPrimitiveTable(): AnalyticsData.JsonPrimitiveTable {
    return AnalyticsData.JsonPrimitiveTable(
        title = title,
        columns = columnNames.indices.map { columnIndex ->
            AnalyticsData.JsonPrimitiveColumn(
                columnName = columnNames[columnIndex],
                values = (rows.indices).map { rowIndex ->
                    rows[rowIndex][columnIndex]
                },
            )
        },
    )
}

data class DashboardTableSortedColumnState(
    val columnName: String,
    val columnIndex: Int,
    val sorting: Sorting,
)

enum class Sorting {
    Ascending {
        override val icon = FormationUIIcons.ChevronDown.icon
    },
    Descending {
        override val icon = FormationUIIcons.ChevronUp.icon
    },
    None {
        override val icon = FormationUIIcons.Select.icon
    },
    ;

    abstract val icon: IconDefinition
}

fun Sorting?.next(): Sorting {
    return when (this) {
        Sorting.None, null -> Sorting.Descending
        Sorting.Descending -> Sorting.Ascending
        Sorting.Ascending -> Sorting.None
        else -> Sorting.None
    }
}

fun sortDashboardTableRows(
    analyticsType: AnalyticsType,
    data: AnalyticsData.JsonPrimitiveTable,
    sortingState: DashboardTableSortedColumnState?
): AnalyticsData.JsonPrimitiveTable {
    val sortedRows = when (sortingState?.sorting) {
        Sorting.None, null -> data.rows
        Sorting.Ascending -> when (analyticsType.returnColumns[sortingState.columnIndex].columnType) {
            ColumnType.Text -> data.rows.sortedBy { it[sortingState.columnIndex].content }
            ColumnType.Count -> data.rows.sortedBy { it[sortingState.columnIndex].intOrNull }
            ColumnType.TimeMilliseconds -> data.rows.sortedBy { it[sortingState.columnIndex].longOrNull }
            ColumnType.DoubleValue -> data.rows.sortedBy { it[sortingState.columnIndex].doubleOrNull }
            else -> data.rows.sortedBy { it[sortingState.columnIndex].content }
        }

        Sorting.Descending -> when (analyticsType.returnColumns[sortingState.columnIndex].columnType) {
            ColumnType.Text -> data.rows.sortedByDescending { it[sortingState.columnIndex].content }
            ColumnType.Count -> data.rows.sortedByDescending { it[sortingState.columnIndex].intOrNull }
            ColumnType.TimeMilliseconds -> data.rows.sortedByDescending { it[sortingState.columnIndex].longOrNull }
            ColumnType.DoubleValue -> data.rows.sortedByDescending { it[sortingState.columnIndex].doubleOrNull }
            else -> data.rows.sortedByDescending { it[sortingState.columnIndex].content }
        }

        else -> data.rows
    }
    return data.toJsonPrimitiveTableWithRows().copy(rows = sortedRows).toJsonPrimitiveTable()
}

fun filterDashboardTableData(textQuery: String, data: AnalyticsData.JsonPrimitiveTable): AnalyticsData.JsonPrimitiveTable {
    val filteredRows = textQuery.takeIf { it.isNotBlank() }?.let { query ->
        data.rows.filter { row ->
            row.map { column ->
                column.content.contains(query)
            }.reduceOrNull { a, b -> a || b } ?: false
        }
    } ?: data.rows
    return data.toJsonPrimitiveTableWithRows().copy(rows = filteredRows).toJsonPrimitiveTable()
}

fun msTowdhms(ms: Long): String {
    val translation by koinCtx.inject<Translation>()
    val s = translation.getString(TL.TimeAbbrevations.SECONDS)
    val m = translation.getString(TL.TimeAbbrevations.MINUTES)
    val h = translation.getString(TL.TimeAbbrevations.HOURS)
    val d = translation.getString(TL.TimeAbbrevations.DAYS)
    val w = translation.getString(TL.TimeAbbrevations.WEEKS)
    val secondsR = (ms / 1000) % 60
    val minutesR = (ms / 60000) % 60
    val hoursR = (ms / 3600000) % 24
    val daysR = (ms / 86400000) % 7
    val weeksR = (ms / 604800000).toInt()
    return (if (weeksR >= 1) "$weeksR$w " else "") +
        (if (daysR >= 1) "$daysR$d " else if (weeksR > 1) "0$d " else "") +
        (if (hoursR >= 1) "$hoursR$h " else if (daysR > 1) "0$h " else "") +
        (if (minutesR >= 1) "$minutesR$m " else if (hoursR > 1) "0$m " else "") +
        (if (secondsR >= 1) "$secondsR$s" else "") +
        (if (ms < 1000) "-" else "")
}

fun getPrintableTable(element: DashboardElement, data: AnalyticsData.JsonPrimitiveTable, dashboard: Dashboard): String {
    val translation by koinCtx.inject<Translation>()
    val analyticsTimeFilterStore: AnalyticsTimeFilterStore by koinCtx.inject()
    val picker = analyticsTimeFilterStore.pickerStartDate
    val fromTime = analyticsTimeFilterStore.current.filterStartDate?.let { picker?.formatDate(flatpickr.parseDate(it, "Y-m-dTH:i:S\\"), "D, d.m.Y, H:i") }
    val toTime = analyticsTimeFilterStore.current.filterEndDate?.let { picker?.formatDate(flatpickr.parseDate(it, "Y-m-dTH:i:S\\"), "D, d.m.Y, H:i") }
    val filterActive = (fromTime != null) && (toTime != null)

    return """
        <!DOCTYPE html>
            <html lang="en">
                <head><title>${dashboard.title} (${dashboard.elements.indexOf(element) + 1}/${dashboard.elements.size}) > ${element.title}</title></head>
                <body style="font-family: system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';">
                    <div style="display:flex; align-items:center; justify-content:space-between; margin-bottom: 0.75rem; padding: 0 0.5rem;">
                        <h3>${element.title}</h3>
                        ${if (filterActive) "<p style=\"font-family: monospace;\">($fromTime - $toTime)</p>" else ""}
                    </div>
                    <div style="border: 1px solid #0e292f; border-radius: 12px; max-width: 100%; overflow: hidden;">
                        <table style="width: 100%; border-collapse: collapse; overflow: hidden;">
                            <tr style="padding: 0.25rem; background-color: #0e292f; color: white;">
    ${
        data.columNames.map { data.columNames.indexOf(it) to it }.joinToString("\n") { (index, columnName) ->
            """<th scope="col" style="padding: 0.25rem; ${if (index != 0) "border-left: 1px solid #555;" else ""}">
                <span>
            ${
                when {
                    element.columnNameMapping?.get(columnName) != null -> {
                        element.columnNameMapping?.get(columnName)
                    }

                    columnName == "analytics-field-value" -> {
                        translation.getString(
                            columnName,
                            mapOf("fieldValueName" to (element.elementParams[DashboardParamNames.FieldName] ?: columnName)),
                        )
                    }

                    else -> {
                        translation.getString(columnName)
                    }
                }
            }
            </span>
                </th>
            """.trimMargin()
        }
    }
                            </tr>
    ${
        if (data.rows.isEmpty()) {
            """<tr>
                <td style="padding: 0.25rem; border-top: 1px solid #e5e7eb;">
                ${
                translation.getString(AnalyticsDashboardTexts.NO_DATA)
            }
                </td>
                ${
                data.columns.drop(1).joinToString("\n") {
                    """<td style="padding: 0.25rem; border-top: 1px solid #e5e7eb; border-left: 1px solid #e5e7eb;">
                            </td>""".trimIndent()
                }
            }
                </tr>
            """.trimMargin()
        } else {
            data.rows.map { data.rows.indexOf(it) to it }.joinToString("\n") { (index, row) ->
                """<tr style="${if (index % 2 != 0) "background-color: #edf2f7;" else "background-color: white;"}">
                ${
                    row.map { row.indexOf(it) to it }.joinToString("\n") { (columnIndex, column) ->
                        """<td style="padding: 0.25rem; border-top: 1px solid #e5e7eb; ${if (columnIndex != 0) "border-left: 1px solid #e5e7eb;" else ""}">
                        ${
                            try {
                                if (data.columns[columnIndex].columnName == "analytics-time-span-ms"
                                    || data.columns[columnIndex].columnName == "analytics-time-span-per-parent-per-field"
                                ) {
                                    (column.longOrNull?.let { msTowdhms(it) } ?: getFieldValueTitle(column.content))
                                } else {
                                    getFieldValueTitle(column.content)
                                }
                            } catch (e: IndexOutOfBoundsException) {
                                console.warn("Could not get column name", e)
                                getFieldValueTitle(column.content)
                            }
                        }
                            </td>
                        """.trimMargin()
                    }
                }
                    </tr>
                """.trimMargin()
            }
        }
    }
                        </table>
                    </div>
                </body>
            </html>
        """.trimIndent()
}
