package map

import apiclient.geoobjects.ObjectType
import auth.ApiUserStore
import data.users.ActiveUserStore
import dev.fritz2.core.RootStore
import koin.koinCtx
import kotlinx.coroutines.CoroutineName
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.launch
import location.GeoPositionStore
import location.LocationFollowStore
import location.LocationUploadStore
import location.geolocation.GeoPosition
import maplibreGL.MaplibreElement
import maplibreGL.MaplibreMap
import maplibreGL.generateMyMaplibreUserMarker
import model.ObjectLayer
import model.ObjectLayers
import services.GeoPositionService


class MapObjectStore(
    private val geoPositionStore: GeoPositionStore,
    private val locationUploadStore: LocationUploadStore,
    private val locationFollowStore: LocationFollowStore,
) : RootStore<ObjectLayers>(
    initialData = ObjectLayers(objectLayers = initialObjectLayerSet),
    job = Job(),
) {

    private val maplibreMap: MaplibreMap by koinCtx.inject()
    private val apiUserStore: ApiUserStore by koinCtx.inject()
    private val activeUserStore: ActiveUserStore by koinCtx.inject()
    private val geoPositionService: GeoPositionService by koinCtx.inject()

    companion object {
        val initialObjectLayerSet = listOf(
            ObjectLayer(ObjectType.POI),
            ObjectLayer(ObjectType.ObjectMarker),
            ObjectLayer(ObjectType.Task),
            ObjectLayer(ObjectType.Event),
            ObjectLayer(ObjectType.UserMarker),
            ObjectLayer(ObjectType.CurrentUserMarker),
            ObjectLayer(ObjectType.Building),
            ObjectLayer(ObjectType.Floor),
            ObjectLayer(ObjectType.Unit),
            ObjectLayer(ObjectType.Notification),
            ObjectLayer(ObjectType.Area),
            ObjectLayer(ObjectType.Zone),
            ObjectLayer(ObjectType.HistoryEntry),
            ObjectLayer(ObjectType.GeneralMarker),
            //    ObjectLayer(ObjectType.TransientMarker)
        )
    }

    // use var latest to mirror the current store value to handle almost simultaneous updates of the store
    var latest = ObjectLayers(objectLayers = initialObjectLayerSet)

    val jumpToMyPosition = handle { current ->
        CoroutineScope(CoroutineName("jump-to-user")).launch {
            for (i in 0..1) {
                if (geoPositionStore.current == null) {
                    geoPositionService.locateMe()
                    console.log("Trying to jump to users position")
                } else {
                    geoPositionService.locateMe()
                    break
                }
                delay(500)
            }
            maplibreMap.addHiddenOverride(apiUserStore.current.userId)
        }
        current
    }

    private fun setObjects(objectLayerId: ObjectType, newObjects: List<MaplibreElement>?) {
        val newLayers = latest.objectLayers?.map { objectLayer ->
            if (objectLayer.id == objectLayerId) {
                if (objectLayer.objects != newObjects) {
                    objectLayer.copy(objects = newObjects, hasChanged = true)
                } else objectLayer.copy(hasChanged = false)
            } else objectLayer
        }
        latest = current.copy(objectLayers = newLayers)
        update(latest)
    }

    private fun setObjects(objectMap: Map<ObjectType, List<MaplibreElement>?>) {
        val newLayers = latest.objectLayers?.map { objectLayer ->
            if (objectMap.keys.contains(objectLayer.id)) {
                if (objectLayer.objects != objectMap[objectLayer.id]) {
//                    console.log("SET OBJECTS (${objectLayer.id.name})",
//                        objectMap[objectLayer.id]?.map { (it as? MaplibreMarker)?.title }.toString())
                    objectLayer.copy(objects = objectMap[objectLayer.id], hasChanged = true)
                } else objectLayer.copy(hasChanged = false)
            } else objectLayer
        }
        latest = current.copy(objectLayers = newLayers)
        update(latest)
    }

    private val updateMaplibreMap = handle<ObjectLayers> { _, newMapObjectLayers ->
        newMapObjectLayers.objectLayers?.let { objectsLayers -> maplibreMap.updateMap(objectsLayers.filter { it.hasChanged }) }
        maplibreMap.syncMarkersNow()
        newMapObjectLayers
    }

    private fun updateMyUser(
        position: GeoPosition? = geoPositionStore.current,
//        sharingState: LocationUploadState = locationUploadStore.current,
//        followState: LocationFollowState = locationFollowStore.current,
    ) {
        position?.let {
            console.warn("update MaplibreMap and trigger a refresh instead")
            //TODO update MaplibreMap and trigger a refresh instead
            val newUserMarker = generateMyMaplibreUserMarker(
                user = apiUserStore.current,
                position = it,
//                sharing = sharingState.isActive,
//                follow = followState.isActive
            )
            maplibreMap.setMyUserMarker(
                newUserMarker,
            )
            // setObjects(ObjectType.CurrentUserMarker, newUserMarker)
        }
    }

    private val updateMyUserPosition = handle<GeoPosition?> { _, newPosition ->
        updateMyUser(position = newPosition)
        latest // using latest here instead of current, to ensure objects are already set
    }

//    private val updateMyUserMarkerColor = handle<LocationUploadState> { _, newSharingState ->
//        updateMyUser() //sharingState = newSharingState)
//        latest // using latest here instead of current, to ensure objects are already set
//    }

//    private val updateMyUserMarkerCircle = handle<LocationFollowState> { _, newFollowState ->
//        updateMyUser() //followState = newFollowState)
//        latest // using latest here instead of current, to ensure objects are already set
//    }

    private val updateMyUserHandler = handle {
        updateMyUser()
        latest // using latest here instead of current, to ensure objects are already set
    }

    val reset = handle {
        console.log("Reset MapObjectStore")
        latest = ObjectLayers(initialObjectLayerSet)
        ObjectLayers(initialObjectLayerSet)
    }

    init {
        combine(
            apiUserStore.data,
            activeUserStore.data,
            locationUploadStore.data,
            locationFollowStore.data,
        ) { _, _, _, _ -> } handledBy updateMyUserHandler
        geoPositionStore.data handledBy updateMyUserPosition
//        locationUploadStore.data handledBy updateMyUserMarkerColor
//        locationFollowStore.data handledBy updateMyUserMarkerCircle
        data handledBy updateMaplibreMap
    }
}
