package mainmenu.termstexts

import dev.fritz2.components.compat.a
import dev.fritz2.components.compat.div
import dev.fritz2.components.compat.p
import dev.fritz2.components.compat.span
import dev.fritz2.core.RenderContext
import dev.fritz2.core.href
import dev.fritz2.core.target
import mainmenu.termsLinkStyle
import mainmenu.textParagraphs
import mainmenu.textPart
import mainmenu.textPartTitle

fun RenderContext.privacyTextDE() {
    div({
        width { full }
    }) {
        textPartTitle("DATENSCHUTZERKLÄRUNG") {
            p {
                +"Stand: März 2023"
            }
        }
        textPart("NAME UND ANSCHRIFT DES VERANTWORTLICHEN", 1) {
            textParagraphs {
                p {
                    +"Der Verantwortliche im Sinne der Datenschutz-Grundverordnung (DSGVO) und anderer nationaler Datenschutzgesetze der Mitgliedsstaaten sowie sonstiger datenschutzrechtlicher Bestimmungen ist:"
                }
                p {
                    +"FORMATION GmbH"
                }
                div {
                    p {
                        +"Urbanstraße 71"
                    }
                    p {
                        +"10967 Berlin"
                    }
                    p {
                        +"Deutschland"
                    }
                }
                p {
                    +"mail: info@tryformation.com"
                }
            }
        }
        textPart("ZUGRIFFSDATEN UND SERVER-LOGFILES", 2) {
            textParagraphs {
                p {
                    +"Bei Besuch unserer Plattform werden automatisiert Zugriffsdaten in sogenannten Server-Logfiles gespeichert. Dies umfasst Datum und Uhrzeit des Abrufs und ggf. den eingegebenen Suchbegriff."
                }
                p {
                    +"Die vorübergehende Speicherung der IP-Adresse durch das System ist notwendig, um eine Auslieferung der Plattform und ihrer Inhalte an Ihr Endgerät zu ermöglichen. Hierfür muss Ihre IP-Adresse für die Dauer der Sitzung gespeichert bleiben."
                }
                p {
                    +"Rechtsgrundlage für die vorübergehende Speicherung Ihrer Daten und der Logfiles ist Art. 6 Abs. 1 lit. b DSGVO. Diese Daten werden ausschließlich zur Sicherstellung eines dauerhaften und störungsfreien Betriebs der Plattform sowie zur Verbesserung der Inhalte unserer Plattform Sicherstellung der Sicherheit unserer informationstechnischen Systeme ausgewertet. Eine Auswertung Ihrer personenbezogenen Daten zu Marketingzwecken findet in diesem Zusammenhang nicht statt."
                }
                p {
                    +"Die Erfassung der Daten zur Bereitstellung der Plattform und die Speicherung der Daten in Logfiles ist für den Betrieb unserer Plattform erforderlich. Es besteht folglich keine Widerspruchsmöglichkeit."
                }
            }
        }
        textPart("VERWENDUNG VON COOKIES", 3) {
            textParagraphs {
                p {
                    +"Um die Nutzung unserer Plattform attraktiv zu gestalten und die Nutzung bestimmter Funktionen zu ermöglichen, verwenden wir sogenannte „Cookies“. Dabei handelt es sich um kleine Textdateien, welche über einen Browser auf Ihrem Endgerät abgelegt und gespeichert werden. Cookies können eine sogenannte Cookie-ID enthalten. Sie besteht aus einer Zeichenfolge, durch welche diese Cookie-ID einem konkreten Browser zugeordnet werden kann, in dem das jeweilige Cookie gespeichert wurde."
                }
                p {
                    +"In den Cookies werden dabei folgende Daten gespeichert und übermittelt: Spracheinstellungen, eingegebene Suchbegriffe, Häufigkeit von Seitenaufrufen, Inanspruchnahme von Plattform- Funktionen, Herkunft des Nutzers, verwendetes Betriebssystem, verwendetes Endgerät, verwendeter Browser, Auflösung des Endgerätes."
                }
                p {
                    +"Ihre auf unserer Plattform erhobenen Daten werden durch technische Vorkehrungen anonymisiert. Daher ist eine Zuordnung der Daten zu Ihnen nicht mehr möglich. Die Daten werden nicht gemeinsam mit sonstigen Ihrer personenbezogenen Daten gespeichert."
                }
                p {
                    +"Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten unter Verwendung von Cookies ist Art. 6 Abs. 1 lit. a DSGVO."
                }
                p {
                    +"Der Zweck der Verwendung technisch notwendiger Cookies ist, die Nutzung unserer Plattform für Sie zu vereinfachen (z.B. werden Ihre Einstellungen gespeichert). Die Funktionen unserer Plattform können ohne den Einsatz von Cookies nicht angeboten werden. Denn dafür ist erforderlich, da wir nachvollziehen können, dass Sie unseren Plattform-Nutzungsbedingungen zugestimmt haben. Daher ist es wichtig, dass Sie als Plattform-User in anonymisierter Form wiedererkannt werden. Bei der Nichtannahme oder Deaktivierung von Cookies kann die Funktionalität unserer Plattform eingeschränkt sein."
                }
            }
        }
        textPart("DATENVERARBEITUNG BEI NUTZUNG DER APP", 4) {
            textParagraphs {
                p {
                    +"Bei Nutzung der App verarbeiten wir auf Grundlage von Art. 6 Abs. 1 lit. b) DSGVO die nachfolgend beschriebenen Informationen, um die komfortable Nutzung der Funktionen zu ermöglichen. Wenn Sie die App nutzen möchten, verarbeiten wir die folgenden Daten, die für uns technisch erforderlich sind, um Ihnen die Funktionen der App anzubieten und die Stabilität und Sicherheit zu gewährleisten, insbesondere: die IP-Adresse des verwendeten Gerätes, Datum und Uhrzeit der Nutzung, Zugriffsstatus/HTTP-Statuscode, der von Ihnen verwendete Browser, Betriebssystem und dessen Oberfläche, Sprache und Version der Browsersoftware, usw."
                }
            }
        }
        textPart("MAPTILER", 5) {
            textParagraphs {
                p {
                    +"Für die Bereitstellung unserer Services und der dargestellten Karten nutzen wir auf Grundlage von Art. 1 lit. b) DSGVO Maptiler, einen Service der MapTiler AG, Höfnerstrasse 98, Unterägeri, Zug 6314, Schweiz (nachfolgend: „Maptiler“)."
                }
                p {
                    +"Nutzen Sie unsere Karten, so werden hierbei die Karten und Koordinationen über die Server von Maptiler in verarbeitet. Wir haben mit Maptiler einen Vertrag über eine Auftragsverarbeitung nach Art. 28 DSGVO abgeschlossen, in dem sich Maptiler verpflichtet, die erhaltenen Daten nur gemäß unseren Anweisungen zu verarbeiten und das EU-Datenschutzniveau einzuhalten. Zusätzliche Informationen zu Sendgrid und dem Datenschutz bei Sendgrid finden Sie in der "
                    a(termsLinkStyle) {
                        +"Datenschutzerklärung"
                        href("https://www.maptiler.com/privacy-policy/")
                        target("_blank")
                    }
                    +" des Anbieters."
                }
                p {
                    +"Die IP-Adressen der MapTiler-Cloud-Besucher werden nur für eine begrenzte Zeit gespeichert, maximal 20 Minuten, und dann automatisch vernichtet. Die Erhebung und kurzzeitige Speicherung sind zur Protokollierung von sicherheitsrelevanten Aktivitäten auf der MapTiler-Infrastruktur notwendig. Wie Sie die Karte selbst nutzen, wird durch MapTiler nicht erfasst."
                }
                p {
                    +"Weitere Informationen zum Datenschutz durch MapTiler finden Sie unter:"
                }
                a(termsLinkStyle) {
                    +"www.maptiler.com/privacy-policy/index.html"
                    href("www.maptiler.com/privacy-policy/index.html")
                    target("_blank")
                }
            }
        }
        textPart("SANDGRID", 6) {
            textParagraphs {
                p {
                    +"Für die Bereitstellung unserer Services und der dargestellten Karten nutzen wir auf Grundlage von Art. 1 lit. b) DSGVO Maptiler, einen Service der MapTiler AG, Höfnerstrasse 98, Unterägeri, Zug 6314, Schweiz (nachfolgend: „Maptiler“)."
                }
                p {
                    +"Für die E-Mail-Kommunikation mit Kunden und Interessenten, wie zum Beispiel Anmelde- und Terminbestätigungen, verwenden wir auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO die Versandsoftware Sendgrid, einen Service der Sendgrid Inc., 1801 California Street, Denver, CO 80202, USA (nachfolgend: „Sendgrid). Hierbei werden die folgenden Daten: E-Mail-Adresse, Name, Vorname, Inhalte der jeweiligen Transaktion, IP-Adresse, usw. über die Server von Sendgrid in den USA verarbeitet. Wir haben mit Sendgrid einen Vertrag über eine Auftragsverarbeitung nach Art. 28 DSGVO abgeschlossen, in dem sich Sendgrid verpflichtet, die erhaltenen Daten nur gemäß unseren Anweisungen zu verarbeiten und das EU-Datenschutzniveau einzuhalten. Zusätzliche Informationen zu Sendgrid und dem Datenschutz bei Sendgrid finden Sie in der "
                    a(termsLinkStyle) {
                        +"Datenschutzerklärung"
                        href("https://www.twilio.com/en-us/legal/privacy")
                        target("_blank")
                    }
                    +" des Anbieters."
                }
            }
        }
        textPart("E-MAIL UND KONTAKTFORMULAR", 7) {
            textParagraphs {
                p {
                    +"Auf unserer Website halten wir aufgrund von gesetzlichen Vorschriften unter „Kontakt“ Angaben bereit, die eine schnelle elektronische Kontaktaufnahme zu uns sowie eine unmittelbare Kommunikation per E-Mail mit uns ermöglichen. Soweit Sie uns per E-Mail kontaktieren, werden die von Ihnen übermittelten personenbezogenen Daten automatisch gespeichert."
                }
                p {
                    +"Rechtsgrundlage für die Verarbeitung der Daten, die im Zuge einer Übersendung einer E-Mail übermittelt werden, ist Art. 6 Abs. 1 lit. b DSGVO. Wir verwenden die von Ihnen übermittelten personenbezogenen Daten ausschließlich für die Bearbeitung Ihrer konkreten Anfrage. Die angegebenen Daten werden stets vertraulich behandelt."
                }
                p {
                    +"Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich sind. Für die personenbezogenen Daten, die per E-Mail übersandt wurden, ist dies dann der Fall, wenn die jeweilige Konversation mit Ihnen beendet ist. Beendet ist die Konversation dann, wenn sich aus den Umständen entnehmen lässt, dass der betroffene Sachverhalt abschließend geklärt ist."
                }
                p {
                    +"Nehmen Sie Kontakt mit uns auf, so können Sie der Speicherung Ihrer personenbezogenen Daten jederzeit widersprechen. In einem solchen Fall kann die Konversation nicht fortgeführt werden."
                }
            }
        }
        textPart("GOOGLE FIREBASE", 8) {
            textParagraphs {
                p {
                    +"Zum Zwecke der bedarfsgerechten Gestaltung und fortlaufenden nutzerindividuellen Optimierung unserer App nutzen wir für die Aussteuerung von Push-Notifications die Dienste von Firebase, einem Service der Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA (nachfolgend: „Firebase”). Indem Sie der App erlauben, Ihnen Push-Nachrichten zu schicken, willigen Sie darin in, dass eine anonymisierte Geräte-ID (Push-Notification-Token) sowie Status- und Technikdaten auf unseren Servern gemeinsam mit den damit assoziierten App-Einstellungen des jeweiligen Nutzers gespeichert und über die Server von Firebase verarbeitet werden. Rechtsgrundlage hierfür ist Artikel 6 Abs. 1 lit. a) DSGVO."
                }
                p {
                    +"Wir haben mit Firebase einen Vertrag zur Auftragsverarbeitung entsprechend der EU- Standardvertragsklauseln nach Maßgabe von Art. 28 DSGVO abgeschlossen, um die Sicherheit dieser Datenverarbeitung zu gewährleisten."
                }
                div {
                    p {
                        +"Sie können Ihre Einwilligung in diese Datenverarbeitung jederzeit mit Wirkung für die Zukunft widerrufen, indem Sie die Push-Notifcations in Ihren Geräteeinstellungen deaktivieren oder Sie uns einfach darüber informieren, dass Sie eine entsprechende Verarbeitung in Zukunft nicht mehr wünschen. Hierfür verwenden Sie bitte die oben angegebenen Kontaktmöglichkeiten. Weitere Informationen zu Firebase und dem Datenschutz bei Firebase finden Sie in den Datenschutzbestimmungen des Anbieters unter diesem "

                        span({ fontWeight { bold } }) {
                            +"widerrufen"
                        }

                        +", indem Sie die Push-Notifcations in Ihren Geräteeinstellungen deaktivieren oder Sie uns einfach darüber informieren, dass Sie eine entsprechende Verarbeitung in Zukunft nicht mehr wünschen. Hierfür verwenden Sie bitte die oben angegebenen Kontaktmöglichkeiten. Weitere Informationen zu Firebase und dem Datenschutz bei Firebase finden Sie in den Datenschutzbestimmungen des Anbieters unter diesem "

                        a(termsLinkStyle) {
                            +"Link"
                            href("https://firebase.google.com/support/privacy/")
                            target("_blank")
                        }

                        +"."
                    }
                }
            }
        }
        textPart("HUBSPOT", 9) {
            textParagraphs {
                div {
                    p {
                        +"Zur Verwaltung unserer Kundendaten und Interessenten verwenden wir die CRM-Plattform von Hubspot, einen Service der HubSpot Inc., 2nd Floor 30 North Wall Quay, Dublin 1, Ireland (nachfolgend: „Hubspot“). Dies hilft uns dabei, Kundendaten aufzunehmen, mit dem Kunden zu kommunizieren, diesen Kontakt zu dokumentieren, und den Wünschen entsprechende Angebote zu erstellen. Haben Sie Kontakt zu uns aufgenommen, bspw. über das Fragebogen, so werden die folgenden Daten über die Server von Salesforce verarbeitet: Name, E-Mail-Adresse, Angebote für den Kunden, Daten über die Interaktionen mit E-Mailsi usw. Rechtsgrundlage für diese Datenverarbeitungen ist Art. 6 Abs. 1 S. 1 lit. b DSGVO. Hubspot verwendet sog. „Cookies“, Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch das Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden über einen Server von Hubspot verarbeitet und dort gespeichert. Wir haben mit Hubspot einen Vertrag über eine Auftragsverarbeitung nach Art. 28 DSGVO abgeschlossen, in dem sich Hubspot verpflichtet, die erhaltenen Daten nur gemäß unseren Anweisungen zu verarbeiten und das EU-Datenschutzniveau einzuhalten. Weitere Informationen zum Datenschutz bei Hubspot finden Sie in der Datenschutzbestimmung des Anbieters indem Sie diesen "

                        a(termsLinkStyle) {
                            +"Link"
                            href("https://legal.hubspot.com/de/privacy-policy")
                            target("_blank")
                        }

                        +" klicken."
                    }
                }
            }
        }
        textPart("DATENSICHERHEIT", 10) {
            textParagraphs {
                p {
                    +"Wir sichern unsere Plattform und sonstigen Systeme durch zahlreiche technische und organisatorische Maßnahmen gegen Verlust, Zerstörung, Zugriff, Veränderung oder Verbreitung Ihrer Daten durch unbefugte Personen. Trotz regelmäßiger Kontrollen ist ein vollständiger Schutz gegen alle Gefahren jedoch nicht möglich und kann nicht von uns gewährt werden. Aus diesem Grund steht es Ihnen jederzeit frei, Ihre personenbezogenen Daten auch auf anderen Wegen, beispielsweise telefonisch oder per Post, an uns zu übermitteln."
                }
            }
        }
        textPart("DATENLÖSCHUNG UND SPEICHERDAUER", 11) {
            textParagraphs {
                p {
                    +"Ihre personenbezogenen Daten werden gelöscht oder gesperrt, sobald der Zweck der Speicherung entfällt oder Sie Ihre Einwilligung widerrufen. Eine Speicherung kann darüber hinaus dann erfolgen, wenn dies durch den europäischen oder nationalen Gesetzgeber in unionsrechtlichen Verordnungen, Gesetzen oder sonstigen Vorschriften, denen der Verantwortliche unterliegt, vorgesehen wurde. Entfällt der Speicherungszweck, widerrufen Sie Ihre Einwilligung oder läuft eine vom Europäischen Richtlinien- und Verordnungsgeber oder einem anderen zuständigen Gesetzgeber vorgeschriebene Speicherfrist ab, werden die personenbezogenen Daten routinemäßig und entsprechend den gesetzlichen Vorschriften gesperrt oder gelöscht, es sei denn, dass eine Erforderlichkeit zur weiteren Speicherung der Daten für einen Vertragsabschluss oder eine Vertragserfüllung besteht."
                }
            }
        }
        textPart("RECHT AUF AUSKUNFT", 12) {
            textParagraphs {
                p {
                    +"Sie haben zudem das Recht, jederzeit von uns unentgeltliche Auskunft über die zu Ihrer Person gespeicherten personenbezogenen Daten und eine Kopie dieser Auskunft zu erhalten. Sie haben zudem ein Auskunftsrecht bezüglich der folgenden Informationen:"
                }
                ul {
                    li {
                        +"die Verarbeitungszwecke,"
                    }
                    li {
                        +"die Kategorien personenbezogener Daten, die verarbeitet werden,"
                    }
                    li {
                        +"die Empfänger oder Kategorien von Empfängern, gegenüber denen die personenbezogenen Daten offengelegt worden sind oder noch offengelegt werden, insbesondere bei Empfängern in Drittländern oder bei internationalen Organisationen,"
                    }
                    li {
                        +"falls möglich die geplante Dauer, für die die personenbezogenen Daten gespeichert werden, oder, falls dies nicht möglich ist, die Kriterien für die Festlegung dieser Dauer,"
                    }
                    li {
                        p {
                            +"das Bestehen eines Rechts auf Berichtigung oder Löschung der sie betreffenden personenbezogenen Daten oder auf Einschränkung der Verarbeitung durch den Verantwortlichen oder eines "

                            span({ fontWeight { bold } }) {
                                +"Widerspruchsrechts"
                            }

                            +" gegen diese Verarbeitung,"
                        }
                    }
                    li {
                        +"das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde,"
                    }
                    li {
                        +"wenn die personenbezogenen Daten nicht bei der betroffenen Person erhoben werden: alle verfügbaren Informationen über die Herkunft der Daten sowie,"
                    }
                    li {
                        +"das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling gemäß Artikel 22 Abs.1 und 4 DSGVO und - zumindest in diesen Fällen - aussagekräftige Informationen über die involvierte Logik sowie die Tragweite und die angestrebten Auswirkungen einer derartigen Verarbeitung für die betroffene Person."
                    }
                }
                p {
                    +"Ferner steht Ihnen ein Auskunftsrecht darüber zu, ob personenbezogene Daten an ein Drittland oder an eine internationale Organisation übermittelt wurden. Sofern dies der Fall ist, so steht Ihnen im Übrigen das Recht zu, Auskunft über die geeigneten Garantien im Zusammenhang mit der Übermittlung zu erhalten."
                }
            }
        }
        textPart("RECHT AUF BERICHTIGUNG", 13) {
            textParagraphs {
                p {
                    +"Sie haben das Recht, die unverzügliche Berichtigung und/oder Vervollständigung Sie betreffender unrichtiger oder unvollständiger personenbezogener Daten zu verlangen. Wir haben die Berichtigung unverzüglich vorzunehmen."
                }
            }
        }
        textPart("RECHT AUF EINSCHRÄNKUNG DER VERARBEITUNG", 14) {
            textParagraphs {
                p {
                    +"Sie haben das Recht, von uns die Einschränkung der Verarbeitung zu verlangen, wenn eine der folgenden Voraussetzungen gegeben ist:"
                }
                ul {
                    li {
                        +"Die Richtigkeit der personenbezogenen Daten wird von der betroffenen Person bestritten, und zwar für eine Dauer, die es dem Verantwortlichen ermöglicht, die Richtigkeit der personenbezogenen Daten zu überprüfen."
                    }
                    li {
                        +"Die Verarbeitung ist unrechtmäßig, die betroffene Person lehnt die Löschung der personenbezogenen Daten ab und verlangt stattdessen die Einschränkung der Nutzung der personenbezogenen Daten."
                    }
                    li {
                        +"Der Verantwortliche benötigt die personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger, die betroffene Person benötigt sie jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen."
                    }
                    li {
                        +"Die betroffene Person hat Widerspruch gegen die Verarbeitung gem. Art. 21 Abs. 1 DSGVO eingelegt und es steht noch nicht fest, ob die berechtigten Gründe des Verantwortlichen gegenüber denen der betroffenen Person überwiegen."
                    }
                }
                p {
                    +"Wurde die Verarbeitung der Sie betreffenden personenbezogenen Daten eingeschränkt, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Union oder eines Mitgliedstaats verarbeitet werden."
                }
                p {
                    +"Wurde die Verarbeitung nach den o.g. Voraussetzungen eingeschränkt, werden Sie von uns unterrichtet bevor die Einschränkung aufgehoben wird."
                }
            }
        }
        textPart("RECHT AUF LÖSCHUNG", 15) {
            textParagraphs {
                p {
                    +"Sie haben das Recht, von uns zu verlangen, dass die Sie betreffenden personenbezogenen Daten unverzüglich gelöscht werden, sofern einer der folgenden Gründe zutrifft und soweit die Verarbeitung nicht erforderlich ist:"
                }
                ul {
                    li {
                        +"Die personenbezogenen Daten wurden für solche Zwecke erhoben oder auf sonstige Weise verarbeitet, für welche sie nicht mehr notwendig sind."
                    }
                    li {
                        +"Die betroffene Person widerruft ihre Einwilligung, auf die sich die Verarbeitung gemäß Art. 6 Abs. 1 Buchstabe a DSGVO oder Art. 9 Abs. 2 Buchstabe a DSGVO stützte, und es fehlt an einer anderweitigen Rechtsgrundlage für die Verarbeitung."
                    }
                    li {
                        +"Die betroffene Person legt gemäß Art. 21 Abs. 1 DSGVO Widerspruch gegen die Verarbeitung ein, und es liegen keine vorrangigen berechtigten Gründe für die Verarbeitung vor oder die betroffene Person legt gemäß Art. 21 Abs. 2 DSGVO Widerspruch gegen die Verarbeitung ein."
                    }
                    li {
                        +"Die personenbezogenen Daten wurden unrechtmäßig verarbeitet."
                    }
                    li {
                        +"Die Löschung der personenbezogenen Daten ist zur Erfüllung einer rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich, dem der Verantwortliche unterliegt."
                    }
                    li {
                        +"Die personenbezogenen Daten wurden in Bezug auf angebotene Dienste der Informationsgesellschaft gemäß Art. 8 Abs. 1 DSGVO erhoben."
                    }
                }
                p {
                    +"Wurden die personenbezogenen Daten von uns öffentlich gemacht und sind wir als Verantwortlicher gemäß Art. 17 Abs. 1 DSGVO zur Löschung der personenbezogenen Daten verpflichtet, so treffen wir unter Berücksichtigung der verfügbaren Technologie und der Implementierungskosten angemessene Maßnahmen, auch technischer Art, um andere für die Datenverarbeitung Verantwortliche, welche die veröffentlichten personenbezogenen Daten verarbeiten, darüber in Kenntnis zu setzen, dass die betroffene Person von diesen anderen für die Datenverarbeitung Verantwortlichen die Löschung sämtlicher Links zu diesen personenbezogenen Daten oder von Kopien oder Replikationen dieser personenbezogenen Daten verlangt hat, soweit die Verarbeitung nicht erforderlich ist."
                }
                p {
                    +"Das Recht auf Löschung besteht nicht, soweit die Verarbeitung erforderlich ist:"
                }
                ul {
                    li {
                        +"zur Ausübung des Rechts auf freie Meinungsäußerung und Information;"
                    }
                    li {
                        +"zur Erfüllung einer rechtlichen Verpflichtung, die die Verarbeitung nach dem Recht der Union oder der Mitgliedstaaten, dem der Verantwortliche unterliegt, erfordert, oder zur Wahrnehmung einer Aufgabe, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde;"
                    }
                    li {
                        +"aus Gründen des öffentlichen Interesses im Bereich der öffentlichen Gesundheit gemäß Art. 9 Abs. 2 lit. h und i sowie Art. 9 Abs. 3 DSGVO;"
                    }
                    li {
                        +"für im öffentlichen Interesse liegende Archivzwecke, wissenschaftliche oder historische Forschungszwecke oder für statistische Zwecke gem. Art. 89 Abs. 1 DSGVO, soweit das unter Abschnitt a) genannte Recht voraussichtlich die Verwirklichung der Ziele dieser Verarbeitung unmöglich macht oder ernsthaft beeinträchtigt, oder"
                    }
                    li {
                        +"zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen."
                    }
                }
            }
        }
        textPart("RECHT AUF UNTERRICHTUNG", 16) {
            textParagraphs {
                p {
                    +"Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung der Verarbeitung gegenüber uns geltend gemacht, sind wir verpflichtet, allen Empfängern, denen die Sie betreffenden personenbezogenen Daten offengelegt wurden, diese Berichtigung oder Löschung der Daten oder Einschränkung der Verarbeitung mitzuteilen, es sei denn, dies erweist sich als unmöglich oder ist mit einem unverhältnismäßigen Aufwand verbunden."
                }
                p {
                    +"Ihnen steht gegenüber uns das Recht zu, über diese Empfänger unterrichtet zu werden."
                }
            }
        }
        textPart("RECHT AUF DATENÜBERTRAGBARKEIT", 17) {
            textParagraphs {
                p {
                    +"Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, welche Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten. Sie haben außerdem das Recht, diese Daten einem anderen Verantwortlichen ohne Behinderung durch uns zu übermitteln, sofern die Verarbeitung auf der Einwilligung gemäß Art. 6 Abs. 1 Buchstabe a DSGVO oder Art. 9 Abs. 2 Buchstabe a DSGVO oder auf einem Vertrag gemäß Art. 6 Abs. 1 Buchstabe b DSGVO beruht und die Verarbeitung mithilfe automatisierter Verfahren erfolgt, sofern die Verarbeitung nicht für die Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, welche uns übertragen wurde."
                }
                p {
                    +"Ferner haben Sie bei der Ausübung Ihres Rechts auf Datenübertragbarkeit gemäß Art. 20 Abs. 1 DSGVO das Recht, zu erwirken, dass die personenbezogenen Daten direkt von uns an einen anderen Verantwortlichen übermittelt werden, soweit dies technisch machbar ist und sofern hiervon nicht die Rechte und Freiheiten anderer Personen beeinträchtigt werden."
                }
                p {
                    +"Das Recht auf Datenübertragbarkeit gilt nicht für eine Verarbeitung personenbezogener Daten, die für die Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde."
                }
            }
        }
        textPart("RECHT AUF WIDERSPRUCH", 18) {
            textParagraphs {
                p {
                    +"Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender personenbezogener Daten, die aufgrund von Art. 6 Abs. 1 Buchstaben e oder f DSGVO erfolgt, Widerspruch einzulegen. Dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling."
                }
                p {
                    +"Wir verarbeiten die personenbezogenen Daten im Falle des Widerspruchs nicht mehr, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihren Interessen, Rechten und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen."
                }
                p {
                    +"Sofern wir Ihre personenbezogenen Daten verarbeiten, um Direktwerbung zu betreiben, haben Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen. Dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht. Widersprechen Sie uns gegenüber der Verarbeitung für Zwecke der Direktwerbung, so werden wir Ihre personenbezogenen Daten nicht mehr für diese Zwecke verarbeiten."
                }
                p {
                    +"Sie haben zudem das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, gegen die Sie betreffende Verarbeitung Ihrer personenbezogenen Daten, die bei uns zu wissenschaftlichen oder historischen Forschungszwecken oder zu statistischen Zwecken gemäß Art. 89 Abs. 1 DSGVO erfolgt, Widerspruch einzulegen, es sei denn, eine solche Verarbeitung ist zur Erfüllung einer im öffentlichen Interesse liegenden Aufgabe erforderlich."
                }
                p {
                    +"Zur Ausübung des Rechts auf Widerspruch können Sie sich jederzeit an uns wenden. Es steht Ihnen ferner frei, im Zusammenhang mit der Nutzung von Diensten der Informationsgesellschaft, ungeachtet der Richtlinie 2002/58/EG, Ihr Widerspruchsrecht mittels automatisierter Verfahren auszuüben, bei denen technische Spezifikationen verwendet werden."
                }
            }
        }
        textPart("RECHT AUF WIDERRUF EINER DATENSCHUTZRECHTLICHEN EINWILLIGUNG", 19) {
            textParagraphs {
                p({ fontWeight { bold } }) {
                    +"Sie haben das Recht, Ihre Einwilligung zur Verarbeitung personenbezogener Daten jederzeit zu widerrufen. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit, der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt."
                }
            }
        }
        textPart("RECHT AUF AUTOMATISIERTE ENTSCHEIDUNGEN IM EINZELFALL EINSCHLIEßLICH PROFILING", 20) {
            textParagraphs {
                p {
                    +"Sie haben das Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung - einschließlich Profiling - beruhenden Entscheidung unterworfen zu werden, die Ihnen gegenüber rechtliche Wirkung entfaltet oder Sie in ähnlicher Weise erheblich beeinträchtigt, sofern die Entscheidung"
                }
                ul {
                    li {
                        +"nicht für den Abschluss oder die Erfüllung eines Vertrags zwischen Ihnen und uns erforderlich ist oder"
                    }
                    li {
                        +"aufgrund von Rechtsvorschriften der Union oder der Mitgliedstaaten, denen wir unterliegen, zulässig ist und diese Rechtsvorschriften angemessene Maßnahmen zur Wahrung Ihrer Rechte und Freiheiten sowie Ihrer berechtigten Interessen enthalten oder"
                    }
                    li {
                        +"mit Ihrer ausdrücklichen Einwilligung erfolgt."
                    }
                }
                p {
                    +"Ist die Entscheidung"
                }
                ul {
                    li {
                        +"für den Abschluss oder die Erfüllung eines Vertrags zwischen Ihnen und uns erforderlich oder"
                    }
                    li {
                        +"erfolgt sie mit Ihrer ausdrücklichen Einwilligung,"
                    }
                }
                p {
                    +"treffen wir angemessene Maßnahmen, um Ihre Rechte und Freiheiten sowie Ihre berechtigten Interessen zu wahren, wozu mindestens das Recht auf Erwirkung des Eingreifens einer Person seitens uns auf Darlegung des eigenen Standpunkts und auf Anfechtung der Entscheidung gehört."
                }
            }
        }
        textPart("BESTEHEN EINER AUTOMATISIERTEN ENTSCHEIDUNGSFINDUNG", 21) {
            textParagraphs {
                p {
                    +"Wir führen keine automatische Entscheidungsfindung oder ein Profiling durch."
                }
            }
        }
        textPart("RECHT AUF BESCHWERDE BEI EINER AUFSICHTSBEHÖRDE", 22) {
            textParagraphs {
                p {
                    +"Unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs steht Ihnen das Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat Ihres Aufenthaltsorts, Ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, zu, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die DSGVO verstößt."
                }
                p {
                    +"Die Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde, unterrichtet den Beschwerdeführer über den Stand und die Ergebnisse der Beschwerde einschließlich der Möglichkeit eines gerichtlichen Rechtsbehelfs nach Art. 78 DSGVO. Die für uns zuständige Aufsichtsbehörde der Berliner Beauftragte für Datenschutz und Informationsfreiheit, Friedrichstraße 219, 10969 Berlin."
                }
            }
        }
    }
}
