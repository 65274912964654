package camera.nimiq

import com.tryformation.localization.Translatable
import dev.fritz2.core.RootStore
import koin.koinCtx
import kotlinx.coroutines.Job
import localization.TL
import localization.Translation
import model.Camera

enum class CameraType { QRScanner, Photo }

class CameraListStore : RootStore<List<Camera>>(
    initialData = emptyList(),
    job = Job(),
)

class ActiveCameraStore : RootStore<Camera?>(
    initialData = null,
    job = Job(),
)

enum class FacingMode(val captureName: String) {
    Front("user"),
    Back("environment"),
    ;
}

class SwitchCameraStore : RootStore<FacingMode>(
    initialData = FacingMode.Front,
    job = Job(),
) {
    private val translation: Translation by koinCtx.inject()
    private val browserCamera: BrowserCamera by koinCtx.inject()
    private val activeCameraStore: ActiveCameraStore by koinCtx.inject()

    val switchCamera = handle { current ->
        val activeMode = browserCamera.qrScanner?._preferredCamera
        val defaultBackCamera = Camera(
            id = FacingMode.Back.captureName,
            label = translation.getString(TL.CardBrowserQrScanner.DEFAULT_BACK_CAMERA),
        )
        val defaultFrontCamera = Camera(
            id = FacingMode.Front.captureName,
            label = translation.getString(TL.CardBrowserQrScanner.DEFAULT_FRONT_CAMERA),
        )
        when {
            activeMode == FacingMode.Front.captureName || current == FacingMode.Front -> {
                console.log("Set camera facing mode from \"$activeMode\" to \"${FacingMode.Back.captureName}\"")
                browserCamera.setCamera(FacingMode.Back.captureName)
                activeCameraStore.update(defaultBackCamera)
                FacingMode.Back
            }

            activeMode == FacingMode.Back.captureName || current == FacingMode.Back -> {
                console.log("Set camera facing mode from \"$activeMode\" to \"${FacingMode.Front.captureName}\"")
                browserCamera.setCamera(FacingMode.Front.captureName)
                activeCameraStore.update(defaultFrontCamera)
                FacingMode.Front
            }

            else -> current
        }
    }
}

enum class InversionMode(val key: String) : Translatable {
    BlackOnWhite("original"),
    WhiteOnBlack("invert"),
    Both("both")
    ;

    override val prefix = "inversionmode"
}

class InversionModeStore : RootStore<InversionMode>(
    initialData = InversionMode.Both,
    job = Job(),
)
