package model

import apiclient.geoobjects.ObjectType
import apiclient.geoobjects.SearchQueryContext
import apiclient.search.ObjectSearchResults
import apiclient.markers.MapLayerContext
import apiclient.markers.SearchLayers
import maplibreGL.MaplibreElement

enum class LayerType {
    MapSearchResults,
    GlobalSearchResults,
    HubSearchResults,
    MarkerClientUsers,
    MarkerClientObjects,
    CurrentFloor,
    CurrentUnits,
    FloorResults,
    ActiveObjectOrUserCopy,
    HistoryPath,
}

data class MapLayer(
    val id: LayerType,
    val enabled: Boolean,
    val title: String,
    val results: ObjectSearchResults?,
    val clustered: Boolean = true,
)

data class MapLayers(val layers: List<MapLayer>) {
    companion object
}

data class ObjectLayer(
    val id: ObjectType,
    val objects: List<MaplibreElement>? = null,
    var hasChanged: Boolean = false,
)

data class ObjectLayers(val objectLayers: List<ObjectLayer>? = null) {
    companion object
}

data class SearchContexts(
    val mapSearch: MapLayerContext,
    val globalSearch: SearchQueryContext,
    val notificationSearch: MapLayerContext,
    val areaSearch: MapLayerContext,
    val hubSearch: SearchQueryContext,
    val analyticsPaths: SearchQueryContext,
    val analyticsHeatmaps: SearchQueryContext
) {
    companion object
}

data class SearchClients(
    val clients: Map<String, layercache.LayerCache> = emptyMap(),
    val activeClientIds: List<String> = listOf(SearchLayers.ALL.mapLayerMetaData.id),
)
