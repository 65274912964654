package analyticsdashboard

import apiclient.analytics.Dashboard
import dev.fritz2.components.flexBox
import dev.fritz2.core.HtmlTag
import dev.fritz2.core.RenderContext
import dev.fritz2.core.storeOf
import koin.koinCtx
import kotlinx.coroutines.flow.flowOf
import org.w3c.dom.HTMLDivElement
import styling.primaryButtonStyleParams
import theme.FormationColors
import theme.FormationDefault.Companion.formationStyles
import webcomponents.genericButton

fun RenderContext.dashboardTabContainer(tabs: HtmlTag<HTMLDivElement>.() -> Unit) {
    flexBox({
        width { full }
        minWidth { minContent }
//        height(formationStyles.buttonHeight)
        direction { row }
        alignItems { center }
        justifyContent { spaceBetween }
//        radius { larger }
//        borders {
//            bottom {
//                width { "2px" }
//                color { primary.main }
//            }
//        }
        padding { tiny }
    }) {
        tabs.invoke(this)
    }
}

fun RenderContext.dashbaordFilterContainer(content: HtmlTag<HTMLDivElement>.() -> Unit) {
    val expandStateStore = storeOf(false)
    expandStateStore.data.render { expand ->
        flexBox({
            width { full }
            minWidth { minContent }
            direction { row }
            alignItems { center }
            justifyContent { spaceBetween }
            radius { larger }
            border {
                width { "1px" }
                color { primary.main }
            }
            padding { tiny }
            if (expand) {
                height { maxContent }
            } else {
                height(formationStyles.buttonHeight)
            }
        }) {
            content.invoke(this)
        }
    }

}


fun RenderContext.dashboardTab(
    dashboard: Dashboard,
    active: Boolean
) {
    val selectedAnalyticsDashboardStore: SelectedAnalyticsDashboardStore by koinCtx.inject()
//    flexBox({
//        height(formationStyles.buttonHeight)
////        maxHeight(formationStyles.buttonHeight)
//        radius { larger }
//        paddings {
//            vertical { tiny }
//            horizontal { small }
//        }
//        margins { horizontal { tiny } }
//        css("cursor:pointer;")
//        alignItems { center }
//        justifyContent { center }
//        if(active) {
//            color { secondary.main }
//            background {
//                color { primary.main }
//            }
//        } else {
//            color { FormationColors.GrayDisabled.color }
//            background {
//                color { FormationColors.GrayLight.color }
//            }
//        }
//        hover {
//            color { secondary.main }
//            background {
//                color { primary.main }
//            }
//        }
//    }) {
//        ellipseText({
//            fontSize { small }
//            fontWeight { bold }
//        }, maxLines = 1) {
//            +dashboard.title
//        }
//        clicks.map { dashboard } handledBy selectedAnalyticsDashboardStore.update
//    }
    genericButton(
        title = flowOf(dashboard.title),
        icon = { barChartAlt },
        width = { auto },
        styleFlow = flowOf {
            if (active) {
                primaryButtonStyleParams()
            } else {
                color { FormationColors.GrayDisabled.color }
                background { color { secondary.main } }
                fontSize { small }
                fontWeight { bold }
                radius(formationStyles.buttonRadius)
            }

            margins {
                vertical { none }
            }
        },
        valueHandlers = listOf(selectedAnalyticsDashboardStore.update),
        value = dashboard,
    )
}

enum class DashboardPages {
    HistoryTool, HeatmapTool
}

fun RenderContext.dashbaordToolTab(page: DashboardPages) {

}
