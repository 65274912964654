package theme

import dev.fritz2.core.RootStore
import kotlinx.browser.document
import kotlinx.coroutines.Job

enum class AppTheme {
    Default,
    MonoBW,
    Modern,
}

class AppThemeStore : RootStore<AppTheme>(
    initialData = AppTheme.Default,
    job = Job()
) {

    private val changeTheme = handle<AppTheme> { _, new ->
        document.body?.setAttribute("class", new.name.lowercase())
        new
    }

    init {
        data handledBy changeTheme
    }
}
