package theme

import dev.fritz2.styling.params.ColorProperty
import dev.fritz2.styling.params.ScaledValueProperty
import dev.fritz2.styling.params.SizesProperty
import dev.fritz2.styling.theme.ColorScheme
import dev.fritz2.styling.theme.Colors
import dev.fritz2.styling.theme.DefaultTheme
import dev.fritz2.styling.theme.FontFamilies
import dev.fritz2.styling.theme.Property
import dev.fritz2.styling.theme.ScaledValue
import dev.fritz2.styling.theme.Thickness
import org.koin.dsl.module

val themeModule = module {
    single { FormationDefault() }
}

@Deprecated("Stop using fritz2 theme stuff")
class FormationDefault : DefaultTheme() {
    override val name = "FORMATION default theme"

    override val colors: Colors = object : Colors by super.colors {

        // override DefaultTheme colors
        override val gray50 = "#F7FAFC"
        override val gray100 = "#EDF2F7"
        override val gray200 = "#E2E8F0"
        override val gray300 = "#CBD5E0"
        override val gray400 = "#A0AEC0"
        override val gray500 = "#718096"
        override val gray600 = "#4A5568"
        override val gray700 = "#2D3748"
        override val gray800 = "#1A202C"
        override val gray900 = "#171923"

        override val neutral = // white
            ColorScheme(
                main = "#ffffff",
                mainContrast = gray700,
                highlight = gray100,
                highlightContrast = gray700
            )
        override val primary =
            ColorScheme(
                main = FormationColors.BlueDeep.color,
                mainContrast = FormationColors.White.color,
                highlight = gray600,
                highlightContrast = FormationColors.White.color
            )
        override val secondary =
            ColorScheme(
                main = FormationColors.White.color,
                mainContrast = FormationColors.Black.color,
                highlight = gray300,
                highlightContrast = FormationColors.Black.color
            )
        override val tertiary =
            ColorScheme(
                main = FormationColors.Black.color,
                mainContrast = gray300,
                highlight = FormationColors.White.color,
                highlightContrast = gray600
            )
        override val info =
            ColorScheme(
                main = FormationColors.White.color,
                mainContrast = FormationColors.MarkerYou.color,
                highlight = FormationColors.MarkerYou.color,
                highlightContrast = FormationColors.White.color
            )
        override val success =
            ColorScheme(
                main = FormationColors.GreenBright.color,
                mainContrast = neutral.main,
                highlight = "#ccedda",
                highlightContrast = gray700
            )
        override val warning =
            ColorScheme(
                main = FormationColors.YellowDoing.color,
                mainContrast = neutral.main,
                highlight = "#fce7d7",
                highlightContrast = gray700
            )
        override val danger =
            ColorScheme(
                main = FormationColors.RedError.color,
                mainContrast = neutral.main,
                highlight = "#f9dbd4",
                highlightContrast = gray700
            )
        override val disabled: ColorProperty = FormationColors.GrayPrivate.color
        override val focus: ColorProperty = FormationColors.BlueMarine.color
        override val background = secondary.main
        override val font = primary.main
    }

    override val fontSizes = ScaledValue(
        tiny = "0.625rem",
        smaller = "0.75rem",
        small = "0.875rem",
        normal = "1rem",
        large = "1.25rem",
        larger = "1.5rem",
        huge = "1.875rem",
        giant = "3rem",
        full = "4rem"
    )

    override val fontFamilies = object : FontFamilies {
        override val normal =
            "system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';"

        //"Avenir, Montserrat, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'"
        override val mono = "ui-monospace, SFMono-Regular, Consolas, 'Liberation Mono', Menlo, monospace;"
    }

    override val borderWidths = Thickness(
        none = "0px",
        normal = "2px",
        thin = "1px",
        fat = "4px",
        hair = "0.1px"
    )

    override val radii = ScaledValue(
        none = "0",
        smaller = "0.125rem",
        small = "0.225rem",
        normal = "0.375rem",
        large = "0.5rem", // = 8px
        larger = "0.75rem", // = 12px
        full = "9999px"
    )

    companion object {

        val formationStyles: FormationStyles
            get() = object : FormationStyles {
                override val borderWidth: Thickness.() -> Property = { thin }
                override val inputHeight: SizesProperty = { "36px" }
                override val inputRadius: ScaledValueProperty = { "0.75rem" } // = "12px"
                override val tagRadius: ScaledValueProperty = { "36px" }
                override val inputIconSize: SizesProperty = { normal }
                override val inputIconButtonSize: SizesProperty = { large }
                override val buttonHeight: SizesProperty = { "40px" }
                override val buttonRadius: ScaledValueProperty = { larger }
                override val cardWidth: ScaledValueProperty = { "400px" }
                override val sideButtonSize: ScaledValueProperty = { "38px" }
            }
    }
}
