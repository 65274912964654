package objectrouting

import apiclient.FormationClient
import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.LatLon
import apiclient.geoobjects.ObjectTags
import apiclient.geoobjects.ObjectType
import apiclient.geoobjects.SearchQueryContext
import apiclient.geoobjects.distanceTo
import apiclient.geoobjects.isFlagged
import apiclient.geoobjects.newContext
import apiclient.geoobjects.restSearch
import apiclient.tags.getUniqueTag
import apiclient.tags.tag
import auth.CurrentWorkspaceStore
import data.objects.building.ActiveFloorLevelStore
import data.objects.building.CurrentBuildingsStore
import data.objects.building.getBuilding
import data.objects.building.getFloorLevel
import data.objects.views.objectrouting.getFloorIdForNavigationPointSource
import data.objects.views.objectrouting.getLevelFromFloorId
import data.users.profile.MyProfileStore
import dev.fritz2.core.RenderContext
import dev.fritz2.core.placeholder
import dev.fritz2.core.storeOf
import dev.fritz2.headless.foundation.utils.floatingui.utils.PlacementValues
import koin.withKoin
import kotlin.math.max
import kotlinx.coroutines.CoroutineName
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.launch
import location.LocationUploadStore
import map.MapStateStore
import maplibreGL.MaplibreMap
import model.destinationLatLon
import model.destinationObject
import model.destinationPointSource
import model.originLatLon
import model.originObject
import model.originPointSource
import point2pointnavigation.RoutingService
import search.getSvgIconOptions
import search.separationLine
import search.simpleListEntry
import search.titleSizedText
import services.GeoPositionService
import svgmarker.MarkerSize
import svgmarker.myUserSize
import svgmarker.myUserSvgIconOptions
import svgmarker.renderSvgIcon
import theme.FormationIcons
import theme.FormationUIIcons
import twcomponents.doOnceWhenElementInDOM
import twcomponents.twColOfNoGap
import twcomponents.twFlatIconButton
import twcomponents.twIconMedium
import twcomponents.twIconSmall
import twcomponents.twIconSmallToMedium
import twcomponents.twInputField
import twcomponents.twInputTextField
import twcomponents.twLargeIconButtonNeutral
import twcomponents.twMediumIconButton
import twcomponents.twRowOf
import twcomponents.twRowOfJustifyBetween
import twcomponents.twRowOfJustifyStart
import twcomponents.twRowOfNoGap
import twcomponents.twRowOfWrap
import twcomponents.twTooltip
import utils.Quadruple
import webcomponents.ellipseText
import websocket.MarkerClientStore

const val navigationToolContainerId = "navigation-tool-container"

fun RenderContext.navigationComponent() {

    withKoin {
        val navigationToolToggleStore: NavigationToolToggleStore = get()
        val maplibreMap: MaplibreMap = get()
        val geoPositionService: GeoPositionService = get()
        val lastKnownPositionStore: LastKnownPositionStore = get()
        val currentBuildingsStore: CurrentBuildingsStore = get()
        val activeFloorLevelStore: ActiveFloorLevelStore = get()
        val mapStateStore: MapStateStore = get()
        val navigationStore: NavigationStore = get()
        val originPointSourceStore = navigationStore.map(NavigationData.originPointSource())
        val originObjectStore = navigationStore.map(NavigationData.originObject())
        val originLatLonStore = navigationStore.map(NavigationData.originLatLon())
        val destinationPointSourceStore = navigationStore.map(NavigationData.destinationPointSource())
        val destinationObjectStore = navigationStore.map(NavigationData.destinationObject())
        val destinationLatLonStore = navigationStore.map(NavigationData.destinationLatLon())
        val navigationSearchHistoryStore: NavigationSearchHistoryStore = get()
        val myProfileStore: MyProfileStore = get()
        val locationUploadStore: LocationUploadStore = get()

        val navigationSearchResultsStore = storeOf<List<GeoObjectDetails>>(emptyList())
        val selectedNavigationOriginCoordinatesStore = storeOf<LatLon?>(null)

        var lastSelectedInput: NavigationInput? = null

        suspend fun doSearch(query: String) {

            val formationClient: FormationClient = get()
            val currentWorkspaceStore: CurrentWorkspaceStore = get()
            val markerClientStore: MarkerClientStore = get()

            if (query.isNotBlank()) {
                val result = formationClient.restSearch(
                    SearchQueryContext.newContext(listOf(currentWorkspaceStore.current?.groupId ?: error("no group"))).copy(
                        from = 0,
                        size = 15,
                        text = query,
                        objectTypes = listOf(
                            ObjectType.ObjectMarker, ObjectType.Task, ObjectType.GeneralMarker, ObjectType.UserMarker, ObjectType.Zone,
                            ObjectType.POI, ObjectType.Event,
                        ),
                        excludeTags = listOf(
                            ObjectTags.Archived.tag("true"),
                            ObjectTags.Deleted.tag("true"),
                            ObjectTags.Flagged.tag("true"),
                        ),
                    ),
                )
                if (result.isFailure) {
                    console.error("Error searching for $query", result.exceptionOrNull())
                } else {
                    val results = result.getOrThrow().hits.mapNotNull {
                        // filter out users that are not sharing
                        when (it.hit.objectType) {
                            ObjectType.UserMarker -> {
                                if (markerClientStore.isUserSharing(it.hit.ownerId)) {
                                    it.hit
                                } else null
                            }

                            else -> it.hit
                        }
                    }
                    navigationSearchResultsStore.update(results)
                }
            } else {
                navigationSearchResultsStore.update(emptyList())
            }
        }

        suspend fun getRoutePoints(changeFloor: Boolean = true): List<RoutePoint>? {
            val originLatLon = originLatLonStore.current
            selectedNavigationOriginCoordinatesStore.update(originLatLon)

            val originFloorId: String? = getFloorIdForNavigationPointSource(
                navigationPointSource = originPointSourceStore.current,
                latLon = originLatLon,
                selectedObjectStore = originObjectStore,
            )

            val destinationLatLon = destinationLatLonStore.current

            val destinationFloorId = getFloorIdForNavigationPointSource(
                navigationPointSource = destinationPointSourceStore.current,
                latLon = destinationLatLon,
                selectedObjectStore = destinationObjectStore,
            )

            if (changeFloor) {
                // switch floor to known level of starting point
                getLevelFromFloorId(originFloorId)?.let { floorLevel ->
                    activeFloorLevelStore.update(floorLevel)
                }
            }

            // calculate new route points and display navigation path on the map
            if (originLatLon != null && destinationLatLon != null) {
                RoutingService.navigate(
                    from = originLatLon,
                    to = destinationLatLon,
                    startFloorId = originFloorId,
                    targetFloorId = destinationFloorId,
                ).also { routingPoints ->
                    val listOfRoutePoints = routingPoints.mapIndexed { index, routePoint ->
                        val floorId = routePoint.tags.getUniqueTag(ObjectTags.ConnectedTo)
                        val floorLevel = floorId?.let {
                            currentBuildingsStore.current.getBuilding(it)?.getFloorLevel(it)
                        }
                        val previousFloorId = if (index > 0) {
                            routingPoints[index - 1].tags.getUniqueTag(ObjectTags.ConnectedTo)
                        } else null
                        val previousLevel = previousFloorId?.let {
                            currentBuildingsStore.current.getBuilding(it)?.getFloorLevel(it)
                        }
                        val nextFloorId = if (index < routingPoints.size - 1) {
                            routingPoints[index + 1].tags.getUniqueTag(ObjectTags.ConnectedTo)
                        } else null
                        val nextLevel = nextFloorId?.let {
                            currentBuildingsStore.current.getBuilding(it)?.getFloorLevel(it)
                        }
                        val isLevelDown =
                            previousFloorId?.let { previous -> floorId?.let { pointFloorId -> previous != pointFloorId } }
                                ?: false
                        val isLevelUp = nextFloorId?.let { next -> floorId?.let { pointFloorId -> next != pointFloorId } }
                            ?: false

                        RoutePoint(
                            lat = routePoint.latLon.lat,
                            lon = routePoint.latLon.lon,
                            floorId = floorId,
                            floorLevel = floorLevel,
                            isFloorChange = isLevelUp || isLevelDown,
                            floorChange = floorLevel?.let {
                                if (isLevelUp) {
                                    nextLevel?.let {
                                        FloorChange(
                                            previousLevel = floorLevel,
                                            newLevel = nextLevel,
                                        )
                                    }
                                } else if (isLevelDown) {
                                    previousLevel?.let {
                                        FloorChange(
                                            previousLevel = previousLevel,
                                            newLevel = floorLevel,
                                        )
                                    }
                                } else null
                            },
                        )
                    }

                    return listOfNotNull(
                        RoutePoint(
                            lat = originLatLon.lat,
                            lon = originLatLon.lon,
                            floorId = originFloorId,
                        ),
                    ) + listOfRoutePoints + listOfNotNull(
                        RoutePoint(
                            lat = destinationLatLon.lat,
                            lon = destinationLatLon.lon,
                            floorId = destinationFloorId,
                        ),
                    )
                }
            } else {
                return null
            }
        }

        navigationToolToggleStore.data.render { navigationEnabled ->
            if (navigationEnabled) {

                val activeNavigationInputStore = storeOf<NavigationInput?>(null)

                val originInputQueryStore = storeOf("", Job())
                val destinationInputQueryStore = storeOf("", Job())

                // handle object search input queries
                originInputQueryStore.data handledBy { q ->
                    doSearch(q)
                }
                destinationInputQueryStore.data handledBy { q ->
                    doSearch(q)
                }

                // check last known position store here and update originPointSourceStore with it
                lastKnownPositionStore.current?.latLon?.let { lastPosition ->
                    originPointSourceStore.update(NavigationPointSource.LastKnownLocation)
                    originLatLonStore.update(lastPosition)
                }

                // Main navigation component container
                div(
                    baseClass = "flex flex-col gap-1 overflow-hidden w-full md:w-120 md:min-w-100 h-min min-h-min max-h-128 z-[1020] md:z-[1009] absolute top-0 md:top-4 md:left-4 md:relative rounded-b-2xl md:rounded-2xl bg-formationWhite transition-all duration-300 ease-in-out shadow-2xl", // rounded-r-2xl md:w-100 absolute top-0 left-0 right-14 md:right-auto
                    id = navigationToolContainerId,
                ) {
                    // Row of Title and Close button
//                    twRowOfJustifyBetween {
//                        className("pl-10 pr-2")
//                        // Title
//                        p("h-12 text-center text-xl font-bold py-2") { +"Navigation" } // TODO translate
//                        // Button to close navigation
//                        twCloseButton {
//                            clicks handledBy {
//                                navigationStore.reset(Unit)
//                                maplibreMap.showRoutingPath(listOf())
//                                navigationToolToggleStore.update(false)
//                            }
//                        }.twTooltip(positioning = PlacementValues.left) {
//                            +"Close Navigation" // TODO translate
//                        }
//                    }


                    // Column of Containers for origin and destination input
                    twColOfNoGap {
                        className("w-full px-2 pt-4 pb-3")

                        /**
                         * Row of "Move to Location"-(icon <-> button) and Origin inputField
                         */
                        twRowOf {
                            // Origin: Position icon <-> "Move to Start" button
                            combine(originLatLonStore.data, mapStateStore.data) { d, m -> Pair(d, m) }.render { (originLatLon, mapState) ->
                                if (originLatLon != null && originLatLon.distanceTo(mapState?.center ?: originLatLon) > 1) {
                                    twMediumIconButton(FormationUIIcons.StartCircle.icon) {
                                        className("text-slate-500 hover:text-red-300")
                                        clicks handledBy {
                                            maplibreMap.flyTo(
                                                originLatLon,
                                                zoom = max(mapState?.zoom ?: maplibreMap.getZoom(), 19.0),
                                                maxDuration = 1000.0,
                                            )
                                            val floorId = getFloorIdForNavigationPointSource(
                                                navigationPointSource = originPointSourceStore.current,
                                                latLon = originLatLon,
                                                selectedObjectStore = originObjectStore,
                                            )
                                            // switch floor to known level of starting point
                                            getLevelFromFloorId(floorId)?.let { floorLevel ->
                                                activeFloorLevelStore.update(floorLevel)
                                            }
                                        }
                                    }.twTooltip(positioning = PlacementValues.right, fallback = "Move Map to Starting Point") {
                                        +"Move Map to Starting Point" // TODO translate
                                    }
                                } else {
                                    div {
                                        className(if (originLatLon != null) "text-red-500" else "text-slate-500")
                                        twIconMedium(FormationUIIcons.StartCircle.icon)
                                    }
                                }
                            }

                            // Origin: InputField for Search <-> Selected NavigationPointSource (YourLocation, MapCenter, LastKnownLocation or Object)
                            originPointSourceStore.data.render { originSource ->
                                when (originSource) {

                                    // display user marker when using GPS location
                                    NavigationPointSource.YourLocation -> {
                                        navigationPointSourceRichButton(
                                            title = originSource.name,
                                            icon = originSource.icon,
                                            latLonAsSubtitleFlow = originLatLonStore.data,
                                        ) {
                                            val profilePicture = myProfileStore.current.profilePhoto?.href
                                            renderSvgIcon(
                                                myUserSvgIconOptions(
                                                    size = myUserSize,
                                                    sharing = locationUploadStore.current.isActive,
                                                    color = null,
                                                    icon = null,
                                                    shape = null,
                                                    picture = profilePicture,
                                                ),
                                            )
                                            clicks handledBy {
                                                navigationStore.setOriginObjectWithSourceAndLatLon(null)
                                                activeNavigationInputStore.update(NavigationInput.Origin)
                                                lastSelectedInput = NavigationInput.Origin
                                            }
                                        }
                                    }
//
                                    // display basic button with Map center icon and raw map center coordinates as subtitle
                                    NavigationPointSource.MapCenter -> {
                                        navigationPointSourceRichButton(
                                            title = originSource.name,
                                            icon = originSource.icon,
                                            latLonAsSubtitleFlow = originLatLonStore.data,
                                        ) {
                                            // check if current map center is point in building
                                            combine(originLatLonStore.data, activeFloorLevelStore.data) { latLon, level ->
                                                Pair(
                                                    latLon,
                                                    level,
                                                )
                                            }.render { (mapCenter, floorLevel) ->
                                                currentBuildingsStore.pointWithinFloor(mapCenter)?.let { floorId ->
                                                    currentBuildingsStore.current.getBuilding(floorId)?.let { building ->
                                                        building.buildingName?.let { buildingName ->
                                                            twIconSmallToMedium(icon = FormationUIIcons.ArrowRight.icon)
                                                            twColOfNoGap {
                                                                className("p-0.5 self-start min-w-0 overflow-hidden")
                                                                titleSizedText {
                                                                    +buildingName
                                                                }
                                                                ellipseText(
                                                                    {
                                                                        fontSize(sm = { tiny }, md = { smaller })
                                                                    },
                                                                ) {
                                                                    +"Level: $floorLevel"
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            clicks handledBy {
                                                navigationStore.setOriginObjectWithSourceAndLatLon(null)
                                                activeNavigationInputStore.update(NavigationInput.Origin)
                                                lastSelectedInput = NavigationInput.Origin
                                            }
                                        }
                                    }

                                    // Last Known Position (GPS -> User Icon, Scan -> mini Marker of last scanned Object)
                                    NavigationPointSource.LastKnownLocation -> {
                                        navigationPointSourceRichButton(
                                            title = originSource.name,
                                            icon = when (lastKnownPositionStore.current?.type) {
                                                PositionType.GPS -> FormationIcons.Position.icon
                                                PositionType.ScannedObject -> FormationIcons.QRCode.icon
                                                null -> originSource.icon
                                            },
                                            // use subtitle to specify where last known location is from (fallback to display raw coordinates)
                                            subtitleBlock = when (lastKnownPositionStore.current?.type) {
                                                PositionType.GPS -> {
                                                    {
                                                        ellipseText(
                                                            {
                                                                fontSize { tiny }
                                                                fontStyle { italic }
                                                            },
                                                        ) { +"from GPS" }
                                                    }
                                                }

                                                PositionType.ScannedObject -> {
                                                    {
                                                        ellipseText(
                                                            {
                                                                fontSize { tiny }
                                                                fontStyle { italic }
                                                            },
                                                        ) { +"from QR Scan" }
                                                    }
                                                }

                                                else -> null
                                            },
                                            latLonAsSubtitleFlow = originLatLonStore.data,
                                        ) {
                                            when (lastKnownPositionStore.current?.type) {
                                                // display user icon if last known location is from GPS
                                                PositionType.GPS -> {
                                                    twIconSmallToMedium(icon = FormationUIIcons.ArrowLeft.icon)
                                                    val profilePicture = myProfileStore.current.profilePhoto?.href
                                                    renderSvgIcon(
                                                        myUserSvgIconOptions(
                                                            size = myUserSize,
                                                            sharing = locationUploadStore.current.isActive,
                                                            color = null,
                                                            icon = null,
                                                            shape = null,
                                                            picture = profilePicture,
                                                        ),
                                                    )
                                                }
                                                // display mini Marker if last known location is from of last scanned object
                                                PositionType.ScannedObject -> {
                                                    lastKnownPositionStore.current?.geoObjectDetails?.let { geoObject ->
                                                        getSvgIconOptions(
                                                            obj = geoObject,
                                                            flagged = geoObject.isFlagged,
                                                            showStateIndicator = true,
                                                        )?.copy(size = MarkerSize.XS)?.let {
                                                            twIconSmallToMedium(icon = FormationUIIcons.ArrowLeft.icon)
                                                            div("flex shrink flex-col max-h-11 items-center justify-center overflow-hidden") {
                                                                renderSvgIcon(it)
                                                                ellipseText({ fontSize { tiny } }) {
                                                                    +geoObject.title
                                                                }
                                                            }
                                                        }
                                                    }
                                                }

                                                null -> {}
                                            }

                                            clicks handledBy {
                                                navigationStore.setOriginObjectWithSourceAndLatLon(null)
                                                activeNavigationInputStore.update(NavigationInput.Origin)
                                                lastSelectedInput = NavigationInput.Origin
                                            }
                                        }
                                    }

                                    // display simplified list entry of selected object
                                    NavigationPointSource.OtherObject -> {
                                        originObjectStore.data.render { obj ->
                                            if (obj != null) {
                                                div("flex flex-row w-full items-center justify-center cursor-pointer rounded-xl bg-gray-100 hover:bg-gray-200 overflow-hidden") {
                                                    simpleListEntry(obj)
                                                    clicks handledBy {
                                                        originInputQueryStore.update("")
                                                        navigationStore.setOriginObjectWithSourceAndLatLon(null)
                                                        activeNavigationInputStore.update(NavigationInput.Origin)
                                                        lastSelectedInput = NavigationInput.Origin
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    // display input field, if nothing is selected
                                    null -> {
                                        twInputField(originInputQueryStore) {
                                            twInputTextField {
                                                doOnceWhenElementInDOM(domNode = domNode) {
                                                    domNode.focus()
                                                    activeNavigationInputStore.update(NavigationInput.Origin)
                                                }
                                                placeholder("Choose starting point... (type to search)") // TODO translate
                                            }
                                            focusinsCaptured handledBy {
                                                activeNavigationInputStore.update(NavigationInput.Origin)
                                                lastSelectedInput = NavigationInput.Origin
                                            }
                                            focusoutsCaptured.handledBy {
                                                delay(500)
                                                // reset focus input state, but only if not other input is already in focus
                                                if (activeNavigationInputStore.current == NavigationInput.Origin) {
                                                    activeNavigationInputStore.update(null)
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            // Button to close navigation
                            twLargeIconButtonNeutral(icon = FormationUIIcons.Close.icon) {
                                clicks handledBy {
                                    navigationStore.reset(Unit)
                                    maplibreMap.showRoutingPath(listOf())
                                    navigationToolToggleStore.update(false)
                                }
                            }.twTooltip(positioning = PlacementValues.left, fallback = "Close Navigation") {
                                +"Close Navigation" // TODO translate
                            }
                        }

                        // Dots visualisation between inputs
                        twRowOfJustifyStart {
                            className("text-slate-500")
                            twIconMedium(icon = FormationUIIcons.OptionsVertical.icon)
                        }

                        /**
                         * Row of "Move to Location"-(icon <-> button) and Destination inputField
                         */
                        twRowOf {
                            // Destination: Location icon <-> "Move to Destination" button
                            combine(destinationLatLonStore.data, mapStateStore.data) { d, m -> Pair(d, m) }.render { (destinationLatLon, mapState) ->
                                if (destinationLatLon != null && destinationLatLon.distanceTo(mapState?.center ?: destinationLatLon) > 1) {
                                    twMediumIconButton(FormationIcons.Location.icon) {
                                        className("text-slate-500 hover:text-red-300")
                                        clicks handledBy {
                                            maplibreMap.flyTo(
                                                destinationLatLon,
                                                zoom = max(mapState?.zoom ?: maplibreMap.getZoom(), 19.0),
                                                maxDuration = 1000.0,
                                            )

                                            val floorId = getFloorIdForNavigationPointSource(
                                                navigationPointSource = destinationPointSourceStore.current,
                                                latLon = destinationLatLon,
                                                selectedObjectStore = destinationObjectStore,
                                            )

                                            // switch floor to known level of starting point
                                            getLevelFromFloorId(floorId)?.let { floorLevel ->
                                                activeFloorLevelStore.update(floorLevel)
                                            }
                                        }
                                    }.twTooltip(positioning = PlacementValues.right, fallback = "Move Map to Destination Point") {
                                        +"Move Map to Destination Point" // TODO translate
                                    }
                                } else {
                                    div {
                                        className(if (destinationLatLon != null) "text-red-500" else "text-slate-500")
                                        twIconMedium(FormationIcons.Location.icon)
                                    }
                                }
                            }

                            // Destination: InputField for Search <-> Selected NavigationPointSource (YourLocation, MapCenter, LastKnownLocation or Object)
                            destinationPointSourceStore.data.render { destinationSource ->
                                when (destinationSource) {

                                    // display user marker when using GPS location
                                    NavigationPointSource.YourLocation -> {
                                        navigationPointSourceRichButton(
                                            title = destinationSource.name,
                                            icon = destinationSource.icon,
                                            latLonAsSubtitleFlow = destinationLatLonStore.data,
                                        ) {
                                            val profilePicture = myProfileStore.current.profilePhoto?.href
                                            renderSvgIcon(
                                                myUserSvgIconOptions(
                                                    size = myUserSize,
                                                    sharing = locationUploadStore.current.isActive,
                                                    color = null,
                                                    icon = null,
                                                    shape = null,
                                                    picture = profilePicture,
                                                ),
                                            )
                                            clicks handledBy {
                                                navigationStore.setDestinationObjectWithSourceAndLatLon(null)
                                                activeNavigationInputStore.update(NavigationInput.Destination)
                                                lastSelectedInput = NavigationInput.Destination
                                            }
                                        }
                                    }
//
                                    // display basic button with Map center icon and raw map center coordinates as subtitle
                                    NavigationPointSource.MapCenter -> {
                                        // MapCenter
                                        navigationPointSourceRichButton(
                                            title = destinationSource.name,
                                            icon = destinationSource.icon,
                                            latLonAsSubtitleFlow = destinationLatLonStore.data,
                                        ) {
                                            // check if current map center is point in building
                                            combine(destinationLatLonStore.data, activeFloorLevelStore.data) { latLon, level ->
                                                Pair(
                                                    latLon,
                                                    level,
                                                )
                                            }.render { (mapCenter, floorLevel) ->
                                                currentBuildingsStore.pointWithinFloor(mapCenter)?.let { floorId ->
                                                    currentBuildingsStore.current.getBuilding(floorId)?.let { building ->
                                                        building.buildingName?.let { buildingName ->
                                                            twIconSmallToMedium(icon = FormationUIIcons.ArrowRight.icon)
                                                            twColOfNoGap {
                                                                className("p-0.5 self-start min-w-0 overflow-hidden")
                                                                titleSizedText {
                                                                    +buildingName
                                                                }
                                                                ellipseText(
                                                                    {
                                                                        fontSize(sm = { tiny }, md = { smaller })
                                                                    },
                                                                ) {
                                                                    +"Level: $floorLevel"
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            clicks handledBy {
                                                navigationStore.setDestinationObjectWithSourceAndLatLon(null)
                                                activeNavigationInputStore.update(NavigationInput.Destination)
                                                lastSelectedInput = NavigationInput.Destination
                                            }
                                        }
                                    }

                                    // Last Known Position (GPS -> User Icon, Scan -> mini Marker of last scanned Object)
                                    NavigationPointSource.LastKnownLocation -> {
                                        navigationPointSourceRichButton(
                                            title = destinationSource.name,
                                            icon = when (lastKnownPositionStore.current?.type) {
                                                PositionType.GPS -> FormationIcons.Position.icon
                                                PositionType.ScannedObject -> FormationIcons.QRCode.icon
                                                null -> destinationSource.icon
                                            },
                                            subtitleBlock = when (lastKnownPositionStore.current?.type) {
                                                PositionType.GPS -> {
                                                    {
                                                        ellipseText(
                                                            {
                                                                fontSize { tiny }
                                                                fontStyle { italic }
                                                            },
                                                        ) { +"from GPS" }
                                                    }
                                                }

                                                PositionType.ScannedObject -> {
                                                    {
                                                        ellipseText(
                                                            {
                                                                fontSize { tiny }
                                                                fontStyle { italic }
                                                            },
                                                        ) { +"from QR Scan" }
                                                    }
                                                }

                                                else -> null
                                            },
                                            latLonAsSubtitleFlow = destinationLatLonStore.data,
                                        ) {
                                            when (lastKnownPositionStore.current?.type) {
                                                PositionType.GPS -> {
                                                    twIconSmallToMedium(icon = FormationUIIcons.ArrowLeft.icon)
                                                    val profilePicture = myProfileStore.current.profilePhoto?.href
                                                    renderSvgIcon(
                                                        myUserSvgIconOptions(
                                                            size = myUserSize,
                                                            sharing = locationUploadStore.current.isActive,
                                                            color = null,
                                                            icon = null,
                                                            shape = null,
                                                            picture = profilePicture,
                                                        ),
                                                    )
                                                }

                                                PositionType.ScannedObject -> {
                                                    lastKnownPositionStore.current?.geoObjectDetails?.let { geoObject ->
                                                        getSvgIconOptions(
                                                            obj = geoObject,
                                                            flagged = geoObject.isFlagged,
                                                            showStateIndicator = true,
                                                        )?.copy(size = MarkerSize.XS)?.let {
                                                            twIconSmall(icon = FormationUIIcons.ArrowLeft.icon)
                                                            div("flex shrink flex-col max-h-11 items-center justify-center overflow-hidden") {
                                                                renderSvgIcon(it)
                                                                ellipseText({ fontSize { tiny } }) {
                                                                    +geoObject.title
                                                                }
                                                            }
                                                        }
                                                    }
                                                }

                                                null -> {}
                                            }

                                            clicks handledBy {
                                                navigationStore.setDestinationObjectWithSourceAndLatLon(null)
                                                activeNavigationInputStore.update(NavigationInput.Destination)
                                                lastSelectedInput = NavigationInput.Destination
                                            }
                                        }
                                    }

                                    // display simplified list entry of selected object
                                    NavigationPointSource.OtherObject -> {
                                        destinationObjectStore.data.render { obj ->
                                            if (obj != null) {
                                                div("flex flex-row w-full items-center justify-center cursor-pointer rounded-xl bg-gray-100 hover:bg-gray-200 overflow-hidden") { //border border-formationBlack
                                                    simpleListEntry(obj)
                                                    clicks handledBy {
                                                        navigationSearchHistoryStore.add(obj)
                                                        destinationInputQueryStore.update("")
                                                        navigationStore.setDestinationObjectWithSourceAndLatLon(null)
                                                        activeNavigationInputStore.update(NavigationInput.Destination)
                                                        lastSelectedInput = NavigationInput.Destination
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    // display input field, if nothing is selected
                                    null -> {
                                        twInputField(store = destinationInputQueryStore) {
                                            twInputTextField {
                                                doOnceWhenElementInDOM(domNode = domNode) {
                                                    domNode.focus()
                                                    activeNavigationInputStore.update(NavigationInput.Destination)
                                                }
                                                placeholder("Choose destination... (type to search)") // TODO translate
                                            }
                                            focussCaptured handledBy {
                                                activeNavigationInputStore.update(NavigationInput.Destination)
                                                lastSelectedInput = NavigationInput.Destination
                                            }
                                            focusoutsCaptured.handledBy {
                                                // reset focus input state, but only if not other input is already in focus
                                                delay(500)
                                                if (activeNavigationInputStore.current == NavigationInput.Destination) {
                                                    activeNavigationInputStore.update(null)
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            // Button to Reverse selected NavigationPointSources of Origin and Destination
                            twLargeIconButtonNeutral(FormationUIIcons.Sort.icon) {
                                clicks handledBy navigationStore.reverse
                            }.twTooltip(positioning = PlacementValues.left, fallback = "Reverse Starting Point and Destination Point") {
                                +"Reverse Starting Point and Destination Point" // TODO translate
                            }
                        }
                    }

                    // Combined search results with buttons to select navigation point source options: LastKnownLocation, MapCenter, Your Location (GPS)
                    combine(activeNavigationInputStore.data, navigationSearchResultsStore.data) { i, r -> Pair(i, r) }.render { (selectedInput, results) ->
                        if (selectedInput != null) {
                            if (results.isNotEmpty()) {

                                separationLine { bottom { small } }

                                div(
                                    "flex flex-col w-full max-h-100 mt-1 rounded-b-2xl overflow-y-auto", // md:max-w-100 bg-white border border-gray-300 shadow-lg
                                ) {
                                    results.forEach { obj ->
                                        div("p-1 cursor-pointer hover:bg-gray-100") {
                                            simpleListEntry(obj)
                                            clicks handledBy {
                                                when (selectedInput) {
                                                    NavigationInput.Origin -> {
                                                        originInputQueryStore.update("")
                                                        navigationStore.setOriginObjectWithSourceAndLatLon(obj)
                                                    }

                                                    NavigationInput.Destination -> {
                                                        destinationInputQueryStore.update("")
                                                        navigationStore.setDestinationObjectWithSourceAndLatLon(obj)
                                                    }

                                                    else -> {}
                                                }
                                                navigationSearchHistoryStore.add(obj)
                                                activeNavigationInputStore.update(null)
                                            }
                                        }
                                    }
                                }
                            } else {
                                // show buttons for navigation point source options here:
                                // MapCenter, Your Location (GPS), LastKnownLocation
                                combine(
                                    activeNavigationInputStore.data,
                                    originPointSourceStore.data,
                                    destinationPointSourceStore.data,
                                ) { input, o, d -> Triple(input, o, d) }.render { (activeInput, originSource, destinationSource) ->

                                    separationLine { bottom { small } }

                                    val currentSelectedOtherSource = if (activeInput == NavigationInput.Destination) originSource else destinationSource

                                    // display source option buttons
                                    twRowOfWrap {
                                        className("px-2 pb-2")

                                        NavigationPointSource.entries.forEach { navPointSrc ->
                                            // Don't show button for current selected PointSource, OtherObject
                                            if (
                                                currentSelectedOtherSource != navPointSrc
                                                && navPointSrc != NavigationPointSource.OtherObject
                                                && (if (navPointSrc == NavigationPointSource.LastKnownLocation) {
                                                    // only show Last Position as Option here, if its available and from a Scanned object,
                                                    // otherwise it's same as YourLocation
                                                    lastKnownPositionStore.current?.type == PositionType.ScannedObject
                                                } else true)
                                            ) {
                                                navigationPointSourceSmallButton(
                                                    title = navPointSrc.name,
                                                    icon = if (navPointSrc == NavigationPointSource.LastKnownLocation) {
                                                        when (lastKnownPositionStore.current?.type) {
                                                            PositionType.GPS -> FormationIcons.Position.icon
                                                            PositionType.ScannedObject -> FormationIcons.QRCode.icon
                                                            null -> navPointSrc.icon
                                                        }
                                                    } else navPointSrc.icon,
                                                ) {
                                                    clicks handledBy {
                                                        if (navPointSrc == NavigationPointSource.YourLocation) {
                                                            geoPositionService.getActiveWatchIdOrNew()
                                                        }
                                                        when (activeInput) {
                                                            NavigationInput.Origin -> originPointSourceStore.update(navPointSrc)
                                                            NavigationInput.Destination -> destinationPointSourceStore.update(navPointSrc)
                                                            else -> {}
                                                        }
                                                        activeNavigationInputStore.update(null)
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                // Navigation search history (last 5 objects)
                                combine(activeNavigationInputStore.data, navigationSearchHistoryStore.data) { input, history ->
                                    Pair(input, history)
                                }.render { (activeInput, objectHistory) ->
                                    if (activeInput != null && objectHistory.isNotEmpty()) {

                                        twRowOfJustifyBetween {
                                            className("px-2 opacity-50")
                                            twRowOf {
                                                twIconMedium(icon = FormationIcons.History.icon)
                                                p("flex w-full items-center, justify-center italic") { +"History" } // TODO translate
                                            }
                                            twMediumIconButton(icon = FormationUIIcons.Close.icon) {
                                                clicks handledBy navigationSearchHistoryStore.clear
                                            }.twTooltip {
                                                +"Clear History" // TODO translate
                                            }
                                        }

                                        div(
                                            "flex flex-col w-full max-h-100 mt-1 bg-white rounded-b-2xl overflow-y-auto", //md:max-w-100 border border-gray-300 shadow-lg
                                        ) {
                                            objectHistory.forEach { obj ->
                                                div("p-1 cursor-pointer opacity-50 hover:bg-gray-100") {
                                                    simpleListEntry(obj)
                                                    clicks handledBy {
                                                        when (activeInput) {
                                                            NavigationInput.Origin -> {
                                                                originInputQueryStore.update("")
                                                                navigationStore.setOriginObjectWithSourceAndLatLon(obj)
                                                            }

                                                            NavigationInput.Destination -> {
                                                                destinationInputQueryStore.update("")
                                                                navigationStore.setDestinationObjectWithSourceAndLatLon(obj)
                                                            }

                                                            else -> {}
                                                        }
                                                        navigationSearchHistoryStore.add(obj)
                                                        activeNavigationInputStore.update(null)
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        } else {
                            combine(
                                originPointSourceStore.data,
                                originObjectStore.data,
                                destinationPointSourceStore.data,
                                destinationObjectStore.data,
                            ) { origin, originObj, destination, destinationObj ->
                                Quadruple(origin, originObj, destination, destinationObj)
                            }.render { (origin, _, destination, _) ->
                                if (origin != null && destination != null) {

                                    // Trigger drawing the path on the map
                                    CoroutineScope(CoroutineName("draw-navigation-path")).launch {
                                        getRoutePoints(changeFloor = false)?.let { points ->
                                            maplibreMap.drawRoutingPath(points)
                                        }
                                    }

                                    twRowOfNoGap {
                                        // Go to Start Button
                                        twFlatIconButton(icon = FormationUIIcons.StartCircle.icon) {
                                            className("grow")
                                            p("text-sm truncate select-none") {
                                                +"Go to Start"
                                            }
                                            clicks handledBy {

                                                val latLon = selectedNavigationOriginCoordinatesStore.current

                                                val floorId = getFloorIdForNavigationPointSource(
                                                    navigationPointSource = origin,
                                                    latLon = latLon,
                                                    selectedObjectStore = originObjectStore,
                                                )

                                                // switch floor to known level of starting point
                                                getLevelFromFloorId(floorId)?.let { floorLevel ->
                                                    activeFloorLevelStore.update(floorLevel)
                                                }

                                                latLon?.let {
                                                    delay(500) // give the map a little time to trigger a search
                                                    maplibreMap.easeTo(
                                                        center = it,
                                                        zoom = max(maplibreMap.getZoom(), 19.0),
                                                        duration = 1000.0,
                                                    )
                                                }
                                            }
                                        }

                                        // Start Navigation button
                                        twFlatIconButton(icon = FormationUIIcons.NavigationArrow.icon) {

                                            className("grow text-white hover:text-white bg-sky-600 hover:bg-sky-500 border-sky-600 hover:border-sky-500")

                                            p("text-sm truncate select-none") {
                                                +"Show the Route" // TODO translate
                                            }

                                            clicks handledBy {
                                                getRoutePoints()?.let { points ->
                                                    maplibreMap.showRoutingPath(points)
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
