package search

import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.ObjectType
import apiclient.search.ObjectSearchResult
import apiclient.search.ObjectSearchResults
import apiclient.tags.floorId
import apiclient.validations.parseEnumValue
import com.jillesvangurp.geo.GeoGeometry
import data.objects.building.ActiveBuildingStore
import data.objects.building.CurrentBuildingsStore
import dev.fritz2.core.RootStore
import dev.fritz2.core.SimpleHandler
import dev.fritz2.routing.MapRouter
import koin.koinCtx
import kotlinx.coroutines.CoroutineName
import kotlinx.coroutines.Job
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.launch
import mainmenu.Pages
import map.MapLayersStore
import map.MapStateStore
import model.LayerType
import model.MapState
import websocket.UserAndObjectResultsStore

enum class SearchType {
    Map, Global, Hub, Analytics
}

class SearchResultsCoordinator : RootStore<SearchType>(
    initialData = SearchType.Map,
    job = Job(),
) {

    val router: MapRouter by koinCtx.inject()
    val mapLayersStore: MapLayersStore by koinCtx.inject()
    private val currentBuildingsStore: CurrentBuildingsStore by koinCtx.inject()
    private val activeBuildingStore: ActiveBuildingStore by koinCtx.inject()
    private val userAndObjectResultsStore: UserAndObjectResultsStore by koinCtx.inject()
    val mapStateStore: MapStateStore by koinCtx.inject()

    var last: SearchType = SearchType.Map

    val renderSearchResults = SimpleHandler<Pair<SearchType, List<GeoObjectDetails>>> { data, _ ->
        data handledBy { (searchType, results) ->
            if (current == searchType) {
                last = searchType
                coroutineScope {
                    CoroutineName("render-search-results")
                    launch {
                        val filteredResults = results.filter {
                            when (it.objectType) {
                                ObjectType.UserMarker, ObjectType.HistoryEntry -> false
                                else -> true
                            }
                        }
                        val floorOrMapResultGroups = filteredResults.groupBy { it.tags.floorId == null }
                        val filteredForMap = (floorOrMapResultGroups[true] ?: listOf()).map { ObjectSearchResult(hit = it) }
                        val allFloorResults = (floorOrMapResultGroups[false] ?: listOf()).map { ObjectSearchResult(hit = it) }

                        val currentFloorResults =
                            allFloorResults.filter {
                                currentBuildingsStore.current.flatMap { (id, building) -> building.activeFloorIds ?: emptyList() }.contains(it.hit.tags.floorId)
                            }
                        // set results to show on the map
                        mapLayersStore.setResults(
                            mapOf(
                                when (searchType) {
                                    SearchType.Global -> LayerType.GlobalSearchResults
                                    SearchType.Hub -> LayerType.HubSearchResults
                                    SearchType.Map -> LayerType.MapSearchResults
                                    else -> LayerType.MapSearchResults
                                } to ObjectSearchResults(
                                    from = 0,
                                    pageSize = 0,
                                    total = filteredForMap.size,
                                    hits = filteredForMap,
                                ),
                                LayerType.FloorResults to ObjectSearchResults(
                                    from = 0,
                                    pageSize = 0,
                                    total = currentFloorResults.size,
                                    hits = currentFloorResults,
                                ),
                            ),
                        )
                    }

                    launch {
                        val objectResults = results.filter {
                            it.objectType == ObjectType.ObjectMarker
                        }.associateBy { it.id }
                        userAndObjectResultsStore.updateStoreBySearchResults(objectResults) // store is used for displaying users and tracked objects
                    }

                    launch {
                        handleBuildings(mapStateStore.current, results)
                    }
                }
            }
        }
    }

    private fun handleBuildings(mapState: MapState?, searchResults: List<GeoObjectDetails>) {
        val buildings = searchResults.filter { result ->
            result.objectType == ObjectType.Building
        }

        currentBuildingsStore.updateBuildings(buildings)
        mapState?.let { newMapState ->
            val nearestBuilding = buildings.minByOrNull { building ->
                GeoGeometry.distance(
                    building.latLon.lat,
                    building.latLon.lon,
                    newMapState.center.lat,
                    newMapState.center.lon,
                )
            }
            if (nearestBuilding != null) {
                activeBuildingStore.update(nearestBuilding.id)
            }
        }
    }

    private val manageSearchResultMapLayers = handle<String?> { current, page ->
        last = current
        when (parseEnumValue<Pages>(page)) {
            Pages.Hub -> {
                mapLayersStore.flipLayer(
                    mapOf(
                        LayerType.MapSearchResults to false,
                        LayerType.HubSearchResults to true,
                        LayerType.GlobalSearchResults to false,
                    ),
                )
                console.log("SearchType -> ${SearchType.Hub.name}")
                SearchType.Hub
            }

            Pages.Search -> {
                mapLayersStore.flipLayer(
                    mapOf(
                        LayerType.MapSearchResults to false,
                        LayerType.HubSearchResults to false,
                        LayerType.GlobalSearchResults to true,
                    ),
                )
                console.log("SearchType -> ${SearchType.Global.name}")
                SearchType.Global
            }

            else -> { // Pages.Map
                mapLayersStore.flipLayer(
                    mapOf(
                        LayerType.MapSearchResults to true,
                        LayerType.HubSearchResults to false,
                        LayerType.GlobalSearchResults to false,
                    ),
                )
                console.log("SearchType -> ${SearchType.Map.name}")
                SearchType.Map
            }
        }
    }

    init {
        router.data.map { route -> route["page"] } handledBy manageSearchResultMapLayers
    }
}


//val shape1 = GeometricShape.Polygon(
//    outer = listOf(
//        GeometricShape.Point(0.0,0.0),
//        GeometricShape.Point(3.0,0.0),
//        GeometricShape.Point(3.0,9.0),
//        GeometricShape.Point(0.0,9.0),
//        GeometricShape.Point(0.0,0.0),
//    )
//)
//
//val connectors1 = shape1.outer.zipWithNext { a, b ->
//    (a + b) / 2
//}.mapIndexed { i, point ->
//    Connector(
//        "C1-connector-$i",
//        point = point,
//        minDistance = 2.0,
//        maxDistance = 20.0
//    )
//}
//
//val shape2 = GeometricShape.Polygon(
//    outer = listOf(
//        GeometricShape.Point(0.0,0.0),
//        GeometricShape.Point(2.0,0.0),
//        GeometricShape.Point(2.0,4.0),
//        GeometricShape.Point(0.0,4.0),
//        GeometricShape.Point(0.0,0.0),
//    )
//)
//
//val connectors2 = shape2.outer.zipWithNext { a, b ->
//    (a + b) / 2
//}.mapIndexed { i, point ->
//    Connector(
//        "C2-connector-$i",
//        point = point,
//        minDistance = 2.0,
//        maxDistance = 20.0
//    )
//}
//
//val shape3 = GeometricShape.Polygon(
//    outer = listOf(
//        GeometricShape.Point(0.0,0.0),
//        GeometricShape.Point(2.0,0.0),
//        GeometricShape.Point(2.0,4.0),
//        GeometricShape.Point(0.0,4.0),
//        GeometricShape.Point(0.0,0.0),
//    )
//)
//
//val connectors3 = shape3.outer.zipWithNext { a, b ->
//    (a + b) / 2
//}.mapIndexed { i, point ->
//    Connector(
//        "C3-connector-$i",
//        point = point,
//        minDistance = 2.0,
//        maxDistance = 20.0
//    )
//}

//val connectableTestObjects = listOf(
//    // source
//    GeoObjectDetails(
//        id = "connectable-shape-1",
//        title = "Connectable Shape 1",
//        objectType = ObjectType.GeneralMarker,
//        ownerId = "me",
//        latLon = LatLon(lat = 52.50159675361502, lon = 13.386209719343412),
//        createdAt = "2024-01-17T16:10:10.000Z",
//        updatedAt = "2024-01-17T16:10:10.000Z",
//        geoReferencedConnectableObject = GeoReferencedConnectableObject(
//            original = ConnectableObject(
//                shape = shape1,
//                connectors = connectors1
//            ),
//            position = LatLon(lat = 52.50159675361502, lon = 13.386209719343412),
//            rotation = 33.0,
//            connections = listOf(
//                Connection(
//                    sourceMarkerId = "connectable-shape-1",
//                    sourceConnectorId = connectors1[0].id,
//                    targetMarkerId = "connectable-shape-2",
//                    targetConnectorId = connectors2[0].id
//                ),
//                Connection(
//                    sourceMarkerId = "connectable-shape-1",
//                    sourceConnectorId = connectors1[1].id,
//                    targetMarkerId = "connectable-shape-2",
//                    targetConnectorId = connectors2[2].id
//                ),
//            )
//        )
//    ).toSearchResult(),
//    // target 1
//    GeoObjectDetails(
//        id = "connectable-shape-2",
//        title = "Connectable Shape 2",
//        objectType = ObjectType.GeneralMarker,
//        ownerId = "me",
//        latLon = LatLon(lat = 52.501493377943405, lon = 13.386569823317132),
//        createdAt = "2024-01-17T16:10:10.000Z",
//        updatedAt = "2024-01-17T16:10:10.000Z",
//        geoReferencedConnectableObject = GeoReferencedConnectableObject(
//            original = ConnectableObject(
//                shape = shape2,
//                connectors = connectors2
//            ),
//            position = LatLon(lat = 52.501493377943405, lon = 13.386569823317132),
//            rotation = 78.0,
//            connections = listOf(
//                Connection(
//                    sourceMarkerId = "connectable-shape-2",
//                    sourceConnectorId = connectors2[0].id,
//                    targetMarkerId = "connectable-shape-1",
//                    targetConnectorId = connectors1[0].id
//                ),
//                Connection(
//                    sourceMarkerId = "connectable-shape-2",
//                    sourceConnectorId = connectors2[2].id,
//                    targetMarkerId = "connectable-shape-1",
//                    targetConnectorId = connectors1[1].id
//                ),
//            )
//        )
//    ).toSearchResult(),
//    // target 2
//    GeoObjectDetails(
//        id = "connectable-shape-3",
//        title = "Connectable Shape 3",
//        objectType = ObjectType.GeneralMarker,
//        ownerId = "me",
//        latLon = LatLon(lat = 52.50161864741284, lon = 13.386323315481263),
//        createdAt = "2024-01-17T16:10:10.000Z",
//        updatedAt = "2024-01-17T16:10:10.000Z",
//        geoReferencedConnectableObject = GeoReferencedConnectableObject(
//            original = ConnectableObject(
//                shape = shape3,
//                connectors = connectors3
//            ),
//            position = LatLon(lat = 52.50161864741284, lon = 13.386323315481263),
//            rotation = 2.0,
//            connections = listOf(
//                Connection(
//                    sourceMarkerId = "connectable-shape-3",
//                    sourceConnectorId = connectors3[0].id,
//                    targetMarkerId = "connectable-shape-1",
//                    targetConnectorId = connectors1[0].id
//                ),
//                Connection(
//                    sourceMarkerId = "connectable-shape-3",
//                    sourceConnectorId = connectors3[3].id,
//                    targetMarkerId = "connectable-shape-1",
//                    targetConnectorId = connectors1[1].id
//                ),
//            )
//        )
//    ).toSearchResult(),
//    // target 3
//    GeoObjectDetails(
//        id = "connectable-shape-4",
//        title = "Connectable Shape 4",
//        objectType = ObjectType.GeneralMarker,
//        ownerId = "me",
//        latLon = LatLon(lat = 52.50159295245723, lon = 13.386195479111734),
//        createdAt = "2024-01-17T16:10:10.000Z",
//        updatedAt = "2024-01-17T16:10:10.000Z",
//        geoReferencedConnectableObject = GeoReferencedConnectableObject(
//            original = ConnectableObject(
//                shape = shape3,
//                connectors = null
//            ),
//            position = LatLon(lat = 52.50159295245723, lon = 13.386195479111734),
//            rotation = 2.0,
//            connections = null
//        )
//    ).toSearchResult()
//)
