package objectrouting

import apiclient.geoobjects.AddOpenRouteToXActionCode
import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.LatLon
import apiclient.geoobjects.ObjectChanges
import apiclient.geoobjects.applyObjectChanges
import camera.cameraWrapper.cameraModal
import camera.nimiq.qrScanCamera
import data.objects.ActiveObjectStore
import dev.fritz2.core.Handler
import dev.fritz2.core.Store
import dev.fritz2.core.placeholder
import dev.fritz2.core.storeOf
import koin.koinCtx
import koin.withKoin
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.map
import localization.TL
import localization.getTranslationFlow
import localization.translate
import model.L
import model.ScanPurpose
import overlays.withBusyApiClient
import theme.FormationUIIcons
import twcomponents.twColOfNoGap
import twcomponents.twIconLarge
import twcomponents.twInputField
import twcomponents.twInputTextField
import twcomponents.twLargeIconButtonNeutral
import twcomponents.twModal
import twcomponents.twPrimaryButton
import twcomponents.twRowOf
import twcomponents.twRowOfJustifyBetween
import twcomponents.twSecondaryButton
import twcomponents.twTitle
import utils.insertObjectInCachesAndMap

fun addRouteToXActionCodeModal(
    title: Flow<String>,
    toggleShowModalStore: Store<Boolean>,
    actionCodeStore: Store<String>,
    geoObject: GeoObjectDetails?,
    destinationObjectId: String? = null,
    destinationLatLon: LatLon? = null,
    objectUpdateHandlers: List<Handler<GeoObjectDetails?>>,
) {
    withKoin {

        val activeObjectStore: ActiveObjectStore = koinCtx.get()
        val tags = activeObjectStore.map(GeoObjectDetails.L.tags)

        twModal(toggleShowModalStore) { close, modalOpened, optionalCloseHandlers ->
            twColOfNoGap {
                className("relative max-w-96 bg-formationWhite rounded-2xl overflow-hidden p-4 m-4 gap-4")

                // QR Scan Camera Modal
                val toggleCamera = storeOf(false, Job())
                cameraModal(toggleCamera) { close, opened, video, _ ->
                    qrScanCamera(
                        closeModal = close,
                        modalOpened = opened,
                        videoElement = video,
                        scanPurpose = ScanPurpose.ReadCodeAndStore,
                        codeStore = actionCodeStore,
                    )
                }

                twRowOfJustifyBetween {
                    twTitle { title.renderText() }
                    twLargeIconButtonNeutral(FormationUIIcons.Close) {
                        clicks handledBy {
                            optionalCloseHandlers?.let { handlers ->
                                handlers.forEach {
                                    it.invoke(Unit)
                                }
                            }
                            close(Unit)
                        }
                    }
                }

                p {
                    translate(TL.ActionCode.ADD_ACTION_CODE_EXPLANATION)
                }

                twRowOf {
                    twInputField(actionCodeStore) {
                        twInputTextField {
                            placeholder(TL.ActionCode.ENTER_ACTION_CODE_PLACEHOLDER.getTranslationFlow())
                        }
                    }
                    twSecondaryButton {
                        twIconLarge(FormationUIIcons.ScanQR)
                        clicks handledBy {
                            // open Camera & scan code
                            toggleCamera.update(true)
                        }
                    }
                }

                // If action code is entered, use code together with destination latLon to add a RouteToX action code to the origin object
                actionCodeStore.data.render { actionCode ->
                    if (geoObject != null) {
                        twPrimaryButton(
                            disabledFlow = actionCodeStore.data.map { it.isBlank() },
                        ) {
                            p("text-xs font-bold") { translate(TL.ActionCode.ADD_ACTION_CODE) }
                            clicks handledBy {
                                withBusyApiClient(
                                    { client ->
                                        client.applyObjectChanges(
                                            ObjectChanges(
                                                geoObject.id,
                                                AddOpenRouteToXActionCode(
                                                    actionCode = actionCode,
                                                    destinationObjectId = destinationObjectId,
                                                    latLon = destinationLatLon,
                                                ),
                                            ),
                                        )
                                    },
                                ) { results ->
                                    results.firstOrNull()?.let { obj ->
                                        if (activeObjectStore.current.id == obj.id) {
                                            tags.update(obj.tags)
                                        }
                                        insertObjectInCachesAndMap(obj)
                                        objectUpdateHandlers.forEach { update ->
                                            update(obj)
                                        }
                                    }
                                }
                                optionalCloseHandlers?.let { handlers ->
                                    handlers.forEach {
                                        it.invoke(Unit)
                                    }
                                }
                                close(Unit)
                            }
                        }
                    }
                }
            }
        }
    }
}
