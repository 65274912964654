package data.objects.views.cardinfo

import apiclient.geoobjects.CustomFields
import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.MarkerColor
import apiclient.geoobjects.ObjectTags
import apiclient.geoobjects.ObjectType
import apiclient.tags.getTagValues
import apiclient.tags.getUniqueTag
import apiclient.validations.parseEnumValue
import data.objects.ActiveObjectStore
import data.objects.views.directediting.directEditingCardContent
import data.users.settings.zoneTypeIconMap
import dev.fritz2.components.compat.span
import dev.fritz2.components.flexBox
import dev.fritz2.components.icon
import dev.fritz2.core.RenderContext
import koin.koinCtx
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.mapNotNull
import localization.TL
import localization.Translation
import mainmenu.RouterStore
import model.L
import theme.FormationColors
import theme.FormationIcons
import utils.getColorForStatus
import utils.getIcon
import utils.getName
import utils.makeRGBA
import webcomponents.baseLayout
import webcomponents.cardTitleWithSubtitle
import webcomponents.cardTitleWithSubtitleDirectEdit
import webcomponents.ellipseText
import webcomponents.selectorButton
import webcomponents.selectorContent

fun RenderContext.zoneInfoCardContent() {

    val objectType = ObjectType.Zone
    val translation: Translation by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()
    val activeObjectStore: ActiveObjectStore by koinCtx.inject()
    val title = activeObjectStore.map(GeoObjectDetails.L.title)
    val customData = activeObjectStore.map(GeoObjectDetails.L.tags).data.map { it.getTagValues(ObjectTags.ExtraData) }
    val customDataFieldsMapFlow = getCustomFieldMap(activeObjectStore.map(GeoObjectDetails.L.tags).data.map { it.getTagValues(ObjectTags.CustomField) })
    val customDataZoneType = customDataFieldsMapFlow.map { fieldMap -> fieldMap["Type"]?.fieldValue }
    val externalId = activeObjectStore.map(GeoObjectDetails.L.tags).data.map { it.getUniqueTag(ObjectTags.ExternalId) }
    val canModify = activeObjectStore.map(GeoObjectDetails.L.canModify)
    val canManage = activeObjectStore.map(GeoObjectDetails.L.canManage)

    baseLayout(
        header = {
            flexBox(
                {
                    direction { row }
                    justifyContent { spaceBetween }
                    alignItems { start }
                    /* w-full */
                    height { maxContent }
                },
            ) {
                // CARD TITLE
                canManage.data.combine(canModify.data) { manage, modify -> manage || modify }
                    .render { editAccess ->
                        if (editAccess) {
                            customDataZoneType.render { tagZoneType ->
                                if (tagZoneType != null) {
                                    zoneTypeIconMap[tagZoneType]?.let { zoneIcon ->
                                        cardTitleWithSubtitleDirectEdit(
                                            titleFlow = title.data,
                                            title = title.current,
                                            subtitle = flowOf("${objectType.getName()} - ${tagZoneType.uppercase()}"),
                                            titleIconFlow = flowOf(zoneIcon.getIcon().icon),
                                            subtitleIconFlow = externalId.map { if (!it.isNullOrBlank()) FormationIcons.QRCode.icon else null },
                                            changeHandler = activeObjectStore.editTitle,
                                        )
                                    } ?: cardTitleWithSubtitleDirectEdit(
                                        titleFlow = title.data,
                                        title = title.current,
                                        subtitle = flowOf(objectType.getName()),
                                        titleIconFlow = flowOf(objectType.getIcon().icon),
                                        subtitleIconFlow = externalId.map { if (!it.isNullOrBlank()) FormationIcons.QRCode.icon else null },
                                        changeHandler = activeObjectStore.editTitle,
                                    )
                                } else {
                                    cardTitleWithSubtitleDirectEdit(
                                        titleFlow = title.data,
                                        title = title.current,
                                        subtitle = flowOf(objectType.getName()),
                                        titleIconFlow = flowOf(objectType.getIcon().icon),
                                        subtitleIconFlow = externalId.map { if (!it.isNullOrBlank()) FormationIcons.QRCode.icon else null },
                                        changeHandler = activeObjectStore.editTitle,
                                    )
                                }
                            }
                        } else {
                            customDataZoneType.render { tagZoneType ->
                                if (tagZoneType != null) {
                                    zoneTypeIconMap[tagZoneType]?.let { zoneIcon ->
                                        cardTitleWithSubtitle(
                                            title = title.data,
                                            subtitle = flowOf("${objectType.getName()} - ${tagZoneType.uppercase()}"),
                                            titleIconFlow = flowOf(zoneIcon.getIcon().icon),
                                            subtitleIconFlow = externalId.map { if (!it.isNullOrBlank()) FormationIcons.QRCode.icon else null },
                                        )
                                    } ?: cardTitleWithSubtitle(
                                        title = title.data,
                                        subtitle = flowOf(objectType.getName()),
                                        titleIconFlow = flowOf(objectType.getIcon().icon),
                                        subtitleIconFlow = externalId.map { if (!it.isNullOrBlank()) FormationIcons.QRCode.icon else null },
                                    )
                                } else {
                                    cardTitleWithSubtitle(
                                        title = title.data,
                                        subtitle = flowOf(objectType.getName()),
                                        titleIconFlow = flowOf(objectType.getIcon().icon),
                                        subtitleIconFlow = externalId.map { if (!it.isNullOrBlank()) FormationIcons.QRCode.icon else null },
                                    )
                                }
                            }
                        }
                    }
                infoCardButtons()
            }
        },
        content = {
            directEditingCardContent {
                customDataZoneType.render { zoneType ->
                    if (zoneType != null) {
                        when (zoneType) {
                            "containers", "Container", "coils", "Coil" -> capacityInfo()
                            "machine" -> statusInfo()
                        }
                    }
                }

                customData.render { dataFlow ->
                    if (dataFlow.isNotEmpty()) {
                        selectorButton {
                            selectorContent {
                                icon({ margins { horizontal { smaller } }; size { normal } }) { fromTheme { FormationIcons.API.icon } }
                                ellipseText { translation[TL.CardCustomData.BUTTON_TITLE].renderText(into = this) }
                            }
                            clicks.map {
                                mapOf(
                                    "show" to "objectData",
                                )
                            } handledBy routerStore.addOrReplaceRoute
                        }
                    }
                }
                nestedObjectsButton()
            }
        },
        footer = {
            // CARD BUTTONS FOOTER WITH: [BACK] [EDIT]
            infoCardEditFooter(objectType)
        },
    )
}

fun RenderContext.capacityInfo() {

    val activeObjectStore: ActiveObjectStore by koinCtx.inject()
    val tagsFlow = activeObjectStore.map(GeoObjectDetails.L.tags)
    val customFieldMapFlow = getCustomFieldMap(tagsFlow.data.map { it.getTagValues(ObjectTags.CustomField) })
    val capacityFlow = customFieldMapFlow.map { fieldMap -> fieldMap["Capacity"]?.fieldValue }
    val occupancyFlow = tagsFlow.data.map { it.getTagValues(ObjectTags.ChildCount).getUniqueTag(ObjectType.ObjectMarker) }
    // TODO remove Fallback to zero, when capacity tag is "0" on all zones that have unknown capacity and when childCount tag is added with "0" when the object has zero children
    capacityFlow.mapNotNull { it?.toDoubleOrNull() ?: 0.0 }.combine(occupancyFlow.mapNotNull { it?.toDoubleOrNull() ?: 0.0 }) { c, o -> Pair(c, o) }
        .render { (capacity, occupancy) ->
            val fraction = if (capacity > 0.0) occupancy / capacity else -1.0 // -1.0 used to indicate unknown capacity
            val color = getColorForOccupancy(fraction)
            span(
                {
                    margins { top { smaller } }
                    fontSize { large }
                    color?.let { color { it.color } }
                    background {
                        color?.let { color { makeRGBA(it.color, 0.05) } }
                    }
                    radius { large }
                    padding { tiny }
                },
            ) { +"Capacity: $occupancy/${if (capacity > 0.0) capacity else "-"}" }
        }
}

fun RenderContext.statusInfo() {

    val activeObjectStore: ActiveObjectStore by koinCtx.inject()
    val tagsFlow = activeObjectStore.map(GeoObjectDetails.L.tags)
    val customFieldMapFlow = getCustomFieldMap(tagsFlow.data.map { it.getTagValues(ObjectTags.CustomField) })
    val statusCodeFlow = customFieldMapFlow.map { fieldMap -> fieldMap["Status Code"]?.fieldValue }
    val statusColorFlow = tagsFlow.data.map { parseEnumValue<MarkerColor>(it.getUniqueTag(ObjectTags.StatusColor)).getColorForStatus() }

    statusCodeFlow.combine(statusColorFlow) { s, c -> Pair(s, c) }.render { (status, color) ->
        if (!status.isNullOrBlank()) {
            span(
                {
                    margins { top { smaller } }
                    fontSize { large }
                    color { color.color }
                    background {
                        color { makeRGBA(color.color, 0.05) }
                    }
                    radius { large }
                    padding { tiny }
                },
            ) { +status }
        }
    }
}

data class CustomEntry(
    val orderNo: Int?,
    val field: CustomFields?,
    val fieldName: String?,
    val fieldValue: String?
)

fun getCustomFieldMap(customFieldsFlow: Flow<List<String>>): Flow<Map<String, CustomEntry>> {
    return try {
        val map = mutableMapOf<String, CustomEntry>()
        customFieldsFlow.map { customFields ->
            customFields.forEach { customData ->
                val enum = parseEnumValue<CustomFields>(customData.substring(0, customData.indexOf(":")))
                if (enum != null) {
                    val split = customData.split(":")
                    if (split.size > 3) {
                        val (_, orderNo, fieldName, fieldValue) = split
                        val entry = CustomEntry(
                            orderNo = orderNo.toInt(),
                            field = enum,
                            fieldName = fieldName,
                            fieldValue = fieldValue,
                        )
                        fieldName.let { map[it] = entry }
                    }
                }
            }
            map
        }
    } catch (e: Error) {
        customFieldsFlow.map {
            console.warn("Could not parse (old) custom fields from:", it.toString(), e.message)
            emptyMap()
        }
    }
}

fun getCustomFieldMap(customFields: List<String>): Map<String, CustomEntry> {
    return try {
        val map = mutableMapOf<String, CustomEntry>()
        customFields.forEach { customData ->
            val enum = parseEnumValue<CustomFields>(customData.substring(0, customData.indexOf(":")))
            if (enum != null) {
                val (_, orderNo, fieldName, fieldValue) = customData.split(":")
                val entry = CustomEntry(
                    orderNo = orderNo.toInt(),
                    field = enum,
                    fieldName = fieldName,
                    fieldValue = fieldValue,
                )
                fieldName.let { map[it] = entry }
            }
        }
        map
    } catch (e: IndexOutOfBoundsException) {
        console.warn("Error. Could not parse (old) custom fields from:", customFields.toString(), e.message)
        emptyMap()
    }
}

fun getColorForOccupancy(occupancyFraction: Double?): FormationColors? {
    return when {
        occupancyFraction != null && occupancyFraction < 0.0 -> FormationColors.GrayDisabled
        occupancyFraction != null && occupancyFraction >= 0.0 && occupancyFraction < 0.6 -> FormationColors.GreenForest
        occupancyFraction != null && occupancyFraction >= 0.6 && occupancyFraction < 1.0 -> FormationColors.YellowDoing
        occupancyFraction != null && occupancyFraction >= 1.0 -> FormationColors.RedError
        else -> null
    }
}
